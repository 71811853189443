<script>
    import InputTime from "./InputTimeCalc.svelte";
    
    import { formatTime, timeConvert } from '../../../js/formatDateTime';

    export let predefinedTimes;

    const convertTimeToSeconds = (timeStr) => {
        const timeStrParts = timeStr.split(":");
        return ( parseInt(timeStrParts[0]) * 3600 + parseInt(timeStrParts[1]) * 60 );
    };

    const { numberOfWorkingDays, maxSeconds, weekArr } = predefinedTimes.reduce((acc, day) => {
        const { timeStr, work, absence, blocked, workedTime } = day;
        const seconds = convertTimeToSeconds(timeStr);
        if (work) {
            acc.maxSeconds += seconds;
            acc.numberOfWorkingDays++;
        }
        acc.weekArr.push({ timeStr, work, absence, blocked, workedTime });

        return acc;
    }, { numberOfWorkingDays: 0, maxSeconds: 0, weekArr: [] });

    const workMinutesByDay = maxSeconds / numberOfWorkingDays / 60;

    let week = weekArr.map(day => {
        const { work, absence, blocked, workedTime } = day;
        
        let str = '';
        if (absence) {
            str = timeConvert(workMinutesByDay);
        } else if (work && !absence && blocked && workedTime !== '00:00') {
            str = workedTime;
        } else {
            str = '00:00';
        }

        return { timeStr: str, work, absence, blocked, workedTime };
    });

    let totalMinutes = 0;
    const calculatedWeek = week;

    const calculate = (ev) => {
        let error = false;
        if (ev !== undefined) {
            const timeStr = formatTime(ev.detail.time);
            if (timeStr !== -1) {
                week[ev.detail.index].timeStr = timeStr;
                week[ev.detail.index].blocked = true;
            } else {
                week[ev.detail.index].error = true;
                error = true;
            }
        }
        if (!error) {
            const { nUnlockedDays, blockedSeconds } = week.reduce((acc, day) => {
                const { timeStr, work, absence, blocked } = day;
                if (work) {
                    if (!absence && !blocked) acc.nUnlockedDays++;
                    else if (absence) acc.blockedSeconds += workMinutesByDay * 60;
                    else if (blocked) acc.blockedSeconds += convertTimeToSeconds(timeStr);
                }
                return acc;
            }, { nUnlockedDays: 0, blockedSeconds: 0 });

            if (nUnlockedDays === 0) {
                totalMinutes = (blockedSeconds - maxSeconds) / 60;
            } else {
                const avgSecondsToWork = (maxSeconds - blockedSeconds) / nUnlockedDays;

                week = week.map((day) => {
                    const { timeStr, work, absence, blocked } = day;
                    const time = (work && !absence && !blocked) ? timeConvert(avgSecondsToWork / 60) : timeStr;

                    return { timeStr: time, work, absence, blocked };
                });
            }

            week = week.map((day, index) => {
                const { timeStr, work, absence, blocked } = day;
                const expectedSeconds = convertTimeToSeconds(weekArr[index].timeStr);
                const workedSeconds = convertTimeToSeconds(timeStr);
                
                return { timeStr, work, absence, blocked, moreThanExpected: workedSeconds >= expectedSeconds }
            });
        }
    };

    calculate();

    const reset = () => { week = structuredClone(calculatedWeek); calculate(); };
</script>

<div class="calculator-container flex">

    <button class="reset-btn flex" on:click={reset}>
        <img src="/images/new/reset.svg" width="14px" alt="">
    </button>
    <div class="inputs-container flex">
        {#each week as { timeStr, moreThanExpected, work, absence, blocked, error }, index}
            {#if absence}
            <span class="time absence">{timeConvert(workMinutesByDay)}</span>
            {:else if blocked}
                <span class="time {moreThanExpected ? 'yellow' : 'red'}">{timeStr}</span>
            {:else if work}
                <InputTime
                    time={timeStr}
                    placeholder="00:00"
                    width="100px"
                    height="22px"
                    {index}
                    {error}
                    on:blocked={calculate}
                />
            {/if}
        {/each}
    </div>
    <span style={ totalMinutes >= 0 ? 'color: #e9ad00' : 'color: #ff3e3e' }>{timeConvert(totalMinutes)}h</span>
</div>

<style>
    .reset-btn {
        margin: 0;
        border: none;
        border-radius: 100%;
        padding: 4px;
        justify-content: center;
    }

    .calculator-container {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    .inputs-container {
        justify-content: space-around;
        width: 94%;
    }

    span.time {
        width: 100px;
        height: 22px;
        border-radius: 20px;
        border: none;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .yellow {
        background-color: #ffeece;
        color: #e9ad00;
    }

    .red {
        background-color: #ffe6e6;
        color: #ff3e3e;
    }

    .absence {
        background-color: #fae1ff;
        color: #b600ff;
    }

    .flex {
        display: flex;
    }
</style>
