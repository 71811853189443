<script>
  import { onMount, afterUpdate } from 'svelte';
  export let id;
  export let gray;
  export let blue;
  export let green;
  export let grayLabel;
  export let blueLabel;
  export let greenLabel;
  export let backgroundColor = "white";
  export let thickness = '80%';
  export let fontSize = "24px";

  const GRAY_COLOR = "#605E5C";
  const BLUE_COLOR = "#327EAA";
  const GREEN_COLOR = "#418040";

  let chart;
  let data;
  let canvas;

  $: data = green + gray + blue > 0 ? (
    data = {
      labels: [grayLabel, blueLabel, greenLabel],
      datasets: [
        {
          label: "Dataset",
          data: [gray, blue, green],
          backgroundColor: [GRAY_COLOR, BLUE_COLOR, GREEN_COLOR],
        },
      ],
    }
  ) : (
    data = {
      labels: ["All tasks completed"],
      datasets: [
        {
          data: [1],
          backgroundColor: [GREEN_COLOR],
        },
      ],
    }
  );

  const chartConfig = {
    type: "doughnut",
    data: data,
    options: {
      responsive: true,
      cutout: thickness,
      tooltips: { 
        callbacks: { 
          label: (a,e) => {
            const label = e.labels[a.index] || '';
            return label === "All tasks completed" ? "All tasks completed" : `${label}: ${e.datasets[0].data[a.index]}`
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    },
  };

  onMount(() => {
    let context = canvas.getContext("2d");
    chart = new Chart(context, chartConfig);
    chart.data = data;
    chart.update();
  });
  
  afterUpdate(() => {
    chart.data = data;
    chart.update();
	});
  
</script>

<div class="taskChartContainer" style="background-color: {backgroundColor}">
  <canvas bind:this={canvas} width="100%" height="100%" />
  <div class="perc" style="font-size: {fontSize}">
    <!-- <span>{greenP}%</span> -->
    <span>{gray + blue}</span>
    <span class="messagePenTasks">Pending tasks</span>
  </div>
</div>

<style>
  div.taskChartContainer {
    width: 100%;
    height: 100%;
    position: relative;
    font-weight: bold;
    color: var(--labit-card-gray);
  }

  div.perc {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: 70%;
    transform: translate(-50%, -50%);
  }

  .messagePenTasks {
    font-size: 15px;
    font-weight: normal;
  }
</style>
