<script>
  export let wifi;
  export let onEdit;
  export let onDelete;
  export let deletingId;
</script>

<div class="cityCodeItemContainer">
  <div class="info">
    <span>{wifi.network}</span>
    <div class="codes">
      <span>{wifi.key}</span>
    </div>
  </div>
  <div class="options">
    <img
      class="fadedButton"
      src="/images/edit.svg"
      alt="Edit"
      on:click={onEdit}
    />
    <img
      class="fadedButton"
      src={deletingId === wifi.id
        ? "/images/trash-red.svg"
        : "/images/trash.svg"}
      alt="Remove"
      on:click={onDelete}
    />
  </div>
</div>

<style>
  div.cityCodeItemContainer {
    display: flex;
    margin-bottom: 10px;
  }

  div.info {
    width: 90%;
    font-size: 14px;
    color: var(--labit-card-gray);
    flex-direction: column;
  }

  div.codes {
    display: flex;
    font-size: 12px;
  }

  div.options {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }

  div.options img {
    width: 20px;
    margin-left: 5px;
  }
</style>
