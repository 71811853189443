<script>
  export let spaceRoom;
  export let type; // space, room
  export let onClick;
  export let onSelect;
  export let onEdit;
  export let onRemove;
  export let removingSpaceRoom;
  export let selected = false;
</script>

<div class="standardProjectSpaceRoomContainer" on:click={onClick}>
  <div
    class="spaceRoomInfo {type === 'space' ? 'spaceInfo' : ''}"
    on:click={onSelect}
  >
    <span class={selected ? "selected" : ""}>{spaceRoom.name}</span>
  </div>
  <div class="options">
    <img
      class="fadedButton"
      src="/images/edit.svg"
      alt="Edit"
      on:click={(ev) => {
        ev.stopPropagation();
        onEdit();
      }}
    />
    <img
      class="fadedButton last {removingSpaceRoom === spaceRoom.id
        ? 'hiddenElement'
        : ''}"
      src="/images/trash.svg"
      alt="Remove"
      on:click={(ev) => {
        ev.stopPropagation();
        onRemove();
      }}
    />
    <img
      class="fadedButton last {removingSpaceRoom !== spaceRoom.id
        ? 'hiddenElement'
        : ''}"
      src="/images/trash-red.svg"
      alt="Remove"
      on:click={(ev) => {
        ev.stopPropagation();
        onRemove();
      }}
    />
  </div>
</div>

<style>
  div.standardProjectSpaceRoomContainer {
    width: 100%;
    display: flex;
  }

  div.standardProjectSpaceRoomContainer:not(:last-child) {
    margin-bottom: 5px;
  }

  div.spaceRoomInfo {
    width: 80%;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--labit-card-gray);
  }

  div.spaceInfo {
    cursor: pointer;
  }

  div.options {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }

  div.options img {
    width: 20px;
  }

  div.options img:not(.last) {
    margin-right: 5px;
  }

  span.selected {
    font-weight: bold;
  }
</style>
