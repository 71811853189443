<script>
  import { post } from '../../js/labit-connection';
  import Toggle from "../ToggleSwitchSelector.svelte";
  import Picture from "../UserPicture.svelte";

  import dayjs from 'dayjs';
  import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

  dayjs.extend(isSameOrAfter);
  
  const options = {
    1: { id: 1, value: 'Birthdays', active: false },
    2: { id: 2, value: 'Absences', active: false }
  }

	let selected = options["2"].id;

  let users = {};
  let [absences, birthdays] = [[], []];

  const today = dayjs();
  const todayStr = today.format('MM-DD');
  const nextAbsencesLimit = dayjs().add(1, 'week');

  const size = 40;

  (async () => {
      const userDataPromise = post('SELECT contact_id as id, name, birthDate, pictureurl as pic FROM people WHERE company_id = 2 AND active = 1;');
      const absencesPromise = post('SELECT a.start, a.end, a.accepted_by_ceo, a.half, a.user_id, at.approvable, at.name as type FROM absences as a JOIN absence_type as at ON a.absence_type_id = at.id JOIN people as p ON a.user_id = p.contact_id WHERE p.active = 1;');

      const [userDataRes, absencesRes] = await Promise.all([userDataPromise, absencesPromise]);

      const usersArr = userDataRes.map(user => {
          const { id, name, birthDate, pic } = user;
          return { id, name, birthDate: dayjs(birthDate, 'YYYY-MM-DD').format('MM-DD'), pic };
      });

      users = usersArr.reduce((acc, user) => {
          const { id, name, birthDate, pic } = user;
          acc[id] = { name, birthDate, pic };
          return acc;
      }, {});

      birthdays = usersArr.filter(user => todayStr === user.birthDate);

      const filteredAbsences = absencesRes
          .map(absence => {
              const { start, end, accepted_by_ceo, half, user_id, approvable, type} = absence;
              return { id: user_id, type: type, start: dayjs(parseInt(start)), end: dayjs(parseInt(end)), accepted: parseInt(accepted_by_ceo), half: parseInt(half), pic: users.hasOwnProperty(user_id) ? users[user_id].pic : '' , approvable: parseInt(approvable)}
          })
          .filter(abs => abs.end.isSameOrAfter(today) && abs.start.isBefore(nextAbsencesLimit,'day') && (abs.accepted === 1 || abs.approvable === 0));
      
      console.log(filteredAbsences);
      const transformedAbsences = filteredAbsences.reduce((acc, absence) => {
        if (!acc.hasOwnProperty(absence.id)) acc[absence.id] = [absence];
        else {
          const absences = acc[absence.id];
          absences.forEach((abs) => {
            if (absence.start.add(1, "day").isSame(abs.end, "day")) {
              abs.end = absence.end;
            } 
            if (absence.end.subtract(1, "day").isSame(abs.start, "day")) {
              abs.start = absence.start;
            }
            if (absence.start.day() === 1) {
              if (absence.start.subtract(3, "day").isSame(abs.end, "day")) {
                abs.end = absence.end;
              }
            }
            if (absence.end.day() === 5) {
              if (absence.end.add(3, "day").isSame(abs.start, "day")) {
                abs.start = absence.start;
              }
            }
            if (absence.end.isAfter(acc[absence.id].end)) {
              acc[absence.id].end = absence.end;
            } 
            else {
              absences.push(absence);
            }
          });
        }

        return acc;
      }, {});
      console.log(transformedAbsences);
      
      absences = Object.values(transformedAbsences).flat();

      options["1"].active = birthdays.length > 0;
      options["2"].active = absences.length > 0;
  })();

</script>

<div class="main flex">
    <div class="header">
        <Toggle {options} bind:selected title={true} />
    </div>
    <div class="content">
        {#if selected === 1}
            {#each birthdays as {id, pic} (id)}
                <Picture src={pic} {size}/>
            {/each}
        {:else}
            {#each absences as {start, end, half, pic,type}, i (i)}
            {@const dayhalf = (half) ? "Half day" : "Full day"}
            {@const tooltipText = `${start.format('YYYY-MM-DD')} - ${end.format('YYYY-MM-DD')} || ${type} || ${dayhalf}`}
            {@const active = today.isBetween(start, end, 'day', '[]')}
                <Picture src={pic} {size} {tooltipText} {active} />
            {/each}
        {/if}
    </div>
</div>

<style>
    .main {
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .flex {
        display: flex;
    }

    .header, .content {
        max-width: 100%;
    }

    .content {
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow-y: auto;
        gap: 4px;
    }

    .header {
        min-height: 45px;
        height: 45px;
        border-bottom: 1px solid #e3e3e3;
    }

    .content::-webkit-scrollbar {
        width: 4px;
    }

    .content::-webkit-scrollbar-track {
        background: #efefef;
    }

    .content::-webkit-scrollbar-thumb {
        background-color: #cfcfcf;
        border-radius: 20px;
    }
</style>