<script>
  export let contact;
  export let selected;
  export let onClick;
</script>

<style>
  div.peopleDocumentsContactContainer {
    width: 100%;
    cursor: pointer;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

  div.selected {
    background-color: var(--labit-selected);
  }

  span {
    font-size: 16px;
  }

  div.selection {
    background-color: green;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }

  /* Animations */

  div.noSelected {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.noSelected:hover {
    opacity: 0.5;
  }
</style>

<div
  class="peopleDocumentsContactContainer {selected ? 'selected' : 'noSelected'}"
  on:click={onClick}>
  {#if contact.hasFiles}
    <div class="selection" />
  {/if}
  <span>{contact.name}</span>
</div>
