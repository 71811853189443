<script>
  export let tool;
  export let index;
  export let notification;
  export let onClick;
  export let editMode;
  export let onRemove;
  export let onClickEdit;

  const SLACK_ICON_URL = "/images/tools/slack.svg";
  const SLACK_CUSTOM_ICONS_URL = "/images/slack/";

  let icon = SLACK_ICON_URL;

  $: if (tool) {
    icon =
      tool.icon === ""
        ? SLACK_ICON_URL
        : SLACK_CUSTOM_ICONS_URL + tool.icon + ".svg";
  }
</script>

<div
  class="menuToolContainer"
  title={tool.name}
  on:click={() => {
    if (editMode && tool.custom) {
      onClickEdit(tool);
    } else {
      onClick(index, false);
    }
  }}
  on:contextmenu={(ev) => {
    ev.preventDefault();
    onClick(index, true);
    return false;
  }}
>
  <div
    class="icon"
    style={"background: url('" +
      icon +
      "') no-repeat center center; background-size: cover;"}
    title={tool.name}
  />
  {#if editMode && tool.custom}
    <img
      class="arrow"
      src="/images/trash-red.svg"
      alt="Remove Tool"
      on:click={(e) => {
        e.stopPropagation();
        onRemove();
      }}
    />
  {:else if notification}
    <div class="notification" />
  {/if}
</div>

<style>
  div.menuToolContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 25px;
    position: relative;
  }

  div.icon {
    width: 35px;
    height: 35px;
    /*border-radius: 50%;*/
  }

  img.arrow {
    height: 40%;
    position: absolute;
    right: 5px;
    bottom: -10px;
  }

  div.notification {
    width: 7px;
    height: 7px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    bottom: 0px;
  }

  /* Animations */

  div.icon {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.icon:hover {
    opacity: 0.5;
  }
</style>
