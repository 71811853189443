<script>
  export let text;
  export let icon;
  export let options;
  export let selectedValue;
  export let placeholder;
  export let onChange;
  export let onClickIcon;
  export let width = "259px";

  import Combobox from "./Combobox.svelte";
</script>

<style>
  div.submenuComboboxContainer {
    display: flex;
    color: var(--labit-card-gray);
    height: 21px;
    font-size: 13px;
    align-items: center;
  }

  div.submenuComboboxContainer div {
    height: 100%;
  }

  span {
    margin-right: 7px;
  }

  img {
    height: 100%;
    margin-right: 7px;
  }

  img.clickable {
    cursor: pointer;
  }

  /* Animations */

  img.clickable {
    transition: opacity 0.5s linear 0s;
  }

  img.clickable:hover {
    opacity: 0.5;
  }
</style>

<div class="submenuComboboxContainer">
  <img
    class={onClickIcon ? 'clickable' : ''}
    src={icon}
    alt="Combobox Icon"
    on:click={onClickIcon} />
  <span>{text}</span>
  <div style="width: {width}">
    <Combobox {options} {placeholder} bind:selectedValue {onChange} />
  </div>
</div>
