<script>
    export let images = [];
    export let clickEvents = [];
    export let disabled = false;
    export let size = 40;

</script>

{#if !disabled}
    <div class="arrBtnsContainer">
    {#each images as image, index}
        <input style='--size: {size}px' type="image" src={image} alt="button" on:click={clickEvents[index]}/>
    {/each}
    </div>
{/if}

<style>

    input {
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 6px;
        padding: 8px;
        height: var(--size);
        width: var(--size);
    }

    input:hover, input:focus {
        background-color: #eff2f5;
    }

    .arrBtnsContainer {
        display: flex;
        gap: 8px;
    }

    /*
    input:first-child {
        margin-left: 0;
    }

    input:last-child {
        margin-right: 0;
    }
    */
    
</style>