<script>
  export let project;
  export let index;
  export let onClose;
  export let onSave;
  export let onRemove;
  export let onNextProject;
  export let onPreviousProject;
  export let action = "show";
  export let url = "";
  export let refreshCompanyList = false;
  export let selectedProjectPDashboard = -1;

  import Switch from "./Switch.svelte";
  //import DialogFileContainer from "./DialogFileContainer.svelte";
  //import DialogURLContainer from "./DialogURLContainer.svelte";
  import DialogExpandSpan from "./DialogExpandSpan.svelte";
  import DialogSpanEdit from "./DialogSpanEdit.svelte";
  import DialogSpanSuggestionEdit from "./DialogSpanSuggestionEdit.svelte";
  //import DialogPeopleContainer from "./DialogPeopleContainer.svelte";
  //import DialogStatusHistoryContainer from "./DialogStatusHistoryContainer.svelte";
  import DialogSpanCombobox from "./DialogSpanCombobox.svelte";
  import Combobox from "./Combobox.svelte";
  //import DialogAEdit from "./DialogAEdit.svelte";
  //import DialogSpanCalendarPicker from "./DialogSpanCalendarPicker.svelte";
  import FileUploader from "./FileUploader.svelte";
  import GoogleMaps from "./GoogleMaps.svelte";
  import FileBoobleContainer from "./FileBoobleContainer.svelte";
  //import PreviewRemoteFiles from "./PreviewRemoteFiles.svelte";
  import PreviewRemoteFile from "./PreviewRemoteFile.svelte";
  import RoundedButton from "./RoundedButton.svelte";
  import DialogTab from "./DialogTab.svelte";
  import DialogRelatedProjectsContainer from "./DialogRelatedProjectsContainer.svelte";
  import DialogProjectPhaseHistory from "./DialogProjectPhaseHistory.svelte";
  import DialogProjectStatusHistory from "./DialogProjectStatusHistory.svelte";
  //import CalendarPicker from "./CalendarPicker/CalendarPicker.svelte";
  import DialogProjectTeams from "./DialogProjectTeams.svelte";
  //import DialogProjectCompanies from "./DialogProjectCompanies.svelte";
  import DialogProjectImage from "./DialogProjectImage.svelte";
  //import DialogProjectApplication from "./DialogProjectApplication.svelte";
  //import DialogProjectApplicationKey from "./DialogProjectApplicationKey.svelte";
  import DialogProjectStakeholderList from "./DialogProjectStakeholderList.svelte";
  import Picture from "./Picture.svelte";
  import FolderTypeIcon from "./FolderTypeIcon.svelte";
  import WebBrowser from "./WebBrowser.svelte";
  //import ContextMenu from "./ContextMenu.svelte";
  import Loading from "./Loading.svelte";
  import UserCard from "./UserCard.svelte";
  import UserCardWorkPeriods from "./UserCardWorkPeriods.svelte";
  import DialogSmallButton from "../components/DialogSmallButton.svelte";
  import CloseButton from "../components/CloseButton.svelte";
  import BorderButton from "../components/BorderButton.svelte";
  //import SimplePhasePreview from "../components/SimplePreview/SimplePhasePreview.svelte";
  import CompleteResourcePlanner from "../components/CompleteResourcePlanner/CompleteResourcePlanner.svelte";

  import {
    getRole,
    getOutlookToken,
    getPlatform,
    setOpenTool,
    setBackPage,
    getToken,
  } from "../js/localInfo";
  import {
    post,
    rpost,
    LABIT_PUBLIC_FOLDER,
    DOMAIN,
    LABIT_REST_SERVER,
  } from "../js/labit-connection";
  import {
    outlookLogin,
    outlookGetCalendars,
  } from "./dashboard/outlook/outlook";
  import { getCoordinatesFromMapsURL } from "../js/getCoordinatesFromMapsURL";
  import { updateCityCode } from "../js/updateCityCode";
  import {
    updateCurrentPhases,
    updateCurrentStatus,
    getCities,
    getCityCodes,
    getClients,
    getRoleInProject,
  } from "../js/projects";
  import { copyObject } from "../js/copyObject";
  import { createProjectPicName } from "../js/createPicNames";
  import { randomString } from "../js/randomString";
  import { getUserId } from "../js/localInfo";
  import { openNewWindow } from "../js/openNewWindow";
  import { openURL } from "../js/openURL";
  import { insertCompanyBasic } from "../js/companies";
  import { loadCalendar } from "../js/loadCalendar";

  import countriesJSON from "../data/countries";

  import { onMount } from "svelte";
  import moment from 'moment';

  //$: console.log(project);

  let mode = "public";

  let cardMode = "info"; // info, images, files, team, software, planning

  const myId = getUserId();

  let remoteFilePrev = "";
  let filesImages = [];
  let filesSubmissions = [];
  let filesReports = [];
  let filesModels = [];
  let selectedFiles = [];

  let externalUrls = [];

  let people = [];
  let peopleOptions = [];
  let selectedPeople = null;
  let roleOptions = [];
  let selectedRole = null;
  let contact = [];
  let contactOptions = [];
  let selectedContact = [];
  let croleOptions = [];
  let cselectedRole = null;
  let selectedPeopleCalendar1 = "01/01/" + moment().format("YYYY");
  let selectedPeopleCalendar2 = "31/12/" + moment().format("YYYY");
  let selectedContactCalendar1 = "01/01/" + moment().format("YYYY");
  let selectedContactCalendar2 = "31/12/" + moment().format("YYYY");
  let statusOptions = [];
  let status = [];
  let selectedStatus = null;
  let selectedStatusCalendar1 = moment().format("DD/MM/YYYY");
  let selectedStatusCalendar2 = moment().format("DD/MM/YYYY");
  let selectedStatusCheck = true;
  let statusText = "";
  let statusCheck = true;
  let clients = [];
  let clientList = [];

  let externalUrlOptions = [];
  let externalUrlOptionsSel = null;

  /*let externalUrlOptions = [
    {
      label: "Server",
      value: "Server",
    },
    {
      label: "Trello",
      value: "Trello",
    },
    {
      label: "Slack",
      value: "Slack",
    },
    {
      label: "Sharepoint",
      value: "Sharepoint",
    },
    {
      label: "BIM360",
      value: "BIM360",
    },
  ];
  let externalUrlOptionsSel = externalUrlOptions[0];*/
  let externaUrlOptionUrl = "";

  let teams = [];
  let companyTeams = [];

  let platform = getPlatform();

  let outlookToken = "";
  let outlookCalendarOptions = [
    {
      value: "nocreate",
      label: "Do not create an Outlook calendar for this project",
    },
    {
      value: "create",
      label: "Create a new Outlook calendar for this project",
    },
  ];
  let outlookCalendarOptionsSelected = outlookCalendarOptions[1];
  let outlookCalendarOptionsSelectedText = "";

  let loaded = false;

  let errorName = false;
  let error = false;

  let mapsURL = "";

  let toolURL = "";
  let selectedToolType = "";

  let myRole = getRole();

  let cities = [];
  let countries = [];
  let countriesSel = null;
  let cityCodes = [];
  let cityCode2 = "";
  let cityCode3 = "";
  let setCityCode2 = false;
  let setCityCode3 = false;

  let clientCode = "";

  let labitPeopleOptions = [];
  let projectManagersSel = null;
  let regularTeamSel = [];
  let benchTeamSel = [];
  let projectManagers = [];
  let regularTeam = [];
  let benchTeam = [];
  let historyTeam = [];
  let selectedForRemovalTeam = "";

  let hasId = false;

  let accId = -1;

  let userCard = null;

  let newClient = "";
  let showNewClient = "";

  let loadingFileIcon = false;
  let loadingFilePlan = false;

  //$: console.log(project);

  let defaultFolderList = [
    {
      label: "Existing Conditions",
      value: {
        id: -1,
        name: "Existing Conditions",
        url:
          PROJECTS_PUBLIC_IMAGE_URL +
          "/" +
          createProjectPicName(project) +
          "/pictures/Existing Conditions",
        isSharepoint: false,
      },
    },
    {
      label: "Renders",
      value: {
        id: -1,
        name: "Renders",
        url:
          PROJECTS_PUBLIC_IMAGE_URL +
          "/" +
          createProjectPicName(project) +
          "/pictures/Renders",
        isSharepoint: false,
      },
    },
    {
      label: "Works",
      value: {
        id: -1,
        name: "Works",
        url:
          PROJECTS_PUBLIC_IMAGE_URL +
          "/" +
          createProjectPicName(project) +
          "/pictures/Works",
        isSharepoint: false,
      },
    },
    {
      label: "Final Pictures",
      value: {
        id: -1,
        name: "Final Pictures",
        url:
          PROJECTS_PUBLIC_IMAGE_URL +
          "/" +
          createProjectPicName(project) +
          "/pictures/Final Pictures",
        isSharepoint: false,
      },
    },
  ];
  let folderList = copyObject(defaultFolderList);
  let folderListSel = folderList[0];

  let images = [];
  let imageList = [];
  let imageSel = 0;

  let imageFullscreen = false;

  let newFolderType = "aws"; // aws, sharepoint
  let newFolderName = "";
  let newFolderUrl = "";

  let removableFolder = false;
  let removingFolder = false;
  let removingFile = "";

  const PROJECTS_PUBLIC_IMAGE_URL = "/projects";
  const PROJECTS_URL = "/projects";

  let applications = [];
  let selectedApplication = -1;

  let creatingFolder = false;
  let creatingFolderName = "";
  let defaultFolders = ["submissions", "reports", "models"];
  let folders = copyObject(defaultFolders);
  let lFolders = [];

  let lastModifiedUser = null;

  let editRole = "";

  let stakeholders = [];
  let stakeholdersSel = null;
  let stakeholdersSelRole = "";

  let uploadingImageUrl = false;
  let imageUrl = "";

  let showNavMenu = null;

  let uploadingImage = false;

  let showTool = "";

  let downloadingFolder = false;

  let askingForClosing = false;
  let savedProject = null;
  let modified = false;

  let phaseReview = [];
  let milestoneReview = [];

  let platformLinks = [];

  let projectTypes = [
    {
      label: "N/A",
      value: "N/A",
    },
    {
      label: "Urban Planning",
      value: "Urban Planning",
    },
    {
      label: "Workplace",
      value: "Workplace",
    },
    {
      label: "Residential",
      value: "Residential",
    },
    {
      label: "Education",
      value: "Education",
    },
    {
      label: "Hospitality",
      value: "Hospitality",
    },
    {
      label: "Retail",
      value: "Retail",
    },
    {
      label: "Logistics",
      value: "Logistics",
    },
    {
      label: "Public Buildings",
      value: "Public Buildings",
    },
  ];
  let projectTypesSel = projectTypes[0];

  //$: console.log(project.planPicUrl);

  onMount(async () => {
    /*jscolor.presets.default = {
      format: "any",
      alphaChannel: true,
      mode: "HVS",
      closeButton: true,
      controlBorderColor: "rgba(101,187,149,1)",
      shadow: false
    };*/

    loaded = false;

    checkProjectPermissions();

    await getLastModificationUser();

    await getStatus();

    await getApplications();

    preparePeople();

    await getLabitPeople();

    await listStakeholders();

    await getPlatformLinks();

    outlookCalendarOptionsSelectedText =
      project.outlookCalendarId === "" ? "None" : project.outlookCalendarName;

    hasId = project.card.code !== "";

    countries = countriesJSON.map((country) => {
      return {
        label: country.code + " - " + country.name,
        value: country.code,
      };
    });

    countries = countries.sort((a, b) => {
      if (a.value > b.value) {
        return 1;
      } else if (a.value < b.value) {
        return -1;
      }
      return 0;
    });

    if (!project.card.city || project.card.city === "") {
      countriesSel = countries[0];
    } else {
      countriesSel = countries.find((item) => {
        return item.label === project.card.country;
      });
    }

    const pt = projectTypes.find((item) => {
      return item.value === project.programType;
    });
    projectTypesSel = pt ? pt : projectTypes[0];

    await getClientsDialog();

    cityCodes = await getCityCodes();
    cities = await getCities();
    await getPeople();
    await getRoles();
    //await getStatus();

    outlookToken = getOutlookToken();
    if (outlookToken !== "") {
      await getCalendars();
    }

    if (project.id !== -1) {
      outlookCalendarOptionsSelected =
        project.outlookCalendarId === ""
          ? outlookCalendarOptions[0]
          : {
              value: project.outlookCalendarId,
              label: project.outlookCalendarName,
            };
    }

    setCityCodes();

    await listFolders();
    await listPictureFolders();
    await listPictures();

    loaded = true;
  });

  let getPlatformLinks = async () => {
    const response = await post(
      `select name, iconUrl
        from platforms`
    );
    platformLinks = response.map((item) => {
      return {
        name: item.name,
        icon: item.iconUrl,
      };
    });
    externalUrlOptions = platformLinks.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
    externalUrlOptionsSel = externalUrlOptions[0];
  };

  let getClientsDialog = async () => {
    clients = await getClients();
    clientList = clients.map((client) => {
      return client.name;
    });
  };

  let listStakeholders = async () => {
    const response = await post(
      `select company_id, Name
        from companies
        where Active=1
        order by Name asc`
    );
    stakeholders = response.map((item) => {
      return {
        value: item.company_id,
        label: item.Name,
      };
    });
    stakeholdersSel = stakeholders[0];
  };

  let checkProjectPermissions = () => {
    const projectRole = getRoleInProject(project);
    console.log(projectRole);
    editRole =
      projectRole === "Admin" ||
      projectRole === "Analytics" ||
      projectRole === "Project Manager"
        ? "Admin"
        : "";
  };

  let getLastModificationUser = async () => {
    if (
      project.lastModificationDate &&
      project.lastModificatedBy !== myId.toString()
    ) {
      const now = moment();
      const diff = now.diff(
        moment(project.lastModificationDate, "YYYY-MM-DD HH:mm:ss"),
        "minutes"
      );
      if (diff < 60) {
        const response = await post(
          `select Name, PictureUrl
          from people
          where contact_id=${project.lastModificatedBy}`
        );
        lastModifiedUser = {
          name: response[0].Name,
          pic: response[0].PictureUrl,
          time: moment(
            project.lastModificationDate,
            "YYYY-MM-DD HH:mm:ss"
          ).fromNow(true),
        };
      }
    }
  };

  let getApplications = async () => {
    const today = moment().format("YYYY-MM-DD");
    const response = await post(
      `select AK.id as keyId, userKey, A.id as applicationId, A.name as applicationName, global, UK.id as usedId, date, P.contact_id as contactId, Email, picPath
        from applicationKeys as AK
        left join applications as A on A.id=AK.application
        left join usedKeys as UK on UK.keyId=AK.id and date>="${today}"
        left join people as P on P.contact_id=UK.people
        left join projectKeys as PK on PK.applicationKey=AK.id
        where PK.project=${project.id} or global=1
        order by applicationName asc`
    );
    applications = response.reduce((acc, curr) => {
      let application = acc.find((item) => {
        return item.id === curr.applicationId;
      });
      if (!application) {
        application = {
          id: curr.applicationId,
          name: curr.applicationName,
          global: curr.global,
          keys: [],
        };
        acc.push(application);
      }
      let key = application.keys.find((item) => {
        return item.id === curr.keyId;
      });
      if (!key) {
        key = {
          id: curr.keyId,
          key: curr.userKey,
          usedBy: null,
        };
        application.keys.push(key);
      }
      if (!key.usedBy && curr.usedId) {
        let contact = {
          id: curr.usedId,
          contactId: curr.contactId,
          email: curr.Email,
          pic: curr.picPath,
          date: curr.date,
        };
        key.usedBy = contact;
      }
      return acc;
    }, []);
    //console.log(applications);
  };

  let getLabitPeople = async () => {
    const response = await post(
      `select P.contact_id as peopleId, P.Name as peopleName, P.Email as peopleEmail, P.picPath as peoplePic, C.company_id as companyId, C.Name as companyName
        from people as P
        left join companies as C on C.company_id=P.company_id
        where esLabit=1 and P.active=1
        order by P.Name asc`
    );
    labitPeopleOptions = response.map((people) => {
      return {
        value: {
          id: people.peopleId,
          name: people.peopleName,
          email: people.peopleEmail,
          pic: people.peoplePic,
          companyId: people.companyId,
          companyName: people.companyName,
        },
        label: people.peopleName,
      };
    });
    if (labitPeopleOptions.length > 0) {
      projectManagersSel = labitPeopleOptions[0];
      regularTeamSel = labitPeopleOptions[0];
      benchTeamSel = labitPeopleOptions[0];
    }
  };

  let preparePeople = () => {
    // Labit team

    teams = [];
    project.people.forEach((people) => {
        if (!people.stakeholder) {
            let role = teams.find((item) => item.name === people.role);
            if (!role) {
                role = {
                    name: people.role,
                    people: [],
                };
                teams.push(role);
            }
            // Verificar si la persona ya está en el equipo
            const isPersonAlreadyInTeam = role.people.some(p => p.peopleId === people.peopleId);
            if (!isPersonAlreadyInTeam) {
                role.people.push(people);
            }
        }
    });

    // Company teams

    companyTeams = project.companies.map((company) => {
      return {
        companyId: company.companyId,
        companyName: company.companyName,
        role: company.role,
        teams: [],
      };
    });
    project.people.forEach((people) => {
      if (people.stakeholder) {
        let company = companyTeams.find((c) => {
          return c.companyId === people.companyId;
        });
        if (company) {
          let role = company.teams.find((item) => {
            return item.name === people.role;
          });
          if (!role) {
            role = {
              name: people.role,
              people: [],
            };
            company.teams.push(role);
          }
          role.people.push(people);
        }
      }
    });

    /*projectManagers = [];
    regularTeam = [];
    benchTeam = [];
    historyTeam = [];

    project.people.forEach((people) => {
      if (people.esLabit) {
        if (people.active) {
          switch (people.role) {
            case "Project Manager":
              projectManagers.push(people);
              break;
            case "Regular Team":
              regularTeam.push(people);
              break;
            case "Bench Team":
              benchTeam.push(people);
              break;
          }
        } else {
          historyTeam.push(people);
        }
      }
    });*/
  };

  let getStatus = async () => {
    let response = await post(
      `select id, name, active
        from defaultStatusHistory`
    );
    status = response.map((item) => {
      return {
        value: {
          id: item.id,
          name: item.name,
          active: item.active === "1",
        },
        label: item.name,
      };
    });
    selectedStatus = status[0];
  };

  let getCalendars = async () => {
    await outlookGetCalendars(
      (calendars) => {
        const calendarOptions = calendars.map((calendar) => {
          return {
            value: calendar.id,
            label: calendar.name,
          };
        });
        outlookCalendarOptions = outlookCalendarOptions.concat(calendarOptions);
      },
      () => {}
    );
  };

  let checkErrors = () => {
    errorName = false;
    error = false;

    if (project.card.name === "") {
      errorName = true;
    }

    error = errorName;
  };

  /*let getStatus = async () => {
    const response = await post(
      `select status_id, Name
        from status
        order by sort asc`
    );
    statusOptions = response.map(item => {
      return {
        value: item.status_id,
        label: item.Name
      };
    });
    if (statusOptions.length > 0) {
      selectedStatus = statusOptions[0];
    }
  };*/

  let getPeople = async () => {
    const response = await post(
      `select
          contact_id,
          PictureUrl,
          p.Name as contactName,
          Charge,
          p.Email,
          p.PhoneNumber,
          c.Name as companyName,
          p.active
          from people as p
          left join companies as c on c.company_id=p.company_id`
    );
    response.forEach((it) => {
      const pictureUrl =
        it.PictureUrl === "" ? "/images/no_user.svg" : it.PictureUrl;
      const c = {
        id: it.contact_id,
        pic: pictureUrl,
        nameSurname: it.contactName,
        companyRole: "",
        companyRoleId: -1,
        company: it.companyName ? it.companyName : "",
        prof: it.Charge,
        from: "",
        to: "",
        email: it.Email,
        phone: it.PhoneNumber,
        active: it.active === "1",
      };
      contact.push(c);
    });
    contactOptions = contact.map((c) => {
      return {
        value: c.id,
        label: c.nameSurname,
      };
    });
    selectedContact = contactOptions[0];
    people = contact.filter((c) => {
      return c.company.trim() === "Labit Group" && c.active;
    });
    peopleOptions = people.map((p) => {
      return {
        value: p.id,
        label: p.nameSurname,
      };
    });
    selectedPeople = peopleOptions[0];
  };

  let getRoles = async () => {
    const response = await post(
      `select id, role
          from roles`
    );
    roleOptions = response.map((r) => {
      return {
        value: r.id,
        label: r.role,
      };
    });
    selectedRole = roleOptions[0];
    croleOptions = JSON.parse(JSON.stringify(roleOptions));
    cselectedRole = croleOptions[0];
  };

  /*let addTeam = () => {
    let cont = people.find(p => {
      return p.id === selectedPeople.value;
    });
    cont = JSON.parse(JSON.stringify(cont));
    cont.status = "new";
    cont.companyRole = selectedRole.label;
    cont.companyRoleId = selectedRole.value;
    cont.from = selectedPeopleCalendar1;
    cont.to = selectedPeopleCalendar2;
    cont.teamId = "";
    project.team.push(cont);
    project = project;
  };*/

  let addTeam = async (role) => {
    let pos = -1;
    switch (role) {
      case "Project Manager":
        pos = project.people.findIndex((item) => {
          return (
            item.peopleId === projectManagersSel.value.id && item.role === role
          );
        });
        if (pos === -1) {
          project.people.push({
            id: accId,
            peopleId: projectManagersSel.value.id,
            peopleEmail: projectManagersSel.value.email,
            peoplePic: projectManagersSel.value.pic,
            companyId: projectManagersSel.value.companyId,
            companyName: projectManagersSel.value.companyName,
            esLabit: true,
            active: true,
            projectId: project.id,
            role,
            unactiveDate: null,
          });
          accId--;
          preparePeople();
          project = project;
        }
        break;
      case "Regular Team":
        pos = project.people.findIndex((item) => {
          return (
            item.peopleId === regularTeamSel.value.id && item.role === role
          );
        });
        if (pos === -1) {
          project.people.push({
            id: accId,
            peopleId: regularTeamSel.value.id,
            peopleEmail: regularTeamSel.value.email,
            peoplePic: regularTeamSel.value.pic,
            companyId: regularTeamSel.value.companyId,
            companyName: regularTeamSel.value.companyName,
            esLabit: true,
            active: true,
            projectId: project.id,
            role,
            unactiveDate: null,
          });
          accId--;
          preparePeople();
          project = project;
        }
        break;
      case "Bench Team":
        pos = project.people.findIndex((item) => {
          return item.peopleId === benchTeamSel.value.id && item.role === role;
        });
        if (pos === -1) {
          project.people.push({
            id: accId,
            peopleId: benchTeamSel.value.id,
            peopleEmail: benchTeamSel.value.email,
            peoplePic: benchTeamSel.value.pic,
            companyId: benchTeamSel.value.companyId,
            companyName: benchTeamSel.value.companyName,
            esLabit: true,
            active: true,
            projectId: project.id,
            role,
            unactiveDate: null,
          });
          accId--;
          preparePeople();
          project = project;
        }
        break;
    }
  };

  let removeTeam = (id) => {
    if (selectedForRemovalTeam === id || id < 0) {
      project.people = project.people.filter((item) => {
        return item.id !== id;
      });
      preparePeople();
      project = project;
      selectedForRemovalTeam = "";
    } else {
      selectedForRemovalTeam = id;
    }
  };

  let unactiveTeam = (id) => {
    const p = project.people.find((item) => {
      return item.id === id;
    });
    if (p) {
      p.active = false;
      p.unactiveDate = moment().format("YYYY-MM-DD");
      preparePeople();
      project = project;
      selectedForRemovalTeam = "";
    }
  };

  let activeTeam = (id) => {
    const p = project.people.find((item) => {
      return item.id === id;
    });
    if (p) {
      p.active = true;
      p.unactiveDate = null;
      preparePeople();
      project = project;
      selectedForRemovalTeam = "";
    }
  };

  /*let removeTeam = index => {
    project.team.splice(index, 1);
    project = project;
  };*/

  /*let addContact = () => {
    let cont = contact.find(p => {
      return p.id === selectedContact.value;
    });
    cont = JSON.parse(JSON.stringify(cont));
    cont.status = "new";
    cont.companyRole = cselectedRole.label;
    cont.companyRoleId = cselectedRole.value;
    cont.from = selectedContactCalendar1;
    cont.to = selectedContactCalendar2;
    cont.teamId = "";
    project.contact.push(cont);
    project = project;
  };

  let removeContact = index => {
    project.contact.splice(index, 1);
    project = project;
  };*/

  let addHistory = () => {
    let history = {
      id: -1,
      statusId: selectedStatus.value,
      name: selectedStatus.label,
      from: selectedStatusCalendar1,
      to: selectedStatusCalendar2,
      active: statusCheck,
      other: statusText,
      status: "new",
    };
    project.statusHistory.push(history);
    project = project;
  };

  let removeHistory = (index) => {
    project.statusHistory.splice(index, 1);
    project = project;
  };

  function toggleStatus() {
    project.active = !project.active;
  }

  function toggleInternal() {
    project.isInternal = !project.isInternal;
  }

  let toggleStatusHistory = () => {
    statusCheck = !statusCheck;
  };

  async function toggleAction() {
    if (action === "show") {
      const now = moment().format("YYYY-MM-DD HH:mm:ss");
      post(
        `update projects set
          lastModificationDate='${now}',
          lastModificatedBy=${myId}
          where project_id=${project.id}`
      );

      savedProject = copyObject(project);
      modified = true;
    }
    if (action === "edit" || action === "new") {
      checkErrors();
      if (!error) {
        // Check whether it is a new city code
        if (project.card.city !== "" && (!setCityCode2 || !setCityCode3)) {
          await updateCityCode(project.card.city, cityCode2, cityCode3);

          await getCityCodes();
          setCityCodes();
        }

        onSave();

        modified = false;
      }
    }
    if (!error) {
      if (action !== "new") {
        action = action === "edit" ? "show" : "edit";
      }
    }
  }

  let changeOutlookCalendar = (sel) => {
    project.outlookCalendarId = sel.value;
    project.outlookCalendarName = sel.label;
  };

  let clickLoginOutlook = () => {
    outlookLogin(
      async () => {
        outlookToken = getOutlookToken();
        if (outlookToken !== "") {
          await getCalendars();
        }
      },
      () => {}
    );
  };

  let changeToolURL = (url, toolType) => {
    toolURL = url;
    selectedToolType = toolType;
    console.log("SELECTED TOOL TYPEEE",selectedToolType);
    console.log("toolURLLLL",toolURL);
  };

  let clickTool = (forceNewWindow) => {
    if (selectedToolType !== "folder") {
      if (platform === "Web") {
        openNewWindow(toolURL);
      } else {
        if (forceNewWindow) {
          openNewWindow(toolURL);
        } else {
          showTool = toolURL;
        }
      }
    }
  };

  let changeCardMode = (newMode) => {
    cardMode = newMode;
    showTool = "";
  };

  let previewFile = (id) => {
    remoteFilePrev = id.toLowerCase().trim().startsWith("http")
      ? id
      : btoa(unescape(encodeURIComponent(id)));
  };

  let setCityCodes = () => {
    const code = cityCodes.find((cityCode) => {
      return cityCode.name === project.card.city;
    });
    if (code) {
      cityCode2 = code.code2;
      cityCode3 = code.code3;
      setCityCode2 = cityCode2 !== "";
      setCityCode3 = cityCode3 !== "";
    } else {
      cityCode2 = "";
      cityCode3 = "";
      setCityCode2 = false;
      setCityCode3 = false;
    }
  };

  let onChangeClient = () => {
    const client = clients.find((item) => {
      return item.name === project.card.client;
    });
    clientCode = client ? client.code : "";
  };

  let generateProjectId = async () => {
    if (!hasId) {
      project.card.code = clientCode + cityCode2;

      if (project.card.code.length >= 4) {
        if (project.card.code) {
          const response = await post(
            "select FolderLabitCode from projects where FolderLabitCode like '" +
              project.card.code +
              "%' and project_id!=" +
              project.id
          );

          const num = response.length + 1;
          const numS = num < 10 ? "0" + num.toString() : num.toString();

          project.card.code = project.card.code + numS;
        }
      }

      project = project;
    }
  };

  let updatePhases = () => {
    project = updateCurrentPhases(project);
  };

  let removeStatus = (index) => {
    project.statusHistory.splice(index, 1);
    project = updateCurrentStatus(project);
    project.statusHistory = project.statusHistory;
  };

  let editStatus = () => {
    project.statusHistory.sort((a, b) => {
      if (a.start < b.start) {
        return -1;
      }
      if (a.start > b.start) {
        return 1;
      }
      return 0;
    });
    project = updateCurrentStatus(project);
    project.statusHistory = project.statusHistory;
  };

  let prepareImages = () => {
    imageList = copyObject(images);
    for (let i = images.length; i < 12; i++) {
      imageList.push(null);
    }
    imageList = imageList;
  };

  let previewNextImage = () => {
    imageSel++;
    if (imageSel >= images.length) {
      imageSel = 0;
    }
  };

  let previewPreviousImage = () => {
    imageSel--;
    if (imageSel < 0) {
      imageSel = images.length - 1;
    }
  };

  let previewImage = (index) => {
    imageSel = index;
  };

  let showFullscreen = (show) => {
    imageFullscreen = show;
  };

  let changePictureFolder = () => {
    removingFolder = false;
    removingFile = "";
    /*const pos = defaultFolderList.findIndex((item) => {
      return item.value === folderListSel.value;
    });
    removableFolder = pos === -1;*/
    removableFolder = folderListSel.value.id !== -1;
    listPictures();

    if (folderListSel.value.isSharepoint && platform === "Web") {
      window.open(folderListSel.value.url);
    }
  };

  let listPictureFolders = async () => {
    /*const url =
      PROJECTS_PUBLIC_IMAGE_URL +
      "/" +
      createProjectPicName(project) +
      "/pictures";

    let folders = await rpost("ListPublicFolder", {
      path: url,
    });*/

    const pos = folderList.findIndex((item) => {
      return item.value.name === folderListSel.value.name;
    });

    const response = await post(
      `select id, name, url, isSharepoint
        from projectImageFolder
        where project=${project.id}`
    );

    folderList = copyObject(defaultFolderList);
    folderList = response.reduce((acc, curr) => {
      //if (curr.folder === "1") {
      /*const pos = acc.findIndex((item) => {
        return item.value === "/" + curr.name;
      });
      if (pos === -1) {*/
      acc.push({
        value: {
          id: curr.id,
          name: curr.name,
          url: curr.url,
          isSharepoint: curr.isSharepoint === "1",
        },
        label:
          curr.isSharepoint === "0" ? curr.name : "[Sharepoint] " + curr.name,
      });
      //}
      //}
      return acc;
    }, folderList);

    folderListSel = folderList[pos];

    //console.log(folderListSel);
  };

  let listPictures = async () => {
    /*const url =
      PROJECTS_PUBLIC_IMAGE_URL +
      "/" +
      createProjectPicName(project) +
      "/pictures" +
      folderListSel.value;

    let files = await rpost("ListPublicFolder", {
      path: url,
    });

    images = files.map((f) => {
      return (
        LABIT_PUBLIC_FOLDER +
        PROJECTS_PUBLIC_IMAGE_URL +
        "/" +
        createProjectPicName(project) +
        "/pictures" +
        folderListSel.value +
        "/" +
        f.name
      );
    });*/

    const response = await post(
      `select id, url
        from projectImage
        where project=${project.id} and folder='${folderListSel.value.name}'`
    );

    images = response.map((item) => {
      return {
        id: item.id,
        url: item.url,
      };
    });

    imageSel = images.length > 0 ? 0 : -1;

    //console.log(files);
    //console.log(images);
  };

  let uploadImages = (ev) => {
    const fileList = event.target.files;
    let toUpload = fileList.length;
    uploadingImage = true;
    for (let i = 0; i < fileList.length; i++) {
      const f = fileList[i];
      const reader = new FileReader();
      reader.addEventListener("load", async (event) => {
        const content = event.target.result;
        const url =
          PROJECTS_PUBLIC_IMAGE_URL +
          "/" +
          createProjectPicName(project) +
          "/pictures/" +
          folderListSel.value.name;
        const tokens = f.name.split(".");
        if (tokens.length >= 2) {
          let fileName = tokens[0] + "-" + randomString(32);
          fileName = fileName + "." + tokens[tokens.length - 1];
          await rpost("UploadPublicFile", {
            path: url,
            fileName,
            fileData: content,
          });

          await post(
            `insert into projectImage (folder, url, project, folderId)
              values ('${folderListSel.value.name}', '${
              LABIT_PUBLIC_FOLDER + url + "/" + fileName
            }', ${project.id}, ${
              folderListSel.value.id === -1 ? "null" : folderListSel.value.id
            })`
          );
        }
        toUpload--;
        if (toUpload <= 0) {
          uploadingImage = false;
          listPictures();
        }
      });
      reader.readAsDataURL(f);
    }
  };

  let uploadImageURL = async () => {
    if (imageUrl.trim() !== "") {
      await post(
        `insert into projectImage (folder, url, project, folderId)
          values ('${folderListSel.value.name}', '${imageUrl}', ${
          project.id
        }, ${folderListSel.value.id === -1 ? "null" : folderListSel.value.id})`
      );
      imageUrl = "";
      listPictures();
    }
  };

  let addPictureFolder = async () => {
    let ok = false;
    if (newFolderType === "aws") {
      ok = newFolderName.trim() !== "";
    } else {
      ok = newFolderName.trim() !== "" && newFolderUrl !== "";
    }
    if (ok) {
      const pos = folderList.findIndex((item) => {
        return (
          item.value.name.toUpperCase().trim() ===
          newFolderName.toUpperCase().trim()
        );
      });

      if (pos === -1) {
        let folder = {
          label:
            newFolderType === "aws"
              ? newFolderName
              : "[Sharepoint] " + newFolderName,
          value: {
            id: accId,
            name: newFolderName,
            url:
              newFolderType === "aws"
                ? PROJECTS_PUBLIC_IMAGE_URL +
                  "/" +
                  createProjectPicName(project) +
                  "/pictures/" +
                  newFolderName
                : newFolderUrl,
            isSharepoint: newFolderType === "sharepoint",
          },
        };
        accId--;

        const now = moment().format("YYYY-MM-DD HH:mm:ss");

        const response = await post(
          `insert into projectImageFolder (name, url, isSharepoint, creationDate, project)
              values ('${newFolderName}', '${folder.value.url}', ${
            folder.value.isSharepoint ? 1 : 0
          }, '${now}', ${project.id})`
        );

        folder.value.id = response[0];
        folderList.push(folder);
        folderList = folderList;
        folderListSel = folder;

        newFolderName = "";
        newFolderUrl = "";
        changePictureFolder();
      }
    }
    /*if (newFolderName !== "") {
      const pos = folderList.findIndex((item) => {
        return item.value === "/" + newFolderName;
      });
      if (pos === -1) {
        const folder = {
          value: "/" + newFolderName,
          label: "/" + newFolderName,
        };
        folderList.push(folder);
        folderList = folderList;
        folderListSel = folder;

        const url =
          PROJECTS_PUBLIC_IMAGE_URL +
          "/" +
          createProjectPicName(project) +
          "/pictures";
        await rpost("CreatePublicFolder", {
          path: url,
          folder: newFolderName,
        });

        newFolderName = "";
        changePictureFolder();
      }
    }*/
  };

  let removePictureFolder = async () => {
    if (removingFolder) {
      if (!folderListSel.value.isSharepoint) {
        const url =
          PROJECTS_PUBLIC_IMAGE_URL +
          "/" +
          createProjectPicName(project) +
          "/pictures/" +
          folderListSel.value.name;
        await rpost("RemovePublicFolder", {
          path: url,
        });
      }

      await post(
        `delete from projectImageFolder
          where id=${folderListSel.value.id}`
      );

      folderList = folderList.filter((item) => {
        return item.value.id !== folderListSel.value.id;
      });
      folderListSel = folderList[0];
      changePictureFolder();
    } else {
      removingFolder = true;
    }
  };

  let removePicture = async (picture) => {
    if (removingFile === picture.url) {
      if (removingFile.startsWith(DOMAIN)) {
        const url = removingFile.replace(LABIT_PUBLIC_FOLDER, "");
        await rpost("RemovePublicFile", {
          path: url,
        });
      }

      await post(
        `delete from projectImage
          where id=${picture.id}`
      );

      images = images.filter((item) => {
        return item.id !== picture.id;
      });
      prepareImages();
      removingFile = "";
    } else {
      removingFile = picture.url;
    }
  };

  let selectApplication = (index) => {
    selectedApplication = index;
  };

  let assignKey = async (applicationIndex, keyIndex) => {
    const today = moment().format("YYYY-MM-DD");
    let key = project.licenses[applicationIndex].keys[keyIndex];
    await post(
      `insert usedKeys (keyId, people, date, project)
        values(${key.id}, ${myId}, '${today}', ${project.id})`
    );

    const response = await post(
      `select Email, picPath
        from people
        where contact_id=${myId}`
    );

    key.usedBy = {
      contactId: myId.toString(),
      email: response[0].Email,
      pic: response[0].picPath,
    };

    project = project;
  };

  let unassingKey = async (applicationIndex, keyIndex) => {
    let key = project.licenses[applicationIndex].keys[keyIndex];
    await post(
      `delete from usedKeys
        where keyId=${key.id}`
    );
    key.usedBy = null;
    project = project;
    //await getApplications();
  };

  let listFolders = async () => {
    folders = copyObject(defaultFolders);
    let remoteFolders = await rpost("ListFolder", {
      path: PROJECTS_URL + "/" + createProjectPicName(project),
    });
    remoteFolders.forEach((f) => {
      if (f.folder === "1") {
        const pos = folders.findIndex((item) => {
          return f.name === item;
        });
        if (pos === -1) {
          folders.push(f.name);
        }
      }
    });
    folders = folders;
    //console.log(remoteFolders);
  };

  let startCreatingFolder = () => {
    creatingFolder = true;
  };

  let cancelCreatingFolder = () => {
    creatingFolder = false;
    creatingFolderName = "";
  };

  let createFolder = async () => {
    if (creatingFolderName !== "") {
      const pos = folders.findIndex((f) => {
        return f === creatingFolderName;
      });
      if (pos === -1) {
        await rpost("CreateFolder", {
          path: PROJECTS_URL + "/" + createProjectPicName(project),
          folder: creatingFolderName,
        });

        folders.push(creatingFolderName);
        folders = folders;
        creatingFolder = false;
        creatingFolderName = "";
      }
    }
  };

  let onRemoveFolder = async (index) => {
    folders.splice(index, 1);
    folders = folders;
  };

  let removeStakeholder = (index) => {
    const id = project.companies[index].companyId;
    project.companies.splice(index, 1);
    project.companies = project.companies;

    project.people = project.people.filter((p) => {
      return !p.stakeholder || (p.stakeholder && id !== p.companyId);
    });
  };

  let addStakeholder = () => {
    if (stakeholdersSelRole.trim() !== "") {
      project.companies.push({
        id: accId,
        companyId: stakeholdersSel.value,
        companyName: stakeholdersSel.label,
        projectId: project.id,
        role: stakeholdersSelRole,
      });
      accId--;
      stakeholdersSel = stakeholders[0];
      stakeholdersSelRole = "";
      project.companies = project.companies;
    }
  };

  let openUploadImageUrl = () => {
    uploadingImageUrl = true;
  };

  let closeUploadImageUrl = () => {
    uploadingImageUrl = false;
    imageUrl = "";
  };

  let changeNewFolderType = (type) => {
    newFolderType = type;
    newFolderName = "";
    newFolderUrl = "";
  };

  let closeTool = () => {
    showTool = "";
  };

  let getUserCard = async (id, isLabit) => {
    const response = await post(
      `select contact_id, PictureUrl, p.Name as peopleName, c.Name as companyName, Charge, ProfessionRole, p.PhoneNumber as phone, p.Email as peopleEmail, p.Active as peopleActive, c.Active as companyActive, lastActivity, ps.description as profileStatusDescription, ps.iconImg as profileStatusIcon, atHome
        from people as p
        left join companies as c on c.company_id=p.company_id
        left join profileStatus as ps on ps.id=p.profileStatus
        where contact_id=${id}`
    );

    userCard =
      response.length > 0
        ? {
            id: response[0].contact_id,
            pic: response[0].PictureUrl,
            name: response[0].peopleName,
            company: response[0].companyName,
            charge: response[0].Charge,
            professionRole: response[0].ProfessionRole,
            phone: response[0].phone,
            email: response[0].peopleEmail,
            active:
              response[0].peopleActive === "1" &&
              response[0].companyActive === "1",
            workPeriods: [],
            lastActivity: response[0].lastActivity,
            officeStatus: {
              description: response[0].profileStatusDescription,
              icon: response[0].profileStatusIcon,
            },
            atHome: response[0].atHome,
          }
        : null;

    if (isLabit) {
      project.people.forEach((item) => {
        if (item.peopleId === id) {
          userCard.workPeriods = userCard.workPeriods.concat(item.workPeriods);
        }
      });
      userCard.workPeriods.sort((a, b) => {
        let keyA = a.start,
          keyB = b.start;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      userCard = userCard;
      /*const p = project.people.find((item) => {
        return item.peopleId === id;
      });
      if (p) {
        userCard.workPeriods = p.workPeriods;
        userCard.workPeriods.sort((a, b) => {
          let keyA = a.start,
            keyB = b.start;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        userCard = userCard;
      }*/
      //console.log(project);
    }
  };

  let addFileUrl = () => {
    if (externaUrlOptionUrl.trim() !== "") {
      if (
        externalUrlOptionsSel.value !== "Server" &&
        !externaUrlOptionUrl.startsWith("http")
      ) {
        externaUrlOptionUrl = "https://" + externaUrlOptionUrl;
      }
      project.externalUrls.push({
        id: accId,
        url: externaUrlOptionUrl,
        type: externalUrlOptionsSel.value,
      });
      accId--;
      project = project;
      externaUrlOptionUrl = "";
    }
  };

  let deleteFileUrl = (id) => {
    project.externalUrls = project.externalUrls.filter((item) => {
      return item.id !== id;
    });
    project = project;
  };

  let downloadFolder = async () => {
    downloadingFolder = true;
    //console.log(folderListSel);
    const files = images.reduce((acc, curr) => {
      if (curr.url.startsWith(LABIT_PUBLIC_FOLDER)) {
        const tokens = curr.url.replace(LABIT_PUBLIC_FOLDER, "").split("/");
        const basePath =
          tokens[0] + "/" + tokens[1] + "/" + tokens[2] + "/" + tokens[3] + "/";
        acc.push({
          name: curr.url.replace(LABIT_PUBLIC_FOLDER, ""),
          basePath,
        });
      }
      return acc;
    }, []);
    let data = await rpost("DownloadPublicFiles", {
      files,
      folderName: folderListSel.value.name,
      token: getToken(),
    });
    const path = data[0];
    downloadingFolder = false;
    location.href =
      LABIT_REST_SERVER +
      "DownloadTempFile.php?url=" +
      btoa(unescape(encodeURIComponent(path))) +
      "&token=" +
      getToken();
  };

  let openNewClient = (show) => {
    showNewClient = show;
    if (showNewClient) {
      newClient = "";
    }
  };

  let saveNewClient = async () => {
    if (newClient !== "") {
      const pos = clientList.findIndex((item) => {
        return item.trim().toUpperCase() === newClient.trim().toUpperCase();
      });
      if (pos === -1) {
        const response = await post(
          `select category_id 
            from categories
            where Name='Clients'`
        );
        const clientCatId = response[0].category_id;
        await insertCompanyBasic(newClient, clientCatId);

        clientList.push(newClient);
        clientList = clientList;

        refreshCompanyList = true;
      }
      project.card.client = newClient;
      openNewClient(false);
    }
  };

  let xDialog = (option) => {
    switch (option) {
      case "request":
        if (action === "show") {
          onClose(false);
        } else {
          if (modified) {
            if (!savedProject.picImage) {
              savedProject.picImage = project.picImage;
            }
            if (!savedProject.planPicImage) {
              savedProject.planPicImage = project.planPicImage;
            }
            savedProject.outlookCalendarId = project.outlookCalendarId;
            savedProject.outlookCalendarName = project.outlookCalendarName;
            const savedProjectS = JSON.stringify(savedProject);
            const projectS = JSON.stringify(project);
            /*console.log(savedProjectS);
            console.log(projectS);
            console.log(savedProjectS !== projectS);*/
            if (savedProjectS !== projectS) {
              askingForClosing = true;
            } else {
              xDialog("close");
            }
          } else {
            xDialog("close");
          }
        }
        break;
      case "save":
        toggleAction();
        askingForClosing = false;
        break;
      case "cancel":
        askingForClosing = false;
        break;
      case "close":
        project = JSON.parse(JSON.stringify(savedProject));
        askingForClosing = false;
        action = "show";
        onClose(true, index);
        break;
    }
  };

  let generateExternalUrls = () => {
    externalUrls = project.externalUrls.reduce((acc, curr) => {
      let eu = {
        id: curr.id,
        url: curr.url,
        type: curr.type,
        icon: "",
      };
      const platformLink = platformLinks.find((item) => {
        return item.name === eu.type;
      });
      if (platformLink) {
        eu.icon = platformLink.icon;
        acc.push(eu);
      }
      return acc;
    }, []);
    externalUrls.sort((a, b) => {
      const A = a.type;
      const B = b.type;
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });
    externalUrls = externalUrls;
    console.log("EXTERNAL URLS", externalUrls)
  };

  $: if (loaded) {
    changeOutlookCalendar(outlookCalendarOptionsSelected);
  }

  $: if (loaded) {
    outlookCalendarOptionsSelectedText =
      project.outlookCalendarId === "create" ||
      project.outlookCalendarId === "nocreate"
        ? "None"
        : project.outlookCalendarName;
  }

  $: {
    const coordinates = getCoordinatesFromMapsURL(mapsURL);
    if (coordinates.latitude && coordinates.longitude) {
      project.latitude = coordinates.latitude;
      project.longitude = coordinates.longitude;
      project = project;
    }
  }

  $: if (countriesSel && loaded) {
    project.card.country = countriesSel.label;
  }

  $: if (projectTypesSel && loaded && action !== "show") {
    project.programType = projectTypesSel.value;
  }

  $: if (project && project.card.code === "") {
    hasId = false;
  }

  $: if (images) {
    prepareImages();
  } else {
    imageList = [];
  }

  $: if (project.people) {
    preparePeople();
    checkProjectPermissions();
  }

  $: if (project) {
    console.log(project);
    phaseReview = [];
    milestoneReview = [];

    phaseReview = copyObject(project.phases);
    phaseReview.forEach((p) => {
      p.startDate = p.start;
      p.endDate = p.end;
      p.subphases.forEach((sp) => {
        sp.startDate = sp.start;
        sp.endDate = sp.end;
      });
    });

    milestoneReview = copyObject(project.milestones);
    /*milestoneReview.forEach((m) => {
      m.date = moment(m.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    });*/

    console.log(phaseReview);
    console.log(milestoneReview);
  }

  $: if (project.externalUrls && platformLinks.length > 0) {
    generateExternalUrls();
    console.log(externalUrls);
  }

  //$: console.log(folderListSel);

  $: console.log("PROJECT: ", project);

  //$: console.log("LOADING", loadingFileIcon);
</script>

{#if project}
  <div class="dialogContent {imageFullscreen ? 'preview' : ''}">
    {#if !imageFullscreen}
      <div class="card {action === 'edit' || action === 'new' ? 'edit' : ''}">
        <!-- Header -->
        <div class="header">
          <div class="projectCard">
            <div class="projectCardInfo">
              {#if action === "show"}
                {#if project.card.mainPic === ""}
                  <div class="nopic">
                    <img src="/images/no_pic.svg" alt="No Pic" />
                  </div>
                {:else}
                  <div
                    class="pic"
                    style="background-image: url('{project.card.mainPic}')"
                  />
                {/if}
              {:else}
                <div class="nopic">
                  <FileUploader
                    bind:fileInfo={project.picImage}
                    loadFromPath={project.picPath}
                    picExtraStyle="border-radius: 10px"
                    showFullImage={true}
                    bind:loadingFile={loadingFileIcon}
                  />
                </div>
              {/if}
              <div class="projectMainInfo">
                <span>{project.card.name}</span>
                <span>{project.card.code}</span>
                <span>
                  {project.statusHistory.length === 0
                    ? ""
                    : project.currentStatus}
                </span>
                <span>{project.currentPhase}</span>
                <span>{project.currentSubphase}</span>
                <div class="projectActive">
                  <Switch
                    width={40}
                    height={22}
                    bind:checked={project.active}
                    onClick={() => toggleStatus()}
                    readonly={true}
                    isStatic={true}
                  />
                  <span>{project.active ? "Active" : "Inactive"}</span>
                </div>
              </div>
            </div>
            {#if askingForClosing}
              <div class="cardButtons">
                <div class="xDialog">
                  <span class="xDialog">Save changes before closing?</span>
                  <div class="xDialogRow">
                    <div class="editButton cardIcon">
                      <BorderButton
                        text="Save"
                        onClick={() => xDialog("save")}
                        borderColor="var(--labit-light-red)"
                        fontColor="white"
                        backgroundColor="var(--labit-light-red)"
                      />
                    </div>
                    <div class="editButton cardIcon">
                      <BorderButton
                        text="No"
                        onClick={() => xDialog("close")}
                        borderColor="var(--labit-light-red)"
                        fontColor="var(--labit-light-red)"
                      />
                    </div>
                    <div class="editButton cardIcon">
                      <BorderButton
                        text="Cancel"
                        onClick={() => xDialog("cancel")}
                        borderColor="var(--labit-light-red)"
                        fontColor="var(--labit-light-red)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            {:else}
              <div class="projectCardInfo">
                <div class="projectCardButtons">
                  <div class="projectCardButtonsRow">
                    {#if action === "show"}
                      <RoundedButton
                        icon="/images/arrow-left.svg"
                        onClick={() => {
                          userCard = null;
                          onPreviousProject();
                          changeCardMode("info");
                          /*preparePeople();
                        checkProjectPermissions();*/
                        }}
                      />
                      <RoundedButton
                        icon="/images/arrow-right.svg"
                        onClick={() => {
                          userCard = null;
                          onNextProject();
                          changeCardMode("info");
                          /*preparePeople();
                        checkProjectPermissions();*/
                        }}
                      />
                      <div class="cardIcon cardIconIcon">
                        <img
                          class="icon"
                          src="/images/factory-blue.svg"
                          alt="Project Button"
                          on:click={() => {
                            selectedProjectPDashboard = project.id;
                            openURL("/dashboard-project", true);
                          }}
                          on:contextmenu={(ev) => {
                            ev.stopPropagation();
                            openNewWindow(
                              "/skylab-main/dashboard-project/" + project.id
                            );
                            return false;
                          }}
                        />
                      </div>
                      <div class="cardIcon cardIconIcon">
                        <img
                          class="icon"
                          src="/images/calendar.svg"
                          alt="Calendar Button"
                          on:click={() => {
                            loadCalendar(project.id);
                            openURL("/calendar", true);
                          }}
                          on:contextmenu={(ev) => {
                            ev.stopPropagation();
                            loadCalendar(project.id);
                            openNewWindow("/skylab-main/calendar");
                            return false;
                          }}
                        />
                      </div>
                      {#if editRole === "Admin" || editRole === "Analytics"}
                        <div class="editButton cardIcon">
                          <BorderButton
                            text="Edit"
                            onClick={() => toggleAction()}
                          />
                        </div>
                      {/if}
                    {/if}
                    {#if action === "edit" && editRole === "Admin"}
                      <div class="cardIcon cardIconIcon">
                        <img
                          class="icon"
                          src="/images/trash.svg"
                          alt="Remove Button"
                          on:click={onRemove}
                        />
                      </div>
                    {/if}
                    {#if (action === "edit" || action === "new") && !loadingFileIcon && !loadingFilePlan}
                      <div class="cardIcon cardIconIcon">
                        <img
                          src="/images/save.svg"
                          class="icon"
                          alt="Save Button"
                          on:click={() => toggleAction()}
                        />
                      </div>
                    {/if}
                    <div class="cardIcon">
                      <CloseButton
                        size="38px"
                        onClick={() => {
                          /*if (action === "edit") {
                            action = "show";
                            onClose(true, index);
                          } else {
                            onClose(false);
                          }*/
                          xDialog("request");
                        }}
                        closing={action !== "show"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            {/if}
          </div>
        </div>
        {#if lastModifiedUser && editRole !== ""}
          <div class="modDiv">
            <div>
              <Picture
                image={lastModifiedUser.pic}
                name={lastModifiedUser.name}
                grayscale={true}
              />
            </div>
            <span>
              Warning!
              {lastModifiedUser.name}
              opened this project for modification
              {lastModifiedUser.time}
              ago.
            </span>
          </div>
        {/if}
        <div
          class="options"
          on:contextmenu={(ev) => {
            ev.stopPropagation();
          }}
        >
          <DialogTab
            text="Info"
            active={cardMode === "info"}
            onClick={() => changeCardMode("info")}
          />
          <DialogTab
            text="Images"
            active={cardMode === "images"}
            onClick={() => changeCardMode("images")}
          />
          <DialogTab
            text="Documents"
            active={cardMode === "files"}
            onClick={() => changeCardMode("files")}
          />
          <DialogTab
            text="Team"
            active={cardMode === "team"}
            onClick={() => changeCardMode("team")}
          />
          <!--<DialogTab
            text="Software"
            active={cardMode === "software"}
            onClick={() => changeCardMode("software")}
          />-->
          <DialogTab
            text="Planning"
            active={cardMode === "planning"}
            onClick={() => changeCardMode("planning")}
          />
        </div>
        <div class="body">
          {#if cardMode === "info"}
            <!-- Info 1 -->

            <div class="tool {showTool !== '' ? '' : 'hiddenElement'}">
              <div class="toolOptions">
                <img
                  class="fadedButton"
                  src="/images/x.svg"
                  alt="Close Tool"
                  on:click={() => closeTool()}
                />
              </div>
              <div class="toolWebview">
                <WebBrowser
                  id=dialogProjectWebBrowser1
                  type="webview"
                  iframeTitle="External Tool"
                  src={showTool}
                />
              </div>
            </div>
            <div class="pageContainer {showTool === '' ? '' : 'hiddenElement'}">
              <div class="columnInfo1 columnData">
                <div class="googlemap">
                  <GoogleMaps
                    id={project.id}
                    mapCenter={{
                      latitude: project.latitude,
                      longitude: project.longitude,
                    }}
                    locations={[
                      {
                        latitude: project.latitude,
                        longitude: project.longitude,
                        name: project.card.name,
                      },
                    ]}
                  />
                </div>
                {#if action === "edit"}
                  <div class="box">
                    <div class="tableInfo">
                      <div class="tableInfoColumnHalf">
                        <span class="columnTitle">Latitude</span>
                        <DialogSpanEdit
                          bind:text={project.latitude}
                          {action}
                          last={false}
                        />
                      </div>
                      <div class="tableInfoColumnHalf">
                        <span class="columnTitle">Longitude</span>
                        <DialogSpanEdit
                          bind:text={project.longitude}
                          {action}
                          last={action === "show"}
                        />
                      </div>
                    </div>
                    {#if action !== "show"}
                      <span class="columnTitle">Parse from Google Maps URL</span
                      >
                      <DialogSpanEdit
                        bind:text={mapsURL}
                        action="edit"
                        last={true}
                      />
                    {/if}
                  </div>
                {/if}
                <div class="box">
                  <span class="columnTitle">Name</span>
                  <DialogSpanEdit
                    bind:text={project.card.name}
                    {action}
                    last={false}
                    error={errorName}
                  />
                  <!--<div class="tableInfo">
                    <div class="tableInfoColumnHalf">
                      <div class="tableInfoCell">
                        <span
                          class="columnTitle {action !== 'show'
                            ? 'requiredElement'
                            : ''}">Name</span
                        >
                      </div>
                      <div class="tableInfoCell">
                        <DialogSpanEdit
                          bind:text={project.card.name}
                          {action}
                          last={false}
                          error={errorName}
                        />
                      </div>
                    </div>
                    <div class="tableInfoColumnHalf">
                      <div class="tableInfoCell">
                        <span class="columnTitle">Nickname</span>
                      </div>
                      <div class="tableInfoCell">
                        <DialogSpanEdit
                          bind:text={project.nickName}
                          {action}
                          last={false}
                        />
                      </div>
                    </div>
                  </div>-->
                  <span class="columnTitle">Typology</span>
                  {#if project.programType !== "N/A" || action === "edit"}
                    <div class="tableInfo">
                      <div class="tableInfoCell">
                        <DialogSpanCombobox
                          bind:selectedValue={projectTypesSel}
                          options={projectTypes}
                          action={action === "edit" && editRole === "Admin"
                            ? "edit"
                            : "show"}
                          last={false}
                        />
                      </div>
                    </div>
                  {:else}
                    <div class="tableInfo">
                      <div class="tableInfoCell">
                        <DialogSpanEdit text="N/A" action="show" last={false} />
                      </div>
                    </div>
                  {/if}
                  <div class="internalSwitch">
                    <Switch
                      width={20}
                      height={11}
                      bind:checked={project.isInternal}
                      onClick={() => toggleInternal()}
                      readonly={action !== "edit" || editRole !== "Admin"}
                    />
                    <span>Internal Project</span>
                  </div>
                  {#if !project.isInternal}
                    {#if !showNewClient}
                      <div class="tableInfo">
                        <div class="tableInfoColumnHalf">
                          <div class="titleAdd">
                            <span class="columnTitle">Client</span>
                            {#if action !== "show" && editRole === "Admin"}
                              <img
                                class="fadedButton"
                                src="/images/refresh.svg"
                                alt="Refresh Clients"
                                on:click={() => getClientsDialog()}
                              />
                              <img
                                class="fadedButton"
                                src="/images/plus-no-circle.svg"
                                alt="Add Company"
                                on:click={() => openNewClient(true)}
                              />
                            {/if}
                          </div>
                          <DialogSpanSuggestionEdit
                            bind:text={project.card.client}
                            suggestions={clientList}
                            onChange={() => onChangeClient()}
                            onFocusOut={() => {
                              generateProjectId();
                            }}
                            action={action === "edit" && editRole === "Admin"
                              ? "edit"
                              : "show"}
                            last={false}
                          />
                        </div>
                        {#if action !== "show"}
                          <div class="tableInfoColumnHalf">
                            <div class="tableInfoCell">
                              <span class="columnTitle">Client Code</span>
                            </div>
                            <div class="tableInfoCell">
                              <DialogSpanEdit
                                bind:text={clientCode}
                                maxLength={5}
                                action={action === "edit" &&
                                editRole === "Admin"
                                  ? "edit"
                                  : "show"}
                                last={false}
                                onFocusOut={() => generateProjectId()}
                              />
                            </div>
                          </div>
                        {/if}
                      </div>
                    {:else}
                      <span class="columnTitle">New Client</span>
                      <DialogSpanEdit
                        bind:text={newClient}
                        action="edit"
                        last={false}
                      />
                      <div class="titleButtons">
                        <DialogSmallButton
                          text="Add"
                          onClick={() => saveNewClient()}
                        />
                        <DialogSmallButton
                          text="Close"
                          onClick={() => openNewClient(false)}
                        />
                      </div>
                    {/if}
                  {/if}
                  <span class="columnTitle">Address</span>
                  <DialogSpanEdit
                    bind:text={project.card.address}
                    action={action === "edit" && editRole === "Admin"
                      ? "edit"
                      : "show"}
                    last={false}
                  />
                  <div class="tableInfo">
                    <div class="tableInfoColumnHalf">
                      <div class="tableInfoCell">
                        <span class="columnTitle">City</span>
                      </div>
                      <div class="tableInfoCell">
                        <DialogSpanSuggestionEdit
                          bind:text={project.card.city}
                          suggestions={cities}
                          onChange={() => {
                            setCityCodes();
                          }}
                          onFocusOut={() => {
                            generateProjectId();
                          }}
                          action={action === "edit" && editRole === "Admin"
                            ? "edit"
                            : "show"}
                          last={false}
                        />
                      </div>
                    </div>
                    {#if action !== "show"}
                      <div class="tableInfoColumnHalf">
                        <div class="tableInfoCell">
                          <span class="columnTitle">2-Digit Code</span>
                        </div>
                        <div class="tableInfoCell">
                          <DialogSpanEdit
                            bind:text={cityCode2}
                            action={action === "edit" && editRole === "Admin"
                              ? "edit"
                              : "show"}
                            maxLength={2}
                            readOnly={setCityCode2}
                            last={false}
                            onFocusOut={() => generateProjectId()}
                          />
                        </div>
                      </div>
                    {/if}
                  </div>
                  <span class="columnTitle">Country</span>
                  <div class="tableInfo">
                    <div class="tableInfoCell">
                      <DialogSpanCombobox
                        bind:selectedValue={countriesSel}
                        options={countries}
                        searchable={true}
                        action={action === "edit" && editRole === "Admin"
                          ? "edit"
                          : "show"}
                        last={false}
                      />
                    </div>
                  </div>
                  <div class="tableInfo">
                    <div class="tableInfoColumnHalf">
                      <div class="tableInfoCell">
                        <span class="columnTitle">Time Labit Code</span>
                      </div>
                      <div class="tableInfoCell">
                        <DialogSpanEdit
                          bind:text={project.timeLabitCode}
                          action={action === "edit" && editRole === "Admin"
                            ? "edit"
                            : "show"}
                          last={true}
                        />
                      </div>
                    </div>
                    <div class="tableInfoColumnHalf">
                      <div class="tableInfoCell">
                        <span class="columnTitle">Folder Labit Code</span>
                      </div>
                      <div class="tableInfoCell">
                        <DialogSpanEdit
                          bind:text={project.card.code}
                          action={action === "edit" && editRole === "Admin"
                            ? "edit"
                            : "show"}
                          last={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--<div class="box">
                  <span class="columnTitle">Company</span>
                  <DialogSpanEdit
                    bind:text={project.company}
                    {action}
                    last={true}
                  />
                </div>-->
                <div class="box description">
                  <span class="columnTitle">Description</span>
                  <DialogExpandSpan
                    bind:text={project.info}
                    {action}
                    last={true}
                  />
                </div>
              </div>

              <!-- Info 2 -->

              <div class="columnInfo2 columnData">
                {#if action === "edit" && editRole === "Admin"}
                  <div class="box customScrollbars">
                    <span class="columnTitle">Project Links</span>
                    <div class="comboboxProjectLinks">
                      <Combobox
                        options={externalUrlOptions}
                        placeholder=""
                        bind:selectedValue={externalUrlOptionsSel}
                      />
                      <img
                        class="fadedButton"
                        src="/images/plus.svg"
                        alt="Add External URL"
                        on:click={() => addFileUrl()}
                      />
                    </div>
                    <div class="comboboxProjectLinks">
                      <DialogSpanEdit
                        bind:text={externaUrlOptionUrl}
                        action="edit"
                        last={true}
                      />
                    </div>
                  </div>
                  <!--<div class="box doc">
                    <span class="columnTitle">Server Link</span>
                    <DialogAEdit
                      bind:text={project.serverLink[0]}
                      {action}
                      last={false}
                      width="90%"
                      embed={false}
                      slackLink={false}
                    />
                    <img
                      class="urlImg"
                      src="/images/folder.png"
                      alt="Folder Icon"
                    />
                  </div>
                  <div class="box doc">
                    <span class="columnTitle">Trello</span>
                    <DialogAEdit
                      bind:text={project.trello[0]}
                      {action}
                      last={false}
                      width="90%"
                      embed={false}
                      slackLink={false}
                    />
                    <img
                      class="urlImg"
                      src="/images/trello.png"
                      alt="Trello Icon"
                    />
                  </div>
                  <div class="box doc">
                    <span class="columnTitle">Slack</span>
                    <DialogAEdit
                      bind:text={project.slack[0]}
                      {action}
                      last={false}
                      width="90%"
                      embed={false}
                      slackLink={true}
                    />
                    <img
                      class="urlImg"
                      src="/images/slack.png"
                      alt="Slack Icon"
                    />
                  </div>
                  <div class="box doc">
                    <span class="columnTitle">Sharepoint</span>
                    <DialogAEdit
                      bind:text={project.sharepoint[0]}
                      {action}
                      last={false}
                      width="90%"
                      embed={true}
                      slackLink={false}
                    />
                    <img
                      class="urlImg"
                      src="/images/sharepoint.png"
                      alt="Sharepoint Icon"
                    />
                  </div>
                  <div class="box doc">
                    <span class="columnTitle">BIM360</span>
                    <DialogAEdit
                      bind:text={project.bim360[0]}
                      {action}
                      last={true}
                      width="90%"
                      embed={true}
                      slackLink={false}
                    />
                    <img
                      class="urlImg"
                      src="/images/bim360.png"
                      alt="BIM360 Icon"
                    />
                  </div>-->
                {/if}
                <div class="box boxProjectLinks">
                  <div class="iconList">
                    {#each externalUrls as externalUrl}
                      <div class="external">
                        <img
                          class="url"
                          src={externalUrl.icon}
                          alt="Platform Icon"
                          on:mouseenter={() =>
                            changeToolURL(
                              externalUrl.url,
                              externalUrls.type === "Server"
                                ? "folder"
                                : externalUrls.type
                            )}
                          on:click={() => clickTool(false)}
                          on:contextmenu={(ev) => {
                            ev.preventDefault();
                            clickTool(true);
                            return false;
                          }}
                        />
                        {#if action !== "show"}
                          <img
                            class="remove fadedButton"
                            src="/images/trash.svg"
                            alt="Remove URL"
                            on:click={(ev) => {
                              ev.stopPropagation();
                              deleteFileUrl(externalUrl.id);
                            }}
                          />
                        {/if}
                      </div>
                    {/each}

                    <!--{#each project.externalUrls as externalUrl}
                      {#if externalUrl.type === "Server"}
                        <div class="external">
                          <img
                            class="url"
                            src="/images/folder.png"
                            alt="Folder Icon"
                            on:mouseenter={() =>
                              changeToolURL(externalUrl.url, "folder")}
                          />
                          {#if action !== "show"}
                            <img
                              class="remove fadedButton"
                              src="/images/trash.svg"
                              alt="Remove URL"
                              on:click={(ev) => {
                                ev.stopPropagation();
                                deleteFileUrl(externalUrl.id);
                              }}
                            />
                          {/if}
                        </div>
                      {/if}
                    {/each}
                    {#each project.externalUrls as externalUrl}
                      {#if externalUrl.type === "Trello"}
                        <div class="external">
                          <img
                            class="url"
                            src="/images/trello.png"
                            alt="Trello Icon"
                            on:mouseenter={() =>
                              changeToolURL(externalUrl.url, "trello")}
                            on:click={() => clickTool(false)}
                            on:contextmenu={(ev) => {
                              ev.preventDefault();
                              clickTool(true);
                              return false;
                            }}
                          />
                          {#if action !== "show"}
                            <img
                              class="remove fadedButton"
                              src="/images/trash.svg"
                              alt="Remove URL"
                              on:click={(ev) => {
                                ev.stopPropagation();
                                deleteFileUrl(externalUrl.id);
                              }}
                            />
                          {/if}
                        </div>
                      {/if}
                    {/each}
                    {#each project.externalUrls as externalUrl}
                      {#if externalUrl.type === "Slack"}
                        <div class="external">
                          <img
                            class="url"
                            src="/images/slack.png"
                            alt="Slack Icon"
                            on:mouseenter={() =>
                              changeToolURL(externalUrl.url, "slack")}
                            on:click={() => clickTool(false)}
                            on:contextmenu={(ev) => {
                              ev.preventDefault();
                              clickTool(true);
                              return false;
                            }}
                          />
                          {#if action !== "show"}
                            <img
                              class="remove fadedButton"
                              src="/images/trash.svg"
                              alt="Remove URL"
                              on:click={(ev) => {
                                ev.stopPropagation();
                                deleteFileUrl(externalUrl.id);
                              }}
                            />
                          {/if}
                        </div>
                      {/if}
                    {/each}
                    {#each project.externalUrls as externalUrl}
                      {#if externalUrl.type === "Sharepoint"}
                        <div class="external">
                          <img
                            class="url"
                            src="/images/sharepoint.png"
                            alt="Sharepoint Icon"
                            on:mouseenter={() =>
                              changeToolURL(externalUrl.url, "sharepoint")}
                            on:click={() => clickTool(false)}
                            on:contextmenu={(ev) => {
                              ev.preventDefault();
                              clickTool(true);
                              return false;
                            }}
                          />
                          {#if action !== "show"}
                            <img
                              class="remove fadedButton"
                              src="/images/trash.svg"
                              alt="Remove URL"
                              on:click={(ev) => {
                                ev.stopPropagation();
                                deleteFileUrl(externalUrl.id);
                              }}
                            />
                          {/if}
                        </div>
                      {/if}
                    {/each}
                    {#each project.externalUrls as externalUrl}
                      {#if externalUrl.type === "BIM360"}
                        <div class="external">
                          <img
                            class="url"
                            src="/images/bim360.png"
                            alt="BIM360 Icon"
                            on:mouseenter={() =>
                              changeToolURL(externalUrl.url, "bim360")}
                            on:click={() => clickTool(false)}
                            on:contextmenu={(ev) => {
                              ev.preventDefault();
                              clickTool(true);
                              return false;
                            }}
                          />
                          {#if action !== "show"}
                            <img
                              class="remove fadedButton"
                              src="/images/trash.svg"
                              alt="Remove URL"
                              on:click={(ev) => {
                                ev.stopPropagation();
                                deleteFileUrl(externalUrl.id);
                              }}
                            />
                          {/if}
                        </div>
                      {/if}
                    {/each}-->
                    <!--{#if project.serverLink[0]}
                        <img
                          src="/images/folder.png"
                          alt="Folder Icon"
                          on:mouseenter={() =>
                            changeToolURL(project.serverLink[0], "folder")}
                        />
                      {/if}
                      {#if project.trello[0]}
                        <img
                          src="/images/trello.png"
                          alt="Trello Icon"
                          on:mouseenter={() =>
                            changeToolURL(project.trello[0], "trello")}
                          on:click={() => clickTool(false)}
                          on:contextmenu={(ev) => {
                            ev.preventDefault();
                            clickTool(true);
                            return false;
                          }}
                        />
                      {/if}
                      {#if project.slack[0]}
                        <img
                          src="/images/slack.png"
                          alt="Slack Icon"
                          on:mouseenter={() =>
                            changeToolURL(project.slack[0], "slack")}
                          on:click={() => clickTool(false)}
                          on:contextmenu={(ev) => {
                            ev.preventDefault();
                            clickTool(true);
                            return false;
                          }}
                        />
                      {/if}
                      {#if project.sharepoint[0]}
                        <img
                          src="/images/sharepoint.png"
                          alt="Sharepoint Icon"
                          on:mouseenter={() =>
                            changeToolURL(project.sharepoint[0], "sharepoint")}
                          on:click={() => clickTool(false)}
                          on:contextmenu={(ev) => {
                            ev.preventDefault();
                            clickTool(true);
                            return false;
                          }}
                        />
                      {/if}
                      {#if project.bim360[0]}
                        <img
                          src="/images/bim360.png"
                          alt="BIM360 Icon"
                          on:mouseenter={() =>
                            changeToolURL(project.bim360[0], "bim360")}
                          on:click={() => clickTool(false)}
                          on:contextmenu={(ev) => {
                            ev.preventDefault();
                            clickTool(true);
                            return false;
                          }}
                        />
                      {/if}-->
                  </div>
                  {#if toolURL !== ""}
                    <span
                      class="toolURL {selectedToolType !== 'folder'
                        ? 'selectableTool'
                        : ''}"
                      on:click={() => clickTool(false)}
                      on:contextmenu={(ev) => {
                        ev.preventDefault();
                        clickTool(true);
                        return false;
                      }}
                    >
                      {toolURL}
                    </span>
                  {/if}
                </div>
                <div class="box relatedProjects customScrollbars">
                  <span class="columnTitle">
                    Related projects ({project.relatedProjects.length})
                  </span>
                  <DialogRelatedProjectsContainer
                    bind:relatedProjects={project.relatedProjects}
                    {action}
                  />
                </div>
                <!--<div class="box boxFixed phasesScopes customScrollbars">
                  <span class="columnTitle">Scopes</span>
                  {#each project.scopes as scope}
                    <DialogSpanEdit text={scope} action="show" last={false} />
                  {/each}
                  <span class="columnTitle">Phases</span>
                  {#each project.phases as phase, index}
                    <DialogSpanEdit
                      text={phase.name}
                      action="show"
                      last={index === project.phases.length - 1}
                    />
                  {/each}
                </div>-->
                <div class="box boxFixed stakeholders customScrollbars">
                  <span class="columnTitle">Stakeholder / Company</span>
                  {#each project.companies as company, index}
                    <div class="tableInfo">
                      <DialogProjectStakeholderList
                        {company}
                        {action}
                        onRemove={() => removeStakeholder(index)}
                      />
                    </div>
                  {/each}
                  {#if action !== "show"}
                    <div class="tableInfo stakeholdersOptions">
                      <div class="comboboxStakeholder">
                        <Combobox
                          options={stakeholders}
                          placeholder=""
                          bind:selectedValue={stakeholdersSel}
                        />
                      </div>
                      <div class="comboboxStakeholder">
                        <DialogSpanEdit
                          bind:text={stakeholdersSelRole}
                          action="edit"
                        />
                      </div>
                      <img
                        class="fadedButton stakeholderAdd"
                        src="/images/plus.svg"
                        alt="Add Stakeholder"
                        on:click={() => addStakeholder()}
                      />
                    </div>
                  {/if}
                </div>
              </div>

              <!-- Info 3 -->

              <div class="columnInfo3 columnData">
                <div class="box plan">
                  {#if action === "show"}
                    {#if !project.planPicUrl || project.planPicUrl === ""}
                      <div class="noplanpic"><span>PLAN</span></div>
                    {:else}
                      <div
                        class="planpic"
                        style="background-image: url('{project.planPicUrl}')"
                      />
                    {/if}
                  {:else}
                    <span class="columnTitle">Plan Image</span>
                    <div class="nopic">
                      <FileUploader
                        bind:fileInfo={project.planPicImage}
                        loadFromPath={project.planPicPath}
                        picExtraStyle="border-radius: 10px"
                        showFullImage={true}
                      />
                    </div>
                  {/if}
                </div>
                <div class="box">
                  <span class="columnTitle">Area</span>
                  <DialogSpanEdit
                    bind:text={project.aream2}
                    {action}
                    last={false}
                  />
                  <span class="columnTitle">No. Floors</span>
                  <DialogSpanEdit
                    bind:text={project.noFloors}
                    {action}
                    last={false}
                  />
                  <span class="columnTitle">All Building Demise</span>
                  <DialogSpanEdit
                    bind:text={project.allBuildingDemise}
                    {action}
                    last={false}
                  />
                  <span class="columnTitle">New Stair Case</span>
                  <DialogSpanEdit
                    bind:text={project.newStairCase}
                    {action}
                    last={false}
                  />
                  <span class="columnTitle">Occupancy</span>
                  <DialogSpanEdit
                    bind:text={project.occupancy}
                    {action}
                    last={false}
                  />
                  <span class="columnTitle">Raised Floor Cavity Height</span>
                  <DialogSpanEdit
                    bind:text={project.raisedFloorCavityHeight}
                    {action}
                    last={false}
                  />
                  <span class="columnTitle">Existing Minimum Height</span>
                  <DialogSpanEdit
                    bind:text={project.existingMinimumHeight}
                    {action}
                    last={false}
                  />
                  <span class="columnTitle">Singularities</span>
                  <DialogSpanEdit
                    bind:text={project.singularities}
                    {action}
                    last={false}
                  />
                  <span class="columnTitle">Sprinklers</span>
                  <DialogSpanEdit
                    bind:text={project.sprinklers}
                    {action}
                    last={false}
                  />
                  <span class="columnTitle">PEM</span>
                  <DialogSpanEdit
                    bind:text={project.PEN}
                    {action}
                    last={true}
                  />
                </div>
              </div>

              <!-- Info 4 -->

              <div class="columnInfo4 columnData">
                <div class="box boxFixed phaseHistory customScrollbars">
                  <span class="columnTitle">Phase History</span>
                  {#each project.phases as phase}
                    <DialogProjectPhaseHistory
                      {phase}
                      {action}
                      currentPhase={project.currentPhase}
                      currentSubphase={project.currentSubphase}
                      isSubphase={false}
                      last={false}
                      updateCurrentPhases={() => updatePhases()}
                    />
                  {/each}
                </div>
                <div class="box boxFixed statusHistory customScrollbars">
                  <span class="columnTitle">Status History</span>
                  {#each project.statusHistory as statusH, index}
                    <DialogProjectStatusHistory
                      status={statusH}
                      statusList={status}
                      action={action === "edit" && editRole === "Admin"
                        ? "edit"
                        : "show"}
                      onRemove={() => removeStatus(index)}
                      onEdit={() => editStatus()}
                    />
                  {/each}
                </div>
              </div>
            </div>
          {:else if cardMode === "files"}
            <div class="submain submainFiles submainF">
              {#if remoteFilePrev === ""}
                <div class="submainFiles">
                  <!-- <div class="booble">
                    <FileBoobleContainer
                      title="Images"
                      url={'/projects/' + project.id + ' - ' + project.card.name + '/images'}
                      onPreviewFile={id => previewFile(id, filesImages)}
                      initialBorder={true}
                      readOnly={action === 'show'}
                      bind:files={filesImages}
                      compressedMode={true} />
                  </div>
                  <div class="booble">
                    <FileBoobleContainer
                      title="Submissions"
                      url={'/projects/' + project.id + ' - ' + project.card.name + '/submissions'}
                      onPreviewFile={id => previewFile(id, filesSubmissions)}
                      initialBorder={true}
                      readOnly={action === 'show'}
                      bind:files={filesSubmissions}
                      compressedMode={true} />
                  </div>
                  <div class="booble">
                    <FileBoobleContainer
                      title="Reports"
                      url={'/projects/' + project.id + ' - ' + project.card.name + '/reports'}
                      onPreviewFile={id => previewFile(id, filesReports)}
                      initialBorder={true}
                      readOnly={action === 'show'}
                      bind:files={filesReports}
                      compressedMode={true} />
                  </div>
                  <div class="booble">
                    <FileBoobleContainer
                      title="Models"
                      url={'/projects/' + project.id + ' - ' + project.card.name + '/models'}
                      onPreviewFile={id => previewFile(id, filesModels)}
                      initialBorder={true}
                      readOnly={action === 'show'}
                      bind:files={filesModels}
                      compressedMode={true} />
                  </div>-->
                  {#each folders as folder, index}
                    <div class="booble">
                      <FileBoobleContainer
                        title={folder}
                        url={PROJECTS_URL +
                          "/" +
                          createProjectPicName(project) +
                          "/" +
                          folder}
                        onPreviewFile={(id) => previewFile(id)}
                        initialBorder={true}
                        readOnly={action === "show"}
                        compressedMode={true}
                        removable={action !== "show" &&
                          index >= defaultFolders.length}
                        onRemoveFullFolder={() => onRemoveFolder(index)}
                      />
                    </div>
                  {/each}
                </div>
                {#if action !== "show"}
                  <div class="addFolderDiv">
                    <div class="addFolder">
                      {#if creatingFolder}
                        <div class="addFolderEdit">
                          <DialogSpanEdit
                            bind:text={creatingFolderName}
                            action={false}
                            last={true}
                          />
                        </div>
                        <img
                          class="addFolderOption"
                          src="/images/save.svg"
                          alt="Add Folder"
                          on:click={() => createFolder()}
                        />
                        <img
                          class="addFolderOption"
                          src="/images/x-red.svg"
                          alt="Cancel"
                          on:click={() => cancelCreatingFolder()}
                        />
                      {:else}
                        <img
                          class="addBig"
                          src="/images/plus.svg"
                          alt="Add Folder"
                          on:click={() => startCreatingFolder()}
                        />
                      {/if}
                    </div>
                  </div>
                {/if}
              {:else if remoteFilePrev !== "" && remoteFilePrev
                  .toLowerCase()
                  .trim()
                  .startsWith("http")}
                <div class="iframe iframeFile">
                  <img
                    class="fadedButton"
                    src="/images/x.svg"
                    alt="Close"
                    on:click={() => {
                      remoteFilePrev = "";
                    }}
                  />
                  <WebBrowser
                    type={platform === "Web" ? "iframe" : "webview"}
                    iframeTitle="External URL"
                    id=dialogProjectWebBrowser2
                    src={remoteFilePrev}
                    
                  />
                </div>
              {:else}
                <PreviewRemoteFile bind:fileUrl={remoteFilePrev} />
              {/if}
            </div>
          {:else if cardMode === "images"}
            <div class="submainH">
              <div class="columnInfo1 columnData">
                <div class="boxImages">
                  <div class="imagesHeader">
                    <div class="imagesCombobox">
                      <Combobox
                        options={folderList}
                        placeholder=""
                        bind:selectedValue={folderListSel}
                        onChange={() => changePictureFolder()}
                      />
                    </div>
                    {#if removableFolder && action !== "show"}
                      <img
                        class="removePictureFolder fadedButton"
                        src={removingFolder
                          ? "/images/trash-red.svg"
                          : "/images/trash.svg"}
                        alt="Remove Folder"
                        on:click={() => removePictureFolder()}
                      />
                    {/if}
                  </div>
                  {#if action !== "show"}
                    <div class="pictureType">
                      <FolderTypeIcon
                        image="/images/folder.svg"
                        disabled={newFolderType !== "aws"}
                        onClick={() => changeNewFolderType("aws")}
                      />
                      <FolderTypeIcon
                        image="/images/sharepoint-blue20.png"
                        disabled={newFolderType !== "sharepoint"}
                        onClick={() => changeNewFolderType("sharepoint")}
                      />
                    </div>
                    <div
                      class="addPictureFolder {newFolderType !== 'aws'
                        ? 'hiddenElement'
                        : ''}"
                    >
                      <div class="addPictureFolderTitle">
                        <span>Folder Name</span>
                      </div>
                      <div class="addPictureFolderFill">
                        <div class="addPictureFolderEdit">
                          <DialogSpanEdit
                            bind:text={newFolderName}
                            action="edit"
                            last={true}
                          />
                        </div>
                        <div class="addPictureFolderOptions">
                          <img
                            class="fadedButton"
                            src="/images/plus.svg"
                            alt="Add Folder"
                            on:click={() => addPictureFolder()}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="addPictureFolder {newFolderType !== 'sharepoint'
                        ? 'hiddenElement'
                        : ''}"
                    >
                      <div class="addPictureFolderTitle">
                        <span>Folder Name</span>
                        <span>Url</span>
                      </div>
                      <div class="addPictureFolderFill">
                        <div class="addPictureFolderEdit">
                          <div class="addPictureFolderSpan">
                            <DialogSpanEdit
                              bind:text={newFolderName}
                              action="edit"
                              last={true}
                            />
                          </div>
                          <div class="addPictureFolderSpan">
                            <DialogSpanEdit
                              bind:text={newFolderUrl}
                              action="edit"
                              last={true}
                            />
                          </div>
                        </div>
                        <div class="addPictureFolderOptions">
                          <img
                            class="fadedButton"
                            src="/images/plus.svg"
                            alt="Add Folder"
                            on:click={() => addPictureFolder()}
                          />
                        </div>
                      </div>
                    </div>
                  {/if}
                  <div
                    class="imagesBox customScrollbars {folderListSel.value
                      .isSharepoint
                      ? 'hiddenElement'
                      : ''}"
                  >
                    <div class="imageListBox">
                      {#each imageList as image, index}
                        <DialogProjectImage
                          {image}
                          numImage={index}
                          numImages={imageList.length}
                          onClick={() => previewImage(index)}
                          {removingFile}
                          onRemove={() => removePicture(image)}
                          {action}
                        />
                      {/each}
                    </div>
                  </div>
                  {#if action !== "show" && !folderListSel.value.isSharepoint}
                    {#if uploadingImageUrl}
                      <div class="urlImages">
                        <div class="urlInput">
                          <span>File URL</span>
                          <DialogSpanEdit
                            bind:text={imageUrl}
                            action="edit"
                            last={true}
                          />
                        </div>
                        <div class="urlOptions">
                          <img
                            class="fadedButton"
                            src="/images/save.svg"
                            alt="Save"
                            on:click={() => uploadImageURL()}
                          />
                          <img
                            class="fadedButton"
                            src="/images/x.svg"
                            alt="Cancel"
                            on:click={() => closeUploadImageUrl()}
                          />
                        </div>
                      </div>
                    {:else}
                      <div
                        class="addImageDiv {uploadingImage
                          ? 'hiddenElement'
                          : ''}"
                      >
                        <img
                          class="fadedButton"
                          src="/images/add-image.svg"
                          alt="Upload"
                          on:click={() => window.$("#uploadImages").click()}
                        />
                        <input
                          type="file"
                          id="uploadImages"
                          multiple
                          on:change={(ev) => uploadImages(ev)}
                          accept=".jpg, .jpeg, .png"
                        />
                        <img
                          class="fadedButton"
                          src="/images/add-image-url.svg"
                          alt="Upload from URL"
                          on:click={() => openUploadImageUrl()}
                        />
                      </div>
                      <div
                        class="addImageDiv {uploadingImage
                          ? ''
                          : 'hiddenElement'}"
                      >
                        <Loading size="60px" marginTop="20px" />
                      </div>
                    {/if}
                  {/if}
                </div>
              </div>
              <div
                class="columnInfo1 columnData {!folderListSel.value.isSharepoint
                  ? ''
                  : 'hiddenElement'}"
              >
                {#if images.length > 0}
                  <div class="boxImagePreview">
                    <img
                      class="imagePreviewLeft fadedButton"
                      src="/images/left-arrow.svg"
                      alt="Previous"
                      on:click={() => previewPreviousImage()}
                    />
                    <div class="imagePreview">
                      <img src={images[imageSel].url} alt="Preview" />
                    </div>
                    <img
                      class="imagePreviewRight fadedButton"
                      src="/images/right-arrow.svg"
                      alt="Next"
                      on:click={() => previewNextImage()}
                    />
                  </div>
                  <div class="imageIcons">
                    <img
                      class="imageIcon fadedButton"
                      src="/images/presentation.svg"
                      alt="Fullscreen"
                      on:click={() => showFullscreen(true)}
                    />
                    {#if !folderListSel.value.isSharepoint}
                      {#if downloadingFolder}
                        <Loading size="40px" />
                      {:else}
                        <img
                          class="imageIcon fadedButton"
                          src="/images/download-version.svg"
                          alt="Download Folder"
                          on:click={() => downloadFolder()}
                        />
                      {/if}
                    {/if}
                  </div>
                {/if}
              </div>
              <div
                class="columnInfo1 columnData columnWebview {folderListSel.value
                  .isSharepoint && !folderListSel.value.url.startsWith('/')
                  ? ''
                  : 'hiddenElement'}"
              >
              <!--
                <div class="picWebview">
                  <WebBrowser
                    type="webview"
                    iframeTitle="External Tool"
                    src={folderListSel.value.url.startsWith("/")
                      ? ""
                      : folderListSel.value.url}
                  />
                </div>
              -->
              </div>
            </div>
          {:else if cardMode === "team"}
            <div class="submainH">
              <div class="columnInfo1 columnData">
                <div class="box boxPeople customScrollbars">
                  <span class="columnTitle companyName"
                    >{project.isDSC ? "DSC" : "Labit Group"}</span
                  >
                  <span class="columnTitle teamTitle">Project Manager</span>
                  {#each teams as team}
                    {#if team.name === "Project Manager"}
                      <DialogProjectTeams
                        team={team.people}
                        grayscale={true}
                        onRemove={(id) => removeTeam(id)}
                        onUnactive={(id) => unactiveTeam(id)}
                        onClickPic={(id) => getUserCard(id, true)}
                        selectedForRemoval={selectedForRemovalTeam}
                        action="show"
                      />
                    {/if}
                  {/each}
                  {#each teams as team}
                    {#if team.name !== "Project Manager"}
                      <span class="columnTitle teamTitle">{team.name}</span>
                      <DialogProjectTeams
                        team={team.people}
                        grayscale={true}
                        onRemove={(id) => removeTeam(id)}
                        onUnactive={(id) => unactiveTeam(id)}
                        onClickPic={(id) => getUserCard(id, true)}
                        selectedForRemoval={selectedForRemovalTeam}
                        action="show"
                      />
                    {/if}
                  {/each}
                </div>
              </div>
              <div class="columnInfo1 columnData">
                <div class="box boxPeople customScrollbars">
                  <!--{#each project.companies as company}
                    <span class="columnTitle stakeholderTitle"
                      >{company.companyName} - {company.role}</span
                    >
                    <DialogProjectCompanies
                      bind:people={project.people}
                      companyId={company.companyId}
                      companyName={company.companyName}
                      projectId={project.id}
                      preparePeople={() => preparePeople()}
                      {action}
                    />
                  {/each}-->
                  {#each companyTeams as company}
                    <span class="columnTitle stakeholderTitle companyName"
                      >{company.companyName} - {company.role}</span
                    >
                    {#each company.teams as team}
                      <span class="columnTitle teamTitle">{team.name}</span>
                      <DialogProjectTeams
                        team={team.people}
                        grayscale={true}
                        onRemove={(id) => removeTeam(id)}
                        onUnactive={(id) => unactiveTeam(id)}
                        onClickPic={(id) => getUserCard(id, false)}
                        selectedForRemoval={selectedForRemovalTeam}
                        action="show"
                      />
                    {/each}
                    <div class="spacer" />
                  {/each}
                </div>
              </div>
              {#if userCard}
                <div class="columnInfo1 columnData">
                  <div class="userCard">
                    <UserCard
                      pic={userCard.pic}
                      name={userCard.name}
                      company={userCard.company}
                      companyRole={userCard.charge}
                      profRole={userCard.professionRole}
                      email1={userCard.email}
                      telephone1={userCard.phone}
                      active={userCard.active}
                      onClick={() => {
                        setBackPage(url);
                        openURL("/people/show-people/" + userCard.id, true);
                      }}
                      borderColor="var(--labit-card-lightgray)"
                      lastActivity={userCard.lastActivity}
                      officeStatus={userCard.officeStatus}
                      atHome={userCard.atHome}
                    />
                  </div>
                  {#if userCard.workPeriods.length > 0}
                    <div class="userCardWorkPeriods">
                      {#each userCard.workPeriods as wp}
                        <UserCardWorkPeriods workPeriod={wp} />
                      {/each}
                    </div>
                  {/if}
                </div>
              {/if}
            </div>
            <!--{:else if cardMode === "software"}
            <div class="submainH">
              <div class="columnInfo1 columnData">
                <div class="box boxPeople customScrollbars">
                  <span class="columnTitle">Applications</span>
                  {#each project.licenses as application, index}
                    <DialogProjectApplication
                      {application}
                      onClick={() => selectApplication(index)}
                    />
                  {/each}
                </div>
              </div>
              <div class="columnInfo1 columnData">
                <div class="box boxPeople customScrollbars">
                  <span class="columnTitle">Keys</span>
                  {#if selectedApplication >= 0}
                    {#each project.licenses[selectedApplication].keys as key, index}
                      <DialogProjectApplicationKey
                        {key}
                        onAssign={() => assignKey(selectedApplication, index)}
                        onUnassign={() =>
                          unassingKey(selectedApplication, index)}
                      />
                    {/each}
                  {/if}
                </div>
              </div>
            </div>-->
          {:else if cardMode === "planning"}
            <div class="submainPlanning">
              <CompleteResourcePlanner
                projectId={project.id}
                archive={true}
                editable={false}
              />
              <!--<CompleteResourcePlanner
                projectId={project.id}
                archive={true}
                editable={editRole === "Admin"}
              />-->
              <!--<SimplePhasePreview
                phases={phaseReview}
                milestones={milestoneReview}
              />-->
            </div>
          {/if}
        </div>
      </div>
    {:else}
      <div class="previewContainer">
        <div class="previewDiv">
          <img src={images[imageSel].url} alt="Fullscreen Preview" />
        </div>
        <div class="previewOptions">
          <div
            class="previewOptionsX fadedButton"
            on:click={() => showFullscreen(false)}
          >
            <img src="/images/x.svg" alt="Close Fullscreen" />
          </div>
          <div
            class="previewOptionsLeft fadedButton"
            on:click={() => previewPreviousImage()}
          >
            <img src="/images/left-arrow.svg" alt="Fullscreen Previous" />
          </div>
          <div
            class="previewOptionsRight fadedButton"
            on:click={() => previewNextImage()}
          >
            <img src="/images/right-arrow.svg" alt="Fullscreen Next" />
          </div>
        </div>
      </div>
    {/if}
  </div>
{/if}

<!--
{#if platform === "APP" && ((folderListSel && folderListSel.value.isSharepoint && cardMode === "images") || showTool !== "")}
  <ContextMenu
    currentURL={showTool !== "" ? showTool : folderListSel.value.url}
    bind:showMenu={showNavMenu}
  />
{/if}
-->

<style>
  div.dialogContent {
    background-color: var(--labit-background-gray);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
  }

  div.dialogContent:hover {
    width: 100%;
    height: 100%;
  }

  div.card {
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    padding: 11px;
    width: 1647px;
  }

  div.edit div.box:not(.boxFixed) {
    border-color: transparent !important;
  }

  div.header {
    width: 100%;
    display: flex;
    position: relative;
  }

  div.body {
    background-color: white;
    width: 100%;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding: 23px;
    display: flex;
  }

  div.projectCard {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }

  div.projectCardInfo {
    display: flex;
  }

  div.projectMainInfo {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    font-size: 21px !important;
    color: var(--labit-rdstable-header-text);
  }

  div.projectMainInfo span {
    margin-bottom: 3px;
    opacity: 0.7;
  }

  div.projectMainInfo span:empty:before {
    content: "\200b";
  }

  div.projectActive {
    display: flex;
    font-size: 15px;
    font-weight: bold;
    align-items: center;
    margin-top: 7px;
  }

  div.projectActive span {
    margin-bottom: 0px;
    margin-left: 7px;
    color: var(--labit-card-gray);
  }

  div.projectCardButtons {
    padding: 11px;
  }

  div.projectCardButtonsRow {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
  }

  div.pic {
    width: 259px;
    height: 166px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 14px;
  }

  div.nopic {
    width: 259px;
    height: 166px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.nopic img {
    width: 77px;
  }

  div.planpic {
    width: 241px;
    height: 155px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 14px;
  }

  div.noplanpic {
    width: 241px;
    height: 155px;
    background-color: white;
    font-size: 11px;
    color: var(--labit-card-gray);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.info {
    width: 100%;
    height: 35%;
    padding-top: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  div.row {
    width: 100%;
    display: flex;
  }

  div.row span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div.projectCard span.title {
    font-size: 16px;
    font-weight: bold;
    color: var(--labit-card-gray);
  }

  div.projectCard span.spacer {
    margin-left: 5%;
  }

  div.projectCard div.first {
    justify-content: space-between;
  }

  /*div.cardButtons {
    height: 192px;
    margin-left: 38px;
    margin-right: 38px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div.cardButtons div {
    height: 16%;
  }

  div.cardButtons div img {
    height: 100%;
    cursor: pointer;
  }

  div.cardButtons div.edit img {
    height: 16%;
  }*/

  img.close {
    position: absolute;
    width: 12px;
    top: 0px;
    right: 23px;
    cursor: pointer;
  }

  div.box {
    border-color: var(--labit-card-lightgray);
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    border-radius: 14px;
    width: 367px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  div.boxImages {
    width: 389px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  div.boxx2 {
    width: 771px;
  }

  div.box:not(:last-child) {
    margin-bottom: 10px;
  }

  div.columnData:not(:first-child) {
    margin-left: 38px;
  }

  span.columnTitle {
    width: 100%;
    font-size: 11px;
    color: var(--labit-card-gray);
    margin-bottom: 1px;
  }

  div.box img {
    width: auto;
    margin-bottom: 8px;
  }

  div.tableInfo {
    display: flex;
    margin-bottom: 0px;
  }

  div.tableInfoColumnHalf {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  div.tableInfoColumnHalf:not(:last-child) {
    padding-right: 10px;
  }

  div.tableInfoColumnThird {
    width: 33%;
    display: flex;
    flex-direction: column;
  }

  div.tableInfoColumnThird:not(:last-child) {
    padding-right: 10px;
  }

  div.tableInfoCell {
    display: flex;
  }

  div.tableInfo:last-child {
    margin-bottom: 0px;
  }

  div.tableInfo span:empty:before {
    content: "\200b";
  }

  div.active {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div.active span {
    margin-bottom: 0px;
  }

  iframe {
    margin-left: 38px;
    width: 367px;
    height: 192px;
    border-radius: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  div.googlemap {
    width: 100%;
    height: 180px;
    border-radius: 14px;
    margin-bottom: 10px;
  }

  div.nomap {
    margin-left: 38px;
    width: 367px;
    height: 192px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.nomap img {
    width: 77px;
  }

  div.projectInfo {
    margin-left: 38px;
    width: 367px;
    height: 192px;
  }

  img.urlImg {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 29px !important;
  }

  div.outlookError {
    font-size: 11px;
    color: red;
    cursor: pointer;
  }

  div.doc {
    min-height: 48px;
  }

  div.combobox {
    width: 20%;
    height: 17px;
    margin-top: 3px;
    margin-right: 6px;
  }

  div.comboboxFull {
    width: 100%;
    height: 17px;
    margin-top: 3px;
  }

  div.addOption {
    display: flex;
    width: 100%;
    align-items: center;
  }

  div.addOption img {
    height: 17px;
    width: 17px;
    cursor: pointer;
    margin: 0px;
  }

  div.calendar {
    width: 25%;
    height: 17px;
    margin-top: 3px;
    margin-right: 6px;
  }

  div.text {
    width: 20%;
    height: 17px;
    margin-top: 0;
    margin-right: 6px;
  }

  div.check {
    height: 17px;
    margin-top: 0;
    margin-right: 6px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: var(--labit-card-lightgray);
  }

  div.check span {
    margin-right: 4px;
  }

  div.comboboxStatus {
    width: 15%;
    height: 17px;
    margin-top: 3px;
    margin-right: 6px;
  }

  div.dumbBox1 {
    flex-grow: 1;
  }

  div.dumbBox2 {
    width: 771px;
    flex-grow: 1;
  }

  div.columnInfo1,
  div.columnInfo2,
  div.columnInfo3,
  div.columnInfo4 {
    display: flex;
    flex-direction: column;
  }

  span.span {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div.iconList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  div.iconList img.url {
    width: auto;
    height: 29px !important;
    cursor: pointer;
    margin-bottom: 0px !important;
  }

  span.toolURL {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    word-break: break-all;
    margin-bottom: 10px;
  }

  span.selectableTool {
    cursor: pointer;
  }

  div.options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
  }

  div.options span {
    margin-right: 40px;
    cursor: pointer;
  }

  div.submain {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.submainH {
    height: 100%;
    width: 100%;
    display: flex;
  }

  div.submainF {
    min-height: 572px;
  }

  div.submainFiles {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  div.booble {
    width: 32%;
    margin-bottom: 20px;
    margin-right: 1%;
    position: relative;
  }

  span.projectId {
    margin-bottom: 8px;
  }

  img.icon {
    width: 38px;
    cursor: pointer;
  }

  div.addStatus {
    display: flex;
  }

  div.statusCombobox {
    width: 28%;
    height: 17px;
    padding-right: 5px;
  }

  div.statusCheck {
    width: 10%;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.statusOptions {
    width: 5%;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div.statusOptions img {
    width: 15px;
    cursor: pointer;
    margin-bottom: 0px;
  }

  div.team {
    width: 100%;
    display: flex;
  }

  div.addTeam {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  div.addTeamCombobox {
    width: 90%;
    height: 17px;
  }

  div.addTeamOptions {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  div.addTeamOptions img {
    width: 17px;
    height: 17px;
    cursor: pointer;
    margin: 0px;
  }

  div.boxPeople {
    min-height: 572px;
    max-height: 572px;
    overflow-y: scroll;
  }

  div.imagesHeader {
    width: 100%;
    display: flex;
    align-items: center;
  }

  div.imagesHeader img {
    height: 22px;
    margin-right: 12px;
  }

  div.imagesCombobox {
    height: 22px;
    flex-grow: 1;
  }

  div.imagesBox {
    width: 389px;
    margin-top: 16px;
    display: flex;
  }

  div.imageListBox {
    width: 363px;
    display: flex;
    flex-wrap: wrap;
  }

  div.boxImagePreview {
    width: 1110px;
    display: flex;
    height: 522px;
    align-items: center;
  }

  img.imagePreviewLeft {
    margin-right: 17px;
    height: 15px;
    cursor: pointer;
  }

  img.imagePreviewRight {
    margin-left: 17px;
    height: 15px;
    cursor: pointer;
  }

  div.imagePreview {
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  div.imagePreview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  img.imageIcon {
    height: 35px;
    cursor: pointer;
    margin-top: 15px;
  }

  img.imageIcon:not(:last-child) {
    margin-right: 15px;
  }

  div.previewContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  div.preview {
    margin-top: 0px !important;
  }

  div.previewDiv {
    width: 1640px;
    height: 950px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.previewDiv img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
  }

  div.previewOptions {
    width: 1640px;
    height: 950px;
    position: absolute;
  }

  div.previewOptionsX {
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--labit-background-gray);
    opacity: 0.8;
    cursor: pointer;
    top: 54px;
    right: 41px;
  }

  div.previewOptionsX img {
    width: 20px;
    height: 20px;
  }

  div.previewOptionsLeft {
    position: absolute;
    width: 70px;
    height: 92px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--labit-background-gray);
    opacity: 0.8;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
    left: 41px;
  }

  div.previewOptionsRight {
    position: absolute;
    width: 70px;
    height: 92px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--labit-background-gray);
    opacity: 0.8;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
    right: 41px;
  }

  div.previewOptionsLeft img,
  div.previewOptionsRight img {
    width: 21px;
  }

  div.addImageDiv {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div.addImageDiv img {
    height: 35px;
    cursor: pointer;
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
  }

  input#uploadImages {
    display: none;
  }

  div.addPictureFolder {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }

  div.addPictureFolderTitle {
    font-size: 11px;
    color: var(--labit-card-gray);
  }

  div.addPictureFolderTitle span:first-child {
    width: 48%;
    display: inline-block;
  }

  div.addPictureFolderFill {
    display: flex;
  }

  div.addPictureFolderEdit {
    width: 93%;
    height: 17px;
    display: flex;
    justify-content: space-between;
  }

  div.addPictureFolderSpan {
    width: 49%;
    height: 17px;
  }

  div.addPictureFolderOptions {
    width: 7%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  div.addPictureFolderOptions img {
    width: 17px;
    height: 17px;
    cursor: pointer;
    margin: 0px;
  }

  img.removePictureFolder {
    cursor: pointer;
    height: 17px !important;
    margin-right: 0px !important;
    margin-left: 12px;
  }

  div.addFolderDiv {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div.addFolder {
    height: 50px;
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.addFolderEdit {
    width: 50%;
  }

  img.addFolderOption {
    cursor: pointer;
    width: 15px;
    margin-left: 5px;
  }

  img.addBig {
    cursor: pointer;
    width: 30px;
  }

  img.addSmall {
  }

  img.removeFolder {
    width: 15px;
  }

  div.modDiv {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 14px;
    color: red;
    margin-top: 11px;
  }

  div.modDiv div {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  div.description {
    height: 135px;
  }

  div.relatedProjects {
    /*max-height: 223px;*/
    height: 220px;
    overflow-y: scroll;
  }

  div.phasesScopes {
    /*max-height: 223px;*/
    height: 190px;
    overflow-y: scroll;
  }

  div.phaseHistory {
    /*max-height: 314px;*/
    height: 280px;
    overflow-y: scroll;
  }

  div.statusHistory {
    /*max-height: 314px;*/
    height: 280px;
    overflow-y: scroll;
  }

  div.stakeholders {
    height: 252px;
    overflow-y: scroll;
  }

  span.teamTitle {
    margin-top: 10px;
  }

  span.stakeholderTitle:not(:first-child) {
    margin-top: 10px;
  }

  div.comboboxStakeholder {
    width: 45%;
    height: 17px;
    align-items: center;
  }

  div.stakeholdersOptions {
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  img.stakeholderAdd {
    height: 17px;
    width: 17px;
    margin-bottom: 0px !important;
  }

  div.urlImages {
    width: 100%;
    display: flex;
    margin-top: 16px;
  }

  div.urlInput {
    width: 90%;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    color: var(--labit-card-gray);
  }

  div.urlOptions {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  div.urlOptions img {
    width: 15px;
    margin-left: 5px;
  }

  div.pictureType {
    width: 100%;
    display: flex;
    margin-top: 16px;
  }

  div.columnWebview {
    width: 100%;
  }

  div.picWebview {
    width: 100%;
    height: 800px;
  }

  div.tool {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.toolOptions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  div.toolOptions img {
    width: 10px;
  }

  div.toolWebview {
    width: 100%;
    height: 800px;
  }

  div.pageContainer {
    display: flex;
  }

  span.companyName {
    font-weight: bold;
  }

  div.spacer {
    width: 100%;
    height: 15px;
  }

  div.userCard {
    height: 202px;
    width: 398px;
  }

  div.userCardWorkPeriods {
    border: solid 1px var(--labit-card-lightgray);
    padding: 10px;
    border-radius: 14px;
    margin-top: 10px;
  }

  div.comboboxProjectLinks {
    width: 100%;
    height: 17px;
    display: flex;
  }

  div.comboboxProjectLinks:not(:last-child) {
    margin-bottom: 10px;
  }

  div.comboboxProjectLinks img {
    width: 17px;
    height: 17px;
    margin-left: 6px;
  }

  div.external {
    position: relative;
    margin-right: 15px;
    margin-bottom: 8px;
  }

  div.external img.remove {
    height: 15px;
    position: absolute;
    bottom: -8px;
    right: -8px;
  }

  div.boxProjectLinks {
    padding-bottom: 0px;
  }

  div.imageIcons {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  div.titleAdd {
    display: flex;
    align-items: center;
  }

  div.titleAdd span {
    width: auto;
  }

  div.titleAdd img {
    height: 10px;
    margin: 0px 0px 0px 5px;
    width: auto;
  }

  div.titleButtons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 8px;
  }

  div.editButton {
    width: 100px;
    height: 38px;
  }

  div.cardIcon {
    margin-left: 12px;
  }

  div.cardIconIcon {
    width: 38px;
    height: 38px;
  }

  div.cardButtons {
    height: 100%;
    padding: 10px;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding-top: 35px;
    margin-right: 10px;
  }

  div.cardButtons div {
    height: 38px;
  }

  div.cardButtons div a {
    height: 100%;
  }

  div.cardButtons div img {
    height: 100%;
    cursor: pointer;
  }

  div.xDialog {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  span.xDialog {
    position: absolute;
    top: -28px;
    left: 12px;
    color: var(--labit-light-red);
    font-size: 13px;
  }

  div.xDialogRow {
    display: flex;
  }

  div.plan {
    align-items: center;
  }

  div.submainPlanning {
    height: 100%;
    width: 100%;
    display: flex;
    min-height: 800px;
  }

  div.iframeFile {
    height: 800px;
    background-color: var(--labit-dialog-background);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    margin-top: 20px;
    border-radius: 14px;
  }

  div.iframeFile img {
    width: 9px;
    height: 9px;
    margin: 0px 20px 10px 0px;
  }

  /* Animations */

  div.addOption img {
    transition: opacity 0.5s linear 0s;
  }

  div.addOption img:hover {
    opacity: 0.5;
  }

  img.close {
    transition: opacity 0.5s linear 0s;
  }

  img.close:hover {
    opacity: 0.5;
  }

  div.cardButtons img {
    transition: opacity 0.5s linear 0s;
  }

  div.cardButtons img:hover {
    opacity: 0.5;
  }

  div.options span {
    transition: opacity 0.5s linear 0s;
  }

  div.options span:hover {
    opacity: 0.5;
  }

  img.icon {
    transition: opacity 0.5s linear 0s;
  }

  img.icon:hover {
    opacity: 0.5;
  }

  div.internalSwitch {
    display: flex;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-bottom: 8px;
    align-items: center;
  }

  div.internalSwitch span {
    margin-left: 5px;
  }
</style>
