<script>
  export let category;
  export let onEdit;
  export let onDelete;
  export let deletingId;
</script>

<div class="categoryItemContainer">
  <div class="info">
    <span>{category.name}</span>
  </div>
  <div class="options">
    {#if category.editable}
      <img
        class="fadedButton"
        src="/images/edit.svg"
        alt="Edit"
        on:click={onEdit}
      />
      <img
        class="fadedButton"
        src={deletingId === category.id
          ? "/images/trash-red.svg"
          : "/images/trash.svg"}
        alt="Remove"
        on:click={onDelete}
      />
    {/if}
  </div>
</div>

<style>
  div.categoryItemContainer {
    display: flex;
    margin-bottom: 10px;
  }

  div.info {
    width: 90%;
    font-size: 14px;
    color: var(--labit-card-gray);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.options {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }

  div.options img {
    width: 20px;
    margin-left: 5px;
  }
</style>
