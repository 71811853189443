<script>
  export let project;
  export let phase;
  export let dayArray;
  export let dayWidth;
  export let stickyPhase;
  export let stickyStart;
  export let stickyEnd;
  export let stickyPeople;
  export let showStartColumn;
  export let showEndColumn;
  export let showTeamColumn;
  export let editingPlan;
  export let onClickPhase;
  export let startDate;
  export let endDate;
  export let showPlannerTasks;
  export let CRPid;
  export let loaded;
  export let drawPhases;
  export let view;
  import moment from 'moment';

  import CompleteResourcePlannerGridCell from "./CompleteResourcePlannerGridCell.svelte";
  import Picture from "../Picture.svelte";

  const PHASE_WIDTH = 330;
  const START_WIDTH = 70;
  const END_WIDTH = 70;
  const PEOPLE_WIDTH = 130;
</script>

<div class="mainPhase" style={view === "project" ? "" : "display: none;"}>
  <div
    class="phase borderRight borderBottom borderLeft"
    style="width: {PHASE_WIDTH}px; left: {stickyPhase}px;"
    on:click={(ev) => {
      if (editingPlan) {
        onClickPhase(true, ev.pageX, ev.pageY, project, phase, "phase");
      }
    }}
  >
    <span>{phase.name}</span>
  </div>
  {#if showStartColumn}
    <div
      class="start borderRight borderBottom"
      style="width: {START_WIDTH}px; left: {stickyStart}px;"
    >
      {moment(phase.start, "YYYY-MM-DD").format("DD/MM/YYYY")}
    </div>
  {/if}
  {#if showEndColumn}
    <div
      class="end borderRight borderBottom"
      style="width: {END_WIDTH}px; left: {stickyEnd}px;"
    >
      {moment(phase.end, "YYYY-MM-DD").format("DD/MM/YYYY")}
    </div>
  {/if}
  {#if showTeamColumn}
    <div
      class="people borderRight borderBottom"
      style="width: {PEOPLE_WIDTH}px; left: {stickyPeople}px;"
      on:click={(ev) => {
        if (editingPlan) {
          onClickPhase(true, ev.pageX, ev.pageY, project, phase, "team");
        }
      }}
    >
      {#each phase.people as p}
        <div class="contact">
          <Picture
            image={p.pic}
            name={p.name + " - " + p.role}
            showHint={true}
            small={true}
          />
        </div>
      {/each}
    </div>
  {/if}
  {#each dayArray as day, index}
    <CompleteResourcePlannerGridCell
      {day}
      phase={day === phase.start || (phase.start < day && index === 0)
        ? phase
        : null}
      {dayWidth}
      subphase={false}
      {dayArray}
      {startDate}
      {endDate}
      {showPlannerTasks}
      {CRPid}
      {loaded}
      {drawPhases}
    />
  {/each}
</div>

<style>
  div.mainPhase {
    display: flex;
    width: fit-content;
    min-height: 30px;
  }

  div.people {
    display: flex;
    /*width: 100%;*/
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  div.contact {
    width: 25px;
    height: 25px;
    margin: 2px;
  }

  div.phase,
  div.start,
  div.end,
  div.people {
    position: sticky;
    background-color: white;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    z-index: 2;
  }

  div.phase {
    padding-left: 10px;
  }

  div.start,
  div.end {
    display: flex;
    font-size: 11px;
    justify-content: center;
  }

  div.phase {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
