<script>
  export let image;
  export let disabled;
  export let onClick;
</script>

<div
  class="folderTypeIconContainer {disabled ? 'disabled' : 'fadedButton'}"
  on:click={onClick}
>
  <img src={image} alt="Folder Icon" />
</div>

<style>
  div.folderTypeIconContainer {
    display: flex;
    cursor: pointer;
  }

  div.disabled {
    opacity: 0.5;
  }

  img {
    height: 20px;
    margin-right: 7px;
  }
</style>
