<script>
  export let day;
  export let dayWidth;
  export let CRPid;

  import moment from 'moment';
  let weekend = false;
  let today = false;

  let checkWeekend = () => {
    const ddd = moment(day, "YYYY-MM-DD").format("ddd");
    weekend = ddd === "Sat" || ddd === "Sun";
  };

  let checkToday = () => {
    today = moment().format("YYYY-MM-DD") === day;
  };

  $: if (day) {
    checkWeekend();
    checkToday();
  }
</script>

<div
  class="{weekend ? 'weekend' : ''} {today ? 'today' : ''} {dayWidth > 10
    ? ''
    : 'small'} borderRight borderBottom"
  style="width: {dayWidth}px"
  id={CRPid + "-" + day}
>
  {moment(day, "YYYY-MM-DD").format("DD")}
</div>

<style>
  div {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    font-size: 11px;
    height: 25px;
    align-items: center;
    background-color: white;
  }

  div.small {
    font-size: 1px;
    color: transparent;
  }

  div.today {
    color: white;
  }
</style>
