<script>
  export let type;
</script>

<style>
  div.RDSType {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: var(--labit-rdstable-card-header);
    border-width: 1px;
    border-style: solid;
    border-color: var(--labit-dialog-background);
    padding: 6px;
    border-radius: 10px;
    font-weight: bold;
  }

  div.RDSType:not(:last-child) {
    margin-bottom: 6px;
  }
</style>

<div class="RDSType">
  <span>{type.name}</span>
</div>
