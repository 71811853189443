<script>
  export let page = "";

  import Menu from "../components/Menu.svelte";
  import LoginIcon from "../components/LoginIcon.svelte";
</script>

<div class="content">
  <div class="info">
    <LoginIcon
      imgIcon="/images/login/cloud-logo.svg"
      color="var(--labit-login-icon-gray)"
    />
    <div class="form formDiv">
      <div class="info">
        <span class="msg formDiv coming">ONLY DIRECTION!</span>
      </div>
    </div>
  </div>
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 19px;
  }

  div.form {
    margin-top: -96px;
  }

  span.msg {
    font-size: 12px;
    color: var(--labit-darkgray);
    margin-bottom: 29px;
  }

  span.coming {
    font-size: 46px;
    font-weight: bold;
    color: var(--labit-card-gray);
  }
</style>
