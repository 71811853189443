<script>
  export let element;
  export let onClose;
  export let onRemove;

  import DialogRemovePasswordEdit from "../components/DialogRemovePasswordEdit.svelte";

  import { rpost } from "../js/labit-connection";
  import { getUserId } from "../js/localInfo";

  import { onMount } from "svelte";

  let passwd = "";
  let realPasswd = "";
  let validPassword = false;

  onMount(() => {
    getRealPassword();
  });

  let getRealPassword = async () => {
    const response = await rpost("GetPassword", { contact_id: getUserId() });
    realPasswd = response[0];
  };

  $: {
    if (passwd !== "") {
      validPassword = passwd === realPasswd;
    }
  }
</script>

<style>
  div.dialogContent {
    background-color: var(--labit-background-gray);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
  }

  div.dialogContent:hover {
    width: 100%;
    height: 100%;
  }

  div.card {
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    width: 1640px;
    height: 902px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  div.new {
    width: 372px;
    height: 372px;
    background-color: white;
    border-radius: 19px;
    margin-bottom: 21px;
    margin-top: 106px;
  }

  div.newContainer {
    width: 100%;
    height: 100%;
    padding-left: 18.8%;
    padding-right: 9.1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.newContainer span {
    font-size: 13px;
    color: var(--labit-card-gray);
    font-weight: bold;
  }

  div.remove,
  div.back {
    width: 372px;
    height: 372px;
    border-radius: 19px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.remove {
    background-color: var(--labit-card-red);
  }

  div.back {
    background-color: var(--labit-card-gray);
  }

  div.cardButtons div img {
    height: 100%;
    cursor: pointer;
  }

  img.close {
    position: absolute;
    width: 12px;
    top: 12px;
    right: 25px;
    cursor: pointer;
  }

  div.info {
    display: flex;
    flex-direction: column;
  }

  div.info span {
    margin-bottom: 10px;
  }

  span.name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div.password {
    margin-top: 29px;
  }

  div.passwdContainer {
    width: 100%;
    height: 40px;
  }

  img.icon {
    height: 52px;
  }

  /* Animations */

  img.close {
    transition: opacity 0.5s linear 0s;
  }

  img.close:hover {
    opacity: 0.5;
  }

  div.back {
    transition: opacity 0.5s linear 0s;
  }

  div.back:hover {
    opacity: 0.5;
  }

  div.remove {
    transition: opacity 0.5s linear 0s;
  }

  div.remove:hover {
    opacity: 0.5;
  }
</style>

<div class="dialogContent">
  <div class="card">
    <img
      class="close"
      src="/images/dialog_x.svg"
      alt="Close"
      on:click={onClose} />
    <div class="new">
      <div class="newContainer">
        <div class="info">
          <span>Are you sure you want to delete</span>
          <span class="name">{element ? element : ''}</span>
        </div>
        <div class="info password">
          <span>Password</span>
          <div class="passwdContainer">
            <DialogRemovePasswordEdit bind:passwd {validPassword} />
          </div>
        </div>
      </div>
    </div>
    {#if validPassword}
      <div class="remove" on:click={onRemove}>
        <img class="icon" src="/images/remove-trash.svg" alt="Remove Icon" />
      </div>
    {:else}
      <div class="back" on:click={onClose}>
        <img class="icon" src="/images/remove-back.svg" alt="Back Icon" />
      </div>
    {/if}
  </div>
</div>
