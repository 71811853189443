<script>
  export let projects;
  export let day;
  export let dayWidth;
  export let dayArray;
  export let startDate;
  export let endDate;
  export let CRPid;
  export let loaded;
  export let drawPhases;
  export let index;
  export let rowHeight;

  import moment from 'moment';

  let weekend = false;
  let today = false;

  let clipLeft = false;
  let clipRight = false;

  let periods = [];

  const PHASE_HEIGHT = 25;
  const PHASE_FONTSIZE = 13;

  const TEAM_MARGIN_TOP_BAR = 30;

  let preparePeriods = () => {
    periods = [];
    projects.forEach((project) => {
      if (project.visible) {
        project.dates.forEach((date) => {
          if (day === date.start || (date.start < day && index === 0)) {
            let lineOffset = 0;
            let lineWidth = 0;
            let start = 0;
            let end = 0;
            if (date.end < startDate || date.start > endDate) {
              start = 0;
              end = 0;
            } else {
              start = date.start;
              end = date.end;
              clipLeft = false;
              clipRight = false;

              if (date.start < startDate) {
                start = startDate;
                clipLeft = true;
              }
              if (date.end > endDate) {
                end = endDate;
                clipRight = true;
              }

              const endOffset = window.$("#" + CRPid + "-" + end).offset();
              const startOffset = window.$("#" + CRPid + "-" + start).offset();
              if (startOffset !== undefined && endOffset !== undefined) {
                lineWidth =
                  endOffset.left - startOffset.left + parseInt(dayWidth);
              }

              const top = TEAM_MARGIN_TOP_BAR * (date.topLevel + 1);

              periods.push({
                id: date.id,
                phase: date.phase,
                role: date.role,
                dateStart: date.start,
                dateEnd: date.end,
                start,
                end,
                title:
                  project.code +
                  " " +
                  project.name.toUpperCase() +
                  " " +
                  date.phase +
                  " " +
                  moment(date.start, "YYYY-MM-DD").format("DD/MM/YYYY") +
                  " - " +
                  moment(date.end, "YYYY-MM-DD").format("DD/MM/YYYY") +
                  " " +
                  date.role,
                projectId: project.id,
                projectName: project.name,
                projectCode: project.code,
                top: top,
                projectColor: project.color,
                lineOffset,
                lineWidth,
                clipLeft,
                clipRight,
              });
            }
          }
        });
      }
    });

    periods = periods;
  };

  let checkWeekend = () => {
    const ddd = moment(day, "YYYY-MM-DD").format("ddd");
    weekend = ddd === "Sat" || ddd === "Sun";
  };

  let checkToday = () => {
    today = moment().format("YYYY-MM-DD") === day;
  };

  $: if (day) {
    checkWeekend();
    checkToday();
  }

  $: if (drawPhases) {
    if (loaded && projects && dayWidth && dayArray && startDate && endDate) {
      preparePeriods();
    }
  }
</script>

<div
  class="cell {periods.length > 0 ? 'anchor' : ''} {weekend
    ? 'weekend'
    : ''} {today ? 'today' : ''} borderRight borderBottom"
  style="width: {dayWidth}px; height: {rowHeight}px;"
>
  {#each periods as period}
    <div
      class="phaseBar {period.clipLeft ? 'clipLeft' : ''} {period.clipRight
        ? 'clipRight'
        : ''}"
      title={period.title}
      style="width: {period.lineWidth}px; margin-left: {period.lineOffset}px; height: {PHASE_HEIGHT}px; font-size: {PHASE_FONTSIZE}px; background-color: {period.projectColor}; top: {period.top}px; z-index: {period.top};"
    >
      <span
        >{period.projectCode +
          " " +
          period.projectName +
          " - " +
          period.phase}</span
      >
    </div>
  {/each}
</div>

<style>
  div.cell {
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
  }

  div.phaseBar {
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    padding: 0px 5px 0px 5px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    border: 1px solid var(--labit-card-gray);
  }

  div.phaseBar:hover {
    z-index: 450 !important;
    background-color: var(--labit-people-work-plan) !important;
  }

  div.clipLeft {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  div.clipRight {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  div.phaseBar span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
