import { encrypt, decrypt } from "./encryption";

import { tempUser } from "../js/stores.js";

const USER_ID = "99803420697439332690";
const USER_NAME = "57887485292069451930";
const TOKEN = "27093772624459659979";
const ROLE = "64570647363485639786";
const PICTURE = "69654232532317036238";
const OUTLOOK = "15365916510277232491";
const OUTLOOK_EMAIL = "59959215984593736906";
const OPEN_TOOL = "48433373999029014936";
const OPEN_SLACK = "03218493506488685675";
const OUTLOOK_NEXT = "73615199203872223878";
const LAST_OUTLOOK_MESSAGE = "62446262699107931102";
const OUTLOOK_SEND_EMAIL = "96676290027048465438";
const SLACK_NOTIFICATION = "24035719725793431355";
const OPEN_URL = "78258935603786485631";
const OPEN_URL_USERAGENT = "60085151264772721952";
const NO_NAV_MENU_DOMAINS = "57236564906770620162";
const MAIN_ARGS = "08045998439121362117";
const TESTER = "06126890159031618673";
const SKYLAB_VERSION = "61321475674217702793";
const OS = "57702112917973316860";
const BACK_PAGE = "72011987944326384773";
const LAST_PROJECT_PDASHBOARD = "96821984977207906630";
const PROJECT_MANAGER_DASHBOARD = "13550529212005807021";

let viewAsUser = null;
const unsubscribe = tempUser.subscribe((value) => {
  viewAsUser = value;
});

export let setUserId = (id) => {
  const enc = encrypt(id.toString());
  localStorage.setItem(USER_ID, enc);
};

export let getUserId = () => {
  let id = "";
  if (viewAsUser) {
    id = viewAsUser.id;
  } else {
    const enc = localStorage.getItem(USER_ID);
    if (enc) {
      id = parseInt(decrypt(enc));
    }
  }
  return id;
};

export let setUserName = (name) => {
  const enc = encrypt(name.toString());
  localStorage.setItem(USER_NAME, enc);
};

export let getUserName = () => {
  let name = "";
  if (viewAsUser) {
    name = viewAsUser.name;
  } else {
    const enc = localStorage.getItem(USER_NAME);
    if (enc) {
      name = decrypt(enc);
    }
  }
  return name;
};

export let setToken = (token) => {
  const enc = encrypt(token);
  localStorage.setItem(TOKEN, enc);
};

export let getToken = () => {
  let token = "";
  if (viewAsUser) {
    token = viewAsUser.token;
  } else {
    const enc = localStorage.getItem(TOKEN);
    if (enc) {
      token = decrypt(enc);
    }
  }
  return token;
};

export let setRole = (role) => {
  const enc = encrypt(role);
  localStorage.setItem(ROLE, enc);
};

export let getRole = () => {
  let role = "";
  if (viewAsUser) {
    role = viewAsUser.role;
  } else {
    const enc = localStorage.getItem(ROLE);
    if (enc) {
      role = decrypt(enc);
    }
  }
  return role;
};

export let setPicture = (pic) => {
  const enc = encrypt(pic);
  localStorage.setItem(PICTURE, enc);
};

export let getPicture = () => {
  let pic = "";
  if (viewAsUser) {
    pic = viewAsUser.pic;
  } else {
    const enc = localStorage.getItem(PICTURE);
    if (enc) {
      pic = decrypt(enc);
    }
  }
  return pic;
};

export let logout = () => {
  localStorage.clear();
  document.cookie = "skylab-settings=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
};

export let setOutlookToken = (token) => {
  const enc = encrypt(token);
  localStorage.setItem(OUTLOOK, enc);
};

export let getOutlookToken = () => {
  let token = "";
  const enc = localStorage.getItem(OUTLOOK);
  if (enc) {
    token = decrypt(enc);
  }
  return token;
};

export let setOutlookEmail = (email) => {
  const enc = encrypt(email);
  localStorage.setItem(OUTLOOK_EMAIL, enc);
};

export let getOutlookEmail = () => {
  let email = "";
  const enc = localStorage.getItem(OUTLOOK_EMAIL);
  if (enc) {
    email = decrypt(enc);
  }
  return email;
};

export let getPlatform = () => {
  const platform = navigator.userAgent.includes("SkyLab") ? "APP" : "Web";
  return platform;
};

export let setOpenTool = (url) => {
  const enc = encrypt(url);
  localStorage.setItem(OPEN_TOOL, enc);
};

export let getOpenTool = () => {
  let url = "";
  const enc = localStorage.getItem(OPEN_TOOL);
  if (enc) {
    url = decrypt(enc);
  }
  setOpenTool("");
  return url;
};

export let setOpenSlack = (index) => {
  const enc = encrypt(index.toString());
  localStorage.setItem(OPEN_SLACK, enc);
};

export let getOpenSlack = () => {
  let index = -1;
  const enc = localStorage.getItem(OPEN_SLACK);
  if (enc) {
    index = parseInt(decrypt(enc));
  }
  setOpenSlack(-1);
  return index;
};

export let setOutlookNext = (url) => {
  const enc = encrypt(url);
  localStorage.setItem(OUTLOOK_NEXT, enc);
};

export let getOutlookNext = () => {
  let url = "";
  const enc = localStorage.getItem(OUTLOOK_NEXT);
  if (enc) {
    url = decrypt(enc);
  }
  setOutlookNext("");
  return url;
};

export let setLastOutlookMessage = (id) => {
  const enc = encrypt(id);
  localStorage.setItem(LAST_OUTLOOK_MESSAGE, enc);
};

export let getLastOutlookMessage = () => {
  let id = "";
  const enc = localStorage.getItem(LAST_OUTLOOK_MESSAGE);
  if (enc) {
    id = decrypt(enc);
  }
  return id;
};

export let setSlackNotification = (not) => {
  const enc = encrypt(not);
  localStorage.setItem(SLACK_NOTIFICATION, enc);
};

export let getSlackNotification = () => {
  let not = "";
  const enc = localStorage.getItem(SLACK_NOTIFICATION);
  if (enc) {
    not = decrypt(enc);
  }
  return not;
};

export let setOutlookSendEmail = (email) => {
  const enc = encrypt(email);
  localStorage.setItem(OUTLOOK_SEND_EMAIL, enc);
};

export let getOutlookSendEmail = () => {
  let email = "";
  const enc = localStorage.getItem(OUTLOOK_SEND_EMAIL);
  if (enc) {
    email = decrypt(enc);
  }
  return email;
};

export let setOpenURL = (url) => {
  const enc = encrypt(url);
  localStorage.setItem(OPEN_URL, enc);
};

export let getOpenURL = () => {
  let url = "";
  const enc = localStorage.getItem(OPEN_URL);
  if (enc) {
    url = decrypt(enc);
  }
  setOpenURL("");
  return url;
};

export let setOpenURLUserAgent = (useragent) => {
  const enc = encrypt(useragent);
  localStorage.setItem(OPEN_URL_USERAGENT, enc);
};

export let getOpenURLUserAgent = () => {
  let useragent = "";
  const enc = localStorage.getItem(OPEN_URL_USERAGENT);
  if (enc) {
    useragent = decrypt(enc);
  }
  setOpenURLUserAgent("");
  return useragent;
};

export let addDomainNoNavMenu = (url) => {
  if (url !== "") {
    let domain = new URL(url);
    let list = [];
    let enc = localStorage.getItem(NO_NAV_MENU_DOMAINS);
    if (enc) {
      let s = decrypt(enc);
      list = JSON.parse(s);
    }
    const pos = list.findIndex((item) => {
      return item === domain.hostname;
    });
    if (pos === -1) {
      list.push(domain.hostname);
      let s = JSON.stringify(list);
      enc = encrypt(s);
      localStorage.setItem(NO_NAV_MENU_DOMAINS, enc);
    }
  }
};

export let removeDomainNoNavMenu = (url) => {
  if (url !== "") {
    try {
      let domain = new URL(url);
      let list = [];
      let enc = localStorage.getItem(NO_NAV_MENU_DOMAINS);
      if (enc) {
        let s = decrypt(enc);
        list = JSON.parse(s);
        const pos = list.findIndex((item) => {
          return item === domain.hostname;
        });
        if (pos >= 0) {
          list = list.filter((item) => {
            return item !== domain.hostname;
          });
          s = JSON.stringify(list);
          enc = encrypt(s);
          localStorage.setItem(NO_NAV_MENU_DOMAINS, enc);
        }
      }
    } catch (e) {}
  }
};

export let showDomainNavMenu = (url) => {
  let show = true;
  if (url !== "") {
    try {
      let domain = new URL(url);
      let list = [];
      let enc = localStorage.getItem(NO_NAV_MENU_DOMAINS);
      if (enc) {
        let s = decrypt(enc);
        list = JSON.parse(s);
        const pos = list.findIndex((item) => {
          return item === domain.hostname;
        });
        show = pos === -1;
      }
    } catch (e) {}
  }
  return show;
};

export let setMainArgs = (args) => {
  const enc = encrypt(args);
  localStorage.setItem(MAIN_ARGS, enc);
};

export let getMainArgs = () => {
  let args = "";
  const enc = localStorage.getItem(MAIN_ARGS);
  if (enc) {
    args = decrypt(enc);
  }
  if (args !== "") {
    setMainArgs("");
  }
  return args;
};

export let setTester = (tester) => {
  const enc = encrypt(tester ? "1" : "0");
  localStorage.setItem(TESTER, enc);
};

export let getTester = () => {
  let tester = false;
  const enc = localStorage.getItem(TESTER);
  console.log("TESTER", decrypt(enc));
  if (enc) {
    tester = decrypt(enc) === "1";
  }
  return tester;
};

export let setSkylabVersion = (version) => {
  const enc = encrypt(version);
  localStorage.setItem(SKYLAB_VERSION, enc);
};

export let getSkylabVersion = () => {
  let version = "";
  const enc = localStorage.getItem(SKYLAB_VERSION);
  if (enc) {
    version = decrypt(enc);
  }
  return version;
};

export let setOS = (os) => {
  const enc = encrypt(os);
  localStorage.setItem(OS, enc);
};

export let getOS = () => {
  let os = "windows";
  const enc = localStorage.getItem(OS);
  if (enc) {
    os = decrypt(enc);
  }
  return os;
};

export let setBackPage = (page) => {
  const enc = encrypt(page);
  localStorage.setItem(BACK_PAGE, enc);
};

export let getBackPage = () => {
  let page = "";
  const enc = localStorage.getItem(BACK_PAGE);
  if (enc) {
    page = decrypt(enc);
  }
  setBackPage("");
  return page;
};

export let setLastProjectPDashboard = (id) => {
  const enc = encrypt(id);
  localStorage.setItem(LAST_PROJECT_PDASHBOARD, enc);
};

export let getLastProjectPDashboard = () => {
  let id = "";
  const enc = localStorage.getItem(LAST_PROJECT_PDASHBOARD);
  if (enc) {
    id = decrypt(enc);
  }
  return id;
};

export let setProjectManagerDashboard = (conf) => {
  const enc = encrypt(JSON.stringify(conf));
  localStorage.setItem(PROJECT_MANAGER_DASHBOARD, enc);
};

export let getProjectManagerDashboard = () => {
  let conf = null;
  const enc = localStorage.getItem(PROJECT_MANAGER_DASHBOARD);
  if (enc) {
    conf = JSON.parse(decrypt(enc));
  }
  return conf;
};
