<script>
  export let visible;

  import LoginIcon from "../components/LoginIcon.svelte";
  import VersionButton from "../components/VersionButton.svelte";

  import { LABIT_REST_SERVER } from "../js/labit-connection";
  import { getToken } from "../js/localInfo";

  const SKYLAB_PATH = "/skylab-versions/";

  let clickDownload = (platform) => {
    location.href =
      LABIT_REST_SERVER +
      "DownloadFile.php?url=" +
      btoa(
        unescape(
          encodeURIComponent(SKYLAB_PATH + "skylab-" + platform + ".zip")
        )
      ) +
      "&token=" +
      getToken();
  };
</script>

<div class="loginContainer {visible ? '' : 'hiddenElement'}">
  <a class="logo" href="https://labit.es" target="_blank">
    <img src="/images/logo-labit-website.svg" alt="Logo Icon" />
  </a>
  <div class="info">
    <LoginIcon
      imgIcon="/images/login/thunder.svg"
      color="var(--labit-login-icon-red)"
      imgWidth="90px"
    />
    <div class="form formDiv">
      <div class="info">
        <span class="msg formDiv">
          The SkyLab version you are working with is obsolete and you need to
          download the latest version to continue working.
        </span>
      </div>
      <div class="formItem button">
        <VersionButton
          text="Download WINDOWS version >"
          colorText="white"
          colorBackground="var(--labit-login-icon-gray)"
          colorBorder="var(--labit-login-icon-gray)"
          onClick={() => clickDownload("windows")}
          icon="/images/windows-white.svg"
        />
      </div>
      <div class="formItem button">
        <VersionButton
          text="Download MAC version >"
          colorText="white"
          colorBackground="var(--labit-login-icon-gray)"
          colorBorder="var(--labit-login-icon-gray)"
          onClick={() => clickDownload("mac")}
          icon="/images/apple-white.svg"
        />
      </div>
    </div>
  </div>
</div>

<style>
  div.loginContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  }

  div.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 134px;
  }

  div.form {
    width: 50%;
    margin-top: -173px;
  }

  div.formItem {
    width: 100%;
    height: 29px;
    margin-bottom: 19px;
    position: relative;
  }

  div.button {
    height: 33px;
  }

  span.msg {
    font-size: 12px;
    color: var(--labit-darkgray);
    margin-bottom: 33px;
    text-align: center;
  }

  a.logo {
    position: absolute;
    top: 38px;
    left: 115px;
  }

  a.logo img {
    width: 48px;
    cursor: pointer;
  }

  /* Animations */

  a.logo img {
    transition: opacity 0.5s linear 0s;
  }

  a.logo img:hover {
    opacity: 0.5;
  }
</style>
