<script>
  export let attribute;
  export let epigraphIndex;
  export let columnIndex;
  export let selFilterEpigraph;
  export let selFilterAttribute;
  export let epigraph;

  import RDSType from "./RDSType.svelte";

  let visible = true;

  $: {
    let visibleE = true;
    let visibleA = true;

    if (selFilterEpigraph.length > 0) {
      const pos = selFilterEpigraph.findIndex(sE => {
        return sE === epigraph.id;
      });
      visibleE = pos >= 0;
    } else {
      visibleE = true;
    }

    if (selFilterAttribute.length > 0) {
      const pos = selFilterAttribute.findIndex(sA => {
        return sA === attribute.name;
      });
      visibleA = pos >= 0;
    } else {
      visibleA = true;
    }

    visible = visibleE && visibleA;
  }
</script>

<style>
  td {
    background-color: var(--labit-rdstable-header);
    vertical-align: top;
  }

  div.RDSAttribute {
    display: flex;
    flex-direction: column;
    min-width: 227px;
  }

  div.fixedColumn0,
  div.fixedColumn1 {
    display: flex;
    flex-direction: column;
    min-width: 134px;
  }

  div.title {
    display: flex;
    color: var(--labit-rdstable-header-text);
    font-size: 13px;
    padding: 12px;
  }

  div.title span {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }

  div.types {
    background-color: var(--labit-rdstable-header);
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
  }
</style>

{#if visible}
  <td
    class="fixedRow1 {epigraphIndex === 0 && columnIndex === 0 ? 'fixedColumn0' : ''}
    {epigraphIndex === 0 && columnIndex === 1 ? 'fixedColumn1' : ''}">
    <div
      class="RDSAttribute {epigraphIndex === 0 && columnIndex === 0 ? 'fixedColumn0' : ''}
      {epigraphIndex === 0 && columnIndex === 1 ? 'fixedColumn1' : ''}">
      <div class="title">
        <span>{attribute.name.toUpperCase()}</span>
      </div>
      <div class="types">
        {#each attribute.keys as type}
          <RDSType {type} />
        {/each}
      </div>
    </div>
  </td>
{/if}
