<script>
  export let marginTop = 0;
  export let size = "134px";
</script>

<div class="loadingContainer" style="margin-top: {marginTop}">
  <div class="cloud" style="width: {size}">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 85.4 85.4"
      preserveAspectRatio="xMidYMid meet"
      style="enable-background:new 0 0 85.4 85.4;"
      xml:space="preserve"
      ><style type="text/css">
        .st1 {
          fill: none;
          stroke: #d7e1ea;
          stroke-width: 6;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        }
        .st0 {
          fill: none;
          stroke: #35425b;
          stroke-width: 6;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        }
      </style><g id="Layer_1_1_">
        <g>
          <path
            id="cloudy"
            class="st1"
            d="M67.9,68.8H18.4C9.9,68.8,3,61.9,3,53.5C3,46.8,7.4,41,13.5,38.9v-0.5c0-7.7,6.3-14,14-14
            c1.9,0,3.8,0.5,5.6,1.2c3.8-5.6,9.9-9,16.9-9c11.2,0,20.2,9,20.2,20.2c0,1.2-0.2,2.4-0.3,3.6c7.1,0.9,12.5,6.9,12.5,14.2
            C82.2,62.3,75.7,68.8,67.9,68.8z"
          />
          <path
            id="cloudy"
            class="st0"
            d="M67.9,68.8H18.4C9.9,68.8,3,61.9,3,53.5C3,46.8,7.4,41,13.5,38.9v-0.5c0-7.7,6.3-14,14-14
            c1.9,0,3.8,0.5,5.6,1.2c3.8-5.6,9.9-9,16.9-9c11.2,0,20.2,9,20.2,20.2c0,1.2-0.2,2.4-0.3,3.6c7.1,0.9,12.5,6.9,12.5,14.2
            C82.2,62.3,75.7,68.8,67.9,68.8z"
          />
        </g>
      </g>
    </svg>
  </div>
  <!--<span>"Clouds are coming"</span>-->
</div>

<style>
  div.loadingContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  span {
    font-size: 19px;
    margin-top: 10px;
  }

  .st0 {
    position: absolute;
    stroke-dasharray: 110;
    stroke-dashoffset: 0;
    animation: dash 5s linear infinite;
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 225;
    }
    to {
      stroke-dashoffset: 5;
    }
  }

  .st1 {
    position: absolute;
  }
</style>
