<script>
    import { createEventDispatcher } from 'svelte';

    export let option;
    
    const dispatch = createEventDispatcher();
    const toggleSelected = () => {
        option.selected = !option.selected;
        dispatch('clicked', {id: option.id, selected: option.selected});
    }

</script>

<div class='option' on:click={ toggleSelected }>
    <img src={option.selected ? "/images/checkbox.svg" : "/images/checkbox_empty.svg"} alt="Checkbox">
    <span>{option.value}</span>
</div>

<style>
    .option {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
        gap: 6px;
        padding: 6px 0;
    }

    div:hover {
        background-color: lightgray;
    }
    
    img {
        height: 16px;
        width: 16px;
    }
</style>