export function confirmCloseIfText(node) {

    const handleUnload = (ev) => {
        const text = node.value.trim();

        if (text !== '') {
            ev.preventDefault();
            ev.returnValue = '';
            return '';
        }
    }

    window.addEventListener('beforeunload', handleUnload);
}
