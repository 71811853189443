<script>
  export let value;
  export let placeholder;
</script>

<style>
  input {
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: var(--labit-darkgray);
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--labit-gray);
    background-color: transparent;
    padding: 0px;
    font-weight: bold;
  }

  input::placeholder {
    font-size: 12px;
    color: var(--labit-darkgray);
  }
</style>

<input type="text" bind:value {placeholder} />
