<script>
  export let active;
  export let isDashboard = false;
  export let outlookNotification = false;
  export let oldVersionNotification = false;
  let absencesNotif = false;
  let slackNotif = false;


  import MenuItem from "./MenuItem.svelte";
  import MenuItemProfile from "./MenuItemProfile.svelte";

  //import menuJSON from "../data/menu";
  import { menuJSON } from "../data/menu";
  import { getPicture, getPlatform } from "../js/localInfo";
  import { copyObject } from "../js/copyObject";
  import { randomString } from "../js/randomString";
  import { absencesNotification } from '../../src/js/stores';
  import { slackNotification } from "../../src/js/stores";
  import { getRole } from '../js/localInfo'

  import { onMount } from "svelte";

  let pic = "";

  let userRole = getRole();
  const platform = getPlatform();

  let menu = copyObject(menuJSON);

  absencesNotification.subscribe(value => {
    absencesNotif = value;
    console.log('absencesNotification value:', value);
  });

  slackNotification.subscribe(value => {
    slackNotif = value;
    console.log('slacknotification', value);
  })

  let setMenu = () => {
    //console.log("Nuevo Menu");
    menu = copyObject(menuJSON);
  };

  onMount(() => {
    pic = getPicture();
    if (platform === "APP") {
      window.addEventListener("focus", () => setMenu());
    }
  });

  $: isAdmin = userRole === 'Admin';
</script>

<div class="headerContainer">
  <div
    class="header"
    id="menuHeader"
    on:contextmenu={(ev) => {
      ev.stopPropagation();
    }}
  >
    <MenuItem
      src={isDashboard
        ? "/images/menu-icons/logo-labit.svg"
        : "/images/menu-icons/cloud.svg"}
      text="HOME"
      url="/dashboard"
      externalUrl={isDashboard ? "https://labit.es" : ""}
      type="logo"
      {active}
      requiresOutlook={true}
      {isDashboard}
    />
    <div class="menuDivider" />
    {#each menu as item, index}
      {#each item as subItem}
        <MenuItem
          src={subItem.icon + "?" + randomString(10)}
          text={subItem.text}
          url={subItem.url}
          externalUrl={subItem.externalUrl}
          type="item"
          active={active === subItem.url}
          notification={(subItem.url === "/slack" && slackNotif) ||
            (subItem.url === "/outlook" && outlookNotification) ||
            (subItem.url === "/process" && oldVersionNotification) || 
            (subItem.url === "/direction" && absencesNotif && isAdmin)} 
        />
      {/each}
      {#if index < menu.length - 1}
        <div class="menuDivider" />
      {/if}
    {/each}
    <MenuItemProfile src={pic} />
    <div id="menuShadow" class="shadow" />
  </div>
</div>

<style>
  div.headerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div.header {
    width: 100%;
    height: 70px;
    background-color: white;
    margin-top: 20px;
    border-radius: 19px;
    display: flex;
    justify-content: space-between;
    padding-left: 33px;
    position: relative;
  }

  div.menuDivider {
    width: 49px;
  }

  div.shadow {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: var(--labit-faded-div);
    opacity: 0.5;
    border-radius: 19px;
  }
</style>
