<script>
  export let visible;

  import { onMount } from "svelte";
  // Just in case you need user's rol and id
  import { getUserId } from "../../../../js/localInfo";
  // Just in case you need to access the database through the simplerest API
  import { post } from "../../../../js/labit-connection";

  //const moment = require('moment-timezone');
  import moment from 'moment-timezone';
  //import moment from 'moment';
  

  const APIKEY = "f9ce6a73748eb8c25b7a19bcefaf3b02";

  let myId = getUserId();
  let userName = "";
  let city = "";
  let weatherDataJSON = "";
  let iconSrc = "";
  let temp = "";
  let tempDescription = "";
  let iconMad = "";
  let iconBcn = "";
  let iconLon = "";
  let iconPar = "";
  let message = "";
  let madH;
  let bcnH;
  let lonH;
  let parH;

  let cityTimer = null;
  let weatherDataTimer = null;
  let weatherDataCityTimer = null;

  let backgroundImage = "";
  let mustBeWhite = false;
  const whiteIcons = ["01", "09", "10", "11"];

  let loading = true;

  const CITY_TIMER = 1000;
  const WEATHER_DATA_TIMER = 1800000;
  const WEATHER_DATA_CITIES = 1800000;

  const WEATHER_BACKGROUND_IMAGES_PATH = "/images/weather/backgrounds/";

  onMount(async () => {
    await getUserName();
    await getCity();
    loadInfo();
  });

  let getUserName = async () => {
    let resp = await post(
      `SELECT Nickname, Name
        FROM people
        WHERE contact_id = ` + myId
    );
    userName = resp[0].Nickname;
    if (userName === "" || !userName) {
      const tokens = resp[0].Name.split(" ");
      userName = tokens[0];
    }
  };

  let getCity = async () => {
    /*let resp = await post(
      `SELECT c.city
      FROM people AS p
      LEFT JOIN companies AS c ON p.company_id = c.company_id
      WHERE p.contact_id = ` + myId
    );
    city = resp[0].city;*/
    let resp = await post(
      `select City
        from people
        where contact_id=${myId}`
    );
    city = resp[0].City;
    if (city === "" || !city) {
      let resp = await post(
        `SELECT c.city
      FROM people AS p
      LEFT JOIN companies AS c ON p.company_id = c.company_id
      WHERE p.contact_id = ` + myId
      );
      city = resp[0].city;
      if (city === "" || !city) {
        city = "Madrid";
      }
    }
  };

  let getWeatherData = async () => {
    let response = await fetch(
      "https://api.openweathermap.org/data/2.5/weather?q=" +
        city +
        "&appid=" +
        APIKEY
    );
    weatherDataJSON = "";
    weatherDataJSON = await response.json();
    iconSrc = "/images/weather/" + weatherDataJSON.weather[0].icon + ".svg";
    /*       "https://openweathermap.org/img/wn/" +
      weatherDataJSON.weather[0].icon +
      "@4x.png"; */
    temp = Math.round((weatherDataJSON.main.temp - 273.15).toFixed(2));
    tempDescription =
      weatherDataJSON.weather[0].description[0].toUpperCase() +
      weatherDataJSON.weather[0].description.slice(1);
  };

  let getWeatherDataCities = async () => {
    let responseMad = await fetch(
      "https://api.openweathermap.org/data/2.5/weather?q=Madrid&appid=" + APIKEY
    );
    let weatherDataJSONMad = await responseMad.json();

    let responseBcn = await fetch(
      "https://api.openweathermap.org/data/2.5/weather?q=Barcelona&appid=" +
        APIKEY
    );
    let weatherDataJSONBcn = await responseBcn.json();

    let responseLon = await fetch(
      "https://api.openweathermap.org/data/2.5/weather?q=London&appid=" + APIKEY
    );
    let weatherDataJSONLon = await responseLon.json();

    let responsePar = await fetch(
      "https://api.openweathermap.org/data/2.5/weather?q=Paris&appid=" + APIKEY
    );
    let weatherDataJSONPar = await responsePar.json();

    iconMad = "/images/weather/" + weatherDataJSONMad.weather[0].icon + ".svg";
    iconBcn = "/images/weather/" + weatherDataJSONBcn.weather[0].icon + ".svg";
    iconLon = "/images/weather/" + weatherDataJSONLon.weather[0].icon + ".svg";
    iconPar = "/images/weather/" + weatherDataJSONPar.weather[0].icon + ".svg";
  };

  let welcomeMessage = () => {
    let hour = moment().hour();
    if (hour >= 0 && hour < 5) {
      message = "evening";
    } else if (hour >= 5 && hour < 12) {
      message = "morning";
    } else if (hour >= 12 && hour <= 17) {
      message = "afternoon";
    } else if (hour > 17 && hour <= 23) {
      message = "evening";
    }
  };

  let getCitiesTime = () => {
    madH = moment().tz("Europe/Madrid").format("HH:mm");
    bcnH = moment().tz("Europe/Madrid").format("HH:mm");
    lonH = moment().tz("Europe/London").format("HH:mm");
    parH = moment().tz("Europe/Paris").format("HH:mm");
    welcomeMessage();
  };

  let loadInfo = async () => {
    getCitiesTime();
    cityTimer = setInterval(getCitiesTime, CITY_TIMER);
    if (city !== "") {
      await getWeatherData();
      await getWeatherDataCities();
      weatherDataTimer = setInterval(getWeatherData, WEATHER_DATA_TIMER);
      weatherDataCityTimer = setInterval(
        getWeatherDataCities,
        WEATHER_DATA_CITIES
      );
    }
    loading = false;
  };

  let setBackground = () => {
    const tokens = iconSrc.split("/");
    const file = tokens[tokens.length - 1];
    const prefix = file.substring(0, 2);
    backgroundImage = WEATHER_BACKGROUND_IMAGES_PATH + prefix + ".png";
    const pos = whiteIcons.findIndex((item) => {
      return prefix === item;
    });
    mustBeWhite = pos >= 0;
  };

  $: if (iconSrc && iconSrc !== "") {
    setBackground();
  }

  /*let stopTimers = () => {
    if (cityTimer) {
      clearInterval(cityTimer);
    }
    if (weatherDataTimer) {
      clearInterval(weatherDataTimer);
    }
    if (weatherDataCityTimer) {
      clearInterval(weatherDataCityTimer);
    }
  };*/

  /*$: if (visible) {
    loadInfo();
  } else {
    stopTimers();
  }*/
</script>

<div
  class="weatherBox {mustBeWhite ? 'white' : ''}"
  style="background-image: url('{backgroundImage}'); background-position: center; background-repeat: no-repeat; background-size: cover;"
>
  {#if !loading}
    <div class="header">
      <span>Good {message},</span>
      <span class="userName">{userName}!</span>
    </div>
    <div class="weatherInfo">
      <img class="icon" src={iconSrc} alt="icon" />
      <span class="location">{city}</span>
      <span class="tempDescription">{tempDescription}</span>
      <span class="temp">{temp}°</span>
    </div>
    <div class="cities">
      {#if city !== "Madrid"}
        <div class="mad">
          <span>MAD</span>
          <span class="time">{madH}</span>
          <img class="iconCities" src={iconMad} alt="iconMad" />
        </div>
      {/if}
      {#if city !== "Barcelona"}
        <div class="bcn">
          <span>BCN</span>
          <span class="time">{bcnH}</span>
          <img class="iconCities" src={iconBcn} alt="iconBcn" />
        </div>
      {/if}
      {#if city !== "London"}
        <div class="lon">
          <span>LON</span>
          <span class="time">{lonH}</span>
          <img class="iconCities" src={iconLon} alt="iconLon" />
        </div>
      {/if}
      {#if city !== "Paris"}
        <div class="par">
          <span>PAR</span>
          <span class="time">{parH}</span>
          <img class="iconCities" src={iconPar} alt="iconPar" />
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  div.weatherBox {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
    color: var(--labit-card-gray);
    font-size: 18px;
    border-radius: 19px;
  }

  div.white {
    color: white;
  }

  div.white img {
    filter: grayscale(100%) brightness(100);
  }

  div.weatherBox div.header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 20%;
    margin: 10px 0 0 20px;
  }

  div.weatherBox div.weatherInfo {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.weatherBox div.weatherInfo img.icon {
    width: 70px;
  }

  div.weatherBox div.header span.userName {
    font-weight: bold;
  }

  div.weatherBox div.cities {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 40px 15px 40px;
    font-size: 13px;
  }

  div.mad,
  div.bcn,
  div.lon,
  div.par {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.weatherInfo span.temp {
    font-size: 41px;
  }

  div.weatherInfo span.location {
    font-size: 25px;
    font-weight: bold;
  }

  div.weatherInfo span.tempDescription {
    font-size: 13px;
  }

  img.iconCities {
    width: 33px;
    height: 33px;
  }
</style>
