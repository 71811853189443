<script>
  export let companyId;
  export let selectedValue;
  export let backgroundColor;

  import Combobox from "./Combobox.svelte";

  import { post } from "../../../../js/labit-connection";

  import { onMount } from "svelte";

  let people = [];

  onMount(async () => {
    await listPeople();
  });

  let listPeople = async () => {
    const response = await post(
      `select contact_id, Name, PictureUrl
        from people
        where company_id=${companyId}
        order by Name asc;`
    );
    people = response.map((item) => {
      return {
        value: item.contact_id,
        label: item.Name,
        image: item.PictureUrl,
      };
    });
  };
</script>

{#if people.length > 0}
  <Combobox bind:selectedValue options={people} {backgroundColor} />
{/if}
