import { getCalendar, saveCalendar } from "../modules/session/session";

export let loadCalendar = (idCalendar) => {
  let calendars = getCalendar();
  calendars.forEach((c) => {
    c.active = c.id === idCalendar ? true : false;
    c.menu = c.id === idCalendar ? true : false;
  });
  saveCalendar(calendars);
};
