<script>
  export let week;
  export let startDate;
  export let endDate;
  export let dayWidth;
  
  import moment from 'moment';

  let length = -1;
  let weekNumber = "";

  let calculateLength = () => {
    length = 7;
    let date = moment(week, "YYYY-MM-DD").locale("es");
    weekNumber = date.week();
    const firstDay = moment(week, "YYYY-MM-DD")
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    const lastDay = moment(week, "YYYY-MM-DD")
      .endOf("isoWeek")
      .format("YYYY-MM-DD");
    let d =
      moment(lastDay, "YYYY-MM-DD").diff(
        moment(startDate, "YYYY-MM-DD"),
        "days"
      ) + 1;
    if (d < 7) {
      length = d;
    }
    d =
      moment(endDate, "YYYY-MM-DD").diff(
        moment(firstDay, "YYYY-MM-DD"),
        "days"
      ) + 1;
    if (d < 7) {
      length = d;
    }
  };

  $: if (week && startDate && endDate) {
    calculateLength();
  }
</script>

{#if length >= 0}
  <div style="width: {dayWidth * length}px" class="borderBottom borderRight">
    {#if length >= 5}
      {#if parseInt(dayWidth) > 5}
        <span>
          Week {weekNumber}
        </span>
      {:else}
        <span>
          W{weekNumber}
        </span>
      {/if}
    {/if}
  </div>
{/if}

<style>
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    flex-shrink: 0;
    flex-grow: 0;
    height: 25px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
</style>
