<script>
  export let status;
  export let statusList;
  export let action;
  export let onRemove;
  export let onEdit;
  import moment from 'moment';

  import Switch from "./Switch.svelte";
  import Combobox from "./Combobox.svelte";
  import CalendarPicker from "./CalendarPicker/CalendarPicker.svelte";

  let editing = false;
  let selectedStatus = null;
  let selectedStatusCalendar1 = moment().format("DD/MM/YYYY");
  let selectedStatusCalendar2 = moment().format("DD/MM/YYYY");
  let selectedStatusCheck = true;

  let editStatus = () => {
    editing = true;
    selectedStatus = statusList.find((item) => {
      return item.value.id === status.statusId;
    });
    selectedStatusCalendar1 = moment(status.start).format("DD/MM/YYYY");
    selectedStatusCalendar2 = status.end
      ? moment(status.end).format("DD/MM/YYYY")
      : "Sin fecha final";
  };

  let saveStatus = () => {
    status.name = selectedStatus.label;
    status.statusId = selectedStatus.value.id;
    status.start = moment(selectedStatusCalendar1, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    status.end =
      selectedStatusCalendar2 === "Sin fecha final"
        ? null
        : moment(selectedStatusCalendar2, "DD/MM/YYYY").format("YYYY-MM-DD");
    status.modified = true;
    status.active = selectedStatus.value.active;
    status = status;
    editing = false;
    onEdit();
  };

  let changeStatus = () => {
    selectedStatusCheck = selectedStatus.value.active;
  };
</script>

<div class="dialogProjectStatusHistoryContainer">
  <div class="row">
    {#if editing}
      <div class="editStatus">
        <div class="statusCombobox">
          <Combobox
            options={statusList}
            placeholder=""
            bind:selectedValue={selectedStatus}
            onChange={() => changeStatus()}
          />
        </div>
        <div class="statusCombobox">
          <CalendarPicker bind:date={selectedStatusCalendar1} options={[]} />
        </div>
        <div class="statusCombobox">
          <CalendarPicker
            bind:date={selectedStatusCalendar2}
            options={["Sin fecha final"]}
          />
        </div>
        <div class="statusCheck">
          <Switch
            width="24"
            height="12"
            checked={selectedStatusCheck}
            onClick={null}
            readonly={true}
          />
        </div>
        <div class="statusOptions">
          <img
            class="fadedButton"
            src="/images/save.svg"
            alt="Save"
            on:click={() => saveStatus()}
          />
          <img
            class="fadedButton"
            src="/images/x.svg"
            alt="Cancel"
            on:click={() => {
              editing = false;
            }}
          />
        </div>
      </div>
    {:else}
      <div class="statusName {action !== 'show' ? 'edit' : ''}">
        <span>{status.name}</span>
      </div>
      <div class="statusDate">
        <span>{moment(status.start, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
      </div>
      <div class="statusDate">
        <span>
          {status.end
            ? moment(status.end, "YYYY-MM-DD").format("DD/MM/YYYY")
            : ""}
        </span>
      </div>
      <!--<div class="statusActive">
        <Switch
          width="24"
          height="12"
          checked={status.active}
          onClick={null}
          readonly={true}
        />
      </div>-->
      <!--{#if action !== "show"}
        <div class="options">
          <img
            class="fadedButton"
            src="/images/edit.svg"
            alt="Edit"
            on:click={() => editStatus()}
          />-
          <img
            class="fadedButton"
            src="/images/trash.svg"
            alt="Remove"
            on:click={onRemove}
          />
        </div>
      {/if}-->
    {/if}
  </div>
</div>

<style>
  div.dialogProjectStatusHistoryContainer {
    width: 100%;
    display: flex;
    margin-bottom: 8px;
    font-size: 13px;
    color: var(--labit-card-lightgray);
  }

  div.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.statusName {
    display: flex;
    width: 45%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    align-items: center;
  }

  div.statusDate {
    display: flex;
    width: 25%;
    justify-content: center;
    align-items: center;
  }

  /*div.statusActive {
    display: flex;
    width: 10%;
    justify-content: flex-end;
    align-items: center;
  }*/

  div.options {
    display: flex;
    width: 15%;
    justify-content: flex-end;
    align-items: center;
  }

  div.statusName.edit {
    width: 30%;
  }

  div.options img {
    height: 15px;
    cursor: pointer;
  }

  div.options img:not(:first-child) {
    margin-left: 3px;
  }

  div.editStatus {
    display: flex;
  }

  div.statusCombobox {
    width: 25%;
    height: 17px;
    padding-right: 5px;
  }

  div.statusCheck {
    width: 10%;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.statusOptions {
    width: 15%;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div.statusOptions img {
    width: 15px;
    cursor: pointer;
    margin-bottom: 0px;
  }

  div.statusOptions img:not(:first-child) {
    margin-left: 3px;
  }
</style>
