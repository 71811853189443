export let RDSCreateConflictTable = (keys, dynamo) => {
  // Conflict table

  let conflictTable = [];
  const numAttributes = keys.reduce((acc, curr) => {
    return acc + curr.categories.length;
  }, 0);
  dynamo.forEach(() => {
    let row = [];
    for (let i = 0; i < numAttributes; i++) {
      row.push([]);
    }
    conflictTable.push(row);
  });

  return conflictTable;
};

export let RDSAddRoomLevel = (keys) => {
  keys[0].categories.unshift({
    id: -1,
    name: "Level",
    keys: [],
    sequence: -1,
  });
  return keys;
};

export let RDSEmptyTable = (dynamo, conflictTable) => {
  dynamo.forEach((item, index) => {
    conflictTable[index][0] = {
      type: "text",
      status: "",
      value: dynamo[index][0],
    };
    conflictTable[index][1] = {
      type: "text",
      status: "",
      value: dynamo[index][1],
    };
  });
  return conflictTable;
};

let getAttributeWithSequence = (keys, sequence) => {
  let attribute = null;
  let epigraph = "";
  let pos = -1;
  let found = false;
  let counter = 0;
  for (let i = 0; i < keys.length && !found; i++) {
    const key = keys[i];
    for (let j = 0; j < key.categories.length && !found; j++) {
      const category = key.categories[j];
      const seq = parseInt(category.sequence);
      if (seq !== 0 && seq === sequence) {
        found = true;
        attribute = category;
        pos = counter;
        epigraph = key.id;
      }
      counter++;
    }
  }
  return { attribute, pos, epigraph };
};

let getAttributeWithId = (keys, id) => {
  let attribute = null;
  let found = false;
  for (let i = 0; i < keys.length && !found; i++) {
    const key = keys[i];
    for (let j = 0; j < key.categories.length && !found; j++) {
      const category = key.categories[j];
      if (category.id === id) {
        found = true;
        attribute = category;
      }
    }
  }
  return attribute;
};

let getAttributeWithName = (keys, name) => {
  let attribute = null;
  let found = false;
  for (let i = 0; i < keys.length && !found; i++) {
    const key = keys[i];
    for (let j = 0; j < key.categories.length && !found; j++) {
      const category = key.categories[j];
      if (category.name.trim().toUpperCase() === name.trim().toUpperCase()) {
        found = true;
        attribute = category;
      }
    }
  }
  return attribute;
};

let getNumberFromString = (string) => {
  //const token = string.match(/\d+/);
  string = string.trim().replace(/,/, ".");
  var regex = /[+-]?\d+(\.\d+)?/g;
  var s = string.match(regex);
  if (s) {
    var token = s.map(function (v) {
      return parseFloat(v);
    });

    const thenum = token ? token[0] : "-1";
    return thenum;
  } else {
    return "";
  }
};

let getNumbersFromString = (string) => {
  //const token = string.match(/\d+/g);
  string = string.trim().replace(/,/, ".");
  var regex = /[+-]?\d+(\.\d+)?/g;
  var s = string.match(regex);
  if (s) {
    var token = s.map(function (v) {
      return parseFloat(v);
    });

    const thenums = token ? token : [];
    return thenums;
  } else {
    return [];
  }
};

let compareExact = (a, b) => {
  return a.trim().toUpperCase() === b.trim().toUpperCase();
};

let compareBeginning = (a, b) => {
  let ok = false;
  const A = a.trim().toUpperCase();
  const B = b.trim().toUpperCase();

  // Codes
  let codeA = "";
  let codeB = "";
  const posCodeA = A.search(":");
  if (posCodeA >= 0) {
    codeA = A.substring(0, posCodeA);
  }
  const posCodeB = B.search(":");
  if (posCodeB >= 0) {
    codeB = B.substring(0, posCodeB);
  }

  if (codeA !== "") {
    if (B.startsWith(codeA)) {
      ok = true;
    }
  } else {
    if (codeB !== "") {
      if (A.startsWith(codeB)) {
        ok = true;
      }
    } else {
      // Comparison with no codes

      /*console.log("ENTRA");
      console.log(A);
      console.log(B);
      console.log(A.startsWith(B));*/

      ok = A.startsWith(B);
      if (!ok) {
        ok = B.startsWith(A);
        if (!ok) {
          let C = A.substring(0, A.length - 1);
          while (C.length >= 4 && !ok) {
            ok = B.startsWith(C);
            C = C.substring(0, C.length - 1);
          }
        }
      }
    }
  }

  return ok;
};

let compareBeginningNumbers = (a, b) => {
  let ok = false;
  const aN = getNumberFromString(a);
  const bN = getNumberFromString(b);
  if (aN !== "" && bN !== "") {
    if (a.trim().startsWith(aN) && b.trim().startsWith(bN)) {
      ok = a.trim().startsWith(bN);
    }
  }
  return ok;
};

let removeTypeExceptions = (compAttribute, typeExceptions, dynamoRow) => {
  let newCompAttribute = compAttribute.filter((item) => {
    const pos = typeExceptions.findIndex((it) => {
      return (
        it.typeId === item.id &&
        it.dynamoRow === dynamoRow &&
        it.type === "exception"
      );
    });
    return pos === -1;
  });
  return newCompAttribute;
};

let getTypeNameToCompare = (type) => {
  const name =
    type.exception && type.exception.type === "replace"
      ? type.exception.replacingValue
      : type.name;
  return name;
};

let splitByQuantity = (fullString) => {
  const string = fullString.trim();
  let result = [];
  let foundChar = false;
  let foundCharPos = -1;
  let i = 0;
  while (!foundChar && i < string.length) {
    const character = string[i];
    if ((character < "0" || character > "9") && character !== " ") {
      foundChar = true;
      foundCharPos = i;
    }
    i++;
  }
  if (foundChar && foundCharPos > 0) {
    const token1 = string.substring(0, foundCharPos);
    const token2 = string.substring(foundCharPos + 1, string.length);
    result = [token1.trim(), token2.trim()];
  } else {
    result = [string];
  }
  return result;
};

let compareDynamoPosWithAttribute = (
  dynamo,
  dynamoRow,
  dynamoPos,
  attribute,
  epigraphName,
  occupancyAttribute
) => {
  let conflicts = [];
  let compDynamo = JSON.parse(JSON.stringify(dynamo[dynamoRow][dynamoPos]));
  let compAttribute = JSON.parse(JSON.stringify(attribute.keys));
  /*console.log(attribute.name);
  console.log(compAttribute);
  console.log(compDynamo);
  console.log(dynamoRow);
  console.log(dynamoPos);*/
  if (Array.isArray(compDynamo)) {
    compDynamo.forEach((d, index) => {
      let sol = null;
      let pos = -1;
      let ignoreFoundAttribute = false;

      /* Comparison table */
      switch (attribute.name.toUpperCase()) {
        case "OCCUPANCY":
        case "DIMENSIONS":
          const numberD = getNumberFromString(d);
          pos = compAttribute.findIndex((type) => {
            const typeName = getTypeNameToCompare(type);
            const tokens = typeName.split("to");
            if (tokens.length === 2) {
              let a = getNumberFromString(tokens[0]);
              let b = getNumberFromString(tokens[1]);
              if (a > b) {
                let aux = a;
                a = b;
                b = aux;
              }
              return numberD >= a && numberD <= b;
            } else {
              let a = getNumberFromString(typeName);
              return a === numberD;
            }
          });
          break;
        case "AV (DISPLAYS)":
          pos = compAttribute.findIndex((type) => {
            const typeName = getTypeNameToCompare(type);
            return compareBeginningNumbers(typeName, d);
          });
          if (pos === -1) {
            pos = compAttribute.findIndex((type) => {
              let ok = false;
              const typeName = getTypeNameToCompare(type);
              const A = typeName.trim().toUpperCase();
              const B = d.trim().toUpperCase();
              ok =
                (A === "NODATA" && B === "NOT APPLICABLE") ||
                (A === "NOT APPLICABLE" && B === "NODATA");

              return ok;
            });
          }
          break;
        default:
          pos = compAttribute.findIndex((type) => {
            let ok = false;
            const typeName = getTypeNameToCompare(type);
            if (typeName.trim().endsWith("[=]")) {
              const a = typeName.trim().replace(/\[=\]/, "");
              ok = compareExact(a, d);
            }
            if (!ok) {
              ok = compareBeginning(typeName, d);
            }
            if (!ok) {
              const A = typeName.trim().toUpperCase();
              const B = d.trim().toUpperCase();
              ok =
                (A === "NODATA" && B === "NOT APPLICABLE") ||
                (A === "NOT APPLICABLE" && B === "NODATA");
            }
            return ok;
          });
          if (pos === -1) {
            // If default comparison does not work

            if (attribute.name.toUpperCase() === "REVIT FAMILY NAME") {
              ignoreFoundAttribute = true;

              // Compare string with no quantity nx

              const tokens = splitByQuantity(d);
              if (tokens.length == 2) {
                const quantity = getNumberFromString(tokens[0]);
                const text = tokens[1];
                pos = compAttribute.findIndex((type) => {
                  let ok = false;
                  const typeName = getTypeNameToCompare(type);
                  if (typeName.trim().endsWith("[=]")) {
                    const a = typeName.trim().replace(/\[=\]/, "");
                    ok = compareExact(a, text);
                  }
                  if (!ok) {
                    ok = compareBeginning(typeName, text);
                  }
                  if (!ok) {
                    const A = typeName.trim().toUpperCase();
                    const B = text.trim().toUpperCase();
                    ok =
                      (A === "NODATA" && B === "NOT APPLICABLE") ||
                      (A === "NOT APPLICABLE" && B === "NODATA");
                  }
                  return ok;
                });
                if (pos >= 0) {
                  // It also has to meet the occupancy
                  //console.log({ occupancyAttribute });
                  if (occupancyAttribute && occupancyAttribute.keys) {
                    compAttribute = JSON.parse(
                      JSON.stringify(occupancyAttribute.keys)
                    );
                    const numberD = quantity;
                    pos = compAttribute.findIndex((type) => {
                      const typeName = getTypeNameToCompare(type);
                      const tokens = typeName.split("to");
                      if (tokens.length === 2) {
                        let a = getNumberFromString(tokens[0]);
                        let b = getNumberFromString(tokens[1]);
                        if (a > b) {
                          let aux = a;
                          a = b;
                          b = aux;
                        }
                        return numberD >= a && numberD <= b;
                      } else {
                        let a = getNumberFromString(typeName);
                        return a === numberD;
                      }
                    });
                    compAttribute = [];
                  }
                }
              }
            }
            if (attribute.name.toUpperCase() === "FURNITURE") {
              const tokens = splitByQuantity(d);
              if (tokens.length == 2) {
                const dString = tokens[1];
                pos = compAttribute.findIndex((type) => {
                  let ok = false;
                  const typeName = getTypeNameToCompare(type);
                  if (typeName.trim().endsWith("[=]")) {
                    const a = typeName.trim().replace(/\[=\]/, "");
                    ok = compareExact(a, dString);
                  }
                  if (!ok) {
                    ok = compareBeginning(typeName, dString);
                  }
                  if (!ok) {
                    const A = typeName.trim().toUpperCase();
                    const B = dString.trim().toUpperCase();
                    ok =
                      (A === "NODATA" && B === "NOT APPLICABLE") ||
                      (A === "NOT APPLICABLE" && B === "NODATA");
                  }
                  return ok;
                });
              }
            }
          }
          break;
      }
      if (pos === -1) {
        sol = {
          typeId: -1,
          attributeId: attribute.id,
          dynamoId: {
            x: dynamoRow,
            y: dynamoPos,
            z: index,
          },
          text: d,
          status: "notExpected",
          exception: null,
          epigraph: epigraphName,
        };
      } else {
        if (ignoreFoundAttribute) {
          sol = {
            typeId: -1,
            attributeId: attribute.id,
            dynamoId: {
              x: dynamoRow,
              y: dynamoPos,
              z: index,
            },
            text: d,
            status: "hidden",
            exception: null,
            epigraph: epigraphName,
          };
        } else {
          sol = {
            typeId: compAttribute[pos].id,
            attributeId: attribute.id,
            dynamoId: {
              x: dynamoRow,
              y: dynamoPos,
              z: index,
            },
            text: d,
            status: "found",
            exception: null,
            epigraph: epigraphName,
          };
        }
        compAttribute.splice(pos, 1);
      }
      /*console.log(conflictTable);
      console.log(i);
      console.log(j);*/
      conflicts.push(sol);
    });
  }
  compAttribute.forEach((a) => {
    const sol = {
      typeId: a.id,
      attributeId: attribute.id,
      dynamoId: {
        x: -1,
        y: -1,
        z: -1,
      },
      text: a.name,
      status: "missing",
      exception: null,
      epigraph: epigraphName,
    };
    conflicts.push(sol);
  });
  /*console.log("Comparando...");
    console.log(compDynamo);
    console.log(compAttribute);*/
  return conflicts;
};

export let RDSFillTable = (keys, dynamo, conflictTable) => {
  // Get the results of comparing every attribute x every dynamo input

  const occupancyAttribute = getAttributeWithName(keys, "OCCUPANCY");

  dynamo.forEach((dynamoItem, i) => {
    dynamoItem.forEach((item, j) => {
      const response = getAttributeWithSequence(keys, j);
      const attribute = response.attribute;
      const pos = response.pos;
      const epigraph = response.epigraph;
      if (
        attribute &&
        attribute.name.toUpperCase() !== "LEVEL" &&
        !attribute.name.toUpperCase().startsWith("ROOM NUMBER")
      ) {
        // Get Occupancy keys since they are needed in some cells

        const conflicts = compareDynamoPosWithAttribute(
          dynamo,
          i,
          j,
          attribute,
          epigraph,
          occupancyAttribute
        );
        conflictTable[i][pos] = {
          type: "conflict",
          status: "",
          conflicts,
        };
      }
    });
  });

  //console.log(conflictTable);
  return conflictTable;
};

export let RDSUpdateTable = (keys, conflictTable) => {
  // Fill the table with a decision for every cell

  //console.log({ keys });

  const fixRoomNumber =
    keys &&
    keys[0] &&
    keys[0].categories &&
    keys[0].categories[0].name.toUpperCase() === "LEVEL" &&
    keys[0].categories[1].name.toUpperCase("ROOM NUMBERING");

  conflictTable.forEach((row) => {
    // Special columns

    if (fixRoomNumber) {
      const numbers = getNumbersFromString(row[0].value[0]);
      if (numbers.length > 0) {
        const number = numbers[numbers.length - 1];
        if (row[1].value) {
          const roomNumbering = row[1].value[0].trim();
          const tokens = roomNumbering.split("to");
          if (tokens.length === 2) {
            let a = parseInt(tokens[0].trim());
            let b = parseInt(tokens[1].trim());
            if (a > b) {
              let aux = a;
              a = b;
              b = aux;
            }
            if (number < a || number > b) {
              row[1].status = "noOk";
            }
          }
        } else {
          row[1].status = "noOk";
        }
      }
    }

    // Rest of columns

    row.forEach((column) => {
      if (column.type === "conflict") {
        let hasRed = false;
        let hasYellow = false;

        // Special case with only one attribute [OPTIONAL]

        const numMissing = column.conflicts.reduce((acc, curr) => {
          if (curr.status === "missing" && !curr.exception) {
            acc++;
          }
          return acc;
        }, 0);
        const numMissingAttrOptional = column.conflicts.reduce((acc, curr) => {
          if (
            curr.status === "missing" &&
            curr.text.trim().endsWith("[OPTIONAL]") &&
            !curr.exception
          ) {
            acc++;
          }
          return acc;
        }, 0);

        if (numMissing > 0 && numMissing === numMissingAttrOptional) {
          column.status = "ok";
        } else {
          // Default comparison

          const numMissingNoOptional = column.conflicts.reduce((acc, curr) => {
            if (
              curr.status === "missing" &&
              !curr.text.trim().endsWith("[OR]") &&
              !curr.exception
            ) {
              acc++;
            }
            return acc;
          }, 0);
          const numNotExpected = column.conflicts.reduce((acc, curr) => {
            if (curr.status === "notExpected" && !curr.exception) {
              acc++;
            }
            return acc;
          }, 0);
          const numExceptions = column.conflicts.reduce((acc, curr) => {
            if (curr.exception) {
              acc++;
            }
            return acc;
          }, 0);
          hasRed = numMissingNoOptional > 0 || numNotExpected > 0;
          hasYellow = numExceptions > 0;
          let columnStatus = "ok";
          if (hasRed) {
            columnStatus = "noOk";
          } else {
            if (hasYellow) {
              columnStatus = "exceptions";
            }
          }
          column.status = columnStatus;
        }
      }
    });
  });

  return conflictTable;
};

export let RDSAddExceptions = (conflictTable, exceptions) => {
  /*console.log({ conflictTable });
  console.log({ exceptions });*/
  exceptions.forEach((exception) => {
    //console.log({ exception });
    const roomNumbering = exception.roomNumbering;
    const pos = conflictTable.findIndex((row) => {
      if (row[1]) {
        return row[1].value[0] === roomNumbering;
      } else {
        return false;
      }
    });
    if (pos >= 0) {
      exception.posTableRow = pos;
      if (
        conflictTable[exception.posTableRow][exception.posTableColumn]
          .conflicts[exception.posTableConflict]
      ) {
        conflictTable[exception.posTableRow][
          exception.posTableColumn
        ].conflicts[exception.posTableConflict].exception = {
          id: exception.id,
          type: exception.type,
          description: exception.description,
          replacingValue: exception.replacingValue,
          typeId: exception.typeId,
          attributeId: exception.attributeId,
          text: exception.text,
        };
      }
    }
  });
  return conflictTable;
};
