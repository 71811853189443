<script>
    import { fly } from "svelte/transition";

    export let show = false;

    let visible = show;
    const toggleVisibility = () => (visible = !visible);
</script>

<div class="container">
    <div class="header" on:click={toggleVisibility}>
        <slot name="header" />
    </div>
    {#if visible}
        <div class="body" transition:fly={{ y: -40, duration: 150 }}>
            <slot name="body" />
        </div>
    {/if}
</div>

<style>
    .container {
        background-color: var(--bgColor);
        border-radius: 19px;
        border-bottom: 1px solid #ededed;
    }

    .header {
        background-color: var(--bgColor);
        border-radius: 19px;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid #ededed;
        padding: 8px;
    }

    .body {
        padding: 2rem;
    }
</style>
