<script>
    export let text;
    export let color;
</script>

<div class="legend flex">                
    <svg height="16" width="16">
        <circle cx="8" cy="8" r="6" stroke="black" stroke-width="0" fill="{color}" />
        Sorry, your browser does not support inline SVG.  
    </svg> 
    <span>{text}</span>
</div>

<style>

.legend {
    text-align: center;
}

.legend > svg {
    width: 16px;
    height: 16px;
    display: block;
    margin: auto;
    margin-right: 6px;
}

.flex {
    display: flex;
}

</style>