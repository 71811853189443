<script>
  export let type; // red, yellow, green
  export let size;
  export let onClick = null;
  export let marginLeft = 0;
  export let marginRight = 0;
  export let strong = false;
  export let light = false;
  export let text = "";
</script>

<style>
  div.lightContainer {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: white;
    font-weight: bold;
  }

  div.clickable {
    cursor: pointer;
  }

  div.red {
    background-color: var(--labit-light-red);
  }

  div.green {
    background-color: var(--labit-light-green);
  }

  div.yellow {
    background-color: var(--labit-light-yellow);
  }

  div.red.strong {
    background-color: var(--labit-light-red-strong);
  }

  div.green.strong {
    background-color: var(--labit-light-green-strong);
  }

  div.yellow.strong {
    background-color: var(--labit-light-yellow-strong);
  }

  div.light {
    opacity: 0.3;
  }
</style>

<div
  class="lightContainer {onClick ? 'clickable' : ''}
  {type}
  {strong ? 'strong' : ''}
  {light ? 'light' : ''}"
  on:click={onClick}
  style="width: {size}; height: {size}; margin-left: {marginLeft}; margin-right:
  {marginRight};">
  {#if text !== ''}
    <span>{text}</span>
  {/if}
</div>
