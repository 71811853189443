<script>
  export let questId;
  export let answerId;
  export let title;
  export let people;
  export let answered;
  export let isRead;
  export let onResend;
  import moment from 'moment';

  import Picture from "./Picture.svelte";

  let backgroundColor = "white";

  let setBackgroundColor = () => {
    if (answered) {
      backgroundColor = "var(--labit-card-gray)";
    } else {
      if (isRead) {
        backgroundColor = "var(--labit-rds-operate)";
      } else {
        backgroundColor = "var(--labit-light-red)";
      }
    }
  };

  $: if (title) {
    setBackgroundColor();
  }
</script>

<div class="HOTPPeopleContainer">
  <div class="row">
    <div class="people">
      <div class="pic">
        <Picture image={people.pic} name={people.name} />
      </div>
      <span>{people.name}</span>
    </div>
    <div class="status">
      <div class="answer" style="background-color: {backgroundColor}">
        <span>{title}</span>
      </div>
    </div>
    <div class="resend">
      <span class="fadedButton" on:click={() => onResend(questId, answerId)}
        >RESEND</span
      >
    </div>
  </div>
  {#if answered}
    <div class="answerDate">
      <span
        >{moment(people.date, "YYYY-MM-DD HH:mm:ss").format(
          "DD/MM/YYYY HH:mm:ss"
        )}</span
      >
    </div>
  {/if}
</div>

<style>
  div.HOTPPeopleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: var(--labit-card-gray);
    font-weight: bold;
    margin-bottom: 7px;
  }

  div.row {
    width: 100%;
    display: flex;
    align-items: center;
  }

  div.people {
    width: 53%;
    display: flex;
    align-items: center;
  }

  div.status {
    width: 31%;
  }

  div.resend {
    width: 16%;
    display: flex;
    justify-content: flex-end;
  }

  div.people div.pic {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  div.answer {
    display: flex;
    width: 116px;
    height: 18px;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 10px;
  }

  div.answerDate {
    width: 100%;
    font-weight: normal;
    font-size: 10px;
    padding-left: 53%;
    display: flex;
  }

  div.answerDate span {
    width: 116px;
    text-align: center;
    margin-top: -10px;
  }
</style>
