<script>
  export let title;
  export let icon;
  export let url;
  export let onClick = null;
  export let onRightClick = null;
  export let width = 'auto';
  export let notification;

  import { openNewWindow } from "../../js/openNewWindow";
  import { openURL } from "../../js/openURL";

  import DashboardTitle from "./DashboardTitle.svelte";

  let clickIcon = (forceNewWindow) => {
    if (forceNewWindow) {
      openNewWindow("/skylab-main" + url);
    } else {
      openURL(url, true);
    }
  };
</script>

<div
  class="dashboardContainer"
  on:click={() => {
    if (onClick) {
      onClick();
    } else {
      clickIcon(false);
    }
  }}
  on:contextmenu={(ev) => {
    ev.preventDefault();
    if (onRightClick) {
      onRightClick();
    } else {
      clickIcon(true);
    }
    return false;
  }}
>
  <DashboardTitle {title} numEvents={0} />
  <div class="dashboardBody">
    <img src={icon} alt="Dashboard Icon" style="width: {width}"/>
  </div>
  {#if notification}
    <div class="not">
      <div class="notification"></div>
    </div>
  {/if}
</div>

<style>
  div.dashboardContainer {
    width: 100%;
    height: 100%;
    position: relative;
  }

  div.dashboardBody {
    width: 100%;
    height: 85%;
    padding: 5%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.dashboardBody img {
    max-height: 40%;
    max-width: 70%;
  }

  div.not{
    position: absolute;
    top: 0;
    right: -5px;
  }

  div.notification {
    width: 14px;
    height: 14px;
    background-color: red;
    border-radius: 50%;
  }

  /* Animations */

  div.dashboardBody img {
    transition: opacity 0.5s linear 0s;
  }

  div.dashboardBody:hover img {
    opacity: 0.5;
  }
</style>
