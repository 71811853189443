<script>
    import { getContext } from "svelte";
    import Legend from "./Legend.svelte";
    import RangeDatePicker from "./RangeDatePicker.svelte";

    const { close } = getContext("simple-modal");

    import { createEventDispatcher } from "svelte";
    import moment from "moment";

    export let mode = "";
    export let action = "add";
    export let options = {};
    export let absenceTypes;
    export let myId;
    export let events = [];
    export let absenceFn = () => {};

    const dispatch = createEventDispatcher();

    let absence = {
        id: "",
        start: 0,
        end: 0,
        type: 0,
        description: "",
        half: false,
        userId: myId,
        action: "",
    };

    let errors = {
        absenceType: false,
        description: false,
        period: false,
    };

    const { event, detail } = options;
    let s, e;
    if (event) {
        const { start, startDate, date, end, endDate } = event;
        s = start || startDate || date;
        e = end || endDate || date;
    } else if (detail) {
        const { start, startDate, date, end, endDate } = detail;
        s = start || startDate || date;
        e = end || endDate || date;
    } else {
        s = new Date();
        e = s;
    }

    const start = (s instanceof Date) ? s.getTime() : parseInt(s.format("x"));
    const end = (e instanceof Date) ? e.getTime() : parseInt(e.format("x"));

    events = events.filter((e) => !(e.start.getTime() === start && e.end.getTime() === end));

    absence.start = start;
    absence.end = start === end ? parseInt(moment(end, 'x').endOf('day').format('x')) : end;
    if (action === "edit") {
        mode = absence.start === absence.end ? "day" : "range";

        absence.id = event.id;
        absence.type = event.absenceType;
        absence.description = event.description;
        absence.half = event.half === "1";
    }

    const checkDate = (start, end) => {
        start = moment(start, "x");
        end = moment(end, "x");

        if (start.isBefore(end)) {
          let arr = [];
          while (start.isSameOrBefore(end)) {
              arr.push(start.toDate());
              start.add(1, "days");
          }
          
          const now = new Date();
          return arr.some((e) => events.some((f) => f.start <= e && f.end >= e) || e < now );
        }
        return true;
    };

    const checkValues = (action) => {
        absence.action = action;
        if (action === "delete") {
            absenceFn(absence);
            closeModal();
        } else {
            absence.description = absence.description.trim();
            errors.absenceType = absence.type === 0;
            errors.description = absence.description === "";

            const periodError = checkDate(absence.start, absence.end);
            errors.period =
                absence.start === 0 ||
                absence.end === 0 ||
                periodError;

            if (!errors.absenceType && !errors.description && !errors.period) {
                absenceFn(absence);
                closeModal();
            }
        }
    };

    const closeModal = () => {
        dispatch("close");
        close();
    };
    const toggleHalfDay = () => (absence.half = !absence.half);

    const showButton =
        mode === "" ||
        mode === "day" ||
        (action === "edit" && absence.start === absence.end);
</script>

<!-- <ClickOutside on:clickoutside={closeModal} exclude={[exclude]}> -->
<div class="container">
    <div id="btnContainer" class="flex">
        <img
            id="closeModalWindow"
            src="/images/x.svg"
            alt="Quit"
            on:click={closeModal}
        />
    </div>
    <div id="absenceType" class:error={errors.absenceType}>
        <!-- {#each absences as absence} -->
        {#each absenceTypes as { id, name, color, approvable }}
            <div class="absence {absence.type === id ? 'selected' : ''}">
                <label id="absenceLabel">
                    <input
                        id="absenceInput"
                        type="radio"
                        bind:group={absence.type}
                        name="absenceTypes"
                        value={id}
                    />
                    <Legend text={name} {color} />
                    <span class="grayColor"
                        >{approvable === "1" ? "Must be approved" : ""}</span
                    >
                </label>
            </div>
        {/each}
    </div>
    <div id="descriptionContainer" class="flex">
        <span class="grayColor">Description</span>
        <textarea
            class:error={errors.description}
            bind:value={absence.description}
        />
    </div>
    <div
        id="dateSelectorContainer"
        style={mode === "range" ? "justify-content: end" : ""}
    >
        {#if showButton}
            <button class:halfDay={!absence.half} on:click={toggleHalfDay}
                >1/2 Day</button
            >
        {/if}
        <RangeDatePicker
            error={errors.period}
            half={absence.half}
            {events}
            start={absence.start}
            end={absence.end}
            on:close={(ev) => {
                absence.start = ev.detail.start;
                absence.end = ev.detail.end;
            }}
        />
    </div>
    <div
        id="askAbsenceBtnContainer"
        class="flex"
        style={action === "edit" ? "justify-content: space-between" : ""}
    >
        {#if action === "edit"}
            <button
                class="askAbsence"
                style="background-color:#ff00009e;"
                on:click={() => checkValues("delete")}>Remove absence!</button
            >
            <button class="askAbsence" on:click={() => checkValues("edit")}
                >Edit absence!</button
            >
        {:else}
            <button class="askAbsence" on:click={() => checkValues("add")}
                >Ask absence!</button
            >
        {/if}
    </div>
</div>

<!-- </ClickOutside> -->
<style>
    .error {
        border: 1px solid red !important;
        border-radius: 8px !important;
    }

    .container {
        width: 100%;
        border: 1px solid #cdcdcd;
        border-radius: 19px;
        padding: 8px 24px;
    }

    #btnContainer {
        width: 100%;
        justify-content: flex-end;
    }

    #closeModalWindow {
        width: 40px;
        padding: 10px;
        cursor: pointer;
    }

    #absenceType {
        width: 100%;
        padding: 12px;
    }

    #descriptionContainer {
        flex-direction: column;
        padding: 8px;
    }

    .absence {
        width: 100%;
        height: 26px;
        padding: 0 20px;
        border-radius: 19px;
    }

    #absenceLabel {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #absenceInput {
        display: none;
    }

    .absence:hover,
    .selected {
        background-color: #518bc51c;
    }

    .grayColor {
        color: #b7b7b7;
    }

    div#dateSelectorContainer {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
    }

    div#dateSelectorContainer > button {
        border: 1px solid #e1e1e1;
        border-radius: 40px;
        margin: 0px;
    }

    textarea {
        height: 140px;
        border: none;
        border-bottom: 1px solid #cfcfcf;
    }

    .flex {
        display: flex;
    }

    .halfDay {
        color: gray;
        background-color: white;
    }

    #askAbsenceBtnContainer {
        justify-content: end;
        padding: 16px 0;
    }

    .askAbsence {
        margin: 0;
        border-radius: 40px;
        border: none;
        cursor: pointer;
        background-color: #a7e521;
        color: white;
    }
</style>
