<script>
  export let tool;
  export let onClick;
  export let editMode;
  export let onRemove;
  export let onClickEdit;

  import { LABIT_PUBLIC_FOLDER } from "../js/labit-connection";
  import { randomString } from "../js/randomString";

  let icon = "";

  $: if (tool && tool.custom) {
    icon = LABIT_PUBLIC_FOLDER + tool.icon + "?" + randomString(10);
  }
</script>

<style>
  div.menuToolContainer {
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    margin-right: 38px;
    position: relative;
  }

  img.tool {
    height: 45%;
  }

  img.arrow {
    height: 20%;
    position: absolute;
    bottom: 20%;
    right: -10px;
  }

  img.custom {
    border-radius: 5px;
    filter: sepia(100%) hue-rotate(165deg) saturate(100%);
  }

  /* Animations */

  img {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  img:hover {
    opacity: 0.5;
  }
</style>

<div
  class="menuToolContainer"
  title={tool.name}
  on:click={() => {
    if (editMode && tool.custom) {
      onClickEdit(tool);
    } else {
      onClick(tool, false);
    }
  }}
  on:contextmenu={ev => {
    ev.preventDefault();
    onClick(tool, true);
    return false;
  }}>
  <img
    class="tool {tool.custom ? 'custom' : ''}"
    src={tool.custom ? icon : tool.icon}
    alt="Tool Icon" />
  {#if editMode && tool.custom}
    <img
      class="arrow"
      src="/images/trash-red.svg"
      alt="Remove Tool"
      on:click={onRemove} />
  {/if}
</div>
