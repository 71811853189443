<script>
  export let text;
  export let active;
  export let onClick;
</script>

<style>
  div.dialogTabContainer {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 19.8%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--labit-card-lightgray);
    cursor: pointer;
  }

  div.active {
    background-color: white;
  }

  div.noActive {
    background-color: var(--labit-notselected-tab);
  }
</style>

<div
  class="dialogTabContainer {active ? 'active' : 'noActive'}"
  on:click={onClick}>
  <span>{text}</span>
</div>
