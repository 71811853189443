export class Queue {
    constructor() {
        this.elements = {};
        this.head = 0;
        this.tail = 0;
    }
    enqueue(element) {
        this.elements[this.tail] = element;
        this.tail++;
    }
    dequeue() {
        const item = this.elements[this.head];
        delete this.elements[this.head];
        this.head++;
        return item;
    }
    peek() {
        return this.elements[this.head];
    }
    getElementByIndex(index) {
        return this.elements[index];
    }
    dequeueByIndex(index) {
        delete this.elements[index];
    }
    hasChildren(element) {
        const pointer = this.head;
        let children = [];
        while (pointer < this.length()) {
            if (element.id === this.element[pointer]) children.push(pointer);
        }
        return children;
    }
    get length() {
        return this.tail - this.head;
    }
    get isEmpty() {
        return this.length === 0;
    }
}