<script>
  import Company from "./components/Company.svelte";
  import DialogRemove from "../../components/DialogRemove.svelte";
  import TextEdit from "../../components/TextEdit.svelte";
  import SubmenuMultipleSelect from "../../components/SubmenuMultipleSelect.svelte";
  import SubmenuButton from "../../components/SubmenuButton.svelte";

  import { post, rpost, LABIT_PUBLIC_FOLDER } from "../../js/labit-connection";
  import { removeAccents } from "../../js/removeAccents";
  import { openURL } from "../../js/openURL";
  import { openNewWindow } from "../../js/openNewWindow";

  import { refreshLicenses } from "../../js/stores.js";

  import { onMount } from "svelte";
  import moment from 'moment';
  let currentLicenses = [];

  const COMPANY_FOLDER = "licenses/companies";
  const PRODUCT_FOLDER = "licenses/products";

  let company = {
    id: -1,
    name: "",
    pictureURL: "",
    products: [],
    keys: [],
    suscriptions: [],
  };

  let optionNewCompany = false;

  let showCompany = true;
  let errorCompany = false;

  let showRemoveDialog = false;
  let removeElement = "";
  let removeCompany = null;

  let editing = -1;

  let companiesList = [];
  let productsList = [];
  let keysList = [];
  let rolesList = [];
  let suscriptionsList = [];
  let keyRolesList = [];

  let companyOptions = [];
  let selectedCompany = [];
  let productOptions = [];
  let selectedProduct = [];
  let contractOptions = [];
  let selectedContract = [];
  let renewalOptions = [];
  let selectedRenewal = [];
  let typeOptions = [];
  let selectedType = [];
  let searchFilter = "";

  onMount(async () => {
    await loadCompanies();
    await loadProducts();
    await loadKeys();
    await loadSuscriptions();
    await loadRoles();
    createCurrentLicenses();
  });

  let createCurrentLicenses = () => {
    companiesList.forEach((c) => {
      let currentCompany = {
        id: c.id,
        name: c.name,
        pictureURL: c.pictureURL,
        products: productsList.filter((p) => {
          return p.company === c.id;
        }),
        keys: keysList.filter((k) => {
          return k.company === c.id;
        }),
        suscriptions: suscriptionsList.filter((s) => {
          return s.companyId === c.id;
        }),
        visible: true,
      };
      keyRolesList = [];
      currentCompany.keys.forEach((k) => {
        let keyRole = {
          id: k.id,
          username: k.username,
          passwd: k.passwd,
          company: k.company,
          active: k.active,
          roles: rolesList.filter((r) => {
            return r.keyId === k.id;
          }),
        };
        const rolesAux = [];
        keyRole.roles.forEach((role) => {
          rolesAux.push(role.name);
        });
        keyRole.roles = rolesAux;
        keyRole = keyRole;
        keyRolesList.push(keyRole);
      });

      currentCompany.suscriptions.map((sus) => {
        sus.renewalStatus =
          sus.renewalStatus === "InProcess" ? "In Process" : sus.renewalStatus;
        return sus;
      });

      currentCompany.keys = keyRolesList;
      currentCompany = currentCompany;
      currentLicenses.push(currentCompany);
      currentLicenses = currentLicenses;
    });

    currentLicenses.forEach((license) => {
      license.visible = true;
      license.search = "";
      license.search =
        license.search + removeAccents(license.name).toUpperCase();
      license.products.forEach((product) => {
        license.search =
          license.search + " " + removeAccents(product.name).toUpperCase();
      });
      license.keys.forEach((key) => {
        license.search =
          license.search + " " + removeAccents(key.username).toUpperCase();
      });
      license.suscriptions.forEach((suscription) => {
        license.search =
          license.search +
          " " +
          removeAccents(suscription.contractNumber).toUpperCase();
      });

      currentLicenses = currentLicenses;
    });
    //console.log(currentLicenses);

    getCompanies();
    getProducts();
    getContracts();
    getRenewals();
    getTypes();
  };

  let getCompanies = () => {
    companyOptions = currentLicenses.map((license) => {
      return {
        value: license.id,
        label: license.name,
      };
    });
    selectedCompany = [];
  };

  let getProducts = () => {
    productOptions = [];
    currentLicenses.forEach((license) => {
      license.products.forEach((product) => {
        productOptions.push({
          value: product.id,
          label: product.name,
        });
      });
    });
    productOptions = productOptions;
    selectedProduct = [];
  };

  let getContracts = () => {
    contractOptions = [];
    currentLicenses.forEach((license) => {
      license.suscriptions.forEach((suscription) => {
        contractOptions.push({
          value: suscription.id,
          label: suscription.contractNumber,
        });
      });
    });
    contractOptions = contractOptions;
    selectedContract = [];
  };

  let getRenewals = () => {
    renewalOptions = [];
    currentLicenses.forEach((license) => {
      license.suscriptions.forEach((suscription) => {
        const pos = renewalOptions.findIndex((item) => {
          return item.value === suscription.renewalStatus;
        });
        if (pos === -1) {
          renewalOptions.push({
            value: suscription.renewalStatus,
            label: suscription.renewalStatus,
          });
        }
      });
    });
    renewalOptions = renewalOptions;
    selectedRenewal = [];
  };

  let getTypes = () => {
    typeOptions = [];
    currentLicenses.forEach((license) => {
      license.suscriptions.forEach((suscription) => {
        const pos = typeOptions.findIndex((item) => {
          return item.value === suscription.type;
        });
        if (pos === -1) {
          typeOptions.push({
            value: suscription.type,
            label: suscription.type,
          });
        }
      });
    });
    typeOptions = typeOptions;
    selectedType = [];
  };

  let loadCompanies = async () => {
    companiesList = await post(
      `SELECT id, name, pictureURL FROM licenseCompanies`
    );
  };

  let loadProducts = async () => {
    productsList = await post(
      `SELECT id, name, pictureURL, company FROM licenseProducts`
    );
  };

  let loadKeys = async () => {
    keysList = await post(
      `SELECT id, username, passwd, company, active FROM licenseKeys`
    );

    keysList = keysList.map((key) => {
      key.active = key.active === "1" ? true : false;
      return key;
    });
  };

  let loadRoles = async () => {
    rolesList = await post(`SELECT id, name, keyId FROM licenseRoles`);
  };

  let loadSuscriptions = async () => {
    suscriptionsList =
      await post(`SELECT ls.id, ls.contractNumber, ls.seats, ls.type, ls.term, ls.expiringDate, ls.renewalComments, ls.renewalStatus, ls.product, ls.license, username, passwd, lc.id as companyId
FROM licenseSuscriptions AS ls
LEFT JOIN licenseKeys AS lk ON lk.id=ls.license
LEFT JOIN licenseCompanies AS lc ON lc.id=lk.company`);
    suscriptionsList = suscriptionsList.map((suscription) => {
      suscription.expiringDate = moment(
        suscription.expiringDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
      return suscription;
    });
  };

  let emptyCompany = () => {
    company = {
      id: -1,
      name: "",
      pictureURL: "",
      products: [],
      keys: [],
      suscriptions: [],
    };
  };

  let createUpdateCompany = async (company) => {
    if (company.name) {
      errorCompany = false;

      if (company.id < 0) {
        // Create Company
        const responseCompany = await post(
          `INSERT INTO licenseCompanies (name, pictureURL)
        VALUES ('${company.name}', '')`
        );
        company.id = responseCompany[0];

        if (company.icon) {
          const path = COMPANY_FOLDER;
          const fileName = company.id + "." + company.icon.extension;
          const pictureURL = LABIT_PUBLIC_FOLDER + "/" + path + "/" + fileName;

          await rpost("UploadPublicFile", {
            path,
            fileName,
            fileData: company.icon.data,
          });

          await post(
            `update licenseCompanies set
          pictureURL='${pictureURL}'
          where id=${company.id}`
          );

          company.pictureURL = pictureURL;
        }
      } else {
        // Update Company

        await post(
          `UPDATE licenseCompanies SET name='${company.name}', pictureURL='${company.pictureURL}' WHERE id=${company.id}`
        );

        if (company.icon) {
          const path = COMPANY_FOLDER;
          const fileName = company.id + "." + company.icon.extension;
          const pictureURL = LABIT_PUBLIC_FOLDER + "/" + path + "/" + fileName;

          await rpost("UploadPublicFile", {
            path,
            fileName,
            fileData: company.icon.data,
          });

          await post(
            `update licenseCompanies set
          pictureURL='${pictureURL}'
          where id=${company.id}`
          );

          company.pictureURL = pictureURL;
        }
      }

      for (let i = 0; i < company.products.length; i++) {
        const product = company.products[i];
        if (product.id < 0) {
          // Create Product
          const responseProduct =
            await post(`INSERT INTO licenseProducts (name, pictureURL, company)
        VALUES ('${product.name}', '', ${company.id})`);
          company.suscriptions.forEach((s) => {
            if (s.product === company.products[i].id) {
              s.product = responseProduct[0];
            }
          });
          company.products[i].id = responseProduct[0];

          if (company.products[i].icon) {
            const path = PRODUCT_FOLDER;
            const fileName =
              company.products[i].id + "." + company.products[i].icon.extension;
            const pictureURL =
              LABIT_PUBLIC_FOLDER + "/" + path + "/" + fileName;

            await rpost("UploadPublicFile", {
              path,
              fileName,
              fileData: company.products[i].icon.data,
            });

            await post(
              `update licenseProducts set
              pictureURL='${pictureURL}'
              where id=${company.products[i].id}`
            );

            company.products[i].pictureURL = pictureURL;
          }
        } else {
          // Update Product

          await post(
            `UPDATE licenseProducts SET name='${product.name}', pictureURL='${product.pictureURL}', company=${company.id} WHERE id=${product.id}`
          );

          if (company.products[i].icon) {
            const path = PRODUCT_FOLDER;
            const fileName =
              company.products[i].id + "." + company.products[i].icon.extension;
            const pictureURL =
              LABIT_PUBLIC_FOLDER + "/" + path + "/" + fileName;

            await rpost("UploadPublicFile", {
              path,
              fileName,
              fileData: company.products[i].icon.data,
            });

            await post(
              `update licenseProducts set
              pictureURL='${pictureURL}'
              where id=${company.products[i].id}`
            );

            company.products[i].pictureURL = pictureURL;
          }
        }
      }

      for (let i = 0; i < company.keys.length; i++) {
        const key = company.keys[i];
        if (key.id < 0) {
          // Create Key
          const responseKey =
            await post(`INSERT INTO licenseKeys (username, passwd, company, active)
        VALUES ('${key.username}', '${key.passwd}', ${company.id}, ${key.active})`);
          company.suscriptions.forEach((s) => {
            if (s.license === company.keys[i].id) {
              s.license = responseKey[0];
            }
          });
          company.keys[i].id = responseKey[0];
          // roles
          await post(`DELETE FROM licenseRoles WHERE keyId=${key.id}`);
          for (let j = 0; j < key.roles.length; j++) {
            const roleName = key.roles[j];
            // Create/Update Role
            await post(`INSERT INTO licenseRoles (name, keyId)
        VALUES ('${roleName}', ${key.id})`);
          }
        } else {
          if (key.edit) {
            // Update Key
            await post(
              `UPDATE licenseKeys SET username='${key.username}', passwd='${key.passwd}', company=${company.id}, active=${key.active} WHERE id=${key.id}`
            );
            // roles
            await post(`DELETE FROM licenseRoles WHERE keyId=${key.id}`);
            for (let j = 0; j < key.roles.length; j++) {
              const roleName = key.roles[j];
              // Create/Update Role
              await post(`INSERT INTO licenseRoles (name, keyId)
        VALUES ('${roleName}', ${key.id})`);
            }
            key.edit = false;
          }
        }
      }

      for (let i = 0; i < company.suscriptions.length; i++) {
        const suscription = company.suscriptions[i];
        if (suscription.id < 0) {
          // Create Suscription
          const responseSuscription =
            await post(`INSERT INTO licenseSuscriptions (contractNumber, seats, type, term, expiringDate, renewalComments, renewalStatus, product, license)
        VALUES ('${suscription.contractNumber}', ${suscription.seats}, '${
              suscription.type
            }', '${suscription.term}', '${moment(
              suscription.expiringDate,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")}', '${suscription.renewalComments}', '${
              suscription.renewalStatus === "In Process"
                ? "InProcess"
                : suscription.renewalStatus
            }', ${suscription.product}, ${suscription.license})`);
          company.suscriptions[i].id = responseSuscription[0];
        } else {
          // Update Suscription
          await post(
            `UPDATE licenseSuscriptions SET contractNumber='${
              suscription.contractNumber
            }', seats=${suscription.seats}, type='${suscription.type}', term='${
              suscription.term
            }', expiringDate='${moment(
              suscription.expiringDate,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")}', renewalComments='${
              suscription.renewalComments
            }', renewalStatus='${
              suscription.renewalStatus === "In Process"
                ? "InProcess"
                : suscription.renewalStatus
            }', product=${suscription.product}, license=${
              suscription.license
            }  WHERE id=${suscription.id}`
          );
        }
      }
      company = company;

      if (editing < 0) {
        currentLicenses.push(company);
      }
      currentLicenses = currentLicenses;

      emptyCompany();
      optionNewCompany = false;

      refreshLicenses.set(true);
    } else {
      errorCompany = true;
    }
  };

  let deleteCompany = async (c) => {
    const response = await post(
      `DELETE FROM licenseCompanies WHERE id=${c.id}`
    );

    currentLicenses = currentLicenses.filter((company) => {
      return company.id !== c.id;
    });
    currentLicenses = currentLicenses;
    emptyCompany();
    optionNewCompany = false;

    showRemove(false);
    showCompany = true;
    editing = -1;

    refreshLicenses.set(true);
  };

  let showRemove = (visible) => {
    removeElement = `"Standard: ${removeCompany.name}"`;
    showRemoveDialog = visible;
  };

  let applyFilter = () => {
    currentLicenses.forEach((license) => {
      license.visible = true;
    });

    if (searchFilter !== "") {
      let SearchFilter = removeAccents(searchFilter);
      SearchFilter = SearchFilter.toUpperCase();
      currentLicenses.forEach((license) => {
        if (license.visible) {
          if (!license.search.includes(SearchFilter)) {
            license.visible = false;
          }
        }
      });
    } else {
      if (selectedCompany && selectedCompany.length > 0) {
        currentLicenses.forEach((license) => {
          if (license.visible) {
            const pos = selectedCompany.findIndex((c) => {
              return c === license.id;
            });
            if (pos === -1) {
              license.visible = false;
            }
          }
        });
      }

      if (selectedProduct && selectedProduct.length > 0) {
        currentLicenses.forEach((license) => {
          if (license.visible) {
            if (license.products.length === 0) {
              license.visible = false;
            } else {
              license.products.forEach((product) => {
                const pos = selectedProduct.findIndex((item) => {
                  return item === product.id;
                });
                if (pos === -1) {
                  license.visible = false;
                }
              });
            }
          }
        });
      }

      if (selectedContract && selectedContract.length > 0) {
        currentLicenses.forEach((license) => {
          if (license.visible) {
            if (license.suscriptions.length === 0) {
              license.visible = false;
            } else {
              license.suscriptions.forEach((suscription) => {
                const pos = selectedContract.findIndex((item) => {
                  return item === suscription.id;
                });
                if (pos === -1) {
                  license.visible = false;
                }
              });
            }
          }
        });
      }

      if (selectedRenewal && selectedRenewal.length > 0) {
        currentLicenses.forEach((license) => {
          if (license.visible) {
            if (license.suscriptions.length === 0) {
              license.visible = false;
            } else {
              license.suscriptions.forEach((suscription) => {
                const pos = selectedRenewal.findIndex((item) => {
                  return item === suscription.renewalStatus;
                });
                if (pos === -1) {
                  license.visible = false;
                }
              });
            }
          }
        });
      }

      if (selectedType && selectedType.length > 0) {
        currentLicenses.forEach((license) => {
          if (license.visible) {
            if (license.suscriptions.length === 0) {
              license.visible = false;
            } else {
              license.suscriptions.forEach((suscription) => {
                const pos = selectedType.findIndex((item) => {
                  return item === suscription.type;
                });
                if (pos === -1) {
                  license.visible = false;
                }
              });
            }
          }
        });
      }
    }

    currentLicenses = currentLicenses;
  };

  let clearFilters = () => {
    selectedCompany = [];
    selectedProduct = [];
    selectedContract = [];
    selectedRenewal = [];
    selectedType = [];
    searchFilter = "";
  };

  $: if (
    selectedCompany ||
    selectedProduct ||
    selectedContract ||
    selectedRenewal ||
    selectedType ||
    searchFilter
  ) {
    applyFilter();
  }
</script>

{#if showRemoveDialog}
  <DialogRemove
    element={removeElement}
    onClose={() => {
      showRemove(false);
    }}
    onRemove={() => deleteCompany(removeCompany)}
  />
{/if}
<div class="content {showRemoveDialog ? 'hiddenElement' : ''}">
  <!-- Menu Options -->

  <div class="optionsMenu">
    <div
      class="plusButton"
      on:click={() => {
        optionNewCompany = true;
        editing = -1;
      }}
    >
      <img src="/images/plus.svg" alt="New Icon" />
    </div>
    <div class="optionsRows">
      <div class="optionsRow">
        <div class="optionsColumn1">
          <div class="optionsSearch">
            <span>Search</span>
            <TextEdit
              placeholder="type here..."
              bind:value={searchFilter}
              maxlength="150"
              img="/images/search.svg"
            />
          </div>
        </div>
        <div class="optionsColumn2">
          <SubmenuButton
            text="Clear all"
            img="/images/x.svg"
            onClick={() => clearFilters()}
          />
        </div>
      </div>
      <div class="optionsRow lastRow">
        <div class="optionsColumn1">
          <SubmenuMultipleSelect
            text="Company"
            options={companyOptions}
            bind:selected={selectedCompany}
            width="170px"
          />
          <SubmenuMultipleSelect
            text="Product"
            options={productOptions}
            bind:selected={selectedProduct}
            width="170px"
          />
          <SubmenuMultipleSelect
            text="Type"
            options={typeOptions}
            bind:selected={selectedType}
            width="170px"
          />
          <SubmenuMultipleSelect
            text="Contract Number"
            options={contractOptions}
            bind:selected={selectedContract}
            width="170px"
          />
          <SubmenuMultipleSelect
            text="Renewal Status"
            options={renewalOptions}
            bind:selected={selectedRenewal}
            width="170px"
          />
        </div>
        <div class="optionsColumn2">
          <SubmenuButton
            text=""
            img="/images/key-assignment.svg"
            onClick={() => {
              openURL("/assigned-licenses", true);
            }}
            onRightClick={() => {
              openNewWindow("/skylab-main/assigned-licenses");
            }}
            bigIcon={true}
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Body -->
  {#if optionNewCompany}
    <Company
      bind:company
      bind:newCompany={optionNewCompany}
      addCompany={async () => {
        await createUpdateCompany(company);
      }}
      creatingNew={true}
      bind:errorCompany
    />
  {/if}
  {#if !optionNewCompany}
    {#each currentLicenses as c}
      {#if showCompany && c.visible}
        <Company
          bind:company={c}
          onDelete={() => {
            removeCompany = c;
            showRemove(true);
          }}
          editCompany={async () => await createUpdateCompany(c)}
          bind:editing
          bind:errorCompany
        />
      {/if}
    {/each}
  {/if}
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  .newCompanyContainer {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin: 20px 0px 20px 0px;
    color: var(--labit-darkgray);
  }

  div.optionsRows {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    padding-right: 13px;
    justify-content: space-between;
  }

  div.optionsColumn2 {
    display: flex;
  }

  div.optionsSearch {
    display: flex;
    flex-grow: 1;
    height: 21px;
    align-items: center;
  }

  div.optionsSearch span {
    margin-right: 13px;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  div.plusButton {
    width: 122px;
    background-color: var(--labit-dialog-background);
    border-radius: 10px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  div.plusButton img {
    width: 30px;
    height: 30px;
  }
</style>
