<script>
  export let text = "";
  export let img = "";

  let openApp = () => {
    window.open(text);
  };
</script>

<div class="dashboardContainer">
  {#if text && !img}
    <span>{text}</span>
  {:else if img && text}
    <img on:click={() => openApp()} src={img} alt="" />
  {/if}
</div>

<style>
  div.dashboardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 100%;
    background-color: white;
    border-radius: 14px;
    /*margin-bottom: 20px;*/
  }

  span {
    color: var(--labit-card-gray);
    font-size: 25px;
    font-weight: bold;
  }

  img {
    max-width: 150px;
    max-height: 60px;
    cursor: pointer;
  }
</style>
