<script>
    import { onMount } from 'svelte';
    import { generalHolidays } from "../../../js/stores";
    import { post } from "../../../js/labit-connection";
    import moment from 'moment';
    let holidaysCal = [];
    let holidaysList = [];

    const getGeneralHolidays = async () => {
        const res = await post( `SELECT name, startDate, endDate, location, countsAsVacations FROM general_holidays where startDate > '${moment().startOf("year").format("YYYY-MM-DD")}' order by startDate;` );
        return res.reduce((acc, e) => {
            acc.push( {
                title: e.name,
                start: e.startDate,
                end: e.endDate,
                vacations: e.countsAsVacations,
                location: e.location
                //allDay: true,
                //color: e.location === 'Madrid' ? 'blue' : 'green'
            } );

            return acc;
        }, []);
    }

    onMount( async () => {

        const gHolidays = $generalHolidays;
        let holidays;
        if (gHolidays.length === 0 ) {
            holidays = await getGeneralHolidays();
            generalHolidays.set(holidays);
        } else {
            holidays = gHolidays;
        }

        holidaysCal = holidays.map((e) => {
            return {
                title: e.title,
                start: e.start,
                end: e.end,
                color: e.vacations === '1' ? 'green' : e.location === 'Madrid' ? 'blue' : 'orange'
            }
        });

        const calendarCtx = document.getElementById('calendar');
        const calendar = new FullCalendar.Calendar(calendarCtx, {
            headerToolbar: {
                left: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            timeZone: 'local',
            initialView: 'dayGridMonth',
            events: holidaysCal,
            weekNumberCalculation: 'ISO',
            firstDay: 1,
            expandRows: true,
            hiddenDays: [6, 0]
            //businessHours: {
            //    daysOfWeek: [1,2,3,4,5]
            //}
        });
        calendar.render();

        holidaysList = holidays.reduce((acc, e) => {
            const i = acc.findIndex((f) => f.title === e.title);
            
            let elem = {};
            if (i > -1) {
                acc[i].locations.push(e.location);
            } else {
                elem = { title: e.title, start: e.start, end: e.end, vacations: e.vacations, locations: [e.location] };
                acc.push(elem)
            }
            return acc;
        }, []);

    });
</script>

<div class="holidaysContainer wh-100">
    <div id="calendar" class="left"></div>
    <div class="right">
        <div class="holidaysList">
            {#each holidaysList as { title, start, end, vacations, locations }}
                <div class="holidayDetails flex">
                    <div class="column flex">
                        <span>{title}</span>
                        {#if start === end}
                            <span>{start}</span>
                        {:else}
                            <span>{start} - {end}</span>
                        {/if}
                        {#if vacations === 1}
                            <span>General Holidays</span>
                        {/if}
                    </div>
                    <div class="column flex">
                        {#each locations as location}
                            <span>{location}</span>
                        {/each}
                    </div>
                </div>
            {/each}
        </div>
        <div class="flex row">
            <div class="btn addHoliday" on:click={ () => alert("Coming...") }></div>
        </div>
    </div>
</div>

<style>
    .column {
        flex-direction: column;
        min-width: 90px;
        align-self: center;
    }

    .holidaysList {
        height: 90%;
        overflow-y: scroll;
        font-size: larger;
    }

    .row {
        justify-content: center;
    }
    .holidayDetails {
        height: 80px;
        width: 98%;
        border: 1px solid #ddd;
        margin: 3px 0;
        border-radius: 8px;
        
        justify-content: space-between;
        padding: 10px;
    }

    .btn {
        cursor: pointer;
    }

    .addHoliday {
        height: 50px;
        width: 50px;
        background-image: url(/images/plus.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 10px;
    }

    .flex {
        display: flex;
    }

    .wh-100 {
        width: 100%;
        height: 75vh;
    }

    .holidaysContainer {
        display: flex;
        justify-content: space-evenly;
    }

    .left {
        width: 63%;
    }
    .right {
        width: 35%;
    }
    .left, .right {
        height: 100%;    
        padding: 10px;
        border: 1px solid #dddddd;
        border-radius: 10px;
    }
</style>