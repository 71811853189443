<script>
    export let options;
    export let selected;
</script>

{#each options as {id, value} (id)}
	<label>
		<input type=radio bind:group={selected} value={id}>
		<span>{value}</span>
	</label>
{/each}

<style>
    label {
        align-items: center;
        display: flex;
        gap: 4px;
    }

    input {
        margin: 0;
        padding: 0;
    }
</style>