<script>
    import { formatTime } from "../../../js/formatDateTime";

    export let placeholder;
    export let time = '';
    export let width = '100%';
    export let height = '29px';
    export let style = '';
    export let blocked;
    export let half;
    export let error = false;

    const format = (e) => time = formatTime(e.target.value);

    const text = (style === 'holiday' && half !== "1") ? 'Free day!' : time;
</script>

{#if blocked}
<span class="{style} blocked element" style="width: {width}; height: {height}">{text}</span>
{:else}
<input class="element {error ? 'error' : ''}" bind:value={time} {placeholder} on:blur={format} on:blur style="width: {width}; height: {height}">
{/if}

<style>

    .element {
        font-size: 16px;
        margin: 0 !important;
        border-radius: 16px;
        padding: 0 12px;
        text-align: center;
    }

    span.blocked {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    span.holiday {
        background-color: #ddd4ff;
        color: #686868;
        border: 1px solid #ddd4ff;
    }

    input {
        background-color: white;
        border: 1px solid #e7e7e7;
    }
    
    span.moreThanExpected {
        background-color: #ffeece;
        color: #e9ad00;
        border: none;
    }
    
    span.lessThanExpected {
        background-color: #ffe6e6;
        color: #ff3e3e;
        border: none;
    }

    .error {
        border-color: red;
    }
</style>
