<script>
  export let dashboard = false;
  export let project;

  import {
    outlookGetCalendars,
    outlookGetCalendarEvents,
  } from "../../../../components/dashboard/outlook/outlook";

  //import { post } from "../../../../js/labit-connection";
  //import { getColor } from "../../../../components/Planner/colors";
  import { copyObject } from "../../../../js/copyObject";
  import {
    getOutlookToken,
    getUserId,
    getOutlookEmail,
  } from "../../../../js/localInfo";
  //import TaskChart from "./TaskChart.svelte";
  import moment from 'moment';

  const MILESTONESBACKGROUNDCOLOR = "var(--labit-bar)";
  const MAX_PHASES = 5;

  let currentDate = moment();
  let dateOptionSelected = currentDate.format("MMMM YYYY");
  let calendar = null;
  let eventsFiltered = [];
  let milestones = null;
  let tasks = null;
  let currentPlan = null;
  let currentProject = null;
  //let myMail = null;
  //let myId = getUserId(); //Isabel Melero
  let myOutlookCalendarsList = [];
  let events = [];
  let outlookEventsList = [];
  let outlookError = false;

  let myOutlookTasks = [];

  import { onMount } from 'svelte';

  let prepareCalendar = async () => {
    resetVariables();
    currentProject = copyObject(project);
    milestones = currentProject.milestones;
    await initOutlook();
    await extractMyTasks();
    /* await getEvents(); */
    filterEvents();
    createCalendar();
  };

  let resetVariables = () => {
    eventsFiltered = [];
    currentPlan = [];
    currentProject = [];
    tasks = [];
    milestones = [];
    myOutlookTasks = [];
  };

  // Function that assigns the e-mail to the variable myMail.
  /*   let getMyMail = async () => {
    let response = await post(
      `SELECT Email
      FROM people
      WHERE contact_id = ` + myId
    );
    myMail = response[0].Email;
  }; */

  // Function that extracts all the tasks of a person
  let extractMyTasks = async () => {
    const myMail = await getOutlookEmail();

    /*
    currentPlan.forEach((person) => {
      if (person.email === myMail) {
        // All my tasks
        myOutlookTasks = myOutlookTasks.concat(person.tasks.toDo);
        myOutlookTasks = myOutlookTasks.concat(person.tasks.inProgress);
        myOutlookTasks = myOutlookTasks.concat(person.tasks.done);
      }
    });
    */
    // Filter tasks with start and end date.
    myOutlookTasks = myOutlookTasks.filter((task) => {
      return task.start && task.end;
    });

    myOutlookTasks = myOutlookTasks.reduce((acc, curr) => {
      acc.push({
        id: "task-" + curr.id,
        title: curr.name,
        allDay: true,
        start: moment(curr.start, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DDT00:00:00"
        ),
        end: moment(curr.end, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DDT23:59:59"
        ),
        backgroundColor:
          curr.status === "done"
            ? "#418040"
            : curr.status === "inprogress"
            ? "#327eaa"
            : curr.status === "todo"
            ? "#605e5c"
            : "",
        borderColor:
          curr.status === "done"
            ? "#418040"
            : curr.status === "inprogress"
            ? "#327eaa"
            : curr.status === "todo"
            ? "#605e5c"
            : "",
        textColor: "white",
        description:
          curr.status === "done"
            ? "Done: " + curr.name
            : curr.status === "inprogress"
            ? "In progress: " + curr.name
            : curr.status === "todo"
            ? "To do: " + curr.name
            : "",
        visible: true,
      });
      return acc;
    }, []);
    events = events.concat(myOutlookTasks);

    /*
    const myPhases = [];

    for (let i = 0; i < currentPlan.phases.length; i++) {
      let phase = currentPlan.phases[i];
      for (let j = 0; j < phase.people.length; j++) {
        let person = phase.people[j];
        if (person.email === myMail) {
          const contact = {
            color: getColor(i % MAX_PHASES, "dark"),
            tasks: person.tasks,
          };
          myPhases.push(contact);
        }
      }
    }

    for (let i = 0; i < myPhases.length; i++) {
      const phase = myPhases[i];
      for (let j = 0; j < phase.tasks.toDo.length; j++) {
        const task = phase.tasks.toDo[j];
        task.color = phase.color;
        tasks.push(task);
      }
      for (let j = 0; j < phase.tasks.pending.length; j++) {
        const task = phase.tasks.pending[j];
        task.color = phase.color;
        tasks.push(task);
      }
      for (let j = 0; j < phase.tasks.done.length; j++) {
        const task = phase.tasks.done[j];
        task.color = phase.color;
        tasks.push(task);
      }
    } */
  };

  let filterEvents = () => {
    events = [];
    events = events.concat(milestones);
    events = events.concat(myOutlookTasks);
    events = events.concat(outlookEventsList);
    eventsFiltered = events.reduce((acc, curr) => {
      if (curr.visible) {
        acc.push({
          id: curr.id,
          title: curr.title,
          start: curr.start,
          end: curr.end,
          allDay: curr.allDay,
          backgroundColor: curr.backgroundColor,
          borderColor: curr.backgroundColor,
          textColor: curr.textColor,
          projectId: curr.projectId,
          description: curr.description,
          type: curr.type,
          outlookEventType:
            curr.type === "outlook" ? curr.outlookEventType : "general",
        });
      }
      return acc;
    }, []);
  };

  let initOutlook = async () => {
    let outlookCalendars;
    // Get Outlook Calendars
    outlookGetCalendars(
      (calendars) => {
        outlookCalendars = calendars;
      },
      async () => {
        outlookError = true;
        myOutlookCalendarsList = outlookCalendars.reduce((acc, curr) => {
          acc.push({
            text: curr.name,
            id: curr.id,
            color: "red", // Outlook events have no associated color, we assign red color.
            outlookCalendarType: curr.outlookCalendarType,
            additionalMail: curr.additionalMail,
          });
          return acc;
        }, []);

        let token = getOutlookToken();
        if (token !== "") {
          outlookError = false;
          await getOutlookCalendar(
            currentDate.startOf("month").format("YYYY-MM-DD"),
            currentDate.endOf("month").format("YYYY-MM-DD")
          );
        }
      }
    );
  };

  let getOutlookCalendar = async (from, to) => {
    outlookEventsList = [];
    let myOutlookCalendarEventList = [];
    const calendarOutlookProject = myOutlookCalendarsList.find((p) => {
      return p.text === currentProject.name;
    });
    if (calendarOutlookProject) {
      outlookGetCalendarEvents(
        calendarOutlookProject.id,
        calendarOutlookProject.outlookCalendarType,
        from,
        to,
        (outlookEvents) => {
          myOutlookCalendarEventList = outlookEvents.reduce((acc, curr) => {
            acc.push({
              id: curr.id,
              title: curr.subject,
              start: curr.startDateTime,
              end: curr.endDateTime,
              textColor: "white",
              allDay: curr.isAllday,
              type: "outlook",
              description: curr.subject,
              backgroundColor: "red",
              borderColor: "red",
              visible: true,
            });
            return acc;
          }, []);
          outlookEventsList = outlookEventsList.concat(
            myOutlookCalendarEventList
          );
          filterEvents();
          createCalendar();
        },
        () => {
          outlookError = true;
          filterEvents();
          createCalendar();
        }
      );
    }
  };

  /*
  let getEvents = async () => {
    let eventsAndTasks = [];
    // Tasks
    tasks = tasks.reduce((acc, curr) => {
      acc.push({
        id: "task-" + curr.id,
        title: curr.name,
        allDay: true,
        start: moment(curr.start, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DDT00:00:00"
        ),
        end: moment(curr.end, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DDT23:59:59"
        ),
        type: null,
        location: null,
        backgroundColor: curr.color,
        borderColor: curr.color,
        textColor: "white",
        description: curr.name,
        visible: true,
      });
      return acc;
    }, []);
    // events = events.concat(tasks); 

    // Milestones
    milestones = milestones.reduce((acc, curr) => {
      acc.push({
        id: "milestone-" + curr.id,
        title: curr.name,
        allDay: false,
        start: moment(curr.start, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DDT00:00:00"
        ),
        end: moment(curr.end, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DDT23:59:59"
        ),
        type: null,
        location: null,
        backgroundColor: MILESTONESBACKGROUNDCOLOR,
        borderColor: MILESTONESBACKGROUNDCOLOR,
        textColor: "white",
        description: curr.name,
        visible: true,
      });
      return acc;
    }, []);
  };

  */

  let createCalendar = () => {
    var calendarEl = document.getElementById("calendar");
    calendar = new FullCalendar.Calendar(calendarEl, {
      initialView: "dayGridMonth",
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-MM-DD"),
      fixedWeekCount: false,
      dayMaxEvents: 2, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "short" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      events: eventsFiltered,
    });
    calendar.render();
  };

  let clickNext = async () => {
    currentDate.add(1, "months");
    dateOptionSelected = currentDate.format("MMMM YYYY");
    currentDate = currentDate;
    if (!outlookError) {
      await getOutlookCalendar(
        currentDate.startOf("month").format("YYYY-MM-DD"),
        currentDate.endOf("month").format("YYYY-MM-DD")
      );
    }
    await initOutlook();
    createCalendar();
  };
  let clickPrev = async () => {
    currentDate.subtract(1, "months");
    dateOptionSelected = currentDate.format("MMMM YYYY");
    currentDate = currentDate;
    if (!outlookError) {
      await getOutlookCalendar(
        currentDate.startOf("month").format("YYYY-MM-DD"),
        currentDate.endOf("month").format("YYYY-MM-DD")
      );
    }
    await initOutlook();
    createCalendar();
  };

  onMount(() => prepareCalendar());
</script>

<div class={dashboard ? "monthlyCalendarDashboard" : "monthlyCalendar"}>
  <div class="header">
    <div class="monthSearch">
      <div class="leftArrow" on:click={() => clickPrev()}>
        <img src="/images/calendar/left_arrow.svg" alt="Left Arrow" />
      </div>
      <div class="monthSelected">
        <span>{dateOptionSelected}</span>
      </div>
      <div class="rightArrow" on:click={() => clickNext()}>
        <img src="/images/calendar/right_arrow.svg" alt="Right Arrow" />
      </div>
    </div>
  </div>
  <div class="divCalendar">
    <div id="calendar" />
  </div>
</div>

<style>
  div.monthlyCalendar {
    height: 384px;
    width: 100%;
    margin-top: 16px;
    background-color: white;
    border-radius: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  div.header {
    height: 15%;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.monthSearch {
    height: 29px;
    width: 90%;
    background-color: var(--fullcalendar-header-monthlycalendar);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-right: 10px;
    padding-left: 10px;
  }

  div.divCalendar {
    /* padding-top: 10px; */
    /* height: 80%; */
    height: 90%;
    /* width: 233px; */
    /* width: 90%; */
    width: 90%;
  }

  #calendar {
    height: 90%;
    width: 100%;
    /*     margin: 0 auto; */
  }

  div.leftArrow,
  div.rightArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 15px;
    cursor: pointer;
  }

  div.monthSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 70%;
  }

  img {
    height: 15px;
    width: 15px;
  }

  span {
    color: var(--labit-card-gray);
    font-weight: bold;
    font-size: 15px;
  }
</style>
