<script>
  export let text;
  export let icon = null;
  export let onClick;
</script>

<style>
  div.submenuBlackButtonIconContainer {
    width: 123px;
    height: 30px;
    background-color: var(--labit-black-button);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  span {
    font-size: 13px;
    color: white;
    font-weight: bold;
  }

  img {
    height: 60%;
    margin-right: 8px;
  }

  /* Animations */

  div.submenuBlackButtonIconContainer {
    transition: opacity 0.5s linear 0s;
  }

  div.submenuBlackButtonIconContainer:hover {
    opacity: 0.5;
  }
</style>

<div class="submenuBlackButtonIconContainer" on:click={onClick}>
  {#if icon}
    <img src={icon} alt="Button Icon" />
  {/if}
  <span>{text}</span>
</div>
