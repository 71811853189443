<script>
  export let title;
  export let numEvents;
</script>

<div class="dashboardTitleContainer">
  <span>{title}</span>
  {#if numEvents > 0}
    <div class="event">
      <span>{numEvents}</span>
    </div>
  {/if}
</div>

<style>
  div.dashboardTitleContainer {
    height: 45px;
    width: 100%;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--labit-card-gray);
  }

  div.event {
    background-color: var(--labit-bar);
    border-radius: 50%;
    width: 19px;
    height: 19px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
  }
</style>
