<script>
  export let projectName;
  export let projectCode;
</script>

<div class="assignedProjectsContainer">
  <span>{projectCode} - {projectName}</span>
</div>

<style>
  div.assignedProjectsContainer {
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-bottom: 2px;
  }
</style>
