<script>
import moment from 'moment';

    import { msToTime } from '../../../js/formatDateTime'
    import ToggleableContainer from "./ToggleableContainer.svelte";

    export let data;
    //export let show;

    const projectImagesURL = 'https://skylab.labit.es/uploads';

    const projects = data.map(project => {
        const usersKeys = Object.keys(project.users);
        let totalProjectTime = 0;
        usersKeys.forEach(user => {
            let totalTime = 0;
            const daysKeys = Object.keys(project.users[user].days);
            
            // Obteniendo el tiempo total que cada usuario le ha dedicado al proyecto
            totalTime += daysKeys.reduce(
                (acc, day) => acc += project.users[user].days[day].reduce(
                    (acc2, task) => acc2 += task.duration
                , 0)
            , 0);
            
            project.users[user].totalTime = totalTime;

            // Obteniendo la suma total de tiempos de todos los usuarios
            totalProjectTime += totalTime;
        });

        return {
            picture: project.picture,
            project_id: project.project_id,
            project_name: project.project_name,
            users: project.users,
            totalTime: totalProjectTime
        }
    });

    console.log(projects);
</script>

<div class="container w-100">
    {#each projects as {project_id, project_name, picture, users, totalTime} (project_id)}
    {@const usersKeys = Object.keys(users)}
    {@const time = msToTime(totalTime * 60 * 1000)}
    
    {#if usersKeys.length > 0}
    <ToggleableContainer --bgColor="#fafafa">
        <div class="header-main flex-row-center" slot="header">
            <img class="projectPicture" src={`${projectImagesURL}${picture}`} alt={project_name}>
            <span class="bigger">{project_name}</span>
            <span class="big">{time}h</span>
        </div>

        <div class="body-main" slot="body">
        {#each usersKeys as user}
        {@const daysKeys = Object.keys(users[user].days)}
        {@const total = users[user].totalTime * 60 * 1000}
            <ToggleableContainer --bgColor="white">
                <div class="subheader flex-row-center" slot="header">
                    <img class="userPicture" src={users[user].picture} alt={project_name}>
                    <span>{users[user].name}</span>
                    <span>{msToTime(total)}h</span>
                </div>
                <div class="sub-body" slot="body">
                    <table class="w-100">
                        {#each daysKeys as day}
                        {@const tasks = users[user].days[day]}
                        {@const nRows = tasks.length}
                            {#each tasks as {description, start, end, duration}, index (index)}
                            {@const taskStart = moment(start, 'x').format('HH:mm')}
                            {@const taskEnd = moment(end, 'x').format('HH:mm')}
                            {@const taskTotal = msToTime(duration * 60 * 1000)}
                            <tr class="{index % 2 === 0 ? '' : 'bgGray'} {index === nRows-1 ? 'border-bottom' : ''}">
                                {#if index === 0}
                                <td rowspan={nRows}>{day}</td>
                                {/if}
                                <td>{description}</td>
                                <td>{taskStart} - {taskEnd}</td>
                                <td>{taskTotal}</td>
                            </tr>
                            {/each}
                        {/each}
                    </table>
                </div>
            </ToggleableContainer>
        {/each}
        </div>
    </ToggleableContainer>
    {/if}
    {/each}
</div>

<style>
    table > tr:last-child {
        border: none !important;
    }

    tr {
        height: 24px;
    }

    td {
        text-align: center;
        vertical-align: middle;
    }

    table {
        border-collapse: collapse;
    }

    .border-bottom {
        border-bottom: 1px solid #efefef;
    }

    .bgGray {
        background-color: #fafafa;
    }

    .flex-row-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .container {
        border-radius: 19px;
        padding: 19px;
        gap: 20px;
        display: flex;
        flex-direction: column;
    }

    .w-100 {
        width: 100%;
    }

    .big {
        font-size: 1.2rem;
    }

    .bigger {
        font-size: 1.4rem;
    }

    .header-main {
        margin: 0 auto;
        width: 50%;
    }

    .subheader {
        width: 80%;
        margin: 0 auto;
    }

    .body-main, .sub-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .subheader span {
        font-size: 1rem;
    }

    .projectPicture {
        width: 52px;
        height: 52px;
        border-radius: 100%;
    }

    .userPicture {
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }

</style>