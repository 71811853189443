<script>
  export let product;
  export let createUpdateProduct;
  export let currentProduct;
  export let option;
  export let onClick;
</script>

<div class="productContainer">
  <div class="productInfoImageName">
    <img
      class="productLogo"
      src={product.icon && product.icon.data !== ""
        ? product.icon.data
        : product.pictureURL}
      alt="Product Logo"
    />
    <span class="productName">{product.name}</span>
  </div>
  <div class="optionsContainer">
    <img
      class="optionIcon"
      src="/images/edit.svg"
      alt="Edit Logo"
      on:click={() => {
        option = "update";
        createUpdateProduct = true;
        currentProduct.id = product.id;
        currentProduct.name = product.name;
        currentProduct.pictureURL = product.pictureURL;
        currentProduct = currentProduct;
      }}
    />
    <img
      class="optionIcon"
      src="/images/trash.svg"
      alt="Remove Logo"
      on:click={() => {
        option = "delete";
        onClick();
      }}
    />
  </div>
</div>

<style>
  .productLogo {
    width: 30px;
  }

  .productContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 10px 0;
    height: 30px;
  }

  .productInfoImageName {
    width: 88%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .productContainer span {
    font-size: 13px;
  }

  .productName {
    margin-left: 10px;
  }

  .optionIcon {
    width: 20px;
    cursor: pointer;
  }

  .optionsContainer {
    width: 12%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
