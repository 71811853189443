<script>
  export let title;
  export let titleIcon;

  import CityCodeItem from "./CityCodeItem.svelte";
  import EditBoxTab from "./EditBoxTab.svelte";
  import EditBoxButton from "./EditBoxButton.svelte";

  import DialogSpanFullEdit from "../modules/project-analytics/screens/components/DialogSpanFullEdit.svelte";

  import { post } from "../js/labit-connection";
  import { removeAccents } from "../js/removeAccents";

  import { onMount } from "svelte";

  let backgroundColorInput = "#d7e1ea";
  let borderColor = "var(--labit-card-gray)";

  let creating = false;
  let editing = null;
  let deleting = null;

  let currentName = "";
  let currentCode2 = "";
  let currentCode3 = "";

  let errorName = false;
  let errorCode2 = false;

  let cityCodes = [];

  let cancelTimer = null;

  const CANCEL_DELETING_TIME = 10000;

  onMount(async () => {
    await getCityCodes();
  });

  let getCityCodes = async () => {
    const response = await post(
      `select id, name, code2, code3
            from city_codes`
    );
    cityCodes = response.map((item) => {
      return {
        id: item.id,
        name: item.name,
        code2: item.code2,
        code3: item.code3,
      };
    });

    sortCityCodes();
  };

  let sortCityCodes = () => {
    cityCodes.sort((a, b) => {
      const A = removeAccents(a.name).toUpperCase();
      const B = removeAccents(b.name).toUpperCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });
    cityCodes = cityCodes;
  };

  let requestAdd = () => {
    creating = true;
    editing = null;
    deleting = null;
    currentName = "";
    currentCode2 = "";
    currentCode3 = "";
    errorName = false;
    errorCode2 = false;
    clearTimeout(cancelTimer);
  };

  let requestEdit = (index) => {
    creating = false;
    editing = cityCodes[index].id;
    deleting = null;
    currentName = cityCodes[index].name;
    currentCode2 = cityCodes[index].code2;
    currentCode3 = cityCodes[index].code3;
    errorName = false;
    errorCode2 = false;
    clearTimeout(cancelTimer);
  };

  let requestDelete = async (index) => {
    creating = false;
    editing = null;
    errorName = false;
    errorCode2 = false;
    clearTimeout(cancelTimer);
    if (!deleting) {
      deleting = cityCodes[index].id;
      cancelTimer = setTimeout(cancelDelete, CANCEL_DELETING_TIME);
    } else {
      await post(
        `delete from city_codes
                where id=${deleting}`
      );
      cityCodes = cityCodes.filter((item) => {
        return item.id !== deleting;
      });
      closeForm();
    }
  };

  let cancelDelete = () => {
    deleting = null;
  };

  let closeForm = () => {
    creating = false;
    editing = null;
    deleting = null;
    currentName = "";
    currentCode2 = "";
    currentCode3 = "";
    errorName = false;
    errorCode2 = false;
  };

  let save = async () => {
    errorName = false;
    errorCode2 = false;
    if (currentName.trim() === "") {
      errorName = true;
      return;
    }
    if (currentCode2.trim() === "") {
      errorCode2 = true;
      return;
    }
    if (creating) {
      let pos = cityCodes.findIndex((item) => {
        return (
          removeAccents(item.name.trim()).toUpperCase() ===
          removeAccents(currentName.trim()).toUpperCase()
        );
      });
      if (pos >= 0) {
        errorName = true;
        return;
      }
      pos = cityCodes.findIndex((item) => {
        return (
          removeAccents(item.code2.trim()).toUpperCase() ===
          removeAccents(currentCode2.trim()).toUpperCase()
        );
      });
      if (pos >= 0) {
        errorCode2 = true;
        return;
      }
      const response = await post(
        `insert into city_codes (name, code2, code3)
                values ('${currentName}', '${currentCode2}', '${currentCode3}')`
      );
      const cityCode = {
        id: response[0],
        name: currentName,
        code2: currentCode2,
        code3: currentCode3,
      };
      cityCodes.push(cityCode);
    } else {
      await post(
        `update city_codes set
                name='${currentName}',
                code2='${currentCode2}',
                code3='${currentCode3}'
                where id=${editing}`
      );
      let cityCode = cityCodes.find((item) => {
        return item.id === editing;
      });
      if (cityCode) {
        cityCode.name = currentName;
        cityCode.code2 = currentCode2;
        cityCode.code3 = currentCode3;
      }
    }
    sortCityCodes();
    closeForm();
  };
</script>

<div class="box">
  <div class="editBoxTab">
    <EditBoxTab icon={titleIcon} text={title} />
  </div>
  <div class="items">
    <div
      class="itemList customScrollbars {creating || editing
        ? 'itemListMin'
        : ''}"
    >
      {#each cityCodes as cityCode, index}
        <CityCodeItem
          city={cityCode}
          onEdit={() => requestEdit(index)}
          onDelete={() => requestDelete(index)}
          deletingId={deleting}
        />
      {/each}
    </div>
    <div class="plus">
      <img
        class="fadedButton"
        src="/images/plus.svg"
        alt="Add"
        on:click={() => requestAdd()}
      />
    </div>
  </div>
  <div class="form {creating || editing ? '' : 'hiddenElement'}">
    <span class="editBoxTitle requiredElement">Name</span>
    <div class="editBoxInput containerBox">
      <DialogSpanFullEdit
        color={backgroundColorInput}
        height="22"
        border={borderColor}
        error={errorName}
        bind:text={currentName}
      />
    </div>
    <div class="editBoxTitle">
      <div class="editBoxTitleHalf">
        <span class="requiredElement">2-Digit Code</span>
      </div>
      <div class="editBoxTitleHalf">
        <span>3-Digit Code</span>
      </div>
    </div>
    <div class="editBoxTitle">
      <div class="editBoxTitleHalf">
        <DialogSpanFullEdit
          color={backgroundColorInput}
          height="22"
          border={borderColor}
          error={errorCode2}
          bind:text={currentCode2}
          maxLength={2}
        />
      </div>
      <div class="editBoxTitleHalf">
        <DialogSpanFullEdit
          color={backgroundColorInput}
          height="22"
          border={borderColor}
          error={false}
          bind:text={currentCode3}
          maxLength={3}
        />
      </div>
    </div>
    <div class="editBoxAcceptCancel">
      <EditBoxButton text="Save" onClick={() => save()} />
      <EditBoxButton text="Close" onClick={() => closeForm()} />
    </div>
  </div>
</div>

<style>
  div.box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  div.editBoxTab {
    width: 428px;
    margin-bottom: 25px;
  }

  div.editBoxTitle,
  span.editBoxTitle {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 10px;
    width: 100%;
  }

  div.editBoxTitle {
    display: flex;
    justify-content: space-between;
  }

  div.editBoxTitleHalf {
    width: 48%;
    display: flex;
  }

  div.editBoxAcceptCancel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  div.editBoxInput {
    height: 22px;
    margin-bottom: 10px;
  }

  div.items {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 15px;
    border: 1px solid var(--labit-card-gray);
    padding: 15px 23px 15px 23px;
  }

  div.plus {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div.plus img {
    width: 20px;
    height: 20px;
  }

  div.itemList {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
  }

  div.itemListMin {
    max-height: 360px !important;
  }
</style>
