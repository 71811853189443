<script>
  export let selectedImage;
  export let noSelectedImage;
  export let selected;
  export let onClick;
</script>

<style>
  div.selectableMenuButtonContainer {
    width: 55px;
    height: 55px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
  }

  div.selected {
    background-color: var(--labit-card-gray);
  }

  div.noSelected {
    background-color: var(--labit-dialog-background);
  }

  img {
    height: 40%;
  }

  /* Animations */

  div.selectableMenuButtonContainer {
    transition: opacity 0.5s linear 0s;
  }

  div.selectableMenuButtonContainer:hover {
    opacity: 0.5;
  }
</style>

<div
  class="selectableMenuButtonContainer {selected ? 'selected' : 'noSelected'}"
  on:click={onClick}>
  <img src={selected ? selectedImage : noSelectedImage} alt="Button Icon" />

</div>
