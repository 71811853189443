<script>
    import { post } from '../../../js/labit-connection';
    import { getRoundOff } from '../../me/clockify/clockifyData';

    import MultiSelector from "./dropdownMenuMultiselect/DropdownContainer.svelte";
    import UsersData from "./UsersData.svelte";
    import ProjectsData from "./ProjectsData.svelte";
    import RangeDatePicker from '../../me/factorial/RangeDatePicker.svelte';
    import Chart from './Chart.svelte';
    import moment from 'moment';

    import { fillDatesArr } from '../../me/clockify/clockifyData';
    import { getColor } from '../../../js/colors';
    
    let colorOptions = { type: 'index', index: 0, transparency: 50 };

    let range = { start: moment().startOf('month'), end: moment().endOf('month') };
    let data = { byUser: {}, byProject: {} };
    let startEndDayObj = {};
    
    let [users, tasks, projects] = [[], [], []];
    let [usersDropdown, projectsDropdown] = [[], []];
    let datasets = [];
    let resultDetails = [];

    let typeOfDataToShow = { project: false, user: false };

    (async () => {
        const usersPromise = post('SELECT contact_id AS id, name as value, pictureUrl AS picture FROM people WHERE company_id = 2;');
        const projectsPromise = post('SELECT project_id AS id, CONCAT(FolderLabitCode, " - ", Name) AS value, picPath AS picture FROM projects;');
        const tasksPromise = post('SELECT t.id, t.description, t.start, t.end, t.task_type_id, t.user_id, p.project_id, CONCAT(p.FolderLabitCode, " - ", p.Name) AS project_name FROM tasks AS t JOIN phase AS ph ON t.phase_id = ph.id JOIN projects AS p ON ph.project = p.project_id;');
        const startEndPromise = post('SELECT start, end, user_id as userId from startEndDay;');
        //const tasksPromise = post('SELECT t.id, t.description, t.start, t.end, t.task_type_id, t.user_id, p.project_id, CONCAT(p.FolderLabitCode, " - ", p.Name) AS project_name FROM tasks AS t JOIN phase AS ph ON t.phase_id = ph.id JOIN projects AS p ON ph.project = p.project_id;');
        // TODO: query task types
        let startEndDay;
        [users, tasks, projects, startEndDay] = await Promise.all([usersPromise, tasksPromise, projectsPromise, startEndPromise]);
        
        data.byUser = users.reduce((acc, user) => {
            acc[user.id] = { id: user.id, name: user.value, picture: user.picture, tasks: {} };
            return acc;
        }, data.byUser);

        data.byProject = projects.reduce((acc, project) => {
            acc[project.id] = { id: project.id, name: project.value, picture: project.picture, tasks: {} };
            return acc;
        }, data.byProject);
        
        startEndDayObj = startEndDay.reduce((acc, day) => {
            const start = moment(day.start, 'YYYY-MM-DD HH:mm:ss');
            const end = moment(day.end, 'YYYY-MM-DD HH:mm:ss');
            const startStr = start.format('DD-MM-YYYY');
            const id = day.userId;
            
            if (!acc.hasOwnProperty(id)) acc[id] = {};
            
            if (!acc[id].hasOwnProperty(startStr)) {
                const duration = day.end === null ? 0 : getRoundOff(start, end) / 60000;
                acc[id][startStr] = { day: start, duration: duration };
            }
            return acc;
        }, {});
        
        data = tasks.reduce((acc, task) => {

            if (!data.byUser[task.user_id]) {
            // Continuar al siguiente ciclo si el usuario no está definido
                return acc;
            }

            const start = moment(task.start, 'x').format('DD-MM-YYYY');
            const userTasks = data.byUser[task.user_id].tasks;
            const projectTasks = data.byProject[task.project_id].tasks;
            const duration = (parseInt(task.end) - parseInt(task.start)) / 1000 / 60;

            task.duration = duration;

            if (!userTasks.hasOwnProperty(start)) userTasks[start] = [];
            userTasks[start].push(task);
        
            if (!projectTasks.hasOwnProperty(start)) projectTasks[start] = [];
            projectTasks[start].push(task);
            
            return acc;
        }, data);

        // Cleaning object from items with empty task array
        let byUserKeys = Object.keys(data.byUser);
        byUserKeys.forEach(key => {
            const length = Object.keys(data.byUser[key].tasks).length;
            if (length === 0) delete data.byUser[key];
        });

        let byProjectKeys = Object.keys(data.byProject);
        byProjectKeys.forEach(key => {
            const length = Object.keys(data.byProject[key].tasks).length;
            if (length === 0) delete data.byProject[key];
        });

        // Using just the items with tasks in the multi selector
        byUserKeys = Object.keys(data.byUser);
        usersDropdown = byUserKeys.map(key => {return { id: key, value: data.byUser[key].name }}).sort((a,b) => a.value.localeCompare(b.value));

        byProjectKeys = Object.keys(data.byProject);
        projectsDropdown = byProjectKeys.map(key => {return { id: key, value: data.byProject[key].name }}).sort((a,b) => a.value.localeCompare(b.value));
    })();

    const filterData = () => {
        const filteredUsers = usersDropdown.filter(e => e.selected);
        const filteredProjects = projectsDropdown.filter(e => e.selected);
        typeOfDataToShow = { project: false, user: false };
        
        let filteredData = { users: [], projects: [] };
        if (filteredUsers.length !== 0) {
            filteredData.users = filteredUsers.map(user => data.byUser[user.id]);
            typeOfDataToShow.user = true;
        } 
        if (filteredProjects.length !== 0) {
            filteredData.projects = filteredProjects.map(project => data.byProject[project.id]);
            typeOfDataToShow.project = true;
        }
        return filteredData;
    }

    const showData = () => {
        const filteredData = filterData();
        const datesArr = fillDatesArr(range.start, range.end, 'moment', 'DD-MM-YYYY');

        const projectsArr = typeOfDataToShow.project ? filteredData.projects.map(project => project.id) : [];
        const dataArr = typeOfDataToShow.user ? filteredData.users : filteredData.projects;

        datasets = []; //limpiar
        if (dataArr.length !== 0) {
            datasets = dataArr.map((fd, index) => {
                const datasetData = datesArr.map(date => {
                    let totalTime = fd.tasks.hasOwnProperty(date) ? 
                            fd.tasks[date].reduce((acc, task) => {
                                if (task.task_type_id !== "119" && task.task_type_id !== "118") {
                                    if (typeOfDataToShow.user && typeOfDataToShow.project) {
                                        if (projectsArr.some(proj => proj === task.project_id)) acc += task.duration;
                                    } else {
                                        acc += task.duration;
                                    }
                                }
                                return acc;
                            }, 0) : 0;

                            if (startEndDayObj.hasOwnProperty(fd.id)) {
                                if (startEndDayObj[fd.id].hasOwnProperty(date)) totalTime -= startEndDayObj[fd.id][date].duration;
                            }
                    return { id: fd.id, x: date, y: totalTime };
                });

                const notEmpty = datasetData.some(data => data.y !== 0);
                if(notEmpty) {
                    colorOptions.index = index;
                    const dataset = {
                        backgroundColor: getColor(colorOptions),
                        //barPercentage: 0.6
                        borderRadius: 7,
                        borderWidth: 0,
                        data: datasetData,
                        label: fd.name
                    }
                    return dataset;
                }
            }).filter(data => data !== undefined);
        }
    }

    const getDetails = () => {
        const filteredData = filterData();
        const datesArr = fillDatesArr(range.start, range.end, 'moment', 'DD-MM-YYYY');

        const usersArr = filteredData.users.map(user => user.id);
        //const projectsArr = filteredData.projects.map(project => project.id);
        const dataArr = typeOfDataToShow.project ? filteredData.projects : filteredData.users;

        resultDetails = [];
        if (typeOfDataToShow.project) {
            resultDetails = dataArr.map(d => { // Por cada proyecto
                let usersObj = {};
                datesArr.forEach(date => {  // Por cada día del filtro
                    if (d.tasks.hasOwnProperty(date)) {
                        d.tasks[date].forEach(task => {
                            const id = task.user_id;
                            if(typeOfDataToShow.user) {
                                if (usersArr.some(userId => id === userId)) {
                                    if (!usersObj.hasOwnProperty(id)) usersObj[id] = { days: {}, name: data.byUser[id].name, picture: data.byUser[id].picture };
                                    if (!usersObj[id].days.hasOwnProperty(date)) usersObj[id].days[date] = [];
                                    usersObj[id].days[date].push(task);
                                }
                            } else {
                                if (!usersObj.hasOwnProperty(id)) usersObj[id] = { days: {}, name: data.byUser[id].name, picture: data.byUser[id].picture };
                                if (!usersObj[id].days.hasOwnProperty(date)) usersObj[id].days[date] = [];
                                usersObj[id].days[date].push(task);
                            }
                        });
                    }
                });
                
                return {
                    project_id: d.id, 
                    project_name: d.name,
                    picture: d.picture,
                    users: usersObj
                }
            });
        } else {
            resultDetails = dataArr.map(d => { // Por cada usuario
                const { id, name, picture, tasks } = d;
                let daysObj = {};
                datesArr.forEach(day => {
                    if (tasks.hasOwnProperty(day)) {
                        const roundOffMin = startEndDayObj[id].hasOwnProperty(day) ? startEndDayObj[id][day].duration : 0;
                        daysObj[day] = { roundOffMin, tasks: tasks[day] };
                    }
                });
                // ADD HERE START AND END OF THE DAY PER USER
                return {
                    user_id: id, 
                    user_name: name,
                    picture: picture,
                    days: daysObj
                }
            });
        }
        console.log(resultDetails);
    }

    // Click on bar
    const barClicked = (evt) => {
        const day = evt.detail;
        const tasks = typeOfDataToShow === 'user' ? data.byUser[day.id].tasks[day.x] : data.byProject[day.id].tasks[day.x];

        console.log(tasks);
    }

</script>

<div class="w-100 h-100">
    <div id="dropdown-selector-container" class="w-100 flex justify-center">
        <MultiSelector defaultText=Users checked={false} bind:options={usersDropdown} />
        <MultiSelector defaultText=Projects checked={false} bind:options={projectsDropdown} />
        <RangeDatePicker returnFormat={'moment'} disableBeforeToday={false} start={range.start} end={range.end} on:close={ (ev) => { range = ev.detail } }/>
        <img class="search" src="/images/loupe.svg" alt="" on:click={() => {showData(); getDetails()}}>
    </div>
    <div id="chartContainer" class="w-100">
        <Chart {datasets} on:clicked={barClicked}/>
    </div>
    {#key resultDetails}
    {#if resultDetails.length > 0}
    <div class="w-100">
        {#if typeOfDataToShow.project}
            <ProjectsData data={resultDetails} />
        {:else if typeOfDataToShow.user}
            <UsersData data={resultDetails} />
        {/if}
    </div>
    {/if}
    {/key}
</div>

<style>
    .search {
        width: 32px;
        padding: 4px;
        cursor: pointer;
    }

    .search:hover {
        background-color: #f0f4f8;
        border-radius: 2px;
    }

    #dropdown-selector-container {
        gap: 20px;
    }

    .flex {
        display: flex;
    }

    .justify-center {
        justify-content: center;
    }

    .w-100 {
        width: 100%;
    }

    .h-100 {
        height: 100%;
    }
</style>