<script>
    import DropdownItem from './DropdownItem.svelte';
    import ClickOutside from 'svelte-click-outside';

    export let checked = true;
    export let options;
    export let defaultText = 'Select...';

    let text = '';
    let show = false;
    let button;
    let selectedValues = '';

    const resultText = () => selectedValues = options.filter(opt => opt.selected === true).map(e => e.value).join(', ');
    const toggleAllSelected = () => {
        checked = !checked;
        options = options.map((opt) => { 
            return { id: opt.id, value: opt.value, selected: checked };
        });
        resultText();
    };

    const selected = (event) => {
        const index = options.findIndex(opt => opt.id === event.detail.id);
        options[index].selected = event.detail.selected;
        checked = options.every(opt => opt.selected === true);
        resultText();
    };

    $: filteredOptions = options.filter(opt => opt.value.toLocaleLowerCase().includes(text.toLocaleLowerCase()));

    options = options.map((opt) => { return { id: opt.id, value: opt.value, selected: checked } } );
    //options = options.sort((a,b) => a.value.localeCompare(b.value));

</script>

<div class="dropdown" on:blur={ () => show = false }>
    <button bind:this={button} class="dropbtn" on:click={ () => show = !show }>
        <span class="buttonText"> { checked ? 'All' : selectedValues === '' ? defaultText : selectedValues } </span>
        <img src="/images/down_arrow.svg" alt="dropdown">
    </button>

    <ClickOutside on:clickoutside={ () => show = false } exclude={[button]}>
    <div class="dropdown-content {show ? 'd-col' : 'd-none'}">
        <div class="filter-container">
            <input type="text" placeholder="Filter" bind:value={text}>
        </div>
        <div class="select-all" on:click={toggleAllSelected}>
            <img src={checked ? "/images/checkbox.svg" : "/images/checkbox_empty.svg"} alt="Checkbox">
            <span>Select all</span>
        </div>
        <div class="options">
            <!-- {#key filteredOptions} -->
            {#each filteredOptions as option, index (index)}
                <DropdownItem {option} on:clicked={selected}/>
            {/each}
            <!-- {/key} -->
        </div>
    </div>
    </ClickOutside>
</div>

<style>

    .dropdown {
        position: relative;
        display: inline-block;
        width: 220px;
    }

    .dropdown-content {
        position: absolute;
        width: 100%;
        border-radius: 16px;
        margin-top: 4px;
        border: 1px solid #e7e7e7;
        background-color: white;
        z-index: 1;
        max-height: 500px;
        padding: 0 8px;
    }

    .dropdown-content .options {
        overflow-y: auto;
    }

    .filter-container {
        width: 100%;
        padding: 8px 0;
        border-bottom: 1px solid #e7e7e7;
    }

    .filter-container input {
        width: 100%;
        height: 100%;
        border: 1px solid #e7e7e7;
    }

    .dropbtn {
        height: 100%;
        width: 100%;
        background-color: white;
        margin: 0 !important;
        border: 1px solid #e7e7e7;
        border-radius: 16px;
        font-size: 16px;
        padding: 4px 32px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .d-col {
        display: flex;
        flex-direction: column;
    }

    .d-none {
        display: none;
    }

    button { 
        margin: 0;
    }
    
    img {
        height: 16px;
        width: 16px;
    }

    .dropdown-content .select-all {
        display: flex;
        align-items: center;
        margin: 8px 0;
        gap: 6px;
    }

    .select-all {
        cursor: pointer;
    }
    
    .buttonText {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .options {
        border-top: 1px solid #e7e7e7;
    }
    
    .options::-webkit-scrollbar {
        width: 4px;
    }

    .options::-webkit-scrollbar-track {
        background: #efefef;
    }

    .options::-webkit-scrollbar-thumb {
        background-color: #cfcfcf;
        border-radius: 20px;
    }
</style>