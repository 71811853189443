<script>

    import Select from './SelectConversor.svelte';

    let actualWorkspace = "Longitud";
    let fontSize = 50;
    let fontSize_2 = 50;
	let texto = '';
    
    const medidas = {
        Longitud: [
            {name: "Milimeters", conversion: 1000},
            {name: "Centimeters", conversion: 100},
            {name: "Decimeters", conversion: 10},
            {name: "Meters", conversion: 1},
            {name: "Dekameters", conversion: 0.1},
            {name: "Hectometers", conversion: 0.01},
            {name: "Kilometers", conversion: 0.001},
            {name: "Yard", conversion: 1.09361},
            {name: "Feet", conversion: 3.28084},
            {name: "Inches", conversion: 39.3701},
            {name: "Miles", conversion: 0.000539957}
                ],

        Peso: [
            {name: "Miligrams", conversion: 1000},
            {name: "Centigrams", conversion: 100},
            {name: "Decigrams", conversion: 10},
            {name: "Grams", conversion: 1},
            {name: "Dekagrams", conversion: 0.1},
            {name: "Hectograms", conversion: 0.01},
            {name: "Kilograms", conversion: 0.001},
            {name: "Tons", conversion: 0.000001},
            {name: "Pounds", conversion: 0.00220462},
            ],
        
        Superficie: [
            {name: "Milimeters 2", conversion: 1000000},
            {name: "Centimeters 2", conversion: 10000},
            {name: "Decimeters 2", conversion: 100},
            {name: "Meters 2", conversion: 1},
            {name: "Dekameters 2", conversion: 0.01},
            {name: "Hectometers 2", conversion: 0.0001},
            {name: "Kilometers 2", conversion: 0.000001},
            {name: "Yard 2", conversion: 1.19599},
            {name: "Feet 2", conversion: 10.7639},
            {name: "Inches 2", conversion: 1550},
            {name: "Miles 2", conversion: 0.0000003861},
            {name: "Acre", conversion: 0.000247105}
            ],
        Volumen: [ 
            {name: "Milimeters 3", conversion: 100000000},
            {name: "Centimeters 3", conversion: 1000000},
            {name: "Decimeters 3", conversion: 1000},
            {name: "Meters 3", conversion: 1},
            {name: "Dekameters 3", conversion: 0.001},
            {name: "Hectometers 3", conversion: 0.000001},
            {name: "Kilometers 3", conversion: 0.000000001},
            ],
        Moneda: [
            {name: "Euros", conversion: 1 },
            {name: "Dolar", conversion: 1.01},
            {name: "Pounds", conversion: 0.85}    
                ],
        Pixel: [
            {name: "Pt", conversion: 1},
            {name:"Px" , conversion: 1.333}    
            ]
    }

    const tipos = ["Longitud", "Peso", "Superficie", "Volumen", "Moneda", "Pixel"];

    function assignWorkspace(tipo) {
        actualWorkspace = tipo;
        console.log(tipo);
    }

    function conversion(){
    
        const conversionFactor = tipoinicio.conversion / tipofinal.conversion;
        console.log("ValorFinal es");
        console.log(valorFinal);
        valorFinal = valorInicio/ conversionFactor;
    }

    function swap(){
        let temp = tipoinicio;
        tipoinicio = tipofinal;
        tipofinal = temp;
    }

   /* const changeFontSize = (event) => {
        
        if (event === undefined)
        {
            let text = valorFinal.toString()
		    const textLength = text.length;
            if (textLength > 5 && textLength <= 10)
            {
                fontSize_2 = 40
            }
            if (textLength > 10 && textLength <= 20)
            {
                fontSize_2 = 30
            }
            if (textLength > 20 && textLength <= 30)
            {
                fontSize_2 = 20
            }
            if (textLength > 30)
            {
                fontSize_2 = 10
            }

        }
        let text = valorInicio.toString()
		const textLength = text.length;
        if(event.key === "Backspace" && textLength == 0){
            fontSize = 50;
        }
		else if (event.key === "Backspace"){
			if(fontSize < 50){
				if (textLength % 6 === 0) fontSize += 10;
			}
		}
		else if(fontSize > 10)
			{
				if (textLength % 3 === 0) fontSize -= 10;
			}
        
    }*/

    let tipoinicio = medidas[[tipos[0]]][0];
    let tipofinal = medidas[[tipos[0]]][1];

    let valorInicio = 0;
    let valorFinal = 0;

    let selected;


    
    /* $: console.log(tipoinicio);
    $: console.log(tipofinal);
    $: console.log(valorInicio);
    $: console.log(selected); */


    
</script>

<div class="component-container">
    <div class="start-part">
        <div class="icons-container">
                <Select items={tipos} bind:choosen={selected}
                    on:change= { assignWorkspace(selected)} 
                    on:change= { () => tipoinicio = medidas[selected][0]} 
                    on:change= { () => tipofinal = medidas[selected][1]}/> 
            
        </div>
        <div class= "input-container">
            <div class="start-input">
                <input type="text" placeholder = {valorInicio} bind:value = {valorInicio} on:keyup={conversion} />
    
              
            </div>
            <div class="start-select">
                <Select prepared={medidas[actualWorkspace]} mode = "1" 
                    bind:choosen={tipoinicio}
                    on:change = {conversion}/>

            </div>
        </div>
    </div>
    <div class="end-part">
        <div class="end-input">
            <input type="text" id="txt" readonly="readonly" placeholder = {valorFinal}/>
        </div>
        <div class="end-select">
            <Select prepared={medidas[actualWorkspace]} mode = "2" 
                    bind:choosen={tipofinal}
                    other = {tipoinicio}
                    on:change = {conversion}/>

        </div>
    </div>
    <div class= "button-swap">
        <img on:click = {swap} 
            on:click = {conversion}
            class="change-units" src="/images/new/change.svg" alt="">
    </div>
</div>

<style>
    .change-units {
        width: 70%;
    }
    .button-swap{
        position: absolute;
        right: 27px;
        top: 250px; 
        width: 40px;
        height: 26px;
        position: absolute;
        cursor:pointer;
        display:flex;
        text-align: center;
        background-color: white;
        border-radius: 18px;
        /*border: 2px solid;*/
        border-color:#F4F4F4; 
        justify-content: center;
        box-shadow: 0px 3px 3px #718DC23D;
        
    }
    /*COMPONENT CONTAINER*/
    .component-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 260px;
        height: 460px;
        border-radius: 15px;
        background-color: white;
        align-items: center;
        justify-items: center;
    }

        /*ENTRY PART*/
        .start-part{
            background: #8AAAD1 0% 0% no-repeat padding-box;
            height: 264px;
            border-radius: 20px 20px 0px 0px;
            opacity: 1;
            width: 100%;
            display:flex;
            flex-direction: column;
        }
            /*ICON SELECTOR*/
            .icons-container {
                display: flex;
                height: 52px;
                flex-direction: row;
                justify-content: center;
                padding: 15px 45px 0px 45px;
            }


        
        
            /*INPUT CONTAINER*/
            .input-container{
                display:flex;
                flex-direction: row;
                height:100%;
                width:100%;
            }

            .start-input{
                display:flex;
                justify-content: center;
                height:80px;
                width: 100%;
                padding: 90px 4px 20px 20px;
            }
            .start-input>input{
                width: 135px;
                height:55px;
                background-color: transparent;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom-color: rgba(255, 255, 255, 0.8);
                color: rgba(255, 255, 255, 0.8);
                padding: 0px 0px 10px 0px;
                font-weight: 400;
                font-size:30px;
            }
            .start-input>input::placeholder{
                color:white;
            }
            .start-input>input:focus-visible{
                outline:none;
            }
            .start-select{
                display:flex;
                padding: 105px 0px 85px 0px;
            }
           

        /*END PART*/
        .end-part{
            display:flex;
            flex-direction:row;
            width:100%;
        }

        .end-input{
                justify-content: center;
                height:80px;
                width: 100%;
                padding: 90px 4px 20px 20px;
            }
        .end-input>input{
            width: 135px;
            background-color: transparent;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom-color: #35425B;
            color: #35425B;
            padding: 0px 0px 10px 0px;
            font-size:30px;
            font-weight: 400;
        }
        .end-input>input::placeholder{
            color: #35425B;
        }
        .end-input>input:focus-visible{
            outline:none;
        }
        .end-select{
            display:flex;
            padding: 105px 0px 85px 0px;
        }



</style>
