<script>
  export let idCompany = null;
  export let idVersion = null;
  export let idProject = null;
  export let setPrintView = false;

  import Menu from "../components/Menu.svelte";
  import SubmenuCombobox from "../components/SubmenuCombobox.svelte";
  import SubmenuButton from "../components/SubmenuButton.svelte";
  import SubmenuIcon from "../components/SubmenuIcon.svelte";
  import SubmenuButtonIcon from "../components/SubmenuButtonIcon.svelte";
  import SubmenuTextLights from "../components/SubmenuTextLights.svelte";
  import SubmenuMultipleSelect from "../components/SubmenuMultipleSelect.svelte";
  import RDSColumn from "../components/RDSColumn.svelte";
  import Loading from "../components/Loading.svelte";
  import PrintOptions from "../components/PrintOptions.svelte";

  import { post } from "../js/labit-connection";
  import {
    RDSCreateConflictTable,
    RDSAddRoomLevel,
    RDSEmptyTable,
    RDSFillTable,
    RDSUpdateTable,
    RDSAddExceptions,
  } from "../js/rds-conflicts-exceptions";
  import { openURL } from "../js/openURL";
  import { openNewWindow } from "../js/openNewWindow";
  import getClients from "../js/clients";

  import { onMount } from "svelte";

  import jsPDF from "jspdf";

  const PAGE = "RESOURCES";

  let companies = [];
  let selectedCompany = null;
  let versions = [];
  let selectedVersion = null;
  let versionName = [];
  let roomsInfo = [];
  let projects = [];
  let selectedProject = null;
  let dynamo = null;

  let programs = [];
  let roomDataSheet = [];

  let loading = false;

  let printView = setPrintView;

  let filterRed = false;
  let filterYellow = false;
  let filterGreen = false;

  let filterEpigraph = [];
  let selFilterEpigraph = [];
  let filterSpace = [];
  let selFilterSpace = [];
  let filterType = [];
  let selFilterType = [];

  let idCompany_ = null;
  let idVersion_ = null;
  let idProject_ = null;

  onMount(async () => {
    getCompanies();
  });

  let getCompanies = async () => {
    //console.log("GETCOMPANIES");
    if (!loading) {
      if (
        selectedCompany &&
        selectedCompany.value === idCompany_ &&
        selectedVersion &&
        selectedVersion.value === idVersion_
      ) {
        loading = false;
        idCompany_ = false;
        idVersion_ = false;
        idProject_ = false;
      } else {
        companies = [];
        selectedCompany = null;
        versions = [];
        selectedVersion = null;
        versionName = [];
        roomsInfo = [];
        projects = [];
        selectedProject = null;
        dynamo = null;
        //console.log("GETCOMPANIES");
        /*const response = await post(
          `select company_id, Name
        from companies
        where category_id=3`
        );*/
        const response = await getClients();
        companies = response.map((item) => {
          return {
            value: item.company_id,
            label: item.Name,
          };
        });
        if (idCompany_) {
          selectedCompany = companies.find((item) => {
            return item.value === idCompany_;
          });
        } else {
          selectedCompany = companies[0];
        }
        changeCompany();
      }
    }
  };

  let changeCompany = () => {
    //console.log("CHANGECOMPANY");
    versions = [];
    selectedVersion = null;
    versionName = "";
    projects = [];
    selectedProject = null;
    programs = [];
    roomDataSheet = [];

    getVersions();
  };

  let getVersions = async () => {
    //console.log("GETVERSIONS");
    const response = await post(
      `select standards_versions_id, standard_name
        from standards_versions
        where company_id=${selectedCompany.value}`
    );
    versions = response.map((item) => {
      return {
        value: item.standards_versions_id,
        label: item.standard_name,
      };
    });
    if (versions.length > 0) {
      if (idVersion_) {
        selectedVersion = versions.find((item) => {
          return item.value === idVersion_;
        });
        //idVersion = null;
      } else {
        selectedVersion = versions[0];
      }
      changeVersion();
    } else {
      selectedVersion = null;
      loading = false;
      idCompany_ = false;
      idVersion_ = false;
      idProject_ = false;
    }
  };

  let changeVersion = () => {
    //console.log("CHANGEVERSION");
    projects = [];
    selectedProject = null;
    programs = [];
    roomDataSheet = [];
    getProjects();
  };

  let getProjects = async () => {
    //console.log("GETPROJECTS");
    const response = await post(
      `select project_id, Name
        from projects
        where standards_versions_id=${selectedVersion.value}
        order by Name asc`
    );
    projects = response.map((item) => {
      return {
        value: item.project_id,
        label: item.Name,
      };
    });
    if (projects.length > 0) {
      if (idProject_) {
        selectedProject = projects.find((item) => {
          return item.value === idProject_;
        });
        //idProject = null;
      } else {
        selectedProject = projects[0];
      }
      changeProject();
    } else {
      selectedProject = null;
      loading = false;
      idCompany_ = false;
      idVersion_ = false;
      idProject_ = false;
    }
  };

  let changeProject = async () => {
    //console.log("CHANGEPROJECT");
    programs = [];
    roomDataSheet = [];
    if (!loading) {
      loadInfo();
    }
  };

  let createStatistics = () => {
    let pos = -1;
    programs.forEach((program) => {
      let listEpigraphs = [];
      program.spaces.forEach((space) => {
        space.rooms.forEach((room) => {
          if (room.conflictTable) {
            room.conflictTable.forEach((row) => {
              row.forEach((conflict) => {
                let ep = null;
                switch (conflict.status) {
                  case "ok":
                    room.numGreen++;
                    space.numGreen++;
                    program.numGreen++;
                    ep = listEpigraphs.find((item) => {
                      return item === conflict.epigraph;
                    });
                    if (!ep) {
                      listEpigraphs.push({
                        name: conflict.epigraph,
                        status: "ok",
                      });
                    }
                    break;
                  case "noOk":
                    room.numRed++;
                    space.numRed++;
                    program.numRed++;
                    ep = listEpigraphs.find((item) => {
                      return item === conflict.epigraph;
                    });
                    if (!ep) {
                      listEpigraphs.push({
                        name: conflict.epigraph,
                        status: "noOk",
                      });
                    } else {
                      ep.status = "noOk";
                    }
                    break;
                  case "exceptions":
                    room.numYellow++;
                    space.numYellow++;
                    program.numYellow++;
                    ep = listEpigraphs.find((item) => {
                      return item === conflict.epigraph;
                    });
                    if (!ep) {
                      listEpigraphs.push({
                        name: conflict.epigraph,
                        status: "exceptions",
                      });
                    } else {
                      if (ep.status === "ok") ep.status = "exceptions";
                    }
                    break;
                }
              });
            });
            const total = room.numGreen + room.numYellow + room.numRed;
            if (total > 0) {
              room.pRed = Math.ceil((room.numRed / total) * 100);
              room.pYellow = Math.ceil((room.numYellow / total) * 100);
              room.pGreen = 100 - room.pYellow - room.pRed;
            }

            listEpigraphs.forEach((item) => {
              if (item.status === "ok") {
                program.standard.NEpigraphs++;
              } else {
                if (item.status === "exceptions") {
                  program.replaced.NEpigraphs++;
                } else {
                  program.wrong.NEpigraphs++;
                }
              }
            });
          }
          const total = space.numGreen + space.numYellow + space.numRed;
          if (total > 0) {
            space.pRed = Math.ceil((space.numRed / total) * 100);
            space.pYellow = Math.ceil((space.numYellow / total) * 100);
            space.pGreen = 100 - space.pYellow - space.pRed;
          }
        });
      });
      let total = program.numGreen + program.numYellow + program.numRed;
      if (total > 0) {
        program.pRed = Math.ceil((program.numRed / total) * 100);
        program.pYellow = Math.ceil((program.numYellow / total) * 100);
        program.pGreen = 100 - program.pYellow - program.pRed;
      }

      total =
        program.standard.NEpigraphs +
        program.replaced.NEpigraphs +
        program.wrong.NEpigraphs;
      program.standard.NEpigraphs = Math.ceil(
        (program.standard.NEpigraphs / total) * 100
      );
      program.replaced.NEpigraphs = Math.ceil(
        (program.replaced.NEpigraphs / total) * 100
      );
      program.wrong.NEpigraphs = Math.ceil(
        (program.wrong.NEpigraphs / total) * 100
      );

      program.wrong.NSpaces = program.pRed;
      program.replaced.NSpaces += program.pYellow;
      program.standard.NSpaces += program.pGreen;
    });

    roomDataSheet = programs.map((program) => {
      return {
        id: program.id,
        programId: program.programId,
        icon: program.icon,
        color: program.color,
        textColor: "white",
        charts: {
          standard: {
            NSpaces: "",
            NEpigraphs: "",
          },
          replaced: {
            NSpaces: "",
            NEpigraphs: "",
          },
          wrong: {
            NSpaces: "",
            NEpigraphs: "",
          },
        },
        items: [],
      };
    });

    roomDataSheet.forEach((RDS) => {
      const program = programs.find((item) => {
        return item.id.toUpperCase() === RDS.id.toUpperCase();
      });
      const totalSpaces =
        program.standard.NSpaces +
        program.replaced.NSpaces +
        program.wrong.NSpaces;
      const totalEpigraphs =
        program.standard.NEpigraphs +
        program.replaced.NEpigraphs +
        program.wrong.NEpigraphs;
      let pReplacedNSpaces =
        totalSpaces === 0
          ? "N/A"
          : Math.ceil((program.replaced.NSpaces / totalSpaces) * 100);
      let pWrongNSpaces =
        totalSpaces === 0
          ? "N/A"
          : Math.ceil((program.wrong.NSpaces / totalSpaces) * 100);
      let pStandardNSpaces =
        totalSpaces === 0 ? "N/A" : 100 - pReplacedNSpaces - pWrongNSpaces;
      let pReplacedNEpigraphs =
        totalEpigraphs === 0
          ? "N/A"
          : Math.ceil((program.replaced.NEpigraphs / totalEpigraphs) * 100);
      let pWrongNEpigraphs =
        totalEpigraphs === 0
          ? "N/A"
          : Math.ceil((program.wrong.NEpigraphs / totalEpigraphs) * 100);
      let pStandardNEpigraphs =
        totalEpigraphs === 0
          ? "N/A"
          : 100 - pReplacedNEpigraphs - pWrongNEpigraphs;
      if (pReplacedNSpaces !== "N/A") {
        pReplacedNSpaces = pReplacedNSpaces + "%";
      }
      if (pWrongNSpaces !== "N/A") {
        pWrongNSpaces = pWrongNSpaces + "%";
      }
      if (pStandardNSpaces !== "N/A") {
        pStandardNSpaces = pStandardNSpaces + "%";
      }
      if (pReplacedNEpigraphs !== "N/A") {
        pReplacedNEpigraphs = pReplacedNEpigraphs + "%";
      }
      if (pWrongNEpigraphs !== "N/A") {
        pWrongNEpigraphs = pWrongNEpigraphs + "%";
      }
      if (pStandardNEpigraphs !== "N/A") {
        pStandardNEpigraphs = pStandardNEpigraphs + "%";
      }

      RDS.charts = {
        standard: {
          NSpaces: pStandardNSpaces,
          NEpigraphs: pStandardNEpigraphs,
        },
        replaced: {
          NSpaces: pReplacedNSpaces,
          NEpigraphs: pReplacedNEpigraphs,
        },
        wrong: {
          NSpaces: pWrongNSpaces,
          NEpigraphs: pWrongNEpigraphs,
        },
      };

      RDS.items = [];
      program.spaces.forEach((space) => {
        space.rooms.forEach((room) => {
          const total = room.numGreen + room.numYellow + room.numRed;
          const pRed = Math.ceil((room.numRed / total) * 100);
          const pYellow = Math.ceil((room.numYellow / total) * 100);
          const pGreen = 100 - pYellow - pRed;
          const item = {
            idProgram: program.programId,
            idSpace: space.id,
            idRoom: room.id,
            nameSpace: space.name,
            nameRoom: room.name,
            title: space.name + " - " + room.name,
            status: {
              ok: room.numGreen > 0,
              warning: room.numYellow > 0,
              error: room.numRed > 0,
              numGreen: pGreen,
              numYellow: pYellow,
              numRed: pRed,
            },
          };
          RDS.items.push(item);
        });
      });
    });

    programs = programs;
    roomDataSheet = roomDataSheet;
  };

  let loadInfo = async () => {
    //console.log("LOADINFO");
    loading = true;

    let response = await post(
      `select program_type_id, Name, color, iconUrl
        from program_types
        where standards_version_id=${selectedVersion.value}
        order by program_type_id asc`
    );

    programs = response.map((item) => {
      return {
        id: item.Name.trim().toUpperCase(),
        programId: item.program_type_id,
        color: item.color,
        icon: item.iconUrl,
        spaces: [],
        numRed: 0,
        numYellow: 0,
        numGreen: 0,
        pRed: -1,
        pYellow: -1,
        pGreen: -1,
        standard: {
          NSpaces: 0,
          NEpigraphs: 0,
        },
        replaced: {
          NSpaces: 0,
          NEpigraphs: 0,
        },
        wrong: {
          NSpaces: 0,
          NEpigraphs: 0,
        },
      };
    });

    response = await post(
      `select space_name_id, sn.Name as spaceName, pt.Name as programName
        from space_names as sn
        left join program_types as pt on pt.program_type_id=sn.program_type_id
        where standards_version_id=${selectedVersion.value}`
    );

    response.forEach((item) => {
      const space = {
        id: item.space_name_id,
        name: item.spaceName,
        rooms: [],
        numRed: 0,
        numYellow: 0,
        numGreen: 0,
        pRed: -1,
        pYellow: -1,
        pGreen: -1,
      };
      let program = programs.find((p) => {
        return p.id === item.programName.trim().toUpperCase();
      });
      if (program) {
        program.spaces.push(space);
      }
    });
    //console.log(programs);

    for (let p = 0; p < programs.length; p++) {
      const program = programs[p];
      for (let s = 0; s < program.spaces.length; s++) {
        let space = program.spaces[s];

        // Rooms

        let response = await post(
          `select space_type_id, st.Name as spaceTypeName, description, long_description, room_name, sn.Name spaceNameName
            from space_types as st
            left join space_names as sn on sn.space_name_id=st.space_name_id
            where st.space_name_id=${space.id}`
        );
        roomsInfo = response.map((item) => {
          return {
            id: item.space_type_id,
            name: item.spaceTypeName,
            roomName: item.room_name,
            numRed: 0,
            numYellow: 0,
            numGreen: 0,
            pRed: -1,
            pYellow: -1,
            pGreen: -1,
            conflictTable: null,
          };
        });

        for (let r = 0; r < roomsInfo.length; r++) {
          const room = roomsInfo[r];
          response = await post(
            `select inputs_id, input
              from dynamo_inputs
              where project_name='${selectedProject.label}' and room_name='${room.roomName}'
              order by updated desc`
          );
          if (response.length > 0) {
            const dynamoId = response[0].inputs_id;
            dynamo = JSON.parse(response[0].input);

            let conflictTable = await getItems(room.id, dynamoId, dynamo);

            //space.conflictTables.push(conflictTable);
            room.conflictTable = conflictTable;

            space.rooms.push(room);
          }
        }
        //console.log(roomsInfo);
      }
    }

    //console.log(programs);

    createStatistics();

    //console.log(programs);

    updateFilterEpigraph();

    // Storing % for project dashboard
    await post(
      `delete from projectRDS
        where project=${selectedProject.value}`
    );
    for (let i = 0; i < roomDataSheet.length; i++) {
      try {
        const rds = roomDataSheet[i];
        const completed = parseInt(
          rds.charts.standard.NSpaces.replace("%", "")
        );
        await post(
          `insert into projectRDS (program, project, completed)
          values (${rds.programId}, ${selectedProject.value}, ${completed})`
        );
      } catch (e) {}
    }
    //console.log(roomDataSheet);

    loading = false;
    idCompany_ = false;
    idVersion_ = false;
    idProject_ = false;
  };

  let getItems = async (roomId, dynamoId, dynamo) => {
    let exceptions = await post(
      `select id, roomNumbering, posTableRow, posTableColumn, posTableConflict, type, description, replacingValue, typeId, attributeId, text
        from exceptions
        where spaceId=${roomId}
        order by id desc`
    );
    //console.log(exceptions);

    let response = await post(
      `select distinct a.attribute_id as categoryId, a.Name as categoryName, e.Name as epigraphName, a.sequence
      from attributes as a
      left join epigraphs as e on a.epigraph_id=e.epigraph_id
      left join types as t on t.attribute_id=a.attribute_id
      where a.sequence!=0 and t.space_type_id=${roomId}
      order by a.attribute_id asc`
    );

    let conflictTable = [];

    if (response.length > 0) {
      let keys = [];

      for (let i = 0; i < 10; i++) {
        let epigraphName = "";
        let epigraphIcon = "";
        let epigraphId = -1;
        switch (i) {
          case 0:
            epigraphName = "ROOM IDENTITY";
            epigraphIcon = "/images/rds-icons/white/room-identity.svg";
            epigraphId = 1;
            break;
          case 1:
            epigraphName = "GENERAL REQUIREMENTS";
            epigraphIcon = "/images/rds-icons/white/general-requirements.svg";
            epigraphId = 2;
            break;
          case 2:
            epigraphName = "ARCHITECTURE";
            epigraphIcon = "/images/rds-icons/white/architecture.svg";
            epigraphId = 3;
            break;
          case 3:
            epigraphName = "ACCESS";
            epigraphIcon = "/images/rds-icons/white/access.svg";
            epigraphId = 4;
            break;
          case 4:
            epigraphName = "AUDIOVISUAL";
            epigraphIcon = "/images/rds-icons/white/audiovisual.svg";
            epigraphId = 5;
            break;
          case 5:
            epigraphName = "POWER & DATA";
            epigraphIcon = "/images/rds-icons/white/power-data.svg";
            epigraphId = 6;
            break;
          case 6:
            epigraphName = "LIGHTING";
            epigraphIcon = "/images/rds-icons/white/lighting.svg";
            epigraphId = 7;
            break;
          case 7:
            epigraphName = "APPLIANCES";
            epigraphIcon = "/images/rds-icons/white/appliances.svg";
            epigraphId = 8;
            break;
          case 8:
            epigraphName = "FURNISHING";
            epigraphIcon = "/images/rds-icons/white/furnishing.svg";
            epigraphId = 9;
            break;
          case 9:
            epigraphName = "HVAC";
            epigraphIcon = "/images/rds-icons/white/hvac.svg";
            epigraphId = 10;
            break;
        }

        const categories = response.reduce((acc, curr) => {
          if (curr.epigraphName.toUpperCase() === epigraphName) {
            const category = {
              id: curr.categoryId,
              name: curr.categoryName,
              sequence: curr.sequence,
              keys: [],
            };
            acc.push(category);
          }
          return acc;
        }, []);

        const key = {
          id: epigraphName,
          icon: epigraphIcon,
          epId: epigraphId,
          categories,
        };

        keys.push(key);
      }

      response = await post(
        `select type_id, Name, attribute_id
          from types 
          where space_type_id=${roomId}
          order by type_id asc`
      );

      response.forEach((type) => {
        let found = false;
        for (let i = 0; i < keys.length && !found; i++) {
          let key = keys[i];
          for (let j = 0; j < key.categories.length && !found; j++) {
            let category = key.categories[j];
            if (category.id === type.attribute_id) {
              found = true;
              const newType = {
                id: type.type_id,
                name: type.Name,
                status: "",
              };
              category.keys.push(newType);
            }
          }
        }
      });

      keys = keys;

      keys = RDSAddRoomLevel(keys);

      //console.log(keys);

      conflictTable = RDSCreateConflictTable(keys, dynamo);
      conflictTable = RDSEmptyTable(dynamo, conflictTable);
      conflictTable = RDSFillTable(keys, dynamo, conflictTable);

      // Assigning saved exceptions
      conflictTable = RDSAddExceptions(conflictTable, exceptions);

      conflictTable = RDSUpdateTable(keys, conflictTable);
      //console.log(conflictTable);
    }

    //console.log(conflictTable);

    return conflictTable;
  };

  let printMode = (active) => {
    printView = active;
  };

  let printPDF = () => {
    let options = {};
    let content = window.$("#RDSBody");
    let pdf = new jsPDF("l", "px", [content.width(), content.height()]);
    pdf.internal.scaleFactor = 1;
    pdf.addHTML(content, 15, 15, options, function () {
      pdf.save("RoomDataSheet.pdf");
    });
  };

  let toggleFilterLight = (color) => {
    switch (color) {
      case "red":
        filterRed = !filterRed;
        break;
      case "yellow":
        filterYellow = !filterYellow;
        break;
      case "green":
        filterGreen = !filterGreen;
        break;
    }
  };

  let updateFilterEpigraph = () => {
    filterEpigraph = programs.map((key) => {
      return {
        label: key.id,
        value: key.id,
      };
    });
    updateFilterSpace();
  };

  let updateFilterSpace = () => {
    filterSpace = [];
    programs.forEach((key) => {
      const pos = selFilterEpigraph.findIndex((fE) => {
        return fE === key.id;
      });
      if (selFilterEpigraph.length === 0 || pos >= 0) {
        key.spaces.forEach((space) => {
          filterSpace.push({
            label: space.name,
            value: space.name,
          });
        });
      }
    });
    updateFilterType();
  };

  let updateFilterType = () => {
    filterType = [];
    programs.forEach((key) => {
      const pos = selFilterEpigraph.findIndex((fE) => {
        return fE === key.id;
      });
      if (selFilterEpigraph.length === 0 || pos >= 0) {
        key.spaces.forEach((space) => {
          const pos = selFilterSpace.findIndex((fS) => {
            return fS === space.name;
          });
          if (selFilterSpace.length === 0 || pos >= 0) {
            space.rooms.forEach((type) => {
              filterType.push({
                label: space.name + " - " + type.name,
                value: space.name + " - " + type.name,
              });
            });
          }
        });
      }
    });
  };

  let clearFilters = () => {
    selFilterEpigraph = [];
    selFilterSpace = [];
    selFilterType = [];
    filterGreen = false;
    filterYellow = false;
    filterRed = false;
  };

  $: {
    updateFilterSpace();
    if (selFilterEpigraph.length > 0) {
      let sels = [];
      selFilterEpigraph.forEach((selK) => {
        const key = programs.find((key) => {
          return key.id === selK;
        });
        if (key) {
          key.spaces.forEach((space) => {
            const pos = selFilterSpace.findIndex((selF) => {
              return space.name === selF;
            });
            if (pos >= 0) {
              sels.push(space.name);
            }
          });
        }
      });
      selFilterSpace = sels;
    }
  }

  $: {
    updateFilterSpace();
    if (selFilterEpigraph.length > 0) {
      let sels = [];
      selFilterEpigraph.forEach((selK) => {
        const key = programs.find((key) => {
          return key.id === selK;
        });
        if (key) {
          key.spaces.forEach((space) => {
            const pos = selFilterSpace.findIndex((selF) => {
              return space.name === selF;
            });
            if (pos >= 0) {
              sels.push(space.name);
            }
          });
        }
      });
      selFilterSpace = sels;
    }
  }

  $: if (idCompany || idVersion || idProject) {
    idCompany_ = idCompany;
    idVersion_ = idVersion;
    idProject_ = idProject;
    getCompanies();
  }

  $: if (setPrintView) {
    console.log(setPrintView);
    printMode(true);
  }
</script>

<div class="content">
  <!-- Menu Options -->

  <div class="optionsMenu" style={printView ? "display: none" : ""}>
    <div class="optionsRow firstRow">
      <div class="optionsColumn1">
        <SubmenuIcon
          img="/images/book.svg"
          onClick={() => {
            if (selectedCompany && selectedVersion) {
              openURL(
                "/roomdatasheetg/" +
                  selectedCompany.value +
                  "/" +
                  selectedVersion.value,
                true
              );
            }
          }}
          onRightClick={() => {
            if (selectedCompany && selectedVersion) {
              openNewWindow(
                "/skylab-main/roomdatasheetg/" +
                  selectedCompany.value +
                  "/" +
                  selectedVersion.value
              );
            }
          }}
        />
        <div class="submenuBox">
          <div>
            <SubmenuCombobox
              text="Company"
              options={companies}
              bind:selectedValue={selectedCompany}
              placeholder=""
              onChange={() => changeCompany()}
              backgroundColor="var(--labit-dialog-background)"
              disabled={loading}
            />
          </div>
          <div>
            <SubmenuCombobox
              text="Version"
              options={versions}
              bind:selectedValue={selectedVersion}
              placeholder=""
              width="307px"
              onChange={() => changeVersion()}
              backgroundColor="var(--labit-dialog-background)"
              disabled={loading}
            />
          </div>
        </div>
        <SubmenuIcon
          img="/images/factory.svg"
          onClick={() => {
            if (selectedProject) {
              openURL("/projects/" + selectedProject.value, true);
            }
          }}
          onRightClick={() => {
            if (selectedProject) {
              openNewWindow("/skylab-main/projects/" + selectedProject.value);
            }
          }}
        />
        <div class="submenuBox">
          <div>
            <SubmenuCombobox
              text="Project"
              options={projects}
              bind:selectedValue={selectedProject}
              placeholder=""
              width="326px"
              onChange={() => changeProject()}
              backgroundColor="var(--labit-dialog-background)"
              disabled={loading}
            />
          </div>
        </div>
        <SubmenuButtonIcon
          img="/images/chain.svg"
          backgroundColor="var(--labit-dialog-background)"
          onClick={() => {
            if (selectedCompany && selectedVersion) {
              openURL(
                "/roomdatasheetl/" +
                  selectedCompany.value +
                  "/" +
                  selectedVersion.value,
                true
              );
            }
          }}
          onRightClick={() => {
            if (selectedCompany && selectedVersion) {
              openNewWindow(
                "/skylab-main/roomdatasheetl/" +
                  selectedCompany.value +
                  "/" +
                  selectedVersion.value
              );
            }
          }}
        />
        <SubmenuButtonIcon
          img="/images/print.svg"
          backgroundColor="var(--labit-dialog-background)"
          onClick={() => printMode(true)}
        />
      </div>
    </div>

    <div class="optionsRow lastRow">
      <div class="optionsColumn1">
        <SubmenuMultipleSelect
          text="Programme"
          options={filterEpigraph}
          bind:selected={selFilterEpigraph}
          icon="/images/program.svg"
          width="269px"
        />
        <SubmenuMultipleSelect
          text="Space"
          options={filterSpace}
          bind:selected={selFilterSpace}
          icon="/images/space.svg"
          width="326px"
        />
        <SubmenuMultipleSelect
          text="Type"
          options={filterType}
          bind:selected={selFilterType}
          icon="/images/room.svg"
          width="480px"
        />
        <SubmenuTextLights
          text="Status"
          onClickGreen={() => toggleFilterLight("green")}
          onClickYellow={() => toggleFilterLight("yellow")}
          onClickRed={() => toggleFilterLight("red")}
          selectedRed={filterRed}
          selectedYellow={filterYellow}
          selectedGreen={filterGreen}
        />
        <SubmenuButton
          text="Clear all"
          img="/images/x.svg"
          onClick={() => clearFilters()}
        />
      </div>
    </div>
  </div>

  <!-- Body -->
  <div
    class="RDSBody"
    id="RDSBody"
    style="background-color: {printView ? 'white' : 'transparent'}"
  >
    {#if loading}
      <Loading marginTop="20px " />
    {/if}
    {#if roomDataSheet && selectedCompany && selectedVersion && selectedProject}
      {#each roomDataSheet as RDS}
        <RDSColumn
          title={RDS.id.toUpperCase()}
          RDSInfo={RDS}
          selectedCompany={selectedCompany.value}
          selectedVersion={selectedVersion.value}
          selectedProject={selectedProject.value}
          {selFilterEpigraph}
          {selFilterSpace}
          {selFilterType}
          {filterRed}
          {filterYellow}
          {filterGreen}
        />
      {/each}
    {/if}
  </div>
</div>

{#if printView}
  <PrintOptions
    onClose={() => printMode(false)}
    onPrintPDF={() => printPDF()}
  />
{/if}

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin-top: 20px;
    color: var(--labit-card-gray);
    flex-direction: column;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  div.lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  div.RDSBody {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
  }

  div.submenuBox {
    height: 52px;
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 13px;
    padding-right: 13px;
  }

  div.submenuBox div:not(:last-child) {
    margin-right: 13px;
  }
</style>
