<script>
  export let selectedValue;
  export let options;
  export let action;
  export let last;
  export let searchable = false;
  export let error = false;

  import Combobox from "./Combobox.svelte";
</script>

{#if options.length > 0}
  {#if action === "show"}
    <span class="columnInfo {last ? 'last' : ''}"
      >{selectedValue ? selectedValue.label : ""}</span
    >
  {:else}
    <div class="element {last ? 'last' : ''}">
      <Combobox
        {options}
        placeholder=""
        bind:selectedValue
        {searchable}
        {error}
      />
    </div>
  {/if}
{/if}

<style>
  span.columnInfo {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-bottom: 8px;
  }

  div.element {
    width: 100%;
    height: 17px;
    margin-bottom: 8px;
  }

  .last {
    margin-bottom: 0px !important;
  }

  span:empty:before {
    content: "\200b";
  }
</style>
