<script>
    import Absences from "./absences/Absences.svelte";
    import TimeReports from "./timeReports/TimeReports.svelte";
    import Holidays from "./holidays/Holidays.svelte";
    import Calendars from "./calendar/Main.svelte";
    import Modal from 'svelte-simple-modal';

    import { getRole } from "../../js/localInfo";

    let myRole = getRole();

    if (myRole !== "Admin") {
        location.href = "/skylab-main";
    }

    let visible = "absences";
    
</script>
<div class="dialogContent">
    <div class="main flex">
        <div class="left-menu white-container flex-col">
            <button class="left-menu-btn {visible === "absences" ? "btn-selected" : ""}" on:click={ () => visible="absences" }>Absences</button>
            <button class="left-menu-btn {visible === "calendar" ? "btn-selected" : ""}" on:click={ () => visible="calendar" }>Calendar</button>
            <button class="left-menu-btn {visible === "reports" ? "btn-selected" : ""}" on:click={ () => visible="reports" }>Reports</button>
            <button class="left-menu-btn {visible === "holidays" ? "btn-selected" : ""}" on:click={ () => visible="holidays" }>Holidays</button>
        </div>
        <div class="principal white-container flex-col flex-center">
            <Modal closeButton={false}>
            {#if visible === "absences"}
                <Absences />
            {:else if visible === "calendar"}
                <Calendars />
            {:else if visible === "reports"}
                <TimeReports />
            {:else if visible === "holidays"}
                <Holidays />
            {/if}
            </Modal>
        </div>
    </div>
</div>

<svelte:head>
<title>SkyLab - Inventory</title>
</svelte:head>

<style>
    .white-container {
		background-color: white;
		border-radius: 30px;
	}

    div.dialogContent {
        background-color: var(--labit-background-gray);
        width: 1640px;
        min-height: 97%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        flex-direction: column;
    }

    .main {
        min-height: 98%;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    .left-menu {
        height: 100%;
        width: 10%;
        padding: 20px;
        padding-top: 60px;
    }

    .principal {
        height: 100%;
        width: 89.5%;
        padding: 30px;
        overflow: hidden;
    }

    .flex {
        display: flex;
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    .flex-center {
        align-items: center;
    }

    button {
        margin: 0;
    }

    .left-menu-btn {
        color: #696969;
        background-color: #ffffff;
        outline: none;
        border: 1px solid #e6e6e6;
        height: 50px;
        border-radius: 10px;
        margin-bottom: 1rem;
    }

    .btn-selected {
        background-color: #d7e1ea !important;
        border: none;
    }
    
</style>