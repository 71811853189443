<script>
  export let text;
  export let placeholder = "";
  export let suggestions = [];
  export let height = "22px";
  export let marginTop = "4px";
  export let borderColor = "var(--labit-card-gray)";
  export let fontSize = "12px";
  export let paddingLeft = "14px";
  export let paddingRight = "14px";
  export let color = "black";
  export let error = false;
  export let onChange = null;
  export let onFocusOut = null;

  import { removeAccents } from "../../../../js/removeAccents";

  import ClickOutside from "svelte-click-outside";

  let suggestionMap = [];
  let selectedSuggestion = "";

  let clickSuggestion = (suggestion) => {
    text = suggestion;
    selectedSuggestion = suggestion;
    suggestionMap = [];
    if (onChange) {
      onChange();
    }
    if (onFocusOut) {
      onFocusOut();
    }
  };

  let onClickOutside = () => {
    suggestionMap = [];
  };

  let clickInput = () => {
    if (text === "") {
      suggestionMap = suggestions;
    }
  };

  $: {
    if (text && text.length > 0 && text !== selectedSuggestion) {
      suggestionMap = suggestions.filter((suggestion) => {
        return removeAccents(suggestion.trim())
          .toUpperCase()
          .includes(removeAccents(text.trim()).toUpperCase());
      });
    } else {
      suggestionMap = [];
    }
  }
</script>

<div
  class="suggestionEditContainer"
  style="height: {height}; margin-top: {marginTop}"
>
  <ClickOutside on:clickoutside={() => onClickOutside()}>
    <div
      class="edit"
      style="height: {height}; border-color: {error ? 'red' : borderColor};
      font-size: {fontSize}; padding-left: {paddingLeft}; padding-right: {paddingRight};"
    >
      <input
        type="text"
        style="color: {color};"
        bind:value={text}
        {placeholder}
        on:keyup={() => {
          if (onChange) {
            onChange();
          }
        }}
        on:focusout={() => {
          if (onFocusOut) {
            onFocusOut();
          }
        }}
        on:click={() => {
          clickInput();
        }}
      />
    </div>
    {#if suggestionMap.length > 0}
      <div class="suggestions customScrollbars" style="font-size: {fontSize};">
        {#each suggestionMap as suggestion}
          <div class="suggestion" on:click={() => clickSuggestion(suggestion)}>
            {suggestion}
          </div>
        {/each}
      </div>
    {/if}
  </ClickOutside>
</div>

<style>
  div.suggestionEditContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  div.edit {
    width: 100%;
    background-color: white;
    border-radius: 19px;
    font-weight: bold;
    display: flex;
    align-items: center;
    background-color: rgb(215, 225, 234);
    border: 0.5px solid var(--labit-card-gray);
  }

  input {
    border-width: 0px;
    background-color: transparent;
    width: 100%;
  }

  input::placeholder {
    font-size: 12px;
  }

  div.suggestions {
    width: 100%;
    position: absolute;
    top: 22px;
    left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: white;
    color: var(--labit-card-gray);
    box-shadow: 0 2px 3px 0 rgba(44, 62, 80, 0.24);
    border-radius: 4px;
    max-height: 130px;
    overflow-y: auto;
    z-index: 10;
    margin-top: 4px;
    min-width: 250px;
  }

  div.suggestion {
    height: 25px !important;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    cursor: default;
  }

  div.suggestion:hover {
    background-color: var(--labit-card-lightgray);
    color: white;
  }
</style>
