<script>
  export let placeholder;
  export let maxlength = 150;
  export let img;
  export let value;
  export let onKeyUp = null;
  export let bgColor = "";
  export let type = "text";

  import { formatTime } from "../js/formatDateTime";

  function checkTimeInput(e) {
    if (type === "hours") {
      value = formatTime(e.target.value);
    }
  }
</script>

<div>
  {#if bgColor == ""}
    {#if img}
      <input
        class="inputImage inputImagePad"
        type="text"
        {placeholder}
        {maxlength}
        bind:value
        on:keyup={onKeyUp}
        on:blur={checkTimeInput}
      />
      <img src={img} alt="icon" />
    {:else}
      <input
        class="inputImage"
        type="text"
        {placeholder}
        {maxlength}
        bind:value
        on:keyup={onKeyUp}
        on:blur={checkTimeInput}
      />
    {/if}
  {:else if img}
    <input
      class="inputImage inputImagePad"
      style="background-color: {bgColor} !important"
      type="text"
      {placeholder}
      {maxlength}
      bind:value
      on:keyup={onKeyUp}
      on:blur={checkTimeInput}
    />
    <img src={img} alt="icon" />
  {:else}
    <input
      class="inputImage"
      style="background-color: {bgColor} !important"
      type="text"
      {placeholder}
      {maxlength}
      bind:value
      on:keyup={onKeyUp}
      on:blur={checkTimeInput}
    />
  {/if}
</div>

<style>
  div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  input {
    width: 100%;
    height: 100%;
    background-color: var(--labit-background-gray);
    border-style: solid;
    border-color: var(--labit-gray);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 960px/960px;
    font-size: 12px;
    position: relative;
  }

  input.inputImagePad {
    padding-right: 25px;
  }

  img {
    position: absolute;
    height: 50%;
    right: 10px;
  }
</style>
