<script>
  export let text;
  export let img;
  export let imgHover;
  export let onClick;
  export let bigIcon = false;

  import Hoverable from "./Hoverable.svelte";
</script>

<style>
  div {
    width: 121px;
    height: 21px;
    cursor: pointer;
    border-style: solid;
    border-color: var(--labit-gray);
    border-width: 1px;
    border-radius: 960px/960px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.8%;
    padding-right: 0.8%;
    font-size: 12px;
    position: relative;
    background-color: var(--labit-background-gray);
    color: var(--labit-darkgray);
  }

  div:hover {
    background-color: var(--labit-card-gray);
  }

  div:hover span {
    color: white;
  }

  img {
    position: relative;
    height: 50%;
    margin-left: 7%;
  }

  img.bigIcon {
    height: 85%;
  }

  /* Animations */

  /*div {
    transition: background-color 0.5s linear 0s;
  }

  div:hover {
    background-color: lightgray;
  }

  div span {
    transition: color 0.5 linear 0s;
  }

  div:hover span {
    color: black;
  }*/
</style>

<Hoverable let:hovering={active}>
  <div class="submenuButton" style="" on:click={onClick}>
    <span>{text}</span>
    {#if active}
      {#if imgHover}
        <img class={bigIcon ? 'bigIcon' : ''} src={imgHover} alt="icon" />
      {/if}
    {:else if img}
      <img class={bigIcon ? 'bigIcon' : ''} src={img} alt="icon" />
    {/if}
  </div>
</Hoverable>
