<script>
    export let num;
    export let selected;

    let selectIcon = () => {
        selected = num;
    };
</script>

<style>
    img {
        width: 35px;
        cursor: pointer;
    }

    div.slackIconContainer div {
        padding: 5px;
        display: flex;
    }

    div.selected {
        border-width: 1px;
        border-color: var(--labit-card-gray);
        border-style: solid;
        border-radius: 10px;
    }
</style>

<div class="slackIconContainer">
    <div
        class={selected === num ? 'selected' : ''}
        on:click={() => selectIcon()}>
        <img src={'/images/slack/' + num + '.svg'} alt="Slack colored Icon" />
    </div>
</div>
