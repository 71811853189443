<script>
  export let cell;
  export let rowIndex;
  export let columnIndex;
  export let onChangeTable;
  export let onSaveException;
  export let onUpdateException;
  export let onDeleteException;
  export let filterGreen;
  export let filterYellow;
  export let filterRed;
  export let selFilterEpigraph;
  export let selFilterAttribute;
  export let selFilterLevel;
  export let selFilterRooms;
  export let epigraph;
  export let attribute;
  export let level;
  export let roomNumbering;
  export let editable;

  import RDSCellCard from "./RDSCellCard.svelte";
  import Combobox from "./Combobox.svelte";

  import { percentageColors } from "../js/percentage-colors";

  const GREEN_COLOR = "d3eea2";
  const RED_COLOR = "f6aba1";

  let editing = [];

  let backgroundColor = "";

  let visible = true;

  $: {
    let visibleE = true;
    let visibleA = true;
    let visibleL = true;
    let visibleR = true;

    if (selFilterEpigraph.length > 0) {
      const pos = selFilterEpigraph.findIndex(sE => {
        return sE === epigraph;
      });
      visibleE = pos >= 0;
    } else {
      visibleE = true;
    }

    if (selFilterAttribute.length > 0) {
      const pos = selFilterAttribute.findIndex(sA => {
        return sA === attribute;
      });
      visibleA = pos >= 0;
    } else {
      visibleA = true;
    }

    if (selFilterLevel.length > 0) {
      const pos = selFilterLevel.findIndex(sL => {
        return sL === level;
      });
      visibleL = pos >= 0;
    } else {
      visibleL = true;
    }

    if (selFilterRooms.length > 0) {
      const pos = selFilterRooms.findIndex(sR => {
        return sR === roomNumbering;
      });
      visibleR = pos >= 0;
    } else {
      visibleR = true;
    }

    visible = visibleE && visibleA && visibleL && visibleR;
  }

  $: {
    backgroundColor = "";

    if (
      cell &&
      cell.type &&
      cell.type === "conflict" &&
      cell.status === "noOk"
    ) {
      const numNoOk = cell.conflicts.reduce((acc, curr) => {
        if (
          curr.status === "notExpected" ||
          (curr.status === "missing" &&
            !(
              curr.text
                .trim()
                .toUpperCase()
                .endsWith("[OR]") ||
              curr.text
                .trim()
                .toUpperCase()
                .endsWith("[OPTIONAL]")
            ))
        ) {
          acc++;
        }
        return acc;
      }, 0);
      const numOk = cell.conflicts.reduce((acc, curr) => {
        if (curr.status === "found") {
          acc++;
        }
        return acc;
      }, 0);
      const total = numNoOk + numOk;
      if (total > 0) {
        const pOk = Math.ceil((numOk / total) * 100);
        const bgColor = percentageColors(
          GREEN_COLOR,
          RED_COLOR,
          parseFloat(pOk) / 100
        );
        backgroundColor = "background-color: #" + bgColor + ";";
      }
    }
  }

  let resetEditing = () => {
    cell.conflicts.forEach(conflict => {
      editing.push({
        editing: false,
        type: conflict.status === "missing" ? "replace" : "exception",
        description: "",
        replacingValue: "",
        typeId: "-1",
        text: ""
      });
    });
  };

  let showEditing = (index, show, conflict = null) => {
    editing[index].editing = show;
    if (editing[index].editing) {
      if (cell.conflicts[index].exception) {
        editing[index].type = cell.conflicts[index].exception.type;
        editing[index].description =
          cell.conflicts[index].exception.description;
        editing[index].replacingValue =
          cell.conflicts[index].exception.replacingValue;
      }
      editing[index].typeId = conflict.typeId;
      editing[index].attributeId = conflict.attributeId;
      editing[index].text = conflict.text;
    } else {
      (editing[index].type =
        conflict.status === "missing" ? "replace" : "exception"),
        (editing[index].description = "");
      editing[index].replacingValue = "";
      editing[index].typeId = "-1";
      editing[index].attributeId = "-1";
      editing[index].text = "";
    }
  };

  let saveEditing = async (conflict, index) => {
    if (editing[index].description.trim() !== "") {
      if (
        editing[index].type !== "replace" ||
        (editing[index].type === "replace" &&
          editing[index].replacingValue.trim() !== "")
      ) {
        if (
          conflict.exception &&
          conflict.exception.id !== "-1" &&
          conflict.exception.type === "replace" &&
          (conflict.exception.type !== editing[index].type ||
            conflict.exception.replacingValue !== editing[index].replacingValue)
        ) {
          // Se ha actualizado un replace, buscar los hidden
          for (let i = 0; i < cell.conflicts.length; i++) {
            let conf = cell.conflicts[i];
            if (
              conf.exception &&
              conf.exception.type === "hidden" &&
              conf.text.trim().toUpperCase() ===
                conflict.text.trim().toUpperCase()
            ) {
              await onDeleteException(conf.exception.id);
              conf.exception = null;
            }
          }
        }

        conflict.exception = {
          id: conflict.exception ? conflict.exception.id : "-1",
          type: editing[index].type,
          description: editing[index].description,
          replacingValue: editing[index].replacingValue,
          typeId: editing[index].typeId,
          attributeId: editing[index].attributeId,
          text: editing[index].text
        };
        if (conflict.exception.id === "-1") {
          await onSaveException(
            rowIndex,
            columnIndex,
            index,
            conflict.exception
          );
        } else {
          await onUpdateException(conflict.exception);
        }
        showEditing(index, false, conflict);
        if (conflict.exception.type === "replace") {
          for (let i = 0; i < cell.conflicts.length; i++) {
            let conf = cell.conflicts[i];
            if (
              !conf.exception &&
              conf.text.trim().toUpperCase() ===
                conflict.exception.replacingValue.trim().toUpperCase()
            ) {
              conf.exception = {
                id: "-1",
                type: "hidden",
                description: "",
                replacingValue: "",
                typeId: conflict.exception.typeId,
                attributeId: conflict.exception.attributeId,
                text: conflict.exception.text
              };
              await onSaveException(rowIndex, columnIndex, i, conf.exception);
            }
          }
        }
        onChangeTable();
      }
    }
  };

  let removeException = async (conflict, index) => {
    if (conflict.exception.type === "replace") {
      for (let i = 0; i < cell.conflicts.length; i++) {
        let conf = cell.conflicts[i];
        if (
          conf.exception &&
          conf.exception.type === "hidden" &&
          conf.text.trim().toUpperCase() ===
            conflict.exception.replacingValue.trim().toUpperCase()
        ) {
          await onDeleteException(conf.exception.id);
          conf.exception = null;
        }
      }
    }
    await onDeleteException(conflict.exception.id);
    conflict.exception = null;
    showEditing(index, false, conflict);
    onChangeTable();
  };

  $: if (cell && cell.conflicts) {
    resetEditing();
  }
</script>

<style>
  td {
    background-color: var(--labit-rdstable-header);
    vertical-align: top;
    min-height: 20px;
  }

  td.ok {
    background-color: var(--labit-light-green);
  }

  td.noOk {
    background-color: var(--labit-light-red);
  }

  td.exceptions {
    background-color: var(--labit-light-yellow);
  }

  div.RDSCell {
    min-height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
  }

  span.text {
    color: var(--labit-card-labit-hover-background);
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }

  div.editCard:not(:last-child) {
    margin-bottom: 12px;
  }

  div.editCard {
    width: 100%;
    border-radius: 10px;
    font-size: 12px;
    padding: 6px;
    color: var(--labit-rdstable-card-header);
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  div.editCard span {
    margin-bottom: 6px;
  }

  div.editCard textarea {
    height: 134px;
  }

  div.editCard textarea,
  div.editCard input {
    border-color: var(--labit-darkgray);
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    margin-bottom: 6px;
  }

  div.icons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  div.icons img {
    height: 21px;
    cursor: pointer;
  }

  div.spacer {
    height: 8px;
  }

  div.inputMissing {
    display: flex;
    align-items: center;
  }

  div.inputMissing span {
    margin-bottom: 0px;
    margin-right: 6px;
  }

  div.inputMissing select {
    margin-bottom: 0px;
    font-size: 12px;
    border-radius: 19px;
    background-color: var(--labit-gray);
  }

  /* Animations */

  div.icons img {
    transition: opacity 0.5s linear 0s;
  }

  div.icons img:hover {
    opacity: 0.5;
  }
</style>

{#if visible}
  <td
    class="{cell ? cell.status : ''}
    {columnIndex === 0 ? 'fixedColumn0' : ''}
    {columnIndex === 1 ? 'fixedColumn1' : ''}"
    style={backgroundColor}>
    <div class="RDSCell">
      {#if cell}
        {#if cell.type === 'text'}
          <span class="text">{cell.value}</span>
        {:else if cell.type === 'conflict'}
          {#each cell.conflicts as conflict, index}
            {#if editing[index].editing}
              <div class="editCard">
                <span>Description</span>
                <textarea
                  class="customScrollbars"
                  maxlength="300"
                  bind:value={editing[index].description} />
                <div class="spacer" />
                {#if conflict.status === 'missing'}
                  <div class="inputMissing">
                    <span>Type:</span>
                    <select bind:value={editing[index].type}>
                      <option value="replace">REPLACE</option>
                      <option value="exception">EXCEPTION</option>
                    </select>
                    <!--<Combobox
                    options={[{ value: 'replace', label: 'replace' }, { value: 'exception', label: 'exception' }]}
                    placeholder=""
                    bind:selectedValue
                    searchable={false}
                    onChange={() => {
                      editing[index].type = selectedValue.value;
                    }} />-->
                    <!--<Combobox
                    options={['replace', 'exception']}
                    placeholder=""
                    bind:selectedValue={editing[index].type}
                    searchable={false} />-->
                  </div>
                  {#if editing[index].type === 'replace'}
                    <div class="spacer" />
                    <span>Replacing Value</span>
                    <input
                      type="text"
                      maxlength="500"
                      bind:value={editing[index].replacingValue} />
                  {/if}
                {:else}
                  <span>
                    Type:
                    <b>EXCEPTION</b>
                  </span>
                {/if}
                <div class="spacer" />
                <div class="spacer" />
                <div class="icons">
                  {#if conflict.exception}
                    <img
                      src="/images/trash.svg"
                      alt="Remove Icon"
                      on:click={() => removeException(conflict, index)} />
                  {/if}
                  <img
                    src="/images/save.svg"
                    alt="Save Icon"
                    on:click={() => saveEditing(conflict, index)} />
                  <img
                    src="/images/x.svg"
                    alt="Close Icon"
                    on:click={() => showEditing(index, false, conflict)} />
                </div>
              </div>
            {:else}
              <RDSCellCard
                id={rowIndex + '-' + columnIndex + '-' + index}
                {conflict}
                onClick={(conflict.status !== 'found' || conflict.exception) && editable ? () => showEditing(index, true, conflict) : null}
                {filterGreen}
                {filterYellow}
                {filterRed}
                {editable} />
            {/if}
          {/each}
        {/if}
      {/if}
    </div>
  </td>
{/if}
