export let menuJSON = [
  [
    {
      icon: "/images/menu-icons/logo-user.svg",
      text: "PEOPLE",
      url: "/people",
      requiresOutlook: false,
      externalUrl: "",
    },
    {
      icon: "/images/menu-icons/logo-mail.svg",
      text: "MAIL",
      url: "/outlook",
      requiresOutlook: false,
      //externalUrl: "https://outlook.office365.com/mail/inbox",
      externalUrl: "https://outlook.office.com/mail/",
    },
    {
      icon: "/images/menu-icons/logo-chat.svg",
      text: "CHAT",
      url: "/slack",
      requiresOutlook: false,
      externalUrl: "https://slack.com",
    },
    {
      icon: "/images/menu-icons/logo-calendar.svg",
      text: "CALENDAR",
      url: "/calendar",
      requiresOutlook: true,
      externalUrl: "",
    },
  ],
  [
    {
      icon: "/images/menu-icons/logo-places.svg",
      text: "PLACES",
      url: "/project-dashboard",
      requiresOutlook: false,
      externalUrl: "",
    },
    {
      icon: "/images/menu-icons/logo-projects.svg",
      text: "DASHBOARD",
      url: "/dashboard-project",
      requiresOutlook: false,
      externalUrl: "",
    },
    {
      icon: "/images/menu-icons/logo-archive.svg",
      text: "ARCHIVE",
      url: "/projects",
      requiresOutlook: false,
      externalUrl: "",
    },
  ],
  [
    {
      icon: "/images/menu-icons/logo-process.svg",
      text: "PROCESS",
      url: "/process",
      requiresOutlook: false,
      externalUrl: "",
    },
    {
      icon: "/images/menu-icons/logo-companies.svg",
      text: "CLIENTS",
      url: "/guidelines-clients",
      requiresOutlook: false,
      externalUrl: "",
    },
    {
      icon: "/images/menu-icons/logo-resources.svg",
      text: "GUIDELINES",
      url: "/guidelines",
      requiresOutlook: false,
      externalUrl: "",
    },
  ],
  [
    {
      icon: "/images/menu-icons/logo-apps.svg",
      text: "APPS",
      url: "/tools",
      requiresOutlook: false,
      externalUrl: "",
    },
    {
      icon: "/images/menu-icons/logo-tools.svg",
      text: "TOOLS",
      url: "/extra-tools",
      requiresOutlook: false,
      externalUrl: "",
    },
    {
      icon: "/images/menu-icons/logo-owner.svg",
      text: "MANAGEMENT",
      url: "/direction",
      requiresOutlook: false,
      externalUrl: "",
    },
    {
      icon: "/images/menu-icons/logo-analytics.svg",
      text: "ANALYTICS",
      url: "/analytics",
      requiresOutlook: false,
      externalUrl: "",
    },
  ],
];
