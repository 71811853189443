<script>
	export let qrList = [];
	export let type = "";
	export let label;
	export let value;
	export let mine;
	export let toAssign = false;
	
	let selectedQR = { label: label, value: value };

	import Combobox from "../../../components/Combobox.svelte";
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	const dispatchQR = (action) => {
		const qr = {label: selectedQR.label, value: selectedQR.value};

		switch (action) {
			case "edit":
				dispatch("dispatchedEditQR", { qr: qr });
				break;
			case "notOwn":
				dispatch("dispatchedNotOwnQR", { qr: qr });
				break;
			case "own":
				dispatch("dispatchedOwnQR", { qr: qr });
				break;
		}
	};

	/*
	const setQR = async (event) => {
        selectedQR.label = event.detail.selectedQR;
        let categAndTypeQuery = `select category, type from inventory where tag = "${selectedQR.label}";`;
		let categAndType = await post( categAndTypeQuery );

		//console.log(categAndType);
		type = categAndType[0].type || categAndType[0].category;
	} 
	*/

</script>

<div class="container flex">
	{#if type.toLowerCase() === "computer" || type.toLowerCase() === "pc"}
		<img class="icon pointer" src="/images/computer.svg" alt="" />
	{:else if type.toLowerCase() === "laptop"}
		<img class="icon pointer" src="/images/laptop.svg" alt="" />
	{:else if type.toLowerCase() === "monitor"}
		<img class="icon pointer" src="/images/monitor.svg" alt="" />
	{:else if type.toLowerCase() === "powercube"}
		<img class="icon pointer" src="/images/powercube.svg" alt="" />
	{:else if type.toLowerCase() === "wireless"}
		<img class="icon pointer" src="/images/wireless.svg" alt="" />
	{:else if type.toLowerCase() === "webcam" || type.toLowerCase() === "camera" ||  type.toLowerCase() === "cam"}
		<img class="icon pointer" src="/images/webcam.svg" alt="" />
	{:else if type.toLowerCase() === "headphones" || type.toLowerCase() === "earphones" || type.toLowerCase() === "headset"}
		<img class="icon pointer" src="/images/headphones.svg" alt="" />
	{:else}
		<img class="icon pointer" src="/images/unknown.svg" alt="" />
	{/if}
	
	<!-- <input type="text" name="" id="" value="{type}"/> -->
	<span>{type}</span>
	{#if toAssign}
		<Combobox
			options={qrList}
			bind:selectedValue={selectedQR}
			preSelectedValue={label}
			width="100%"
			persistentText="true"
			preSelectedOption ="true"
		/>
		<button class="pointer" on:click={() => dispatchQR("own")}> Own </button>
	{:else}
		<span>{selectedQR.label}</span>
		{#if mine}
			<img class="icon pointer" src="/images/edit.svg" alt="" on:click={() => dispatchQR("edit")}/>
			<img class="icon pointer" src="/images/x-red.svg" alt="" on:click={() => dispatchQR("notOwn")}/>
		{/if}
	{/if}
</div>

<style>
	.container {
		height: 30px;
		border-radius: 40px;
		width: 40%;
    	margin: 20px 40px 20px 40px;
	}

	button {
		margin: 0 5px 0 20px;
		border-radius: 10px;
		background-color: #d7e1ea;
		border: none;
	}

	.container:hover {
		background-color: #ffffff;
	}

	.flex {
		align-items: center;
		justify-content: space-between;
		display: flex;
	}

	.icon {
		width: 24px;
		margin: 0 10px 0 10px;
	}

    .pointer {
        cursor: pointer;
    }

	span {
		width: 500px;
		padding-left: 15px;
	}
/*
	input[type="text"] {
		border: none;
		text-align: center;
	}
*/
</style>
