<script>
  export let pic;
  export let name;
  export let timeLabitCode;
  export let folderLabitCode;
  export let externalUrls = [];
  export let city;
  export let country;
  export let area;
  export let status;
  export let onClick;
  export let active;

  import { openNewWindow } from "../js/openNewWindow";
  import {getPlatform} from "../js/localInfo";
  import WebBrowser from "./WebBrowser.svelte";

  let selectedToolType;
  let platform = getPlatform();

  let showWebBrowser = false; // Controla la visualización del WebBrowser
  let toolURL = ''; // URL para el navegador web
  let showLineContent = true;


  let changeToolURL = (url, toolType) => {
    toolURL = url;
    selectedToolType = toolType;
    console.log("SELECTED TOOL TYPEEE",selectedToolType);
    console.log("toolURLLLL",toolURL);
  };

   let clickTool = (forceNewWindow) => {
    if (selectedToolType !== "folder") {
      if (forceNewWindow || platform !== "APP") { // APP ···VALIDO····
        console.log("platform",platform)
        openNewWindow(toolURL); // Abre en una nueva ventana si no es web o si se fuerza
      } else {
        console.log("platform",platform)
        showWebBrowser = true; // Abre en el componente WebBrowser
        showLineContent = false;
      }
    }
  };

  // Función para cerrar el navegador web
  let closeWebBrowser = () => {
    showWebBrowser = false;
    showLineContent = true;
  };
</script>


<div class={`content ${showWebBrowser ? 'noHoverEffect' : 'hoverEffect'}`}>
  {#if showLineContent} <!-- Controla la visibilidad del contenido de la línea -->
  
    <div class="column1">
      <img
        src={pic !== '' ? pic : '/images/no_pic.svg'}
        alt="Project Pic"
        onerror="this.src='/images/no_pic.svg';" />
    </div>

    <div class="column2">
      <span>{name}</span>
    </div>

    <div class="column3">
      <span>{timeLabitCode}</span>
    </div>

    <div class="column3">
      <span>{folderLabitCode}</span>
    </div>

    <div class="column0">
      {#if active}
        <span class="circle green"></span> <!-- Círculo verde si activo -->
      {:else}
        <span class="circle red"></span> <!-- Círculo rojo si no activo -->
      {/if}
    </div>

    <div class="column4">
      {#each externalUrls as externalUrl}
        {#if externalUrl.icon !== ''}
          <img
            src={externalUrl.icon}
            alt={externalUrl.type}
            on:mouseenter={() => changeToolURL(externalUrl.url, externalUrls.type === "Server" ? "folder" : externalUrls.type)}
            on:click={() => clickTool(false)}
            on:contextmenu={(ev) => {
              ev.preventDefault();
              clickTool(true);
              return false;
            }} />
        {/if}
      {/each}
    </div>
    <div class="column5_1">
      <span>{city}</span>
    </div>
    <div class="column5_2">
      <span>{country}</span>
    </div>
    <div class="column6">
      <span>{area}</span>
    </div>
    <div class="column7">
      <img
        class="opcionesDots"
        src="/images/dots.svg"
        alt="Opciones"
        on:click={onClick} />
    </div>
  {/if}

  {#if showWebBrowser}
    <div class="tool">
      <div class="toolOptions">
        <img
          class="fadedButton"
          src="/images/x.svg"
          alt="Close Tool"
          on:click={closeWebBrowser}
        />
      </div>
      <div class="toolWebview">
        <WebBrowser
          id="dialogProjectWebBrowser1"
          type="webview"
          iframeTitle="External Tool"
          src={toolURL}
        />
      </div>
    </div>
  {/if}
</div>


<style>
  div.content {
    width: 100%;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    /* color: var(--labit-card-gray); */
  }

  /* div.noActive {
    color: var(--labit-text-lightgray) !important;
  } */

  .circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 12px;
  }

  .green {
    background-color: #68ab5d;
  }

  .red {
    background-color: #ff9090;
  }

  div.column1 {
    width: 7%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
  }

  div.column1 img {
    max-width: 58px;
    max-height: 38px;
    border-radius: 5px;
  }

  div.column2 {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
  }

  div.column3 {
    width: 7%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column4 {
    width: 25%;
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    font-size: 13px;
  }

  div.column4 img {
    max-width: 30px;
    max-height: 20px;
    cursor: pointer;
  }

  div.column4 img:hover {
    max-width: 32px;
    max-height: 22px;
  }


  div.column5_1 {
    width: 9%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column5_2 {
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column6 {
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }

  div.column7 {
    width: 5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  div.column7 img {
    width: 40%;
    opacity: 1;
    cursor: pointer;
    padding-right: 5px;
  }

  div.toolWebview {
    width: 100%;
    height: 800px;
  }

  div.tool {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.toolOptions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  div.toolOptions img {
    width: 15px;
  }

  /* Animations */

  div.hoverEffect {
    transition: background-color 0.5s linear 0s;
    border-radius: 15px;
  }

  /* div.content:hover {
    background-color: lightgray;
  } */

  .hoverEffect:hover {
    background-color: lightgray;
    border-radius: 15px;
  }

  .noHoverEffect:hover {
    background-color: transparent;
  }

  div.column7 img {
    transition: opacity 0.5s linear 0s;
  }

  div.column7 img:hover {
    opacity: 0.5;
  }
</style>


