export let getCoordinatesFromMapsURL = (url) => {
  let latitude = null;
  let longitude = null;

  // Google Maps Web

  let pos = url.indexOf("@");
  if (pos >= 0) {
    if (pos >= 0) {
      let str = url.substring(pos+1);
      const tokens = str.split(",");
      if (tokens.length >= 2) {
        latitude = tokens[0];
        longitude = tokens[1];
      }
    }
  }
  if (!latitude && !longitude) {
    // Google Maps Iframe
    pos = url.indexOf("!");
    if (pos >= 0) {
      let str = url.substring(pos);
      pos = str.indexOf("d");
      if (pos >= 0) {
        str = str.substring(pos + 1);
        const tokens = str.split("d");
        latitude = tokens[2];
        longitude = tokens[1];
        pos = latitude.indexOf("!");
        if (pos >= 0) {
          latitude = latitude.substring(0, pos);
        }
        pos = longitude.indexOf("!");
        if (pos >= 0) {
          longitude = longitude.substring(0, pos);
        }
      }
    }
  }
  return {
    latitude,
    longitude,
  };
};
