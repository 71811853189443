<script>
  export let img;
  export let imgHover;
  export let backgroundColor;
  export let backgroundColorHover;
  export let onClick;

  import Hoverable from "./Hoverable.svelte";
</script>

<style>
  div {
    width: 122px;
    height: 52px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  img {
    height: 59.6%;
  }
</style>

<Hoverable let:hovering={active}>
  <div
    class="submenuIcon"
    style="background-color: {active ? backgroundColorHover : backgroundColor}"
    on:click={onClick}>
    {#if active}
      <img src={imgHover} alt="icon" />
    {:else}
      <img src={img} alt="icon" />
    {/if}
  </div>
</Hoverable>
