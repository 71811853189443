<script>
  export let pic;
  export let name;
  export let company;
  export let companyRole;
  export let location;
  export let status;
  export let email1;
  export let onClick;
</script>

<style>
  div.content {
    width: 100%;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--labit-card-gray);
  }

  div.noActive {
    color: var(--labit-text-lightgray) !important;
  }

  div.column1 {
    width: 4%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div.column1 img {
    max-height: 38px;
    max-width: 38px;
  }

  div.column2 {
    width: 19%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
  }

  div.column3 {
    width: 17%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column4 {
    width: 18%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column5 {
    width: 17%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column6 {
    width: 12%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column7 {
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column7 a {
    width: 100%;
    text-decoration: none;
    color: var(--labit-card-gray);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div.column8 {
    width: 3%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  div.column8 img {
    width: 40%;
    opacity: 1;
    cursor: pointer;
  }

  /* Animations */

  div.content {
    transition: background-color 0.5s linear 0s;
  }

  div.content:hover {
    background-color: lightgray;
  }

  div.column8 img {
    transition: opacity 0.5s linear 0s;
  }

  div.column8 img:hover {
    opacity: 0.5;
  }
</style>

<div class="content {status ? '' : 'noActive'}">
  <div class="column1">
    <img
      class="userPic"
      src={pic}
      alt="User Pic"
      onerror="this.src='/images/no_user.svg';" />
  </div>
  <div class="column2">
    <span>{name}</span>
  </div>
  <div class="column3">
    <span>{company}</span>
  </div>
  <div class="column4">
    <span>{companyRole}</span>
  </div>
  <div class="column5">
    <span>{location}</span>
  </div>
  <div class="column6">
    <span>{status ? 'Active' : 'No Active'}</span>
  </div>
  <div class="column7">
    <a href={'mailto:' + email1}>
      <span>{email1}</span>
    </a>
  </div>
  <div class="column8">
    <img
      class="opcionesDots"
      src="/images/dots.svg"
      alt="Opciones"
      on:click={onClick} />
  </div>
</div>
