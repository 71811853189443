import { getPlatform } from "./localInfo";

const platform = getPlatform();

const BORDER_RADIUS = "19px";

export let roundWebview = (id = null) => {
  if (platform === "APP") {
    let webview =
      id === ""
        ? document.querySelector("webview.webview")
        : document.querySelector("webview#" + id);
    if (webview) {
      webview.shadowRoot.querySelector(
        "iframe"
      ).style.borderRadius = BORDER_RADIUS;
    }
  }
};

export let roundHalfWebview = (id = null) => {
  if (platform === "APP") {
    let webview =
      id === ""
        ? document.querySelector("webview.webview")
        : document.querySelector("webview#" + id);
    if (webview) {
      webview.shadowRoot.querySelector("iframe").style.borderRadius =
        "0px 0px " + BORDER_RADIUS + " " + BORDER_RADIUS;
    }
  }
};

export let roundThisWebview = (webview) => {
  if (platform === "APP") {
    webview.shadowRoot.querySelector(
      "iframe"
    ).style.borderRadius = BORDER_RADIUS;
  }
};
