<script>
    import { createEventDispatcher } from 'svelte';
    import { slide } from 'svelte/transition';


    export let items;
    export let prepared;
    let expanded = false;
    export let mode = 0;
    export let choosen = items[0];
    export let other = {name: ""};

    





    console.log(choosen);

    const dispatch = createEventDispatcher();


    function fadeSlide(node, options) {
		const slideTrans = slide(node, options)
		return {
			duration: options.duration,
			css: t => `
				${slideTrans.css(t)}
				opacity: ${t};
			`
		};
	}
/*
    $: {
        if (mode!== 0){
            choosen = prepared[0].name;
            items = prepared.map(item => item.name);
        }
    }
*/
    function toggle(){
        expanded = !expanded;
    }
</script>


<div class= "main">
    {#if mode === 0}
    <div class="header-container" on:click = {toggle}>
        <img class = "logo-header" src="/images/new/{choosen}.svg" alt="">
        <span class="header" >{choosen}</span>
        <img class= "arrow-header" src="/images/new/down-arrow-white.svg" alt="">
    </div>  
    {#if expanded}
    <div class= "opciones" transition:fadeSlide="{{duration: 250}}">
            {#each items as item}
            <div class= "each-option">
                <!--
            <img class="logo" src="/assets/{item}.svg" alt="">
                 -->
            <span class = "select-option" 
                on:click = {() => choosen = item}
                on:click={() => dispatch("change")}
                on:click = {toggle}>{item}</span>
            </div>
            {/each}
        </div>
    {/if}
    {:else if mode === "1"}
    <div class="header-container-1" on:click = {toggle}>
        <img class= "arrow-header-1" src="/images/new/down-arrow-white.svg" alt="">
        <span class="header-1" >{choosen.name}</span>
    </div>  
    {#if expanded}
        <div class= "opciones-1" transition:fadeSlide="{{duration: 250}}">
            {#each prepared as item}
            <div class= "each-option-1">
            <span class = "select-option-1" 
                on:click = {() => choosen = item}
                on:click={() => dispatch("change")}
                on:click = {toggle}>{item.name}</span>
            </div>
            {/each}
        </div>
    {/if}
    {:else if mode === "2"}
        <div class="header-container-2" on:click = {toggle}>
            <img class= "arrow-header-1" src="/images/new/down-arrow.svg" alt="">
            <span class="header-2" >{choosen.name}</span>
        </div>  
        {#if expanded}
        <div class= "opciones-2" transition:fadeSlide="{{duration: 250}}">
            {#each prepared as item}
            {#if item.name !== other.name}
            <div class= "each-option-2">
            <span class = "select-option-2" 
                on:click = {() => choosen = item}
                on:click={() => dispatch("change")}
                on:click = {toggle}>{item.name}</span>
            </div>
            {/if}
            {/each}            
        </div>
        {/if}
    {/if}
</div>

<style>
    .header-container{
        display: flex;
        background-color: #8AAAD1 ;
        border-radius: 19px;
        cursor:pointer;
        border:2px solid;
        border-color: rgba(255, 255, 255, 0.15);
        border-radius: 26px;
    }

    .header-container-1{
        display: flex;
        background-color: #8AAAD1 ;
        border-radius: 19px;
        cursor:pointer;
    }
    .header-container-2{
        display: flex;
        background-color: white;
        border-radius: 19px;
        cursor:pointer;
    }
    .logo-header{
        width: 15%;
        padding-left: 12px;
    }
    .arrow-header{
        width:7%;
        padding: 4px 20px ;
        position:absolute;
        left:72%;
        top:30%;
    }
    .main{
        display: flex;
        flex-direction: column;
        justify-content: start;
        position: relative;
        width:100%;
        height: 100%;
    }
    .header{
        cursor: pointer;
        padding: 5px 12px;
        font-weight: 400;
        font-size: 100%;
        color: rgba(255, 255, 255, 0.9);

        user-select:none;
        -ms-user-select:none;
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -moz-user-select:none;
    }

    .header-1{
        cursor: pointer;
        padding: 5px 4px;
        font-weight: 400;
        font-size: 80%;
        color: rgba(255, 255, 255, 0.9);

        user-select:none;
        -ms-user-select:none;
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -moz-user-select:none;
    }

    .header-2{
        cursor: pointer;
        padding: 5px 4px;
        font-weight: 400;
        font-size: 80%;
        color: #35425B;

        user-select:none;
        -ms-user-select:none;
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -moz-user-select:none;
    }
    .arrow-header-1{
        width:10%;
    }
    .header-container:hover .header{
        font-weight: 500;
    }
    .header-container-1:hover .header-1{
        font-weight: 500;
    }

    .select-option{
        width:100%;
        padding-left: 46px;
        cursor: pointer;
        font-weight: 400;
        color:white;
    }

    .select-option-1{
        width:100%;
        padding-left:12px ;
        padding-top:2px;
        padding-bottom: 2px;
        cursor: pointer;
        font-weight: 400;
        font-size: 80%;
        color:white;
    }

    .select-option-2{
        width:100%;
        padding-left:12px ;
        padding-top:2px;
        padding-bottom: 2px;
        cursor: pointer;
        font-weight: 400;
        font-size: 80%;
        color:black;
    }


    .each-option{
        display: flex;

    }
    .each-option-1{
        display: flex;

    }
    .each-option-2{
        display: flex;
    }


    .each-option:hover{
        background-color: #9DCBE2;
        border-radius: 15px;

    }

    .each-option-1:hover{
        background-color: #9DCBE2;
        border-radius: 15px;


    }
    .each-option-2:hover{
        background-color: #8AAAD1;
        border-radius: 15px;

    }

    .opciones{
        background-color: #8AAAD1;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        top: 40px;
        z-index:1;
        border:1px solid;
        border-color:rgba(255, 255, 255, 0.2);


        user-select:none;
        -ms-user-select:none;
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -moz-user-select:none;
    }

    .opciones-1{
        background-color: #8AAAD1;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        top: 20px;
        z-index: 1;
        border:1px solid;
        border-color:rgba(255, 255, 255, 0.2);

        user-select:none;
        -ms-user-select:none;
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -moz-user-select:none;
    }

    .opciones-2{
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        top: 20px;
        z-index: 1;
        border:1px solid;
        border-color:rgba(0,0,0, 0.1);

        user-select:none;
        -ms-user-select:none;
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -moz-user-select:none;
    }

    @keyframes prueba{
        0% {transform: rotate(45deg)}
        25% {transform: rotate(90deg)}
        50% {transform: rotate(135deg)}
        75% {transform: rotate(180deg)}
        100% {transform: rotate(45deg)}
    }
</style>