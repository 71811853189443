<script>
  let value = 0;

  let results = [];

  let saveResult = () => {
    const result = window.$("#display").val();
    results.unshift(result);
    results = results;
    console.log(results);
  };
</script>

<div class="dashboardContainer">
  <div class="dashboardBody">
    <form class="calculatorForm" name="sci-calc">
      <table class="calculator" cellspacing="0" cellpadding="1">
        <tr>
          <td colspan="5">
            <input id="display" name="display" value="0" maxlength="25" />
          </td>
        </tr>
        <tr>
          <td>
            <input
              type="button"
              class="btnC"
              name="btnC"
              value="C"
              onclick="this.form.display.value= 0 "
            />
          </td>
          <td>
            <input
              type="button"
              class="btnMath"
              name="btnMath"
              value="("
              onclick="addChar(this.form.display, '(')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnMath"
              name="btnMath"
              value=")"
              onclick="addChar(this.form.display,')')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpB"
              name="btnOp"
              value="/"
              onclick="addChar(this.form.display, '/')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="&#177"
              onclick="changeSign(this.form.display)"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="%"
              onclick="percent(this.form.display)"
            />
          </td>
        </tr>
        <tr>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="7"
              onclick="addChar(this.form.display, '7')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="8"
              onclick="addChar(this.form.display, '8')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="9"
              onclick="addChar(this.form.display, '9')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpB"
              name="btnOpB"
              value="*"
              onclick="addChar(this.form.display, '*')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="cos"
              onclick="if(checkNum(this.form.display.value)) cos(this.form)"
              on:click={() => saveResult()}
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="sin"
              onclick="if(checkNum(this.form.display.value)) sin(this.form)"
              on:click={() => saveResult()}
            />
          </td>
        </tr>
        <tr>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="4"
              onclick="addChar(this.form.display, '4')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="5"
              onclick="addChar(this.form.display, '5')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="6"
              onclick="addChar(this.form.display, '6')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpB"
              name="btnOpB"
              value="-"
              onclick="addChar(this.form.display, '-')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="tan"
              onclick="if(checkNum(this.form.display.value)) tan(this.form)"
              on:click={() => saveResult()}
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="ln"
              onclick="if(checkNum(this.form.display.value)) ln(this.form)"
              on:click={() => saveResult()}
            />
          </td>
        </tr>
        <tr>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="1"
              onclick="addChar(this.form.display, '1')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="2"
              onclick="addChar(this.form.display, '2')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="3"
              onclick="addChar(this.form.display, '3')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpB"
              name="btnOpB"
              value="+"
              onclick="addChar(this.form.display, '+')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="&#960;"
              onclick="addChar(this.form.display,'3.14159265359')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="&radic;"
              onclick="if(checkNum(this.form.display.value)) sqrt(this.form)"
              on:click={() => saveResult()}
            />
          </td>
        </tr>
        <tr>
          <td>
            <input
              type="button"
              class="btnMath"
              name="btnMath"
              value="<"
              onclick="deleteChar(this.form.display)"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnNum"
              name="btnNum"
              value="0"
              onclick="addChar(this.form.display, '0')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnMath"
              name="btnMath"
              value="&#46;"
              onclick="addChar(this.form.display, '&#46;')"
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpB"
              name="btnOpB"
              value="="
              onclick="if(checkNum(this.form.display.value)) compute(this.form)"
              on:click={() => saveResult()}
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="x&#50;"
              onclick="if(checkNum(this.form.display.value)) square(this.form)"
              on:click={() => saveResult()}
            />
          </td>
          <td>
            <input
              type="button"
              class="btnOpS"
              name="btnOpS"
              value="x&#94;"
              onclick="if(checkNum(this.form.display.value)) exp(this.form)"
              on:click={() => saveResult()}
            />
          </td>
        </tr>
        <tr />
      </table>
    </form>
  </div>
  <div class="log">
    <div class="separator" />
    <div class="values customScrollbars">
      {#each results as result}
        <span>{result}</span>
      {/each}
    </div>
  </div>
</div>

<style>
  div.dashboardContainer {
    width: 100%;
    height: 100%;
  }

  div.dashboardBody {
    width: 100%;
    height: 62%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 39px;
  }

  div.log {
    height: 33%;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    text-align: end;
    align-items: center;
  }

  div.log span {
    width: 100%;
    margin: 3px 0px 3px 0px;
  }

  div.separator {
    width: 85%;
    background-color: var(--labit-light-gray);
    height: 1px;
    margin-bottom: 13px;
  }

  div.values {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }

  input#display {
    background-color: var(--labit-background-gray);
    border-radius: 16px;
    box-shadow: none;
  }
</style>
