<script>
  export let text;
  export let checked;
  export let onClick;
</script>

<div class="container {checked ? 'marked' : 'notMarked'}" on:click={onClick}>
  <img
    src={checked ? "images/checkbox.svg" : "images/checkbox_empty.svg"}
    alt="check"
  />
  <span>{text}</span>
</div>

<style>
  div.container {
    background-color: white;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  div:hover {
    background-color: lightgray;
  }

  img {
    height: 15px;
    width: 15px;
    margin-right: 6px;
  }
</style>
