<script>
  export let idCompany;
  export let imgSrc;
  export let showRDSButton;
  export let showGuidelineButton;

  import { openNewWindow } from "../js/openNewWindow";
  import { openURL } from "../js/openURL";
  import { setBackPage } from "../js/localInfo";

  let img = imgSrc === "" ? "/images/default-company.png" : imgSrc;
</script>

<div class="resourceCardContainer">
  <div class="imgContainer"><img src={img} alt="Resource Icon" /></div>
  <div class="infoContainer">
    <div class="border" />
    <div class="options">
      <img
        src="/images/user.svg"
        alt="Guideline Icon"
        on:click={() => {
          setBackPage("/guidelines-clients");
          openURL("/people/show-company/" + idCompany, true);
        }}
        on:contextmenu={(ev) => {
          ev.preventDefault();
          setBackPage("/guidelines-clients");
          openNewWindow("/skylab-main/people/show-company/" + idCompany);
        }}
      />
      <!--<img src="/images/book-blue.svg" alt="Guideline Icon" />
      <img src="/images/book-blue.svg" alt="Guideline Icon" />-->
      {#if showRDSButton}
      <img
        src="/images/room.svg"
        alt="Room Data Sheet Icon"
        on:click={() => {
          openURL("/roomdatasheet/" + idCompany, true);
          //location.href = "/roomdatasheet/" + idCompany;
        }}
        on:contextmenu={(ev) => {
          ev.preventDefault();
          //openNewWindow("/roomdatasheet/" + idCompany);
          openNewWindow("/skylab-main/roomdatasheet/" + idCompany);
        }}
      />
      {/if}
      {#if showGuidelineButton}
      <img
        src="/images/book-blue.svg"
        alt="Guideline Icon"
        on:click={() => {
          //location.href = "/roomdatasheetg/" + idCompany;
          openURL("/roomdatasheetg/" + idCompany, true);
        }}
        on:contextmenu={(ev) => {
          ev.preventDefault();
          //openNewWindow("/roomdatasheetg/" + idCompany);
          openNewWindow("/skylab-main/roomdatasheetg/" + idCompany);
        }}
      />
      {/if}
    </div>
  </div>
</div>

<style>
  div.resourceCardContainer {
    width: 259px;
    height: 259px;
    background-color: white;
    border-radius: 19px;
    position: relative;
  }

  div.imgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
  }

  div.imgContainer img {
    max-width: 192px;
    max-height: 48.5%;
  }

  div.infoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    opacity: 0;
  }

  div.border {
    width: 100%;
    height: 81%;
    border-color: var(--labit-card-gray);
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
  }

  div.options {
    width: 100%;
    height: 19%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  div.options img {
    height: 80%;
    cursor: pointer;
  }

  div.options img:not(:last-child) {
    margin-right: 10px;
  }

  /* Animations */

  div.options img {
    transition: opacity 0.5s linear 0s;
  }

  div.options img:hover {
    opacity: 0.5;
  }

  div.resourceCardContainer div.imgContainer {
    transition: height 0.2s linear 0s;
  }

  div.resourceCardContainer:hover div.imgContainer {
    height: 81%;
  }

  div.resourceCardContainer div.infoContainer {
    transition: opacity 0.2s linear 0s;
  }

  div.resourceCardContainer:hover div.infoContainer {
    opacity: 1;
  }
</style>
