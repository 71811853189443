<script>
  //import EditBoxFormButton from "../components/EditBoxFormButton.svelte";
  import CityCodeBox from "../components/CityCodeBox.svelte";
  import CategoryBox from "../components/CategoryBox.svelte";
  import ColorBox from "../components/ColorBox.svelte";
  //import BoxEditPager from "../components/BoxEditPager.svelte";
  //import ScopeBox from "../components/ScopeBox.svelte";
  import WifiBox from "../components/WifiBox.svelte";
  import PlatformIconBox from "../components/PlatformIconBox.svelte";
  import AnalyticsTabs from "../components/AnalyticsTabs.svelte";

  const NUM_PAGES = 2;
  let currentPage = 0;

  let nextPage = () => {
    currentPage++;
    if (currentPage >= NUM_PAGES) {
      currentPage = 0;
    }
  };

  let previousPage = () => {
    currentPage--;
    if (currentPage < 0) {
      currentPage = NUM_PAGES - 1;
    }
  };

  let setPage = (page) => {
    currentPage = page;
  };
</script>

<div class="content">
  <!-- Body -->
  <div class="container">
    <div class="editBox">
      <div class="editBoxInfo">
        <div class="tabs">
          <AnalyticsTabs
            tabs={[
              {
                title: "Categories / City Codes / Labit colors",
                index: 0,
                onClick: () => {
                  setPage(0);
                },
              },
              {
                title: "Wifi Keys / Platform Icons",
                index: 1,
                onClick: () => {
                  setPage(1);
                },
              },
            ]}
            selected={currentPage}
          />
        </div>
        <div class="editBoxInfoInfo">
          <div class="editBoxPage {currentPage !== 0 ? 'hiddenElement' : ''}">
            <div class="editBoxColumn">
              <CategoryBox
                title="CATEGORIES"
                titleIcon="/images/categories.svg"
              />
            </div>
            <div class="editBoxColumn">
              <CityCodeBox
                title="CITY CODES"
                titleIcon="/images/city-codes.svg"
              />
            </div>
            <div class="editBoxColumn">
              <ColorBox
                title="LABIT colorS"
                titleIcon="/images/labit-colors.svg"
              />
            </div>
          </div>
          <!--<div class="editBoxPage {currentPage !== 1 ? 'hiddenElement' : ''}">
            <ScopeBox />
          </div>-->
          <div class="editBoxPage {currentPage !== 1 ? 'hiddenElement' : ''}">
            <div class="editBoxColumn">
              <WifiBox title="WIFI KEYS" titleIcon="/images/wifi.svg" />
            </div>
            <div class="editBoxColumn">
              <PlatformIconBox
                title="PLATFORM ICONS"
                titleIcon="/images/platform-icons.svg"
              />
            </div>
            <div class="dummyBox" />
          </div>
          <!--<div class="pager">
            <BoxEditPager
              numPages={NUM_PAGES}
              page={currentPage}
              clicks={[() => setPage(0), () => setPage(1), () => setPage(2)]}
            />
          </div>-->
        </div>
        <!--<div class="editBoxButtons">
          <EditBoxFormButton
            icon="/images/left-arrow.svg"
            onClick={() => previousPage()}
            buttonHeight="350px"
          />
          <EditBoxFormButton
            icon="/images/right-arrow.svg"
            onClick={() => nextPage()}
            buttonHeight="350px"
          />
        </div>-->
      </div>
    </div>
  </div>
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    flex-direction: column;
    margin-bottom: 20px;
  }

  div.editBox {
    width: 100%;
    margin-top: 20px;
  }

  div.editBoxInfo {
    width: 100%;
    display: flex;
    min-height: 710px !important;
    /*justify-content: space-between;*/
    margin-bottom: 20px;
    flex-direction: column;
  }

  div.editBoxInfoInfo {
    background-color: #d7e1ea;
    padding: 41px 49px 20px 36px;
    border-radius: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin-top: -20px;
    flex: 1;
  }

  div.editBoxPage {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }

  div.editBoxColumn,
  div.dummyBox {
    width: 450px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  div.pager {
    margin-top: 20px;
  }
</style>
