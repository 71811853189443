<div class="spinner"></div>

<style>
    .spinner {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .spinner::before,
    .spinner:after{
        content: "";
        position: absolute;
        border-radius: inherit;
    }
    .spinner:before {
        width: 100%;
        height: 100%;
        background-image:linear-gradient(
            0deg, #f0f4f8 0%,
            #35425b 100% );
        animation: spin .5s infinite linear;
    }
    .spinner:after {
        width: 85%;
        height: 85%;
        background-color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
</style>