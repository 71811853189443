<script>
  export let editable = true;
  export let personal = false;
  export let projectId = -1;
  export let archive = false;
  export let centerView = false;

  import CompleteResourcePlannerHeaderMonth from "./CompleteResourcePlannerHeaderMonth.svelte";
  import CompleteResourcePlannerHeaderWeek from "./CompleteResourcePlannerHeaderWeek.svelte";
  import CompleteResourcePlannerHeaderDay from "./CompleteResourcePlannerHeaderDay.svelte";
  import CompleteResourcePlannerProject from "./CompleteResourcePlannerProject.svelte";
  import CompleteResourcePlannerTeam from "./CompleteResourcePlannerTeam.svelte";
  import Combobox from "../Combobox.svelte";
  import DialogSpanEdit from "../DialogSpanEdit.svelte";
  import CalendarPicker from "../CalendarPicker/CalendarPicker.svelte";
  import Button from "../Button.svelte";
  import Picture from "../Picture.svelte";
  import MultipleSelect from "./../MultipleSelect/MultipleSelect.svelte";
  import Loading from "../Loading.svelte";

  import { post } from "../../js/labit-connection";
  import { defaultTeamRoles } from "../../js/teamRoles";
  import { getAllProjects, getProject } from "../../js/projects";
  import { randomString } from "../../js/randomString";
  import {
    outlookGetTasks,
    outlookLogin,
  } from "../dashboard/outlook/outlook.js";
  import { copyObject } from "../../js/copyObject";
  import {
    getUserId,
    getProjectManagerDashboard,
    setProjectManagerDashboard,
  } from "../../js/localInfo";

  import { onMount, afterUpdate } from "svelte";
  
  import moment from 'moment';

  let drawPhases = 0;

  let id = randomString(10);

  const myId = getUserId();

  let startDate = personal
    ? moment().subtract(7, "days").format("YYYY-MM-DD")
    : moment().subtract(7, "days").format("YYYY-MM-DD");
  let endDate = personal
    ? moment().add(14, "days").format("YYYY-MM-DD")
    : moment().add(3, "months").format("YYYY-MM-DD");
  let selectedStartDate = moment(startDate, "YYYY-MM-DD").format("DD/MM/YYYY");
  let selectedEndDate = moment(endDate, "YYYY-MM-DD").format("DD/MM/YYYY");

  let dayArray = [];
  let weekArray = [];
  let monthArray = [];

  let loaded = false;

  let projects = [];

  let dayWidth = "20"; // 20

  let stickyPhase = 0;
  let stickyStart = 0;
  let stickyEnd = 0;
  let stickyPeople = 0;

  const DELETING_TIME = 5000;

  const PHASE_HEIGHT = 25;
  const TEAM_MARGIN_TOP_BAR = 30;

  let managerProject = null;
  let showPhaseManager = false;
  let managerPhase = null;
  let managerSubphase = null;
  let managerPhaseTitle = "";
  let managerPhaseStart = "15/07/2021";
  let managerPhaseEnd = "15/07/2021";
  let managerSubphaseTitle = "";
  let managerSubphaseStart = "15/07/2021";
  let managerSubphaseEnd = "15/07/2021";
  let managerEditingPhase = false;
  let managerEditingSubphase = false;
  let managerPhaseAddPeopleAllPhases = false;
  let managerPhaseEditingPeople = null;
  let managerPhaseEditingPeopleDate = null;
  let managerPhaseEditingPeopleStart = "15/07/2021";
  let managerPhaseEditingPeopleEnd = "15/07/2021";
  let managerCurrentPage = ""; // phase, subphases, team
  let managerPhaseDeletingPhase = null;
  let managerPhaseDeletingPhaseTimer = null;
  let managerPhaseDeletingSubphase = null;
  let managerPhaseDeletingSubphaseTimer = null;
  let managerPhaseDeletingPeopleDate = null;
  let managerPhaseDeletingPeopleDateTimer = null;

  let peopleOptions = [];
  let peopleOptionsSel = null;

  let teamRolesOptions = [];
  let teamRolesOptionsSel = null;

  let companiesOptions = [];
  let companiesOptionsSel = null;
  let cPeopleOptions = [];
  let cPeopleOptionsSel = null;

  const milestonesOptions = [
    {
      value: "in",
      label: "In",
    },
    {
      value: "out",
      label: "Out",
    },
    {
      value: "general",
      label: "General",
    },
  ];
  let milestonesOptionsSel = milestonesOptions[0];

  let showMilestoneManager = false;
  let managerMilestoneName = "";
  let managerMilestoneDate = "17/07/2021";
  let managerEditingMilestone = false;
  let managerEditingMilestoneMilestone = null;
  let managerMilestoneDeleting = null;
  let managerMilestoneDeletingTimer = null;

  let showNewPhaseManager = false;

  const PHASE_WIDTH = 330;
  const START_WIDTH = 70;
  const END_WIDTH = 70;
  const PEOPLE_WIDTH = 130;

  let accId = -1;

  let centerDate = moment().format("DD/MM/YYYY");

  let zoomOptions = [
    {
      value: "120",
      label: "1 week",
    },
    {
      value: "60",
      label: "2 weeks",
    },
    {
      value: "30",
      label: "1 month",
    },
    {
      value: "10",
      label: "3 months",
    },
    {
      value: "5",
      label: "6 months",
    },
  ];
  let zoomOptionsSel = personal ? zoomOptions[1] : zoomOptions[2];

  let viewOptions = [
    {
      label: "Project",
      value: "project",
    },
    {
      label: "Team",
      value: "team",
    },
  ];
  let viewOptionsSel = viewOptions[0];

  let view = viewOptionsSel.value;

  let showStartColumn = true;
  let showEndColumn = true;
  let showTeamColumn = true;
  let showSubphasesColumn = false;

  let editingPlan = false;

  let projectsOptions = [];
  let projectsOptionsSel = [];

  let peopleViewOptions = [];
  let peopleViewOptionsSel = [];

  let showEmptyProjects = true;
  let showPlannerTasks = false;
  let showMilestones = true;

  let projIndex = 0;
  let loadingPlannerTasks = false;
  let plans = [];

  let team = [];

  const PROJECT_COLORS = [
    "#93a7da",
    "#8f7dd1",
    "#558f92",
    "#bc6e8e",
    "#f9bb81",
  ];

  let numVisible = 0;

  afterUpdate(() => {
    drawPhases++;
  });

  onMount(async () => {
    await getTeam();
    //await getStakeholders();

    teamRolesOptions = defaultTeamRoles.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    teamRolesOptionsSel = teamRolesOptions[0];

    await getPeopleView();
    await getProjects()
    setInterval(async () => {
      await getProjects();
    }, 4*3600000);

    prepareStickies();

    setTimeout(() => centerCalendarDate(), 100);

    if (personal) {
      if (toggleShowPlannerTasks) {
        getPlannerTasks();
      }

      projectsOptionsSel = projectsOptionsSel;
    }

  });

  let getPeopleView = async () => {
    const response = await post(
      `select contact_id, p.Name
        from people as p
        left join companies as c on c.company_id=p.company_id
        where c.Name='Labit Group' and p.Active=1
        order by p.Name asc`
    );

    peopleViewOptions = response.map((item) => {
      return {
        label: item.Name,
        value: item.contact_id,
      };
    });
  };

  let getProjects = async () => {
    loaded = false;

    let projectsOr = null;

    if (personal) {
      projectsOr = await getAllProjects(true, myId);

      projectsOr = projectsOr.filter((project) => {
        return project.active;
      });
    } else {
      if (projectId !== -1) {
        const project = await getProject(projectId);
        projectsOr = [project];
      } else {
        projectsOr = await getAllProjects(false);

        projectsOr = projectsOr.filter((project) => {
          return project.active;
        });
      }
    }

    if (archive && projectsOr.length > 0) {
      let start = "";
      let end = "";
      projectsOr.forEach((project) => {
        if (start === "") {
          start = project.start;
          end = project.end;
        } else {
          if (project.start < start) {
            start = project.start;
          }
          if (project.end > end) {
            end = project.end;
          }
        }
      });
      startDate = start;
      endDate = end;
      selectedStartDate = moment(start, "YYYY-MM-DD").format("DD/MM/YYYY");
      selectedEndDate = moment(end, "YYYY-MM-DD").format("DD/MM/YYYY");
      centerDate = start;
    }

    projects = projectsOr.map((project) => {
      const phases = project.phases.map((phase) => {
        const subphases = phase.subphases.map((subphase) => {
          return {
            id: subphase.id,
            start: subphase.start,
            end: subphase.end,
            name: subphase.name,
            plannerInfo: null,
          };
        });

        return {
          id: phase.id,
          start: phase.start,
          end: phase.end,
          name: phase.name,
          subphases,
          people: phase.people,
          plannerInfo: null,
        };
      });

      const milestones = project.milestones.map((milestone) => {
        return {
          id: milestone.id,
          name: milestone.name,
          date: milestone.start.split(" ")[0],
          type: milestone.mtype,
        };
      });

      return {
        id: project.id,
        name: project.name,
        code: project.folderLabitCode,
        pic: project.pic,
        start: project.start,
        end: project.end,
        phases,
        milestones,
        plannerInfo: null,
      };
    });

    projectsOptions = projects.map((project) => {
      return {
        value: project.id,
        label: project.code + ' - ' + project.name,
      };
    });
    if (!personal) {
      projectsOptionsSel = [];
    }

    applyFilters();

    projects.forEach((project, index) => {
      project.color = PROJECT_COLORS[index % 5];
      prepareProjectRoles(project);
      prepareSortPhases(project);
      prepareSortMilestones(project);
    });

    if (archive) {
      const project = projects[0];
      peopleViewOptions = peopleViewOptions.filter((item) => {
        let found = false;
        project.phases.forEach((phase) => {
          phase.people.forEach((peop) => {
            if (peop.peopleId === item.value) {
              found = true;
            }
          });
        });
        return found;
      });
    }

    prepareTeamView();

    //console.log(projects);
    //console.log(team);
    //prepareChartBounds();

    //console.log(projects);

    prepareChartBounds();

    loaded = true;
  };

  let prepareTeamView = () => {
    team = [];
    projects.forEach((project) => {
      project.phases.forEach((phase) => {
        phase.people.forEach((p) => {
          if (!personal || (personal && p.peopleId === myId.toString())) {
            let peop = team.find((item) => {
              return item.id === p.peopleId;
            });
            if (!peop) {
              peop = {
                id: p.peopleId,
                name: p.name,
                pic: p.pic,
                height: 0,
                projects: [],
                visible: false,
              };
              team.push(peop);
            }
            let proj = peop.projects.find((item) => {
              return item.id === project.id;
            });
            if (!proj) {
              proj = {
                id: project.id,
                name: project.name,
                code: project.code,
                color: project.color,
                dates: [],
                visible: false,
              };
              peop.projects.push(proj);
            }
            p.dates.forEach((date) => {
              const d = {
                id: date.id,
                phase: phase.name,
                role: p.role,
                start: date.start,
                end: date.end,
                drawStart: date.start,
                drawEnd: date.end,
                topLevel: 0,
              };
              proj.dates.push(d);
            });
          }
        });
      });
    });

    team.forEach((people) => {
      calculateTeamPlanBounds(people);
    });

    team = team;
  };

  let calculateTeamPlanBounds = (people) => {
    people.height = 0;
    let dates = [];
    people.projects.forEach((project) => {
      if (project.visible) {
        project.dates.forEach((date) => {
          date.topLevel = 0;
          if (
            !(date.start < startDate && date.end < startDate) ||
            (date.start > endDate && date.end > endDate)
          ) {
            date.drawStart = date.start < startDate ? startDate : date.start;
            date.drawEnd = date.end > endDate ? endDate : date.end;
          }
          dates.push(date);
        });
      }
    });
    dates.sort((a, b) => {
      if (a.drawStart < b.drawStart) {
        return -1;
      }
      if (a.drawStart > b.drawStart) {
        return 1;
      }
      return 0;
    });
    let maxLevel = 0;
    dates.forEach((date, index) => {
      /*if (people.name === "Beatriz Barberán") {
        console.log("--------");
        console.log("DATE", date);
      }*/
      for (let i = 0; i < index; i++) {
        const d = dates[i];
        /*if (people.name === "Beatriz Barberán") {
          console.log("D", d);
        }*/
        if (
          !(date.start < startDate && date.end < startDate) ||
          (date.start > endDate && date.end > endDate)
        ) {
          if (
            !(d.drawStart < date.drawStart && d.drawEnd < date.drawStart) ||
            (d.drawStart > date.drawEnd && d.drawEnd > date.drawEnd)
          ) {
            const topLevel = d.topLevel + 1;
            if (topLevel > date.topLevel) {
              date.topLevel = topLevel;
              if (date.topLevel > maxLevel) {
                maxLevel = date.topLevel;
              }
              /*if (people.name === "Beatriz Barberán") {
              console.log(date.topLevel);
            }*/
            }
          }
        }
      }
    });
    people.height =
      maxLevel * TEAM_MARGIN_TOP_BAR + PHASE_HEIGHT + TEAM_MARGIN_TOP_BAR * 2;
    people = people;
  };

  let prepareProjectRoles = (project) => {
    project.phases.forEach((phase) => {
      let roles = [];
      phase.people.forEach((peop) => {
        let role = roles.find((item) => {
          return item.role === peop.role;
        });
        if (!role) {
          role = {
            role: peop.role,
            people: [],
          };
          roles.push(role);
        }
        role.people.push(peop);
      });
      phase.prepRoles = roles;
    });
    project = project;
  };

  let prepareStickies = () => {
    stickyPhase = 0;
    stickyStart = 0;
    stickyEnd = 0;
    stickyPeople = 0;
    let acc = stickyPhase + PHASE_WIDTH; /*+ 10 + 2*/
    if (showStartColumn && view === "project") {
      stickyStart = acc;
      acc = acc + START_WIDTH /*+ 1*/;
    }
    if (showEndColumn && view === "project") {
      stickyEnd = acc;
      acc = acc + END_WIDTH /*+ 1*/;
    }
    if (showTeamColumn && view === "project") {
      stickyPeople = acc;
    }
  };

  let prepareSortPhases = (project) => {
    project.phases.sort((a, b) => {
      if (a.start < b.start) {
        return -1;
      }
      if (a.start > b.start) {
        return 1;
      }
      return 0;
    });

    project.phases.forEach((phase) => {
      phase.subphases.sort((a, b) => {
        if (a.start < b.start) {
          return -1;
        }
        if (a.start > b.start) {
          return 1;
        }
        return 0;
      });
    });

    project = project;
  };

  let prepareSortMilestones = (project) => {
    project.milestones.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    });

    project = project;
  };

  let prepareChartBounds = () => {
    /*startDate = "";
    endDate = "";
    projects.forEach((project) => {
      if (startDate === "") {
        startDate = project.start;
        endDate = project.end;
      } else {
        if (project.start < startDate) {
          startDate = project.start;
        }
        if (project.end > endDate) {
          endDate = project.end;
        }
      }
    });

    if (startDate === "") {
      const now = moment();
      startDate = now.format("YYYY") + "-01-01";
      endDate = now.format("YYYY") + "-12-31";
    } else {
      startDate = moment(startDate, "YYYY-MM-DD").format("YYYY-MM") + "-01";
      endDate = moment(endDate, "YYYY-MM-DD")
        .endOf("month")
        .format("YYYY-MM-DD");
    }*/

    monthArray = [];
    weekArray = [];
    dayArray = [];

    let dates = moment(startDate, "YYYY-MM-DD");
    let ed = moment(endDate, "YYYY-MM-DD").format("YYYY-MM");
    do {
      monthArray.push(dates.format("YYYY-MM-DD"));
      dates.add(1, "months");
    } while (dates.format("YYYY-MM") <= ed);

    dates = moment(startDate, "YYYY-MM-DD");
    weekArray.push(dates.format("YYYY-MM-DD"));
    do {
      dates.add(1, "weeks");
      weekArray.push(dates.format("YYYY-MM-DD"));
    } while (dates.format("YYYY-MM-DD") <= endDate);

    dates = moment(startDate, "YYYY-MM-DD");
    do {
      dayArray.push(dates.format("YYYY-MM-DD"));
      dates.add(1, "days");
    } while (dates.format("YYYY-MM-DD") <= endDate);
  };

  let readjustPhaseDates = async (
    phase,
    oldStart,
    newStart,
    oldEnd,
    newEnd
  ) => {
    if (oldStart !== newStart) {
      for (let i = 0; i < phase.subphases.length; i++) {
        const subphase = phase.subphases[i];
        if (subphase.start === oldStart) {
          subphase.start = newStart;
          /* BDD */

          await post(
            `update phase set
              start='${subphase.start}'
              where id=${subphase.id}`
          );
        }
      }
      for (let i = 0; i < phase.people.length; i++) {
        const p = phase.people[i];
        for (let j = 0; j < p.dates.length; j++) {
          const d = p.dates[j];
          if (d.start === oldStart) {
            d.start = newStart;
            /* BDD */

            await post(
              `update projectTeamPeriods set
                start='${d.start}'
                where id=${d.id}`
            );
          }
        }
      }
    }
    if (oldEnd !== oldStart) {
      for (let i = 0; i < phase.subphases.length; i++) {
        const subphase = phase.subphases[i];
        if (subphase.end === oldEnd) {
          subphase.end = newEnd;
          /* BDD */

          await post(
            `update phase set
              end='${subphase.end}'
              where id=${subphase.id}`
          );
        }
      }
      for (let i = 0; i < phase.people.length; i++) {
        const p = phase.people[i];
        for (let j = 0; j < p.dates.length; j++) {
          const d = p.dates[j];
          if (d.end === oldEnd) {
            d.end = newEnd;
            /* BDD */

            await post(
              `update projectTeamPeriods set
                end='${d.end}'
                where id=${d.id}`
            );
          }
        }
      }
    }

    phase = phase;
    projects = projects;
  };

  let prepareTasks = (index) => {
    let project = projects[index];
    project.phases.forEach((phase) => {
      phase.plannerInfo = null;
      phase.subphases.forEach((subphase) => {
        subphase.plannerInfo = null;
      });
    });
    if (project.plannerInfo) {
      project.phases.forEach((phase) => {
        let totalDone = 0;
        let total = 0;
        phase.plannerInfo = copyObject(project.plannerInfo);
        phase.plannerInfo.percCompleted = -1;

        phase.subphases.forEach((subphase) => {
          let totalS = 0;
          let totalSDone = 0;
          phase.plannerInfo.tasks.forEach((t) => {
            const tasksDone = t.tasks.done.filter((item) => {
              return item.bucket.toUpperCase() === subphase.name.toUpperCase();
            });
            totalSDone = totalSDone + tasksDone.length;
            totalS = totalS + tasksDone.length;
            const tasksInProgress = t.tasks.inProgress.filter((item) => {
              return item.bucket.toUpperCase() === subphase.name.toUpperCase();
            });
            totalS = totalS + tasksInProgress.length;
            const tasksToDo = t.tasks.toDo.filter((item) => {
              return item.bucket.toUpperCase() === subphase.name.toUpperCase();
            });
            totalS = totalS + tasksToDo.length;
          });
          total = total + totalS;
          totalDone = totalDone + totalSDone;
          subphase.plannerInfo = {
            percCompleted: totalS === 0 ? 0 : (totalSDone / totalS) * 100,
          };
        });

        phase.plannerInfo.tasks.forEach((t) => {
          t.tasks.done = t.tasks.done.filter((item) => {
            return item.bucket.toUpperCase() === phase.name.toUpperCase();
          });
          totalDone = totalDone + t.tasks.done.length;
          total = total + t.tasks.done.length;
          t.tasks.inProgress = t.tasks.inProgress.filter((item) => {
            return item.bucket.toUpperCase() === phase.name.toUpperCase();
          });
          total = total + t.tasks.inProgress.length;
          t.tasks.toDo = t.tasks.toDo.filter((item) => {
            return item.bucket.toUpperCase() === phase.name.toUpperCase();
          });
          total = total + t.tasks.toDo.length;
        });
        phase.plannerInfo.percCompleted =
          total === 0 ? 0 : (totalDone / total) * 100;
      });

      project.phases.forEach((phase) => {
        phase.plannerInfo.tasks = phase.plannerInfo.tasks.filter((t) => {
          return (
            t.tasks.done.length > 0 ||
            t.tasks.inProgress.length > 0 ||
            t.tasks.toDo.length > 0
          );
        });
        /*if (phase.plannerInfo.tasks.length === 0) {
          phase.plannerInfo = null;
        }*/
        if (phase.plannerInfo.percCompleted === -1) {
          phase.plannerInfo = null;
        }
      });
    }
    //console.log(project);
  };

  let getTeam = async () => {
    const response = await post(
      `SELECT contact_id, Name, Email, PictureUrl from people where company_id = 2 and active = 1;`
    );

    peopleOptions = response.map((item) => {
      return {
        label: item.Name,
        value: {
          id: item.contact_id,
          name: item.Name,
          email: item.Email,
          pic: item.PictureUrl,
        },
      };
    });
    //peopleOptionsSel = peopleOptions[0];
  };

  let getStakeholders = async () => {
    const response = await post(
      `select contact_id, p.Name as peopleName, p.Email, PictureUrl, p.company_id, c.Name as companyName, Logo_url
        from people as p
        left join companies as c on c.company_id=p.company_id
        where c.Name!='Labit Group' and p.Active=1 and c.Active=1
        order by p.Name asc`
    );

    companiesOptions = [];
    response.forEach((p) => {
      let company = companiesOptions.find((c) => {
        return c.value.id === p.company_id;
      });
      if (!company) {
        company = {
          label: p.companyName,
          value: {
            id: p.company_id,
            name: p.companyName,
            logo: p.Logo_url,
            team: [],
          },
        };
        companiesOptions.push(company);
      }
      company.value.team.push({
        label: p.peopleName,
        value: {
          id: p.contact_id,
          name: p.peopleName,
          email: p.Email,
          pic: p.PictureUrl,
        },
      });
      companiesOptions = companiesOptions;
      if (companiesOptions.length > 0) {
        companiesOptionsSel = companiesOptions[0];
      }
    });
    //console.log(companiesOptions);
  };

  let changePlannerView = () => {
    view = viewOptionsSel.value;
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let openPhaseManager = (
    open,
    x = -1,
    y = -1,
    project = null,
    phase = null,
    section
  ) => {
    //console.log(x, y);
    showPhaseManager = open;
    if (open) {
      window.$("#phaseManager-" + id).css({
        left: x,
        top: y,
      });
      managerProject = project;
      managerPhase = phase;
      managerCurrentPage = section;
    }
  };

  let phaseManagerClickSection = (section) => {
    managerCurrentPage = section;
  };

  let phaseManagerOpenEditPhase = () => {
    managerEditingPhase = !managerEditingPhase;
    if (managerEditingPhase) {
      managerPhaseTitle = managerPhase.name;
      managerPhaseStart = moment(managerPhase.start, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
      managerPhaseEnd = moment(managerPhase.end, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
    }
  };

  let phaseManagerEditPhase = async () => {
    if (managerPhaseTitle.trim() !== "") {
      const oldStart = managerPhase.start;
      const oldEnd = managerPhase.end;

      managerPhase.name = managerPhaseTitle;
      managerPhase.start = moment(managerPhaseStart, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      managerPhase.end = moment(managerPhaseEnd, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      managerPhase = managerPhase;
      projects = projects;
      phaseManagerOpenEditPhase(false);
      prepareSortPhases(managerProject);

      const newStart = managerPhase.start;
      const newEnd = managerPhase.end;

      await readjustPhaseDates(
        managerPhase,
        oldStart,
        newStart,
        oldEnd,
        newEnd
      );

      /* BD */

      await post(
        `update phase set
          name='${managerPhase.name}',
          start='${managerPhase.start}',
          end='${managerPhase.end}'
          where id=${managerPhase.id}`
      );

      prepareTeamView();
      applyFilters();
    }
  };

  let phaseManagerOpenEditSubphase = (subphase = null) => {
    managerEditingSubphase = !managerEditingSubphase;
    if (managerEditingSubphase) {
      managerSubphase = subphase;
      if (subphase) {
        managerSubphaseTitle = subphase.name;
        managerSubphaseStart = moment(subphase.start, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        managerSubphaseEnd = moment(subphase.end, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      } else {
        managerSubphaseTitle = "";
        managerSubphaseStart = moment(managerPhase.start, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        managerSubphaseEnd = moment(managerPhase.end, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      }
    }
  };

  let phaseManagerEditSubphase = async () => {
    if (managerSubphaseTitle.trim() !== "") {
      if (
        moment(managerSubphaseStart, "DD/MM/YYYY").format("YYYY-MM-DD") >
        moment(managerSubphaseEnd, "DD/MM/YYYY").format("YYYY-MM-DD")
      ) {
        [managerSubphaseStart, managerSubphaseEnd] = [
          managerSubphaseEnd,
          managerSubphaseStart,
        ];
      }
      if (managerSubphase) {
        managerSubphase.name = managerSubphaseTitle;
        managerSubphase.start = moment(
          managerSubphaseStart,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        managerSubphase.end = moment(managerSubphaseEnd, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );

        /* BD */

        await post(
          `update phase set
            name='${managerSubphase.name}',
            start='${managerSubphase.start}',
            end='${managerSubphase.end}'
            where id=${managerSubphase.id}`
        );

        const phaseStartOld = managerPhase.start;
        let phaseStartNew = managerPhase.start;
        const phaseEndOld = managerPhase.end;
        let phaseEndNew = managerPhase.end;
        if (managerSubphase.start < managerPhase.start) {
          managerPhase.start = managerSubphase.start;
          phaseStartNew = managerPhase.start;
        }
        if (managerSubphase.end > managerPhase.end) {
          managerPhase.end = managerSubphase.end;
          phaseEndNew = managerPhase.end;
        }
        if (phaseStartOld !== phaseStartNew || phaseEndOld !== phaseEndNew) {
          /* BDD */
          await post(
            `update phase set
              start='${phaseStartNew}',
              end='${phaseEndNew}'
              where id=${managerPhase.id}`
          );

          await readjustPhaseDates(
            managerPhase,
            phaseStartOld,
            phaseStartNew,
            phaseEndOld,
            phaseEndNew
          );
        }
      } else {
        const subphase = {
          name: managerSubphaseTitle,
          start: moment(managerSubphaseStart, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          end: moment(managerSubphaseEnd, "DD/MM/YYYY").format("YYYY-MM-DD"),
        };
        managerPhase.subphases.push(subphase);

        /* BD */
        const response = await post(
          `insert into phase (name, start, end, phaseParent, project)
            values('${subphase.name}', '${subphase.start}', '${subphase.end}', ${managerPhase.id}, ${managerProject.id})`
        );
        subphase.id = response[0];

        const phaseStartOld = managerPhase.start;
        let phaseStartNew = managerPhase.start;
        const phaseEndOld = managerPhase.end;
        let phaseEndNew = managerPhase.end;
        if (subphase.start < managerPhase.start) {
          managerPhase.start = subphase.start;
          phaseStartNew = managerPhase.start;
        }
        if (subphase.end > managerPhase.end) {
          managerPhase.end = subphase.end;
          phaseEndNew = managerPhase.end;
        }
        if (phaseStartOld !== phaseStartNew || phaseEndOld !== phaseEndNew) {
          /* BDD */
          await post(
            `update phase set
              start='${phaseStartNew}',
              end='${phaseEndNew}'
              where id=${managerPhase.id}`
          );

          await readjustPhaseDates(
            managerPhase,
            phaseStartOld,
            phaseStartNew,
            phaseEndOld,
            phaseEndNew
          );
        }
      }
      managerPhase = managerPhase;
      projects = projects;
      phaseManagerOpenEditSubphase();
      prepareSortPhases(managerProject);

      prepareTeamView();
      applyFilters();
    }
  };

  const addAllPeople = async () => {
    const people = await post('select contact_id as id from people where company_id = 2 and active = 1;');
    const start = moment().format('YYYY-MM-DD');
    const end = moment().add(1, 'month').format('YYYY-MM-DD');

    projects[0].phases.forEach(async phase => {
      const peopleToAdd = people.filter(user => phase.people.findIndex(u => u.peopleId === user.id) === -1);
      
      let promiseArr = [];
      peopleToAdd.forEach(user => {
        const promise = post(`insert into projectTeam (people, phase, role) values (${user.id}, ${phase.id}, 'Architect');`);
        promiseArr.push(promise);
      });
      
      const idsArr = await Promise.all(promiseArr);
      promiseArr = [];

      idsArr.forEach(id => {
        const promise = post(`insert into projectTeamPeriods (start, end, projectTeam) values (${start}, ${end}, ${[id]});`);
        promiseArr.push(promise);
      });

      await Promise.all(promiseArr);

    });
  }

  const removeAllPeople = async () => {
    let projectTeamQuery = 'delete from projectTeam where id in (';
    let projectTeamPeriodsQuery = 'delete from projectTeamPeriods where projectTeam in (';
    projects[0].phases.forEach(async phase => {
      phase.people.forEach(user => {
        projectTeamQuery += `${user.id},`;
        projectTeamPeriodsQuery += `${user.id},`;
      });
    });
    projectTeamQuery = projectTeamQuery.slice(0, -1);
    projectTeamQuery += ');';
    
    projectTeamPeriodsQuery = projectTeamPeriodsQuery.slice(0, -1);
    projectTeamPeriodsQuery += ');';

    await Promise.all([post(projectTeamQuery), post(projectTeamPeriodsQuery)]);
  }

  let phaseManagerAddPeople = async () => {
    let p = {
      id: accId,
      peopleId: peopleOptionsSel.value.id,
      name: peopleOptionsSel.label,
      pic: peopleOptionsSel.value.pic,
      role: teamRolesOptionsSel.label,
      dates: [
        {
          id: accId - 1,
          start: managerPhase.start,
          end: managerPhase.end,
        },
      ],
    };
    if (managerPhaseAddPeopleAllPhases) {
      for (let i = 0; i < managerProject.phases.length; i++) {
        let phase = managerProject.phases[i];

        const pos = phase.people.findIndex((item) => {
          return (
            item.peopleId === peopleOptionsSel.value.id &&
            item.role === teamRolesOptionsSel.label
          );
        });

        if (pos === -1) {
          phase.people.push(p);

          accId -= 2;

          let response = await post(
            `insert into projectTeam (people, phase, role)
            values (${p.peopleId}, ${phase.id}, '${p.role}')`
          );
          p.id = response[0];

          response = await post(
            `insert projectTeamPeriods (start, end, projectTeam)
            values ('${p.dates[0].start}', '${p.dates[0].end}', '${p.id}')`
          );
          p.dates[0].id = response[0];
        }

        prepareProjectRoles(managerProject);
        managerPhase = managerPhase;
        projects = projects;
      }
    } else {
      const pos = managerPhase.people.findIndex((item) => {
        return (
          item.peopleId === peopleOptionsSel.value.id &&
          item.role === teamRolesOptionsSel.label
        );
      });

      if (pos === -1) {
        managerPhase.people.push(p);

        accId -= 2;

        /* BDD */

        let response = await post(
          `insert into projectTeam (people, phase, role)
            values (${p.peopleId}, ${managerPhase.id}, '${p.role}')`
        );
        p.id = response[0];

        response = await post(
          `insert projectTeamPeriods (start, end, projectTeam)
            values ('${p.dates[0].start}', '${p.dates[0].end}', '${p.id}')`
        );
        p.dates[0].id = response[0];

        prepareProjectRoles(managerProject);
        managerPhase = managerPhase;
        projects = projects;
      }
    }

    prepareTeamView();
    applyFilters();
  };

  let togglePeopleAllPhases = () => {
    managerPhaseAddPeopleAllPhases = !managerPhaseAddPeopleAllPhases;
  };

  let phaseManagerOpenEditPeople = (people = null, date = null) => {
    if (managerPhaseEditingPeople === people) {
      managerPhaseEditingPeople = null;
    } else {
      managerPhaseEditingPeople = people;
      if (managerPhaseEditingPeople) {
        managerPhaseEditingPeopleDate = date;
        if (managerPhaseEditingPeopleDate) {
          managerPhaseEditingPeopleStart = moment(
            date.start,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY");
          managerPhaseEditingPeopleEnd = moment(date.end, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          );
        } else {
          managerPhaseEditingPeopleStart = moment(
            managerPhase.start,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY");
          managerPhaseEditingPeopleEnd = moment(
            managerPhase.end,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY");
        }
      }
    }
  };

  let phaseManagerAddPeopleDates = async () => {
    const start = moment(managerPhaseEditingPeopleStart, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    const end = moment(managerPhaseEditingPeopleEnd, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );

    if (start > end) {
      [start, end] = [end, start];
      [managerPhaseEditingPeopleStart, managerPhaseEditingPeopleEnd] = [
        managerPhaseEditingPeopleEnd,
        managerPhaseEditingPeopleStart,
      ];
    }

    if (managerPhaseEditingPeopleDate) {
      managerPhaseEditingPeopleDate.start = start;
      managerPhaseEditingPeopleDate.end = end;

      /* BDD */

      await post(
        `update projectTeamPeriods set
          start='${managerPhaseEditingPeopleDate.start}',
          end='${managerPhaseEditingPeopleDate.end}'
          where id=${managerPhaseEditingPeopleDate.id}`
      );
    } else {
      let dates = {
        id: accId,
        start,
        end,
      };
      managerPhaseEditingPeople.dates.push(dates);
      accId--;

      /* BDD */

      const response = await post(
        `insert into projectTeamPeriods (start, end, projectTeam)
          values ('${dates.start}', '${dates.end}', ${managerPhaseEditingPeople.id})`
      );
      dates.id = response[0];
    }

    phaseManagerOpenEditPeople(managerPhaseEditingPeople);

    managerPhase = managerPhase;
    managerProject = managerProject;
    projects = projects;

    prepareTeamView();
    applyFilters();
  };

  let managerPhaseDeletePhase = async () => {
    if (managerPhaseDeletingPhase !== managerPhase) {
      managerPhaseDeletingPhase = managerPhase;
      managerPhaseDeletingPhaseTimer = setTimeout(() => {
        managerPhaseDeletingPhase = null;
      }, DELETING_TIME);
    } else {
      managerProject.phases = managerProject.phases.filter((phase) => {
        return phase.id !== managerPhase.id;
      });

      /* BDD */

      await post(
        `delete from phase
        where id=${managerPhase.id}`
      );

      openPhaseManager(false);
      clearTimeout(managerPhaseDeletingPhaseTimer);
      projects = projects;
    }

    prepareTeamView();
    applyFilters();
  };

  let managerPhaseDeleteSubphase = async (subphase) => {
    if (managerPhaseDeletingSubphase !== subphase) {
      managerPhaseDeletingSubphase = subphase;
      managerPhaseDeletingSubphaseTimer = setTimeout(() => {
        managerPhaseDeletingSubphase = null;
      }, DELETING_TIME);
    } else {
      managerPhase.subphases = managerPhase.subphases.filter((subphase) => {
        return subphase.id !== managerPhaseDeletingSubphase.id;
      });

      /* BDD */

      await post(
        `delete from phase
        where id=${subphase.id}`
      );

      clearTimeout(managerPhaseDeletingSubphaseTimer);
      managerPhase = managerPhase;
      projects = projects;
    }
  };

  let managerPhaseDeletePeopleDate = async (peopleDate) => {
    if (managerPhaseDeletingPeopleDate !== peopleDate) {
      managerPhaseDeletingPeopleDate = peopleDate;
      managerPhaseDeletingPeopleDateTimer = setTimeout(() => {
        managerPhaseDeletingPeopleDate = null;
      }, DELETING_TIME);
    } else {
      for (let i = 0; i < managerPhase.people.length; i++) {
        let p = managerPhase.people[i];
        p.dates = p.dates.filter((item) => {
          return item.id !== managerPhaseDeletingPeopleDate.id;
        });

        /* BDD */

        await post(
          `delete from projectTeamPeriods
            where id=${managerPhaseDeletingPeopleDate.id}`
        );

        if (p.dates.length === 0) {
          managerPhase.people = managerPhase.people.filter((item) => {
            return item.id !== p.id;
          });

          /* BDD */

          await post(
            `delete from projectTeam
              where id=${p.id}`
          );
        }
      }

      clearTimeout(managerPhaseDeletingPeopleDateTimer);
      prepareProjectRoles(managerProject);
      managerPhase.people = managerPhase.people;
      managerPhase = managerPhase;
      projects = projects;

      prepareTeamView();
      applyFilters();
    }
  };

  let openMilestoneManager = (
    open,
    x = -1,
    y = -1,
    project = null,
    date = "",
    milestone = null
  ) => {
    showMilestoneManager = open;
    if (open) {
      window.$("#milestoneManager-" + id).css({
        left: x,
        top: y,
      });
      managerProject = project;
      //managerMilestoneDate = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      milestoneManagerOpenEdit(milestone || date !== "", milestone, date);
    }
  };

  let milestoneManagerOpenEdit = (show, milestone = null, date = "") => {
    managerEditingMilestone = show;
    if (managerEditingMilestone) {
      managerEditingMilestoneMilestone = milestone;
      if (milestone) {
        managerMilestoneName = milestone.name;
        managerMilestoneDate = moment(milestone.date, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        milestonesOptionsSel = milestonesOptions.find((item) => {
          return item.value === milestone.type;
        });
      } else {
        managerMilestoneName = "";
        managerMilestoneDate =
          date === ""
            ? moment().format("DD/MM/YYYY")
            : moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
        milestonesOptionsSel = milestonesOptions.find((item) => {
          return item.value === "general";
        });
      }
    }
  };

  let milestoneManagerEdit = async () => {
    if (managerMilestoneName.trim() !== "") {
      if (managerEditingMilestoneMilestone) {
        managerEditingMilestoneMilestone.name = managerMilestoneName;
        managerEditingMilestoneMilestone.date = moment(
          managerMilestoneDate,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        managerEditingMilestoneMilestone.type = milestonesOptionsSel.value;

        /* BD */

        await post(
          `update milestone set 
            name='${managerEditingMilestoneMilestone.name}',
            isAllDay=1,
            start='${managerEditingMilestoneMilestone.date + " 00:00:00"}',
            end='${managerEditingMilestoneMilestone.date + " 23:59:59"}',
            type='${managerEditingMilestoneMilestone.type}'
            where id=${managerEditingMilestoneMilestone.id}`
        );
      } else {
        let milestone = {
          id: accId,
          name: managerMilestoneName,
          date: moment(managerMilestoneDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          type: milestonesOptionsSel.value,
        };
        managerProject.milestones.push(milestone);

        accId--;

        /* BD */

        const response = await post(
          `insert into milestone (name, isAllDay, start, end, type, project)
            values ('${milestone.name}', 1, '${
            milestone.date + " 00:00:00"
          }', end='${milestone.date + " 23:59:59"}', '${milestone.type}', ${
            managerProject.id
          })`
        );
        milestone.id = response[0];
      }
      prepareSortMilestones(managerProject);

      milestoneManagerOpenEdit(false);
      managerProject = managerProject;
      projects = projects;
    }
  };

  let milestoneManagerDelete = async (milestone) => {
    if (managerMilestoneDeleting !== milestone) {
      managerMilestoneDeleting = milestone;
      managerMilestoneDeletingTimer = setTimeout(() => {
        managerMilestoneDeleting = null;
      }, DELETING_TIME);
    } else {
      managerProject.milestones = managerProject.milestones.filter((m) => {
        return m.id !== milestone.id;
      });

      /* BD */
      await post(
        `delete from milestone
          where id=${milestone.id}`
      );

      clearTimeout(managerMilestoneDeletingTimer);
      managerProject = managerProject;
      projects = projects;
    }
  };

  let openNewPhaseManager = (open, x = -1, y = -1, project = null) => {
    showNewPhaseManager = open;
    if (open) {
      window.$("#newPhaseManager-" + id).css({
        left: x,
        top: y,
      });
      managerProject = project;
      managerPhaseTitle = "";
      managerPhaseStart = moment().format("DD/MM/YYYY");
      managerPhaseEnd = moment().format("DD/MM/YYYY");
    }
  };

  let newPhaseManagerSave = async () => {
    if (managerPhaseTitle.trim() !== "") {
      let phase = {
        id: accId,
        name: managerPhaseTitle,
        start: moment(managerPhaseStart, "DD/MM/YYYY").format("YYYY-MM-DD"),
        end: moment(managerPhaseEnd, "DD/MM/YYYY").format("YYYY-MM-DD"),
        subphases: [],
        people: [],
      };
      managerProject.phases.push(phase);
      accId--;

      /* BD */

      let response = await post(
        `insert into phase (name, start, end, project)
          values ('${phase.name}', '${phase.start}', '${phase.end}', ${managerProject.id})`
      );
      phase.id = response[0];

      /* Out Milestone */
      managerEditingMilestoneMilestone = null;
      managerMilestoneName = managerPhaseTitle + " OUT";
      managerMilestoneDate = managerPhaseEnd;
      milestonesOptionsSel = milestonesOptions.find((item) => {
        return item.value === "out";
      });
      milestoneManagerEdit();

      managerPhaseTitle = "";
      managerPhaseStart = moment(phase.end, "YYYY-MM-DD")
        .add(1, "days")
        .format("DD/MM/YYYY");
      managerPhaseEnd = moment(phase.end, "YYYY-MM-DD")
        .add(30, "days")
        .format("DD/MM/YYYY");
      managerProject = managerProject;
      projects = projects;
      prepareSortPhases(managerProject);
      prepareProjectRoles(managerProject);
    }
  };

  let changeZoom = () => {
    dayWidth = zoomOptionsSel.value;
    setTimeout(() => centerCalendarDate(), 100);
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let toggleShowStartColumn = () => {
    showStartColumn = !showStartColumn;
    prepareStickies();
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let toggleShowEndColumn = () => {
    showEndColumn = !showEndColumn;
    prepareStickies();
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let toggleShowTeamColumn = () => {
    showTeamColumn = !showTeamColumn;
    prepareStickies();
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let toggleShowSubphasesColumn = () => {
    showSubphasesColumn = !showSubphasesColumn;
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let toggleEditingPlan = () => {
    editingPlan = !editingPlan;
  };

  let centerCalendarDate = () => {
    const s = window.$("#" + id + " div.CRPContainer");
    const c = window.$("#" + id + " div.CRPTableHeader3");
    const cW = c.width();
    const sW = s.width();

    const daysC = moment(endDate, "YYYY-MM-DD").diff(
      moment(startDate, "YYYY-MM-DD"),
      "days"
    );
    const days = moment(centerDate, "DD/MM/YYYY").diff(
      moment(startDate, "YYYY-MM-DD"),
      "days"
    );
    const p = days / daysC;
    const scrollP = cW * p - sW * 0.5;

    s.scrollLeft(scrollP);

    if (personal) {
      savePlannerConfiguration();
    }
  };

  let changeStartDate = () => {
    startDate = moment(selectedStartDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
      selectedStartDate = moment(startDate, "YYYY-MM-DD").format("DD/MM/YYYY");
      selectedEndDate = moment(endDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    }
    prepareChartBounds();
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let changeEndDate = () => {
    endDate = moment(selectedEndDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    if (endDate < startDate) {
      [startDate, endDate] = [endDate, startDate];
      selectedStartDate = moment(startDate, "YYYY-MM-DD").format("DD/MM/YYYY");
      selectedEndDate = moment(endDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    }
    prepareChartBounds();
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let applyFilters = () => {
    // Projects

    projects.forEach((project) => {
      project.visible = true;
    });

    if (projectsOptionsSel.length > 0) {
      projects.forEach((project) => {
        const pos = projectsOptionsSel.findIndex((item) => {
          return item === project.id;
        });
        if (pos === -1) {
          project.visible = false;
        }
      });
    }

    projects.forEach((project) => {
      if (project.visible && !showEmptyProjects) {
        if (project.phases.length === 0) {
          project.visible = false;
        }
      }
    });

    numVisible = projects.reduce((acc, curr) => {
      if (curr.visible) {
        acc++;
      }
      return acc;
    }, 0);

    projects = projects;

    // Team

    peopleViewOptionsSel = [];
    projects.forEach((project) => {
      if (project.visible) {
        project.phases.forEach((phase) => {
          phase.people.forEach((peop) => {
            const p = peopleViewOptionsSel.find((item) => {
              return item === peop.peopleId;
            });
            if (!p) {
              peopleViewOptionsSel.push(peop.peopleId);
            }
          });
        });
      }
    });

    peopleViewOptionsSel = peopleViewOptionsSel;

    // Projects in teams

    team.forEach((p) => {
      p.projects.forEach((project) => {
        project.visible = false;
        const proj = projects.find((item) => {
          return item.id === project.id;
        });
        if (proj) {
          project.visible = proj.visible;
        }
      });
    });

    team.forEach((people) => {
      calculateTeamPlanBounds(people);
    });

    team = team;
  };

  let applyFilterTeam = () => {
    team.forEach((p) => {
      p.visible = false;
    });

    if (peopleViewOptionsSel.length > 0) {
      team.forEach((p) => {
        const pos = peopleViewOptionsSel.findIndex((item) => {
          return item === p.id;
        });
        if (pos >= 0) {
          p.visible = true;
        }
      });
    }

    team = team;
  };

  let toggleShowEmptyProjects = () => {
    showEmptyProjects = !showEmptyProjects;
    applyFilters();
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let toggleShowMilestones = () => {
    showMilestones = !showMilestones;
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let getTasks = async () => {
    //console.log(projIndex);
    const projectName = projects[projIndex].code;
    projects[projIndex].plannerInfo = null;
    //console.log(projectName);
    outlookGetTasks(
      projectName,
      (plan) => {
        //console.log(plan);
        plans[projIndex] = plan;
        if (plan.length > 0) {
          projects[projIndex].plannerInfo = {
            tasks: plan,
          };
        } else {
          projects[projIndex].plannerInfo = null;
        }
        prepareTasks(projIndex);
        //console.log(projectName, plan);
        //console.log(projects[projIndex]);
        projIndex++;
        if (projIndex < projects.length) {
          getTasks();
        } else {
          plans = plans;
          loadingPlannerTasks = false;
        }
      },
      () => {
        outlookLogin(
          () => {
            outlookGetTasks(
              projectName,
              (plan) => {
                //console.log(plan);
                plans[projIndex] = plan;
                if (plan.length > 0) {
                  projects[projIndex].plannerInfo = {
                    tasks: plan,
                  };
                } else {
                  projects[projIndex].plannerInfo = null;
                }
                prepareTasks(projIndex);
                //console.log(projectName, plan);
                //console.log(projects[projIndex]);
                projIndex++;
                if (projIndex < projects.length) {
                  getTasks();
                } else {
                  plans = plans;
                  loadingPlannerTasks = false;
                }
              },
              () => {
                loadingPlannerTasks = false;
              }
            );
          },
          () => {
            loadingPlannerTasks = false;
          }
        );
      }
    );
  };

  let getPlannerTasks = () => {
    loadingPlannerTasks = true;
    projIndex = 0;
    plans = [];
    getTasks();
  };

  let toggleShowPlannerTasks = () => {
    showPlannerTasks = !showPlannerTasks;
    if (showPlannerTasks) {
      getPlannerTasks();
    }
    if (personal) {
      savePlannerConfiguration();
    }
  };

  let savePlannerConfiguration = () => {
    const conf = {
      selectedStartDate: selectedStartDate,
      selectedEndDate: selectedEndDate,
      startDate: startDate,
      endDate: endDate,
      centerDate,
      timeView: zoomOptionsSel,
      projects: projectsOptionsSel,
      showStartColumn,
      showEndColumn,
      showTeamColumn,
      showSubphasesColumn,
      showMilestones,
      showEmptyProjects,
      showPlannerTasks,
    };
    //console.log("S CONF", conf);
    setProjectManagerDashboard(conf);
  };

  let loadPlannerConfiguration = () => {
    const conf = getProjectManagerDashboard();
    //console.log("CONF", conf);
    if (conf) {
      selectedStartDate = conf.selectedStartDate;
      selectedEndDate = conf.selectedEndDate;
      startDate = conf.startDate;
      endDate = conf.endDate;
      centerDate = conf.centerDate;
      zoomOptionsSel = conf.timeView;
      projectsOptionsSel = conf.projects;
      showStartColumn = conf.showStartColumn;
      showEndColumn = conf.showEndColumn;
      showTeamColumn = conf.showTeamColumn;
      showSubphasesColumn = conf.showSubphasesColumn;
      showMilestones = conf.showMilestones;
      showEmptyProjects = conf.showEmptyProjects;
      showPlannerTasks = conf.showPlannerTasks;
    }
  };

  $: if (projectsOptionsSel) {
    applyFilters();
    if (personal) {
      savePlannerConfiguration();
    }
  }

  $: if (peopleViewOptionsSel) {
    applyFilterTeam();
    if (personal) {
      savePlannerConfiguration();
    }
  }

  $: if (centerView === true) {
    centerCalendarDate();
  }

  $: if (projectId !== -1) {
    getProjects();
  }

  //$: console.log(projectsOptionsSel);

  if (personal === true) {
    loadPlannerConfiguration();
  }

  //$: console.log(projects)

</script>

<div class="CRPMainContainer customScrollbars" {id}>
  <!--<div class="marginLeft" />-->
  <div class="CRPHeader">
    <div class="headerRow">
      <div class="headerInfo">
        {#if !loaded || loadingPlannerTasks}
          <div class="loader">
            <Loading size="40px" />
          </div>
        {:else}
          <span class="headerTitle">Planner</span>
        {/if}
        <span class="headerSubtitle">Start Date</span>
        <div class="headerCalendar">
          <CalendarPicker
            bind:date={selectedStartDate}
            options={[]}
            onChange={() => changeStartDate()}
          />
        </div>
        <span class="headerSubtitle">End Date</span>
        <div class="headerCalendar">
          <CalendarPicker
            bind:date={selectedEndDate}
            options={[]}
            onChange={() => changeEndDate()}
          />
        </div>
        <span class="headerSubtitle">Go to Date</span>
        <div class="headerCalendar">
          <CalendarPicker
            bind:date={centerDate}
            options={[]}
            onChange={() => centerCalendarDate()}
          />
        </div>
        <span class="headerSubtitle">Time View</span>
        <div class="headerCombobox">
          <Combobox
            options={zoomOptions}
            placeholder=""
            bind:selectedValue={zoomOptionsSel}
            onChange={() => changeZoom()}
          />
        </div>
        <span class="headerSubtitle">Planning View</span>
        <div class="headerCombobox">
          <Combobox
            options={viewOptions}
            placeholder=""
            bind:selectedValue={viewOptionsSel}
            onChange={() => changePlannerView()}
          />
        </div>
      </div>
      <div class="headerOptions">
        {#if editable}
          <img
            class="fadedButton"
            src={editingPlan ? "/images/x.svg" : "/images/edit.svg"}
            alt="Edit Planning"
            on:click={() => toggleEditingPlan()}
          />
        {/if}
      </div>
    </div>
    <div class="headerRow headerRow2">
      <div class="headerInfo">
        <span
          class="headerSubtitle {view === 'team' && !personal
            ? ''
            : 'hiddenElement'}">Team</span
        >
        <div
          class="headerCombobox {view === 'team' && !personal
            ? ''
            : 'hiddenElement'}"
        >
          <MultipleSelect
            text=""
            options={peopleViewOptions}
            bind:selected={peopleViewOptionsSel}
            width="165px"
          />
        </div>
        <span
          class="headerSubtitle {!archive && projectId === -1
            ? ''
            : 'hiddenElement'}">Projects</span
        >
        <div
          class="headerCombobox {!archive && projectId === -1
            ? ''
            : 'hiddenElement'}"
        >
          <MultipleSelect
            text=""
            options={projectsOptions}
            bind:selected={projectsOptionsSel}
            width="165px"
          />
        </div>
        <div
          class="headerCheck {view === 'project' ? '' : 'hiddenElement'}"
          on:click={() => toggleShowStartColumn()}
        >
          <img
            src={showStartColumn
              ? "/images/checkbox.svg"
              : "/images/checkbox_empty.svg"}
            alt="Check"
          />
          <span>Show Start Column</span>
        </div>
        <div
          class="headerCheck  {view === 'project' ? '' : 'hiddenElement'}"
          on:click={() => toggleShowEndColumn()}
        >
          <img
            src={showEndColumn
              ? "/images/checkbox.svg"
              : "/images/checkbox_empty.svg"}
            alt="Check"
          />
          <span>Show End Column</span>
        </div>
        <div
          class="headerCheck  {view === 'project' ? '' : 'hiddenElement'}"
          on:click={() => toggleShowTeamColumn()}
        >
          <img
            src={showTeamColumn
              ? "/images/checkbox.svg"
              : "/images/checkbox_empty.svg"}
            alt="Check"
          />
          <span>Show Team Column</span>
        </div>
        <div
          class="headerCheck {view === 'project' ? '' : 'hiddenElement'}"
          on:click={() => toggleShowSubphasesColumn()}
        >
          <img
            src={showSubphasesColumn
              ? "/images/checkbox.svg"
              : "/images/checkbox_empty.svg"}
            alt="Check"
          />
          <span>Show Subphases</span>
        </div>
        <div
          class="headerCheck {view === 'project' ? '' : 'hiddenElement'}"
          on:click={() => toggleShowMilestones()}
        >
          <img
            src={showMilestones
              ? "/images/checkbox.svg"
              : "/images/checkbox_empty.svg"}
            alt="Check"
          />
          <span>Show Milestones</span>
        </div>
        <div
          class="headerCheck {view === 'project' ? '' : 'hiddenElement'}"
          on:click={() => toggleShowEmptyProjects()}
        >
          <img
            src={showEmptyProjects
              ? "/images/checkbox.svg"
              : "/images/checkbox_empty.svg"}
            alt="Check"
          />
          <span>Show Empty Projects</span>
        </div>
        <div
          class="headerCheck {view === 'project' ? '' : 'hiddenElement'}"
          on:click={() => {
            if (!loadingPlannerTasks) {
              toggleShowPlannerTasks();
            }
          }}
        >
          <img
            src={showPlannerTasks
              ? "/images/checkbox.svg"
              : "/images/checkbox_empty.svg"}
            alt="Check"
          />
          <span>Show Planner Progress</span>
        </div>
      </div>
    </div>
  </div>
  {#if loaded}
    <div class="CRPContainer customScrollbars">
      <div class="CRP">
        <div class="CRPTableHeader1">
          <div
            class="phase phaseRowHeader phaseTitleParent borderLeft borderTop borderRight"
            style="width: {PHASE_WIDTH}px; left: {stickyPhase}px;"
          />
          {#if showStartColumn && view === "project"}
            <div
              class="start phaseRowHeader phaseTitleParent borderRight borderTop"
              style="width: {START_WIDTH}px; left: {stickyStart}px;"
            />
          {/if}
          {#if showEndColumn && view === "project"}
            <div
              class="end phaseRowHeader phaseTitleParent borderRight borderTop"
              style="width: {END_WIDTH}px; left: {stickyEnd}px;"
            />
          {/if}
          {#if showTeamColumn && view === "project"}
            <div
              class="people phaseRowHeader phaseTitleParent borderRight borderTop"
              style="width: {PEOPLE_WIDTH}px; left: {stickyPeople}px;"
            />
          {/if}
          {#each monthArray as monthH}
            <CompleteResourcePlannerHeaderMonth
              month={monthH}
              {startDate}
              {endDate}
              {dayWidth}
            />
          {/each}
        </div>
        <div class="CRPTableHeader2">
          <div
            class="phase phaseRowHeader phaseTitle borderLeft borderRight"
            style="width: {PHASE_WIDTH}px; left: {stickyPhase}px;"
          >
            {view === "project" ? "Phase" : "Team"}
          </div>
          {#if showStartColumn && view === "project"}
            <div
              class="start phaseRowHeader phaseTitle borderRight"
              style="width: {START_WIDTH}px; left: {stickyStart}px;"
            >
              Start
            </div>
          {/if}
          {#if showEndColumn && view === "project"}
            <div
              class="end phaseRowHeader phaseTitle borderRight"
              style="width: {END_WIDTH}px; left: {stickyEnd}px;"
            >
              End
            </div>
          {/if}
          {#if showTeamColumn && view === "project"}
            <div
              class="people phaseRowHeader phaseTitle borderRight"
              style="width: {PEOPLE_WIDTH}px; left: {stickyPeople}px;"
            >
              Team
            </div>
          {/if}
          {#each weekArray as weekH}
            <CompleteResourcePlannerHeaderWeek
              week={weekH}
              {startDate}
              {endDate}
              {dayWidth}
            />
          {/each}
        </div>
        <div class="CRPTableHeader3">
          <div
            class="phase phaseRowHeader borderLeft borderRight borderBottom"
            style="width: {PHASE_WIDTH}px; left: {stickyPhase}px;"
          />
          {#if showStartColumn && view === "project"}
            <div
              class="start phaseRowHeader borderRight borderBottom"
              style="width: {START_WIDTH}px; left: {stickyStart}px;"
            />
          {/if}
          {#if showEndColumn && view === "project"}
            <div
              class="end phaseRowHeader borderRight borderBottom"
              style="width: {END_WIDTH}px; left: {stickyEnd}px;"
            />
          {/if}
          {#if showTeamColumn && view === "project"}
            <div
              class="people phaseRowHeader borderRight borderBottom"
              style="width: {PEOPLE_WIDTH}px; left: {stickyPeople}px;"
            />
          {/if}
          {#each dayArray as dayH}
            <CompleteResourcePlannerHeaderDay
              day={dayH}
              {dayWidth}
              CRPid={id}
            />
          {/each}
        </div>
        {#each projects as project}
          {#if project.visible && !project.code == ""}
            <CompleteResourcePlannerProject
              {project}
              {startDate}
              {endDate}
              {dayArray}
              {dayWidth}
              {stickyPhase}
              {stickyStart}
              {stickyEnd}
              {stickyPeople}
              {showStartColumn}
              {showEndColumn}
              {showTeamColumn}
              showSubphases={showSubphasesColumn}
              {editingPlan}
              onClickPhase={(open, x, y, project, phase, section) =>
                openPhaseManager(open, x, y, project, phase, section)}
              onClickMilestone={(open, x, y, project, date, milestone) =>
                openMilestoneManager(open, x, y, project, date, milestone)}
              onClickNewPhase={(open, x, y, project) =>
                openNewPhaseManager(open, x, y, project)}
              {showPlannerTasks}
              {showMilestones}
              CRPid={id}
              {loaded}
              {drawPhases}
              {view}
              fixed={numVisible === 1}
            />
          {/if}
        {/each}
        {#each team as people}
          {#if people.visible}
            <CompleteResourcePlannerTeam
              {people}
              {startDate}
              {endDate}
              {dayArray}
              {dayWidth}
              CRPid={id}
              {loaded}
              {drawPhases}
              {view}
            />
          {/if}
        {/each}
      </div>
    </div>

    <!-- PHASE MANAGER -->

    <div
      id={"phaseManager-" + id}
      class="phaseManager {showPhaseManager ? '' : 'hiddenElement'}"
    >
      <span>
        <div class="close">
          <img
            class="fadedButton"
            src="/images/x.svg"
            alt="Close Popup"
            on:click={() => openPhaseManager(false)}
          />
        </div>
        <div class="menuManager">
          <span
            class="fadedButton {managerCurrentPage === 'phase'
              ? 'selected'
              : ''}"
            on:click={() => phaseManagerClickSection("phase")}>Phase</span
          >
          <span
            class="fadedButton {managerCurrentPage === 'subphases'
              ? 'selected'
              : ''}"
            on:click={() => phaseManagerClickSection("subphases")}
            >Subphases</span
          >
          <span
            class="fadedButton {managerCurrentPage === 'team'
              ? 'selected'
              : ''}"
            on:click={() => phaseManagerClickSection("team")}>Team</span
          >
        </div>
        {#if managerCurrentPage === "phase"}
          <div class="optionTitle">
            <div class="title">
              <span>{managerPhase.name}</span>
            </div>
            <div class="options">
              <img
                class="fadedButton"
                src="/images/edit.svg"
                alt="Edit"
                on:click={() => phaseManagerOpenEditPhase()}
              />
              <img
                class="fadedButton"
                src={managerPhaseDeletingPhase === managerPhase
                  ? "images/trash-red.svg"
                  : "/images/trash.svg"}
                alt="Remove"
                on:click={() => managerPhaseDeletePhase()}
              />
            </div>
          </div>
          <div class="date">
            <span
              >{moment(managerPhase.start, "YYYY-MM-DD").format("DD/MM/YYYY")} -
              {moment(managerPhase.end, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )}</span
            >
          </div>
          {#if managerEditingPhase}
            <div class="managerRow">
              <DialogSpanEdit
                bind:text={managerPhaseTitle}
                action="edit"
                last={false}
                error={null}
                marginBottom="0px"
              />
            </div>
            <div class="managerRow">
              <div class="managerHalfRow">
                <div class="calendar">
                  <CalendarPicker bind:date={managerPhaseStart} options={[]} />
                </div>
              </div>
              <div class="managerHalfRow">
                <div class="calendar">
                  <CalendarPicker bind:date={managerPhaseEnd} options={[]} />
                </div>
              </div>
            </div>
            <div class="buttonRow">
              <div class="button">
                <Button text="Save" onClick={() => phaseManagerEditPhase()} />
              </div>
            </div>
          {/if}
        {/if}
        {#if managerCurrentPage === "subphases"}
          {#each managerPhase.subphases as subphase}
            <div class="optionTitle">
              <div class="title">
                <span>{subphase.name}</span>
              </div>
              <div class="options">
                <img
                  class="fadedButton"
                  src="/images/edit.svg"
                  alt="Edit"
                  on:click={() => phaseManagerOpenEditSubphase(subphase)}
                />
                <img
                  class="fadedButton"
                  src={managerPhaseDeletingSubphase === subphase
                    ? "/images/trash-red.svg"
                    : "/images/trash.svg"}
                  alt="Remove"
                  on:click={() => managerPhaseDeleteSubphase(subphase)}
                />
              </div>
            </div>
            <div class="date">
              <span
                >{moment(subphase.start, "YYYY-MM-DD").format("DD/MM/YYYY")} - {moment(
                  subphase.end,
                  "YYYY-MM-DD"
                ).format("DD/MM/YYYY")}</span
              >
            </div>
          {/each}
          <div class="buttonRow">
            <img
              class="fadedButton add"
              src="/images/plus.svg"
              alt="Add Subphase"
              on:click={() => phaseManagerOpenEditSubphase()}
            />
          </div>
          {#if managerEditingSubphase}
            <div class="managerRow">
              <DialogSpanEdit
                bind:text={managerSubphaseTitle}
                action="edit"
                last={false}
                error={null}
                marginBottom="0px"
              />
            </div>
            <div class="managerRow">
              <div class="managerHalfRow">
                <div class="calendar">
                  <CalendarPicker
                    bind:date={managerSubphaseStart}
                    options={[]}
                  />
                </div>
              </div>
              <div class="managerHalfRow">
                <div class="calendar">
                  <CalendarPicker bind:date={managerSubphaseEnd} options={[]} />
                </div>
              </div>
            </div>
            <div class="buttonRow">
              <div class="button">
                <Button
                  text="Save"
                  onClick={() => phaseManagerEditSubphase()}
                />
              </div>
            </div>
          {/if}
        {/if}
        {#if managerCurrentPage === "team"}
          <div class="managerRow">
            <div class="managerHalfRow">
              <Combobox
                options={peopleOptions}
                placeholder=""
                bind:selectedValue={peopleOptionsSel}
              />
            </div>
            <div class="managerHalfRow">
              <Combobox
                options={teamRolesOptions}
                placeholder=""
                bind:selectedValue={teamRolesOptionsSel}
              />
            </div>
          </div>
          <div class="checkRow" on:click={() => togglePeopleAllPhases()}>
            <img
              src={managerPhaseAddPeopleAllPhases
                ? "/images/checkbox.svg"
                : "/images/checkbox_empty.svg"}
              alt="Check to All Phases"
            />
            <span>Add to all the phases of the project</span>
          </div>
          <div class="buttonRow">
            <div class="button">
              <Button text="Add" onClick={() => phaseManagerAddPeople()} />
            </div>
            <div class="button">
              <Button text="Add all" onClick={addAllPeople} />
            </div>
            <div class="button">
              <Button text="Remove all" onClick={removeAllPeople} />
            </div>
          </div>
          {#each managerPhase.prepRoles as role}
            <div class="managerRow">
              <span>{role.role}</span>
            </div>
            {#each role.people as people}
              <div class="managerRow">
                <div class="peoplePic">
                  <Picture
                    image={people.pic}
                    name={people.name}
                    showHint={true}
                  />
                </div>
                <div class="peopleDates">
                  {#each people.dates as date}
                    <div class="peopleDate">
                      <div class="peopleDateInfo">
                        <span
                          >{moment(date.start, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}
                          - {moment(date.end, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}</span
                        >
                      </div>
                      <div class="peopleDateOptions">
                        <img
                          class="fadedButton"
                          src="/images/edit.svg"
                          alt="Edit"
                          on:click={() =>
                            phaseManagerOpenEditPeople(people, date)}
                        />
                        <img
                          class="fadedButton"
                          src={managerPhaseDeletingPeopleDate === date
                            ? "/images/trash-red.svg"
                            : "/images/trash.svg"}
                          alt="Remove"
                          on:click={() => managerPhaseDeletePeopleDate(date)}
                        />
                      </div>
                    </div>
                  {/each}
                  <div class="buttonRow">
                    <img
                      class="fadedButton add"
                      src="/images/plus.svg"
                      alt="Add Dates"
                      on:click={() => phaseManagerOpenEditPeople(people)}
                    />
                  </div>
                  {#if managerPhaseEditingPeople === people}
                    <div class="managerRow">
                      <div class="managerHalfRow">
                        <div class="calendar">
                          <CalendarPicker
                            bind:date={managerPhaseEditingPeopleStart}
                            options={[]}
                          />
                        </div>
                      </div>
                      <div class="managerHalfRow">
                        <div class="calendar">
                          <CalendarPicker
                            bind:date={managerPhaseEditingPeopleEnd}
                            options={[]}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="buttonRow">
                      <div class="button">
                        <Button
                          text="Save"
                          onClick={() => phaseManagerAddPeopleDates()}
                        />
                      </div>
                    </div>
                  {/if}
                </div>
              </div>
            {/each}
          {/each}
        {/if}
      </span>
    </div>

    <!-- MILESTONE MANAGER -->

    <div
      id={"milestoneManager-" + id}
      class="milestoneManager {showMilestoneManager ? '' : 'hiddenElement'}"
    >
      <div class="close">
        <img
          class="fadedButton"
          src="/images/x.svg"
          alt="Close Popup"
          on:click={() => openMilestoneManager(false)}
        />
      </div>
      <div class="managerRow">
        <span>MILESTONES</span>
      </div>
      {#if showMilestoneManager}
        {#each managerProject.milestones as milestone}
          <div class="managerRow">
            <div class="milestoneInfo">
              <div class="milestone">
                {#if milestone.type !== "general"}
                  <img
                    src={milestone.type === "in"
                      ? "/images/milestone/IN.svg"
                      : "/images/milestone/OUT.svg"}
                    alt="Milestone Type"
                  />
                {/if}
              </div>
              <div class="milestoneDescription">
                <span>{milestone.name}</span>
                <span class="milestoneDate"
                  >{moment(milestone.date, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )}</span
                >
              </div>
            </div>
            <div class="options">
              <img
                class="fadedButton"
                src="/images/edit.svg"
                alt="Edit"
                on:click={() =>
                  milestoneManagerOpenEdit(
                    !managerEditingMilestone ||
                      managerEditingMilestoneMilestone !== milestone,
                    milestone,
                    ""
                  )}
              />
              <img
                class="fadedButton"
                src={managerMilestoneDeleting === milestone
                  ? "images/trash-red.svg"
                  : "/images/trash.svg"}
                alt="Remove"
                on:click={() => milestoneManagerDelete(milestone)}
              />
            </div>
          </div>
        {/each}
      {/if}
      <div class="buttonRow">
        <img
          class="fadedButton add"
          src="/images/plus.svg"
          alt="Add Milestone"
          on:click={() =>
            milestoneManagerOpenEdit(!managerEditingMilestone, null, "")}
        />
      </div>
      {#if managerEditingMilestone}
        <div class="managerRow">
          <DialogSpanEdit
            bind:text={managerMilestoneName}
            action="edit"
            last={false}
            error={null}
            marginBottom="0px"
          />
        </div>
        <div class="managerRow">
          <div class="managerHalfRow">
            <div class="calendar">
              <CalendarPicker bind:date={managerMilestoneDate} options={[]} />
            </div>
          </div>
          <div class="managerHalfRow">
            <Combobox
              options={milestonesOptions}
              placeholder=""
              bind:selectedValue={milestonesOptionsSel}
            />
          </div>
        </div>
        <div class="buttonRow">
          <div class="button">
            <Button text="Save" onClick={() => milestoneManagerEdit()} />
          </div>
        </div>
      {/if}
    </div>

    <!-- NEW PHASE MANAGER -->

    <div
      id={"newPhaseManager-" + id}
      class="newPhaseManager {showNewPhaseManager ? '' : 'hiddenElement'}"
    >
      <div class="close">
        <img
          class="fadedButton"
          src="/images/x.svg"
          alt="Close Popup"
          on:click={() => openNewPhaseManager(false)}
        />
      </div>
      <div class="managerRow">
        <span>NEW PHASE</span>
      </div>
      <div class="managerRow">
        <DialogSpanEdit
          bind:text={managerPhaseTitle}
          action="edit"
          last={false}
          error={null}
          marginBottom="0px"
        />
      </div>
      <div class="managerRow">
        <div class="managerHalfRow">
          <div class="calendar">
            <CalendarPicker bind:date={managerPhaseStart} options={[]} />
          </div>
        </div>
        <div class="managerHalfRow">
          <div class="calendar">
            <CalendarPicker bind:date={managerPhaseEnd} options={[]} />
          </div>
        </div>
      </div>
      <div class="buttonRow">
        <div class="button">
          <Button text="Save" onClick={() => newPhaseManagerSave()} />
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  div.CRPMainContainer {
    background-color: white;
    border-radius: 14px;
    padding: 0px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: hidden;
  }

  div.CRPContainer {
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 1px;
  }

  div.CRPTableHeader1,
  div.CRPTableHeader2,
  div.CRPTableHeader3 {
    display: flex;
    width: fit-content;
    position: sticky;
    z-index: 503;
  }

  div.CRPTableHeader1 {
    top: 0px;
  }

  div.CRPTableHeader2 {
    top: 25px;
  }

  div.CRPTableHeader3 {
    top: 50px;
  }

  div.phase,
  div.start,
  div.end,
  div.people {
    position: sticky;
    background-color: white;
    font-weight: bold;
    flex-shrink: 0;
    flex-grow: 0;
    height: 25px;
    display: flex;
    align-items: center;
  }

  div.start,
  div.end,
  div.people {
    justify-content: center;
  }

  div.phase {
    padding-left: 10px;
  }

  div.phaseManager {
    position: absolute;
    top: 0px;
    left: 0px;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    border: 1px solid var(--labit-card-gray);
    width: 450px;
    color: var(--labit-card-gray);
    z-index: 510;
  }

  div.milestoneManager {
    position: absolute;
    top: 0px;
    left: 0px;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    border: 1px solid var(--labit-card-gray);
    width: 450px;
    color: var(--labit-card-gray);
    z-index: 510;
  }

  div.close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  div.close img {
    width: 12px;
    height: 12px;
  }

  div.optionTitle {
    width: 100%;
    display: flex;
  }

  div.title {
    width: 90%;
  }

  div.options {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  div.options img,
  div.peopleDateOptions img {
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }

  div.date {
    width: 100%;
    font-size: 11px;
    margin-bottom: 10px;
  }

  div.managerRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  div.managerHalfRow {
    width: 49%;
  }

  div.calendar {
    height: 17px;
  }

  div.buttonRow {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  div.button {
    width: 80px;
    height: 25px;
  }

  div.menuManager {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  div.menuManager span {
    padding: 2px 10px 2px 10px;
    margin: 3px 0px 3px 0px;
  }

  div.menuManager span:not(:first-child) {
    border-left: 1px solid lightgray;
  }

  div.menuManager span:not(:last-child) {
    border-right: 1px solid lightgray;
  }

  div.menuManager span.selected {
    font-weight: bold;
  }

  img.add {
    width: 14px;
    height: 14px;
  }

  div.checkRow {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
  }

  div.checkRow img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  div.peoplePic {
    width: 35px;
    height: 35px;
  }

  div.peopleDates {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
  }

  div.peopleDate {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.peopleDateInfo {
    margin-left: 20px;
    font-size: 11px;
  }

  div.milestone {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid white;
    background-color: var(--labit-people-work-plan);
  }

  div.milestone img {
    width: 40%;
  }

  div.milestoneInfo {
    display: flex;
    width: 90%;
  }

  div.milestoneDescription {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }

  span.milestoneDate {
    font-size: 11px;
  }

  div.newPhaseManager {
    position: absolute;
    top: 0px;
    left: 0px;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    border: 1px solid var(--labit-card-gray);
    width: 450px;
    color: var(--labit-card-gray);
    z-index: 510;
  }

  div.CRPHeader {
    width: 100%;
    color: var(--labit-card-gray);
    position: sticky;
    top: 0px;
    z-index: 505;
    background-color: white;
    padding: 20px 0px 20px 0px;
  }

  div.headerInfo {
    display: flex;
    align-items: center;
  }

  span.headerTitle {
    font-size: 22px;
    font-weight: bold;
    margin-right: 50px;
  }

  span.headerSubtitle {
    font-size: 13px;
    margin-right: 13px;
    color: var(--labit-card-gray);
    white-space: nowrap;
  }

  div.headerCalendar {
    height: 17px;
    margin-right: 25px;
  }

  div.headerCombobox {
    width: 175px;
    margin-right: 25px;
    height: 17px;
  }

  div.headerOptions img {
    height: 25px;
    width: 25px;
  }

  div.headerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 20px 7px 20px;
  }

  div.headerCheck {
    display: flex;
    margin-right: 25px;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap;
  }

  div.headerCheck img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  div.loader {
    display: flex;
    justify-content: center;
    margin-right: 50px;
  }

  div.headerRow2 div.headerInfo {
    flex-wrap: wrap;
    margin-top: -12px;
  }

  div.headerRow2 span.headerSubtitle,
  div.headerRow2 div.headerCombobox,
  div.headerRow2 div.headerCheck {
    margin-top: 12px;
  }
</style>
