<script>
  export let imgIcon;
  export let color;
  export let imgWidth = "115px";
</script>

<style>
  div.loginIconContainer {
    width: 422px;
    height: 422px;
    position: relative;
  }

  div.icon {
    position: absolute;
    width: 422px;
    height: 422px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.icon img {
    width: 115px;
    margin-top: -86px;
  }
</style>

<div class="loginIconContainer">
  <svg
    style="position:absolute; fill:{color};"
    width="422px"
    height="422px"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 500 500">
    <g transform="">
      <linearGradient id="lg-0.16784974068078506" x1="0" x2="1" y1="0" y2="0">
        <stop stop-color="#191d3a" offset="0" />
        <stop stop-color="#3b4368" offset="1" />
      </linearGradient>
      <path
        d="M 471.78 200 C 471.78 260 359.88 343.58 302.88 362.58 C 245.88 381.58
        110.259 376.1 75.2593 327.1 C 40.2593 278.1 75.3404 147.52 110.34
        98.5203 C 145.34 49.5203 236.78 46.5414 293.78 65.5414 C 350.78 84.5414
        471.78 140 471.78 200"
        opacity="0.6">
        <animate
          attributeName="d"
          dur="20s"
          repeatCount="indefinite"
          keyTimes="0;0.3333333333333333;0.6666666666666666;1"
          calcmod="spline"
          keySplines="0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9"
          begin="-13.333333333333334s"
          values="M 428 200 C 428 254 350 329 298 346 C 246 363 150 340 118 296
          C 86 252 58 128 90 84 C 122 40 238 59 290 76 C 342 93 428 146 428
          200;M 484 200 C 484 260 358 337 301 356 C 244 375 130 362 95 313 C 60
          264 65 140 100 91 C 135 42 249 8 306 27 C 363 46 484 140 484 200;M 471
          200 C 471 260 360 344 303 363 C 246 382 109 377 74 328 C 39 279 76 148
          111 99 C 146 50 236 49 293 68 C 350 87 471 140 471 200;M 428 200 C 428
          254 350 329 298 346 C 246 363 150 340 118 296 C 86 252 58 128 90 84 C
          122 40 238 59 290 76 C 342 93 428 146 428 200" />
      </path>
      <path
        d="M 454.199 200 C 454.199 254.42 353.36 339.88 301 357 C 248.64 374.12
        151.182 339.519 118.942 295.219 C 86.7018 250.919 68.5008 135.46 100.741
        91.1605 C 132.981 46.8606 248.7 25.6402 301.06 42.7601 C 353.42 59.8801
        454.199 145.58 454.199 200"
        opacity="0.6">
        <animate
          attributeName="d"
          dur="20s"
          repeatCount="indefinite"
          keyTimes="0;0.3333333333333333;0.6666666666666666;1"
          calcmod="spline"
          keySplines="0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9"
          begin="-13.333333333333334s"
          values="M 482 200 C 482 259 355 334 299 352 C 243 370 148 348 113 300
          C 78 252 47 126 82 78 C 117 30 249 12 305 30 C 361 48 482 141 482
          200;M 473 200 C 473 261 359 338 301 357 C 243 376 107 379 71 330 C 35
          281 45 127 81 78 C 117 29 244 20 302 39 C 360 58 473 139 473 200;M 453
          200 C 453 254 353 340 301 357 C 249 374 154 337 122 293 C 90 249 70
          136 102 92 C 134 48 249 26 301 43 C 353 60 453 146 453 200;M 482 200 C
          482 259 355 334 299 352 C 243 370 148 348 113 300 C 78 252 47 126 82
          78 C 117 30 249 12 305 30 C 361 48 482 141 482 200" />
      </path>
      <path
        d="M 412.078 200 C 412.078 250.42 337.079 306.979 289.66 322.099 C
        242.24 337.219 132.84 347 103.54 306.64 C 74.2399 266.28 91.0211 146.1
        120.261 105.741 C 149.501 65.3809 248.34 43.5997 295.76 58.7796 C 343.18
        73.9595 412.078 149.58 412.078 200"
        opacity="0.6">
        <animate
          attributeName="d"
          dur="20s"
          repeatCount="indefinite"
          keyTimes="0;0.3333333333333333;0.6666666666666666;1"
          calcmod="spline"
          keySplines="0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9"
          begin="-13.333333333333334s"
          values="M 457 200 C 457 257 347 317 293 334 C 239 351 140 350 106 304
          C 72 258 80 147 114 101 C 148 55 246 28 300 46 C 354 64 457 143 457
          200;M 476 200 C 476 257 354 338 300 355 C 246 372 146 347 112 301 C 78
          255 60 132 93 86 C 126 40 238 53 292 71 C 346 89 476 143 476 200;M 408
          200 C 408 250 336 305 289 320 C 242 335 132 347 103 307 C 74 267 93
          147 122 107 C 151 67 249 43 296 58 C 343 73 408 150 408 200;M 457 200
          C 457 257 347 317 293 334 C 239 351 140 350 106 304 C 72 258 80 147
          114 101 C 148 55 246 28 300 46 C 354 64 457 143 457 200" />
      </path>
    </g>
  </svg>
  <div class="icon">
    <img src={imgIcon} alt="Icon" style="width: {imgWidth};" />
  </div>
</div>
