<script>
  export let text;
  export let placeholder = "";
  export let id;
</script>

<style>
  div.RDSGKeyEdit {
    width: 100%;
    height: 22px;
    background-color: white;
    border-radius: 19px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;
    border-color: var(--labit-card-gray);
    border-width: 1px;
    border-style: solid;
    margin-top: 4px;
  }

  input {
    border-width: 0px;
    background-color: transparent;
    width: 100%;
  }

  input::placeholder {
    font-size: 12px;
  }
</style>

<div class="RDSGKeyEdit">
  <input {id} type="text" bind:value={text} {placeholder} />
</div>
