<script>
	export let popupType;
	export let popupText;
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	function dispatchBtnClick(accepted) {
		dispatch("dispatchedClick", {
			accepted: accepted
		});
	}
</script>

<div class="alert">
	<span>{popupText}</span>
	{#if popupType === "alert"}
		<button on:click={ () => dispatchBtnClick(false) }>OK</button>
	{:else if popupType === "info"}
		<button on:click={ () => dispatchBtnClick(true) }>OK</button>
	{:else if popupType === "prompt"}
		<button class="decline" on:click={ () => dispatchBtnClick(false) }>No</button>
		<button class="accept" on:click={ () => dispatchBtnClick(true) }>Yes</button>
	{/if}
</div>

<style>
	.alert {
		padding: 3%;
		width: 100%;
		height: 100%;
		margin: 0;
		display: flex;
		text-align: center;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	span {
		width: 100%;
		display: block;
		word-wrap: break-word;
	}

	button {
		width: 100px;
		height: 50px;
		margin: 2%;
		border-radius: 20px;
		border: none;
		background-color: aliceblue;
	}

	.decline:hover {
		background-color: #ffe2e2;
	}
	.accept:hover {
		background-color: #e0ffd5;
	}

</style>