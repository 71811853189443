<script>
  export let title;
  export let RDSInfo;
  export let selectedCompany;
  export let selectedVersion;
  export let selectedProject;
  export let selFilterEpigraph;
  export let selFilterSpace;
  export let selFilterType;
  export let filterRed;
  export let filterYellow;
  export let filterGreen;

  import Light from "./Light.svelte";
  //import SwipeDot from "./SwipeDot.svelte";
  import ChartItem from "./ChartItem.svelte";
  import RoomItem from "./RoomItem.svelte";

  import { onMount } from "svelte";

  let currentPage = 0;
  let shownCharts = false;
  let mySwipe = null;

  let visible = true;

  onMount(async () => {
    /*var element = document.getElementById("slider-" + title);
    mySwipe = new Swipe(element, {
      startSlide: 0,
      auto: 0,
      draggable: true,
      autoRestart: false,
      continuous: true,
      disableScroll: true,
      stopPropagation: true,
      callback: function(index, element) {},
      transitionEnd: function(index, element) {
        currentPage = index;
      }
    });*/
    //toggleDiv(0);
    shownCharts = false;
  });

  /*let moveToPage = index => {
    mySwipe.slide(index, 250);
  };*/

  let toggleDiv = time => {
    //window.$("#charts-" + title).slideToggle(time, function() {});
    shownCharts = !shownCharts;
    /*if (shownCharts) {
      moveToPage(currentPage);
    }*/
  };

  $: {
    if (selFilterEpigraph.length > 0) {
      const pos = selFilterEpigraph.findIndex(sE => {
        return sE === title;
      });
      visible = pos >= 0;
    } else {
      visible = true;
    }
  }

  $: {
    if (!visible) {
      shownCharts = false;
    }
  }
</script>

<style>
  div.RDSColumnContainer {
    width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  div.RDSColumnContainer:not(:last-child) {
    margin-right: 15px;
  }

  div.header {
    width: 100%;
    height: 167px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
  }

  div.header img {
    height: 18%;
  }

  div.header span {
    font-size: 21px;
    color: white;
    margin-top: 21px;
  }

  div.chartInfo {
    width: 100%;
    height: 52px;
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: 14px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 17px;
  }

  div.chartInfoArea {
    width: 100%;
    height: 22px;
    display: flex;
    justify-content: space-between;
  }

  div.chartInfoArea div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  div.chartInfoAreaArrow img {
    height: 100%;
    cursor: pointer;
  }

  img.arrow {
    height: 25% !important;
    margin-left: 11px;
  }

  div.charts {
    width: 100%;
    height: 308px;
    background-color: white;
    border-radius: 14px;
    margin-top: 17px;
    position: relative;
  }

  div.swipe-dev {
    height: 100%;
  }

  div.swipe-dev div.swipe-wrap-dev {
    height: 100%;
  }

  div.dots {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  div.chart1,
  div.chart2,
  div.chart3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.chart2 img,
  div.chart3 img {
    width: 85%;
  }

  div.chart1Charts {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  div.rooms {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    margin-bottom: 19px;
  }
</style>

{#if visible}

  <div class="RDSColumnContainer">

    <div class="header" style="background-color: {RDSInfo.color}">
      <img src={RDSInfo.icon} alt="RDS Icon" />
      <span style="color: {RDSInfo.textColor}">{title}</span>
    </div>

    <div class="chartInfo">
      <div class="chartInfoArea">
        <div>
          <Light
            type="green"
            size="22px"
            marginRight="7px"
            text={RDSInfo.charts.standard.NSpaces.replace(/%/, '')}
            strong={true} />
          <Light
            type="yellow"
            size="22px"
            marginRight="7px"
            text={RDSInfo.charts.replaced.NSpaces.replace(/%/, '')}
            strong={true} />
          <Light
            type="red"
            size="22px"
            text={RDSInfo.charts.wrong.NSpaces.replace(/%/, '')}
            strong={true} />
        </div>
        <div class="chartInfoAreaArrow" on:click={() => toggleDiv(250)}>
          <img src="/images/charts.png" alt="Chart Icon" />
          <img
            class="arrow"
            src={shownCharts ? '/images/up_arrow.png' : '/images/down_arrow.png'}
            alt="Chart Icon" />
        </div>
      </div>
    </div>

    {#if shownCharts}
      <div class="charts" id={'charts-' + title}>
        <div class="swipe-dev">
          <div class="swipe-wrap-dev">
            <div class="chart1">
              <div class="chart1Charts">
                <ChartItem
                  id={'charts-' + title + '-STANDARD'}
                  title="STANDARD"
                  chartInfo={RDSInfo.charts.standard} />
                <ChartItem
                  id={'charts-' + title + '-REPLACED'}
                  title="REPLACED"
                  chartInfo={RDSInfo.charts.replaced} />
                <ChartItem
                  id={'charts-' + title + '-WRONG'}
                  title="WRONG"
                  chartInfo={RDSInfo.charts.wrong} />
              </div>
            </div>
            <!--<div class="chart2">
          <img src="/fake_images/chart1.png" alt="Chart Image 1" />
        </div>
        <div class="chart3">
          <img src="/fake_images/chart2.png" alt="Chart Image 2" />
        </div>-->
          </div>
        </div>
        <!--{#if shownCharts}
      <div class="dots">
        <SwipeDot
          active={currentPage === 0}
          size="10px"
          onClick={() => moveToPage(0)} />
        <SwipeDot
          active={currentPage === 1}
          size="10px"
          onClick={() => moveToPage(1)} />
        <SwipeDot
          active={currentPage === 2}
          size="10px"
          onClick={() => moveToPage(2)} />
      </div>
    {/if}-->
      </div>
    {/if}

    <div class="rooms">
      {#each RDSInfo.items as item}
        <RoomItem
          {item}
          {selectedCompany}
          {selectedVersion}
          {selectedProject}
          selectedProgram={item.idProgram}
          selectedSpace={item.idSpace}
          selectedRoom={item.idRoom}
          {selFilterSpace}
          {selFilterType}
          {filterRed}
          {filterYellow}
          {filterGreen} />
      {/each}
    </div>

  </div>
{/if}
