<script>
    import dayjs from 'dayjs';
    import { onMount, createEventDispatcher } from 'svelte';
    import { getTimeArr } from './utils';
    import { tooltip } from '../../../components/tooltip/tooltip';

    export let options;
    export let role = 'User';

    const dispatch = createEventDispatcher();

    const start = options.start || dayjs().startOf('month');
    const end = options.end || dayjs().endOf('month');
    const rows = options.rows || [];
    const data = options.data || [];
    const generalHolidays = options.generalHolidays || {};

    const smoothScroll = (day) => {
        const dayStr = day.format('YYYY-MM-DD');
        const column = document.querySelector(`[day='${dayStr}']`);
        if (column !== null) {
            column.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
        }
    }
    
    const absences = data.reduce((acc, abs) => {
        const approved = abs.accepted_by_ceo;
        if (approved !== "-1") {
          const userId = abs.user_id;
          const absenceStart = dayjs(parseInt(abs.start));
          const absenceEnd = dayjs(parseInt(abs.end));
          const type = abs.absenceType;
          const color = abs.color;
          const color_light = abs.color_light;
          const description = abs.description;

          const daysArr = getTimeArr(absenceStart, absenceEnd, 'days');

          daysArr.forEach(day => {
              const monthStr = day.format('YYYY-MM');
              const dayStr = day.format('YYYY-MM-DD');

              if (!acc.hasOwnProperty(monthStr)) acc[monthStr] = {};
              if (!acc[monthStr].hasOwnProperty(dayStr)) { 
                  acc[monthStr][dayStr] = [{ id: userId, type: type, color: color, color_light: color_light, description: description, approved: approved }];
              } else {
                  acc[monthStr][dayStr].push({ id: userId, type: type, color: color, color_light: color_light, description: description, approved: approved });
              }
          }); 
        }
        return acc;
    }, {});

    const months = getTimeArr(start, end, 'months');
    const time = months.reduce((acc, month) => {
        const daysArr = getTimeArr(month.startOf('month'), month.endOf('month'), 'days');
        const daysObj = daysArr.reduce((acc, day) => {
            const monthStr = day.format('YYYY-MM');
            const dayStr = day.format('YYYY-MM-DD');
            if (!absences.hasOwnProperty(monthStr)) {
                acc[dayStr] = {
                    day: day,
                    absences: []
                }
            } else {
                if (!absences[monthStr].hasOwnProperty(dayStr)) {
                    acc[dayStr] = {
                        day: day,
                        absences: []
                    }
                } else {
                    acc[dayStr] = {
                        day: day,
                        absences: absences[monthStr][dayStr]
                    }
                }
            }
            return acc;
        }, {});
        const obj = { month: month, days: daysObj };
        const monthStr = month.format('YYYY-MM');
        acc[monthStr] = obj;
        return acc;
    }, {});

    const timeKeys = Object.keys(time);

    onMount(() => {
        smoothScroll(dayjs());
    });

</script>

<table>
    <colgroup>
        <col span="1" style="min-width: 170px !important; padding: 0px 20px; background-color: white; ">
        {#each timeKeys as key}
        {@const days = time[key].days}
        {@const daysKeys = Object.keys(days)}
        {#each daysKeys as dayKey, i}
            {@const day = days[dayKey].day}
            <col span="1" style="background-color: {dayjs().isSame(day, 'day') ? '#f5eddf' : day.day() === 0 || day.day() === 6 ? '#faf9fd' : ''}; border-right: {daysKeys.length-1 === i ? '1px solid #a5a5a5' : ''};">
        {/each}
        {/each}
    </colgroup>
    <thead>
        <tr>
            <th></th>
            {#each timeKeys as key}
            {@const days = Object.keys(time[key].days)}
            {@const month = time[key].month}
            <th colspan={days.length}>{month.format('YYYY MMMM')}</th>
            {/each}
        </tr>
        <tr>
            <th>Name</th>
            {#each timeKeys as key}
            {@const days = time[key].days}
            {@const daysKeys = Object.keys(days)}
                {#each daysKeys as key}
                <th>{days[key].day.format('DD')}</th>
                {/each}
            {/each}
        </tr>
    </thead>
    <tbody>
        {#each rows as {value, picture, id, location}}
        {@const genHolidays = generalHolidays.hasOwnProperty(location) ? generalHolidays[location] : {}}
        <tr>
            <th class="flex pointer" on:click={ () => { dispatch('userSelected', id) } }>
                <img class="profile-picture" src={picture} alt={value}>
                <span>{value}</span>
            </th>
            {#each timeKeys as key}
            {@const days = time[key].days}
            {@const daysKeys = Object.keys(days)}
                {#each daysKeys as key}
                {@const day = days[key]}
                {@const absenceIndex = day.absences.findIndex(d => parseInt(d.id) === parseInt(id))}
                {@const dayStr = day.day.format('YYYY-MM-DD')}
                {#if genHolidays.hasOwnProperty(dayStr) }
                    <td use:tooltip={{ x: -40, y: -80 }} day={dayStr} description={`Holidays - ${genHolidays[dayStr]}`} style="background-color: #e7e3f1"></td>
                {:else if absenceIndex === -1 || day.day.format('d') === '0' || day.day.format('d') === '6'}
                    <td day={dayStr}></td>
                {:else}
                    {#if role === 'Admin'}
                    <td use:tooltip={{ x: -40, y: -80 }} day={dayStr} description={`${day.absences[absenceIndex].type} - ${day.absences[absenceIndex].description}`} style="background-color: {day.absences[absenceIndex].approved === "0" ? day.absences[absenceIndex].color_light : day.absences[absenceIndex].color}"></td>
                    {:else}
                    <td use:tooltip={{ x: -40, y: -80 }} day={dayStr} description={`${day.absences[absenceIndex].type}`} style="background-color: {day.absences[absenceIndex].approved === "0" ? day.absences[absenceIndex].color_light : day.absences[absenceIndex].color}"></td>
                    {/if}
                {/if}
                {/each}
            {/each}
        </tr>
        {/each}
    </tbody>
</table>

<style>
    .pointer {
        cursor: pointer;
    }
    
    .flex {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    thead {
        background: #f7f7f7;
        position: sticky;
        z-index: 2;
        top: 0px;
    }

    thead th {
        padding: 4px;
    }
    
    thead tr > th:first-child {
        background: #f7f7f7;
        position: sticky;
        z-index: 3;
        left: 0;
        top: 0;
    }

    tbody tr > :first-child {
        background: #f7f7f7;
        position: sticky;
        z-index: 1;
        left: 0;
        height: 100%;
    }

    tbody th {
        padding: 4px 16px;
    }

    .profile-picture {
        width: 14px;
        height: 14px;
        border-radius: 100%;
    }

    table {
        white-space: nowrap;
        color: #4b4c68;
        font-size: 10px;
        font-weight: 100;
        border-collapse: collapse;
        height: 100%;
    }

    td {
        padding: 0 8px;
        width: 20px;
        border: 1px ridge #e1e1e1;
    }

    tr:hover{
		background-color: rgba(0,0,0,.04);
	}
</style>