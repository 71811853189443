<script>
  export let text;
  export let action;
  export let last = false;
  export let noScrolls = false;
  export let height = "117px";

  $: text = text.replace(/'/g, "´");
</script>

<style>
  div.content {
    width: 100%;
    margin-bottom: 8px;
    font-size: 13px;
    color: var(--labit-card-lightgray);
  }

  div.noScrolls {
    max-height: 117px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  div.last {
    margin-bottom: 0px;
  }

  textarea {
    width: 100%;
    height: 100%;
    border-radius: 14px;
    border-color: var(--labit-card-lightgray);
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
  }

  textarea.customScrollbars::-webkit-scrollbar-track {
    background: transparent;
  }

  textarea.customScrollbars::-webkit-scrollbar-thumb {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 2px solid transparent;
  }

  span {
    white-space: pre-line;
  }

  span:empty:before {
    content: "\200b";
  }
</style>

{#if action === 'show'}
  <div
    class="content span {noScrolls ? '' : 'noScrolls'} customScrollbars {last ? 'last' : ''}">
    <span>{text}</span>
  </div>
{:else}
  <div class="content {last ? 'last' : ''}">
    <textarea
      class="customScrollbars"
      style="height: {height}"
      bind:value={text} />
  </div>
{/if}
