<script>
  export let title;
  export let perc;
  export let answered;
</script>

<div
  class="HOTPStatsItemContainer"
  style={answered
    ? "background-color: var(--labit-card-gray)"
    : "background-color: var(--labit-light-red)"}
>
  <span>{title + " - " + Math.round(perc * 100) / 100 + "%"}</span>
</div>

<style>
  div.HOTPStatsItemContainer {
    font-size: 15px;
    font-weight: bold;
    color: white;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
    margin: 20px 20px 10px 20px;
  }
</style>
