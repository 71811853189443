<script>
  import DashboardTitle from "../../../../components/dashboard/DashboardTitle.svelte";
  import TaskWidgetContainer from "./TaskWidgetContainer.svelte";

  export let boxTitle = null;
  export let taskList;
  export let editable;

</script>

<div class="dashboardContainer">
  <DashboardTitle title={boxTitle} numEvents={(taskList.todo.length + taskList.progress.length)} />
  <div class="dashboardBody">
    <div class="messages customScrollbars">
      {#each taskList.progress as task}
        <TaskWidgetContainer on:updated
          {task}
          {editable}
          status={"inprogress"}
        />
      {/each}
      {#each taskList.todo as task}
        <TaskWidgetContainer on:updated
          {task}
          {editable}
          status={"todo"}
        />
      {/each}
    </div>
  </div>
  
</div>

<style>
  div.dashboardContainer {
    width: 100%;
    height: 100%;
  }

  div.messages {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  div.dashboardBody {
    width: 100%;
    height: 85%;
    padding: 5% 0 5% 4%;
  }
</style>
