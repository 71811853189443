<script>
  export let standard;
  export let onEdit;

  import Picture from "./Picture.svelte";
  import ButtonIcon from "./ButtonIcon.svelte";

  import { getRole } from "../js/localInfo";
  import { openURL } from "../js/openURL";
  import { openNewWindow } from "../js/openNewWindow";

  let myRole = getRole();

  let projects = [];

  let expanded = false;

  let toggleExpand = () => {
    expanded = !expanded;
  };

  $: if (standard && standard.projects) {
    projects = standard.projects.filter((project) => {
      return project.visible;
    });
  }

  //$: console.log(standard);
</script>

{#if standard.projects.length === 0}
  <div class="standardItemContainer">
    <div class="standard">
      <div class="standardInfo">
        <img src="/images/book.svg" alt="Standard Icon" />
        <span>{standard.company.toUpperCase()}</span>
        <span>{standard.product.toUpperCase()}</span>
        <span>{standard.location.toUpperCase()}</span>
        <span>{standard.version.toUpperCase()}</span>
      </div>
      <div
        class="projects {myRole !== 'Admin' && myRole !== 'Analytics'
          ? 'full'
          : ''}"
      >
        <div class="projectInfo">
          <div class="project" />
        </div>
      </div>
      <div class="projectManager" />
      <div class="buttons" />
      <div class="edit fadedButton" on:click={onEdit()}>
        <span>EDIT</span>
        <img src="/images/edit.svg" alt="Edit Standard" />
      </div>
      <div class="arrow fadedButton" on:click={() => toggleExpand()}>
        <img
          class={expanded ? "hiddenElement" : ""}
          src="/images/down_arrow.svg"
          alt="Expand Arrow"
        />
        <img
          class={expanded ? "" : "hiddenElement"}
          src="/images/up_arrow.svg"
          alt="Expand Arrow"
        />
      </div>
    </div>
  </div>
{:else}
  <div class="standardItemContainer">
    {#each projects as project, index}
      {#if index === 1 && expanded}
        <div class="spacer" />
      {/if}
      <div class="standard {index > 0 && !expanded ? 'hiddenElement' : ''}">
        <div class="standardInfo">
          {#if index === 0}
            <img src="/images/book.svg" alt="Standard Icon" />
            <span>{standard.company.toUpperCase()}</span>
            <span>{standard.product.toUpperCase()}</span>
            <span>{standard.location.toUpperCase()}</span>
            <span>{standard.version.toUpperCase()}</span>
          {/if}
        </div>
        <div
          class="projects {myRole !== 'Admin' && myRole !== 'Analytics'
            ? 'full'
            : ''}"
        >
          <div class="projectInfo">
            <div class="project">
              <img src="/images/factory.svg" alt="Project Icon" />
              <span>{project.timeLabitCode.toUpperCase()}</span>
              <span>{project.folderLabitCode.toUpperCase()}</span>
              <span>{project.name.toUpperCase()}</span>
            </div>
          </div>
        </div>
        <div class="projectManager">
          {#if project.projectManager}
            <div class="pic">
              <Picture
                image={project.projectManagerPic}
                name={project.projectManager}
                grayscale={true}
                small={true}
              />
            </div>
            <span>{project.projectManager.toUpperCase()}</span>
          {/if}
        </div>
        <div class="buttons">
          <div
            class="RDS"
            on:click={() =>
              openURL(
                "/roomdatasheet/" +
                  standard.companyId +
                  "/" +
                  standard.id +
                  "/" +
                  project.id,
                true
              )}
            on:contextmenu={(ev) => {
              ev.preventDefault();
              openNewWindow(
                "/skylab-main/roomdatasheet/" +
                  standard.companyId +
                  "/" +
                  standard.id +
                  "/" +
                  project.id
              );
              return false;
            }}
          >
            <img src="/images/room.svg" alt="RDS Icon" />
          </div>
          <div class="button {project.active ? '' : 'hiddenElement'}">
            <ButtonIcon
              text="ACTIVE"
              img={null}
              width="95%"
              backgroundColor="var(--labit-button-green)"
              textColor="white"
              borderColor={null}
              onClick={null}
            />
          </div>
          <div class="button {project.active ? 'hiddenElement' : ''}" />
          <div class="button">
            <ButtonIcon
              text="PDF"
              img="/images/pdf-gray.svg"
              width="95%"
              backgroundColor="white"
              onClick={() =>
                openURL(
                  "/roomdatasheet/" +
                    standard.companyId +
                    "/" +
                    standard.id +
                    "/" +
                    project.id +
                    "/printmode",
                  true
                )}
              onRightClick={() => {
                openNewWindow(
                  "/skylab-main/roomdatasheet/" +
                    standard.companyId +
                    "/" +
                    standard.id +
                    "/" +
                    project.id +
                    "/printmode"
                );
              }}
            />
          </div>
        </div>
        <div
          class="edit {index === 0 ? 'fadedButton' : ''}"
          on:click={index === 0 ? onEdit() : null}
        >
          {#if index === 0}
            <span>EDIT</span>
            <img src="/images/edit.svg" alt="Edit Standard" />
          {/if}
        </div>
        <div class="arrow fadedButton" on:click={() => toggleExpand()}>
          {#if index === 0}
            <img
              class={expanded ? "hiddenElement" : ""}
              src="/images/down_arrow.svg"
              alt="Expand Arrow"
            />
            <img
              class={expanded ? "" : "hiddenElement"}
              src="/images/up_arrow.svg"
              alt="Expand Arrow"
            />
          {/if}
        </div>
      </div>
    {/each}
  </div>
{/if}

<style>
  div.standardItemContainer {
    display: flex;
    font-size: 13px;
    color: var(--labit-card-gray);
    align-items: flex-start;
    font-weight: bold;
    margin-bottom: 19px;
    background-color: white;
    border-radius: 15px;
    padding: 17px 0px 17px 26px;
    flex-direction: column;
    width: 100%;
  }

  div.standard {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    padding-right: 26px;
  }

  div.standard:last-child,
  div.standard:first-child {
    margin-bottom: 0px;
  }

  div.standard span,
  div.project span {
    margin-right: 13px;
  }

  div.standardInfo {
    display: flex;
    width: 35%;
    align-items: center;
  }

  div.projects {
    width: 25%;
    display: flex;
    flex-direction: column;
  }

  div.full {
    width: 66.3% !important;
  }

  div.edit {
    width: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.edit span {
    margin: 0px;
  }

  div.edit img {
    height: 21px;
    margin: 0px 0px 0px 5px;
  }

  div.projectInfo {
    display: flex;
    width: 100%;
  }

  div.project {
    width: 100%;
    display: flex;
    align-items: center;
  }

  div.projectManager {
    width: 15%;
    display: flex;
    align-items: center;
  }

  div.buttons {
    width: 15%;
    display: flex;
    align-items: center;
  }

  div.arrow {
    width: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.arrow img {
    height: 21px;
    margin: 0px;
  }

  img {
    width: 21px;
    margin-right: 13px;
  }

  div.RDS {
    display: flex;
    cursor: pointer;
    align-items: center;
    width: 10%;
    font-size: 12px;
  }

  div.RDS img {
    margin-right: 6px;
  }

  div.button {
    width: 40%;
    display: flex;
    justify-content: center;
  }

  div.spacer {
    width: 100%;
    height: 1px;
    background-color: var(--labit-card-gray);
    margin: 10px 0px 10px 0px;
  }

  div.pic {
    width: 21px;
    height: 21px;
    margin-right: 5px;
  }

  /* Animations */

  div.RDS {
    transition: opacity 0.5s linear 0s;
  }

  div.RDS:hover {
    opacity: 0.5;
  }
</style>
