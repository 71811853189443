<script>
  export let idProject = -1;
  export let dashboard = false;
  export let refreshCompanyList = false;
  export let refreshProjects = false;
  export let selectedProjectPDashboard = -1;
  export const externalUrls = [];

  //export let showMenu;

  import Menu from "../components/Menu.svelte";
  import TextEdit from "../components/TextEdit.svelte";
  import ProjectCard from "../components/ProjectCard.svelte";
  import ProjectList from "../components/ProjectList.svelte";
  import DialogProject from "../components/DialogProject.svelte";
  import DialogRemove from "../components/DialogRemove.svelte";
  import SubmenuCombobox from "../components/SubmenuCombobox.svelte";
  import SubmenuDoubleCombobox from "../components/SubmenuDoubleCombobox.svelte";
  import SubmenuButton from "../components/SubmenuButton.svelte";
  import GoogleMaps from "../components/GoogleMaps.svelte";
  import Loading from "../components/Loading.svelte";
  import SubmenuMultipleSelect from "../components/SubmenuMultipleSelect.svelte";

  import { post, rpost, LABIT_PUBLIC_FOLDER } from "../js/labit-connection";
  import { getUserId, getRole } from "../js/localInfo";
  import { removeAccents } from "../js/removeAccents";
  import { randomString } from "../js/randomString";
  import { outlookCreateCalendar } from "../components/dashboard/outlook/outlook";
  import {
    getAllProjects,
    updateCurrentPhases,
    updateCurrentStatus,
  } from "../js/projects";
  import { copyObject } from "../js/copyObject";
  import { prepareSearch } from "../js/prepareSearch";
  import { openURL } from "../js/openURL";
  import { createProjectPicName } from "../js/createPicNames";

  import { onMount, beforeUpdate } from "svelte";

  import moment from 'moment';
  const PAGE = dashboard ? "PLACES" : "ARCHIVE";

  let projects = [];
  let platformLinks = [];


  let selectedProject = null;
  let savedProject = null;
  let dialogAction = null;
  let dialogInitialState = "show"; // show, edit, new
  let showProjectDialog = false;
  let showRemoveDialog = false;
  let editProjectIndex = -1;

  let searchFilter = "";
  let code1Options = [];
  let selectedCode1 = [];
  let code2Options = [];
  let selectedCode2 = [];
  let clientOptions = [];
  let selectedClient = [];
  let statusOptions = [];
  let selectedStatus = [];
  let typeOptions = [];
  let selectedType = [];
  let locationOptions = [];
  let selectedLocation = [];
  let active = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Inactive",
      label: "Inactive",
    },
  ];
  let selectedActive = [];
  let internals = [
    {
      value: "Internal",
      label: "Internal",
    },
    {
      value: "External",
      label: "External",
    },
  ];
  let selectedInternals = [];

  let mode = dashboard ? "map" : "cards"; // map, cards, list

  let remainingCards = 0;
  let invisibleCards = 0;

  //let defaultStatusHistories = [];

  let myRole = getRole();
  let myId = getUserId();

  const PROJECTS_REMOTE_URL = "/projects";
  const GOOGLE_MAPS_CENTER = { latitude: 47, longitude: 0 };
  const GOOGLE_MAPS_ZOOM = 5;

  const PROJECTS_URL = "/projects";

  let locations = [];

  let loading = true;

  onMount(async () => {
    //await getRoles();

    //await getDefaultStatusHistories();
    prepareProjects();
    await getPlatformLinks();
  });

  let prepareProjects = async () => {
    if (idProject !== -1) {
      await getProjects();
      const pos = projects.findIndex((project) => {
        return project.id === idProject;
      });
      if (pos >= 0) {
        showProject(true, pos);
      }
      idProject = -1;
      //showProjectDialog = true;
    } else {
      await getProjects();
      //console.log(projects);
    }

    selectedActive = [active[0].value];

    loading = false;
    refreshProjects = false;
  };

  let getPlatformLinks = async () => {
    const response = await post(
      `select name, iconUrl
        from platforms`
    );
    platformLinks = response.map((item) => {
      return {
        name: item.name,
        icon: item.iconUrl,
      };
    });
    /* externalUrlOptions = platformLinks.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
    externalUrlOptionsSel = externalUrlOptions[0]; */
  };

  function generateExternalUrls(project) {
  return project.externalUrls.map(url => {
    const platformLink = platformLinks.find(pl => pl.name === url.type);
    return {
      ...url,
      icon: platformLink ? platformLink.icon : ''
    };
    });
  }


  let getProjects = async () => {
    projects = await getAllProjects(dashboard, myId);

    projects = projects.map(project => ({
    ...project,
    externalUrls: generateExternalUrls(project)
    }));

    if (myRole !== "Analytics") {
      projects = projects.filter((project) => {
        return project.lastName.trim() !== "Labit Group";
      });
    }

    updateCode1Filter();
    updateCode2Filter();
    updateClientFilter();
    updateStatusFilter();
    updateTypeFilter();
    updateLocationFilter();

    locations = projects.reduce((acc, curr, index) => {
      if (curr.latitude !== "" && curr.longitude !== "") {
        const location = {
          latitude: curr.latitude,
          longitude: curr.longitude,
          name: curr.name,
          onClick: () => {
            clearFilters();
            searchFilter = curr.name;
          },
        };
        acc.push(location);
      }
      return acc;
    }, []);

    remainingCards = projects.length % 6;
    invisibleCards = remainingCards > 0 ? 6 - remainingCards : 0;

    console.log(projects);
  };

  let updateCode1Filter = () => {
    code1Options = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = code1Options.findIndex((item) => {
          return item.value === project.timeLabitCode;
        });
        if (pos === -1) {
          code1Options.push({
            value: project.timeLabitCode,
            label: project.timeLabitCode,
          });
        }
        if (!selectedCode1) {
          selectedCode1 = code1Options[0];
        }
      }
    });
  };

  let updateCode2Filter = () => {
    code2Options = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = code2Options.findIndex((item) => {
          return item.value === project.folderLabitCode;
        });
        if (pos === -1) {
          code2Options.push({
            value: project.folderLabitCode,
            label: project.folderLabitCode,
          });
        }
        if (!selectedCode2) {
          selectedCode2 = code2Options[0];
        }
      }
    });
  };

  let updateClientFilter = () => {
    clientOptions = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = clientOptions.findIndex((item) => {
          return item.value === project.lastName.toUpperCase();
        });
        if (pos === -1) {
          clientOptions.push({
            value: project.lastName.toUpperCase(),
            label: project.lastName.toUpperCase(),
          });
        }
        if (!selectedClient) {
          selectedClient = clientOptions[0];
        }
      }
    });
  };

  let updateStatusFilter = () => {
    statusOptions = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        if (project.currentStatus !== "") {
          let pos = statusOptions.findIndex((item) => {
            return item.value === project.currentStatus;
          });
          if (pos === -1) {
            statusOptions.push({
              value: project.currentStatus,
              label: project.currentStatus,
            });
          }
        }
      }
    });

    if (!selectedStatus) {
      selectedStatus = statusOptions[0];
    }
  };

  let updateTypeFilter = () => {
    typeOptions = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = typeOptions.findIndex((item) => {
          console.log(project.programType);
          return item.value === project.programType;
        });
        if (pos === -1) {
          typeOptions.push({
            value: project.programType,
            label: project.programType,
          });
        }
        if (!selectedType) {
          selectedType = typeOptions[0];
        }
      }
    });
  };

  let updateLocationFilter = () => {
    locationOptions = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = locationOptions.findIndex((item) => {
          return item.value === project.country + " - " + project.city;
        });
        if (pos === -1) {
          locationOptions.push({
            value: project.country + " - " + project.city,
            label: project.country + " - " + project.city,
          });
        }
        if (!selectedLocation) {
          selectedLocation = locationOptions[0];
        }
      }
    });
  };

  function showProject(visible, index = -1) {
    if (visible) {
      const project = projects[index];
      console.log("PROJECT", project);
      savedProject = JSON.parse(JSON.stringify(projects[index]));
      selectedProject = {
        id: project.id,
        card: {
          name: project.name,
          code: project.folderLabitCode,
          client: project.lastName,
          address: project.address,
          city: project.city,
          country: project.country,
          mainPic: project.pic,
        },
        map: project.map,
        info: project.longDescription,
        nickName: project.nickName,
        timeLabitCode: project.timeLabitCode,
        company: project.company,
        area: project.area,
        programType: project.programType,
        active: project.active,
        status: true,
        /*serverLink: [project.serverUrl],
        trello: [project.trelloUrl],
        slack: [project.slackUrl],
        sharepoint: [project.sharepointUrl],
        bim360: [project.bim360Url],
        miro: [project.miroUrl],*/
        externalUrls: copyObject(project.externalUrls),
        aream2: project.aream2,
        noFloors: project.noFloors,
        allBuildingDemise: project.allBuildingDemise,
        newStairCase: project.newStairCase,
        occupancy: project.occupancy,
        raisedFloorCavityHeight: project.raisedFloorCavityHeight,
        existingMinimumHeight: project.existingMinimumHeight,
        singularities: project.singularities,
        sprinklers: project.sprinklers,
        PEN: project.PEN,
        documentosAdjuntos: [],
        mailsAdjuntos: [],
        imagenesAdjuntas: [],
        //statusHistory: [],
        statusAnalytics: -1,
        submissions: [],
        //team: JSON.parse(JSON.stringify(project.team)),
        //contact: JSON.parse(JSON.stringify(project.contact)),
        statusHistory: copyObject(project.statusHistory),
        picPath: project.picPath,
        picImage: "",
        latitude: project.latitude,
        longitude: project.longitude,
        outlookCalendarId: project.outlookCalendarId,
        outlookCalendarName: project.outlookCalendarName,
        relatedProjects: copyObject(project.relatedProjects),
        planPicUrl: project.planPicUrl,
        planPicPath: project.planPicPath,
        planPicImage: "",
        lastModificationDate: project.lastModificationDate,
        lastModificatedBy: project.lastModificatedBy,
        phases: copyObject(project.phases),
        scopes: copyObject(project.scopes),
        milestones: copyObject(project.milestones),
        currentPhase: project.currentPhase,
        currentSubphase: project.currentSubphase,
        currentStatus: project.currentStatus,
        people: copyObject(project.people),
        companies: copyObject(project.companies),
        licenses: copyObject(project.licenses),
        isDSC: project.isDSC,
        isInternal: project.isInternal,
      };

      //console.log(selectedProject);

      selectedProject.people = selectedProject.people;

      editProjectIndex = index;

      dialogAction = editProject;
      dialogInitialState = "show";
    }
    showProjectDialog = visible;
    //showMenu = !visible;
  }

  function showNewProject(visible) {
    selectedProject = {
      id: -1,
      card: {
        name: "",
        code: "",
        client: "",
        address: "",
        city: "",
        country: "",
        mainPic: "",
      },
      map: "",
      info: "",
      nickName: "",
      timeLabitCode: "",
      company: "",
      area: "",
      programType: "",
      active: true,
      serverLink: [""],
      trello: [""],
      slack: [""],
      sharepoint: [""],
      bim360: [""],
      miro: [""],
      aream2: "",
      noFloors: "",
      allBuildingDemise: "",
      newStairCase: "",
      occupancy: "",
      raisedFloorCavityHeight: "",
      existingMinimumHeight: "",
      singularities: "",
      sprinklers: "",
      PEN: "",
      documentosAdjuntos: [],
      mailsAdjuntos: [],
      imagenesAdjuntas: [],
      statusAnalytics: -1,
      submissions: [],
      team: [],
      contact: [],
      statusHistory: [],
      picPath: "",
      picImage: "",
      latitude: "",
      longitude: "",
      outlookCalendarId: "",
      outlookCalendarName: "",
      relatedProjects: [],
      planPicUrl: "",
      planPicPath: "",
      planPicImage: "",
      scopes: [],
      phases: [],
      companies: [],
      people: [],
    };
    dialogAction = createProject;
    dialogInitialState = "new";
    showProjectDialog = visible;

    //console.log(selectedProject);
  }

  function showRemove(visible) {
    showRemoveDialog = visible;
  }

  let createProject = async () => {
    let picUrl = "";
    let picPath = "";
    let planPicUrl = "";
    let planPicPath = "";

    if (selectedProject.serverLink[0] !== "") {
      selectedProject.serverLink[0] = selectedProject.serverLink[0].replace(
        /\\\\/g,
        "\\"
      );
      selectedProject.serverLink[0] = selectedProject.serverLink[0].replace(
        /\\/g,
        "\\\\"
      );
    }

    const response = await post(
      `insert into projects (
          Name,
          TimeLabitCode,
          FolderLabitCode,
          Nickname,
          Client,
          Company,
          Address,
          City,
          Country,
          Area,
          aream2,
          noFloors,
          allBuildingDemise,
          newStairCase,
          occupancy,
          existingMinimumHeight,
          raisedFloorCavityHeight,
          singularities,
          sprinklers,
          PEN,
          longDescription,
          ProgramType,
          active,
          picUrl,
          gMapUrl,
          picPath,
          latitude,
          longitude,
          outlookCalendarId,
          outlookCalendarName,
          planPicUrl,
          planPicPath
        )
        values (
         '${selectedProject.card.name}', 
          '${selectedProject.timeLabitCode}',
          '${selectedProject.card.code}',
          '${selectedProject.nickName}',
          '${selectedProject.card.client}', 
          '${selectedProject.company}', 
          '${selectedProject.card.address}', 
          '${selectedProject.card.city}', 
          '${selectedProject.card.country}',
          '${selectedProject.area}',
          '${selectedProject.aream2}',
          '${selectedProject.noFloors}',
          '${selectedProject.allBuildingDemise}',
          '${selectedProject.newStairCase}',
          '${selectedProject.occupancy}',
          '${selectedProject.existingMinimumHeight}',
          '${selectedProject.raisedFloorCavityHeight}',
          '${selectedProject.singularities}',
          '${selectedProject.sprinklers}',
          '${selectedProject.PEN}',
          '${selectedProject.info}',
          '${selectedProject.programType}',
          ${selectedProject.active ? "1" : "0"},
          '',
          '${selectedProject.map}',
          '',
          '${selectedProject.latitude}',
          '${selectedProject.longitude}',
          '',
          '',
          '',
          ''
        )`
    );

    const projectId = response[0];
    selectedProject.id = projectId;

    // Adding

    for (let i = 0; i < selectedProject.team.length; i++) {
      let t = selectedProject.team[i];
      const response = await post(
        `insert into teams (project_id, contact_id, role_id, startDate, endDate)
            values (${projectId}, ${t.id}, ${t.companyRoleId}, '${moment(
          t.from,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD")}', '${moment(t.to, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )}')`
      );
      t.teamId = response;
    }

    for (let i = 0; i < selectedProject.contact.length; i++) {
      const t = selectedProject.contact[i];
      const response = await post(
        `insert into project_contacts (project_id, contact_id, role_id, startDate, endDate)
            values (${projectId}, ${t.id}, ${t.companyRoleId}, '${moment(
          t.from,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD")}', '${moment(t.to, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )}')`
      );
    }

    for (let i = 0; i < selectedProject.statusHistory.length; i++) {
      const status = selectedProject.statusHistory[i];
      await post(
        `insert into project_status (project_id, status_id, Start, End, Active, Stage)
            values (${projectId}, ${status.statusId}, '${moment(
          status.from,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD")}', '${moment(status.to, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )}', ${status.active ? "1" : "0"}, '${status.other}')`
      );
    }

    // Image

    if (selectedProject.picImage) {
      const projectPicName =
        createProjectPicName(selectedProject) +
        "." +
        selectedProject.picImage.extension;
      picPath = PROJECTS_REMOTE_URL + "/" + projectPicName;
      picUrl = LABIT_PUBLIC_FOLDER + picPath;

      await rpost("UploadPublicFile", {
        path: PROJECTS_REMOTE_URL,
        fileName: projectPicName,
        fileData: selectedProject.picImage.data,
      });

      await post(
        `update projects
          set 
          picUrl='${picUrl}',
          picPath='${picPath}'
          where project_id=${selectedProject.id}`
      );
    }

    // Plan Image

    if (selectedProject.planPicImage) {
      const projectPlanPicName =
        createProjectPicName(selectedProject) +
        "-plan." +
        selectedProject.planPicImage.extension;
      planPicPath = PROJECTS_REMOTE_URL + "/" + projectPlanPicName;
      planPicUrl = LABIT_PUBLIC_FOLDER + planPicPath;

      await rpost("UploadPublicFile", {
        path: PROJECTS_REMOTE_URL,
        fileName: projectPlanPicName,
        fileData: selectedProject.planPicImage.data,
      });

      await post(
        `update projects
          set 
          planPicUrl='${planPicUrl}',
          planPicPath='${planPicPath}'
          where project_id=${selectedProject.id}`
      );
    }

    selectedProject.card.mainPic = picUrl + "?" + randomString(10);
    selectedProject.picPath = picPath;
    selectedProject.planPicUrl = planPicUrl + "?" + randomString(10);
    selectedProject.planPicPath = planPicPath;
    selectedProject = selectedProject;

    // Related Projects

    for (let i = 0; i < selectedProject.relatedProjects.length; i++) {
      await post(
        `insert into relatedProjects (project1, project2)
          values(${projectId}, ${selectedProject.relatedProjects[i].id})`
      );
    }

    // External Urls

    for (let i = 0; i < selectedProject.externalUrls.length; i++) {
      const url = selectedProject.externalUrls[i];
      await post(
        `insert into externalUrls (url, type, project)
          values ('${url.url}', '${url.type}', ${selectedProject.id})`
      );
    }

    // Outlook Calendar

    if (selectedProject.outlookCalendarId !== "nocreate") {
      if (selectedProject.outlookCalendarId === "create") {
        await outlookCreateCalendar(
          selectedProject.card.name,
          (id) => {
            selectedProject.outlookCalendarId = id;
            selectedProject.outlookCalendarName = selectedProject.card.name;
            selectedProject = selectedProject;
          },
          () => {}
        );
      }
      await post(
        `update projects
          set 
          outlookCalendarId='${selectedProject.outlookCalendarId}',
          outlookCalendarName='${selectedProject.outlookCalendarName}'
          where project_id=${selectedProject.id}`
      );
    }
    selectedProject = selectedProject;

    await getProjects();
    showProject(false);
  };

  let editProject = async () => {
    let picUrl = "";
    let picPath = "";
    let planPicUrl = "";
    let planPicPath = "";

    const now = moment().format("YYYY-MM-DD HH:mm:ss");

    await post(
      `update projects set
        Name='${selectedProject.card.name}', 
        TimeLabitCode='${selectedProject.timeLabitCode}',
        FolderLabitCode='${selectedProject.card.code}',
        Nickname='${selectedProject.nickName}',
        Client='${selectedProject.card.client}', 
        Company='${selectedProject.company}', 
        Address='${selectedProject.card.address}', 
        City='${selectedProject.card.city}', 
        Country='${selectedProject.card.country}',
        Area='${selectedProject.area}',
        aream2='${selectedProject.aream2}',
        noFloors='${selectedProject.noFloors}',
        allBuildingDemise='${selectedProject.allBuildingDemise}',
        newStairCase='${selectedProject.newStairCase}',
        occupancy='${selectedProject.occupancy}',
        existingMinimumHeight='${selectedProject.existingMinimumHeight}',
        raisedFloorCavityHeight='${selectedProject.raisedFloorCavityHeight}',
        singularities='${selectedProject.singularities}',
        sprinklers='${selectedProject.sprinklers}',
        PEN='${selectedProject.PEN}',
        longDescription='${selectedProject.info}',
        ProgramType='${selectedProject.programType}',
        active=${selectedProject.active ? "1" : "0"},
        gMapUrl='${selectedProject.map}',
        latitude='${selectedProject.latitude}',
        longitude='${selectedProject.longitude}',
        isInternal=${selectedProject.isInternal ? "1" : "0"},
        lastModificationDate='${now}',
        lastModificatedBy=${myId}
        where project_id=${selectedProject.id}`
    );

    // Name
    const tempOldProject = {
      id: savedProject.id,
      card: {
        name: savedProject.name,
      },
    };
    if (selectedProject.card.name !== tempOldProject.card.name) {
      const oldPath = PROJECTS_URL + "/" + createProjectPicName(tempOldProject);
      const newPath =
        PROJECTS_URL + "/" + createProjectPicName(selectedProject);
      await rpost("RenameFolder", {
        oldPath,
        newPath,
      });
    }

    // Image

    if (selectedProject.picImage) {
      const projectPicName =
        createProjectPicName(selectedProject) +
        "." +
        selectedProject.picImage.extension;
      picPath = PROJECTS_REMOTE_URL + "/" + projectPicName;
      picUrl = LABIT_PUBLIC_FOLDER + picPath;

      await rpost("UploadPublicFile", {
        path: PROJECTS_REMOTE_URL,
        fileName: projectPicName,
        fileData: selectedProject.picImage.data,
      });

      await post(
        `update projects
          set 
          picUrl='${picUrl}',
          picPath='${picPath}'
          where project_id=${selectedProject.id}`
      );
    }

    // Plan Image

    if (selectedProject.planPicImage) {
      const projectPlanPicName =
        createProjectPicName(selectedProject) +
        "-plan." +
        selectedProject.planPicImage.extension;
      planPicPath = PROJECTS_REMOTE_URL + "/" + projectPlanPicName;
      planPicUrl = LABIT_PUBLIC_FOLDER + planPicPath;

      await rpost("UploadPublicFile", {
        path: PROJECTS_REMOTE_URL,
        fileName: projectPlanPicName,
        fileData: selectedProject.planPicImage.data,
      });

      await post(
        `update projects
          set 
          planPicUrl='${planPicUrl}',
          planPicPath='${planPicPath}'
          where project_id=${selectedProject.id}`
      );
    }

    // External Urls

    for (let i = 0; i < selectedProject.externalUrls.length; i++) {
      let url = selectedProject.externalUrls[i];
      if (url.id < 0) {
        if (url.type === "Server") {
          url.url = url.url.replace(/\\/g, "\\\\");
        }
        await post(
          `insert into externalUrls (url, type, project)
          values ('${url.url}', '${url.type}', ${selectedProject.id})`
        );
      }
    }

    // RelatedProjects

    for (let i = 0; i < selectedProject.relatedProjects.length; i++) {
      const rProject = selectedProject.relatedProjects[i];
      const pos = savedProject.relatedProjects.findIndex((item) => {
        return item.id === rProject.id;
      });
      if (pos === -1) {
        await post(
          `insert into relatedProjects (project1, project2)
          values(${selectedProject.id}, ${rProject.id})`
        );
      }
    }

    for (let i = 0; i < savedProject.relatedProjects.length; i++) {
      const rProject = savedProject.relatedProjects[i];
      const pos = selectedProject.relatedProjects.findIndex((item) => {
        return item.id === rProject.id;
      });
      if (pos === -1) {
        await post(
          `delete from relatedProjects
            where (project1=${selectedProject.id} and project2=${rProject.id}) or (project1=${rProject.id} and project2=${selectedProject.id})`
        );
      }
    }

    selectedProject.card.mainPic = picUrl + "?" + randomString(10);
    selectedProject.picPath = picPath;
    selectedProject.planPicUrl = planPicUrl + "?" + randomString(10);
    selectedProject.planPicPath = planPicPath;
    selectedProject = selectedProject;

    // Phases

    for (let i = 0; i < selectedProject.phases.length; i++) {
      const phase = selectedProject.phases[i];
      if (phase.modified) {
        const realStart = phase.realStart ? `'${phase.realStart}'` : "null";
        const realEnd = phase.realEnd ? `'${phase.realEnd}'` : "null";
        const realDescription = phase.realDescription
          ? `'${phase.realDescription}'`
          : "null";
        await post(
          `update phase
            set
            realStart=${realStart},
            realEnd=${realEnd},
            realDescription=${realDescription}
            where id=${phase.id}`
        );
      }
      for (let j = 0; j < phase.subphases.length; j++) {
        const subphase = phase.subphases[j];
        if (subphase.modified) {
          const realStart = subphase.realStart
            ? `'${subphase.realStart}'`
            : "null";
          const realEnd = subphase.realEnd ? `'${subphase.realEnd}'` : "null";
          const realDescription = subphase.realDescription
            ? `'${subphase.realDescription}'`
            : "null";
          await post(
            `update phase
            set
            realStart=${realStart},
            realEnd=${realEnd},
            realDescription=${realDescription}
            where id=${subphase.id}`
          );
        }
      }
    }

    // Status

    for (let i = 0; i < selectedProject.statusHistory.length; i++) {
      const status = selectedProject.statusHistory[i];
      if (status.id === -1) {
        const end = status.end ? `'${status.end}'` : "null";
        const id = await post(
          `insert into statusHistory (start, end, status, project)
            values ('${status.start}', ${end}, ${status.statusId}, ${selectedProject.id})`
        );
        selectedProject.statusHistory[i].id = id[0];
      } else {
        if (status.modified) {
          const end = status.end ? `'${status.end}'` : "null";
          await post(
            `update statusHistory set
              start='${status.start}',
              end=${end},
              status=${status.statusId}
              where id=${status.id}`
          );
        }
      }
    }

    for (let i = 0; i < savedProject.statusHistory.length; i++) {
      const status = savedProject.statusHistory[i];
      const s = selectedProject.statusHistory.find((s) => {
        return s.id === status.id;
      });
      if (!s) {
        await post(
          `delete from statusHistory
            where id=${status.id}`
        );
      }
    }

    // Companies

    for (let i = 0; i < selectedProject.companies.length; i++) {
      const company = selectedProject.companies[i];
      if (company.id < 0) {
        await post(
          `insert into projectCompany (company, project, role)
            values (${company.companyId}, ${selectedProject.id}, '${company.role}')`
        );
      }
    }

    for (let i = 0; i < savedProject.companies.length; i++) {
      const company = savedProject.companies[i];
      const pos = selectedProject.companies.findIndex((item) => {
        return item.id === company.id;
      });
      if (pos === -1) {
        await post(
          `delete from projectCompany
            where id=${company.id}`
        );
      }
    }

    // People

    for (let i = 0; i < selectedProject.people.length; i++) {
      const people = selectedProject.people[i];
      if (people.id < 0) {
        // New people
        const unactiveDate = people.unactiveDate
          ? `'${people.unactiveDate}'`
          : "null";
        await post(
          `insert into projectPeople (people, project, role, active, unactiveDate)
            values (${people.peopleId}, ${selectedProject.id}, '${
            people.role
          }', ${people.active ? "1" : "0"}, ${unactiveDate})`
        );
      } else {
        // Activated / Unactivated people
        const p = savedProject.people.find((item) => {
          return (
            item.peopleId === people.peopleId && item.active !== people.active
          );
        });
        if (p) {
          await post(
            `update projectPeople set
              active=${people.active ? "1" : "0"}
              where id=${people.id}`
          );
        }
      }
    }

    for (let i = 0; i < savedProject.people.length; i++) {
      const people = savedProject.people[i];
      const p = selectedProject.people.find((item) => {
        return people.id === item.id;
      });
      if (!p) {
        // Delete people
        await post(
          `delete from projectPeople
            where id=${people.id}`
        );
      }
    }

    savedProject = JSON.parse(JSON.stringify(selectedProject));

    getProjects();
  };

  let removeProject = async () => {
    let response = await post(
      `delete from projects
          where project_id=${selectedProject.id}`
    );

    await getProjects();
    showRemoveDialog = false;
    showProject(false);
  };

  let changeMode = (newMode) => {
    mode = newMode;
  };

  let applyFilter = () => {
    projects.forEach((project) => {
      project.visible = true;
    });

    if (searchFilter !== "") {
      const SearchFilter = searchFilter.toUpperCase();
      projects.forEach((item) => {
        if (item.visible) {
          const searchTerms = prepareSearch(searchFilter);
          if (!item.searchTerms.includes(searchTerms)) {
            item.visible = false;
          }
        }
      });
    } //else {
    // Time Labit Code

    if (selectedCode1 && selectedCode1.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedCode1.findIndex((c) => {
            return c === item.timeLabitCode;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Folder Labit Code

    if (selectedCode2 && selectedCode2.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedCode2.findIndex((c) => {
            return c === item.folderLabitCode;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Client

    if (selectedClient && selectedClient.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedClient.findIndex((c) => {
            return c.toUpperCase() === item.lastName.toUpperCase();
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Status

    if (selectedStatus && selectedStatus.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedStatus.findIndex((c) => {
            return c === item.currentStatus;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Type

    if (selectedType && selectedType.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedType.findIndex((c) => {
            return c === item.programType;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Location

    if (selectedLocation && selectedLocation.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedLocation.findIndex((c) => {
            return c === item.country + " - " + item.city;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Active

    if (selectedActive && selectedActive.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedActive.findIndex((c) => {
            return (
              (item.active && c === "Active") ||
              (!item.active && c === "Inactive")
            );
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Internals

    if (selectedInternals && selectedInternals.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedInternals.findIndex((c) => {
            return (
              (item.isInternal && c === "Internal") ||
              (!item.isInternal && c === "External")
            );
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    //}

    const num = projects.reduce((acc, curr) => {
      if (curr.visible) {
        acc++;
      }
      return acc;
    }, 0);

    remainingCards = num % 6;
    invisibleCards = remainingCards > 0 ? 6 - remainingCards : 0;

    projects = projects;
  };

  let clearFilters = () => {
    searchFilter = "";
    selectedCode1 = [];
    selectedCode2 = [];
    selectedClient = [];
    selectedStatus = [];
    selectedType = [];
    selectedLocation = [];
    selectedActive = [];
    selectedInternals = [];
  };

  let nextProject = () => {
    const pos = projects.findIndex((item) => {
      return item.id === selectedProject.id;
    });
    let index = pos + 1;
    if (index >= projects.length) {
      index = 0;
    }
    showProject(true, index);
  };

  let previousProject = () => {
    const pos = projects.findIndex((item) => {
      return item.id === selectedProject.id;
    });
    let index = pos - 1;
    if (index < 0) {
      index = projects.length - 1;
    }
    showProject(true, index);
  };

  $: if (
    selectedCode1 ||
    selectedCode2 ||
    selectedStatus ||
    selectedClient ||
    selectedType ||
    selectedLocation ||
    selectedActive ||
    selectedInternals ||
    searchFilter
  ) {
    applyFilter();
  }

  $: if (idProject !== -1) {
    prepareProjects();
  }

  $: if (refreshProjects === true) {
    prepareProjects();
  }
</script>

{#if showProjectDialog}
  <DialogProject
    bind:project={selectedProject}
    action={dialogInitialState}
    index={editProjectIndex}
    onClose={(show, index) => showProject(show, index)}
    onSave={dialogAction}
    onRemove={() => {
      showRemove(true);
      showProject(false);
    }}
    onNextProject={() => nextProject()}
    onPreviousProject={() => previousProject()}
    url={dashboard ? "/project-dashboard" : "/projects"}
    bind:refreshCompanyList
    bind:selectedProjectPDashboard
  />
{:else if showRemoveDialog}
  <DialogRemove
    element={selectedProject.card.name}
    onClose={() => {
      showRemove(false);
      showProject(true);
    }}
    onRemove={() => removeProject()}
  />
{:else}
  <div class="content">
    <!-- Menu Options -->

    <div class="optionsMenu">
      <div class="optionsRow">
        <div class="optionsColumn1">
          <div class="optionsSearch">
            <span>Search</span>
            <TextEdit
              bind:value={searchFilter}
              placeholder="type here..."
              maxlength="150"
              img="/images/search.svg"
            />
          </div>
          {#if myRole === "Admin"}
            <SubmenuButton
              text="New"
              img="/images/factory.svg"
              onClick={() => {
                //location.href = "/project-analytics/new";
                openURL("/project-analytics/new", true);
              }}
            />
          {/if}
        </div>
        <div class="optionsColumn2">
          <div class="optionsIcons">
            <img
              src="/images/world.svg"
              alt="world"
              on:click={() => changeMode("map")}
            />
            <img
              src="/images/list_square.svg"
              alt="cards"
              on:click={() => changeMode("cards")}
            />
            <img
              src="/images/list_column.svg"
              alt="list"
              on:click={() => changeMode("list")}
            />
          </div>
        </div>
      </div>
      <div class="optionsRow lastRow">
        <div class="optionsColumn1">
          <SubmenuMultipleSelect
            text="Code"
            options={code1Options}
            bind:selected={selectedCode1}
            width="115px"
          />
          <SubmenuMultipleSelect
            text=""
            options={code2Options}
            bind:selected={selectedCode2}
            width="145px"
          />
          <SubmenuMultipleSelect
            text="Client"
            options={clientOptions}
            bind:selected={selectedClient}
            width="145px"
          />
          <SubmenuMultipleSelect
            text="Status"
            options={statusOptions}
            bind:selected={selectedStatus}
            width="145px"
          />
          <SubmenuMultipleSelect
            text="Typology"
            options={typeOptions}
            bind:selected={selectedType}
            width="145px"
          />
          <SubmenuMultipleSelect
            text="Location"
            options={locationOptions}
            bind:selected={selectedLocation}
            width="145px"
          />
          <SubmenuMultipleSelect
            text="Internal"
            options={internals}
            bind:selected={selectedInternals}
            width="115px"
          />
          <SubmenuMultipleSelect
            text=""
            options={active}
            bind:selected={selectedActive}
            width="115px"
          />
        </div>
        <div class="optionsColumn2">
          <SubmenuButton
            text="Clear all"
            img="/images/x.svg"
            onClick={() => clearFilters()}
          />
        </div>
      </div>
    </div>

    <!-- Body -->

    {#if loading}
      <Loading size="50px" marginTop="20px" />
    {/if}
    <div class="projectSectionCards {mode === 'map' ? '' : 'hiddenElement'}">
      <!--{#if dashboard}-->
      <div class="projectList customScrollbars">
        {#each projects as project, index}
          {#if project.visible}
            <div class="projectSectionCardMap">
              <ProjectCard
                pic={project.pic}
                name={project.name}
                lastName={project.lastName}
                company={project.company}
                address={project.address}
                city={project.city}
                country={project.country}
                code={project.folderLabitCode}
                active={project.active}
                isInternal={project.isInternal}
                onClick={() => {
                  if (dashboard && project.active) {
                    selectedProjectPDashboard = project.id;
                    openURL("/dashboard-project", true);
                  } else {
                    showProject(true, index);
                  }
                }}
              />
            </div>
          {/if}
        {/each}
      </div>
      <!--{/if}-->
      <div class="googlemaps-dashboard">
        <GoogleMaps
          id="googlemaps-dashboard"
          mapCenter={GOOGLE_MAPS_CENTER}
          {locations}
          zoom={GOOGLE_MAPS_ZOOM}
        />
      </div>
    </div>
    <div class="projectSectionCards {mode === 'cards' ? '' : 'hiddenElement'}">
      {#each projects as project, index}
        {#if project.visible}
          <div class="projectSectionCard">
            <ProjectCard
              pic={project.pic}
              name={project.name}
              lastName={project.lastName}
              company={project.company}
              address={project.address}
              city={project.city}
              country={project.country}
              code={project.folderLabitCode}
              active={project.active}
              isInternal={project.isInternal}
              onClick={() => showProject(true, index)}
            />
          </div>
        {/if}
      {/each}
      {#each Array(invisibleCards) as _, i}
        <div class="projectSectionCard" />
      {/each}
    </div>
    <div class="projectSectionList  {mode === 'list' ? '' : 'hiddenElement'}">
      {#each projects as project, index}
        {#if project.visible}
          <ProjectList
            pic={project.pic}
            name={project.name}
            company={project.company}
            active={project.active}
            timeLabitCode={project.timeLabitCode}
            folderLabitCode={project.folderLabitCode}
            externalUrls={project.externalUrls}
            city={project.city}
            country={project.country}
            area={project.aream2}
            status={project.status}
            onClick={() => showProject(true, index)}
          />
        {/if}
      {/each}
    </div>
  </div>
{/if}

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin-top: 20px;
    height: 55px;
    color: var(--labit-card-gray);
    flex-direction: column;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    padding-right: 13px;
    justify-content: space-between;
  }

  div.optionsColumn2 {
    display: flex;
  }

  div.optionsIcons {
    display: flex;
    width: 121px;
    height: 21px;
    justify-content: flex-end;
  }

  div.optionsIcons img {
    width: 21px;
    height: 21px;
    margin-left: 17px;
    cursor: pointer;
  }

  div.optionsSearch {
    display: flex;
    flex-grow: 1;
    height: 21px;
    align-items: center;
    padding-right: 13px;
  }

  div.optionsSearch span {
    margin-right: 13px;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  div.projectSectionCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: space-between;
  }

  div.projectSectionCardMap {
    height: 234px;
    width: 260px;
    margin-bottom: 19px;
  }

  div.projectSectionCard {
    height: 260px;
    width: 260px;
    margin-bottom: 19px;
  }

  div.googlemaps-dashboard {
    width: 1354px !important;
    height: 743px;
    border-width: 0px;
    border-radius: 19px;
  }

  div.projectList {
    height: 743px;
    overflow-y: scroll;
  }

  div.projectSectionList {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  /* Animations */

  div.optionsIcons img {
    transition: opacity 0.5s linear 0s;
  }

  div.optionsIcons img:hover {
    opacity: 0.5;
  }
</style>
