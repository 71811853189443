import { mainUrl } from "./stores.js";

export let openURL = (url, skylabMain = false) => {
  if (skylabMain) {
    mainUrl.set({
      url,
    });
  } else {
    location.href = url;
  }
};
