<script>
  export let epigraph;
  export let selFilterEpigraph;
  export let selFilterAttribute;

  let visible = true;
  let numCategories = -1;

  $: {
    numCategories = -1;

    let visibleE = true;
    let visibleA = true;

    if (selFilterEpigraph.length > 0) {
      const pos = selFilterEpigraph.findIndex(sE => {
        return sE === epigraph.id;
      });
      visibleE = pos >= 0;
    }

    if (selFilterAttribute.length > 0) {
      numCategories = epigraph.categories.reduce((acc, curr) => {
        const pos = selFilterAttribute.findIndex(sA => {
          return sA === curr.name;
        });
        if (pos >= 0) {
          acc++;
        }
        return acc;
      }, 0);
      visibleA = numCategories > 0;
    }

    visible = visibleE && visibleA;

    if (numCategories === -1 && epigraph) {
      numCategories = epigraph.categories.length;
    }
  }
</script>

<style>
  td {
    background-color: var(--labit-rdstable-header-text);
    vertical-align: middle;
  }

  div.RDSEpigraph {
    display: flex;
    flex-direction: column;
  }

  div.header {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 13px;
    /*padding: 15px;*/
    font-weight: bold;
    justify-content: center;
    height: 86px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
  }

  div.header img {
    width: 37px;
    margin-bottom: 6px;
  }

  span {
    text-align: center;
  }
</style>

{#if visible}
  <td class="fixedRow0" colspan={numCategories}>
    <div class="RDSEpigraph">
      <div class="header">
        <img src={epigraph.icon} alt="Epigraph Icon" />
        <span>{epigraph.id}</span>
      </div>
    </div>
  </td>
{/if}
