<script>
  import TimelineCalendar from "./TimelineCalendar.svelte";
  import Multiselector from "../timeReports/dropdownMenuMultiselect/DropdownContainer.svelte";
  import RangeDatePicker from "../../me/factorial/RangeDatePicker.svelte";
  import Loading from "../../../components/Loading-new.svelte";

  import { objectIsEmpty } from "./utils";
  import { post } from "../../../js/labit-connection";
  import dayjs from "dayjs";

  export let role = "User";

  let start = dayjs().startOf("year");
  let end = dayjs().endOf("year");
  let options = {};
  let absenceTypesObj = {};
  let dropdownOptions = [];
  let data = [];
  let filteredHolidays;
  let users;

  (async () => {
    const usersPromise = post(
      "SELECT contact_id AS id, name as value, pictureUrl AS picture, city as location FROM people WHERE company_id = 2 AND active = 1 order by name;"
    );
    const absencesPromise = post(
      "SELECT id, description, start, end, accepted_by_ceo, half, user_id, absence_type_id FROM absences;"
    );
    const generalHolidaysPromise = post(
      "SELECT id, name as description, startDate as start, endDate as end, location FROM general_holidays;"
    );
    const absenceTypesPromise = post(
      "SELECT id, name, color, color_light, approvable from absence_type;"
    );

    let absences;
    let absenceTypes;
    let generalHolidays;
    [users, generalHolidays, absences, absenceTypes] = await Promise.all([
      usersPromise,
      generalHolidaysPromise,
      absencesPromise,
      absenceTypesPromise,
    ]);

    absenceTypesObj = absenceTypes.reduce((acc, absence) => {
      if (!acc.hasOwnProperty(absence.id)) {
        acc[absence.id] = {
          name: absence.name,
          color: absence.color,
          color_light: absence.color_light,
          approvable: absence.approvable,
        };
        return acc;
      }
    }, {});

    data = absences.reduce((acc, absence) => {
      const absTypeId = absence.absence_type_id;
      let a;
        a = {
          id: absence.id,
          description: absence.description,
          start: absence.start,
          end: absence.end,
          accepted_by_ceo: absence.accepted_by_ceo,
          half: absence.half,
          user_id: absence.user_id,
          absence_type_id: absTypeId,
          absenceType: absenceTypesObj[absTypeId].name,
          color: absenceTypesObj[absTypeId].color,
          color_light: absenceTypesObj[absTypeId].color_light,
          approvable: absenceTypesObj[absTypeId].approvable,
        };
      acc.push(a);
      return acc;
      }, []);

    filteredHolidays = generalHolidays.reduce((acc, day) => {
      const location = day.location;
      if (!acc.hasOwnProperty(location)) acc[location] = {};
      acc[location][day.start] = day.description;
      return acc;
    }, {});

    dropdownOptions = users.map((user) => {
      return {
        id: user.id,
        value: user.value,
        selected: true,
        picture: user.picture,
        location: user.location,
      };
    });

    options = {
      start: start,
      end: end,
      rows: users,
      data: data,
      generalHolidays: filteredHolidays,
    };
  })();

  const filterData = () => {
    const selectedUsers = dropdownOptions.filter((user) => user.selected);
    const filteredUsers = users.filter((user) => {
      return selectedUsers.some((selectedUser) => selectedUser.id === user.id);
    });

    options = {
      start: start,
      end: end,
      rows: filteredUsers,
      data: data,
      generalHolidays: filteredHolidays,
    };
    console.log(filteredHolidays);
  };

  const setStartAndEnd = (ev) => {
    console.log(ev);
    start = dayjs(parseInt(ev.detail.start));
    end = dayjs(parseInt(ev.detail.end));
  };

  const smoothScroll = (evt) => {
    const day = dayjs(parseInt(evt.detail.start));
    const dayStr = day.format("YYYY-MM-DD");
    const column = document.querySelector(`[day='${dayStr}']`);
    column.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };
</script>

<main class="main">
  <div class="options">
    <Multiselector bind:options={dropdownOptions} defaultText="Users" />
    <div>
      <span>Select a range</span><RangeDatePicker
        disableBeforeToday={false}
        on:close={setStartAndEnd}
      />
    </div>
    <div>
      <span>Go to day</span><RangeDatePicker
        half={true}
        disableBeforeToday={false}
        on:close={smoothScroll}
      />
    </div>
    <img
      class="search"
      src="/images/loupe.svg"
      alt=""
      on:click={() => filterData()}
    />
  </div>

  {#if !objectIsEmpty(options)}
    {#key options}
      <div class="calendar-container">
        <TimelineCalendar {options} {role} on:userSelected />
      </div>
    {/key}
  {:else}
    <div class="loading-container">
      <Loading />
    </div>
  {/if}
</main>

<style>
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search {
    width: 32px;
    cursor: pointer;
    padding: 4px;
  }

  .search:hover {
    background-color: #f0f4f8;
    border-radius: 8px;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 16px;
    background-color: white;
    padding: 16px;
    border-radius: 0 0 20px 20px;
  }

  .calendar-container {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  .options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 4;
  }

  .calendar-container::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .calendar-container::-webkit-scrollbar-track {
    background: #efefef;
  }

  .calendar-container::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
  }
</style>
