<script>
  export let options;
  export let placeholder;
  export let selectedValue;
  export let searchable = true;
  export let onChange = null;
  export let backgroundColor = "transparent";
  export let disabled = false;
  export let error = false;

  import { onMount } from "svelte";

  import { randomString } from "../js/randomString";
  import { removeAccents } from "../js/removeAccents";
  import { clickOutside } from "../js/clickOutside";

  let id = randomString(10);

  let show = false;
  let text = "";
  let searchText = "";
  let selPrev = null;

  let input = null;
  let list = null;

  onMount(() => {
    input = window.$("#" + id + " div.combo");
    list = window.$("#" + id + " div.options");
    list.detach().appendTo("body");
  });

  let clickCombobox = () => {
    const pos = input.offset();
    const top = pos.top + input.height() + 5;
    const left = pos.left; /* - 5*/
    list.css({ top: top + "px", left: left + "px" });
    if (!disabled) {
      show = !show;
      checkSearch();
    }
  };

  let selectItem = (index) => {
    selectedValue = options[index];
    show = false;
  };

  let onClickOutside = () => {
    if (show) {
      selectedValue = options.find((item) => {
        return item.value === selPrev;
      });
      if (!selectedValue) {
        selectedValue = options[0];
      }
      show = false;
    }
  };

  let pressKey = () => {
    searchText = text;
    show = true;
  };

  let checkSearch = () => {
    if (!show) {
      searchText = "";
      const pos = options.findIndex((option) => {
        return option.label === text;
      });
      if (pos === -1) {
        text = "";
      }
    }
  };

  let applySelected = () => {
    if (selectedValue) {
      text = selectedValue.label;
      const changed = selectedValue.value !== selPrev;
      selPrev = selectedValue.value;
      searchText = "";
      show = false;
      if (onChange && changed) {
        onChange();
      }
    }
  };

  let checkSelected = () => {
    if (selectedValue) {
      //console.log(selectedValue);
      applySelected();
    } else {
      if (options && options.length === 0) {
        searchText = "";
        text = "";
      }
    }
  };

  $: {
    options = options;
    selectedValue = selectedValue;
    checkSelected();
  }
</script>

<div
  class="comboboxContainer"
  {id}
  use:clickOutside
  on:click_outside={onClickOutside}
>
  <div class="combo" on:click={() => clickCombobox()}>
    {#if selectedValue && selectedValue.value.icon && selectedValue.value.icon !== ""}
      <img
        class="selectedIcon"
        src={selectedValue.value.icon}
        alt="Selected Icon"
      />
    {/if}
    <input
      type="text"
      bind:value={text}
      style="background-color: {backgroundColor}; border-color: {error
        ? 'red'
        : 'var(--labit-card-lightgray)'};"
      readonly={!searchable}
      {placeholder}
      on:keyup={() => {
        if (searchable) {
          pressKey();
        }
      }}
    />
    <img class="arrow" src="/images/down_arrow.svg" alt="icon" />
  </div>
  <div class="options customScrollbars {show ? '' : 'hiddenElement'}">
    {#each options as option, index}
      {#if option && option.value !== ""}
        <div
          class="comboItem {selectedValue &&
          option.value === selectedValue.value
            ? 'selected'
            : ''} {searchText === '' ||
          (searchText !== '' &&
            removeAccents(option.label.trim().toUpperCase()).includes(
              removeAccents(searchText.trim().toUpperCase())
            ))
            ? ''
            : 'hiddenElement'}"
          on:click={() => selectItem(index)}
        >
          <div class="imgOption">
            {#if option.value.icon && option.value.icon !== ""}
              <img src={option.value.icon} alt="Option Icon" />
            {/if}
          </div>
          <div class="textOption">
            <span>{option.label}</span>
          </div>
        </div>
      {/if}
    {/each}
  </div>
</div>

<style>
  div.comboboxContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    color: var(--labit-card-gray);
    font-size: 13px;
  }

  div.combo {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  img.arrow {
    position: absolute;
    height: 15px;
    right: 10px;
  }

  input {
    border-radius: 20px;
    border-width: 1px;
    border-style: solid;
    height: 100%;
    width: 100%;
    padding: 0px 18px 0px 34px;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: var(--labit-card-gray);
  }

  div.options {
    background-color: white;
    border-radius: 10px;
    border-color: var(--labit-card-lightgray);
    border-width: 1px;
    border-style: solid;
    position: absolute;
    padding: 5px 0px 5px 0px;
    min-width: 250px;
    max-width: 288px;
    min-height: 30px;
    z-index: 10005;
    color: var(--labit-card-gray);
    font-size: 15px;
    font-weight: bold;
    max-height: 400px;
    overflow-y: scroll;
  }

  div.comboItem {
    margin-top: 0px;
    cursor: pointer;
    display: flex;
    height: 35px;
  }

  div.comboItem:hover {
    background-color: var(--labit-gray);
  }

  div.comboItem img {
    width: 18px;
  }

  div.selected {
    background-color: var(--labit-gray);
  }

  div.imgOption {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.textOption {
    width: 85%;
    display: flex;
    align-items: center;
  }

  img.selectedIcon {
    width: 18px;
    position: absolute;
    left: 10px;
  }
</style>
