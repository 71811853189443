<script>
  export let color;
  export let text;
  export let active;
  export let onClickVisibility;
  export let onClickMenu;
  export let menu = "";
</script>

<div class="container {active ? '' : 'inactive'}">
  <div class="divPointer">
    <div class="circle" style="background-color: {color}" />
  </div>
  <div class="divText">
    <span>{text}</span>
  </div>
  <div class="divVisibility" on:click={onClickVisibility}>
    <img
      src={active ? "/images/eye.svg" : "/images/eye-closed.svg"}
      alt="Visible"
    />
  </div>
  {#if menu !== ""}
    <div class="divOpenClose" on:click={onClickMenu}>
      <img
        src={menu ? "/images/up_arrow.svg" : "/images/down_arrow.svg"}
        alt="Visible"
      />
    </div>
  {/if}
</div>

<style>
  div.container {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    border-radius: 19px;
  }

  div.divPointer {
    height: 100%;
    margin-bottom: 5px;
    margin-left: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.divText {
    height: 100%;
    width: 80%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  div.circle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

  div.inactive {
    opacity: 0.5;
  }

  span {
    font-size: 15px;
    font-weight: bold;
    color: var(--labit-card-labit-background);
    margin-left: 8px;
  }

  .divVisibility,
  .divOpenClose {
    cursor: pointer;
    width: 10%;
  }

  .divVisibility img {
    width: 20px;
  }

  .divOpenClose img {
    width: 15px;
  }
</style>
