<script>
  export let status;
  export let defaultStatus;
  export let onEdit;
  export let onRemove;
  export let editable = true;

  import moment from 'moment';

  import Combobox from "./Combobox.svelte";
  import DialogSpanCalendarPickerEvent from "../../../calendar/screens/components/DialogSpanCalendarPickerEvent.svelte";
  import Switch from "../../../../components/Switch.svelte";

  let isEditing = false;

  let statusSel = null;
  let startDate = "";
  let endDate = "";

  const backgroundColorInput = "#d7e1ea";

  let showEdit = (show) => {
    isEditing = show;
    if (isEditing) {
      statusSel = defaultStatus.find((item) => {
        return item.value.id === status.statusId;
      });
      startDate = moment(status.start, "YYYY-MM-DD").format("DD/MM/YYYY");
      endDate = status.end
        ? moment(status.end, "YYYY-MM-DD").format("DD/MM/YYYY")
        : "Sin fecha final";
    }
  };

  let saveStatus = () => {
    status.name = statusSel.label;
    status.statusId = statusSel.value.id;
    status.start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    status.end =
      endDate === "Sin fecha final"
        ? null
        : moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    status.active = statusSel.value.active;
    status.modified = true;
    status = status;
    showEdit(false);
  };
</script>

<div class="statusHistoryItemContainer">
  {#if isEditing}
    <div class="divComboBox analytics">
      <div class="divComboBoxElement">
        <Combobox
          bind:selectedValue={statusSel}
          options={defaultStatus}
          backgroundColor={backgroundColorInput}
        />
      </div>
      <div class="divComboBoxElement">
        <DialogSpanCalendarPickerEvent
          bind:text={startDate}
          calendarOptions={[]}
          action="edit"
          error={false}
          marginBottom="0px"
          height="16"
          analytics={true}
        />
      </div>
      <div class="divComboBoxElement">
        <DialogSpanCalendarPickerEvent
          bind:text={endDate}
          calendarOptions={["Sin fecha final"]}
          action="edit"
          error={false}
          marginBottom="0px"
          height="16"
          analytics={true}
        />
      </div>
      <!--<div class="divComboBoxSwitch">
        <Switch
          width={30}
          height={16}
          checked={statusSel.value.active}
          onClick={null}
          readonly={true}
        />
      </div>-->
      <div class="options">
        <img
          class="fadedButton"
          src="/images/save.svg"
          alt="Save"
          on:click={() => saveStatus()}
        />
        <img
          class="fadedButton"
          src="/images/x.svg"
          alt="Cancel"
          on:click={() => showEdit(false)}
        />
      </div>
    </div>
  {:else}
    <div class="name">{status.name}</div>
    <div class="date">
      {moment(status.start, "YYYY-MM-DD").format("DD/MM/YYYY")}
    </div>
    <div class="date">
      {status.end ? moment(status.end, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}
    </div>
    <div class="switch">
      <!--<Switch
        width={24}
        height={12}
        checked={status.active}
        onClick={null}
        readonly={true}
      />-->
    </div>
    {#if editable}
      <div class="options">
        <img
          class="fadedButton"
          src="/images/edit.svg"
          alt="Edit"
          on:click={onEdit}
        />
        <img
          class="fadedButton"
          src="/images/trash.svg"
          alt="Remove"
          on:click={onRemove}
        />
      </div>
    {/if}
  {/if}
</div>

<style>
  div.statusHistoryItemContainer {
    display: flex;
    font-size: 12px;
    color: var(--labit-card-gray);
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }

  div.name {
    width: 40%;
  }

  div.date {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  div.switch {
    width: 10%;
    display: flex;
    justify-content: center;
  }

  div.options {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  div.options img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  div.divComboBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  div.divComboBoxElement {
    height: 16px;
    width: 25%;
  }

  /*div.divComboBoxSwitch {
    width: 10%;
    display: flex;
    justify-content: center;
  }*/
</style>
