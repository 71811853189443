<script>
  /* Screens */

  import Login from "./Login.svelte";
  import Logout from "./Logout.svelte";
  import NotFound404 from "./NotFound404.svelte";
  import OnlyAnalytics from "./OnlyAnalytics.svelte";
  import URLBrowser from "./URLBrowser.svelte";
  import SkylabMain from "./SkylabMain.svelte";
  import HotOfThePress from "./HotOfThePress.svelte";
  import Settings from "./Settings.svelte";
  import ExtraTools from "../modules/tools/screens/Tools.svelte";
  import Monitor from "../modules/monitor/Monitor.svelte"
  
  import {
    getRole,
    getMainArgs,
    setMainArgs,
    getPlatform,
    setSkylabVersion,
    setOS,
  } from "../js/localInfo";
  import { Router, Route } from "svelte-routing";

  import { mainUrl } from "../js/stores.js";

  const platform = getPlatform();

  export let url = "";

  import { checkLogin } from "../js/checkLogin.js";
  
  
  url = checkLogin() ? url : "/login";
  console.log("URL after checkLogin: ",url)

  let myRole = getRole();
  if (myRole === "None") {
    url = "/logout";
    console.log("Redirecting to logout because role is None");
  }else{
    console.log("User role:", myRole);
  }


  if (platform === "APP") {
    if (window.location.href.includes("skylab-version")) {
      const tokens = window.location.href.split("?");
      const vars = tokens[1].split("&");
      vars.forEach((v) => {
        const tks = v.split("=");
        switch (tks[0]) {
          case "skylab-version":
            //alert(tks[1]);
            setSkylabVersion(tks[1]);
            break;
          case "os":
            //alert(tks[1]);
            setOS(tks[1]);
            break;
        }
      });
      location.href = "/";
    }
  }

  let args = "";
  if (window.location.pathname.startsWith("/skylab-main/")) {
    args = window.location.pathname.replace(/\/skylab-main/g, "");
    setMainArgs(args);
    location.href = "/skylab-main";
  } else {
    if (window.location.pathname === "/skylab-main") {
      args = getMainArgs();
      const subUrl =
        args !== ""
          ? {
              url: args,
            }
          : { url: "/" };
      mainUrl.set(subUrl);
    }
  }
</script>

<Router {url}>
  <Route path="/skylab-main">
    <SkylabMain />
  </Route>
  <Route path="/hot-of-the-press">
    <HotOfThePress />
  </Route>
  <Route path="/settings">
    <Settings />
  </Route>
  <Route path="/skylab-main/*">
    <SkylabMain />
  </Route>
  <Route path="/check-outlook">
    <Login />
  </Route>
  <Route path="/pdashboard">
    <Login />
  </Route>
  <Route path="/tools">
    <Login />
  </Route>
  <Route path="/extra-tools">
    <ExtraTools />
  </Route>
  <Route path="/outlook">
    <Login />
  </Route>
  <Route path="/outlook/:calendar" let:params>
    <Login />
  </Route>
  <Route path="/slack">
    <Login />
  </Route>
  <Route path="/calendar">
    <Login />
  </Route>
  <Route path="/templates">
    <Login />
  </Route>
  <Route path="/process">
    <Login />
  </Route>
  <Route path="/dashboard">
    <Login />
  </Route>
  <Route path="/people">
    <Login />
  </Route>
  <Route path="/people/create-people">
    <Login />
  </Route>
  <Route path="/people/create-company">
    <Login />
  </Route>
  <Route path="/people/show-people/:id" let:params>
    <Login />
  </Route>
  <Route path="/people/show-company/:id" let:params>
    <Login />
  </Route>
  <Route path="/projects">
    <Login />
  </Route>
  <Route path="/projects/:id" let:params>
    <Login />
  </Route>
  <Route path="/projects/dashboard">
    <Login />
  </Route>
  <Route path="/roomdatasheet" component={Login} />
  <Route path="/roomdatasheet/:idCompany" let:params>
    <Login />
  </Route>
  <Route path="/roomdatasheet/:idCompany/:idVersion/:idProject" let:params>
    <Login />
  </Route>
  <Route
    path="/roomdatasheet/:idCompany/:idVersion/:idProject/printmode"
    let:params
  >
    <Login />
  </Route>
  <Route
    path="/roomdatasheetl/:idCompany/:idVersion/:idProject/:idProgram/:idSpace/:idRoom"
    let:params
  >
    <Login />
  </Route>

  
  <Route path="/people">
    <Login />
  </Route>
  <Route path="/clockify"> <Login /> </Route>
  <Route path="/factorial"> <Login /> </Route>
  <Route path="/me"> <Login /> </Route>
  <Route path="/inventory"> <Login /> </Route>
  <Route path="/monitor" component={Monitor}><Login /> </Route>

  <Route path="/roomdatasheetl" component={Login} />
  <Route path="/roomdatasheetg" component={Login} />
  <Route path="/roomdatasheetg/:idCompany" let:params>
    <Login />
  </Route>
  <Route path="/roomdatasheetg/:idCompany/:idVersion/:idProject" let:params>
    <Login />
  </Route>
  <Route path="/resources-generalg" component={Login} />
  <Route path="/resources-management" component={Login} />
  <Route path="/resources-newtmo" component={Login} />
  <Route path="/resources-production" component={Login} />
  <Route path="/resources-software" component={Login} />
  <Route path="/guidelines" component={Login} />
  <Route path="/guidelines-clients" component={Login} />
  <Route path="/apps" component={Login} />
  <Route path="/urlbrowser" component={URLBrowser} />
  <Route path="/urlbrowser/:url" let:params>
    <URLBrowser urlFromParams={params.url} />
  </Route>
  <Route path="/soon" component={Login} />
  <Route path="/login" component={Login} />
  <Route path="/logout" component={Logout} />

  <Route path="/skeleton" component={Login} />

  <!-- Analytics -->

  <Route path="/analytics" component={Login} />
  <Route path="/project-analytics" component={Login} />
  <Route path="/project-analytics/new" let:params>
    {#if myRole === "Admin" || myRole === "Analytics"}
      <Login />
    {:else}
      <OnlyAnalytics />
    {/if}
  </Route>
  <Route path="/projects-documents" component={Login} />
  <Route path="/licenses" component={Login} />
  <Route path="/standards" component={Login} />
  <Route path="/document-management" component={Login} />
  <Route path="/revit-link" component={Login} />
  <Route path="/debug" component={Login} />
  <Route path="/resource-management" component={Login} />
  <Route path="/resource-management-static" component={Login} />
  <Route path="/license-manager" component={Login} />
  <Route path="/assigned-licenses" component={Login} />

  <!-- Direction -->

  <Route path="/direction" component={Login}>{console.log("Cargando Ruta: /direction")}</Route>
  
  <Route path="/hubspot" component={Login} />
  <Route path="/people-documents" component={Login} />
  <Route path="/tests" component={Login} />

  <Route path="/" exact component={SkylabMain} />

  <Route component={NotFound404} />
</Router>

<style>
</style>
