<script>
  export let text;
  export let onClickRed;
  export let onClickGreen;
  export let onClickYellow;
  export let selectedRed;
  export let selectedYellow;
  export let selectedGreen;
  export let textRed = "";
  export let textYellow = "";
  export let textGreen = "";

  import Light from "./Light.svelte";
</script>

<style>
  div.submenuTextLights {
    display: flex;
    color: var(--labit-card-gray);
    height: 21px;
    font-size: 13px;
    align-items: center;
  }

  span {
    margin-right: 7px;
  }
</style>

<div class="submenuTextLights">
  <span>{text}</span>
  <Light
    type="green"
    size="22px"
    onClick={onClickGreen}
    marginRight="7px"
    light={!selectedGreen}
    text={textGreen}
    strong={textGreen !== '' || textYellow !== '' || textRed !== ''} />
  <Light
    type="yellow"
    size="22px"
    onClick={onClickYellow}
    marginRight="7px"
    light={!selectedYellow}
    text={textYellow}
    strong={textGreen !== '' || textYellow !== '' || textRed !== ''} />
  <Light
    type="red"
    size="22px"
    onClick={onClickRed}
    light={!selectedRed}
    text={textRed}
    strong={textGreen !== '' || textYellow !== '' || textRed !== ''} />
</div>
