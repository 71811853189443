<script>
  export let text;
  export let onClick;
</script>

<div class="editBoxButtonContainer fadedButton" on:click={onClick}>
  <span>{text}</span>
</div>

<style>
  div.editBoxButtonContainer {
    display: flex;
    justify-content: center;
    background-color: #72809d;
    border-radius: 25px;
    width: 129px;
    height: 22px;
    align-items: center;
    color: #ffff;
    font-size: 15px;
  }
</style>
