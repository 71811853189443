<script>
  export let keyword;
</script>

<div class="fileBoobleFileKeyword">
  <span>{keyword.keyword}</span>
</div>

<style>
  div.fileBoobleFileKeyword {
    background-color: var(--labit-black-button);
    font-size: 11px;
    color: white;
    padding: 2px 6px 2px 6px;
    border-radius: 4px;
    margin-right: 4px;
  }
</style>
