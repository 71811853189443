<script>
  export let company;
  export let onClose;
  export let onSave;
  export let onRemove;
  export let action = "show";
  export let newCompany;

  import Switch from "../components/Switch.svelte";
  //import DialogExpandSpan from "../components/DialogExpandSpan.svelte";
  import DialogSpanEdit from "../components/DialogSpanEdit.svelte";
  import DialogSpanSuggestionEdit from "../components/DialogSpanSuggestionEdit.svelte";
  //import Combobox from "./Combobox.svelte";
  import DialogSpanCombobox from "./DialogSpanCombobox.svelte";
  import FileUploader from "../components/FileUploader.svelte";
  import GoogleMaps from "../components/GoogleMaps.svelte";
  import BorderButton from "../components/BorderButton.svelte";
  import CloseButton from "../components/CloseButton.svelte";

  import { getRole } from "../js/localInfo";
  import { post } from "../js/labit-connection";
  import { getCoordinatesFromMapsURL } from "../js/getCoordinatesFromMapsURL";
  import { updateCityCode } from "../js/updateCityCode";
  import { copyObject } from "../js/copyObject";

  import countriesJSON from "../data/countries";

  import { onMount } from "svelte";
  import DialogCompanyCategory from "./DialogCompanyCategory.svelte";

  let categoryOptions = [];
  let selectedCategory = null;

  let errorName = false;
  let errorCityCode2 = false;
  let errorCityCode3 = false;
  let errorCategory = false;
  let error = false;

  let mapsURL = "";

  let myRole = getRole();

  let cities = [];
  let countries = [];
  let countriesSel = null;
  let cityCodes = [];
  let cityCode2 = "";
  let cityCode3 = "";
  let setCityCode2 = false;
  let setCityCode3 = false;

  let askingForClosing = false;
  let savedCompany = null;
  let modified = false;

  onMount(async () => {
    countries = countriesJSON.map((country) => {
      return {
        label: country.code + " - " + country.name,
        value: country.code,
      };
    });

    countries = countries.sort((a, b) => {
      if (a.value > b.value) {
        return 1;
      } else if (a.value < b.value) {
        return -1;
      }
      return 0;
    });

    countriesSel = countries.find((item) => {
      return item.label === company.country;
    });
    if (!countriesSel) {
      const c = countriesJSON.find((item) => {
        return item.name === "None";
      });
      countriesSel = {
        label: c.code + " - " + c.name,
        value: c.code,
      };
    }

    await getCityCodes();
    await getCities();
    await getCategories();

    setCityCodes();

    if (newCompany) {
      savedCompany = copyObject(company);
      modified = true;
    }
  });

  let getCityCodes = async () => {
    let response = await post(
      `select name, code2, code3
        from city_codes
        order by name asc`
    );
    cityCodes = response.map((item) => {
      return {
        name: item.name,
        code2: item.code2,
        code3: item.code3,
      };
    });
  };

  let getCities = async () => {
    let response = await post(
      `select distinct City
        from people`
    );
    const citiesPeople = response.map((item) => {
      return item.City ? item.City : "";
    });

    response = await post(
      `select distinct City
        from companies`
    );
    const citiesCompanies = response.map((item) => {
      return item.City ? item.City : "";
    });

    cities = citiesPeople.concat(citiesCompanies);
    cities = [...new Set(cities)];
    cities.sort();
  };

  let getCategories = async () => {
    const response = await post(
      `select category_id, Name
          from categories`
    );
    categoryOptions = response.map((c) => {
      return {
        value: c.category_id,
        label: c.Name,
      };
    });
    selectedCategory = categoryOptions.find((category) => {
      return category.value === company.categoryId;
    });
    if (!selectedCategory) {
      selectedCategory = categoryOptions[0];
    }
  };

  function toggleLabit() {
    company.labit = !company.labit;
  }

  function toggleActive() {
    company.active = !company.active;
  }

  async function toggleAction() {
    if (action === "edit" || action === "new") {
      checkErrors();
      if (!error) {
        // Check whether it is a new city code
        if (company.city !== "" && (!setCityCode2 || !setCityCode3)) {
          await updateCityCode(company.city, cityCode2, cityCode3);

          await getCityCodes();
          setCityCodes();
        }

        onSave();

        modified = false;
      }
    } else {
      savedCompany = copyObject(company);
      modified = true;
    }
    if (!error) {
      if (action !== "new") {
        action = action === "edit" ? "show" : "edit";
      }
    }
  }

  let checkErrors = () => {
    errorName = false;
    errorCityCode2 = false;
    errorCityCode3 = false;

    if (company.name === "") {
      errorName = true;
    }
    if (company.city !== "") {
      if (cityCode2.trim() === "") {
        errorCityCode2 = true;
      }
      if (cityCode3.trim() === "") {
        errorCityCode3 = true;
      }
    }

    errorCategory = company.categories.length === 0;

    error = errorName || errorCityCode2 || errorCityCode3 || errorCategory;
  };

  let setCityCodes = () => {
    const code = cityCodes.find((cityCode) => {
      return cityCode.name === company.city;
    });
    if (code) {
      cityCode2 = code.code2;
      cityCode3 = code.code3;
      setCityCode2 = cityCode2 !== "";
      setCityCode3 = cityCode3 !== "";
    } else {
      cityCode2 = "";
      cityCode3 = "";
      setCityCode2 = false;
      setCityCode3 = false;
    }
  };

  let addCategory = () => {
    const pos = company.categories.findIndex((item) => {
      return item.id === selectedCategory.value;
    });
    if (pos === -1) {
      const cat = {
        id: selectedCategory.value,
        name: selectedCategory.label,
      };
      company.categories.push(cat);
      company.categories = company.categories;
    }
  };

  let removeCategory = (id) => {
    company.categories = company.categories.filter((item) => {
      return item.id !== id;
    });
  };

  let xDialog = (option) => {
    switch (option) {
      case "request":
        if (action === "show") {
          onClose();
        } else {
          if (modified) {
            if (!savedCompany.picImage) {
              savedCompany.picImage = company.picImage;
            }
            const savedCompanyS = JSON.stringify(savedCompany);
            const companyS = JSON.stringify(company);
            /*console.log(savedCompanyS);
            console.log(companyS);
            console.log(savedCompanyS !== companyS);*/
            if (savedCompanyS !== companyS) {
              askingForClosing = true;
            } else {
              if (newCompany) {
                onClose();
              } else {
                xDialog("close");
              }
            }
          } else {
            xDialog("close");
          }
        }
        break;
      case "save":
        toggleAction();
        askingForClosing = false;
        break;
      case "cancel":
        askingForClosing = false;
        break;
      case "close":
        if (newCompany) {
          onClose();
        } else {
          company = JSON.parse(JSON.stringify(savedCompany));
          askingForClosing = false;
          action = "show";
        }
        break;
    }
  };

  /*$: if (selectedCategory) {
    company.category = selectedCategory.label;
    company.categoryId = selectedCategory.value;
  }*/

  $: {
    const coordinates = getCoordinatesFromMapsURL(mapsURL);
    if (coordinates.latitude && coordinates.longitude) {
      company.latitude = coordinates.latitude;
      company.longitude = coordinates.longitude;
      company = company;
    }
  }

  $: if (countriesSel) {
    company.country = countriesSel.label;
  }
</script>

<div class="dialogContent">
  <div class="card {action === 'edit' || action === 'new' ? 'edit' : ''}">
    <!-- Header -->
    <div class="header">
      <div class="projectCard">
        {#if action === "show"}
          {#if company.pic === ""}
            <div class="nopic">
              <img src="/images/default-company.png" alt="No Pic" />
            </div>
          {:else}
            <div class="pic" style="background-image: url('{company.pic}')" />
          {/if}
        {:else}
          <div class="nopic">
            <FileUploader
              bind:fileInfo={company.picImage}
              loadFromPath={company.picPath}
              picExtraStyle="border-radius: 10px"
              showFullImage={true}
            />
          </div>
        {/if}
        <div class="info">
          <div class="row first">
            <span class="title">
              {company.name !== "" ? company.name.toUpperCase() : ""}
            </span>
          </div>
          <div class="row">
            <span>{company.city}</span>
            <span class="spacer">{cityCode2}</span>
          </div>
        </div>
      </div>
      <div class="googlemap">
        <GoogleMaps
          mapCenter={{
            latitude: company.latitude,
            longitude: company.longitude,
          }}
          locations={[
            {
              latitude: company.latitude,
              longitude: company.longitude,
              name: company.name,
            },
          ]}
        />
      </div>
      <!--{#if company.map === ''}
        <div class="nomap">
          <img src="/images/no_loc.svg" alt="No Map" />
        </div>
      {:else}
        <iframe
          title="Company Map"
          src={company.map}
          frameborder="0"
          style="border:0;"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0" />
      {/if}-->
      {#if askingForClosing}
        <div class="cardButtons">
          <div class="xDialog">
            <span class="xDialog">Save changes before closing?</span>
            <div class="xDialogRow">
              <div class="editButton cardIcon">
                <BorderButton
                  text="Save"
                  onClick={() => xDialog("save")}
                  borderColor="var(--labit-light-red)"
                  fontColor="white"
                  backgroundColor="var(--labit-light-red)"
                />
              </div>
              <div class="editButton cardIcon">
                <BorderButton
                  text="No"
                  onClick={() => xDialog("close")}
                  borderColor="var(--labit-light-red)"
                  fontColor="var(--labit-light-red)"
                />
              </div>
              <div class="editButton cardIcon">
                <BorderButton
                  text="Cancel"
                  onClick={() => xDialog("cancel")}
                  borderColor="var(--labit-light-red)"
                  fontColor="var(--labit-light-red)"
                />
              </div>
            </div>
          </div>
        </div>
      {:else}
        <div class="cardButtons">
          {#if action === "edit"}
            <div class="cardIcon cardIconIcon">
              <img src="/images/trash.svg" alt="Remove" on:click={onRemove} />
            </div>
          {/if}
          {#if action === "edit" || action === "new"}
            <div class="cardIcon cardIconIcon">
              <img
                src="/images/save.svg"
                alt="Save"
                on:click={() => toggleAction()}
              />
            </div>
          {/if}
          {#if myRole === "Admin"}
            {#if action === "show"}
              <div class="editButton cardIcon">
                <BorderButton text="Edit" onClick={() => toggleAction()} />
              </div>
            {/if}
          {/if}
          <div class="cardIcon cardIconIcon">
            <!--<CloseButton
            size="38px"
            onClick={onClose}
            closing={action !== "show"}
          />-->
            <CloseButton
              size="38px"
              onClick={() => xDialog("request")}
              closing={action !== "show"}
            />
          </div>
        </div>
      {/if}
    </div>
    <div class="body">
      <!-- Info 1 -->

      <div class="columnInfo1 columnData">
        <div class="box">
          <div class="tableInfo">
            <div class="tableInfoColumnHalf">
              <div class="tableInfoCell">
                <span
                  class="columnTitle {action !== 'show'
                    ? 'requiredElement'
                    : ''}">Name</span
                >
              </div>
              <div class="tableInfoCell">
                <DialogSpanEdit
                  bind:text={company.name}
                  {action}
                  last={true}
                  error={errorName}
                />
              </div>
            </div>
          </div>
          <div class="tableInfo">
            <div class="tableInfoColumnHalf">
              <div class="tableInfoCell">
                <span class="columnTitle">Company Code</span>
              </div>
              <div class="tableInfoCell">
                <DialogSpanEdit
                  bind:text={company.code}
                  maxLength={5}
                  {action}
                  last={true}
                />
              </div>
            </div>
          </div>
          <span class="columnTitle">Address</span>
          <DialogSpanEdit bind:text={company.address} {action} last={false} />
          <div class="tableInfo">
            <div class="tableInfoColumnHalf">
              <div class="tableInfoCell">
                <span class="columnTitle">City</span>
              </div>
              <div class="tableInfoCell">
                <DialogSpanSuggestionEdit
                  bind:text={company.city}
                  suggestions={cities}
                  onChange={() => setCityCodes()}
                  {action}
                  last={true}
                />
              </div>
            </div>
            {#if action !== "show"}
              <div class="tableInfoColumnHalf">
                <div class="tableInfoCell">
                  <span class="columnTitle">2-Digit Code</span>
                </div>
                <div class="tableInfoCell">
                  <DialogSpanEdit
                    bind:text={cityCode2}
                    {action}
                    maxLength={2}
                    readOnly={setCityCode2}
                    error={errorCityCode2}
                    last={true}
                  />
                </div>
              </div>
              <div class="tableInfoColumnHalf">
                <div class="tableInfoCell">
                  <span class="columnTitle">3-Digit Code</span>
                </div>
                <div class="tableInfoCell">
                  <DialogSpanEdit
                    bind:text={cityCode3}
                    {action}
                    maxLength={3}
                    readOnly={setCityCode3}
                    error={errorCityCode3}
                    last={true}
                  />
                </div>
              </div>
            {/if}
          </div>
          <span class="columnTitle">Country</span>
          <DialogSpanCombobox
            bind:selectedValue={countriesSel}
            options={countries}
            searchable={true}
            {action}
            last={false}
          />
          <span class="columnTitle">Telephone</span>
          <DialogSpanEdit bind:text={company.telephone} {action} last={false} />
          <span class="columnTitle">Email</span>
          <DialogSpanEdit bind:text={company.email} {action} last={false} />
          <span class="columnTitle {action !== 'show' ? 'requiredElement' : ''}"
            >Categories</span
          >
          <div class="categories">
            {#if action !== "show"}
              <div class="categoriesAdd">
                <DialogSpanCombobox
                  bind:selectedValue={selectedCategory}
                  options={categoryOptions}
                  action="edit"
                  last={false}
                  error={errorCategory}
                />
                <img
                  class="fadedButton"
                  src="/images/plus.svg"
                  alt="Add Category"
                  on:click={() => addCategory()}
                />
              </div>
            {/if}
            {#each company.categories as category}
              <DialogCompanyCategory
                {category}
                {action}
                onRemove={(id) => removeCategory(id)}
              />
            {/each}
          </div>
          <span class="columnTitle">Practise</span>
          <DialogSpanEdit bind:text={company.practise} {action} last={true} />
        </div>
        <div class="box">
          <div class="active">
            <span class="columnTitle">Belongs to Labit</span>
            <Switch
              width={40}
              height={22}
              bind:checked={company.labit}
              onClick={() => toggleLabit()}
              readonly={action === "show"}
            />
          </div>
        </div>
      </div>

      <!-- Info 2 -->

      <div class="columnInfo2 columnData">
        <div class="box">
          <!--<span class="columnTitle">Picture</span>
          <DialogSpanEdit bind:text={company.pic} {action} last={false} />-->
          <!--<span class="columnTitle">Google Maps URL</span>
          <DialogSpanEdit bind:text={company.map} {action} last={true} />-->
          <div class="tableInfo">
            <div class="tableInfoColumnHalf">
              <span class="columnTitle">Latitude</span>
              <DialogSpanEdit
                bind:text={company.latitude}
                {action}
                last={false}
              />
            </div>
            <div class="tableInfoColumnHalf">
              <span class="columnTitle">Longitude</span>
              <DialogSpanEdit
                bind:text={company.longitude}
                {action}
                last={false}
              />
            </div>
          </div>
          {#if action !== "show"}
            <span class="columnTitle">Parse from Google Maps URL</span>
            <DialogSpanEdit bind:text={mapsURL} action="edit" last={true} />
          {/if}
        </div>
        <div class="box">
          <div class="active">
            <span class="columnTitle">Active</span>
            <Switch
              width={40}
              height={22}
              bind:checked={company.active}
              onClick={() => toggleActive()}
              readonly={action === "show"}
            />
          </div>
        </div>
        <div class="box dumbBox1" />
      </div>
    </div>
  </div>
</div>

<style>
  div.dialogContent {
    background-color: var(--labit-background-gray);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
  }

  div.dialogContent:hover {
    width: 100%;
    height: 100%;
  }

  div.card {
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    padding: 10px;
  }

  div.edit div.box {
    border-color: transparent !important;
  }

  div.header {
    width: 100%;
    display: flex;
    position: relative;
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 10px;
    padding-top: 0px;
  }

  div.body {
    background-color: white;
    width: 100%;
    border-radius: 14px;
    padding: 23px;
    display: flex;
  }

  div.projectCard {
    width: 198px;
    height: 192px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0px;
    display: flex;
    flex-direction: column;
  }

  div.pic {
    width: 100%;
    height: 65%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 14px;
  }

  div.nopic {
    width: 100%;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.nopic img {
    width: 77px;
  }

  div.info {
    width: 100%;
    height: 35%;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  div.row {
    width: 100%;
    display: flex;
  }

  div.projectCard span {
    font-size: 11px;
    color: var(--labit-card-lightgray);
  }

  div.projectCard span.title {
    font-size: 16px;
    font-weight: bold;
    color: var(--labit-card-gray);
  }

  div.projectCard span.spacer {
    margin-left: 5%;
  }

  div.projectCard div.first {
    justify-content: space-between;
  }

  div.cardButtons {
    height: 100%;
    padding: 10px 0px 10px 10px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    padding-top: 35px;
  }

  div.cardButtons div img {
    cursor: pointer;
  }

  div.cardButtons div.edit img {
    height: 16%;
  }

  img.close {
    position: absolute;
    width: 12px;
    top: 0px;
    right: 23px;
    cursor: pointer;
  }

  div.box {
    border-color: var(--labit-card-lightgray);
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    border-radius: 14px;
    width: 367px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  div.box:not(:last-child) {
    margin-bottom: 10px;
  }

  div.columnData:not(:first-child) {
    margin-left: 38px;
  }

  span.columnTitle {
    width: 100%;
    font-size: 11px;
    color: var(--labit-card-gray);
    margin-bottom: 1px;
  }

  div.tableInfo {
    display: flex;
    margin-bottom: 8px;
  }

  div.tableInfoColumnHalf {
    width: 33%;
    display: flex;
    flex-direction: column;
  }

  div.tableInfoColumnHalf:not(:last-child) {
    padding-right: 10px;
  }

  div.tableInfoCell {
    display: flex;
  }

  div.tableInfo:last-child {
    margin-bottom: 0px;
  }

  div.tableInfo span:empty:before {
    content: "\200b";
  }

  div.active {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div.active span {
    margin-bottom: 0px;
  }

  /*iframe {
    margin-left: 38px;
    width: 367px;
    height: 192px;
    border-radius: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }*/

  div.googlemap {
    margin-left: 38px;
    width: 367px;
    height: 192px;
    border-radius: 14px;
  }

  /*div.nomap {
    margin-left: 38px;
    width: 367px;
    height: 192px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.nomap img {
    width: 77px;
  }*/

  div.xDialog {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  span.xDialog {
    position: absolute;
    top: -28px;
    left: 12px;
    color: var(--labit-light-red);
    font-size: 13px;
  }

  div.xDialogRow {
    display: flex;
  }

  /* Animations */

  img.close {
    transition: opacity 0.5s linear 0s;
  }

  img.close:hover {
    opacity: 0.5;
  }

  div.cardButtons img {
    transition: opacity 0.5s linear 0s;
  }

  div.cardButtons img:hover {
    opacity: 0.5;
  }

  div.dumbBox1 {
    height: 144px;
  }

  div.categoriesAdd {
    display: flex;
    align-items: center;
  }

  div.categoriesAdd img {
    width: 16px;
    height: 16px;
    margin: 0px 0px 8px 5px;
  }

  div.editButton {
    width: 100px;
    height: 38px;
  }

  div.cardIcon {
    margin-left: 12px;
  }

  div.cardIconIcon {
    width: 38px;
    height: 38px;
  }
</style>
