<script>
  export let options;
  export let placeholder = "";
  export let selectedValue;
  export let searchable = true;
  export let multiOptions = false;
  export let onChange = null;
  export let backgroundColor = "transparent";
  export let error = false;
  export let disabled = false;

  import Select from "svelte-select";

  
  console.log("options: " + options, "selectedVal: " + selectedValue);

</script>

<div class={error ? "selectError" : ""}>
  <Select
    items={options}
    isMulti={multiOptions}
    bind:selectedValue
    {placeholder}
    on:select={onChange}
    isSearchable={searchable}
    inputStyles="background-color: {backgroundColor}; border-radius:
    969px/969px;"
    isDisabled={disabled}
  />
  <img src="/images/down_arrow.svg" alt="icon" />
</div>

<style>
  div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  img {
    position: absolute;
    height: 35%;
    right: 10px;
  }
</style>
