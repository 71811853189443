/*

CALENDAR EXAMPLES

await outlookGetCalendarEvents(
      "AQMkADAwATM0MDAAMS0yYTRlLTMzADU3LTAwAi0wMAoARgAAAw9FSLkrNlBEgW5RF1eRBFgHAM86KYL_FdJMgKOM-Ni8GxsAAAACAQYAAADPOimC-hXSTICjjPzYvBsbAAAAAiLbAAAA",
      "2020-09-01",
      "2020-09-30",
      events => {
        console.log(events);
      },
      () => {}
    );

    await outlookCreateEvent(
      "AQMkADAwATM0MDAAMS0yYTRlLTMzADU3LTAwAi0wMAoARgAAAw9FSLkrNlBEgW5RF1eRBFgHAM86KYL_FdJMgKOM-Ni8GxsAAAACAQYAAADPOimC-hXSTICjjPzYvBsbAAAAAiLbAAAA",
      "Subject",
      "Description",
      "2020-10-22 09:00:00",
      "2020-10-22 10:00:00",
      "Cafetería",
      false,
      [
        {
          name: "Support",
          email: "support@labit.es"
        }
      ],
      type,
      () => {},
      e => {
        console.log(e);
      }
    );

    await outlookEditEvent(
      "AQMkADAwATM0MDAAMS0yYTRlLTMzADU3LTAwAi0wMAoARgAAAw9FSLkrNlBEgW5RF1eRBFgHAM86KYL_FdJMgKOM-Ni8GxsAAAACAQ0AAADPOimC-hXSTICjjPzYvBsbAAAAABSY1DsAAAA=",
      "Subject",
      "Description",
      "2020-10-24 09:00:00",
      "2020-10-24 10:00:00",
      "Cafetería",
      false,
      [],
      () => {},
      e => {
        console.log(e);
      }
    );

    await outlookDeleteEvent(
      "AQMkADAwATM0MDAAMS0yYTRlLTMzADU3LTAwAi0wMAoARgAAAw9FSLkrNlBEgW5RF1eRBFgHAM86KYL_FdJMgKOM-Ni8GxsAAAACAQ0AAADPOimC-hXSTICjjPzYvBsbAAAAABQB7QoAAAA=",
      () => {},
      e => {
        console.log(e);
      }
    );
*/

import {
  getOutlookToken,
  setOutlookToken,
  getOutlookEmail,
  setOutlookEmail,
} from "../../../js/localInfo";

const CLIENT_ID = "891501e5-7cdd-4caf-9f3a-ad6ce8cd6c54";
const ENDPOINT_ME = "https://graph.microsoft.com/v1.0/me";
const ENDPOINT_EMAILS = "https://graph.microsoft.com/v1.0/me/mailFolders/Inbox/messages";
const ENDPOINT_CALENDAR = "https://graph.microsoft.com/v1.0/me/calendars/#CALENDARID#/calendarView";
const ENDPOINT_CALENDARS = "https://graph.microsoft.com/v1.0/me/calendars";
const ENDPOINT_USER = "https://graph.microsoft.com/v1.0/me";
const ENDPOINT_EVENT_CREATION = "https://graph.microsoft.com/v1.0/me/calendars/#ID#/events";
const ENDPOINT_EVENT_EDITION = "https://graph.microsoft.com/v1.0/me/events/#ID#";
const ENDPOINT_EVENT_DELETION = "https://graph.microsoft.com/v1.0/me/events/#ID#";
const ENDPOINT_CALENDAR_CREATION = "https://graph.microsoft.com/v1.0/me/calendars";
const ENDPOINT_GROUP = "https://graph.microsoft.com/v1.0/groups/#ID#/calendarView";
const ENDPOINT_GROUPS = "https://graph.microsoft.com/v1.0/users/#USERID#/memberOf";
const ENDPOINT_GROUP_EVENT_CREATION = "https://graph.microsoft.com/v1.0/groups/#ID#/events";
const ENDPOINT_GROUP_EVENT_EDITION = "https://graph.microsoft.com/v1.0/groups/#CALENDARID#/events/#ID#";
const ENDPOINT_GROUP_EVENT_DELETION = "https://graph.microsoft.com/v1.0/groups/#CALENDARID#/events/#ID#";
const ENDPOINT_PLANS = "https://graph.microsoft.com/v1.0/groups/#GROUPID#/planner/plans";
const ENDPOINT_BUCKETS = "https://graph.microsoft.com/v1.0/planner/plans/#PLANID#/buckets";
const ENDPOINT_TASKS = "https://graph.microsoft.com/v1.0/planner/plans/#PLANID#/tasks";
const ENDPOINT_TASK_EDITION = "https://graph.microsoft.com/v1.0/planner/tasks/#TASKID#";
const ENDPOINT_USERS = "https://graph.microsoft.com/v1.0/users/#USERID#";
const ENDPOINT_TODO_LISTS = "https://graph.microsoft.com/v1.0/me/todo/lists";
const ENDPOINT_TODO_TASKS = "https://graph.microsoft.com/v1.0/me/todo/lists/#LISTID#/tasks";
const ENDPOINT_TODO_TASK_EDITION = "https://graph.microsoft.com/v1.0/me/todo/lists/#LISTID#/tasks/#TASKID#";
const PLANNER_LINK = "https://tasks.office.com/labit.es/es-ES/Home/Planner/#/plantaskboard?groupId=#GROUPID#&planId=#PLANID#";
const ALL_MY_TASKS = "https://graph.microsoft.com/v1.0/me/planner/tasks";
const ALL_MY_GROUPS = "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true";
const GROUP_DETAILS = "https://graph.microsoft.com/v1.0/groups/#GROUPID#";
const GROUP_PLANS = "https://graph.microsoft.com/v1.0/groups/#GROUPID#/planner/plans"
const CALENDAR_EVENTS = "https://graph.microsoft.com/v1.0/me/calendar/events";

const MAX_EVENTS = 1000;
const MAX_TIME_OUT = 300000; // 5 mins to type credentials

import moment from 'moment';
const myEmail = getOutlookEmail();

const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    //redirectUri: "http://localhost:5000",
  },
  system: {
    loadFrameTimeout: MAX_TIME_OUT, // 5 mins to type credentials
  },
};

let msalInstance = new Msal.UserAgentApplication(msalConfig);

const scopes = [
  "Mail.read",
  "Calendars.Read",
  "Calendars.Read.Shared",
  "Calendars.ReadWrite",
  "Calendars.ReadWrite.Shared",
  "User.Read",
  "User.ReadBasic.All",
  "Group.Read.All",
  "Group.ReadWrite.All",
  "Tasks.Read",
  "Tasks.Read.Shared",
  "Tasks.ReadWrite",
  "Tasks.ReadWrite.Shared",
  "Files.ReadWrite.All"
];

/*const scopes = [
  "Mail.read",
  "Calendars.Read",
  "Calendars.Read.Shared",
  "Calendars.ReadWrite",
  "Calendars.ReadWrite.Shared",
  "User.Read",
  "User.ReadBasic.All",
  "Group.Read.All",
  "Group.ReadWrite.All",
];*/

var loginRequest = {
  scopes,
};

var tokenRequest = {
  scopes,
  loginHint: myEmail,
};

export let outlookLoginSilent = (callbackOK, callbackKO) => {
  msalInstance
    .acquireTokenSilent(tokenRequest)
    .then((response) => {
      // get access token from response
      // response.accessToken
      //console.log("TOKEN " + response.accessToken);
      let token = response.accessToken;
      //setOutlookToken(token);

      callbackOK(token);
    })
    .catch((err) => {
      console.log(err.errorMessage);
      callbackKO();
    });
};

export const outlookLoginSilent2 = async () => {
  try {
    let res = await msalInstance.acquireTokenSilent(tokenRequest);
    return res.accessToken;

  } catch (e) {
    console.log(e.errorMessage);
  };
}

export let outlookLoginPopup = (callbackOK, callbackKO) => {
  msalInstance.loginPopup(loginRequest).then((response) => {
    if (msalInstance.getAccount()) {
      msalInstance
        .acquireTokenPopup(tokenRequest)
        .then((response) => {
          // get access token from response
          // response.accessToken
          let token = response.accessToken;
          //setOutlookToken(token);

          callbackOK(token);
        })
        .catch((err) => {
          // handle error
          console.log(err);
          callbackKO();
        });
    }
  });
};

export const outlookLogin2 = async () => {
  if(msalInstance.getAccount()) {
    try {
      const res = await msalInstance.acquireTokenSilent(tokenRequest);
      setOutlookToken(res.accessToken);
    } catch (e) {
      try {
        const res = await msalInstance.acquireTokenPopup(loginRequest);
        setOutlookToken(res.accessToken);
      } catch (err) {
        try {
          const res = await msalInstance.acquireTokenRedirect(loginRequest);
          setOutlookToken(res.accessToken);
        } catch (error) {
          console.log(error);
        }
      }
    }
  } else {
    const loginRequest = {
      scopes: ["User.ReadWrite"]
    }
    
    try {
      const res = await msalInstance.loginPopup(loginRequest);
      setOutlookToken(res.accessToken);
    } catch (e) {
      try {
        const res = await msalInstance.loginRedirect(loginRequest);
        setOutlookToken(res.accessToken);
      } catch (err) {
        console.log(err);
      }
    }
  }
}

export let outlookLogin = async (callbackOK, callbackKO) => {
  msalInstance
    .acquireTokenSilent(tokenRequest)
    .then((response) => {
      // get access token from response
      // response.accessToken
      //console.log("TOKEN " + response.accessToken);
      let token = response.accessToken;
      setOutlookToken(token);

      callbackOK();
    })
    .catch((err) => {
      //console.log(err.errorMessage);
      msalInstance
        .loginPopup(loginRequest)
        .then((response) => {
          if (msalInstance.getAccount()) {
            msalInstance
              .acquireTokenSilent(tokenRequest)
              .then((response) => {
                //console.log(response);
                // get access token from response
                // response.accessToken
                //console.log("TOKEN " + response.accessToken);
                let email = response.account.userName;
                setOutlookEmail(email);
                //console.log(email);

                let token = response.accessToken;
                setOutlookToken(token);

                callbackOK();
              })
              .catch((err) => {
                // could also check if err instance of InteractionRequiredAuthError if you can import the class.
                //console.log(err);
                if (err.name === "InteractionRequiredAuthError") {
                  return msalInstance
                    .acquireTokenPopup(tokenRequest)
                    .then(async (response) => {
                      // get access token from response
                      // response.accessToken
                      let token = response.accessToken;
                      setOutlookToken(token);

                      callbackOK();
                    })
                    .catch((err) => {
                      // handle error
                      console.log(err);
                      callbackKO();
                    });
                }
              });
          }
        })
        .catch((err) => {
          // handle error
          console.log(err);
          callbackKO();
        });
    });
};

export let outlookLogout = async () => {
  const msalConfig = {
    auth: {
      clientId: CLIENT_ID,
      redirectUri: "https://skylab.labit.es/login",
      postLogoutRedirectUri: "https://skylab.labit.es/login",
    },
  };

  const msalInstance = new Msal.UserAgentApplication(msalConfig);

  msalInstance.logout();
};

let outlookGetMessages_ = async (token, callbackOK, callbackKO) => {
  var headers = new Headers();
  var bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  var options = {
    method: "GET",
    headers: headers,
  };
  var graphEndpoint = ENDPOINT_EMAILS;

  const resp = await fetch(graphEndpoint, options);
  /*if (resp.status === 401) {
    //alert("401");
    console.log(resp.status);
    //setOutlookToken("");
    callbackKO();
    return;
  }*/
  const data = await resp.json();

  if (data.error) {
    console.log(data.error);
    callbackKO();
  } else {
    const messages = data.value.map((message) => {
      try {
        //console.log(message);
        let datetime = message.receivedDateTime;
        datetime = datetime.replace("T", " ");
        datetime = datetime.replace("Z", "");
        const datetimeM = moment(datetime, "YYYY-MM-DD HH:mm:ss"); // TODO ARREGLAR
        const datetimeL = new Date(
          Date.UTC(
            datetimeM.format("YYYY"),
            (parseInt(datetimeM.format("MM")) - 1).toString(),
            datetimeM.format("DD"),
            datetimeM.format("HH"),
            datetimeM.format("mm"),
            datetimeM.format("ss")
          )
        );

        datetime = moment(datetimeL.toLocaleString(), 'D/M/YYYY H:mm:ss'); //'25/1/2022 9:20:08'
        return {
          id: message.id,
          from: message.from.emailAddress.name,
          subject: message.subject ? message.subject : "(No subject)",
          new: !message.isRead,
          datetime,
        };
      } catch (e) {
        console.log(e);
      }
    });

    const numNewMessages = messages.reduce((acc, curr) => {
      if (curr.new) {
        acc++;
      }
      return acc;
    }, 0);

    callbackOK(messages, numNewMessages);
  }
};

export let outlookGetMessagesTiming = (callbackOK, callbackKO) => {
  //console.log("LOGIN SILENT...");
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookGetMessages_(token, callbackOK, callbackKO);
    },
    () => {
      callbackKO();
    }
  );
};

export let outlookGetMessages = (callbackOK, callbackKO) => {
  //console.log("LOGIN SILENT...");
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookGetMessages_(token, callbackOK, callbackKO);
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookGetMessages_(token, callbackOK, callbackKO);
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};

export let outlookGetMessagesOLD = async (callbackOK, callbackKO) => {
  //console.log("PRE LOGIN");
  /*outlookLogin(
    async () => {
      console.log("LOGIN");*/
  try {
    let token = getOutlookToken();
    if (token === "") {
      callbackKO();
    } else {
      //console.log("BIEN1");
      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      var options = {
        method: "GET",
        headers: headers,
      };
      var graphEndpoint = ENDPOINT_EMAILS;

      const resp = await fetch(graphEndpoint, options);
      if (resp.status === 401) {
        //alert("401");
        //console.log(resp.status);
        //setOutlookToken("");
        callbackKO();
        return;
      }
      const data = await resp.json();

      if (data.error) {
        console.log(data.error);
        callbackKO();
      } else {
        const messages = data.value.map((message) => {
          try {
            //console.log(message);
            let datetime = message.receivedDateTime;
            datetime = datetime.replace("T", " ");
            datetime = datetime.replace("Z", "");
            const datetimeM = moment(datetime, "YYYY-MM-DD HH:mm:ss");
            const datetimeL = new Date(
              Date.UTC(
                datetimeM.format("YYYY"),
                (parseInt(datetimeM.format("MM")) - 1).toString(),
                datetimeM.format("DD"),
                datetimeM.format("HH"),
                datetimeM.format("mm"),
                datetimeM.format("ss")
              )
            );
            datetime = moment(datetimeL.toString());
            return {
              id: message.id,
              from: message.from.emailAddress.name,
              subject: message.subject ? message.subject : "(No subject)",
              new: !message.isRead,
              datetime,
            };
          } catch (e) {
            console.log(e);
          }
        });

        const numNewMessages = messages.reduce((acc, curr) => {
          if (curr.new) {
            acc++;
          }
          return acc;
        }, 0);

        callbackOK(messages, numNewMessages);
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
  /*},
    () => {
      callbackKO();
    }
  );*/
};

export let outlookGetCalendar = async (from, to, callbackOK, callbackKO) => {
  /*outlookLogin(
    async () => {*/
  try {
    const token = getOutlookToken();
    if (token === "") {
      callbackKO();
    } else {
      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      var options = {
        method: "GET",
        headers: headers,
      };

      var graphEndpoint = ENDPOINT_CALENDARS;

      const resp = await fetch(graphEndpoint, options);
      if (resp.status === 401) {
        //console.log(resp.status);
        //setOutlookToken("");
        callbackKO();
        return;
      }
      const data = await resp.json();
      if (data.error) {
        console.log(data.error);
        callbackKO();
      } else {
        //console.log(data.value);
        const calendars = data.value.map((calendar) => {
          return calendar.id;
        });
        //console.log(calendars);
        let events = [];
        for (let i = 0; i < calendars.length; i++) {
          const calendarId = calendars[i];
          await outlookGetCalendarEvents(
            calendarId,
            from,
            to,
            (calEvents) => {
              if (calEvents.length > 0) {
                events = events.concat(calEvents);
              }
            },
            () => {
              callbackKO();
            }
          );
        }

        //console.log(events);

        const eventsAllDay = events.filter((event) => {
          return event.isAllday;
        });

        let eventsNotAllDay = events.filter((event) => {
          return !event.isAllday;
        });
        eventsNotAllDay.sort((a, b) => {
          if (a.startTime > b.startTime) {
            return 1;
          } else {
            if (a.startTime < b.startTime) {
              return -1;
            } else {
              return 0;
            }
          }
        });

        events = eventsAllDay;
        events = events.concat(eventsNotAllDay);

        callbackOK(events);
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
  /*},
    () => {
      callbackKO();
    }
  );*/
};

let outlookGetCalendarEventsDashboard_ = async (
  idCalendar,
  outlookCalendarType,
  from,
  to,
  token,
  callbackOK,
  callbackKO
) => {
  try {
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    headers.append("Prefer", 'outlook.timezone="Europe/Paris"');
    var options = {
      method: "GET",
      headers: headers,
    };

    const fromUTC = new Date(from + " 00:00:01").toISOString();
    const toUTC = new Date(to + " 23:59:59").toISOString();

    let graphEndpoint =
      outlookCalendarType === "calendar"
        ? ENDPOINT_CALENDAR.replace("#CALENDARID#", idCalendar)
        : ENDPOINT_GROUP.replace("#ID#", idCalendar);

    graphEndpoint =
      graphEndpoint +
      "?startDateTime=" +
      fromUTC +
      "&endDateTime=" +
      toUTC +
      "&top=" +
      MAX_EVENTS;

    let resp = await fetch(graphEndpoint, options);
    let data = await resp.json();
    if (data.error) {
      console.log(data.error);
      callbackKO();
    } else {
      //console.log(data.value);
      const events = data.value.map((event) => {
        //console.log(event);

        try {
          const isAllday = event.isAllDay;

          let tokens = event.start.dateTime.split(".");
          const startDateTime = tokens[0];
          tokens = event.end.dateTime.split(".");
          const endDateTime = tokens[0];

          const type = event.categories.length === 0 ? "" : event.categories[0];

          const users = event.attendees.map((user) => {
            return {
              name: user.emailAddress.name,
              email: user.emailAddress.address,
            };
          });

          return {
            id: event.id,
            isAllday,
            startDateTime,
            endDateTime,
            subject: event.subject,
            description: event.bodyPreview,
            location: event.location.displayName,
            users,
            type,
            idCalendar,
            outlookCalendarType,
          };
        } catch (e) {
          console.log(e);
          callbackKO();
        }
      });

      //console.log(events);

      callbackOK(events);
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
};

export let outlookGetCalendarEventsDashboard = (
  idCalendar,
  outlookCalendarType,
  from,
  to,
  callbackOK,
  callbackKO
) => {
  //console.log("LOGIN SILENT...");
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookGetCalendarEventsDashboard_(
        idCalendar,
        outlookCalendarType,
        from,
        to,
        token,
        callbackOK,
        callbackKO
      );
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookGetCalendarEventsDashboard_(
            idCalendar,
            outlookCalendarType,
            from,
            to,
            token,
            callbackOK,
            callbackKO
          );
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};

export let outlookGetCalendarEvents = async (
  idCalendar,
  outlookCalendarType,
  from,
  to,
  callbackOK,
  callbackKO
) => {
  try {
    const token = getOutlookToken();
    if (token === "") {
      callbackKO();
    } else {
      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      headers.append("Prefer", 'outlook.timezone="Europe/Paris"');
      var options = {
        method: "GET",
        headers: headers,
      };

      const fromUTC = new Date(from + " 00:00:01").toISOString();
      const toUTC = new Date(to + " 23:59:59").toISOString();

      let graphEndpoint =
        outlookCalendarType === "calendar"
          ? ENDPOINT_CALENDAR.replace("#CALENDARID#", idCalendar)
          : ENDPOINT_GROUP.replace("#ID#", idCalendar);

      graphEndpoint =
        graphEndpoint +
        "?startDateTime=" +
        fromUTC +
        "&endDateTime=" +
        toUTC +
        "&top=" +
        MAX_EVENTS;

      let resp = await fetch(graphEndpoint, options);
      if (resp.status === 401) {
        //console.log(resp.status);
        //setOutlookToken("");
        callbackKO();
        return;
      }
      let data = await resp.json();
      if (data.error) {
        console.log(data.error);
        callbackKO();
      } else {
        //console.log(data.value);
        const events = data.value.map((event) => {
          //console.log(event);

          try {
            const isAllday = event.isAllDay;

            let tokens = event.start.dateTime.split(".");
            const startDateTime = tokens[0];
            tokens = event.end.dateTime.split(".");
            const endDateTime = tokens[0];

            const type =
              event.categories.length === 0 ? "" : event.categories[0];

            const users = event.attendees.map((user) => {
              return {
                name: user.emailAddress.name,
                email: user.emailAddress.address,
              };
            });

            return {
              id: event.id,
              isAllday,
              startDateTime,
              endDateTime,
              subject: event.subject,
              description: event.bodyPreview,
              location: event.location.displayName,
              users,
              type,
              idCalendar,
              outlookCalendarType,
            };
          } catch (e) {
            console.log(e);
            callbackKO();
          }
        });

        //console.log(events);

        callbackOK(events);
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
};

let outlookGetCalendarsDashboard_ = async (token, callbackOK, callbackKO) => {
  /*outlookLogin(
    async () => {*/
  let calendars = [];
  let outlookCalendars = [];
  let outlookGroups = [];
  // User ID

  var headers = new Headers();
  var bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  var options = {
    method: "GET",
    headers: headers,
  };

  var graphEndpoint = ENDPOINT_ME;

  let resp = await fetch(graphEndpoint, options);
  let data = await resp.json();
  if (data.error) {
    console.log(data.error);
    callbackKO();
  } else {
    const userId = data.id;

    // Calendars

    headers = new Headers();
    bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    options = {
      method: "GET",
      headers: headers,
    };

    graphEndpoint = ENDPOINT_CALENDARS;

    resp = await fetch(graphEndpoint, options);
    if (resp.status === 401) {
      //console.log(resp.status);
      //setOutlookToken("");
      callbackKO();
      return;
    }
    data = await resp.json();

    if (data.error) {
      console.log(data.error);
      callbackKO();
    } else {
      //console.log(data.value);
      outlookCalendars = data.value.map((calendar) => {
        return {
          id: calendar.id,
          name: calendar.name,
          outlookCalendarType: "calendar",
          additionalMail: "",
        };
      });
    }

    // Groups

    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers,
    };

    var graphEndpoint = ENDPOINT_GROUPS;
    graphEndpoint = graphEndpoint.replace("#USERID#", userId);

    resp = await fetch(graphEndpoint, options);
    data = await resp.json();
    if (data.error) {
      console.log(data.error);
      callbackKO();
    } else {
      //console.log(data.value);
      outlookGroups = data.value.reduce((acc, curr) => {
        if (curr.createdDateTime) {
          acc.push({
            id: curr.id,
            name: curr.displayName,
            outlookCalendarType: "group",
            additionalMail: curr.mail,
          });
        }
        return acc;
      }, []);

      calendars = outlookCalendars.concat(outlookGroups);
      calendars.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      //console.log(calendars);

      callbackOK(calendars);
    }
  }
};

export let outlookGetCalendarsDashboard = (callbackOK, callbackKO) => {
  //console.log("LOGIN SILENT...");
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookGetCalendarsDashboard_(token, callbackOK, callbackKO);
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookGetCalendarsDashboard_(token, callbackOK, callbackKO);
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};

export const changeStatusPlannerTask = async (task, newStatus) => {
  
  try {
    const token = await outlookLoginSilent2();
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("If-Match", task.etag);

    let percentComplete = 0;
    switch (newStatus) {
      case "todo":
        percentComplete = 0;
        break;
      case "inprogress":
        percentComplete = 50;
        break;
      case "done":
        percentComplete = 100;
        break;
    }

    let options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({
        percentComplete,
      }),
    };

    let graphEndpoint = ENDPOINT_TASK_EDITION.replace("#TASKID#", task.id);
    await fetch(graphEndpoint, options);
  
  } catch (err) {
    console.log("Error!!");
  }
};

const getPlannerLink = (groupId, planId) => {
  const link = PLANNER_LINK;
  let plannerLink = link.replace("#GROUPID#", groupId);
  return plannerLink.replace("#PLANID#", planId);
}

export const getCalendarEvents = async () => {

  try {
    const token = await outlookLoginSilent2();

    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };

    const res = await fetch(CALENDAR_EVENTS, options);
    const obj = await res.json();
    let tasks = obj.value;

    return tasks.map((e) => {
      return {
        id: e.id,
        createdAt: e.createdDateTime,
        subject: e.subject,
        webLink: e.webLink,
        start: e.start.dateTime,
        end: e.end.dateTime,
        response: e.responseStatus.response
      }
    });
    
  } catch (err) {
    console.log("Error " + err);
    return -1;
  }
};

export const getAllMyTasks = async () => {

  try {
    const token = await outlookLoginSilent2();

    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };

    const res = await fetch(ALL_MY_TASKS, options);
    const obj = await res.json();
    let tasks = obj.value;

    tasks = tasks.reduce((a, e) => {
      const t = {
        id: e.id,
        planId: e.planId,
        bucketId: e.bucketId,
        title: e.title,
        etag: e['@odata.etag']
      };

      if (e.percentComplete === 0) {
        a.todo.push(t);
      } else if (e.percentComplete !== 0 && e.percentComplete !== 100) {
        a.inProgress.push(t);
      } else if (e.percentComplete === 100) {
        a.done.push(t);
      }

      return a;
    }, {
      todo: [],
      inProgress: [],
      done: []
    });
    
    return tasks;
    
  } catch (err) {
    console.log("Error " + err);
    return -1;
  }
};

export const getMyPersonalPlanner = async (userName) => {

  try {
    const token = await outlookLoginSilent2();

    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers
    };

    let res = await fetch(ALL_MY_GROUPS, options);
    let obj = await res.json();

    const userNameParts = userName.split("@");
    
    const myPlan = obj['value'].find(e => {
      const desc = e.displayName;
      if (desc !== null && desc !== undefined && desc !== '') {
        return desc.toLowerCase() === userNameParts[0].toLowerCase();
      }
    });

    if (myPlan === undefined) {
      return {id: -2, owner: -2};
    } else {
      const endp = GROUP_PLANS.replace('#GROUPID#', myPlan.id);
      res = await fetch(endp, options);
      obj = await res.json();

      return {id: obj.value[0].id, owner: obj.value[0].owner}
    }

  } catch (err) {
    console.log('Error: ' + err);
    return {id: -1, owner: -1};
  }
}

export const getTasksFromPlanner = async (groupOwner, groupId) => {
  try {
    const token = await outlookLoginSilent2();

    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers
    };

    const endp = ENDPOINT_TASKS.replace('#PLANID#', groupId);
    let res = await fetch(endp, options);
    let tasks = await res.json();

    const link = getPlannerLink(groupOwner, groupId);
    
    tasks = tasks.value.reduce((a, e) => {
      const t = {
        id: e.id,
        planId: e.planId,
        bucketId: e.bucketId,
        title: e.title,
        etag: e['@odata.etag']
      };

      if (e.percentComplete === 0) {
        a.todo.push(t);
      } else if (e.percentComplete !== 0 && e.percentComplete !== 100) {
        a.inProgress.push(t);
      } else if (e.percentComplete === 100) {
        a.done.push(t);
      }

      return a;
    }, {
      todo: [],
      inProgress: [],
      done: []
    });

    tasks.link = link;
    
    return tasks;

  } catch (err) {
    console.log("Error " + err);
    return -1;
  }
};

export const getMyPlanners = async () => {

  let myGroups = [];
  const token = await outlookLoginSilent2();

  const headers = new Headers();
  const bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers
  };

  try {
    const res = await fetch(ALL_MY_GROUPS, options);
    const obj = await res.json();
    myGroups = obj['value'].map(e => e.id);
  } catch (err) {
    console.log('Error: ' + err);
    return {id: -1, owner: -1};
  }
  
  if (myGroups.length > 0) {
    let planners = [];
    let promises = [];
    myGroups.forEach((e) => {
      const endp = GROUP_PLANS.replace('#GROUPID#', e);
      promises.push(fetch(endp, options));
    });

    for (var i = 0; i < promises.length; i++) {
      const res = await promises[i];
      const jsonRes = await res.json()
      
      for (var j = 0; j < jsonRes['value'].length; j++) {
        planners.push( {id:jsonRes['value'][j].id, name:jsonRes['value'][j].title, owner: jsonRes['value'][j].owner} );
      }
    }

    return planners;
  } else {
    return {id: -2, owner: -2};
  }
}

export const getAllMyPersonalTasks = async (myPersonalPlanner) => {

  try {
    const token = await outlookLoginSilent2();

    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };

    const endp = ENDPOINT_TASKS.replace("#PLANID#", myPersonalPlanner.id);
    const res = await fetch(endp, options);
    let tasks = await res.json();

    const link = getPlannerLink(myPersonalPlanner.owner, myPersonalPlanner.id);

    tasks = tasks.value.reduce((a, e) => {
      const t = {
        id: e.id,
        planId: e.planId,
        bucketId: e.bucketId,
        title: e.title,
        etag: e['@odata.etag']
      };

      if (e.percentComplete === 0) {
        a.todo.push(t);
      } else if (e.percentComplete !== 0 && e.percentComplete !== 100) {
        a.inProgress.push(t);
      } else if (e.percentComplete === 100) {
        a.done.push(t);
      }

      return a;
    }, {
      todo: [],
      inProgress: [],
      done: []
    });

    tasks.link = link;
    
    return tasks;
    
  } catch (err) {
    console.log("Error " + err);
    return -1;
  }
};

export let outlookGetCalendars = async (callbackOK, callbackKO) => {
  /*outlookLogin(
    async () => {*/
  let calendars = [];
  let outlookCalendars = [];
  let outlookGroups = [];
  try {
    const token = getOutlookToken();
    if (token === "") {
      callbackKO();
    } else {
      // User ID

      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      var options = {
        method: "GET",
        headers: headers,
      };

      var graphEndpoint = ENDPOINT_ME;

      let resp = await fetch(graphEndpoint, options);
      if (resp.status === 401) {
        //console.log(resp.status);
        //setOutlookToken("");
        callbackKO();
        return;
      }
      let data = await resp.json();
      if (data.error) {
        console.log(data.error);
        callbackKO();
      } else {
        const userId = data.id;

        // Calendars

        headers = new Headers();
        bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
        options = {
          method: "GET",
          headers: headers,
        };

        graphEndpoint = ENDPOINT_CALENDARS;

        resp = await fetch(graphEndpoint, options);
        if (resp.status === 401) {
          //console.log(resp.status);
          //setOutlookToken("");
          callbackKO();
          return;
        }
        data = await resp.json();

        if (data.error) {
          console.log(data.error);
          callbackKO();
        } else {
          //console.log(data.value);
          outlookCalendars = data.value.map((calendar) => {
            return {
              id: calendar.id,
              name: calendar.name,
              outlookCalendarType: "calendar",
              additionalMail: "",
            };
          });
        }

        // Groups

        var headers = new Headers();
        var bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
        var options = {
          method: "GET",
          headers: headers,
        };

        var graphEndpoint = ENDPOINT_GROUPS;
        graphEndpoint = graphEndpoint.replace("#USERID#", userId);

        resp = await fetch(graphEndpoint, options);
        if (resp.status === 401) {
          //console.log(resp.status);
          //setOutlookToken("");
          callbackKO();
          return;
        }
        data = await resp.json();
        if (data.error) {
          console.log(data.error);
          callbackKO();
        } else {
          //console.log(data.value);
          outlookGroups = data.value.reduce((acc, curr) => {
            if (curr.createdDateTime) {
              acc.push({
                id: curr.id,
                name: curr.displayName,
                outlookCalendarType: "group",
                additionalMail: curr.mail,
              });
            }
            return acc;
          }, []);

          calendars = outlookCalendars.concat(outlookGroups);
          calendars.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          //console.log(calendars);

          callbackOK(calendars);
        }
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
  /*},
    () => {
      callbackKO();
    }
  );*/
};

/*export let outlookUserId = async (callbackOK, callbackKO) => {
  try {
    const token = getOutlookToken();
    if (token === "") {
      callbackKO();
    } else {
      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      var options = {
        method: "GET",
        headers: headers,
      };

      var graphEndpoint = ENDPOINT_USER;

      const resp = await fetch(graphEndpoint, options);
      const data = await resp.json();
      if (data.error) {
        console.log(data.error);
        callbackKO();
      } else {
        console.log(data);

        callbackOK(data.id);
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
};*/

export let outlookCreateEvent = async (
  idCalendar,
  outlookCalendarType,
  subject,
  description,
  startDateTime,
  endDateTime,
  location,
  isAllDay,
  users,
  type,
  callbackOK,
  callbackKO
) => {
  /*outlookLogin(
    async () => {*/
  try {
    const token = getOutlookToken();

    if (token === "") {
      callbackKO();
    } else {
      let attendees = [];
      if (users) {
        attendees = users.map((user) => {
          return {
            emailAddress: {
              address: user.email,
              name: user.name,
            },
            type: "required",
          };
        });
      }

      let startDT = "";
      let endDT = "";
      if (isAllDay) {
        const day = moment(startDateTime, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DD"
        );
        const next = moment(startDateTime, "YYYY-MM-DD HH:mm:ss")
          .add(1, "days")
          .format("YYYY-MM-DD");
        startDT = day + "T00:00:00";
        endDT = next + "T00:00:00";
      } else {
        startDT = startDateTime.replace(/ /g, "T");
        endDT = endDateTime.replace(/ /g, "T");
      }

      const event = {
        subject,
        body: {
          contentType: "HTML",
          content: description,
        },
        start: {
          dateTime: startDT,
          timeZone: "Europe/Paris",
        },
        end: {
          dateTime: endDT,
          timeZone: "Europe/Paris",
        },
        location: {
          displayName: location,
        },
        attendees,
        isAllDay,
        categories: [type],
      };

      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      headers.append("Content-Type", "application/json");
      var options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(event),
      };

      var graphEndpoint =
        outlookCalendarType === "calendar"
          ? ENDPOINT_EVENT_CREATION.replace(/#ID#/g, idCalendar)
          : ENDPOINT_GROUP_EVENT_CREATION.replace(/#ID#/g, idCalendar);

      const resp = await fetch(graphEndpoint, options);
      if (resp.status === 401) {
        //console.log(resp.status);
        //setOutlookToken("");
        callbackKO();
        return;
      }
      const data = await resp.json();
      //console.log(data);
      if (data.id) {
        //console.log(data.id);

        callbackOK(data.id);
      } else {
        callbackKO(
          "There was an error when creating the event. Do you have enough permissions?"
        );
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
  /*},
    () => {
      callbackKO();
    }
  );*/
};

export let outlookEditEvent = async (
  idEvent,
  idCalendar,
  outlookCalendarType,
  subject,
  description,
  startDateTime,
  endDateTime,
  location,
  isAllDay,
  users,
  callbackOK,
  callbackKO
) => {
  /*outlookLogin(
    async () => {*/
  try {
    const token = getOutlookToken();

    if (token === "") {
      callbackKO();
    } else {
      let attendees = [];
      if (users) {
        attendees = users.map((user) => {
          return {
            emailAddress: {
              address: user.email,
              name: user.name,
            },
            type: "required",
          };
        });
      }

      let startDT = "";
      let endDT = "";
      if (isAllDay) {
        const day = moment(startDateTime, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DD"
        );
        const next = moment(startDateTime, "YYYY-MM-DD HH:mm:ss")
          .add(1, "days")
          .format("YYYY-MM-DD");
        startDT = day + "T00:00:00";
        endDT = next + "T00:00:00";
      } else {
        startDT = startDateTime.replace(/ /g, "T");
        endDT = endDateTime.replace(/ /g, "T");
      }

      const event = {
        subject,
        body: {
          contentType: "HTML",
          content: description,
        },
        start: {
          dateTime: startDT,
          timeZone: "Europe/Paris",
        },
        end: {
          dateTime: endDT,
          timeZone: "Europe/Paris",
        },
        location: {
          displayName: location,
        },
        attendees,
        isAllDay,
      };

      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      headers.append("Content-Type", "application/json");
      var options = {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(event),
      };

      var graphEndpoint =
        outlookCalendarType === "calendar"
          ? ENDPOINT_EVENT_EDITION.replace(/#ID#/g, idEvent)
          : ENDPOINT_GROUP_EVENT_EDITION.replace(
              /#CALENDARID#/g,
              idCalendar
            ).replace(/#ID#/g, idEvent);

      const resp = await fetch(graphEndpoint, options);
      if (resp.status === 401) {
        //console.log(resp.status);
        //setOutlookToken("");
        callbackKO();
        return;
      }
      const data = await resp.json();
      //console.log(data);
      if (data.id) {
        //console.log(data.id);

        callbackOK(data.id);
      } else {
        callbackKO(
          "There was an error when editing the event. Do you have enough permissions?"
        );
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
  /*},
    () => {
      callbackKO();
    }
  );*/
};

export let outlookDeleteEvent = async (
  idEvent,
  idCalendar,
  outlookCalendarType,
  callbackOK,
  callbackKO
) => {
  /*outlookLogin(
    async () => {*/
  try {
    const token = getOutlookToken();

    if (token === "") {
      callbackKO();
    } else {
      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      headers.append("Content-Type", "application/json");
      var options = {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(event),
      };

      var graphEndpoint =
        outlookCalendarType === "calendar"
          ? ENDPOINT_EVENT_DELETION.replace(/#ID#/g, idEvent)
          : ENDPOINT_GROUP_EVENT_DELETION.replace(
              /#CALENDARID#/g,
              idCalendar
            ).replace(/#ID#/g, idEvent);

      const response = await fetch(graphEndpoint, options);
      if (response.status !== 200 && response.status !== 204) {
        callbackKO(
          "There was an error when deleting the event. Do you have enough permissions?"
        );
      } else {
        callbackOK();
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO(e);
  }
  /*},
    () => {
      callbackKO();
    }
  );*/
};

export let outlookCreateCalendar = async (
  calendarName,
  callbackOK,
  callbackKO
) => {
  /*outlookLogin(
    async () => {*/
  try {
    const token = getOutlookToken();

    if (token === "") {
      callbackKO();
    } else {
      const calendar = {
        name: calendarName,
      };

      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      headers.append("Content-Type", "application/json");
      var options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(calendar),
      };

      var graphEndpoint = ENDPOINT_CALENDAR_CREATION;

      const resp = await fetch(graphEndpoint, options);
      if (resp.status === 401) {
        //console.log(resp.status);
        //setOutlookToken("");
        callbackKO();
        return;
      }
      const data = await resp.json();
      //console.log(data);
      if (data.id) {
        //console.log(data.id);

        callbackOK(data.id);
      } else {
        callbackKO();
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
  /*},
    () => {
      callbackKO();
    }
  );*/
};

export let outlookGetTasks = async (projectName, callbackOK, callbackKO) => {
  
  let userTasks = [];
  let buckets = [];
  let plannerLink = "";

  try {
    const token = getOutlookToken();
    if (token === "") {
      callbackKO();
    } else {
      // User ID

      const headers = new Headers();
      const bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers,
      };

      let graphEndpoint = ENDPOINT_ME;

      let resp = await fetch(graphEndpoint, options);
      if (resp.status === 401) {
        //console.log(resp.status);
        //setOutlookToken("");
        callbackKO();
        return;
      }
      let data = await resp.json();
      if (data.error) {
        console.log(data.error);
        callbackKO();
      } else {
        const userId = data.id;

        graphEndpoint = ENDPOINT_GROUPS;
        graphEndpoint = graphEndpoint.replace("#USERID#", userId);

        resp = await fetch(graphEndpoint, options);
        if (resp.status === 401) {
          //console.log(resp.status);
          //setOutlookToken("");
          callbackKO();
          return;
        }
        data = await resp.json();
        //console.log("GROUPS", data);
        if (data.error) {
          console.log(data.error);
          callbackKO();
        } else {
          const outlookGroups = data.value.reduce((acc, curr) => {
            if (curr.createdDateTime) {
              acc.push({
                id: curr.id,
                name: curr.displayName,
                outlookCalendarType: "group",
                additionalMail: curr.mail,
              });
            }
            return acc;
          }, []);

          const projectGroup = outlookGroups.find((item) => {
            return item.name === projectName;
          });

          if (projectGroup) {

            graphEndpoint = ENDPOINT_PLANS;
            graphEndpoint = graphEndpoint.replace("#GROUPID#", projectGroup.id);

            resp = await fetch(graphEndpoint, options);
            if (resp.status === 401) {
              //console.log(resp.status);
              //setOutlookToken("");
              callbackKO();
              return;
            }
            data = await resp.json();

            if (data.error) {
              console.log(data.error);
              callbackKO();
            } else {
              // console.log(data.value);

              const plan = data.value.find((item) => {
                return item.title === projectName;
              });

              if (plan) {
                plannerLink = getPlannerLink(projectGroup.id, plan.id);
                
                graphEndpoint = ENDPOINT_BUCKETS;
                graphEndpoint = graphEndpoint.replace("#PLANID#", plan.id);
                resp = await fetch(graphEndpoint, options);
                if (resp.status === 401) {
                  //console.log(resp.status);
                  //setOutlookToken("");
                  callbackKO();
                  return;
                }
                data = await resp.json();

                buckets = data.value.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                  };
                });
  
                graphEndpoint = ENDPOINT_TASKS;
                graphEndpoint = graphEndpoint.replace("#PLANID#", plan.id);
                resp = await fetch(graphEndpoint, options);
                if (resp.status === 401) {
                  //console.log(resp.status);
                  //setOutlookToken("");
                  callbackKO();
                  return;
                }
                data = await resp.json();

                /*console.log("TASKS", data);
                console.log(data.value.length);*/

                if (!data.error) {
                  let tasks = [];
                  for (let i = 0; i < data.value.length; i++) {
                    const item = data.value[i];
                    //console.log(item);
                    let start = item.startDateTime
                      ? item.startDateTime.replace("Z", "").replace("T", " ")
                      : null;
                    const end = item.dueDateTime
                      ? item.dueDateTime.replace("Z", "").replace("T", " ")
                      : null;

                    if (!start && end) {
                      start = end;
                    }

                    const created = item.createdDateTime
                      .split(".")[0]
                      .replace("Z", "")
                      .replace("T", " ");

                    const users = Object.keys(item.assignments);
                    users.forEach((user) => {
                      const pos = userTasks.findIndex((ut) => {
                        return ut.id === user;
                      });
                      if (pos === -1) {
                        userTasks.push({
                          id: user,
                          email: "",
                        });
                      }
                    });
                    const userList = users.map((user) => {
                      return {
                        id: user,
                        email: "",
                      };
                    });

                    let status = "inprogress";
                    if (item.percentComplete === 0) {
                      status = "todo";
                    } else {
                      if (item.percentComplete === 100) {
                        status = "done";
                      }
                    }

                    const bucket = buckets.find((b) => {
                      return b.id === item.bucketId;
                    });

                    let notes = "";
                    if (item.hasDescription) {
                      graphEndpoint =
                        "https://graph.microsoft.com/v1.0/planner/tasks/" +
                        item.id +
                        "/details";
                      graphEndpoint = graphEndpoint.replace(
                        "#PLANID#",
                        plan.id
                      );
                      const r = await fetch(graphEndpoint, options);
                      const d = await r.json();
                      //console.log("RESPONSE", d);
                      notes = d.description;
                    }

                    tasks.push({
                      id: item.id,
                      name: item.title,
                      start,
                      end,
                      created,
                      users: userList,
                      status,
                      bucket: bucket ? bucket.name : "",
                      etag: item["@odata.etag"],
                      notes,
                    });
                  }

                  // Users

                  for (let i = 0; i < userTasks.length; i++) {
                    let user = userTasks[i];
                    
                    graphEndpoint = ENDPOINT_USERS;
                    graphEndpoint = graphEndpoint.replace("#USERID#", user.id);
                    resp = await fetch(graphEndpoint, options);
                    if (resp.status === 401) {
                      //console.log(resp.status);
                      //setOutlookToken("");
                      callbackKO();
                      return;
                    }
                    data = await resp.json();
                    if (!data.error) {
                      user.email = data.mail;
                    }
                  }

                  // Task reassignment

                  tasks.forEach((task) => {
                    task.users.forEach((user) => {
                      const u = userTasks.find((item) => {
                        return item.id === user.id;
                      });
                      if (u) {
                        user.email = u.email;
                      }
                    });
                  });

                  userTasks.forEach((user) => {
                    user.tasks = {
                      toDo: [],
                      done: [],
                      inProgress: [],
                    };
                  });

                  tasks.forEach((task) => {
                    task.users.forEach((user) => {
                      let u = userTasks.find((item) => {
                        return item.id === user.id;
                      });
                      if (u) {
                        switch (task.status) {
                          case "todo":
                            u.tasks.toDo.push(task);
                            break;
                          case "inprogress":
                            u.tasks.inProgress.push(task);
                            break;
                          case "done":
                            u.tasks.done.push(task);
                            break;
                        }
                      }
                    });
                  });

                  userTasks.forEach((user) => {
                    let tasksDate = user.tasks.toDo.filter((task) => {
                      return task.end && task.end !== "";
                    });
                    let tasksNoDate = user.tasks.toDo.filter((task) => {
                      return !task.end || task.end === "";
                    });
                    tasksDate.sort((a, b) => {
                      if (a.end < b.end) {
                        return -1;
                      }
                      if (a.end > b.end) {
                        return 1;
                      }
                      return 0;
                    });
                    user.tasks.toDo = tasksDate.concat(tasksNoDate);

                    tasksDate = user.tasks.inProgress.filter((task) => {
                      return task.end && task.end !== "";
                    });
                    tasksNoDate = user.tasks.inProgress.filter((task) => {
                      return !task.end || task.end === "";
                    });
                    tasksDate.sort((a, b) => {
                      if (a.end < b.end) {
                        return -1;
                      }
                      if (a.end > b.end) {
                        return 1;
                      }
                      return 0;
                    });
                    user.tasks.inProgress = tasksDate.concat(tasksNoDate);

                    tasksDate = user.tasks.done.filter((task) => {
                      return task.end && task.end !== "";
                    });
                    tasksNoDate = user.tasks.done.filter((task) => {
                      return !task.end || task.end === "";
                    });
                    tasksDate.sort((a, b) => {
                      if (a.end < b.end) {
                        return -1;
                      }
                      if (a.end > b.end) {
                        return 1;
                      }
                      return 0;
                    });
                    user.tasks.done = tasksDate.concat(tasksNoDate);
                  });

                  userTasks = userTasks.map((ut) => {
                    return {
                      email: ut.email,
                      tasks: ut.tasks,
                    };
                  });

                  //console.log("USERS", userTasks);
                }
              }
            }
          }

          callbackOK(userTasks, plannerLink);
        }
      }
    }
  } catch (e) {
    console.log(e);
    callbackKO();
  }
  /*},
    () => {
      callbackKO();
    }
  );*/
};

let outlookChangeTaskStatus_ = async (
  token,
  task,
  newStatus,
  callbackOK,
  callbackKO
) => {
  var headers = new Headers();
  var bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  headers.append("If-Match", task.etag);

  let percentComplete = 0;
  switch (newStatus) {
    case "todo":
      percentComplete = 0;
      break;
    case "inprogress":
      percentComplete = 50;
      break;
    case "done":
      percentComplete = 100;
      break;
  }

  var options = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify({
      percentComplete,
    }),
  };

  var graphEndpoint = ENDPOINT_TASK_EDITION;
  graphEndpoint = graphEndpoint.replace("#TASKID#", task.id);

  let resp = await fetch(graphEndpoint, options);
  //console.log(resp);

  callbackOK();
};

export let outlookChangeTaskStatus = (
  task,
  newStatus,
  callbackOK,
  callbackKO
) => {
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookChangeTaskStatus_(token, task, newStatus, callbackOK, callbackKO);
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookChangeTaskStatus_(
            token,
            taskId,
            newStatus,
            callbackOK,
            callbackKO
          );
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};

let outlookGetTodos_ = async (token, callbackOK, callbackKO) => {
  let todos = [];

  var headers = new Headers();
  var bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  headers.append("Prefer", 'outlook.timezone="Europe/Paris"');
  var options = {
    method: "GET",
    headers: headers,
  };

  var graphEndpoint = ENDPOINT_TODO_LISTS;

  let resp = await fetch(graphEndpoint, options);
  let data = await resp.json();
  if (data.error) {
    console.log(data.error);
    callbackKO();
  } else {
    //console.log(data);

    const lists = data.value.map((item) => {
      return item.id;
    });
    //console.log(lists);

    for (let i = 0; i < lists.length; i++) {
      const list = lists[i];

      graphEndpoint = ENDPOINT_TODO_TASKS.replace("#LISTID#", list);

      resp = await fetch(graphEndpoint, options);
      data = await resp.json();

      //console.log(data);

      let tasks = data.value.map((item) => {
        let dateTime = "";
        if (item.dueDateTime) {
          const tokens = item.dueDateTime.dateTime.split("T");
          const date = tokens[0];
          const time = tokens[1].split(".")[0];
          dateTime = date + " " + time;
        }
        //console.log(item);
        return {
          id: item.id,
          listId: list,
          title: item.title,
          completed: item.status === "completed",
          dateTime,
          notes: item.body.content,
        };
      });

      todos = todos.concat(tasks);
    }

    if (todos.length > 0) {
      const todosNoDateTime = todos.filter((item) => {
        return item.dateTime === "";
      });

      let todosDateTime = todos.filter((item) => {
        return item.dateTime !== "";
      });

      todosDateTime.sort((a, b) => {
        if (a.dateTime < b.dateTime) {
          return -1;
        }
        if (a.dateTime > b.dateTime) {
          return 1;
        }
        return 0;
      });
      todosDateTime = todosDateTime.reverse();

      todos = todosDateTime.concat(todosNoDateTime);
    }

    callbackOK(todos);
  }
};

export let outlookGetTodos = (callbackOK, callbackKO) => {
  //console.log("LOGIN SILENT...");
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookGetTodos_(token, callbackOK, callbackKO);
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookGetTodos_(token, callbackOK, callbackKO);
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};

let outlookCompleteTask_ = async (token, task, callbackOK, callbackKO) => {
  var headers = new Headers();
  var bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  var options = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify({
      status: "completed",
    }),
  };

  var graphEndpoint = ENDPOINT_TODO_TASK_EDITION;
  graphEndpoint = graphEndpoint.replace("#LISTID#", task.listId);
  graphEndpoint = graphEndpoint.replace("#TASKID#", task.id);

  let resp = await fetch(graphEndpoint, options);

  callbackOK();
};

export let outlookCompleteTask = (task, callbackOK, callbackKO) => {
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookCompleteTask_(token, task, callbackOK, callbackKO);
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookCompleteTask_(token, task, callbackOK, callbackKO);
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};

let outlookUncompleteTask_ = async (token, task, callbackOK, callbackKO) => {
  var headers = new Headers();
  var bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  var options = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify({
      status: "notStarted",
    }),
  };

  var graphEndpoint = ENDPOINT_TODO_TASK_EDITION;
  graphEndpoint = graphEndpoint.replace("#LISTID#", task.listId);
  graphEndpoint = graphEndpoint.replace("#TASKID#", task.id);

  let resp = await fetch(graphEndpoint, options);

  callbackOK();
};

export let outlookUncompleteTask = (task, callbackOK, callbackKO) => {
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookUncompleteTask_(token, task, callbackOK, callbackKO);
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookUncompleteTask_(token, task, callbackOK, callbackKO);
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};

let outlookCreateTask_ = async (
  token,
  title,
  dateTime,
  notes,
  callbackOK,
  callbackKO
) => {
  var headers = new Headers();
  var bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  var options = {
    method: "GET",
    headers: headers,
  };

  var graphEndpoint = ENDPOINT_TODO_LISTS;

  let resp = await fetch(graphEndpoint, options);
  let data = await resp.json();
  if (data.error) {
    console.log(data.error);
    callbackKO();
  } else {
    //console.log(data);

    if (data.value.length > 0) {
      const list = data.value[0].id;

      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      headers.append("Content-Type", "application/json");
      var options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          title,
          dueDateTime: {
            dateTime,
            timeZone: "Europe/Paris",
          },
          body: {
            content: notes,
            contentType: "text",
          },
        }),
      };

      graphEndpoint = ENDPOINT_TODO_TASKS;
      graphEndpoint = graphEndpoint.replace("#LISTID#", list);

      resp = await fetch(graphEndpoint, options);
      data = await resp.json();
      if (data.error) {
        console.log(data.error);
        callbackKO();
      } else {
        callbackOK(data.id);
      }
    }
  }
};

export let outlookCreateTask = (
  title,
  dateTime,
  notes,
  callbackOK,
  callbackKO
) => {
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookCreateTask_(token, title, dateTime, notes, callbackOK, callbackKO);
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookCreateTask_(
            token,
            title,
            dateTime,
            notes,
            callbackOK,
            callbackKO
          );
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};

let outlookEditTask_ = async (
  token,
  task,
  title,
  dateTime,
  notes,
  callbackOK,
  callbackKO
) => {
  var headers = new Headers();
  var bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  var options = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify({
      id: task.id,
      title,
      dueDateTime: {
        dateTime,
        timeZone: "Europe/Paris",
      },
      body: {
        content: notes,
        contentType: "text",
      },
    }),
  };

  var graphEndpoint = ENDPOINT_TODO_TASK_EDITION;
  graphEndpoint = graphEndpoint.replace("#LISTID#", task.listId);
  graphEndpoint = graphEndpoint.replace("#TASKID#", task.id);

  let resp = await fetch(graphEndpoint, options);

  callbackOK();
};

export let outlookEditTask = (
  task,
  title,
  dateTime,
  notes,
  callbackOK,
  callbackKO
) => {
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookEditTask_(
        token,
        task,
        title,
        dateTime,
        notes,
        callbackOK,
        callbackKO
      );
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookEditTask_(
            token,
            task,
            title,
            dateTime,
            notes,
            callbackOK,
            callbackKO
          );
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};

let outlookDeleteTask_ = async (token, task, callbackOK, callbackKO) => {
  var headers = new Headers();
  var bearer = "Bearer " + token;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  var options = {
    method: "DELETE",
    headers: headers,
    body: JSON.stringify({}),
  };

  var graphEndpoint = ENDPOINT_TODO_TASK_EDITION;
  graphEndpoint = graphEndpoint.replace("#LISTID#", task.listId);
  graphEndpoint = graphEndpoint.replace("#TASKID#", task.id);

  let resp = await fetch(graphEndpoint, options);

  callbackOK();
};

export let outlookDeleteTask = (task, callbackOK, callbackKO) => {
  outlookLoginSilent(
    (token) => {
      //console.log("LOGIN SILENT OK");
      outlookDeleteTask_(token, task, callbackOK, callbackKO);
    },
    () => {
      //console.log("LOGIN SILENT NO OK");
      //console.log("LOGIN POPUP...");
      outlookLoginPopup(
        (token) => {
          //console.log("LOGIN POPUP OK");
          outlookDeleteTask_(token, task, callbackOK, callbackKO);
        },
        () => {
          //console.log("LOGIN POPUP NO OK");
          callbackKO();
        }
      );
    }
  );
};
