<script>
  export let text;
  export let action;
  export let last;
  export let error = false;
</script>

<style>
  span.columnInfo,
  input {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-bottom: 8px;
  }

  span.last,
  input.last {
    margin-bottom: 0px;
  }

  input {
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 14px;
    border-color: var(--labit-card-lightgray);
    border-width: 1px;
    border-style: solid;
    height: 17px;
  }

  span:empty:before {
    content: "\200b";
  }

  input.error {
    border-color: red !important;
  }
</style>

{#if action === 'show'}
  <span class="columnInfo {last ? 'last' : ''}">********</span>
{:else}
  <input
    type="password"
    class="{last ? 'last' : ''}
    {error ? 'error' : ''}"
    bind:value={text} />
{/if}
