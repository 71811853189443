<script>
  export let admin = false;

  import Menu from "../components/Menu.svelte";
  import Combobox from "../components/Combobox.svelte";
  import PeopleDocumentsContact from "../components/PeopleDocumentsContact.svelte";
  /*import FileBoobleContainerDocuments from "../components/FileBoobleContainerDocuments.svelte";*/
  import FileBoobleContainer from "../components/FileBoobleContainer.svelte";
  import PreviewRemoteFile from "../components/PreviewRemoteFile.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  //import ContextMenu from "../components/ContextMenu.svelte";

  import { post, rpost } from "../js/labit-connection";
  import { getPlatform } from "../js/localInfo";
  import { randomString } from "../js/randomString";

  import { onMount } from "svelte";

  const PAGE = "MANAGEMENT";
  import moment from 'moment';

  const platform = getPlatform();

  let documentTypeOptions = admin
    ? [
        {
          value: "Payroll",
          label: "Payroll",
        },
      ]
    : [
        {
          value: "Labor Contract",
          label: "Labor Contract",
        },
        {
          value: "CV",
          label: "CV",
        },
        {
          value: "Absence",
          label: "Absence",
        },
        {
          value: "Others",
          label: "Others",
        },
      ];
  let documentTypeOptionsSel = documentTypeOptions[0];

  let monthOptions = [
    {
      value: "January",
      label: "January",
    },
    {
      value: "February",
      label: "February",
    },
    {
      value: "March",
      label: "March",
    },
    {
      value: "April",
      label: "April",
    },
    {
      value: "May",
      label: "May",
    },
    {
      value: "June",
      label: "June",
    },
    {
      value: "July",
      label: "July",
    },
    {
      value: "August",
      label: "August",
    },
    {
      value: "September",
      label: "September",
    },
    {
      value: "October",
      label: "October",
    },
    {
      value: "November",
      label: "November",
    },
    {
      value: "December",
      label: "December",
    },
  ];
  let monthOptionsSel = {
    value: moment().format("MMMM"),
    label: moment().format("MMMM"),
  };
  let yearOptions = [];
  let yearOptionsSel = null;

  let people = [];
  let peopleSel = -1;
  let peopleSelPath = "";
  let showAllPeople = false;

  let remoteFilePrev = "";

  let showNavMenu = null;

  //$: console.log(peopleSelPath);

  onMount(async () => {
    const endYear = parseInt(moment().format("YYYY")) + 2;
    for (let i = 2015; i <= endYear; i++) {
      const yearS = i.toString();
      const year = {
        value: yearS,
        label: yearS,
      };
      yearOptions.push(year);
    }
    yearOptionsSel = moment().format("YYYY");

    const response = await post(
      `select contact_id, p.Name as contactName, p.active as contactActive
        from people as p
        left join companies as c on p.company_id=c.company_id
        where esLabit=1
        order by contactName asc`
    );
    people = response.map((item) => {
      return {
        id: item.contact_id,
        name: item.contactName,
        active: item.contactActive === "1",
        visible: item.contactActive === "1",
        hasFiles: false,
      };
    });
    peopleSel = 0;
    selectContact(0);

    checkFiles();
  });

  let toggleShowAllPeople = () => {
    showAllPeople = !showAllPeople;
    people.forEach((p) => {
      if (showAllPeople) {
        p.visible = true;
      } else {
        p.visible = p.active;
      }
    });
    people = people;
    peopleSel = -1;
    peopleSelPath = "";
  };

  let createPath = (index) => {
    let path = "";
    if (index >= 0) {
      let p = "";
      switch (documentTypeOptionsSel.value) {
        case "Payroll":
          const m = moment(monthOptionsSel.value, "MMMM").format("MM");
          p = "payroll/" + yearOptionsSel.value + "-" + m;
          break;
        case "CV":
          p = "cv";
          break;
        case "Labor Contract":
          p = "labor-contract";
          break;
        case "Absence":
          p = "absence";
          break;
        case "Others":
          p = "others";
          break;
      }
      path =
        "/people/" + people[index].id + " - " + people[index].name + "/" + p;
    }
    return path;
  };

  let selectContact = (index) => {
    peopleSel = index;
    peopleSelPath = createPath(index);
  };

  let previewFile = (id) => {
    remoteFilePrev = id.toLowerCase().trim().startsWith("http")
      ? id
      : btoa(unescape(encodeURIComponent(id)));
  };

  let checkFiles = async () => {
    for (let i = 0; i < people.length; i++) {
      const path = createPath(i);
      const response = await rpost("ListFolder", { path });
      people[i].hasFiles = response.length > 0;
    }
    people = people;
  };
</script>

<div class="content">
  <!-- Body -->
  <div class="container">
    <div class="leftMenu">
      <div class="leftMenuOption">
        <div class="form">
          <span>Document Type</span>
          <div class="combobox">
            <Combobox
              options={documentTypeOptions}
              placeholder=""
              bind:selectedValue={documentTypeOptionsSel}
              searchable={false}
              onChange={() => {
                selectContact(peopleSel);
                checkFiles();
              }}
            />
          </div>
        </div>
        {#if documentTypeOptionsSel.value === "Payroll"}
          <div class="form payroll">
            <div class="comboboxOpt">
              <span>Month</span>
              <div class="combobox">
                <Combobox
                  options={monthOptions}
                  placeholder=""
                  bind:selectedValue={monthOptionsSel}
                  searchable={false}
                  onChange={() => {
                    selectContact(peopleSel);
                    checkFiles();
                  }}
                />
              </div>
            </div>
            <div class="comboboxOpt">
              <span>Year</span>
              <div class="combobox">
                <Combobox
                  options={yearOptions}
                  placeholder=""
                  bind:selectedValue={yearOptionsSel}
                  searchable={false}
                  onChange={() => {
                    selectContact(peopleSel);
                    checkFiles();
                  }}
                />
              </div>
            </div>
          </div>
        {/if}
      </div>
      <div class="leftMenuOption people">
        <div class="form title"><span>LABIT people</span></div>
        <div class="form">
          <div class="checkbox" on:click={() => toggleShowAllPeople()}>
            <img
              src={showAllPeople
                ? "/images/checkbox_empty.svg"
                : "/images/checkbox.svg"}
              alt="Show all Labit people"
            />
            <span>Show only active people</span>
          </div>
        </div>
        <div class="form listPeople customScrollbars">
          {#each people as p, index}
            {#if p.visible}
              <PeopleDocumentsContact
                contact={p}
                selected={index === peopleSel}
                onClick={() => selectContact(index)}
              />
            {/if}
          {/each}
        </div>
      </div>
    </div>
    <div class="rightMenu">
      {#if peopleSel >= 0}
        <FileBoobleContainer
          title={people[peopleSel].name + " - " + documentTypeOptionsSel.value}
          url={peopleSelPath}
          onPreviewFile={(id) => previewFile(id)}
          onUpload={() => checkFiles()}
        />
      {/if}
      {#if remoteFilePrev !== "" && remoteFilePrev
          .toLowerCase()
          .trim()
          .startsWith("http")}
        <div class="iframe iframeFile">
          <img
            class="fadedButton"
            src="/images/x.svg"
            alt="Close"
            on:click={() => {
              remoteFilePrev = "";
            }}
          />
          <WebBrowser
            type={platform === "Web" ? "iframe" : "webview"}
            iframeTitle="External URL"
            id=remoteFileWebBrowser
            src={remoteFilePrev}
          />
        </div>
      {:else}
        <PreviewRemoteFile bind:fileUrl={remoteFilePrev} />
      {/if}
    </div>
  </div>
</div>

<!--
{#if platform === "APP" && remoteFilePrev !== "" && remoteFilePrev
    .toLowerCase()
    .trim()
    .startsWith("http")}
  <ContextMenu currentURL={remoteFilePrev} bind:showMenu={showNavMenu} />
{/if}
-->

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  div.leftMenu {
    width: 30%;
    display: flex;
    flex-direction: column;
  }

  div.leftMenuOption {
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
  }

  div.leftMenuOption:not(:first-child) {
    margin-top: 20px;
  }

  div.rightMenu {
    width: 67%;
  }

  div.form {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  div.form:not(:first-child) {
    margin-top: 15px;
  }

  div.form span {
    margin-bottom: 5px;
  }

  div.combobox {
    width: 100%;
    height: 25px;
  }

  div.payroll {
    flex-direction: row !important;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  div.comboboxOpt {
    width: 48%;
    display: flex;
    flex-direction: column;
  }

  div.checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  div.checkbox img {
    width: 17px;
  }

  div.checkbox span {
    margin-bottom: 0px !important;
    margin-left: 7px;
  }

  div.title {
    font-size: 15px;
    font-weight: bold;
  }

  div.people {
    height: 640px;
  }

  div.listPeople {
    max-height: 520px;
    overflow-y: scroll;
  }

  div.iframeFile {
    height: 800px;
    background-color: var(--labit-dialog-background);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    margin-top: 20px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  div.iframeFile img {
    width: 9px;
    height: 9px;
    margin: 0px 20px 10px 0px;
  }
</style>
