<script>
  export let options;
  export let selectedValue;
  export let placeholder;
  export let onChange;
  export let onClick;
  export let download = false;
  export let width = "300px";

  import ComboboxVersion from "./ComboboxVersion.svelte";
</script>

<style>
  div.submenuComboboxContainerVersion {
    display: flex;
    color: var(--labit-card-gray);
    height: 21px;
    font-size: 13px;
    align-items: center;
  }

  div.submenuComboboxContainerVersion div {
    height: 100%;
  }

  img {
    margin-right: 13px;
    height: 100%;
  }

  img.clickable {
    cursor: pointer;
  }

  /* Animations */

  img.clickable {
    transition: opacity 0.5s linear 0s;
  }

  img.clickable:hover {
    opacity: 0.5;
  }
</style>

<div class="submenuComboboxContainerVersion">
  <img
    class={onClick ? 'clickable' : ''}
    src={download ? '/images/download-version.svg' : '/images/publish-version.svg'}
    alt="Publish Version Icon"
    on:click={onClick} />
  <div style="width: {width}">
    <ComboboxVersion
      {options}
      {placeholder}
      bind:selectedValue
      {onChange}
      {download} />
  </div>
</div>
