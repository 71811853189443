<script>
  export let img;
  export let width = "122px";
  export let onClick = null;
  export let onRightClick = null;
</script>

<div
  class="submenuIcon {onClick ? 'clickable' : ''}"
  style="width: {width}"
  on:click={onClick}
  on:contextmenu={(ev) => {
    ev.preventDefault();
    if (onRightClick) {
      onRightClick();
    }
    return false;
  }}
>
  <img src={img} alt="icon" />
</div>

<style>
  div {
    height: 52px;
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.clickable {
    cursor: pointer;
  }

  img {
    height: 59.6%;
  }

  /* Animations */

  div.clickable {
    transition: opacity 0.5s linear 0s;
  }

  div.clickable:hover {
    opacity: 0.5;
  }
</style>
