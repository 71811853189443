<script>
  export let people;
  export let startDate;
  export let endDate;
  export let dayArray;
  export let dayWidth;
  export let CRPid;
  export let loaded;
  export let drawPhases;
  export let view;

  import CompleteResourcePlannerGridCellTeam from "./CompleteResourcePlannerGridCellTeam.svelte";
  import Picture from "../../components/Picture.svelte";

  const TEAM_WIDTH = 330;
</script>

{#if people}
  <div class="mainPeople" style={view === "team" ? "" : "display: none;"}>
    <div
      class="name borderRight borderBottom borderLeft"
      style="width: {TEAM_WIDTH}px; left: 0px; height: {people.height}px;"
    >
      <div class="picture">
        <Picture image={people.pic} />
      </div>
      <div class="nameInfo">
        <span>{people.name}</span>
      </div>
    </div>
    {#each dayArray as day, index}
      <CompleteResourcePlannerGridCellTeam
        projects={people.projects}
        {day}
        {dayWidth}
        {dayArray}
        {startDate}
        {endDate}
        {CRPid}
        {loaded}
        {drawPhases}
        {index}
        rowHeight={people.height}
      />
    {/each}
  </div>
{/if}

<style>
  div.mainPeople {
    display: flex;
    width: fit-content;
  }

  div.name {
    padding: 10px;
    font-size: 15px;
    font-weight: 700;
    flex-shrink: 0;
    flex-grow: 0;
    position: sticky;
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 501;
  }

  div.nameInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5px;
  }

  div.picture {
    height: 30px;
    width: 30px;
  }
</style>
