import Tooltip from './Tooltip.svelte';

export function tooltip(element, props) {
	let title;
	let tooltipComponent;
	
	function mouseOver(event) {
		title = element.getAttribute('description');
		element.removeAttribute('title');

		const x = event.pageX + props.x;
		const y = event.pageY + props.y;
		tooltipComponent = new Tooltip({
			props: { title: title, x: x, y: y },
			target: document.body
		});
	}
	function mouseMove(event) {
		const x = event.pageX + props.x;
		const y = event.pageY + props.y;

		tooltipComponent.$set({ x: x, y: y });
	}
	function mouseLeave() {
		tooltipComponent.$destroy();
		// NOTE: restore the `title` attribute
		element.setAttribute('title', title);
	}
	
	element.addEventListener('mouseover', mouseOver);
    element.addEventListener('mouseleave', mouseLeave);
	element.addEventListener('mousemove', mouseMove);
	
	return {
		destroy() {
			element.removeEventListener('mouseover', mouseOver);
			element.removeEventListener('mouseleave', mouseLeave);
			element.removeEventListener('mousemove', mouseMove);
		}
	}
}