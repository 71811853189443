<script>
  export let relatedProject;
  export let action;
  export let onRemove;

  import { openNewWindow } from "../js/openNewWindow";

  let openProject = () => {
    openNewWindow("/projects/" + relatedProject.id);
  };
</script>

<style>
  div.dialogRelatedProjectsItemContainer {
    width: 100%;
    display: flex;
    margin-bottom: 3px;
  }

  span {
    flex-grow: 1;
    font-size: 13px;
    color: var(--labit-card-lightgray);
  }

  img {
    width: 12px;
    cursor: pointer;
    margin-left: 5px;
  }

  /* Animations */

  img {
    transition: opacity 0.5s linear 0s;
  }

  img:hover {
    opacity: 0.5;
  }
</style>

<div class="dialogRelatedProjectsItemContainer">
  <span>{relatedProject.name}</span>
  <img
    src="/images/eye.svg"
    alt="Show Project"
    on:click={() => openProject()} />
  {#if action !== 'show'}
    <img src="/images/x-red.svg" on:click={onRemove} alt="Remove Relation" />
  {/if}
</div>
