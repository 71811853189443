<script>
  import Menu from "../../components/Menu.svelte";

  const PAGE = "ANALYTICS";

  /* --- */

  import ObjectView from "./components/ObjectView.svelte";

  const projectUrn =
    "dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLnV0cXRTRVZQUWUtdEotREVEcGdlVEE_dmVyc2lvbj00";

  const manifestUrl =
    "https://developer.api.autodesk.com/modelderivative/v2/designdata/:urn/manifest";
  const hubsUrl = "https://developer.api.autodesk.com/project/v1/hubs";
  const projectsUrl =
    "https://developer.api.autodesk.com/project/v1/hubs/:hub_id/projects";
  const foldersUrl =
    "https://developer.api.autodesk.com/project/v1/hubs/:hub_id/projects/:project_id/topFolders";
  const contentsUrl =
    "https://developer.api.autodesk.com/data/v1/projects/:project_id/folders/:folder_id/contents";
  const versionsUrl =
    "https://developer.api.autodesk.com/data/v1/projects/:project_id/items/:item_id/versions";
  const metadatasUrl =
    "https://developer.api.autodesk.com/modelderivative/v2/designdata/:urn/metadata";
  const propertiesUrl =
    "https://developer.api.autodesk.com/modelderivative/v2/designdata/:urn/metadata/:guid/properties?forceget=true";
  const generateRoomsUrl =
    "https://developer.api.autodesk.com/modelderivative/v2/designdata/job";
  //const auth = 'Bearer eyJhbGciOiJIUzI1NiIsImtpZCI6Imp3dF9zeW1tZXRyaWNfa2V5In0.eyJjbGllbnRfaWQiOiJFTG9kSkRmVHhwaDJLUlJxRER5QVl4cnN3MTU5QzVsWiIsImV4cCI6MTU4MDM3NjI0MCwic2NvcGUiOlsiZGF0YTpyZWFkIl0sImF1ZCI6Imh0dHBzOi8vYXV0b2Rlc2suY29tL2F1ZC9qd3RleHA2MCIsImp0aSI6InE0N3I2T0t6b0g1aGpVampPZ1dEYTE0Szl5aXNrVG50TW9ub05QdUR2QXNYbG5MOHNlVkNLYW1Zck5xeW5WT1AifQ.vehXBQ5sK-FFU6L0e9-7027wMhR-TqTnwuckeae17JY';

  // Conventional auth
  const authUrl =
    "https://developer.api.autodesk.com/authentication/v2/token";
  const authClientId = "ELodJDfTxph2KRRqDDyAYxrsw159C5lZ";
  const authClientSecret = "juLsn872v7R8nfGQ";
  const combined = `${authClientId}:${authClientSecret}`;
  const base64Encoded = btoa(combined);

  String.prototype.toBase64 = function () {
    return new Buffer(this)
      .toString("base64")
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  };

  let hubs;
  let projects;
  let folders;
  let contents;
  let versions;
  let metadatas;
  let properties;
  let currentHub = 0;
  let currentProject = 0;
  let currentFolder = 0;
  let currentItem = 0;
  let currentVersion = 0;
  let currentMetadata = 0;
  let loading = true;

  let auth;

  let html = "";

  const fetchAuth = async () => {
    const response = await fetch(authUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Basic ${base64Encoded}`
      },
      body: `grant_type=client_credentials&scope=data:read`,
    });
    const myJson = await response.json();
    return myJson;
  };

  const authPromise = fetchAuth();
  authPromise.then((result) => {
    auth = "Bearer " + result.access_token;
    iniViewer();
    hubs = fetchHubs();
  });

  const fetchGenerateRooms = async (urn) => {
    const response = await fetch(generateRoomsUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: auth,
        "x-ads-force": true,
      },
      body: JSON.stringify({
        input: { urn: urn },
        output: {
          formats: [
            {
              type: "svf",
              views: ["2d", "3d"],
              advanced: {
                generateMasterViews: true,
              },
            },
          ],
        },
      }),
    });
    const myJson = await response.json();
    return myJson;
  };

  const fetchManifest = async (id) => {
    const url = manifestUrl.replace(":urn", id);
    const response = await fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });
    const myJson = await response.json();
    return myJson;
  };

  const fetchHubs = async () => {
    const response = await fetch(hubsUrl, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });
    const myJson = await response.json();
    loading = false;
    return myJson;
  };

  const fetchProjects = async () => {
    const url = projectsUrl.replace(":hub_id", currentHub);
    const response = await fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });
    const myJson = await response.json();
    return myJson;
  };

  const fetchFolders = async () => {
    const url = foldersUrl
      .replace(":project_id", currentProject)
      .replace(":hub_id", currentHub);
    const response = await fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });
    const myJson = await response.json();
    return myJson;
  };

  const fetchContents = async () => {
    const url = contentsUrl
      .replace(":project_id", currentProject)
      .replace(":folder_id", currentFolder);
    const response = await fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });
    const myJson = await response.json();
    return myJson;
  };

  const fetchVersions = async () => {
    const url = versionsUrl
      .replace(":project_id", currentProject)
      .replace(":item_id", currentItem);
    const response = await fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });
    const myJson = await response.json();
    return myJson;
  };

  const fetchMetadatas = async () => {
    const url = metadatasUrl.replace(":urn", currentVersion);
    const response = await fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });
    const myJson = await response.json();
    return myJson;
  };

  const fetchProperties = async () => {
    const url = propertiesUrl
      .replace(":urn", currentVersion)
      .replace(":guid", currentMetadata);
    const response = await fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: auth,
      },
    });
    const myJson = await response.json();
    return myJson;
  };

  function getProjects(e) {
    const id = e.target.id;
    currentHub = id;
    projects = fetchProjects();
  }

  function getFolders(e) {
    const id = e.target.id;
    currentProject = id;
    folders = fetchFolders();
  }

  function getContents(e) {
    const id = e.target.id;
    currentFolder = id;
    contents = fetchContents();
  }

  function getVersions(e) {
    const id = e.target.id;
    currentItem = id;
    versions = fetchVersions();
  }

  function getMetadatas(e) {
    const id = e.target.id;
    currentVersion = id;
    metadatas = fetchMetadatas();
  }

  function getProperties(e) {
    const id = e.target.id;
    currentMetadata = id;
    properties = fetchProperties();
  }

  function generateRooms(e) {
    const id = e.target.id;
    const roomsResult = fetchGenerateRooms(id);
  }

  async function openForge(e) {
    const name = e.target.id;
    const id = currentVersion;
    var documentId = "urn:" + id;
    const manif = await fetchManifest(id);

    Autodesk.Viewing.Document.load(
      documentId,
      onDocumentLoadSuccess,
      onDocumentLoadFailure
    );

    function onDocumentLoadSuccess(viewerDocument) {
      var viewables = viewerDocument.getRoot().search({ type: "geometry" });
      defaultModel = viewerDocument.getRoot().getDefaultGeometry();
      let thisViewable = undefined;
      viewables.forEach((item, index) => {
        if (item.data.name.contains(name)) {
          thisViewable = index;
        }
      });
      viewer.loadDocumentNode(viewerDocument, viewables[thisViewable]);
    }

    function onDocumentLoadFailure() {
      console.error("Failed fetching Forge manifest");
    }
  }

  function loadViewable() {}

  function syntaxHighlight(json) {
    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        var cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
  }

  hubs = fetchHubs();
  projects = fetchProjects(0);
  folders = fetchFolders(0);
  contents = fetchContents(0);
  versions = fetchVersions(0);
  metadatas = fetchMetadatas(0);

  var viewer;
  function iniViewer() {
    var options = {
      env: "AutodeskProduction",
      api: "derivativeV2", // for models uploaded to EMEA change this option to 'derivativeV2_EU'
      getAccessToken: function (onTokenReady) {
        var token = auth.replace("Bearer ", "");
        var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
        onTokenReady(token, timeInSeconds);
      },
    };

    /*Autodesk.Viewing.Initializer(options, function() {
      var htmlDiv = document.getElementById("forgeViewer");
      viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
      var startedCode = viewer.start();
      if (startedCode > 0) {
        console.error("Failed to create a Viewer: WebGL not supported.");
        return;
      }

      console.log("Initialization complete, loading a model next...");
    });*/
  }
</script>

<div class="content">
  <div class="oldCode">
    <div class="header">
      <img src="/images/revit.png" alt="Revit Icon" />
      <span>REVIT LINK</span>
    </div>

    {#if loading}
      <div class="loading">Loading Hubs...</div>
    {/if}
    {#await hubs}
      <!--<div class="loading">Loading Hubs...</div>-->
    {:then hubs}
      <div class="hubs">
        <ul>
          {#each hubs.data as hub}
            <li>
              <a href="javascript:void(0);" id={hub.id} on:click={getProjects}>
                {hub.id}
              </a>
              <b>{hub.attributes.name}</b>
              {#if hub.id == currentHub}
                {#await projects}
                  <div class="loading">Loading Projects...</div>
                {:then projects}
                  <ul>
                    {#each projects.data as project}
                      <li>
                        <a
                          href="javascript:void(0);"
                          id={project.id}
                          on:click={getFolders}
                        >
                          {project.id}
                        </a>
                        <b>{project.attributes.name}</b>
                        {#if project.id == currentProject}
                          {#await folders}
                            <div class="loading">Loading Folders...</div>
                          {:then folders}
                            <ul>
                              {#each folders.data as folder}
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    id={folder.id}
                                    on:click={getContents}
                                  >
                                    {folder.id}
                                  </a>
                                  <b>{folder.attributes.name}</b>
                                  {#if folder.id == currentFolder}
                                    {#await contents}
                                      <div class="loading">
                                        Loading Contents...
                                      </div>
                                    {:then contents}
                                      <ul>
                                        {#each contents.data as content}
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              id={content.id}
                                              on:click={getVersions}
                                            >
                                              {content.id}
                                            </a>
                                            <b>
                                              {content.attributes.displayName}
                                            </b>
                                            {#if content.id == currentItem}
                                              {#await versions}
                                                <div class="loading">
                                                  Loading Versions...
                                                </div>
                                              {:then versions}
                                                <ul>
                                                  {#each versions.data as version}
                                                    {#if version.relationships.derivatives}
                                                      <li>
                                                        <button
                                                          id={version
                                                            .relationships
                                                            .derivatives.data
                                                            .id}
                                                          on:click={generateRooms}
                                                        >
                                                          Rooms
                                                        </button>
                                                        <a
                                                          href="javascript:void(0);"
                                                          id={version
                                                            .relationships
                                                            .derivatives.data
                                                            .id}
                                                          on:click={getMetadatas}
                                                        >
                                                          {version.relationships
                                                            .derivatives.data
                                                            .id}
                                                        </a>
                                                        <b>
                                                          {version.attributes
                                                            .versionNumber}
                                                        </b>
                                                        {#if version.relationships.derivatives.data.id == currentVersion}
                                                          {#await metadatas}
                                                            <div
                                                              class="loading"
                                                            >
                                                              Loading
                                                              Metadatas...
                                                            </div>
                                                          {:then metadatas}
                                                            <ul>
                                                              {#each metadatas.data.metadata as metadata}
                                                                <li>
                                                                  <a
                                                                    href="javascript:void(0);"
                                                                    id={metadata.guid}
                                                                    on:click={getProperties}
                                                                  >
                                                                    {metadata.guid}
                                                                  </a>
                                                                  <b>
                                                                    {metadata.name}
                                                                  </b>
                                                                  <button
                                                                    id={metadata.name}
                                                                    on:click={openForge}
                                                                  >
                                                                    View
                                                                  </button>
                                                                  {#if metadata.guid == currentMetadata}
                                                                    {#await properties}
                                                                      <div
                                                                        class="loading"
                                                                      >
                                                                        Loading
                                                                        Properties...
                                                                      </div>
                                                                    {:then properties}
                                                                      <div>
                                                                        <ObjectView
                                                                          children={properties}
                                                                        />
                                                                      </div>
                                                                    {/await}
                                                                  {/if}
                                                                </li>
                                                              {/each}
                                                            </ul>
                                                          {/await}
                                                        {/if}
                                                      </li>
                                                    {/if}
                                                  {/each}
                                                </ul>
                                              {/await}
                                            {/if}
                                          </li>
                                        {/each}
                                      </ul>
                                    {/await}
                                  {/if}
                                </li>
                              {/each}
                            </ul>
                          {/await}
                        {/if}
                      </li>
                    {/each}
                  </ul>
                {/await}
              {/if}
            </li>
          {/each}
        </ul>
      </div>
    {/await}

    <!--<div id="forgeViewer" />-->
  </div>
</div>

<style>
  ul {
    font-size: 20px;
  }

  /*#forgeViewer {
    width: 600px;
    height: 300px;
    margin: 0;
    background-color: #f0f8ff;
    position: relative;
  }*/

  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  div.oldCode {
    width: 100%;
  }

  div.header {
    display: flex;
    align-items: center;
    margin-top: 23px;
    margin-bottom: 23px;
  }

  div.header img {
    width: 23px;
  }

  div.header span {
    font-size: 19px;
    font-weight: bold;
    margin-left: 8px;
  }

  div.hubs {
    margin-top: 23px;
    background-color: var(--labit-dialog-background);
    padding: 10px;
    border-radius: 19px;
    font-size: 15px !important;
  }

  div.hubs ul li {
    font-size: 17px !important;
  }

  div.hubs ul li a {
    margin-right: 13px;
    color: #0021c3;
  }

  li {
    margin-top: 10px;
  }

  div.loading {
    font-size: 13px !important;
  }
</style>
