<script>
  export let openNewProject = false;
  export let refreshCompanyList = false;
  export let refreshProjects = false;
  export let projectManagerMode = false;
  export let projectManagerProjectId = -1;

  //import Menu from "../../../components/Menu.svelte";
  import FileUploader from "../../../components/FileUploader.svelte";
  import GoogleMaps from "../../../components/GoogleMaps.svelte";
  //import SimplePhasePreview from "../../../components/SimplePreview/SimplePhasePreview.svelte";
  //import SimpleTeamPreview from "../../../components/SimplePreview/SimpleTeamPreview.svelte";
  import DialogRemove from "../../../components/DialogRemove.svelte";
  import TextEdit from "../../../components/TextEdit.svelte";
  import SubmenuButton from "../../../components/SubmenuButton.svelte";
  //import SubmenuCombobox from "../../../components/SubmenuCombobox.svelte";
  //import SubmenuDoubleCombobox from "../../../components/SubmenuDoubleCombobox.svelte";
  import EditSection from "../../../components/EditSection.svelte";
  //import BoxEditPager from "../../../components/BoxEditPager.svelte";
  import DialogSpanSuggestionFullEdit from "./components/DialogSpanSuggestionFullEdit.svelte";
  import CompanyTeams from "./components/CompanyTeams.svelte";
  import ProjectAnalyticsExternalUrl from "./components/ProjectAnalyticsExternalUrl.svelte";
  import DialogSmallButton from "../../../components/DialogSmallButton.svelte";
  import SubmenuMultipleSelect from "../../../components/SubmenuMultipleSelect.svelte";
  import AnalyticsTabs from "../../../components/AnalyticsTabs.svelte";
  import CompleteResourcePlanner from "../../../components/CompleteResourcePlanner/CompleteResourcePlanner.svelte";

  import { copyObject } from "../../../js/copyObject";
  import {
    updateCurrentStatus,
    getAllProjects,
    getCities,
    getCityCodes,
    getClients,
    updateDates,
  } from "../../../js/projects";

  import { prepareSearch } from "../../../js/prepareSearch";
  import { getCoordinatesFromMapsURL } from "../../../js/getCoordinatesFromMapsURL";
  import { LABIT_PUBLIC_FOLDER } from "../../../js/labit-connection";
  import { removeAccents } from "../../../js/removeAccents";
  import { updateCityCode } from "../../../js/updateCityCode";
  import { insertCompanyBasic } from "../../../js/companies";
  import { openURL } from "../../../js/openURL";

  import { onMount } from "svelte";

  import countriesJSON from "../../../data/countries";

  // Just in case you need user's rol and id
  import { getRole, getUserId } from "../../../js/localInfo";

  // Just in case you need to access the database through the simplerest API
  import { post, rpost } from "../../../js/labit-connection";

  import Combobox from "./components/Combobox.svelte";
  import DialogSpanFullEdit from "./components/DialogSpanFullEdit.svelte";
  import Switch from "../../../components/Switch.svelte";
  //import ComboboxEvent from "../../calendar/screens/components/ComboboxEvent.svelte";

  import ItemListed from "./components/ItemListed.svelte";
  import ProjectPageButton from "./components/ProjectPageButton.svelte";
  //import Team from "./components/Team.svelte";

  import StatusHistoryItem from "./components/StatusHistoryItem.svelte";

  import DialogSpanCalendarPickerEvent from "../../calendar/screens/components/DialogSpanCalendarPickerEvent.svelte";

  import Picture from "../../../components/Picture.svelte";
  //import People from "../../../pages/People.svelte";
  //import PeopleDocuments from "../../../pages/PeopleDocuments.svelte";

  // See src/data/components/menu.json ('text' attribute) to mark current page

  import moment from 'moment';
  
  const PAGE = "ANALYTICS";
  const action = "edit";
  const HOURS = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  const MINS = ["00", "15", "30", "45"];

  let myRole = getRole();
  let myId = getUserId();

  let backgroundColorInput = "#d7e1ea";
  let borderColor = "var(--labit-card-gray)";
  let status = false;

  let externalUrlOptions = [];
  let externalUrlOptionsSel = null;

  /*let externalUrlOptions = [
    {
      label: "Server",
      value: "Server",
    },
    {
      label: "Trello",
      value: "Trello",
    },
    {
      label: "Slack",
      value: "Slack",
    },
    {
      label: "Sharepoint",
      value: "Sharepoint",
    },
    {
      label: "BIM360",
      value: "BIM360",
    },
  ];
  let externalUrlOptionsSel = externalUrlOptions[0];*/
  let externaUrlOptionUrl = "";

  //let countries = [{ label: "Spain" }, { label: "France" }];
  //let cities = [{ label: "Cáceres" }, { label: "París" }];

  let projects = [];

  let companySelected = null;
  let roleSelected = "";
  let teamRoleSelected = "";
  let roleStart = moment().format("DD/MM/YYYY");
  let roleEnd = moment().format("DD/MM/YYYY");

  let companyList = [];

  let scopeList = [];

  let currentScope = {
    id: "",
    name: "",
    duration: 0,
    phases: [],
    type: "scope",
    notAvailable: false,
  };

  let currentMilestone = {
    id: "",
    name: "",
    mtype: "generic",
    date: moment().format("DD/MM/YYYY"),
    startH: "00",
    startM: "00",
    endH: "00",
    endM: "00",
    type: "milestone",
    allDay: false,
    isEditing: false,
  };

  let usedScopesList = [];

  let showRemoveDialog = false;

  let errorName = false;
  let error = false;

  let menu = 1;
  let numPhases = 0;
  let numSubphases = 0;

  let scopeStartDate = moment().format("DD/MM/YYYY");

  let scopeOptions = [];
  let scopeSelected = null;
  let countrySelected = null;
  let projectManagerSelected = null;
  let personTeamSelected = null;
  let citySelected = null;
  let selected = null;
  let saveNewScope = false;
  let newScopeSaved = false;
  let indexPhase = -1;
  let indexSubphase = -1;
  let indexMilestone = -1;
  let scopeDuration = 0;
  let isEditing = false; // Indicates if we are editing phase or sub-phase.
  let isPhase = 0; // 0 is unselected, 1 is phase, 2 is subphase, 3 is milestone
  let editedType = "";

  let stakeholder = {
    id: "",
    type: "stakeholder",
    company: "",
    role: "",
    selectedPerson: "",
    selectedRole: "",
    team: [],
  };

  let peopleList = [];

  /* let projectManagerList = []; */
  /* let teamList = []; */
  /* let stakeholdersList = []; */

  let currentProject = null;
  let savedProject = null;

  let defaultProject = {
    id: -1,
    active: true,
    name: "",
    nickName: "",
    client: "",
    company: "",
    address: "",
    latitude: "",
    longitude: "",
    //googleMapsURL: "",
    country: "",
    city: "",
    digit2: "",
    digit3: "",
    timeCode: "",
    folderCode: "",
    /*serverLink: "",
    trello: "",
    slack: "",
    sharepoint: "",
    bim360: "",
    miro: "",*/
    externalUrls: [],
    picImage: "",
    picPath: "",
    phases: [],
    milestones: [],
    projectManager: [],
    team: [],
    stakeholders: [],
    licenses: [],
    statusHistory: [],
    isInternal: false,
    programType: "",
    creationInfo: null,
    modificationInfo: null,
  };

  let currentPhase = {
    id: "",
    name: "",
    startDate: moment().format("DD/MM/YYYY"),
    endDate: moment().format("DD/MM/YYYY"),
    notAvailable: false,
    subphases: [],
    type: "phase",
    isEditing: false,
    scopeId: "",
  };

  let currentSubphase = {
    id: "",
    name: "",
    startDate: moment().format("DD/MM/YYYY"),
    endDate: moment().format("DD/MM/YYYY"),
    type: "subphase",
    isEditing: false,
  };

  let countries = [];
  let countriesSel = null;

  let cities = [];
  let citySel = "";

  let cityCodes = [];
  let cityCode2 = "";
  let cityCode3 = "";
  let setCityCode2 = false;
  let setCityCode3 = false;
  let hasId = false;

  let clients = [];
  let clientList = [];
  let clientCode = "";

  let mapsURL = "";

  let accId = -1;

  let phaseReview = [];
  let milestoneReview = [];

  let applicationOptions = [];
  let applicationOptionsSel = null;
  let keyOptions = [];
  let keyOptionsSel = [];

  let newApplication = "";
  let newKey = "";

  const PROJECTS_REMOTE_URL = "/projects";

  let mode = "list"; // list, new, edit

  let searchFilter = "";
  let code1Options = [];
  let selectedCode1 = [];
  let code2Options = [];
  let selectedCode2 = [];
  let clientOptions = [];
  let selectedClient = [];
  let statusOptions = [];
  let selectedStatus = [];
  let typeOptions = [];
  let selectedType = [];
  let locationOptions = [];
  let selectedLocation = [];
  let active = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Inactive",
      label: "Inactive",
    },
  ];
  let selectedActive = [];
  let internals = [
    {
      value: "Internal",
      label: "Internal",
    },
    {
      value: "External",
      label: "External",
    },
  ];
  let selectedInternals = [];

  let statusP = [];
  let selectedStatusP = null;
  let statusStart = moment().format("DD/MM/YYYY");
  let statusEnd = moment().format("DD/MM/YYYY");
  let editingStatus = null;

  let works = [];
  let selectedWorks = null;

  let roles = [];

  const milestoneOptions = [
    {
      value: "generic",
      label: "Generic",
    },
    {
      value: "in",
      label: "In",
    },
    {
      value: "out",
      label: "Out",
    },
  ];

  let milestoneOptionsSel = milestoneOptions[0];

  let newClient = "";
  let showNewClient = "";

  let projectManagerModeShown = -1;

  let externalUrls = [];
  let platformLinks = [];

  let loadingFileIcon = false;

  let centerView = false;

  const NUM_PAGES = 2;

  const stakeholdersOptions = [
    {
      label: "Client",
      value: "Client",
    },
    {
      label: "Consultant",
      value: "Consultant",
    },
  ];
  let stakeholdersOptionsSel = stakeholdersOptions[0];

  const clientsOptions = [
    {
      label: "Client",
      value: "Client",
    },
    {
      label: "Project Manager Client",
      value: "Project Manager Client",
    },
    {
      label: "Architect",
      value: "Architect",
    },
    {
      label: "Designer",
      value: "Designer",
    },
    {
      label: "MEP Engineer",
      value: "MEP Engineer",
    },
  ];
  let clientsOptionsSel = clientsOptions[0];

  const consultantsOptions = [
    {
      label: "MEP Engineer",
      value: "MEP Engineer",
    },
    {
      label: "STR Engineer",
      value: "STR Engineer",
    },
    {
      label: "Acoustics Engineer",
      value: "Acoustics Engineer",
    },
    {
      label: "Project Manager Consultant",
      value: "Project Manager Consultant",
    },
  ];
  let consultantsOptionsSel = consultantsOptions[0];

  let projectTypes = [
    {
      label: "N/A",
      value: "N/A",
    },
    {
      label: "Urban Planning",
      value: "Urban Planning",
    },
    {
      label: "Workplace",
      value: "Workplace",
    },
    {
      label: "Residential",
      value: "Residential",
    },
    {
      label: "Education",
      value: "Education",
    },
    {
      label: "Hospitality",
      value: "Hospitality",
    },
    {
      label: "Retail",
      value: "Retail",
    },
    {
      label: "Logistics",
      value: "Logistics",
    },
    {
      label: "Public Buildings",
      value: "Public Buildings",
    },
  ];
  let projectTypesSel = projectTypes[0];

  onMount(async () => {
    await getPlatformLinks();

    prepareProject();
  });

  let getPlatformLinks = async () => {
    const response = await post(
      `select name, iconUrl
        from platforms`
    );
    platformLinks = response.map((item) => {
      return {
        name: item.name,
        icon: item.iconUrl,
      };
    });
    externalUrlOptions = platformLinks.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
    externalUrlOptionsSel = externalUrlOptions[0];
  };

  let prepareProject = async () => {
    await listScopes();
    loadScopeList();

    await listPeople();
    await listCompanies();
    await listApplications();
    await getRoles();

    await getStatus();

    await getWorks();

    await getProjects();

    await getClientsDialog();

    countries = countriesJSON.map((country) => {
      return {
        label: country.code + " - " + country.name,
        value: country.code,
      };
    });
    countries = countries.sort((a, b) => {
      if (a.value > b.value) {
        return 1;
      } else if (a.value < b.value) {
        return -1;
      }
      return 0;
    });

    cityCodes = await getCityCodes();
    cities = await getCities();

    selectedActive = [active[0].value];

    if (openNewProject) {
      newProject();

      //menu = 3;
    }
  };

  let getClientsDialog = async () => {
    clients = await getClients();
    clientList = clients.map((client) => {
      return client.name;
    });
  };

  let getRoles = async () => {
    const response = await post(
      `select distinct role
        from projectPeople
        where role!=""
        order by role asc`
    );
    roles = response.map((item) => {
      return item.role;
    });
  };

  let getWorks = async () => {
    works = await post(
      `select people, project, start, end, P.name as projectName
        from projectPeople as PP
        left join projects as P on P.project_id=PP.project
        where PP.active=1 and stakeholder=0 and start is not null and end is not null`
    );
  };

  let getStatus = async () => {
    let response = await post(
      `select id, name, active
        from defaultStatusHistory`
    );
    statusP = response.map((item) => {
      return {
        value: {
          id: item.id,
          name: item.name,
          active: item.active === "1",
        },
        label: item.name,
      };
    });
    selectedStatusP = statusP[0];
  };

  let getProjects = async () => {
    projects = [];

    if (myRole === "Admin" || myRole === "Analytics") {
      projects = await getAllProjects(false);
    } else {
      if (projectManagerMode) {
        const today = moment().format("YYYY-MM-DD");
        projects = await getAllProjects(true, myId);
        projects = projects.filter((project) => {
          const pos = project.people.findIndex((p) => {
            return (
              p.peopleId === myId.toString() &&
              p.role === "Project Manager" &&
              p.start <= today &&
              p.end >= today
            );
          });
          return pos >= 0;
        });
      }
    }
    if (projectManagerMode) {
      /*projects = projects.filter((p) => {
        return p.id === projectManagerProjectId;
      });*/
      if (projects.length > 0) {
        editProject(projectManagerProjectId);
      }
    }

    projects.forEach((project) => {
      project.people.forEach((p) => {
        p.modified = false;
      });
    });
    
    projects.forEach((project) => {
      const projectManagersAdded = new Set();
      const filteredPeople = [];

      project.people.forEach((person) => {
      if (person.role === "Project Manager") {
         if (!projectManagersAdded.has(person.peopleId)) {
          filteredPeople.push(person);
          projectManagersAdded.add(person.peopleId);
      }
      } else {
      filteredPeople.push(person);
      }
    });

    project.people = filteredPeople;
    });

    if (myRole !== "Analytics") {
      projects = projects.filter((project) => {
        return project.lastName.trim() !== "Labit Group";
      });
    }

    updateCode1Filter();
    updateCode2Filter();
    updateClientFilter();
    updateStatusFilter();
    updateTypeFilter();
    updateLocationFilter();

    applyFilter();
  };

  let listApplications = async () => {
    const response = await post(
      `select id, name
        from applications 
        order by name asc`
    );

    applicationOptions = response.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

    if (applicationOptions.length > 0) {
      applicationOptionsSel = applicationOptions[0];
    }
  };

  let listKeys = async () => {
    const response = await post(
      `select id, userKey
        from applicationKeys
        where application=${applicationOptionsSel.value} and global=0
        order by userKey asc`
    );

    keyOptions = response.map((item) => {
      return {
        value: item.id,
        label: item.userKey,
      };
    });

    keyOptionsSel = keyOptions.length > 0 ? keyOptions[0] : null;
  };

  let listCompanies = async () => {
    const response = await post(
      `select company_id, Name
        from companies
        where Active=1
        order by Name asc`
    );
    companyList = response.map((item) => {
      return {
        value: item.company_id,
        label: item.Name,
      };
    });
  };

  let listScopes = async () => {
    const response = await post(
      `select s.id as scopeId, s.name as scopeName, sp.id as scopePhaseId, sp.name as scopePhaseName, days, offset, parentPhase
        from scope as s
        left join scopePhase as sp on sp.scope=s.id
        order by s.name asc`
    );
    scopeList = [];
    response.forEach((s) => {
      let scope = scopeList.find((sl) => {
        return sl.id === s.scopeId;
      });
      if (!scope) {
        scope = {
          id: s.scopeId,
          name: s.scopeName,
          duration: -1,
          type: "scope",
          notAvailable: false,
          phases: [],
        };
        scopeList.push(scope);
      }
      const scopePhase = {
        id: s.scopePhaseId,
        name: s.scopePhaseName,
        days: s.days,
        offset: s.offset,
        subphases: [],
      };
      if (s.parentPhase) {
        let pp = scope.phases.find((item) => {
          return item.id === s.parentPhase;
        });
        if (pp) {
          pp.subphases.push(scopePhase);
        }
      } else {
        scope.phases.push(scopePhase);
      }
    });

    //console.log(scopeList);
  };

  let listPeople = async () => {
    const response = await post(
      `select contact_id, p.Name as peopleName, PictureUrl
        from people as p
        left join companies as c on c.company_id=p.company_id
        where esLabit=1
        order by peopleName asc;`
    );
    peopleList = response.map((item) => {
      return {
        value: item.contact_id,
        label: item.peopleName,
        image: item.PictureUrl,
      };
    });
  };

  let setCityCodes = () => {
    const code = cityCodes.find((cityCode) => {
      return cityCode.name === citySel;
    });
    if (code) {
      currentProject.digit2 = code.code2;
      currentProject.digit3 = code.code3;
      setCityCode2 = currentProject.digit2 !== "";
      setCityCode3 = currentProject.digit3 !== "";
    } else {
      currentProject.digit2 = "";
      currentProject.digit3 = "";
      setCityCode2 = false;
      setCityCode3 = false;
    }
    currentProject = currentProject;
  };

  let changeCountryCode = () => {
    currentProject.country = countriesSel.label;
  };

  let changeProjectType = () => {
    currentProject.programType = projectTypesSel.value;
  };

  let onChangeClient = () => {
    const client = clients.find((item) => {
      return item.name === currentProject.client;
    });
    clientCode = client ? client.code : "";
  };

  let generateProjectId = async () => {
    if (mode === "new") {
      currentProject.folderCode = clientCode + currentProject.digit2;

      if (currentProject.folderCode.length >= 4) {
        const response = await post(
          "select FolderLabitCode from projects where FolderLabitCode like '" +
            currentProject.folderCode +
            "%' and project_id!=" +
            currentProject.id
        );

        const num = response.length + 1;
        const numS = num < 10 ? "0" + num.toString() : num.toString();

        currentProject.folderCode = currentProject.folderCode + numS;
      }

      currentProject = currentProject;
    }
  };

  let createProjectPicName = (project) => {
    let projectName = project.name.trim().replace(/ /g, "").replace(/\//g, "");
    projectName = removeAccents(projectName);
    const projectPicName = project.id + "-" + projectName;
    return projectPicName;
  };

  let createProject = async () => {
    const now = moment().format("YYYY-MM-DD HH:mm:ss");
    const today = moment().format("YYYY-MM-DD");

    let response = await post(
      `insert into projects (
          Name,
          TimeLabitCode,
          FolderLabitCode,
          Nickname,
          Client,
          Company,
          Address,
          City,
          Country,
          Area,
          aream2,
          noFloors,
          allBuildingDemise,
          newStairCase,
          occupancy,
          existingMinimumHeight,
          raisedFloorCavityHeight,
          singularities,
          sprinklers,
          PEN,
          longDescription,
          ProgramType,
          active,
          picUrl,
          gMapUrl,
          picPath,
          latitude,
          longitude,
          isInternal,
          outlookCalendarId,
          outlookCalendarName,
          planPicUrl,
          planPicPath,
          creationDate,
          createdBy
        )
        values (
         '${currentProject.name}', 
          '${currentProject.timeCode}',
          '${currentProject.folderCode}',
          '${currentProject.nickName}',
          '${currentProject.client}', 
          'Labit Group', 
          '${currentProject.address}', 
          '${citySel}', 
          '${currentProject.country}',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '${currentProject.programType}',
          ${currentProject.active ? "1" : "0"},
          '',
          '',
          '',
          '${currentProject.latitude}',
          '${currentProject.longitude}',
          ${currentProject.isInternal ? "1" : "0"},
          '',
          '',
          '',
          '',
          '${now}',
          ${myId}
        )`
    );

    currentProject.id = response[0];

    // Image

    if (currentProject.picImage) {
      const projectPicName =
        createProjectPicName(currentProject) +
        "." +
        currentProject.picImage.extension;
      const picPath = PROJECTS_REMOTE_URL + "/" + projectPicName;
      const picUrl = LABIT_PUBLIC_FOLDER + picPath;

      await rpost("UploadPublicFile", {
        path: PROJECTS_REMOTE_URL,
        fileName: projectPicName,
        fileData: currentProject.picImage.data,
      });

      await post(
        `update projects
          set 
          picUrl='${picUrl}',
          picPath='${picPath}'
          where project_id=${currentProject.id}`
      );
    }

    // Project Links

    for (let i = 0; i < currentProject.externalUrls.length; i++) {
      let url = currentProject.externalUrls[i];
      if (url.id < 0) {
        if (url.type === "Server") {
          url.url = url.url.replace(/\\/g, "\\\\");
        }
      }
    }

    for (let i = 0; i < currentProject.externalUrls.length; i++) {
      const url = currentProject.externalUrls[i];
      await post(
        `insert into externalUrls (url, type, project)
          values ('${url.url}', '${url.type}', ${currentProject.id})`
      );
    }

    // Phases

    for (let i = 0; i < currentProject.phases.length; i++) {
      let phase = currentProject.phases[i];
      let scopeId = phase.scopeId === "" ? "null" : `${phase.scopeId}`;
      let start =
        phase.startDate === ""
          ? "null"
          : `'${moment(phase.startDate, "DD/MM/YYYY").format("YYYY-MM-DD")}'`;
      let end =
        phase.endDate === ""
          ? "null"
          : `'${moment(phase.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")}'`;
      let response = await post(
        `insert into phase (name, start, end, project, scope)
          values ('${phase.name}', ${start}, ${end}, ${currentProject.id}, ${scopeId})`
      );
      phase.id = response[0];
      for (let j = 0; j < phase.subphases.length; j++) {
        let subphase = phase.subphases[j];
        start =
          subphase.startDate === ""
            ? "null"
            : `'${moment(subphase.startDate, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )}'`;
        end =
          subphase.endDate === ""
            ? "null"
            : `'${moment(subphase.endDate, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )}'`;
        response = await post(
          `insert into phase (name, start, end, phaseParent, project, scope)
          values ('${subphase.name}', ${start}, ${end}, ${phase.id}, ${currentProject.id}, ${scopeId})`
        );
        subphase.id = response[0];
      }
    }

    // Milestones

    for (let i = 0; i < currentProject.milestones.length; i++) {
      let milestone = currentProject.milestones[i];
      let start = moment(milestone.date, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(milestone.date, "DD/MM/YYYY").format("YYYY-MM-DD");
      if (milestone.allDay) {
        start = start + " 00:00:00";
        end = end + " 23:59:59";
      } else {
        start =
          start +
          " " +
          milestone.startH.value +
          ":" +
          milestone.startM.value +
          ":00";
        end =
          end + " " + milestone.endH.value + ":" + milestone.endM.value + ":00";
      }

      response = await post(
        `insert into milestone (name, isAllDay, start, end, type, project)
          values ('${milestone.name}', ${
          milestone.allDay ? 1 : 0
        }, '${start}', '${end}', '${milestone.mtype}', ${currentProject.id})`
      );

      milestone.id = response[0];
    }

    // Team

    for (let i = 0; i < currentProject.team.length; i++) {
      const people = currentProject.team[i];
      await post(
        `insert into projectPeople (people, project, role, start, end, active, stakeholder)
          values (${people.contactId}, ${currentProject.id}, '${people.role}', '${people.start}', '${people.end}', 1, 0)`
      );
    }

    for (let i = 0; i < currentProject.stakeholders.length; i++) {
      const company = currentProject.stakeholders[i];
      await post(
        `insert into projectCompany (company, project, role)
          values (${company.id}, ${currentProject.id}, '${company.role}')`
      );

      for (let j = 0; j < company.team.length; j++) {
        const people = company.team[j];
        await post(
          `insert into projectPeople (people, project, role, active, stakeholder)
          values (${people.selectedPerson.value}, ${currentProject.id}, '${people.selectedRole}', 1, 1)`
        );
      }
    }

    // Licenses

    for (let i = 0; i < currentProject.licenses.length; i++) {
      const application = currentProject.licenses[i];
      for (let j = 0; j < application.keys.length; j++) {
        const key = application.keys[j];
        await post(
          `insert into projectKeys (applicationKey, project)
            values (${key.id}, ${currentProject.id})`
        );
      }
    }

    // Status

    for (let i = 0; i < currentProject.statusHistory.length; i++) {
      const status = currentProject.statusHistory[i];
      const end = status.end ? `'${status.end}'` : "null";
      await post(
        `insert into statusHistory (start, end, status, project)
        values ('${status.start}', ${end}, ${status.statusId}, ${currentProject.id})`
      );
    }

    // Updating City Codes

    if (
      citySel.trim() !== "" &&
      (currentProject.digit2.trim() !== "" ||
        currentProject.digit3.trim() !== "")
    ) {
      await updateCityCode(
        citySel,
        currentProject.digit2,
        currentProject.digit3
      );
      cityCodes = await getCityCodes();
      cities = await getCities();
    }

    await getProjects();
    //applyFilter();
    closeProcess();

    refreshProjects = true;
  };

  // Activates or deactivates the active status.
  let toggleStatus = () => {
    status = !status;
    currentProject.active = status;
  };

  // Advance to the next menu.
  let nextMenu = () => {
    menu++;
    if (menu > NUM_PAGES) {
      menu = 1;
    }
  };

  // Return to the previous menu.
  let previousMenu = () => {
    menu--;
    if (menu < 1) {
      menu = 4;
    }
  };

  // Reset the current phase.
  let resetCurrentPhase = () => {
    currentPhase = {
      id: "",
      name: "",
      startDate: currentPhase.startDate,
      endDate: currentPhase.endDate,
      notAvailable: false,
      subphases: [],
      type: "phase",
      isEditing: false,
      scopeId: "",
    };
  };

  // Reset the current subphase.
  let resetCurrentSubphase = () => {
    currentSubphase = {
      id: "",
      name: "",
      startDate: currentSubphase.startDate,
      endDate: currentSubphase.endDate,
      type: "subphase",
      isEditing: false,
    };
  };

  // Reset the current milestone.
  let resetCurrentMilestone = () => {
    currentMilestone = {
      id: "",
      name: "",
      mtype: "generic",
      date: moment().format("DD/MM/YYYY"),
      startH: "00",
      startM: "00",
      endH: "00",
      endM: "00",
      type: "milestone",
      allDay: false,
      isEditing: false,
    };
  };

  // Adding a phase to a project.
  let addPhase = () => {
    if (currentPhase.name.trim() !== "") {
      currentPhase.id = accId;
      accId--;
      if (currentPhase.notAvailable) {
        currentPhase.startDate = "";
        currentPhase.endDate = "";
      }
      currentPhase = currentPhase;
      currentProject.phases.push(currentPhase);

      currentProject.milestones.push({
        id: accId,
        name: currentPhase.name + " OUT",
        date: currentPhase.endDate,
        mtype: "out",
        allDay: true,
        startH: {
          value: "00",
          label: "00",
        },
        endH: {
          value: "00",
          label: "00",
        },
        startM: {
          value: "00",
          label: "00",
        },
        endM: {
          value: "00",
          label: "00",
        },
        isEditing: false,
        type: "milestone",
      });
      accId--;

      currentProject = updateDates(currentProject);
      numPhases = currentProject.phases.length;
      resetCurrentPhase();
      isPhase = 0;
      sortPhases();
    }
  };

  // Add a new subphase to the selected phase.
  let addNewSubphase = (index) => {
    if (currentSubphase.name.trim() !== "") {
      isEditing = false;
      currentSubphase.id = accId;
      accId--;
      /*currentSubphase.startDate = currentProject.phases[index].startDate;
    currentSubphase.endDate = currentProject.phases[index].endDate;*/
      currentProject.phases[index].subphases.push(currentSubphase);
      currentProject.phases = currentProject.phases;
      currentProject = currentProject;
      numSubphases = currentProject.phases[index].subphases.length;
      resetCurrentSubphase();
      resetCurrentPhase();
      sortSubphases(index);
      isPhase = 0;
    }
  };

  // Add a new milestone.
  let addMilestone = () => {
    currentMilestone.id = accId;
    currentMilestone.mtype = milestoneOptionsSel.value;
    accId--;
    currentProject.milestones.push(currentMilestone);
    currentProject = currentProject;
    resetCurrentMilestone();
    isPhase = 0;
    sortMilestones();
  };

  // Open the box to create a new phase.
  let openCreateNewSubphase = (index) => {
    isPhase = 0;
    isEditing = false;
    indexPhase = index;
  };

  // Close the box to create a new phase.
  let cancelCreateNewSubphase = () => {
    isPhase = 0;
    indexPhase = -1;
    isEditing = false;
    resetCurrentSubphase();
    resetCurrentPhase();
  };

  // Load the data of a phase into currentPhase.
  let loadPhaseData = (index) => {
    indexPhase = index;
    isEditing = currentProject.phases[index].isEditing;
    for (let i = 0; i < currentProject.phases.length; i++) {
      if (index !== i) {
        currentProject.phases[i].isEditing = false;
      }
    }
    resetCurrentPhase();
    currentPhase = copyObject(currentProject.phases[index]);
  };

  // Load the data of a milestone into currentMilestone.
  let loadMilestone = (index) => {
    indexMilestone = index;
    isEditing = currentProject.milestones[index].isEditing;
    milestoneOptionsSel = milestoneOptions.find((item) => {
      return item.value === currentProject.milestones[index].mtype;
    });
    for (let i = 0; i < currentProject.milestones.length; i++) {
      if (index !== i) {
        currentProject.milestones[i].isEditing = false;
      }
    }
    resetCurrentMilestone();
    currentMilestone = copyObject(currentProject.milestones[index]);
  };

  // Load the data of a sub-phase into currentSubphase.
  let loadSubphaseData = (index, indexS) => {
    /* isEditing = true; */
    indexPhase = index;
    indexSubphase = indexS;
    isEditing = currentProject.phases[index].subphases[indexS].isEditing;
    for (let i = 0; i < currentProject.phases[index].subphases.length; i++) {
      if (indexS !== i) {
        currentProject.phases[index].subphases[i].isEditing = false;
      }
    }
    resetCurrentSubphase();
    currentSubphase = copyObject(
      currentProject.phases[index].subphases[indexS]
    );
  };

  // Function that edits a phase.
  let editPhase = (index) => {
    currentPhase.isEditing = false;
    currentPhase.id = currentProject.phases[index].id;
    currentPhase.modified = true;
    currentProject.phases[index] = copyObject(currentPhase);
    currentProject = updateDates(currentProject);
    // Reset values.
    isEditing = false;
    indexPhase = -1;
    isPhase = 0;
    resetCurrentPhase();
    resetCurrentSubphase();
    sortPhases();
  };

  // Function that edits a milestone.
  let editMilestone = (index) => {
    currentMilestone.isEditing = false;
    currentMilestone.id = currentProject.milestones[index].id;
    currentMilestone.mtype = milestoneOptionsSel.value;
    currentMilestone.modified = true;
    currentProject.milestones[index] = copyObject(currentMilestone);
    currentProject = currentProject;
    // Reset values.
    isEditing = false;
    indexMilestone = -1;
    isPhase = 0;
    resetCurrentMilestone();
    sortMilestones();
  };

  // Function that edits a sub-phase.
  let editSubphase = (index, indexS) => {
    currentSubphase.isEditing = false;
    currentSubphase.id = currentProject.phases[index].subphases[indexS].id;
    currentSubphase.modified = true;
    currentProject.phases[index].subphases[indexS] =
      copyObject(currentSubphase);
    currentProject = currentProject;
    // Reset values.
    isEditing = false;
    indexPhase = -1;
    indexSubphase = -1;
    isPhase = 0;
    resetCurrentSubphase();
    resetCurrentPhase();
    sortSubphases(index);
  };

  // Function that cancels the editing process of a phase.
  let cancelEditPhase = (index) => {
    isEditing = false;
    currentProject.phases.forEach((p) => {
      p.isEditing = false;
    });
    currentProject = currentProject;
    // Reset values.
    indexPhase = -1;
    isPhase = 0;
    resetCurrentPhase();
    resetCurrentSubphase();
  };

  // Function that cancels the editing process of a milestone.
  let cancelEditMilestone = (index) => {
    isEditing = false;
    currentProject.milestones.forEach((m) => {
      m.isEditing = false;
    });
    currentProject = currentProject;
    // Reset values.
    indexMilestone = -1;
    isPhase = 0;
    resetCurrentMilestone();
  };

  // Function that cancels the editing process of a sub-phase.
  let cancelEditSubphase = (index, indexS) => {
    currentProject.phases[index].subphases.forEach((s) => {
      s.isEditing = false;
    });
    currentProject = currentProject;
    isEditing = false;
    indexPhase = -1;
    indexSubphase = -1;
    isPhase = 0;
    resetCurrentPhase();
    resetCurrentSubphase();
  };

  // Delete a phase selected.
  let deletePhase = (index) => {
    isEditing = false;
    currentProject.phases.splice(index, 1);
    currentProject = updateDates(currentProject);
    numPhases = currentProject.phases.length;
    indexPhase = -1;
    indexSubphase = -1;
    isPhase = 0;
    resetCurrentPhase();
    resetCurrentSubphase();
  };

  // Delete a milestone selected.
  let deleteMilestone = (index) => {
    isEditing = false;
    currentProject.milestones.splice(index, 1);
    currentProject = currentProject;
    indexMilestone = -1;
    isPhase = 0;
    resetCurrentMilestone();
  };

  // Delete a sub-phase selected.
  let deleteSubphase = (index, indexS) => {
    indexPhase = index;
    indexSubphase = indexS;
    currentProject.phases[index].subphases.splice(indexS, 1);
    currentProject = currentProject;
    numSubphases = currentProject.phases[index].subphases.length;
    indexSubphase = -1;
    indexPhase = -1;
    isPhase = 0;
    isEditing = false;
  };

  // Load Scope List
  let loadScopeList = () => {
    scopeOptions = scopeList.map((s) => {
      return { label: s.name, value: s.id };
    });
  };

  // Sort phases by start date.
  let sortPhases = () => {
    currentProject.phases.sort(function (a, b) {
      if (
        moment(a.startDate, "DD/MM/YYYY").format("YYYYMMDD") >
        moment(b.startDate, "DD/MM/YYYY").format("YYYYMMDD")
      ) {
        return 1;
      }
      if (
        moment(a.startDate, "DD/MM/YYYY").format("YYYYMMDD") <
        moment(b.startDate, "DD/MM/YYYY").format("YYYYMMDD")
      ) {
        return -1;
      }
      return 0;
    });
    currentProject.phases.forEach((phase) => {
      phase.subphases.sort(function (a, b) {
        if (
          moment(a.startDate, "DD/MM/YYYY").format("YYYYMMDD") >
          moment(b.startDate, "DD/MM/YYYY").format("YYYYMMDD")
        ) {
          return 1;
        }
        if (
          moment(a.startDate, "DD/MM/YYYY").format("YYYYMMDD") <
          moment(b.startDate, "DD/MM/YYYY").format("YYYYMMDD")
        ) {
          return -1;
        }
        return 0;
      });
    });
    currentProject = currentProject;
  };

  // Sort milestones by start date.
  let sortMilestones = () => {
    currentProject.milestones.sort(function (a, b) {
      if (
        moment(a.date, "DD/MM/YYYY").format("YYYYMMDD") >
        moment(b.date, "DD/MM/YYYY").format("YYYYMMDD")
      ) {
        return 1;
      }
      if (
        moment(a.date, "DD/MM/YYYY").format("YYYYMMDD") <
        moment(b.date, "DD/MM/YYYY").format("YYYYMMDD")
      ) {
        return -1;
      }
      return 0;
    });
    currentProject = currentProject;
  };

  // Sort subphases by start date.
  let sortSubphases = (index) => {
    currentProject.phases[index].subphases.sort(function (a, b) {
      if (
        moment(a.startDate, "DD/MM/YYYY").format("YYYYMMDD") >
        moment(b.startDate, "DD/MM/YYYY").format("YYYYMMDD")
      ) {
        return 1;
      }
      if (
        moment(a.startDate, "DD/MM/YYYY").format("YYYYMMDD") <
        moment(b.startDate, "DD/MM/YYYY").format("YYYYMMDD")
      ) {
        return -1;
      }
      return 0;
    });
    currentProject = currentProject;
  };

  // Add a new used scope.
  let addUsedScope = () => {
    if (scopeSelected) {
      const scope = scopeList.find((s) => {
        return s.id === scopeSelected.value;
      });
      if (scope) {
        scope.phases.forEach((p) => {
          const phase = {
            id: accId,
            name: p.name,
            startDate: moment(scopeStartDate, "DD/MM/YYYY")
              .add(p.offset, "days")
              .format("DD/MM/YYYY"),
            endDate: moment(scopeStartDate, "DD/MM/YYYY")
              .add(p.offset, "days")
              .add(p.days - 1, "days")
              .format("DD/MM/YYYY"),
            isEditing: false,
            notAvailable: false,
            type: "phase",
            scopeId: scope.id,
            subphases: [],
          };
          accId--;
          currentProject.phases.push(phase);
          p.subphases.forEach((sp) => {
            const subphase = {
              id: accId,
              name: sp.name,
              isEditing: false,
              startDate: moment(scopeStartDate, "DD/MM/YYYY")
                .add(sp.offset, "days")
                .format("DD/MM/YYYY"),
              endDate: moment(scopeStartDate, "DD/MM/YYYY")
                .add(sp.offset, "days")
                .add(sp.days - 1, "days")
                .format("DD/MM/YYYY"),
              type: "subphase",
            };
            accId--;
            phase.subphases.push(subphase);
          });
        });
        currentProject.phases = currentProject.phases;
        currentProject = currentProject;
        numPhases = currentProject.phases.length;
        resetCurrentPhase();
        isPhase = 0;
        sortPhases();

        const pos = usedScopesList.findIndex((item) => {
          return item.value === scopeSelected.value;
        });
        if (pos === -1) {
          usedScopesList.push(scopeSelected);
          usedScopesList = usedScopesList;
        }
      }
    }
  };

  // Delete the scope and associated phases in currentProject.
  let deletePhaseFromScope = (index) => {
    // Eliminate the phases of the current project.
    currentProject.phases = currentProject.phases.filter(
      (p) => p.scopeId !== usedScopesList[index].value
    );
    numPhases = currentProject.phases.length;
    currentProject = currentProject;
    // Remove scope from the list of used scopes.
    usedScopesList.splice(index, 1);
    usedScopesList = usedScopesList;
    resetCurrentPhase();
  };

  // Add a new scope.
  let addNewScope = async () => {
    const phases = copyObject(currentProject.phases);
    let filteredPhases = phases.reduce((acc, curr) => {
      if (curr.startDate !== "" && curr.endDate !== "") {
        acc.push(curr);
      }
      return acc;
    }, []);
    filteredPhases.forEach((p) => {
      p.subphases = p.subphases.reduce((acc, curr) => {
        if (curr.startDate !== "" && curr.endDate !== "") {
          acc.push(curr);
        }
        return acc;
      }, []);
    });
    if (filteredPhases.length > 0) {
      let minDate = moment(filteredPhases[0].startDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      filteredPhases.forEach((p) => {
        let start = moment(p.startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
        if (start < minDate) {
          minDate = start;
        }
        p.subphases.forEach((sp) => {
          start = moment(sp.startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
          if (start < minDate) {
            minDate = start;
          }
        });
      });

      const minDateM = moment(minDate, "YYYY-MM-DD");

      let scopes = [];
      filteredPhases.forEach((p) => {
        let offset = moment(p.startDate, "DD/MM/YYYY").diff(minDateM, "days");
        let days =
          moment(p.endDate, "DD/MM/YYYY").diff(
            moment(p.startDate, "DD/MM/YYYY"),
            "days"
          ) + 1;
        let scope = {
          id: accId,
          name: p.name,
          days,
          offset,
          subphases: [],
        };
        accId--;
        p.subphases.forEach((sp) => {
          offset = moment(sp.startDate, "DD/MM/YYYY").diff(minDateM, "days");
          days =
            moment(sp.endDate, "DD/MM/YYYY").diff(
              moment(sp.startDate, "DD/MM/YYYY"),
              "days"
            ) + 1;
          scope.subphases.push({
            id: accId,
            name: sp.name,
            days,
            offset,
          });
          accId--;
        });
        scopes.push(scope);
      });

      //console.log(scopes);

      let response = await post(
        `insert into scope (name)
          values ('${currentScope.name}')`
      );

      const scopeId = response[0];

      for (let i = 0; i < scopes.length; i++) {
        let scope = scopes[i];
        response = await post(
          `insert into scopePhase (name, days, offset, scope)
            values('${scope.name}', ${scope.days}, ${scope.offset}, ${scopeId})`
        );
        scope.id = response[0];

        for (let j = 0; j < scope.subphases.length; j++) {
          let subscope = scope.subphases[j];
          response = await post(
            `insert into scopePhase (name, days, offset, parentPhase, scope)
            values('${subscope.name}', ${subscope.days}, ${subscope.offset}, ${scope.id}, ${scopeId})`
          );

          subscope.id = response[0];
        }
      }

      currentProject.phases.forEach((p) => {
        p.scopeId = scopeId;
      });

      currentProject = currentProject;

      await listScopes();
      loadScopeList();

      usedScopesList = [
        {
          value: scopeId,
          label: currentScope.name,
        },
      ];
    }
  };

  // Add a new project manager.
  let addNewProjectManager = () => {
    if (projectManagerSelected) {
      const contact = currentProject.projectManager.find((item) => {
        return item.value === projectManagerSelected.value;
      });
      if (!contact) {
        currentProject.projectManager.push(projectManagerSelected);
        currentProject.projectManager.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });
        currentProject = currentProject;
        projectManagerSelected = null;
      }
    }
  };

  let changeSelectedTeam = () => {
    selectedWorks = {
      name: personTeamSelected.label,
      image: personTeamSelected.image,
      dates: [],
    };
    if (currentProject.start && currentProject.end) {
      const fWorks = works.filter((item) => {
        return (
          item.people === personTeamSelected.value &&
          item.project !== currentProject.id &&
          currentProject.start &&
          currentProject.end &&
          ((item.start >= currentProject.start &&
            item.start <= currentProject.end) ||
            (item.end >= currentProject.start &&
              item.end <= currentProject.end))
        );
      });
      selectedWorks.dates = fWorks.map((item) => {
        return {
          project: item.projectName,
          start: item.start,
          end: item.end,
        };
      });
      selectedWorks.dates.forEach((item) => {
        if (item.start < currentProject.start) {
          item.start = currentProject.start;
        }
        if (item.end > currentProject.end) {
          item.end = currentProject.end;
        }
      });
    }
    selectedWorks = selectedWorks;
  };

  // Add a new person to the team.
  let addNewPersonTeam = () => {
    /*if (personTeamSelected) {
      const contact = currentProject.team.find((item) => {
        return item.value === personTeamSelected.value;
      });
      if (!contact) {
        currentProject.team.push(personTeamSelected);
        currentProject.team.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });
        currentProject = currentProject;
        personTeamSelected = null;
      }
    }*/
    if (personTeamSelected && teamRoleSelected.trim() !== "") {
      const contact = {
        id: accId,
        contactId: personTeamSelected.value,
        name: personTeamSelected.label,
        image: personTeamSelected.image,
        role: teamRoleSelected,
        start: moment(roleStart, "DD/MM/YYYY").format("YYYY-MM-DD"),
        end: moment(roleEnd, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      accId--;
      currentProject.team.push(contact);
      currentProject = currentProject;
      personTeamSelected = null;
      selectedWorks = null;
    }
  };

  // Remove a project manager.
  let deleteProjectManager = (pos) => {
    currentProject.projectManager.splice(pos, 1);
    currentProject = currentProject;
  };

  // Remove one person from the team.
  let deletePersonTeam = (pos) => {
    currentProject.team.splice(pos, 1);
    currentProject = currentProject;
  };

  // Add a new stakeholder.
  let addNewStakeholder = () => {
    if (companySelected) {
      stakeholder = "";
      stakeholder = {
        id: companySelected.value,
        company: companySelected.label,
        role: stakeholdersOptionsSel.value,
        team: [],
      };
      currentProject.stakeholders.push(stakeholder);
      currentProject = currentProject;
      companySelected = null;
      roleSelected = null;
    }
  };

  // Remove a stakeholder.
  let deleteStakeholder = (pos) => {
    currentProject.stakeholders.splice(pos, 1);
    currentProject = currentProject;
  };

  // Add a new person with a role to the stakeholder.
  let addStakeholderPeople = (index) => {
    if (currentProject.stakeholders[index].selectedPerson) {
      const contact = currentProject.stakeholders[index].team.find((item) => {
        //
        return (
          item.selectedPerson.value ===
          currentProject.stakeholders[index].selectedPerson.value
        );
      });
      if (!contact) {
        currentProject.stakeholders[index].team.push({
          selectedPerson: currentProject.stakeholders[index].selectedPerson,
          /*selectedRole: currentProject.stakeholders[index].selectedRole,*/
          selectedRole:
            currentProject.stakeholders[index].role === "Client"
              ? clientsOptionsSel.value
              : consultantsOptionsSel.value,
        });
        currentProject.stakeholders[index].team.sort(function (a, b) {
          if (a.selectedPerson.label > b.selectedPerson.label) {
            return 1;
          }
          if (a.selectedPerson.label < b.selectedPerson.label) {
            return -1;
          }
          return 0;
        });
        currentProject.stakeholders[index].team =
          currentProject.stakeholders[index].team;
        currentProject.stakeholders[index].selectedPerson = null;
      }
    }
  };

  // Remove one person with a stakeholder role.
  let deleteStakeholderPeople = (index, indexP) => {
    currentProject.stakeholders[index].team.splice(indexP, 1);
    currentProject.stakeholders[index].team =
      currentProject.stakeholders[index].team;
  };

  let createApplication = async () => {
    if (newApplication.trim() !== "") {
      const application = applicationOptions.find((item) => {
        return (
          item.label.trim().toUpperCase() ===
          newApplication.trim().toUpperCase()
        );
      });
      if (application) {
        applicationOptionsSel = application;
      } else {
        const response = await post(
          `insert into applications (name)
          values ('${newApplication}')`
        );

        const id = response[0];

        await listApplications();
        applicationOptionsSel = applicationOptions.find((item) => {
          return item.value === id;
        });
      }
      newApplication = "";
    }
  };

  let changeApplication = () => {
    listKeys();
  };

  let createKey = async () => {
    if (newKey.trim() !== "") {
      const response = await post(
        `insert into applicationKeys (userKey, application, global)
          values ('${newKey}', ${applicationOptionsSel.value}, 0)`
      );
      const id = response[0];

      await listKeys();
      keyOptionsSel = keyOptions.find((item) => {
        return item.value === id;
      });
    }
    newKey = "";
  };

  let addKey = () => {
    if (keyOptionsSel) {
      const keys = currentProject.licenses.reduce((acc, curr) => {
        curr.keys.forEach((k) => {
          acc.push(k);
        });
        return acc;
      }, []);
      const pos = keys.findIndex((k) => {
        return k.id === keyOptionsSel.value;
      });
      if (pos === -1) {
        let application = currentProject.licenses.find((l) => {
          return l.id === applicationOptionsSel.value;
        });
        if (!application) {
          application = {
            id: applicationOptionsSel.value,
            name: applicationOptionsSel.label,
            keys: [],
          };
          currentProject.licenses.push(application);
        }
        const key = {
          id: keyOptionsSel.value,
          key: keyOptionsSel.label,
        };
        application.keys.push(key);
        currentProject = currentProject;
      }
    }
  };

  let removeKey = (id) => {
    currentProject.licenses.forEach((l) => {
      l.keys = l.keys.filter((k) => {
        return k.id !== id;
      });
    });
    currentProject = currentProject;
  };

  let editProject = (id) => {
    const project = projects.find((p) => {
      return p.id === id;
    });
    if (project) {
      resetProcess();

      const cProject = copyObject(project);
      currentProject = copyObject(defaultProject);

      // Info

      currentProject.id = cProject.id;
      currentProject.active = cProject.active;
      currentProject.name = cProject.name;
      currentProject.nickName = cProject.nickName;
      currentProject.client = cProject.lastName;
      currentProject.company = cProject.company;
      currentProject.address = cProject.address;
      currentProject.latitude = cProject.latitude;
      currentProject.longitude = cProject.longitude;
      currentProject.country = cProject.country;
      currentProject.city = cProject.city;
      currentProject.timeCode = cProject.timeLabitCode;
      currentProject.folderCode = cProject.folderLabitCode;
      /*currentProject.serverLink = cProject.serverUrl;
      currentProject.trello = cProject.trelloUrl;
      currentProject.slack = cProject.slackUrl;
      currentProject.sharepoint = cProject.sharepointUrl;
      currentProject.bim360 = cProject.bim360Url;
      currentProject.miro = cProject.miroUrl;*/
      currentProject.externalUrls = copyObject(cProject.externalUrls);
      currentProject.picImage = cProject.pic;
      currentProject.picPath = cProject.picPath;
      currentProject.statusHistory = copyObject(cProject.statusHistory);
      currentProject.start = cProject.start;
      currentProject.end = cProject.end;
      currentProject.isInternal = cProject.isInternal;
      currentProject.programType = cProject.programType;

      /*console.log(currentProject);
      console.log(cProject);*/

      onChangeClient();

      countriesSel = {
        value: currentProject.country,
        label: currentProject.country,
      };

      citySel = currentProject.city;
      setCityCodes();

      const pt = projectTypes.find((item) => {
        return item.value === currentProject.programType;
      });
      projectTypesSel = pt ? pt : projectTypes[0];

      // Phases

      usedScopesList = [];

      cProject.phases.forEach((p) => {
        let phase = {
          id: p.id,
          name: p.name,
          startDate: moment(p.start).format("DD/MM/YYYY"),
          endDate: moment(p.end).format("DD/MM/YYYY"),
          isEditing: false,
          notAvailable: false,
          scopeId: p.scope ? p.scope : "",
          type: "phase",
          subphases: [],
        };
        currentProject.phases.push(phase);
        p.subphases.forEach((s) => {
          const subphase = {
            id: s.id,
            name: s.name,
            startDate: moment(s.start).format("DD/MM/YYYY"),
            endDate: moment(s.end).format("DD/MM/YYYY"),
            isEditing: false,
            type: "subphase",
          };
          phase.subphases.push(subphase);
        });
        const pos = usedScopesList.findIndex((item) => {
          return item.value === phase.scopeId;
        });
        if (pos === -1) {
          const s = scopeList.find((item) => {
            return item.id === phase.scopeId;
          });
          if (s) {
            usedScopesList.push({
              value: s.id,
              label: s.name,
              duration: s.duration,
            });
          }
        }
      });

      usedScopesList = usedScopesList;

      numPhases = currentProject.phases.length;
      resetCurrentPhase();
      isPhase = 0;
      sortPhases();

      // Milestones

      cProject.milestones.forEach((m) => {
        let tokens = m.start.split(" ");
        const date = moment(tokens[0], "YYYY-MM-DD").format("DD/MM/YYYY");
        tokens = tokens[1].split(":");
        const startH = {
          value: tokens[0],
          label: tokens[0],
        };
        const startM = {
          value: tokens[1],
          label: tokens[1],
        };
        tokens = m.end.split(" ");
        tokens = tokens[1].split(":");
        const endH = {
          value: tokens[0],
          label: tokens[0],
        };
        const endM = {
          value: tokens[1],
          label: tokens[1],
        };
        const milestone = {
          id: m.id,
          name: m.name,
          mtype: m.mtype,
          allDay: m.allDay,
          date,
          startH,
          startM,
          endH,
          endM,
          isEditing: false,
          type: "milestone",
        };
        currentProject.milestones.push(milestone);
      });

      resetCurrentMilestone();
      isPhase = 0;
      sortMilestones();

      // Team

      cProject.people.forEach((p) => {
        if (!p.stakeholder) {
          const people = {
            id: p.id,
            contactId: p.peopleId,
            name: p.peopleName,
            role: p.role,
            start: p.start,
            end: p.end,
            image: p.peoplePic,
            modified: p.modified,
          };
          currentProject.team.push(people);
        }
      });

      cProject.companies.forEach((c) => {
        const company = {
          id: c.companyId,
          company: c.companyName,
          role: c.role,
          selectedPerson: null,
          selectedRole: "",
          team: [],
        };
        currentProject.stakeholders.push(company);
      });

      cProject.people.forEach((p) => {
        if (p.stakeholder) {
          let company = currentProject.stakeholders.find((item) => {
            return item.id === p.companyId;
          });
          if (company) {
            const people = {
              value: p.peopleId,
              label: p.peopleName,
              image: p.peoplePic,
            };
            company.team.push({
              selectedPerson: people,
              selectedRole: p.role,
            });
          }
        }
      });

      // Licenses

      currentProject.licenses = copyObject(cProject.licenses);

      // Creation / Modification Info

      currentProject.creationInfo = cProject.creationInfo;
      currentProject.modificationInfo = cProject.modificationInfo;

      currentProject = updateDates(currentProject);

      savedProject = copyObject(currentProject);

      menu = 1;
      mode = "edit";

      centerView = false;

      window.$("html").animate({ scrollTop: 0 }, "fast");
    }
  };

  let updateProject = async () => {
    let picUrl = "";
    let picPath = "";
    let projectPicName = "";

    const now = moment().format("YYYY-MM-DD HH:mm:ss");

    await post(
      `update projects set
        Name='${currentProject.name}',
        FolderLabitCode='${currentProject.folderCode}',
        TimeLabitCode='${currentProject.timeCode}',
        Nickname='${currentProject.nickName}',
        Client='${currentProject.client}',
        Company='${currentProject.company}',
        Address='${currentProject.address}',
        City='${citySel}',
        Country='${currentProject.country}',
        latitude='${currentProject.latitude}',
        longitude='${currentProject.longitude}',
        picUrl='',
        picPath='',
        isInternal=${currentProject.isInternal ? "1" : "0"},
        ProgramType='${currentProject.programType}',
        modificationDate='${now}',
        modificatedBy=${myId},
        active=${currentProject.active ? "1" : "0"}
        where project_id=${currentProject.id}`
    );

    // Image

    if (currentProject.picImage) {
      if ((typeof currentProject.picImage) === "object" && currentProject.picImage.extension !== undefined && currentProject.picImage.data !== undefined) {
        projectPicName = createProjectPicName(currentProject) + "." + currentProject.picImage.extension;
        picPath = PROJECTS_REMOTE_URL + "/" + projectPicName;
        picUrl = LABIT_PUBLIC_FOLDER + picPath;

        await rpost("UploadPublicFile", {
          path: PROJECTS_REMOTE_URL,
          fileName: projectPicName,
          fileData: currentProject.picImage.data,
        });

      } else if ((typeof currentProject.picImage) === "string" && currentProject.picPath.length > 0) {
        let filename = currentProject.picPath;
        const extension = filename.split("/")[2].split(".")[1];
        projectPicName = createProjectPicName(currentProject) + "." + extension;
        picPath = PROJECTS_REMOTE_URL + "/" + projectPicName;
        picUrl = LABIT_PUBLIC_FOLDER + picPath;
      }

      await post(
        `update projects
          set 
          picUrl='${picUrl}',
          picPath='${picPath}'
          where project_id=${currentProject.id}`
      );
    }

    /* Project Links */

    for (let i = 0; i < currentProject.externalUrls.length; i++) {
      let url = currentProject.externalUrls[i];
      if (url.id < 0) {
        if (url.type === "Server") {
          url.url = url.url.replace(/\\/g, "\\\\");
        }
      }
    }

    // Adding

    for (let i = 0; i < currentProject.externalUrls.length; i++) {
      const url = currentProject.externalUrls[i];
      if (url.id < 0) {
        await post(
          `insert into externalUrls (url, type, project)
          values ('${url.url}', '${url.type}', ${currentProject.id})`
        );
      }
    }

    // Removing

    for (let i = 0; i < savedProject.externalUrls.length; i++) {
      const url = savedProject.externalUrls[i];
      const pos = currentProject.externalUrls.findIndex((item) => {
        return item.id === url.id;
      });
      if (pos === -1) {
        await post(
          `delete from externalUrls
            where id=${url.id}`
        );
      }
    }

    /* Status History */

    // Adding

    for (let i = 0; i < currentProject.statusHistory.length; i++) {
      const status = currentProject.statusHistory[i];
      if (status.id < 0) {
        const end = status.end ? `'${status.end}'` : "null";
        await post(
          `insert into statusHistory (start, end, status, project)
            values ('${status.start}', ${end}, ${status.statusId}, ${currentProject.id})`
        );
      }
    }

    // Updating

    for (let i = 0; i < currentProject.statusHistory.length; i++) {
      const status = currentProject.statusHistory[i];
      if (status.modified) {
        const end = status.end ? `'${status.end}'` : "null";
        await post(
          `update statusHistory set
            start='${status.start}',
            end=${end},
            status=${status.statusId}
            where id=${status.id}`
        );
      }
    }

    // Deleting

    for (let i = 0; i < savedProject.statusHistory.length; i++) {
      const cStatus = savedProject.statusHistory[i];
      const status = currentProject.statusHistory.find((s) => {
        return s.id === cStatus.id;
      });
      if (!status) {
        await post(
          `delete from statusHistory
            where id=${cStatus.id}`
        );
      }
    }

    /* Phases */

    // Adding

    for (let i = 0; i < currentProject.phases.length; i++) {
      let phase = currentProject.phases[i];
      let scopeId = phase.scopeId === "" ? "null" : `${phase.scopeId}`;
      if (phase.id < 0) {
        let start =
          phase.startDate === ""
            ? "null"
            : `'${moment(phase.startDate, "DD/MM/YYYY").format("YYYY-MM-DD")}'`;
        let end =
          phase.endDate === ""
            ? "null"
            : `'${moment(phase.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")}'`;
        let response = await post(
          `insert into phase (name, start, end, project, scope)
          values ('${phase.name}', ${start}, ${end}, ${currentProject.id}, ${scopeId})`
        );
        phase.id = response[0];
        for (let j = 0; j < phase.subphases.length; j++) {
          let subphase = phase.subphases[j];
          start =
            subphase.startDate === ""
              ? "null"
              : `'${moment(subphase.startDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )}'`;
          end =
            subphase.endDate === ""
              ? "null"
              : `'${moment(subphase.endDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )}'`;
          response = await post(
            `insert into phase (name, start, end, phaseParent, project, scope)
          values ('${subphase.name}', ${start}, ${end}, ${phase.id}, ${currentProject.id}, ${scopeId})`
          );
          subphase.id = response[0];
        }
      } else {
        for (let j = 0; j < phase.subphases.length; j++) {
          let subphase = phase.subphases[j];
          if (subphase.id < 0) {
            let start =
              subphase.startDate === ""
                ? "null"
                : `'${moment(subphase.startDate, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )}'`;
            let end =
              subphase.endDate === ""
                ? "null"
                : `'${moment(subphase.endDate, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )}'`;
            let response = await post(
              `insert into phase (name, start, end, phaseParent, project, scope)
          values ('${subphase.name}', ${start}, ${end}, ${phase.id}, ${currentProject.id}, ${scopeId})`
            );
            subphase.id = response[0];
          }
        }
      }
    }

    // Updating

    for (let i = 0; i < currentProject.phases.length; i++) {
      let phase = currentProject.phases[i];
      if (phase.modified) {
        await post(
          `update phase set
            name='${phase.name}',
            start='${moment(phase.startDate, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )}',
            end='${moment(phase.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")}'
            where id=${phase.id}`
        );
      }
      for (let j = 0; j < phase.subphases.length; j++) {
        let subphase = phase.subphases[j];
        if (subphase.modified) {
          await post(
            `update phase set
              name='${subphase.name}',
              start='${moment(subphase.startDate, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )}',
              end='${moment(subphase.endDate, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )}'
              where id=${subphase.id}`
          );
        }
      }
    }

    // Deleting

    for (let i = 0; i < savedProject.phases.length; i++) {
      const cPhase = savedProject.phases[i];
      const phase = currentProject.phases.find((p) => {
        return p.id === cPhase.id;
      });
      if (!phase) {
        await post(
          `delete from phase
            where id=${cPhase.id}`
        );
      } else {
        for (let j = 0; j < cPhase.subphases.length; j++) {
          const cSubphase = cPhase.subphases[j];
          const pos = phase.subphases.findIndex((s) => {
            return s.id === cSubphase.id;
          });
          if (pos === -1) {
            await post(
              `delete from phase
              where id=${cSubphase.id}`
            );
          }
        }
      }
    }

    /* Milestones */

    // Adding

    for (let i = 0; i < currentProject.milestones.length; i++) {
      let milestone = currentProject.milestones[i];
      if (milestone.id < 0) {
        let start = moment(milestone.date, "DD/MM/YYYY").format("YYYY-MM-DD");
        let end = moment(milestone.date, "DD/MM/YYYY").format("YYYY-MM-DD");
        if (milestone.allDay) {
          start = start + " 00:00:00";
          end = end + " 23:59:59";
        } else {
          start =
            start +
            " " +
            milestone.startH.value +
            ":" +
            milestone.startM.value +
            ":00";
          end =
            end +
            " " +
            milestone.endH.value +
            ":" +
            milestone.endM.value +
            ":00";
        }

        let response = await post(
          `insert into milestone (name, isAllDay, start, end, type, project)
          values ('${milestone.name}', ${
            milestone.allDay ? 1 : 0
          }, '${start}', '${end}', '${milestone.mtype}', ${currentProject.id})`
        );

        milestone.id = response[0];
      }
    }

    // Updating

    for (let i = 0; i < currentProject.milestones.length; i++) {
      let milestone = currentProject.milestones[i];
      if (milestone.modified) {
        let start = moment(milestone.date, "DD/MM/YYYY").format("YYYY-MM-DD");
        let end = moment(milestone.date, "DD/MM/YYYY").format("YYYY-MM-DD");
        if (milestone.allDay) {
          start = start + " 00:00:00";
          end = end + " 23:59:59";
        } else {
          start =
            start +
            " " +
            milestone.startH.value +
            ":" +
            milestone.startM.value +
            ":00";
          end =
            end +
            " " +
            milestone.endH.value +
            ":" +
            milestone.endM.value +
            ":00";
        }

        await post(
          `update milestone set
            name='${milestone.name}',
            isAllDay=${milestone.allDay ? 1 : 0},
            start='${start}',
            end='${end}',
            type='${milestone.mtype}'
            where id=${milestone.id}`
        );
      }
    }

    // Deleting

    for (let i = 0; i < savedProject.milestones.length; i++) {
      const cMilestone = savedProject.milestones[i];
      const pos = currentProject.milestones.findIndex((item) => {
        return item.id === cMilestone.id;
      });
      if (pos === -1) {
        await post(
          `delete from milestone
            where id=${cMilestone.id}`
        );
      }
    }

    /* Team */

    // Adding / Updating

    for (let i = 0; i < currentProject.team.length; i++) {
      const people = currentProject.team[i];
      if (parseInt(people.id) < 0) {
        await post(
          `insert into projectPeople (people, project, role, start, end, active, stakeholder)
          values (${people.contactId}, ${currentProject.id}, '${people.role}', '${people.start}', '${people.end}', 1, 0)`
        );
      } else {
        if (people.modified) {
          await post(
            `update projectPeople set
              start='${people.start}',
              end='${people.end}',
              role='${people.role}'
              where id=${people.id}`
          );
        }
      }
    }

    for (let i = 0; i < currentProject.stakeholders.length; i++) {
      const company = currentProject.stakeholders[i];
      const sCompany = savedProject.stakeholders.find((item) => {
        return item.id === company.id;
      });
      if (!sCompany) {
        await post(
          `insert into projectCompany (company, project, role)
            values (${company.id}, ${currentProject.id}, '${company.role}')`
        );
        for (let j = 0; j < company.team.length; j++) {
          const people = company.team[j];
          await post(
            `insert into projectPeople (people, project, role, active, stakeholder)
              values (${people.selectedPerson.value}, ${currentProject.id}, '${people.selectedRole}', 1, 1)`
          );
        }
      } else {
        for (let j = 0; j < company.team.length; j++) {
          const people = company.team[j];
          const pos = sCompany.team.findIndex((item) => {
            return (
              item.selectedPerson.value === people.selectedPerson.value &&
              item.selectedRole === people.selectedRole
            );
          });
          if (pos === -1) {
            await post(
              `insert into projectPeople (people, project, role, active, stakeholder)
                values (${people.selectedPerson.value}, ${currentProject.id}, '${people.selectedRole}', 1, 1)`
            );
          }
        }
      }
    }

    // Deleting

    for (let i = 0; i < savedProject.team.length; i++) {
      const people = savedProject.team[i];
      const pos = currentProject.team.findIndex((item) => {
        return item.id === people.id;
      });
      if (pos === -1) {
        await post(
          `delete from projectPeople
            where id=${people.id}`
        );
      }
    }

    for (let i = 0; i < savedProject.stakeholders.length; i++) {
      const company = savedProject.stakeholders[i];
      const sCompany = currentProject.stakeholders.find((item) => {
        return item.id === company.id;
      });
      if (!sCompany) {
        await post(
          `delete from projectCompany
            where company=${company.id} and project=${currentProject.id}`
        );
        for (let j = 0; j < company.team.length; j++) {
          const people = company.team[j];
          await post(
            `delete from projectPeople
              where people=${people.selectedPerson.value} and project=${currentProject.id}`
          );
        }
      } else {
        for (let j = 0; j < company.team.length; j++) {
          const people = company.team[j];
          const pos = sCompany.team.findIndex((item) => {
            return (
              item.selectedPerson.value === people.selectedPerson.value &&
              item.selectedRole === people.selectedRole
            );
          });
          if (pos === -1) {
            await post(
              `delete from projectPeople
              where people=${people.selectedPerson.value} and project=${currentProject.id} and role='${people.selectedRole}'`
            );
          }
        }
      }
    }

    /* Licenses */

    // Adding

    for (let i = 0; i < currentProject.licenses.length; i++) {
      const application = currentProject.licenses[i];
      const sApplication = savedProject.licenses.find((item) => {
        return item.id === application.id;
      });
      if (!sApplication) {
        for (let j = 0; j < application.keys.length; j++) {
          const key = application.keys[j];
          await post(
            `insert into projectKeys (applicationKey, project)
            values (${key.id}, ${currentProject.id})`
          );
        }
      } else {
        for (let j = 0; j < application.keys.length; j++) {
          const key = application.keys[j];
          const pos = sApplication.keys.findIndex((item) => {
            return item.id === key.id;
          });
          if (pos === -1) {
            await post(
              `insert into projectKeys (applicationKey, project)
            values (${key.id}, ${currentProject.id})`
            );
          }
        }
      }
    }

    // Deleting

    for (let i = 0; i < savedProject.licenses.length; i++) {
      const application = savedProject.licenses[i];
      const sApplication = currentProject.licenses.find((item) => {
        return item.id === application.id;
      });
      if (!sApplication) {
        for (let j = 0; j < application.keys.length; j++) {
          const key = application.keys[j];
          await post(
            `delete from projectKeys
              where applicationKey=${key.id} and project=${currentProject.id}`
          );
        }
      } else {
        for (let j = 0; j < application.keys.length; j++) {
          const key = application.keys[j];
          const pos = sApplication.keys.findIndex((item) => {
            return item.id === key.id;
          });
          if (pos === -1) {
            await post(
              `delete from projectKeys
              where applicationKey=${key.id} and project=${currentProject.id}`
            );
          }
        }
      }
    }

    // Updating City Codes

    if (
      citySel.trim() !== "" &&
      (currentProject.digit2.trim() !== "" ||
        currentProject.digit3.trim() !== "")
    ) {
      await updateCityCode(
        citySel,
        currentProject.digit2,
        currentProject.digit3
      );
      cityCodes = await getCityCodes();
      cities = await getCities();
    }

    // License unassignments

    let proj1 = copyObject(currentProject);
    let proj2 = copyObject(savedProject);
    updateCurrentStatus(proj1);
    updateCurrentStatus(proj2);
    if (proj1.currentStatus !== proj2.currentStatus) {
      await post(
        `delete from licenseAssignments
          where project=${currentProject.id}`
      );
    }

    await getProjects();
    //applyFilter();
    closeProcess();

    refreshProjects = true;
  };

  let deleteProject = async () => {
    await post(
      `delete from projects
        where project_id=${currentProject.id}`
    );

    closeProcess();
    await getProjects();
    //applyFilter();
  };

  let addStatusHistory = () => {
    currentProject.statusHistory.push({
      id: accId,
      name: selectedStatusP.label,
      statusId: selectedStatusP.value.id,
      start: moment(statusStart, "DD/MM/YYYY").format("YYYY-MM-DD"),
      end:
        statusEnd === "Sin fecha final"
          ? null
          : moment(statusEnd, "DD/MM/YYYY").format("YYYY-MM-DD"),
      active: selectedStatusP.value.active,
      modified: false,
    });
    accId--;
    currentProject.statusHistory.sort((a, b) => {
      if (a.start < b.start) {
        return -1;
      }
      if (a.start > b.start) {
        return 1;
      }
      return 0;
    });

    currentProject = updateCurrentStatus(currentProject);
  };

  let openEditStatusHistory = (id) => {
    const status = currentProject.statusHistory.find((item) => {
      return item.id === id;
    });
    if (status) {
      selectedStatusP = {
        label: status.name,
        value: {
          id: status.statusId,
          name: status.name,
          active: status.active,
        },
      };
      statusStart = moment(status.start, "YYYY-MM-DD").format("DD/MM/YYYY");
      statusEnd = status.end
        ? moment(status.end, "YYYY-MM-DD").format("DD/MM/YYYY")
        : "Sin fecha final";
      editingStatus = id;
    }
  };

  let closeEditStatusHistory = () => {
    editingStatus = null;
    selectedStatusP = statusP[0];
    statusStart = moment().format("DD/MM/YYYY");
    statusEnd = moment().format("DD/MM/YYYY");
  };

  let editStatusHistory = () => {
    let status = currentProject.statusHistory.find((item) => {
      return item.id === editingStatus;
    });
    if (status) {
      status.name = selectedStatusP.label;
      status.statusId = selectedStatusP.value.id;
      status.start = moment(statusStart, "DD/MM/YYYY").format("YYYY-MM-DD");
      status.end =
        statusEnd === "Sin fecha final"
          ? null
          : moment(statusEnd, "DD/MM/YYYY").format("YYYY-MM-DD");
      status.active = selectedStatusP.value.active;
      status.modified = true;
      currentProject.statusHistory.sort((a, b) => {
        if (a.start < b.start) {
          return -1;
        }
        if (a.start > b.start) {
          return 1;
        }
        return 0;
      });
      currentProject = updateCurrentStatus(currentProject);
    }
    closeEditStatusHistory();
  };

  let removeStatusHistory = (id) => {
    if (editingStatus !== id) {
      currentProject.statusHistory = currentProject.statusHistory.filter(
        (item) => {
          return item.id !== id;
        }
      );
      currentProject = currentProject;
    }
  };

  let resetProcess = () => {
    companySelected = null;
    roleSelected = null;
    usedScopesList = [];
    menu = 1;
    numPhases = 0;
    scopeSelected = null;
    projectManagerSelected = null;
    personTeamSelected = null;
    saveNewScope = false;
    newScopeSaved = false;
    indexPhase = -1;
    indexSubphase = -1;
    indexMilestone = -1;
    isEditing = false;
    isPhase = 0;
    currentProject = null;
    savedProject = null;
    countriesSel = null;
    projectTypesSel = projectTypes[0];
    citySel = "";
    clientCode = "";
    mapsURL = "";
    newApplication = "";
    newKey = "";
    error = false;
    errorName = false;
    selectedWorks = null;
    externalUrlOptionsSel = externalUrlOptions[0];
    externaUrlOptionUrl = "";
    showRemove(false);
  };

  let closeProcess = () => {
    resetProcess();
    mode = "list";
    if (projectManagerMode) {
      openURL("/dashboard-project", true);
    }
  };

  let showRemove = (visible) => {
    showRemoveDialog = visible;
  };

  let newProject = () => {
    if (mode === "list") {
      resetProcess();
      currentProject = copyObject(defaultProject);
      currentProject.id = accId;
      accId--;
      currentProject.statusHistory.push({
        id: accId,
        name: statusP[0].label,
        statusId: statusP[0].value.id,
        start: moment().format("YYYY-MM-DD"),
        end: null,
        active: statusP[0].value.active,
        modified: false,
      });
      accId--;
      currentProject = currentProject;
      mode = "new";
    }
  };

  let updateCode1Filter = () => {
    code1Options = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = code1Options.findIndex((item) => {
          return item.value === project.timeLabitCode;
        });
        if (pos === -1) {
          code1Options.push({
            value: project.timeLabitCode,
            label: project.timeLabitCode,
          });
        }
        if (!selectedCode1) {
          selectedCode1 = code1Options[0];
        }
      }
    });
  };

  let updateCode2Filter = () => {
    code2Options = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = code2Options.findIndex((item) => {
          return item.value === project.folderLabitCode;
        });
        if (pos === -1) {
          code2Options.push({
            value: project.folderLabitCode,
            label: project.folderLabitCode,
          });
        }
        if (!selectedCode2) {
          selectedCode2 = code2Options[0];
        }
      }
    });
  };

  let updateClientFilter = () => {
    clientOptions = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = clientOptions.findIndex((item) => {
          return item.value === project.lastName.toUpperCase();
        });
        if (pos === -1) {
          clientOptions.push({
            value: project.lastName.toUpperCase(),
            label: project.lastName.toUpperCase(),
          });
        }
        if (!selectedClient) {
          selectedClient = clientOptions[0];
        }
      }
    });
  };

  let updateStatusFilter = () => {
    statusOptions = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        if (project.currentStatus !== "") {
          let pos = statusOptions.findIndex((item) => {
            return item.value === project.currentStatus;
          });
          if (pos === -1) {
            statusOptions.push({
              value: project.currentStatus,
              label: project.currentStatus,
            });
          }
        }
      }
    });

    if (!selectedStatus) {
      selectedStatus = statusOptions[0];
    }
  };

  let updateTypeFilter = () => {
    typeOptions = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = typeOptions.findIndex((item) => {
          return item.value === project.programType;
        });
        if (pos === -1) {
          typeOptions.push({
            value: project.programType,
            label: project.programType,
          });
        }
        if (!selectedType) {
          selectedType = typeOptions[0];
        }
      }
    });
  };

  let updateLocationFilter = () => {
    locationOptions = [
      /*{
        value: -1,
        label: "ALL",
      },*/
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = locationOptions.findIndex((item) => {
          return item.value === project.country + " - " + project.city;
        });
        if (pos === -1) {
          locationOptions.push({
            value: project.country + " - " + project.city,
            label: project.country + " - " + project.city,
          });
        }
        if (!selectedLocation) {
          selectedLocation = locationOptions[0];
        }
      }
    });
  };

  let updateActiveFilter = () => {
    active = [
      {
        value: -1,
        label: "ALL",
      },
    ];

    projects.forEach((project) => {
      if (project.visible) {
        let pos = active.findIndex((item) => {
          return (
            (item.value === "Active" && project.active) ||
            (item.value === "No Active" && !project.active)
          );
        });
        if (pos === -1) {
          active.push({
            value: project.active ? "Active" : "No Active",
            label: project.active ? "Active" : "No Active",
          });
        }
        if (!selectedActive) {
          selectedActive = active[2] ? active[2] : active[0];
        }
      }
    });
  };

  let applyFilter = () => {
    projects.forEach((project) => {
      project.visible = true;
    });

    if (searchFilter !== "") {
      const SearchFilter = searchFilter.toUpperCase();
      projects.forEach((item) => {
        if (item.visible) {
          const searchTerms = prepareSearch(searchFilter);
          if (!item.searchTerms.includes(searchTerms)) {
            item.visible = false;
          }
        }
      });
    } //else {
    // Time Labit Code

    if (selectedCode1 && selectedCode1.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedCode1.findIndex((c) => {
            return c === item.timeLabitCode;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Folder Labit Code

    if (selectedCode2 && selectedCode2.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedCode2.findIndex((c) => {
            return c === item.folderLabitCode;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Client

    if (selectedClient && selectedClient.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedClient.findIndex((c) => {
            return c.toUpperCase() === item.lastName.toUpperCase();
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Status

    if (selectedStatus && selectedStatus.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedStatus.findIndex((c) => {
            return c === item.currentStatus;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Type

    if (selectedType && selectedType.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedType.findIndex((c) => {
            return c === item.programType;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Location

    if (selectedLocation && selectedLocation.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedLocation.findIndex((c) => {
            return c === item.country + " - " + item.city;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Active

    if (selectedActive && selectedActive.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedActive.findIndex((c) => {
            return (
              (item.active && c === "Active") ||
              (!item.active && c === "Inactive")
            );
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Internals

    if (selectedInternals && selectedInternals.length > 0) {
      projects.forEach((item) => {
        if (item.visible) {
          const pos = selectedInternals.findIndex((c) => {
            return (
              (item.isInternal && c === "Internal") ||
              (!item.isInternal && c === "External")
            );
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    //}

    projects = projects;
  };

  let clearFilters = () => {
    searchFilter = "";
    selectedCode1 = [];
    selectedCode2 = [];
    selectedClient = [];
    selectedStatus = [];
    selectedType = [];
    selectedLocation = [];
    selectedActive = [];
    selectedInternals = [];
  };

  let checkErrors = () => {
    errorName = false;
    error = false;

    if (currentProject.name === "") {
      errorName = true;
    }

    error = errorName;
  };

  let setPage = (page) => {
    menu = page;
  };

  let addUrl = () => {
    if (externaUrlOptionUrl.trim() !== "") {
      if (
        externalUrlOptionsSel.value !== "Server" &&
        !externaUrlOptionUrl.startsWith("http")
      ) {
        externaUrlOptionUrl = "https://" + externaUrlOptionUrl;
      }
      currentProject.externalUrls.push({
        id: accId,
        url: externaUrlOptionUrl,
        type: externalUrlOptionsSel.value,
      });
      accId--;
      currentProject = currentProject;
      externaUrlOptionUrl = "";
    }
  };

  let removeUrl = (id) => {
    currentProject.externalUrls = currentProject.externalUrls.filter((item) => {
      return item.id !== id;
    });
    currentProject = currentProject;
  };

  let openNewClient = (show) => {
    showNewClient = show;
    if (showNewClient) {
      newClient = "";
    }
  };

  let saveNewClient = async () => {
    if (newClient !== "") {
      const pos = clientList.findIndex((item) => {
        return item.trim().toUpperCase() === newClient.trim().toUpperCase();
      });
      if (pos === -1) {
        const response = await post(
          `select category_id 
            from categories
            where Name='Clients'`
        );
        const clientCatId = response[0].category_id;
        await insertCompanyBasic(newClient, clientCatId);

        clientList.push(newClient);
        clientList = clientList;

        refreshCompanyList = true;
      }
      currentProject.client = newClient;
      openNewClient(false);
    }
  };

  let checkFinishedSelected = () => {
    if (selectedStatusP.value.name === "Finished") {
      statusEnd = "Sin fecha final";
    }
  };

  let restartProjectManager = async () => {
    resetProcess();
    if (projects.length === 0) {
      await getProjects();
    }
    editProject(projectManagerProjectId);
  };

  let generateExternalUrls = () => {
    externalUrls = currentProject.externalUrls.reduce((acc, curr) => {
      let eu = {
        id: curr.id,
        url: curr.url,
        type: curr.type,
        icon: "",
      };
      const platformLink = platformLinks.find((item) => {
        return item.name === eu.type;
      });
      if (platformLink) {
        eu.icon = platformLink.icon;
        acc.push(eu);
      }
      return acc;
    }, []);
    externalUrls.sort((a, b) => {
      const A = a.type;
      const B = b.type;
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });
    externalUrls = externalUrls;
  };

  let toggleProjectStatus = () => {
    currentProject.active = !currentProject.active;
  };

  let toggleInternal = () => {
    currentProject.isInternal = !currentProject.isInternal;
  };

  $: if (currentProject) {
    phaseReview = copyObject(currentProject.phases);
    phaseReview.forEach((p) => {
      p.startDate = moment(p.startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      p.endDate = moment(p.endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      p.subphases.forEach((sp) => {
        sp.startDate = moment(sp.startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
        sp.endDate = moment(sp.endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      });
    });

    milestoneReview = copyObject(currentProject.milestones);
    milestoneReview.forEach((m) => {
      m.date = moment(m.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    });
  }

  /*$: {
    console.log("Current Project: ", currentProject);
    console.log("Current Phase: ", currentPhase);
    console.log("Current Subphase: ", currentSubphase);
    console.log("Current Milestone: ", currentMilestone);
    console.log("Stakeholder List: ", currentProject.stakeholders);
  }*/

  $: {
    const coordinates = getCoordinatesFromMapsURL(mapsURL);
    if (coordinates.latitude && coordinates.longitude) {
      currentProject.latitude = coordinates.latitude;
      currentProject.longitude = coordinates.longitude;
      currentProject = currentProject;
    }
  }

  $: if (openNewProject) {
    prepareProject();
  }

  $: if (selectedStatusP && selectedStatusP.value) {
    checkFinishedSelected();
  }

  $: if (
    selectedCode1 ||
    selectedCode2 ||
    selectedStatus ||
    selectedClient ||
    selectedType ||
    selectedLocation ||
    selectedActive ||
    selectedInternals ||
    searchFilter
  ) {
    applyFilter();
  }

  $: if (
    projectManagerMode === true &&
    projectManagerModeShown === projectManagerProjectId
  ) {
    projectManagerModeShown = projectManagerProjectId;
    restartProjectManager();
  } else {
    if (projectManagerProjectId !== -1) {
      projectManagerModeShown = projectManagerProjectId;
      restartProjectManager();
    } else {
      projectManagerModeShown = -1;
      closeProcess();
      getProjects();
    }
  }

  $: if (
    currentProject &&
    currentProject.externalUrls &&
    platformLinks.length > 0
  ) {
    generateExternalUrls();
    console.log(externalUrls);
  }

  //$: console.log("Current Project:", currentProject);

  //$: console.log(selectedWorks);
</script>

{#if showRemoveDialog}
  <DialogRemove
    element={currentProject.name}
    onClose={() => {
      showRemove(false);
      //showProject(true);
    }}
    onRemove={async () => {
      showRemove(false);
      await deleteProject();
    }}
  />
{:else}
  <div class="content">
    <!-- Menu Options -->

    {#if mode === "list"}
      <div class="optionsMenu">
        <div class="optionsRow">
          <div class="optionsColumn1">
            <div class="optionsSearch">
              <span>Search</span>
              <TextEdit
                bind:value={searchFilter}
                placeholder="type here..."
                maxlength="150"
                img="/images/search.svg"
              />
            </div>
            {#if myRole === "Admin"}
              <SubmenuButton
                text="New"
                img="/images/factory.svg"
                onClick={() => newProject()}
              />
            {/if}
          </div>
        </div>
        <div class="optionsRow lastRow">
          <div class="optionsColumn1">
            <!--<SubmenuDoubleCombobox
              text="Code"
              options1={code1Options}
              bind:selectedValue1={selectedCode1}
              placeholder1=""
              onChange1={null}
              options2={code2Options}
              bind:selectedValue2={selectedCode2}
              placeholder2=""
              onChange2={null}
            />
            <SubmenuCombobox
              text="Client"
              options={clientOptions}
              bind:selectedValue={selectedClient}
              placeholder=""
              onChange={null}
              width="190px"
            />
            <SubmenuCombobox
              text="Status"
              options={statusOptions}
              bind:selectedValue={selectedStatus}
              placeholder=""
              onChange={null}
            />
            <SubmenuCombobox
              text="Type"
              options={typeOptions}
              bind:selectedValue={selectedType}
              placeholder=""
              onChange={null}
              width="190px"
            />
            <SubmenuCombobox
              text="Location"
              options={locationOptions}
              bind:selectedValue={selectedLocation}
              placeholder=""
              onChange={null}
              width="190px"
            />
            <SubmenuCombobox
              text=""
              options={active}
              bind:selectedValue={selectedActive}
              placeholder=""
              onChange={null}
              width="125px"
            />-->
            <SubmenuMultipleSelect
              text="Code"
              options={code1Options}
              bind:selected={selectedCode1}
              width="115px"
            />
            <SubmenuMultipleSelect
              text=""
              options={code2Options}
              bind:selected={selectedCode2}
              width="145px"
            />
            <SubmenuMultipleSelect
              text="Client"
              options={clientOptions}
              bind:selected={selectedClient}
              width="145px"
            />
            <SubmenuMultipleSelect
              text="Status"
              options={statusOptions}
              bind:selected={selectedStatus}
              width="145px"
            />
            <SubmenuMultipleSelect
              text="Typology"
              options={typeOptions}
              bind:selected={selectedType}
              width="145px"
            />
            <SubmenuMultipleSelect
              text="Location"
              options={locationOptions}
              bind:selected={selectedLocation}
              width="145px"
            />
            <SubmenuMultipleSelect
              text="Internal"
              options={internals}
              bind:selected={selectedInternals}
              width="115px"
            />
            <SubmenuMultipleSelect
              text=""
              options={active}
              bind:selected={selectedActive}
              width="115px"
            />
          </div>
          <div class="optionsColumn2">
            <SubmenuButton
              text="Clear all"
              img="/images/x.svg"
              onClick={() => clearFilters()}
            />
          </div>
        </div>
      </div>
    {/if}

    <!-- Body -->
    <div class="container">
      {#if mode === "edit"}
        <EditSection
          creationInfo={currentProject.creationInfo}
          modificationInfo={currentProject.modificationInfo}
          onRemove={() => showRemove(true)}
          showRemove={myRole === "Admin" || myRole === "Analytics"}
        />
      {/if}
      {#if mode !== "list"}
        <div class="tagProject">
          <img src="/images/menu-icons/logo-projects.svg" alt="" />
          <span class="tag"
            >{currentProject.name !== ""
              ? currentProject.name.toUpperCase()
              : "PROJECT"}</span
          >
        </div>
        <div class="tabs">
          <AnalyticsTabs
            tabs={[
              {
                title: "Project",
                index: 0,
                onClick: () => {
                  setPage(1);
                },
              },
              {
                title: "Stakeholders",
                index: 1,
                onClick: () => {
                  setPage(2);
                },
              },
              {
                title: "Planning",
                index: 2,
                onClick: () => {
                  setPage(3);
                  centerView = true;
                },
              },
            ]}
            selected={menu - 1}
          />
        </div>
        <div class="body">
          <div
            class="creationPannel {menu === 3 ? 'creationPannelPlanning' : ''}"
          >
            <div class="creationPanelInfo">
              <!-- Info -->

              <div class="col1 {menu === 1 ? '' : 'hiddenElement'}">
                <div class="activeContainer">
                  <span>{currentProject.active ? "Active" : "Inactive"} </span>
                  <Switch
                    width={40}
                    height={22}
                    checked={currentProject.active}
                    onClick={() => toggleProjectStatus()}
                  />
                </div>
                <div class="containerInput">
                  <span class="requiredElement">Name</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    error={errorName}
                    bind:text={currentProject.name}
                  />
                </div>
                <div class="containerInput">
                  <span>Typology</span>
                  <div class="containerBox">
                    <Combobox
                      bind:selectedValue={projectTypesSel}
                      options={projectTypes}
                      backgroundColor={backgroundColorInput}
                      onChange={() => changeProjectType()}
                      disabled={myRole !== "Admin" && myRole !== "Analytics"}
                    />
                  </div>
                </div>
                <div class="activeContainer">
                  <span>Internal Project</span>
                  <Switch
                    width={40}
                    height={22}
                    checked={currentProject.isInternal}
                    onClick={() => toggleInternal()}
                  />
                </div>
                <!--<div class="containerInput">
                  <span>Nickname</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={currentProject.nickName}
                  />
                </div>-->
                {#if !currentProject.isInternal}
                  {#if !showNewClient}
                    <div class="containerInputDouble">
                      <div class="containerLeft">
                        <div class="titleAdd">
                          <span class="columnTitle">Client</span>
                          <img
                            class="fadedButton"
                            src="/images/refresh.svg"
                            alt="Refresh Clients"
                            on:click={() => getClientsDialog()}
                          />
                          {#if action !== "show" && (myRole === "Admin" || myRole === "Analytics")}
                            <img
                              class="fadedButton"
                              src="/images/plus-no-circle.svg"
                              alt="Add Company"
                              on:click={() => openNewClient(true)}
                            />
                          {/if}
                        </div>
                        <DialogSpanSuggestionFullEdit
                          bind:text={currentProject.client}
                          suggestions={clientList}
                          onChange={() => {
                            onChangeClient();
                          }}
                          onFocusOut={() => {
                            generateProjectId();
                          }}
                          action={myRole === "Admin" || myRole === "Analytics"
                            ? "edit"
                            : "show"}
                          last={false}
                        />
                      </div>
                      <div class="containerRight">
                        <span>Client Code</span>
                        <DialogSpanFullEdit
                          color={backgroundColorInput}
                          height="22"
                          border={borderColor}
                          bind:text={clientCode}
                          action={myRole === "Admin" || myRole === "Analytics"
                            ? "edit"
                            : "show"}
                        />
                      </div>
                    </div>
                  {:else}
                    <div class="containerInput">
                      <span class="columnTitle">New Client</span>
                      <DialogSpanFullEdit
                        color={backgroundColorInput}
                        height="22"
                        border={borderColor}
                        bind:text={newClient}
                      />
                    </div>
                    <div class="titleButtons">
                      <DialogSmallButton
                        text="Add"
                        onClick={() => saveNewClient()}
                      />
                      <DialogSmallButton
                        text="Close"
                        onClick={() => openNewClient(false)}
                      />
                    </div>
                  {/if}
                {/if}
                <!--<div class="containerInput">
                  <span>Company</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={currentProject.company}
                  />
                </div>-->
                <div class="containerInput">
                  <span>Address</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={currentProject.address}
                    action={myRole === "Admin" || myRole === "Analytics"
                      ? "edit"
                      : "show"}
                  />
                </div>
                <div class="containerInputDouble">
                  <div class="containerLeft">
                    <span>Country</span>
                    <div class="containerBox">
                      <Combobox
                        bind:selectedValue={countriesSel}
                        options={countries}
                        backgroundColor={backgroundColorInput}
                        onChange={() => changeCountryCode()}
                        disabled={myRole !== "Admin" && myRole !== "Analytics"}
                      />
                    </div>
                  </div>
                  <div class="containerRight">
                    <span>City</span>
                    <div class="containerBox">
                      <DialogSpanSuggestionFullEdit
                        bind:text={citySel}
                        suggestions={cities}
                        onChange={() => {
                          setCityCodes();
                        }}
                        onFocusOut={() => {
                          generateProjectId();
                        }}
                        action={myRole === "Admin" || myRole === "Analytics"
                          ? "edit"
                          : "show"}
                        last={false}
                      />
                    </div>
                  </div>
                </div>
                <div class="containerInputDouble">
                  <div class="containerLeft">
                    <span>2-Digit Code</span>
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      bind:text={currentProject.digit2}
                      maxLength={2}
                      readOnly={setCityCode2}
                      action={myRole === "Admin" || myRole === "Analytics"
                        ? "edit"
                        : "show"}
                      onFocusOut={() => generateProjectId()}
                    />
                  </div>
                  <div class="containerRight">
                    <span>3-Digit Code</span>
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      bind:text={currentProject.digit3}
                      maxLength={3}
                      readOnly={myRole === "Admin" || myRole === "Analytics"
                        ? setCityCode3
                        : true}
                      action={myRole === "Admin" || myRole === "Analytics"
                        ? "edit"
                        : "show"}
                    />
                  </div>
                </div>
                <div class="containerInputDouble">
                  <div class="containerLeft">
                    <span>Time Code</span>
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      bind:text={currentProject.timeCode}
                      action={myRole === "Admin" || myRole === "Analytics"
                        ? "edit"
                        : "show"}
                    />
                  </div>
                  <div class="containerRight">
                    <span>Folder Code</span>
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      bind:text={currentProject.folderCode}
                      action={myRole === "Admin" || myRole === "Analytics"
                        ? "edit"
                        : "show"}
                    />
                  </div>
                </div>
              </div>
              <div class="col2 {menu === 1 ? '' : 'hiddenElement'}">
                <div class="projectImage">
                  <FileUploader
                    bind:fileInfo={currentProject.picImage}
                    loadFromPath={currentProject.picPath}
                    picExtraStyle="border-radius: 10px"
                    showFullImage={true}
                    bind:loadingFile={loadingFileIcon}
                  />
                </div>

                <div class="googlemap">
                  <GoogleMaps
                    id="project-analytics"
                    mapCenter={{
                      latitude: currentProject.latitude,
                      longitude: currentProject.longitude,
                    }}
                    locations={[
                      {
                        latitude: currentProject.latitude,
                        longitude: currentProject.longitude,
                        name: currentProject.name,
                      },
                    ]}
                  />
                </div>

                <div class="containerInputDouble">
                  <div class="containerLeft">
                    <span>Latitude</span>
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      bind:text={currentProject.latitude}
                    />
                  </div>
                  <div class="containerRight">
                    <span>Longitude</span>
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      bind:text={currentProject.longitude}
                    />
                  </div>
                </div>

                <div class="containerInput">
                  <span>Parse from Google Maps URL</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={mapsURL}
                  />
                </div>
              </div>

              <div class="col3 {menu === 1 ? '' : 'hiddenElement'}">
                <div class="containerInput">
                  <span>Project Links</span>
                  <div class="comboPlus">
                    <div class="divComboBox containerBox">
                      <Combobox
                        bind:selectedValue={externalUrlOptionsSel}
                        options={externalUrlOptions}
                        backgroundColor={backgroundColorInput}
                      />
                    </div>
                    <img
                      class="fadedButton comboPlusPlus"
                      src="/images/calendar/logo-new-20x20-34525B.svg"
                      alt="Add External URL"
                      on:click={() => addUrl()}
                    />
                  </div>
                  <div class="divComboBox containerBox">
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      bind:text={externaUrlOptionUrl}
                    />
                  </div>
                </div>

                {#each externalUrls as url}
                  <ProjectAnalyticsExternalUrl
                    {url}
                    onRemove={() => removeUrl(url.id)}
                  />
                {/each}

                <!--{#each currentProject.externalUrls as url}
                  {#if url.type === "Server"}
                    <ProjectAnalyticsExternalUrl
                      {url}
                      onRemove={() => removeUrl(url.id)}
                    />
                  {/if}
                {/each}
                {#each currentProject.externalUrls as url}
                  {#if url.type === "Trello"}
                    <ProjectAnalyticsExternalUrl
                      {url}
                      onRemove={() => removeUrl(url.id)}
                    />
                  {/if}
                {/each}
                {#each currentProject.externalUrls as url}
                  {#if url.type === "Slack"}
                    <ProjectAnalyticsExternalUrl
                      {url}
                      onRemove={() => removeUrl(url.id)}
                    />
                  {/if}
                {/each}
                {#each currentProject.externalUrls as url}
                  {#if url.type === "Sharepoint"}
                    <ProjectAnalyticsExternalUrl
                      {url}
                      onRemove={() => removeUrl(url.id)}
                    />
                  {/if}
                {/each}
                {#each currentProject.externalUrls as url}
                  {#if url.type === "BIM360"}
                    <ProjectAnalyticsExternalUrl
                      {url}
                      onRemove={() => removeUrl(url.id)}
                    />
                  {/if}
                {/each}-->
                <!--<div class="containerInput">
                  <span>Server Link</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={currentProject.serverLink}
                  />
                </div>
                <div class="containerInput">
                  <span>Trello</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={currentProject.trello}
                  />
                </div>
                <div class="containerInput">
                  <span>Slack</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={currentProject.slack}
                  />
                </div>
                <div class="containerInput">
                  <span>Sharepoint</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={currentProject.sharepoint}
                  />
                </div>
                <div class="containerInput">
                  <span>Bim360</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={currentProject.bim360}
                  />
                </div>
                <div class="containerInput">
                  <span>Miro</span>
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    bind:text={currentProject.miro}
                  />
                </div>-->
                <div class="containerInput statusHistory">
                  <span>Status History</span>
                  <div class="statusHistoryList customScrollbars">
                    {#each currentProject.statusHistory as status}
                      <StatusHistoryItem
                        bind:status
                        defaultStatus={statusP}
                        onEdit={() => openEditStatusHistory(status.id)}
                        onRemove={() => removeStatusHistory(status.id)}
                        editable={myRole === "Admin" || myRole === "Analytics"}
                      />
                    {/each}
                  </div>
                  {#if myRole === "Admin" || myRole === "Analytics"}
                    <div
                      class="divComboBox statusHistoryAdd {editingStatus
                        ? 'statusHistoryEdit'
                        : ''} analytics"
                    >
                      <div class="divComboBoxElement">
                        <Combobox
                          bind:selectedValue={selectedStatusP}
                          options={statusP}
                          backgroundColor={backgroundColorInput}
                        />
                      </div>
                      <div class="divComboBoxElement">
                        <DialogSpanCalendarPickerEvent
                          bind:text={statusStart}
                          calendarOptions={[]}
                          action="edit"
                          error={false}
                          marginBottom="0px"
                          height="22"
                          analytics={true}
                        />
                      </div>
                      <div class="divComboBoxElement">
                        <DialogSpanCalendarPickerEvent
                          bind:text={statusEnd}
                          calendarOptions={["Sin fecha final"]}
                          action="edit"
                          error={false}
                          marginBottom="0px"
                          height="22"
                          analytics={true}
                        />
                      </div>
                      <!--<div>
                        <Switch
                          width={40}
                          height={22}
                          checked={selectedStatusP.value.active}
                          onClick={null}
                          readonly={true}
                        />
                      </div>-->
                      {#if editingStatus}
                        <img
                          class="addIcon fadedButton"
                          src="/images/save.svg"
                          alt=""
                          on:click={() => editStatusHistory()}
                        />
                        <img
                          class="addIcon fadedButton"
                          src="/images/x.svg"
                          alt=""
                          on:click={() => closeEditStatusHistory()}
                        />
                      {:else}
                        <img
                          class="addIcon fadedButton"
                          src="/images/calendar/logo-new-20x20-34525B.svg"
                          alt=""
                          on:click={() => addStatusHistory()}
                        />
                      {/if}
                    </div>
                  {/if}
                </div>
              </div>
              <!-- Phases -->

              <!--<div class="menu2 {menu === 2 ? '' : 'hiddenElement'}">
                {#if phaseReview.length > 0}
                  <div class="previewPhases">
                    <SimplePhasePreview
                      phases={phaseReview}
                      milestones={milestoneReview}
                    />
                  </div>
                {/if}
                <div class="menu2Info">
                  <div class="col1">
                    <div class="phase">
                      {#if isEditing && isPhase === 1}
                        <span class="title">Edit Phase</span>
                      {:else}<span class="title">New Phase</span>{/if}
                      <div class="divComboBox">
                        <DialogSpanFullEdit
                          color={backgroundColorInput}
                          height="22"
                          border={borderColor}
                          bind:text={currentPhase.name}
                        />
                      </div>
                      <div class="dateOptionsV2">
                        <!--<div
                          class="itemSelectable"
                          on:click={() => {
                            currentPhase.notAvailable = !currentPhase.notAvailable;
                          }}>
                          <span>N/A</span>
                          {#if !currentPhase.notAvailable}
                            <img src="/images/unselected.svg" alt="" />
                          {:else if currentPhase.notAvailable}
                            <img src="/images/selected.svg" alt="" />
                          {/if}
                        </div>-->
              <!--{#if !currentPhase.notAvailable}
                          <div class="dateBox">
                            <span>Start</span>
                            <DialogSpanCalendarPickerEvent
                              bind:text={currentPhase.startDate}
                              calendarOptions={[]}
                              action="edit"
                              error={false}
                              last={true}
                              marginBottom="0px"
                              analytics={true}
                            />
                          </div>
                          <div class="dateBox">
                            <span>End</span>
                            <DialogSpanCalendarPickerEvent
                              bind:text={currentPhase.endDate}
                              calendarOptions={[]}
                              {action}
                              error={false}
                              last={true}
                              marginBottom="0px"
                              analytics={true}
                            />
                          </div>
                        {/if}
                      </div>
                      <div class="buttons">
                        {#if isEditing && isPhase === 1}
                          <ProjectPageButton
                            text="Save"
                            onClick={() => editPhase(indexPhase)}
                          />
                          <ProjectPageButton
                            text="Cancel"
                            onClick={() => cancelEditPhase(indexPhase)}
                          />
                        {:else}
                          <ProjectPageButton
                            text="Add"
                            onClick={() => addPhase()}
                          />
                        {/if}
                      </div>
                    </div>
                    <div class="scope">
                      <span class="title">Add Phases from Scope</span>
                      <div class="divComboBox containerBox">
                        <Combobox
                          bind:selectedValue={scopeSelected}
                          options={scopeOptions}
                          backgroundColor={backgroundColorInput}
                        />
                      </div>
                      <div class="dateOptionsV1">
                        <!--<div
                          class="itemSelectable"
                          on:click={() => {
                            currentScope.notAvailable = !currentScope.notAvailable;
                          }}>
                         <span>N/A</span>
                          {#if !currentScope.notAvailable}
                            <img src="/images/unselected.svg" alt="" />
                          {:else if currentScope.notAvailable}
                            <img src="/images/selected.svg" alt="" />
                          {/if}
                        </div>-->
              <!--<div class="separator" />
                        {#if !currentScope.notAvailable}
                          <div class="dateBox">
                            <span>Start</span>
                            <DialogSpanCalendarPickerEvent
                              bind:text={scopeStartDate}
                              calendarOptions={[]}
                              {action}
                              error={false}
                              last={true}
                              marginBottom="0px"
                              analytics={true}
                            />
                          </div>
                        {/if}
                      </div>
                      <ProjectPageButton
                        text="Add"
                        onClick={() => addUsedScope()}
                      />
                    </div>
                    {#if usedScopesList.length}
                      <div class="elementList customScrollbars">
                        <span class="title">Scopes used</span>
                        {#each usedScopesList as s, index}
                          <div class="usedElement">
                            <ItemListed
                              name={s.label}
                              fromScope="true"
                              editedType={s.type}
                              bind:text={s.duration}
                              onClick={() => {
                                usedScopesList.find((item) => item === s)
                                  ? deletePhaseFromScope(index)
                                  : "";
                              }}
                            />
                          </div>
                        {/each}
                      </div>
                    {/if}
                  </div>
                  <div class="col2">
                    {#if numPhases}
                      <div class="elementList customScrollbars">
                        <span class="title">Phases</span>
                        {#each currentProject.phases as p, index}
                          <div class="boxElement">
                            <ItemListed
                              name={p.name}
                              start={p.startDate}
                              end={p.endDate}
                              bind:isEditing={p.isEditing}
                              bind:isPhase
                              editedType={p.type}
                              notAvailable={p.notAvailable}
                              onClick={() => {
                                p.isEditing && isPhase === 1
                                  ? loadPhaseData(index)
                                  : deletePhase(index);
                              }}
                            />
                          </div>
                          {#each currentProject.phases[index].subphases as s, indexS}
                            <div class="boxElementSubPhase">
                              <ItemListed
                                name={s.name}
                                start={s.startDate}
                                end={s.endDate}
                                bind:isEditing={s.isEditing}
                                bind:isPhase
                                editedType={s.type}
                                notAvailable="false"
                                onClick={() => {
                                  s.isEditing && isPhase === 2
                                    ? loadSubphaseData(index, indexS)
                                    : deleteSubphase(index, indexS);
                                }}
                              />
                            </div>
                          {/each}
                          <div class="newKey">
                            <div
                              on:click={() => {
                                openCreateNewSubphase(index);
                              }}
                            >
                              <img src="/images/plus.svg" alt="New Icon" />
                              <span>Add Subphase</span>
                            </div>
                          </div>
                          {#if index === indexPhase && isPhase !== 1}
                            <div class="subphase">
                              {#if isEditing && isPhase === 2}
                                <span class="title">Edit Subphase</span>
                              {:else}
                                <span class="title">Add New Subphase</span>
                              {/if}
                              <div class="divComboBox">
                                <DialogSpanFullEdit
                                  color={backgroundColorInput}
                                  height="22"
                                  border={borderColor}
                                  bind:text={currentSubphase.name}
                                />
                              </div>
                              <div class="dateOptionsV1">
                                <div class="dateBox">
                                  <span>Start</span>
                                  <DialogSpanCalendarPickerEvent
                                    bind:text={currentSubphase.startDate}
                                    calendarOptions={[]}
                                    {action}
                                    error={false}
                                    last={true}
                                    marginBottom="0px"
                                    analytics={true}
                                  />
                                </div>
                                <div class="separator" />
                                <div class="dateBox">
                                  <span>End</span>
                                  <DialogSpanCalendarPickerEvent
                                    bind:text={currentSubphase.endDate}
                                    calendarOptions={[]}
                                    {action}
                                    error={false}
                                    last={true}
                                    marginBottom="0px"
                                    analytics={true}
                                  />
                                </div>
                              </div>
                              <div class="buttons">
                                {#if isEditing && isPhase === 2}
                                  <ProjectPageButton
                                    text="Edit"
                                    onClick={() =>
                                      editSubphase(index, indexSubphase)}
                                  />
                                  <ProjectPageButton
                                    text="Cancel"
                                    onClick={() =>
                                      cancelEditSubphase(index, indexSubphase)}
                                  />
                                {:else}
                                  <ProjectPageButton
                                    text="Add"
                                    onClick={() => addNewSubphase(index)}
                                  />
                                  <ProjectPageButton
                                    text="Close"
                                    onClick={() => cancelCreateNewSubphase()}
                                  />
                                {/if}
                              </div>
                            </div>
                          {/if}
                        {/each}
                        <div class="saveScope">
                          {#if !saveNewScope && !newScopeSaved}
                            <span class="title">
                              Do you want to save the phases above as a new
                              scope?
                            </span>
                          {:else if saveNewScope && !newScopeSaved}
                            <div class="containerInput">
                              <span class="title">
                                Enter a name for the template and press the save
                                button
                              </span>
                              <DialogSpanFullEdit
                                color={backgroundColorInput}
                                height="22"
                                border={borderColor}
                                bind:text={currentScope.name}
                              />
                            </div>
                          {:else if !saveNewScope && newScopeSaved}
                            <div class="containerInput">
                              <span class="title">Scope saved!</span>
                            </div>
                          {/if}
                          <ProjectPageButton
                            text={newScopeSaved ? "Done!" : "Save"}
                            onClick={() => {
                              if (!saveNewScope && !newScopeSaved) {
                                saveNewScope = true;
                              } else if (saveNewScope && !newScopeSaved) {
                                addNewScope();
                                saveNewScope = false;
                                newScopeSaved = true;
                              } else if (!saveNewScope && newScopeSaved) {
                                newScopeSaved = false;
                              }
                            }}
                          />
                        </div>
                      </div>
                    {/if}
                  </div>
                  <div class="col3">
                    <div class="milestone">
                      {#if isEditing && isPhase === 3}
                        <span class="title">Edit Milestone</span>
                      {:else}<span class="title">New Milestone</span>{/if}
                      <div class="divComboBox">
                        <DialogSpanFullEdit
                          color={backgroundColorInput}
                          height="22"
                          border={borderColor}
                          bind:text={currentMilestone.name}
                        />
                      </div>
                      <div class="dateOptionsV1">
                        <div class="dateBox mdateBox">
                          <span>Type</span>
                          <div class="divComboBox containerBox mcombo">
                            <Combobox
                              bind:selectedValue={milestoneOptionsSel}
                              options={milestoneOptions}
                              backgroundColor={backgroundColorInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="dateOptionsV1">
                        <div class="dateBox">
                          <span>Date</span>
                          <DialogSpanCalendarPickerEvent
                            bind:text={currentMilestone.date}
                            calendarOptions={[]}
                            {action}
                            error={false}
                            last={true}
                            marginBottom="0px"
                            analytics={true}
                          />
                        </div>
                        <div class="separator" />
                        <div
                          class="itemSelectable"
                          on:click={() => {
                            currentMilestone.allDay = !currentMilestone.allDay;
                          }}
                        >
                          <span>All day</span>
                          {#if !currentMilestone.allDay}
                            <img src="/images/unselected.svg" alt="" />
                          {:else if currentMilestone.allDay}
                            <img src="/images/selected.svg" alt="" />
                          {/if}
                        </div>
                      </div>
                      {#if !currentMilestone.allDay}
                        <div class="dateOptionsV2">
                          <div class="dateBox">
                            <span>Start time</span>
                            <div class="containerHoursAndMins containerBox">
                              <div class="time">
                                <ComboboxEvent
                                  bind:selectedValue={currentMilestone.startH}
                                  options={HOURS}
                                  backgroundColor="rgb(215, 225, 234)"
                                />
                              </div>
                              <div class="time">
                                <ComboboxEvent
                                  bind:selectedValue={currentMilestone.startM}
                                  options={MINS}
                                  backgroundColor="rgb(215, 225, 234)"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="dateBox">
                            <span>End time</span>
                            <div class="containerHoursAndMins containerBox">
                              <div class="time">
                                <ComboboxEvent
                                  bind:selectedValue={currentMilestone.endH}
                                  options={HOURS}
                                  backgroundColor="rgb(215, 225, 234)"
                                />
                              </div>
                              <div class="time">
                                <ComboboxEvent
                                  bind:selectedValue={currentMilestone.endM}
                                  options={MINS}
                                  backgroundColor="rgb(215, 225, 234)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      {/if}
                      <div class="buttons">
                        {#if isEditing && isPhase === 3}
                          <ProjectPageButton
                            text="Save"
                            onClick={() => editMilestone(indexMilestone)}
                          />
                          <ProjectPageButton
                            text="Cancel"
                            onClick={() => cancelEditMilestone(indexMilestone)}
                          />
                        {:else}
                          <ProjectPageButton
                            text="Add"
                            onClick={() => addMilestone()}
                          />
                        {/if}
                      </div>
                    </div>
                    {#if currentProject.milestones.length}
                      <div class="elementList customScrollbars">
                        <span class="title">Milestones</span>
                        {#each currentProject.milestones as m, index}
                          <div class="boxElement">
                            <ItemListed
                              name={m.name}
                              start={m.date}
                              editedType={m.type}
                              startTime={m.startH.value + `:` + m.startM.value}
                              endTime={m.endH.value + `:` + m.endM.value}
                              notAvailable={m.allDay}
                              bind:isEditing={m.isEditing}
                              bind:isPhase
                              onClick={() => {
                                m.isEditing && isPhase === 3
                                  ? loadMilestone(index)
                                  : deleteMilestone(index);
                              }}
                            />
                          </div>
                        {/each}
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
              <!-- Team -->

              <div class="menu2 {menu === 2 ? '' : 'hiddenElement'}">
                <!--<div class="previewTeams">
                  <SimpleTeamPreview
                    project={currentProject}
                    {selectedWorks}
                    selectedStart={moment(roleStart, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )}
                    selectedEnd={moment(roleEnd, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )}
                    selectedRole={teamRoleSelected}
                    {works}
                  />
                </div>-->
                <div class="menu2Info">
                  <!--<div class="col1">
                    <div class="sectionDiv">
                      <!--<span class="title">Team</span>
                  <div class="divComboBox">
                    <Combobox
                      bind:selectedValue={projectManagerSelected}
                      options={peopleList}
                      backgroundColor={backgroundColorInput} />
                    <img
                      class="addPersonIcon fadedButton"
                      src="/images/calendar/logo-new-20x20-34525B.svg"
                      alt=""
                      on:click={() => addNewProjectManager()} />
                  </div>-->
                  <!--<span class="title">Team</span>
                      <div class="divComboBox containerBox">
                        <Combobox
                          bind:selectedValue={personTeamSelected}
                          options={peopleList}
                          backgroundColor={backgroundColorInput}
                          analytics={true}
                          onChange={() => changeSelectedTeam()}
                        />
                        <img
                          class="addPersonIcon fadedButton"
                          src="/images/calendar/logo-new-20x20-34525B.svg"
                          alt=""
                          on:click={() => addNewPersonTeam()}
                        />
                      </div>
                      <div class="containerInput">
                        <span>Role</span>
                        <DialogSpanSuggestionFullEdit
                          color={backgroundColorInput}
                          height="22"
                          border={borderColor}
                          bind:text={teamRoleSelected}
                          suggestions={roles}
                        />
                      </div>
                      <div class="dateOptionsTeam">
                        <div class="dateBox">
                          <span>Start</span>
                          <DialogSpanCalendarPickerEvent
                            bind:text={roleStart}
                            calendarOptions={[]}
                            action="edit"
                            error={false}
                            last={true}
                            marginBottom="0px"
                            analytics={true}
                          />
                        </div>
                        <div class="separator" />
                        <div class="dateBox">
                          <span>End</span>
                          <DialogSpanCalendarPickerEvent
                            bind:text={roleEnd}
                            calendarOptions={[]}
                            action="edit"
                            error={false}
                            last={true}
                            marginBottom="0px"
                            analytics={true}
                          />
                        </div>
                      </div>
                      <Team bind:team={currentProject.team} {roles} />
                    </div>
                  </div>-->
                  <div class="col2">
                    <div class="sectionDiv">
                      <span class="title">New Stakeholder</span>
                      <div class="divComboBox containerBox">
                        <Combobox
                          bind:selectedValue={companySelected}
                          options={companyList}
                          backgroundColor={backgroundColorInput}
                        />
                      </div>
                      <!--<div class="divComboBox">
                <Combobox
                  bind:selectedValue={roleSelected}
                  options={rolesList}
                  backgroundColor={backgroundColorInput} />
              </div>-->
                      <!--<div class="containerInput">
                        <span>Role</span>
                        <DialogSpanFullEdit
                          color={backgroundColorInput}
                          height="22"
                          border={borderColor}
                          bind:text={roleSelected}
                        />
                      </div>-->
                      <span class="title">Role</span>
                      <div class="divComboBox containerBox">
                        <Combobox
                          bind:selectedValue={stakeholdersOptionsSel}
                          options={stakeholdersOptions}
                          backgroundColor={backgroundColorInput}
                        />
                      </div>
                      <ProjectPageButton
                        text="Add"
                        onClick={() => addNewStakeholder()}
                      />
                    </div>
                    <div class="stakeholdersList customScrollbars">
                      {#if currentProject.stakeholders.length}
                        <span class="title">Stakeholders</span>
                      {/if}
                      {#each currentProject.stakeholders as s, index}
                        <div class="boxElement">
                          <ItemListed
                            name={s.company}
                            text={s.role}
                            editedType={"stakeholder"}
                            onClick={() => {
                              deleteStakeholder(index);
                            }}
                          />
                        </div>
                      {/each}
                    </div>
                  </div>
                  <div class="col3">
                    <div class="stakeholdersList customScrollbars">
                      {#each currentProject.stakeholders as s, index}
                        <div class="sectionDiv">
                          <span class="title"><b>{s.company}</b></span>
                          <div class="divComboBox containerBox">
                            <CompanyTeams
                              companyId={s.id}
                              bind:selectedValue={s.selectedPerson}
                              backgroundColor={backgroundColorInput}
                            />
                            <img
                              class="addPersonIcon fadedButton"
                              src="/images/calendar/logo-new-20x20-34525B.svg"
                              alt=""
                              on:click={() => addStakeholderPeople(index)}
                            />
                          </div>
                          <div class="containerInput">
                            <!--<span>Role</span>
                            <DialogSpanSuggestionFullEdit
                              color={backgroundColorInput}
                              height="22"
                              border={borderColor}
                              bind:text={s.selectedRole}
                              suggestions={roles}
                            />-->
                            <span class="title">Role</span>
                            <div class="divComboBox containerBox">
                              {#if s.role === "Consultant"}
                                <Combobox
                                  bind:selectedValue={consultantsOptionsSel}
                                  options={consultantsOptions}
                                  backgroundColor={backgroundColorInput}
                                />
                              {:else}
                                <Combobox
                                  bind:selectedValue={clientsOptionsSel}
                                  options={clientsOptions}
                                  backgroundColor={backgroundColorInput}
                                />
                              {/if}
                            </div>
                          </div>
                          <div class="peopleList">
                            {#each s.team as p, indexP}
                              <div class="stakeholderPerson">
                                <div class="selectedPerson">
                                  <div class="teamPic" title={p.label}>
                                    <Picture
                                      name={p.selectedPerson.label}
                                      image={p.selectedPerson.image}
                                    />
                                  </div>
                                  <img
                                    class="removeSelectedPerson fadedButton"
                                    src="/images/x-red.svg"
                                    alt=""
                                    on:click={() =>
                                      deleteStakeholderPeople(index, indexP)}
                                  />
                                </div>
                                <span>{p.selectedRole}</span>
                              </div>
                            {/each}
                          </div>
                        </div>
                      {/each}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Planning -->

              <div class="planning {menu === 3 ? '' : 'hiddenElement'}">
                {#if currentProject.id === -1}
                  <div class="noPlanning">
                    Sorry! You have to save your project before building its
                    plan!
                  </div>
                {:else}
                  <CompleteResourcePlanner
                    projectId={currentProject.id}
                    {centerView}
                  />
                {/if}
              </div>
              <!--<div class="col1 {menu === 3 ? '' : 'hiddenElement'}">
                
              </div>-->

              <!-- Licenses -->

              <!--<div class="col1 {menu === 4 ? '' : 'hiddenElement'}">
                <div class="sectionDiv">
                  <span class="title">Applications</span>
                  <div class="divComboBox">
                    <Combobox
                      bind:selectedValue={applicationOptionsSel}
                      options={applicationOptions}
                      backgroundColor={backgroundColorInput}
                      onChange={() => changeApplication()}
                    />
                  </div>
                </div>
                <div class="sectionDiv">
                  <span class="title">New Application</span>
                  <div class="containerInput">
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      bind:text={newApplication}
                    />
                  </div>
                  <ProjectPageButton
                    text="Create"
                    onClick={() => createApplication()}
                  />
                </div>
              </div>
              <div class="col2 {menu === 4 ? '' : 'hiddenElement'}">
                <div class="sectionDiv">
                  <span class="title">Keys</span>
                  <div class="divComboBox">
                    <Combobox
                      bind:selectedValue={keyOptionsSel}
                      options={keyOptions}
                      backgroundColor={backgroundColorInput}
                    />
                    <img
                      class="addPersonIcon fadedButton"
                      src="/images/calendar/logo-new-20x20-34525B.svg"
                      alt=""
                      on:click={() => addKey()}
                    />
                  </div>
                </div>
                <div class="sectionDiv">
                  <span class="title">New Key</span>
                  <span class="title"
                    >* Keys created here will not be available as global</span
                  >
                  <div class="containerInput">
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      bind:text={newKey}
                    />
                  </div>
                  <ProjectPageButton
                    text="Create"
                    onClick={() => createKey()}
                  />
                </div>
              </div>
              <div class="col3 {menu === 4 ? '' : 'hiddenElement'}">
                <div class="sectionDiv">
                  <span class="title">Assigned Keys</span>
                  {#each currentProject.licenses as license}
                    {#each license.keys as key}
                      <div class="license">
                        <div class="licenseKey">
                          <span>{license.name}</span>
                          <span>{key.key}</span>
                        </div>
                        <div class="licenseOptions">
                          <img
                            class="fadedButton"
                            src="/images/trash.svg"
                            alt="Remove Key"
                            on:click={() => removeKey(key.id)}
                          />
                        </div>
                      </div>
                    {/each}
                  {/each}
                </div>
              </div>-->
            </div>
            <!-- Pager -->

            <!--<div class="pager">
              <BoxEditPager
                numPages={NUM_PAGES}
                page={menu - 1}
                clicks={[() => setPage(1), () => setPage(2)]}
              />
            </div>-->
          </div>
          <div class="buttonPannel">
            <!--<div class="button fadedButton" on:click={() => previousMenu()}>
              <img class="next" src="/images/left-arrow.svg" alt="" />
            </div>-->
            <div class="button fadedButton" on:click={() => closeProcess()}>
              <img class="cancel" src="/images/x.svg" alt="" />
            </div>
            <div
              class="button {loadingFileIcon ? 'waiting' : 'fadedButton'}"
              on:click={() => {
                if (!loadingFileIcon) {
                  checkErrors();
                  if (!error) {
                    if (mode === "new") {
                      createProject();
                    } else {
                      updateProject();
                    }
                  } else {
                    menu = 1;
                  }
                }
              }}
            >
              <img class="save" src="/images/save.svg" alt="" />
            </div>
            <!--<div class="button fadedButton" on:click={() => nextMenu()}>
              <img class="next" src="/images/right-arrow.svg" alt="" />
            </div>-->
          </div>
        </div>
      {/if}
      {#if mode === "list"}
        {#each projects as project}
          {#if project.visible}
            <div class="footer">
              <div class="footerLeft">
                <img src="/images/menu-icons/logo-projects.svg" alt="" />
                <span>{project.folderLabitCode}</span>
                <span>{project.name}</span>
              </div>
              <div class="footerRight">
                <div class="footerActive">
                  <div
                    class={project.active ? "activeButton" : "noActiveButton"}
                  >
                    <span>{project.active ? "ACTIVE" : "INACTIVE"}</span>
                  </div>
                </div>
                <div class="footerStatus">
                  <span>{project.currentStatus}</span>
                </div>
                <div class="footerPhase">
                  <span>{project.currentPhase}</span>
                </div>
                <div class="containerPJ">
                  {#each project.people as people}
                    {#if people.role === "Project Manager"}
                      <div>
                        <div class="PJProfile">
                          <Picture
                            image={LABIT_PUBLIC_FOLDER + people.peoplePic}
                            name={people.peopleName}
                            grayscale={true}
                            small={true}
                          />
                        </div>
                        <span>{people.peopleName}</span>
                      </div>
                    {/if}
                  {/each}
                </div>
                <div
                  class="containerEdit fadedButton"
                  on:click={() => editProject(project.id)}
                >
                  <span>EDIT</span>
                  <img src="/images/edit.svg" alt="" />
                </div>
              </div>
            </div>
          {/if}
        {/each}
      {/if}
    </div>
  </div>
{/if}

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20px;
  }

  div.body,
  div.footer {
    width: 100%;
    display: flex;
  }

  div.footer {
    background-color: #ffff;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  div.footer span {
    color: var(--labit-card-gray);
    font-size: 15px;
    font-weight: bold;
  }

  div.body div.buttonPannel div.button img {
    width: 30px;
  }

  div.body div.buttonPannel div.button img.cancel {
    width: 20px !important;
  }

  div.body {
    min-height: 710px !important;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: -20px;
  }

  div.body div.creationPannel {
    background-color: #d7e1ea;
    padding: 41px 49px 43px 36px;
    border-radius: 15px;
    width: 1510px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div.body div.creationPannel div.col1,
  div.body div.creationPannel div.col2 {
    display: flex;
    flex-direction: column;
    margin-right: 70px;
    width: 430px;
  }

  div.creationPannel div.col3 {
    display: flex;
    flex-direction: column;
    width: 430px;
  }

  div.creationPanelInfo {
    width: 100%;
    display: flex;
  }

  div.containerInputDouble {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  div.containerInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  div.containerInputDouble div.containerLeft,
  div.containerInputDouble div.containerRight {
    width: 46%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  div.containerInput span,
  div.containerImage span.fieldTitle,
  div.scope span.title,
  div.phase span.title,
  div.containerInputDouble div.containerLeft span,
  div.containerInputDouble div.containerRight span,
  div.elementList span.title,
  div.subphase span.title,
  div.milestone span.title,
  div.sectionDiv span.title {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 10px;
  }

  div.body div.buttonPannel {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
  }

  div.body div.buttonPannel div.button {
    background-color: #d7e1ea;
    height: 356px;
    width: 118px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.body {
    margin-bottom: 35px;
  }

  div.button:not(:last-child) {
    margin-bottom: 25px;
  }

  div.tagProject {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 8px 35px;
  }

  div.tagProject img {
    width: 30px;
  }

  div.tagProject span.tag {
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
    color: var(--labit-card-gray);
  }

  div.containerBox,
  div.divComboBox,
  img.addPersonIcon {
    height: 22px;
  }

  img.addPersonIcon {
    padding-left: 10px;
    cursor: pointer;
  }

  img.addIcon {
    height: 22px;
    width: 22px;
  }

  div.divComboBox {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div.divComboBoxElement {
    width: 25%;
  }

  div.searchImage {
    border-radius: 15px;
    border: 0.5px solid var(--labit-card-gray);
    width: 431px;
    height: 255px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  }

  div.searchImage span {
    font-size: 12px;
    color: var(--labit-card-gray);
  }

  div.searchImage span.spanEdited {
    font-weight: bold;
    color: var(--labit-card-gray);
  }

  div.searchImage img {
    width: 64px;
  }

  div.searchImage div.uploadImgButton {
    border-radius: 15px;
    border: 0.5px solid var(--labit-card-gray);
    width: 114px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  div.footer div.PJProfile {
    width: 18px;
    height: 18px;
    margin-right: 14px;
  }

  div.footer img {
    width: 18px;
  }

  div.footer div.footerLeft,
  div.footerRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 26px 0 26px;
  }

  div.footerLeft {
    width: 35%;
  }

  div.footerRight {
    width: 65%;
  }

  div.footerRight {
    justify-content: space-between;
    align-items: flex-start;
  }

  div.footerLeft img {
    margin: 0 14px 0 0;
  }

  div.footerLeft span {
    margin-right: 20px;
  }

  div.activeButton,
  div.noActiveButton {
    width: 99px;
    height: 20px;
    background-color: #a7ef7e;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.noActiveButton {
    background-color: #72809d;
  }

  div.activeButton span,
  div.noActiveButton span {
    color: #ffff;
  }

  div.footerActive {
    width: 15%;
    display: flex;
  }

  div.footerStatus {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  div.footerPhase {
    width: 30%;
    display: flex;
  }

  div.footerActive {
    width: 10%;
    display: flex;
  }

  div.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div.dateOptionsV1,
  div.dateOptionsV2,
  div.dateOptionsTeam {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  div.dateOptionsV1 {
    justify-content: flex-start;
  }

  div.dateOptionsV1 div.itemSelectable,
  div.dateOptionsV2 div.itemSelectable {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  div.dateOptionsV1 div.itemSelectable img,
  div.dateOptionsV2 div.itemSelectable img {
    width: 22px;
    height: 22px;
    cursor: pointer;
    /* margin-right: 6px; */
  }

  div.dateOptionsV1 div.itemSelectable div.circularContainer,
  div.dateOptionsV2 div.itemSelectable div.circularContainer {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    /* margin-right: 6px; */
    border: 0.5px solid var(--labit-card-gray);
    cursor: pointer;
  }

  div.dateOptionsV1 div.itemSelectable span,
  div.dateOptionsV2 div.itemSelectable span,
  div.dateBox span {
    font-size: 15px;
    font-weight: bold;
    color: var(--labit-card-gray);
    margin-right: 6px;
  }

  div.dateOptionsTeam span {
    font-weight: normal;
    font-size: 12px;
  }

  div.dateBox {
    /* width: 180px; */
    /* height: 22px; */
    display: flex;
    align-items: center;
  }

  div.scope,
  div.phase,
  div.milestone {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  div.subphase {
    width: 95%;
    margin-bottom: 10px;
  }

  div.elementList,
  div.stakeholdersList {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    /* height: 400px; */
    /*overflow: overlay;*/
  }

  div.stakeholdersList {
    /*height: 800px;*/
  }

  div.sectionDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  div.peopleList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  div.peopleList div.selectedPerson {
    /*width: 50px;*/
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 5px;
  }

  div.peopleList div.selectedPerson img.picPerson {
    width: 40px;
    border-radius: 50%;
  }

  div.teamPic {
    width: 40px;
    height: 40px;
  }

  div.selectedPerson img.removeSelectedPerson {
    width: 10px;
    cursor: pointer;
    position: absolute;
    margin-left: 32px;
    margin-top: 3px;
  }

  div.containerPJ,
  div.containerEdit {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.containerEdit {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  div.containerPJ {
    width: 25%;
    flex-direction: column;
    align-items: flex-start;
  }

  div.containerPJ div {
    display: flex;
    align-items: center;
  }

  div.containerPJ div:not(:last-child) {
    margin-bottom: 5px;
  }

  div.containerEdit img {
    margin-left: 6px;
  }

  div.separator {
    width: 30px;
  }

  div.newKey {
    height: 22px;
    display: flex;
    align-items: center;
    padding-left: 29px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: bold;
    opacity: 0.2;
  }

  div.newKey div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  div.newKey div img {
    height: 50%;
    margin-right: 5px;
  }

  div.boxElement {
    display: flex;
    width: 398px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  div.usedElement {
    width: 398px;
    height: 22px;
    margin-top: 5px;
  }

  div.boxElementSubPhase {
    margin-left: 15px;
    margin-bottom: 5px;
    display: flex;
    align-content: center;
    margin-right: 30px;
  }

  div.saveScope {
    width: 398px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  div.containerHoursAndMins {
    display: flex;
    flex-direction: row;
    height: 22px;
  }

  div.projectImage {
    width: 431px;
    height: 255px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  div.googlemap {
    width: 431px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  div.time:first-child {
    margin-right: 6px;
  }

  div.menu2 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.menu2Info {
    display: flex;
    width: 100%;
  }

  div.previewPhases {
    margin-bottom: 10px;
  }

  div.previewTeams {
    margin-bottom: 10px;
    overflow-x: hidden;
  }

  div.license {
    font-size: 15px;
    font-weight: bold;
    color: var(--labit-card-gray);
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }

  div.licenseKey {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  div.licenseOptions {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div.licenseOptions img {
    cursor: pointer;
    width: 20px;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin-top: 20px;
    height: 55px;
    color: var(--labit-card-gray);
    flex-direction: column;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  div.optionsColumn2 {
    display: flex;
  }

  div.lastRow div.optionsColumn1 {
    padding-right: 10px;
  }

  div.optionsIcons {
    display: flex;
    width: 121px;
    height: 21px;
    justify-content: flex-end;
  }

  div.optionsIcons img {
    width: 21px;
    height: 21px;
    margin-left: 17px;
    cursor: pointer;
  }

  div.optionsSearch {
    display: flex;
    flex-grow: 1;
    height: 21px;
    align-items: center;
    padding-right: 13px;
  }

  div.optionsSearch span {
    margin-right: 13px;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  div.statusHistory {
    margin-top: 20px;
  }

  div.statusHistoryList {
    max-height: 200px;
    overflow-y: auto;
  }

  div.statusHistoryEdit div.divComboBoxElement {
    width: 25%;
  }

  div.statusHistoryEdit {
    margin-top: 10px;
  }

  div.activeContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    color: var(--labit-card-gray);
  }

  div.activeContainer span {
    margin-right: 10px;
  }

  div.mdateBox {
    width: 100%;
  }

  div.mcombo {
    margin-bottom: 0px;
    width: 100%;
  }

  div.stakeholderPerson {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10px 8px 0px;
  }

  div.stakeholderPerson span {
    font-size: 10px;
  }

  div.pager {
    margin: 20px 0px 20px 0px;
  }

  div.comboPlus {
    width: 100%;
    display: flex;
  }

  div.comboPlus div.divComboBox {
    width: 100%;
  }

  img.comboPlusPlus {
    width: 22px;
    height: 22px;
    margin-left: 10px;
  }

  div.titleAdd {
    display: flex;
    align-items: center;
  }

  div.titleAdd span {
    width: auto;
  }

  div.titleAdd img {
    height: 10px;
    margin: 0px 0px 8px 5px;
    width: auto;
  }

  div.titleButtons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 8px;
  }

  div.waiting {
    cursor: wait !important;
  }

  div.planning {
    width: 100%;
    height: 800px;
    display: flex;
  }

  div.creationPannelPlanning {
    padding: 10px !important;
  }

  div.noPlanning {
    margin: 20px;
    color: red;
  }
</style>
