<script>
  export let file;
  export let onClick;

  import { randomString } from "../js/randomString";

  let id = randomString(20);

  let openFileDialog = () => {
    window.$("#" + id).click();
  };
</script>

<div class="fileBoobleFolderUploadButtonContainer">
  <div class="button" on:click={() => openFileDialog()}>
    <img src="/images/cloud-upload.svg" alt="Upload File or Folder" />
    <span>Upload files or drag a folder</span>
  </div>
  <input
    {id}
    type="file"
    multiple
    on:change={(ev) => onClick(ev, file === -1 ? file : file.id)}
  />
</div>

<style>
  div.fileBoobleFolderUploadButtonContainer {
    display: flex;
    justify-content: center;
    margin: 0px 20px 0px 20px;
  }

  div.button {
    background-color: var(--labit-black-button);
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    color: white;
    font-size: 13px;
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    max-width: 180px;
    text-align: center;
  }

  div.button img {
    margin-right: 10px;
    width: 20px;
  }

  input {
    display: none;
  }
</style>
