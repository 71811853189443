<script>
  export let company;
  export let action;
  export let onRemove;
</script>

<div class="dialogProjectStakeHolderListContainer">
  <div class="info {action === 'edit' ? 'edit' : ''}">
    <span>{company.companyName}</span>
  </div>
  <div class="info {action === 'edit' ? 'edit' : ''}">
    <span>{company.role}</span>
  </div>
  {#if action === "edit"}
    <div class="options">
      <img
        class="fadedButton"
        src="/images/trash.svg"
        alt="Remove Stakeholder"
        on:click={onRemove}
      />
    </div>
  {/if}
</div>

<style>
  div.dialogProjectStakeHolderListContainer {
    width: 100%;
    display: flex;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-top: 8px;
  }

  div.info {
    width: 50%;
  }

  div.edit {
    width: 45% !important;
  }

  div.options {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  img {
    height: 15px;
  }
</style>
