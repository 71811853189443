<script>
  export let people;
  export let grayscale;
  export let onHover;
  export let onRemove;
  export let onUnactive;
  export let onActive;
  export let onClickPic;
  export let selectedForRemoval;
  export let isHistory;
  export let isCompany;
  export let action;

  import Picture from "./Picture.svelte";

  import { LABIT_PUBLIC_FOLDER } from "../js/labit-connection";
</script>

<div class="dialogProjectTeamContainer" on:mouseenter={onHover}>
  <Picture
    image={people.peoplePic}
    name={people.peopleName}
    {grayscale}
    onClick={() => onClickPic(people.peopleId)}
    lastActivity={people.lastActivity}
    officeStatus={people.officeStatus}
    atHome={people.atHome}
  />
  {#if !isHistory && action !== "show"}
    <img
      class="remove fadedButton"
      src={selectedForRemoval === people.id
        ? "/images/trash-red.svg"
        : "/images/trash.svg"}
      alt="Remove Team"
      on:click={onRemove}
    />
  {/if}
  {#if !isCompany && action !== "show"}
    <img
      class="unactive fadedButton"
      src="/images/x-red.svg"
      alt="Unactive Team"
      on:click={isHistory ? onActive : onUnactive}
    />
  {/if}
</div>

<style>
  div.dialogProjectTeamContainer {
    width: 76px;
    height: 76px;
    margin-right: 7px;
    cursor: pointer;
    margin-top: 5px;
    position: relative;
  }

  img.remove {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 17px;
    cursor: pointer;
  }

  img.unactive {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 13px;
    cursor: pointer;
  }
</style>
