<script>
  export let text;
  export let action;
  export let last;
  export let width = "";
  export let embed;

  import { getPlatform, setOpenTool } from "../js/localInfo";
  import { openNewWindow } from "../js/openNewWindow";
  import { openURL } from "../js/openURL";

  const platform = getPlatform();

  let clickLink = (forceNewWindow) => {
    if (forceNewWindow) {
      openNewWindow(text);
    } else {
      if (platform === "APP" || (platform === "Web" && embed)) {
        setOpenTool(text);
        openURL("/tools/external", true);
      } else {
        openNewWindow(text);
      }
    }
  };
</script>

{#if action === "show"}
  <span
    class="columnInfo {last ? 'last' : ''}"
    style="{width !== '' ? 'width: ' + width + ';' : ''} white-space: nowrap;
    overflow: hidden; text-overflow: ellipsis;"
    on:click={() => clickLink(false)}
    on:contextmenu={(ev) => {
      ev.preventDefault();
      clickLink(true);
      return false;
    }}
  >
    {text}
  </span>
{:else}
  <input
    style={width !== "" ? "width: " + width + ";" : ""}
    class={last ? "last" : ""}
    type="text"
    bind:value={text}
  />
{/if}

<style>
  span {
    cursor: pointer;
  }

  span.columnInfo,
  input {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-bottom: 8px;
  }

  span.last,
  input.last {
    margin-bottom: 0px;
  }

  input {
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 14px;
    border-color: var(--labit-card-lightgray);
    border-width: 1px;
    border-style: solid;
    height: 17px;
  }
</style>
