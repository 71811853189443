<script>
  export let title;
  export let titleIcon;

  import EditBoxTab from "./EditBoxTab.svelte";
  import EditBoxButton from "./EditBoxButton.svelte";
  import PlatformIconItem from "./PlatformIconItem.svelte";
  import FileUploader from "./FileUploader.svelte";

  import DialogSpanFullEdit from "../modules/project-analytics/screens/components/DialogSpanFullEdit.svelte";

  import { post, rpost, LABIT_PUBLIC_FOLDER } from "../js/labit-connection";

  import { onMount } from "svelte";

  const PLATFORM_FOLDER = "platforms";

  let backgroundColorInput = "#d7e1ea";
  let borderColor = "var(--labit-card-gray)";

  let creating = false;
  let editing = null;
  let deleting = null;

  let currentName = "";
  let currentFile = null;
  let currentPath = "";

  let errorIcon = false;
  let errorName = false;

  let icons = [];

  let cancelTimer = null;

  const CANCEL_DELETING_TIME = 10000;

  onMount(async () => {
    await getIcons();
  });

  let getIcons = async () => {
    const response = await post(
      `select id, name, iconUrl
            from platforms`
    );
    icons = response.map((item) => {
      return {
        id: item.id,
        name: item.name,
        icon: item.iconUrl,
      };
    });
  };

  let requestAdd = () => {
    creating = true;
    editing = null;
    deleting = null;
    currentName = "";
    currentFile = null;
    currentPath = "";
    errorName = false;
    errorIcon = false;
    clearTimeout(cancelTimer);
  };

  let requestEdit = (index) => {
    creating = false;
    editing = icons[index].id;
    deleting = null;
    currentName = icons[index].name;
    currentFile = null;
    currentPath = icons[index].icon.replace(LABIT_PUBLIC_FOLDER, "");
    errorName = false;
    errorIcon = false;
    clearTimeout(cancelTimer);
  };

  let requestDelete = async (index) => {
    creating = false;
    editing = null;
    errorName = false;
    errorIcon = false;
    clearTimeout(cancelTimer);
    if (!deleting) {
      deleting = icons[index].id;
      cancelTimer = setTimeout(cancelDelete, CANCEL_DELETING_TIME);
    } else {
      await post(
        `delete from platforms
                where id=${deleting}`
      );
      icons = icons.filter((item) => {
        return item.id !== deleting;
      });
      closeForm();
    }
  };

  let cancelDelete = () => {
    deleting = null;
  };

  let closeForm = () => {
    creating = false;
    editing = null;
    deleting = null;
    currentName = "";
    currentFile = null;
    currentPath = "";
    errorName = false;
    errorIcon = false;
  };

  let save = async () => {
    errorIcon = false;
    errorName = false;
    if (currentName.trim() === "") {
      errorName = true;
      return;
    }
    console.log(currentFile);
    if (!currentFile) {
      errorIcon = true;
      return;
    }
    if (creating) {
      const response = await post(
        `insert into platforms (name, iconUrl)
                values ('${currentName}', '')`
      );

      const iconId = response[0];

      const path = PLATFORM_FOLDER;
      const fileName = iconId + "." + currentFile.extension;
      const pictureURL = LABIT_PUBLIC_FOLDER + "/" + path + "/" + fileName;

      await rpost("UploadPublicFile", {
        path,
        fileName,
        fileData: currentFile.data,
      });

      await post(
        `update platforms set
          iconUrl='${pictureURL}'
          where id=${iconId}`
      );

      const icon = {
        id: iconId,
        name: currentName,
        icon: pictureURL,
      };
      icons.push(icon);

      currentFile = null;
      currentPath = "";
    } else {
      const iconId = editing;

      const path = PLATFORM_FOLDER;
      const fileName = iconId + "." + currentFile.extension;
      const pictureURL = LABIT_PUBLIC_FOLDER + "/" + path + "/" + fileName;

      await rpost("UploadPublicFile", {
        path,
        fileName,
        fileData: currentFile.data,
      });

      await post(
        `update platforms set
          name='${currentName}',
          iconUrl='${pictureURL}'
          where id=${iconId}`
      );

      let icon = icons.find((item) => {
        return item.id === editing;
      });
      if (icon) {
        icon.name = currentName;
        icon.icon = pictureURL;
      }

      currentFile = null;
      currentPath = "";
    }
    icons = icons;
    closeForm();
  };
</script>

<div class="box">
  <div class="editBoxTab">
    <EditBoxTab icon={titleIcon} text={title} />
  </div>
  <div class="items">
    <div
      class="itemList customScrollbars {creating || editing
        ? 'itemListMin'
        : ''}"
    >
      {#each icons as icon, index}
        <PlatformIconItem
          {icon}
          onEdit={() => requestEdit(index)}
          onDelete={() => requestDelete(index)}
          deletingId={deleting}
        />
      {/each}
    </div>
    <div class="plus">
      <img
        class="fadedButton"
        src="/images/plus.svg"
        alt="Add"
        on:click={() => requestAdd()}
      />
    </div>
  </div>
  <div class="form {creating || editing ? '' : 'hiddenElement'}">
    <span class="editBoxTitle requiredElement">Icon</span>
    <div class="pic">
      <FileUploader
        bind:fileInfo={currentFile}
        loadFromPath={currentPath}
        picExtraStyle=""
        showFullImage={true}
      />
    </div>
    <span class="editBoxTitle requiredElement">Name</span>
    <div class="editBoxInput containerBox">
      <DialogSpanFullEdit
        color={backgroundColorInput}
        height="22"
        border={borderColor}
        error={errorName}
        bind:text={currentName}
      />
    </div>
    <div class="editBoxAcceptCancel">
      <EditBoxButton text="Save" onClick={() => save()} />
      <EditBoxButton text="Close" onClick={() => closeForm()} />
    </div>
  </div>
</div>

<style>
  div.box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  div.editBoxTab {
    width: 428px;
    margin-bottom: 25px;
  }

  span.editBoxTitle {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 10px;
    width: 100%;
  }

  div.editBoxAcceptCancel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  div.editBoxInput {
    height: 22px;
    margin-bottom: 10px;
  }

  div.items {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 15px;
    border: 1px solid var(--labit-card-gray);
    padding: 15px 23px 15px 23px;
  }

  div.plus {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div.plus img {
    width: 20px;
    height: 20px;
  }

  div.itemList {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
  }

  div.itemListMin {
    max-height: 360px !important;
  }

  div.pic {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
  }
</style>
