<script>
    import dayjs from "dayjs";
    import { onMount } from "svelte";
    
    export let predefinedTimes = [
        { "timeStr": "08:30", "work": true, "absence": false, "blocked": false },
        { "timeStr": "08:30", "work": true, "absence": false, "blocked": false },
        { "timeStr": "08:30", "work": true, "absence": false, "blocked": false },
        { "timeStr": "08:30", "work": true, "absence": false, "blocked": false },
        { "timeStr": "06:00", "work": true, "absence": false, "blocked": false },
        { "timeStr": "00:00", "work": false, "absence": false, "blocked": false },
        { "timeStr": "00:00", "work": false, "absence": false, "blocked": false }
    ];
    
    export let currentBillableSeconds = 0;

    const today = dayjs().day();

    const convertTimeToSeconds = (timeStr) => {
        const timeStrParts = timeStr.split(':');
        return parseInt(timeStrParts[0]) * 3600 + parseInt(timeStrParts[1]) * 60;
    }

    const { numberOfAbsencesUntilToday, expectedSeconds, numberOfWorkingDays, maxSeconds, weekArr } = predefinedTimes.reduce((acc, day, i) => { 
        const { timeStr, work, absence, blocked } = day;
        const seconds = convertTimeToSeconds(timeStr);
        if (work) {
            acc.maxSeconds += seconds;
            acc.numberOfWorkingDays++;
            if (i < today) acc.expectedSeconds += seconds;
        }

        if (absence && (i < today)) acc.numberOfAbsencesUntilToday++;
        acc.weekArr.push({ seconds, work, absence, blocked });

        return acc; 
    }, { numberOfAbsencesUntilToday: 0, expectedSeconds: 0, numberOfWorkingDays: 0, maxSeconds: 0, weekArr: [] });

    const maxHours = maxSeconds / 3600;
    const workTimeByDay = (maxSeconds / numberOfWorkingDays) / 3600;
    currentBillableSeconds += numberOfAbsencesUntilToday * workTimeByDay * 3600;

    onMount(() => {
        const bar1 = document.getElementById("bar1");
        
        let progress1 = currentBillableSeconds > 0 ? (currentBillableSeconds * 100) / maxSeconds : 0; // Si es 0 o NaN, en vez dar infinito o NaN como resultado, se queda en 0
        progress1 = progress1 > 100 ? 100 : progress1;

        const bar2 = document.getElementById("bar2");
        let progress2 = expectedSeconds > 0 ? (expectedSeconds * 100) / maxSeconds : 0;
        progress2 = progress2 > 100 ? 100 : progress2;

        let width1 = 0;
        const frame1 = () => {
            if (width1 < progress1) {
                width1++;
                if (bar1 !== null) bar1.style.width = width1 + "%";
                window.requestAnimationFrame(frame1);
            }
        };

        let width2 = 0;
        const frame2 = () => {
            if (width2 < progress2) {
                width2++;
                if (bar2 !== null) bar2.style.width = width2 + "%";
                window.requestAnimationFrame(frame2);
            }
        };
        window.requestAnimationFrame(frame1);
        window.requestAnimationFrame(frame2);
    });
</script>

<div id="container">
    <div class="flex row justify-sp-around">
        <div id="chartContainer" class="blue">
            <div class="daysContainer">
                {#each weekArr as { seconds, work, absence }, i}
                {@const percent = (seconds * 100) / (maxSeconds)}
                    {#if work}
                        <div class="box" class:holiday={absence} style={`width: ${percent}%`}></div>
                    {/if}
                {/each}
            </div>
            <div id="bar2" />
            <div id="bar1" />
        </div>
        <span class="center-self">{maxHours}h</span>
    </div>
</div>

<style>

    .daysContainer > .box:first-child {
        border-radius: 19px 0 0 19px;
    }

    .daysContainer > .box:last-child {
        border-radius: 0 19px 19px 0;
    }

    .center-self {
        align-self: center;
    }

    .flex {
        display: flex;
    }

    .row {
        flex-direction: row;
    }

    .justify-sp-around {
        justify-content: space-around;
    }

    #container {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    #chartContainer {
        height: 22px;
        width: 94%;
        border-radius: 20px;
        position: relative;
        margin: 12px 0;
    }

    #chartContainer.blue {
        background-color: rgb(229 235 237);
    }

    #bar1,
    #bar2 {
        top: 0px;
        width: 0%;
        height: 22px;
        border-radius: 20px;
        z-index: 1;
        position: absolute;
    }

    .blue > #bar1 {
        background-color: #9fc9ba;
    }

    .blue > #bar2 {
        background-color: #c8dfd9;
    }

    .daysContainer {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .box {
        z-index: 2;
        height: 100%;
    }

    .holiday {
        border: none !important;
        background-color: #927dde;
        height: 100%;
    }

    .blue > .daysContainer > .box {
        border-right: 1px solid white !important;
    }

    .box:last-child {
        border: none !important;
    }
</style>
