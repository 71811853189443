<script>
  import Menu from "../components/Menu.svelte";

  import CheckOutlook from "./CheckOutlook.svelte";
  import Dashboard from "./Dashboard.svelte";
  import People from "./People.svelte";
  import Outlook from "./Outlook.svelte";
  import Slack from "./Slack.svelte";
  import Projects from "./Projects.svelte";
  import Process from "./Process.svelte";
  import GuidelinesClients from "./GuidelinesClients.svelte";
  import RoomDataSheet from "./RoomDataSheet.svelte";
  import RoomDataSheetL from "./RoomDataSheetL.svelte";
  import RoomDataSheetG from "./RoomDataSheetG.svelte";
  import Guidelines from "./Guidelines.svelte";
  import Tools from "./Tools.svelte";
  import Direction from "./Direction.svelte";
  import Hubspot from "./Hubspot.svelte";
  import PeopleDocuments from "./PeopleDocuments.svelte";
  import Analytics from "./Analytics.svelte";
  import ProjectsDocuments from "./ProjectsDocuments.svelte";
  import Standards from "./Standards.svelte";
  import DocumentManagement from "./DocumentManagement.svelte";
  import OnlyAdmins from "./OnlyAdmins.svelte";
  import OnlyAnalytics from "./OnlyAnalytics.svelte";
  import ObsoleteVersion from "./ObsoleteVersion.svelte";
  import Settings from "./Settings.svelte";
  import CompleteResourceManager from "./CompleteResourceManager.svelte";

  import Calendar from "../modules/calendar/screens/Calendar.svelte";
  import ExtraTools from "../modules/tools/screens/Tools.svelte";
  import ProjectAnalytics from "../modules/project-analytics/screens/ProjectAnalytics.svelte";
  import RevitLink from "../modules/revit-link/RevitLink.svelte";
  import PDashboard from "../modules/pdashboard/screens/PDashboard.svelte";
  import LicenseManager from "../modules/license-manager/LicenseManager.svelte";
  import AssignedLicenses from "../modules/assigned-licenses/AssignedLicenses.svelte";
  
  import Clockify from "../modules/me/clockify/Clockify.svelte";
  import Factorial from "../modules/me/factorial/Factorial.svelte";
  import Inventory from "../modules/me/inventory/Inventory.svelte";
  import Monitor from "../modules/monitor/Monitor.svelte";
  import Me from "../modules/me/MainWindow.svelte";

  import { outlookGetMessagesTiming } from "../components/dashboard/outlook/outlook";

  import {
    getOutlookToken,
    getRole,
    getUserId,
    getToken,
    getPlatform,
    getSkylabVersion,
  } from "../js/localInfo";
  import { updateRole } from "../js/update-role";
  import { slackURLCorrection } from "../js/slack";
  import { post } from "../js/labit-connection";
  import { updateLastConnection } from "../js/update-last-connection";
  import { updateTester } from "../js/update-tester";
  import { checkSkylabVersion } from "../js/skylab-version";

  import { openSlackUrlStore } from "../js/stores.js";

  import { onDestroy, onMount } from "svelte";
  import { openURL } from "../js/openURL";
  import { mainUrl } from "../js/stores.js";

  let viewAsUser = null;

  let openSlackUrl = "";
  openSlackUrlStore.subscribe( value => openSlackUrl = value );

  const OUTLOOK_NOTIFICATIONS_TIMER = 20000;

  //let myRole = getRole();
  let myId = getUserId();
  let platform = getPlatform(); // Mira en el useragent si existe el literal 'SkyLab', si es asi, es "APP"

  let url = null;

  let selectedPage = "";
  let loadedPages = [];
  let args = [];

  const RELOAD_TIME = 100;

  let requiresOutlook = false;

  let outlookNextPage = "";

  let token = "";

  let showMenu = true;

  let currentSection = "";

  const DEFAULT_PAGE = "/dashboard";

  let mySlacks = [];

  let slackNotification = false;
  let slackNotified = false;
  let outlookNotification = false;
  let outlookNotified = false;

  let pageName = "";

  let oldVersion = false;
  let obsoleteVersion = false;

  let refreshCompanyList = false;
  let refreshProjects = false;
  let selectedProjectPDashboard = -1;

  /* CONF */

  // List of pages that may require a previous Outlook connection
  const requiresOutlookList = [
    "/dashboard",
    "/calendar",
    "/pdashboard",
    "/resource-management",
    "/dashboard-project",
  ];

  // List of pages that do not reload when pressing the menu item twice
  let notReloadingList = [];
  notReloadingList["/tools"] = 0;
  notReloadingList["/slack"] = 0;

  // Assignments of pages to menu icons
  let menuSections = [];
  menuSections["/hubspot"] = "/direction";
  menuSections["/people-documents"] = "/direction";
  menuSections["/roomdatasheet"] = "/guidelines-clients";
  menuSections["/roomdatasheetl"] = "/guidelines-clients";
  menuSections["/roomdatasheetg"] = "/guidelines-clients";
  menuSections["/project-analytics"] = "/analytics";
  menuSections["/projects-documents"] = "/analytics";
  menuSections["/people-documents-analytics"] = "/analytics";
  menuSections["/license-manager"] = "/analytics";
  menuSections["/assigned-licenses"] = "/analytics";
  menuSections["/revit-link"] = "/analytics";
  menuSections["/standards"] = "/analytics";
  menuSections["/document-management"] = "/analytics";
  menuSections["/resource-management"] = "/analytics";
  menuSections["/license-manager"] = "/analytics";
  menuSections["/only-analytics"] = "/analytics";
  menuSections["/settings"] = "/analytics";
  menuSections["/debug"] = "/direction";
  menuSections["/only-direction"] = "/direction";

  // List of pages only accessed by analytics or direction (admin)
  let onlyAnalyticsSections = [
    "/analytics",
    "/project-analytics",
    "/projects-documents",
    "/licenses",
    "/revit-link",
    "/standards",
    "/document-management",
    "/people-documents-analytics",
    "/resource-management",
    "/debug",
    "/pdashboard",
    "/resource-management-static",
    "/license-manager",
    "/assigned-licenses",
    "/settings",
  ];

  // List of pages only accessed by direction (admin)
  let onlyDirectionsSections = ["/direction", "/hubspot", "/people-documents"];

  // Store the last opened child, in pages with children
  let lastOpenedChildren = [];
  lastOpenedChildren["/analytics"] = "";
  lastOpenedChildren["/direction"] = "";

  // Page names (if different to component names)
  let pageNames = [];
  pageNames["/outlook"] = "MAIL";
  pageNames["/slack"] = "CHAT";
  pageNames["/project-dashboard"] = "PLACES";
  pageNames["/projects"] = "ARCHIVE";
  pageNames["/dashboard-project"] = "PROJECT DASHBOARD";
  pageNames["/guidelines-clients"] = "CLIENTS";
  pageNames["/roomdatasheet"] = "ROOM DATA SHEET";
  pageNames["/roomdatasheetl"] = "ROOM DATA SHEET";
  pageNames["/roomdatasheetg"] = "ROOM DATA SHEET GUIDELINES";
  pageNames["/tools"] = "APPS";
  pageNames["/extra-tools"] = "TOOLS";
  pageNames["/people-documents"] = "PAYROLLS";
  pageNames["/project-analytics"] = "PROJECTS";
  pageNames["/projects-documents"] = "PROJECT DOCUMENTS";
  pageNames["/assigned-licenses"] = "LICENSE ASSIGNMENT";
  pageNames["/people-documents-analytics"] = "PEOPLE DOCUMENTS";

  let getSlackNotificationIntervalID = 0;
  let webviewReloadIntervalID = 0;
  mainUrl.subscribe((value) => url = value );
  let slackNotificationCounter = [];

  onMount(async () => {
    window.addEventListener("focus", () => checkLogged(), false);

    if (platform === "APP") {
      await checkAPPVersion();
    }
    if (platform === "APP") {
      getSlacks();
      getSlackNotificationIntervalID = setInterval(() => {
        const webviews = document.querySelectorAll(".slack-webview");
        /*const counter = [];
        [...webviews].map((webview,index) => {
          let changed = false;
          const parts = webview.split('|');
          if(parts.length > 3){
            if( !counter[index] || counter[index] !== parts.at(-1) )
              changed = true;
              counter[index] = parts.at(-1);
          }*/
          let changed = false;
          [...webviews].map((webview,index) => {
            const title = webview.getTitle().split('|');
            console.log(slackNotificationCounter[index]);
            if(title.length > 3){
              if(!slackNotificationCounter[index] || title[title.length -1] !== slackNotificationCounter[index] ){
                slackNotificationCounter[index] = title[title.length - 1];
                changed = true;
              }
            }
          })
          slackNotification = [...webviews].some(webview => webview.getTitle().includes('!'));
          if (slackNotification) {
            if (!slackNotified) {
              new Notification("Slack", {
                body: "You have a new slack.",
              });
            slackNotified = true;
            } else if(slackNotified && changed){
              slackNotified = false;
            }
          } else{
            slackNotified = false;
          }
          }, 5000);

      webviewReloadIntervalID = setInterval(() => {
        const webviews = document.querySelectorAll(".slack-webview");
        if (selectedPage !== "/slack") [...webviews].forEach( webview => webview.reload() );
      }, 10000);
    }
    getOutlookNotifications();
  });

  onDestroy(() => {
    clearInterval(getSlackNotificationIntervalID);
    clearInterval(webviewReloadIntervalID);
  });

  let checkLogged = () => {
    const token = getToken();
    if (token === "") {
      location.href = "/login";
    }
  };

  /* const addRoundOffTimeAutomatically = async () => {
    const start = moment().startOf('day').format('x');
    const end = moment().endOf('day').format('x');

    let res = await post( `SELECT task_type_id FROM tasks WHERE user_id = ${myId} AND start > ${start} AND end < ${end};` );
        
    const thereIsRoundOff = res.some(e=>e.task_type_id === '118')
    if (!thereIsRoundOff) {
      const absStart = moment().hour(19);
      const absEnd = moment().hour(19);

      await post(`INSERT INTO tasks (description, start, end, phase_id, task_type_id, user_id) VALUES ('Round Off time',${absStart.minute(0).format('x')},${absEnd.minute(15).format('x')},'663','118','${myId}');`);
    }
  }; */

  /* SLACK NOTIFICATIONS */

  let getSlacks = async () => {
    let response = await post(
      `select id, url
        from slacks`
    );

    const slacks = response.map((slack) => {
      return {
        id: slack.id,
        name: "",
        url: slack.url,
        icon: "",
        custom: false,
        changes: false,
      };
    });

    response = await post(
      `select id, url, icon
        from slacks_custom
        where contact=${myId}`
    );

    const customSlacks = response.map((slack) => {
      return {
        id: slack.id,
        name: "",
        url: slack.url,
        icon: slack.icon,
        custom: true,
        changes: false,
      };
    });

    let myTools = slacks.concat(customSlacks);

    myTools.forEach((slack) => {
      slack.url = slackURLCorrection(slack.url);
    });

    mySlacks = myTools;
    checkWebviewsAvailability();
  };

  let checkAPPVersion = async () => {
    const myVersion = getSkylabVersion();
    if (myVersion !== "") {
      const publishedVersion = await checkSkylabVersion();
      const publishedVersionI = parseInt(publishedVersion);
      const myVersionI = parseInt(myVersion);
      if (publishedVersionI > myVersionI) {
        oldVersion = true;
        if (publishedVersionI > myVersionI + 1) {
          obsoleteVersion = true;
          url.url = "/obsolete-version";
        }
      }
    }
  };

  let checkWebviewsAvailability = () => {
    let counter = 0;
    mySlacks.forEach((slack, index) => {
      let webview = document.querySelector(
        "#webview_mainskylab_slack_" + index
      );
      if (webview) {
        counter++;
      }
    });
    if (counter === mySlacks.length) {
      mySlacks.forEach((slack, index) => {
        setTimeout(checkSlackTitles, 1000);
      });
    } else {
      setTimeout(checkWebviewsAvailability, 1000);
    }
  };

  // Only for testing and checking titles
  let checkSlackTitles = () => {
    mySlacks.forEach((slack, index) => {
      let webview = document.querySelector("#webview_slack_" + index);
      if (!webview) {
        webview = document.querySelector("#webview_mainskylab_slack_" + index);
      }
      if (webview) {
        const title = webview.getTitle();
      }
    });
    setTimeout(checkSlackTitles, 5000);
  };

  /* OUTLOOK NOTIFICATIONS */

  let getOutlookNotifications = () => {
    const token = getOutlookToken();
    if (token !== "") {
      //console.log("CHECK EMAIL");
      outlookGetMessagesTiming(
        (m, nm) => {
          //console.log(m);
          if (m.length > 0) {
            outlookNotification = m[0].new;
            if (outlookNotification) {
              if (!outlookNotified) {
                const not = new Notification("OUTLOOK", {
                  body: "You have a new email.",
                });

                not.onclick = () => {
                  openURL("/outlook", true);
                };

                outlookNotified = true;
              }
            } else {
              outlookNotified = false;
            }
          } else {
            outlookNotification = false;
            outlookNotified = false;
          }
        },
        () => {
          outlookNotification = false;
          outlookNotified = false;
        }
      );
    } else {
      outlookNotification = false;
      outlookNotified = false;
    }
    setTimeout(getOutlookNotifications, OUTLOOK_NOTIFICATIONS_TIMER);
  };

  //let checkSkylabVersion

  /* SKYLAB MAIN */

  let updateURL = () => {
    //console.log("PREV URL: ", prevURL);

    //url.url = "/rm";

    if (obsoleteVersion) {
      url.url = "/obsolete-version";
    }

    if (url.url === "/") {
      url.url = DEFAULT_PAGE;
    }

    const lastChild = lastOpenedChildren[url.url];
    if (typeof lastChild !== "undefined") {
      if (lastChild !== "") {
        url.url = lastChild;
      }
    }

    let myRole = getRole();
    const analytics = onlyAnalyticsSections.findIndex((item) => {
      return item === url.url;
    });
    const direction = onlyDirectionsSections.findIndex((item) => {
      return item === url.url;
    });
    if (analytics >= 0 && myRole !== "Admin" && myRole !== "Analytics") {
      url.url = "/only-analytics";
    } else {
      if (direction >= 0 && myRole !== "Admin") {
        url.url = "/only-direction";
      }
    }

    requiresOutlook = false;
    const pos = requiresOutlookList.findIndex((item) => {
      return item === url.url;
    });

    requiresOutlook = pos >= 0;
    token = getOutlookToken();
    if (requiresOutlook && outlookNextPage === "" && token === "") {
      outlookNextPage = url.url;
      url = {
        url: "/check-outlook",
      };
    } else {
      outlookNextPage = "";

      const tokens = url.url.split("/");
      args = tokens.reduce((acc, curr, index) => {
        if (index >= 2) {
          acc.push(curr);
        }
        return acc;
      }, []);
      url = args.length > 0 ? { url: "/" + tokens[1] } : url;

      currentSection =
        typeof menuSections[url.url] === "undefined"
          ? url.url
          : menuSections[url.url];

      if (selectedPage === url.url && args.length === 0) {
        // Already selected, reload

        let clicks = notReloadingList[url.url];
        if (typeof clicks !== "undefined") {
          // Not Reloadable
          notReloadingList[url.url]++;
          notReloadingList = notReloadingList;
        } else {
          let parentNode = menuSections[url.url];
          if (typeof parentNode !== "undefined") {
            // Load parent node
            lastOpenedChildren[parentNode] = "";
            url.url = parentNode;
            selectedPage = url.url;
          } else {
            // Reload

            loadedPages = loadedPages.filter((item) => {
              if (item === url.url) {
                return false;
              }
              // Also remove children
              for (var key in menuSections) {
                const value = menuSections[key];
                if (item === key && value === url.url) {
                  return false;
                }
              }
              return true;
            });
            selectedPage = null;
            setTimeout(() => {
              url = url;
            }, RELOAD_TIME);
          }
        }
      } else {
        // Save opened child in case the page has parent
        const parent = menuSections[url.url];
        if (typeof parent !== "undefined") {
          lastOpenedChildren[parent] = url.url;
        }

        selectedPage = url.url;
        const pos = loadedPages.findIndex((item) => {
          return item === url.url;
        });
        if (pos === -1) {
          loadedPages.push(url.url);
        }
      }
      loadedPages = loadedPages;
    }

    pageName = pageNames[url.url];
    if (typeof pageName === "undefined") {
      pageName = url.url.replace(/\//, "").replace(/-/, " ").toUpperCase();
    }
  };

  let nextPageOutlook = () => {
    if (outlookNextPage !== "") {
      url = {
        url: outlookNextPage,
      };
    }
  };

  $: if (url) {
    //console.log(url);
    if (!viewAsUser) {
      updateRole();
      updateTester();
      updateLastConnection();
    }
    //checkSkylabVersion();
    if (!obsoleteVersion) {
      updateURL();
      if (platform === "APP") {
        checkAPPVersion();
      }
    }
  }

  $: if (viewAsUser) {
    loadedPages = [];
    url = {
      url: "/dashboard",
    };
  }

  window.$(document).ready(function () {
    window.$(document).on("keydown", (e) => {
      // Windows
      if ((e.which || e.keyCode) == 116) {
        e.preventDefault();
        updateURL();
      }
      // Mac
      if (e.keyCode == 82 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        updateURL();
      }
    });
  });

  $: if (openSlackUrl !== "") {
    url = {
      url: "/slack",
    };
    updateURL();
  }

</script>

<svelte:head>
  <title>SkyLab - {pageName}</title>
</svelte:head>

<CheckOutlook
  nextPage={() => nextPageOutlook()}
  visible={url.url === "/check-outlook"}
/>
<ObsoleteVersion visible={url.url === "/obsolete-version"} />
<div
  class="content {url.url === '/check-outlook' ||
  url.url === '/obsolete-version'
    ? 'hiddenElement'
    : ''}"
>
  <!-- Menu -->

  <div id="mainMenu" class="menu {showMenu ? '' : 'hiddenElement'}">
    <Menu
      active={currentSection}
      isDashboard={selectedPage === "/dashboard"}
      {slackNotification}
      {outlookNotification}
      oldVersionNotification={oldVersion}
    />
  </div>

  <!-- CHECK OUTLOOK -->
  {#if url.url === "/check-outlook"}
    <div class="container" />
  {/if}

  <!-- DASHBOARD -->
  {#if loadedPages.findIndex((item) => {
    return item === "/dashboard";
  }) >= 0}
    <div id='Dashboard'
      class="container {selectedPage === '/dashboard' ? '' : 'hiddenElement'}"
    >
      <Dashboard visible={selectedPage === "/dashboard"} />
    </div>
  {/if}

  <!-- PEOPLE -->
  {#if loadedPages.findIndex((item) => {
    return item === "/people";
  }) >= 0}
    <div class="container {selectedPage === '/people' ? '' : 'hiddenElement'}">
      <People
        createPeople={selectedPage === "/people" &&
          args.length > 0 &&
          args[0] === "create-people"}
        createCompany={selectedPage === "/people" &&
          args.length > 0 &&
          args[0] === "create-company"}
        showPeopleId={selectedPage === "/people" &&
        args.length > 0 &&
        args[0] === "show-people"
          ? args[1]
          : -1}
        showCompanyId={selectedPage === "/people" &&
        args.length > 0 &&
        args[0] === "show-company"
          ? args[1]
          : -1}
        bind:showMenu
        bind:refreshCompanyList
      />
    </div>
  {/if}
  <!-- OUTLOOK -->
  {#if loadedPages.findIndex((item) => {
    return item === "/outlook";
  }) >= 0}
    <div class="container {selectedPage === '/outlook' ? '' : 'hiddenElement'}">
      <Outlook
        calendar={selectedPage === "/outlook" &&
        args.length > 0 &&
        args[0] === "calendar"
          ? 0
          : -1}
        visible={selectedPage === "/outlook"}
      />
    </div>
  {/if}
  <!-- SLACK -->
  
    <div class="container" style="display: {selectedPage === '/slack' ? 'flex' : 'none'}">
      <Slack
        clicked={notReloadingList["/slack"]}
        visible={selectedPage === "/slack"}
        {openSlackUrl}
      />
    </div>
  
  <!-- CALENDAR -->
  {#if loadedPages.findIndex((item) => {
    return item === "/calendar";
  }) >= 0}
    <div
      class="container {selectedPage === '/calendar' ? '' : 'hiddenElement'}"
    >
      <Calendar visible={selectedPage === "/calendar"} />
    </div>
  {/if}

  <!-- PROJECT DASHBOARD -->
  {#if loadedPages.findIndex((item) => {
    return item === "/project-dashboard";
  }) >= 0}
    <div
      class="container {selectedPage === '/project-dashboard'
        ? ''
        : 'hiddenElement'}"
    >
      <Projects
        dashboard={true}
        bind:refreshCompanyList
        bind:refreshProjects
        bind:selectedProjectPDashboard
      />
    </div>
  {/if}
  <!-- PROJECTS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/projects";
  }) >= 0}
    <div
      class="container {selectedPage === '/projects' ? '' : 'hiddenElement'}"
    >
      <Projects
        idProject={selectedPage === "/projects" &&
        args.length > 0 &&
        args[0] !== "dashboard"
          ? args[0]
          : -1}
        bind:refreshCompanyList
        bind:refreshProjects
        bind:selectedProjectPDashboard
      />
    </div>
  {/if}
  <!-- PROJECT DASHBOARD -->
  {#if loadedPages.findIndex((item) => {
    return item === "/dashboard-project";
  }) >= 0}
    <div id='PDashboard' class="container {selectedPage === '/dashboard-project' ? '' : 'hiddenElement'}">
      <PDashboard />
    </div>
  {/if}

  <!-- PROCESS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/process";
  }) >= 0}
    <div class="container {selectedPage === '/process' ? '' : 'hiddenElement'}">
      <Process
        visible={selectedPage === "/process"}
        oldVersionNotification={oldVersion}
      />
    </div>
  {/if}
  <!-- GUIDELINES CLIENTS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/guidelines-clients";
  }) >= 0}
    <div
      class="container {selectedPage === '/guidelines-clients'
        ? ''
        : 'hiddenElement'}"
    >
      <GuidelinesClients />
    </div>
  {/if}
  <!-- ROOM DATA SHEET -->
  {#if loadedPages.findIndex((item) => {
    return item === "/roomdatasheet";
  }) >= 0}
    <div
      class="container {selectedPage === '/roomdatasheet'
        ? ''
        : 'hiddenElement'}"
    >
      <RoomDataSheet
        idCompany={selectedPage === "/roomdatasheet" &&
        args.length > 0 &&
        args[0]
          ? args[0]
          : null}
        idVersion={selectedPage === "/roomdatasheet" &&
        args.length > 0 &&
        args[1]
          ? args[1]
          : null}
        idProject={selectedPage === "/roomdatasheet" &&
        args.length > 0 &&
        args[2]
          ? args[2]
          : null}
        setPrintView={selectedPage === "/roomdatasheet" &&
          args.length > 0 &&
          args[3] === "printmode"}
      />
    </div>
  {/if}
  <!-- ROOM DATA SHEET L -->
  {#if loadedPages.findIndex((item) => {
    return item === "/roomdatasheetl";
  }) >= 0}
    <div
      class="container {selectedPage === '/roomdatasheetl'
        ? ''
        : 'hiddenElement'}"
    >
      <RoomDataSheetL
        idCompany={selectedPage === "/roomdatasheetl" &&
        args.length > 0 &&
        args[0]
          ? args[0]
          : null}
        idVersion={selectedPage === "/roomdatasheetl" &&
        args.length > 0 &&
        args[1]
          ? args[1]
          : null}
      />
    </div>
  {/if}
  <!-- ROOM DATA SHEET G -->
  {#if loadedPages.findIndex((item) => {
    return item === "/roomdatasheetg";
  }) >= 0}
    <div
      class="container {selectedPage === '/roomdatasheetg'
        ? ''
        : 'hiddenElement'}"
    >
      <RoomDataSheetG
        idCompany={selectedPage === "/roomdatasheetg" &&
        args.length > 0 &&
        args[0]
          ? args[0]
          : null}
        idVersion={selectedPage === "/roomdatasheetg" &&
        args.length > 0 &&
        args[1]
          ? args[1]
          : null}
        idProject={selectedPage === "/roomdatasheetg" &&
        args.length > 0 &&
        args[2]
          ? args[2]
          : null}
        visible={selectedPage === "/roomdatasheetg"}
      />
    </div>
  {/if}
  <!-- GUIDELINES -->
  {#if loadedPages.findIndex((item) => {
    return item === "/guidelines";
  }) >= 0}
    <div
      class="container {selectedPage === '/guidelines' ? '' : 'hiddenElement'}"
    >
      <Guidelines
        idCompany={selectedPage === "/guidelines" && args.length > 0 && args[0]
          ? args[0]
          : null}
        idVersion={selectedPage === "/guidelines" && args.length > 0 && args[1]
          ? args[1]
          : null}
        idProject={selectedPage === "/guidelines" && args.length > 0 && args[2]
          ? args[2]
          : null}
        idProgram={selectedPage === "/guidelines" && args.length > 0 && args[3]
          ? args[3]
          : null}
        idSpace={selectedPage === "/guidelines" && args.length > 0 && args[4]
          ? args[4]
          : null}
        idRoom={selectedPage === "/guidelines" && args.length > 0 && args[5]
          ? args[5]
          : null}
        visible={selectedPage === "/guidelines"}
      />
    </div>
  {/if}

  <!-- TOOLS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/tools";
  }) >= 0}
    <div class="container {selectedPage === '/tools' ? '' : 'hiddenElement'}">
      <Tools
        clicked={notReloadingList["/tools"]}
        externalUrl={selectedPage === "/tools" &&
          args.length > 0 &&
          args[0] === "external"}
        visible={selectedPage === "/tools"}
      />
    </div>
  {/if}
  <!-- EXTRA TOOLS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/extra-tools";
  }) >= 0}
    <div
      class="container {selectedPage === '/extra-tools' ? '' : 'hiddenElement'}"
    >
      <ExtraTools />
    </div>
  {/if}

  <!-- DIRECTION -->
  {#if loadedPages.findIndex((item) => {
    return item === "/direction";
  }) >= 0}
    <div
      class="container {selectedPage === '/direction' ? '' : 'hiddenElement'}"
    >
      <Direction />
    </div>
  {/if}
  <!-- HUBSPOT -->
  {#if loadedPages.findIndex((item) => {
    return item === "/hubspot";
  }) >= 0}
    <div class="container {selectedPage === '/hubspot' ? '' : 'hiddenElement'}">
      <Hubspot visible={selectedPage === "/hubspot"} />
    </div>
  {/if}
  <!-- PEOPLE DOCUMENTS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/people-documents";
  }) >= 0}
    <div
      class="container {selectedPage === '/people-documents'
        ? ''
        : 'hiddenElement'}"
    >
      <PeopleDocuments admin={true} />
    </div>
  {/if}

  <!-- ANALYTICS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/analytics";
  }) >= 0}
    <div
      class="container {selectedPage === '/analytics' ? '' : 'hiddenElement'}"
    >
      <Analytics />
    </div>
  {/if}
  <!-- PROJECT ANALYTICS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/project-analytics";
  }) >= 0}
    <div
      class="container {selectedPage === '/project-analytics'
        ? ''
        : 'hiddenElement'}"
    >
      <ProjectAnalytics
        openNewProject={selectedPage === "/project-analytics" &&
          args.length > 0 &&
          args[0] === "new"}
        bind:refreshCompanyList
        bind:refreshProjects
        projectManagerMode={selectedPage === "/project-analytics" &&
          args.length > 0 &&
          args[0] === "pm"}
        projectManagerProjectId={selectedPage === "/project-analytics" &&
        args.length > 0 &&
        args[0] === "pm"
          ? args[1]
          : -1}
      />
    </div>
  {/if}
  <!-- PROJECT DOCUMENTS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/projects-documents";
  }) >= 0}
    <div
      class="container {selectedPage === '/projects-documents'
        ? ''
        : 'hiddenElement'}"
    >
      <ProjectsDocuments />
    </div>
  {/if}
  <!-- PEOPLE DOCUMENTS ANALYTICS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/people-documents-analytics";
  }) >= 0}
    <div
      class="container {selectedPage === '/people-documents-analytics'
        ? ''
        : 'hiddenElement'}"
    >
      <PeopleDocuments admin={false} />
    </div>
  {/if}
  <!-- LICENSES -->
  {#if loadedPages.findIndex((item) => {
    return item === "/license-manager";
  }) >= 0}
    <div
      class="container {selectedPage === '/license-manager'
        ? ''
        : 'hiddenElement'}"
    >
      <LicenseManager />
    </div>
  {/if}
  {#if loadedPages.findIndex((item) => {
    return item === "/assigned-licenses";
  }) >= 0}
    <div
      class="container {selectedPage === '/assigned-licenses'
        ? ''
        : 'hiddenElement'}"
    >
      <AssignedLicenses />
    </div>
  {/if}
  <!-- REVIT LINK -->
  {#if loadedPages.findIndex((item) => {
    return item === "/revit-link";
  }) >= 0}
    <div
      class="container {selectedPage === '/revit-link' ? '' : 'hiddenElement'}"
    >
      <RevitLink />
    </div>
  {/if}
  <!-- STANDARDS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/standards";
  }) >= 0}
    <div
      class="container {selectedPage === '/standards' ? '' : 'hiddenElement'}"
    >
      <Standards />
    </div>
  {/if}
  <!-- DOCUMENT MANAGEMENT -->
  {#if loadedPages.findIndex((item) => {
    return item === "/document-management";
  }) >= 0}
    <div
      class="container {selectedPage === '/document-management'
        ? ''
        : 'hiddenElement'}"
    >
      <DocumentManagement />
    </div>
  {/if}
  <!-- RESOURCE MANAGEMENT -->
  {#if loadedPages.findIndex((item) => {
    return item === "/resource-management";
  }) >= 0}
    <div
      class="container {selectedPage === '/resource-management'
        ? ''
        : 'hiddenElement'}"
    >
      <CompleteResourceManager />
    </div>
  {/if}
  <!-- SETTINGS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/settings";
  }) >= 0}
    <div
      class="container {selectedPage === '/settings' ? '' : 'hiddenElement'}"
    >
      <Settings />
    </div>
  {/if}

  <!-- DEBUG -->
  {#if loadedPages.findIndex((item) => {
    return item === "/debug";
  }) >= 0}
    <div class="container {selectedPage === '/debug' ? '' : 'hiddenElement'}">
      <Debug />
    </div>
  {/if}
  <!--{#if loadedPages.findIndex((item) => {
    return item === "/rm";
  }) >= 0}
    <div class="container {selectedPage === '/rm' ? '' : 'hiddenElement'}">
      <CompleteResourceManager />
    </div>
  {/if}-->

  <!-- ONLY ANALYTICS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/only-analytics";
  }) >= 0}
    <div
      class="container {selectedPage === '/only-analytics'
        ? ''
        : 'hiddenElement'}"
    >
      <OnlyAnalytics />
    </div>
  {/if}
  <!-- ONLY ADMINS -->
  {#if loadedPages.findIndex((item) => {
    return item === "/only-direction";
  }) >= 0}
    <div
      class="container {selectedPage === '/only-direction'
        ? ''
        : 'hiddenElement'}"
    >
      <OnlyAdmins />
    </div>
  {/if}

  {#if loadedPages.findIndex((item) => {
    return item === "/clockify";
  }) >= 0}
    <div class="container {selectedPage === '/clockify' ? '' : 'hiddenElement'}" >
      <Clockify />
    </div>
  {/if}
  {#if loadedPages.findIndex((item) => {
    return item === "/factorial";
  }) >= 0}
    <div class="container {selectedPage === '/factorial' ? '' : 'hiddenElement'}" >
      <Factorial />
    </div>
  {/if}
  {#if loadedPages.findIndex((item) => {
    return item === "/me";
  }) >= 0}
    <div class="container {selectedPage === '/me' ? '' : 'hiddenElement'}" >
      <Me />
    </div>
  {/if}
  {#if loadedPages.findIndex((item) => {
    return item === "/inventory";
  }) >= 0}
    <div class="container {selectedPage === '/inventory' ? '' : 'hiddenElement'}" >
      <Inventory />
    </div>
  {/if}
  {#if loadedPages.findIndex((item) => {
    return item === "/monitor";
  }) >= 0}
    <div class="container {selectedPage === '/monitor' ? '' : 'hiddenElement'}" >
      <Monitor />
    </div>
  {/if}
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    /*margin: auto;
    min-height: 100%;*/
    flex: 1;
  }

  div.container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  div.menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 999;
    background-color: var(--labit-background-gray);
    padding-bottom: 20px;
    margin-bottom: -20px;
  }

  webview {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
  }
</style>
