<script>
  import ClickOutside from "svelte-click-outside";
  import { changeStatusPlannerTask } from "../../../../components/dashboard/outlook/outlook.js";
  import { createEventDispatcher } from "svelte";
  import { get_current_component } from "svelte/internal";
  import { fade } from 'svelte/transition';

  export let task;
  export let status;
  export let editable;

  let color = "";
  switch (status) {
    case "todo":
      color = "transparent";
      break;
    case "inprogress":
      color = "#327eaa";
      break;
  }

  const component = get_current_component();
  const svelteDispatch = createEventDispatcher();
  const dispatch = (name) => {
    svelteDispatch(name);
    component.dispatchEvent && component.dispatchEvent(new CustomEvent(name));
  };

  let m = { x: 0, y: 0 };

  let statusOptions = [
    { value: "todo", label: "To do", color: "rgb(225 225 225)" },
    { value: "inprogress", label: "In progress", color: "rgb(200 225 255)" },
    { value: "done", label: "Done", color: "rgb(211 255 211)" },
  ];

  let button;
  let showDropdownMenu = false;

  const dropdown = (event) => {
    m.x = event.clientX;
    m.y = event.clientY;
    showDropdownMenu = !showDropdownMenu;
    console.log(m);
  };

  const update = async (val) => {
    if (val !== status) {
      await changeStatusPlannerTask(task, val);
      dispatch("updated");
      showDropdownMenu = false;
    }
  };
</script>

<div class="taskContainer">
  <div class="progressColorContainer">
    <svg height="12" width="12">
      <circle cx="6" cy="6" r="4" fill={color} />
    </svg>
  </div>
  <div class="taskInfoName">
    <span class="taskName">{task.title || task.name}</span>
  </div>
  {#if editable}
  <div class="btnContainer">
    <div class="dropdownBtn" bind:this={button} on:click={dropdown} />
  </div>
  {/if}
  {#if showDropdownMenu}
    <ClickOutside
      on:clickoutside={() => {
        showDropdownMenu = false;
      }}
      exclude={[button]}
    >
      <!-- <div class="dropdownMenu" style="top: {m.y}px; left: {m.x}px;"> -->
      
      <div class="dropdownMenu">
        {#each statusOptions as option}
          <div
            class="dropdownOption"
            style="background-color: {option.color};"
            on:click={() => update(option.value)}
            transition:fade
          >
            {option.label}
          </div>
        {/each}
      </div>
      
    </ClickOutside>
  {/if}
</div>

<style>
  .progressColorContainer {
    display: flex;
    align-self: center;
  }

  .dropdownOption {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    cursor: pointer;
    border-radius: 6px;
  }

  .dropdownMenu {
    width: 120px;
    position: absolute;
    z-index: 9999999;
    top: 0px;
    left: 70px;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
  }

  div.taskContainer {
    display: flex;
    position:relative;
    flex-direction: row;
    width: 100%;
    min-height: 32px;
    margin: 3px 0 3px 0;
    border-bottom: 1px solid #e3e3e399;
  }

  div.taskInfoName {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 5%;
  }

  .btnContainer {
    min-width: 24px;
    align-self: center;
  }

  .dropdownBtn {
    height: 12px;
    width: 12px;
    cursor: pointer;
    background-image: url("/images/down_arrow.svg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: auto;
  }
</style>
