<script>
  export let clicked;
  export let externalUrl = "";
  export let visible;

  import Menu from "../components/Menu.svelte";
  import MenuTool from "../components/MenuTool.svelte";
  import FormTextEdit from "../components/FormTextEdit.svelte";
  import Button from "../components/Button.svelte";
  import FileUploader from "../components/FileUploader.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  //import ContextMenu from "../components/ContextMenu.svelte";

  import { getUserId, getPlatform, getOpenTool } from "../js/localInfo";
  import { copyObject } from "../js/copyObject";
  import { post, rpost } from "../js/labit-connection";
  import { openNewWindow } from "../js/openNewWindow";

  import { onMount } from "svelte";

  const PAGE = "APPS";

  const TOOLS_REMOTE_URL = "/tools";

  let managingTools = false;
  let selectedTool = null;
  let formAction = "new"; // new, edit
  let currentTool = "";

  const myId = getUserId();
  let myTools = [];
  const platform = getPlatform();

  let toolbarShown = false;

  let showNavMenu = null;

  let externalTool = "";

  onMount(async () => {
    /*if (externalUrl !== "") {
      openExternalUrl();
    }*/

    let response = await post(
      `select id, name, url, icon, type
          from external_tools`
    );

    const myFixedTools = response.map((tool) => {
      return {
        id: tool.id,
        name: tool.name,
        url: tool.url,
        icon: tool.icon,
        type: tool.type,
        custom: false,
        visible: false,
      };
    });

    response = await post(
      `select id, name, url, icon, type
          from external_tools_custom
          where contact=${myId}`
    );

    const myCustomTools = response.map((tool) => {
      return {
        id: tool.id,
        name: tool.name,
        url: tool.url,
        icon: tool.icon,
        type: tool.type,
        custom: true,
        visible: false,
      };
    });

    myTools = myFixedTools.concat(myCustomTools);

    openMenu();
  });

  let showToolForm = (show) => {
    managingTools = show;
    if (show) {
      formAction = "new";
      selectedTool = {
        id: -1,
        name: "",
        url: "",
        icon: "",
        type: "embed",
        custom: true,
        image: null,
      };
    }
  };

  let toggleToolForm = () => {
    if (managingTools) {
      showToolForm(false);
    } else {
      showToolForm(true);
    }
  };

  let clickTool = (tool, forceNewWindow) => {
    //console.log(tool);
    externalTool = "";
    if (platform === "Web") {
      if (tool.type === "redirect" || forceNewWindow) {
        window.open(tool.url);
      } else {
        tool.visible = true;
        currentTool = tool.url;
      }
    } else {
      if (forceNewWindow) {
        openNewWindow(tool.url);
      } else {
        tool.visible = true;
        tool = tool;
        currentTool = tool.url;
      }
    }
  };

  let toogleForceNewTab = () => {
    selectedTool.type = selectedTool.type === "embed" ? "redirect" : "embed";
  };

  let addTool = async () => {
    if (
      selectedTool.name !== "" &&
      selectedTool.url !== "" &&
      selectedTool.image
    ) {
      const response = await post(
        `insert into external_tools_custom (
          name, 
          url, 
          type, 
          contact
        )values(
          '${selectedTool.name}',
          '${selectedTool.url}',
          '${selectedTool.type}',
          ${myId}
            )`
      );
      selectedTool.id = response[0];

      if (selectedTool.image) {
        const fileName = selectedTool.id + "." + selectedTool.image.extension;
        const fullPath = TOOLS_REMOTE_URL + "/" + fileName;
        await rpost("UploadPublicFile", {
          path: TOOLS_REMOTE_URL,
          fileName,
          fileData: selectedTool.image.data,
        });

        await post(
          `update external_tools_custom
            set icon='${fullPath}'
            where id=${selectedTool.id}`
        );

        selectedTool.icon = fullPath;
      }

      myTools.push(selectedTool);
      myTools = myTools;
      showToolForm(false);
    }
  };

  let clickEditTool = (tool) => {
    formAction = "edit";
    selectedTool = copyObject(tool);
  };

  let editTool = async () => {
    if (
      selectedTool.name !== "" &&
      selectedTool.url !== "" &&
      selectedTool.image
    ) {
      await post(
        `update external_tools_custom set
          name='${selectedTool.name}',
          url='${selectedTool.url}', 
          type='${selectedTool.type}'
          where id=${selectedTool.id}`
      );
      const tool = myTools.find((t) => {
        return t.id === selectedTool.id && t.custom;
      });
      if (tool) {
        tool.name = selectedTool.name;
        tool.url = selectedTool.url;
        tool.type = selectedTool.type;
        myTools = myTools;
        showToolForm(false);
      }

      const fileName = selectedTool.id + "." + selectedTool.image.extension;
      const fullPath = TOOLS_REMOTE_URL + "/" + fileName;
      await rpost("UploadPublicFile", {
        path: TOOLS_REMOTE_URL,
        fileName,
        fileData: selectedTool.image.data,
      });

      await post(
        `update external_tools_custom
            set icon='${fullPath}'
            where id=${selectedTool.id}`
      );

      selectedTool.icon = fullPath;

      const pos = myTools.findIndex((tool) => {
        return tool.id === selectedTool.id && tool.custom;
      });
      if (pos >= 0) {
        myTools[pos] = copyObject(selectedTool);
      }
      myTools = myTools;
      showToolForm(false);
    }
  };

  let removeTool = async (index) => {
    const tool = myTools[index];
    await post(
      `delete from external_tools_custom
        where id=${tool.id}`
    );
    myTools.splice(index, 1);
    myTools = myTools;
  };

  let closeMenu = () => {
    if (toolbarShown && !managingTools) {
      window.$("#toolBar_tools").animate(
        {
          height: "0px",
          marginBottom: "0px",
        },
        500,
        () => {
          toolbarShown = false;
        }
      );
    }
  };

  let openMenu = () => {
    window.$("#toolBar_tools").animate(
      {
        height: "48px",
        marginBottom: "20px",
      },
      500,
      () => {
        toolbarShown = true;
      }
    );
  };

  let processToolbar = () => {
    if (toolbarShown) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  let openExternalUrl = () => {
    if (externalUrl) {
      closeMenu();
      externalTool = getOpenTool();
    }
  };

  $: if (clicked) {
    processToolbar();
  }

  $: if (visible) {
    openExternalUrl();
  }
</script>

<div class="content">
  <div class="container">
    <div class="tools">
      <div
        id="toolBar_tools"
        on:contextmenu={(ev) => {
          ev.stopPropagation();
          return false;
        }}
      >
        {#each myTools as tool, index}
          <MenuTool
            {tool}
            onClick={(tool, forceNewWindow) => clickTool(tool, forceNewWindow)}
            editMode={managingTools}
            onRemove={() => removeTool(index)}
            onClickEdit={(tool) => clickEditTool(tool)}
          />
        {/each}
        <img
          class="add"
          src="/images/plus.svg"
          alt="Add Tool"
          on:click={() => toggleToolForm()}
        />
      </div>
      {#if selectedTool && managingTools}
        <div class="toolForm">
          <div class="x">
            <img
              src="/images/x.svg"
              alt="Close Form"
              on:click={() => showToolForm(false)}
            />
          </div>
          <div class="formElement">
            <div class="pic">
              <FileUploader
                bind:fileInfo={selectedTool.image}
                loadFromPath={selectedTool.icon}
                picExtraStyle="border-radius: 10px;"
              />
            </div>
          </div>
          <div class="formElement">
            <span>Name</span>
            <div class="formLine">
              <FormTextEdit placeholder="" bind:value={selectedTool.name} />
            </div>
          </div>
          <div class="formElement">
            <span>URL</span>
            <div class="formLine">
              <FormTextEdit
                placeholder=""
                maxlength={500}
                bind:value={selectedTool.url}
              />
            </div>
          </div>
          <div class="formElement">
            <span>
              Some websites may reject to be embed when using the web version of
              Skylab. Check this option to open it in a new browser tab if this
              happens (Unchecked by default).
            </span>
            <div class="formOption" on:click={() => toogleForceNewTab()}>
              <img
                src={selectedTool.type === "embed"
                  ? "/images/checkbox_empty.svg"
                  : "/images/checkbox.svg"}
                alt="Check Option"
              />
              <span>
                Force to open this tool in a new tab when using Skylab in web
                mode.
              </span>
            </div>
          </div>
          <div class="formElement space">
            <div class="formButton">
              <Button
                text="Save"
                onClick={() => {
                  if (formAction === "new") {
                    addTool();
                  } else {
                    editTool();
                  }
                }}
              />
            </div>
          </div>
        </div>
      {/if}
    </div>
    {#each myTools as tool, index}
      {#if tool.visible}
        <div
          class="iframe {currentTool === tool.url && externalTool === ''
            ? ''
            : 'hiddenElement'}"
        >
          <WebBrowser
            type={platform === "Web" && tool.url !== "" ? "iframe" : "webview"}
            iframeTitle="External Tool"
            id={"hubspotWebBrowser"}
            useragent={tool.url.includes("hubspot.es") ||
            tool.url.includes("hubspot.com")
              ? "Custom"
              : "Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"}
            src={tool.url}
          />
        </div>
      {/if}
    {/each}
    <div class="iframe {externalTool !== '' ? '' : 'hiddenElement'}">
      <WebBrowser
        type={platform === "Web" && externalTool !== "" ? "iframe" : "webview"}
        iframeTitle="External Tool"
        id={"wv_ext_tools"}
        useragent="Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"
        src={externalTool}
      />
    </div>
  </div>
</div>
<!--
{#if visible}
  <ContextMenu currentURL={currentTool} bind:showMenu={showNavMenu} />
{/if}
-->

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  div.tools {
    width: 100%;
    min-height: 14px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  div#toolBar_tools {
    width: 100%;
    height: 0px;
    background-color: var(--labit-dialog-background);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 33px;
    padding-right: 33px;
    position: relative;
    margin-bottom: 0px;
  }

  img.add {
    height: 45%;
    cursor: pointer;
    position: absolute;
    right: 33px;
  }

  div.toolForm {
    background-color: var(--labit-dialog-background);
    width: 30%;
    padding: 14px;
    margin-bottom: 14px;
    border-radius: 10px;
  }

  div.toolForm span {
    font-size: 13px;
    color: var(--labit-card-gray);
  }

  div.formElement {
    margin-bottom: 6px;
  }

  div.formLine {
    height: 23px;
  }

  div.formOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  div.formOption img {
    width: 15px;
    margin-right: 8px;
  }

  div.formButton {
    width: 77px;
    height: 29px;
  }

  div.space {
    margin-top: 19px;
  }

  div.x {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  div.x img {
    width: 12px;
    cursor: pointer;
  }

  div.iframe {
    width: 100%;
    flex-grow: 1;
    border-radius: 19px;
    padding-bottom: 20px;
  }

  div.pic {
    width: 120px;
    height: 120px;
  }

  /* Animations */

  img.add {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  img.add:hover {
    opacity: 0.5;
  }
</style>
