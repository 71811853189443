<script>
  export let placeholder;
  export let maxlength = 150;
  export let img;
  export let value;
  export let onKeyUp = null;
</script>

<style>
  div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  input {
    width: 100%;
    height: 100%;
    background-color: white;
    border-style: solid;
    border-color: var(--labit-gray);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 960px/960px;
    font-size: 13px;
    position: relative;
    color: var(--labit-card-lightgray);
  }

  input.inputImagePad {
    padding-right: 25px;
  }

  img {
    position: absolute;
    height: 50%;
    right: 10px;
  }
</style>

<div>
  {#if img}
    <input
      class="inputImage inputImagePad"
      type="text"
      {placeholder}
      {maxlength}
      bind:value
      on:keyup={onKeyUp} />
    <img src={img} alt="icon" />
  {:else}
    <input
      class="inputImage"
      type="text"
      {placeholder}
      {maxlength}
      bind:value
      on:keyup={onKeyUp} />
  {/if}

</div>
