<script>
  export let pic;
  export let name;
  export let category;
  export let address;
  export let city;
  export let status;
  export let email;
  export let onClick;
</script>

<style>
  div.content {
    width: 100%;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--labit-card-gray);
  }

  div.noActive {
    color: var(--labit-text-lightgray) !important;
  }

  div.column1 {
    width: 6%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div.column1 img {
    max-width: 58px;
    max-height: 38px;
  }

  div.column2 {
    width: 19%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
  }

  div.column3 {
    width: 12%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column4 {
    width: 23%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column5 {
    width: 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column6 {
    width: 7%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column7 {
    width: 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  div.column7 a {
    width: 100%;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div.column8 {
    width: 3%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  div.column8 img {
    width: 40%;
    opacity: 1;
    cursor: pointer;
  }

  /* Animations */

  div.content {
    transition: background-color 0.5s linear 0s;
  }

  div.content:hover {
    background-color: lightgray;
  }

  div.column8 img {
    transition: opacity 0.5s linear 0s;
  }

  div.column8 img:hover {
    opacity: 0.5;
  }
</style>

<div class="content {status ? '' : 'noActive'}">
  <div class="column1">
    <img
      src={pic}
      alt="Company Pic"
      onerror="this.src='/images/default-company.png';" />
  </div>
  <div class="column2">
    <span>{name}</span>
  </div>
  <div class="column3">
    <span>{category}</span>
  </div>
  <div class="column4">
    <span>{address}</span>
  </div>
  <div class="column5">
    <span>{city}</span>
  </div>
  <div class="column6">
    <span>{status ? 'Active' : 'No Active'}</span>
  </div>
  <div class="column7">
    <a href={'mailto:' + email}>
      <span>{email}</span>
    </a>
  </div>
  <div class="column8">
    <img
      class="opcionesDots"
      src="/images/dots.svg"
      alt="Opciones"
      on:click={onClick} />
  </div>
</div>
