<script>
  export let event;

  let time = "";

  let getTime = () => {
    if (!event.isAllday) {
      const datetime = event.startDateTime.split("T");
      const tokens = datetime[1].split(":");
      time = tokens[0] + ":" + tokens[1];
    }
  };

  $: if (event) {
    getTime();
  }
</script>

<div class="outlookCalendarContainer">
  <div class="header">
    <div class="title">
      <span>{event.subject}</span>
    </div>
    <div class="time">
      <span>{time}</span>
    </div>
  </div>
  <div class="description">
    <div>
      <span>{event.description}</span>
    </div>
  </div>
</div>

<style>
  div.outlookCalendarContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  div.header {
    width: 100%;
    display: flex;
    margin-bottom: 2px;
    align-items: flex-start;
  }

  div.title {
    width: 80%;
    font-size: 15px;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div.time {
    width: 20%;
    font-size: 12px;
    color: var(--labit-dashboard-light-gray);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  div.description {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: var(--labit-dashboard-light-gray);
  }

  div.description div {
    width: 100%;
  }
</style>
