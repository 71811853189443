<script>
  export let id;
  export let description;

  import { linkify } from "../js/linkify";

  import { onMount } from "svelte";

  onMount(() => {
    window.$("#" + id).html(linkify(window.$("#" + id).html()));
  });
</script>

<style>
  div.comment {
    background-color: white;
    width: 100%;
    font-size: 12px;
    border-radius: 10px;
    padding: 6px;
  }

  div.arrow {
    display: flex;
    justify-content: center;
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
  }
</style>

<div class="arrow">
  <div class="arrow-up" />
</div>
<div {id} class="comment withURL">{description}</div>
