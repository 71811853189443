<script>
  export let visible;

  import { getAllProjects } from "../../../js/projects";
  import EventManager from "./components/EventManager.svelte";
  import CalendarType from "./components/CalendarType.svelte";
  import Combobox from "../../../components/Combobox.svelte";
  import CalendarView from "./components/CalendarView.svelte";
  import MonthlyCalendar from "./components/MonthlyCalendar.svelte";
  import { copyObject } from "../../../js/copyObject";
  import DayComponent from "./components/DayComponent.svelte";
  import Loading from "../../../components/Loading.svelte";
  import { onMount, afterUpdate } from "svelte";
  import {
    outlookLogin,
    outlookGetTasks,
    outlookGetCalendars,
    outlookGetCalendarEvents,
  } from "../../../components/dashboard/outlook/outlook";
  // Just in case you need user's rol and id
  import { getRole, getUserId, getOutlookToken } from "../../../js/localInfo";
  // Just in case you need to access the database through the simplerest API
  import { post } from "../../../js/labit-connection";
  import {
    saveCalendar,
    saveGCalendar,
    saveEACalendar,
    saveBCalendar,
    saveHCalendar,
    getCalendar,
    getGCalendar,
    getEACalendar,
    getBCalendar,
    getHCalendar,
  } from "../../session/session";
  import moment from 'moment';

  const PAGE = "CALENDAR";
  const BIRTHDAYBACKGROUNDCOLOR = "#E0EB49";
  const HOLIDAYSBACKGROUNDCOLOR = "#8b008b";
  const ABSENCEBACKGROUNDCOLOR = "#B8860B";
  const BIRTHDAYTEXTCOLOR = "black";
  const PROJECTTEXTCOLOR = "white";

  const emptyEvent = {
    id: "",
    title: "",
    description: "",
    type: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    allDay: false,
    location: "",
    createdBy: "",
    creationDate: "",
    labitPeople: [],
    people: [],
    outlookCalendar: "",
    outlookEventType: "",
    externalPeopleList: [],
    externalPeople: "",
  };

  //let myRole = getRole();
  let myId = getUserId();
  //let myId = 29;
  let activeBirthday = true;
  let activeHolidays = true;
  let activeTasks = true;
  let activeMeetings = true;
  let activeGeneralEvents = true;
  let activeAbsence = true;
  let monthlyCalendarCount = 0;
  let view = "m";
  let currentYear = moment().year();
  let dateOptions = [];
  let dateOptionsSelected = null;
  let currentDate = moment();
  let events = [];
  let eventsProject = [];
  let eventsFiltered = [];
  let projects = [];
  let holidays = [];
  let absences = [];
  let statusCreationEvent = false;
  let statusEventForm = false;
  let opOutlookError = false;
  let opOutlookMsgError = "";
  let myProjectsList = [];
  let pressedArrow = true;
  let outlookError = false;
  let outlookEvents = [];
  let closeErrorMsg = false;
  let currentEvent = null;
  let op = "create";
  let eventType = "";
  let clickedCalendar = "";
  let outlookCalendars = null;
  let myOutlookCalendarsList = [];
  let myOutlookCalendarEventList = [];
  let fixedOutlookCalendarsList = [];
  let fixedMyProjectsList = [];
  let fixedEvents = [];
  let loadingOutlookEvents = false;
  let allMyProjects = [];

  let processingProjects = 0;

  onMount(async () => {
    /*     resetVars();
    prepareCalendars(); */
  });

  let resetVars = () => {
    activeBirthday = true;
    activeHolidays = true;
    activeTasks = true;
    activeMeetings = true;
    activeGeneralEvents = true;
    activeAbsence = true;
    monthlyCalendarCount = 0;
    view = "m";
    currentYear = moment().year();
    dateOptions = [];
    dateOptionsSelected = null;
    currentDate = moment();
    events = [];
    eventsProject = [];
    eventsFiltered = [];
    projects = [];
    holidays = [];
    absences = [];
    statusCreationEvent = false;
    statusEventForm = false;
    opOutlookError = false;
    opOutlookMsgError = "";
    myProjectsList = [];
    pressedArrow = true;
    outlookError = false;
    outlookEvents = [];
    closeErrorMsg = false;
    currentEvent = null;
    op = "create";
    eventType = "";
    clickedCalendar = "";
    outlookCalendars = null;
    myOutlookCalendarsList = [];
    myOutlookCalendarEventList = [];
    fixedOutlookCalendarsList = [];
    fixedMyProjectsList = [];
    fixedEvents = [];
    loadingOutlookEvents = false;
    allMyProjects = [];
  };

  let prepareCalendars = async () => {
    activeAbsence = getEACalendar() === "1";
    activeBirthday = getBCalendar() === "1";
    activeHolidays = getHCalendar() === "1";

    getCalendars();
    await loadFixedOutlookCalendarsList();
    await completeAll();
  };

  let loadFixedOutlookCalendarsList = async () => {
    outlookGetCalendars(
      (calendars) => {
        outlookCalendars = calendars;
        fixedOutlookCalendarsList = outlookCalendars.reduce((acc, curr) => {
          acc.push({
            text: curr.name,
            active: false,
            id: curr.id,
            color: "",
            outlookCalendarType: curr.outlookCalendarType,
            additionalMail: curr.additionalMail,
          });
          return acc;
        }, []);
      },
      () => {
        outlookError = true;
      }
    );
  };

  let getMyProjects = async () => {
    let projects = await getAllProjects(true, myId);
    for (let i = 0; i < projects.length; i++) {
      allMyProjects.push(projects[i]);
    }

    processingProjects = allMyProjects.length;
    for (let i = 0; i < allMyProjects.length; i++) {
      let project = allMyProjects[i];
      project.projectTasks = [];
      getTasks(project, project.name);
    }
  };

  let prepareProjectTasks = (plan, project) => {
    let tasks = [];
    for (let i = 0; i < plan.phases.length; i++) {
      const phase = plan.phases[i];
      for (let j = 0; j < phase.people.length; j++) {
        const person = phase.people[j];
        person.tasks.toDo.forEach((t) => {
          tasks.push(t);
        });
        person.tasks.pending.forEach((t) => {
          tasks.push(t);
        });
        person.tasks.done.forEach((t) => {
          tasks.push(t);
        });
      }

      tasks = tasks;
    }

    // Eliminate duplicate tasks.
    let fullTasks = [];
    tasks.forEach((t) => {
      let task = fullTasks.findIndex((item) => {
        return t.id === item.id;
      });
      if (task === -1) {
        fullTasks.push(t);
      }
    });

    project.projectTasks = fullTasks;

    processingProjects--;
    if (processingProjects === 0) {
      allMyProjects = allMyProjects;
    }
  };

  let loadTasksIntoEvents = (project) => {
    const auxMenuProject = myProjectsList.find((p) => {
      return p.id === project.id;
    });

    /*     for (let index = 0; index < myProjectsList.length; index++) {
      const myProjectTasks = allMyProjects.find((p) => {
        return p.id === myProjectsList[index].id;
      }); */
    const pTasks = project.projectTasks;

    if (pTasks.length > 0) {
      eventsProject = pTasks.reduce((acc, curr) => {
        let rTask = events.findIndex((item) => {
          return curr.id === item.id;
        });
        if (rTask === -1) {
          acc.push({
            id: "task-" + curr.id,
            title: curr.name,
            allDay: true,
            start: moment(curr.start, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DDT00:00:00"
            ),
            end: moment(curr.end, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DDT23:59:59"
            ),
            backgroundColor: auxMenuProject.color,
            projectId: project.id,
            borderColor: auxMenuProject.color,
            textColor: "white",
            type: "task",
            description: curr.name,
            //Quedarte con el valor de task.active del proyecto en cuestión
            visible:
              fixedMyProjectsList.length !== 0
                ? /* ? fixedMyProjectsList[index].tasks.active  */
                  fixedMyProjectsList.find((p) => {
                    return p.id === project.id;
                  }).tasks.active &&
                  fixedMyProjectsList.find((p) => {
                    return p.id === project.id;
                  }).menu
                : false,
          });
          return acc;
        }
      }, []);
      events = events.concat(eventsProject);
    }
    /*     } */
    fixedEvents = events;
  };

  let getTasks = async (project, projectName) => {
    outlookGetTasks(
      projectName,
      (plan) => {
        prepareProjectTasks(plan, project);
        loadTasksIntoEvents(project);
        filterEvents();
        createCalendars();
      },
      () => {
        outlookLogin(
          () => {
            outlookGetTasks(
              projectName,
              (plan) => {
                prepareProjectTasks(plan, project);
                loadTasksIntoEvents();
                filterEvents();
                createCalendars();
              },
              () => {}
            );
          },
          () => {}
        );
      }
    );
  };

  let completeAll = async () => {
    loadingOutlookEvents = true;
    await getEvents();
    await initOutlook();
    /*await getMyProjects();
    filterEvents();
    createCalendars();*/
    loadingOutlookEvents = false;
  };

  let initOutlook = async () => {
    // Get Outlook Calendars
    outlookGetCalendars(
      async (calendars) => {
        outlookCalendars = calendars;
        myOutlookCalendarsList = outlookCalendars;

        // set outlook calendars color
        setOutlookCalendarsColor(myOutlookCalendarsList);
        myOutlookCalendarsList.forEach((c) => {
          c.text = c.name;
        });
        let fcalendars = getGCalendar();
        myOutlookCalendarsList.forEach((item) => {
          if (item.outlookCalendarType === "calendar") {
            const pos = fcalendars.findIndex((fc) => {
              return fc === item.id;
            });
            if (pos >= 0) {
              item.active = true;
            }
          }
        });
        myOutlookCalendarsList = myOutlookCalendarsList;

        let token = getOutlookToken();
        if (token !== "") {
          outlookError = false;
          if (view === "d") {
            await getOutlookCalendar(
              currentDate.startOf("day").format("YYYY-MM-DD"),
              currentDate.endOf("day").format("YYYY-MM-DD")
            );
          } else if (view === "m" || view === "a") {
            await getOutlookCalendar(
              currentDate.startOf("month").format("YYYY-MM-DD"),
              currentDate.endOf("month").format("YYYY-MM-DD")
            );
          } else if (view === "w") {
            await getOutlookCalendar(
              currentDate.startOf("week").format("YYYY-MM-DD"),
              currentDate.endOf("week").format("YYYY-MM-DD")
            );
          } else if (view === "y") {
            await getOutlookCalendar(
              currentDate.startOf("year").format("YYYY-MM-DD"),
              currentDate.endOf("year").format("YYYY-MM-DD")
            );
          }
        }
      },
      () => {
        outlookError = true;
      }
    );
  };

  afterUpdate(() => {
    createCalendars();
    if (view === "a") {
      window
        .$("div.containerAgendaFC div.fc-scroller")
        .addClass("customScrollbars");
    } else if (view === "d") {
      window
        .$("div.containerDailyFC div.fc-scroller")
        .addClass("customScrollbars");
    } else if (view === "w") {
      window
        .$("div.containerWeeklyFC div.fc-scroller")
        .addClass("customScrollbars");
    }
  });

  //Outlook*****
  let getOutlookCalendar = (from, to) => {
    loadingOutlookEvents = true;
    events = fixedEvents;
    myOutlookCalendarEventList = [];

    let auxMyOutlookCalendarsList = [];

    myOutlookCalendarsList.forEach((outlookCalendar) => {
      let searchOutlookCalendar = myProjectsList.findIndex((p) => {
        return (
          (p.text === outlookCalendar.text &&
            outlookCalendar.outlookCalendarType === "group") ||
          outlookCalendar.outlookCalendarType === "calendar"
        );
      });
      if (searchOutlookCalendar !== -1) {
        auxMyOutlookCalendarsList.push(outlookCalendar);
      }
    });

    let toRead = myOutlookCalendarsList.length;

    for (let i = 0; i < myOutlookCalendarsList.length; i++) {
      outlookGetCalendarEvents(
        myOutlookCalendarsList[i].id,
        myOutlookCalendarsList[i].outlookCalendarType,
        from,
        to,
        (outlookEvents) => {
          myOutlookCalendarEventList = outlookEvents.reduce((acc, curr) => {
            // Is the event calendar among the active projects read?
            const calendarText = myOutlookCalendarsList.find((c) => {
              return c.id === curr.idCalendar;
            }).text;
            const isInProjectsList = myProjectsList.find((p) => {
              return p.folderLabitCode === calendarText;
            });
            // If it is true, then the event is formatted.
            let fcalendars = getGCalendar();
            const pos = fcalendars.findIndex((fc) => {
              return fc === curr.idCalendar;
            });
            const vis = pos >= 0;
            acc.push({
              id: curr.id,
              title: curr.subject,
              start: curr.startDateTime,
              end: curr.endDateTime,
              textColor: "white",
              allDay: curr.isAllday,
              type: "outlook",
              description: curr.description,
              idCalendar: curr.idCalendar,
              location: curr.location,
              users: curr.users,
              outlookEventType: curr.type === "" ? "general" : curr.type,
              outlookCalendarType: curr.outlookCalendarType,
              outlookCalendarFolderLabitCode: calendarText,
              // BACKGROUND: If the outlook event has a project in the database, the color is updated with the project color.
              // Otherwise, it remains the same as the one set for outlook projects.
              backgroundColor: isInProjectsList
                ? isInProjectsList.color
                : myOutlookCalendarsList.find((c) => {
                    return c.id === curr.idCalendar;
                  }).color,
              visible:
                curr.outlookCalendarType === "group" && isInProjectsList
                  ? isInProjectsList.outlookEvents.active &&
                    isInProjectsList.menu
                  : vis,
            });
            return acc;
          }, []);

          // Insertar en eventos si no está insertado.
          myOutlookCalendarEventList.forEach((event, index) => {
            let searchEvent = events.findIndex((e) => {
              return e.id === event.id;
            });
            if (searchEvent === -1) {
              events.push(event);
            }
          });

          events = events;

          filterEvents();
          createCalendars();

          toRead--;
          if (toRead <= 0) {
            loadingOutlookEvents = false;
          }
        },
        () => {
          outlookError = true;
          loadingOutlookEvents = false;

          filterEvents();
          createCalendars();
        }
      );
      /* } */
    }
  };

  let setEventsColor = (myProjectsList) => {
    let iterator = 0;
    let colors = [
      "#00008B",
      "#8B0000",
      "#4B0082",
      "#006400",
      "#800000",
      "#483D8B",
      "#B22222",
      "#8B008B",
      "#008080",
      "#0000CD",
      "#8B4513",
      "#DC143C",
      "#C71585",
      "#9400D3",
      "#008000",
      "#0000FF",
      "#A52A2A",
      "#696969",
      "#4169E1",
      "#4682B4",
      "#228B22",
      "#663399",
    ];
    myProjectsList = myProjectsList.map((project) => {
      if (iterator === colors.length) {
        iterator = 0;
      }
      project.color = colors[iterator];
      project.backgroundColor = colors[iterator];
      for (let index = 0; index < events.length; index++) {
        if (
          events[index].type === "project" &&
          project.id === events[index].projectId
        ) {
          events[index].backgroundColor = colors[iterator];
        }
      }
      iterator++;
      return project;
    });
  };

  let setOutlookCalendarsColor = (outlookCalendars) => {
    let colors = [
      "#00008B",
      "#8B0000",
      "#4B0082",
      "#006400",
      "#800000",
      "#483D8B",
      "#B22222",
      "#8B008B",
      "#008080",
      "#0000CD",
      "#8B4513",
      "#DC143C",
      "#C71585",
      "#9400D3",
      "#008000",
      "#0000FF",
      "#A52A2A",
      "#696969",
    ];

    let iterator = colors.length - 1;
    outlookCalendars = outlookCalendars.map((oc) => {
      if (iterator === 0) {
        iterator = colors.length - 1;
      }
      oc.color = colors[iterator];
      oc.backgroundColor = colors[iterator];
      iterator--;
    });
  };

  let getEvents = async () => {
    // Birthdays --------------------------------------------------
    let peopleBirthdays = await post(
      `SELECT p.Name AS contactName, BirthDate, contact_id
        FROM people AS p
        LEFT JOIN companies AS c ON p.company_id = c.company_id
        WHERE c.esLabit = 1 AND p.active = 1`
    );

    events = peopleBirthdays.reduce((acc, curr) => {
      if (curr.BirthDate && curr.BirthDate !== "") {
        acc.push({
          id: "birthdate-" + curr.contact_id,
          title: curr.contactName,
          start:
            currentDate.format("YYYY") +
            "-" +
            moment(curr.BirthDate, "YYYY-MM-DD").format("MM-DD"),
          end:
            currentDate.format("YYYY") +
            "-" +
            moment(curr.BirthDate, "YYYY-MM-DD").format("MM-DD"),
          backgroundColor: BIRTHDAYBACKGROUNDCOLOR,
          textColor: BIRTHDAYTEXTCOLOR,
          allDay: false,
          borderColor: BIRTHDAYBACKGROUNDCOLOR,
          type: "birthday",
          description: "Birthday: " + curr.contactName,
          visible: activeBirthday,
        });
      }
      return acc;
    }, []);

    /*
    projects = await post(
      `SELECT DISTINCT p.project_id, p.Name AS projectName, p.FolderLabitCode
      FROM projects AS p
      LEFT JOIN projectPeople AS ppe ON p.project_id = ppe.project
      WHERE p.active=1 and ppe.people = ` + myId
    );
    */
    projects = await post(
      `SELECT DISTINCT proj.project_id, proj.Name AS projectName, proj.FolderLabitCode
      FROM projects AS proj
      JOIN phase AS ph ON proj.project_id = ph.project
      JOIN projectTeam AS pt ON pt.phase = ph.id
      JOIN people AS peop ON peop.contact_id = pt.people
      WHERE proj.active = 1
      AND peop.contact_id = ${myId};`
    );

    myProjectsList = projects.reduce((acc, curr) => {
      acc.push({
        text: curr.projectName,
        active: false,
        menu: false,
        id: curr.project_id,
        folderLabitCode: curr.FolderLabitCode,
        phases: {
          valor: false,
          active: false,
        },
        milestones: {
          valor: false,
          active: true,
        },
        outlookEvents: {
          valor: false,
          active: true,
        },
        tasks: { valor: false, active: true },
      });
      return acc;
    }, []);

    if (fixedMyProjectsList) {
      myProjectsList.forEach((project) => {
        const p = fixedMyProjectsList.find((item) => {
          return item.id === project.id;
        });
        if (p) {
          project.menu = p.menu;
          project.active = p.active;
          project.phases.active = p.phases.active;
          project.milestones.active = p.milestones.active;
          project.outlookEvents.active = p.outlookEvents.active;
          project.tasks.active = p.tasks.active;
        }
      });
      myProjectsList = myProjectsList;
    }
    saveCalendars();

    /*fixedMyProjectsList.forEach((item) => {
      item.active = true;
    });
    fixedMyProjectsList = fixedMyProjectsList;*/

    setEventsColor(myProjectsList);

    for (let index = 0; index < myProjectsList.length; index++) {
      // Phases
      let myProjectsPhases = await post(
        `SELECT ph.id, p.project_id, ph.realDescription, ph.name, ph.start, ph.end, ph.realStart, ph.realEnd
      FROM projects AS p
      LEFT JOIN phase AS ph ON p.project_id = ph.project
      WHERE ph.project = ${myProjectsList[index].id} and phaseParent is null`
      );
      // Adapt phase
      eventsProject = myProjectsPhases.reduce((acc, curr) => {
        const project = myProjectsList.find((p) => {
          return p.id === curr.project_id;
        });
        if (project) {
          project.phases.valor = true;
        }
        acc.push({
          id: "PRO" + curr.project_id + "PHA" + curr.id,
          title: curr.name === "" ? "[Unnamed]" : curr.name,
          allDay: true,
          start: !curr.realStart ? curr.start : curr.realStart,
          end: !curr.realEnd ? curr.end : curr.realEnd,
          textColor: PROJECTTEXTCOLOR,
          backgroundColor: myProjectsList[index].backgroundColor + "aa",
          type: "phase",
          projectId: curr.project_id,
          description: !curr.realDescription
            ? "(No description)"
            : curr.realDescription,
          visible:
            fixedMyProjectsList.length !== 0
              ? fixedMyProjectsList[index].phases.active &&
                fixedMyProjectsList[index].menu
              : false,
        });
        return acc;
      }, []);
      events = events.concat(eventsProject);

      // Milestones
      let myProjectsMilestones = await post(
        `SELECT m.id, p.project_id, m.name, m.start, m.end, m.isAllDay, m.project
      FROM projects AS p
      LEFT JOIN milestone AS m ON p.project_id = m.project
      WHERE m.project = ` + myProjectsList[index].id
      );
      // Adapt milestones
      eventsProject = myProjectsMilestones.reduce((acc, curr) => {
        const project = myProjectsList.find((p) => {
          return p.id === curr.project_id;
        });
        if (project) {
          project.milestones.valor = true;
        }
        acc.push({
          id: "PRO" + curr.project_id + "MIL" + curr.id,
          title: curr.name === "" ? "[Unnamed]" : curr.name,
          allDay: curr.isAllDay,
          start: moment(curr.start, "YYYY-MM-DD hh:mm:ss").format(
            "YYYY-MM-DD hh:mm:ss"
          ),
          end: moment(curr.end, "YYYY-MM-DD hh:mm:ss").format(
            "YYYY-MM-DD hh:mm:ss"
          ),
          textColor: PROJECTTEXTCOLOR,
          backgroundColor: myProjectsList[index].backgroundColor,
          type: "milestone",
          projectId: curr.project_id,
          description: !curr.realDescription
            ? "(No description)"
            : curr.realDescription,
          visible:
            fixedMyProjectsList.length !== 0
              ? fixedMyProjectsList[index].milestones.active &&
                fixedMyProjectsList[index]
              : false,
        });
        return acc;
      }, []);
      events = events.concat(eventsProject);
    }

    // Holidays --------------------------------------------------
    holidays = await post(
      `SELECT c.city, g.id, g.name, g.startDate, g.location 
      FROM people AS p
      INNER JOIN companies AS c ON p.company_id = c.company_id
      INNER JOIN general_holidays AS g ON c.City = g.location
      WHERE p.contact_id = ` + myId
    );

    holidays = holidays.reduce((acc, curr) => {
      acc.push({
        id: "holiday-" + curr.id,
        title: curr.name,
        allDay: false,
        start: curr.startDate,
        end: curr.startDate,
        type: "holiday",
        location: curr.location,
        backgroundColor: HOLIDAYSBACKGROUNDCOLOR,
        borderColor: HOLIDAYSBACKGROUNDCOLOR,
        textColor: HOLIDAYSBACKGROUNDCOLOR,
        description: curr.name,
        visible: activeHolidays,
      });
      return acc;
    }, []);

    events = events.concat(holidays);

    // Absences --------------------------------------------------
    let absences = await post(
      `SELECT *
      FROM events
      WHERE type = "absence" and createdBy = ` + myId
    );

    absences = absences.reduce((acc, curr) => {
      acc.push({
        id: curr.id,
        title: curr.description,
        allDay: curr.allDay,
        start: curr.startDate,
        end: curr.endDate,
        type: "absence",
        location: null,
        backgroundColor: ABSENCEBACKGROUNDCOLOR,
        borderColor: ABSENCEBACKGROUNDCOLOR,
        textColor: "black",
        description: curr.description,
        visible: activeAbsence,
      });
      return acc;
    }, []);

    events = events.concat(absences);
    monthlyCalendarCount += 1;

    fixedEvents = copyObject(events);
  };

  let filterEvents = () => {
    eventsFiltered = events.reduce((acc, curr) => {
      if (curr.visible) {
        acc.push({
          id: curr.id,
          title: curr.title,
          start: curr.start,
          end: curr.end,
          allDay: curr.allDay,
          backgroundColor: curr.backgroundColor,
          borderColor: curr.backgroundColor,
          textColor: curr.textColor,
          projectId: curr.projectId,
          description: curr.description,
          type: curr.type,
          outlookEventType:
            curr.type === "outlook" ? curr.outlookEventType : "general",
        });
      }
      return acc;
    }, []);
  };

  let createCalendars = () => {
    let currYear = "";
    let yearBefore = "";
    let yearAfter = "";
    let currWeek = "";
    let weekBefore = "";
    let weekAfter = "";
    let currMonth = "";
    switch (view) {
      case "d":
        createDailyCalendar();
        currWeek = moment();
        weekBefore = moment().subtract(1, "weeks");
        weekAfter = moment().add(1, "weeks");
        dateOptions = [weekBefore, currWeek, weekAfter].reduce((acc, curr) => {
          acc.push(
            {
              label: moment(curr).isoWeekday(1).format("dddd, D MMMM YYYY"),
              value: moment(curr).isoWeekday(1).format("dddd, D MMMM YYYY"),
            },
            {
              label: moment(curr).isoWeekday(2).format("dddd, D MMMM YYYY"),
              value: moment(curr).isoWeekday(2).format("dddd, D MMMM YYYY"),
            },
            {
              label: moment(curr).isoWeekday(3).format("dddd, D MMMM YYYY"),
              value: moment(curr).isoWeekday(3).format("dddd, D MMMM YYYY"),
            },
            {
              label: moment(curr).isoWeekday(4).format("dddd, D MMMM YYYY"),
              value: moment(curr).isoWeekday(4).format("dddd, D MMMM YYYY"),
            },
            {
              label: moment(curr).isoWeekday(5).format("dddd, D MMMM YYYY"),
              value: moment(curr).isoWeekday(5).format("dddd, D MMMM YYYY"),
            },
            {
              label: moment(curr).isoWeekday(6).format("dddd, D MMMM YYYY"),
              value: moment(curr).isoWeekday(6).format("dddd, D MMMM YYYY"),
            },
            {
              label: moment(curr).isoWeekday(7).format("dddd, D MMMM YYYY"),
              value: moment(curr).isoWeekday(7).format("dddd, D MMMM YYYY"),
            }
          );
          return acc;
        }, []);
        /* dateOptionsSelected = moment().format("dddd, D MMMM YYYY"); */
        dateOptionsSelected = {
          value: currentDate.format("dddd, D MMMM YYYY"),
          label: currentDate.format("dddd, D MMMM YYYY"),
        };
        break;
      case "w":
        createWeeklyCalendar();
        currMonth = moment();
        dateOptions = [currMonth].reduce((acc, curr) => {
          acc.push(
            {
              label:
                "Week " + moment().subtract(5, "weeks").format("W, MMMM YYYY"),
              value:
                "Week " + moment().subtract(5, "weeks").format("W, MMMM YYYY"),
            },
            {
              label:
                "Week " + moment().subtract(4, "weeks").format("W, MMMM YYYY"),
              value:
                "Week " + moment().subtract(4, "weeks").format("W, MMMM YYYY"),
            },
            {
              label:
                "Week " + moment().subtract(3, "weeks").format("W, MMMM YYYY"),
              value:
                "Week " + moment().subtract(3, "weeks").format("W, MMMM YYYY"),
            },
            {
              label:
                "Week " + moment().subtract(2, "weeks").format("W, MMMM YYYY"),
              value:
                "Week " + moment().subtract(2, "weeks").format("W, MMMM YYYY"),
            },
            {
              label:
                "Week " + moment().subtract(1, "weeks").format("W, MMMM YYYY"),
              value:
                "Week " + moment().subtract(1, "weeks").format("W, MMMM YYYY"),
            },
            {
              label: "Week " + moment().format("W, MMMM YYYY"),
              value: "Week " + moment().format("W, MMMM YYYY"),
            },
            {
              label: "Week " + moment().add(1, "weeks").format("W, MMMM YYYY"),
              value: "Week " + moment().add(1, "weeks").format("W, MMMM YYYY"),
            },
            {
              label: "Week " + moment().add(2, "weeks").format("W, MMMM YYYY"),
              value: "Week " + moment().add(2, "weeks").format("W, MMMM YYYY"),
            },
            {
              label: "Week " + moment().add(3, "weeks").format("W, MMMM YYYY"),
              value: "Week " + moment().add(3, "weeks").format("W, MMMM YYYY"),
            },
            {
              label: "Week " + moment().add(4, "weeks").format("W, MMMM YYYY"),
              value: "Week " + moment().add(4, "weeks").format("W, MMMM YYYY"),
            },
            {
              label: "Week " + moment().add(5, "weeks").format("W, MMMM YYYY"),
              value: "Week " + moment().add(5, "weeks").format("W, MMMM YYYY"),
            }
          );
          return acc;
        }, []);
        dateOptionsSelected =
          /* "Week " + moment().format("W, MMMM YYYY"); */
          dateOptionsSelected = {
            label: "Week " + currentDate.format("W, MMMM YYYY"),
            value: "Week " + currentDate.format("W, MMMM YYYY"),
          };
        break;

      case "m":
        createMonthlyCalendar();
        currYear = moment().format("YYYY");
        yearBefore = (parseInt(currYear) - 1).toString();
        yearAfter = (parseInt(currYear) + 1).toString();
        dateOptions = [yearBefore, currYear, yearAfter].reduce((acc, curr) => {
          acc.push(
            {
              label: "January " + curr,
              value: "January " + curr,
            },
            {
              label: "February " + curr,
              value: "February " + curr,
            },
            {
              label: "March " + curr,
              value: "March " + curr,
            },
            {
              label: "April " + curr,
              value: "April " + curr,
            },
            {
              label: "May " + curr,
              value: "May " + curr,
            },
            {
              label: "June " + curr,
              value: "June " + curr,
            },
            {
              label: "July " + curr,
              value: "July " + curr,
            },
            {
              label: "August " + curr,
              value: "August " + curr,
            },
            {
              label: "September " + curr,
              value: "September " + curr,
            },
            {
              label: "October " + curr,
              value: "October " + curr,
            },
            {
              label: "November " + curr,
              value: "November " + curr,
            },
            {
              label: "December " + curr,
              value: "December " + curr,
            }
          );
          return acc;
        }, []);
        dateOptionsSelected = {
          value: currentDate.format("MMMM") + " " + currentDate.format("YYYY"),
          label: currentDate.format("MMMM") + " " + currentDate.format("YYYY"),
        };
        break;

      case "y":
        createYearlyCalendar();
        currYear = moment().format("YYYY");
        yearBefore = (parseInt(currYear) - 1).toString();
        yearAfter = (parseInt(currYear) + 1).toString();
        dateOptions = [
          {
            label: yearBefore,
            value: yearBefore,
          },
          {
            label: currYear,
            value: currYear,
          },
          {
            label: yearAfter,
            value: yearAfter,
          },
        ];
        dateOptionsSelected = {
          value: currentDate.format("YYYY"),
          label: currentDate.format("YYYY"),
        };
        break;

      case "a":
        createAgendaCalendar();
        currYear = moment().format("YYYY");
        yearBefore = (parseInt(currYear) - 1).toString();
        yearAfter = (parseInt(currYear) + 1).toString();
        dateOptions = [yearBefore, currYear, yearAfter].reduce((acc, curr) => {
          acc.push(
            {
              label: "January " + curr,
              value: "January " + curr,
            },
            {
              label: "February " + curr,
              value: "February " + curr,
            },
            {
              label: "March " + curr,
              value: "March " + curr,
            },
            {
              label: "April " + curr,
              value: "April " + curr,
            },
            {
              label: "May " + curr,
              value: "May " + curr,
            },
            {
              label: "June " + curr,
              value: "June " + curr,
            },
            {
              label: "July " + curr,
              value: "July " + curr,
            },
            {
              label: "August " + curr,
              value: "August " + curr,
            },
            {
              label: "September " + curr,
              value: "September " + curr,
            },
            {
              label: "October " + curr,
              value: "October " + curr,
            },
            {
              label: "November " + curr,
              value: "November " + curr,
            },
            {
              label: "December " + curr,
              value: "December " + curr,
            }
          );
          return acc;
        }, []);
        /* dateOptionsSelected = moment().format("MMMM") + " " + currYear; */
        dateOptionsSelected = {
          value: currentDate.format("MMMM") + " " + currentDate.format("YYYY"),
          label: currentDate.format("MMMM") + " " + currentDate.format("YYYY"),
        };
        break;
    }
  };

  let clickPrev = async () => {
    if (!loadingOutlookEvents) {
      pressedArrow = true;
      switch (view) {
        case "d":
          currentDate.subtract(1, "days");
          dateOptionsSelected = {
            value: currentDate.format("dddd, D MMMM YYYY"),
            label: currentDate.format("dddd, D MMMM YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            await getOutlookCalendar(
              currentDate.startOf("day").format("YYYY-MM-DD"),
              currentDate.endOf("day").format("YYYY-MM-DD")
            );
          }
          break;
        case "w":
          currentDate.subtract(7, "days");
          dateOptionsSelected = {
            value: "Week " + currentDate.format("w, MMMM YYYY"),
            label: "Week " + currentDate.format("w, MMMM YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            await getOutlookCalendar(
              currentDate.startOf("week").format("YYYY-MM-DD"),
              currentDate.endOf("week").format("YYYY-MM-DD")
            );
          }
          break;
        case "m":
          currentDate.subtract(1, "months");
          dateOptionsSelected = {
            value: currentDate.format("MMMM YYYY"),
            label: currentDate.format("MMMM YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            getOutlookCalendar(
              currentDate.startOf("month").format("YYYY-MM-DD"),
              currentDate.endOf("month").format("YYYY-MM-DD")
            );
          }
          break;
        case "y":
          currentDate.subtract(1, "years");
          dateOptionsSelected = {
            value: currentDate.format("YYYY"),
            label: currentDate.format("YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            await getOutlookCalendar(
              currentDate.startOf("year").format("YYYY-MM-DD"),
              currentDate.endOf("year").format("YYYY-MM-DD")
            );
          }
          break;
        case "a":
          currentDate.subtract(1, "months");
          dateOptionsSelected = {
            value: currentDate.format("MMMM YYYY"),
            label: currentDate.format("MMMM YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            await getOutlookCalendar(
              currentDate.startOf("month").format("YYYY-MM-DD"),
              currentDate.endOf("month").format("YYYY-MM-DD")
            );
          }
          break;
      }
      reloadBirthdaysAndHolidays();
      pressedArrow = false;
    }
  };

  let clickNext = async () => {
    if (!loadingOutlookEvents) {
      pressedArrow = true;
      switch (view) {
        case "d":
          currentDate.add(1, "days");
          dateOptionsSelected = {
            value: currentDate.format("dddd, D MMMM YYYY"),
            label: currentDate.format("dddd, D MMMM YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            await getOutlookCalendar(
              currentDate.startOf("day").format("YYYY-MM-DD"),
              currentDate.endOf("day").format("YYYY-MM-DD")
            );
          }
          break;
        case "w":
          currentDate.add(7, "days");
          dateOptionsSelected = {
            value: "Week " + currentDate.format("w, MMMM YYYY"),
            label: "Week " + currentDate.format("w, MMMM YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            await getOutlookCalendar(
              currentDate.startOf("week").format("YYYY-MM-DD"),
              currentDate.endOf("week").format("YYYY-MM-DD")
            );
          }
          break;
        case "m":
          currentDate.add(1, "months");
          dateOptionsSelected = {
            value: currentDate.format("MMMM YYYY"),
            label: currentDate.format("MMMM YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            getOutlookCalendar(
              currentDate.startOf("month").format("YYYY-MM-DD"),
              currentDate.endOf("month").format("YYYY-MM-DD")
            );
          }
          break;
        case "y":
          currentDate.add(1, "years");
          dateOptionsSelected = {
            value: currentDate.format("YYYY"),
            label: currentDate.format("YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            await getOutlookCalendar(
              currentDate.startOf("year").format("YYYY-MM-DD"),
              currentDate.endOf("year").format("YYYY-MM-DD")
            );
          }
          break;
        case "a":
          currentDate.add(1, "months");
          dateOptionsSelected = {
            value: currentDate.format("MMMM YYYY"),
            label: currentDate.format("MMMM YYYY"),
          };
          currentDate = currentDate;
          if (!outlookError) {
            await getOutlookCalendar(
              currentDate.startOf("month").format("YYYY-MM-DD"),
              currentDate.endOf("month").format("YYYY-MM-DD")
            );
          }
          break;
      }
      reloadBirthdaysAndHolidays();
      pressedArrow = false;
    }
  };

  //*****Adding a sample daily calendar
  let dCalendar = null;
  //*****Adding a sample weekly calendar
  let wCalendar = null;
  //*****Adding a sample monthly calendar
  let mCalendar = null;
  //*****Adding a sample yearly calendar
  let januaryCalendar = null;
  let februaryCalendar = null;
  let marchCalendar = null;
  let aprilCalendar = null;
  let mayCalendar = null;
  let juneCalendar = null;
  let julyCalendar = null;
  let augustCalendar = null;
  let septemberCalendar = null;
  let octoberCalendar = null;
  let novemberCalendar = null;
  let decemberCalendar = null;
  //*****Adding a sample agenda day calendar
  let aCalendar = null;

  let toggleVisibility = (type, index = -1) => {
    if (!loadingOutlookEvents) {
      if (type === "project") {
        myProjectsList[index].active = !myProjectsList[index].active;

        if (!myProjectsList[index].active) {
          // OJO CERRADO
          myProjectsList[index].phases.active = false;
          myProjectsList[index].milestones.active = false;
          myProjectsList[index].outlookEvents.active = false;
          myProjectsList[index].tasks.active = false;
          events = events.map((event) => {
            if (
              event.type === "phase" &&
              event.projectId === myProjectsList[index].id
            ) {
              event.visible = myProjectsList[index].active;
            }
            if (
              event.type === "milestone" &&
              event.projectId === myProjectsList[index].id
            ) {
              event.visible = false;
            }
            if (
              event.type === "outlook" &&
              event.outlookCalendarFolderLabitCode ===
                myProjectsList[index].folderLabitCode
            ) {
              event.visible = false;
            }
            if (
              event.type === "task" &&
              event.projectId === myProjectsList[index].id
            ) {
              event.visible = false;
            }
            return event;
          });
          fixedMyProjectsList = myProjectsList;
          filterEvents();
        } else if (myProjectsList[index].active) {
          // OJO ABIERTO
          myProjectsList[index].phases.active = false;
          myProjectsList[index].milestones.active = true;
          myProjectsList[index].outlookEvents.active = true;
          myProjectsList[index].tasks.active = true;
          events = events.map((event) => {
            if (
              event.type === "milestone" &&
              event.projectId === myProjectsList[index].id
            ) {
              event.visible = myProjectsList[index].milestones.active;
            }
            if (
              event.type === "outlook" &&
              event.outlookCalendarFolderLabitCode ===
                myProjectsList[index].folderLabitCode
            ) {
              event.visible = myProjectsList[index].outlookEvents.active;
            }
            if (
              event.type === "task" &&
              event.projectId === myProjectsList[index].id
            ) {
              event.visible = myProjectsList[index].tasks.active;
            }
            return event;
          });
          fixedMyProjectsList = myProjectsList;
          filterEvents();
        }
      }
      saveCalendars();
    }
  };

  let toggleMenu = (type, index = -1) => {
    if (!loadingOutlookEvents) {
      if (type === "project") {
        myProjectsList[index].menu = !myProjectsList[index].menu;
      }
      saveCalendars();
    }
  };

  let toggleActive = (type, index = -1) => {
    if (!loadingOutlookEvents) {
      switch (type) {
        case "phase":
          myProjectsList[index].phases.active =
            !myProjectsList[index].phases.active;
          if (
            myProjectsList[index].phases.active &&
            !myProjectsList[index].active
          ) {
            myProjectsList[index].active = true;
          }
          events = events.map((event) => {
            if (
              event.type === "phase" &&
              event.projectId === myProjectsList[index].id
            ) {
              event.visible = myProjectsList[index].phases.active;
            }
            return event;
          });
          filterEvents();
          break;
        case "milestone":
          myProjectsList[index].milestones.active =
            !myProjectsList[index].milestones.active;
          if (
            myProjectsList[index].milestones.active &&
            !myProjectsList[index].active
          ) {
            myProjectsList[index].active = true;
          }
          events = events.map((event) => {
            if (
              event.type === "milestone" &&
              event.projectId === myProjectsList[index].id
            ) {
              event.visible = myProjectsList[index].milestones.active;
            }
            return event;
          });
          filterEvents();
          break;
        case "outlookEvent":
          myProjectsList[index].outlookEvents.active =
            !myProjectsList[index].outlookEvents.active;
          if (
            myProjectsList[index].outlookEvents.active &&
            !myProjectsList[index].active
          ) {
            myProjectsList[index].active = true;
          }
          events = events.map((event) => {
            if (
              event.type === "outlook" &&
              event.outlookCalendarFolderLabitCode ===
                myProjectsList[index].folderLabitCode
            ) {
              event.visible = myProjectsList[index].outlookEvents.active;
            }
            return event;
          });
          filterEvents();
          break;
        case "task":
          myProjectsList[index].tasks.active =
            !myProjectsList[index].tasks.active;
          if (
            myProjectsList[index].tasks.active &&
            !myProjectsList[index].active
          ) {
            myProjectsList[index].active = true;
          }
          events = events.map((event) => {
            if (
              event.type === "task" &&
              event.projectId === myProjectsList[index].id
            ) {
              event.visible = myProjectsList[index].tasks.active;
            }
            return event;
          });
          filterEvents();
          break;
        case "birthday":
          activeBirthday = !activeBirthday;
          events = events.map((event) => {
            if (event.type === "birthday") {
              event.visible = activeBirthday;
            }
            return event;
          });
          filterEvents();
          saveBCalendar(activeBirthday ? "1" : "0");
          break;
        case "outlook":
          myOutlookCalendarsList[index].active =
            !myOutlookCalendarsList[index].active;
          events = events.map((event) => {
            if (
              event.type === "outlook" &&
              event.idCalendar === myOutlookCalendarsList[index].id
            ) {
              event.visible = myOutlookCalendarsList[index].active;
            }
            return event;
          });
          /* events = events; */
          fixedOutlookCalendarsList = myOutlookCalendarsList;
          filterEvents();
          break;
        case "holiday":
          activeHolidays = !activeHolidays;
          events = events.map((event) => {
            if (event.type === "holiday") {
              event.visible = activeHolidays;
            }
            return event;
          });
          filterEvents();
          saveHCalendar(activeHolidays ? "1" : "0");
          break;
        case "task":
          activeTasks = !activeTasks;
          events = events.map((event) => {
            if (event.type === "task") {
              event.visible = activeTasks;
            }
            return event;
          });
          filterEvents();
          break;
        case "meeting":
          activeMeetings = !activeMeetings;
          events = events.map((event) => {
            if (event.type === "meeting") {
              event.visible = activeMeetings;
            }
            return event;
          });
          filterEvents();
          break;
        case "general":
          activeGeneralEvents = !activeGeneralEvents;
          events = events.map((event) => {
            if (event.type === "general") {
              event.visible = activeGeneralEvents;
            }
            return event;
          });
          filterEvents();
          break;
        case "absence":
          activeAbsence = !activeAbsence;
          events = events.map((event) => {
            if (event.type === "absence") {
              event.visible = activeAbsence;
            }
            return event;
          });
          filterEvents();
          saveEACalendar(activeAbsence ? "1" : "0");
          break;
      }
      saveCalendars();
      saveGCalendars();
    }
  };

  let createDailyCalendar = () => {
    var dailyCalendar = document.getElementById("dailyFC");
    dCalendar = new FullCalendar.Calendar(dailyCalendar, {
      initialView: "timeGridDay",
      initialDate: currentDate.format("YYYY-MM-DD"),
      height: "100%",
      headerToolbar: false,
      scrollTime: "08:00:00",
      slotMinTime: "07:00:00",
      slotMaxTime: "23:00:00",
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
      },
      dayMaxEvents: true, // allow "more" link when too many events
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    dCalendar.render();
  };

  let createWeeklyCalendar = () => {
    var weeklyCalendar = document.getElementById("weeklyFC");
    wCalendar = new FullCalendar.Calendar(weeklyCalendar, {
      initialView: "timeGridWeek",
      initialDate: currentDate.format("YYYY-MM-DD"),
      height: "100%",
      scrollTime: "08:00:00",
      slotMinTime: "07:00:00",
      slotMaxTime: "23:00:00",
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
      },
      dayHeaderFormat: { weekday: "long" },
      dayMaxEvents: true, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      headerToolbar: false,
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    wCalendar.render();
  };

  let clickEvent = async (event) => {
    /*     statusCreationEvent = false;
    statusEventForm = false; */
    window.$("div.tooltip").remove();
    op = "edit";

    const id = event.id;
    const type = event.extendedProps.type;

    switch (type) {
      case "outlook":
        /* let outEvent = myOutlookCalendarEventList.find(e => e.id === event.id); */
        let outEvent = events.find((e) => e.id === event.id);
        eventType =
          event.extendedProps.type === "outlook"
            ? event.extendedProps.outlookEventType
            : event.extendedProps.type;

        // filter external persons and skylab/labit persons.
        let externalPeopleFiltered = outEvent.users.filter(
          (p) => p.name === "EXTERNAL-PERSON"
        );
        externalPeopleFiltered = externalPeopleFiltered.map((p) => {
          return { value: p.email, label: p.name };
        });

        let noExternalPeopleFiltered = outEvent.users.filter(
          (p) => p.name !== "EXTERNAL-PERSON"
        );
        noExternalPeopleFiltered = noExternalPeopleFiltered.map((p) => {
          return { value: p.email, label: p.name };
        });

        currentEvent = {
          id,
          idCalendar: outEvent.idCalendar,
          title: outEvent.title,
          description: outEvent.description,
          type: outEvent.type,
          startDate: moment(outEvent.start.split("T")[0], "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          ),
          endDate: moment(outEvent.end.split("T")[0], "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          ),
          allDay: outEvent.allDay,
          startTime: outEvent.start.split("T")[1],
          startTimeH: outEvent.start.split("T")[1].split(":")[0],
          startTimeM: outEvent.start.split("T")[1].split(":")[1],
          endTime: outEvent.end.split("T")[1],
          endTimeH: outEvent.end.split("T")[1].split(":")[0],
          endTimeM: outEvent.end.split("T")[1].split(":")[1],
          location: outEvent.location,
          labitPeople: noExternalPeopleFiltered,
          people: noExternalPeopleFiltered,
          outlookEventType:
            outEvent.type === "" ? "general" : outEvent.outlookEventType,
          externalPeopleList: externalPeopleFiltered,
          externalPeople: "",
          outlookCalendarType: outEvent.outlookCalendarType,
        };

        op = "edit";
        statusCreationEvent = true;
        statusEventForm = true;
        break;

      case "absence":
        let absenceResponse = await post(
          `select description, startDate, endDate, allDay, startTime, endTime
            from events
            where id=${id}`
        );
        currentEvent = {
          id,
          description: absenceResponse[0].description,
          type: "absence",
          startDate: moment(absenceResponse[0].startDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          ),
          endDate: moment(absenceResponse[0].endDate, "YYYY-MM-DD")
            .subtract(1, "days")
            .format("DD/MM/YYYY"),
          allDay: absenceResponse[0].allDay,
          startTime: absenceResponse[0].startTime,
          startTimeH: absenceResponse[0].startTime.split(":")[0],
          startTimeM: absenceResponse[0].startTime.split(":")[1],
          endTime: absenceResponse[0].endTime,
          endTimeH: absenceResponse[0].endTime.split(":")[0],
          endTimeM: absenceResponse[0].endTime.split(":")[1],
          location: "",
          createdBy: "",
          creationDate: "",
          labitPeople: [],
          backupLabitPeople: [],
          people: [],
          backupPeople: [],
          outlookCalendar: "",
        };

        absenceResponse = await post(
          `select contact, Name
            from eventsShared as es
            left join people as p on p.contact_id=es.contact
            where event=${id}`
        );
        currentEvent.people = absenceResponse.map((contact) => {
          return {
            value: contact.contact,
            label: contact.Name,
          };
        });
        currentEvent.backupPeople = absenceResponse.map((contact) => {
          return {
            value: contact.contact,
            label: contact.Name,
          };
        });
        op = "edit";
        statusCreationEvent = true;
        statusEventForm = true;
        eventType = "absence";
        break;
      default:
        statusCreationEvent = false;
        statusEventForm = false;
    }
  };

  let createMonthlyCalendar = () => {
    var monthlyCalendar = document.getElementById("monthlyFC");
    mCalendar = new FullCalendar.Calendar(monthlyCalendar, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-MM-DD"),
      dayMaxEvents: 5, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "long" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    mCalendar.render();
  };

  let createJanuaryCalendar = () => {
    var janCal = document.getElementById("januaryCalendar");
    januaryCalendar = new FullCalendar.Calendar(janCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-01-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      displayEventTime: false,
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    januaryCalendar.render();
  };

  let createFebruaryCalendar = () => {
    var febCal = document.getElementById("februaryCalendar");
    februaryCalendar = new FullCalendar.Calendar(febCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-02-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    februaryCalendar.render();
  };

  let createMarchCalendar = () => {
    var marCal = document.getElementById("marchCalendar");
    marchCalendar = new FullCalendar.Calendar(marCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-03-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    marchCalendar.render();
  };

  let createAprilCalendar = () => {
    var aprCal = document.getElementById("aprilCalendar");
    aprilCalendar = new FullCalendar.Calendar(aprCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-04-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    aprilCalendar.render();
  };

  let createMayCalendar = () => {
    var mayCal = document.getElementById("mayCalendar");
    mayCalendar = new FullCalendar.Calendar(mayCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-05-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    mayCalendar.render();
  };

  let createJuneCalendar = () => {
    var junCal = document.getElementById("juneCalendar");
    juneCalendar = new FullCalendar.Calendar(junCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-06-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    juneCalendar.render();
  };

  let createJulyCalendar = () => {
    var julCal = document.getElementById("julyCalendar");
    julyCalendar = new FullCalendar.Calendar(julCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-07-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    julyCalendar.render();
  };

  let createAugustCalendar = () => {
    var augCal = document.getElementById("augustCalendar");
    augustCalendar = new FullCalendar.Calendar(augCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-08-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    augustCalendar.render();
  };

  let createSeptemberCalendar = () => {
    var sepCal = document.getElementById("septemberCalendar");
    septemberCalendar = new FullCalendar.Calendar(sepCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-09-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    septemberCalendar.render();
  };

  let createOctoberCalendar = () => {
    var octCal = document.getElementById("octoberCalendar");
    octoberCalendar = new FullCalendar.Calendar(octCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-10-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    octoberCalendar.render();
  };

  let createNovemberCalendar = () => {
    var novCal = document.getElementById("novemberCalendar");
    novemberCalendar = new FullCalendar.Calendar(novCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-11-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    novemberCalendar.render();
  };

  let createDecemberCalendar = () => {
    var decCal = document.getElementById("decemberCalendar");
    decemberCalendar = new FullCalendar.Calendar(decCal, {
      initialView: "dayGridMonth",
      fixedWeekCount: false,
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-12-01"),
      dayMaxEvents: 1, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "narrow" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      eventClick: (info) => clickEvent(info.event),
      events: eventsFiltered,
    });
    decemberCalendar.render();
  };

  let createYearlyCalendar = () => {
    createJanuaryCalendar();
    createFebruaryCalendar();
    createMarchCalendar();
    createAprilCalendar();
    createMayCalendar();
    createJuneCalendar();
    createJulyCalendar();
    createAugustCalendar();
    createSeptemberCalendar();
    createOctoberCalendar();
    createNovemberCalendar();
    createDecemberCalendar();
  };

  let createAgendaCalendar = () => {
    var agendaCalendar = document.getElementById("agendaFC");
    aCalendar = new FullCalendar.Calendar(agendaCalendar, {
      initialView: "listMonth",
      height: "100%",
      initialDate: currentDate.format("YYYY-MM-DD"),
      dayMaxEvents: true, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      headerToolbar: false,
      events: eventsFiltered,
    });
    aCalendar.render();
  };

  let clickCalendarView = async (letterView) => {
    if (!loadingOutlookEvents) {
      view = letterView;
      pressedArrow = true;
      currentDate = moment();

      if (view === "d") {
        currentDate = moment(currentDate, "dddd, D MMMM YYYY");
        await getOutlookCalendar(
          currentDate.startOf("day").format("YYYY-MM-DD"),
          currentDate.endOf("day").format("YYYY-MM-DD")
        );
      } else if (view === "w") {
        currentDate = moment(currentDate, "W YYYY");
        await getOutlookCalendar(
          currentDate.startOf("week").format("YYYY-MM-DD"),
          currentDate.endOf("week").format("YYYY-MM-DD")
        );
      } else if (view === "m" || view === "a") {
        currentDate = moment(currentDate, "MMMM YYYY");
        await getOutlookCalendar(
          currentDate.startOf("month").format("YYYY-MM-DD"),
          currentDate.endOf("month").format("YYYY-MM-DD")
        );
      } else if (view === "y") {
        currentDate = moment(currentDate, "YYYY");
        await getOutlookCalendar(
          currentDate.startOf("year").format("YYYY-MM-DD"),
          currentDate.endOf("year").format("YYYY-MM-DD")
        );
      }

      if (moment(currentDate, "YYYY-MM-DD").year() != currentYear) {
        reloadBirthdaysAndHolidays();
      }
      pressedArrow = false;
    }
  };

  let onChangeComboboxDate = async () => {
    if (!loadingOutlookEvents) {
      if (!pressedArrow) {
        if (view === "d") {
          currentDate = moment(dateOptionsSelected.value, "dddd, D MMMM YYYY");
          await getOutlookCalendar(
            currentDate.startOf("day").format("YYYY-MM-DD"),
            currentDate.endOf("day").format("YYYY-MM-DD")
          );
        } else if (view === "w") {
          currentDate = moment(dateOptionsSelected.value, "W YYYY");
          await getOutlookCalendar(
            currentDate.startOf("week").format("YYYY-MM-DD"),
            currentDate.endOf("week").format("YYYY-MM-DD")
          );
        } else if (view === "m") {
          currentDate = moment(dateOptionsSelected.value, "MMMM YYYY");
          await getOutlookCalendar(
            currentDate.startOf("month").format("YYYY-MM-DD"),
            currentDate.endOf("month").format("YYYY-MM-DD")
          );
        } else if (view === "y") {
          currentDate = moment(dateOptionsSelected.value, "YYYY");
          await getOutlookCalendar(
            currentDate.startOf("year").format("YYYY-MM-DD"),
            currentDate.endOf("year").format("YYYY-MM-DD")
          );
        } else if (view === "a") {
          currentDate = moment(dateOptionsSelected.value, "MMMM YYYY");
          await getOutlookCalendar(
            currentDate.startOf("month").format("YYYY-MM-DD"),
            currentDate.endOf("month").format("YYYY-MM-DD")
          );
        }
        reloadBirthdaysAndHolidays();
      }
      pressedArrow = false;
    }
  };

  let clickCalendar = (id) => {
    if (clickedCalendar !== "") {
      window
        .$("div." + clickedCalendar)
        .removeClass("fullCalendarYearlyClicked");
    }
    window.$("div." + id).addClass("fullCalendarYearlyClicked");
    clickedCalendar = id;
  };

  let returnToToday = () => {
    currentDate = moment();
    if (view === "d") {
      dateOptionsSelected = {
        value: currentDate.format("dddd, D MMMM YYYY"),
        label: currentDate.format("dddd, D MMMM YYYY"),
      };
    } else if (view === "m" || view === "a") {
      dateOptionsSelected = {
        value: currentDate.format("MMMM YYYY"),
        label: currentDate.format("MMMM YYYY"),
      };
    } else if (view === "w") {
      dateOptionsSelected = {
        value: "Week " + currentDate.format("w, MMMM YYYY"),
        label: "Week " + currentDate.format("w, MMMM YYYY"),
      };
    } else if (view === "y") {
      dateOptionsSelected = {
        value: currentDate.format("YYYY"),
        label: currentDate.format("YYYY"),
      };
    }
    if (moment(currentDate, "YYYY-MM-DD").year() != currentYear) {
      currentYear = moment(currentDate, "YYYY-MM-DD").year();
      // check eventFiltered and adjust birthday and holiday dates.
      events = events.map((e) => {
        if (
          e.id.split("-")[0] === "birthdate" ||
          e.id.split("-")[0] === "holiday"
        ) {
          e.start = moment(
            currentYear.toString() +
              moment(e.start, "YYYY-MM-DD").format("-MM-DD")
          ).format("YYYY-MM-DD");
          e.end = moment(
            currentYear.toString() +
              moment(e.end, "YYYY-MM-DD").format("-MM-DD")
          ).format("YYYY-MM-DD");
        }
        return e;
      });

      filterEvents();
      //createCalendars();
    }
  };

  let createEvent = () => {
    window.$("div.tooltip").remove();
    statusCreationEvent = !statusCreationEvent;
    if (statusCreationEvent) {
      op = "create";
      currentEvent = copyObject(emptyEvent);
      statusEventForm = true;
    }
  };

  let reloadBirthdaysAndHolidays = () => {
    currentYear = moment(currentDate, "YYYY-MM-DD").year();
    // check eventFiltered and adjust birthday and holiday dates.
    events = events.map((e) => {
      if (
        e.id.split("-")[0] === "birthdate" ||
        e.id.split("-")[0] === "holiday"
      ) {
        //change date
        e.start = moment(
          currentYear.toString() +
            moment(e.start, "YYYY-MM-DD").format("-MM-DD")
        ).format("YYYY-MM-DD");
        e.end = moment(
          currentYear.toString() + moment(e.end, "YYYY-MM-DD").format("-MM-DD")
        ).format("YYYY-MM-DD");
      }
      return e;
    });
    filterEvents();
  };

  let saveCalendars = () => {
    saveCalendar(myProjectsList);
  };

  let saveGCalendars = () => {
    const cals = myOutlookCalendarsList.reduce((acc, curr) => {
      if (curr.outlookCalendarType === "calendar" && curr.active) {
        acc.push(curr.id);
      }
      return acc;
    }, []);
    saveGCalendar(cals);
  };

  let getCalendars = () => {
    fixedMyProjectsList = getCalendar();
    fixedMyProjectsList = fixedMyProjectsList;
  };

  let processVisible = () => {
    let fcalendars = getCalendar();
    if (JSON.stringify(fcalendars) !== JSON.stringify(fixedMyProjectsList)) {
      resetVars();
      prepareCalendars();
    }
  };

  $: if (visible) {
    processVisible();
  }
</script>

<div class="content">
  <!-- Body -->
  <div class="container">
    <div class="leftColumn">
      <div class="containerCreateEvent" on:click={() => createEvent()}>
        {#if !statusCreationEvent}
          <img
            src="/images/calendar/logo-new-20x20-34525B.svg"
            alt="Menu Icon"
          />
        {:else if statusCreationEvent}
          <img
            src="/images/calendar/logo-minimize-20x20-34525B.svg"
            alt="Menu Icon"
          />
        {/if}
      </div>
      {#if statusCreationEvent}
        <EventManager
          modifyCallback={() => completeAll()}
          bind:visible={statusCreationEvent}
          bind:eventForm={statusEventForm}
          {currentEvent}
          {op}
          {eventType}
          outError={outlookError}
          onLogin={() =>
            outlookLogin(
              async () => {
                await initOutlook();
                filterEvents();
                createCalendars();
              },
              () => {
                outlookError = true;
              }
            )}
          bind:opErrorMessage={opOutlookMsgError}
          bind:opError={opOutlookError}
        />
      {/if}
      <MonthlyCalendar refresh={monthlyCalendarCount} />
      <div class="projectsList">
        <div class="title">
          <span>Projects</span>
        </div>
        <div class="calendars customScrollbars">
          {#each myProjectsList as p, index}
            <CalendarType
              text={p.text}
              color={p.color}
              active={p.active}
              onClickVisibility={() => toggleVisibility("project", index)}
              onClickMenu={() => toggleMenu("project", index)}
              menu={p.menu}
            />
            {#if p.menu}
              {#if p.phases}
                <div class="projectPhases">
                  <CalendarType
                    text={"Phases"}
                    color={p.color + "aa"}
                    active={p.phases.active}
                    onClickVisibility={() => toggleActive("phase", index)}
                    onClickMenu={() => toggleActive("phase", index)}
                  />
                </div>
              {/if}
              {#if p.milestones}
                <div class="projectPhases">
                  <CalendarType
                    text={"Milestones"}
                    color={p.color}
                    active={p.milestones.active}
                    onClickVisibility={() => toggleActive("milestone", index)}
                    onClickMenu={() => toggleActive("milestone", index)}
                  />
                </div>
              {/if}
              {#if p.outlookEvents}
                <div class="projectPhases">
                  <CalendarType
                    text={"Outlook"}
                    color={p.color}
                    active={p.outlookEvents.active}
                    onClickVisibility={() =>
                      toggleActive("outlookEvent", index)}
                    onClickMenu={() => toggleActive("outlookEvent", index)}
                  />
                </div>
              {/if}
              {#if p.tasks}
                <div class="projectPhases">
                  <CalendarType
                    text={"Tasks"}
                    color={p.color}
                    active={p.tasks.active}
                    onClickVisibility={() => toggleActive("task", index)}
                    onClickMenu={() => toggleActive("task", index)}
                  />
                </div>
              {/if}
            {/if}
          {/each}
        </div>
      </div>
      <div class="calendarsList">
        <div class="title">
          <span>Calendars</span>
        </div>
        <div class="calendars customScrollbars">
          {#if outlookError == false}
            {#each myOutlookCalendarsList as c, index}
              {#if c.outlookCalendarType === "calendar"}
                <CalendarType
                  text={c.text}
                  color={c.color}
                  active={c.active}
                  onClickVisibility={() => toggleActive("outlook", index)}
                  onClickMenu={() => toggleActive("outlook", index)}
                />
              {/if}
            {/each}
          {/if}
          <CalendarType
            text="External Absences"
            color={ABSENCEBACKGROUNDCOLOR}
            active={activeAbsence}
            onClickVisibility={() => toggleActive("absence")}
            onClickMenu={() => toggleActive("absence")}
          />
          <CalendarType
            text="Birthdays"
            color={BIRTHDAYBACKGROUNDCOLOR}
            active={activeBirthday}
            onClickVisibility={() => toggleActive("birthday")}
            onClickMenu={() => toggleActive("birthday")}
          />
          <CalendarType
            text="Holidays"
            color={HOLIDAYSBACKGROUNDCOLOR}
            active={activeHolidays}
            onClickVisibility={() => toggleActive("holiday")}
            onClickMenu={() => toggleActive("holiday")}
          />
        </div>
      </div>
    </div>
    <div class="rightColumn">
      {#if outlookError && !closeErrorMsg}
        <div
          class="outlookError"
          on:click={() =>
            outlookLogin(
              async () => {
                await initOutlook();
                filterEvents();
                createCalendars();
              },
              () => {
                outlookError = false;
              }
            )}
        >
          <span>
            Outlook connection failed or token expired. Click this box to retry.
          </span>
          <img
            src="/images/x.svg"
            alt=""
            on:click={() => {
              closeErrorMsg = true;
            }}
          />
        </div>
      {/if}

      {#if opOutlookError}
        <div class="outlookOpError">
          <span>{opOutlookMsgError}</span>
          <img
            src="/images/x.svg"
            alt=""
            on:click={() => {
              opOutlookError = false;
              opOutlookMsgError = "";
            }}
          />
        </div>
      {/if}

      <div class="menuCalendar">
        <div class="leftMenu">
          <div class="buttonToday" on:click={() => returnToToday()}>
            <span>Today</span>
          </div>
          {#if loadingOutlookEvents}
            <div class="loader">
              <Loading size="50px" />
            </div>
          {/if}
        </div>
        <div class="rightMenu">
          <div class="leftArrow">
            <img
              src="/images/calendar/left_arrow.svg"
              alt="Left Arrow"
              on:click={() => clickPrev()}
            />
          </div>
          <div class="rightArrow">
            <img
              src="/images/calendar/right_arrow.svg"
              alt="Right Arrow"
              on:click={() => clickNext()}
            />
          </div>
          <div class="comboBoxSelector">
            <Combobox
              bind:selectedValue={dateOptionsSelected}
              options={dateOptions}
              backgroundColor="white"
              searchable={false}
              onChange={() => onChangeComboboxDate()}
            />
          </div>
          <CalendarView
            text="Day"
            icon="/images/calendar/logo-day-20x20-34525B.svg"
            active={view === "d"}
            onClick={() => {
              getCalendars;
              clickCalendarView("d");
            }}
          />
          <!--           <CalendarView
            text="Day"
            icon="/images/calendar/logo-day-20x20-34525B.svg"
            active={view === "d"}
            onClick={() => clickCalendarView("d")}
          /> -->
          <CalendarView
            text="Week"
            icon="/images/calendar/logo-week-20x20-34525B.svg"
            active={view === "w"}
            onClick={() => clickCalendarView("w")}
          />
          <CalendarView
            text="Month"
            icon="/images/calendar/logo-month-20x20-34525B.svg"
            active={view === "m"}
            onClick={() => clickCalendarView("m")}
          />
          <CalendarView
            text="Year"
            icon="/images/calendar/logo-year-20x20-34525B.svg"
            active={view === "y"}
            onClick={() => clickCalendarView("y")}
          />
          <CalendarView
            text="Agenda"
            icon="/images/calendar/logo-agenda-20x20-34525B.svg"
            active={view === "a"}
            onClick={() => clickCalendarView("a")}
          />
        </div>
      </div>
      <!-- Daily Calendar ---------------------------------------------------------------------------->
      {#if view === "d"}
        <div class="containerDailyFC">
          <div class="dayViewHeader">
            <!-- svelte-ignore missing-declaration -->
            <DayComponent
              dayName={moment(currentDate).format("dddd")}
              dayNumber={moment(currentDate).format("D")}
              dayColor={moment(currentDate).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
                ? "var(--labit-bar)"
                : "white"}
              numberColor={moment(currentDate).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
                ? "white"
                : "var(--labit-card)"}
            />
          </div>
          <div class="fullCalendarDaily">
            <div id="dailyFC" />
          </div>
        </div>
      {:else if view === "w"}
        <div class="containerWeeklyFC">
          <div class="weekViewHeader">
            <!-- svelte-ignore missing-declaration -->
            <DayComponent
              dayName={moment(currentDate).isoWeekday(1).format("dddd")}
              dayNumber={moment(currentDate).isoWeekday(1).format("D")}
              dayColor={moment(currentDate)
                .isoWeekday(1)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "var(--labit-bar)"
                : "white"}
              numberColor={moment(currentDate)
                .isoWeekday(1)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "white"
                : "black"}
            />
            <!-- svelte-ignore missing-declaration -->
            <DayComponent
              dayName={moment(currentDate).isoWeekday(2).format("dddd")}
              dayNumber={moment(currentDate).isoWeekday(2).format("D")}
              dayColor={moment(currentDate)
                .isoWeekday(2)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "var(--labit-bar)"
                : "white"}
              numberColor={moment(currentDate)
                .isoWeekday(2)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "white"
                : "black"}
            />
            <!-- svelte-ignore missing-declaration -->
            <DayComponent
              dayName={moment(currentDate).isoWeekday(3).format("dddd")}
              dayNumber={moment(currentDate).isoWeekday(3).format("D")}
              dayColor={moment(currentDate)
                .isoWeekday(3)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "var(--labit-bar)"
                : "white"}
              numberColor={moment(currentDate)
                .isoWeekday(3)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "white"
                : "black"}
            />
            <!-- svelte-ignore missing-declaration -->
            <DayComponent
              dayName={moment(currentDate).isoWeekday(4).format("dddd")}
              dayNumber={moment(currentDate).isoWeekday(4).format("D")}
              dayColor={moment(currentDate)
                .isoWeekday(4)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "var(--labit-bar)"
                : "white"}
              numberColor={moment(currentDate)
                .isoWeekday(4)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "white"
                : "black"}
            />
            <!-- svelte-ignore missing-declaration -->
            <DayComponent
              dayName={moment(currentDate).isoWeekday(5).format("dddd")}
              dayNumber={moment(currentDate).isoWeekday(5).format("D")}
              dayColor={moment(currentDate)
                .isoWeekday(5)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "var(--labit-bar)"
                : "white"}
              numberColor={moment(currentDate)
                .isoWeekday(5)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "white"
                : "black"}
            />
            <!-- svelte-ignore missing-declaration -->
            <DayComponent
              dayName={moment(currentDate).isoWeekday(6).format("dddd")}
              dayNumber={moment(currentDate).isoWeekday(6).format("D")}
              dayColor={moment(currentDate)
                .isoWeekday(6)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "var(--labit-bar)"
                : "white"}
              numberColor={moment(currentDate)
                .isoWeekday(6)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "white"
                : "black"}
            />
            <!-- svelte-ignore missing-declaration -->
            <DayComponent
              dayName={moment(currentDate).isoWeekday(7).format("dddd")}
              dayNumber={moment(currentDate).isoWeekday(7).format("D")}
              dayColor={moment(currentDate)
                .isoWeekday(7)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "var(--labit-bar)"
                : "white"}
              numberColor={moment(currentDate)
                .isoWeekday(7)
                .format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "white"
                : "black"}
            />
          </div>
          <div class="fullCalendarWeekly">
            <div id="weeklyFC" />
          </div>
        </div>
      {:else if view === "m"}
        <div class="containerMonthlyFC">
          <div class="fullCalendarMonthly">
            <div id="monthlyFC" />
          </div>
        </div>
      {:else if view === "y"}
        <div class="containerYearlyFC">
          <div
            class="fullCalendarYearly yearJanuary"
            on:click={() => clickCalendar("yearJanuary")}
          >
            <div class="headerYearlyCalendar">
              <span>January</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="januaryCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearFebruary"
            on:click={() => clickCalendar("yearFebruary")}
          >
            <div class="headerYearlyCalendar">
              <span>February</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="februaryCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearMarch"
            on:click={() => clickCalendar("yearMarch")}
          >
            <div class="headerYearlyCalendar">
              <span>March</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="marchCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearApril"
            on:click={() => clickCalendar("yearApril")}
          >
            <div class="headerYearlyCalendar">
              <span>April</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="aprilCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearMay"
            on:click={() => clickCalendar("yearMay")}
          >
            <div class="headerYearlyCalendar">
              <span>May</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="mayCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearJune"
            on:click={() => clickCalendar("yearJune")}
          >
            <div class="headerYearlyCalendar">
              <span>June</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="juneCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearJuly"
            on:click={() => clickCalendar("yearJuly")}
          >
            <div class="headerYearlyCalendar">
              <span>July</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="julyCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearAugust"
            on:click={() => clickCalendar("yearAugust")}
          >
            <div class="headerYearlyCalendar">
              <span>August</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="augustCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearSeptember"
            on:click={() => clickCalendar("yearSeptember")}
          >
            <div class="headerYearlyCalendar">
              <span>September</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="septemberCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearOctober"
            on:click={() => clickCalendar("yearOctober")}
          >
            <div class="headerYearlyCalendar">
              <span>October</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="octoberCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearNovember"
            on:click={() => clickCalendar("yearNovember")}
          >
            <div class="headerYearlyCalendar">
              <span>November</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="novemberCalendar" />
            </div>
          </div>
          <div
            class="fullCalendarYearly yearDecember"
            on:click={() => clickCalendar("yearDecember")}
          >
            <div class="headerYearlyCalendar">
              <span>December</span>
            </div>
            <div class="containerYearlyCalendar">
              <div id="decemberCalendar" />
            </div>
          </div>
        </div>
      {:else if view === "a"}
        <div class="containerAgendaFC">
          <div class="fullCalendarAgenda">
            <div id="agendaFC" />
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  div.leftColumn {
    display: flex;
    flex-direction: column;
    /*     width: 260px; */
    width: 20%;
    margin-top: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  div.projectsList,
  div.calendarsList {
    /* height: 166px; */
    height: 226px;
    margin-top: 16px;
    background-color: #ffffff;
    border-radius: 19px;
  }

  div.title {
    /* height: 20%; */
    height: auto;
    padding: 5px;
    border-bottom-width: 1px;
    border-bottom-color: var(--labit-background-gray);
    border-bottom-style: solid;
    display: flex;
    align-items: center;
  }

  div.rightColumn {
    display: flex;
    flex-direction: column;
    /* width: 1364px; */
    width: 80%;
    margin-top: 20px;
    margin-left: 16px;
    /* justify-content: space-between; */
    flex-wrap: wrap;
  }

  div.menuCalendar {
    height: 52px;
    background-color: #ffffff;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  div.leftMenu {
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  div.rightMenu {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div.containerMonthlyFC {
    height: 869px;
    /* width: 1364px; */
    width: 100%;
    margin-top: 16px;
    background-color: #ffffff;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  div.containerDailyFC {
    height: 869px;
    /* width: 1364px; */
    width: 100%;
    margin-top: 16px;
    background-color: #ffffff;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding-bottom: 19px;
  }

  div.containerWeeklyFC {
    height: 869px;
    /* width: 1364px; */
    width: 100%;
    margin-top: 16px;
    background-color: #ffffff;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding-bottom: 19px;
  }

  div.containerYearlyFC {
    /* height: 869px; */
    height: 1056px;
    /* width: 1364px; */
    width: 100%;
    margin-top: 16px;
    background-color: #ffffff;
    border-radius: 19px;
    display: flex;
    align-content: space-evenly;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  div.containerAgendaFC {
    height: 869px;
    /* width: 1364px; */
    width: 100%;
    margin-top: 16px;
    background-color: #ffffff;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 19px;
  }

  div.projectsList span,
  div.calendarsList span {
    margin-left: 13px;
    font-size: 15px;
    font-weight: bold;
  }

  div.calendars {
    height: 83%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 5px;
  }

  div.leftArrow,
  div.rightArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 19px;
  }

  div.leftArrow img,
  div.rightArrow img {
    height: 19px;
    width: 19px;
    cursor: pointer;
  }

  div.comboBoxSelector {
    width: 164px;
    height: 22px;
    margin-right: 20px;
  }

  div.buttonToday {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 71px;
    cursor: pointer;
    background-color: var(--fullcalendar-header-monthlycalendar);
    border-radius: 19px;
    padding: 4px 19px;
  }

  div.buttonToday span {
    font-size: 15px;
    font-weight: bold;
  }

  div.fullCalendarDaily,
  div.fullCalendarWeekly,
  div.fullCalendarAgenda,
  div.fullCalendarMonthly {
    height: 100%;
    width: 100%;
  }

  div.dayViewHeader {
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 127px;
  }

  div.weekViewHeader {
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    padding-left: 77px;
    padding-right: 19px;
  }

  div.fullCalendarYearly {
    height: 346px;
    /* width: 341px; */
    width: 25%;
    border-radius: 19px;
    background-color: var(--labit-white);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
  div.headerYearlyCalendar {
    height: 10%;
    width: 90%;
    background-color: var(--labit-white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: bold;
    cursor: default;
  }

  div.containerYearlyCalendar {
    height: 90%;
    width: 90%;
  }

  div.outlookOpError,
  div.outlookError {
    border: 1px solid;
    margin-bottom: 10px;
    padding: 5px;
    background-color: #ffbaba;
    color: #d8000c;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  /*  div.outlookEventLoadingMessage {
    border: 1px solid lightgray;
    margin-top: 17px;
    padding: 2px 0 2px 5px;
    background-color: rgb(255, 171, 0);
    opacity: 0.8;
    color: rgb(37, 56, 88);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 13px;
  } */

  div.outlookOpError span,
  div.outlookError span {
    font-size: 15px;
    color: #8d0108;
    pointer-events: none;
    padding-left: 10px;
  }

  div.outlookOpError img,
  div.outlookError img {
    height: 12px;
    cursor: pointer;
    padding-right: 10px;
  }

  div.containerCreateEvent {
    display: flex;
    align-items: center;
    height: 52px;
    background-color: #d7e1ea;
    border-radius: 19px;
    cursor: pointer;
    justify-content: center;
  }

  div.containerCreateEvent img {
    width: 24px;
    height: 24px;
  }

  div.projectPhases {
    /* margin-left: 20px; */
    width: 100%;
    transform: scale(0.9);
  }

  div.loader {
    width: 100%;
  }

  /******************************************************************************************************************************/
</style>
