<script>
  export let size;
  export let onClick;
  export let closing;
</script>

<div
  class="borderButtonContainer fadedButton"
  style="width: {size}; height: {size}; background-color: {closing
    ? 'var(--labit-light-red)'
    : 'var(--labit-background-gray)'};"
  on:click={onClick}
>
  <img class={closing ? "hiddenElement" : ""} src="/images/x.svg" alt="Close" />
  <img
    class={closing ? "" : "hiddenElement"}
    src="/images/x-white.svg"
    alt="Close"
  />
</div>

<style>
  div.borderButtonContainer {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 16px;
  }
</style>
