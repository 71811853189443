<script>
  export let image;
  export let color;
</script>

<div class="programIconContainer" style="background-color: {color}">
  {#if image && image !== ""}
    <img src={image} alt="Program Icon" />
  {/if}
</div>

<style>
  div.programIconContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  img {
    max-height: 50%;
    max-width: 50%;
  }
</style>
