<script>
  // import TextEdit from "../components/TextEdit.svelte";
  // import SubmenuCombobox from "../components/SubmenuCombobox.svelte";
  // import SubmenuButton from "../components/SubmenuButton.svelte";
  import DialogRemove from "../components/DialogRemove.svelte";
  // import SubmenuDoubleCombobox from "../components/SubmenuDoubleCombobox.svelte";
  // import StandardItem from "../components/StandardItem.svelte";
  // import Loading from "../components/Loading.svelte";
  import EditBoxTab from "../components/EditBoxTab.svelte";
  import EditBoxFormButton from "../components/EditBoxFormButton.svelte";
  import EditBoxButton from "../components/EditBoxButton.svelte";
  // import StandardProjectItem from "../components/StandardProjectItem.svelte";
  // import StandardProjectProgram from "../components/StandardProjectProgram.svelte";
  // import StandardProjectSpaceRoom from "../components/StandardProjectSpaceRoom.svelte";
  // import FileUploader from "../components/FileUploader.svelte";
  // import ProgramIcon from "../components/ProgramIcon.svelte";
  // import VerticalSpacer from "../components/VerticalSpacer.svelte";
  import AnalyticsTextArea from "../components/AnalyticsTextArea.svelte";
  //import BoxEditPager from "../components/BoxEditPager.svelte";
  import EditSection from "../components/EditSection.svelte";
  import AnswerItem from "../components/AnswerItem.svelte";
  import AnalyticsRadioButton from "../components/AnalyticsRadioButton.svelte";
  import Picture from "../components/Picture.svelte";
  import HOTPItem from "../components/HOTPItem.svelte";

  //import ColorPicker from "../components/ColorPicker/ColorPicker.svelte";

  import DialogSpanFullEdit from "../modules/project-analytics/screens/components/DialogSpanFullEdit.svelte";
  import Combobox from "../modules/project-analytics/screens/components/Combobox.svelte";
  import DialogSpanCalendarPickerEvent from "../modules/calendar/screens/components/DialogSpanCalendarPickerEvent.svelte";

  import { post } from "../js/labit-connection";
  import { getUserId, getRole } from "../js/localInfo";
  //import { getInfoFromStandardName } from "../js/utils";
  //import { getDefaultColors } from "../js/colors";
  //import { openURL } from "../js/openURL";
  //import { openNewWindow } from "../js/openNewWindow";
  import { getAllProjects } from "../js/projects";

  //import newStandardEmpty from "../data/newStandard";

  import { onMount } from "svelte";
  import { copyObject } from "../js/copyObject";
  //import { loadStandard } from "../js/standards";

  import moment from 'moment';

  const myId = getUserId();

  let loading = false;

  const hoursOptions = [
    {
      label: "00",
      value: "00",
    },
    {
      label: "01",
      value: "01",
    },
    {
      label: "02",
      value: "02",
    },
    {
      label: "03",
      value: "03",
    },
    {
      label: "04",
      value: "04",
    },
    {
      label: "05",
      value: "05",
    },
    {
      label: "06",
      value: "06",
    },
    {
      label: "07",
      value: "07",
    },
    {
      label: "08",
      value: "08",
    },
    {
      label: "09",
      value: "09",
    },
    {
      label: "10",
      value: "10",
    },
    {
      label: "11",
      value: "11",
    },
    {
      label: "12",
      value: "12",
    },
    {
      label: "13",
      value: "13",
    },
    {
      label: "14",
      value: "14",
    },
    {
      label: "15",
      value: "15",
    },
    {
      label: "16",
      value: "16",
    },
    {
      label: "17",
      value: "17",
    },
    {
      label: "18",
      value: "18",
    },
    {
      label: "19",
      value: "19",
    },
    {
      label: "20",
      value: "20",
    },
    {
      label: "21",
      value: "21",
    },
    {
      label: "22",
      value: "22",
    },
    {
      label: "23",
      value: "23",
    },
  ];

  const minutesOptions = [
    {
      label: "00",
      value: "00",
    },
    {
      label: "15",
      value: "15",
    },
    {
      label: "30",
      value: "30",
    },
    {
      label: "45",
      value: "45",
    },
  ];

  const emptyQuest = {
    title: "",
    description: "",
    question: "",
    answers: [
      {
        id: -1,
        answer: "Yes",
      },
      {
        id: -1,
        answer: "No",
      },
      {
        id: -1,
        answer: "Later",
      },
    ],
    location: "dashboard", // dashboard, analytics, project
    assigned: [],
    notAssigned: [],
    scheduledDate: moment().format("DD/MM/YYYY"),
    scheduledHour: hoursOptions[0],
    scheduledMinute: minutesOptions[0],
    scheduledNow: true,
    expiredDate: moment().add(15, "days").format("DD/MM/YYYY"),
    expiredHour: hoursOptions[0],
    expiredMinute: minutesOptions[0],
    expired15: true,
    project: null,
  };

  let currentQuest = null;

  let currentAnswer = "";
  let editingAnswer = -1;
  let showAnswerForm = "";
  let currentAnswerError = false;
  let removingAnswer = null;
  let deletedAnswers = [];

  //let selectedLocation = "dashboard";

  let backgroundColorInput = "#d7e1ea";
  let borderColor = "var(--labit-card-gray)";

  let accId = -1;

  let projects = [];
  let selectedProject = null;

  let people = [];
  let peopleOptions = [];
  let peopleOptionsSel = null;

  let errorTitle = false;
  let errorQuestion = false;
  let errorDescription = false;
  let errorAnswers = false;
  let errorAssigned = false;

  let showQuestForm = ""; // "", "new", "edit"

  let quests = [];

  let savedQuest = null;
  let selectedQuest = null;

  let removeElement = "";
  let showRemoveDialog = false;

  onMount(async () => {
    await getProjects();
    await getPeople();

    await getQuests();

    //loadQuest();
  });

  let getQuests = async () => {
    const responseQuests = await post(
      `select id, title, question, description, scheduleNow, scheduleDate, expired15, expiredDate, location, project, pr.Name as projectName, peC.Name as peopleNameC, peC.PictureUrl as peoplePicC, peM.Name as peopleNameM, peM.PictureUrl as peoplePicM, q.creationDate, q.modificationDate
            from quest as q
            left join projects as pr on pr.project_id=q.project
            left join people as peC on peC.contact_id=q.createdBy
            left join people as peM on peM.contact_id=q.modifiedBy
            order by id desc`
    );

    const responseAnswers = await post(
      `select id, answer, quest
            from questAnswer`
    );

    const responsePeople = await post(
      `select qp.id, quest, people, answer, answerDateTime, isRead, Name, Access, lastActivity, PictureUrl, description, iconImg, atHome
            from questPeople as qp
            left join people as p on p.contact_id=qp.people
            left join profileStatus as ps on ps.id=p.profileStatus`
    );

    quests = responseQuests.map((q) => {
      return {
        id: q.id,
        title: q.title,
        question: q.question,
        description: q.description,
        scheduleNow: q.scheduleNow === "1",
        scheduleDate: q.scheduleDate,
        expired15: q.expired15 === "1",
        expiredDate: q.expiredDate,
        location: q.location,
        project: q.project
          ? {
              id: q.project,
              name: q.projectName,
            }
          : null,
        answers: [],
        people: [],
        creationInfo: {
          name: q.peopleNameC,
          pic: q.peoplePicC,
          date: q.creationDate,
        },
        modificationInfo: q.modificationDate
          ? {
              name: q.peopleNameM,
              pic: q.peoplePicM,
              date: q.modificationDate,
            }
          : null,
      };
    });

    responseAnswers.forEach((a) => {
      const answer = {
        id: a.id,
        answer: a.answer,
      };
      let quest = quests.find((q) => {
        return q.id === a.quest;
      });
      if (quest) {
        quest.answers.push(answer);
      }
    });

    responsePeople.forEach((p) => {
      let people = {
        id: p.id,
        peopleId: p.people,
        name: p.Name,
        pic: p.PictureUrl,
        role: p.Access,
        lastActivity: p.lastActivity,
        officeStatus: {
          description: p.description,
          icon: p.iconImg,
        },
        atHome: p.atHome,
        answer: null,
        answerDateTime: p.answerDateTime,
        isRead: p.isRead === "1",
      };
      let quest = quests.find((q) => {
        return q.id === p.quest;
      });
      if (quest) {
        if (p.answer) {
          const answer = quest.answers.find((a) => {
            return a.id === p.answer;
          });
          people.answer = answer;
        }
        quest.people.push(people);
      }
    });

    sortQuests();

    console.log(quests);
  };

  let sortQuests = () => {
    quests.forEach((quest) => {
      let answered = quest.people.filter((p) => {
        return p.answer;
      });
      let notAnsweredRead = quest.people.filter((p) => {
        return !p.answer && p.isRead;
      });
      let notAnsweredReadNotRead = quest.people.filter((p) => {
        return !p.answer && !p.isRead;
      });

      quest.people = [];
      answered.forEach((item) => {
        quest.people.push(item);
      });
      notAnsweredRead.forEach((item) => {
        quest.people.push(item);
      });
      notAnsweredReadNotRead.forEach((item) => {
        quest.people.push(item);
      });
    });

    quests = quests;
  };

  /*let loadQuest = () => {
    deletedAnswers = [];
    assignLocation("dashboard");
    scheduleNow();
  };*/

  let getProjects = async () => {
    const allProjects = await getAllProjects(false);
    const activeProjects = allProjects.filter((project) => {
      return project.active;
    });
    projects = activeProjects.map((project) => {
      return {
        label: project.name,
        value: project.id,
        project,
      };
    });
    console.log(projects);
  };

  let getPeople = async () => {
    const response = await post(
      `select contact_id, p.Name, PictureUrl, Access, lastActivity, description, iconImg, atHome
            from people as p
            left join companies as c on c.company_id=p.company_id
            left join profileStatus as ps on ps.id=p.profileStatus
            where p.active=1 and c.Name='Labit Group'`
    );
    people = response.map((item) => {
      return {
        id: item.contact_id,
        name: item.Name,
        pic: item.PictureUrl,
        role: item.Access,
        lastActivity: item.lastActivity,
        officeStatus: {
          description: item.description,
          icon: item.iconImg,
        },
        atHome: item.atHome,
      };
    });

    peopleOptions = people.map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
    if (peopleOptions.length > 0) {
      peopleOptionsSel = peopleOptions[0];
    }
    console.log(people);
  };

  let toggleAnswer = () => {
    restartAnswer();
    showAnswerForm = showAnswerForm !== "new" ? "new" : "";
  };

  let restartAnswer = () => {
    currentAnswer = "";
    //removingProgram = null;
  };

  let showAnswer = (action, edit = null) => {
    restartAnswer();
    if (edit) {
      editingAnswer = edit.id;
      currentAnswer = edit.answer;
    }
    showAnswerForm = action;
  };

  let addAnswer = () => {
    currentAnswerError = false;
    if (currentAnswer.trim() === "") {
      currentAnswerError = true;
      return;
    }
    const answer = {
      id: accId,
      answer: currentAnswer,
    };
    accId--;
    currentQuest.answers.push(answer);
    currentQuest = currentQuest;
    restartAnswer();
  };

  let editAnswer = () => {
    currentAnswerError = false;
    if (currentAnswer.trim() === "") {
      currentAnswerError = true;
      return;
    }
    let answer = currentQuest.answers.find((item) => {
      return item.id === editingAnswer;
    });
    answer.answer = currentAnswer.trim();
    currentQuest = currentQuest;
    restartAnswer();
    showAnswer("");
  };

  let removeAnswer = (id) => {
    if (!removingAnswer || removingAnswer !== id) {
      removingAnswer = id;
    } else {
      if (removingAnswer >= 0) {
        deletedAnswers.push(removingAnswer);
      }
      currentQuest.answers = currentQuest.answers.filter((item) => {
        return item.id !== id;
      });
      currentQuest = currentQuest;
      removingAnswer = null;
      showAnswerForm = "";
    }
  };

  let clickLocation = (location) => {
    currentQuest.location = location;
    assignLocation(location);
  };

  let assignLocation = (location) => {
    currentQuest.assigned = [];
    currentQuest.notAssigned = [];
    switch (location) {
      case "dashboard":
        currentQuest.assigned = people;
        break;
      case "analytics":
        currentQuest.assigned = people.filter((p) => {
          return p.role === "Analytics" || p.role === "Admin";
        });
        break;
      case "project":
        if (!currentQuest.project) {
          currentQuest.project = projects[0];
        }
        currentQuest.project.project.people.forEach((p) => {
          if (p.esLabit) {
            const peop = people.find((item) => {
              return item.id === p.peopleId;
            });
            if (peop) {
              currentQuest.assigned.push(peop);
            }
          }
        });
        break;
    }
    currentQuest.notAssigned = people.reduce((acc, curr) => {
      const pos = currentQuest.assigned.findIndex((item) => {
        return item.id === curr.id;
      });
      if (pos === -1) {
        acc.push(curr);
      }
      return acc;
    }, []);
    currentQuest = currentQuest;
    console.log(currentQuest);
  };

  let changeProject = () => {
    assignLocation("project");
  };

  let assignAll = () => {
    currentQuest.assigned = people;
    currentQuest.notAssigned = [];
    currentQuest = currentQuest;
  };

  let unassignAll = () => {
    currentQuest.assigned = [];
    currentQuest.notAssigned = people;
    currentQuest = currentQuest;
  };

  let assign = (id) => {
    const p = people.find((item) => {
      return item.id === id;
    });
    currentQuest.assigned.push(p);
    currentQuest.notAssigned = currentQuest.notAssigned.filter((na) => {
      return na.id !== id;
    });
    currentQuest = currentQuest;
  };

  let unassign = (id) => {
    const p = people.find((item) => {
      return item.id === id;
    });
    currentQuest.notAssigned.push(p);
    currentQuest.assigned = currentQuest.assigned.filter((na) => {
      return na.id !== id;
    });
    currentQuest = currentQuest;
  };

  let addAssign = () => {
    if (peopleOptionsSel) {
      const pos = currentQuest.assigned.findIndex((p) => {
        return p.id === peopleOptionsSel.value;
      });
      if (pos === -1) {
        assign(peopleOptionsSel.value);
      }
    }
  };

  let changeScheduledDate = () => {
    currentQuest.scheduledNow = false;
    currentQuest = currentQuest;
  };

  let changeExpiredDate = () => {
    currentQuest.expired15 = false;
    currentQuest = currentQuest;
  };

  let scheduleNow = () => {
    if (!currentQuest.scheduledNow) {
      currentQuest.scheduledNow = true;
      currentQuest = currentQuest;
    }
  };

  let expire15 = () => {
    if (!currentQuest.expired15) {
      currentQuest.expired15 = true;
      currentQuest = currentQuest;
    }
  };

  let save = async () => {
    if (!checkErrors()) {
      return;
    }

    const now = moment().format("YYYY-MM-DD HH:mm:ss");

    if (showQuestForm === "new") {
      const scheduledDate = currentQuest.scheduledNow
        ? moment().format("YYYY-MM-DD HH:mm:ss")
        : moment(
            currentQuest.scheduledDate +
              " " +
              currentQuest.scheduledHour +
              ":" +
              currentQuest.scheduledMinute +
              ":00",
            "DD/MM/YYYY HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
      const expiredDate = currentQuest.expired15
        ? moment().add(15, "days").format("YYYY-MM-DD") + " 00:00:00"
        : moment(
            currentQuest.expiredDate +
              " " +
              currentQuest.expiredHour +
              ":" +
              currentQuest.expiredMinute +
              ":00",
            "DD/MM/YYYY HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
      const project =
        currentQuest.location === "project"
          ? `'${currentQuest.project.value}'`
          : "null";
      const response = await post(
        `insert into quest (title, question, description, scheduleNow, scheduleDate, expired15, expiredDate, location, project, createdBy, creationDate)
            values ('${currentQuest.title}', '${currentQuest.question}', '${
          currentQuest.description
        }', ${currentQuest.scheduledNow ? "1" : "0"}, '${scheduledDate}', ${
          currentQuest.expired15 ? "1" : "0"
        }, '${expiredDate}', '${
          currentQuest.location
        }', ${project}, ${myId}, '${now}')`
      );
      currentQuest.id = response[0];

      for (let i = 0; i < currentQuest.answers.length; i++) {
        let answer = currentQuest.answers[i];
        const r = await post(
          `insert into questAnswer (answer, quest)
                values ('${answer.answer}', ${currentQuest.id})`
        );
        answer.id = r[0];
      }

      for (let i = 0; i < currentQuest.assigned.length; i++) {
        let people = currentQuest.assigned[i];
        await post(
          `insert into questPeople (quest, people, answer, answerDateTime)
                values (${currentQuest.id}, ${people.id}, null, null)`
        );
      }
    }

    if (showQuestForm === "edit") {
      const scheduledDate = currentQuest.scheduledNow
        ? moment().format("YYYY-MM-DD HH:mm:ss")
        : moment(
            currentQuest.scheduledDate +
              " " +
              currentQuest.scheduledHour.value +
              ":" +
              currentQuest.scheduledMinute.value +
              ":00",
            "DD/MM/YYYY HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
      const expiredDate = currentQuest.expired15
        ? moment().add(15, "days").format("YYYY-MM-DD") + " 00:00:00"
        : moment(
            currentQuest.expiredDate +
              " " +
              currentQuest.expiredHour.value +
              ":" +
              currentQuest.expiredMinute.value +
              ":00",
            "DD/MM/YYYY HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
      const project =
        currentQuest.location === "project"
          ? `'${currentQuest.project.value}'`
          : "null";

      await post(
        `update quest set
          title='${currentQuest.title}',
          question='${currentQuest.question}',
          description='${currentQuest.description}',
          scheduleNow=${currentQuest.scheduledNow ? "1" : "0"},
          scheduleDate='${scheduledDate}',
          expired15=${currentQuest.expired15 ? "1" : "0"},
          expiredDate='${expiredDate}',
          location='${currentQuest.location}',
          project=${project}
          where id=${selectedQuest.id}`
      );

      /* ANSWERS */

      // Update
      for (let i = 0; i < currentQuest.answers.length; i++) {
        const answer = currentQuest.answers[i];
        if (answer.id >= 0) {
          const pos = deletedAnswers.findIndex((item) => {
            return item === answer.id;
          });
          if (pos === -1) {
            await post(
              `update questAnswer
                set answer='${answer.answer}'
                where id=${answer.id}`
            );
          }
        }
      }

      // Insert
      for (let i = 0; i < currentQuest.answers.length; i++) {
        const answer = currentQuest.answers[i];
        if (answer.id < 0) {
          await post(
            `insert into questAnswer (answer, quest)
              values ('${answer.answer}', ${selectedQuest.id})`
          );
        }
      }

      // Delete
      for (let i = 0; i < deletedAnswers.length; i++) {
        await post(
          `delete from questAnswer
            where id=${deletedAnswers[i]}`
        );
      }

      /* ASSIGNMENTS */

      for (let i = 0; i < currentQuest.assigned.length; i++) {
        const assigned = currentQuest.assigned[i];
        const pos = savedQuest.assigned.findIndex((item) => {
          return item.id === assigned.id;
        });
        if (pos === -1) {
          await post(
            `insert into questPeople (quest, people)
              values (${selectedQuest.id}, ${assigned.id})`
          );
        }
      }

      for (let i = 0; i < savedQuest.assigned.length; i++) {
        const assigned = savedQuest.assigned[i];
        const pos = currentQuest.assigned.findIndex((item) => {
          return item.id === assigned.id;
        });
        if (pos === -1) {
          await post(
            `delete from questPeople
              where quest=${selectedQuest.id} and people=${assigned.id}`
          );
        }
      }

      await post(
        `update quest set
          modifiedBy=${myId},
          modificationDate='${now}'
          where id=${selectedQuest.id}`
      );
    }

    showForm("");
    getQuests();
  };

  let resend = (questId, answerId) => {
    console.log(questId);
    console.log(answerId);
    let quest = quests.find((item) => {
      return item.id === questId;
    });
    if (quest) {
      let people = quest.people.find((item) => {
        return item.id === answerId;
      });
      if (people) {
        people.answer = null;
        people.answerDateTime = null;
        people.isRead = false;
      }
    }

    sortQuests();

    console.log(quests);
  };

  let checkErrors = () => {
    errorTitle = false;
    errorQuestion = false;
    errorDescription = false;
    errorAnswers = false;
    errorAssigned = false;
    if (currentQuest.title.trim() === "") {
      errorTitle = true;
      return false;
    }
    if (currentQuest.question.trim() === "") {
      errorQuestion = true;
      return false;
    }
    if (currentQuest.description.trim() === "") {
      errorDescription = true;
      return false;
    }
    if (currentQuest.answers.length === 0) {
      errorAnswers = true;
      return false;
    }
    if (currentQuest.assigned.length === 0) {
      errorAssigned = true;
      return false;
    }
    return true;
  };

  let restartQuest = () => {
    currentAnswer = "";
    editingAnswer = -1;
    showAnswerForm = "";
    currentAnswerError = false;
    removingAnswer = null;
    deletedAnswers = [];
    selectedProject = null;
    peopleOptionsSel = null;
    errorTitle = false;
    errorQuestion = false;
    errorDescription = false;
    errorAnswers = false;
    errorAssigned = false;
  };

  let showForm = (show, questId = null) => {
    showQuestForm = show;
    if (show === "new") {
      restartQuest();
      currentQuest = copyObject(emptyQuest);
      currentQuest.answers[0].id = accId;
      accId--;
      currentQuest.answers[1].id = accId;
      accId--;
      currentQuest.answers[2].id = accId;
      accId--;
      currentQuest.project = projects[0];
      assignLocation("dashboard");
      scheduleNow();
      currentQuest = currentQuest;
    }
    if (show === "edit") {
      restartQuest();
      selectedQuest = quests.find((q) => {
        return q.id === questId;
      });

      let assigned = [];
      let notAssigned = [];
      people.forEach((p) => {
        const pos = selectedQuest.people.findIndex((peop) => {
          return peop.peopleId === p.id;
        });
        if (pos === -1) {
          notAssigned.push(copyObject(p));
        } else {
          assigned.push(copyObject(p));
        }
      });

      const scheduledHour = moment(
        selectedQuest.scheduleDate,
        "YYYY-MM-DD HH:mm:ss"
      ).format("HH");
      const scheduledMinute = moment(
        selectedQuest.scheduleDate,
        "YYYY-MM-DD HH:mm:ss"
      ).format("mm");
      const expiredHour = moment(
        selectedQuest.expiredDate,
        "YYYY-MM-DD HH:mm:ss"
      ).format("HH");
      const expiredMinute = moment(
        selectedQuest.expiredDate,
        "YYYY-MM-DD HH:mm:ss"
      ).format("mm");

      let project = null;
      if (selectedQuest.project) {
        const p = projects.find((pr) => {
          return pr.id === selectedQuest.project.id;
        });
        project = {
          value: selectedQuest.project.id,
          label: selectedQuest.project.name,
          project: p,
        };
      } /*else {
        if (projects)
        project = {
          value: projects[0].id,
          label: projects[0].name,
          project: projects[0],
        };
      }*/

      currentQuest = copyObject(emptyQuest);
      currentQuest.title = selectedQuest.title;
      currentQuest.description = selectedQuest.description;
      currentQuest.question = selectedQuest.question;
      currentQuest.answers = copyObject(selectedQuest.answers);
      currentQuest.location = selectedQuest.location; // dashboard, analytics, project
      currentQuest.assigned = assigned;
      currentQuest.notAssigned = notAssigned;
      currentQuest.scheduledDate = moment(
        selectedQuest.scheduleDate,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD/MM/YYYY");
      currentQuest.scheduledHour = {
        label: scheduledHour,
        value: scheduledHour,
      };
      currentQuest.scheduledMinute = {
        label: scheduledMinute,
        value: scheduledMinute,
      };
      currentQuest.scheduledNow = selectedQuest.scheduleNow;
      currentQuest.expiredDate = moment(
        selectedQuest.expiredDate,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD/MM/YYYY");
      currentQuest.expiredHour = {
        label: expiredHour,
        value: expiredHour,
      };
      currentQuest.expiredMinute = {
        label: expiredMinute,
        value: expiredMinute,
      };
      currentQuest.expired15 = selectedQuest.expired15;
      currentQuest.project = project;

      savedQuest = copyObject(currentQuest);

      currentQuest = currentQuest;
    }
  };

  let deleteQuest = async () => {
    showRemoveDialog = false;
    showForm("");

    await post(
      `delete from quest
        where id=${selectedQuest.id}`
    );

    await getQuests();
  };

  let showRemove = (visible) => {
    removeElement = `"Questionnaire: ${selectedQuest.title}"`;
    showRemoveDialog = visible;
  };

  $: console.log(currentQuest);
  $: console.log("PEOPLE", people);
</script>

{#if showRemoveDialog}
  <DialogRemove
    element={removeElement}
    onClose={() => {
      showRemove(false);
    }}
    onRemove={() => deleteQuest()}
  />
{:else}
  <div class="content">
    <!-- Menu Options -->

    <!--<div class="optionsMenu {showStandardForm !== '' ? 'hiddenElement' : ''}">
      {#if myRole === "Admin"}
        <div class="plusButton" on:click={() => showForm("new")}>
          <img src="/images/plus.svg" alt="New Icon" />
        </div>
      {/if}
      <div class="optionsRows">
        <div class="optionsRow">
          <div class="optionsColumn1">
            <div class="optionsSearch">
              <span>Search</span>
              <TextEdit
                placeholder="type here..."
                bind:value={filterText}
                maxlength="150"
                img="/images/search.svg"
              />
            </div>
          </div>
          <div class="optionsColumn2">
            <SubmenuButton
              text="Clear all"
              img="/images/x.svg"
              onClick={() => clearFilters()}
            />
          </div>
        </div>
        <div class="optionsRow lastRow">
          <div class="optionsColumn1">
            <SubmenuCombobox
              text="Client"
              options={clientOptions}
              bind:selectedValue={selectedClient}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "288px" : "326px"}
            />
            <SubmenuCombobox
              text="Product"
              options={productOptions}
              bind:selectedValue={selectedProduct}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "346px" : "384px"}
            />
            <SubmenuCombobox
              text="Location"
              options={locationOptions}
              bind:selectedValue={selectedLocation}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "192px" : "230px"}
            />
            <SubmenuCombobox
              text="Version"
              options={versionOptions}
              bind:selectedValue={selectedVersion}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "192px" : "230px"}
            />
          </div>
          <div class="optionsColumn2">
            <SubmenuButton
              text=""
              img="/images/book.svg"
              onClick={() => {
                openURL("/roomdatasheetg", true);
              }}
              onRightClick={() => {
                openNewWindow("/skylab-main/roomdatasheetg");
              }}
              bigIcon={true}
            />
          </div>
        </div>
        <div class="optionsRow lastRow">
          <div class="optionsColumn1">
            <SubmenuCombobox
              text="Time Code"
              options={timeCodeOptions}
              bind:selectedValue={selectedTimeCode}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "96px" : "115px"}
            />
            <SubmenuCombobox
              text="Folder Code"
              options={folderCodeOptions}
              bind:selectedValue={selectedFolderCode}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "96px" : "115px"}
            />
            <SubmenuCombobox
              text="Name"
              options={nameOptions}
              bind:selectedValue={selectedName}
              placeholder=""
              onChange={null}
            />
            <SubmenuDoubleCombobox
              text="Location"
              options1={countryOptions}
              bind:selectedValue1={selectedCountry}
              placeholder1=""
              onChange1={null}
              options2={cityOptions}
              bind:selectedValue2={selectedCity}
              placeholder2=""
              onChange2={null}
              width1={myRole === "Admin" ? "96px" : "115px"}
              width2={myRole === "Admin" ? "96px" : "115px"}
            />
            <SubmenuCombobox
              text="Project Manager"
              options={projectManagerOptions}
              bind:selectedValue={selectedProjectManager}
              placeholder=""
              onChange={null}
            />
            <SubmenuCombobox
              text="Status"
              options={statusOptions}
              bind:selectedValue={selectedStatus}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "96px" : "115px"}
            />
          </div>
          <div class="optionsColumn2">
            <SubmenuButton
              text=""
              img="/images/factory.svg"
              onClick={() => {
                openURL("/projects", true);
              }}
              onRightClick={() => {
                openNewWindow("/skylab-main/projects");
              }}
              bigIcon={true}
            />
          </div>
        </div>
      </div>
    </div>-->

    <!-- Body -->

    <!--<div class="loader {loading ? '' : 'hiddenElement'}">
      <Loading size="80px" />
    </div>-->

    <!--<div
      class="editBar {!loading && showStandardForm === 'edit'
        ? ''
        : 'hiddenElement'}"
    >
      <EditSection
        creationInfo={currentStandard.creationInfo}
        modificationInfo={currentStandard.modificationInfo}
        onRemove={() => {
          selectedStandard = currentStandard;
          showRemove(true);
        }}
      />
    </div>-->

    {#if showQuestForm !== "" && selectedQuest}
      <div class="editBar {showQuestForm === 'edit' ? '' : 'hiddenElement'}">
        <EditSection
          creationInfo={selectedQuest.creationInfo}
          modificationInfo={selectedQuest.modificationInfo}
          onRemove={() => {
            showRemove(true);
          }}
        />
      </div>

      <div class="editBox">
        <div class="editBoxTabs">
          <EditBoxTab icon="/images/book-blue.svg" text="HOT OF THE PRESS" />
        </div>
        <!--<div class="editBoxLoader {loadingStandard ? '' : 'hiddenElement'}">
      <Loading />
    </div>-->
        <div class="editBoxInfo">
          <div class="editBoxInfoInfo">
            <!-- Standard Info -->

            <div class="editBoxPage">
              <div class="editBoxColumnDouble">
                <span class="editBoxTitle requiredElement">Title</span>
                <div class="editBoxInput">
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    error={errorTitle}
                    bind:text={currentQuest.title}
                    maxLength={100}
                  />
                </div>
                <span class="editBoxTitle requiredElement">Question</span>
                <div class="editBoxInput">
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    error={errorQuestion}
                    bind:text={currentQuest.question}
                    maxLength={200}
                  />
                </div>
                <span class="editBoxTitle requiredElement">Description</span>
                <div class="editBoxTextarea">
                  <AnalyticsTextArea
                    bind:text={currentQuest.description}
                    error={errorDescription}
                  />
                </div>
                <span class="editBoxTitle requiredElement">Answers</span>
                <div class="editBoxSection {errorAnswers ? 'error' : ''}">
                  {#each currentQuest.answers as answer}
                    <AnswerItem
                      {answer}
                      onEdit={() => showAnswer("edit", answer)}
                      onRemove={() => removeAnswer(answer.id)}
                      {removingAnswer}
                    />
                  {/each}
                  <div class="addOption">
                    <img
                      class="fadedButton"
                      src="/images/plus.svg"
                      alt="Add Program"
                      on:click={() => {
                        toggleAnswer();
                      }}
                    />
                  </div>
                </div>
                {#if showAnswerForm !== ""}
                  <span class="editBoxTitle requiredElement">Answer</span>
                  <div class="editBoxInput">
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      error={currentAnswerError}
                      bind:text={currentAnswer}
                      maxLength={30}
                    />
                  </div>
                  <div class="editBoxAcceptCancel">
                    <EditBoxButton
                      text="Save"
                      onClick={showAnswerForm === "new"
                        ? () => addAnswer()
                        : () => editAnswer()}
                    />
                    <EditBoxButton
                      text="Close"
                      onClick={() => showAnswer("")}
                    />
                  </div>
                {/if}
              </div>
              <div class="editBoxColumn">
                <span class="editBoxTitle">People</span>
                <div class="divComboBox containerBox">
                  <Combobox
                    bind:selectedValue={peopleOptionsSel}
                    options={peopleOptions}
                    backgroundColor={backgroundColorInput}
                    analytics={true}
                    onChange={null}
                  />
                  <img
                    class="addAssign fadedButton"
                    src="/images/calendar/logo-new-20x20-34525B.svg"
                    alt=""
                    on:click={() => addAssign()}
                  />
                </div>
                <div class="radios">
                  <AnalyticsRadioButton
                    text="All Team"
                    selected={currentQuest.location === "dashboard"}
                    onClick={() => clickLocation("dashboard")}
                  />
                  <AnalyticsRadioButton
                    text="Analytics"
                    selected={currentQuest.location === "analytics"}
                    onClick={() => clickLocation("analytics")}
                  />
                  <AnalyticsRadioButton
                    text="Project"
                    selected={currentQuest.location === "project"}
                    onClick={() => clickLocation("project")}
                  />
                </div>
                {#if currentQuest.location === "project"}
                  <span class="editBoxTitle">Project</span>
                  <div class="divComboBox  containerBox editBoxInput">
                    <Combobox
                      bind:selectedValue={currentQuest.project}
                      options={projects}
                      backgroundColor={backgroundColorInput}
                      onChange={() => changeProject()}
                      analytics={true}
                    />
                  </div>
                {/if}
                <span class="editBoxTitle">Assigned</span>
                <div class="editBoxSection {errorAssigned ? 'error' : ''}">
                  <div class="people">
                    {#each currentQuest.assigned as people}
                      <div class="picture">
                        <Picture
                          image={people.pic}
                          name={people.name}
                          showHint={true}
                          onClick={() => unassign(people.id)}
                          lastActivity={people.lastActivity}
                          officeStatus={people.officeStatus}
                          atHome={people.atHome}
                        />
                      </div>
                    {/each}
                  </div>
                  <EditBoxButton
                    text="Unassign All"
                    onClick={() => unassignAll()}
                  />
                </div>
                <span class="editBoxTitle">Not Assigned</span>
                <div class="editBoxSection">
                  <div class="people">
                    {#each currentQuest.notAssigned as people}
                      <div class="picture">
                        <Picture
                          image={people.pic}
                          name={people.name}
                          showHint={true}
                          onClick={() => assign(people.id)}
                          lastActivity={people.lastActivity}
                          officeStatus={people.officeStatus}
                          atHome={people.atHome}
                        />
                      </div>
                    {/each}
                  </div>
                  <EditBoxButton
                    text="Assign All"
                    onClick={() => assignAll()}
                  />
                </div>
                <span class="editBoxTitle">Schedule</span>
                <div class="dateOptions">
                  <div class="dateBox">
                    <DialogSpanCalendarPickerEvent
                      bind:text={currentQuest.scheduledDate}
                      calendarOptions={[]}
                      action="edit"
                      error={false}
                      last={true}
                      marginBottom="0px"
                      analytics={true}
                      onChange={() => changeScheduledDate()}
                    />
                  </div>
                  <div class="divComboBox containerBox">
                    <Combobox
                      bind:selectedValue={currentQuest.scheduledHour}
                      options={hoursOptions}
                      backgroundColor={backgroundColorInput}
                      analytics={true}
                      onChange={() => changeScheduledDate()}
                    />
                  </div>
                  <div class="divComboBox containerBox">
                    <Combobox
                      bind:selectedValue={currentQuest.scheduledMinute}
                      options={minutesOptions}
                      backgroundColor={backgroundColorInput}
                      analytics={true}
                      onChange={() => changeScheduledDate()}
                    />
                  </div>
                  <div class="divComboBox containerBox">
                    <AnalyticsRadioButton
                      text="Now"
                      selected={currentQuest.scheduledNow}
                      onClick={() => scheduleNow()}
                    />
                  </div>
                </div>
                <span class="editBoxTitle">Expires</span>
                <div class="dateOptions">
                  <div class="dateBox">
                    <DialogSpanCalendarPickerEvent
                      bind:text={currentQuest.expiredDate}
                      calendarOptions={[]}
                      action="edit"
                      error={false}
                      last={true}
                      marginBottom="0px"
                      analytics={true}
                      onChange={() => changeExpiredDate()}
                    />
                  </div>
                  <div class="divComboBox containerBox">
                    <Combobox
                      bind:selectedValue={currentQuest.expiredHour}
                      options={hoursOptions}
                      backgroundColor={backgroundColorInput}
                      analytics={true}
                      onChange={() => changeExpiredDate()}
                    />
                  </div>
                  <div class="divComboBox containerBox">
                    <Combobox
                      bind:selectedValue={currentQuest.expiredMinute}
                      options={minutesOptions}
                      backgroundColor={backgroundColorInput}
                      analytics={true}
                      onChange={() => changeExpiredDate()}
                    />
                  </div>
                  <div class="divComboBox containerBox">
                    <AnalyticsRadioButton
                      text="15 Days"
                      selected={currentQuest.expired15}
                      onClick={() => expire15()}
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Standard Programs -->

            <!--<div class="editBoxPage {currentPage !== 1 ? 'hiddenElement' : ''}">
          <div class="editBoxColumn">
            <div class="editBoxTab">
              <EditBoxTab icon="/images/program.svg" text="PROGRAMMES" />
            </div>
            <div
              class="copy {showStandardForm === 'new' ? '' : 'hiddenElement'}"
            >
              <span class="editBoxTitle">Copy from Standard</span>
              <div class="editBoxInput containerBox">
                <Combobox
                  bind:selectedValue={standardListOptionsSel}
                  options={standardListOptions}
                  backgroundColor={backgroundColorInput}
                  onChange={null}
                />
              </div>
              <div class="editBoxAcceptCancel">
                <EditBoxButton text="Load" onClick={() => copyStandard()} />
              </div>
            </div>
            <div class="editBoxSection">
              {#each currentStandard.programs as program}
                <StandardProjectProgram
                  {program}
                  onClick={() => selectProgram(program)}
                  onEdit={() => showProgram("edit", program)}
                  onRemove={() => removeProgram(program.id)}
                  {removingProgram}
                  selected={selectedProgram &&
                    selectedProgram.id === program.id}
                />
              {/each}
              <div class="addOption">
                <img
                  class="fadedButton"
                  src="/images/plus.svg"
                  alt="Add Program"
                  on:click={() => toggleProgram()}
                />
              </div>
            </div>
            <div
              class="programForm {showProgramForm !== ''
                ? ''
                : 'hiddenElement'}"
            >
              <VerticalSpacer height="20" />
              <span class="editBoxTitle">Name</span>
              <div class="editBoxInput containerBox">
                <DialogSpanFullEdit
                  color={backgroundColorInput}
                  height="22"
                  border={borderColor}
                  error={currentProgramNameError !== ""}
                  bind:text={currentProgramName}
                />
              </div>
              <div class="programResources">
                <div class="programResourcesLeft">
                  <span class="editBoxTitle">Color</span>
                  <div class="colorpicker">
                    <ColorPicker bind:currentColor {defaultColors} />
                  </div>
                </div>
                <div class="programResourcesRight">
                  <span class="editBoxTitle">Icon</span>
                  <div class="programIcon">
                    <FileUploader
                      bind:fileInfo={currentIcon}
                      loadFromPath={currentIconPath}
                      picExtraStyle="border-radius: 10px"
                      showFullImage={true}
                    />
                  </div>
                  <span class="editBoxTitle">Preview</span>
                  <div class="previewIcon">
                    <ProgramIcon
                      image={currentIcon && currentIcon.data
                        ? currentIcon.data
                        : null}
                      color={currentColor}
                    />
                  </div>
                </div>
              </div>
              {#if currentIconError !== ""}
                <div class="editBoxError">
                  <span>{currentIconError}</span>
                </div>
              {/if}
              <div class="editBoxAcceptCancel">
                <EditBoxButton
                  text="Save"
                  onClick={showProgramForm === "new"
                    ? () => addProgram()
                    : () => editProgram()}
                />
                <EditBoxButton text="Close" onClick={() => showProgram("")} />
              </div>
            </div>
          </div>
          <div class="editBoxColumn">
            <div class="editBoxTab">
              <EditBoxTab icon="/images/space.svg" text="SPACES" />
            </div>
            <div
              class="selectedColumn {selectedProgram ? '' : 'hiddenElement'}"
            >
              <div class="editBoxSection">
                {#if selectedProgram}
                  {#each selectedProgram.spaces as space}
                    <StandardProjectSpaceRoom
                      spaceRoom={space}
                      type="space"
                      onClick={() => selectSpace(space)}
                      onEdit={() => showSpace("edit", space)}
                      onRemove={() => removeSpace(space.id)}
                      removingSpaceRoom={removingSpace}
                      selected={selectedSpace && selectedSpace.id === space.id}
                    />
                  {/each}
                {/if}
                <div class="addOption">
                  <img
                    class="fadedButton"
                    src="/images/plus.svg"
                    alt="Add Space"
                    on:click={() => toggleSpace()}
                  />
                </div>
              </div>
              <div
                class="programForm {showSpaceForm !== ''
                  ? ''
                  : 'hiddenElement'}"
              >
                <VerticalSpacer height="20" />
                <span class="editBoxTitle">Name</span>
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    error={currentSpaceNameError !== ""}
                    bind:text={currentSpaceName}
                  />
                </div>
                <div class="editBoxAcceptCancel">
                  <EditBoxButton
                    text="Save"
                    onClick={showSpaceForm === "new"
                      ? () => addSpace()
                      : () => editSpace()}
                  />
                  <EditBoxButton text="Close" onClick={() => showSpace("")} />
                </div>
              </div>
            </div>
          </div>
          <div class="editBoxColumn">
            <div class="editBoxTab">
              <EditBoxTab icon="/images/room.svg" text="ROOMS" />
            </div>
            <div class="selectedColumn {selectedSpace ? '' : 'hiddenElement'}">
              <div class="editBoxSection">
                {#if selectedSpace}
                  {#each selectedSpace.rooms as room}
                    <StandardProjectSpaceRoom
                      spaceRoom={room}
                      type="room"
                      onClick={null}
                      onEdit={() => showRoom("edit", room)}
                      onRemove={() => removeRoom(room.id)}
                      removingSpaceRoom={removingRoom}
                    />
                  {/each}
                {/if}
                <div class="addOption">
                  <img
                    class="fadedButton"
                    src="/images/plus.svg"
                    alt="Add Room"
                    on:click={() => toggleRoom()}
                  />
                </div>
              </div>
              <div
                class="programForm {showRoomForm !== '' ? '' : 'hiddenElement'}"
              >
                <VerticalSpacer height="20" />
                <span class="editBoxTitle">Name</span>
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    error={currentRoomNameError !== ""}
                    bind:text={currentRoomName}
                  />
                </div>
                <span class="editBoxTitle">Room Code</span>
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    error={currentRoomCodeError !== ""}
                    bind:text={currentRoomCode}
                  />
                </div>
                <span class="editBoxTitle">Description</span>
                <div class="editBoxTextarea containerBox">
                  <AnalyticsTextArea bind:text={currentRoomDescription} />
                </div>
                <div class="editBoxAcceptCancel">
                  <EditBoxButton
                    text="Save"
                    onClick={showRoomForm === "new"
                      ? () => addRoom()
                      : () => editRoom()}
                  />
                  <EditBoxButton text="Close" onClick={() => showRoom("")} />
                </div>
              </div>
            </div>
          </div>
        </div>-->
          </div>
          <div class="editBoxButtons">
            <EditBoxFormButton
              icon="/images/x.svg"
              onClick={() => showForm("")}
            />
            <EditBoxFormButton icon="/images/save.svg" onClick={() => save()} />
            <!--<EditBoxFormButton
          icon="/images/save.svg"
          onClick={() => {
            if (showStandardForm === "new") {
              insertStandard();
            } else {
              updateStandard();
            }
          }}
        />-->
          </div>
        </div>
      </div>
    {/if}

    <span on:click={() => showForm("new")}>NUEVO</span>
    <div class="quests {showQuestForm ? 'hiddenElement' : ''}">
      {#each quests as quest}
        <HOTPItem
          {quest}
          onResend={(questId, answerId) => resend(questId, answerId)}
          onEdit={() => showForm("edit", quest.id)}
        />
      {/each}
    </div>

    <!--<div class="standards {showStandardForm !== '' ? 'hiddenElement' : ''}">
    <div class="standardTitles {loading ? 'hiddenElement' : ''}">
      <div class="standardInfo">
        <img src="/images/book.svg" alt="Standard Icon" />
        <span>CLIENT</span>
        <span>PRODUCT</span>
        <span>LOCATION</span>
        <span>LB VERSION</span>
      </div>
      <div class="projectInfo">
        <img src="/images/factory.svg" alt="Project Icon" />
        <span>CODE</span>
        <span>CODE</span>
        <span>NAME</span>
      </div>
      <div class="projectManager">
        <img src="/images/user.svg" alt="Project Manager Icon" />
        <span>PROJECT MANAGER</span>
      </div>
    </div>
    {#each standards as standard}
      {#if standard.visible}
        <StandardItem
          {standard}
          onEdit={() => {
            showForm("edit", standard.id);
          }}
          onRemove={() => {
            selectedStandard = standard;
            showRemove(true);
          }}
        />
      {/if}
    {/each}
  </div>-->
  </div>
{/if}

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin-top: 20px;
    color: var(--labit-darkgray);
  }

  div.optionsRows {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    padding-right: 13px;
    justify-content: space-between;
  }

  div.optionsColumn2 {
    display: flex;
  }

  div.optionsSearch {
    display: flex;
    flex-grow: 1;
    height: 21px;
    align-items: center;
  }

  div.optionsSearch span {
    margin-right: 13px;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  div.standards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    flex-direction: column;
  }

  div.plusButton {
    width: 122px;
    background-color: var(--labit-dialog-background);
    border-radius: 10px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  div.plusButton img {
    width: 30px;
    height: 30px;
  }

  div.editBox {
    width: 100%;
    margin-top: 20px;
  }

  div.editBoxTabs {
    width: 1510px;
    display: flex;
    margin-bottom: 8px;
    padding: 0px 49px 0px 36px;
    justify-content: space-between;
  }

  div.editBoxTab {
    width: 428px;
    margin-bottom: 25px;
  }

  div.editBoxTabEmpty {
    width: 428px;
    margin-bottom: 55px;
  }

  div.editBoxInfo {
    width: 100%;
    display: flex;
    min-height: 710px !important;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  div.editBoxInfoInfo {
    background-color: #d7e1ea;
    padding: 41px 49px 20px 36px;
    border-radius: 15px;
    width: 1510px;
    display: flex;
    flex-direction: column;
  }

  div.editBoxLoader {
    width: 100%;
    display: flex;
    min-height: 710px !important;
    justify-content: center;
    align-items: center;
    background-color: #d7e1ea;
    border-radius: 15px;
  }

  div.editBoxButtons {
    display: flex;
    flex-direction: column;
  }

  div.editBoxPage {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }

  div.editBoxColumn {
    width: 428px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  div.editBoxColumnDouble {
    width: 932px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  span.editBoxTitle {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 10px;
    width: 100%;
  }

  span.editBoxTitleName {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 20px;
    width: 100%;
  }

  div.editBoxInput {
    height: 22px;
    margin-bottom: 10px;
  }

  div.editBoxSection {
    width: 100%;
    border-radius: 15px;
    border: 1px solid var(--labit-card-gray);
    padding: 15px 23px 15px 23px;
    margin-bottom: 10px;
  }

  div.addOption {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  div.addOption img {
    width: 20px;
  }

  div.programResources {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  div.programResourcesLeft {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  div.programResourcesRight {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.colorpicker {
    width: 100%;
    height: 250px;
  }

  div.programIcon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  div.previewIcon {
    width: 100px;
    height: 100px;
  }

  div.editBoxAcceptCancel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.editBoxError {
    width: 100%;
    font-size: 12px;
    display: flex;
    color: red;
    margin: -10px 0px 20px 0px;
  }

  div.programForm {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.selectedColumn {
    display: flex;
    flex-direction: column;
  }

  div.editBoxTextarea {
    margin-bottom: 10px;
  }

  div.loader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  div.copy {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  div.standardTitles {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-gray);
    align-items: flex-start;
    font-weight: bold;
    padding: 0px 26px 0px 26px;
    display: flex;
    margin-bottom: 19px;
  }

  div.standardTitles div.standardInfo {
    width: 35%;
    display: flex;
    align-items: center;
  }

  div.standardTitles div.standardInfo span {
    margin-right: 13px;
  }

  div.standardTitles img {
    height: 21px;
    margin-right: 13px;
  }

  div.standardTitles div.projectInfo {
    width: 25%;
    display: flex;
    align-items: center;
  }

  div.standardTitles div.projectInfo span {
    margin-right: 13px;
  }

  div.standardTitles div.projectManager {
    width: 15%;
    display: flex;
    align-items: center;
  }

  div.editBar {
    width: 100%;
    margin-top: 20px;
  }

  div.radios {
    display: flex;
    margin-bottom: 10px;
  }

  div.divComboBox {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div.picture {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  div.people {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  div.containerBox {
    height: 22px;
  }

  img.addAssign {
    height: 22px;
    margin-left: 10px;
  }

  div.dateOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  div.dateOptions div.containerBox {
    height: 17px;
    margin-bottom: 0px;
  }

  div.dateOptions div.divComboBox {
    margin-left: 15px;
  }

  div.quests {
    width: 100%;
  }

  div.error {
    border: 1px solid red !important;
  }

  /* Animations */

  div.plusButton {
    transition: opacity 0.5s linear 0s;
  }

  div.plusButton:hover {
    opacity: 0.5;
  }
</style>
