export const OUTLOOK_URL = "https://outlook.office.com/mail/";
export const OUTLOOK_CALENDAR_URL = "https://outlook.office365.com/calendar";
export const OUTLOOK_SEND_EMAIL_URL =
  "https://outlook.office.com/owa/?path=/mail/action/compose&to=";
export const CLOUDHQ_URL = "https://www.cloudhq.net/synch";
export const YOUTUBE_URL =
  "https://www.youtube.com/channel/UCl5d3D3NNyuMs0iZyprlTuQ/playlists";
export const HUBSPOT_URL = "https://app.hubspot.com/contacts/7723524/contacts/";
export const SLACK_URL = "https://app.slack.com/client/";
export const SLACK_FILE_SERVER = "https://files.slack.com/";
export const BIM360_URL =
  "https://docs.b360.autodesk.com/projects/39314b9f-986b-4a1a-915a-c5d44634dd5c/folders/urn:adsk.wipprod:fs.folder:co.n593cy0ST-ysjaaFdY917Q/detail";
export const SHAREPOINT_URL =
  "https://labitgroup.sharepoint.com/_layouts/15/sharepoint.aspx";
export const LABIT_URL = "https://labit.es";
export const SLACK_TEAM_URL =
  "https://labitgroup.slack.com/app_redirect?channel=";
