<script>
  export let title;
  export let titleIcon;

  import CategoryItem from "./CategoryItem.svelte";
  import EditBoxTab from "./EditBoxTab.svelte";
  import EditBoxButton from "./EditBoxButton.svelte";

  import DialogSpanFullEdit from "../modules/project-analytics/screens/components/DialogSpanFullEdit.svelte";

  import { post } from "../js/labit-connection";

  import { onMount } from "svelte";

  let backgroundColorInput = "#d7e1ea";
  let borderColor = "var(--labit-card-gray)";

  let creating = false;
  let editing = null;
  let deleting = null;

  let currentName = "";

  let errorName = false;

  let categories = [
    {
      id: "1",
      name: "Team",
      editable: false,
    },
    {
      id: "2",
      name: "Consultants",
      editable: false,
    },
    {
      id: "3",
      name: "Clients",
      editable: false,
    },
    {
      id: "4",
      name: "Services",
      editable: false,
    },
    {
      id: "5",
      name: "Landlords",
      editable: false,
    },
    {
      id: "6",
      name: "Suppliers",
      editable: false,
    },
  ];

  let cancelTimer = null;

  const CANCEL_DELETING_TIME = 10000;

  onMount(async () => {
    await getCategories();
  });

  let getCategories = async () => {
    const response = await post(
      `select category_id, Name
            from categories`
    );
    response.forEach((item) => {
      const pos = categories.findIndex((c) => {
        return c.id === item.category_id;
      });
      if (pos === -1) {
        categories.push({
          id: item.category_id,
          name: item.Name,
          editable: true,
        });
      }
    });
    categories = categories;
  };

  let requestAdd = () => {
    creating = true;
    editing = null;
    deleting = null;
    currentName = "";
    errorName = false;
    clearTimeout(cancelTimer);
  };

  let requestEdit = (index) => {
    creating = false;
    editing = categories[index].id;
    deleting = null;
    currentName = categories[index].name;
    errorName = false;
    clearTimeout(cancelTimer);
  };

  let requestDelete = async (index) => {
    creating = false;
    editing = null;
    errorName = false;
    clearTimeout(cancelTimer);
    if (!deleting) {
      deleting = categories[index].id;
      cancelTimer = setTimeout(cancelDelete, CANCEL_DELETING_TIME);
    } else {
      await post(
        `delete from categories
                where category_id=${deleting}`
      );
      categories = categories.filter((item) => {
        return item.id !== deleting;
      });
      closeForm();
    }
  };

  let cancelDelete = () => {
    deleting = null;
  };

  let closeForm = () => {
    creating = false;
    editing = null;
    deleting = null;
    currentName = "";
    errorName = false;
  };

  let save = async () => {
    errorName = false;
    if (currentName.trim() === "") {
      errorName = true;
      return;
    }
    if (creating) {
      let pos = categories.findIndex((item) => {
        return item.name.trim() === currentName.trim();
      });
      if (pos >= 0) {
        errorName = true;
        return;
      }
      const response = await post(
        `insert into categories (Name, sort)
                values ('${currentName}', -1)`
      );
      const category = {
        id: response[0],
        name: currentName,
        editable: true,
      };
      categories.push(category);
      await post(
        `update categories set
            sort=${category.id}
            where category_id=${category.id}`
      );
    } else {
      await post(
        `update categories set
                Name='${currentName}'
                where category_id=${editing}`
      );
      let category = categories.find((item) => {
        return item.id === editing;
      });
      if (category) {
        category.name = currentName;
      }
    }
    categories = categories;
    closeForm();
  };
</script>

<div class="box">
  <div class="editBoxTab">
    <EditBoxTab icon={titleIcon} text={title} />
  </div>
  <div class="items">
    <div
      class="itemList customScrollbars {creating || editing
        ? 'itemListMin'
        : ''}"
    >
      {#each categories as category, index}
        <CategoryItem
          {category}
          onEdit={() => requestEdit(index)}
          onDelete={() => requestDelete(index)}
          deletingId={deleting}
        />
      {/each}
    </div>
    <div class="plus">
      <img
        class="fadedButton"
        src="/images/plus.svg"
        alt="Add"
        on:click={() => requestAdd()}
      />
    </div>
  </div>
  <div class="form {creating || editing ? '' : 'hiddenElement'}">
    <span class="editBoxTitle requiredElement">Name</span>
    <div class="editBoxInput containerBox">
      <DialogSpanFullEdit
        color={backgroundColorInput}
        height="22"
        border={borderColor}
        error={errorName}
        bind:text={currentName}
      />
    </div>
    <div class="editBoxAcceptCancel">
      <EditBoxButton text="Save" onClick={() => save()} />
      <EditBoxButton text="Close" onClick={() => closeForm()} />
    </div>
  </div>
</div>

<style>
  div.box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div.editBoxTab {
    width: 428px;
    margin-bottom: 25px;
  }

  span.editBoxTitle {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 10px;
    width: 100%;
  }

  div.editBoxAcceptCancel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  div.editBoxInput {
    height: 22px;
    margin-bottom: 10px;
  }

  div.items {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 15px;
    border: 1px solid var(--labit-card-gray);
    padding: 15px 23px 15px 23px;
  }

  div.plus {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div.plus img {
    width: 20px;
    height: 20px;
  }

  div.itemList {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
  }

  div.itemListMin {
    max-height: 360px !important;
  }
</style>
