<script>
  export let mapCenter; // {latitude, longitude}
  export let locations; // [ {latitude, longitude, name, onClick} ]
  export let zoom = 17;
  export let id = "";

  const GOOGLE_API_KEY = "AIzaSyA3J_Ted4QhRRuqKGIIxK1bLHlbhSoRlG4";

  let loaded = false;

  window.initMap = function () {
    const center = {
      lat: parseFloat(mapCenter.latitude),
      lng: parseFloat(mapCenter.longitude),
    };
    try {
      var map = new google.maps.Map(
        document.getElementById("googleMap-" + id),
        {
          zoom,
          center,
          mapTypeId: google.maps.MapTypeId.SATELLITE,
        }
      );
      locations.forEach((location) => {
        const loc = {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
        };
        let marker = new google.maps.marker.AdvancedMarkerElement({
          position: loc,
          title: location.name,
          map: map,
        });
        marker.addListener("click", location.onClick);
      });
    } catch (e) {}

    loaded = true;
  };

  $: if (locations && loaded) {
    window.initMap();
  }
</script>

<svelte:head>
  <script
    src={"https://maps.googleapis.com/maps/api/js?key=" +
      GOOGLE_API_KEY +
      "&callback=initMap"}>
  </script>
</svelte:head>

<div class="googleMapsContainer" id={"googleMap-" + id} />

<style>
  div.googleMapsContainer {
    width: 100%;
    height: 100%;
    border-radius: 19px;
  }
</style>
