<script>
    import RadioButtonSelector from "./RadioButtonSelector.svelte";
    import Calendar from './Calendar.svelte';
    import YearCalendar from './YearCalendar.svelte';
    import Modal from 'svelte-simple-modal';

    import { getRole } from '../../../js/localInfo';

    const options = [
        { id: 0, value: 'Timeline calendar' },
        { id: 1, value: 'Year calendar' }
    ]

    const role = getRole();

    let selected = 0;

    let userId;

    const changeToYearCalendar = (ev) => {
        userId = ev.detail;
        selected = 1;
    }

</script>

<div class="main">
    <div class="radio-container">
        <RadioButtonSelector {options} bind:selected/>
    </div>

    <Modal 
        closeButton={false}
        styleWindow={{ width: '340px', height: '400px' }}
    >
    {#if selected === 0}
        <Calendar on:userSelected={changeToYearCalendar} {role}/>
    {:else if selected === 1}
        <YearCalendar {userId} />
    {/if}
    </Modal>

</div>

<style>
    .main {
        width: 100%;
        height: 100%;
    }

    .radio-container {
        display: flex;
        gap: 2rem;
        justify-content: center;
    }
</style>