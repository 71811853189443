<script>
  export let link;
  export let columnIndex;
  export let firstColumn;
  export let lastColumn;
  export let firstLink;
  export let lastLink;
  export let editLinks;
  export let onDeleteSection;
  export let onSectionUp;
  export let onSectionDown;
  export let onSectionRight;
  export let onSectionLeft;
  export let savingLinks;

  import LinkColumnLinkItem from "./LinkColumnLinkItem.svelte";

  import DialogSpanEdit from "../../../../components/DialogSpanEdit.svelte";
  import DialogSmallButton from "../../../../components/DialogSmallButton.svelte";

  import { clickOutside } from "../../../../js/clickOutside";
  import { post } from "../../../../js/labit-connection";

  let changed = false;

  let showForm = ""; // new, edit
  let currentDescription = "";
  let currentUrl = "";
  let errorCurrentDescription = false;
  let errorCurrentUrl = false;
  let selectedLink = null;

  let accId = -1;

  let onClickOutside = async () => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        if (changed) {
          await post(
            `update linkSection
          set title='${link.title.title}'
          where id=${link.title.id}`
          );
          changed = false;
        }
        savingLinks = false;
      }
    }
  };

  let onChange = () => {
    changed = true;
  };

  let openForm = (visible, editLink = null) => {
    showForm = visible;
    if (showForm != "") {
      if (showForm === "edit") {
        currentDescription = editLink.title;
        currentUrl = editLink.url;
        selectedLink = editLink;
      } else {
        currentDescription = "";
        currentUrl = "";
        selectedLink = null;
      }
      errorCurrentDescription = false;
      errorCurrentUrl = false;
    }
  };

  let checkErrors = () => {
    errorCurrentDescription = false;
    errorCurrentUrl = false;
    if (currentDescription.trim() === "") {
      errorCurrentDescription = true;
      return true;
    }
    if (currentUrl.trim() === "") {
      errorCurrentUrl = true;
      return true;
    }
    return false;
  };

  let fixUrl = (url) => {
    let fixedUrl = url;
    if (!url.startsWith("http")) {
      fixedUrl = "https://" + url;
    }
    return fixedUrl;
  };

  let saveLink = async () => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        if (!checkErrors()) {
          currentUrl = fixUrl(currentUrl);
          if (showForm === "new") {
            let l = {
              id: accId,
              title: currentDescription,
              url: currentUrl,
            };
            accId--;
            link.links.push(l);

            const response = await post(
              `insert into linkTool (description, url, pos, section)
              values ('${l.title}', '${l.url}', ${link.links.length - 1}, ${
                link.title.id
              })`
            );
            l.id = response[0];
          } else {
            selectedLink.title = currentDescription;
            selectedLink.url = currentUrl;

            await post(
              `update linkTool set
              description='${selectedLink.title}',
              url='${selectedLink.url}'
              where id=${selectedLink.id}`
            );
          }

          link = link;

          openForm("");
        }
        savingLinks = false;
      }
    }
  };

  let upLink = async (id) => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        const pos = link.links.findIndex((l) => {
          return l.id === id;
        });
        if (pos >= 0) {
          const item = link.links.splice(pos, 1);
          link.links.splice(pos - 1, 0, item[0]);
          link = link;
        }

        for (let i = 0; i < link.links.length; i++) {
          const l = link.links[i];

          await post(
            `update linkTool set
            pos=${i}
            where id=${l.id}`
          );
        }

        savingLinks = false;
      }
    }
  };

  let downLink = async (id) => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        const pos = link.links.findIndex((l) => {
          return l.id === id;
        });
        if (pos >= 0) {
          const item = link.links.splice(pos, 1);
          link.links.splice(pos + 1, 0, item[0]);
          link = link;
        }

        for (let i = 0; i < link.links.length; i++) {
          const l = link.links[i];

          await post(
            `update linkTool set
            pos=${i}
            where id=${l.id}`
          );
        }

        savingLinks = false;
      }
    }
  };

  let deleteLink = async (id) => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        link.links = link.links.filter((l) => {
          return l.id !== id;
        });
        link = link;

        await post(
          `delete from linkTool
          where id=${id}`
        );
        savingLinks = false;
      }
    }
  };

  //$: console.log(link);
</script>

<div class="linkColumnLinkContainer">
  <div class="title">
    {#if editLinks}
      {#if !firstColumn}
        <img
          class="fadedButton first"
          src="/images/arrows/left-arrow.svg"
          alt="Move to left group"
          on:click={() => onSectionLeft(columnIndex, link.title.id)}
        />
      {/if}
    {/if}
    <input
      type="text"
      bind:value={link.title.title}
      use:clickOutside
      on:click_outside={onClickOutside}
      on:change={() => onChange()}
      readonly={!editLinks}
    />
    {#if editLinks}
      {#if !firstLink}
        <img
          class="fadedButton"
          src="./images/arrows/up-arrow.svg"
          alt="Move up"
          on:click={() => onSectionUp(link.title.id)}
        />
      {/if}
      {#if !lastLink}
        <img
          class="fadedButton"
          src="/images/arrows/down-arrow.svg"
          alt="Move down"
          on:click={() => onSectionDown(link.title.id)}
        />
      {/if}
      {#if !lastColumn}
        <img
          class="fadedButton"
          src="/images/arrows/right-arrow.svg"
          alt="Move to right group"
          on:click={() => onSectionRight(columnIndex, link.title.id)}
        />
      {/if}
      <img
        class="fadedButton"
        src="/images/trash.svg"
        alt="Delete section"
        on:click={() => onDeleteSection(link.title.id)}
      />
    {/if}
  </div>
  {#each link.links as item, index}
    <LinkColumnLinkItem
      link={item}
      firstLink={index === 0}
      lastLink={index === link.links.length - 1}
      {editLinks}
      onEditLink={() => openForm("edit", item)}
      onDeleteLink={() => deleteLink(item.id)}
      onUpLink={() => upLink(item.id)}
      onDownLink={() => downLink(item.id)}
    />
  {/each}
  {#if editLinks}
    <div class="options">
      <img
        class="fadedButton"
        src="/images/plus.svg"
        alt="Add new link"
        on:click={() => {
          if (showForm === "") {
            openForm("new");
          } else {
            openForm("");
          }
        }}
      />
    </div>
    {#if showForm !== ""}
      <div class="form">
        <span class="requiredElement">Link Description</span>
        <DialogSpanEdit
          bind:text={currentDescription}
          action="edit"
          maxLength={50}
          error={errorCurrentDescription}
          last={false}
        />
        <span class="requiredElement">Link URL</span>
        <DialogSpanEdit
          bind:text={currentUrl}
          action="edit"
          error={errorCurrentUrl}
          last={false}
        />
        <div class="titleButtons">
          <DialogSmallButton text="Save" onClick={() => saveLink()} />
          <DialogSmallButton text="Close" onClick={() => openForm("")} />
        </div>
      </div>
    {/if}
  {/if}
</div>

<style>
  div.linkColumnLinkContainer {
    width: 100%;
    margin-bottom: 20px;
  }

  div.title {
    display: flex;
    align-items: center;
  }

  input {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: var(--labit-card-gray);
    background-color: transparent;
    border: none;
    padding: 0px;
  }

  img {
    height: 15px;
    max-width: 15px;
  }

  img:not(:first-child) {
    margin-left: 3px;
  }

  img:not(:last-child) {
    margin-right: 3px;
  }

  img.first {
    margin-right: 6px;
  }

  div.options {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 8px 0px 8px 0px;
  }

  div.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    color: var(--labit-card-gray);
  }

  div.titleButtons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 8px;
  }
</style>
