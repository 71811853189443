<script>
  export let nextPage;
  export let visible;

  import LoginIcon from "../components/LoginIcon.svelte";
  import LoginButton from "../components/LoginButton.svelte";

  import { getOutlookNext } from "../js/localInfo";
  import { outlookLogin } from "../components/dashboard/outlook/outlook";

  import { openURL } from "../js/openURL";

  let executeLogin = () => {
    outlookLogin(
      () => {
        /*const url = getOutlookNext();
        location.href = url;*/
        nextPage();
      },
      () => {}
    );
  };

  let cancelLogin = () => {
    /*const url = getOutlookNext();
    location.href = url;*/
    nextPage();
  };

  $: if (visible) {
    executeLogin();
  }
</script>

<div class="loginContainer {visible ? '' : 'hiddenElement'}">
  <a class="logo" href="https://labit.es" target="_blank">
    <img src="/images/logo-labit-website.svg" alt="Logo Icon" />
  </a>
  <div class="info">
    <LoginIcon
      imgIcon="/images/outlook-white.svg"
      color="var(--labit-login-icon-red)"
      imgWidth="90px"
    />
    <div class="form formDiv">
      <div class="info">
        <span class="msg formDiv">
          The page you are about to visit requires an active Outlook session to
          work properly.
        </span>
      </div>
      <div class="formItem button">
        <LoginButton
          text="Outlook LOGIN and continue >"
          colorText="white"
          colorBackground="var(--labit-login-icon-gray)"
          colorBorder="var(--labit-login-icon-gray)"
          onClick={() => executeLogin()}
        />
      </div>
      <div class="formItem button">
        <LoginButton
          text="Cancel and continue"
          colorText="white"
          colorBackground="var(--labit-login-icon-red)"
          colorBorder="var(--labit-login-icon-red)"
          onClick={() => cancelLogin()}
        />
      </div>
    </div>
  </div>
</div>

<style>
  div.loginContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  }

  div.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 134px;
  }

  div.form {
    width: 50%;
    margin-top: -173px;
  }

  div.formItem {
    width: 100%;
    height: 29px;
    margin-bottom: 19px;
    position: relative;
  }

  div.button {
    height: 33px;
  }

  span.msg {
    font-size: 12px;
    color: var(--labit-darkgray);
    margin-bottom: 33px;
    text-align: center;
  }

  a.logo {
    position: absolute;
    top: 38px;
    left: 115px;
  }

  a.logo img {
    width: 48px;
    cursor: pointer;
  }

  /* Animations */

  a.logo img {
    transition: opacity 0.5s linear 0s;
  }

  a.logo img:hover {
    opacity: 0.5;
  }
</style>
