<script>
  import CompleteResourcePlanner from "../components/CompleteResourcePlanner/CompleteResourcePlanner.svelte";
</script>

<div class="completeResourceManagerContainer">
  <CompleteResourcePlanner />
</div>

<style>
  div.completeResourceManagerContainer {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding-bottom: 40px;
  }
</style>
