<script>
    import { getContext } from "svelte";
    import { post } from '../../../js/labit-connection';

    export let absence = {};
    export let absenceType = {};
    //export let action;

    const { close } = getContext("simple-modal");

    let status = '';
    if (absence.acceptedByCEO === "1") {
        status = 'Accepted';
    } else if (absence.acceptedByCEO === "-1") {
        status = 'Denied';
    }

    const onDeny = () => {
        post(`UPDATE absences SET accepted_by_ceo = -1 WHERE id = ${absence.id};`);
        close();
    }

    const onAccept = () => {
        post(`UPDATE absences SET accepted_by_ceo = 1 WHERE id = ${absence.id};`);
        close();
    }

</script>

<div class="main flex flex-col">
    <div class="btnContainer flex">
        <img
            class="closeModalWindow"
            src="/images/x.svg"
            alt="Quit"
            on:click={close}
        />
    </div>
    <div class="text-container">
        <p class={status === 'Accepted' ? 'accepted' : 'denied'}>{status}</p>
        <p class="absence-type">{absenceType.name || absence.name}</p>
        <p class="absence-desc">{absence.description}</p>
    </div>
    <div class="buttons-container flex">
        <button class="deny" on:click={onDeny}>Deny</button>
        <button class="accept" on:click={onAccept}>Accept</button>
    </div>
</div>

<style>

    .text-container {
        flex-grow: 1;
    }

    .accepted {
        color: #3b9d3b; 
    }

    .denied {
        color: #ff6b6b;
    }

    .main {
        height: 360px;
        padding: 0 16px;
    }

    .absence-type {
        font-size: 1.4rem;
    }

    .absence-desc {
        font-size: 1rem;
    }

    .absence-desc, .absence-type {
        margin-top: 0;
    }
    
    .btnContainer {
        width: 100%;
        justify-content: flex-end;
    }

    .closeModalWindow {
        width: 40px;
        padding: 10px;
        cursor: pointer;
    }

    .flex {
        display: flex;
    }

    .flex-col {
        flex-direction: column;
    }

    .buttons-container, .main {
        justify-content: space-between;
    }

    button {
        width: 80px;
        height: 32px;
        margin: 0;
        border: none;
        color: white;
        border-radius: 20px;
        cursor: pointer;
    }

    .accept {
        background-color: #3b9d3b;
    }

    .deny {
        background-color: #ff6b6b;
    }

</style>
