<script>
  export let keyword;
  export let onRemove;
</script>

<style>
  div.keywordContainer {
    background-color: var(--labit-card-gray);
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 15px;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  img {
    cursor: pointer;
    width: 10px;
    margin-left: 6px;
  }

  /* Animations */

  img {
    transition: opacity 0.5s linear 0s;
  }

  img:hover {
    opacity: 0.5;
  }
</style>

<div class="keywordContainer">
  <span>{keyword}</span>
  <img src="/images/x-white.svg" alt="Remove Keyword" on:click={onRemove} />
</div>
