<script>
  export let text;
</script>

<style>
  div.submenuTexts {
    width: 123px;
    height: 30px;
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    font-size: 13px;
    color: var(--labit-card-gray);
    font-weight: bold;
  }
</style>

<div class="submenuTexts">
  <span>{text}</span>
</div>
