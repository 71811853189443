<script>
  export let text;
  export let calendarOptions;
  export let action;
  export let last;
  export let analytics = false;

  import CalendarPicker from "./CalendarPicker/CalendarPicker.svelte";
</script>

{#if action === "show"}
  <span class="columnInfo {last ? 'last' : ''}">{text}</span>
{:else}
  <div class="element {last ? 'last' : ''}">
    <CalendarPicker bind:date={text} options={calendarOptions} />
  </div>
{/if}

<style>
  span.columnInfo {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-bottom: 8px;
  }

  div.element {
    width: 100%;
    height: 17px;
    margin-bottom: 8px;
  }

  .last {
    margin-bottom: 0px !important;
  }

  span:empty:before {
    content: "\200b";
  }
</style>
