<script>
  export let item;
  export let onClick;
</script>

<div class="textEditHintsItemContainer" on:click={onClick}>
  <span>{item}</span>
</div>

<style>
  div.textEditHintsItemContainer {
    width: 100%;
    font-size: 12px;
    cursor: pointer;
    padding: 4px 25px 4px 25px;
    height: 25px;
    display: flex;
    align-items: center;
  }

  div.textEditHintsItemContainer:hover {
    background-color: lightgray;
  }
</style>
