<script>
  export let key;
  export let createUpdateKey;
  export let currentKey;
  export let option;
  export let onClick;
</script>

<div class="keyContainer">
  <div class="keyInfoImageName">
    <div
      class="keyActiveCircle"
      style="background-color: {key.active ? '#31d64a' : 'gray'}"
    />
    <span>{key.username}</span>
  </div>
  <div class="optionsContainer">
    <img
      class="optionIcon"
      src="/images/edit.svg"
      alt="Edit Logo"
      on:click={() => {
        option = "update";
        currentKey.id = key.id;
        currentKey.username = key.username;
        currentKey.passwd = key.passwd;
        currentKey.active = key.active;
        /*         key.roles.forEach((role) => {
          currentKey.roles.push(role.name);
        }); */
        currentKey.roles = key.roles;
        currentKey = currentKey;
        createUpdateKey = true;
      }}
    />
    <img
      class="optionIcon"
      src="/images/trash.svg"
      alt="Remove Logo"
      on:click={() => {
        option = "delete";
        onClick();
      }}
    />
  </div>
</div>

<style>
  .keyInfoImageName {
    width: 88%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .keyContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
  }

  .keyActiveCircle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .keyContainer span {
    font-size: 13px;
  }

  .optionIcon {
    width: 20px;
    cursor: pointer;
  }
  .optionsContainer {
    width: 12%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
