<script>
  export let color;
  export let onEdit;
  export let onDelete;
  export let deletingId;
</script>

<div class="colorItemContainer">
  <div class="info">
    <div class="color" style="background-color: {color.color};" />
    <span>{color.color}</span>
  </div>
  <div class="options">
    <img
      class="fadedButton"
      src="/images/edit.svg"
      alt="Edit"
      on:click={onEdit}
    />
    <img
      class="fadedButton"
      src={deletingId === color.id
        ? "/images/trash-red.svg"
        : "/images/trash.svg"}
      alt="Remove"
      on:click={onDelete}
    />
  </div>
</div>

<style>
  div.colorItemContainer {
    display: flex;
    margin-bottom: 10px;
  }

  div.info {
    width: 90%;
    font-size: 14px;
    color: var(--labit-card-gray);
    display: flex;
    align-items: center;
  }

  div.options {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }

  div.options img {
    width: 20px;
    margin-left: 5px;
  }

  div.color {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }
</style>
