<script>
  export let fileInfo;
  export let loadFromPath = "";
  export let picExtraStyle = "border-radius: 50%;";
  export let showFullImage = false;
  export let loadingFile = false;

  import { rpost } from "../js/labit-connection";

  let loadFromRemotePath = async () => {
    loadingFile = true;
    if (loadFromPath === "") {
      fileInfo = null;
    } else {
      const response = await rpost(
        "GetPublicFile",
        {
          fullPath: loadFromPath,
        },
        "text"
      );

      const tokens = loadFromPath.split(".");
      const extension = tokens[tokens.length - 1];

      fileInfo = {
        data: response,
        extension,
      };
    }
    loadingFile = false;
  };

  $: if (loadFromPath) {
    //console.log("FILEUPLOADER", loadFromPath);
    loadFromRemotePath();
  }

  //$: console.log(loadFromPath);

  let fileInput = null;
  let dragging = false;

  let openDialog = () => {
    fileInput.click();
  };

  let selectFile = () => {
    loadFile(fileInput.files[0]);
  };

  let clearIcon = (ev) => {
    ev.stopPropagation();
    fileInfo = null;
  };

  let onDragOver = (ev) => {
    dragging = true;
    ev.preventDefault();
  };

  let onDragLeave = () => {
    dragging = false;
  };

  let onDrop = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    dragging = false;

    loadFile(ev.dataTransfer.files[0]);
  };

  let loadFile = (file) => {
    loadingFile = true;
    const tokens = file.name.split(".");
    const extension = tokens.length > 0 ? tokens[tokens.length - 1] : "";
    let reader = new FileReader();
    reader.onload = function (event) {
      const data = event.target.result;
      fileInfo = {
        data,
        extension,
      };
      loadingFile = false;
    };
    reader.readAsDataURL(file);
  };
</script>

<div
  class="fileUploaderContainer {dragging ? 'dragging' : 'notdragging'}"
  on:click={() => openDialog()}
  on:dragover={(e) => {
    onDragOver(e);
  }}
  on:dragleave={() => {
    onDragLeave();
  }}
  on:drop={(e) => {
    onDrop(e);
  }}
>
  {#if !fileInfo}
    <div class="form">
      <img src="/images/cloud-upload-dark.svg" alt="Upload File" />
      <div>
        <span>Upload</span>
      </div>
    </div>
  {:else}
    <div class="iconContainer">
      <div
        class="icon"
        style="background: url({fileInfo.data}) no-repeat center center;
        background-size: {showFullImage ? 'contain' : 'cover'}; {picExtraStyle}"
      />
      <img
        class="x"
        src="/images/x.svg"
        alt="Clear Icon"
        on:click={(ev) => clearIcon(ev)}
      />
    </div>
  {/if}
  <input
    bind:this={fileInput}
    id="fileUploderInput"
    type="file"
    style="display: none"
    accept="image/x-png,image/gif,image/jpeg"
    on:change={() => selectFile()}
  />
</div>

<style>
  div.fileUploaderContainer {
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
  }

  div.form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: var(--labit-card-gray);
    font-weight: bold;
    border-radius: 19px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--labit-card-gray);
  }

  div.form img {
    height: 50%;
    width: 90%;
    max-width: 200px;
    max-height: 200px;
  }

  div.form div {
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.iconContainer {
    width: 100%;
    height: 100%;
    position: relative;
  }

  div.icon {
    width: 100%;
    height: 100%;
  }

  div.icon:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  img.x {
    width: 10px;
    position: absolute;
    right: -12px;
    top: 0px;
    cursor: pointer;
  }

  div.dragging div.form {
    opacity: 0.5 !important;
  }

  div.dragging div.icon {
    opacity: 0.5 !important;
  }

  /* Animations */

  div.notdragging div.form {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.notdragging:hover div.form {
    opacity: 0.5;
  }

  div.notdragging div.icon {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.notdragging:hover div.icon {
    opacity: 0.5;
  }

  img.x {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  img.x:hover {
    opacity: 0.5;
  }
</style>
