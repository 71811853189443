import { post } from '../../../js/labit-connection';
import { getAllProjects } from "../../../js/projects";
import { getRole } from "../../../js/localInfo";
import { timeToMs, timeConvert } from '../../../js/formatDateTime';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

const myRole = getRole();

export const getTaskTypes = async () => {
    const taskTypes = await post( `SELECT id, name as value FROM task_type;` );

    const taskTypeObj = taskTypes.reduce((acc, e) => (acc[e.id] = e.value, acc), {});
    const taskTypeSeparated = taskTypes.reduce((acc, e) => { 
        const type = (e.id === '118' || e.id === '119') ? 'nonBillable' : 'billable';
        acc[type].push( {id: e.id, value: e.value} );
        return acc;
    }, { 'billable': [], 'nonBillable': [] });

    return [taskTypeObj, taskTypeSeparated];
}

export const getMyProjects = async (role = myRole, id) => {
    const res = await getAllProjects(true, id, false, true);
    const myProjectsList = res.filter((e) => (e.active && (role !== 'Admin' || (role === 'Admin' && e.lastName.trim() !== 'Labit Group' ))));
    console.log(myProjectsList);

    return myProjectsList.reduce((acc, elem) => {
        acc[0].push({ id: elem.id, value: elem.name });
        
        for (var i = 0, phasesArr = elem.phases, len = phasesArr.length; i < len; i++) {
            if (!acc[1].hasOwnProperty(elem.id)) acc[1][elem.id] = [];
            acc[1][elem.id].push( { id: phasesArr[i].id, value: phasesArr[i].name } );

            acc[2] = {...acc[2], [phasesArr[i].id]: {projName: elem.name, phaseName: phasesArr[i].name}};
        }

        return acc;
    }, [[], {}, {}] );
};

export const getRoundOff = (start, end) => {
    const startCopy = moment(start);
    const endCopy = moment(end);
    const difference = parseInt(moment(endCopy, 'YYYY-MM-DD HH:mm:ss').format('x')) - parseInt(moment(startCopy, 'YYYY-MM-DD HH:mm:ss').format('x'));
    return difference > 32400000 ? 900000 : (difference * 900000) / 32400000;
};

export const getTasks = async (from, to, id) => {
    const tasksPromise = post( `SELECT * FROM tasks WHERE user_id = ${id} AND start > ${from.format('x')} AND start < ${to.format('x')} order by start asc;` );
    const startEndPromise = post( `SELECT id, start, end FROM startEndDay WHERE user_id = ${id} AND start > "${from.startOf('day').format('YYYY-MM-DD HH:mm:ss')}" AND (end < "${to.endOf('day').format('YYYY-MM-DD HH:mm:ss')}" OR end IS NULL) order by start asc;` );
    const [tasks, startEnd] = await Promise.all([tasksPromise, startEndPromise]);

    const startEndObj = startEnd.reduce((acc, day) => {
        const date = moment(day.start, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
        acc[date] = {
            id: day.id,
            start: day.start === null ? null : day.start,
            end: day.end === null ? null : day.end
        }
        return acc;
    }, {});
    
    const days = fillDates(from, to, 'DD/MM/YYYY');

    const keys = Object.keys(days);
    const keysLength = keys.length;

    // This groups each task within its day
    tasks.forEach((e) => {//holamonito
        const start = parseInt(e.start);
        for(let i = 0; i < keysLength; i++) { // Se utiliza un for normal para poder utilizar un break. Se puede sustituir por un .every().
            const day = days[keys[i]];
            if (parseInt(day.startMillis) <= start && parseInt(day.endMillis) > start) {
                days[keys[i]].tasks.push(e);
                break;
            }
        }
    });
    
    keys.forEach(key => {
        const day = days[key];
        const [billable, nonBillable] = getBillableAndnonBillableMillis(day.tasks);
        day.billableMillis = billable;
        day.nonBillableMillis = nonBillable;

        if (startEndObj.hasOwnProperty(key)) {
            const startEndDateId = startEndObj[key].id;
            const startStr = startEndObj[key].start;
            const endStr = startEndObj[key].end;

            const roundOffMs = (endStr === null) ? 0 : (days[key].hasOwnProperty('tasks')) ? getRoundOff(startStr, endStr) : 0;

            day.roundOffMs = roundOffMs;
            day.startDate = moment(startStr, 'YYYY-MM-DD HH:mm:ss');
            day.endDate = endStr === null ? null : moment(endStr, 'YYYY-MM-DD HH:mm:ss');
            day.startEndDateId = startEndDateId;
        } else {
            day.roundOffMs = 0;
        }
    });

    return days;
}

const fillDates = (from, to, formatIn) => {
    let [fromCopy, toCopy] = [moment(from), moment(to)];

    const diff = (toCopy.diff(fromCopy, 'days') + 1);

    let datesArray = [];
    for (let i = 0; i < diff; i++) {
        const m = moment(fromCopy);
    	datesArray.push(m);
        fromCopy.add(1, 'days');
    }
  
    return datesArray.reduce((acc,curr) => {
      const key = curr.format(formatIn);
      const endMillis = curr.endOf('day').format('x');
      const startMillis = curr.startOf('day').format('x');
      acc[key] = { startMillis, endMillis, billableMillis: 0, nonBillableMillis: 0, tasks: [] };
      return acc;
    },{});
}

const getBillableAndnonBillableMillis = (tasks) => {
    return tasks.reduce((acc, i) => {
        const totalMillis = parseInt(i.end) - parseInt(i.start);
        if (i.task_type_id === "118" || i.task_type_id === "119") {
            acc[1] += totalMillis;
        } else {
            acc[0] += totalMillis;
        }
        return acc;
    }, [0,0]);
}

// TODO: comprobar si la tarea que se copia es una tarea que se quedó en pantalla del día anterior
export const modifyTask = async (event) => {
    const id = event.detail.id;
    const desc = event.detail.desc;
    const start = event.detail.start;
    const end = event.detail.end;

    const query = `update tasks set description = '${desc}', start = ${start}, end = ${end} where id = ${id};`
    await post(query);
}

export const copyTask = async (event) => {

    // Obtener última tarea del día de hoy para el usuario con ID. Si hay, utilizar hora de fin para hora inicio de esta tarea

    let id = event.detail.id;
    const now = moment().format("x");
    const query = `insert into tasks (description, start, end, task_type_id, phase_id, user_id) select description, "${now}", (end-start)+${now}, task_type_id, phase_id, user_id from tasks where id = ${id};`;
    
    await post( query );
}

export const deleteTask = async (id) => {
    await post( `delete from tasks where id = ${id};` );
}

export const addTask = async (desc, phaseId, taskTypeId, start, end, id) => {
    start = start.toString();
    end = end.toString();
    
    const error = {
        description: desc === '',
        phase: phaseId === '',
        taskType: taskTypeId === '',
        start: start === '' || !start.includes(':'),
        end: end === '' || !end.includes(':'),
    }
    if (!error.description && !error.phase && !error.taskType && !error.start && !error.end) {
        let formattedDayStart = new Date();
        let formattedDayEnd = new Date();
        
        start = start.split(":");
        end = end.split(":");
        formattedDayStart.setHours(start[0], start[1], 0);
        formattedDayEnd.setHours(end[0], end[1], 0);

        desc = desc.replace(/'/g, '');
        desc = desc.replace(/\\/g, '');
        
        if (formattedDayStart > formattedDayEnd) {
            formattedDayEnd.setDate(formattedDayEnd.getDate() + 1);
        }

        if((formattedDayStart<formattedDayEnd) && (formattedDayEnd - formattedDayStart) < 36000000) { // 10 horas
            let query = `INSERT INTO tasks (description, start, end, phase_id, task_type_id, user_id) VALUES ('${desc}',${formattedDayStart.getTime()},${formattedDayEnd.getTime()}, '${phaseId}', '${taskTypeId}', '${id}');`;
            await post( query );
            return { type: 'success' };
        } else {
            error.start = true;
            error.end = true;
            return { type: 'error', error: error };
        }

    } else {
        return { type: 'error', error: error };
    }
};

export const getNonWorkingDays = async (from, to, id) => {
    const [city] = await post(`SELECT city FROM people WHERE contact_id = ${id}`);
    const holidaysPromise = post( `SELECT name, startDate as date FROM general_holidays WHERE location = '${city.city}' and startDate between '${from.format('YYYY-MM-DD')}' and '${to.format('YYYY-MM-DD')}';` );
    const absencesPromise = post(`SELECT a.description as name, a.start, a.end, a.half, a.accepted_by_team_leader, a.accepted_by_ceo, at.approvable FROM absences AS a JOIN absence_type AS at ON at.id = a.absence_type_id where a.user_id = ${id} and start >= ${from.format('x')} and end <= ${to.format('x')};`);
    const [holidays, absences] = await Promise.all([holidaysPromise, absencesPromise]);

    const nonWorkingDaysArr = holidays.map((e) => { return { name: e.name, date: moment(e.date, 'YYYY-MM-DD'), time: 8 } });
    const filteredAbsences = absences.filter( (e) => ( e.half === "0" && ((e.approvable === "0") || (e.approvable === "1" && e.accepted_by_ceo === "1")) ) )
        .map( (e) => {
            const from = moment(e.start, 'x');
            const to = moment(e.end, 'x');
            const arr = fillDatesArr(from, to, 'x', 'moment');
            return arr.map(f => {return { name: e.name, date: f, time: e.half === '1' ? 4 : 8 }});
        } );
    const arr = nonWorkingDaysArr.concat(filteredAbsences);
    return [].concat.apply([], arr);
}

export const fillDatesArr = (from, to, formatIn, formatOut) => {
    const start = formatIn === 'moment' ? moment(from) : moment(from, formatIn);
    const end = formatIn === 'moment' ? moment(to) : moment(to, formatIn);
    const diff = end.diff(start, 'days') + 1;

    const datesArray = [];
    for (let i = 0; i < diff; i++) {
        if (formatOut === 'moment') {
            datesArray.push(moment(start));
        } else {
            datesArray.push(start.format(formatOut));
        }
        start.add(1, 'days');
    }
    return datesArray;
}

export const getWeek = async (myId) => {
    const start = dayjs().startOf('isoWeek');
    const end = dayjs().endOf('isoWeek');
    
    const userDataPromise = post(`SELECT weekWorkDistribution, weekWorkTime, city, nWorkingDays FROM people WHERE contact_id = ${myId};`);
    // const absencesPromise = post(`SELECT description, start, end, accepted_by_ceo, half, absence_type_id FROM absences WHERE user_id = ${myId} AND absence_type_id != 21;`);
    const absencesPromise = post(`SELECT description, start, end, accepted_by_ceo, half, absence_type_id FROM absences a join absence_type at on a.absence_type_id = at.id WHERE user_id = ${myId} AND at.recoverable != 1;`);
    const holidaysPromise = post(`SELECT startDate as start, location as city FROM general_holidays;`);
    const tasksPromise = post(`SELECT description, start, end FROM tasks WHERE user_id = ${myId} AND phase_id != 663 and start > ${start.format('x')} and end < ${end.format('x')}`);
    const startEndDayPromise = post(`SELECT start, end from startEndDay where user_id = ${myId}`);

    const [[userData], absences, holidays, tasks, startEnd] = await Promise.all([userDataPromise, absencesPromise, holidaysPromise, tasksPromise, startEndDayPromise]);

    const holidaysDict = holidays.reduce((acc, day) => {
        const { start, city } = day;
        if (!acc.hasOwnProperty(city)) acc[city] = [];
        acc[city].push(dayjs(start, 'YYYY-MM-DD'));
        return acc;
    }, {});

    // TODO: falta roundoff auto
    let tasksDict = tasks.reduce((acc, task) => {
        const { description, start, end } = task;
        const dayStr = dayjs(parseInt(start)).format('YYYY-MM-DD');
        const taskDurationMinutes = (((parseInt(end) - parseInt(start)) / 1000) / 60);
        
        if (!acc.hasOwnProperty(dayStr)) acc[dayStr] = { duration: 0, tasks: [] };
        acc[dayStr].duration += taskDurationMinutes;
        acc[dayStr].tasks.push({ start, end, taskDurationMinutes, description });
        
        return acc;
    }, {});

    startEnd.forEach(day => {
      const { start, end } = day;
      if (start !== null && end !== null) {
        const d = start.split(' ')[0];
        if (tasksDict.hasOwnProperty(d)) {
          const roundoff = (getRoundOff(start, end) / 60000);
          tasksDict[d].duration -= roundoff;
        }
      }
    });

    let weekWorkDistribution = userData.weekWorkDistribution;
    const { weekWorkTime, city, nWorkingDays } = userData;

    if (weekWorkDistribution === null || weekWorkDistribution === undefined || weekWorkDistribution === '') {
        const day = timeConvert(weekWorkTime * 60 / parseInt(nWorkingDays));
        weekWorkDistribution = new Array(5).fill(day);
    } else {
        weekWorkDistribution = weekWorkDistribution.split('|');
    }
    
    weekWorkDistribution.push("0", "0");

    const absenceDaysArr = absences.reduce((acc, absence) => {
        acc = acc.concat(fillDatesArrDayjs(dayjs(parseInt(absence.start)), dayjs(parseInt(absence.end)), 'dayjs', 'dayjs'));
        return acc;
    }, []).concat(holidaysDict[city]);

    const datesArr = fillDatesArrDayjs(start, end, 'dayjs', 'dayjs');
    const today = dayjs();
    const predefinedTimes = weekWorkDistribution.reduce((acc, time, index) => {
        const day = datesArr[index];
        const dayStr = day.format('YYYY-MM-DD');
        const holiday = absenceDaysArr.findIndex((absence) => absence.isSame(day,'day'));

        const timeStr = (time === '0') ? '00:00' : time;
        const work = (time !== '0');
        const absence = (holiday !== -1);
        const workedTime = absence ? '00:00' : tasksDict.hasOwnProperty(dayStr) ? timeConvert(tasksDict[dayStr].duration) : '00:00';
        const blocked = day.isBefore(today, 'day');

        acc.push({ timeStr, work, absence, blocked, workedTime });

        return acc;
    }, []);

    return { distribution: Object.assign({}, weekWorkDistribution), duration: weekWorkTime, predefinedTimes, nWorkingDays };
}

const fillDatesArrDayjs = (from, to, formatIn, formatOut) => {
    const start = formatIn === 'dayjs' ? from : dayjs(from, formatIn);
    const end = formatIn === 'dayjs' ? to : dayjs(to, formatIn);
    const diff = end.diff(start, 'days') + 1;

    const datesArray = [];
    let day = start;
    for (let i = 0; i < diff; i++) {
        if (formatOut === 'dayjs') {
            datesArray.push(dayjs(day));
        } else {
            datesArray.push(day.format(formatOut));
        }
        day = day.add(1, 'days');
    }
    return datesArray;
}

export const getExpectedWorkedHours = () => (moment().isoWeekday()) * 8;

export const getTaskTimesWeek = (today, rest) => {
    const todayMoment = moment();
    const weekStart = moment().startOf('isoWeek');
    const arr = fillDatesArr(weekStart, todayMoment, 'moment', 'DD/MM/YYYY');
    let time = arr.reduce((acc, e) => {
        if (rest.hasOwnProperty(e)) {
            acc.billableMillis += rest[e].billableMillis;
            acc.nonBillableMillis += rest[e].nonBillableMillis;
        }
        return acc;
    }, { billableMillis: 0, nonBillableMillis: 0 });

    time.billableMillis += parseInt(today.billableMillis);
    time.nonBillableMillis += parseInt(today.nonBillableMillis);

    return time;
}

export const getMillisFromTasks = (from, to, tasks,
    weekTime = { 0: '08:30', 1: '08:30', 2: '08:30', 3: '08:30', 4: '06:00', 5: '04:00', 6: '04:00' } // Asumiendo 4 horas de trabajo los fines de semana
) => {

    const [fromCopy, toCopy] = [moment(from), moment(to)];
    const datesArr = fillDatesArr(fromCopy, toCopy, 'moment', 'DD/MM/YYYY');
    const time = { total: 0, billable: 0, expected: 0, roundOffMs: 0, nonBillable: 0, timeExtraPos: 0, timeExtraNeg: 0, timeExtra: 0 };

    return datesArr.reduce((acc, day) => {
        const currentDay = moment(day, 'DD/MM/YYYY');
        const weekDay = currentDay.day(); // Obtener el día de la semana
        const expectedWorkingTime = weekTime[weekDay]; // Usar el tiempo de trabajo esperado según el día de la semana
        const expectedMillis = timeStringToMinutes(expectedWorkingTime) * 60 * 1000; // Convertir a milisegundos

        if (tasks.hasOwnProperty(day) && currentDay.isSameOrBefore(moment(), 'day')) {
            const billable = tasks[day].billableMillis || 0;
            const nonBillable = tasks[day].nonBillableMillis || 0;
            const roundOffMs = tasks[day].roundOffMs || 0;

            const timeExtra = billable - expectedMillis;

            acc.total += billable + nonBillable;
            acc.billable += billable - roundOffMs;
            acc.roundOffMs += roundOffMs;
            acc.nonBillable += nonBillable + roundOffMs;

            if (timeExtra > 0) {
                acc.timeExtraPos += timeExtra;
            } else {
                acc.timeExtraNeg += timeExtra;
            }

            acc.timeExtra = acc.timeExtraPos + acc.timeExtraNeg;
        }

        return acc;
    }, time);
};

function timeStringToMinutes(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
}


export const getNumberOfWorkedDays = async (from, to, id) => {
    const [{ city, hired, weekWorkDistribution }] = await post(`SELECT city, hired, weekWorkDistribution FROM people WHERE contact_id = ${id}`);
    const holidaysPromise = post(`SELECT name, startDate as date FROM general_holidays WHERE location = '${city}';`);
    const absencesPromise = post(`SELECT a.description as name, a.start, a.end, a.half, a.accepted_by_team_leader, a.accepted_by_ceo, at.approvable, at.recoverable FROM absences AS a JOIN absence_type AS at ON at.id = a.absence_type_id where a.user_id = ${id};`);
    const [holidays, absences] = await Promise.all([holidaysPromise, absencesPromise]);

    const fromDayjs = dayjs(parseInt(from.format('x')));
    const toDayjs = dayjs(parseInt(to.format('x')));
    
    let nonWorkingDaysArr = absences
        .filter(absence => absence.half === '0' && absence.recoverable === '0')
        .flatMap(absence => {
            const { start, end } = absence;
            const startDate = dayjs(parseInt(start));
            const endDate = dayjs(parseInt(end));
            return fillDatesArrDayjs(startDate, endDate, 'dayjs', 'dayjs');
        });

    nonWorkingDaysArr = nonWorkingDaysArr.concat( holidays.map(day => dayjs(day.date, 'YYYY-MM-DD')) );
    
    let filtered = [];
    nonWorkingDaysArr.forEach(abs => {
        const absStr = abs.format('YYYY-MM-DD');
        const index = filtered.findIndex(f => f.str === absStr);
        if (index === -1) filtered.push({str: absStr, date: abs});
    });

    const hiredDate = dayjs(hired, 'YYYY-MM-DD HH:mm:ss');
    const start = fromDayjs.isBefore(hiredDate, 'day') ? hiredDate : fromDayjs;
    const daysArr = fillDatesArrDayjs(start, toDayjs, 'dayjs', 'dayjs');

    const nonWorkingDays = weekWorkDistribution.split('|').reduce((acc, day, i) => {
      if (day === '00:00') acc.push(i+1);
      return acc;
    }, []);
    nonWorkingDays.push(...[6, 0]); // fin de semana

    const numberOfWeekEndDays = daysArr.reduce((acc, day) => {
        if (nonWorkingDays.includes(day.day())) {
            acc++;
        } else {
            const index = filtered.findIndex(f => f.date.isSame(day, 'day'));
            if (index !== -1) acc++;
        }
        return acc;
    }, 0);

    const halfDaysCount = absences
        .filter(absence => absence.half === '1' && absence.recoverable ==! '0' && dayjs(parseInt(absence.start)).isBetween(fromDayjs, toDayjs, null, '[]'))
        .length * 0.5;

    const numberDays = toDayjs.diff(start, 'days') + 1;
    const totalWorkindays = numberDays - numberOfWeekEndDays - halfDaysCount;
    
    return totalWorkindays;
} 