<script>

import {getPlatform} from '../../../js/localInfo';
import {openNewWindow} from '../../../js/openNewWindow';
import { slide } from 'svelte/transition';


    let show = false;
	export let name;
    export let type;
    export let url;


    let platform = getPlatform();
	$: type = name.slice(name.lastIndexOf('.') + 1);

    function travel(){
        window.open(url, '_blank').focus();


    }
    function fadeSlide(node, options) {
		const slideTrans = slide(node, options)
		return {
			duration: options.duration,
			css: t => `
				${slideTrans.css(t)}
				opacity: ${t};
			`
		};
	}


</script>


{#if platform === "Web"}
    <div class= "file-container">
        <img src="/images/payrolls.svg" alt="" >
        <span on:click={travel} transition:fadeSlide="{{duration: 200}}">{name}</span>
    </div>
    
{:else}
    <span on:click={openNewWindow(url)}>{name}</span>
{/if}

<div>

</div>


<style>
	span {
		padding: 0 0 0 0.5em;
		background: 0 0.1em no-repeat;
		background-size: 1em 1em;
        cursor: pointer;
	}

    img{
        width: 20px;
        margin-left: 20px;
        }
</style>