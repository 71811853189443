<script>
  export let project;
  export let startDate;
  export let endDate;
  export let dayArray;
  export let dayWidth;
  export let stickyPhase;
  export let stickyStart;
  export let stickyEnd;
  export let stickyPeople;
  export let showStartColumn;
  export let showEndColumn;
  export let showTeamColumn;
  export let showSubphases;
  export let editingPlan;
  export let onClickPhase;
  export let onClickMilestone;
  export let onClickNewPhase;
  export let showPlannerTasks;
  export let showMilestones;
  export let CRPid;
  export let loaded;
  export let drawPhases;
  export let view;
  export let fixed;
  import moment from 'moment';

  import CompleteResourcePlannerMilestone from "./CompleteResourcePlannerMilestone.svelte";
  import CompleteResourcePlannerPhase from "./CompleteResourcePlannerPhase.svelte";
  import CompleteResourcePlannerSubphase from "./CompleteResourcePlannerSubphase.svelte";

  import { openNewWindow } from "../../js/openNewWindow";
  import { openURL } from "../../js/openURL";
  import { loadCalendar } from "../../js/loadCalendar";
  import { selectedProjectId } from "../../js/stores";

  let length = -1;
  let daysLength = -1;
  let days;

  const PHASE_WIDTH = 330;
  const START_WIDTH = 70;
  const END_WIDTH = 70;
  const PEOPLE_WIDTH = 130;

  let prepareProject = () => {
    daysLength =
      moment(endDate, "YYYY-MM-DD").diff(
        moment(startDate, "YYYY-MM-DD"),
        "days"
      ) + 1;
    length = PHASE_WIDTH;
    if (showStartColumn) {
      length += START_WIDTH;
    }
    if (showEndColumn) {
      length += END_WIDTH;
    }
    if (showTeamColumn) {
      length += PEOPLE_WIDTH;
    }
  };

  $: if (
    project &&
    showStartColumn !== null &&
    showEndColumn !== null &&
    showTeamColumn !== null
  ) {
    prepareProject();
  }
</script>

{#if project}
  <div style={view === "project" ? "" : "display: none;"} class="project {fixed ? 'fixed' : ''}" >
    <div class="projectInfo borderLeft borderRight borderBottom" style="width: {length}px" >
      <div class="name">
        {#if project.pic !== ""}
          <img class="projectImg" src={project.pic} alt="Project" />
        {/if}
        <div class="nameInfo">
          <span>{project.code.toUpperCase() + " - " + project.name.toUpperCase()}</span>
          <div class="nameInfoIcons">
            <img
              class="fadedButton"
              src="/images/factory-blue.svg"
              alt="Project Button"
              on:click={() => {
                selectedProjectId.set(project.id);
                localStorage.setItem('selectedProjectId', project.id);
                openURL("/dashboard-project", true);
              }}
              on:contextmenu={(ev) => {
                ev.stopPropagation();
                openNewWindow("/skylab-main/dashboard-project/" + project.id);
                return false;
              }}
            />
            <img
              class="fadedButton"
              src="/images/calendar.svg"
              alt="Calendar Button"
              on:click={() => {
                loadCalendar(project.id);
                openURL("/calendar", true);
              }}
              on:contextmenu={(ev) => {
                ev.stopPropagation();
                loadCalendar(project.id);
                openNewWindow("/skylab-main/calendar");
                return false;
              }}
            />
          </div>
        </div>
      </div>
    </div>
    {#each dayArray as day}
      <CompleteResourcePlannerMilestone
        {project}
        {day}
        {dayWidth}
        milestones={project.milestones}
        {editingPlan}
        onClick={onClickMilestone}
        {showMilestones}
      />
    {/each}
  </div>
  {#each project.phases as phase}
    <CompleteResourcePlannerPhase
      {project}
      {phase}
      {daysLength}
      {dayArray}
      {dayWidth}
      {stickyPhase}
      {stickyStart}
      {stickyEnd}
      {stickyPeople}
      {showStartColumn}
      {showEndColumn}
      {showTeamColumn}
      {editingPlan}
      {onClickPhase}
      {startDate}
      {endDate}
      {showPlannerTasks}
      {CRPid}
      {loaded}
      {drawPhases}
      {view}
    />
    {#if showSubphases}
      {#each phase.subphases as subphase}
        <CompleteResourcePlannerSubphase
          {project}
          {phase}
          {subphase}
          {dayArray}
          {dayWidth}
          {stickyPhase}
          {stickyStart}
          {stickyEnd}
          {stickyPeople}
          {showStartColumn}
          {showEndColumn}
          {showTeamColumn}
          {editingPlan}
          onClickSubphase={onClickPhase}
          {startDate}
          {endDate}
          {showPlannerTasks}
          {CRPid}
          {loaded}
          {drawPhases}
          {view}
        />
      {/each}
    {/if}
  {/each}
  {#if editingPlan}
    <div class="newPhase" style={view === "project" ? "" : "display: none;"}>
      <div
        class="newPhaseImg borderRight borderBottom borderLeft"
        style="width: {length}px"
      >
        <img
          class="fadedButton"
          src="/images/plus.svg"
          alt="Add Phase"
          on:click={(ev) => onClickNewPhase(true, ev.pageX, ev.pageY, project)}
        />
      </div>
      <div
        class="newPhaseRemaining borderRight borderBottom"
        style="width: {dayWidth * dayArray.length}px"
      />
    </div>
  {/if}
{/if}

<style>
  div.fixed {
    position: sticky;
    top: 75px;
    z-index: 3;
  }

  div.project {
    display: flex;
    background-color: white;
    width: fit-content;
  }

  div.projectInfo {
    flex-shrink: 0;
    flex-grow: 0;
    position: sticky;
    left: 0px;
  }

  div.name {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
    background-color: white;
    display: flex;
  }

  img.projectImg {
    height: 50px;
    margin-right: 10px;
    border-radius: 5px;
  }

  div.newPhase {
    height: 25px;
    background-color: white;
    display: flex;
    align-items: center;
    width: fit-content;
  }

  div.newPhaseImg {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    position: sticky;
    left: 0px;
    height: 25px;
  }

  div.newPhase img {
    width: 14px;
    height: 14px;
    margin-left: 10px;
  }

  div.nameInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  div.nameInfoIcons {
    display: flex;
  }

  div.nameInfoIcons img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  div.nameInfo span {
    margin-bottom: 3px;
  }

  div.newPhaseRemaining {
    height: 25px;
  }
</style>
