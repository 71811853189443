<script>
  export let image;
  export let numImage;
  export let numImages;
  export let onClick;
  export let removingFile;
  export let onRemove;
  export let action;

  let isTopLeft = false;
  let isTopRight = false;
  let isBottomLeft = false;
  let isBottomRight = false;

  $: {
    isTopLeft = numImage === 0;
    isTopRight = numImage === 2;
    let mod = numImages % 3;
    let last = -1;
    switch (mod) {
      case 0:
        last = numImages - 1;
        break;
      case 1:
        last = numImages + 1;
        break;
      case 2:
        last = numImages;
        break;
    }
    isBottomLeft = numImage === last - 2;
    isBottomRight = numImage === last;
  }
</script>

{#if !image}
  <div
    class="dialogProjectImageContainer noimage {isTopLeft
      ? 'topleft'
      : ''}
    {isTopRight ? 'topright' : ''}
    {isBottomLeft
      ? 'bottomleft'
      : ''}
    {isBottomRight ? 'bottomright' : ''}"
  />
{:else}
  <div class="imgDiv">
    <div
      class="dialogProjectImageContainer image {isTopLeft
        ? 'topleft'
        : ''}
      {isTopRight ? 'topright' : ''}
      {isBottomLeft
        ? 'bottomleft'
        : ''}
      {isBottomRight ? 'bottomright' : ''}"
      style="background-image: url('{image.url}');"
      on:click={onClick}
    />
    {#if action !== "show"}
      <img
        src={removingFile === image.url
          ? "/images/trash-red.svg"
          : "/images/trash.svg"}
        alt="Remove"
        on:click={onRemove}
      />
    {/if}
  </div>
{/if}

<style>
  div.dialogProjectImageContainer {
    width: 120px;
    height: 120px;
    margin-right: 1px;
    margin-bottom: 1px;
  }

  div.image {
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  div.noimage {
    background-color: var(--labit-background-gray);
  }

  div.topleft {
    border-top-left-radius: 10px;
  }

  div.topright {
    border-top-right-radius: 10px;
  }

  div.bottomleft {
    border-bottom-left-radius: 10px;
  }

  div.bottomright {
    border-bottom-right-radius: 10px;
  }

  div.imgDiv {
    position: relative;
  }

  img {
    position: absolute;
    height: 17px;
    cursor: pointer;
    bottom: 5px;
    right: 5px;
  }

  /* Animations */

  div.dialogProjectImageContainer {
    transition: opacity 0.5s linear 0s;
  }

  div.dialogProjectImageContainer:hover {
    opacity: 0.5;
  }

  img {
    transition: opacity 0.5s linear 0s;
  }

  img:hover {
    opacity: 0.5;
  }
</style>
