<script>
	import File from './File.svelte';
    import { slide } from 'svelte/transition';

	export let expanded = false;
	export let name;
	export let files;
    export let type;

	let negrita = false;

	function toggle() {
        expanded = !expanded;
		negrita = !negrita;
        
	}
    function fadeSlide(node, options) {
		const slideTrans = slide(node, options)
		return {
			duration: options.duration,
			css: t => `
				${slideTrans.css(t)}
				opacity: ${t};
			`
		};
	}

   
    
</script>
{#if type === 'folder'}
    <div id="bar1" class:expanded >
        <span class={name} class:negrita on:click={toggle} transition:fadeSlide="{{duration: 400}}">{name}</span>
    </div>
{:else}
    <span class="archivo" transition:fadeSlide="{{duration: 500}}">{name}</span>
{/if}

{#if expanded}
	<ul>
		{#each files as file}
			<li>
				{#if file.type === 'folder'} 
					<svelte:self name={file.name} files = {file.content} type = {file.type}/>
				{:else}
					<File name = {file.name} type = {file.type} url = {file.webUrl}/>
				{/if}
			</li>
		{/each}
	</ul>
{/if}

<style>
	span {
		padding: 10px 1.5em;
		background-size: 100%;
		font-weight:300;
        font-size:large;
		cursor: pointer;
	}


	.expanded {
        font-weight: bold;
        border-radius: 15px;
        background-color: #F5F5F5;
	}

	.negrita{
		font-weight: bold;
	}

    .SHAREPOINT {
        font-weight: bold;
    }

	ul {
		padding: 0.2em 0 0 0.5em;
		margin: 0 0 0 0.5em;
		list-style: none;
		border-left: 1px solid #eee;
	}

	li {
		padding: 0.2em 0;
	}

    #bar1 {
        border-radius: 15px;
        padding: 10px 1.5em 10px 0;
    }
</style>
