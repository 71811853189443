<script>
  import { onMount } from "svelte";
  import { post } from "../../js/labit-connection";
  import HeaderElement from "./components/HeaderElement.svelte";
  import Picture from "../../components/Picture.svelte";
  import TextEdit from "../../components/TextEdit.svelte";
  import SubmenuMultipleSelect from "../../components/SubmenuMultipleSelect.svelte";
  import SubmenuButton from "../../components/SubmenuButton.svelte";

  import { removeAccents } from "../../js/removeAccents";
  import { openURL } from "../../js/openURL";
  import { openNewWindow } from "../../js/openNewWindow";

  import { refreshLicenses } from "../../js/stores.js";
  import moment from 'moment';

  const unsubscribe = refreshLicenses.subscribe((value) => {
    if (value) {
      loadPage();
      refreshLicenses.set(false);
    }
  });

  let companiesList = [];
  let productsList = [];
  let keysList = [];
  let suscriptionsList = [];
  let assignedLicenses = [];

  let companyOptions = [];
  let selectedCompany = [];
  let productOptions = [];
  let selectedProduct = [];
  let keyOptions = [];
  let selectedKey = [];
  let peopleOptions = [];
  let selectedPeople = [];
  let projectOptions = [];
  let selectedProject = [];
  let searchFilter = "";

  onMount(() => {
    loadPage();
  });

  let loadPage = async () => {
    companiesList = [];
    productsList = [];
    keysList = [];
    suscriptionsList = [];
    assignedLicenses = [];

    companyOptions = [];
    selectedCompany = [];
    productOptions = [];
    selectedProduct = [];
    keyOptions = [];
    selectedKey = [];
    peopleOptions = [];
    selectedPeople = [];
    projectOptions = [];
    selectedProject = [];
    searchFilter = "";

    await loadCompanies();
    await loadProducts();
    await loadKeys();
    await loadSuscriptions();
    await loadAssignments();
  };

  let loadCompanies = async () => {
    companiesList = await post(
      `SELECT id, name, pictureURL FROM licenseCompanies`
    );
    companiesList.forEach((item) => {
      item.visible = true;
      item.search = removeAccents(item.name).toUpperCase();
    });
    companiesList = companiesList;

    companyOptions = companiesList.map((license) => {
      return {
        value: license.id,
        label: license.name,
      };
    });
    selectedCompany = [];
  };

  let loadProducts = async () => {
    productsList = await post(
      `SELECT id, name, pictureURL, company FROM licenseProducts`
    );

    productsList.forEach((item) => {
      item.visible = true;
      item.search = removeAccents(item.name).toUpperCase();
    });
    productsList = productsList;

    productOptions = productsList.map((product) => {
      return {
        value: product.id,
        label: product.name,
      };
    });
    selectedProduct = [];
  };

  let loadKeys = async () => {
    keysList = await post(
      `SELECT id, username, passwd, company, active FROM licenseKeys`
    );

    keysList = keysList.map((key) => {
      key.active = key.active === "1" ? true : false;
      return key;
    });

    keysList.forEach((item) => {
      item.visible = true;
    });
    keysList = keysList;

    keyOptions = keysList.map((key) => {
      return {
        value: key.id,
        label: key.username,
      };
    });
    selectedKey = [];
  };

  let loadSuscriptions = async () => {
    suscriptionsList =
      await post(`SELECT ls.id, ls.contractNumber, ls.seats, ls.type, ls.term, ls.expiringDate, ls.renewalComments, ls.renewalStatus, ls.product, ls.license, username, passwd, lc.id as companyId
FROM licenseSuscriptions AS ls
LEFT JOIN licenseKeys AS lk ON lk.id=ls.license
LEFT JOIN licenseCompanies AS lc ON lc.id=lk.company`);
    suscriptionsList = suscriptionsList.map((suscription) => {
      suscription.expiringDate = moment(
        suscription.expiringDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
      return suscription;
    });

    suscriptionsList.forEach((item) => {
      item.visible = true;
      item.search = removeAccents(item.username).toUpperCase();
    });
    suscriptionsList = suscriptionsList;
  };

  let loadAssignments = async () => {
    assignedLicenses =
      await post(`SELECT assign.id AS id, company.id AS company, product.id AS product, suscription.id AS suscription, project.Name AS projectName, people.Name AS username, people.PictureURL AS pic, assign.date AS datetime, people.lastActivity, people.atHome, profileStatus.description AS profileStatusDescription, profileStatus.iconImg AS profileStatusIcon
      FROM licenseAssignments AS assign
      LEFT JOIN licenseSuscriptions AS suscription ON suscription.id = assign.suscription
      LEFT JOIN people ON people.contact_id = assign.contact
      LEFT JOIN profileStatus ON profileStatus.id = people.profileStatus
      LEFT JOIN projects AS project ON project.project_id = assign.project
      LEFT JOIN licenseProducts AS product ON product.id = suscription.product
      LEFT JOIN licenseKeys AS license ON license.id = suscription.license
      LEFT JOIN licenseCompanies AS company ON product.company = company.id`);

    assignedLicenses = assignedLicenses.map((li) => {
      li.officeStatus = {
        description: li.profileStatusDescription,
        icon: li.profileStatusIcon,
      };
      li.visible = true;
      return li;
    });

    assignedLicenses.forEach((as) => {
      as.search =
        removeAccents(as.username).toUpperCase() +
        " " +
        removeAccents(as.projectName).toUpperCase();
    });

    assignedLicenses = assignedLicenses;

    peopleOptions = [];
    assignedLicenses.forEach((as) => {
      const pos = peopleOptions.findIndex((item) => {
        return item.value === as.username;
      });
      if (pos === -1) {
        peopleOptions.push({
          value: as.username,
          label: as.username,
        });
      }
    });
    peopleOptions = peopleOptions;
    selectedPeople = [];

    projectOptions = [];
    assignedLicenses.forEach((as) => {
      const pos = projectOptions.findIndex((item) => {
        return item.value === as.projectName;
      });
      if (pos === -1) {
        projectOptions.push({
          value: as.projectName,
          label: as.projectName,
        });
      }
    });
    projectOptions = projectOptions;
    selectedProject = [];
  };

  let clearAssignedLicense = async (assigned, index) => {
    assignedLicenses.splice(index, 1);
    assignedLicenses = assignedLicenses;
    await post(`DELETE FROM licenseAssignments WHERE id=${assigned.id}`);
  };

  let clearAllAsignedLicenses = async (company) => {
    assignedLicenses = assignedLicenses.filter((l) => {
      return l.company !== company.id;
    });
    assignedLicenses = assignedLicenses;
    let assignmentsCompany =
      await post(`select company.id as companyId, assignment.id as assignmentId
      from licenseAssignments as assignment
      left join licenseSuscriptions as suscription on suscription.id = assignment.suscription
      left join licenseProducts as product on suscription.product = product.id
      left join licenseCompanies as company on product.company = company.id`);
    assignmentsCompany = assignmentsCompany.filter((s) => {
      return s.companyId === company.id;
    });

    for (let i = 0; i < assignmentsCompany.length; i++) {
      const assignment = assignmentsCompany[i].assignmentId;
      await post(`DELETE FROM licenseAssignments WHERE id=${assignment}`);
    }
  };

  let applyFilter_ = () => {
    suscriptionsList.forEach((s) => {
      if (s.visible) {
        const as = assignedLicenses.reduce((acc, curr) => {
          if (curr.suscription === s.id) {
            acc.push(curr);
          }
          return acc;
        }, []);
        const num = as.reduce((acc, curr) => {
          if (!curr.visible) {
            acc = acc + 1;
          }
          return acc;
        }, 0);
        if (num === as.length) {
          s.visible = false;
        }
      }
    });

    productsList.forEach((p) => {
      if (p.visible) {
        const su = suscriptionsList.reduce((acc, curr) => {
          if (curr.product === p.id) {
            acc.push(curr);
          }
          return acc;
        }, []);
        const num = su.reduce((acc, curr) => {
          if (!curr.visible) {
            acc = acc + 1;
          }
          return acc;
        }, 0);
        if (num === su.length) {
          p.visible = false;
        }
      }
    });

    companiesList.forEach((c) => {
      if (c.visible) {
        const pr = productsList.reduce((acc, curr) => {
          if (curr.company === c.id) {
            acc.push(curr);
          }
          return acc;
        }, []);
        const num = pr.reduce((acc, curr) => {
          if (!curr.visible) {
            acc = acc + 1;
          }
          return acc;
        }, 0);
        if (num === pr.length) {
          c.visible = false;
        }
      }
    });

    suscriptionsList = suscriptionsList;
    keysList = keysList;
    productsList = productsList;
    companiesList = companiesList;
  };

  let _applyFilter_ = () => {
    /*suscriptionsList.forEach((s) => {
      if (s.visible) {
        const as = assignedLicenses.reduce((acc, curr) => {
          if (curr.suscription === s.id) {
            acc.push(curr);
          }
          return acc;
        }, []);
        const num = as.reduce((acc, curr) => {
          if (!curr.visible) {
            acc = acc + 1;
          }
          return acc;
        }, 0);
        if (num === as.length) {
          s.visible = false;
        }
      }
    });

    productsList.forEach((p) => {
      if (p.visible) {
        const su = suscriptionsList.reduce((acc, curr) => {
          if (curr.product === p.id) {
            acc.push(curr);
          }
          return acc;
        }, []);
        const num = su.reduce((acc, curr) => {
          if (!curr.visible) {
            acc = acc + 1;
          }
          return acc;
        }, 0);
        if (num === su.length) {
          p.visible = false;
        }
      }
    });*/

    suscriptionsList.forEach((s) => {
      if (!s.visible) {
        s.visible = assignedLicenses.reduce((acc, curr) => {
          if (curr.suscription === s.id && curr.visible) {
            acc = true;
          }
          return acc;
        }, false);
      }
    });

    productsList.forEach((p) => {
      if (!p.visible) {
        p.visible = suscriptionsList.reduce((acc, curr) => {
          if (curr.product === p.id && curr.visible) {
            acc = true;
          }
          return acc;
        }, false);
      }
    });

    companiesList.forEach((c) => {
      if (!c.visible) {
        c.visible = productsList.reduce((acc, curr) => {
          if (curr.company === c.id && curr.visible) {
            acc = true;
          }
          return acc;
        }, false);
      }
    });

    //

    productsList.forEach((p) => {
      const c = companiesList.find((item) => {
        return item.id === p.company;
      });
      if (c && c.visible) {
        p.visible = true;
      }
    });

    suscriptionsList.forEach((s) => {
      const p = productsList.find((item) => {
        return item.id === s.product;
      });
      if (p && p.visible) {
        s.visible = true;
      }
    });

    assignedLicenses.forEach((a) => {
      const s = suscriptionsList.find((item) => {
        return item.id === a.suscription;
      });
      if (s && s.visible) {
        a.visible = true;
      }
    });

    suscriptionsList = suscriptionsList;
    keysList = keysList;
    productsList = productsList;
    companiesList = companiesList;
  };

  let applyFilter = () => {
    if (searchFilter !== "") {
      companiesList.forEach((license) => {
        license.visible = false;
      });
      productsList.forEach((product) => {
        product.visible = false;
      });
      /*keysList.forEach((key) => {
      key.visible = true;
    });*/
      suscriptionsList.forEach((suscription) => {
        suscription.visible = false;
      });
      assignedLicenses.forEach((as) => {
        as.visible = false;
      });

      let SearchFilter = removeAccents(searchFilter);
      SearchFilter = SearchFilter.toUpperCase();

      companiesList.forEach((item) => {
        if (!item.visible) {
          if (item.search.includes(SearchFilter)) {
            item.visible = true;
          }
        }
      });

      productsList.forEach((item) => {
        if (!item.visible) {
          if (item.search.includes(SearchFilter)) {
            item.visible = true;
          }
        }
      });

      suscriptionsList.forEach((item) => {
        if (!item.visible) {
          if (item.search.includes(SearchFilter)) {
            item.visible = true;
          }
        }
      });

      /*assignedLicenses.forEach((item) => {
        if (!item.visible) {
          if (item.search.includes(SearchFilter)) {
            item.visible = true;
          }
        }
      });*/

      _applyFilter_();
    } else {
      companiesList.forEach((license) => {
        license.visible = true;
      });
      productsList.forEach((product) => {
        product.visible = true;
      });
      /*keysList.forEach((key) => {
      key.visible = true;
    });*/
      suscriptionsList.forEach((suscription) => {
        suscription.visible = true;
      });
      assignedLicenses.forEach((as) => {
        as.visible = true;
      });

      if (selectedCompany && selectedCompany.length > 0) {
        companiesList.forEach((comp) => {
          if (comp.visible) {
            const pos = selectedCompany.findIndex((c) => {
              return c === comp.id;
            });
            if (pos === -1) {
              comp.visible = false;
            }
          }
        });
      }

      if (selectedProduct && selectedProduct.length > 0) {
        productsList.forEach((prod) => {
          if (prod.visible) {
            const pos = selectedProduct.findIndex((c) => {
              return c === prod.id;
            });
            if (pos === -1) {
              prod.visible = false;
            }
          }
        });

        applyFilter_();
      }

      if (selectedKey && selectedKey.length > 0) {
        suscriptionsList.forEach((sus) => {
          const pos = selectedKey.findIndex((k) => {
            return k === sus.license;
          });
          sus.visible = pos >= 0;
        });

        applyFilter_();
      }

      if (selectedPeople && selectedPeople.length > 0) {
        assignedLicenses.forEach((as) => {
          if (as.visible) {
            const pos = selectedPeople.findIndex((c) => {
              return c === as.username;
            });
            if (pos === -1) {
              as.visible = false;
            }
          }
        });

        applyFilter_();
      }

      if (selectedProject && selectedProject.length > 0) {
        assignedLicenses.forEach((as) => {
          if (as.visible) {
            const pos = selectedProject.findIndex((c) => {
              return c === as.projectName;
            });
            if (pos === -1) {
              as.visible = false;
            }
          }
        });

        applyFilter_();
      }
    }

    companiesList = companiesList;
    productsList = productsList;
    keysList = keysList;
    suscriptionsList = suscriptionsList;
    assignedLicenses = assignedLicenses;
  };

  let clearFilters = () => {
    selectedCompany = [];
    selectedProduct = [];
    selectedKey = [];
    selectedPeople = [];
    selectedProject = [];
    searchFilter = "";
  };

  $: if (
    selectedCompany ||
    selectedProduct ||
    selectedKey ||
    selectedPeople ||
    selectedProject ||
    searchFilter
  ) {
    applyFilter();
  }
</script>

<div class="content">
  <!-- Menu Options -->

  <div class="optionsMenu">
    <div class="optionsRows">
      <div class="optionsRow">
        <div class="optionsColumn1">
          <div class="optionsSearch">
            <span>Search</span>
            <TextEdit
              placeholder="type here..."
              bind:value={searchFilter}
              maxlength="150"
              img="/images/search.svg"
            />
          </div>
        </div>
        <div class="optionsColumn2">
          <SubmenuButton
            text="Clear all"
            img="/images/x.svg"
            onClick={() => clearFilters()}
          />
        </div>
      </div>
      <div class="optionsRow lastRow">
        <div class="optionsColumn1">
          <SubmenuMultipleSelect
            text="Company"
            options={companyOptions}
            bind:selected={selectedCompany}
            width="210px"
          />
          <SubmenuMultipleSelect
            text="Product"
            options={productOptions}
            bind:selected={selectedProduct}
            width="220px"
          />
          <SubmenuMultipleSelect
            text="Account"
            options={keyOptions}
            bind:selected={selectedKey}
            width="220px"
          />
          <SubmenuMultipleSelect
            text="People"
            options={peopleOptions}
            bind:selected={selectedPeople}
            width="220px"
          />
          <SubmenuMultipleSelect
            text="Project"
            options={projectOptions}
            bind:selected={selectedProject}
            width="220px"
          />
        </div>
        <div class="optionsColumn2">
          <SubmenuButton
            text=""
            img="/images/key.svg"
            onClick={() => {
              openURL("/license-manager", true);
            }}
            onRightClick={() => {
              openNewWindow("/skylab-main/license-manager");
            }}
            bigIcon={true}
          />
        </div>
      </div>
    </div>
  </div>

  {#each companiesList as company}
    {#if company.visible}
      <div class="companyHeader">
        <img class="companyLogo" src={company.pictureURL} alt="Company Logo" />
        <span class="companyName">{company.name}</span>
      </div>
      <div class="assignedLicensesContainer">
        <div class="assignedLicensesHeaderInfo">
          <div class="infoLeft">
            <HeaderElement text="PRODUCT" />
          </div>
          <div class="infoRight">
            <HeaderElement
              text="ACCOUNT"
              icon="/images/menu-icons/logo-user.svg"
              width="200px"
            />
            <HeaderElement text="STATUS" width="200px" />
            <HeaderElement
              text="USER"
              icon="/images/menu-icons/logo-user.svg"
              width="300px"
              extra={true}
            />
            <HeaderElement
              text="PROJECT"
              icon="/images/menu-icons/logo-projects.svg"
              width="300px"
              extra={true}
            />
            <HeaderElement
              text="CLEAR ALL"
              icon="/images/clear_all_dark.svg"
              width="150px"
              onClick={async () => {
                await clearAllAsignedLicenses(company);
              }}
              hidden={selectedCompany.length > 0 ||
                selectedProduct.length > 0 ||
                selectedKey.length > 0 ||
                selectedPeople.length > 0 ||
                selectedProject.length > 0 ||
                searchFilter !== ""}
            />
            <HeaderElement
              text="DATE"
              icon="/images/menu-icons/logo-calendar.svg"
              width="150px"
            />
          </div>
        </div>
        {#each productsList.filter((e) => {
          return e.company === company.id;
        }) as product}
          {#if assignedLicenses.find((l) => {
            return l.product === product.id;
          })}
            {#if product.visible}
              <div class="assignedLicensesDataInfo">
                <div class="licenseProductContainer">
                  <img
                    src={product.pictureURL}
                    alt="Product logo"
                    class="productLogo"
                  />
                  <span class="productName">{product.name}</span>
                </div>
                <div class="licenseSuscriptions">
                  {#each suscriptionsList.filter((e) => {
                    return e.product === product.id;
                  }) as suscription}
                    {#if suscription.product === product.id && suscription.visible && assignedLicenses.find( (l) => {
                          return l.suscription === suscription.id;
                        } )}
                      <div class="licenseSuscriptionContainer">
                        <div class="suscriptionAccount">
                          <span class="suscriptionAccountName"
                            >{suscription.contractNumber}</span
                          >
                          <span class="suscriptionAccountKey"
                            >{keysList
                              .filter((e) => {
                                return e.company === company.id;
                              })
                              .find((k) => {
                                return k.id === suscription.license;
                              }).username}</span
                          >
                        </div>
                        <div class="suscriptionAccountStatus">
                          {#if suscription.renewalStatus === "Active"}
                            <div
                              class="circle"
                              style="background-color: var(--labit-button-green);"
                            />
                          {/if}
                          {#if suscription.renewalStatus === "InProcess"}
                            <div
                              class="circle"
                              style="background-color: rgb(224, 235, 73);"
                            />
                          {/if}
                          {#if suscription.renewalStatus === "Expired"}
                            <div
                              class="circle"
                              style="background-color: gray"
                            />
                          {/if}
                          <span
                            >{suscription.renewalStatus === "InProcess"
                              ? "In Process"
                              : suscription.renewalStatus}</span
                          >
                        </div>
                        <div class="containerList">
                          {#each assignedLicenses as assigned, index}
                            {#if assigned.suscription === suscription.id && assigned.visible}
                              <div class="suscriptionDataInfo">
                                <div class="keyUserInfo">
                                  <div class="contactPic">
                                    <Picture
                                      image={assigned.pic}
                                      name={assigned.username}
                                      lastActivity={assigned.lastActivity}
                                      officeStatus={assigned.officeStatus}
                                      atHome={assigned.atHome}
                                      small={true}
                                      showHint={true}
                                    />
                                  </div>
                                  <span class="keyUserInfoName"
                                    >{assigned.username}</span
                                  >
                                </div>
                                <div class="keySuscriptions">
                                  <div class="suscriptionContainerInfo">
                                    <div class="field">
                                      <span>{assigned.projectName}</span>
                                    </div>
                                    <div class="fieldSmall">
                                      <img
                                        src="/images/clear_all_red.svg"
                                        alt="Clear All"
                                        on:click={async () => {
                                          await clearAssignedLicense(
                                            assigned,
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                    <div class="fieldSmall">
                                      <!-- svelte-ignore missing-declaration -->
                                      <span
                                        >{moment(
                                          assigned.datetime,
                                          "YYYY-MM-DD"
                                        ).format("DD/MM/YYYY")}</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            {/if}
                          {/each}
                        </div>
                      </div>
                    {/if}
                  {/each}
                </div>
              </div>
            {/if}
          {/if}
        {/each}
      </div>
    {/if}
  {/each}
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .companyHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    padding: 20px;
  }

  .assignedLicensesContainer {
    width: 100%;
    padding: 20px;
    background-color: var(--labit-white);
    border-radius: 14px;
  }

  .companyName {
    font-size: 22px;
    color: #646464;
    font-weight: bold;
  }

  .companyLogo {
    width: 30px;
    margin-right: 20px;
  }

  .assignedLicensesHeaderInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .assignedLicensesDataInfo {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }

  .productLogo {
    width: 20px;
    margin-right: 10px;
  }

  .licenseProductContainer {
    width: 260px;
    border-radius: 14px;
    background-color: var(--labit-dialog-background);
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
  }

  .licenseSuscriptions {
    display: flex;
    flex-direction: column;
    width: 1320px;
  }

  .licenseSuscriptionContainer {
    width: 100%;
    border: 1px solid var(--labit-card-lightgray);
    border-radius: 14px;
    padding: 10px 0 10px 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }

  .licenseSuscriptionContainer:last-child {
    margin-bottom: 0px !important;
  }

  .keyUserInfo {
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    padding-left: 50px;
  }

  /*   .keyUserInfoPic {
    width: 21px;
    border-radius: 50%;
  } */

  .keyUserInfoName {
    margin-left: 10px;
  }

  .suscriptionContainerInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .suscriptionContainerInfo img {
    width: 18px;
    cursor: pointer;
  }

  .keySuscriptions {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .suscriptionAccount,
  .suscriptionAccountStatus {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .suscriptionAccountStatus {
    flex-direction: row;
  }

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .containerList {
    width: 900px;
    display: flex;
    flex-direction: column;
  }

  .suscriptionDataInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 5px 0 5px 0;
  }

  .productName,
  .suscriptionAccountName,
  .suscriptionAccountKey,
  .suscriptionAccountStatus span,
  .keyUserInfoName,
  .suscriptionContainerInfo span {
    font-size: 13px;
  }

  .suscriptionAccountName {
    font-weight: bold;
  }

  .field {
    width: 300px;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-start;
    padding-left: 50px;
  }

  .fieldSmall {
    width: 150px;
    display: flex;
    justify-content: center;
  }

  .infoLeft,
  .infoRight {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .infoLeft {
    width: 260px;
  }
  .infoRight {
    width: 1320px;
  }

  .assignedLicensesContainer:last-child {
    margin-bottom: 20px;
  }

  .contactPic {
    width: 40px;
    height: 40px;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin: 20px 0px 20px 0px;
    color: var(--labit-darkgray);
  }

  div.optionsRows {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    padding-right: 13px;
    justify-content: space-between;
  }

  div.optionsColumn2 {
    display: flex;
  }

  div.optionsSearch {
    display: flex;
    flex-grow: 1;
    height: 21px;
    align-items: center;
  }

  div.optionsSearch span {
    margin-right: 13px;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  div.plusButton {
    width: 122px;
    background-color: var(--labit-dialog-background);
    border-radius: 10px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  div.plusButton img {
    width: 30px;
    height: 30px;
  }
</style>
