<script>
  export let visible;

  import Menu from "../components/Menu.svelte";
  import ResourceOption from "../components/ResourceOption.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  import PreviewRemoteFile from "../components/PreviewRemoteFile.svelte";
  //import ContextMenu from "../components/ContextMenu.svelte";
  import FileUploader from "../components/FileUploader.svelte";
  import FileUploaderGeneric from "../components/FileUploaderGeneric.svelte";
  import FormTextEdit from "../components/FormTextEdit.svelte";
  import Button from "../components/Button.svelte";
  import Combobox from "../components/Combobox.svelte";

  import { getPlatform, getToken, getRole } from "../js/localInfo";
  import { post, rpost, LABIT_PUBLIC_FOLDER } from "../js/labit-connection";

  import { openNewWindow } from "../js/openNewWindow";

  //import { guidelines, guidelineIcons } from "../data/guidelines";

  import { onMount } from "svelte";

  const PAGE = "GUIDELINES";

  const GUIDELINES_REMOTE_URL = "/guidelines";

  let platform = getPlatform();
  let token = getToken();
  let myRole = getRole();

  let guidelines = [];

  let showNavMenu = null;

  let selectedIndex = -1;

  let managingTools = false;
  let selectedTool = null;

  const fileTypeOptions = [
    {
      label: "PDF",
      value: "pdf",
    },
    {
      label: "URL",
      value: "url",
    },
  ];

  onMount(async () => {
    let response = await post(
      `select id, name, icon, url, type
        from guidelines
        order by id asc`
    );

    guidelines = response.map((guideline) => {
      return {
        id: guideline.id,
        name: guideline.name,
        icon: guideline.icon,
        url: guideline.url,
        type: guideline.type,
        remoteFile: "",
      };
    });

    clickResource(0);
  });

  let setUrl = async (index) => {
    //guidelinesSel = btoa(unescape(encodeURIComponent(guidelines[index].url)));
    let remoteFile = await rpost("ListFolder", {
      path: GUIDELINES_REMOTE_URL + "/" + guidelines[index].id,
    });
    guidelines[index].remoteFile = btoa(
      unescape(
        encodeURIComponent(
          GUIDELINES_REMOTE_URL +
            "/" +
            guidelines[index].id +
            "/" +
            remoteFile[0].name
        )
      )
    );
    selectedIndex = index;
    //console.log(remoteFile);
  };

  let clickResource = async (index) => {
    if (guidelines[index].type === "pdf") {
      if (guidelines[index].remoteFile === "") {
        await setUrl(index);
      }
      selectedIndex = index;
    } else {
      if (platform === "APP") {
        selectedIndex = index;
      } else {
        window.open(guidelines[index].url);
      }
    }
  };

  let openLibraries = (index) => {
    if (guidelines[index].type === "pdf") {
      openNewWindow("/skylab-main/guidelines");
    } else {
      if (platform === "Web") {
        window.open(guidelines[index].url);
      } else {
        openNewWindow(guidelines[index].url);
      }
    }
  };

  let showToolForm = (show) => {
    managingTools = show;
    if (show) {
      selectedTool = {
        id: -1,
        type: "PDF",
        url: "",
        icon: "",
        type: fileTypeOptions[0],
        custom: true,
        image: null,
        file: null,
      };
    }
  };

  let toggleToolForm = () => {
    if (managingTools) {
      showToolForm(false);
    } else {
      showToolForm(true);
    }
  };

  let addTool = async () => {
    console.log(selectedTool);
    if (selectedTool.name !== "" && selectedTool.image) {
      if (selectedTool.type.value === "url") {
        if (selectedTool.url !== "") {
          if (!selectedTool.url.startsWith("http")) {
            selectedTool.url = "https://" + selectedTool.url;
          }

          const response = await post(
            `insert into guidelines (name, icon, url, type)
              values ('${selectedTool.name}', '', '${selectedTool.url}', '${selectedTool.type.value}')`
          );
          selectedTool.id = response[0];

          const iconLink =
            LABIT_PUBLIC_FOLDER +
            GUIDELINES_REMOTE_URL +
            "/" +
            selectedTool.id +
            "." +
            selectedTool.image.extension;

          await post(
            `update guidelines
              set icon='${iconLink}'
              where id=${selectedTool.id}`
          );

          await rpost("UploadPublicFile", {
            path: GUIDELINES_REMOTE_URL,
            fileName: selectedTool.id + "." + selectedTool.image.extension,
            fileData: selectedTool.image.data,
          });

          guidelines.push({
            id: selectedTool.id,
            name: selectedTool.name,
            icon: iconLink,
            url: selectedTool.url,
            type: "url",
            remoteFile: "",
          });
          guidelines = guidelines;
          showToolForm(false);
        }
      } else {
        if (selectedTool.file) {
          const response = await post(
            `insert into guidelines (name, icon, url, type)
              values ('${selectedTool.name}', '', null, '${selectedTool.type.value}')`
          );
          selectedTool.id = response[0];

          const iconLink =
            LABIT_PUBLIC_FOLDER +
            GUIDELINES_REMOTE_URL +
            "/" +
            selectedTool.id +
            "." +
            selectedTool.image.extension;

          await post(
            `update guidelines
              set icon='${iconLink}'
              where id=${selectedTool.id}`
          );

          await rpost("UploadPublicFile", {
            path: GUIDELINES_REMOTE_URL,
            fileName: selectedTool.id + "." + selectedTool.image.extension,
            fileData: selectedTool.image.data,
          });

          await rpost("CreateFolder", {
            path: GUIDELINES_REMOTE_URL,
            folder: selectedTool.id,
            token,
          });
          const f = {
            path: GUIDELINES_REMOTE_URL + "/" + selectedTool.id,
            fileName: selectedTool.file.name,
            fileExtension: selectedTool.file.extension,
            fileData: selectedTool.file.data,
            keywords: [],
            token,
          };
          await rpost("CreateFile", f);

          guidelines.push({
            id: selectedTool.id,
            name: selectedTool.name,
            icon: iconLink,
            url: null,
            type: "pdf",
            remoteFile: "",
          });

          guidelines = guidelines;
          showToolForm(false);
        }
      }
    }
  };

  let removeTool = async (index) => {
    await post(
      `delete from guidelines
        where id=${guidelines[index].id}`
    );

    guidelines = guidelines.filter((item) => {
      return item.id !== guidelines[index].id;
    });
  };
</script>

<div class="content">
  <!-- Menu -->

  <!-- Menu Resources -->

  <div class="optionsResources">
    <div class="guidelines customScrollbars">
      {#each guidelines as guideline, index}
        <ResourceOption
          imgSrc={guideline.icon}
          name={guideline.name}
          onClick={() => {
            clickResource(index);
          }}
          onRightClick={() => openLibraries(index)}
          selected={selectedIndex === index}
          showRemove={selectedTool && managingTools}
          onRemove={() => removeTool(index)}
        />
      {/each}
    </div>
    {#if myRole === "Admin" || myRole === "Analytics"}
      <img
        class="add fadedButton"
        src="/images/plus.svg"
        alt="Add Guideline"
        on:click={() => toggleToolForm()}
      />
    {/if}
  </div>
  {#if selectedTool && managingTools}
    <div class="toolFormArea">
      <div class="toolForm">
        <div class="x">
          <img
            src="/images/x.svg"
            alt="Close Form"
            on:click={() => showToolForm(false)}
          />
        </div>
        <div class="formElement">
          <span class="requiredElement">Icon</span>
          <div class="pic">
            <FileUploader
              bind:fileInfo={selectedTool.image}
              loadFromPath={selectedTool.icon}
              picExtraStyle="border-radius: 10px;"
            />
          </div>
        </div>
        <div class="formElement">
          <span class="requiredElement">Name</span>
          <div class="formLine">
            <FormTextEdit placeholder="" bind:value={selectedTool.name} />
          </div>
        </div>
        <div class="formElement">
          <span>File Type</span>
          <div class="formLine">
            <Combobox
              options={fileTypeOptions}
              searchable={false}
              placeholder=""
              bind:selectedValue={selectedTool.type}
            />
          </div>
        </div>
        <div
          class="formElement {selectedTool.type.value === 'pdf'
            ? 'hiddenElement'
            : ''}"
        >
          <span class="requiredElement">URL</span>
          <div class="formLine">
            <FormTextEdit
              placeholder=""
              maxlength={500}
              bind:value={selectedTool.url}
            />
          </div>
        </div>
        <div
          class="formElement {selectedTool.type.value === 'pdf'
            ? ''
            : 'hiddenElement'}"
        >
          <span class="requiredElement">File</span>
          <div class={selectedTool.file ? "" : "pic"}>
            <FileUploaderGeneric
              bind:fileInfo={selectedTool.file}
              loadFromPath={selectedTool.icon}
              picExtraStyle="border-radius: 10px;"
            />
          </div>
        </div>
        <div class="formElement space">
          <div class="formButton">
            <Button
              text="Save"
              onClick={() => {
                addTool();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  {/if}

  {#each guidelines as guideline, index}
    {#if guideline.type === "pdf"}
      <div
        class="pdfContainer {index === selectedIndex ? '' : 'hiddenElement'}"
      >
        <PreviewRemoteFile fileUrl={guideline.remoteFile} />
      </div>
    {:else}
      <div class="webbrowser {index === selectedIndex ? '' : 'hiddenElement'}">
        <WebBrowser
          id="guidelinesWebBrowser"
          type="webview"
          iframeTitle="External Tool"
          src={guideline.url}
        />
      </div>
      <!--
      {#if index === selectedIndex && visible}
        <ContextMenu currentURL={guideline.url} bind:showMenu={showNavMenu} />
      {/if}
      -->
    {/if}
  {/each}
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.optionsResources {
    width: 100%;
    height: 85px;
    border-radius: 19px;
    background-color: var(--labit-dialog-background);
    margin-top: 20px;
    display: flex;
    padding: 0px 33px 0px 33px;
    align-items: center;
    justify-content: space-between;
  }

  div.guidelines {
    display: flex;
    height: 100%;
    width: 95%;
    overflow-x: auto;
  }

  div.webbrowser {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    flex-grow: 1;
  }

  img.add {
    height: 45%;
    cursor: pointer;
  }

  div.pdfContainer {
    width: 100%;
  }

  div.toolFormArea {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  div.toolForm {
    background-color: var(--labit-dialog-background);
    width: 450px;
    padding: 14px;
    border-radius: 10px;
    margin-top: 14px;
  }

  div.toolForm span {
    font-size: 13px;
    color: var(--labit-card-gray);
  }

  div.x {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  div.x img {
    width: 12px;
    cursor: pointer;
  }

  div.formElement {
    margin-bottom: 6px;
  }

  div.formLine {
    height: 23px;
  }

  div.space {
    margin-top: 19px;
  }

  div.pic {
    width: 120px;
    height: 120px;
  }

  div.formButton {
    width: 77px;
    height: 29px;
  }
</style>
