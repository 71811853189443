<script>
  export let text;
  export let onRemove;
  export let showRemove = true;
</script>

<div class="editSectionContainer">
  <div class="menu">
    <span>{text.toUpperCase()}</span>
  </div>
  {#if showRemove}
    <div class="buttonDelete fadedButton" on:click={onRemove}>
      <img src="/images/trash_white.svg" alt="" />
    </div>
  {/if}
</div>

<style>
  div.editSectionContainer {
    width: 100%;
    display: flex;
    height: 85px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  div.menu {
    width: 1510px;
    height: 100%;
    background-color: #d7e1ea;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30px;
  }

  div.menu span {
    color: var(--labit-card-gray);
    font-size: 15px;
  }

  div.buttonDelete {
    width: 118px;
    height: 100%;
    background-color: #ff7777;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  div.buttonDelete img {
    width: 30px;
  }
</style>
