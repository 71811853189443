<script>
  export let team;
  export let grayscale;
  export let onRemove;
  export let onUnactive;
  export let onClickPic;
  export let selectedForRemoval = "";
  export let isHistory = false;
  export let onActive = null;
  export let action;

  import DialogProjectTeam from "./DialogProjectTeam.svelte";

  let selectedEmail = "";

  let selectEmail = (index) => {
    selectedEmail = team[index].peopleEmail;
  };

  //$: console.log("TEAM", team);
</script>

<div class="dialogProjectTeamsContainer">
  <div class="team">
    {#each team as people, index}
      <DialogProjectTeam
        {people}
        {grayscale}
        onHover={() => selectEmail(index)}
        onRemove={() => {
          onRemove(people.id);
          selectedEmail = "";
        }}
        onUnactive={() => {
          onUnactive(people.id);
          selectedEmail = "";
        }}
        onActive={() => {
          onActive(people.id);
          selectedEmail = "";
        }}
        {onClickPic}
        {selectedForRemoval}
        {isHistory}
        isCompany={false}
        {action}
      />
    {/each}
  </div>
  <div class="email">
    <span>{selectedEmail}</span>
  </div>
</div>

<style>
  div.dialogProjectTeamsContainer {
    width: 100%;
  }

  div.team {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  div.email {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-top: 5px;
  }
</style>
