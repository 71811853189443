<script>
  import Menu from "../components/Menu.svelte";
  import TextEdit from "../components/TextEdit.svelte";
  import SubmenuCombobox from "../components/SubmenuCombobox.svelte";
  import SubmenuButton from "../components/SubmenuButton.svelte";
  import DialogRemove from "../components/DialogRemove.svelte";
  import SubmenuDoubleCombobox from "../components/SubmenuDoubleCombobox.svelte";
  import StandardItem from "../components/StandardItem.svelte";
  import Loading from "../components/Loading.svelte";
  import EditBoxTab from "../components/EditBoxTab.svelte";
  import EditBoxFormButton from "../components/EditBoxFormButton.svelte";
  import EditBoxButton from "../components/EditBoxButton.svelte";
  import StandardProjectItem from "../components/StandardProjectItem.svelte";
  import StandardProjectProgram from "../components/StandardProjectProgram.svelte";
  import StandardProjectSpaceRoom from "../components/StandardProjectSpaceRoom.svelte";
  import FileUploader from "../components/FileUploader.svelte";
  import ProgramIcon from "../components/ProgramIcon.svelte";
  import VerticalSpacer from "../components/VerticalSpacer.svelte";
  import AnalyticsTextArea from "../components/AnalyticsTextArea.svelte";
  //import BoxEditPager from "../components/BoxEditPager.svelte";
  import EditSection from "../components/EditSection.svelte";
  import AnalyticsTabs from "../components/AnalyticsTabs.svelte";

  import ColorPicker from "../components/ColorPicker/ColorPicker.svelte";

  import DialogSpanFullEdit from "../modules/project-analytics/screens/components/DialogSpanFullEdit.svelte";
  import Combobox from "../modules/project-analytics/screens/components/Combobox.svelte";

  import { post } from "../js/labit-connection";
  import { getUserId, getRole, logout } from "../js/localInfo";
  import { getInfoFromStandardName } from "../js/utils";
  import { getDefaultColors } from "../js/colors";
  import { openURL } from "../js/openURL";
  import { openNewWindow } from "../js/openNewWindow";

  import newStandardEmpty from "../data/newStandard";

  import { onMount } from "svelte";
  import { copyObject } from "../js/copyObject";
  import { loadStandard } from "../js/standards";

  const PAGE = "ANALYTICS";
  import moment from 'moment';

  let myRole = getRole();
  let myId = getUserId();

  let standards = [];

  let selectedStandard = null;

  let loading = false;
  let loadingStandard = false;

  let showRemoveDialog = false;
  let removeElement = "";

  let clientOptions = [];
  let selectedClient = null;
  let productOptions = [];
  let selectedProduct = null;
  let locationOptions = [];
  let selectedLocation = null;
  let versionOptions = [];
  let selectedVersion = null;
  let timeCodeOptions = [];
  let selectedTimeCode = null;
  let folderCodeOptions = [];
  let selectedFolderCode = null;
  let nameOptions = [];
  let selectedName = null;
  let countryOptions = [];
  let selectedCountry = null;
  let cityOptions = [];
  let selectedCity = null;
  let projectManagerOptions = [];
  let selectedProjectManager = null;

  let companiesOptions = [];
  let companiesOptionsSel = null;
  let projectsOptions = [];
  let projectsOptionsSel = null;

  let backgroundColorInput = "#d7e1ea";
  let borderColor = "var(--labit-card-gray)";

  let currentStandard = copyObject(newStandardEmpty);

  let defaultPrograms = [];

  const NUM_PAGES = 2;
  let currentPage = 0;

  const statusOptions = [
    {
      value: -1,
      label: "ALL",
    },
    {
      value: 0,
      label: "Active",
    },
    {
      value: 1,
      label: "Inactive",
    },
  ];
  let selectedStatus = null;
  let filterText = "";

  let selectedProgram = null;
  let selectedSpace = null;

  let companyError = false;
  let productError = false;
  let versionError = false;
  let locationError = false;

  let currentProgramName = "";
  let currentColor = "#ffffff";
  let currentIcon = null;
  let currentIconPath = "";
  let currentProgramNameError = "";
  let currentIconError = "";
  let removingProgram = null;
  let editingProgram = null;
  let showProgramForm = ""; // "", "new", "edit"

  let currentSpaceName = "";
  let currentSpaceNameError = "";
  let removingSpace = null;
  let editingSpace = null;
  let showSpaceForm = ""; // "", "new", "edit"

  let currentRoomName = "";
  let currentRoomNameError = "";
  let currentRoomCode = "";
  let currentRoomCodeError = "";
  let currentRoomDescription = "";
  let removingRoom = null;
  let editingRoom = null;
  let showRoomForm = ""; // "", "new", "edit"

  let showStandardForm = ""; // "", "new", "edit"

  let accId = -1;

  let savedStandard = null;

  let deletedPrograms = [];
  let deletedSpaces = [];
  let deletedRooms = [];

  let standardListOptions = [];
  let standardListOptionsSel = null;

  let defaultColors = [];

  onMount(async () => {
    await getDefaultPrograms();
    defaultColors = await getDefaultColors();

    currentStandard = copyObject(newStandardEmpty);
    currentStandard.id = accId;
    currentStandard.programs = copyObject(defaultPrograms);
    currentPage = 0;
    currentStandard = currentStandard;

    getStandardList();
    getCompanies();
    getProjects();
    getStandards();
  });

  let getStandardList = async () => {
    const response = await post(
      `select standards_versions_id, standard_name
        from standards_versions`
    );
    standardListOptions = response.map((item) => {
      return {
        value: item.standards_versions_id,
        label: item.standard_name,
      };
    });
    standardListOptions.unshift({
      value: -1,
      label: "Empty Template",
    });
    standardListOptionsSel = standardListOptions[0];
  };

  let getCompanies = async () => {
    const response = await post(
      `select company_id, Name
        from companies`
    );
    companiesOptions = response.map((item) => {
      return {
        value: item.company_id,
        label: item.Name,
      };
    });
    if (companiesOptions.length > 0) {
      companiesOptionsSel = companiesOptions[0];
      currentStandard.company = companiesOptions[0];
      currentStandard = currentStandard;
    }
  };

  let getProjects = async () => {
    const response = await post(
      `select project_id, TimeLabitCode, FolderLabitCode, Name
            from projects
            where standards_versions_id is null
            order by Name asc`
    );
    projectsOptions = response.map((item) => {
      return {
        value: {
          id: item.project_id,
          name: item.Name,
          timeLabitCode: item.TimeLabitCode,
          folderLabitCode: item.FolderLabitCode,
        },
        label: item.Name,
      };
    });
    if (projectsOptions.length > 0) {
      projectsOptionsSel = projectsOptions[0];
    }
  };

  let getDefaultPrograms = async () => {
    const response = await post(
      `select name, color, iconUrl
        from defaultPrograms`
    );

    defaultPrograms = response.map((item, index) => {
      return {
        id: -1000000 - index,
        name: item.name,
        color: item.color,
        icon: item.iconUrl,
        spaces: [],
        modified: false,
      };
    });

    /*defaultPrograms[0].spaces = [
      {
        id: -10001,
        name: "Space 1 1",
        rooms: [
          {
            id: -1001,
            name: "Room 1 1 1",
            code: "ROOM111",
            description: "This is room 1 1 1",
            modified: false,
          },
          {
            id: -1002,
            name: "Room 1 1 2",
            code: "ROOM112",
            description: "This is room 1 1 2",
            modified: false,
          },
        ],
        modified: false,
      },
      {
        id: -10002,
        name: "Space 1 2",
        rooms: [
          {
            id: -1003,
            name: "Room 1 2 1",
            code: "ROOM121",
            description: "This is room 1 2 1",
            modified: false,
          },
          {
            id: -1004,
            name: "Room 1 2 2",
            code: "ROOM122",
            description: "This is room 1 2 2",
            modified: false,
          },
        ],
        modified: false,
      },
      {
        id: -10003,
        name: "Space 1 3",
        rooms: [],
        modified: false,
      },
    ];
    defaultPrograms[1].spaces = [
      {
        id: -10004,
        name: "Space 2 1",
        rooms: [
          {
            id: -1005,
            name: "Room 2 1 1",
            code: "ROOM211",
            description: "This is room 2 1 1",
            modified: false,
          },
          {
            id: -1006,
            name: "Room 2 1 2",
            code: "ROOM212",
            description: "This is room 2 1 2",
            modified: false,
          },
        ],
        modified: false,
      },
      {
        id: -10005,
        name: "Space 2 2",
        rooms: [],
        modified: false,
      },
    ];

    defaultPrograms = defaultPrograms;*/
  };

  let showForm = async (show, standardId = null) => {
    showStandardForm = show;
    if (show === "new") {
      restartStandard();
      currentStandard = copyObject(newStandardEmpty);
      currentStandard.id = accId;
      accId--;
      currentStandard = currentStandard;
      companiesOptionsSel = companiesOptions.find((item) => {
        return item.label.trim() === "Labit Group";
      });
      currentPage = 0;
    } else {
      if (show === "edit") {
        restartStandard();
        loading = true;
        currentStandard = await loadStandard(standardId);
        savedStandard = copyObject(currentStandard);
        companiesOptionsSel = currentStandard.company;
        currentPage = 0;
        loading = false;
      }
    }
  };

  let nextPage = () => {
    currentPage++;
    if (currentPage >= NUM_PAGES) {
      currentPage = 0;
    }
  };

  let previousPage = () => {
    currentPage--;
    if (currentPage < 0) {
      currentPage = NUM_PAGES - 1;
    }
  };

  let setPage = (page) => {
    currentPage = page;
  };

  let createStandardName = () => {
    const companyName = companiesOptionsSel ? companiesOptionsSel.label : "";
    currentStandard.fullName =
      companyName +
      "_" +
      currentStandard.product +
      "_" +
      currentStandard.version +
      "_" +
      currentStandard.location;
    currentStandard = currentStandard;
  };

  let addProject = () => {
    const pos = currentStandard.projects.findIndex((item) => {
      return item.value.id === projectsOptionsSel.value.id;
    });
    if (pos === -1) {
      currentStandard.projects.push(projectsOptionsSel);
      currentStandard = currentStandard;
    }
  };

  let removeProject = (index) => {
    currentStandard.projects.splice(index, 1);
    currentStandard = currentStandard;
  };

  let restartProgram = () => {
    currentProgramName = "";
    currentColor = "#ffffff";
    currentIcon = null;
    currentIconPath = "";
    currentProgramNameError = "";
    currentIconError = "";
    removingProgram = null;
  };

  let restartSpace = () => {
    currentSpaceName = "";
    currentSpaceNameError = "";
    removingSpace = null;
  };

  let restartRoom = () => {
    currentRoomName = "";
    currentRoomNameError = "";
    currentRoomCode = "";
    currentRoomCodeError = "";
    currentRoomDescription = "";
    removingRoom = null;
  };

  let checkProgramErrors = () => {
    currentProgramNameError = "";
    currentIconError = "";
    if (currentProgramName.trim() === "") {
      currentProgramNameError = "The name of the program must not be empty.";
      return false;
    }
    if (!currentIcon || !currentIcon.data) {
      currentIconError = "You must select a valid image icon for the program.";
      return false;
    }
    return true;
  };

  let checkSpaceErrors = () => {
    currentSpaceNameError = "";
    if (currentSpaceName.trim() === "") {
      currentSpaceNameError = "The name of the space must not be empty.";
      return false;
    }
    return true;
  };

  let checkRoomErrors = () => {
    currentRoomNameError = "";
    currentRoomCodeError = "";
    if (currentRoomName.trim() === "") {
      currentRoomNameError = "The name of the room must not be empty.";
      return false;
    }
    if (currentRoomCode.trim() === "") {
      currentRoomCodeError = "The code of the room must not be empty.";
      return false;
    }
    return true;
  };

  let addProgram = () => {
    if (!checkProgramErrors()) {
      return;
    }
    const pos = currentStandard.programs.findIndex((item) => {
      return item.name === currentProgramName.trim().toUpperCase();
    });
    if (pos === -1) {
      const program = {
        id: accId,
        name: currentProgramName.trim().toUpperCase(),
        color: currentColor,
        icon: currentIcon.data,
        spaces: [],
        modified: false,
      };
      accId--;
      currentStandard.programs.push(program);
      currentStandard = currentStandard;
      restartProgram();
    }
  };

  let addSpace = () => {
    if (!checkSpaceErrors()) {
      return;
    }
    const pos = selectedProgram.spaces.findIndex((item) => {
      return item.name === currentSpaceName.trim();
    });
    if (pos === -1) {
      const space = {
        id: accId,
        name: currentSpaceName.trim(),
        rooms: [],
        modified: false,
      };
      accId--;
      selectedProgram.spaces.push(space);
      selectedProgram = selectedProgram;
      restartSpace();
    }
  };

  let addRoom = () => {
    if (!checkRoomErrors()) {
      return;
    }
    const pos = selectedSpace.rooms.findIndex((item) => {
      return item.name === currentRoomName.trim();
    });
    if (pos === -1) {
      const room = {
        id: accId,
        name: currentRoomName.trim(),
        code: currentRoomCode.trim(),
        description: currentRoomDescription,
        modified: false,
      };
      accId--;
      selectedSpace.rooms.push(room);
      selectedSpace = selectedSpace;
      restartRoom();
    }
  };

  let removeProgram = (id) => {
    if (!removingProgram || removingProgram !== id) {
      removingProgram = id;
    } else {
      if (removingProgram >= 0) {
        deletedPrograms.push(removingProgram);
        const program = currentStandard.programs.find((item) => {
          return item.id === removingProgram;
        });
        if (program) {
          program.spaces.forEach((space) => {
            if (space.id >= 0) {
              deletedSpaces.push(space.id);
              space.rooms.forEach((room) => {
                if (room.id >= 0) {
                  deletedRooms.push(room.id);
                }
              });
            }
          });
        }
        deletedPrograms = deletedPrograms;
        deletedSpaces = deletedSpaces;
        deletedRooms = deletedRooms;
      }
      currentStandard.programs = currentStandard.programs.filter((item) => {
        return item.id !== removingProgram;
      });
      currentStandard = currentStandard;
      removingProgram = null;
      showProgramForm = "";
    }
  };

  let removeSpace = (id) => {
    if (!removingSpace || removingSpace !== id) {
      removingSpace = id;
    } else {
      if (removingSpace >= 0) {
        deletedSpaces.push(removingSpace);
        const space = selectedProgram.spaces.find((item) => {
          return item.id === removingSpace;
        });
        if (space) {
          space.rooms.forEach((room) => {
            if (room.id >= 0) {
              deletedRooms.push(room.id);
            }
          });
        }
        deletedSpaces = deletedSpaces;
        deletedRooms = deletedRooms;
      }
      selectedProgram.spaces = selectedProgram.spaces.filter((item) => {
        return item.id !== removingSpace;
      });
      selectedProgram = selectedProgram;
      removingSpace = null;
      showSpaceForm = "";
    }
  };

  let removeRoom = (id) => {
    if (!removingRoom || removingRoom !== id) {
      removingRoom = id;
    } else {
      if (removingRoom >= 0) {
        deletedRooms.push(removingRoom);
        deletedRooms = deletedRooms;
      }
      selectedSpace.rooms = selectedSpace.rooms.filter((item) => {
        return item.id !== removingRoom;
      });
      selectedSpace = selectedSpace;
      removingRoom = null;
      showRoomForm = "";
    }
  };

  let toggleProgram = () => {
    restartProgram();
    showProgramForm = showProgramForm !== "new" ? "new" : "";
  };

  let toggleSpace = () => {
    restartSpace();
    showSpaceForm = showSpaceForm !== "new" ? "new" : "";
  };

  let toggleRoom = () => {
    restartRoom();
    showRoomForm = showRoomForm !== "new" ? "new" : "";
  };

  let showProgram = (action, edit = null) => {
    restartProgram();
    if (edit) {
      editingProgram = edit.id;
      currentProgramName = edit.name;
      currentColor = edit.color;
      currentIcon = {
        data: edit.icon,
      };
      currentIconPath = null;
    }
    showProgramForm = action;
  };

  let showSpace = (action, edit = null) => {
    restartSpace();
    if (edit) {
      editingSpace = edit.id;
      currentSpaceName = edit.name;
    }
    showSpaceForm = action;
  };

  let showRoom = (action, edit = null) => {
    restartRoom();
    if (edit) {
      editingRoom = edit.id;
      currentRoomName = edit.name;
      (currentRoomCode = edit.code),
        (currentRoomDescription = edit.description);
    }
    showRoomForm = action;
  };

  let editProgram = () => {
    if (!checkProgramErrors()) {
      return;
    }
    let program = currentStandard.programs.find((item) => {
      return item.id === editingProgram;
    });
    program.name = currentProgramName.trim();
    program.color = currentColor;
    program.icon = currentIcon.data;
    program.modified = true;
    currentStandard = currentStandard;
    restartProgram();
    showProgram("");
  };

  let editSpace = () => {
    if (!checkSpaceErrors()) {
      return;
    }
    let space = selectedProgram.spaces.find((item) => {
      return item.id === editingSpace;
    });
    space.name = currentSpaceName.trim();
    space.modified = true;
    selectedProgram = selectedProgram;
    restartSpace();
    showSpace("");
  };

  let editRoom = () => {
    if (!checkRoomErrors()) {
      return;
    }
    let room = selectedSpace.rooms.find((item) => {
      return item.id === editingRoom;
    });
    room.name = currentRoomName.trim();
    room.code = currentRoomCode.trim();
    room.description = currentRoomDescription;
    room.modified = true;
    selectedSpace = selectedSpace;
    restartRoom();
    showRoom("");
  };

  let selectProgram = (program) => {
    selectedProgram = program;
    selectedSpace = null;
    showSpace("");
    showRoom("");
  };

  let selectSpace = (space) => {
    selectedSpace = space;
    showRoom("");
  };

  let getStandards = async () => {
    loading = true;

    let newStandards = [];

    const response = await post(
      `select standards_versions_id, standard_name, revit_link, Name, CityCode, sv.company_id, creationDate, createdBy, modificationDate, modifiedBy
            from standards_versions as sv
            left join companies as c on c.company_id=sv.company_id`
    );

    for (let i = 0; i < response.length; i++) {
      const item = response[i];
      const result = getInfoFromStandardName(item.standard_name);
      const product = result[0];
      const version = result[1];
      const location = result[2];
      let standard = {
        id: item.standards_versions_id,
        fullName: item.standard_name,
        revitLink: item.revit_link,
        company: item.Name,
        companyId: item.company_id,
        cityCode: item.CityCode,
        product,
        version,
        location,
        projects: [],
        creationDate: item.creationDate,
        createdBy: item.createdBy,
        modificationDate: item.modificationDate,
        modifiedBy: item.modifiedBy,
        visible: true,
      };

      let resp = await post(
        `select project_id, TimeLabitCode, FolderLabitCode, Name, City, Country
            from projects
            where standards_versions_id=${standard.id}
            order by TimeLabitCode asc`
      );

      for (let j = 0; j < resp.length; j++) {
        const p = resp[j];
        let project = {
          id: p.project_id,
          timeLabitCode: p.TimeLabitCode,
          folderLabitCode: p.FolderLabitCode,
          name: p.Name,
          city: p.City,
          country: p.Country,
          active: false,
          projectManager: null,
          projectManagerPic: "",
          visible: true,
        };

        /*let r = await post(
          `select Name, PictureUrl
            from projectPeople as pp
            left join people as p on p.contact_id=pp.people
            where project=${project.id} and pp.role='Project Manager'`
        );*/
        let r = await post(
          `select distinct pe.Name, PictureUrl
          from projectTeam as pt
          left join people as pe on pe.contact_id=pt.people
          left join phase as ph on ph.id=pt.phase
          where ph.project=${project.id} and pt.role="Project Manager"`
        );

        project.projectManager = r.length > 0 ? r[0].Name : "";
        project.projectManagerPic = r.length > 0 ? r[0].PictureUrl : "";

        r = await post(
          `select inputs_id
            from dynamo_inputs
            where project_name='${project.name}'`
        );

        project.active = r.length > 0;

        standard.projects.push(project);
      }

      newStandards.push(standard);
    }
    standards = newStandards;

    // Prepare filters

    clientOptions = [];
    productOptions = [];
    locationOptions = [];
    versionOptions = [];
    timeCodeOptions = [];
    folderCodeOptions = [];
    nameOptions = [];
    countryOptions = [];
    cityOptions = [];
    projectManagerOptions = [];

    standards.forEach((standard) => {
      let pos = clientOptions.findIndex((item) => {
        return item.value === standard.company;
      });
      if (pos === -1) {
        clientOptions.push({
          value: standard.company,
          label: standard.company,
        });
      }

      pos = productOptions.findIndex((item) => {
        return item.value === standard.product;
      });
      if (pos === -1) {
        productOptions.push({
          value: standard.product,
          label: standard.product,
        });
      }

      pos = locationOptions.findIndex((item) => {
        return item.value === standard.location;
      });
      if (pos === -1) {
        locationOptions.push({
          value: standard.location,
          label: standard.location,
        });
      }

      pos = versionOptions.findIndex((item) => {
        return item.value === standard.version;
      });
      if (pos === -1) {
        versionOptions.push({
          value: standard.version,
          label: standard.version,
        });
      }

      standard.projects.forEach((project) => {
        pos = timeCodeOptions.findIndex((item) => {
          return item.value === project.timeLabitCode;
        });
        if (pos === -1) {
          timeCodeOptions.push({
            value: project.timeLabitCode,
            label: project.timeLabitCode,
          });
        }

        pos = folderCodeOptions.findIndex((item) => {
          return item.value === project.folderLabitCode;
        });
        if (pos === -1) {
          folderCodeOptions.push({
            value: project.folderLabitCode,
            label: project.folderLabitCode,
          });
        }

        pos = nameOptions.findIndex((item) => {
          return item.value === project.name;
        });
        if (pos === -1) {
          nameOptions.push({
            value: project.name,
            label: project.name,
          });
        }

        pos = countryOptions.findIndex((item) => {
          return item.value === project.country;
        });
        if (pos === -1) {
          countryOptions.push({
            value: project.country,
            label: project.country,
          });
        }

        pos = cityOptions.findIndex((item) => {
          return item.value === project.city;
        });
        if (pos === -1) {
          cityOptions.push({
            value: project.city,
            label: project.city,
          });
        }

        pos = projectManagerOptions.findIndex((item) => {
          return item.value === project.projectManager;
        });
        if (pos === -1) {
          projectManagerOptions.push({
            value: project.projectManager,
            label: project.projectManager,
          });
        }
      });
    });

    clientOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedClient) {
      selectedClient = clientOptions[0];
    }

    productOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedProduct) {
      selectedProduct = productOptions[0];
    }

    locationOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedLocation) {
      selectedLocation = locationOptions[0];
    }

    versionOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedVersion) {
      selectedVersion = versionOptions[0];
    }

    timeCodeOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedTimeCode) {
      selectedTimeCode = timeCodeOptions[0];
    }

    folderCodeOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedFolderCode) {
      selectedFolderCode = folderCodeOptions[0];
    }

    nameOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedName) {
      selectedName = nameOptions[0];
    }

    countryOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedCountry) {
      selectedCountry = countryOptions[0];
    }

    cityOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedCity) {
      selectedCity = cityOptions[0];
    }

    projectManagerOptions.unshift({
      value: -1,
      label: "ALL",
    });
    if (!selectedProjectManager) {
      selectedProjectManager = projectManagerOptions[0];
    }

    selectedStatus = statusOptions[0];

    loading = false;
  };

  let changeCompany = () => {
    currentStandard.company = companiesOptionsSel;
    currentStandard = currentStandard;
  };

  let restartStandard = () => {
    restartStandardErrors();

    selectedProgram = null;
    selectedSpace = null;

    currentProgramName = "";
    currentColor = "#ffffff";
    currentIcon = null;
    currentIconPath = "";
    currentProgramNameError = "";
    currentIconError = "";
    removingProgram = null;
    editingProgram = null;
    showProgramForm = "";

    currentSpaceName = "";
    currentSpaceNameError = "";
    removingSpace = null;
    editingSpace = null;
    showSpaceForm = "";

    currentRoomName = "";
    currentRoomNameError = "";
    currentRoomCode = "";
    currentRoomCodeError = "";
    currentRoomDescription = "";
    removingRoom = null;
    editingRoom = null;
    showRoomForm = "";

    deletedPrograms = [];
    deletedSpaces = [];
    deletedRooms = [];

    standardListOptionsSel = standardListOptions[0];
  };

  let restartStandardErrors = () => {
    companyError = false;
    productError = false;
    locationError = false;
    versionError = false;
  };

  let checkStandardErrors = () => {
    restartStandardErrors();
    if (!currentStandard.company) {
      companyError = true;
      currentPage = 0;
    }
    if (currentStandard.product.trim() === "") {
      productError = true;
      currentPage = 0;
    }
    if (currentStandard.version.trim() === "") {
      versionError = true;
      currentPage = 0;
    }
    if (currentStandard.location.trim() === "") {
      locationError = true;
      currentPage = 0;
    }
    return !(companyError || productError || versionError || productError);
  };

  let insertStandard = async () => {
    if (checkStandardErrors()) {
      loadingStandard = true;

      const now = moment().format("YYYY-MM-DD HH:mm:ss");

      formatStandard();
      let response = await post(
        `insert into standards_versions (
        company_id,
        standard_name,
        revit_link,
        creationDate,
        createdBy
        )
        values (
         ${currentStandard.company.value},
         '${currentStandard.fullName}',
         '${currentStandard.revitLink}',
         '${now}',
         ${myId}
        )`
      );
      currentStandard.id = response[0];

      for (let i = 0; i < currentStandard.projects.length; i++) {
        const project = currentStandard.projects[i];
        response = await post(
          `update projects set
        standards_versions_id=${currentStandard.id}
        where project_id=${project.value.id}`
        );
      }

      for (let i = 0; i < currentStandard.programs.length; i++) {
        let program = currentStandard.programs[i];
        response = await post(
          `insert into program_types (Name, standards_version_id, color, iconUrl)
        values ('${program.name}', ${currentStandard.id}, '${program.color}', '${program.icon}')`
        );
        program.id = response[0];
        for (let j = 0; j < program.spaces.length; j++) {
          let space = program.spaces[j];
          response = await post(
            `insert into space_names (Name, program_type_id)
          values ('${space.name}', ${program.id})`
          );
          space.id = response[0];
          for (let k = 0; k < space.rooms.length; k++) {
            let room = space.rooms[k];
            response = await post(
              `insert into space_types (Name, space_name_id, description, room_name)
          values ('${room.name}', ${space.id}, '${room.description}', '${room.code}')`
            );
            room.id = response[0];
          }
        }
      }

      showForm("");
      await getStandards();

      loadingStandard = false;
    }
  };

  let updateStandard = async () => {
    if (checkStandardErrors()) {
      const now = moment().format("YYYY-MM-DD HH:mm:ss");

      formatStandard();
      let response = null;
      loadingStandard = true;
      await post(
        `update standards_versions
          set 
          company_id=${currentStandard.company.value},
          standard_name='${currentStandard.fullName}',
          revit_link='${currentStandard.revitLink}',
          modificationDate='${now}',
          modifiedBy=${myId}
          where standards_versions_id=${currentStandard.id}`
      );

      // PROJECTS

      //// Insert

      for (let i = 0; i < currentStandard.projects.length; i++) {
        const project = currentStandard.projects[i];
        const p = savedStandard.projects.find((item) => {
          return item.value.id === project.value.id;
        });
        if (!p) {
          await post(
            `update projects set
              standards_versions_id=${currentStandard.id}
              where project_id=${project.value.id}`
          );
        }
      }

      //// Delete

      for (let i = 0; i < savedStandard.projects.length; i++) {
        const project = savedStandard.projects[i];
        const p = currentStandard.projects.find((item) => {
          return item.value.id === project.value.id;
        });
        if (!p) {
          await post(
            `update projects set
              standards_versions_id=null
              where project_id=${project.value.id}`
          );
        }
      }

      // PROGRAMS

      //// Insert

      for (let i = 0; i < currentStandard.programs.length; i++) {
        let program = currentStandard.programs[i];
        if (program.id < 0) {
          response = await post(
            `insert into program_types (Name, standards_version_id, color, iconUrl)
              values ('${program.name}', ${currentStandard.id}, '${program.color}', '${program.icon}')`
          );
          program.id = response[0];
        }
        for (let j = 0; j < program.spaces.length; j++) {
          let space = program.spaces[j];
          if (space.id < 0) {
            response = await post(
              `insert into space_names (Name, program_type_id)
                values ('${space.name}', ${program.id})`
            );
            space.id = response[0];
          }
          for (let k = 0; k < space.rooms.length; k++) {
            let room = space.rooms[k];
            if (room.id < 0) {
              response = await post(
                `insert into space_types (Name, space_name_id, description, room_name)
                  values ('${room.name}', ${space.id}, '${room.description}', '${room.code}')`
              );
              room.id = response[0];
            }
          }
        }
      }

      //// Edit
      for (let i = 0; i < currentStandard.programs.length; i++) {
        let program = currentStandard.programs[i];
        if (program.id >= 0 && program.modified) {
          response = await post(
            `update program_types
              set
              Name='${program.name}',
              color='${program.color}',
              iconUrl='${program.icon}'
              where program_type_id=${program.id}`
          );
        }
        for (let j = 0; j < program.spaces.length; j++) {
          let space = program.spaces[j];
          if (space.id >= 0 && space.modified) {
            response = await post(
              `update space_names
                set
                Name='${space.name}'
                where space_name_id=${space.id}`
            );
          }
          for (let k = 0; k < space.rooms.length; k++) {
            let room = space.rooms[k];
            if (room.id >= 0 && room.modified) {
              response = await post(
                `update space_types
                  set
                  Name='${room.name}',
                  description='${room.description}',
                  room_name='${room.code}'
                  where space_type_id=${room.id}`
              );
            }
          }
        }
      }

      //// Delete

      for (let i = 0; i < deletedRooms.length; i++) {
        const id = deletedRooms[i];
        await post(
          `delete from space_types
            where space_type_id=${id}`
        );
      }
      for (let i = 0; i < deletedSpaces.length; i++) {
        const id = deletedSpaces[i];
        await post(
          `delete from space_names
            where space_name_id=${id}`
        );
      }
      for (let i = 0; i < deletedPrograms.length; i++) {
        const id = deletedPrograms[i];
        await post(
          `delete from program_types
            where program_type_id=${id}`
        );
      }

      showForm("");
      await getStandards();

      loadingStandard = false;
    }
  };

  let copyStandard = async () => {
    if (standardListOptionsSel) {
      loadingStandard = true;
      showProgram("");
      showSpace("");
      showRoom("");
      selectedProgram = null;
      selectedSpace = null;
      if (standardListOptionsSel.value === -1) {
        currentStandard.programs = copyObject(defaultPrograms);
      } else {
        const copiedStandard = await loadStandard(standardListOptionsSel.value);
        currentStandard.programs = copiedStandard.programs;
      }
      currentStandard = currentStandard;
      loadingStandard = false;
    }
  };

  let formatStandard = () => {
    for (let i = 0; i < currentStandard.programs.length; i++) {
      let program = currentStandard.programs[i];
      for (let j = 0; j < program.spaces.length; j++) {
        let space = program.spaces[j];
        space.name = space.name.replace(/'/g, "´");
        for (let k = 0; k < space.rooms.length; k++) {
          let room = space.rooms[k];
          room.name = room.name.replace(/'/g, "´");
          room.description = room.description.replace(/'/g, "´");
        }
      }
    }
  };

  let showRemove = (visible) => {
    removeElement = `"Standard: ${selectedStandard.fullName}"`;
    showRemoveDialog = visible;
  };

  let deleteStandard = async () => {
    showRemoveDialog = false;
    showForm("");
    loading = true;
    let response = await post(
      `update projects
        set standards_versions_id=null
        where standards_versions_id=${selectedStandard.id}`
    );

    response = await post(
      `delete from standards_versions
        where standards_versions_id=${selectedStandard.id}`
    );

    await getStandards();
    loading = false;
  };

  let clearFilters = () => {
    filterText = "";
    selectedClient = clientOptions[0];
    selectedProduct = productOptions[0];
    selectedLocation = locationOptions[0];
    selectedVersion = versionOptions[0];
    selectedTimeCode = timeCodeOptions[0];
    selectedFolderCode = folderCodeOptions[0];
    selectedName = nameOptions[0];
    selectedCountry = countryOptions[0];
    selectedCity = cityOptions[0];
    selectedProjectManager = projectManagerOptions[0];
    selectedStatus = statusOptions[0];
  };

  $: {
    // Filters

    const FilterText = filterText.toUpperCase();

    standards.forEach((standard) => {
      standard.visible = true;
      standard.projects.forEach((project) => {
        project.visible = true;
      });
      if (standard.visible) {
        // Search

        if (filterText.length >= 3) {
          standard.visible = standard.fullName
            .toUpperCase()
            .includes(FilterText);

          if (!standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              return acc || curr.name.toUpperCase().includes(FilterText);
            }, false);
          }

          if (!standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              return (
                acc || curr.timeLabitCode.toUpperCase().includes(FilterText)
              );
            }, false);
          }

          if (!standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              return (
                acc || curr.folderLabitCode.toUpperCase().includes(FilterText)
              );
            }, false);
          }

          if (!standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              return (
                acc || curr.projectManager.toUpperCase().includes(FilterText)
              );
            }, false);
          }
        }

        // Client

        if (selectedClient.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.company
              .toUpperCase()
              .includes(selectedClient.value.toUpperCase());
          }
        }

        // Product

        if (selectedProduct.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.product
              .toUpperCase()
              .includes(selectedProduct.value.toUpperCase());
          }
        }

        // Location

        if (selectedLocation.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.location
              .toUpperCase()
              .includes(selectedLocation.value.toUpperCase());
          }
        }

        // Version

        if (selectedVersion.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.version
              .toUpperCase()
              .includes(selectedVersion.value.toUpperCase());
          }
        }

        // Time Code

        if (selectedTimeCode.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              if (curr.visible) {
                curr.visible = curr.timeLabitCode
                  .toUpperCase()
                  .includes(selectedTimeCode.value.toUpperCase());
                acc = acc || curr.visible;
              }
              return acc;
            }, false);
          }
        }

        // Folder Code

        if (selectedFolderCode.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              if (curr.visible) {
                curr.visible = curr.folderLabitCode
                  .toUpperCase()
                  .includes(selectedFolderCode.value.toUpperCase());
                acc = acc || curr.visible;
              }
              return acc;
            }, false);
          }
        }

        // Name

        if (selectedName.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              if (curr.visible) {
                curr.visible = curr.name
                  .toUpperCase()
                  .includes(selectedName.value.toUpperCase());
                acc = acc || curr.visible;
              }
              return acc;
            }, false);
          }
        }

        // Country

        if (selectedCountry.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              if (curr.visible) {
                curr.visible = curr.country
                  .toUpperCase()
                  .includes(selectedCountry.value.toUpperCase());
                acc = acc || curr.visible;
              }
              return acc;
            }, false);
          }
        }

        // City

        if (selectedCity.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              if (curr.visible) {
                curr.visible = curr.city
                  .toUpperCase()
                  .includes(selectedCity.value.toUpperCase());
                acc = acc || curr.visible;
              }
              return acc;
            }, false);
          }
        }

        // Project Manager

        if (selectedProjectManager.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              if (curr.visible) {
                curr.visible = curr.projectManager
                  .toUpperCase()
                  .includes(selectedProjectManager.value.toUpperCase());
                acc = acc || curr.visible;
              }
              return acc;
            }, false);
          }
        }

        // Status

        if (selectedStatus.value !== -1) {
          if (standard.visible) {
            standard.visible = standard.projects.reduce((acc, curr) => {
              if (curr.visible) {
                curr.visible =
                  (selectedStatus.value === 0 && curr.active) ||
                  (selectedStatus.value === 1 && !curr.active);
                acc = acc || curr.visible;
              }
              return acc;
            }, false);
          }
        }
      }
    });

    standards = standards;
  }

  //$: console.log(currentIcon, currentIconPath);

  //$: console.log(currentStandard);
</script>

{#if showRemoveDialog}
  <DialogRemove
    element={removeElement}
    onClose={() => {
      showRemove(false);
    }}
    onRemove={() => deleteStandard()}
  />
{:else}
  <div class="content">
    <!-- Menu Options -->

    <div class="optionsMenu {showStandardForm !== '' ? 'hiddenElement' : ''}">
      {#if myRole === "Admin"}
        <div class="plusButton" on:click={() => showForm("new")}>
          <img src="/images/plus.svg" alt="New Icon" />
        </div>
      {/if}
      <div class="optionsRows">
        <div class="optionsRow">
          <div class="optionsColumn1">
            <div class="optionsSearch">
              <span>Search</span>
              <TextEdit
                placeholder="type here..."
                bind:value={filterText}
                maxlength="150"
                img="/images/search.svg"
              />
            </div>
          </div>
          <div class="optionsColumn2">
            <SubmenuButton
              text="Clear all"
              img="/images/x.svg"
              onClick={() => clearFilters()}
            />
          </div>
        </div>
        <div class="optionsRow lastRow">
          <div class="optionsColumn1">
            <SubmenuCombobox
              text="Client"
              options={clientOptions}
              bind:selectedValue={selectedClient}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "288px" : "326px"}
            />
            <SubmenuCombobox
              text="Product"
              options={productOptions}
              bind:selectedValue={selectedProduct}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "346px" : "384px"}
            />
            <SubmenuCombobox
              text="Location"
              options={locationOptions}
              bind:selectedValue={selectedLocation}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "192px" : "230px"}
            />
            <SubmenuCombobox
              text="Version"
              options={versionOptions}
              bind:selectedValue={selectedVersion}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "192px" : "230px"}
            />
          </div>
          <div class="optionsColumn2">
            <SubmenuButton
              text=""
              img="/images/book.svg"
              onClick={() => {
                openURL("/roomdatasheetg", true);
              }}
              onRightClick={() => {
                openNewWindow("/skylab-main/roomdatasheetg");
              }}
              bigIcon={true}
            />
          </div>
        </div>
        <div class="optionsRow lastRow">
          <div class="optionsColumn1">
            <SubmenuCombobox
              text="Time Code"
              options={timeCodeOptions}
              bind:selectedValue={selectedTimeCode}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "96px" : "115px"}
            />
            <SubmenuCombobox
              text="Folder Code"
              options={folderCodeOptions}
              bind:selectedValue={selectedFolderCode}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "96px" : "115px"}
            />
            <SubmenuCombobox
              text="Name"
              options={nameOptions}
              bind:selectedValue={selectedName}
              placeholder=""
              onChange={null}
            />
            <SubmenuDoubleCombobox
              text="Location"
              options1={countryOptions}
              bind:selectedValue1={selectedCountry}
              placeholder1=""
              onChange1={null}
              options2={cityOptions}
              bind:selectedValue2={selectedCity}
              placeholder2=""
              onChange2={null}
              width1={myRole === "Admin" ? "96px" : "115px"}
              width2={myRole === "Admin" ? "96px" : "115px"}
            />
            <SubmenuCombobox
              text="Project Manager"
              options={projectManagerOptions}
              bind:selectedValue={selectedProjectManager}
              placeholder=""
              onChange={null}
            />
            <SubmenuCombobox
              text="Status"
              options={statusOptions}
              bind:selectedValue={selectedStatus}
              placeholder=""
              onChange={null}
              width={myRole === "Admin" ? "96px" : "115px"}
            />
          </div>
          <div class="optionsColumn2">
            <SubmenuButton
              text=""
              img="/images/factory.svg"
              onClick={() => {
                openURL("/projects", true);
              }}
              onRightClick={() => {
                openNewWindow("/skylab-main/projects");
              }}
              bigIcon={true}
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Body -->

    <div class="loader {loading ? '' : 'hiddenElement'}">
      <Loading size="80px" />
    </div>

    <div
      class="editBar {!loading && showStandardForm === 'edit'
        ? ''
        : 'hiddenElement'}"
    >
      <EditSection
        creationInfo={currentStandard.creationInfo}
        modificationInfo={currentStandard.modificationInfo}
        onRemove={() => {
          selectedStandard = currentStandard;
          showRemove(true);
        }}
      />
    </div>

    <div class="editBox {loading || !showStandardForm ? 'hiddenElement' : ''}">
      <div class="editBoxTabs">
        <EditBoxTab
          icon="/images/book-blue.svg"
          text={currentStandard.fullName.toString()}
        />
      </div>
      <div class="editBoxLoader {loadingStandard ? '' : 'hiddenElement'}">
        <Loading />
      </div>
      <div class="tabs">
        <AnalyticsTabs
          tabs={[
            {
              title: "Information",
              index: 0,
              onClick: () => {
                setPage(0);
              },
            },
            {
              title: "Programmes / Spaces / Rooms",
              index: 1,
              onClick: () => {
                setPage(1);
              },
            },
          ]}
          selected={currentPage}
        />
      </div>
      <div class="editBoxInfo {loadingStandard ? 'hiddenElement' : ''}">
        <div class="editBoxInfoInfo">
          <!-- Standard Info -->

          <div class="editBoxPage {currentPage !== 0 ? 'hiddenElement' : ''}">
            <div class="editBoxColumn">
              <span class="editBoxTitleName"
                ><b>Standard Name: </b>{currentStandard.fullName}</span
              >
              <span class="editBoxTitle requiredElement">Company</span>
              <div class="editBoxInput containerBox">
                <Combobox
                  bind:selectedValue={companiesOptionsSel}
                  options={companiesOptions}
                  backgroundColor={backgroundColorInput}
                  onChange={() => {
                    changeCompany();
                    createStandardName();
                  }}
                  error={companyError}
                />
              </div>
              <span class="editBoxTitle requiredElement">Product</span>
              <div class="editBoxInput containerBox">
                <DialogSpanFullEdit
                  color={backgroundColorInput}
                  height="22"
                  border={borderColor}
                  error={productError}
                  bind:text={currentStandard.product}
                  onChange={() => createStandardName()}
                />
              </div>
              <span class="editBoxTitle requiredElement">Version</span>
              <div class="editBoxInput containerBox">
                <DialogSpanFullEdit
                  color={backgroundColorInput}
                  height="22"
                  border={borderColor}
                  error={versionError}
                  bind:text={currentStandard.version}
                  onChange={() => createStandardName()}
                />
              </div>
              <span class="editBoxTitle requiredElement">Location</span>
              <div class="editBoxInput containerBox">
                <DialogSpanFullEdit
                  color={backgroundColorInput}
                  height="22"
                  border={borderColor}
                  error={locationError}
                  bind:text={currentStandard.location}
                  onChange={() => createStandardName()}
                />
              </div>
              <span class="editBoxTitle">Revit Link</span>
              <div class="editBoxInput containerBox">
                <DialogSpanFullEdit
                  color={backgroundColorInput}
                  height="22"
                  border={borderColor}
                  error={false}
                  bind:text={currentStandard.revitLink}
                />
              </div>
            </div>
            <div class="editBoxColumn">
              <div class="editBoxTab">
                <EditBoxTab
                  icon="/images/factory-blue.svg"
                  text="PROJECT PREVIEW"
                />
              </div>
              <span class="editBoxTitle">Project List</span>
              <div class="editBoxInput containerBox">
                <Combobox
                  bind:selectedValue={projectsOptionsSel}
                  options={projectsOptions}
                  backgroundColor={backgroundColorInput}
                />
              </div>
              <div class="editBoxInput">
                <EditBoxButton text="Add" onClick={() => addProject()} />
              </div>
            </div>
            <div class="editBoxColumn">
              <div class="editBoxTabEmpty" />
              <span class="editBoxTitle">Projects</span>
              {#each currentStandard.projects as project, index}
                <StandardProjectItem
                  name={project.value.name}
                  timeLabitCode={project.value.timeLabitCode}
                  folderLabitCode={project.value.folderLabitCode}
                  onRemove={() => removeProject(index)}
                />
              {/each}
            </div>
          </div>

          <!-- Standard Programs -->

          <div class="editBoxPage {currentPage !== 1 ? 'hiddenElement' : ''}">
            <div class="editBoxColumn">
              <div class="editBoxTab">
                <EditBoxTab icon="/images/program.svg" text="PROGRAMMES" />
              </div>
              <div
                class="copy {showStandardForm === 'new' ? '' : 'hiddenElement'}"
              >
                <span class="editBoxTitle">Copy from Standard</span>
                <div class="editBoxInput containerBox">
                  <Combobox
                    bind:selectedValue={standardListOptionsSel}
                    options={standardListOptions}
                    backgroundColor={backgroundColorInput}
                    onChange={null}
                  />
                </div>
                <div class="editBoxAcceptCancel">
                  <EditBoxButton text="Load" onClick={() => copyStandard()} />
                </div>
              </div>
              <div class="editBoxSection">
                {#each currentStandard.programs as program}
                  <StandardProjectProgram
                    {program}
                    onClick={() => selectProgram(program)}
                    onEdit={() => showProgram("edit", program)}
                    onRemove={() => removeProgram(program.id)}
                    {removingProgram}
                    selected={selectedProgram &&
                      selectedProgram.id === program.id}
                  />
                {/each}
                <div class="addOption">
                  <img
                    class="fadedButton"
                    src="/images/plus.svg"
                    alt="Add Program"
                    on:click={() => toggleProgram()}
                  />
                </div>
              </div>
              <div
                class="programForm {showProgramForm !== ''
                  ? ''
                  : 'hiddenElement'}"
              >
                <VerticalSpacer height="20" />
                <span class="editBoxTitle">Name</span>
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color={backgroundColorInput}
                    height="22"
                    border={borderColor}
                    error={currentProgramNameError !== ""}
                    bind:text={currentProgramName}
                  />
                </div>
                <div class="programResources">
                  <div class="programResourcesLeft">
                    <span class="editBoxTitle">Color</span>
                    <div class="colorpicker">
                      <ColorPicker bind:currentColor {defaultColors} />
                    </div>
                  </div>
                  <div class="programResourcesRight">
                    <span class="editBoxTitle">Icon</span>
                    <div class="programIcon">
                      <FileUploader
                        bind:fileInfo={currentIcon}
                        loadFromPath={currentIconPath}
                        picExtraStyle="border-radius: 10px"
                        showFullImage={true}
                      />
                    </div>
                    <span class="editBoxTitle">Preview</span>
                    <div class="previewIcon">
                      <ProgramIcon
                        image={currentIcon && currentIcon.data
                          ? currentIcon.data
                          : null}
                        color={currentColor}
                      />
                    </div>
                  </div>
                </div>
                {#if currentIconError !== ""}
                  <div class="editBoxError">
                    <span>{currentIconError}</span>
                  </div>
                {/if}
                <div class="editBoxAcceptCancel">
                  <EditBoxButton
                    text="Save"
                    onClick={showProgramForm === "new"
                      ? () => addProgram()
                      : () => editProgram()}
                  />
                  <EditBoxButton text="Close" onClick={() => showProgram("")} />
                </div>
              </div>
            </div>
            <div class="editBoxColumn">
              <div class="editBoxTab">
                <EditBoxTab icon="/images/space.svg" text="SPACES" />
              </div>
              <div
                class="selectedColumn {selectedProgram ? '' : 'hiddenElement'}"
              >
                <div class="editBoxSection">
                  {#if selectedProgram}
                    {#each selectedProgram.spaces as space}
                      <StandardProjectSpaceRoom
                        spaceRoom={space}
                        type="space"
                        onClick={() => selectSpace(space)}
                        onEdit={() => showSpace("edit", space)}
                        onRemove={() => removeSpace(space.id)}
                        removingSpaceRoom={removingSpace}
                        selected={selectedSpace &&
                          selectedSpace.id === space.id}
                      />
                    {/each}
                  {/if}
                  <div class="addOption">
                    <img
                      class="fadedButton"
                      src="/images/plus.svg"
                      alt="Add Space"
                      on:click={() => toggleSpace()}
                    />
                  </div>
                </div>
                <div
                  class="programForm {showSpaceForm !== ''
                    ? ''
                    : 'hiddenElement'}"
                >
                  <VerticalSpacer height="20" />
                  <span class="editBoxTitle">Name</span>
                  <div class="editBoxInput containerBox">
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      error={currentSpaceNameError !== ""}
                      bind:text={currentSpaceName}
                    />
                  </div>
                  <div class="editBoxAcceptCancel">
                    <EditBoxButton
                      text="Save"
                      onClick={showSpaceForm === "new"
                        ? () => addSpace()
                        : () => editSpace()}
                    />
                    <EditBoxButton text="Close" onClick={() => showSpace("")} />
                  </div>
                </div>
              </div>
            </div>
            <div class="editBoxColumn">
              <div class="editBoxTab">
                <EditBoxTab icon="/images/room.svg" text="ROOMS" />
              </div>
              <div
                class="selectedColumn {selectedSpace ? '' : 'hiddenElement'}"
              >
                <div class="editBoxSection">
                  {#if selectedSpace}
                    {#each selectedSpace.rooms as room}
                      <StandardProjectSpaceRoom
                        spaceRoom={room}
                        type="room"
                        onClick={null}
                        onEdit={() => showRoom("edit", room)}
                        onRemove={() => removeRoom(room.id)}
                        removingSpaceRoom={removingRoom}
                      />
                    {/each}
                  {/if}
                  <div class="addOption">
                    <img
                      class="fadedButton"
                      src="/images/plus.svg"
                      alt="Add Room"
                      on:click={() => toggleRoom()}
                    />
                  </div>
                </div>
                <div
                  class="programForm {showRoomForm !== ''
                    ? ''
                    : 'hiddenElement'}"
                >
                  <VerticalSpacer height="20" />
                  <span class="editBoxTitle">Name</span>
                  <div class="editBoxInput containerBox">
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      error={currentRoomNameError !== ""}
                      bind:text={currentRoomName}
                    />
                  </div>
                  <span class="editBoxTitle">Room Code</span>
                  <div class="editBoxInput containerBox">
                    <DialogSpanFullEdit
                      color={backgroundColorInput}
                      height="22"
                      border={borderColor}
                      error={currentRoomCodeError !== ""}
                      bind:text={currentRoomCode}
                    />
                  </div>
                  <span class="editBoxTitle">Description</span>
                  <div class="editBoxTextarea containerBox">
                    <AnalyticsTextArea bind:text={currentRoomDescription} />
                  </div>
                  <div class="editBoxAcceptCancel">
                    <EditBoxButton
                      text="Save"
                      onClick={showRoomForm === "new"
                        ? () => addRoom()
                        : () => editRoom()}
                    />
                    <EditBoxButton text="Close" onClick={() => showRoom("")} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Pager -->

          <!--<div class="pager">
            <BoxEditPager
              numPages={NUM_PAGES}
              page={currentPage}
              clicks={[() => setPage(0), () => setPage(1)]}
            />
          </div>-->
        </div>
        <div class="editBoxButtons">
          <!--<EditBoxFormButton
            icon="/images/left-arrow.svg"
            onClick={() => previousPage()}
          />
          <EditBoxFormButton
            icon="/images/right-arrow.svg"
            onClick={() => nextPage()}
          />-->
          <EditBoxFormButton
            icon="/images/x.svg"
            onClick={() => showForm("")}
            buttonHeight="342px"
          />
          <EditBoxFormButton
            icon="/images/save.svg"
            buttonHeight="342px"
            onClick={() => {
              if (showStandardForm === "new") {
                insertStandard();
              } else {
                updateStandard();
              }
            }}
          />
        </div>
      </div>
    </div>

    <div class="standards {showStandardForm !== '' ? 'hiddenElement' : ''}">
      <div class="standardTitles {loading ? 'hiddenElement' : ''}">
        <div class="standardInfo">
          <img src="/images/book.svg" alt="Standard Icon" />
          <span>CLIENT</span>
          <span>PRODUCT</span>
          <span>LOCATION</span>
          <span>LB VERSION</span>
        </div>
        <div class="projectInfo">
          <img src="/images/factory.svg" alt="Project Icon" />
          <span>CODE</span>
          <span>CODE</span>
          <span>NAME</span>
        </div>
        <div class="projectManager">
          <img src="/images/user.svg" alt="Project Manager Icon" />
          <span>PROJECT MANAGER</span>
        </div>
      </div>
      {#each standards as standard}
        {#if standard.visible}
          <StandardItem
            {standard}
            onEdit={() => {
              showForm("edit", standard.id);
            }}
            onRemove={() => {
              selectedStandard = standard;
              showRemove(true);
            }}
          />
        {/if}
      {/each}
    </div>
  </div>
{/if}

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin-top: 20px;
    color: var(--labit-darkgray);
  }

  div.optionsRows {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    padding-right: 13px;
    justify-content: space-between;
  }

  div.optionsColumn2 {
    display: flex;
  }

  div.optionsSearch {
    display: flex;
    flex-grow: 1;
    height: 21px;
    align-items: center;
  }

  div.optionsSearch span {
    margin-right: 13px;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  div.standards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    flex-direction: column;
  }

  div.plusButton {
    width: 122px;
    background-color: var(--labit-dialog-background);
    border-radius: 10px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  div.plusButton img {
    width: 30px;
    height: 30px;
  }

  div.editBox {
    width: 100%;
    margin-top: 20px;
  }

  div.editBoxTabs {
    width: 1510px;
    display: flex;
    margin-bottom: 8px;
    padding: 0px 49px 0px 36px;
    justify-content: space-between;
  }

  div.editBoxTab {
    width: 428px;
    margin-bottom: 25px;
  }

  div.editBoxTabEmpty {
    width: 428px;
    margin-bottom: 55px;
  }

  div.editBoxInfo {
    width: 100%;
    display: flex;
    min-height: 710px !important;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: -20px;
  }

  div.editBoxInfoInfo {
    background-color: #d7e1ea;
    padding: 41px 49px 20px 36px;
    border-radius: 15px;
    width: 1510px;
    display: flex;
    flex-direction: column;
  }

  div.editBoxLoader {
    width: 100%;
    display: flex;
    min-height: 710px !important;
    justify-content: center;
    align-items: center;
    background-color: #d7e1ea;
    border-radius: 15px;
  }

  div.editBoxButtons {
    display: flex;
    flex-direction: column;
  }

  div.editBoxPage {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }

  div.editBoxColumn {
    width: 428px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  span.editBoxTitle {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 10px;
    width: 100%;
  }

  span.editBoxTitleName {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 20px;
    width: 100%;
  }

  div.editBoxInput {
    height: 22px;
    margin-bottom: 10px;
  }

  div.editBoxSection {
    width: 100%;
    border-radius: 15px;
    border: 1px solid var(--labit-card-gray);
    padding: 15px 23px 15px 23px;
  }

  div.addOption {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  div.addOption img {
    width: 20px;
  }

  div.programResources {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  div.programResourcesLeft {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  div.programResourcesRight {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.colorpicker {
    width: 100%;
    height: 250px;
  }

  div.programIcon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  div.previewIcon {
    width: 100px;
    height: 100px;
  }

  div.editBoxAcceptCancel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.editBoxError {
    width: 100%;
    font-size: 12px;
    display: flex;
    color: red;
    margin: -10px 0px 20px 0px;
  }

  div.programForm {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.selectedColumn {
    display: flex;
    flex-direction: column;
  }

  div.editBoxTextarea {
    margin-bottom: 10px;
  }

  div.loader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  div.copy {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  div.standardTitles {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-gray);
    align-items: flex-start;
    font-weight: bold;
    padding: 0px 26px 0px 26px;
    display: flex;
    margin-bottom: 19px;
  }

  div.standardTitles div.standardInfo {
    width: 35%;
    display: flex;
    align-items: center;
  }

  div.standardTitles div.standardInfo span {
    margin-right: 13px;
  }

  div.standardTitles img {
    height: 21px;
    margin-right: 13px;
  }

  div.standardTitles div.projectInfo {
    width: 25%;
    display: flex;
    align-items: center;
  }

  div.standardTitles div.projectInfo span {
    margin-right: 13px;
  }

  div.standardTitles div.projectManager {
    width: 15%;
    display: flex;
    align-items: center;
  }

  div.editBar {
    width: 100%;
    margin-top: 20px;
  }

  /* Animations */

  div.plusButton {
    transition: opacity 0.5s linear 0s;
  }

  div.plusButton:hover {
    opacity: 0.5;
  }
</style>
