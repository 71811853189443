<script>
  export let platform;

  import ButtonIcon from "./ButtonIcon.svelte";
  import moment from 'moment';
  import { onMount } from "svelte";
  import { post, LABIT_REST_SERVER } from "../js/labit-connection";
  import { getToken } from "../js/localInfo";

  let version = "";
  let date = "";

  const SKYLAB_PATH = "/skylab-versions/";

  onMount(async () => {
    const response = await post(
      `select version, date
                from skylabVersions
                where platform='${platform}'`
    );
    version = response[0].version;
    date = moment(response[0].date, "YYYY-MM-DD").format("DD/MM/YYYY");
  });

  let clickDownload = () => {
    /*console.log(
            LABIT_REST_SERVER +
                "DownloadFile.php?url=" +
                btoa(
                    unescape(
                        encodeURIComponent(
                            SKYLAB_PATH + "skylab-" + platform + ".zip"
                        )
                    )
                ) +
                "&token=" +
                getToken()
        );*/
    location.href =
      LABIT_REST_SERVER +
      "DownloadFile.php?url=" +
      btoa(
        unescape(
          encodeURIComponent(SKYLAB_PATH + "skylab-" + platform + ".zip")
        )
      ) +
      "&token=" +
      getToken();
  };
</script>

<div class="skylabVersionContainer">
  <img
    class="platformIcon"
    src={platform === "windows" ? "/images/windows.svg" : "/images/apple.svg"}
    alt="Platform"
  />

  <span class="title">SKYLAB</span>
  <span>{version}</span>
  <span>{date}</span>
  <div class="download">
    <ButtonIcon
      text="Download"
      img="/images/download-version-white.svg"
      backgroundColor="var(--labit-black-button)"
      textColor="white"
      width="120px"
      height="30px"
      fontSize="14px"
      onClick={() => clickDownload()}
    />
  </div>
</div>

<style>
  div.skylabVersionContainer {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img.platformIcon {
    width: 120px;
  }

  span {
    font-size: 14px;
    margin-top: 15px;
    color: var(--labit-card-gray);
  }

  span.title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 60px;
  }

  div.download {
    margin-top: 60px;
  }
</style>
