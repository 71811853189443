<script>
  export let onClose;

  import DialogButton from "../components/DialogButton.svelte";
  import { openURL } from "../js/openURL";
</script>

<div class="dialogContent">
  <div class="card">
    <img
      class="close"
      src="/images/dialog_x.svg"
      alt="Close"
      on:click={onClose}
    />
    <div class="new">
      <div class="newContainer">
        <span>Select new category</span>
        <div class="button">
          <DialogButton
            text="CLIENT"
            onClick={() => openURL("/people/create-people", true)}
          />
        </div>
        <div class="button">
          <DialogButton
            text="COMPANY"
            onClick={() => openURL("/people/create-company", true)}
          />
        </div>
        <div class="button">
          <DialogButton text="CONSULTANT" onClick={null} disabled={true} />
        </div>
        <div class="button">
          <DialogButton text="LANDLORD" onClick={null} disabled={true} />
        </div>
        <div class="button">
          <DialogButton text="SERVICE" onClick={null} disabled={true} />
        </div>
        <div class="button">
          <DialogButton text="TEAM" onClick={null} disabled={true} />
        </div>
      </div>
    </div>
    <div class="back" on:click={onClose}>
      <img class="icon" src="/images/remove-back.svg" alt="Back Icon" />
    </div>
  </div>
</div>

<style>
  div.dialogContent {
    background-color: var(--labit-background-gray);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
  }

  div.dialogContent:hover {
    width: 100%;
    height: 100%;
  }

  div.card {
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    width: 1640px;
    height: 902px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  div.new {
    width: 372px;
    height: 372px;
    background-color: white;
    border-radius: 19px;
    margin-bottom: 21px;
    margin-top: 106px;
  }

  div.newContainer {
    width: 100%;
    height: 100%;
    padding-left: 18.8%;
    padding-right: 18.8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.newContainer span {
    font-size: 13px;
    color: var(--labit-card-gray);
    font-weight: bold;
    margin-bottom: 29px;
  }

  div.back {
    width: 372px;
    height: 372px;
    background-color: var(--labit-card-gray);
    border-radius: 19px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.cardButtons div img {
    height: 100%;
    cursor: pointer;
  }

  img.close {
    position: absolute;
    width: 12px;
    top: 12px;
    right: 25px;
    cursor: pointer;
  }

  div.button {
    width: 100%;
    height: 7.2%;
    margin-bottom: 8px;
  }

  img.icon {
    height: 52px;
  }

  /* Animations */

  div.back {
    transition: opacity 0.5s linear 0s;
  }

  div.back:hover {
    opacity: 0.5;
  }

  img.close {
    transition: opacity 0.5s linear 0s;
  }

  img.close:hover {
    opacity: 0.5;
  }

  a {
    text-decoration: none;
  }
</style>
