<script>
  export let relatedProjects;
  export let action;

  import DialogRelatedProjectsItem from "./DialogRelatedProjectsItem.svelte";
  import Combobox from "./Combobox.svelte";

  import { post } from "../js/labit-connection";

  import { onMount } from "svelte";

  let projects = [];
  let selectedProject = null;

  onMount(async () => {
    const response = await post(
      `select project_id, Name
        from projects
        order by Name asc`
    );
    projects = response.map((project) => {
      return {
        value: project.project_id,
        label: project.Name,
      };
    });
    selectedProject = projects[0];
  });

  let addRelation = () => {
    const pos = relatedProjects.findIndex((rp) => {
      return rp.id === selectedProject.value;
    });
    if (pos === -1) {
      relatedProjects.push({
        id: selectedProject.value,
        name: selectedProject.label,
      });
      relatedProjects = relatedProjects;
    }
  };

  let removeRelation = (index) => {
    relatedProjects.splice(index, 1);
    relatedProjects = relatedProjects;
  };
</script>

<div class="dialogRelatedProjectContainerContainer">
  <div
    class="relatedProjects {action !== 'edit'
      ? 'customScrollbars scrolled'
      : ''}"
  >
    {#each relatedProjects as relatedProject, index}
      <DialogRelatedProjectsItem
        {relatedProject}
        {action}
        onRemove={() => removeRelation(index)}
      />
    {/each}
  </div>
  {#if action !== "show"}
    <div class="addRelation">
      <div class="combobox">
        <Combobox
          options={projects}
          placeholder=""
          bind:selectedValue={selectedProject}
        />
      </div>
      <img
        src="/images/plus.svg"
        alt="Add Relation"
        on:click={() => addRelation()}
      />
    </div>
  {/if}
</div>

<style>
  div.dialogRelatedProjectContainerContainer {
    width: 100%;
    margin-top: 2px;
  }

  div.relatedProjects {
    width: 100%;
  }

  div.addRelation {
    width: 100%;
    display: flex;
    align-items: center;
  }

  div.addRelation img {
    width: 17px;
    height: 17px;
    cursor: pointer;
  }

  div.scrolled {
    /*max-height: 115px;*/
    overflow-x: hidden;
    overflow-y: auto;
  }

  div.combobox {
    flex-grow: 1;
    height: 17px;
    margin-top: 3px;
    margin-right: 6px;
  }

  div.hidden {
    display: none;
  }

  /* Animations */

  div.addRelation img {
    transition: opacity 0.5s linear 0s;
  }

  div.addRelation img:hover {
    opacity: 0.5;
  }
</style>
