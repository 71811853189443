<script>
  export let dayName;
  export let dayNumber;
  export let dayColor;
  export let numberColor;
</script>

<style>
  :root {
    --color: blue;
  }

  div.dayContainer {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  div.circleToday {
    background-color: var(--color);
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  div.dayName {
    display: flex;
    align-items: center;
  }

  div.dayName span {
    font-weight: bold;
    font-size: 15px;
  }

  div.circleToday span {
    font-weight: bold;
    font-size: 15px;
  }
</style>

<div class="dayContainer">
  <div class="dayName">
    <span>{dayName}</span>
  </div>
  <div
    class="circleToday"
    style="background-color: {dayColor}; color: {numberColor}">
    <span>{dayNumber}</span>
  </div>
</div>
