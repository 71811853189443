export const defaultTeamRoles = [
  "Partner",
  "Project Manager",
  "Senior Architect",
  "Architect",
  "Analytics Coordinator",
  "BOQ",
  "MEP Support",
  "Model Maker",
  "Visualiser",
  "Reality Capture",
  "Presentations",
];
