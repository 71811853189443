<script>
  import { createEventDispatcher } from "svelte";
  import Combobox from "../../../../components/Combobox.svelte";
  import SuggestionEdit from "../../../../components/SuggestionEdit.svelte";
  import ComboboxEvent from "../components/ComboboxEvent.svelte";
  import DialogSpanCalendarPickerEvent from "../components/DialogSpanCalendarPickerEvent.svelte";
  import {
    getRole,
    getUserId,
    getOutlookToken,
  } from "../../../../js/localInfo";
  import { post } from "../../../../js/labit-connection";
  import { copyObject } from "../../../../js/copyObject";
  import { onMount, afterUpdate } from "svelte";
  import {
    outlookLogin,
    outlookGetCalendar,
    outlookGetCalendars,
    outlookCreateEvent,
    outlookEditEvent,
    outlookDeleteEvent,
  } from "../../../../components/dashboard/outlook/outlook";
  import DialogSpanEdit from "../../../../components/DialogSpanEdit.svelte";
  import DialogSpanCalendarPicker from "../../../../components/DialogSpanCalendarPicker.svelte";

  import moment from 'moment';
  
  const HOURS = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
  const MINS = ["00", "15", "30", "45"];
  const LOCATIONS_LIST = [
    "Sala de reuniones 01",
    "Sala de reuniones 02",
    "ZOOM",
    "Despacho pequeño",
    "Despacho mediano",
    "Despacho grande",
  ];
  const action = "edit";
  const emptyEvent = {
    id: "",
    title: "",
    description: "",
    type: "",
    startDate: moment().format("DD/MM/YYYY"),
    endDate: moment().format("DD/MM/YYYY"),
    startTime: "",
    startTimeH: "00",
    startTimeM: "00",
    endTime: "",
    endTimeH: "00",
    endTimeM: "00",
    allDay: false,
    location: "",
    createdBy: "",
    creationDate: "",
    labitPeople: [],
    people: [],
    outlookCalendar: "",
    outlookEventType: "",
    externalPeopleList: [],
    externalPeople: "",
  };

  export let modifyCallback = null;
  export let visible;
  export let eventForm;
  export let currentEvent;
  export let op; // create, edit
  export let eventType;
  export let outError;
  export let onLogin;
  export let opErrorMessage;
  export let opError;

  let myId = getUserId();
  let errorTitle = false;
  let errorDescription = false;
  let errorLocation = false;
  let errorStartTime = false;
  let errorEndTime = false;
  let errorStartDate = false;
  let errorEndDate = false;
  let errorCalendar = false;
  let activeTask = false;
  let activeMeeting = false;
  let activeGeneral = false;
  let activeAbsence = false;
  let eventRemoved = 0;
  /* let activeButton = false; */
  /*   let taskOptionSelected = null;
  let taskOptionsCombobox = []; */
  let meetingOptionSelected = null;
  let meetingOptionsCombobox = [];
  let absenceOptionSelected = null;
  let taskPeopleOptionSelected = null;
  let absenceOptionsCombobox = [];
  let taskPeopleOptionsCombobox = [];
  let peopleSharedEvent = [];
  let viewForm = eventForm;
  let viewEventMenu = visible;
  let labitPeople = [];
  let people = [];
  let myOutlookCalendars = [];
  let myOutlookCalendarSelected = null;
  let outlookCalendars = null;
  /*   let fullComboboxEvent = false;
  let menuComboboxEvent = false; */
  let outlookError = false;

  onMount(async () => {
    if (op === "create") {
      currentEvent = copyObject(emptyEvent);
      viewForm = false;
      activeAbsence = false;
      activeTask = false;
      activeMeeting = false;
      activeGeneral = false;
    } else if (op === "edit") {
      viewEventMenu = true;
      viewForm = true;
    }

    loadMeetingData();
    /*     loadTaskPeopleData(); */
    loadAbsenceData();
    if (!outError) {
      loadOutlookCalendars();
    }
  });

  let toggleAllDay = () => {
    currentEvent.allDay = !currentEvent.allDay;
    currentEvent.allDay = currentEvent.allDay;
  };

  let loadOutlookCalendars = async () => {
    await outlookGetCalendars(
      (calendars) => {
        console.log("CALS: ", calendars);
        outlookCalendars = calendars;
      },
      () => {
        outlookError = true;
      }
    );
    myOutlookCalendars = outlookCalendars.reduce((acc, curr) => {
      acc.push({
        value: curr.id,
        label: curr.name,
        outlookCalendarType: curr.outlookCalendarType,
        additionalMail: curr.additionalMail,
      });
      return acc;
    }, []);

    myOutlookCalendars.sort(function (a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });

    // If the "Calendar" calendar exists, select from start.
    const cal = myOutlookCalendars.findIndex((c) => {
      return c.label === "Calendar";
    });

    myOutlookCalendarSelected =
      cal !== -1 ? myOutlookCalendars[cal] : myOutlookCalendars[0];

    currentEvent.outlookCalendar = myOutlookCalendarSelected;
    currentEvent = currentEvent;
  };

  let clickEventType = (type) => {
    errorTitle = false;
    errorDescription = false;
    errorLocation = false;
    errorStartTime = false;
    errorEndTime = false;
    errorStartDate = false;
    errorEndDate = false;
    errorCalendar = false;
    viewForm = true;
    eventType = type;
    /*     if (eventType === "task") {
      if (!activeTask) {
        activeTask = !activeTask;
      }
      activeMeeting = false;
      activeGeneral = false;
      activeAbsence = false;
      currentEvent.type = "task";
    } else */ if (eventType === "meeting") {
      if (!activeMeeting) {
        activeMeeting = !activeMeeting;
      }
      activeTask = false;
      activeGeneral = false;
      activeAbsence = false;
      currentEvent.type = "meeting";
    } else if (eventType === "general") {
      if (!activeGeneral) {
        activeGeneral = !activeGeneral;
      }
      activeTask = false;
      activeMeeting = false;
      activeAbsence = false;
      currentEvent.type = "general";
    } else if ((eventType = "absence")) {
      if (!activeAbsence) {
        activeAbsence = !activeAbsence;
      }

      activeTask = false;
      activeMeeting = false;
      activeGeneral = false;
      currentEvent.type = "absence";
    }
  };

  /*   let loadTaskPeopleData = async () => {
    let activeAndLabitPeople = await post(
      `SELECT p.Name as pName, contact_id, p.Email
        FROM people as p
        LEFT JOIN companies as c on p.company_id=c.company_id
        WHERE p.active=1 and c.esLabit=1 and contact_id != ` + myId
    );
    taskPeopleOptionsCombobox = activeAndLabitPeople.map(p => {
      return {
        value: p.Email,
        label: p.pName
      };
    });

    taskPeopleOptionsCombobox.sort(function(a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });

    taskPeopleOptionSelected = taskPeopleOptionsCombobox[0];
  }; */

  let loadMeetingData = async () => {
    // If type is meeting, load active and Labit people
    let activeAndLabitPeople = await post(
      `SELECT Name, contact_id, Email
        FROM people
        WHERE active=1 and contact_id != ` + myId
    );
    meetingOptionsCombobox = activeAndLabitPeople.map((p) => {
      return {
        value: p.Email,
        label: p.Name,
      };
    });

    meetingOptionsCombobox.sort(function (a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });

    meetingOptionSelected = meetingOptionsCombobox[0];
  };

  let loadAbsenceData = async () => {
    let activeSkylabPeople = await post(
      `SELECT Name, contact_id
        FROM people
        WHERE active=1 and contact_id != ` + myId
    );
    absenceOptionsCombobox = activeSkylabPeople.map((p) => {
      return {
        value: p.contact_id,
        label: p.Name,
      };
    });

    absenceOptionsCombobox.sort(function (a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });

    absenceOptionSelected = absenceOptionsCombobox[0];
  };

  /*   let findWithAttr = (array, attr, value) => {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }; */

  let insertIntoMeetingPeople = () => {
    const contact = currentEvent.labitPeople.find((item) => {
      return item.value === meetingOptionSelected.value;
    });
    if (!contact) {
      currentEvent.people.push(meetingOptionSelected);
      currentEvent.people.sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
      currentEvent = currentEvent;
    }
  };

  let deleteFromMeetingPeople = (pos) => {
    currentEvent.people.splice(pos, 1);
    currentEvent = currentEvent;
  };

  let insertIntoMeetingExternalPeopleList = () => {
    const externalEmail = {
      value: currentEvent.externalPeople,
      label: "EXTERNAL-PERSON",
    };
    const contact = currentEvent.externalPeopleList.find((item) => {
      return item.value === externalEmail.value;
    });
    if (!contact) {
      currentEvent.externalPeopleList.push(externalEmail);
      currentEvent.externalPeopleList.sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
      currentEvent = currentEvent;
    }
  };

  let deleteFromMeetingExternalPeopleList = (pos) => {
    currentEvent.externalPeopleList.splice(pos, 1);
    currentEvent = currentEvent;
  };

  /*   let insertIntoTaskLabitPeople = () => {
    const contact = currentEvent.labitPeople.find(item => {
      return item.value === taskPeopleOptionSelected.value;
    });
    if (!contact) {
      currentEvent.labitPeople.push(taskPeopleOptionSelected);
      currentEvent.labitPeople.sort(function(a, b) {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
      currentEvent = currentEvent;
    }
  }; */

  /*   let deleteFromTaskLabitPeople = pos => {
    currentEvent.labitPeople.splice(pos, 1);
    currentEvent = currentEvent;
  }; */

  let insertIntoPeople = () => {
    const contact = currentEvent.people.find((item) => {
      return item.value === absenceOptionSelected.value;
    });
    if (!contact) {
      currentEvent.people.push(absenceOptionSelected);
      currentEvent.people.sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
      currentEvent = currentEvent;
    }
  };

  let deleteFromPeople = (pos) => {
    currentEvent.people.splice(pos, 1);
    currentEvent = currentEvent;
  };

  let insertAbsenceEventDDBB = async () => {
    //Before inserting, one day must be added to endDate.
    let response = await post(
      `INSERT INTO events (description, type, startDate, endDate, allDay, startTime, endTime, createdBy, creationDate)
        VALUES ('${currentEvent.description}', '${
        currentEvent.type
      }', '${moment(currentEvent.startDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      )}','${moment(currentEvent.endDate, "DD/MM/YYYY")
        .add(1, "days")
        .format("YYYY-MM-DD")}', 
        ${currentEvent.allDay},'${currentEvent.startTime}','${
        currentEvent.endTime
      }', ${currentEvent.createdBy}, '${currentEvent.creationDate}')`
    );

    currentEvent.id = response[0];
    // Event shared with Skylab People
    /* if (currentEvent.people !== "") { */
    if (currentEvent.people.length !== 0) {
      for (let index = 0; index < currentEvent.people.length; index++) {
        let res = await post(
          `INSERT INTO eventsShared (contact, event)
        VALUES (${currentEvent.people[index].value}, ${currentEvent.id})`
        );
      }
    }
  };

  let updateAbsenceEventDDBB = async () => {
    let updateResponse = await post(
      `update events set description='${
        currentEvent.description
      }', startDate='${moment(currentEvent.startDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      )}', endDate='${moment(currentEvent.endDate, "DD/MM/YYYY")
        .add(1, "days")
        .format("YYYY-MM-DD")}', allDay=${currentEvent.allDay},startTime='${
        currentEvent.startTime
      }',endTime='${currentEvent.endTime}' where id=${currentEvent.id}`
    );

    // Update events shared with Skylab People
    for (let index = 0; index < currentEvent.backupPeople.length; index++) {
      let resDelete = await post(
        `delete from eventsShared
          where contact=${currentEvent.backupPeople[index].value} and event=${currentEvent.id}`
      );
    }
    for (let index = 0; index < currentEvent.people.length; index++) {
      let resInsert = await post(
        `insert into eventsShared (contact, event)
          values (${currentEvent.people[index].value}, ${currentEvent.id})`
      );
    }
  };

  let deleteAbsenceEventDDBB = async () => {
    let response = await post(
      `delete from events
        where id=${currentEvent.id}`
    );
  };

  /*   let validateTaskEvent = () => {
    errorTitle = false;
    errorStartDate = false;
    errorStartTime = false;
    errorEndTime = false;
    errorCalendar = false;
    if (!currentEvent.title) {
      errorTitle = true;
    }
    if (!currentEvent.startDate) {
      errorStartDate = true;
    }
    if (op === "create") {
      if (!currentEvent.outlookCalendar.value) {
        errorCalendar = true;
      }
    }
    if (!currentEvent.allDay) {
      if (
        currentEvent.startTimeH.value === currentEvent.endTimeH.value &&
        currentEvent.startTimeM.value === currentEvent.endTimeM.value
      ) {
        errorStartTime = true;
        errorEndTime = true;
      }
      if (
        parseInt(currentEvent.startTimeH.value) >
        parseInt(currentEvent.endTimeH.value)
      ) {
        errorEndTime = true;
      }
    }
    if (
      errorTitle ||
      errorStartDate ||
      errorStartTime ||
      errorEndTime ||
      errorCalendar
    ) {
      return false;
    } else {
      return true;
    }
  }; */

  let validateMeetingEvent = () => {
    errorTitle = false;
    errorStartDate = false;
    errorStartTime = false;
    errorEndTime = false;
    errorCalendar = false;
    if (!currentEvent.title) {
      errorTitle = true;
    }
    if (!currentEvent.startDate) {
      errorStartDate = true;
    }
    if (op === "create") {
      if (!currentEvent.outlookCalendar.value) {
        errorCalendar = true;
      }
    }
    if (
      currentEvent.startTimeH.value === currentEvent.endTimeH.value &&
      currentEvent.startTimeM.value === currentEvent.endTimeM.value
    ) {
      errorStartTime = true;
      errorEndTime = true;
    }
    if (
      parseInt(currentEvent.startTimeH.value) >
      parseInt(currentEvent.endTimeH.value)
    ) {
      errorEndTime = true;
    }

    if (
      errorTitle ||
      errorStartDate ||
      errorStartTime ||
      errorEndTime ||
      errorCalendar
    ) {
      return false;
    } else {
      return true;
    }
  };

  let validateGeneralEvent = () => {
    errorTitle = false;
    errorStartDate = false;
    errorStartTime = false;
    errorEndTime = false;
    errorCalendar = false;
    if (!currentEvent.title) {
      errorTitle = true;
    }
    if (!currentEvent.startDate) {
      errorStartDate = true;
    }
    if (op === "create") {
      if (!currentEvent.outlookCalendar.value) {
        errorCalendar = true;
      }
    }
    if (
      currentEvent.startTimeH.value === currentEvent.endTimeH.value &&
      currentEvent.startTimeM.value === currentEvent.endTimeM.value
    ) {
      errorStartTime = true;
      errorEndTime = true;
    }
    if (
      parseInt(currentEvent.startTimeH.value) >
      parseInt(currentEvent.endTimeH.value)
    ) {
      errorEndTime = true;
    }

    if (
      errorTitle ||
      errorStartDate ||
      errorStartTime ||
      errorEndTime ||
      errorLocation
    ) {
      return false;
    } else {
      return true;
    }
  };

  let validateExternalAbsence = () => {
    errorDescription = false;
    errorStartTime = false;
    errorEndTime = false;
    errorEndDate = false;

    if (!currentEvent.description) {
      errorDescription = true;
    }
    if (
      moment(currentEvent.startDate, "DD/MM/YYYY") >
      moment(currentEvent.endDate, "DD/MM/YYYY")
    ) {
      errorEndDate = true;
    }
    if (!currentEvent.allDay) {
      if (
        currentEvent.startTimeH.value === currentEvent.endTimeH.value &&
        currentEvent.startTimeM.value === currentEvent.endTimeM.value
      ) {
        errorStartTime = true;
        errorEndTime = true;
      }
      if (
        parseInt(currentEvent.startTimeH.value) >
        parseInt(currentEvent.endTimeH.value)
      ) {
        errorEndTime = true;
      }
    }

    if (errorDescription || errorStartTime || errorEndTime || errorEndDate) {
      return false;
    } else {
      return true;
    }
  };

  let acceptEvent = async () => {
    // update time values in the currentEvent.
    currentEvent.startTime =
      currentEvent.startTimeH.value + ":" + currentEvent.startTimeM.value;
    currentEvent.endTime =
      currentEvent.endTimeH.value + ":" + currentEvent.endTimeM.value;

    let validate = false;
    // Validate Event
    /*     if (eventType === "task") {
      validate = validateTaskEvent();
    } else */ if (eventType === "meeting") {
      validate = validateMeetingEvent();
    } else if (eventType === "general") {
      validate = validateGeneralEvent();
    } else if (eventType === "absence") {
      validate = validateExternalAbsence();
    }

    if (validate) {
      if (op === "create") {
        // Complete CurrentEvent
        /* activeButton = true; */
        // Assign Outlook event type
        currentEvent.outlookEventType = eventType;
        currentEvent.createdBy = myId;
        currentEvent.creationDate = moment().format("YYYY-MM-DD HH:mm:ss");
        // Asign endDate if not exist
        if (currentEvent.endDate === "") {
          currentEvent.endDate = currentEvent.startDate;
        }
        /*         if (eventType === "task") {
          peopleSharedEvent = currentEvent.labitPeople.map(p => {
            return {
              email: p.value,
              name: p.label
            };
          });
          currentEvent.endDate = currentEvent.startDate;

          await outlookCreateEvent(
            currentEvent.outlookCalendar.value,
            currentEvent.outlookCalendar.outlookCalendarType,
            currentEvent.title,
            currentEvent.description,
            currentEvent.startTime === ""
              ? moment(currentEvent.startDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )
              : moment(currentEvent.startDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ) +
                  "T" +
                  currentEvent.startTime,
            currentEvent.endTime === ""
              ? moment(currentEvent.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")
              : moment(currentEvent.endDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ) +
                  "T" +
                  currentEvent.endTime,
            "",
            currentEvent.allDay,
            peopleSharedEvent,
            currentEvent.outlookEventType,
            () => {},
            e => {
              opErrorMessage = e;
              opError = true;
            }
          );
        } else */ if (eventType === "meeting") {
          // formatting key fields-value when inserting outlook event
          peopleSharedEvent = currentEvent.people.map((p) => {
            return {
              email: p.value,
              name: p.label,
            };
          });

          let otherPeopleSharedEvent =
            currentEvent.externalPeopleList.length > 0
              ? currentEvent.externalPeopleList.map((p) => {
                  return { email: p.value, name: p.label };
                })
              : [];

          /* console.log(otherPeopleSharedEvent); */

          // Add additionalMail in External People
          /*           if (
            myOutlookCalendars.find(
              c => c.value === currentEvent.outlookCalendar.value
            ).additionalMail !== "" &&
            currentEvent.outlookCalendar.outlookCalendarType === "group"
          ) {
            otherPeopleSharedEvent = otherPeopleSharedEvent.concat([
              {
                email: myOutlookCalendars.find(
                  c => c.value === currentEvent.outlookCalendar.value
                ).additionalMail,
                name: "EXTERNAL-PERSON"
              }
            ]);
          } */

          currentEvent.endDate = currentEvent.startDate;
          await outlookCreateEvent(
            currentEvent.outlookCalendar.value,
            currentEvent.outlookCalendar.outlookCalendarType,
            currentEvent.title,
            currentEvent.description,
            currentEvent.startTime === ""
              ? moment(currentEvent.startDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )
              : moment(currentEvent.startDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ) +
                  "T" +
                  currentEvent.startTime,
            currentEvent.endTime === ""
              ? moment(currentEvent.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")
              : moment(currentEvent.endDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ) +
                  "T" +
                  currentEvent.endTime,
            currentEvent.location,
            currentEvent.allDay,
            otherPeopleSharedEvent.length > 0
              ? peopleSharedEvent.concat(otherPeopleSharedEvent)
              : peopleSharedEvent,
            currentEvent.outlookEventType,
            () => {},
            (e) => {
              opErrorMessage = e;
              opError = true;
            }
          );
        } else if (eventType === "general") {
          currentEvent.endDate = currentEvent.startDate;
          await outlookCreateEvent(
            currentEvent.outlookCalendar.value,
            currentEvent.outlookCalendar.outlookCalendarType,
            currentEvent.title,
            currentEvent.description,
            currentEvent.startTime === ""
              ? moment(currentEvent.startDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )
              : moment(currentEvent.startDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ) +
                  "T" +
                  currentEvent.startTime,
            currentEvent.endTime === ""
              ? moment(currentEvent.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")
              : moment(currentEvent.endDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ) +
                  "T" +
                  currentEvent.endTime,
            "",
            currentEvent.allDay,
            currentEvent.people,
            currentEvent.outlookEventType,
            [],
            () => {},
            (e) => {
              opErrorMessage = e;
              opError = true;
            }
          );
        } else if (eventType === "absence") {
          await insertAbsenceEventDDBB();
        }
        modifyCallback();
        restartValues();
      } else if (op === "edit") {
        // op is edit
        if (
          /* eventType === "task" || */
          eventType === "general" ||
          eventType === "meeting"
        ) {
          currentEvent.endDate = currentEvent.startDate;
          /* if (eventType === "task") {
            peopleSharedEvent = currentEvent.labitPeople.map(p => {
              return {
                email: p.value,
                name: p.label
              };
            });
          } else */ if (eventType === "meeting") {
            // formatting key fields-value when inserting outlook event
            peopleSharedEvent = currentEvent.people.map((p) => {
              return {
                email: p.value,
                name: p.label,
              };
            });
          }

          let otherPeopleSharedEvent = currentEvent.externalPeopleList.map(
            (p) => {
              return { email: p.value, name: p.label };
            }
          );

          await outlookEditEvent(
            currentEvent.id,
            currentEvent.idCalendar,
            currentEvent.outlookCalendarType,
            currentEvent.title,
            currentEvent.description,
            currentEvent.startTime === ""
              ? moment(currentEvent.startDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )
              : moment(currentEvent.startDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ) +
                  "T" +
                  currentEvent.startTime,
            currentEvent.endTime === ""
              ? moment(currentEvent.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")
              : moment(currentEvent.endDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ) +
                  "T" +
                  currentEvent.endTime,
            currentEvent.location,
            currentEvent.allDay,
            otherPeopleSharedEvent.length > 0
              ? peopleSharedEvent.concat(otherPeopleSharedEvent)
              : peopleSharedEvent,
            () => {},
            (e) => {
              opErrorMessage = e;
              opError = true;
            }
          );
        } else if (eventType === "absence") {
          await updateAbsenceEventDDBB();
        }
        modifyCallback();
        restartValues();
      }
    } else {
      console.log("Invalid event.");
    }
  };

  let deleteEvent = async () => {
    if (
      /* eventType === "task" || */
      eventType === "meeting" ||
      eventType === "general"
    ) {
      await outlookDeleteEvent(
        currentEvent.id,
        currentEvent.idCalendar,
        currentEvent.outlookCalendarType,
        () => {},
        (e) => {
          opErrorMessage = e;
          opError = true;
        }
      );
    } else if (eventType === "absence") {
      await deleteAbsenceEventDDBB();
    }
    modifyCallback();
    op = "create";
    viewForm = false;
    visible = false;
    viewEventMenu = false;
    currentEvent = copyObject(emptyEvent);
    eventRemoved = 0;
  };

  let restartValues = () => {
    window.$("div.tooltip").remove();
    /*     eventStartTime = { hour: "00", min: "00" };
    eventEndTime = { hour: "00", min: "00" }; */
    op = "create";
    activeAbsence = false;
    activeTask = false;
    activeMeeting = false;
    activeGeneral = false;
    currentEvent = copyObject(emptyEvent);
    viewForm = false;
    visible = false;
    viewEventMenu = false;
  };

  /*   $: {
    console.log("myOutlookCalendarSelected: ", myOutlookCalendarSelected);
    console.log("emptyEvent: ", currentEvent);
  } */
</script>

{#if viewEventMenu}
  <div class="createEvent">
    <div class="header">
      {#if op === "create"}
        <span>Select Event Type</span>
        <!--       {:else if eventType === 'task'}
        <span>Edit or Delete Task Event</span> -->
      {:else if eventType === "meeting"}
        <span>Edit or Delete Meeting Event</span>
      {:else if eventType === "general"}
        <span>Edit or Delete General Event</span>
      {:else if eventType === "absence"}
        <span>Edit or Delete External Absence Event</span>
      {/if}
    </div>
    {#if eventRemoved}
      <div class="deleteMessage">
        <span>
          Are you sure you would like to
          <b>delete</b>
          this event?
        </span>
        <!--         <span>
          (
          <u>Yes</u>
          /Delete,
          <u>No</u>
          /Cancel)
        </span> -->
      </div>
    {/if}
    {#if outError}
      <div class="outlookError" on:click={onLogin}>
        <span>
          Outlook connection failed or token expired. Click this box to retry.
        </span>
        <img src="/images/x.svg" alt="" on:click={(outError = false)} />
      </div>
    {/if}
    {#if op === "create"}
      <div class="eventCreationOptions">
        <!--         <span
          class="options {outError ? 'inactiveOutlook' : !outError && activeTask ? 'active' : 'inactive'}"
          on:click={() => (outError ? null : clickEventType('task'))}>
          Task
        </span> -->
        <span
          class="options {outError
            ? 'inactiveOutlook'
            : !outError && activeMeeting
            ? 'active'
            : 'inactive'}"
          on:click={() => (outError ? null : clickEventType("meeting"))}
        >
          Meeting
        </span>
        <span
          class="options {outError
            ? 'inactiveOutlook'
            : !outError && activeGeneral
            ? 'active'
            : 'inactive'}"
          on:click={() => (outError ? null : clickEventType("general"))}
        >
          Event
        </span>
        <span
          class="options {activeAbsence ? 'active' : 'inactive'}"
          on:click={() => clickEventType("absence")}
        >
          External Absence
        </span>
      </div>
    {/if}
    {#if op === "edit" || viewForm}
      <div class="eventForm">
        {#if eventType === "task" || eventType === "meeting" || eventType === "general"}
          {#if op !== "edit"}
            <div class="containerActiveTeamPeople">
              <label for="activeTeamPeople">Add to calendar...</label>
              <div class="containerComboboxImg">
                <ComboboxEvent
                  bind:selectedValue={currentEvent.outlookCalendar}
                  options={myOutlookCalendars}
                  backgroundColor="white"
                  error={errorCalendar}
                />
              </div>
            </div>
          {/if}
          <div class="containerTitle">
            <label for="title">Title</label>
            <DialogSpanEdit
              bind:text={currentEvent.title}
              {action}
              last={false}
              error={errorTitle}
            />
          </div>
        {/if}
        {#if eventType === "task" || eventType === "meeting" || eventType === "general" || eventType === "absence"}
          <div class="containerDescription">
            <label for="description">Description</label>
            <DialogSpanEdit
              bind:text={currentEvent.description}
              {action}
              last={false}
              error={errorDescription}
            />
          </div>
          <div class="containerStartDate">
            <label for="startDate">Date</label>
            <DialogSpanCalendarPickerEvent
              bind:text={currentEvent.startDate}
              calendarOptions={[]}
              {action}
              error={errorStartDate}
              last={false}
            />
          </div>
        {/if}
        <!--         {#if eventType === 'task'}
          <div class="yesNoAllDay">
            <span>All day?</span>
            {#if !currentEvent.allDay}
              <img
                class="yesNoImg"
                on:click={() => toggleAllDay()}
                src="images/checkbox_empty.svg"
                alt="yesAllDay" />
            {:else if currentEvent.allDay}
              <img
                class="yesNoImg"
                on:click={() => toggleAllDay()}
                src="images/checkbox.svg"
                alt="yesAllDay" />
            {/if}
          </div>
          {#if !currentEvent.allDay}
            <div class="containerStartTime">
              <label for="startTime">Start time</label>
              <div class="containerHoursAndMins">
                <div class="containerComboboxHourMin">
                  <ComboboxEvent
                    bind:selectedValue={currentEvent.startTimeH}
                    options={HOURS}
                    error={errorStartTime}
                    backgroundColor="white" />
                </div>
                <div class="containerComboboxHourMin">
                  <ComboboxEvent
                    bind:selectedValue={currentEvent.startTimeM}
                    options={MINS}
                    error={errorStartTime}
                    backgroundColor="white" />
                </div>
              </div>
            </div>
            <div class="containerEndTime">
              <label for="endTime">End time</label>
              <div class="containerHoursAndMins">
                <div class="containerComboboxHourMin">
                  <ComboboxEvent
                    bind:selectedValue={currentEvent.endTimeH}
                    options={HOURS}
                    error={errorEndTime}
                    backgroundColor="white" />
                </div>
                <div class="containerComboboxHourMin">
                  <ComboboxEvent
                    bind:selectedValue={currentEvent.endTimeM}
                    options={MINS}
                    error={errorEndTime}
                    backgroundColor="white" />
                </div>
              </div>
            </div>
          {/if}
          <div class="containerActiveTeamPeople">
            <label for="activeTeamPeople">Active Team People</label>
            <div class="containerComboboxImg">
              <Combobox
                bind:selectedValue={taskPeopleOptionSelected}
                options={taskPeopleOptionsCombobox}
                backgroundColor="white" />
              <img
                on:click={() => insertIntoTaskLabitPeople()}
                src="images/calendar/logo-new-20x20-34525B.svg"
                alt="Menu Icon" />
            </div>
            <div class="containerCombobox">
              {#each currentEvent.labitPeople as contact, index}
                <div class="contact">
                  <span>{contact.label}</span>
                  <img
                    class="cancelImg"
                    on:click={() => deleteFromTaskLabitPeople(index)}
                    src="images/x-red.svg"
                    alt="Menu Icon" />
                </div>
              {/each}
            </div>
          </div>
        {/if} -->
        {#if eventType === "meeting" || eventType === "general"}
          <div class="containerStartTime">
            <label for="startTime">Start time</label>
            <div class="containerHoursAndMins">
              <div class="containerComboboxHourMin">
                <ComboboxEvent
                  bind:selectedValue={currentEvent.startTimeH}
                  options={HOURS}
                  error={errorStartTime}
                  backgroundColor="white"
                />
              </div>
              <div class="containerComboboxHourMin">
                <ComboboxEvent
                  bind:selectedValue={currentEvent.startTimeM}
                  options={MINS}
                  error={errorStartTime}
                  backgroundColor="white"
                />
              </div>
            </div>
          </div>
          <div class="containerEndTime">
            <label for="endTime">End time</label>
            <div class="containerHoursAndMins">
              <div class="containerComboboxHourMin">
                <ComboboxEvent
                  bind:selectedValue={currentEvent.endTimeH}
                  options={HOURS}
                  error={errorEndTime}
                  backgroundColor="white"
                />
              </div>
              <div class="containerComboboxHourMin">
                <ComboboxEvent
                  bind:selectedValue={currentEvent.endTimeM}
                  options={MINS}
                  error={errorEndTime}
                  backgroundColor="white"
                />
              </div>
            </div>
          </div>
        {/if}
        {#if eventType === "meeting"}
          <div class="containerLocation">
            <label for="location">Location</label>
            <!--             <div class="containerComboboxImg">
              <ComboboxEvent
                bind:selectedValue={currentEvent.location}
                options={LOCATIONS_LIST}
                backgroundColor="white" />
            </div> -->
            <SuggestionEdit
              bind:text={currentEvent.location}
              placeholder={currentEvent.location}
              suggestions={LOCATIONS_LIST}
              height="17px"
              marginTop="0px"
              borderColor="var(--labit-card-lightgray)"
            />
          </div>
          <div class="containerActiveTeamPeople">
            <label for="activeTeamPeople">SkyLab People</label>
            <div class="containerComboboxImg">
              <Combobox
                bind:selectedValue={meetingOptionSelected}
                options={meetingOptionsCombobox}
                backgroundColor="white"
              />
              <img
                on:click={() => insertIntoMeetingPeople()}
                src="images/calendar/logo-new-20x20-34525B.svg"
                alt="Menu Icon"
              />
            </div>
            <div class="containerCombobox">
              {#each currentEvent.people as contact, index}
                <div class="contact">
                  <span>{contact.label}</span>
                  <img
                    class="cancelImg"
                    on:click={() => deleteFromMeetingPeople(index)}
                    src="images/x-red.svg"
                    alt="Menu Icon"
                  />
                </div>
              {/each}
            </div>
          </div>
          <div class="containerActiveTeamPeople">
            <label for="activeTeamPeople">External People</label>
            <div class="containerExternalPeople">
              <DialogSpanEdit
                bind:text={currentEvent.externalPeople}
                {action}
                last={false}
              />
              <img
                on:click={() => insertIntoMeetingExternalPeopleList()}
                src="images/calendar/logo-new-20x20-34525B.svg"
                alt="Menu Icon"
              />
            </div>
            <div class="containerCombobox">
              {#each currentEvent.externalPeopleList as contact, index}
                <div class="contact">
                  <span>{contact.value}</span>
                  <img
                    class="cancelImg"
                    on:click={() => deleteFromMeetingExternalPeopleList(index)}
                    src="images/x-red.svg"
                    alt="Menu Icon"
                  />
                </div>
              {/each}
            </div>
          </div>
        {/if}
        {#if eventType === "absence"}
          <div class="containerEndDate">
            <label for="endDate">End date</label>
            <DialogSpanCalendarPickerEvent
              bind:text={currentEvent.endDate}
              calendarOptions={[]}
              {action}
              error={errorEndDate}
              last={false}
            />
          </div>
          <div class="yesNoAllDay">
            <span>All day?</span>
            {#if !currentEvent.allDay}
              <img
                class="yesNoImg"
                on:click={() => toggleAllDay()}
                src="images/checkbox_empty.svg"
                alt="yesAllDay"
              />
            {:else if currentEvent.allDay}
              <img
                class="yesNoImg"
                on:click={() => toggleAllDay()}
                src="images/checkbox.svg"
                alt="yesAllDay"
              />
            {/if}
          </div>
          {#if !currentEvent.allDay}
            <div class="containerStartTime">
              <label for="startTime">Start time</label>
              <div class="containerHoursAndMins">
                <div class="containerComboboxHourMin">
                  <ComboboxEvent
                    bind:selectedValue={currentEvent.startTimeH}
                    options={HOURS}
                    error={errorStartTime}
                    backgroundColor="white"
                  />
                </div>
                <div class="containerComboboxHourMin">
                  <ComboboxEvent
                    bind:selectedValue={currentEvent.startTimeM}
                    options={MINS}
                    error={errorStartTime}
                    backgroundColor="white"
                  />
                </div>
              </div>
            </div>
            <div class="containerEndTime">
              <label for="endTime">End time</label>
              <div class="containerHoursAndMins">
                <div class="containerComboboxHourMin">
                  <ComboboxEvent
                    bind:selectedValue={currentEvent.endTimeH}
                    options={HOURS}
                    error={errorEndTime}
                    backgroundColor="white"
                  />
                </div>
                <div class="containerComboboxHourMin">
                  <ComboboxEvent
                    bind:selectedValue={currentEvent.endTimeM}
                    options={MINS}
                    error={errorEndTime}
                    backgroundColor="white"
                  />
                </div>
              </div>
            </div>
          {/if}
          <div class="containerSkylabPeople">
            <label for="skylabPeople">SkyLab People</label>
            <div class="containerComboboxImg">
              <Combobox
                bind:selectedValue={absenceOptionSelected}
                options={absenceOptionsCombobox}
                backgroundColor="white"
              />
              <img
                on:click={() => insertIntoPeople()}
                src="images/calendar/logo-new-20x20-34525B.svg"
                alt="Menu Icon"
              />
            </div>
            <div class="containerCombobox">
              {#each currentEvent.people as contact, index}
                <div class="contact">
                  <span>{contact.label}</span>
                  <img
                    class="cancelImg"
                    on:click={() => deleteFromPeople(index)}
                    src="images/x-red.svg"
                    alt="Menu Icon"
                  />
                </div>
              {/each}
            </div>
          </div>
        {/if}
        <div class="footerForm">
          <div class="button" on:click={() => acceptEvent()}>
            {#if op === "create"}Accept{:else}Edit{/if}
          </div>
          {#if op === "edit"}
            <div
              class={eventRemoved === 1 ? "deleteButton" : "button"}
              on:click={() => {
                if (eventRemoved === 0) {
                  eventRemoved = 1;
                } else if (eventRemoved === 1) {
                  deleteEvent();
                }
                op = "edit";
              }}
            >
              Delete
            </div>
          {/if}
          <div
            class="button"
            on:click={() => {
              if (eventRemoved == 1) {
                eventRemoved = 0;
              } else {
                restartValues();
              }
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    {/if}
  </div>
{/if}

<style>
  div.createEvent {
    width: 100%;
    margin-top: 16px;
    display: flex;
    border-radius: 19px;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  div.header {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f0f4f8;
    font-weight: bold;
    font-size: 15px;
  }

  div.footerForm {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-top: 1px solid #f0f4f8;
    margin-top: 10px;
  }

  div.eventCreationOptions {
    height: 96px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid #f0f4f8;
  }

  span.inactive {
    opacity: 0.5;
  }

  span.inactiveOutlook {
    opacity: 0.2;
    cursor: default;
  }

  span.inactive:hover {
    opacity: 1;
  }

  span.active {
    opacity: 1;
  }

  div.eventForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
  }

  div.containerDescription,
  div.containerTitle,
  div.containerLocation,
  div.containerStartDate,
  div.containerActiveTeamPeople,
  div.containerSkylabPeople,
  div.containerEndDate,
  div.containerStartTime,
  div.containerEndTime {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* margin-bottom: 10px; */
  }

  div.containerLocation,
  div.containerActiveTeamPeople {
    margin-bottom: 8px;
  }

  div.containerExternalPeople {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div.containerTitle label,
  div.containerDescription label,
  div.containerStartDate label,
  div.containerEndDate label,
  div.containerStartTime label,
  div.containerEndTime label,
  div.containerLocation label,
  div.containerSkylabPeople label,
  div.containerActiveTeamPeople label {
    width: 100%;
    font-size: 12px;
    color: var(--labit-card-gray);
  }

  /*   div.containerStartTime label,
  div.containerEndTime label {
    width: 50%;
    text-align: end;
  } */

  div.yesNoAllDay span {
    font-size: 12px;
    color: var(--labit-card-gray);
  }

  div.yesNoAllDay {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    cursor: pointer;
    align-items: center;
  }

  /*   div.containerStartTime input,
  div.containerEndTime input {
    margin-left: 10px;
  } */

  div.button,
  div.deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--fullcalendar-header-monthlycalendar);
    border-radius: 15px;
    width: 70px;
    padding: 5px;
    font-size: 15px;
    font-weight: bold;
  }
  div.deleteButton {
    /* border: 3px solid #a20000; */
    color: white;
    background-color: #991515;
  }

  div.containerCombobox {
    /*     margin-top: 10px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
  }
  div.containerComboboxImg {
    width: 100%;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*     margin-bottom: 8px; */
  }

  div.containerHoursAndMins {
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: space-between;
  }

  div.containerComboboxHourMin {
    width: auto;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }

  div.containerExternalPeople img,
  div.containerComboboxImg img {
    height: 19px;
    width: 19px;
    margin-left: 10px;
    cursor: pointer;
  }

  div.containerCombobox div.contact img.cancelImg {
    height: 10px;
    width: 10px;
    cursor: pointer;
    margin-left: 5px;
  }

  div.yesNoAllDay img.yesNoImg {
    height: 19px;
    width: 19px;
    margin-left: 10px;
  }

  div.containerCombobox div.contact {
    display: flex;
    align-items: center;
    margin: 3px 0 3px 0;
  }

  div.containerCombobox div.contact span {
    font-size: 12px;
    /* border-top: 1px solid #d7e1ea; */
  }

  div.outlookError {
    border: 1px solid;
    margin-bottom: 10px;
    padding: 5px;
    height: auto;
    font-size: 15px;
    text-align: center;
    width: auto;
    background-color: #ffbaba;
    color: #d8000c;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  div.outlookError span,
  div.deleteMessage span {
    color: #8d0108;
    pointer-events: none;
  }

  div.deleteMessage {
    margin-bottom: 10px;
    padding: 3px;
    font-size: 13px;
    text-align: center;
    width: 100%;
    background-color: #ffbaba;
    color: #d8000c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  div.outlookError img {
    height: 12px;
    cursor: pointer;
    padding-right: 10px;
  }
</style>
