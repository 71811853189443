<script>
  export let text;
  export let date;
  export let selectedMonth;
  export let selectedYear;
  export let disabled;
  export let onClick;

  import {
    getMonthNumberFromNameAsNumber,
    getMonthNumberFromNameAsString,
  } from "../utils/conversions";

  let today = false;
  let selected = false;

  $: {
    const now = new Date();
    today =
      now.getDate() === text &&
      now.getMonth() === getMonthNumberFromNameAsNumber(selectedMonth) &&
      now.getFullYear().toString() === selectedYear;
  }

  $: {
    const dateItems = date.split("/");
    const formattedDay = text < 10 ? "0" + text.toString() : text.toString();
    selected =
      dateItems[0] === formattedDay &&
      dateItems[1] === getMonthNumberFromNameAsString(selectedMonth) &&
      dateItems[2] === selectedYear;
  }
</script>

<div
  class="container {text < 0 ? 'noselectable' : ''}
  {today ? 'today' : ''}
  {selected ? 'selected' : ''}"
  on:click={disabled || text < 0 ? null : () => onClick(text)}
>
  <span class={disabled ? "disabled" : ""}>{text < 0 ? -text : text}</span>
</div>

<style>
  div.container {
    width: 14.28% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-bottom: 2px;
    padding-top: 2px;
  }

  div.container:hover {
    background-color: lightgray;
  }

  div.noselectable {
    cursor: inherit;
    background-color: white !important;
    color: var(--labit-gray);
  }

  div.today {
    color: red;
    font-weight: bold;
  }

  div.selected {
    background-color: var(--labit-gray);
    color: white;
  }

  span.disabled {
    color: #999;
  }
</style>
