<script>
  export let text;
  export let onClick;
</script>

<style>
  div.button {
    background-color: #72809d;
    border-radius: 25px;
    width: 129px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  div.button span {
    color: #ffff;
    font-size: 15px;
  }
</style>

<div class="button fadedButton" on:click={onClick}><span>{text}</span></div>
