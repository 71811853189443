<script>
  export let attribute;

  import RDSGTextException from "./RDSGTextException.svelte";
</script>

<style>
  div.RDSGTextAttributeContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-left: 10px;
  }

  span {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }
</style>

<div class="RDSGTextAttributeContainer">
  <span>{attribute.name}</span>
  <div>
    {#each attribute.conflicts as exception, index}
      <RDSGTextException id={index} {exception} />
    {/each}
  </div>
</div>
