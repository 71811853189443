<script>
  export let text;
  export let img;
  export let onClick;
</script>

<style>
  div {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: var(--labit-background-gray);
    border-style: solid;
    border-color: var(--labit-gray);
    border-width: 1px;
    border-radius: 960px/960px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.8%;
    padding-right: 0.8%;
    font-size: 13px;
    color: var(--labit-darkgray);
    position: relative;
  }

  img {
    position: relative;
    height: 50%;
    margin-left: 7%;
  }

  /* Animations */

  div {
    transition: background-color 0.5s linear 0s;
  }

  div:hover {
    background-color: lightgray;
  }

  div span {
    transition: color 0.5 linear 0s;
  }

  div:hover span {
    color: black;
  }
</style>

<div on:click={onClick}>
  <span>{text}</span>
  {#if img}<img src={img} alt="icon" />{/if}
</div>
