<script>
  export let visible;
  export let isGeneralEvent;

  import DashboardTitle from "../DashboardTitle.svelte";
  import OutlookCalendarMessage from "./OutlookCalendarMessage.svelte";

  import {
    outlookLogin,
    outlookGetCalendar,
    outlookGetCalendarsDashboard,
    outlookGetCalendarEventsDashboard,
  } from "./outlook";
  import {
    setOutlookToken,
    getOutlookToken,
    getPlatform,
  } from "../../../js/localInfo";
  import { OUTLOOK_CALENDAR_URL } from "../../../js/globalExternalURLs";
  import { openNewWindow } from "../../../js/openNewWindow";
  import { openURL } from "../../../js/openURL";

  import { onMount } from "svelte";

  import moment from 'moment';
  const platform = getPlatform();

  let error = false;
  let token = "";

  let events = [];
  let eventsTemp = [];
  let calendars = [];
  let calIndex = 0;

  let loadingCalendar = false;

  let timerController = null;

  let today = moment().format("YYYY-MM-DD");

  const OUTLOOK_TIMER = 60000; // 1 min

  onMount(async () => {
    loadInfo();
  });

  let getCalendar = async () => {
    if (visible && !loadingCalendar) {
      loadingCalendar = true;
      outlookGetCalendarsDashboard(
        async (cals) => {
          calendars = cals.filter((cal) => {
            return cal.outlookCalendarType === "calendar";
          });
          eventsTemp = [];
          calIndex = 0;
          getCalendarEvents();
          loadingCalendar = false;
          timerController = setTimeout(getCalendar, OUTLOOK_TIMER);
        },
        () => {
          error = true;
          loadingCalendar = false;
          timerController = setTimeout(getCalendar, OUTLOOK_TIMER);
        }
      );
    }
  };

  /*let getCalendar = async () => {
    //console.log("CALENDARS");
    token = getOutlookToken();
    if (token !== "") {
      if (visible && !loadingCalendar) {
        loadingCalendar = true;
        await outlookGetCalendars(
          async (cals) => {
            calendars = cals.filter((cal) => {
              return cal.outlookCalendarType === "calendar";
            });
            eventsTemp = [];
            calIndex = 0;
            getCalendarEvents();
            loadingCalendar = false;
            timerController = setTimeout(getCalendar, OUTLOOK_TIMER);
          },
          () => {
            outlookLogin(
              async (cals) => {
                calendars = cals.filter((cal) => {
                  return cal.outlookCalendarType === "calendar";
                });
                eventsTemp = [];
                calIndex = 0;
                getCalendarEvents();
                loadingCalendar = false;
                timerController = setTimeout(getCalendar, OUTLOOK_TIMER);
              },
              () => {
                error = true;
                loadingCalendar = false;
                timerController = setTimeout(getCalendar, OUTLOOK_TIMER);
              }
            );
          }
        );
      }
    } else {
      timerController = setTimeout(getCalendar, OUTLOOK_TIMER);
    }
  };*/

  let getCalendarEvents = async () => {
    //console.log("CALENDAR " + calIndex);
    const calendar = calendars[calIndex];
    outlookGetCalendarEventsDashboard(
      calendar.id,
      calendar.outlookCalendarType,
      today,
      today,
      (ev) => {
        //console.log(ev);
        // Remove missed events

        const now = moment().format("YYYY-MM-DD HH:mm:ss");

        const evts = ev.filter((event) => {
          const startTime = event.startDateTime.replace("T", " ");
          const startTimeM = moment(startTime, "YYYY-MM-DD HH:mm:ss").format(
            "YYYY-MM-DD HH:mm:ss"
          );
          return (
            event.isAllday || startTimeM >= now /*&&
            ((isGeneralEvent && event.type === "general") ||
              (!isGeneralEvent && event.type !== "general"))*/
          );
        });

        eventsTemp = eventsTemp.concat(evts);

        calIndex++;
        if (calIndex < calendars.length) {
          getCalendarEvents();
        } else {
          events = eventsTemp;
        }
      },
      () => {
        calIndex++;
        if (calIndex < calendars.length) {
          getCalendarEvents();
        } else {
          events = eventsTemp;
        }
      }
    );
  };

  let clickBox = () => {
    if (error) {
      outlookLogin(
        () => {
          openURL("/dashboard", true);
        },
        () => {
          error = true;
        }
      );
    } else {
      if (platform === "APP") {
        openURL("/outlook/calendar", true);
      } else {
        openNewWindow(OUTLOOK_CALENDAR_URL);
      }
    }
  };

  let loadInfo = () => {
    token = getOutlookToken();
    if (token !== "") {
      error = false;
      getCalendar();
    }
  };

  let cancelTimers = () => {
    clearTimeout(timerController);
    loadingCalendar = false;
    timerController = null;
  };

  $: if (visible === true) {
    loadInfo();
  } else {
    cancelTimers();
  }

  //$: console.log(events);
</script>

<div class="dashboardContainer">
  <!--<DashboardTitle
    title={isGeneralEvent ? "Events" : "Meetings & Calendar"}
    numEvents={events.length}
  />-->
  <DashboardTitle title="Outlook Calendar" numEvents={events.length} />
  <div class="dashboardBody" on:click={() => clickBox()}>
    {#if token === "" || error}
      <div class="error">
        <span>
          Outlook connection failed or token expired. Click this box to retry.
        </span>
      </div>
    {:else}
      <div class="messages customScrollbars">
        {#each events as event}
          <OutlookCalendarMessage {event} />
        {/each}
      </div>
    {/if}
  </div>
</div>

<style>
  div.dashboardContainer {
    width: 100%;
    height: 100%;
  }

  div.dashboardBody {
    width: 100%;
    height: 85%;
    padding: 5%;
    cursor: pointer;
  }

  div.messages {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  div.error {
    width: 100%;
    height: 100%;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
</style>
