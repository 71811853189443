<script>
  export let src;
  export let onClose = null;

  import Picture from "./Picture.svelte";
  import Combobox from "./Combobox.svelte";
  import PictureCombobox from "./PictureCombobox.svelte";

  import { onMount, onDestroy } from "svelte";
  import moment from 'moment';

  import { getUserId, getRole, getUserName, getTester } from "../js/localInfo";
  import { openURL } from "../js/openURL";
  import { post } from "../js/labit-connection";

  import { tempUser } from "../js/stores.js";

  import { getCalendarEvents } from './dashboard/outlook/outlook';

  let myId = getUserId();
  let myName = getUserName();
  let tester = getTester();

  let company = 0;

  let showMenuOptions = false;

  let statusOptions = [];
  let statusOptionsSel = null;

  let homeOptions = [
    {
      label: "Working at the office",
      value: {
        id: "0",
        description: "Working at the office",
        icon: "/images/menu-icons/logo-labit.svg",
      },
    },
    {
      label: "Working at home",
      value: {
        id: "1",
        description: "Working at the office",
        icon: "/images/profile-status/athome.svg",
      },
    },
  ];
  let homeOptionsSel = null;

  let officeStatus = null;
  let atHome = false;

  let usersOptions = [];
  let usersOptionsSel = null;

  let viewingAs = null;

  let mouseOver = false;

  let loading = true;

  let getStatusInterval;
  let getStatusActivity;
  let actualMeeting = { id: -1 };
  let ignoreActualMeeting = false;
  
  onDestroy(() => {
    clearInterval(getStatusInterval);
  });
  let lastActivityCheck = moment().format("YYYY-MM-DD HH:mm:ss");
  let actualActivity = moment().format("YYYY-MM-DD HH:mm:ss");

  onMount(async () => {
    loading = true;

    window.$("div#fadedDiv").click(() => toggleMenuOptions());
    await getStatus();

    const response = await post(
      `select profileStatus, atHome, company_id
        from people
        where contact_id=${myId}`
    );

    if (response.length > 0) {
      statusOptionsSel = statusOptions.find((item) => {
        return item.value.id === response[0].profileStatus;
      });

      officeStatus = {
        description: statusOptionsSel.value.description,
        icon: statusOptionsSel.value.icon,
      };

      homeOptionsSel = homeOptions.find((item) => {
        return item.value.id === response[0].atHome;
      });

      company = response[0].company_id;
      
      getStatusInterval = setInterval( async () => {
        const element = document.getElementById('menuItemProfile');
        if (element.offsetParent !== null) {
          const inAMeeting = await getOutlookStatus();
          const myStatus = await setMyStatus(inAMeeting);

          statusOptionsSel = statusOptions.find((item) => {
            return item.value.id === myStatus;
          });

          officeStatus = {
            description: statusOptionsSel.value.description,
            icon: statusOptionsSel.value.icon,
          };

        }
        
        refreshActivityCheck();
      }, 20000 );
      
    }
    await getUsers();

    loading = false;
  });

  const refreshActivityCheck = () => actualActivity = lastActivityCheck;  
  const getLastActivity = () => lastActivityCheck = moment();
  
  window.onclick = getLastActivity;

  const setMyStatus = async (inAMeeting) => {
    let status = await post(`SELECT profileStatus from people where contact_id = ${myId}`);
    status = status[0].profileStatus;

    if (status === '1' && inAMeeting) {
      await post(`UPDATE people set profileStatus = 2 where contact_id = ${myId}`);
      status = '2';
    } else if (status === '2' && !inAMeeting) {
      await post(`UPDATE people set profileStatus = 1 where contact_id = ${myId}`);
      status = '1';
    }
    return status;
  }

  const getOutlookStatus = async () => {
    const events = await getCalendarEvents();
    const now = moment();
    let i = events
    .filter((e) => e.response === 'accepted' || e.response === 'organizer')
    .findIndex((e) => {
      return now.isBetween(moment.utc(e.start, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS'), moment.utc(e.end, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS'));
    });
    
    if (i === -1) {
      ignoreActualMeeting = false;
    }

    if (i === -1 || (ignoreActualMeeting && actualMeeting.id === events[i].id)) {
      return false;
    } else {
      actualMeeting = i === -1 ? 0 : events[i];
      return true;
    }
  };

  let toggleMenuOptions = () => {
    showMenuOptions = !showMenuOptions;
    if (showMenuOptions) {
      window.$("div#fadedDiv").show();
      window.$("#menuShadow").show();
      window.$("#mainMenu").css("background-color", "transparent");
    } else {
      window.$("div#fadedDiv").hide();
      window.$("#menuShadow").hide();
      window
        .$("#mainMenu")
        .css("background-color", "var(--labit-background-gray)");
    }
  };

  let closeMenuOptions = () => {
    showMenuOptions = false;
    window.$("div#fadedDiv").hide();
    window.$("#menuShadow").hide();
    window
      .$("#mainMenu")
      .css("background-color", "var(--labit-background-gray)");
  };

  let getUsers = async () => {
    const response = await post(
      `select p.Name, contact_id, Access, PictureUrl, token
        from people as p
        left join companies as c on c.company_id=p.company_id
        where p.Active=1 and c.Name='Labit Group'
        order by p.Name asc`
    );
    usersOptions = response.map((item) => {
      return {
        label: item.Name,
        value: {
          id: item.contact_id,
          name: item.Name,
          role: item.Access,
          pic: item.PictureUrl,
          token: item.token,
          icon: "/images/view.svg",
        },
      };
    });
    if (usersOptions.length > 0) {
      usersOptionsSel = usersOptions[0];
    }
  };

  let getStatus = async () => {
    const response = await post(
      `select id, description, iconImg
        from profileStatus`
    );
    statusOptions = response.map((item) => {
      return {
        label: item.description,
        value: {
          id: item.id,
          description: item.description,
          icon: item.iconImg,
        },
      };
    });
    statusOptionsSel = statusOptions[0];
  };

  let changeStatus = () => {
    ignoreActualMeeting = true;
    if (!loading) {
      officeStatus = {
        description: statusOptionsSel.value.description,
        icon: statusOptionsSel.value.icon,
      };

      post(
        `update people
        set profileStatus=${statusOptionsSel.value.id}
        where contact_id=${myId}`
      );
    }
  };

  let changeHome = () => {
    if (!loading) {
      post(
        `update people
        set atHome=${homeOptionsSel.value.id}
        where contact_id=${myId}`
      );
    }
  };

  let viewAs = () => {
    viewingAs = usersOptionsSel.value;
    tempUser.set(viewingAs);
    mouseOver = false;
    toggleMenuOptions();
  };

  let closeViewAs = () => {
    viewingAs = null;
    tempUser.set(viewingAs);
    mouseOver = false;
    location.href = "/skylab-main"; // Load all again
  };

</script>

<div class="menuItemProfile" id="menuItemProfile">
  <div class="profile" style={viewingAs ? "" : "cursor: pointer;"}>
    <img
      class="arrow {viewingAs ? 'hiddenElement' : ''}"
      src="/images/down_arrow.svg"
      alt="Options Icon"
      on:click={() => {
        toggleMenuOptions();
      }}
    />
    <div class="hole {viewingAs ? '' : 'hiddenElement'}" />
    <div
      class="profilePic"
      on:click={() => {
        if (!viewingAs) {
          openURL("/people/show-people/" + myId, true);
        }
      }}
      on:contextmenu={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!viewingAs) {
          openURL("/skylab-main/people/show-people/" + myId);
        }
        return false;
      }}
    >
      <Picture
        name={myName}
        image={src}
        lastActivity={viewingAs ? "" : actualActivity}
        officeStatus={viewingAs ? null : officeStatus}
        atHome={homeOptionsSel && !viewingAs ? homeOptionsSel.value.id : "0"}
        blurred={viewingAs}
      />
      {#if viewingAs}
        <div
          class="profilePic2"
          on:mouseenter={() => {
            mouseOver = true;
          }}
          on:mouseleave={() => {
            mouseOver = false;
          }}
        >
          <div
            class="picX {mouseOver ? '' : 'hiddenElement'}"
            on:click={(ev) => {
              ev.stopPropagation();
              closeViewAs();
            }}
          >
            <img src="/images/x.svg" alt="Close View" />
          </div>
          <div class="pic2 {mouseOver ? 'hiddenElement' : ''}">
            <Picture
              name={viewingAs.name}
              image={viewingAs.pic}
              border="var(--labit-card-gray)"
            />
          </div>
        </div>
      {/if}
    </div>
  </div>
  {#if showMenuOptions}
    <div class="profileOptions">
      <div class="option">
        <div class="combobox">
          <PictureCombobox
            options={statusOptions}
            placeholder=""
            bind:selectedValue={statusOptionsSel}
            searchable={false}
            onChange={() => changeStatus()}
          />
        </div>
      </div>
      <div class="option">
        <div class="combobox">
          <PictureCombobox
            options={homeOptions}
            placeholder=""
            bind:selectedValue={homeOptionsSel}
            searchable={false}
            onChange={() => changeHome()}
          />
        </div>
      </div>
      <div class="separator" />
      {#if tester}
        <div class="option">
          <div class="combobox">
            <PictureCombobox
              options={usersOptions}
              placeholder=""
              bind:selectedValue={usersOptionsSel}
              searchable={true}
            />
            <img
              class="view fadedButton"
              src="/images/view.svg"
              alt="View As"
              on:click={() => viewAs()}
            />
          </div>
        </div>
        <div class="separator" />

        <div
          class="button"
          on:click={() => {
            if (onClose) {
              onClose();
            }
            openURL("/debug", true);
            closeMenuOptions();
          }}
        >
          <span>Debug</span>
          <img src="/images/bug.svg" alt="Logout Icon" />
        </div>
      {/if}

      <!-- 
      {#if company === '2'}
        <div class="appsContainer">
          <div class="app" on:click={() => { openURL("/me", true); toggleMenuOptions();}} >
            <img src="/images/user.svg" width="28px" alt="" />
          </div>
          {#if myId === 13 || myId === 29 || myId === 33 || myId === 5 || myId === 15 || myId == 399 }
            <div class="appSeparator" />
            <div class="app" on:click={() => { openURL("/monitor", true); toggleMenuOptions();}} >
              <img src="/images/iron-man.svg" width="28px" alt="" />
            </div>
          {/if}
        </div>
      {/if}
       -->

      <!--
      <div
        class="button"
        on:click={() => {
          openURL("/people/show-people/" + myId, true);
          closeMenuOptions();
        }}
        on:contextmenu={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          openURL("/skylab-main/people/show-people/" + myId);
          return false;
        }}
      >
        <span>My Profile</span>
        <img src="/images/no_user_white.svg" alt="My Profile Icon" />
      </div>
    -->
      <div
        class="button logoutButton"
        on:click={() => {
          location.href = "/logout";
        }}
      >
        <span>Log Out</span>
        <img src="/images/logout.svg" alt="Logout Icon" />
      </div>
    </div>
  {/if}
</div>

<style>
  div.app {
    cursor: pointer;
    width: 100%;
    border: 1px solid lightgrey;
    height: 100%;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    padding: 2px 0;
  }

  div.appSeparator {
    width: 10px;
    background-color: transparent;
  }

  div.appsContainer {
    width: 80%;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
    margin-bottom: 10px;
  }

  div.menuItemProfile {
    display: flex;
    height: 100%;
    align-items: center;
    z-index: 1000;
    background-color: white;
    margin-left: 10px;
    padding-left: 27px;
    padding-right: 33px;
    border-radius: 19px;
  }

  div.profile {
    display: flex;
    height: 100%;
    align-items: center;
  }

  div.profilePic {
    width: 57px;
    height: 57px;
    position: relative;
  }

  img.arrow {
    width: 17px;
    margin-right: 13px;
  }

  div.profileOptions {
    position: absolute;
    right: 0px;
    top: 85px;
    background-color: white;
    z-index: 1000;
    width: 278px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 29px;
    padding-bottom: 29px;
    border-radius: 19px;
  }

  div.button {
    width: 80%;
    height: 38px;
    background-color: var(--labit-card-gray);
    font-size: 16px;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 19px;
  }

  div.button img {
    height: 55%;
    margin-left: 8px;
  }

  div.button:not(:last-child) {
    margin-bottom: 10px;
  }

  div.logoutButton {
    background-color: var(--labit-card-red) !important;
  }

  div.option {
    font-size: 14px;
    width: 80%;
  }

  div.combobox {
    height: 35px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  div.separator {
    height: 1px;
    width: 80%;
    background-color: var(--labit-card-lightgray);
    margin: 10px 0px 30px 0px;
  }

  img.view {
    width: 20px;
    margin-left: 8px;
  }

  div.profilePic2 {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  div.picX {
    width: 100%;
    height: 100%;
    border: 1px solid var(--labit-card-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
  }

  div.picX img {
    width: 10px;
  }

  div.pic2 {
    width: 40px;
    height: 40px;
  }

  div.hole {
    width: 30px;
  }

  /* Animations */

  div.profile {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.profile:hover {
    opacity: 0.8;
  }

  div.button {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.button:hover {
    opacity: 0.5;
  }
</style>
