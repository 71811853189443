<script>
  export let fileUrl;

  import { LABIT_REST_SERVER } from "../js/labit-connection";
  import { getToken } from "../js/localInfo";
  import { openNewWindow } from "../js/openNewWindow";

  import { onMount } from "svelte";

  let isImage = false;

  onMount(() => {
    prepareFile(fileUrl);
  });

  let prepareFile = (file) => {
    isImage = false;
    const tokens = atob(file).split(".");
    if (tokens.length >= 2) {
      const extension = tokens[tokens.length - 1];
      isImage =
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "gif" ||
        extension === "png" ||
        extension === "svg";
    }
  };

  let closeWindow = () => {
    fileUrl = "";
  };

  $: if (fileUrl) {
    prepareFile(fileUrl);
  }
</script>

<div id="pdfFile" />
{#if fileUrl !== ""}
  <div class="previewRemoteFileContainer">
    <img
      class="x"
      src="/images/x.svg"
      alt="Close"
      on:click={() => closeWindow()}
    />
    {#if isImage}
      <div class="imgIframe">
        <img
          class="iframe"
          src={LABIT_REST_SERVER +
            "PreviewFile.php?url=" +
            fileUrl +
            "&token=" +
            getToken()}
          alt="Current File"
          on:click={() => {
            openNewWindow(
              LABIT_REST_SERVER +
                "PreviewFile.php?url=" +
                fileUrl +
                "&token=" +
                getToken()
            );
          }}
        />
      </div>
    {:else}
      <iframe
        title="File Preview"
        src={LABIT_REST_SERVER +
          "PreviewFile.php?url=" +
          fileUrl +
          "&token=" +
          getToken()}
      />
    {/if}
  </div>
{/if}

<style>
  div.previewRemoteFileContainer {
    background-color: var(--labit-dialog-background);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 10px;
    margin-top: 20px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  img.x {
    width: 9px;
    height: 9px;
    cursor: pointer;
    margin-right: 20px;
  }

  iframe,
  div.imgIframe {
    width: 100%;
    height: 1080px;
    margin-top: 10px;
  }

  div.imgIframe {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img.iframe {
    max-width: 95%;
    cursor: pointer;
  }

  /* Animations */

  img.x {
    transition: opacity 0.5s linear 0s;
  }

  img.x:hover {
    opacity: 0.5;
  }
</style>
