<script>
  export let file;
  export let onPreviewFile;

  import { LABIT_REST_SERVER } from "../js/labit-connection";
  import { getToken } from "../js/localInfo";

  let iconImg = "";

  let downloadFile = () => {
    location.href =
      LABIT_REST_SERVER +
      "DownloadFile.php?url=" +
      btoa(unescape(encodeURIComponent(file.id))) +
      "&token=" +
      getToken();
  };

  $: {
    if (file.folder) {
      iconImg = "folder.svg";
    } else {
      switch (file.extension) {
        case "doc":
        case "docx":
          iconImg = "doc.svg";
          break;
        case "ppt":
        case "pptx":
          iconImg = "ppt.svg";
          break;
        case "xls":
        case "xlsx":
          iconImg = "xls.svg";
          break;
        case "pdf":
          iconImg = "pdf.svg";
          break;
        case "txt":
          iconImg = "txt.svg";
          break;
        case "jpg":
        case "jpeg":
        case "bmp":
        case "png":
        case "gif":
        case "svg":
          iconImg = "image.svg";
          break;
        default:
          iconImg = "file.svg";
          break;
      }
    }
  }
</script>

<style>
  div.fileBoobleFileContainer {
    width: 100%;
    height: 23px;
    display: flex;
    align-items: flex-start;
    color: var(--labit-card-gray);
    font-size: 13px;
    color: var(--labit-card-lightgray);
  }

  div.fileBoobleFileContainer:first-child {
    margin-top: 10px;
  }

  span.name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img.fileIcon {
    height: 13px;
    margin-right: 5px;
  }

  div.icons {
    height: 100%;
    display: flex;
    margin-left: 20px;
  }

  div.icons img {
    height: 13px;
    cursor: pointer;
    margin-left: 5px;
  }

  /* Animations */

  div.icons img {
    transition: opacity 0.5s linear 0s;
  }

  div.icons img:hover {
    opacity: 0.5;
  }
</style>

<div class="fileBoobleFileContainer">
  <img class="fileIcon" src="/images/file-icons/{iconImg}" alt="File Icon" />
  <span class="name">{file.name}</span>
  <div class="icons">
    <img
      src="/images/eye.svg"
      alt="Preview File"
      on:click={() => onPreviewFile(file.id)} />
    <img
      src="/images/download-file.svg"
      alt="Download File"
      on:click={() => downloadFile()} />
  </div>
</div>
