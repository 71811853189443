<script>
  //import Menu from "../components/Menu.svelte";
  import FileBoobleContainer from "../components/FileBoobleContainer.svelte";
  import PreviewRemoteFile from "../components/PreviewRemoteFile.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  //import ContextMenu from "../components/ContextMenu.svelte";

  // Just in case you need user's rol and id
  import { getRole } from "../js/localInfo";
  import { getPlatform } from "../js/localInfo";
  import { randomString } from "../js/randomString";

  // See src/data/components/menu.json ('text' attribute) to mark current page
  const PAGE = "ANALYTICS";

  let myRole = getRole();
  const platform = getPlatform();

  let remoteFilePrev = "";

  let showNavMenu = null;

  let previewFile = (id) => {
    remoteFilePrev = id.toLowerCase().trim().startsWith("http")
      ? id
      : btoa(unescape(encodeURIComponent(id)));
    window.$("html, body").animate(
      {
        scrollTop:
          window.$("#documentManagementFiles").offset().top +
          window.$("#documentManagementFiles").outerHeight() -
          80,
      },
      500
    );
  };
</script>

<div class="content">
  <!-- Body -->
  <div class="container">
    <FileBoobleContainer
      title="Labit Guidelines"
      url="/templates/labit-guidelines"
      onPreviewFile={(id) => previewFile(id)}
      readOnly={myRole !== "Admin"}
    />
    <FileBoobleContainer
      title="BIM Manuals"
      url="/templates/bim-manuals"
      onPreviewFile={(id) => previewFile(id)}
      readOnly={myRole !== "Admin"}
    />
    <FileBoobleContainer
      title="Regulations"
      url="/templates/regulations"
      onPreviewFile={(id) => previewFile(id)}
      readOnly={myRole !== "Admin"}
    />
    <FileBoobleContainer
      title="Tutorials"
      url="/templates/tutorials"
      onPreviewFile={(id) => previewFile(id)}
      readOnly={myRole !== "Admin"}
    />
    <FileBoobleContainer
      title="Architectural References"
      url="/templates/architectural-references"
      onPreviewFile={(id) => previewFile(id)}
      readOnly={myRole !== "Admin"}
    />
  </div>

  <div id="documentManagementFiles" />
  {#if remoteFilePrev !== "" && remoteFilePrev
      .toLowerCase()
      .trim()
      .startsWith("http")}
    <div class="iframe iframeFile">
      <img
        class="fadedButton"
        src="/images/x.svg"
        alt="Close"
        on:click={() => {
          remoteFilePrev = "";
        }}
      />
      <WebBrowser
        type={platform === "Web" ? "iframe" : "webview"}
        iframeTitle="External URL"
        id=documentManagementWebBrowser
        src={remoteFilePrev}
      />
    </div>
  {:else}
    <PreviewRemoteFile bind:fileUrl={remoteFilePrev} />
  {/if}
</div>

<!--
{#if platform === "APP" && remoteFilePrev !== "" && remoteFilePrev
    .toLowerCase()
    .trim()
    .startsWith("http")}
  <ContextMenu currentURL={remoteFilePrev} bind:showMenu={showNavMenu} />
{/if}
-->
<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    flex-direction: column;
    margin-bottom: 20px;
  }

  div.iframeFile {
    height: 800px;
    background-color: var(--labit-dialog-background);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    margin-top: 20px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  div.iframeFile img {
    width: 9px;
    height: 9px;
    margin: 0px 20px 10px 0px;
  }
</style>
