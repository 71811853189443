<script>
  export let children;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  function open(e) {
    const ele = e.target.value;
    children[ele].open = true;
  }

  function close(e) {
    const ele = e.target.value;
    children[ele].open = false;
  }

  function onChange() {
    dispatch("changed");
  }

  function onClick(e) {
    dispatch("clicked", { id: e.target.id });
  }

  function parse(e) {
    let result = "{";
    let k;
    for (k in e) {
      result += k;
      result += " : ";
      result += e[k];
      result += ",\n";
    }
    result += "}";
    return result;
  }

  function handleClick(obj) {
    obj.value._isOpen = !obj.value._isOpen;
    children = children;
  }

  function opencloseChar(val) {
    if (val) {
      return "(-)";
    } else {
      return "(+)";
    }
  }

  function getColor() {
    return (
      "#" +
      Math.floor(Math.random() * 127 + 126).toString(16) +
      Math.floor(Math.random() * 127 + 126).toString(16) +
      Math.floor(Math.random() * 127 + 126).toString(16)
    );
  }
</script>

<style>
  div {
    padding-left: 20px;
  }
  span {
    cursor: pointer;
  }
</style>

<div style="background-color: {getColor()}">
  {#each Object.entries(children) as [key, value]}
    {#if key != '_isOpen'}
      <div>
        <span on:click={() => handleClick({ value })}>{key}:</span>
        {#if typeof value === 'object'}
          <span on:click={() => handleClick({ value })}>
            {opencloseChar(value._isOpen)}
          </span>
          {#if value._isOpen}
            <svelte:self children={value} on:changed on:clicked />
          {/if}
        {:else}
          <b>{value}</b>
        {/if}
      </div>
    {/if}
  {/each}
</div>
