<script>
  export let project;
  export let day;
  export let dayWidth;
  export let milestones;
  export let editingPlan;
  export let onClick;
  export let showMilestones;
  import moment from 'moment';

  let milestonesDay = [];

  let weekend = false;
  let today = false;

  let milestoneSize = 19;

  let checkWeekend = () => {
    const ddd = moment(day, "YYYY-MM-DD").format("ddd");
    weekend = ddd === "Sat" || ddd === "Sun";
  };

  let checkToday = () => {
    today = moment().format("YYYY-MM-DD") === day;
  };

  let prepareMilestones = () => {
    milestonesDay = milestones.filter((m) => {
      return m.date === day;
    });
    milestonesDay.forEach((m) => {
      m.icon = "";
      m.title = m.name;
      if (m.type === "in") {
        m.icon = "/images/milestone/IN.svg";
        m.title = "IN - " + m.title;
      }
      if (m.type === "out") {
        m.icon = "/images/milestone/OUT.svg";
        m.title = "OUT - " + m.title;
      }
    });
    milestonesDay = milestonesDay;

    milestoneSize = parseInt(dayWidth) * 0.8;
  };

  $: if (milestones || dayWidth) {
    prepareMilestones();
  }

  $: if (day) {
    prepareMilestones();
    checkWeekend();
    checkToday();
  }

</script>

<div class="milestones {weekend ? 'weekend' : ''} {today ? 'today' : ''} borderRight borderBottom" style="width: {dayWidth}px" on:click={(ev) => {
    if (editingPlan) {
      onClick(true, ev.pageX, ev.pageY, project, day, null);
    }
  }}
>
  {#if showMilestones}
    {#each milestonesDay as md}
      <div
        class="milestone"
        style="width: {milestoneSize}px; height: {milestoneSize}px; {milestoneSize <= 15 ? 'margin: 0px;' : ''}" 
        title={md.title}
        on:click={(ev) => {
          if (editingPlan) {
            ev.stopPropagation();
            onClick(true, ev.pageX, ev.pageY, project, day, md);
          }
        }}
      >
        {#if md.icon !== "" && milestoneSize > 15}
          <img src={md.icon} alt="Milestones Arrow" />
        {/if}
      </div>
    {/each}
  {/if}
</div>

<style>
  div.milestones {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 25px;
    background-color: white;
    flex-shrink: 0;
    flex-grow: 0;
  }

  div.milestone {
    max-width: 19px;
    max-height: 19px;
    border-radius: 50%;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid white;
    background-color: var(--labit-people-work-plan);
  }

  div.milestone img {
    width: 40%;
  }
</style>
