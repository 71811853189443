<script>
  export let project;
  export let phase;
  export let subphase;
  export let dayArray;
  export let dayWidth;
  export let stickyPhase;
  export let stickyStart;
  export let stickyEnd;
  export let stickyPeople;
  export let showStartColumn;
  export let showEndColumn;
  export let showTeamColumn;
  export let editingPlan;
  export let onClickSubphase;
  export let startDate;
  export let endDate;
  export let showPlannerTasks;
  export let CRPid;
  export let loaded;
  export let drawPhases;
  export let view;

  import moment from 'moment';
  import CompleteResourcePlannerGridCell from "./CompleteResourcePlannerGridCell.svelte";

  const PHASE_WIDTH = 330;
  const START_WIDTH = 70;
  const END_WIDTH = 70;
  const PEOPLE_WIDTH = 130;
</script>

<div class="mainPhase" style={view === "project" ? "" : "display: none;"}>
  <div
    class="phase name borderRight borderBottom borderLeft"
    style="width: {PHASE_WIDTH}px; left: {stickyPhase}px;"
    on:click={(ev) => {
      if (editingPlan) {
        onClickSubphase(true, ev.pageX, ev.pageY, project, phase, "subphases");
      }
    }}
  >
    {subphase.name}
  </div>
  {#if showStartColumn}
    <div
      class="start borderRight borderBottom"
      style="width: {START_WIDTH}px; left: {stickyStart}px;"
    >
      {moment(subphase.start, "YYYY-MM-DD").format("DD/MM/YYYY")}
    </div>
  {/if}
  {#if showEndColumn}
    <div
      class="end borderRight borderBottom"
      style="width: {END_WIDTH}px; left: {stickyEnd}px;"
    >
      {moment(subphase.end, "YYYY-MM-DD").format("DD/MM/YYYY")}
    </div>
  {/if}
  {#if showTeamColumn}
    <div
      class="people borderRight borderBottom"
      style="width: {PEOPLE_WIDTH}px; left: {stickyPeople}px;"
    />
  {/if}
  {#each dayArray as day, index}
    <CompleteResourcePlannerGridCell
      {day}
      phase={day === subphase.start || (subphase.start < day && index === 0)
        ? subphase
        : null}
      {dayArray}
      {dayWidth}
      subphase={true}
      {startDate}
      {endDate}
      {showPlannerTasks}
      {CRPid}
      {loaded}
      {drawPhases}
    />
  {/each}
</div>

<style>
  div.mainPhase {
    display: flex;
    height: 25px;
    width: fit-content;
  }

  div.name {
    padding-left: 30px;
  }

  div.phase,
  div.start,
  div.end,
  div.people {
    position: sticky;
    background-color: white;
    z-index: 2;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    font-size: 11px;
  }

  div.start,
  div.end {
    display: flex;
    justify-content: center;
  }
</style>
