<script>
  import { logout, getOutlookToken } from "../js/localInfo";
  import { outlookLogout } from "../components/dashboard/outlook/outlook";

  const outlookToken = getOutlookToken();
  //console.log(outlookToken);

  logout();
  if (outlookToken !== "") {
    outlookLogout();
  } else {
    location.href = "/login";
  }
</script>
