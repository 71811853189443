<script>
	import moment from "moment";

	export let id;
	export let taskDescription;
	export let taskProject;
	export let taskPhase;
	export let taskType;
	export let startMillis;
	export let endMillis;
	export let modificable = false;
	export let selectedDay = moment();
	export let observer = false;
	
	import { createEventDispatcher } from "svelte";

	import { formatTime, msToTime } from "../../../js/formatDateTime";

	let start = moment(startMillis, 'x').format('HH:mm'); // 'HH:mm'
	let end = moment(endMillis, 'x').format('HH:mm'); // 'HH:mm'
	let total = formatTime(msToTime(parseInt(endMillis) - parseInt(startMillis))); // 'HH:mm'

	const startCopy = start;
	const endCopy = end;
	const totalCopy = total;
	//const day = moment(startMillis, 'x').format('DD-MM-YYYY');
	
	const dispatch = createEventDispatcher();

	function dispatchBtnDelete(id) {
		dispatch("dispatchDelete", {
			id: id
		});
	}

	function dispatchBtnCopy(id) {
		dispatch("dispatchCopy", {
			id: id
		});
	}

	function dispatchBtnModify(startMillis, endMillis) {
		dispatch("dispatchModify", {
			id: id,
			desc: taskDescription,
			start: startMillis,
			end: endMillis
		});
	}

	/*
	const dispatchBtnEnable = id => {
		
	}

	const dispatchBtnDisable = id => {
		dispatch("dispatchDisable", {
			id: id
		});
	}
	*/

	const stringToDate = (str) => {
		const parts = str.split(':');
		return moment(selectedDay).hour(parseInt(parts[0])).minute(parseInt(parts[1])); //clonamos el dia selecionado y le ponemos la hora
	}

	// start, end y total son 'HH:mm' o '?' o ''
	const modifyData = (field) => {
		start = formatTime(start); // 'HH:mm' o -1
		end = formatTime(end); // 'HH:mm' o -1
		total = formatTime(total); // 'HH:mm' o -1
		const startDate = stringToDate(start); // momentJS date
		let endDate = stringToDate(end); // momentJS date
		const totalParts = total.split(':'); // [ ]
		
		if (field === 'total' && total !== totalCopy) {
			if (total !== -1) {
				endDate = moment(startDate).add(totalParts[0], 'hours').add(totalParts[1], 'minutes');
			} else {
				total = '?';
			}
		}

		if (start === -1) {
			start = '?';
		} else if (end === -1) {
			end = '?';
		} else if (total === -1) {
			total = '?';
		} else {
			if (field !== 'total' && startDate > endDate) {
				endDate = endDate.add(1, 'days');
			}
			if (start !== startCopy || end !== endCopy || (field === 'total' && total !== totalCopy)) {
				dispatchBtnModify(startDate.format('x'), endDate.format('x'));
			}
		}
	}

	let isRoundOff = false;
	if (taskProject === " - Time Off") {
		taskProject = taskProject.replace(' - ', '');
		isRoundOff = true;
	}

</script>

<div class="container flex w-100 {isRoundOff ? 'bgColor' : ''}">
	<div class="project h-100 padding align-center">
		<span class="w-100 hidden-ellipsis">{taskPhase}</span>
	</div>
	<div class="description h-100 padding align-center">
		{#if modificable || observer}
		<input
			class="w-100 hidden-ellipsis {isRoundOff ? 'bgColor' : ''}"
			type="text"
			name="description"
			id="description"
			bind:value={taskDescription}
			on:blur={() => (dispatchBtnModify(startMillis, endMillis))}
		/>
		{:else}
		<span class="hidden-ellipsis">{taskDescription}</span>
		{/if}
	</div>
	<div class="project h-100 padding align-center">
		<span class="w-100 hidden-ellipsis">{taskProject}</span>
	</div>
	<div class="task h-100 padding align-center">
		<span class="w-100 hidden-ellipsis">{taskType}</span>
	</div>
	<div class="billable flex h-100 padding align-center">
		{#if taskPhase !== "Time Off"}
			<img class="euro" src="/images/new/euro.svg" alt="No billable" />
		<!-- {:else}
			<img class="icon" src="/images/euro_azul.svg" alt="Billable" /> -->
		{/if}
	</div>
	<div class="time_container h-100 padding flex align-center">
		<img class="icon" src="/images/new/chrono.svg" alt="chrono" />

		{#if modificable || observer}
		<input
			class={isRoundOff ? 'bgColor' : ''}
			type="text"
			name="time_start"
			id="time_start"
			bind:value={start}
			on:blur={() => (modifyData('start'))}
		/>
		<span class="padding">to</span>
		<input
			class={isRoundOff ? 'bgColor' : ''}
			type="text"
			name="time_end"
			id="time_end"
			bind:value={end}
			on:blur={() => (modifyData('end'))}
		/>
		{:else}
		<span>{start}</span> <span class="padding">to</span> <span>{end}</span>
		{/if}
	</div>
	<div class="time_total h-100 padding align-center">
		{#if modificable || observer}
		<input
			class={isRoundOff ? 'bgColor' : ''}
			type="text"
			name="total"
			id="total"
			bind:value={total}
			on:blur={() => (modifyData('total'))}
		/>
		{:else}
		<span>{total}</span>
		{/if}
		<span>h</span>
	</div>
	{#if modificable}
	<div class="buttons flex align-center">
		<img class="icon pointer" src="/images/new/copy.svg" alt="copy" on:click={() => dispatchBtnCopy(id)}/>
		<img class="icon pointer" src="/images/new/x.svg" alt="remove" on:click={() => dispatchBtnDelete(id)}/>
	</div>
	<!--
	{:else if !modificable && observer}
		<img class="icon pointer" src="/images/new/copy.svg" alt="copy" on:click={() => dispatch("dispatchEnable", id)}/>
	{:else if modificable && observer}
		<img class="icon pointer" src="/images/new/copy.svg" alt="copy" on:click={() => dispatch("dispatchDisable", id)}/>
	-->
	
	<!--
		{:else if !modificable && observer}
		<img class="icon pointer" src="/images/new/copy.svg" alt="copy" on:click={() => dispatchBtnCopy(id)}/>
	-->
	{/if}
</div>

<style>
	.bgColor {
		background-color: #eef0f2;
	}

	.hidden-ellipsis {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.align-center {
		align-items: center !important;
		display: flex;
	}

	.pointer {
        cursor: pointer;
	}
	.container {
		height: 30px;
		border-radius: 40px;
	}
	.flex {
		align-items: center;
		justify-content: space-between;
		display: flex;
	}

	.billable {
		min-width: 42px;
	}

	.w-100 {
		width: 100% !important;
	}

	.description {
		flex-grow: 7;
		max-width: 250px;
	}

	.project,
	.task {
		flex-grow: 3;
		max-width: 250px;
	}

	.padding {
		padding: 0 10px 0 10px;
	}

	.h-100 {
		height: 100% !important;
	}

	.icon {
		width: 14px;
		margin: 0 5px 0 5px;
	}

	.time_total {
		margin: 0 10px 0 10px;
	}

	.time_total input,
	.time_container input {
		width: 60px;
		text-align: right;
	}

	.time_container > input:nth-child(2),
	.time_total input {
		text-align: right;
	}

	.time_container > input:nth-child(4) {
		text-align: left;
	}

	.time_container {
		width: 160px;
	}

	input[type="text"] {
		border: none;
	}

	.euro {
		width: 10px;
	}
</style>
