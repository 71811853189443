import { encrypt, decrypt } from "../../js/encryption";

const CALENDAR_CONF = "68991136654887752941";
const GCALENDAR_CONF = "68991136654887752942";
const EACALENDAR_CONF = "68991136654887752943";
const BCALENDAR_CONF = "68991136654887752944";
const HCALENDAR_CONF = "68991136654887752945";

export let saveCalendar = (calendar) => {
  let text = JSON.stringify(calendar);
  localStorage.setItem(CALENDAR_CONF, encrypt(text));
};

export let saveGCalendar = (calendar) => {
  let text = JSON.stringify(calendar);
  localStorage.setItem(GCALENDAR_CONF, encrypt(text));
};

export let saveEACalendar = (calendar) => {
  localStorage.setItem(EACALENDAR_CONF, encrypt(calendar));
};

export let saveBCalendar = (calendar) => {
  localStorage.setItem(BCALENDAR_CONF, encrypt(calendar));
};

export let saveHCalendar = (calendar) => {
  localStorage.setItem(HCALENDAR_CONF, encrypt(calendar));
};

export let getCalendar = () => {
  const calendar = localStorage.getItem(CALENDAR_CONF);
  const text = calendar ? JSON.parse(decrypt(calendar)) : "";
  return text;
};

export let getGCalendar = () => {
  const calendar = localStorage.getItem(GCALENDAR_CONF);
  const text = calendar ? JSON.parse(decrypt(calendar)) : [];
  return text;
};

export let getEACalendar = () => {
  const calendar = localStorage.getItem(EACALENDAR_CONF);
  const text = calendar ? decrypt(calendar) : "1";
  return text;
};

export let getBCalendar = () => {
  const calendar = localStorage.getItem(BCALENDAR_CONF);
  const text = calendar ? decrypt(calendar) : "1";
  return text;
};

export let getHCalendar = () => {
  const calendar = localStorage.getItem(HCALENDAR_CONF);
  const text = calendar ? decrypt(calendar) : "1";
  return text;
};
