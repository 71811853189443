import moment from 'moment';

export const checkTime = (str) => {
    let time = "";
    if (str.includes(":")) {
        time = str.replace(":", "");
    } else {
        time = str;
    }

    let nTime = time.length;
    if (nTime < 5 && !isNaN(time)) {
        time = time.split("");

        let hours = "",
            minutes = "";
        if (nTime === 4) {
            hours = time[0] + "" + time[1];
            minutes = time[2] + "" + time[3];
        } else if (nTime === 3) {
            hours = "0" + time[0];
            minutes = time[1] + "" + time[2];
        } else if (nTime === 2) {
            hours = time[0] + "" + time[1];
            minutes = "00";
        } else if (nTime === 1 && time[0] !== "?") {
            hours = "0" + time[0];
            minutes = "00";
        }

        if (
            parseInt(hours) >= 0 &&
            parseInt(hours) < 24 &&
            parseInt(minutes) >= 0 &&
            parseInt(minutes) < 60
        ) {
            return hours + ":" + minutes;
        } else {
            return false;
        }
    }
    return false;
}

export const checkDate = (date) => {
    if (date.includes("-")) {
        let diaMesAño = date.split("-");
        let nums = diaMesAño.every((e) => {
            return !isNaN(e);
        });

        if (
            diaMesAño.length !== 3 ||
            nums ||
            parseInt(diaMesAño[0]) < 1 ||
            parseInt(diaMesAño[0]) > 31 ||
            parseInt(diaMesAño[1]) < 1 ||
            parseInt(diaMesAño[1]) > 12 ||
            parseInt(diaMesAño[2]) < 2010 ||
            parseInt(diaMesAño[2]) > 2300
        ) {
            return false;
        } else {
            return true;
        }
    } else if (date.includes("/")) {
        let diaMesAño = date.split("/");
        let nums = diaMesAño.every((e) => {
            return !isNaN(e);
        });

        if (
            diaMesAño.length !== 3 ||
            nums ||
            parseInt(diaMesAño[0]) < 1 ||
            parseInt(diaMesAño[0]) > 31 ||
            parseInt(diaMesAño[1]) < 1 ||
            parseInt(diaMesAño[1]) > 12 ||
            parseInt(diaMesAño[2]) < 2010 ||
            parseInt(diaMesAño[2]) > 2300
        ) {
            return false;
        } else {
            return true;
        }
    } else if (
        date === "yesterday" ||
        date === "ayer" ||
        (!isNaN(date) && parseInt(date) > 0 && parseInt(date) < 31)
    ) {
        return true;
    } else {
        return false;
    }
}

export const formatTime = (timeString) => {
    if (timeString.includes(" ")) {
        let parts = timeString.split(" ");

        let time = checkTime(parts[0]);
        if (time !== false) {
            let validateDate = checkDate(parts[1]);
            let fecha = "";
            if (validateDate) {
                fecha = parts[1];
            } else {
                fecha = "ayer";
            }
            return time + " " + fecha;
        } else {
            return -1;
        }
    } else {
        let time = checkTime(timeString);
        if (time !== false) {
            return time;
        } else {
            return -1;
        }
    }
}

export const longMsToTime = (s) => {
    const date = new Date(parseInt(s));
    let hours = date.getHours();
    let minutes = date.getMinutes();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes;
}

const pad = (n, z) => {
    z = z || 2;
    return ('00' + n).slice(-z);
}

export const msToTime = (s) => {
    if (isNaN(s) || s === undefined || s === null || s === 0) return '00:00';

    const positive = s > 0;
    s = Math.abs(s);

    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;
    let res = Math.round(hrs) + ':' + pad(mins);
    res = (!positive) ? '-' + res : res;
    return res;
}

export const timeToMs = (strg) => {
    const type = typeof (strg);
    if (type !== 'string' || !strg.includes(':')) {
        strg = "00:00";
    }
    let strgParts = strg.split(":");

    let hours = parseInt(strgParts[0]);
    let minutes = parseInt(strgParts[1]);

    return ((hours * 3600) + (minutes * 60)) * 1000;
}

export const msToFloat = (ms) => Math.round(((ms / 3600000) + Number.EPSILON) * 100) / 100;


/**
 * 
 * @param {Integer, String} ms 
 * @returns {String}
 * 
 * Obtiene por parámetro los milisegundos y lo convierte a fecha con el objeto propio de JS, ya que momentjs es algo lento y no se recomienda utilizarlo
 */
export const msToDateTime = (ms) => {
    const date = new Date(parseInt(ms));
    return date.toLocaleString();
}

/**
 * Obtiene por parametro 2 objetos de MomentJS, fecha inicio y fecha fin.
 * Holidays es un array de objetos moment con formato [ {date: momentObj}, {date: momentObj}, ... ]
 * 
 * Si se pone 'separated' a true, devuelve un objeto con los distintos tipos de dia no laboral por separado
 * Devuelve el numero de días no laborales, incluyendo festivos. No cuenta dos veces si el festivo cae en fin de semana.
 */
export const countNonWorkingHours = (from, to, holidays = [], result = "all") => {
    let weekendDaysCounter = 0, holidaysCounter = 0;
    const [start, end] = [moment(from), moment(to)];

    if (start.startOf('day') !== end.startOf('day').subtract(1, 'ms')) {
        for (let i = moment(start); i.isSameOrBefore(end, "day"); i.add(1, 'days')) {
            if (i.isoWeekday() === 6 || i.isoWeekday() === 7) {
                weekendDaysCounter += 8;
            } else {
                holidays.forEach((e) => {
                    if (e.date.isSame(i, 'day')) {
                        holidaysCounter += 8;
                    }
                });
            }
        }
    }

    switch (result) {
        case 'all':
            return weekendDaysCounter + holidaysCounter;
        case 'separated':
            return { weekendDays: weekendDaysCounter, holidays: holidaysCounter };
        case 'weekendHours':
            return weekendDaysCounter;
        case 'holidays':
            return holidaysCounter;
    }
}

export const getDateSring = () => {
    let today = (new Date()).toDateString();
    let todayParts = today.split(" ");
    let todayName = getDayName(todayParts[0]);
    let todayNumb = getDayNumb(todayParts[2]);

    return todayParts[1] + " " + todayNumb + ", " + todayName;
}

const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
}

const getDayName = (day) => {
    switch (day) {
        case "Mon":
            return "Monday";
        case "Tue":
            return "Tuesday";
        case "Wed":
            return "Wednesday";
        case "Thu":
            return "Thusday";
        case "Fri":
            return "Friday";
        case "Sat":
            return "Saturday";
        case "Sun":
            return "Sunday";
    }
}

const getDayNumb = (numb) => {
    let nParts = numb.split("");
    let res = parseInt(numb).toString();
    switch (nParts[nParts.length - 1]) {
        case "1":
            return res += "st";
        case "2":
            return res += "nd";
        case "3":
            return res += "rd";
        default:
            return res += "th";
    }
}

/**
 * Transforma minutos a string "HH:mm"
 * 
 * @param {number} n numero de minutos a transformar
 * @returns {string} hora formateada como 'H:mm'
 */

export const timeConvert = (n = 0) => {
    const dataIn = parseInt(n);
    n = isNaN(dataIn) ? 0 : n;

    let positive = true;
    if (n < 0) {
        positive = false;
        n = Math.abs(n);
    }
    const hours = (n / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    const res = `${rhours}:${rminutes.toString().length === 1 ? '0' + rminutes : rminutes}`;
    return positive ? res : '-' + res;
}

export const getMinutesFromAbsence = (start, end, holidays, minutesDiff) => {
    if (minutesDiff >= 480 && minutesDiff < 1440) {
        return 480;
    } else if (minutesDiff < 480) {
        return minutesDiff;
    }

    if (minutesDiff >= 1440) {
        const weekendHours = countNonWorkingHours(start, end, holidays, 'weekendHours');
        const weekendMinutes = weekendHours * 60;
        minutesDiff = (Math.round((minutesDiff / 60) / 24) * 8) * 60; // Converts days of 24h to days of 8h
        return minutesDiff - weekendMinutes;
    }
}