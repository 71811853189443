<script>
  export let quest;
  export let onResend;
  export let onEdit;

  import Picture from "./Picture.svelte";
  import HOTPPeople from "./HOTPPeople.svelte";
  import HOTPStatsItem from "./HOTPStatsItem.svelte";
  import moment from 'moment';
  import { linkify } from "../js/linkify";

  let numRead = "";
  let numLeft = "";
  let answers = [];
  let noAnswers = [];

  let stats = [];
  let statsNotAnswered = 0;

  let expired = false;

  let showInfo = false;

  let questInfo = () => {
    const reads = quest.people.reduce((acc, curr) => {
      if (curr.isRead) {
        acc++;
      }
      return acc;
    }, 0);
    numRead = reads < 10 ? "0" + reads.toString() : reads.toString();

    const answered = quest.people.reduce((acc, curr) => {
      if (curr.answer) {
        acc++;
      }
      return acc;
    }, 0);
    const left = quest.people.length - answered;
    numLeft = left < 10 ? "0" + left.toString() : left.toString();
  };

  let prepareAnswers = () => {
    answers = [];
    noAnswers = [];
    answers = quest.answers.map((a) => {
      return {
        id: a.id,
        title: a.answer,
        people: [],
      };
    });
    quest.people.forEach((p) => {
      if (p.answer) {
        let answer = answers.find((a) => {
          return a.id === p.answer.id;
        });
        if (answer) {
          answer.people.push({
            id: p.id,
            name: p.name,
            pic: p.pic,
            date: p.answerDateTime,
            isRead: true,
          });
        }
      } else {
        noAnswers.push({
          id: p.id,
          name: p.name,
          pic: p.pic,
          isRead: p.isRead,
        });
      }
    });

    answers = answers;
    noAnswers = noAnswers;

    console.log("ANSWERS", answers);
    console.log("NO ANSWERS", noAnswers);
  };

  let getStatistics = () => {
    stats = quest.answers.map((a) => {
      return {
        id: a.id,
        title: a.answer,
        stats: 0,
      };
    });
    let num = 0;
    answers.forEach((a) => {
      let stat = stats.find((s) => {
        return s.id === a.id;
      });
      stat.stats = a.people.length;
      num += a.people.length;
    });
    statsNotAnswered = noAnswers.length;
    num += noAnswers.length;

    stats.forEach((s) => {
      s.stats = (s.stats / num) * 100;
    });
    statsNotAnswered = (statsNotAnswered / num) * 100;

    console.log(stats);
    console.log(statsNotAnswered);
  };

  let toggleShowInfo = () => {
    showInfo = !showInfo;
  };

  let checkExpired = () => {
    const now = moment().format("YYYY-MM-DD HH:mm:ss");
    expired = quest.expiredDate < now;
  };

  $: console.log(showInfo);

  $: if (quest) {
    questInfo();
    prepareAnswers();
    getStatistics();
    checkExpired();
  }
</script>

<div class="hotpItemContainer {expired ? 'expired' : ''}">
  <div class="header">
    <div class="titleIcon">
      <img src="/images/plus.svg" alt="Quest Title" />
    </div>
    <div class="title"><span>{quest.title.toUpperCase()}</span></div>
    <div class="target"><span>{quest.location.toUpperCase()}</span></div>
    <div class="schedule">
      <span
        >{"SCHEDULE - " +
          moment(quest.scheduleDate, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY HH:mm"
          )}</span
      >
    </div>
    <div class="expires">
      <span
        >{"EXPIRES - " +
          moment(quest.expiredDate, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY HH:mm"
          )}</span
      >
    </div>
    <div class="read">
      <span>{numRead + " READ"}</span>
    </div>
    <div class="left">
      <span>{numLeft + " LEFT"}</span>
    </div>
    <div class="creationDate">
      <span
        >{moment(quest.creationInfo.date, "YYYY-MM-DD HH:mm:ss").format(
          "DD/MM/YYYY HH:mm:ss"
        )}</span
      >
    </div>
    <div class="creationUser">
      <div class="pic">
        <Picture
          image={quest.creationInfo.pic}
          name={quest.creationInfo.name}
          small={true}
        />
      </div>
      <span>{quest.creationInfo.name}</span>
    </div>
    <div class="iconEye fadedButton">
      <img
        src="/images/eye.svg"
        alt="Preview Quest"
        on:click={() => toggleShowInfo()}
      />
    </div>
    <div class="edit fadedButton" on:click={onEdit}>
      <span>EDIT</span>
      <img src="/images/edit.svg" alt="Edit Quest" />
    </div>
  </div>
  <div class="body {showInfo ? '' : 'hiddenElement'}">
    <div class="bodyInfo">
      <div class="HOTP" id={"HOTP_" + quest.id}>
        <span class="HOTPTitle">{quest.title}</span>
        <span class="HOTPDescription"
          >{@html linkify(quest.description.replace(/\n/g, "<br/>"))}</span
        >
      </div>
      <div class="answers">
        <span class="HOTPTitle">Team</span>
        <div class="results customScrollbars">
          {#each answers as answer}
            {#each answer.people as people}
              <HOTPPeople
                questId={quest.id}
                answerId={people.id}
                title={answer.title}
                {people}
                answered={true}
                isRed={true}
                {onResend}
              />
            {/each}
          {/each}
          {#each noAnswers as answer}
            <HOTPPeople
              questId={quest.id}
              answerId={answer.id}
              title={answer.isRead ? "READ" : "NOT READ"}
              people={{
                name: answer.name,
                pic: answer.pic,
              }}
              answered={false}
              isRead={answer.isRead}
              {onResend}
            />
          {/each}
        </div>
      </div>
    </div>
    <div class="statistics">
      {#each stats as stat}
        <HOTPStatsItem title={stat.title} perc={stat.stats} answered={true} />
      {/each}
      <HOTPStatsItem
        title="Not Answered"
        perc={statsNotAnswered}
        answered={false}
      />
    </div>
  </div>
</div>

<style>
  div.hotpItemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 27px 15px 27px;
    background-color: white;
    border-radius: 14px;
    justify-content: center;
    margin-bottom: 19px;
  }

  div.expired {
    background-color: var(--labit-light-gray) !important;
  }

  div.header {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: var(--labit-card-gray);
    font-weight: bold;
  }

  div.titleIcon {
    width: 2%;
    display: flex;
    align-items: center;
  }

  div.title {
    width: 14%;
  }

  div.target {
    width: 12%;
  }

  div.schedule {
    width: 14%;
  }

  div.expires {
    width: 17%;
  }

  div.read {
    width: 6%;
  }

  div.left {
    width: 6%;
  }

  div.creationDate {
    width: 10%;
  }

  div.creationUser {
    width: 12%;
    display: flex;
    align-items: center;
  }

  div.iconEye {
    width: 2%;
    display: flex;
    align-items: center;
  }

  div.edit {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div.titleIcon img {
    height: 20px;
  }

  div.creationUser div.pic {
    width: 20px;
    height: 20px;
  }

  div.creationUser span {
    margin-left: 5px;
  }

  div.iconEye img {
    height: 20px;
  }

  div.edit img {
    height: 20px;
  }

  div.edit span {
    margin-right: 5px;
  }

  div.body {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }

  div.bodyInfo {
    width: 100%;
    height: 479px;
    display: flex;
    justify-content: space-between;
  }

  div.HOTP {
    height: 479px;
    width: 66%;
    border-radius: 14px;
    border: 1px solid var(--labit-card-gray);
    padding: 26px 36px 26px 36px;
    font-size: 13px;
    color: var(--labit-card-gray);
    display: flex;
    flex-direction: column;
  }

  div.answers {
    height: 479px;
    width: 33%;
    border-radius: 14px;
    border: 1px solid var(--labit-card-gray);
    padding: 26px 36px 26px 36px;
    font-size: 13px;
    color: var(--labit-card-gray);
    display: flex;
    flex-direction: column;
  }

  span.HOTPTitle {
    font-weight: bold;
    margin-bottom: 10px;
  }

  div.results {
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
  }

  div.statistics {
    width: 100%;
    display: flex;
    justify-content: center;
  }
</style>
