<script>
  export let weekdays;
  export let date;
  export let selectedMonth;
  export let selectedYear;
  export let selectedOption;
  export let onClickDay;

  import CalendarPickerWeekdayCell from "./CalendarPickerWeekdayCell.svelte";

  let showRow = true;

  $: showRow = weekdays.reduce((acc, curr) => {
    return acc || curr >= 0;
  }, false);
</script>

<style>
  div.container {
    width: 100%;
    display: flex;
    margin-bottom: 3px;
  }
</style>

{#if showRow}
  <div class="container">
    <CalendarPickerWeekdayCell
      text={weekdays[0]}
      {date}
      {selectedMonth}
      {selectedYear}
      disabled={selectedOption}
      onClick={onClickDay} />
    <CalendarPickerWeekdayCell
      text={weekdays[1]}
      {date}
      {selectedMonth}
      {selectedYear}
      disabled={selectedOption}
      onClick={onClickDay} />
    <CalendarPickerWeekdayCell
      text={weekdays[2]}
      {date}
      {selectedMonth}
      {selectedYear}
      disabled={selectedOption}
      onClick={onClickDay} />
    <CalendarPickerWeekdayCell
      text={weekdays[3]}
      {date}
      {selectedMonth}
      {selectedYear}
      disabled={selectedOption}
      onClick={onClickDay} />
    <CalendarPickerWeekdayCell
      text={weekdays[4]}
      {date}
      {selectedMonth}
      {selectedYear}
      disabled={selectedOption}
      onClick={onClickDay} />
    <CalendarPickerWeekdayCell
      text={weekdays[5]}
      {date}
      {selectedMonth}
      {selectedYear}
      disabled={selectedOption}
      onClick={onClickDay} />
    <CalendarPickerWeekdayCell
      text={weekdays[6]}
      {date}
      {selectedMonth}
      {selectedYear}
      disabled={selectedOption}
      onClick={onClickDay} />
  </div>
{/if}
