<script>
  export let icon;
  export let text;
  export let active;
  export let onClick;
</script>

<style>
  div.container {
    display: flex;
    align-items: center;
    height: 52px;
    background-color: #ffffff;
    border-radius: 19px;
    margin-right: 20px;
    justify-content: center;
    cursor: pointer;
  }

  div.active {
    opacity: 1;
  }
  div.inactive {
    opacity: 0.5;
  }

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 15px;
    font-weight: bold;
    color: var(--labit-card-labit-background);
    margin-left: 13px;
  }

  /* Animations */
  div.inactive {
    opacity: 0.5;
    transition: opacity 0.5s linear 0s;
  }

  div.inactive:hover {
    opacity: 1;
  }
</style>

<div class="container {active ? 'active' : 'inactive'}" on:click={onClick}>

  <img src={icon} alt="Menu Icon" class={active === text ? '' : 'anim'} />
  <span class="anim">{text}</span>

</div>
