<script>
  export let visible;

  import DashboardTitle from "../DashboardTitle.svelte";
  import OutlookEmailsMessage from "./OutlookEmailsMessage.svelte";

  import { outlookLogin, outlookGetMessages } from "./outlook";
  import {
    getPlatform,
    setLastOutlookMessage,
  } from "../../../js/localInfo";
  import { openNewWindow } from "../../../js/openNewWindow";
  import { openURL } from "../../../js/openURL";
  import { OUTLOOK_URL } from "../../../js/globalExternalURLs";

  import { onMount } from "svelte";

  const platform = getPlatform();

  let error = false;
  let numNewMessages = 0;

  let messages = [];
  let loadingMessages = false;

  let timerController = null;

  const OUTLOOK_TIMER = 60000; // 1 min

  onMount(() => {
    //loadInfo();
  });

  /*let getMessages = async () => {
    //console.log("EMAILS");
    token = getOutlookToken();
    if (token !== "") {
      if (visible && !loadingMessages) {
        loadingMessages = true;
        outlookGetMessages(
          (m, nm) => {
            messages = m;
            numNewMessages = nm;
            error = false;
            if (m.length > 0) {
              setLastOutlookMessage(m[0].id);
            }
            timerController = setTimeout(getMessages, OUTLOOK_TIMER);
            loadingMessages = false;
          },
          () => {
            outlookLogin(
              async () => {
                outlookGetMessages(
                  (m, nm) => {
                    messages = m;
                    numNewMessages = nm;
                    error = false;
                    if (m.length > 0) {
                      setLastOutlookMessage(m[0].id);
                      loadingMessages = false;
                      timerController = setTimeout(getMessages, OUTLOOK_TIMER);
                    }
                  },
                  () => {
                    error = true;
                    loadingMessages = false;
                    timerController = setTimeout(getMessages, OUTLOOK_TIMER);
                  }
                );
              },
              () => {
                error = true;
                loadingMessages = false;
                timerController = setTimeout(getMessages, OUTLOOK_TIMER);
              }
            );
          }
        );
      }
    } else {
      timerController = setTimeout(getMessages, OUTLOOK_TIMER);
    }
  };*/

  let getMessages = () => {
    //console.log("EMAILS");
    if (visible && !loadingMessages) {
      loadingMessages = true;
      outlookGetMessages(
        (m, nm) => {
          messages = m;
          numNewMessages = nm;
          error = false;
          if (m.length > 0) {
            setLastOutlookMessage(m[0].id);
          }
          timerController = setTimeout(getMessages, OUTLOOK_TIMER);
          loadingMessages = false;
        },
        () => {
          error = true;
          loadingMessages = false;
          timerController = setTimeout(getMessages, OUTLOOK_TIMER);
        }
      );
    }
  };

  let clickBox = () => {
    if (error) {
      outlookLogin(
        () => {
          openURL("/dashboard", true);
        },
        () => {
          error = true;
        }
      );
    } else {
      if (platform === "APP") {
        openURL("/outlook", true);
      } else {
        openNewWindow(OUTLOOK_URL);
      }
    }
  };

  let loadInfo = () => {
    /*token = getOutlookToken();
    if (token !== "") {
      error = false;*/
    getMessages();
    //}
  };

  let cancelTimers = () => {
    clearTimeout(timerController);
    loadingMessages = false;
    timerController = null;
  };

  $: if (visible === true) {
    loadInfo();
  } else {
    cancelTimers();
  }
</script>

<div class="dashboardContainer">
  <DashboardTitle title="Outlook" numEvents={numNewMessages} />
  <div class="dashboardBody" on:click={() => clickBox()}>
    {#if error}
      <div class="error">
        <span>
          Outlook connection failed or token expired. Click this box to retry.
        </span>
      </div>
    {:else}
      <div class="messages customScrollbars">
        {#each messages as message}
          <OutlookEmailsMessage {message} />
        {/each}
      </div>
    {/if}
  </div>
</div>

<style>
  div.dashboardContainer {
    width: 100%;
    height: 100%;
  }

  div.dashboardBody {
    width: 100%;
    height: 85%;
    padding: 5%;
    cursor: pointer;
  }

  div.messages {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  div.error {
    width: 100%;
    height: 100%;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
</style>
