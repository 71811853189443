<script>
  export let title;
  export let titleIcon;

  import ColorItem from "./ColorItem.svelte";
  import EditBoxTab from "./EditBoxTab.svelte";
  import EditBoxButton from "./EditBoxButton.svelte";
  import ColorPicker from "../components/ColorPicker/ColorPicker.svelte";

  import DialogSpanFullEdit from "../modules/project-analytics/screens/components/DialogSpanFullEdit.svelte";

  import { post } from "../js/labit-connection";

  import { onMount } from "svelte";

  let backgroundColorInput = "#d7e1ea";
  let borderColor = "var(--labit-card-gray)";

  let creating = false;
  let editing = null;
  let deleting = null;

  let currentColor = "#ffffff";

  let errorColor = false;

  let colors = [];

  let cancelTimer = null;

  const CANCEL_DELETING_TIME = 10000;

  onMount(async () => {
    await getColors();
  });

  let getColors = async () => {
    const response = await post(
      `select id, color
            from labitColors`
    );
    colors = response.map((item) => {
      return {
        id: item.id,
        color: item.color,
      };
    });
  };

  let requestAdd = () => {
    creating = true;
    editing = null;
    deleting = null;
    currentColor = "";
    errorColor = false;
    clearTimeout(cancelTimer);
  };

  let requestEdit = (index) => {
    creating = false;
    editing = colors[index].id;
    deleting = null;
    currentColor = colors[index].color;
    errorColor = false;
    clearTimeout(cancelTimer);
  };

  let requestDelete = async (index) => {
    creating = false;
    editing = null;
    errorColor = false;
    clearTimeout(cancelTimer);
    if (!deleting) {
      deleting = colors[index].id;
      cancelTimer = setTimeout(cancelDelete, CANCEL_DELETING_TIME);
    } else {
      await post(
        `delete from labitColors
                where id=${deleting}`
      );
      colors = colors.filter((item) => {
        return item.id !== deleting;
      });
      closeForm();
    }
  };

  let cancelDelete = () => {
    deleting = null;
  };

  let closeForm = () => {
    creating = false;
    editing = null;
    deleting = null;
    currentColor = "";
    errorColor = false;
  };

  let save = async () => {
    errorColor = false;
    if (currentColor.trim() === "") {
      errorColor = true;
      return;
    }
    if (creating) {
      const response = await post(
        `insert into labitColors (color)
                values ('${currentColor}')`
      );
      const color = {
        id: response[0],
        color: currentColor,
      };
      colors.push(color);
    } else {
      await post(
        `update labitColors set
                color='${currentColor}'
                where id=${editing}`
      );
      let color = colors.find((item) => {
        return item.id === editing;
      });
      if (color) {
        color.color = currentColor;
      }
    }
    colors = colors;
    closeForm();
  };
</script>

<div class="box">
  <div class="editBoxTab">
    <EditBoxTab icon={titleIcon} text={title} />
  </div>
  <div class="items">
    <div
      class="itemList customScrollbars {creating || editing
        ? 'itemListMin'
        : ''}"
    >
      {#each colors as color, index}
        <ColorItem
          {color}
          onEdit={() => requestEdit(index)}
          onDelete={() => requestDelete(index)}
          deletingId={deleting}
        />
      {/each}
    </div>
    <div class="plus">
      <img
        class="fadedButton"
        src="/images/plus.svg"
        alt="Add"
        on:click={() => requestAdd()}
      />
    </div>
  </div>
  <div class="form {creating || editing ? '' : 'hiddenElement'}">
    <span class="editBoxTitle requiredElement">Color</span>
    <div class="colorpicker">
      <ColorPicker bind:currentColor />
    </div>
    <div class="editBoxAcceptCancel">
      <EditBoxButton text="Save" onClick={() => save()} />
      <EditBoxButton text="Close" onClick={() => closeForm()} />
    </div>
  </div>
</div>

<style>
  div.box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div.editBoxTab {
    width: 428px;
    margin-bottom: 25px;
  }

  span.editBoxTitle {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 10px;
    width: 100%;
  }

  div.editBoxAcceptCancel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  div.items {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 15px;
    border: 1px solid var(--labit-card-gray);
    padding: 15px 23px 15px 23px;
  }

  div.plus {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div.plus img {
    width: 20px;
    height: 20px;
  }

  div.itemList {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
  }

  div.itemListMin {
    max-height: 360px !important;
  }

  div.colorpicker {
    width: 100%;
    height: 250px;
    margin-bottom: 10px;
  }
</style>
