
<script>
import { navigate } from 'svelte-routing';
import { currentAbsenceId } from '../../../js/stores';
import { post } from '../../../js/labit-connection';
import { onMount } from 'svelte';
  
export let isOpen = false; 
export let close;
export let accepted_by_team_leader; 

let id;
let isAbsenceApprovable = false;

onMount(()=>{
  checkApprovableStatus(id);
})

function handleActionAccept() {
    close();
    setAbsenceManageOnModal(id, "1");
    /* setTimeout(() => navigate('/monitor'),0); */
}

function handleActionDenny() {
    close();
    setAbsenceManageOnModal(id, "-1");
    /* setTimeout(() => navigate('/monitor'),0); */
}

currentAbsenceId.subscribe( (value)=> {
  id = value;
  checkApprovableStatus(id);
});

async function setAbsenceManageOnModal(id, accepted) {
  let query = "";
  let result;
  query = `UPDATE absences SET accepted_by_team_leader = ${accepted} WHERE id = ${id}`
	accepted_by_team_leader = accepted === '1' ? "Yes" : "No";
  result = await post(query)
}


async function checkApprovableStatus(absenceId){
  const query = `SELECT t.approvable, a.description FROM absences a INNER JOIN absence_type t ON(absence_type_id = t.id) WHERE a.id  = ${absenceId}`
  const result = await post(query);
  isAbsenceApprovable = result[0]?.approvable === "1";
}

</script>

{#if isOpen}
    <div class="modal-overlay" on:click={close}>
        <div class="modal" on:click|stopPropagation>
            <div class="modalContour">
                <div class="imageClose">
                  <h4>New Absence Requested</h4>  
                  <button class="close-button" on:click={close}>×</button>
                </div>
                  
                <div class="content">
                  <header class="modal-header">
                    <slot name="header"></slot>
                  </header>
                  <div class="modal-content">
                    <slot></slot>        
                  </div>
                </div>
                
                <div class="buttonContainer">
                  <div>
                    <button on:click={close} class="navButton">Hold Over</button>
                  </div>
                  {#if isAbsenceApprovable}
                    <div class="manageButtons">
                      <button on:click={handleActionAccept} class="buttonAccept">Accept</button>
                      <button on:click={handleActionDenny} class="buttonDecline">Decline</button>
                    </div>
                  {/if}
                  
                </div>

            </div>
            
        </div>
    </div>
{/if}


<style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal {
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    min-width: 500px;
    z-index: 1001;
  }
  .imageClose{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 10px;
  }

  .modalContour{
    width: 100%;
    height: 100%;
    padding: 10px 10px;
    border: 1px solid #cdcdcd;
    border-radius: 15px;
  }

  h4{
    font-family: inherit;
    font-size: inherit;
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-header {
    margin-bottom: 20px;
  }

  .manageButtons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .buttonAccept{
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background-color: #a7e521;
    color: white;
  }

  .buttonDecline{
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background-color: crimson;
    color: white;
  }

  .navButton{
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background-color:  #cdcdcd;
    color: white;
  }

  .navButton:hover{
    background-color: #AED0FA;
  }

  .buttonContainer{
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
    padding-top: 10px;
  }

  .close-button {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 1.8rem;
    padding-top: 5px;
    margin-bottom: 0;
  }
  .modal-content {
    font-family: inherit;
    font-size: inherit;
  }

</style>
