<script>
  export let visible;
  export let clicked;
  export let openSlackUrl = "";

  import MenuToolSlack from "../components/MenuToolSlack.svelte";
  import FormTextEdit from "../components/FormTextEdit.svelte";
  import Button from "../components/Button.svelte";
  import SlackIcon from "../components/SlackIcon.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  
  import {
    getUserId,
    getOpenSlack,
  } from "../js/localInfo";
  import { copyObject } from "../js/copyObject";
  import { post } from "../js/labit-connection";
  import { openNewWindow } from "../js/openNewWindow";
  import { slackURLCorrection } from "../js/slack";
  import { slackNotification } from "../js/stores";

  import { onMount } from "svelte";

  let managingTools = false;
  let selectedTool = null;
  let formAction = "new"; // new, edit

  let showIndex = 0;

  let slackIconSel = 0;

  const myId = getUserId();
  let myTools = [];

  let notifications = [];

  let toolbarShown = false;

  let loaded = false;

  const MAIN_SLACK = 0;

  onMount(async () => {
    let response = await post(
      `select id, url
        from slacks`
    );

    const slacks = response.map((slack) => {
      return {
        id: slack.id,
        name: "",
        url: slack.url,
        icon: "",
        custom: false,
        changes: false,
      };
    });

    response = await post(
      `select id, url, icon
        from slacks_custom
        where contact=${myId}`
    );

    const customSlacks = response.map((slack) => {
      return {
        id: slack.id,
        name: "",
        url: slack.url,
        icon: parseInt(slack.icon),
        custom: true,
        changes: false,
      };
    });

    myTools = slacks.concat(customSlacks);

    myTools.forEach((slack) => {
      slack.url = slackURLCorrection(slack.url);
    });
    myTools = myTools;

    notifications = myTools.map((slack) => {
      return false;
    });

    const index = getOpenSlack();
    if (index >= 0) {
      showIndex = index;
    }

    if (openSlackUrl !== "") {
      showSlackUrl();
    }

    checkWebviewsAvailability();
    checkTitleFrequently();

    loaded = true;
  });

  let retryCount = 0;
  const maxRetries = 10;

  let checkWebviewsAvailability = () => {
    if (visible && retryCount < maxRetries) {
      let counter = 0;
      myTools.forEach((slack, index) => {
        let webview = document.querySelector("#webview_slack_" + index);
        if (webview) {
          counter++;
        }
      });
      if (counter === myTools.length) {
        // Todos los webviews están disponibles, asegúrate de que los listeners estén añadidos correctamente
        myTools.forEach((slack, index) => {
          let webview = document.querySelector("#webview_slack_" + index);
          if (webview && !webview.listenerAdded) {
            webview.addEventListener("page-title-updated", (e) => {
              checkNotification(e.title, index);
            });
            webview.listenerAdded = true;
          }
        });
        retryCount = 0; // Resetear el contador de reintentos
      } else {
        setTimeout(checkWebviewsAvailability, 1000);
        retryCount++;
      }
    }
  };

  let checkTitleFrequently = () => {
    let webview = document.querySelector("#webview_slack_0"); // Asumiendo que es el webview de Slack
    if (webview) {
      let currentTitle = webview.getTitle();
      checkNotification(currentTitle, 0);
    }
    setTimeout(checkTitleFrequently, 500); // Reduce el tiempo de espera para hacerlo más reactivo
  };


  let checkNotification = (title, index) => {
      const newMessageIndicator = "1 nuevo elemento";
    if (title.includes(newMessageIndicator)) {
      console.log("Nuevo mensaje detectado en webview " + index);
      slackNotification.set(true);
    }else{
      slackNotification.set(false);
    }
  } 

  let doubleCheckNotification = (index) => {
    let webview = document.querySelector("#webview_slack_" + index);
    if (webview) {
      const title = webview.getTitle();
      const tokens = title.split("|");
      if (tokens.length > 1 && tokens[0] !== "Conectarse ") {
        notifications[index] = tokens.length > 3;
        notifications = notifications;

        /*notification = notifications.reduce((acc, curr) => {
          return acc || curr;
        }, false);
        setSlackNotification(notification ? "1" : "0");*/
      }
    }
  };

  /*let reloadSlacks = () => {
    myTools.forEach((slack, index) => {
      if (index !== showIndex) {
        let webview = document.querySelector("#webview_" + index);
        if (webview) {
          webview.reloadIgnoringCache();
        }
      }
    });
    setTimeout(reloadSlacks, 5000);
  };*/

  let showToolForm = (show) => {
    managingTools = show;
    if (show) {
      formAction = "new";
      selectedTool = {
        id: -1,
        icon: "",
        url: "",
        custom: true,
        changes: false,
      };
    }
  };

  let toggleToolForm = () => {
    if (managingTools) {
      showToolForm(false);
    } else {
      showToolForm(true);
    }
  };

  let clickTool = (index, forceNewWindow) => {
    if (forceNewWindow) {
      openNewWindow(myTools[index].url);
    } else {
      showIndex = index;
      myTools = myTools;
      let webview = document.querySelector("#webview_slack_" + index);
      if (webview) {
        webview.focus();
      }
    }
  };

  let addTool = async () => {
    if (selectedTool.url !== "") {
      const response = await post(
        `insert into slacks_custom (
          url, 
          icon,
          contact
        )values(
          '${selectedTool.url}',
          ${slackIconSel},
          ${myId}
            )`
      );
      selectedTool.id = response[0];
      selectedTool.icon = slackIconSel;
      selectedTool.url = slackURLCorrection(selectedTool.url);

      myTools.push(selectedTool);
      myTools = myTools;
      showToolForm(false);
      //renderSlackViews(myTools.length - 1);
    }
  };

  let clickEditTool = (tool) => {
    formAction = "edit";
    selectedTool = copyObject(tool);
    slackIconSel = selectedTool.icon;
  };

  let editTool = async () => {
    if (selectedTool.url !== "") {
      await post(
        `update slacks_custom set
          url='${selectedTool.url}',
          icon=${slackIconSel}
          where id=${selectedTool.id}`
      );
      let tool = myTools.find((t) => {
        return t.id === selectedTool.id && t.custom;
      });
      if (tool) {
        tool.url = selectedTool.url;
        tool.icon = slackIconSel;
        myTools = myTools;
        showToolForm(false);
      }

      const pos = myTools.findIndex((slack) => {
        return slack.id === selectedTool.id;
      });
      if (pos >= 0) {
        myTools[pos] = copyObject(selectedTool);
        myTools[pos].icon = slackIconSel;
      }
      myTools = myTools;
      showToolForm(false);
      //renderSlackViews(pos);
    }
  };

  let removeTool = async (index) => {
    const tool = myTools[index];
    await post(
      `delete from slacks_custom
        where id=${tool.id}`
    );
    myTools.splice(index, 1);
    myTools = myTools;
  };

  let closeMenu = () => {
    if (toolbarShown && !managingTools) {
      window.$("#toolBar_slacks").animate(
        {
          width: "0px",
          marginRight: "0px",
        },
        500,
        () => {
          toolbarShown = false;
        }
      );
    }
  };

  let openMenu = () => {
    window.$("#toolBar_slacks").animate(
      {
        width: "48px",
        marginRight: "20px",
      },
      500,
      () => {
        toolbarShown = true;
      }
    );
  };

  let processToolbar = () => {
    if (toolbarShown) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  let showSlackUrl = () => {
    if (myTools.length > 0) {
      myTools[MAIN_SLACK].url = openSlackUrl;
      myTools = myTools;
      clickTool(MAIN_SLACK, false);
    }
    openSlackUrl = "";
  };

  $: if (visible) {
    checkWebviewsAvailability();
  }

  $: if (clicked) {
    processToolbar();
  }

  $: if (openSlackUrl !== "" && loaded) {
    showSlackUrl();
  }
</script>

<div class="content">
  <div class="container">
    <div
      id="toolBar_slacks"
      class="tools"
      
    >
      <div id="toolBar">
        {#each myTools as tool, index}
          <MenuToolSlack
            {tool}
            {index}
            notification={notifications[index]}
            onClick={(index, forceNewWindow) =>
              clickTool(index, forceNewWindow)}
            editMode={managingTools}
            onRemove={() => removeTool(index)}
            onClickEdit={(tool) => clickEditTool(tool)}
          />
        {/each}
        <img
          class="add"
          src="/images/plus.svg"
          alt="Add Tool"
          on:click={() => toggleToolForm()}
        />
      </div>
    </div>
    <div class="iframe-toolform">
      {#if selectedTool && managingTools}
        <div class="toolForm">
          <div class="x">
            <img
              src="/images/x.svg"
              alt="Close Form"
              on:click={() => showToolForm(false)}
            />
          </div>
          <div class="formElement">
            <div class="icons">
              {#each [0, 1, 2, 3, 4, 5, 6, 7] as num}
                <SlackIcon {num} bind:selected={slackIconSel} />
              {/each}
            </div>
          </div>
          <div class="formElement">
            <span>URL or Slack ID</span>
            <div class="formLine">
              <FormTextEdit
                placeholder=""
                maxlength={500}
                bind:value={selectedTool.url}
              />
            </div>
            <div class="column">
              <span>Example (URL): https://app.slack.com/client/TTK55ENTD</span>
              <span>Example (Slack ID): TTK55ENTD</span>
            </div>
          </div>
          <div class="formElement space">
            <div class="formButton">
              <Button
                text="Save"
                onClick={() => {
                  if (formAction === "new") {
                    addTool();
                  } else {
                    editTool();
                  }
                }}
              />
            </div>
          </div>
        </div>
      {/if}
      <div id="slacks" class="iframe">
        {#each myTools as slack, index}
          <div class="slackContainer {index !== showIndex ? 'invisible' : ''}">
            <WebBrowser
              id={"webview_slack_" + index}
              className="slack-webview"
              additionalClasses="slack-webview"
              type="webview"
              iframeTitle="External Tool"
              src={slack.url}
            />
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>
<!--
{#if myTools.length > 0 && visible && !managingTools && myTools[slackIconSel]}
  <ContextMenu
    currentURL={myTools[slackIconSel].url}
    bind:showMenu={showNavMenu}
  />
{/if}
-->
<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
  }

  div.tools {
    height: 100%;
    width: 0px;
    margin-right: 0px;
    min-height: 14px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 14px;
  }

  div#toolBar {
    width: 100%;
    height: 100%;
    background-color: var(--labit-dialog-background);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-top: 33px;
  }

  img.add {
    width: 45%;
    cursor: pointer;
    position: absolute;
    bottom: 33px;
  }

  div.toolForm {
    background-color: var(--labit-dialog-background);
    width: 30%;
    padding: 14px;
    border-radius: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 14px;
  }

  div.toolForm span {
    font-size: 13px;
    color: var(--labit-card-gray);
  }

  div.formElement {
    margin-bottom: 6px;
  }

  div.formLine {
    height: 23px;
  }

  div.formButton {
    width: 77px;
    height: 29px;
  }

  div.space {
    margin-top: 19px;
  }

  div.x {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  div.x img {
    width: 12px;
    cursor: pointer;
  }

  div.iframe {
    width: 100%;
    height: 100%;
    border-radius: 19px;
    margin-top: 20px;
    padding-bottom: 20px;
  }

  div.iframe-toolform {
    width: 100%;
    height: 100%;
    border-radius: 19px;
  }

  div.column {
    display: flex;
    flex-direction: column;
  }

  div.icons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  div#slacks {
    position: relative;
  }

  div.invisible {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
  }

  div.slackContainer {
    height: 100%;
  }

  /* Animations */

  img.add {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  img.add:hover {
    opacity: 0.5;
  }
</style>
