<script>
  export let file;
  export let removeItem;
  export let onClickRemove;
  export let onPreviewFile;
  export let readOnly = false;
  export let compressedMode = false;

  import FileBoobleFileKeyword from "./FileBoobleFileKeyword.svelte";

  import { LABIT_REST_SERVER } from "../js/labit-connection";
  import { getToken } from "../js/localInfo";
  import { openNewWindow } from "../js/openNewWindow";

  let iconImg = "";

  let downloadFile = () => {
    location.href =
      LABIT_REST_SERVER +
      "DownloadFile.php?url=" +
      btoa(unescape(encodeURIComponent(file.id))) +
      "&token=" +
      getToken();
  };

  $: {
    if (file.folder) {
      iconImg = "folder.svg";
    } else {
      if (file.web) {
        iconImg = "globe.svg";
      } else {
        switch (file.extension) {
          case "doc":
          case "docx":
            iconImg = "doc.svg";
            break;
          case "ppt":
          case "pptx":
            iconImg = "ppt.svg";
            break;
          case "xls":
          case "xlsx":
            iconImg = "xls.svg";
            break;
          case "pdf":
            iconImg = "pdf.svg";
            break;
          case "txt":
            iconImg = "txt.svg";
            break;
          case "jpg":
          case "jpeg":
          case "bmp":
          case "png":
          case "gif":
          case "svg":
            iconImg = "image.svg";
            break;
          default:
            iconImg = "file.svg";
            break;
        }
      }
    }
  }
</script>

<div class="fileBoobleFileContainer {compressedMode ? 'compressed' : ''}">
  <div class="fileInfo">
    <div class="iconName">
      <img
        class="fileIcon"
        src="/images/file-icons/{iconImg}"
        alt="File Icon"
      />
      <div class="name">
        <span
          on:click={file.web
            ? () => openNewWindow(file.url)
            : () => downloadFile()}>{file.name}</span
        >
      </div>
    </div>
    {#if file.keywords.length > 0}
      <div class="keywords">
        {#each file.keywords as keyword}
          <FileBoobleFileKeyword {keyword} />
        {/each}
      </div>
    {/if}
  </div>
  {#if file.web}
    <div class="options">
      <span class="lastModified">{file.lastModified}</span>
      <span class="extension">{file.extension}</span>
      <span class="size">{file.size}</span>
      <div class="icons">
        <img
          src="/images/eye.svg"
          alt="Preview File"
          title="Preview File"
          on:click={() => onPreviewFile(file.url)}
        />
        <img
          src="/images/file-icons/out.svg"
          alt="Open URL"
          title="Open URL"
          on:click={() => openNewWindow(file.url)}
        />
        {#if !readOnly}
          <img
            src={removeItem === file.id
              ? "/images/trash-red.svg"
              : "/images/trash.svg"}
            alt="Remove File"
            title="Delete File"
            on:click={() => onClickRemove(file.id)}
          />
        {/if}
      </div>
    </div>
  {:else}
    <div class="options">
      <span class="lastModified">{file.lastModified}</span>
      <span class="extension">{file.extension}</span>
      <span class="size">{file.size}</span>
      <div class="icons">
        <img
          src="/images/eye.svg"
          alt="Preview File"
          title="Preview File"
          on:click={() => onPreviewFile(file.id)}
        />
        <img
          src="/images/download-file.svg"
          alt="Download File"
          title="Download File"
          on:click={() => downloadFile()}
        />
        {#if !readOnly}
          <img
            src={removeItem === file.id
              ? "/images/trash-red.svg"
              : "/images/trash.svg"}
            alt="Remove File"
            title="Delete File"
            on:click={() => onClickRemove(file.id)}
          />
        {/if}
      </div>
    </div>
  {/if}
</div>

<style>
  div.fileBoobleFileContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    color: var(--labit-card-gray);
    margin-bottom: 12px;
  }

  div.compressed {
    flex-direction: column;
  }

  div.fileBoobleFileContainer:first-child {
    margin-top: 10px;
  }

  div.fileInfo {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  div.iconName {
    display: flex;
    width: 100%;
  }

  div.name {
    flex-grow: 1;
  }

  div.name span {
    cursor: pointer;
  }

  div.options {
    display: flex;
    width: 30%;
    justify-content: space-between;
  }

  div.compressed div.options {
    margin-top: 5px;
    width: 100% !important;
    margin-bottom: 5px;
  }

  span.lastModified {
    opacity: 0.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span.extension {
    opacity: 0.5;
    display: flex;
    justify-content: flex-end;
  }

  span.size {
    opacity: 0.5;
    display: flex;
    justify-content: flex-end;
  }

  img.fileIcon {
    height: 15px;
    margin-right: 5px;
  }

  div.icons {
    height: 100%;
    display: flex;
    margin-left: 20px;
  }

  div.icons img {
    height: 15px;
    cursor: pointer;
    margin-left: 5px;
  }

  div.keywords {
    display: flex;
    padding-left: 20px;
    margin-top: 1px;
  }

  /* Animations */

  div.icons img {
    transition: opacity 0.5s linear 0s;
  }

  div.icons img:hover {
    opacity: 0.5;
  }
</style>
