<script>
  export let icon;
  export let text;
</script>

<div class="editBoxTabContainer">
  <img src={icon} alt="Tab Icon" />
  <span>{text}</span>
</div>

<style>
  div.editBoxTabContainer {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    color: var(--labit-card-gray);
    align-items: center;
    margin-bottom: 25px;
  }

  img {
    width: 30px;
    margin: 0px 20px 0px 0px;
  }
</style>
