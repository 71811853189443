<script>
  /*
  import Menu from "../../../components/Menu.svelte";
  import Picture from "../../../components/Picture.svelte";
  import EventManager from "../../calendar/screens/components/EventManager.svelte";
  
  import {
    outlookLogin,
    outlookGetCalendar,
  } from "../../../components/dashboard/outlook/outlook";
  */
  import { onMount, afterUpdate } from "svelte";
  import Conversor from './components/Conversor.svelte';
  import DashboardTitle from "../../../components/dashboard/DashboardTitle.svelte";

  // Just in case you need to access the database through the simplerest API
  import { post } from "../../../js/labit-connection";
  import { getRole } from "../../../js/localInfo";
  import { getDefaultColors } from "../../../js/colors";

  // See src/data/components/menu.json ('text' attribute) to mark current page

  //import EmptyBox from "./components/EmptyBox.svelte";
  import Calculator from "./components/CalculatorBox.svelte";
  //import ColorPicker from "./components/ColorPicker.svelte";
  //import WeatherBox from "./components/WeatherBox.svelte";
  //import PicOfTheDay from "./components/PicOfTheDay.svelte";
  //import Licenses from "./components/LicensesBox.svelte";
  //import Postit from "./components/Postit.svelte";

  //import DialogSpanEdit from "../../../components/DialogSpanEdit.svelte";
  import ButtonIcon from "../../../components/ButtonIcon.svelte";
  import ColorPicker from "../../../components/ColorPicker/ColorPicker.svelte";

  import LinkColumn from "./components/LinkColumn.svelte";

  import LicensesBox from "../../../modules/tools/screens/components/LicensesBox.svelte";

  const PAGE = "TOOLS";

  const myRole = getRole();

  let feet = 0;
  let inches = 0;
  let meters = 0;

  let networkList = [];

  let editLinks = false;

  let linkColumns = [[], [], [], [], [], []];

  let colors = [];
  let currentColor = "#ffffff";

  /*let linkColumns = [
    [
      {
        title: {
          id: 1,
          title: "LINKS 1",
        },
        links: [
          {
            id: 2,
            title: "link 1",
            url: "https://www.google.com",
          },
          {
            id: 3,
            title: "link 2",
            url: "https://himalayac.com",
          },
        ],
      },
      {
        title: {
          id: 4,
          title: "LINKS 2",
        },
        links: [
          {
            id: 5,
            title: "link 3",
            url: "https://www.google.com",
          },
          {
            id: 6,
            title: "link 4",
            url: "https://himalayac.com",
          },
        ],
      },
    ],
    [],
    [],
    [],
    [],
    [],
  ];*/

  /*   jscolor.presets.default = {
    hideOnLeave: false,
    shadow: false,
    container: "div.colorPalette",
    height: 160,
    width: 200,
    padding: 0,
    controlBorderWidth: 0,
    smartPosition: false
  }; */

  let savingLinks = false;

  onMount(async () => {
    /*     jscolor.install();
    document.querySelector("#picker").jscolor.show();

    hex = document.querySelector("#picker").jscolor.toString("hex");
    rgb = document.querySelector("#picker").jscolor.toString("rgb"); */
    //meters = (0.3048 * feet + 0.0254 * inches).toFixed(4);
    await loadNetworkList();

    getLinks();
    colors = await getDefaultColors();
  });

  let getLinks = async () => {
    const responseSections = await post(
      `select id, title, groupColumn, pos
        from linkSection
        order by pos asc`
    );

    const responseLinks = await post(
      `select id, description, url, pos, section
        from linkTool
        order by pos asc`
    );

    let sections = responseSections.map((section) => {
      return {
        title: {
          id: section.id,
          title: section.title,
        },
        links: [],
        group: section.groupColumn,
      };
    });

    responseLinks.forEach((item) => {
      const link = {
        id: item.id,
        title: item.description,
        url: item.url,
      };
      let section = sections.find((s) => {
        return s.title.id === item.section;
      });
      if (section) {
        section.links.push(link);
      }
    });

    sections.forEach((section) => {
      linkColumns[section.group].push({
        title: section.title,
        links: section.links,
      });
    });

    linkColumns = linkColumns;
  };

  let loadNetworkList = async () => {
    networkList = await post(
      `SELECT id, network, wifiKey
        FROM wifi`
    );
    // Data mapping.
    networkList = networkList.map((d) => {
      return {
        networkId: d.id,
        networkName: d.network,
        networkKey: d.wifiKey,
      };
    });
  };

  let sectionRight = async (columnIndex, sectionId) => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        let column = linkColumns[columnIndex];
        const pos = column.findIndex((c) => {
          return c.title.id === sectionId;
        });
        if (pos >= 0) {
          const section = column.splice(pos, 1);
          linkColumns[columnIndex + 1].push(section[0]);
          linkColumns = linkColumns;

          await post(
            `update linkSection
            set groupColumn=${columnIndex + 1}
            where id=${section[0].title.id}`
          );

          for (let i = 0; i < linkColumns[columnIndex].length; i++) {
            const s = linkColumns[columnIndex][i];
            await post(
              `update linkSection
            set pos=${i}
            where id=${s.title.id}`
            );
          }

          for (let i = 0; i < linkColumns[columnIndex + 1].length; i++) {
            const s = linkColumns[columnIndex + 1][i];
            await post(
              `update linkSection
            set pos=${i}
            where id=${s.title.id}`
            );
          }
        }
        savingLinks = false;
      }
    }
  };

  let sectionLeft = async (columnIndex, sectionId) => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        let column = linkColumns[columnIndex];
        const pos = column.findIndex((c) => {
          return c.title.id === sectionId;
        });
        if (pos >= 0) {
          const section = column.splice(pos, 1);
          linkColumns[columnIndex - 1].push(section[0]);
          linkColumns = linkColumns;

          await post(
            `update linkSection
            set groupColumn=${columnIndex - 1}
            where id=${section[0].title.id}`
          );

          for (let i = 0; i < linkColumns[columnIndex].length; i++) {
            const s = linkColumns[columnIndex][i];
            await post(
              `update linkSection
            set pos=${i}
            where id=${s.title.id}`
            );
          }

          for (let i = 0; i < linkColumns[columnIndex - 1].length; i++) {
            const s = linkColumns[columnIndex - 1][i];
            await post(
              `update linkSection
            set pos=${i}
            where id=${s.title.id}`
            );
          }
        }
        savingLinks = false;
      }
    }
  };

  let showEdit = (show) => {
    editLinks = show;
  };

  let changeFeetInches = () => {
    const F = feet ? feet : 0;
    const I = inches ? inches : 0;
    meters = (0.3048 * F + 0.0254 * I).toFixed(4);
  };

  let changeMeters = () => {
    const D = meters / 0.0254;
    feet = Math.floor(D / 12);
    inches = D - 12 * feet;
    feet = feet.toFixed(4);
    inches = inches.toFixed(4);
  };
</script>

<!-- Menu -->
<div class="content">
  <div class="boxContainer">
    <div class="dashboardItem">
      <Calculator />
    </div>
        <Conversor/>
    <!-- <div class="dashboardItem dashboardItemUp">
      <div class="converter">
        <div class="section">
          <label for="feet">feet</label>
          <input
            class="feet"
            type="number"
            bind:value={feet}
            min="0"
            on:keyup={() => changeFeetInches()}
            on:change={() => changeFeetInches()}
          />
        </div>
        <div class="section">
          <label for="inches">inches</label>
          <input
            class="inches"
            type="number"
            bind:value={inches}
            min="0"
            on:keyup={() => changeFeetInches()}
            on:change={() => changeFeetInches()}
          />
        </div>
        <div class="section">
          <label for="meters">meters</label>
          <input
            class="meters"
            type="number"
            min="0"
            bind:value={meters}
            on:keyup={() => changeMeters()}
            on:change={() => changeMeters()}
          />
        </div>
      </div>
    </div> -->
    <div class="dashboardItem">
      <!--<ColorPicker startColor={"#FBFBFB"} />-->
      <ColorPicker bind:currentColor defaultColors={colors} />
    </div>
    <div class="dashboardItem">
      <div class="containerImg">
        <img src="/images/tools2/screen01.JPG" alt="" />
      </div>
    </div>
    <div class="dashboardItem">
      <div class="dashboardContainer">
        <DashboardTitle title="Wifi Keys" />
        <div class="dashboardBody customScrollbars">
          {#each networkList as n}
            <div class="wifiData">
              <span class="title">WIFI</span>
              <span class="data">{n.networkName}</span>
              <span class="title">PASSWORD</span>
              <span class="data">{n.networkKey}</span>
            </div>
          {/each}
        </div>
      </div>
    </div>
    <div class="dashboardItem">
      <LicensesBox />
    </div>
    <!--<div class="dashboardItem">
      <Postit />
    </div>-->
  </div>
  <div class="links">
    <div class="button">
      {#if myRole === "Admin"}
        {#if editLinks}
          <ButtonIcon
            text="Close Editing"
            img="/images/x-white.svg"
            backgroundColor="var(--labit-black-button)"
            textColor="white"
            width="140px"
            height="30px"
            fontSize="14px"
            imgHeight="50%"
            onClick={() => showEdit(false)}
          />
        {:else}
          <ButtonIcon
            text="Edit Links"
            img="/images/edit-white.svg"
            backgroundColor="var(--labit-black-button)"
            textColor="white"
            width="140px"
            height="30px"
            fontSize="14px"
            imgHeight="60%"
            onClick={() => showEdit(true)}
          />
        {/if}
      {/if}
    </div>
    <div class="linkColumns">
      {#each linkColumns as linkColumn, index}
        <LinkColumn
          bind:column={linkColumn}
          columnIndex={index}
          firstColumn={index === 0}
          lastColumn={index === linkColumns.length - 1}
          {editLinks}
          onSectionRight={(columnIndex, sectionId) =>
            sectionRight(columnIndex, sectionId)}
          onSectionLeft={(columnIndex, sectionId) =>
            sectionLeft(columnIndex, sectionId)}
          bind:savingLinks
        />
      {/each}
    </div>
  </div>
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    margin: auto;
    min-height: 100%;
  }

  div.boxContainer {
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 20px;
    flex-wrap: wrap;
  }

  div.dashboardItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 460px;
    background-color: white;
    border-radius: 14px;
    margin-bottom: 20px;
    flex-direction: column;
  }

  div.dashboardItemUp {
    justify-content: flex-start !important;
  }

  div.converter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 39px;
  }

  div.converter div.section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px 0 40px;
    margin-bottom: 16px;
  }

  div.section input.feet,
  div.section input.inches,
  div.section input.meters {
    height: 31px;
    width: 115px;
    border: 0.5px solid var(--labit-card-lightgray);
    border-radius: 8px;
    text-align: right;
    color: #35425b;
  }

  div.section input.meters {
    background-color: #d7e1ea;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div.section div.meters span.result {
    color: #35425b;
    font-weight: bold;
    padding-right: 5px;
  }

  div.containerImg {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  div.containerImg img {
    width: 180px;
  }

  div.section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 30px;
    margin-top: 10px;
  }

  div.section span {
    color: #646464;
  }

  div.dashboardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  div.dashboardBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
    padding: 0 15px 0 15px;
    overflow: overlay;
  }

  span.title,
  span.data {
    font-size: 13px;
    font-weight: bold;
    color: var(--labit-card-gray);
    width: 100%;
    margin-bottom: 5px;
  }

  span.data {
    font-weight: normal !important;
  }

  div.wifiData {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0px 5px 0px;
    border-bottom: solid 1px #ebe7e7;
  }

  div.wifiData:last-child {
    border-bottom: none !important;
  }

  div.links {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 40px 0px 10px 0px;
  }

  div.linkColumns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
</style>
