<script>
  export let text;
  export let icon = null;
  export let onClickIcon = null;
  export let options;
  export let selected;
  export let width = "163px";

  import MultipleSelect from "./MultipleSelect/MultipleSelect.svelte";
</script>

<style>
  div.submenuMultipleSelectContainer {
    display: flex;
    color: var(--labit-card-gray);
    height: 21px;
    font-size: 13px;
    align-items: center;
  }

  div.submenuMultipleSelectContainer div {
    height: 100%;
  }

  span {
    margin-right: 13px;
  }

  img {
    height: 100%;
    margin-right: 7px;
  }

  img.clickable {
    cursor: pointer;
  }

  /* Animations */

  img.clickable {
    transition: opacity 0.5s linear 0s;
  }

  img.clickable:hover {
    opacity: 0.5;
  }
</style>

<div class="submenuMultipleSelectContainer">
  {#if icon}
    <img
      class={onClickIcon ? 'clickable' : ''}
      src={icon}
      alt="Combobox Icon"
      on:click={onClickIcon} />
  {/if}
  <span>{text}</span>
  <div style="width: {width}">
    <MultipleSelect {options} bind:selected />
  </div>
</div>
