<script>
  import { getOutlookToken } from "../../js/localInfo";
  import { Queue } from "../../js/dataStructures/queue";
  import Loading from "../../components/Loading-new.svelte";
  import Folder from "../me/sharepoint/Folder.svelte";

  let loading = true;
  let kill = false;
  const token = getOutlookToken();

  const callToEndpoint = async (url) => {
    let headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);

    const options = { method: "GET", headers: headers };

    try {
      const res = await fetch(url, options);
      return await res.json();
    } catch (err) {
      kill = true;
      return -1;
    }
  };

  const getData = async () => {
    const url = "https://graph.microsoft.com/v1.0/me/drive/sharedWithMe";
    const sharedFolders = await callToEndpoint(url);
    if (sharedFolders !== -1) {
      const hrFolderIndex = sharedFolders.value.findIndex((folder) => {
        if (folder.hasOwnProperty("remoteItem")) {
          if (folder.remoteItem.hasOwnProperty("webUrl")) {
            return folder.remoteItem.webUrl.startsWith(
              "https://labitgroup.sharepoint.com/sites/HR/Documentos%20compartidos/SKYLAB%20HR%20DOCUMENTS/EMPLEADOS/"
            );
          }
        }
        return false;
      });

      return hrFolderIndex === -1 ? -1 : sharedFolders.value[hrFolderIndex];
    }
    return -1;
  };

  let items = [];
  let resultFolders = [];
  (async () => {
    const folder = await getData();
    if (folder !== -1) {
      const driveId = folder.remoteItem.parentReference.driveId;
      const folderId = folder.remoteItem.id;
      const url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${folderId}/children`;
      const folderContent = await callToEndpoint(url); // Todas las carpetas dentro de la carpeta compartida

      console.log(folderContent);
      items = [];
      await iterate(folderContent.value);
      const files = items.filter((item) => item.type === "file");

      const queue = new Queue();
      items.forEach((item) => {
        if (item.type === "folder") queue.enqueue(item);
      });
      const rootFolders = items.filter(
        (file) => -1 === items.findIndex((file2) => file2.id === file.parentId)
      );
      const rootId = rootFolders[0].parentId;
      while (!queue.isEmpty) {
        const element = queue.dequeue();
        const indexesC = [];
        let indexP = -3;
        files.forEach((file, index) => {
          if (file.parentId === element.id) {
            element.content.push(file);
            indexesC.push(file.id);
          } else if (file.id === element.parentId) {
            indexP = element.parentId;
          }
        });
        if (indexesC.length === 0 && indexP === -3) {
          if (element.parentId == rootId) {
            files.push(element);
          } else {
            queue.enqueue(element);
          }
        } else {
          for (let i = 0; i < indexesC.length; i++) {
            let ind = files.findIndex((file) => {
              return file.id === indexesC[i];
            });
            files.splice(ind, 1);
          }
          let indP = files.findIndex((file) => {
            return file.id === indexP;
          });
          indexP === -3
            ? files.push(element)
            : files[indP].content.push(element);
        }
      }
      resultFolders = files;
      loading = false;
    }
  })();

  const iterate = async (item) => {
    for (let i = 0; i < item.length; i++) {
      const { id, name, parentReference, webUrl } = item[i];

      const type = item[i].hasOwnProperty("folder") ? "folder" : "file";
      items.push( { id, name, type, parentId: parentReference.id, content: [], webUrl } );

      if (item[i].hasOwnProperty("folder")) {
        const content = await call(item[i]);
        await iterate(content.value);
      }
    }
  };

  const call = async (item) => {
    const { id, parentReference } = item;
    let endpoint = `https://graph.microsoft.com/v1.0/drives/${parentReference.driveId}/items/${id}/children`;
    return await callToEndpoint(endpoint);
  };
</script>

<div>
  {#if loading === false && kill === false}
    {#if resultFolders.length === 0}
      <span>You have not a folder</span>
    {:else}
      {#each resultFolders as { name, content } (name)}
        <div class="folders-container">
          <Folder {name} files={content} type="folder" />
        </div>
      {/each}
    {/if}
  {:else if loading === true && kill === false}
    <div class="loading-state">
      <Loading />
    </div>
  {:else}
    <div>
      <h1>Error al cargar los datos</h1>
    </div>
  {/if}
</div>

<style>
  .loading-state {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  .folders-container {
    height: 80%;
  }
</style>
