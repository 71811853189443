export let filterViewables = (viewables) => {
  let viewableList = [];
  let toProcess = viewables;
  //console.log(viewables);
  while (toProcess.length > 0) {
    const viewable = toProcess[0];
    if (viewable) {
      if (viewable.children) {
        toProcess = toProcess.concat(viewable.children);
      }
      if (viewable.data && viewable.id) {
        //console.log(viewable);
        if (viewable.data.children) {
          toProcess = toProcess.concat(viewable.data.children);
        }
        if (viewable.data.role === "2d" || viewable.data.role === "3d") {
          const pos = viewableList.findIndex((item) => {
            return item.id === viewable.id;
          });
          if (pos === -1) {
            viewableList.push(viewable);
          }
        }
      }
    }
    toProcess.splice(0, 1);
  }
  return viewableList;
};

export let getInfoFromStandardName = (projectName) => {
  let result = [];
  let tokens = projectName.split("_");
  const location = tokens[tokens.length - 1];
  const version = tokens[tokens.length - 2];
  let product = "";
  for (let i = 1; i < tokens.length - 2; i++) {
    if (i > 1) {
      product += "_";
    }
    product += tokens[i];
  }
  result = [product, version, location];
  return result;
};

export let createStandardName = (company, product, version, location) => {
  return company + "_" + product + "_" + version + "_" + location;
};
