<script>
  export let text;
  export let colorText;
  export let colorBackground;
  export let colorBorder;
  export let onClick;
  export let icon;
</script>

<div
  class="loginButtonContainer"
  style="background-color:{colorBackground}; border-color:{colorBorder};"
  on:click={onClick}
>
  <img src={icon} alt="OS Icon" />
  <span style="color:{colorText}">{text}</span>
</div>

<style>
  div.loginButtonContainer {
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: var(--labit-darkgray);
    border-style: solid;
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
    cursor: pointer;
  }

  span {
    font-size: 12px;
  }

  img {
    height: 20px;
    margin-right: 10px;
  }

  /* Animations */

  div.loginButtonContainer {
    transition: opacity 0.5s linear 0s;
  }

  div.loginButtonContainer:hover {
    opacity: 0.5;
  }
</style>
