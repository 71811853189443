<script>
  export let width;
  export let height;
  export let text = "";
  export let textColor = "";
  export let selectedTextColor = "";
  export let img = null;
  export let selectedImg = null;
  export let backgroundColor = "white";
  export let selectedBackgroundColor = "black";
  export let borderColor = "";
  export let selected;
  export let onClick;
</script>

<style>
  div.selectableButton {
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    border-style: solid;
  }

  img {
    height: 59.6%;
    margin-left: 2px;
    margin-right: 2px;
  }

  span {
    margin-left: 2px;
    margin-right: 2px;
  }

  /* Animations */

  div.selectableButton {
    transition: opacity 0.5s linear 0s;
  }

  div.selectableButton:hover {
    opacity: 0.5;
  }
</style>

<div
  class="selectableButton {selected ? 'selected' : ''}"
  style="width: {width}; height: {height}; background-color: {selected ? selectedBackgroundColor : backgroundColor};
  border-color: {borderColor !== '' ? borderColor : 'transparent'};
  border-width: {borderColor !== '' ? '1px' : '0px'};"
  on:click={onClick}>
  {#if selected}
    {#if selectedImg}
      <img src={selectedImg} alt="Icon" />
    {/if}
    {#if text !== ''}
      <span style="color: {selectedTextColor}">{text}</span>
    {/if}
  {:else}
    {#if img}
      <img src={img} alt="Icon" />
    {/if}
    {#if text !== ''}
      <span style="color: {textColor}">{text}</span>
    {/if}
  {/if}
</div>
