<script>
  export let idCompany = null;
  export let idVersion = null;
  export let idProject = null;
  export let visible;

  //import Menu from "../components/Menu.svelte";
  import SubmenuCombobox from "../components/SubmenuCombobox.svelte";
  import SubmenuComboboxIcon from "../components/SubmenuComboboxIcon.svelte";
  //import SubmenuButton from "../components/SubmenuButton.svelte";
  import SubmenuIcon from "../components/SubmenuIcon.svelte";
  import SubmenuButtonIcon from "../components/SubmenuButtonIcon.svelte";
  import SubmenuTransformableButtonIcon from "../components/SubmenuTransformableButtonIcon.svelte";
  //import SubmenuText from "../components/SubmenuText.svelte";
  //import SubmenuTexts from "../components/SubmenuTexts.svelte";
  import SubmenuBlackButtonIcon from "../components/SubmenuBlackButtonIcon.svelte";
  import TextEdit from "../components/TextEdit.svelte";
  import RDSGKey from "../components/RDSGKey.svelte";
  import SelectableButton from "../components/SelectableButton.svelte";
  //import ImageButton from "../components/ImageButton.svelte";
  import RDSGTextEpigraph from "../components/RDSGTextEpigraph.svelte";
  import SubmenuTextEditIcon from "../components/SubmenuTextEditIcon.svelte";
  import RDSGLongDescription from "../components/RDSGLongDescription.svelte";
  import PrintOptions from "../components/PrintOptions.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  //import ContextMenu from "../components/ContextMenu.svelte";

  import {
    authUrl,
    authClientId,
    authClientSecret,
    versionsUrl,
    revitStandardsProjectId,
  } from "../js/revit-connection";
  import { post, DOMAIN } from "../js/labit-connection";
  import {
    RDSCreateConflictTable,
    RDSAddRoomLevel,
    RDSEmptyTable,
    RDSFillTable,
    RDSUpdateTable,
    RDSAddExceptions,
  } from "../js/rds-conflicts-exceptions";
  import { filterViewables } from "../js/utils";
  import { getRole, getPlatform } from "../js/localInfo";
  import { openNewWindow } from "../js/openNewWindow";
  import { openURL } from "../js/openURL";
  import getClients from "../js/clients";

  import { onMount } from "svelte";

  import jsPDF from "jspdf";

  const PAGE = "RESOURCES";

  const REVIT_CODE_AXO = {
    letter: "",
    sufix: "_AXO",
  };

  const REVIT_CODE_AXO_2D = {
    letter: "B",
    sufix: "_AXO",
  };

  const REVIT_CODE_DETAILS_1 = {
    letter: "J",
    sufix: "",
  };

  const REVIT_CODE_DETAILS_2 = {
    letter: "K",
    sufix: "",
  };

  const REVIT_CODE_DETAILS_3 = {
    letter: "L",
    sufix: "",
  };

  const REVIT_CODE_DETAILS_4 = {
    letter: "M",
    sufix: "",
  };

  const REVIT_CODE_FLOORPLAN = {
    letter: "C",
    sufix: "_FLOORPLAN",
  };
  const REVIT_CODE_DIM = {
    letter: "D",
    sufix: "_ELEVATION DIM",
  };
  const REVIT_CODE_RCP = {
    letter: "E",
    sufix: "_RCP",
  };
  const REVIT_CODE_LFX = {
    letter: "F",
    sufix: "_ELEVATION LFX",
  };
  const REVIT_CODE_AP = {
    letter: "G",
    sufix: "_ELEVATION AP",
  };
  const REVIT_CODE_ELEC = {
    letter: "H",
    sufix: "_ELEVATION ELEC",
  };
  const REVIT_CODE_IMG = {
    letter: "",
    sufix: "_IMG",
  };

  let myRole = getRole();
  const platform = getPlatform();

  //let rightView = "3D"; // 3D, Image, Text
  let view3D_1 = "AXO"; // AXO, ELEVATION, Image, Text
  let view3D_2 = "Dimensions"; // Dimensions, Appliances, PowerData, Lighting

  let search = "";
  let searchImage = "";
  let searchText = "";

  let images = [];
  let selectedImage = -1;

  let revitToken;
  let viewer = null;
  let viewer2;
  let viewer3;
  let currentVersion;
  let viewables;
  let viewerDocumentGlobal;
  let sheets = false;

  let companies = [];
  let selectedCompany = null;
  let versions = [];
  let selectedVersion = null;
  let versionsInfo = [];
  let versionName = [];
  let programs = [];
  let selectedProgram = null;
  let spaces = [];
  let selectedSpace = null;
  let rooms = [];
  let selectedRoom = null;
  let roomsInfo = [];
  let currentRoom = null;
  let revitStandardsItemId = "";
  let projects = [];
  let selectedProject = null;

  let viewAXO = null;
  let viewAXO2D = null;
  let viewDetails1 = null;
  let viewDetails2 = null;
  let viewDetails3 = null;
  let viewDetails4 = null;
  let viewDimensions = null;
  let viewDimensionsF = null;
  let viewAppliances = null;
  let viewPowerData = null;
  let viewLighting = null;
  let viewLightingLFX = null;
  let viewImage = null;

  let keys = [];
  let savedKeys = null;
  let removedCategories = [];
  let removedKeys = [];

  let editMode = false;

  let editDescription = "";
  let editLongDescription = "";
  let newRoomName = "";

  let exceptionsText = [];

  let printView = false;

  let currentPDF = "";

  let showNavMenu = null;

  let loadingRoom = false;

  let idCompany_ = null;
  let idVersion_ = null;
  let idProject_ = null;

  onMount(async () => {
    prepareRoomDataSheetG();
  });

  let prepareRoomDataSheetG = async () => {
    if (!viewer) {
      const response = await fetchAuth();
      revitToken = response.access_token;
      iniViewer();
    }

    if (
      idCompany_ ||
      idVersion_ ||
      idProject_ ||
      (!idCompany_ && !idVersion_ && !idProject_) ||
      (selectedCompany && selectedCompany.value !== idCompany_) ||
      (selectedVersion && selectedVersion.value !== idVersion_) ||
      (selectedProject && selectedProject.value !== idProject_)
    ) {
      selectedImage = 0;

      selectedVersion = null;
      versions = [];
      versionName = [];
      selectedProgram = null;
      programs = [];
      selectedSpace = null;
      spaces = [];
      selectedRoom = null;
      rooms = [];
      currentRoom = null;
      roomsInfo = [];
      revitStandardsItemId = "";
      selectedProject = null;
      projects = [];

      getCompanies();
    }
  };

  function iniViewer() {
    var options = {
      env: "AutodeskProduction",
      api: "derivativeV2", // for models uploaded to EMEA change this option to 'derivativeV2_EU'
      getAccessToken: function (onTokenReady) {
        var token = revitToken;
        var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
        onTokenReady(token, timeInSeconds);
      },
    };

    Autodesk.Viewing.Initializer(options, function () {
      var htmlDiv = document.getElementById("forgeViewer");
      viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
      var startedCode = viewer.start();
      if (startedCode > 0) {
        console.error("Failed to create a Viewer: WebGL not supported.");
        return;
      }
      console.log("Initialization completed.");
    });
  }

  let getCompanies = async () => {
    /*const response = await post(
      `select company_id, Name
        from companies
        where category_id=3`
    );*/
    const response = await getClients();
    companies = response.map((item) => {
      return {
        value: item.company_id,
        label: item.Name,
      };
    });
    if (idCompany_) {
      selectedCompany = companies.find((item) => {
        return item.value === idCompany_;
      });
    } else {
      selectedCompany = companies[0];
    }
    changeCompany();
  };

  let changeCompany = async () => {
    //console.log("CHANGECOMPANY");
    versions = [];
    selectedVersion = null;
    versionName = "";
    projects = [];
    selectedProject = null;
    programs = [];
    selectedProgram = null;
    spaces = [];
    selectedSpace = null;
    rooms = [];
    if (!loadingRoom) {
      selectedRoom = null;
      roomsInfo = [];
      currentRoom = null;
      exceptionsText = [];
    }
    getVersions();
  };

  let getVersions = async () => {
    if (selectedCompany) {
      const response = await post(
        `select standards_versions_id, standard_name, revit_link
        from standards_versions
        where company_id=${selectedCompany.value}`
      );
      versions = response.map((item) => {
        return {
          value: item.standards_versions_id,
          label: item.standard_name,
        };
      });
      versionsInfo = response.map((item) => {
        return {
          id: item.standards_versions_id,
          name: item.standard_name,
          revitLink: item.revit_link,
        };
      });
      if (versions.length > 0) {
        if (idVersion_) {
          const pos = versions.findIndex((item) => {
            return item.value === idVersion_;
          });
          selectedVersion = versions[pos];
          revitStandardsItemId = versionsInfo[pos].revitLink;
        } else {
          selectedVersion = versions[0];
          revitStandardsItemId = versionsInfo[0].revitLink;
        }
      } else {
        selectedVersion = null;
        revitStandardsItemId = "";
      }
      changeVersion();
    }
  };

  let changeVersion = async () => {
    //console.log("CHANGEVERSIONS");
    projects = [];
    selectedProject = null;
    programs = [];
    selectedProgram = null;
    spaces = [];
    selectedSpace = null;
    rooms = [];
    if (!loadingRoom) {
      selectedRoom = null;
      roomsInfo = [];
      currentRoom = null;
      exceptionsText = [];
    }

    const version = versionsInfo.find((item) => {
      return item.id === selectedVersion.value;
    });
    if (version) {
      revitStandardsItemId = version.revitLink;
    }

    getProjects();
  };

  let changeProject = () => {
    //console.log("CHANGEPROJECT");
    programs = [];
    selectedProgram = null;
    spaces = [];
    selectedSpace = null;
    rooms = [];
    if (!loadingRoom) {
      selectedRoom = null;
      roomsInfo = [];
      currentRoom = null;
      exceptionsText = [];
    }

    loadRevitProject();
  };

  let loadRevitProject = async () => {
    if (revitStandardsItemId !== "") {
      //console.log("LOADREVITPROJECT");
      const versionsPromise = fetchVersions(revitToken);
      versionsPromise.then(async (result) => {
        if (!result.data) {
          getPrograms();
          return;
        }
        currentVersion = result.data[0].relationships.derivatives.data.id;
        Autodesk.Viewing.Document.load(
          "urn:" + currentVersion,
          onDocumentLoadSuccess,
          onDocumentLoadFailure
        );
        async function onDocumentLoadSuccess(viewerDocument) {
          viewables = viewerDocument.getRoot().search({ type: "geometry" });
          viewerDocumentGlobal = viewerDocument;

          getPrograms();
        }
        async function onDocumentLoadFailure() {
          console.error("Failed fetching Forge manifest");

          getPrograms();
        }
      });
    }
  };

  let getVersionName = async () => {
    versionName = [];
    const response = await post(
      `select TimeLabitCode, FolderLabitCode, Name
          from projects
          where standards_versions_id=${selectedVersion.value}`
    );
    versionName.push(response[0].TimeLabitCode);
    versionName.push(response[0].FolderLabitCode);
    versionName.push(response[0].Name);
    versionName = versionName;
  };

  let getPrograms = async () => {
    if (selectedVersion) {
      //console.log("GETPROGRAMS");
      const response = await post(
        `select program_type_id, Name
        from program_types
        where standards_version_id=${selectedVersion.value}`
      );
      programs = response.map((item) => {
        return {
          value: item.program_type_id,
          label: item.Name,
        };
      });
      selectedProgram = programs.length > 0 ? programs[0] : null;
      changeProgram();
    }
  };

  let getProjects = async () => {
    if (selectedVersion) {
      const response = await post(
        `select project_id, Name
        from projects
        where standards_versions_id=${selectedVersion.value}
        order by Name asc`
      );
      projects = response.map((item) => {
        return {
          value: item.project_id,
          label: item.Name,
        };
      });
      if (projects.length > 0) {
        if (idProject_) {
          selectedProject = projects.find((item) => {
            return item.value === idProject_;
          });
        } else {
          selectedProject = projects[0];
        }
      } else {
        selectedProject = null;
      }
    }
    changeProject();
  };

  let changeProgram = async () => {
    //console.log("CHANGEPROGRAM");
    spaces = [];
    selectedSpace = null;
    rooms = [];
    if (!loadingRoom) {
      selectedRoom = null;
      roomsInfo = [];
      currentRoom = null;
      exceptionsText = [];
    }
    getSpaces();
  };

  let getSpaces = async () => {
    if (selectedProgram) {
      const response = await post(
        `select space_name_id, Name
        from space_names
        where program_type_id=${selectedProgram.value}`
      );
      spaces = response.map((item) => {
        return {
          value: item.space_name_id,
          label: item.Name,
        };
      });
      selectedSpace = spaces.length > 0 ? spaces[0] : null;
      changeSpace();
    }
  };

  let changeSpace = async () => {
    rooms = [];
    if (!loadingRoom) {
      selectedRoom = null;
      roomsInfo = [];
      currentRoom = null;
      exceptionsText = [];
    }
    getRooms();
  };

  let getRooms = async () => {
    if (selectedProject && selectedSpace) {
      const response = await post(
        `select space_type_id, st.Name as spaceTypeName, description, long_description, room_name, sn.Name spaceNameName
        from space_types as st
        left join space_names as sn on sn.space_name_id=st.space_name_id
        where st.space_name_id=${selectedSpace.value}`
      );

      const resp = await post(
        `select distinct room_name
        from dynamo_inputs
        where project_name='${selectedProject.label}'`
      );

      if (response.length > 0) {
        //console.log("GETROOMS");
        rooms = [];
        roomsInfo = [];
        response.forEach((item) => {
          const pos = resp.findIndex((it) => {
            return it.room_name === item.room_name;
          });
          if (pos >= 0) {
            rooms.push({
              value: item.space_type_id,
              label: item.spaceTypeName.replace(/</, "&lt;"),
            });
            roomsInfo.push({
              id: item.space_type_id,
              name: item.spaceTypeName,
              description: item.description,
              longDescription: item.long_description,
              id3d: item.room_name, //item.spaceNameName + " " + item.spaceTypeName,
              roomName: item.room_name,
            });
          }
        });
      }

      if (rooms.length > 0) {
        if (!loadingRoom) {
          selectedRoom = rooms[0];
          updateCurrentRoom();
        }
      } else {
        selectedRoom = null;
        roomsInfo = [];
        currentRoom = null;
        exceptionsText = [];
        viewAXO = null;
        viewAXO2D = null;
        viewDetails1 = null;
        viewDetails2 = null;
        viewDetails3 = null;
        viewDetails4 = null;
        viewDimensions = null;
        viewDimensionsF = null;
        viewAppliances = null;
        viewPowerData = null;
        viewLighting = null;
        viewLightingLFX = null;
        viewImage = null;
      }
    }
  };

  let updateCurrentRoom = async () => {
    //console.log("UPDATECURRENTROOM");
    if (!loadingRoom) {
      loadingRoom = true;
      if (selectedRoom) {
        currentRoom = roomsInfo.find((item) => {
          return item.id === selectedRoom.value;
        });
        if (currentRoom) {
          newRoomName = currentRoom.roomName;
          await getCurrentExceptions();
          await getItems();
          loadViews(currentRoom.id3d);
          loadAnyView();
        }
      }
      loadingRoom = false;
      idCompany_ = null;
      idVersion_ = null;
      idProject_ = null;
    }
  };

  let getItems = async () => {
    let response = await post(
      `select distinct a.attribute_id as categoryId, a.Name as categoryName, e.Name as epigraphName, a.sequence, a.url
      from attributes as a
      left join epigraphs as e on a.epigraph_id=e.epigraph_id
      where a.standards_versions_id=${selectedVersion.value}
      order by a.attribute_id asc`
    );

    keys = [];

    for (let i = 0; i < 10; i++) {
      let epigraphName = "";
      let epigraphIcon = "";
      let epigraphId = -1;
      switch (i) {
        case 0:
          epigraphName = "ROOM IDENTITY";
          epigraphIcon = "/images/rds-icons/room-identity.png";
          epigraphId = 1;
          break;
        case 1:
          epigraphName = "GENERAL REQUIREMENTS";
          epigraphIcon = "/images/rds-icons/general-requirements.png";
          epigraphId = 2;
          break;
        case 2:
          epigraphName = "ARCHITECTURE";
          epigraphIcon = "/images/rds-icons/architecture.png";
          epigraphId = 3;
          break;
        case 3:
          epigraphName = "ACCESS";
          epigraphIcon = "/images/rds-icons/access.png";
          epigraphId = 4;
          break;
        case 4:
          epigraphName = "AUDIOVISUAL";
          epigraphIcon = "/images/rds-icons/audiovisual.png";
          epigraphId = 5;
          break;
        case 5:
          epigraphName = "POWER & DATA";
          epigraphIcon = "/images/rds-icons/power-data.png";
          epigraphId = 6;
          break;
        case 6:
          epigraphName = "LIGHTING";
          epigraphIcon = "/images/rds-icons/lighting.png";
          epigraphId = 7;
          break;
        case 7:
          epigraphName = "APPLIANCES";
          epigraphIcon = "/images/rds-icons/appliances.png";
          epigraphId = 8;
          break;
        case 8:
          epigraphName = "FURNISHING";
          epigraphIcon = "/images/rds-icons/furnishing.png";
          epigraphId = 9;
          break;
        case 9:
          epigraphName = "HVAC";
          epigraphIcon = "/images/rds-icons/hvac.png";
          epigraphId = 10;
          break;
      }

      const categories = response.reduce((acc, curr) => {
        if (curr.epigraphName.toUpperCase() === epigraphName) {
          const category = {
            id: curr.categoryId,
            name: curr.categoryName,
            url: curr.url,
            keys: [],
            numDeleted: 0,
            allKeys: [],
          };
          acc.push(category);
        }
        return acc;
      }, []);

      const key = {
        id: epigraphName,
        icon: epigraphIcon,
        epId: epigraphId,
        categories,
      };

      keys.push(key);
    }

    response = await post(
      `select type_id, Name, search3dtext, attribute_id
          from types 
          where space_type_id=${selectedRoom.value}
           order by type_id asc`
    );

    response.forEach((type) => {
      let found = false;
      for (let i = 0; i < keys.length && !found; i++) {
        let key = keys[i];
        for (let j = 0; j < key.categories.length && !found; j++) {
          let category = key.categories[j];
          if (category.id === type.attribute_id) {
            found = true;
            const newType = {
              id: type.type_id,
              name: type.Name,
              search3D: type.search3dtext,
              numDeleted: 0,
            };
            category.keys.push(newType);
          }
        }
      }
    });

    response = await post(
      `select t.Name as typeName, a.Name as attributeName
        from types as t
        left join attributes as a on a.attribute_id=t.attribute_id`
    );

    response.forEach((item) => {
      let found = false;
      for (let i = 0; i < keys.length && !found; i++) {
        let key = keys[i];
        for (let j = 0; j < key.categories.length && !found; j++) {
          let category = key.categories[j];
          if (category.name === item.attributeName) {
            found = true;
            const pos = category.allKeys.findIndex((k) => {
              return k.trim() === item.typeName.trim();
            });
            if (pos === -1) {
              category.allKeys.push(item.typeName);
            }
          }
        }
      }
    });

    keys = keys;

    //console.log(keys);
  };

  const clientId = `${authClientId}`;
  const clientSecret = `${authClientSecret}`;
  const combined = `${clientId}:${clientSecret}`;
  const base64Encoded = btoa(combined);

  const fetchAuth = async () => {
    const response = await fetch(authUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Basic ${base64Encoded}`
      },
      body: `grant_type=client_credentials&scope=data:read`,
    });
    const myJson = await response.json();
    return myJson;
  };

  const fetchVersions = async (token) => {
    const url = versionsUrl
      .replace(":project_id", revitStandardsProjectId)
      .replace(":item_id", revitStandardsItemId);

    const response = await fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const myJson = await response.json();
    return myJson;
  };

  function loadViews(e) {
    const name = e.toUpperCase().trim();
    let thisViewable3d = undefined;
    let thisViewable2d = undefined;
    sheets = [];

    /*console.log(revitStandardsItemId);
    console.log(viewables);*/

    viewAXO = null;
    viewAXO2D = null;
    viewDetails1 = null;
    viewDetails2 = null;
    viewDetails3 = null;
    viewDetails4 = null;
    viewDimensions = null;
    viewDimensionsF = null;
    viewAppliances = null;
    viewPowerData = null;
    viewLighting = null;
    viewLightingLFX = null;
    viewImage = null;

    let viewableList = filterViewables(viewables);

    /*viewableList.map(item => {
      const upperName = item.data.name.toUpperCase();
      if (upperName.includes(name)) {
        console.log(item);
      }
    });*/

    viewableList.forEach((item, index) => {
      const upperName = item.data.name.toUpperCase();
      if (
        upperName.startsWith(name + REVIT_CODE_AXO.letter.toUpperCase()) &&
        item.data.role == "3d" &&
        upperName.endsWith(REVIT_CODE_AXO.sufix.toUpperCase())
      ) {
        viewAXO = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_AXO_2D.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_DIM.sufix.toUpperCase())*/
      ) {
        viewAXO2D = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_DETAILS_1.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_DIM.sufix.toUpperCase())*/
      ) {
        viewDetails1 = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_DETAILS_2.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_DIM.sufix.toUpperCase())*/
      ) {
        viewDetails2 = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_DETAILS_3.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_DIM.sufix.toUpperCase())*/
      ) {
        viewDetails3 = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_DETAILS_4.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_DIM.sufix.toUpperCase())*/
      ) {
        viewDetails4 = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_DIM.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_DIM.sufix.toUpperCase())*/
      ) {
        viewDimensions = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_FLOORPLAN.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_FLOORPLAN.sufix.toUpperCase())*/
      ) {
        viewDimensionsF = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_AP.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_AP.sufix.toUpperCase())*/
      ) {
        viewAppliances = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_ELEC.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_ELEC.sufix.toUpperCase())*/
      ) {
        viewPowerData = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_RCP.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_RCP.sufix.toUpperCase())*/
      ) {
        viewLighting = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "SHEET: " + name + REVIT_CODE_LFX.letter.toUpperCase()
        ) &&
        item.data.role == "2d" /*&&
        upperName.endsWith(REVIT_CODE_LFX.sufix.toUpperCase())*/
      ) {
        viewLightingLFX = item;
        thisViewable3d = index;
      }
      if (
        upperName.startsWith(
          "DRAFTING VIEW: " + name + REVIT_CODE_IMG.letter.toUpperCase()
        ) &&
        item.data.role == "2d" &&
        upperName.endsWith(REVIT_CODE_IMG.sufix.toUpperCase())
      ) {
        viewImage = item;
        thisViewable3d = index;
      }
    });

    /*console.log(viewAXO);
    console.log(viewDimensions);
    console.log(viewDimensionsF);
    console.log(viewAppliances);
    console.log(viewPowerData);
    console.log(viewLighting);
    console.log(viewLightingLFX);
    console.log(viewImage);*/
  }

  let loadAnyView = () => {
    if (viewAXO) {
      loadView("AXO");
    } else if (viewAXO2D) {
      loadView("AXO2D");
    } else if (viewDetails1) {
      loadView("Details1");
    } else if (viewDetails2) {
      loadView("Details2");
    } else if (viewDetails3) {
      loadView("Details3");
    } else if (viewDetails4) {
      loadView("Details4");
    } else if (viewDimensions) {
      loadView("Dimensions");
    } else if (viewDimensionsF) {
      loadView("DimensionsF");
    } else if (viewAppliances) {
      loadView("Appliances");
    } else if (viewPowerData) {
      loadView("PowerData");
    } else if (viewLighting) {
      loadView("Lighting");
    } else if (viewLightingLFX) {
      loadView("LightingLFX");
    } else if (viewImage) {
      loadView("Image");
    }
  };

  let loadAny2DView = () => {
    if (viewAXO2D) {
      loadView("AXO2D");
    } else if (viewDetails1) {
      loadView("Details1");
    } else if (viewDetails2) {
      loadView("Details2");
    } else if (viewDetails3) {
      loadView("Details3");
    } else if (viewDetails4) {
      loadView("Details4");
    } else if (viewDimensions) {
      loadView("Dimensions");
    } else if (viewDimensionsF) {
      loadView("DimensionsF");
    } else if (viewAppliances) {
      loadView("Appliances");
    } else if (viewPowerData) {
      loadView("PowerData");
    } else if (viewLighting) {
      loadView("Lighting");
    } else if (viewLightingLFX) {
      loadView("LightingLFX");
    } else if (viewImage) {
      loadView("Image");
    }
  };

  let loadView = (viewName) => {
    let viewInfo = null;
    switch (viewName) {
      case "AXO":
        viewInfo = viewAXO;
        view3D_1 = "AXO";
        view3D_2 = "";
        break;
      case "ELEVATION":
        loadAny2DView();
        break;
      case "AXO2D":
        viewInfo = viewAXO2D;
        view3D_1 = "ELEVATION";
        view3D_2 = "AXO2D";
        break;
      case "Details1":
        viewInfo = viewDetails1;
        view3D_1 = "ELEVATION";
        view3D_2 = "Details1";
        break;
      case "Details2":
        viewInfo = viewDetails2;
        view3D_1 = "ELEVATION";
        view3D_2 = "Details2";
        break;
      case "Details3":
        viewInfo = viewDetails3;
        view3D_1 = "ELEVATION";
        view3D_2 = "Details3";
        break;
      case "Details4":
        viewInfo = viewDetails4;
        view3D_1 = "ELEVATION";
        view3D_2 = "Details4";
        break;
      case "Dimensions":
        viewInfo = viewDimensions;
        view3D_1 = "ELEVATION";
        view3D_2 = "Dimensions";
        break;
      case "DimensionsF":
        viewInfo = viewDimensionsF;
        view3D_1 = "ELEVATION";
        view3D_2 = "DimensionsF";
        break;
      case "Appliances":
        viewInfo = viewAppliances;
        view3D_1 = "ELEVATION";
        view3D_2 = "Appliances";
        break;
      case "PowerData":
        viewInfo = viewPowerData;
        view3D_1 = "ELEVATION";
        view3D_2 = "PowerData";
        break;
      case "Lighting":
        viewInfo = viewLighting;
        view3D_1 = "ELEVATION";
        view3D_2 = "Lighting";
        break;
      case "LightingLFX":
        viewInfo = viewLightingLFX;
        view3D_1 = "ELEVATION";
        view3D_2 = "LightingLFX";
        break;
      case "Image":
        viewInfo = viewImage;
        view3D_1 = "Image";
        view3D_2 = "";
        break;
      case "Text":
        view3D_1 = "Text";
        view3D_2 = "";
        break;
    }
    if (viewInfo) {
      viewer.loadDocumentNode(viewerDocumentGlobal, viewInfo);
    }
  };

  let toggleEditMode = async () => {
    editMode = !editMode;
    if (currentRoom) {
      if (editMode) {
        savedKeys = JSON.parse(JSON.stringify(keys));
        editDescription = currentRoom.description;
        editLongDescription = currentRoom.longDescription;
      } else {
        // Room name

        if (currentRoom !== newRoomName) {
          await post(
            `update space_types
            set room_name='${newRoomName}'
            where space_type_id=${currentRoom.id}`
          );
          currentRoom.roomName = newRoomName;
        }

        // Description

        await post(
          `update space_types
            set description='${editDescription}',
            long_description='${editLongDescription}'
            where space_type_id=${currentRoom.id}`
        );
        currentRoom.description = editDescription;
        currentRoom.longDescription = editLongDescription;
        editDescription = "";
      }
    }
  };

  function search3D(text) {
    function onSuccessCallback(e) {
      const selectionType = Autodesk.Viewing.SelectionMode.OVERLAYED;
      viewer.select(e, selectionType);
    }
    function onErrorCallback(e) {
      console.log("error", e);
    }
    const attributeNames = [];
    viewer.search(text, onSuccessCallback, onErrorCallback, attributeNames);
  }

  let getCurrentExceptions = async () => {
    exceptionsText = [];

    let resp = await post(
      `select dynamoId
            from exceptions
            where spaceId=${selectedRoom.value}
            order by dynamoId desc`
    );

    if (resp.length > 0) {
      const dynamoId = resp[0].dynamoId;

      resp = await post(
        `select input
            from dynamo_inputs
            where inputs_id=${dynamoId}`
      );

      if (resp.length > 0) {
        let dynamo = JSON.parse(resp[0].input);

        //console.log(dynamo);

        let exceptions = await post(
          `select id, roomNumbering, posTableRow, posTableColumn, posTableConflict, type, description, replacingValue, typeId, attributeId, text
            from exceptions
            where spaceId=${selectedRoom.value}
            order by id desc`
        );
        //console.log(exceptions);

        let response = await post(
          `select distinct a.attribute_id as categoryId, a.Name as categoryName, e.Name as epigraphName, a.sequence
            from attributes as a
            left join epigraphs as e on a.epigraph_id=e.epigraph_id
            left join types as t on t.attribute_id=a.attribute_id
            where a.sequence!=0 and t.space_type_id=${selectedRoom.value}
            order by a.attribute_id asc`
        );

        let keys = [];

        for (let i = 0; i < 10; i++) {
          let epigraphName = "";
          let epigraphIcon = "";
          let epigraphId = -1;
          switch (i) {
            case 0:
              epigraphName = "ROOM IDENTITY";
              epigraphIcon = "/images/rds-icons/white/room-identity.png";
              epigraphId = 1;
              break;
            case 1:
              epigraphName = "GENERAL REQUIREMENTS";
              epigraphIcon = "/images/rds-icons/white/general-requirements.png";
              epigraphId = 2;
              break;
            case 2:
              epigraphName = "ARCHITECTURE";
              epigraphIcon = "/images/rds-icons/white/architecture.png";
              epigraphId = 3;
              break;
            case 3:
              epigraphName = "ACCESS";
              epigraphIcon = "/images/rds-icons/white/access.png";
              epigraphId = 4;
              break;
            case 4:
              epigraphName = "AUDIOVISUAL";
              epigraphIcon = "/images/rds-icons/white/audiovisual.png";
              epigraphId = 5;
              break;
            case 5:
              epigraphName = "POWER & DATA";
              epigraphIcon = "/images/rds-icons/white/power-data.png";
              epigraphId = 6;
              break;
            case 6:
              epigraphName = "LIGHTING";
              epigraphIcon = "/images/rds-icons/white/lighting.png";
              epigraphId = 7;
              break;
            case 7:
              epigraphName = "APPLIANCES";
              epigraphIcon = "/images/rds-icons/white/appliances.png";
              epigraphId = 8;
              break;
            case 8:
              epigraphName = "FURNISHING";
              epigraphIcon = "/images/rds-icons/white/furnishing.png";
              epigraphId = 9;
              break;
            case 9:
              epigraphName = "HVAC";
              epigraphIcon = "/images/rds-icons/white/hvac.png";
              epigraphId = 10;
              break;
          }

          const categories = response.reduce((acc, curr) => {
            if (curr.epigraphName.toUpperCase() === epigraphName) {
              const category = {
                id: curr.categoryId,
                name: curr.categoryName,
                sequence: curr.sequence,
                keys: [],
              };
              acc.push(category);
            }
            return acc;
          }, []);

          const key = {
            id: epigraphName,
            icon: epigraphIcon,
            epId: epigraphId,
            categories,
          };

          keys.push(key);
        }

        response = await post(
          `select type_id, Name, attribute_id
            from types 
            where space_type_id=${selectedRoom.value}
            order by type_id asc`
        );

        response.forEach((type) => {
          let found = false;
          for (let i = 0; i < keys.length && !found; i++) {
            let key = keys[i];
            for (let j = 0; j < key.categories.length && !found; j++) {
              let category = key.categories[j];
              if (category.id === type.attribute_id) {
                found = true;
                const newType = {
                  id: type.type_id,
                  name: type.Name,
                  status: "",
                };
                category.keys.push(newType);
              }
            }
          }
        });

        keys = keys;

        keys = RDSAddRoomLevel(keys);

        //console.log(keys);

        let conflictTable = RDSCreateConflictTable(keys, dynamo);
        conflictTable = RDSEmptyTable(dynamo, conflictTable);
        conflictTable = RDSFillTable(keys, dynamo, conflictTable);

        // Assigning saved exceptions
        conflictTable = RDSAddExceptions(conflictTable, exceptions);
        //console.log(conflictTable);

        conflictTable = RDSUpdateTable(keys, conflictTable);
        //console.log({ conflictTable });

        const exceptionsFull = await post(
          `select id, roomNumbering, posTableRow, posTableColumn, posTableConflict, type, description, replacingValue, typeId, attributeId, text, t.Name as typeName, a.Name as attributeName, ep.epigraph_id as epigraphId, ep.Name as epigraphName
            from exceptions as e
            left join types as t on t.type_id=e.typeId
            left join attributes as a on a.attribute_id=e.attributeId
            left join epigraphs as ep on ep.epigraph_id=a.epigraph_id
            where spaceId=${selectedRoom.value}
            order by id desc`
        );

        //console.log({ exceptionsFull });

        conflictTable.forEach((row, rowIndex) => {
          row.forEach((column) => {
            if (column.type === "conflict") {
              column.conflicts.forEach((conflict) => {
                if (conflict.exception) {
                  if (conflict.exception.type !== "hidden") {
                    const exceptionFull = exceptionsFull.find((item) => {
                      return item.id === conflict.exception.id;
                    });
                    //console.log({ exceptionFull });
                    let epigraph = exceptionsText.find((item) => {
                      return item.id === exceptionFull.epigraphId;
                    });
                    if (!epigraph) {
                      epigraph = {
                        id: exceptionFull.epigraphId,
                        name: exceptionFull.epigraphName,
                        icon: "",
                        attributes: [],
                      };
                      switch (epigraph.name.toUpperCase()) {
                        case "ROOM IDENTITY":
                          epigraph.icon = "/images/rds-icons/room-identity.svg";
                          break;
                        case "GENERAL REQUIREMENTS":
                          epigraph.icon =
                            "/images/rds-icons/general-requirements.svg";
                          break;
                        case "ARCHITECTURE":
                          epigraph.icon = "/images/rds-icons/architecture.svg";
                          break;
                        case "ACCESS":
                          epigraph.icon = "/images/rds-icons/access.svg";
                          break;
                        case "AUDIOVISUAL":
                          epigraph.icon = "/images/rds-icons/audiovisual.svg";
                          break;
                        case "POWER & DATA":
                          epigraph.icon = "/images/rds-icons/power-data.svg";
                          break;
                        case "LIGHTING":
                          epigraph.icon = "/images/rds-icons/lighting.svg";
                          break;
                        case "APPLIANCES":
                          epigraph.icon = "/images/rds-icons/appliances.svg";
                          break;
                        case "FURNISHING":
                          epigraph.icon = "/images/rds-icons/furnishing.svg";
                          break;
                        case "HVAC":
                          epigraph.icon = "/images/rds-icons/hvac.svg";
                          break;
                      }
                      exceptionsText.push(epigraph);
                    }
                    let attribute = epigraph.attributes.find((item) => {
                      return item.id === exceptionFull.attributeId;
                    });
                    if (!attribute) {
                      attribute = {
                        id: exceptionFull.attributeId,
                        name: exceptionFull.attributeName,
                        conflicts: [],
                      };
                      epigraph.attributes.push(attribute);
                    }
                    attribute.conflicts.push({
                      type: exceptionFull.type,
                      text: exceptionFull.text,
                      replacingValue: exceptionFull.replacingValue,
                      description: exceptionFull.description,
                      level: dynamo[rowIndex][0][0],
                      roomNumbering: dynamo[rowIndex][1][0],
                    });
                  }
                }
              });
            }
          });
        });

        //console.log({ exceptionsText });
      }
    }
  };

  let printMode = (active) => {
    printView = active;
  };

  let printPDF = () => {
    let options = {};
    let content = window.$("#RDSBody");
    let pdf = new jsPDF("p", "px", [content.width(), content.height()]);
    pdf.internal.scaleFactor = 1;
    pdf.addHTML(content, 15, 15, options, function () {
      pdf.save("RoomDataSheetG.pdf");
    });
  };

  let clickPDF = (url, forceNewWindow) => {
    if (platform === "Web" || forceNewWindow) {
      openNewWindow(url);
    } else {
      currentPDF = url;
    }
  };

  let closePDF = () => {
    currentPDF = "";
  };

  $: {
    window.$("div.bodyLeft mark").contents().unwrap();
    if (currentRoom) {
      if (search.length >= 3) {
        // Description

        let src_str = window.$("#roomDescription").html();
        let term = search;
        term = term.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
        let pattern = new RegExp("(" + term + ")", "gi");

        src_str = src_str.replace(pattern, "<mark>$1</mark>");
        src_str = src_str.replace(
          /(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/,
          "$1</mark>$2<mark>$4"
        );

        window.$("#roomDescription").html(src_str);

        // Categories
        window.$("div.categoryName span").each(function () {
          let src_str = window.$(this).html();
          let term = search;
          term = term.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
          let pattern = new RegExp("(" + term + ")", "gi");

          src_str = src_str.replace(pattern, "<mark>$1</mark>");
          src_str = src_str.replace(
            /(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/,
            "$1</mark>$2<mark>$4"
          );

          window.$(this).html(src_str);
        });

        // Keys
        window.$("div.RDSGKeyCategory span").each(function () {
          let src_str = window.$(this).html();
          let term = search;
          term = term.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
          let pattern = new RegExp("(" + term + ")", "gi");

          src_str = src_str.replace(pattern, "<mark>$1</mark>");
          src_str = src_str.replace(
            /(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/,
            "$1</mark>$2<mark>$4"
          );

          window.$(this).html(src_str);
        });
      }
    }
  }

  $: {
    window.$("span#longDescription mark").contents().unwrap();
    if (currentRoom) {
      if (searchText.length >= 3) {
        // Long Description

        let src_str = window.$("span#longDescription").html();
        let term = searchText;
        term = term.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
        let pattern = new RegExp("(" + term + ")", "gi");

        src_str = src_str.replace(pattern, "<mark>$1</mark>");
        src_str = src_str.replace(
          /(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/,
          "$1</mark>$2<mark>$4"
        );

        window.$("span#longDescription").html(src_str);
      }
    }
  }

  $: if (idCompany || idVersion || idProject) {
    idCompany_ = idCompany;
    idVersion_ = idVersion;
    idProject_ = idProject;
    prepareRoomDataSheetG();
  }
</script>

<div class="content">
  <!-- Menu Options -->

  <div class="optionsMenu" style={printView ? "display: none" : ""}>
    <div class="optionsRow firstRow">
      <div class="optionsColumn1">
        <SubmenuIcon
          img="/images/room.svg"
          width="61px"
          onClick={() => {
            if (selectedCompany && selectedVersion) {
              openURL(
                "/roomdatasheet/" +
                  selectedCompany.value +
                  "/" +
                  selectedVersion.value,
                true
              );
            }
          }}
          onRightClick={() => {
            if (selectedCompany && selectedVersion) {
              openNewWindow(
                "/skylab-main/roomdatasheet/" +
                  selectedCompany.value +
                  "/" +
                  selectedVersion.value
              );
            }
          }}
        />
        <div class="submenuBox">
          <div>
            <SubmenuCombobox
              text="Company"
              options={companies}
              bind:selectedValue={selectedCompany}
              placeholder=""
              onChange={() => changeCompany()}
              backgroundColor="var(--labit-dialog-background)"
            />
          </div>
          <div>
            <SubmenuCombobox
              text="Version"
              options={versions}
              bind:selectedValue={selectedVersion}
              placeholder=""
              onChange={() => changeVersion()}
              width="384px"
              backgroundColor="var(--labit-dialog-background)"
            />
          </div>
        </div>
        <SubmenuIcon
          img="/images/factory.svg"
          onClick={() => {
            if (selectedProject) {
              openURL("/projects/" + selectedProject.value, true);
            }
          }}
          onRightClick={() => {
            if (selectedProject) {
              openNewWindow("/skylab-main/projects/" + selectedProject.value);
            }
          }}
        />
        <div class="submenuBox">
          <div>
            <SubmenuCombobox
              text="Project"
              options={projects}
              bind:selectedValue={selectedProject}
              placeholder=""
              width={myRole === "Admin" ? "307px" : "442px"}
              onChange={() => changeProject()}
              backgroundColor="var(--labit-dialog-background)"
            />
          </div>
        </div>
        <SubmenuIcon
          img="/images/controller.svg"
          onClick={() => {
            if (selectedCompany && selectedVersion) {
              openURL(
                "/roomdatasheetl/" +
                  selectedCompany.value +
                  "/" +
                  selectedVersion.value,
                true
              );
            }
          }}
          onRightClick={() => {
            if (selectedCompany && selectedVersion) {
              openNewWindow(
                "/skylab-main/roomdatasheetl/" +
                  selectedCompany.value +
                  "/" +
                  selectedVersion.value
              );
            }
          }}
        />
        {#if myRole === "Admin"}
          {#if editMode}
            <SubmenuTransformableButtonIcon
              img="/images/save-white.svg"
              imgHover="/images/tick-white.svg"
              backgroundColor="var(--labit-light-red)"
              backgroundColorHover="var(--labit-button-icon)"
              onClick={() => toggleEditMode()}
            />
          {:else}
            <SubmenuButtonIcon
              img="/images/edit.svg"
              backgroundColor="var(--labit-dialog-background)"
              onClick={() => toggleEditMode()}
            />
          {/if}
        {/if}
      </div>
    </div>
    <div class="optionsRow lastRow">
      <div class="optionsColumn1">
        <SubmenuComboboxIcon
          text="Programme"
          icon="/images/program.svg"
          options={programs}
          bind:selectedValue={selectedProgram}
          placeholder=""
          onChange={() => changeProgram()}
          onClickIcon={null}
          width="288px"
        />
        <SubmenuComboboxIcon
          text="Space"
          icon="/images/space.svg"
          options={spaces}
          bind:selectedValue={selectedSpace}
          placeholder=""
          onChange={() => changeSpace()}
          onClickIcon={null}
          width="288px"
        />
        <SubmenuComboboxIcon
          text="Type"
          icon="/images/room.svg"
          options={rooms}
          bind:selectedValue={selectedRoom}
          placeholder=""
          onChange={() => updateCurrentRoom()}
          onClickIcon={null}
          width="288px"
        />
        <SubmenuTextEditIcon
          title="Room Code"
          bind:text={newRoomName}
          mode={editMode ? "edit" : "show"}
          icon="/images/room-id.svg"
        />
        <SubmenuBlackButtonIcon
          text="Print View"
          icon="/images/print-white.svg"
          onClick={() => printMode(true)}
        />
      </div>
    </div>
    {#if selectedProject}
      <div class="optionsRow lastRow reference">
        <b>Reference:</b>
        <span>
          {DOMAIN}skylab-main/roomdatasheetg/{selectedCompany.value}/{selectedVersion.value}/{selectedProject.value}
        </span>
      </div>
    {/if}
  </div>

  <!-- Body -->

  <div
    class="RDSBody"
    id="RDSBody"
    style={printView ? "background-color: white" : ""}
  >
    <div class="bodyLeft">
      <div class="title">Guideline - RDS</div>
      <div class="optionsSearch">
        <span>Search</span>
        <TextEdit
          placeholder="type here..."
          maxlength="150"
          bind:value={search}
          img="/images/search.svg"
        />
      </div>
      {#if currentRoom}
        <div
          class="keys customScrollbars"
          style={printView ? "height: auto" : ""}
        >
          <div class="descriptionKey">
            <div class="descriptionKeyHeader">
              <img src="/images/edit.png" alt="Description Icon" />
              <span>DESCRIPTION</span>
            </div>
            <div class="description">
              <div class="descriptionContainer">
                {#if editMode}
                  <textarea
                    class="customScrollbars"
                    bind:value={editDescription}
                  />
                {:else}
                  <span id="roomDescription">{currentRoom.description}</span>
                {/if}
              </div>
            </div>
          </div>
          {#each keys as key}
            <RDSGKey
              id={key.id}
              epId={key.epId}
              icon={key.icon}
              bind:categories={key.categories}
              {editMode}
              onSelect={(text3D) => search3D(text3D)}
              {currentRoom}
              standardId={selectedVersion.value}
              onClickPDF={(url) => clickPDF(url)}
            />
          {/each}
        </div>
      {/if}
    </div>
    <div class="bodyRight {currentPDF !== '' ? 'hiddenElement' : ''}">
      {#if currentRoom}
        <!--{#if editMode}
          <div class="editBox">
            <span>Content not available.</span>
            <span>To enable the content, exit edit mode.</span>
          </div>
        {:else}-->
        <div class="mainButtonRow">
          {#if viewAXO}
            <div>
              <SelectableButton
                width="122px"
                height="52px"
                img="/images/3d.png"
                selectedImg="/images/3d-white.png"
                selected={view3D_1 === "AXO"}
                backgroundColor="white"
                selectedBackgroundColor="var(--labit-black-button)"
                onClick={() => loadView("AXO")}
              />
            </div>
          {/if}
          {#if viewAXO2D || viewDetails1 || viewDetails2 || viewDetails3 || viewDetails4 || viewDimensions || viewDimensionsF || viewAppliances || viewPowerData || viewLighting || viewLightingLFX}
            <div>
              <SelectableButton
                width="122px"
                height="52px"
                img="/images/document.png"
                selectedImg="/images/document-white.png"
                selected={view3D_1 === "ELEVATION"}
                backgroundColor="white"
                selectedBackgroundColor="var(--labit-black-button)"
                onClick={() => loadAny2DView()}
              />
            </div>
          {/if}
          {#if viewImage}
            <div>
              <SelectableButton
                width="122px"
                height="52px"
                img="/images/picture.png"
                selectedImg="/images/picture-white.png"
                selected={view3D_1 === "Image"}
                backgroundColor="white"
                selectedBackgroundColor="var(--labit-black-button)"
                onClick={() => loadView("Image")}
              />
            </div>
          {/if}
          {#if currentRoom}
            <div>
              <SelectableButton
                width="122px"
                height="52px"
                img="/images/text.png"
                selectedImg="/images/text-white.png"
                selected={view3D_1 === "Text"}
                backgroundColor="white"
                selectedBackgroundColor="var(--labit-black-button)"
                onClick={() => loadView("Text")}
              />
            </div>
          {/if}
        </div>
        {#if view3D_1 === "AXO"}
          <div class="menu3D">
            <div>
              <SelectableButton
                width="131px"
                height="22px"
                text="AXO"
                textColor="var(--labit-black-button)"
                selectedTextColor="white"
                selected={view3D_1 === "AXO"}
                backgroundColor="transparent"
                selectedBackgroundColor="var(--labit-black-button)"
                borderColor="var(--labit-black-button)"
                onClick={() => loadView("AXO")}
              />
            </div>
          </div>
        {/if}
        {#if view3D_1 === "ELEVATION"}
          <div class="menu3D">
            {#if view3D_1 === "ELEVATION"}
              {#if viewAXO2D}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="AXO"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/3d.png"
                    selectedImg="/images/3d-white.png"
                    selected={view3D_2 === "AXO2D"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("AXO2D")}
                  />
                </div>
              {/if}
              {#if viewDimensions}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Dimensions"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/dimension.png"
                    selectedImg="/images/dimension-white.png"
                    selected={view3D_2 === "Dimensions"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("Dimensions")}
                  />
                </div>
              {/if}
              {#if viewDimensionsF}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Dimensions"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/dimension.png"
                    selectedImg="/images/dimension-white.png"
                    selected={view3D_2 === "DimensionsF"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("DimensionsF")}
                  />
                </div>
              {/if}
              {#if viewAppliances}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Appliances"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/rds-icons/appliances.png"
                    selectedImg="/images/rds-icons/appliances-white.png"
                    selected={view3D_2 === "Appliances"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("Appliances")}
                  />
                </div>
              {/if}
              {#if viewPowerData}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Power & Data"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/rds-icons/power-data.png"
                    selectedImg="/images/rds-icons/power-data-white.png"
                    selected={view3D_2 === "PowerData"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("PowerData")}
                  />
                </div>
              {/if}
              {#if viewLighting}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Lighting"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/rds-icons/lighting.png"
                    selectedImg="/images/rds-icons/lighting-white.png"
                    selected={view3D_2 === "Lighting"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("Lighting")}
                  />
                </div>
              {/if}
              {#if viewLightingLFX}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Lighting"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/rds-icons/lighting.png"
                    selectedImg="/images/rds-icons/lighting-white.png"
                    selected={view3D_2 === "LightingLFX"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("LightingLFX")}
                  />
                </div>
              {/if}
              {#if viewDetails1}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Details"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/lens.png"
                    selectedImg="/images/lens-white.png"
                    selected={view3D_2 === "Details1"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("Details1")}
                  />
                </div>
              {/if}
              {#if viewDetails2}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Details"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/lens.png"
                    selectedImg="/images/lens-white.png"
                    selected={view3D_2 === "Details2"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("Details2")}
                  />
                </div>
              {/if}
              {#if viewDetails3}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Details"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/lens.png"
                    selectedImg="/images/lens-white.png"
                    selected={view3D_2 === "Details3"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("Details3")}
                  />
                </div>
              {/if}
              {#if viewDetails4}
                <div>
                  <SelectableButton
                    width="131px"
                    height="22px"
                    text="Details"
                    textColor="var(--labit-black-button)"
                    selectedTextColor="white"
                    img="/images/lens.png"
                    selectedImg="/images/lens-white.png"
                    selected={view3D_2 === "Details4"}
                    backgroundColor="transparent"
                    selectedBackgroundColor="var(--labit-black-button)"
                    borderColor="var(--labit-black-button)"
                    onClick={() => loadView("Details4")}
                  />
                </div>
              {/if}
            {/if}
          </div>
        {/if}
        {#if view3D_1 === "Text"}
          <div class="menuText">
            <div>Search</div>
            <TextEdit
              placeholder="type here..."
              maxlength="150"
              img="/images/search.svg"
              bind:value={searchText}
            />
          </div>
          <div class="descriptionBox customScrollbars">
            {#if editMode}
              <textarea
                class="customScrollbars"
                bind:value={editLongDescription}
              />
            {:else}
              <RDSGLongDescription text={currentRoom.longDescription} />
            {/if}
            {#if exceptionsText.length > 0}
              <div>
                <div class="exceptionsTitle">
                  <span>STANDARDS EXCEPTIONS REPORT:</span>
                </div>
                {#each exceptionsText as epigraphEx}
                  <div class="exceptionsText">
                    <RDSGTextEpigraph epigraph={epigraphEx} />
                  </div>
                {/each}
              </div>
            {/if}
          </div>
        {/if}
      {/if}
      <!--{/if}-->
      <div>
        <div
          style={view3D_1 === "Text" ? "display:none" : ""}
          class="vis3D {view3D_1 === 'Image' ? 'forgeNoToolbar' : ''}"
          id="forgeViewer"
        />
      </div>
    </div>
    <div
      class="bodyRight iframe {currentPDF !== '' && platform === 'APP'
        ? ''
        : 'hiddenElement'}"
    >
      <div class="x">
        <img
          class="fadedButton"
          src="/images/x.svg"
          alt="Close PDF"
          on:click={() => closePDF()}
        />
      </div>
      <WebBrowser
        type="webview"
        iframeTitle="PDF"
        useragent="Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"
        id="wv_pdf"
        src={currentPDF}
      />
    </div>
  </div>
</div>

<!--
{#if visible}
  <ContextMenu currentURL={currentPDF} bind:showMenu={showNavMenu} />
{/if}
-->

{#if printView}
  <PrintOptions
    onClose={() => printMode(false)}
    onPrintPDF={() => printPDF()}
  />
{/if}

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
    min-height: 100%;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin-top: 20px;
    color: var(--labit-card-gray);
    flex-direction: column;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  div.lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  div.RDSBody {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: space-between;
  }

  div.optionsSearch {
    display: flex;
    flex-grow: 1;
    height: 21px;
    align-items: center;
    font-size: 13px;
    width: 669px;
    margin-top: 20px;
  }

  div.optionsSearch span {
    margin-right: 13px;
  }

  div.bodyLeft {
    width: 674px;
  }

  div.bodyRight {
    width: 950px;
  }

  div.description {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: white;
  }

  div.descriptionContainer {
    width: 586px;
    border-radius: 14px;
    color: var(--labit-card-gray);
    font-size: 12px;
    padding-top: 9px;
    padding-bottom: 9px;
    white-space: pre-wrap;
    font-weight: bold;
  }

  div.description textarea {
    width: 100%;
    min-height: 100px;
    border-radius: 14px;
    color: var(--labit-card-gray);
    padding: 10px;
    font-weight: bold;
  }

  div.title {
    width: 100%;
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    color: var(--labit-card-gray);
    font-size: 11px;
    padding-left: 35px;
    height: 52px;
    font-weight: bold;
    font-size: 25px;
    display: flex;
    align-items: center;
  }

  div.keys {
    width: 100%;
    background-color: white;
    border-radius: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--labit-card-gray);
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    height: 689px;
    overflow-y: scroll;
  }

  div.mainButtonRow {
    display: flex;
  }

  div.mainButtonRow div {
    margin-right: 13px;
  }

  div.menu3D {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--labit-black-button);
    flex-wrap: wrap;
  }

  div.menu3D div {
    margin-right: 13px;
    margin-top: 20px;
  }

  .vis3D {
    width: 950px;
    height: 689px;
    border: none;
    border-radius: 19px;
    margin-top: 20px;
  }

  div.menuText {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--labit-black-button);
    margin-top: 20px;
  }

  div.menuText div {
    margin-right: 13px;
  }

  div.descriptionBox {
    width: 100%;
    background-color: white;
    border-radius: 14px;
    color: var(--labit-card-gray);
    font-size: 11px;
    padding: 25px;
    white-space: pre-wrap;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    height: 689px;
    overflow-y: scroll;
  }

  div.descriptionBox textarea {
    width: 100%;
    min-height: 100px;
    border: none;
  }

  div.submenuBox {
    height: 52px;
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 13px;
    padding-right: 13px;
  }

  div.submenuBox div:not(:last-child) {
    margin-right: 13px;
  }

  div.exceptionsText {
    display: flex;
  }

  div.exceptionsText:first-child {
    margin-top: 19px;
  }

  div.descriptionKey {
    width: 100%;
    margin-top: 19px;
  }

  div.descriptionKeyHeader {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
  }

  div.descriptionKeyHeader img {
    width: 30px;
    margin-right: 13px;
  }

  div.reference {
    display: flex;
    justify-content: flex-start;
    font-size: 13px;
  }

  div.reference span {
    margin-left: 10px;
  }

  div.exceptionsTitle {
    font-size: 15px;
    font-weight: bold;
    margin-top: 23px;
    margin-bottom: 10px;
  }

  div.iframe {
    height: 800px;
  }

  div.x {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -19px;
  }

  div.x img {
    width: 9px;
    margin-bottom: 10px;
  }
</style>
