<script>
  import Menu from "../components/Menu.svelte";
  import TextEdit from "../components/TextEdit.svelte";
  import SubmenuCombobox from "../components/SubmenuCombobox.svelte";
  import ResourceCard from "../components/ResourceCard.svelte";

  import { LABIT_PUBLIC_FOLDER, post } from "../js/labit-connection";
  import { getAllProjects } from "../js/projects";
  import getClients from "../js/clients";

  import { onMount } from "svelte";
  import MenuItem from "../components/MenuItem.svelte";

  const PAGE = "CLIENTS";

  let remainingCards = 0;
  let invisibleCards = 0;

  let clients = [];
  let clientsCopy = [];

  const MAX_CARDS = 6;

  const statusOptions = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Inactive",
      label: "Inactive",
    },
  ];
  let statusOptionsSel = statusOptions[1];
  let clientsWithRoomDataSheet = [];
  
  onMount( async () => {
    getProjectClients();
    clientsWithRoomDataSheet = await post("select company_id from standards_versions");
  });

  let getProjectClients = async () => {
    /*let response = await post(
      `select company_id, co.Name, picPath
        from companies as co
        left join categories as ca on ca.category_id=co.category_id
        where ca.Name='Clients'`
    );*/
    const response = await getClients();
    clients = response.map((item) => {
      return {
        id: item.company_id,
        name: item.Name,
        img: item.picPath !== "" ? LABIT_PUBLIC_FOLDER + item.picPath : "",
        active: false,
        show: false,
      };
    });

    clientsCopy = clients

    const projects = await getAllProjects(false);
    projects.forEach((project) => {
      if (project.active) {
        let client = clients.find((item) => {
          return item.name === project.lastName;
        });
        if (client) {
          client.active = true;
          client.show = true;
        }
      }
    });

    clients = clients;

    remainingCards = clients.length % MAX_CARDS;
    invisibleCards = remainingCards > 0 ? MAX_CARDS - remainingCards : 0;
  };

  let changeStatus = () => {
    console.log(statusOptionsSel);
    clients.forEach((client) => {
      client.show =
        statusOptionsSel.value === "ALL" ||
        (statusOptionsSel.value === "Active" && client.active) ||
        (statusOptionsSel.value === "Inactive" && !client.active);
    });

    clients = clients;

    remainingCards = clients.length % MAX_CARDS;
    invisibleCards = remainingCards > 0 ? MAX_CARDS - remainingCards : 0;
  };

  let filter = "";
  $: clients = clientsCopy.filter(client => client.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));

</script>

<div class="content">
  <!-- Menu -->

  <!-- Menu Options -->

  <div class="optionsMenu">
    <div class="optionsRow">
      <div class="optionsColumn1">
        <SubmenuCombobox
          text="Category"
          options={[{ value: "ALL", label: "ALL" }]}
          selectedValue={{ value: "ALL", label: "ALL" }}
          placeholder="( ALL )"
          onChange={null}
        />
        <div class="optionsSearch">
          <span>Search</span>
          <input id="filterSearch" bind:value={filter} placeholder="Type here...">
        </div>
        <SubmenuCombobox
          text="Status"
          options={statusOptions}
          bind:selectedValue={statusOptionsSel}
          onChange={() => changeStatus()}
        />
      </div>
    </div>
  </div>

  <!-- Body -->

  <div class="resourceSectionCards">
    {#each clients as client}
      {#if client.show}
        <div class="resourceSectionCard">
          <ResourceCard 
            idCompany={client.id} 
            imgSrc={client.img}
            showRDSButton={clientsWithRoomDataSheet.some(e => e.company_id.toString() === client.id.toString())}
            showGuidelineButton={clientsWithRoomDataSheet.some(e => e.company_id.toString() === client.id.toString())}
          />
        </div>
      {/if}
    {/each}
    {#each Array(invisibleCards) as _, i}
      <div class="resourceSectionCard" />
    {/each}
  </div>
</div>

<style>
  #filterSearch {
    width: 100%;
    border-radius: 40px;
    height: 22px;
    border: 1px solid #686868;
    background-color: #f0f4f8;
  }
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin-top: 20px;
    height: 21px;
    color: var(--labit-card-gray);
    flex-direction: column;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    padding-right: 13px;
    justify-content: space-between;
  }

  div.optionsSearch {
    display: flex;
    flex-grow: 1;
    height: 21px;
    align-items: center;
    padding-right: 13px;
    padding-left: 13px;
  }

  div.optionsSearch span {
    margin-right: 13px;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.resourceSectionCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: space-between;
  }

  div.resourceSectionCard {
    height: 259px;
    width: 259px;
    margin-bottom: 19px;
  }
</style>
