<script>
  export let file;
  export let expandedList;
  export let drop;
  export let dragOverHandler;
  export let draggableParent;
  export let removeItem;
  export let onClickRemove;
  export let onPreviewFile;
  export let readOnly = false;
  export let mode = "normal";
  export let compressedMode = false;
  export let onClickSelectButton = null;
  export let onClickSelectUrlButton = null;

  import FileBoobleFile from "./FileBoobleFile.svelte";
  import FileBoobleFolderUploadButton from "./FileBoobleFolderUploadButton.svelte";
  import FileBoobleFolderUploadLinkButton from "./FileBoobleFolderUploadLinkButton.svelte";

  import { rpost, LABIT_REST_SERVER } from "../js/labit-connection";
  import { getToken } from "../js/localInfo";

  const WORD_TEMPLATE = "/templates/empty-templates/Word Templates";
  const EXCEL_TEMPLATE = "/templates/empty-templates/Excel Templates";
  const PDF_TEMPLATE = "/templates/empty-templates/PDF Templates";
  const POWERPOINT_TEMPLATE = "/templates/empty-templates/PowerPoint Templates";

  let draggable = true;
  let dragging = false;

  let icon = "";

  let downloadingFolder = false;

  let currentName = "";
  let currentUrl = "";

  let loadingFiles = false;

  let toggleExpandBooble = () => {
    file.expanded = !file.expanded;
    if (file.expanded) {
      expandedList.push(file.id);
    } else {
      expandedList = expandedList.filter((item) => {
        return item !== file.id;
      });
    }
  };

  let downloadFolder = async () => {
    downloadingFolder = true;
    let data = await rpost("DownloadFolder", { folderPath: file.id });
    const path = data[0];
    downloadingFolder = false;
    location.href =
      LABIT_REST_SERVER +
      "DownloadTempFile.php?url=" +
      btoa(unescape(encodeURIComponent(path))) +
      "&token=" +
      getToken();
  };

  $: if (file.id === WORD_TEMPLATE) {
    icon = "doc.svg";
  } else if (file.id === EXCEL_TEMPLATE) {
    icon = "xls.svg";
  } else if (file.id === POWERPOINT_TEMPLATE) {
    icon = "ppt.svg";
  } else if (file.id === PDF_TEMPLATE) {
    icon = "pdf.svg";
  }
</script>

<div
  class="fileBoobleFolderContainer {draggable && dragging ? 'dragging' : ''}"
  on:drop={(e) => {
    drop(e, file.id, file.folder);
    dragging = false;
    draggableParent = true;
  }}
  on:dragover={(e) => {
    dragOverHandler(e);
    dragging = true;
    draggableParent = false;
  }}
  on:dragleave={() => {
    dragging = false;
    draggableParent = true;
  }}
>
  <div class="title" on:click={() => toggleExpandBooble()}>
    <div class="folderTitle">
      {#if icon !== ""}
        <img src={"/images/file-icons/" + icon} alt="Folder Icon" />
      {/if}
      <span class="title">{file.name}</span>
    </div>
    <div class="icons">
      {#if downloadingFolder}
        <div class="loader" />
      {:else}
        <img
          src="/images/download-file.svg"
          alt="Download File"
          title="Download Folder"
          on:click={(event) => {
            event.stopPropagation();
            downloadFolder();
          }}
        />
      {/if}
      {#if !readOnly}
        <img
          src={removeItem === file.id
            ? "/images/trash-red.svg"
            : "/images/trash.svg"}
          alt="Remove File"
          title="Delete Folder"
          on:click={(event) => {
            event.stopPropagation();
            onClickRemove(file.id);
          }}
        />
      {/if}
      <img
        class="arrowImg"
        src={file.expanded ? "/images/down_arrow.svg" : "/images/up_arrow.svg"}
        alt="Expand Arrow"
      />
    </div>
  </div>
  {#if file.expanded}
    <div class="files">
      {#each file.files as f}
        {#if f.folder}
          <svelte:self
            bind:file={f}
            bind:expandedList
            {drop}
            {dragOverHandler}
            bind:draggableParent={draggable}
            {removeItem}
            {onClickRemove}
            {onPreviewFile}
            {readOnly}
            {mode}
            {compressedMode}
            {onClickSelectButton}
            {onClickSelectUrlButton}
          />
        {:else}
          <FileBoobleFile
            file={f}
            {removeItem}
            {onClickRemove}
            {onPreviewFile}
            {readOnly}
            {compressedMode}
          />
        {/if}
      {/each}
    </div>
  {/if}
  {#if mode === "normal" && !readOnly}
    <div class="uploadFile">
      <FileBoobleFolderUploadButton onClick={onClickSelectButton} {file} />
      <FileBoobleFolderUploadLinkButton
        onClick={() => onClickSelectUrlButton(file.id)}
      />
    </div>
  {/if}
</div>

<style>
  div.fileBoobleFolderContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-color: var(--labit-card-lightgray);
    border-style: solid;
    border-width: 1px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 14px;
    margin-bottom: 15px;
  }

  div.title {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    color: var(--labit-card-gray);
    cursor: pointer;
  }

  img.arrowImg {
    width: 15px;
    margin-left: 20px !important;
  }

  div.files {
    padding-left: 20px;
    padding-right: 20px;
  }

  div.dragging {
    background-color: var(--labit-card-gray-trans);
  }

  div.icons {
    height: 100%;
    display: flex;
    margin-left: 20px;
    align-items: center;
  }

  div.icons img {
    height: 15px;
    cursor: pointer;
    margin-left: 5px;
  }

  div.folderTitle {
    display: flex;
    align-items: center;
    height: 100%;
  }

  div.folderTitle img {
    height: 75%;
    margin-right: 8px;
  }

  .loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(
      left,
      var(--labit-card-gray) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
      left,
      var(--labit-card-gray) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
      left,
      var(--labit-card-gray) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
      left,
      var(--labit-card-gray) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
      to right,
      var(--labit-card-gray) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: var(--labit-card-gray);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  .loader:after {
    background: white;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  div.uploadFile {
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
