import { getUserId, getTester, setTester } from "./localInfo";
import { post } from "./labit-connection";

export let updateTester = async () => {
  const id = getUserId();
  const savedTester = getTester();
  const response = await post(
    `select tester
        from people
        where contact_id=${id}`
  );
  const tester = response[0].tester === "1";
  if (tester !== savedTester) {
    setTester(tester);
  }
};
