<script>
  export let currentColor = "#ffffff";
  export let defaultColors = []; // Example: ["#ff0000", "#00ff00", "#0000ff"]

  import { onMount } from "svelte";
  import { randomString } from "../../js/randomString";

  const idN = randomString(10);

  let pickr = null;

  //$: console.log(defaultColors);

  onMount(() => {
    pickr = Pickr.create({
      el: "#color-picker-" + idN,
      theme: "classic", // or 'monolith', or 'nano',

      inline: true,
      showAlways: true,
      default: currentColor,
      defaultRepresentation: "HEX",

      swatches: defaultColors,

      lockOpacity: true,

      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,

        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          hsla: false,
          hsva: false,
          cmyk: true,
          input: true,
          clear: false,
          save: false,
        },
      },
    });

    pickr.on("change", (color, source, instance) => {
      const colorH = color.toHEXA();
      currentColor = "#" + colorH[0] + colorH[1] + colorH[2];
      if (colorH.length > 3) {
        currentColor = currentColor + colorH[3];
      }
    });
  });

  $: if (defaultColors && pickr) {
    defaultColors.forEach((color) => {
      pickr.addSwatch(color);
    });
  }

  $: if (currentColor && pickr) {
    pickr.setColor(currentColor);
  }
</script>

<div class="colorPickerContainer" id={"color-picker-" + idN} />

<style>
  div.colorPickerContainer {
    width: 100%;
    height: 100%;
  }
</style>
