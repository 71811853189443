export function getMonthNumberFromNameAsNumber(month) {
  let monthNumber = 0;
  switch (month) {
    case "January":
      monthNumber = 0;
      break;
    case "February":
      monthNumber = 1;
      break;
    case "March":
      monthNumber = 2;
      break;
    case "April":
      monthNumber = 3;
      break;
    case "May":
      monthNumber = 4;
      break;
    case "June":
      monthNumber = 5;
      break;
    case "July":
      monthNumber = 6;
      break;
    case "August":
      monthNumber = 7;
      break;
    case "September":
      monthNumber = 8;
      break;
    case "October":
      monthNumber = 9;
      break;
    case "November":
      monthNumber = 10;
      break;
    case "December":
      monthNumber = 11;
      break;
  }
  return monthNumber;
}

export function getMonthNumberFromNameAsString(month) {
  let monthNumber = "01";
  switch (month) {
    case "January":
      monthNumber = "01";
      break;
    case "February":
      monthNumber = "02";
      break;
    case "March":
      monthNumber = "03";
      break;
    case "April":
      monthNumber = "04";
      break;
    case "May":
      monthNumber = "05";
      break;
    case "June":
      monthNumber = "06";
      break;
    case "July":
      monthNumber = "07";
      break;
    case "August":
      monthNumber = "08";
      break;
    case "September":
      monthNumber = "09";
      break;
    case "October":
      monthNumber = "10";
      break;
    case "November":
      monthNumber = "11";
      break;
    case "December":
      monthNumber = "12";
      break;
  }
  return monthNumber;
}

export function getMonthNameFromNumberAsString(month) {
  let monthName = "January";
  switch (month) {
    case "01": {
      monthName = "January";
      break;
    }
    case "02": {
      monthName = "February";
      break;
    }
    case "03": {
      monthName = "March";
      break;
    }
    case "04": {
      monthName = "April";
      break;
    }
    case "05": {
      monthName = "May";
      break;
    }
    case "06": {
      monthName = "June";
      break;
    }
    case "07": {
      monthName = "July";
      break;
    }
    case "08": {
      monthName = "August";
      break;
    }
    case "09": {
      monthName = "September";
      break;
    }
    case "10": {
      monthName = "October";
      break;
    }
    case "11": {
      monthName = "November";
      break;
    }
    case "12": {
      monthName = "December";
      break;
    }
  }
  return monthName;
}

export function getMonthNameFromNumberAsNumber(month) {
  let monthName = "January";
  switch (month) {
    case 0: {
      monthName = "January";
      break;
    }
    case 1: {
      monthName = "February";
      break;
    }
    case 2: {
      monthName = "March";
      break;
    }
    case 3: {
      monthName = "April";
      break;
    }
    case 4: {
      monthName = "May";
      break;
    }
    case 5: {
      monthName = "June";
      break;
    }
    case 6: {
      monthName = "July";
      break;
    }
    case 7: {
      monthName = "August";
      break;
    }
    case 8: {
      monthName = "September";
      break;
    }
    case 9: {
      monthName = "October";
      break;
    }
    case 10: {
      monthName = "November";
      break;
    }
    case 11: {
      monthName = "December";
      break;
    }
  }
  return monthName;
}

export function formatNumber(day) {
  const formattedDay = day < 10 ? "0" + day.toString() : day.toString();
  return formattedDay;
}
