<script>
  export let visible;

  import Menu from "../components/Menu.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  import ContextMenu from "../components/ContextMenu.svelte";

  import { getPlatform } from "../js/localInfo";
  import { roundWebview } from "../js/roundWebview";
  import { HUBSPOT_URL } from "../js/globalExternalURLs";

  import { onMount } from "svelte";

  let platform = getPlatform();

  let showNavMenu = null;

  const PAGE = "MANAGEMENT";

  onMount(() => {
    roundWebview("webview_hubspot");
  });
</script>

<div class="content">
  <!-- Body -->
  <div class="container">
    <!--<WebBrowser
      type={platform === "APP" ? "webview" : "iframe"}
      iframeTitle="Hubspot"
      src={HUBSPOT_URL}
      useragent="Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.141 Safari/537.36"
      bind:showMenu={showNavMenu}
    />-->
    <WebBrowser
      type={platform === "APP" ? "webview" : "iframe"}
      id="webview_hubspot"
      iframeTitle="Hubspot"
      src={HUBSPOT_URL}
    />
  </div>
</div>

<!--
{#if visible}
  <ContextMenu currentURL={HUBSPOT_URL} bind:showMenu={showNavMenu} />
{/if}
-->

<!--"https://app.hubspot.com/contacts/19989047/contacts/"-->
<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    flex-direction: column;
    margin-bottom: 20px;
  }
</style>
