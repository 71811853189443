<script>
  import LoginIcon from "../components/LoginIcon.svelte";
  import LoginButton from "../components/LoginButton.svelte";
</script>

<div class="loginContainer">
  <a class="logo" href="https://labit.es" target="_blank">
    <img src="/images/logo-labit-website.svg" alt="Logo Icon" />
  </a>
  <div class="info">
    <LoginIcon
      imgIcon="/images/login/thunder.svg"
      color="var(--labit-login-icon-red)"
    />
    <div class="form formDiv">
      <div class="info">
        <span class="msg formDiv">ERROR: 404</span>
      </div>
      <div class="formItem button">
        <LoginButton
          text="Back"
          colorText="white"
          colorBackground="var(--labit-login-icon-red)"
          colorBorder="var(--labit-login-icon-red)"
          onClick={() => {
            location.href = "/skylab-main";
          }}
        />
      </div>
    </div>
  </div>
</div>

<style>
  div.loginContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  }

  div.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 134px;
  }

  div.form {
    width: 50%;
    margin-top: -173px;
  }

  div.formItem {
    width: 100%;
    height: 29px;
    position: relative;
  }

  div.button {
    height: 33px;
    margin-top: 10px;
  }

  span.msg {
    font-size: 12px;
    color: var(--labit-darkgray);
    margin-bottom: 33px;
  }

  a.logo {
    position: absolute;
    top: 38px;
    left: 115px;
  }

  a.logo img {
    width: 48px;
  }

  /* Animations */

  a.logo img {
    transition: opacity 0.5s linear 0s;
  }

  a.logo img:hover {
    opacity: 0.5;
  }
</style>
