<script>
  export let text;
  export let borderWidth = "2px";
  export let borderRadius = "20px";
  export let borderColor = "var(--labit-card-gray)";
  export let fontColor = "var(--labit-card-gray)";
  export let backgroundColor = "transparent";
  export let onClick;
</script>

<div
  class="borderButtonContainer fadedButton"
  style="border-width: {borderWidth}; border-radius: {borderRadius}; border-color: {borderColor}; color: {fontColor}; background-color: {backgroundColor}"
  on:click={onClick}
>
  <span>{text}</span>
</div>

<style>
  div.borderButtonContainer {
    width: 100%;
    height: 100%;
    border-style: solid;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
</style>
