<script>
  import StatusCircle from "./StatusCircle.svelte";
  export let key;
  export let currentCompany;

  let productsWithActiveKey = [];
  let roles = "";

  let getProductsWithActiveKey = () => {
    productsWithActiveKey = [];
    currentCompany.products.forEach((p) => {
      let keyAndProduct = currentCompany.suscriptions.find((s) => {
        return s.product === p.id && s.license === key.id;
      });
      if (keyAndProduct) {
        if (key.active) {
          productsWithActiveKey.push(1);
        } else {
          productsWithActiveKey.push(0);
        }
      } else {
        productsWithActiveKey.push(0);
      }
      productsWithActiveKey = productsWithActiveKey;
    });
  };

  let setRoles = () => {
    roles = key.roles.reduce((acc, curr, index) => {
      acc = acc + curr;
      if (index < key.roles.length - 1) {
        acc = acc + ",";
      }
      acc = acc + " ";
      return acc;
    }, "");
  };

  $: if (currentCompany) {
    getProductsWithActiveKey();
  }

  if (key) {
    setRoles();
  }
</script>

<div class="keyContainer">
  <div class="keyInfo">
    <div class="state">
      <div class="button {key.active ? 'buttonActive' : 'buttonInactive'}">
        <span>{key.active ? "ACTIVE" : "INACTIVE"}</span>
      </div>
    </div>
    <div class="category"><span>{roles}</span></div>
    <div class="user"><span>{key.username}</span></div>
  </div>
  <div class="keyProducts">
    {#each productsWithActiveKey as active}
      <StatusCircle {active} />
    {/each}
  </div>
</div>

<style>
  .keyContainer {
    display: flex;
    flex-direction: row;
    padding: 5px;
  }

  .keyInfo {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .keyProducts {
    width: 69%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 80px;
  }
  .button {
    width: 80px;
    height: 20px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .state,
  .category,
  .user {
    width: 30%;
  }

  .buttonActive {
    background-color: var(--labit-button-green);
    color: var(--labit-white);
  }
  .buttonInactive {
    background-color: var(--labit-card-lightgray);
    color: var(--labit-white);
  }

  .button span {
    font-weight: bold;
  }

  .keyInfo span {
    font-size: 13px;
  }
</style>
