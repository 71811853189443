<script>
  export let text;
  export let options1;
  export let selectedValue1;
  export let placeholder1;
  export let onChange1;
  export let options2;
  export let selectedValue2;
  export let placeholder2;
  export let onChange2;
  export let width1 = "";
  export let width2 = "";

  import Combobox from "./Combobox.svelte";
</script>

<style>
  div.submenuComboboxContainer {
    display: flex;
    color: var(--labit-card-gray);
    height: 21px;
    font-size: 13px;
    align-items: center;
  }

  div.submenuComboboxContainer div.combobox1 {
    height: 100%;
    width: 142px;
    margin-right: 13px;
  }

  div.submenuComboboxContainer div.combobox2 {
    height: 100%;
    width: 163px;
  }

  span {
    margin-right: 13px;
  }
</style>

<div class="submenuComboboxContainer">
  <span>{text}</span>
  <div class="combobox1" style={width1 !== '' ? 'width: ' + width1 : ''}>
    <Combobox
      options={options1}
      placeholder={placeholder1}
      bind:selectedValue={selectedValue1}
      onChange={onChange1} />
  </div>
  <div class="combobox2" style={width2 !== '' ? 'width: ' + width2 : ''}>
    <Combobox
      options={options2}
      placeholder={placeholder2}
      bind:selectedValue={selectedValue2}
      onChange={onChange2} />
  </div>
</div>
