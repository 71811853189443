<script>
  export let visible;

  import DashboardTitle from "../../../../components/dashboard/DashboardTitle.svelte";
  
  import moment from 'moment';

  let day = moment().dayOfYear();

  $: if (visible) {
    day = moment().dayOfYear();
  }
</script>

<div class="dashboardContainer">
  <DashboardTitle title="Drawing of the day" />
  <div class="picDayBox">
    <img class="picDay" src="/images/daily-pics/{day}.jpg" alt="picDay{day}" />
  </div>
</div>

<style>
  div.dashboardContainer {
    width: 100%;
    height: 100%;
  }
  div.picDayBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 87%;
  }

  div.picDayBox img {
    width: 240px;
  }
</style>
