<script>
  export let text;
  export let error = false;
</script>

<div class="analyticsTextAreaContainer">
  <div class="textarea {error ? 'error' : ''}">
    <textarea class="customScrollbars" bind:value={text} />
  </div>
</div>

<style>
  div.analyticsTextAreaContainer {
    width: 100%;
  }

  div.textarea {
    width: 100%;
    border-radius: 15px;
    border: 1px solid var(--labit-card-gray);
    padding: 5px 0.4em 5px 0.4em;
  }

  div.error {
    border: 1px solid red !important;
  }

  textarea {
    padding: 0px !important;
    font-size: 13px;
    background-color: transparent;
    color: var(--labit-card-gray);
    width: 100%;
    height: 90px;
    border-style: none;
  }
</style>
