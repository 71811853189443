<script>
  import DashboardTitle from "../../../../components/dashboard/DashboardTitle.svelte";
  import TaskChart from "./TaskChart.svelte";
  import { createEventDispatcher } from 'svelte';
  export let id;
  export let boxTitle;
  export let nTodo = 0;
  export let nInProgress = 0;
  export let nDone = 0;
  export let link = "https://tasks.office.com/labit.es/es-ES/Home/Planner/#/planhub";

  const dispatch = createEventDispatcher();
</script>

<div class="dashboardContainer">
  <DashboardTitle title={boxTitle} numEvents={nTodo + nInProgress + nDone} />
  <div class="dashboardBody" on:click={ () => dispatch('clicked', link) } on:contextmenu={ () => dispatch('rigthClicked', link) }>
    <div class="taskChartContainer">
      <TaskChart
        {id}
        gray={nTodo}
        blue={nInProgress}
        green={nDone}
        grayLabel="To Do"
        blueLabel="In Progress"
        greenLabel="Done"
      />
    </div>
    <div class="taskCharLegend">
      <div class="typeContainer">
        <div class="pointerToDo" />
        <span class="status">To do</span><span><b>{nTodo}</b></span>
      </div>
      <div class="typeContainer">
        <div class="pointerInProgress" />
        <span class="status">In progress</span><span><b>{nInProgress}</b></span>
      </div>
      <div class="typeContainer">
        <div class="pointerDone" />
        <span class="status">Done</span><span><b>{nDone}</b></span>
      </div>
    </div>
  </div>
</div>

<style>
  div.dashboardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.dashboardBody {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 85%;
    align-items: center;
    justify-content: center;
  }

  div.taskChartContainer {
    width: 190px;
    height: 190px;
    cursor: pointer;
  }

  div.typeContainer {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  div.pointerToDo,
  div.pointerDone,
  div.pointerInProgress {
    width: 15px;
    height: 15px;
    border-radius: 50% !important;
    margin-right: 10px;
  }

  div.pointerInProgress {
    background-color: #327eaa;
  }

  div.pointerToDo {
    background-color: #605e5c;
  }

  div.pointerDone {
    background-color: #418040;
  }

  div.taskCharLegend {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  div.typeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  span.status {
    margin-right: 10px;
  }
</style>
