<script>
    export let placeholder;
    export let time = '';
    export let width = '100%';
    export let height = '29px';
    export let error = false;
    export let index = -1;

    import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();
</script>

<input class="element {error ? 'error' : ''}" bind:value={time} {placeholder} on:blur={ () => dispatch('blocked', {time, index}) } style="width: {width}; height: {height}">

<style>

    .element {
        font-size: 16px;
        margin: 0 !important;
        border-radius: 16px;
        padding: 0 12px;
        text-align: center;
    }

    input {
        background-color: white;
        border: 1px solid #e7e7e7;
    }

    .error {
        border-color: red;
    }
</style>
