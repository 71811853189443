<script>
  export let img;
  export let width = "122px";
  export let backgroundColor = "var(--labit-button-icon)";
  export let onClick;
  export let onRightClick;
</script>

<div
  class="submenuIcon"
  style="width: {width}; background-color: {backgroundColor};"
  on:click={onClick}
  on:contextmenu={(ev) => {
    ev.preventDefault();
    if (onRightClick) {
      onRightClick();
    }
    return false;
  }}
>
  <img src={img} alt="icon" />
</div>

<style>
  div {
    height: 52px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  img {
    height: 59.6%;
  }

  /* Animations */

  div {
    transition: opacity 0.5s linear 0s;
  }

  div:hover {
    opacity: 0.5;
  }
</style>
