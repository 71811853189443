<script>
  export let perc; // 0-100
  export let backgroundColor = "white";
  export let foregroundColor = "black";
</script>

<style>
  div.progressbarContainer {
    width: 100%;
    height: 100%;
    display: flex;
  }

  div.backgroundBar {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    border-radius: 10px;
  }

  div.foregroundBar {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
  }
</style>

<div class="progressbarContainer">
  <div class="backgroundBar" style="background-color: {backgroundColor}">
    <div
      class="foregroundBar"
      style="background-color: {foregroundColor}; width: {perc}%;" />
  </div>
</div>
