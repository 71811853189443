<script>
  export let onClickProfile;
  // export let onClickClockify;
  export let onClickNewClockify;
  // export let onClickFactorial;
  export let onClickDocuments;
  export let onClickOptions;
  export let onClose;

  import MenuItemProfile from "./MenuItemProfile.svelte";

  import { getPicture } from "../js/localInfo";
  import { openNewWindow } from "../js/openNewWindow";
  import { openURL } from "../js/openURL";

  import { onMount } from "svelte";

  let pic = "";

  onMount(() => {
    pic = getPicture();
  });

  let clickLogo = (forceNewWindow) => {
    if (forceNewWindow) {
      openNewWindow("/skylab-main/dashboard");
    } else {
      if (onClose) {
        onClose();
      }
      openURL("/dashboard", true);
    }
  };
</script>

<div class="headerContainer">
  <div class="header" id="menuHeader">
    <!--<div class="logo">
      <img
        src="/images/menu-icons/logo-labit.svg"
        alt="Logo"
        on:click={() => clickLogo(false)}
        on:contextmenu={(ev) => {
          ev.preventDefault();
          clickLogo(true);
          return false;
        }}
      />
    </div>-->
    <div class="options">
      <img
        class="fadedButton"
        src="/images/profile/info.svg"
        alt="Option Icon"
        on:click={onClickProfile}
      />
      <!-- <img
        class="fadedButton"
        src="/images/profile/hours.svg"
        alt="Option Icon"
        on:click={onClickClockify}
      />
      <img
        class="fadedButton"
        src="/images/profile/calendar.svg"
        alt="Option Icon"
        on:click={onClickFactorial}
      /> -->
      <img
        class="fadedButton"
        src="/images/profile/documents.svg"
        alt="Option Icon"
        on:click={onClickDocuments}
      />
      <img
        class="fadedButton"
        src="/images/profile/hours.svg"
        alt="Option Icon"
        on:click={onClickNewClockify}
      />
      <img
        class="fadedButton"
        src="/images/new/options.svg"
        alt="Option Icon"
        on:click={onClickOptions}
      />
    </div>
    <!--<MenuItemProfile src={pic} {onClose} />-->
  </div>
</div>

<style>
  div.headerContainer {
    /*display: flex;
    flex-direction: column;*/
    /*width: 1640px;*/
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  div.header {
    /*width: 100%;*/
    height: 38px;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    /*margin-bottom: 20px;*/
  }

  div.logo {
    padding-left: 33px;
    padding-right: 33px;
    border-radius: 19px;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: white;
  }

  div.logo img {
    cursor: pointer;
    height: 70%;
  }

  div.options {
    height: 100%;
    /*flex: 1;*/
    background-color: var(--labit-dialog-background);
    /*margin-left: 15px;
    margin-right: 5px;*/
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  div.options img {
    height: 100%;
    cursor: pointer;
  }

  div.options img:not(:last-child) {
    margin-right: 50px;
  }

  /* Animations */

  div.logo img {
    opacity: 0.5;
    transition: opacity 0.5s linear 0s;
  }

  div.logo img:hover {
    opacity: 1;
  }
</style>
