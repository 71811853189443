<script>
  export let link;
  export let firstLink;
  export let lastLink;
  export let editLinks;
  export let onEditLink;
  export let onDeleteLink;
  export let onUpLink;
  export let onDownLink;

  import { openNewWindow } from "../../../../js/openNewWindow";
</script>

<div class="linkColumnLinkItemContainer">
  <a class="fadedButton" title={link.url} href={link.url} on:click|preventDefault={ (ev) => { ev.preventDefault(); openNewWindow(link.url); } }>{link.title}</a>
  {#if editLinks}
    {#if !firstLink}
      <img
        class="fadedButton"
        src="./images/arrows/up-arrow.svg"
        alt="Move up"
        on:click={onUpLink}
      />
    {/if}
    {#if !lastLink}
      <img
        class="fadedButton"
        src="/images/arrows/down-arrow.svg"
        alt="Move down"
        on:click={onDownLink}
      />
    {/if}
    <img
      class="fadedButton"
      src="./images/edit.svg"
      alt="Edit link"
      on:click={onEditLink}
    />
    <img
      class="fadedButton"
      src="./images/trash.svg"
      alt="Delete link"
      on:click={onDeleteLink}
    />
  {/if}
</div>

<style>
  div.linkColumnLinkItemContainer {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  span {
    width: 100%;
    font-size: 14px;
    color: var(--labit-card-gray);
  }

  img {
    height: 15px;
    max-width: 15px;
  }

  img:not(:first-child) {
    margin-left: 3px;
  }

  img:not(:last-child) {
    margin-right: 3px;
  }
</style>
