<script>
  import DashboardTitle from "../../../../components/dashboard/DashboardTitle.svelte";
  //import { openNewWindow } from "../../../../js/openNewWindow";
  //import { openURL } from "../../../../js/openURL";
  //import { getPlatform, setOpenTool } from "../../../../js/localInfo";

  export let boxTitle;
  export let apps;
  export let onClick;

  //const platform = getPlatform();


  /*
    let openApp = (app, forceNewWindow) => {
      if (platform === "Web") {
        window.open(app.link);
      } else {
        if (forceNewWindow) {
          openNewWindow(app.link);
        } else {
          setOpenTool(app.link);
          openURL("/tools/external", true);
        }
      }
    };
    let touchIcon = (index) => {
      currentLink = apps[index].link;
    };

    let currentLink = "";
  */
</script>

<div class="dashboardContainer">
  <DashboardTitle title={boxTitle} numEvents={apps.length} />
  <div class="appList">
    {#each apps as app, index}
      <!--<img
        on:click={() => openApp(app, false)}
        on:contextmenu={(ev) => {
          ev.preventDefault();
          openApp(app, true);
          return false;
        }}
        on:mouseenter={(e) => {
          touchIcon(index);
        }}
        src={app.img}
        alt=""
      />-->
      <img
        on:click={() => onClick(app.link, false)}
        on:contextmenu={(ev) => {
          ev.preventDefault();
          onClick(app, true);
          return false;
        }}
        src={app.img}
        alt=""
      />
    {/each}
  </div>
  <!--
  on:mouseenter={(e) => {
    touchIcon(index);
  }}
  -->

  <!--
  {#if currentLink !== ""}
    <span>{currentLink}</span>
  {/if}
  -->
</div>

<style>
  div.dashboardContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: var(--labit-white);
    border-radius: 14px;
    flex-wrap: wrap;
    position: relative;
  }

  div.appList {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 40px;
    max-height: 40px;
    margin: 15px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  img:hover {
    opacity: 0.5;
  }

  span {
    width: 100%;
    padding: 0px 10px 0 10px;
    position: absolute;
    left: 0px;
    bottom: 10px;
    color: var(--labit-card-gray);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
