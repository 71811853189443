<script>
  export let image;
  export let onClick;
</script>

<style>
  div.roundedButtonContainer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--labit-dialog-background);
    width: 30px;
    height: 30px;
  }

  img {
    width: 50%;
  }

  /* Animations */

  div.roundedButtonContainer {
    transition: opacity 0.5s linear 0s;
  }

  div.roundedButtonContainer:hover {
    opacity: 0.5;
  }
</style>

<div class="roundedButtonContainer" on:click={onClick}>
  <img src={image} alt="Icon" />
</div>
