import { removeAccents } from "../js/removeAccents";

export let createUserPicName = (userProfile) => {
  let userName = userProfile.name.trim().replace(/ /g, "");
  userName = removeAccents(userName);
  const userPicName = "profile-" + userName + "-" + userProfile.picId;
  return userPicName;
};

export let createCompanyPicName = (company) => {
  let companyName = company.name.trim().replace(/ /g, "");
  companyName = removeAccents(companyName);
  const companyPicName = company.id + "-" + companyName;
  return companyPicName;
};

export let createProjectPicName = (project) => {
  let projectName = project.card.name
    .trim()
    .replace(/ /g, "")
    .replace(/\//g, "");
  projectName = removeAccents(projectName);
  const projectPicName = project.id + "-" + projectName;
  return projectPicName;
};
