<script>
  export let options;
  export let placeholder = "";
  export let selectedValue;
  export let error;
  export let searchable = true;
  export let multiOptions = false;
  export let onChange = null;
  export let backgroundColor = "transparent";

  import Select from "svelte-select";
</script>

<div>
  <Select
    items={options}
    isMulti={multiOptions}
    bind:selectedValue
    {placeholder}
    on:select={onChange}
    isSearchable={searchable}
    {error}
    inputStyles="background-color: {backgroundColor}; border-radius:
    100vh/100vh; {error
      ? 'border: 1px solid red;'
      : ''}"
  />
  <img src="/images/down_arrow.svg" alt="icon" />
</div>

<style>
  div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  img {
    position: absolute;
    height: 35%;
    right: 10px;
  }
</style>
