<script>
  export let item;
  export let editMode;
  export let onEdit;
  export let onRemove;
  export let onSelect;
</script>

<style>
  div.RDSGKeyCategory {
    width: 586px;
    font-size: 12px;
    font-weight: bold;
    background-color: white;
    padding-left: 29px;
    margin-top: 4px;
    position: relative;
  }

  div.categoryIcons {
    position: absolute;
    top: 0px;
    right: 14px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-end;
  }

  div.categoryIcons img {
    height: 100%;
    cursor: pointer;
    margin-left: 7px;
    max-height: 14px;
  }

  div.keyName {
    display: flex;
    align-items: center;
  }

  div.keyNameEdit {
    align-items: flex-start !important;
  }

  img.cube {
    height: 100%;
    margin-right: 4px;
    margin-left: 4px;
    max-height: 14px;
  }

  span.edit {
    padding-right: 60px;
  }

  div.selectable3D {
    cursor: pointer;
  }
</style>

<div class="RDSGKeyCategory">
  <div
    class="keyName {editMode ? 'keyNameEdit' : ''}
    {item.search3D !== '' && !editMode ? 'selectable3D' : ''}"
    on:click={item.search3D !== '' && !editMode ? onSelect(item.search3D) : null}>
    <span>·</span>
    {#if item.search3D !== '' && !editMode}
      <img class="cube" src="/images/3d-cube.svg" alt="3D Cube" />
    {/if}
    <span class={editMode ? 'edit' : ''}>{item.name}</span>
  </div>

  {#if editMode}
    <div class="categoryIcons">
      <img src="/images/edit.svg" on:click={onEdit} alt="Edit Icon" />
      {#if item.numDeleted === 0}
        <img src="/images/trash.svg" on:click={onRemove} alt="Remove Icon" />
      {:else}
        <img
          src="/images/trash-red.svg"
          on:click={onRemove}
          alt="Remove Icon" />
      {/if}
    </div>
  {/if}
</div>
