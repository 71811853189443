import { post } from "./labit-connection";

export async function insertCompanyBasic(name, category) {
  const response = await post(
    `insert into companies (Name, Logo_Url, category_id, City, CityCode, Address, PhoneNumber, Notes, Email, gMapUrl, picPath, latitude, longitude, code, practise, Country, esLabit, Active)
      values ('${name}', '', 0, '', '', '', '', '', '', '', '', '', '', '', '', 'ES - Spain', 0, 1)`
  );
  const id = response[0];
  await post(
    `insert into companyCategories (company, category)
      values (${id}, ${category})`
  );

  return id;
}
