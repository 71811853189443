<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let title, prop;

	function modifyProperty() {
		dispatch("modProp", {
			prop: { title, prop },
		});
	}
</script>

<div class="detail">
	<span>{title}: </span>
	{#if title === "tag"}
		<span class="tag">{prop}</span>
	{:else}
		<input
			type="text"
			bind:value={prop}
			on:blur={modifyProperty}
		/>
	{/if}
</div>


<style>
	.detail input:first-child,
	.detail span:first-child {
		font-size: 25px;
	}

	.detail {
		height: 50px;
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #ebebeb;
		border-top: 0;
		padding-left: 2%;
		margin: 0 1%;
	}
	
	input, .tag {
		text-align: initial;
		padding: 0.4em;
		border: none;
		width: 45%;
	}
</style>
