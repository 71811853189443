<script>
  export let text;
  export let selected;
  export let onClick;
  export let visible;
</script>

{#if visible}
  <div class={selected ? "marked" : "notMarked"} on:click={onClick}>
    <img
      src={selected ? "/images/checkbox.svg" : "/images/checkbox_empty.svg"}
      alt="check"
    />
    <span>{text}</span>
  </div>
{/if}

<style>
  div {
    background-color: white;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  div:hover {
    background-color: lightgray;
  }

  img {
    height: 15px;
    width: 15px;
    margin-right: 6px;
  }
</style>
