<script>
  export let value;
  export let placeholder;
  export let onClickForgot;
  export let error;
</script>

<div class="loginPassworEditContainer">
  <input type="password" bind:value {placeholder} />
  {#if !error}
    <span class="fadedButton" on:click={onClickForgot}>Forgot?</span>
  {/if}
</div>

<style>
  div.loginPassworEditContainer {
    width: 100%;
    height: 100%;
    font-size: 12px;
    position: relative;
  }

  input {
    width: 100%;
    height: 100%;
    color: var(--labit-darkgray);
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--labit-gray);
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 44px;
    background-color: transparent;
  }

  input::placeholder {
    font-size: 12px;
    color: var(--labit-darkgray);
  }

  span {
    position: absolute;
    top: 8px;
    right: 0px;
  }
</style>
