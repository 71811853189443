export let percentageColors = (color1, color2, perc) => {
  var hex = function (x) {
    x = x.toString(16);
    return x.length == 1 ? "0" + x : x;
  };

  var r = Math.ceil(
    parseInt(color1.substring(0, 2), 16) * perc +
      parseInt(color2.substring(0, 2), 16) * (1 - perc)
  );
  var g = Math.ceil(
    parseInt(color1.substring(2, 4), 16) * perc +
      parseInt(color2.substring(2, 4), 16) * (1 - perc)
  );
  var b = Math.ceil(
    parseInt(color1.substring(4, 6), 16) * perc +
      parseInt(color2.substring(4, 6), 16) * (1 - perc)
  );

  var pColor = hex(r) + hex(g) + hex(b);

  return pColor;
};
