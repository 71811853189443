<script>
  export let icon;
  export let onClick;
  export let buttonHeight = "165px";
</script>

<div
  class="editBoxButtonContainer fadedButton"
  on:click={onClick}
  style="height: {buttonHeight};"
>
  <img src={icon} alt="Box Button" />
</div>

<style>
  div.editBoxButtonContainer {
    background-color: #d7e1ea;
    width: 118px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.editBoxButtonContainer:not(:last-child) {
    margin-bottom: 25px;
  }

  img {
    width: 20px;
  }
</style>
