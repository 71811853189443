<script>
    import { onMount, afterUpdate, createEventDispatcher } from "svelte";
    import { timeConvert } from '../../../js/formatDateTime';
    const dispatch = createEventDispatcher();

    Chart.register(ChartDataLabels);

    const opts = {
        events: ['click', 'mousemove'],
        responsive: true,
        scales: {
            y: {
                ticks: { 
                    callback: timeConvert
                },
                beginAtZero: true,
                min: 0,
                display: 'auto',
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
                labels : {
                    usePointStyle: true,
                    padding: 20
                }
            },
            tooltip: {
                callbacks: {
                    label: (val) => console.log(val)
                }
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                formatter: (val, ctx) => {
                    const label = timeConvert(ctx.dataset.data[ctx.dataIndex].y);
                    return label === '0:00' ? '' : label;
                }
            }
        },
        onClick: (evt) => {
            const points = chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

            if (points.length) {
                const firstPoint = points[0];
                //const label = chart.data.labels[firstPoint.index];
                const value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
                dispatch('clicked', value)
            }
        }
    };

    export let options = opts;
    export let datasets;

    let reportsChartCanvas, chart;

    onMount(() => {
        const ctx = reportsChartCanvas.getContext('2d');
        chart = new Chart(ctx, {
            type: "bar",
            data: datasets,
            options: options
        });
    });

    afterUpdate(() => {
        chart.data.datasets = datasets;
        chart.update();
    });

</script>

<canvas bind:this={reportsChartCanvas} id="reportsChart" />

<style>
    #reportsChart {
        width: 100%;
        height: 100%;
    }
</style>