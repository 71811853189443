<script>
  export let passwd;
  export let validPassword;
</script>

<style>
  div.container {
    width: 100%;
    height: 100%;
    display: flex;
  }

  input {
    display: flex;
    flex-grow: 1;
    height: 100%;
    border-style: solid;
    border-color: var(--labit-card-gray);
    padding-left: 21px;
    padding-right: 21px;
    border-radius: 960px/960px;
    font-size: 13px;
    color: var(--labit-card-gray);
  }

  div.password {
    height: 100%;
    width: 35px;
    display: flex;
    align-items: center;
    margin-left: 6px;
  }

  div.password img {
    width: 35px;
    height: 35px;
  }
</style>

<div class="container">
  <input type="password" bind:value={passwd} autocomplete="new-password" />
  <div class="password">
    {#if validPassword}
      <img src="/images/green-tick.svg" alt="Valid Icon" />
    {/if}
  </div>
</div>
