<script>
  export let options;
  export let selected;

  import MultipleSelectItem from "./components/MultipleSelectItem.svelte";

  import ClickOutside from "svelte-click-outside";

  let showOptions = false;
  let selectedMap = [];
  let selectedAll = false;
  let filterText = "";
  let clicked = false;

  let sortedOptions = [];

  let selectedText = "";

  function clickMenu() {
    showOptions = !showOptions;
  }

  function onClickOutside() {
    if (!clicked) {
      showOptions = false;
    }
    clicked = false;
  }

  function createSelectedMap() {
    selectedMap = sortedOptions.map((item) => {
      const pos = selected.findIndex((sel) => {
        return sel === item.value;
      });
      return pos !== -1;
    });
  }

  function createSelectedText() {
    selectedText = selectedMap.reduce((acc, curr, index) => {
      if (curr) {
        acc = acc + sortedOptions[index].label + ", ";
      }
      return acc;
    }, "");
    if (selectedText !== "") {
      selectedText = selectedText.substring(0, selectedText.length - 2);
    }
  }

  function clickOption(index) {
    const value = sortedOptions[index].value;
    const pos = selected.findIndex((item) => {
      return item === value;
    });
    if (pos === -1) {
      selected.push(value);
      if (selected.length === sortedOptions.length) {
        selectedAll = true;
      }
    } else {
      selected.splice(pos, 1);
      selectedAll = false;
    }
    selected = selected;
    clicked = true;
  }

  function clickSelectAll() {
    selectedAll = !selectedAll;
    if (selectedAll) {
      if (filterText === "") {
        selected = sortedOptions.map((item) => {
          return item.value;
        });
      } else {
        sortedOptions.forEach((item) => {
          if (isVisible(item.label, filterText)) {
            selected.push(item.value);
          }
        });
      }
    } else {
      if (filterText === "") {
        selected = [];
      } else {
        sortedOptions.forEach((item, index) => {
          if (isVisible(item.label, filterText)) {
            const pos = selected.findIndex((sel) => {
              return sel === item.value;
            });
            if (pos > -1) {
              selected.splice(pos, 1);
            }
          }
        });
      }
    }
  }

  function isVisible(label, filterText) {
    let visible = true;
    if (filterText !== "") {
      if (!label.toUpperCase().includes(filterText.toUpperCase())) {
        visible = false;
      }
    }
    return visible;
  }

  let sortOptions = () => {
    sortedOptions = options.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (a.label < b.label) {
        return -1;
      }
      return 0;
    });
  };

  $: {
    if (sortedOptions) {
      selectedAll = sortedOptions.reduce((acc, curr, index) => {
        if (isVisible(curr.label, filterText)) {
          acc = selectedMap[index] && acc;
        }
        return acc;
      }, true);
    }
  }

  $: if (selected.length === 0 && filterText === "") {
    selectedMap = [];
    selectedAll = false;
    filterText = "";
    selectedText = "";
  } else {
    createSelectedMap();
    createSelectedText();
  }

  $: if (options && options.length > 0) {
    sortOptions();
  }

  //$: console.log("MAP", selectedMap);
</script>

<div class="MSContainer">
  <ClickOutside on:clickoutside={onClickOutside}>
    <input
      class="values"
      type="text"
      readonly="true"
      on:click={() => clickMenu()}
      value={selectedText}
    />
    {#if showOptions}
      <div class="options">
        <div class="filter">
          <input
            class="filter"
            type="text"
            maxlength="75"
            placeholder="filter..."
            bind:value={filterText}
          />
        </div>
        <div class="line" />
        <div class="itemsSel customScrollbars">
          <MultipleSelectItem
            text="Select all"
            selected={selectedAll}
            onClick={() => clickSelectAll()}
            visible={true}
          />
          {#each sortedOptions as option, i}
            {#if selectedMap[i]}
              <MultipleSelectItem
                text={option.label}
                selected={selectedMap[i]}
                onClick={() => clickOption(i)}
                visible={isVisible(option.label, filterText)}
              />
            {/if}
          {/each}
        </div>
        <div class="line" />
        <div class="items customScrollbars">
          {#each sortedOptions as option, i}
            {#if !selectedMap[i]}
              <MultipleSelectItem
                text={option.label}
                selected={selectedMap[i]}
                onClick={() => clickOption(i)}
                visible={isVisible(option.label, filterText)}
              />
            {/if}
          {/each}
        </div>
      </div>
    {/if}
  </ClickOutside>
</div>

<style>
  div.MSContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--labit-dark-gray);
  }

  div.MSContainer div {
    width: 100%;
  }

  div.filter {
    padding: 10px;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  input.values {
    width: 100%;
    height: 100%;
    background-color: var(--labit-background-gray);
    border-style: solid;
    border-color: var(--labit-gray);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 960px/960px;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    color: var(--labit-dark-gray);
  }

  div.options {
    position: absolute;
    z-index: 1000;
    top: 130%;
    background-color: white;
    border-color: var(--labit-gray);
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
  }

  input.filter {
    width: 100%;
    height: 100%;
    background-color: white;
    border-style: solid;
    border-color: var(--labit-gray);
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    border-radius: 6px;
  }

  div.line {
    width: 100%;
    height: 1px;
    background-color: var(--labit-gray);
    margin-top: 5px;
    margin-bottom: 5px;
  }

  div.items {
    max-height: 400px;
    overflow-y: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  div.itemsSel {
    max-height: 200px;
    overflow-y: auto;
  }

  :global(div.MSContainer div:nth-child(1)) {
    width: 100%;
    height: 100%;
    display: flex;
  }
</style>
