<script>
    import { confirmCloseIfText } from '../../../js/actions/confirmCloseIfText';
    export let placeholder = 'Write something...';
    export let text = '';
    export let error = false;
</script>

<input use:confirmCloseIfText class={error ? 'error' : ''} bind:value={text} {placeholder}>

<style>
    .error {
        border-color: red;
    }

    input {
        background-color: white;
        font-size: 16px;
        margin: 0 !important;
        border: 1px solid #e7e7e7;
        border-radius: 16px;
        min-width: 200px;
        height: 28px;
        padding: 0 12px;
        width: 500px;
    }
    
</style>
