<script>
  export let idCompany = null;
  export let idVersion = null;
  /*export let idProject = null;
  export let idProgram = null;
  export let idSpace = null;
  export let idRoom = null;*/

  import Menu from "../components/Menu.svelte";
  import SubmenuCombobox from "../components/SubmenuCombobox.svelte";
  import SubmenuComboboxIcon from "../components/SubmenuComboboxIcon.svelte";
  import SubmenuButton from "../components/SubmenuButton.svelte";
  import SubmenuIcon from "../components/SubmenuIcon.svelte";
  import SubmenuButtonIcon from "../components/SubmenuButtonIcon.svelte";
  import SubmenuText from "../components/SubmenuText.svelte";
  import SubmenuTextLights from "../components/SubmenuTextLights.svelte";
  import SubmenuComboboxVersion from "../components/SubmenuComboboxVersion.svelte";
  import SubmenuBlackButtonIcon from "../components/SubmenuBlackButtonIcon.svelte";
  import RoundedButtonIcon from "../components/RoundedButtonIcon.svelte";
  import SubmenuMultipleSelect from "../components/SubmenuMultipleSelect.svelte";
  import PrintOptions from "../components/PrintOptions.svelte";
  import RDSEpigraph from "../components/RDSEpigraph.svelte";
  import RDSAttribute from "../components/RDSAttribute.svelte";
  import RDSCell from "../components/RDSCell.svelte";

  import { post } from "../js/labit-connection";
  import {
    RDSCreateConflictTable,
    RDSAddRoomLevel,
    RDSEmptyTable,
    RDSFillTable,
    RDSUpdateTable,
    RDSAddExceptions,
  } from "../js/rds-conflicts-exceptions";
  import { getRole, getUserId } from "../js/localInfo";
  import { openURL } from "../js/openURL";
  import { openNewWindow } from "../js/openNewWindow";
  import getClients from "../js/clients";

  import { onMount } from "svelte";

  import jsPDF from "jspdf";

  const PAGE = "RESOURCES";

  let myRole = getRole();
  let myId = getUserId().toString();
  let editable = false;

  let companies = [];
  let selectedCompany = null;
  let versions = [];
  let selectedVersion = null;
  let versionsInfo = [];
  let versionName = [];
  let programs = [];
  let selectedProgram = null;
  let spaces = [];
  let selectedSpace = null;
  let rooms = [];
  let selectedRoom = null;
  let roomsInfo = [];
  let currentRoom = null;
  let revitStandardsItemId = "";
  let projects = [];
  let selectedProject = null;
  let dynamos = [];
  let dynamosInfo = [];
  let selectedDynamo = null;
  let currentDynamo = null;
  let dynamo = null;

  let keys = [];
  let conflictTable = [];

  let filterRed = true;
  let filterYellow = true;
  let filterGreen = true;

  let pGreen = 0;
  let pYellow = 0;
  let pRed = 0;
  let textRed = "";
  let textYellow = "";
  let textGreen = "";

  let expandedMenu = true;

  let filterEpigraph = [];
  let selFilterEpigraph = [];
  let filterAttribute = [];
  let selFilterAttribute = [];
  let filterLevel = [];
  let selFilterLevel = [];
  let filterRooms = [];
  let selFilterRooms = [];

  let epigraphMap = [];
  let attributeMap = [];

  let printView = false;

  let loading = false;

  let idCompany_ = null;
  let idVersion_ = null;

  onMount(async () => {
    prepareRoomDataSheetL();
  });

  let prepareRoomDataSheetL = async () => {
    getCompanies();
  };

  let getProjects = async () => {
    if (selectedVersion) {
      const response = await post(
        `select project_id, Name
        from projects
        where standards_versions_id=${selectedVersion.value}
        order by Name asc`
      );
      projects = response.map((item) => {
        return {
          value: item.project_id,
          label: item.Name,
        };
      });
      selectedProject = projects[0];
      changeProject();

      getEditable();
    }
  };

  let changeProject = async () => {
    getRooms();
    getEditable();
  };

  let getEditable = async () => {
    if (myRole === "Admin") {
      editable = true;
    } else {
      // Get current project managers who are the only ones who can edit
      /*const today = moment().format("YYYY-MM-DD");
      const response = await post(
        `select contact_id
        from teams
        where project_id=${selectedProject.value} and role_id=1 and startDate<='${today}' and endDate>='${today}'`
      );
      const pos = response.findIndex(item => {
        return item.contact_id === myId;
      });
      editable = pos >= 0;*/
      /*const response = await post(
        `select people from projectPeople
          where people=${myId} and project=${selectedProject.value} and role='Project Manager' and active=1`
      );*/
      const response = await post(
        `select pt.people
          from projectTeam as pt
          left join phase as ph on ph.id=pt.phase
          where ph.project=${selectedProject.value} and pt.people=${myId} and pt.role="Project Manager"`
      );
      editable = response.length > 0;
    }
  };

  let getDynamos = async () => {
    let ok = false;
    if (currentRoom && selectedProject) {
      let response = await post(
        `select inputs_id, input, updated
        from dynamo_inputs
        where project_name='${selectedProject.label}' and room_name='${currentRoom.roomName}'
        order by updated desc`
      );
      if (response.length > 0) {
        dynamos = response.map((item) => {
          return {
            value: item.inputs_id,
            label: item.updated,
          };
        });
        selectedDynamo = dynamos[0];
        dynamosInfo = response.map((item) => {
          return {
            id: item.inputs_id,
            input: item.input,
          };
        });
        currentDynamo = dynamosInfo[0];
        dynamo = JSON.parse(currentDynamo.input);
        ok = true;
        changeDynamo();
      }
    }
    if (!ok) {
      dynamos = [];
      selectedDynamo = null;
      dynamosInfo = [];
      currentDynamo = null;
      dynamo = null;
      keys = [];
      conflictTable = [];
      clearFilters();
    }
  };

  let changeDynamo = async () => {
    if (/*!idRoom &&*/ selectedDynamo) {
      keys = [];
      conflictTable = [];
      clearFilters();
      const pos = dynamos.findIndex((dyn) => {
        return dyn.value === selectedDynamo.value;
      });
      if (pos >= 0) {
        currentDynamo = dynamosInfo[pos];
        dynamo = JSON.parse(currentDynamo.input);
        await getItems();
      }
    }
  };

  let getCompanies = async () => {
    /*const response = await post(
      `select company_id, Name
        from companies
        where category_id=3`
    );*/
    const response = await getClients();
    companies = response.map((item) => {
      return {
        value: item.company_id,
        label: item.Name,
      };
    });
    if (companies.length > 0) {
      if (idCompany_) {
        selectedCompany = companies.find((item) => {
          return item.value === idCompany_;
        });
      } else {
        selectedCompany = companies[0];
      }
    } else {
      selectedCompany = null;
    }

    changeCompany();
  };

  let changeCompany = () => {
    versions = [];
    selectedVersion = null;
    versionName = "";
    projects = [];
    selectedProject = null;
    programs = [];
    selectedProgram = null;
    spaces = [];
    selectedSpace = null;
    rooms = [];
    selectedRoom = null;
    roomsInfo = [];
    currentRoom = null;
    dynamos = [];
    selectedDynamo = null;
    dynamosInfo = [];
    currentDynamo = null;
    dynamo = null;
    keys = [];
    conflictTable = [];
    clearFilters();
    getVersions();
  };

  let getVersions = async () => {
    if (selectedCompany) {
      const response = await post(
        `select standards_versions_id, standard_name
        from standards_versions
        where company_id=${selectedCompany.value}`
      );
      versions = response.map((item) => {
        return {
          value: item.standards_versions_id,
          label: item.standard_name,
        };
      });
      if (versions.length > 0) {
        if (idVersion_) {
          selectedVersion = versions.find((item) => {
            return item.value === idVersion_;
          });
        } else {
          selectedVersion = versions[0];
        }
      } else {
        selectedVersion = null;
      }
      changeVersion();
    }
  };

  let changeVersion = () => {
    projects = [];
    selectedProject = null;
    programs = [];
    selectedProgram = null;
    spaces = [];
    selectedSpace = null;
    rooms = [];
    selectedRoom = null;
    roomsInfo = [];
    currentRoom = null;
    dynamos = [];
    selectedDynamo = null;
    dynamosInfo = [];
    currentDynamo = null;
    dynamo = null;
    keys = [];
    conflictTable = [];
    clearFilters();

    getProjects();
    getPrograms();
  };

  let getVersionName = async () => {
    versionName = [];
    const response = await post(
      `select TimeLabitCode, FolderLabitCode, Name
          from projects
          where standards_versions_id=${selectedVersion.value}`
    );
    versionName.push(response[0].TimeLabitCode);
    versionName.push(response[0].FolderLabitCode);
    versionName.push(response[0].Name);
    versionName = versionName;
  };

  let getPrograms = async () => {
    if (selectedVersion) {
      const response = await post(
        `select program_type_id, Name
        from program_types
        where standards_version_id=${selectedVersion.value}`
      );
      programs = response.map((item) => {
        return {
          value: item.program_type_id,
          label: item.Name,
        };
      });
      selectedProgram = programs.length > 0 ? programs[0] : null;
      changeProgram();
    }
  };

  let changeProgram = () => {
    spaces = [];
    selectedSpace = null;
    rooms = [];
    selectedRoom = null;
    roomsInfo = [];
    currentRoom = null;
    dynamos = [];
    selectedDynamo = null;
    dynamosInfo = [];
    currentDynamo = null;
    dynamo = null;
    keys = [];
    conflictTable = [];
    clearFilters();
    getSpaces();
  };

  let getSpaces = async () => {
    if (selectedProgram) {
      const response = await post(
        `select space_name_id, Name
        from space_names
        where program_type_id=${selectedProgram.value}`
      );
      spaces = response.map((item) => {
        return {
          value: item.space_name_id,
          label: item.Name,
        };
      });
      selectedSpace = spaces.length > 0 ? spaces[0] : null;
      changeSpace();
    }
  };

  let changeSpace = () => {
    rooms = [];
    selectedRoom = null;
    roomsInfo = [];
    currentRoom = null;
    dynamos = [];
    selectedDynamo = null;
    dynamosInfo = [];
    currentDynamo = null;
    dynamo = null;
    keys = [];
    conflictTable = [];
    clearFilters();
    getRooms();
  };

  let getRooms = async () => {
    if (selectedSpace && selectedProject) {
      const response = await post(
        `select space_type_id, st.Name as spaceTypeName, description, long_description, room_name, sn.Name spaceNameName
        from space_types as st
        left join space_names as sn on sn.space_name_id=st.space_name_id
        where st.space_name_id=${selectedSpace.value}`
      );

      const resp = await post(
        `select distinct room_name
        from dynamo_inputs
        where project_name='${selectedProject.label}'`
      );

      if (response.length > 0) {
        rooms = [];
        roomsInfo = [];
        response.forEach((item) => {
          const pos = resp.findIndex((it) => {
            return it.room_name === item.room_name;
          });
          if (pos >= 0) {
            rooms.push({
              value: item.space_type_id,
              label: item.spaceTypeName.replace(/</, "&lt;"),
            });
            roomsInfo.push({
              id: item.space_type_id,
              name: item.spaceTypeName,
              description: item.description,
              longDescription: item.long_description,
              id3d: item.room_name,
              roomName: item.room_name,
            });
          }
        });
      }
      if (rooms.length > 0) {
        selectedRoom = rooms[0];
        roomsInfo = response.map((item) => {
          return {
            id: item.space_type_id,
            name: item.spaceTypeName,
            description: item.description,
            longDescription: item.long_description,
            roomName: item.room_name,
          };
        });
        updateCurrentRoom();
      } else {
        selectedRoom = null;
        roomsInfo = [];
        currentRoom = null;
        dynamos = [];
        selectedDynamo = null;
        dynamosInfo = [];
        currentDynamo = null;
        dynamo = null;
        keys = [];
        conflictTable = [];
        clearFilters();
      }
    }
  };

  let updateCurrentRoom = async () => {
    if (selectedRoom) {
      currentRoom = roomsInfo.find((item) => {
        return item.id === selectedRoom.value;
      });
      if (currentRoom && selectedProject /*&& !idRoom*/) {
        dynamos = [];
        selectedDynamo = null;
        dynamosInfo = [];
        currentDynamo = null;
        dynamo = null;
        keys = [];
        conflictTable = [];
        clearFilters();
        getDynamos();
      }
    }
  };

  let getItems = async () => {
    if (selectedRoom) {
      if (!loading) {
        loading = true;
        let numGreen = 0;
        let numYellow = 0;
        let numRed = 0;
        pGreen = 0;
        pYellow = 0;
        pRed = 0;
        textRed = "";
        textYellow = "";
        textGreen = "";

        let exceptions = await post(
          `select id, roomNumbering, posTableRow, posTableColumn, posTableConflict, type, description, replacingValue, typeId, attributeId, text
        from exceptions
        where spaceId=${selectedRoom.value}
        order by id desc`
        );
        //console.log(exceptions);

        let response = await post(
          `select distinct a.attribute_id as categoryId, a.Name as categoryName, e.Name as epigraphName, a.sequence
      from attributes as a
      left join epigraphs as e on a.epigraph_id=e.epigraph_id
      left join types as t on t.attribute_id=a.attribute_id
      where a.sequence!=0 and t.space_type_id=${selectedRoom.value}
      order by a.attribute_id asc`
        );

        keys = [];

        for (let i = 0; i < 10; i++) {
          let epigraphName = "";
          let epigraphIcon = "";
          let epigraphId = -1;
          switch (i) {
            case 0:
              epigraphName = "ROOM IDENTITY";
              epigraphIcon = "/images/rds-icons/white/room-identity.png";
              epigraphId = 1;
              break;
            case 1:
              epigraphName = "GENERAL REQUIREMENTS";
              epigraphIcon = "/images/rds-icons/white/general-requirements.png";
              epigraphId = 2;
              break;
            case 2:
              epigraphName = "ARCHITECTURE";
              epigraphIcon = "/images/rds-icons/white/architecture.png";
              epigraphId = 3;
              break;
            case 3:
              epigraphName = "ACCESS";
              epigraphIcon = "/images/rds-icons/white/access.png";
              epigraphId = 4;
              break;
            case 4:
              epigraphName = "AUDIOVISUAL";
              epigraphIcon = "/images/rds-icons/white/audiovisual.png";
              epigraphId = 5;
              break;
            case 5:
              epigraphName = "POWER & DATA";
              epigraphIcon = "/images/rds-icons/white/power-data.png";
              epigraphId = 6;
              break;
            case 6:
              epigraphName = "LIGHTING";
              epigraphIcon = "/images/rds-icons/white/lighting.png";
              epigraphId = 7;
              break;
            case 7:
              epigraphName = "APPLIANCES";
              epigraphIcon = "/images/rds-icons/white/appliances.png";
              epigraphId = 8;
              break;
            case 8:
              epigraphName = "FURNISHING";
              epigraphIcon = "/images/rds-icons/white/furnishing.png";
              epigraphId = 9;
              break;
            case 9:
              epigraphName = "HVAC";
              epigraphIcon = "/images/rds-icons/white/hvac.png";
              epigraphId = 10;
              break;
          }

          const categories = response.reduce((acc, curr) => {
            if (curr.epigraphName.toUpperCase() === epigraphName) {
              const category = {
                id: curr.categoryId,
                name: curr.categoryName,
                sequence: curr.sequence,
                keys: [],
                visible: true,
              };
              acc.push(category);
            }
            return acc;
          }, []);

          const key = {
            id: epigraphName,
            icon: epigraphIcon,
            epId: epigraphId,
            categories,
            visible: true,
          };

          keys.push(key);
        }

        response = await post(
          `select type_id, Name, attribute_id
          from types 
          where space_type_id=${selectedRoom.value}
          order by type_id asc`
        );

        response.forEach((type) => {
          let found = false;
          for (let i = 0; i < keys.length && !found; i++) {
            let key = keys[i];
            for (let j = 0; j < key.categories.length && !found; j++) {
              let category = key.categories[j];
              if (category.id === type.attribute_id) {
                found = true;
                const newType = {
                  id: type.type_id,
                  name: type.Name,
                  status: "",
                  visible: true,
                };
                category.keys.push(newType);
              }
            }
          }
        });

        keys = keys;

        keys = RDSAddRoomLevel(keys);

        //console.log(keys);

        conflictTable = RDSCreateConflictTable(keys, dynamo);

        conflictTable = RDSEmptyTable(dynamo, conflictTable);
        conflictTable = RDSFillTable(keys, dynamo, conflictTable);
        //console.log(conflictTable);
        //return;

        // Assigning saved exceptions
        conflictTable = RDSAddExceptions(conflictTable, exceptions);
        //console.log(conflictTable);

        conflictTable = RDSUpdateTable(keys, conflictTable);
        //console.log(conflictTable);

        // Statistics for light filters
        conflictTable.forEach((row) => {
          row.forEach((conflict) => {
            switch (conflict.status) {
              case "ok":
                numGreen++;
                break;
              case "noOk":
                numRed++;
                break;
              case "exceptions":
                numYellow++;
                break;
            }
          });
        });
        const total = numGreen + numYellow + numRed;
        if (total > 0) {
          pRed = Math.ceil((numRed / total) * 100);
          pYellow = Math.ceil((numYellow / total) * 100);
          pGreen = 100 - pYellow - pRed;
          textRed = pRed.toString();
          textYellow = pYellow.toString();
          textGreen = pGreen.toString();
        }

        conflictTable = conflictTable;
        //console.log(conflictTable);

        updateFilterEpigraph();
        updateFilterLevel();

        epigraphMap = [];
        keys.forEach((key) => {
          key.categories.forEach((category) => {
            epigraphMap.push(key.id);
            attributeMap.push(category.name);
          });
        });

        loading = false;
        idCompany_ = null;
        idVersion_ = null;
      }
    }
  };

  let onChangeTable = () => {
    conflictTable = RDSUpdateTable(keys, conflictTable);
    conflictTable = conflictTable;
    //console.log(conflictTable);
  };

  let saveException = async (
    posTableRow,
    posTableColumn,
    posTableConflict,
    exception
  ) => {
    const roomNumbering = conflictTable[posTableRow][1].value[0];
    const response = await post(
      `insert into exceptions (spaceId, typeId, attributeId, dynamoId, roomNumbering, posTableRow, posTableColumn, posTableConflict, type, description, replacingValue, text)
        values (${selectedRoom.value}, '${exception.typeId}', '${exception.attributeId}', ${currentDynamo.id}, '${roomNumbering}', ${posTableRow}, ${posTableColumn}, ${posTableConflict}, '${exception.type}', '${exception.description}', '${exception.replacingValue}', '${exception.text}')`
    );
    exception.id = response[0];
  };

  let updateException = async (exception) => {
    const response = await post(
      `update exceptions set 
        type='${exception.type}',
        description='${exception.description}',
        replacingValue='${exception.replacingValue}'
        where id=${exception.id}`
    );
  };

  let deleteException = async (id) => {
    const response = await post(`
      delete from exceptions
      where id=${id}`);
  };

  let toggleFilterLight = (color) => {
    switch (color) {
      case "red":
        filterRed = !filterRed;
        break;
      case "yellow":
        filterYellow = !filterYellow;
        break;
      case "green":
        filterGreen = !filterGreen;
        break;
    }
  };

  let toggleExpandedMenu = () => {
    expandedMenu = !expandedMenu;
  };

  let updateFilterEpigraph = () => {
    filterEpigraph = keys.map((key) => {
      return {
        label: key.id,
        value: key.id,
      };
    });
    updateFilterAttribute();
  };

  let updateFilterAttribute = () => {
    filterAttribute = [];
    keys.forEach((key) => {
      const pos = selFilterEpigraph.findIndex((fE) => {
        return fE === key.id;
      });
      if (selFilterEpigraph.length === 0 || pos >= 0) {
        key.categories.forEach((category) => {
          filterAttribute.push({
            label: category.name,
            value: category.name,
          });
        });
      }
    });
  };

  let updateFilterLevel = () => {
    filterLevel = [];
    conflictTable.forEach((row) => {
      const pos = filterLevel.findIndex((item) => {
        return item.value === row[0].value[0];
      });
      if (pos === -1) {
        filterLevel.push({
          value: row[0].value[0],
          label: row[0].value[0],
        });
      }
    });
    updateFilterRoom();
  };

  let updateFilterRoom = () => {
    filterRooms = [];
    conflictTable.forEach((row) => {
      const pos = selFilterLevel.findIndex((fL) => {
        return fL === row[0].value[0];
      });
      if (selFilterLevel.length === 0 || pos >= 0) {
        filterRooms.push({
          value: row[1].value[0],
          label: row[1].value[0],
        });
      }
    });
  };

  let printMode = (active) => {
    printView = active;
  };

  let printPDF = () => {
    let options = {};
    let content = window.$("#RDSBodyTable");
    let pdf = new jsPDF("l", "px", [content.width(), content.height()]);
    pdf.internal.scaleFactor = 1;
    pdf.addHTML(content, 15, 15, options, function () {
      pdf.save("RoomDataSheetL.pdf");
    });
  };

  let clearFilters = () => {
    selFilterEpigraph = [];
    selFilterAttribute = [];
    selFilterLevel = [];
    selFilterRooms = [];
    filterGreen = true;
    filterYellow = true;
    filterRed = true;
  };

  $: {
    updateFilterAttribute();
    if (selFilterEpigraph.length > 0) {
      let sels = [];
      selFilterEpigraph.forEach((selK) => {
        const key = keys.find((key) => {
          return key.id === selK;
        });
        if (key) {
          key.categories.forEach((category) => {
            const pos = selFilterAttribute.findIndex((selF) => {
              return category.name === selF;
            });
            if (pos >= 0) {
              sels.push(category.name);
            }
          });
        }
      });
      selFilterAttribute = sels;
    }
  }

  $: {
    updateFilterRoom();
    if (selFilterLevel.length > 0) {
      let sels = [];
      selFilterRooms.forEach((selR) => {
        const row = conflictTable.find((row) => {
          return row[1].value[0] === selR;
        });
        const pos = selFilterLevel.findIndex((selL) => {
          return row[0].value[0] === selL;
        });
        if (pos >= 0) {
          sels.push(selR);
        }
      });
      selFilterRooms = sels;
    }
  }

  $: if (idCompany || idVersion) {
    idCompany_ = idCompany;
    idVersion_ = idVersion;
    prepareRoomDataSheetL();
  }
</script>

<div class="content">
  <!-- Menu Options -->

  <div class="optionsMenu" style={printView ? "display: none" : ""}>
    {#if expandedMenu}
      <div class="optionsRow firstRow">
        <div class="optionsColumn1">
          <SubmenuIcon
            img="/images/book.svg"
            onClick={() => {
              if (selectedCompany && selectedVersion && selectedProject) {
                openURL(
                  "/roomdatasheetg/" +
                    selectedCompany.value +
                    "/" +
                    selectedVersion.value +
                    "/" +
                    selectedProject.value,
                  true
                );
              }
            }}
            onRightClick={() => {
              if (selectedCompany && selectedVersion && selectedProject) {
                openNewWindow(
                  "/skylab-main/roomdatasheetg/" +
                    selectedCompany.value +
                    "/" +
                    selectedVersion.value +
                    "/" +
                    selectedProject.value
                );
              }
            }}
          />
          <div class="submenuBox">
            <div>
              <SubmenuCombobox
                text="Company"
                options={companies}
                bind:selectedValue={selectedCompany}
                placeholder=""
                onChange={() => changeCompany()}
                backgroundColor="var(--labit-dialog-background)"
              />
            </div>
            <div>
              <SubmenuCombobox
                text="Version"
                options={versions}
                bind:selectedValue={selectedVersion}
                placeholder=""
                width="384px"
                onChange={() => changeVersion()}
                backgroundColor="var(--labit-dialog-background)"
              />
            </div>
          </div>
          <SubmenuIcon
            img="/images/factory.svg"
            onClick={() => {
              if (selectedProject) {
                openURL("/projects/" + selectedProject.value, true);
              }
            }}
            onRightClick={() => {
              if (selectedProject) {
                openNewWindow("/skylab-main/projects/" + selectedProject.value);
              }
            }}
          />
          <div class="submenuBox">
            <div>
              <SubmenuCombobox
                text="Project"
                options={projects}
                bind:selectedValue={selectedProject}
                placeholder=""
                width="384px"
                onChange={() => changeProject()}
                backgroundColor="var(--labit-dialog-background)"
              />
            </div>
          </div>
          <SubmenuButtonIcon
            img="/images/punch.svg"
            onClick={() => {
              if (currentRoom) {
                getItems();
              }
            }}
          />
        </div>
      </div>
    {/if}
    <div class="optionsRow {expandedMenu ? 'lastRow' : 'firstRow'}">
      <div class="optionsColumn1">
        <RoundedButtonIcon
          image={expandedMenu ? "/images/downup.svg" : "/images/updown.svg"}
          onClick={() => toggleExpandedMenu()}
        />
        <SubmenuComboboxIcon
          text="Programme"
          icon="/images/program.svg"
          options={programs}
          bind:selectedValue={selectedProgram}
          placeholder=""
          onChange={() => changeProgram()}
          onClickIcon={null}
        />
        <SubmenuText text={currentRoom ? currentRoom.roomName : ""} />
        <SubmenuComboboxIcon
          text="Space"
          icon="/images/space.svg"
          options={spaces}
          bind:selectedValue={selectedSpace}
          placeholder=""
          onChange={() => changeSpace()}
          onClickIcon={null}
        />
        <SubmenuComboboxIcon
          text="Type"
          icon="/images/room.svg"
          options={rooms}
          bind:selectedValue={selectedRoom}
          placeholder=""
          onChange={() => updateCurrentRoom()}
          onClickIcon={null}
        />
        <SubmenuComboboxVersion
          options={dynamos}
          bind:selectedValue={selectedDynamo}
          placeholder=""
          onChange={() => changeDynamo()}
          onClick={null}
          download={true}
          width="192px"
        />
        <SubmenuBlackButtonIcon
          text="Print View"
          icon="/images/print-white.svg"
          onClick={() => printMode(true)}
        />
      </div>
    </div>
    {#if expandedMenu}
      <div class="optionsRow lastRow">
        <div class="optionsColumn1">
          <SubmenuMultipleSelect
            text="Epigraph"
            options={filterEpigraph}
            bind:selected={selFilterEpigraph}
            width="250px"
          />
          <SubmenuMultipleSelect
            text="Attributes"
            options={filterAttribute}
            bind:selected={selFilterAttribute}
            width="250px"
          />
          <SubmenuMultipleSelect
            text="Level"
            options={filterLevel}
            bind:selected={selFilterLevel}
            width="250px"
          />
          <SubmenuMultipleSelect
            text="Rooms"
            options={filterRooms}
            bind:selected={selFilterRooms}
            width="250px"
          />
          <SubmenuTextLights
            text="Status (%)"
            onClickGreen={() => toggleFilterLight("green")}
            onClickYellow={() => toggleFilterLight("yellow")}
            onClickRed={() => toggleFilterLight("red")}
            selectedRed={filterRed}
            selectedYellow={filterYellow}
            selectedGreen={filterGreen}
            {textRed}
            {textYellow}
            {textGreen}
          />
          <SubmenuButton
            text="Clear all"
            img="/images/x.svg"
            onClick={() => clearFilters()}
          />
        </div>
      </div>
    {/if}
  </div>

  <!-- Body -->

  {#if currentRoom && keys[0]}
    <div class="RDSBody">
      <div
        class="RDSBodyTable customScrollbars"
        id="RDSBodyTable"
        style={printView
          ? "overflow-x: unset; overflow-y: unset; max-height: unset; width: unset; background-color: white;"
          : ""}
      >
        <table
          class="RDS {printView ? 'printed' : ''}"
          cellspacing="0"
          cellpadding="0"
        >
          <thead>
            <tr>
              {#each keys as epigraph}
                <RDSEpigraph
                  {epigraph}
                  {selFilterEpigraph}
                  {selFilterAttribute}
                />
              {/each}
            </tr>
            <tr>
              {#each keys as epigraph, epigraphIndex}
                {#each epigraph.categories as attribute, columnIndex}
                  <RDSAttribute
                    {attribute}
                    {epigraphIndex}
                    {columnIndex}
                    {selFilterEpigraph}
                    {selFilterAttribute}
                    {epigraph}
                  />
                {/each}
              {/each}
            </tr>
          </thead>
          <tbody>
            {#each conflictTable as row, rowIndex}
              <tr>
                {#each row as cell, columnIndex}
                  <RDSCell
                    {cell}
                    {rowIndex}
                    {columnIndex}
                    {onChangeTable}
                    onSaveException={(
                      posTableRow,
                      posTableColumn,
                      posTableConflict,
                      exception
                    ) =>
                      saveException(
                        posTableRow,
                        posTableColumn,
                        posTableConflict,
                        exception
                      )}
                    onUpdateException={(exception) =>
                      updateException(exception)}
                    onDeleteException={(id) => deleteException(id)}
                    {filterGreen}
                    {filterYellow}
                    {filterRed}
                    {selFilterEpigraph}
                    {selFilterAttribute}
                    {selFilterLevel}
                    {selFilterRooms}
                    epigraph={epigraphMap[columnIndex]}
                    attribute={attributeMap[columnIndex]}
                    level={row[0].value[0]}
                    roomNumbering={row[1].value[0]}
                    {editable}
                  />
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    </div>
  {/if}
</div>

{#if printView}
  <PrintOptions
    onClose={() => printMode(false)}
    onPrintPDF={() => printPDF()}
  />
{/if}

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin-top: 20px;
    color: var(--labit-card-gray);
    flex-direction: column;
  }

  div.optionsRow {
    display: flex;
    width: 100%;
  }

  div.optionsColumn1 {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  div.lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  div.RDSBody {
    background-color: white;
    display: flex;
    margin-top: 35px;
    width: 100%;
    border-radius: 13px;
    margin-bottom: 20px;
  }

  div.RDSBodyTable {
    background-color: white;
    display: flex;
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    max-height: 960px;
  }

  div.submenuBox {
    height: 52px;
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 13px;
    padding-right: 13px;
  }

  div.submenuBox div:not(:last-child) {
    margin-right: 13px;
  }
</style>
