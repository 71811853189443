<script>
  export let onClick;
</script>

<div class="fileBoobleFolderUploadLinkButtonContainer">
  <div class="button" on:click={onClick}>
    <img src="/images/globe-white.svg" alt="Upload Link" />
    <span>Upload Link</span>
  </div>
</div>

<style>
  div.fileBoobleFolderUploadLinkButtonContainer {
    display: flex;
    justify-content: center;
    margin: 0px 20px 0px 20px;
  }

  div.button {
    background-color: var(--labit-black-button);
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    color: white;
    font-size: 13px;
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    max-width: 180px;
    text-align: center;
  }

  div.button img {
    margin-right: 10px;
    width: 20px;
  }
</style>
