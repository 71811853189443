<script>
  export let text;
  export let selected;
  export let onClick;
</script>

<div class="analyticsRadioButtonContainer" on:click={onClick}>
  <img
    class={selected ? "" : "hiddenElement"}
    src="/images/selected.svg"
    alt="Selected Option"
  />
  <img
    class={selected ? "hiddenElement" : ""}
    src="/images/unselected.svg"
    alt="Selected Option"
  />
  <span>{text}</span>
</div>

<style>
  div.analyticsRadioButtonContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  div.analyticsRadioButtonContainer:not(:last-child) {
    margin-right: 30px;
  }

  img {
    width: 22px;
    height: 22px;
  }

  span {
    font-size: 15px;
    font-weight: bold;
    color: var(--labit-card-gray);
    margin-left: 6px;
  }
</style>
