<script>
  export let url;
  export let onRemove;
</script>

<div class="projectAnalyticsExternalUrlContainer">
  <div class="icon">
    <img src={url.icon} alt="URL Icon" />
  </div>
  <div class="span">
    <span>{url.url}</span>
  </div>
  <div class="img">
    <img
      class="fadedButton"
      src="/images/trash.svg"
      alt="Remove URL"
      on:click={onRemove}
    />
  </div>
</div>

<style>
  div.projectAnalyticsExternalUrlContainer {
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
    color: var(--labit-card-gray);
    align-items: flex-start;
  }

  div.icon {
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div.img {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  div.span {
    width: 80%;
    display: flex;
    align-items: center;
  }

  div.icon img {
    width: 20px;
  }

  div.img img {
    width: 16px;
  }

  span {
    word-break: break-word;
  }
</style>
