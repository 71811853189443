<script>
  import { onMount } from 'svelte';
  import Main from "./pages/Main.svelte";
  import Modal from './modules/me/factorial/Modal.svelte'; 
  import { absencesNotification } from './js/stores';
  import { getRole } from './js/localInfo';
  import moment from 'moment';
  import Legend from './modules/me/factorial/Legend.svelte';
  import { post } from './js/labit-connection';


  let showModal = { show: false, userName: '', start: '', end: '', description: '', absence_type: '', color: ''};
  let userRole = getRole();
  let color;

  onMount(() => {
    checkPendingAbsencesAndUpdateNotification();
    const unsubscribe = absencesNotification.subscribe(value => {
      if (value && value.show) {
        
        const startInSeconds = value.details.start / 1000;
        const endInSeconds = value.details.end / 1000;
        
      
        showModal = {
          show: value.show,
          userName: value.userName,
          start: moment.unix(startInSeconds).format("DD/MM/YYYY"),
          end: moment.unix(endInSeconds).format("DD/MM/YYYY"),
          description: value.details.description,
          absence_type: value.details.Name,
          color: value.details.color
        };
      }
    });

    return () => {
      unsubscribe(); 
    };
  });

  async function checkPendingAbsencesAndUpdateNotification() {
    const query = "SELECT COUNT(*) AS pending_absences FROM absences WHERE accepted_by_ceo = 0 AND (absence_type_id = 1 OR absence_type_id = 21)";
    const result = await post(query);
    console.log("Initial check RESULTTTT", result);

    if (result[0].pending_absences === "0") {
      absencesNotification.set(false);
    } else {
      absencesNotification.set(true);
    }
  }

  function closeModal() {
    showModal = {show: false, userName: '', start: '', end: '', description: '', absence_type: '', color: ''};
    absencesNotification.set({show: false, userName: '', start: '', end: '', description: '', absence_type: '', color: ''});
  }

  $: isAdmin = userRole === 'Admin';

</script>

{#if showModal.show && isAdmin}
  <Modal isOpen={showModal.show} close={closeModal}>
    <h4 slot="header">{showModal.userName}</h4>
    <div class="legend">
      <Legend text={showModal.absence_type} color={showModal.color}></Legend>
    </div>
    <div class="time">
      <p><span>Start :   </span>{showModal.start}</p>
      <p><span>End :   </span>{showModal.end}</p>
    </div>
    <p><span>Description :   </span>{showModal.description}</p>
  </Modal>
{/if}


<Main />

<style>

span{
  text-transform: uppercase;
  font-weight: bolder;
}

.time{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
}

.legend{
  display: flex;
  padding-bottom: 5px;
}

h4{
  font-size: 16px;
}

</style>
