<script>
  export let onClose;
  export let onPrintPDF;

  import SubmenuBlackButtonIcon from "../components/SubmenuBlackButtonIcon.svelte";
</script>

<div class="printOptionsContainer">
  <div>
    <SubmenuBlackButtonIcon text="Close" icon={null} onClick={onClose} />
  </div>
  <div>
    <SubmenuBlackButtonIcon
      text="Print PDF"
      icon="/images/pdf-white.svg"
      onClick={onPrintPDF}
    />
  </div>
</div>

<style>
  div.printOptionsContainer {
    display: flex;
    margin-top: 29px;
    margin-bottom: 29px;
    justify-content: center;
  }

  div.printOptionsContainer div:not(:last-child) {
    margin-right: 19px;
  }
</style>
