<script>
    import ClickOutside from 'svelte-click-outside';

    export let defaultText = "Select option...";
    export let selectedOption = '';
    export let options = [];
    export let error = false;

    let display = false;
    let button;

    let index = -1;
    const select = (id) => {
        selectedOption = id;
        display = false;
        index = options.findIndex((e) => selectedOption === e.id);
    };

    $: if (selectedOption !== '') select(selectedOption);

</script>

<div class="dropdown" on:blur={ () => display = false }>
    <button bind:this={button} class="dropbtn {error ? 'error' : ''}" on:click={ () => display = !display }>
        {options[index] === undefined ? defaultText : options[index].value}
        <img src="/images/down_arrow.svg" alt="More options">
    </button>
    
    <ClickOutside on:clickoutside={ () => display = false } exclude={[button]}>
    <div class="dropdown-content {display ? 'd-grid' : 'd-none'}">
        {#each options as {id, value, type}}
        <button class={type} on:click={ () => select(id) }>{value}</button>
        {/each}
    </div>
    </ClickOutside>
</div>

<style>
    .error {
        border-color: red !important;
    }

    .dropbtn {
        background-color: white;
        font-size: 16px;
        margin: 0 !important;
        border: 1px solid #e7e7e7;
        border-radius: 16px;
        padding: 4px 32px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
        white-space: nowrap;
        display: inline-block;
        width: 100%;
    }

    .dropbtn img {
        height: 10px;
        position: absolute;
        right: 12px;
        top: 10px;
    }

    .dropdown {
        position: relative;
        display: inline-block;
        width: 220px;
    }

    .dropdown-content {
        position: absolute;
        width: 100%;
        border-radius: 16px;
        margin-top: 4px;
        border: 1px solid #e7e7e7;
        background-color: white;
        z-index: 1;
        max-height: 500px;
        overflow-y: auto;
    }

    .dropdown-content button {
        padding: 12px 16px;
        border-radius: 16px;
        cursor: pointer;
        margin: 0;
        background-color: transparent;
        border: none;
    }

    .dropdown-content button:hover, .dropdown-content button:focus, .dropdown:hover .dropbtn, .dropdown:focus .dropbtn, .dropdown:focus , .dropbtn:focus {
        background-color: #f7f7f7;
    }

    .d-grid {
        display: grid;
    }

    .d-none {
        display: none;
    }

    .emoji {
        font-size: 32px;
        text-align: center;
    }
</style>
