<script>
    import moment from "moment";
    import flatpickr from "flatpickr";
    import { onMount, createEventDispatcher } from "svelte";

    // HIPER IMPORTANTE, NO BORRAR
    import '../../../../node_modules/flatpickr/dist/flatpickr.min.css'; // HIPER IMPORTANTE, NO BORRAR
    // HIPER IMPORTANTE, NO BORRAR

    export let half;
    export let start = 0;
    export let end = 0;
    export let error = false;
    export let events = [];
    export let disableBeforeToday = true;
    export let returnFormat = "millis";

    const dispatch = createEventDispatcher();

    let calendarRange;

    let data = null;

    if (returnFormat === "moment") {
        data = [start.toDate(), end.toDate()];
    } else {
        if (start !== 0 && end !== 0) {
            data = [new Date(start), new Date(end)];
        } else if (start !== 0) {
            data = new Date(start);
        } else if (end !== 0) {
            data = new Date(end);
        }
    }

    onMount(() => {
        events = events.map((e) => {
            return { from: e.start, to: e.end };
        });

        if (disableBeforeToday) {
            const disableDaysBeforeNow = (date) => {
                return date < new Date();
            };
            events.push(disableDaysBeforeNow);
        }

        //const disableWeekends = (date) => { return (date.getDay() === 0 || date.getDay() === 6); };
        //events.push(disableWeekends);

        flatpickr(calendarRange, {
            mode: half ? "single" : "range",
            locale: { firstDayOfWeek: 1 },
            defaultDate: data,
            allowInvalidPreload: true,
            //dateFormat: 'j/n/Y',
            disable: events,
            onClose: (selectedDates) => {
                if (returnFormat === "millis") {
                    start = `${flatpickr.formatDate(selectedDates[0], "U")}000`;
                    if (selectedDates.length === 2) {
                        end = moment(
                            `${flatpickr.formatDate(selectedDates[1], "U")}000`,
                            "x"
                        )
                        .endOf("day")
                        .format("x");
                    }
                } else if (returnFormat === "moment") {
                    start = moment(selectedDates[0]);
                    end = moment(selectedDates[1]);
                }

                dispatch('close', { start: start, end: end });
            },
        });
    });
</script>

<input class:error bind:this={calendarRange} id="calendar-range" class="flatpickr flatpickr-input btn noselect" type="text"
    placeholder={half ? "Select date" : 'Select dates...'}
    data-id="datetime"
    readonly="readonly"
/>

<style>
    input#calendar-range {
        width: 200px;
        border: 1px solid #e7e7e7;
        border-radius: 40px;
    }

    .error {
        border: 1px solid red !important;
        border-radius: 8px !important;
    }
</style>
