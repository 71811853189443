<script>
  export let pic;
  export let name;
  export let role;
  export let categories;
  export let city;
  export let country;
  export let telephone;
  export let labit;
  export let active;
  export let onClick;

  import { randomString } from "../js/randomString";
</script>

<div class="companyCard {active ? '' : 'noActive'}">
  <div class="cardContainer">
    <div class="presentation" on:click={onClick}>
      <img
        class="companyPic"
        src={pic === ""
          ? "/images/default-company.png"
          : pic + "?r=" + randomString(10)}
        alt="Company Pic"
      />
      <div>
        <span class="name">{name}</span>
        <span>{role}</span>
      </div>
      <img class="opcionesDots" src="/images/card-out.svg" alt="Opciones" />
    </div>
    <div class="info">
      <div class="row">
        <div class="column title">
          {#each categories as category, index}
            <span>{category.name}</span>
            {#if index !== categories.length - 1}
              <span>,&nbsp;</span>
            {/if}
          {/each}
        </div>
        <div class="column title">
          <span />
        </div>
      </div>
      <div class="row">
        <div class="column">
          <span>Address</span>
        </div>
        <div class="column">
          <span />
        </div>
      </div>
      <div class="row">
        <div class="column">
          <span>{city}</span>
          <span class="country">{country}</span>
        </div>
        <div class="column">
          <span />
        </div>
      </div>
      <div class="row">
        <div class="column">
          <span>{telephone}</span>
        </div>
        <div class="column">
          <span />
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  div.companyCard {
    width: 100%;
    height: 100%;
    background-color: var(--labit-dialog-background);
    border-radius: 4%/8%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 25px;
  }

  div.noActive {
    background-color: var(--labit-light-gray);
  }

  div.cardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  img.opcionesDots {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 12px;
    opacity: 1;
  }

  div.presentation {
    width: 100%;
    height: 42%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  div.presentation div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img.companyPic {
    max-width: 134px;
    max-height: 67px;
    margin-right: 6%;
  }

  div.presentation span {
    font-size: 11px;
    color: var(--labit-card-lightgray);
  }

  div.presentation span.name {
    font-size: 19px;
    font-weight: bold;
    color: var(--labit-card-gray);
    margin-bottom: 2%;
  }

  div.info {
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: column;
    font-size: 13px;
  }

  div.row {
    width: 100%;
    height: 25%;
    display: flex;
  }

  div.column {
    width: 50%;
    height: 100%;
    font-size: 11px;
    color: var(--labit-card-lightgray);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div.title {
    font-size: 13px;
    color: var(--labit-card-gray);
  }

  span.country {
    margin-left: 7%;
  }

  /* Animations */

  img.opcionesDots {
    transition: opacity 0.5s linear 0s;
  }

  img.opcionesDots:hover {
    opacity: 0.5;
  }
</style>
