<script>
  import Menu from "../components/Menu.svelte";
  import Combobox from "../components/Combobox.svelte";
  import PeopleDocumentsContact from "../components/PeopleDocumentsContact.svelte";
  import FileBoobleContainer from "../components/FileBoobleContainer.svelte";
  import PreviewRemoteFile from "../components/PreviewRemoteFile.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  import ContextMenu from "../components/ContextMenu.svelte";

  import { post } from "../js/labit-connection";
  import { getPlatform } from "../js/localInfo";
  import { randomString } from "../js/randomString";

  import { onMount } from "svelte";

  const PAGE = "ANALYTICS";

  const platform = getPlatform();

  let documentTypeOptions = [
    {
      value: "Images",
      label: "Images",
    },
    {
      value: "Submissions",
      label: "Submissions",
    },
    {
      value: "Reports",
      label: "Reports",
    },
    {
      value: "Models",
      label: "Models",
    },
  ];
  let documentTypeOptionsSel = documentTypeOptions[0];

  let projects = [];
  let projectSel = -1;
  let projectSelPath = "";
  let showAllProjects = false;

  let remoteFilePrev = "";

  //$: console.log(projectSelPath);

  let showNavMenu = null;

  onMount(async () => {
    const response = await post(
      `select project_id, Name, Active
        from projects
        order by Name asc`
    );
    projects = response.map((item) => {
      return {
        id: item.project_id,
        name: item.Name,
        active: item.Active === "1",
        visible: item.Active === "1",
      };
    });
    projectSel = 0;
    selectProject(0);
  });

  let toggleShowAllProjects = () => {
    showAllProjects = !showAllProjects;
    projects.forEach((p) => {
      if (showAllProjects) {
        p.visible = true;
      } else {
        p.visible = p.active;
      }
    });
    projects = projects;
    projectSel = -1;
    projectSelPath = "";
  };

  let createPath = (index) => {
    let path = "";
    if (index >= 0) {
      let p = "";
      switch (documentTypeOptionsSel.value) {
        case "Images":
          p = "images";
          break;
        case "Submissions":
          p = "submissions";
          break;
        case "Reports":
          p = "reports";
          break;
        case "Models":
          p = "models";
          break;
      }
      path =
        "/projects/" +
        projects[index].id +
        " - " +
        projects[index].name +
        "/" +
        p;
    }
    return path;
  };

  let selectProject = (index) => {
    projectSel = index;
    projectSelPath = createPath(index);
  };

  let previewFile = (id) => {
    remoteFilePrev = id.toLowerCase().trim().startsWith("http")
      ? id
      : btoa(unescape(encodeURIComponent(id)));
  };
</script>

<div class="content">
  <!-- Body -->
  <div class="container">
    <div class="leftMenu">
      <div class="leftMenuOption">
        <div class="form">
          <span>Document Type</span>
          <div class="combobox">
            <Combobox
              options={documentTypeOptions}
              placeholder=""
              bind:selectedValue={documentTypeOptionsSel}
              searchable={false}
              onChange={() => selectProject(projectSel)}
            />
          </div>
        </div>
      </div>
      <div class="leftMenuOption people">
        <div class="form title"><span>Projects</span></div>
        <div class="form">
          <div class="checkbox" on:click={() => toggleShowAllProjects()}>
            <img
              src={showAllProjects
                ? "/images/checkbox_empty.svg"
                : "/images/checkbox.svg"}
              alt="Show all projects"
            />
            <span>Show only active projects</span>
          </div>
        </div>
        <div class="form listPeople customScrollbars">
          {#each projects as p, index}
            {#if p.visible}
              <PeopleDocumentsContact
                contact={p}
                selected={index === projectSel}
                onClick={() => selectProject(index)}
              />
            {/if}
          {/each}
        </div>
      </div>
    </div>
    <div class="rightMenu">
      {#if projectSel >= 0}
        <FileBoobleContainer
          title={projects[projectSel].name +
            " - " +
            documentTypeOptionsSel.value}
          url={projectSelPath}
          onPreviewFile={(id) => previewFile(id)}
        />
      {/if}
      {#if remoteFilePrev !== "" && remoteFilePrev
          .toLowerCase()
          .trim()
          .startsWith("http")}
        <div class="iframe iframeFile">
          <img
            class="fadedButton"
            src="/images/x.svg"
            alt="Close"
            on:click={() => {
              remoteFilePrev = "";
            }}
          />
          <WebBrowser
            type={platform === "Web" ? "iframe" : "webview"}
            iframeTitle="External URL"
            id=ProjectsDocumentsWebBrowser
            src={remoteFilePrev}
          />
        </div>
      {:else}
        <PreviewRemoteFile bind:fileUrl={remoteFilePrev} />
      {/if}
    </div>
  </div>
</div>

<!--
{#if platform === "APP" && remoteFilePrev !== "" && remoteFilePrev
    .toLowerCase()
    .trim()
    .startsWith("http")}
  <ContextMenu currentURL={remoteFilePrev} bind:showMenu={showNavMenu} />
{/if}
-->

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  div.leftMenu {
    width: 30%;
    display: flex;
    flex-direction: column;
  }

  div.leftMenuOption {
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
  }

  div.leftMenuOption:not(:first-child) {
    margin-top: 20px;
  }

  div.rightMenu {
    width: 67%;
  }

  div.form {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  div.form:not(:first-child) {
    margin-top: 15px;
  }

  div.form span {
    margin-bottom: 5px;
  }

  div.combobox {
    width: 100%;
    height: 25px;
  }

  div.checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  div.checkbox img {
    width: 17px;
  }

  div.checkbox span {
    margin-bottom: 0px !important;
    margin-left: 7px;
  }

  div.title {
    font-size: 15px;
    font-weight: bold;
  }

  div.people {
    height: 640px;
  }

  div.listPeople {
    max-height: 520px;
    overflow-y: scroll;
  }

  div.iframeFile {
    height: 800px;
    background-color: var(--labit-dialog-background);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    margin-top: 20px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  div.iframeFile img {
    width: 9px;
    height: 9px;
    margin: 0px 20px 10px 0px;
  }
</style>
