<script>
  export let oldVersionNotification;

  //import Menu from "../components/Menu.svelte";
  import MenuToolClouds from "../components/MenuToolClouds.svelte";
  import FormTextEdit from "../components/FormTextEdit.svelte";
  import Button from "../components/Button.svelte";
  import FileUploader from "../components/FileUploader.svelte";
  import SkylabVersion from "../components/SkylabVersion.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  //import ContextMenu from "../components/ContextMenu.svelte";
  import FileBoobleContainer from "../components/FileBoobleContainer.svelte";
  import PreviewRemoteFile from "../components/PreviewRemoteFile.svelte";

  import {
    getUserId,
    getRole,
    getPlatform,
    getOpenTool,
  } from "../js/localInfo";
  import { copyObject } from "../js/copyObject";
  import { post, rpost } from "../js/labit-connection";
  import { openNewWindow } from "../js/openNewWindow";
  import { BIM360_URL, SHAREPOINT_URL } from "../js/globalExternalURLs";

  import { onMount } from "svelte";
  import { randomString } from "../js/randomString";

  const PAGE = "PROCESS";

  const CLOUDS_REMOTE_URL = "/clouds";

  let managingToolsSharepoint = false;
  let managingToolsBIM360 = false;
  let selectedTool = null;
  let formAction = "new"; // new, edit
  let currentTool = "";
  let awsSel = false;
  let librariesSel = false;

  const myId = getUserId();
  const myRole = getRole();
  let myTools = [];
  const platform = getPlatform();

  let toolbarShown = true;
  let showNavMenu = null;

  let remoteFilePrev = "";

  let previewFile = (id) => {
    remoteFilePrev = id.toLowerCase().trim().startsWith("http")
      ? id
      : btoa(unescape(encodeURIComponent(id)));
    window.$("html, body").animate(
      {
        scrollTop:
          window.$("#files").offset().top +
          window.$("#files").outerHeight() -
          80,
      },
      500
    );
  };

  onMount(async () => {
    currentTool = getOpenTool();

    let response = await post(
      `select id, name, url, icon, type
          from clouds`
    );

    const myFixedTools = response.map((tool) => {
      return {
        id: tool.id,
        name: tool.name,
        url: tool.url,
        icon: tool.icon,
        type: tool.type,
        visible: false,
        custom: false,
      };
    });

    response = await post(
      `select id, name, url, icon, type
          from clouds_custom
          where contact=${myId}`
    );

    const myCustomTools = response.map((tool) => {
      return {
        id: tool.id,
        name: tool.name,
        url: tool.url,
        icon: tool.icon,
        type: tool.type,
        visible: false,
        custom: true,
      };
    });

    myTools = myFixedTools.concat(myCustomTools);
    console.log(myTools);
  });

  let showToolForm = (show, type) => {
    if (type === "sharepoint") {
      managingToolsSharepoint = show;
      managingToolsBIM360 = false;
    } else {
      managingToolsBIM360 = show;
      managingToolsSharepoint = false;
    }
    if (show) {
      formAction = "new";
      selectedTool = {
        id: -1,
        name: "",
        url: "",
        icon: "",
        type: type,
        custom: true,
        image: null,
      };
    }
  };

  let toggleToolForm = (type) => {
    if (type === "sharepoint") {
      if (managingToolsSharepoint) {
        showToolForm(false, type);
      } else {
        showToolForm(true, type);
      }
    } else {
      if (managingToolsBIM360) {
        showToolForm(false, type);
      } else {
        showToolForm(true, type);
      }
    }
  };

  let clickTool = (tool, forceNewWindow) => {
    awsSel = false;
    librariesSel = false;
    if (platform === "Web") {
      window.open(tool.url);
    } else {
      if (forceNewWindow) {
        openNewWindow(tool.url);
      } else {
        tool.visible;
        tool = tool;
        currentTool = tool.url;
      }
    }
  };

  let clickLink = (url, forceNewWindow) => {
    awsSel = false;
    librariesSel = false;
    if (platform === "Web") {
      window.open(url);
    } else {
      if (forceNewWindow) {
        openNewWindow(url);
      } else {
        currentTool = url;
      }
    }
  };

  let addTool = async (type) => {
    if (
      selectedTool.name !== "" &&
      selectedTool.url !== "" &&
      selectedTool.image
    ) {
      const response = await post(
        `insert into clouds_custom (
          name, 
          url, 
          type, 
          contact
        )values(
          '${selectedTool.name}',
          '${selectedTool.url}',
          '${type}',
          ${myId}
            )`
      );
      selectedTool.id = response[0];
      selectedTool.type = type;

      if (selectedTool.image) {
        const fileName = selectedTool.id + "." + selectedTool.image.extension;
        const fullPath = CLOUDS_REMOTE_URL + "/" + fileName;
        await rpost("UploadPublicFile", {
          path: CLOUDS_REMOTE_URL,
          fileName,
          fileData: selectedTool.image.data,
        });

        await post(
          `update clouds_custom
            set icon='${fullPath}'
            where id=${selectedTool.id}`
        );

        selectedTool.icon = fullPath;
      }

      myTools.push(selectedTool);
      myTools = myTools;
      showToolForm(false);
    }
  };

  let clickEditTool = (tool) => {
    formAction = "edit";
    selectedTool = copyObject(tool);
  };

  let editTool = async () => {
    if (
      selectedTool.name !== "" &&
      selectedTool.url !== "" &&
      selectedTool.image
    ) {
      await post(
        `update clouds_custom set
          name='${selectedTool.name}',
          url='${selectedTool.url}', 
          where id=${selectedTool.id}`
      );
      const tool = myTools.find((t) => {
        return t.id === selectedTool.id && t.custom;
      });
      if (tool) {
        tool.name = selectedTool.name;
        tool.url = selectedTool.url;
        tool.type = selectedTool.type;
        myTools = myTools;
        showToolForm(false);
      }

      const fileName = selectedTool.id + "." + selectedTool.image.extension;
      const fullPath = CLOUDS_REMOTE_URL + "/" + fileName;
      await rpost("UploadPublicFile", {
        path: CLOUDS_REMOTE_URL,
        fileName,
        fileData: selectedTool.image.data,
      });

      await post(
        `update clouds_custom
            set icon='${fullPath}'
            where id=${selectedTool.id}`
      );

      selectedTool.icon = fullPath;

      const pos = myTools.findIndex((tool) => {
        return tool.id === selectedTool.id && tool.custom;
      });
      if (pos >= 0) {
        myTools[pos] = copyObject(selectedTool);
      }
      myTools = myTools;
      showToolForm(false);
    }
  };

  let removeTool = async (index) => {
    const tool = myTools[index];
    await post(
      `delete from clouds_custom
        where id=${tool.id}`
    );
    myTools.splice(index, 1);
    myTools = myTools;
  };

  let clickAWS = () => {
    awsSel = true;
    librariesSel = false;
  };

  let clickLibraries = () => {
    awsSel = false;
    librariesSel = true;
  };

  let closeMenu = () => {
    if (toolbarShown && !managingToolsSharepoint && !managingToolsBIM360) {
      window.$("div.toolBar").animate(
        {
          height: "0px",
        },
        500,
        () => {
          toolbarShown = false;
        }
      );
      window.$("div.aws").animate(
        {
          height: "0px",
        },
        500,
        () => {
          toolbarShown = false;
        }
      );
    }
  };

  let openMenu = () => {
    window.$("div.toolBar").animate(
      {
        height: "85px",
      },
      500,
      () => {
        toolbarShown = true;
      }
    );
    window.$("div.aws").animate(
      {
        height: "85px",
      },
      500,
      () => {
        toolbarShown = true;
      }
    );
  };
</script>

<div class="content">
  <div class="container {librariesSel ? '' : 'noLibs'}">
    <div class="tools">
      <div class="toolbars animToolbar">
        <!-- Sharepoint -->

        <div
          class="toolbarObject"
          on:contextmenu={(ev) => {
            ev.stopPropagation();
          }}
        >
          <div class="toolBar">
            <div
              class="titleBar"
              on:click={() => clickLink(SHAREPOINT_URL, false)}
              on:contextmenu={(ev) => {
                clickLink(SHAREPOINT_URL, true);
                ev.stopPropagation();
              }}
            >
              <img src="/images/clouds/sharepoint.svg" alt="Sharepoint Tools" />
            </div>
            <div class="toolIcons customScrollbars">
              {#each myTools as tool, index}
                {#if tool.type === "sharepoint"}
                  <MenuToolClouds
                    {tool}
                    onClick={(tool, forceNewWindow) =>
                      clickTool(tool, forceNewWindow)}
                    editMode={managingToolsSharepoint}
                    onRemove={(ev) => {
                      ev.stopPropagation();
                      removeTool(index);
                    }}
                    onClickEdit={(tool) => clickEditTool(tool)}
                  />
                {/if}
              {/each}
            </div>
            <img
              class="add"
              src="/images/plus.svg"
              alt="Add Tool"
              on:click={() => toggleToolForm("sharepoint")}
            />
          </div>
          {#if selectedTool && managingToolsSharepoint}
            <div class="toolForm">
              <div class="x">
                <img
                  src="/images/x.svg"
                  alt="Close Form"
                  on:click={() => showToolForm(false, "sharepoint")}
                />
              </div>
              <div class="formElement">
                <div class="pic">
                  <FileUploader
                    bind:fileInfo={selectedTool.image}
                    loadFromPath={selectedTool.icon}
                    picExtraStyle="border-radius: 10px;"
                  />
                </div>
              </div>
              <div class="formElement">
                <span>Name</span>
                <div class="formLine">
                  <FormTextEdit placeholder="" bind:value={selectedTool.name} />
                </div>
              </div>
              <div class="formElement">
                <span>URL</span>
                <div class="formLine">
                  <FormTextEdit
                    placeholder=""
                    maxlength={500}
                    bind:value={selectedTool.url}
                  />
                </div>
              </div>
              <div class="formElement space">
                <div class="formButton">
                  <Button
                    text="Save"
                    onClick={() => {
                      if (formAction === "new") {
                        addTool("sharepoint");
                      } else {
                        editTool();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          {/if}
        </div>

        <!-- BIM 360 -->

        <div
          class="toolbarObject"
          on:contextmenu={(ev) => {
            ev.stopPropagation();
          }}
        >
          <div class="toolBar">
            <div
              class="titleBar"
              on:click={() => clickLink(BIM360_URL, false)}
              on:contextmenu={(ev) => {
                clickLink(BIM360_URL, true);
                ev.stopPropagation();
              }}
            >
              <img src="/images/clouds/bim360.svg" alt="BIM360 Tools" />
            </div>
            <div class="toolIcons customScrollbars">
              {#each myTools as tool, index}
                {#if tool.type === "bim360"}
                  <MenuToolClouds
                    {tool}
                    onClick={(tool, forceNewWindow) =>
                      clickTool(tool, forceNewWindow)}
                    editMode={managingToolsBIM360}
                    onRemove={(ev) => {
                      ev.stopPropagation();
                      removeTool(index);
                    }}
                    onClickEdit={(tool) => clickEditTool(tool)}
                  />
                {/if}
              {/each}
            </div>
            <img
              class="add"
              src="/images/plus.svg"
              alt="Add Tool"
              on:click={() => toggleToolForm("bim360")}
            />
          </div>
          {#if selectedTool && managingToolsBIM360}
            <div class="toolForm">
              <div class="x">
                <img
                  src="/images/x.svg"
                  alt="Close Form"
                  on:click={() => showToolForm(false, "bim360")}
                />
              </div>
              <div class="formElement">
                <div class="pic">
                  <FileUploader
                    bind:fileInfo={selectedTool.image}
                    loadFromPath={selectedTool.icon}
                    picExtraStyle="border-radius: 10px;"
                  />
                </div>
              </div>
              <div class="formElement">
                <span>Name</span>
                <div class="formLine">
                  <FormTextEdit placeholder="" bind:value={selectedTool.name} />
                </div>
              </div>
              <div class="formElement">
                <span>URL</span>
                <div class="formLine">
                  <FormTextEdit
                    placeholder=""
                    maxlength={500}
                    bind:value={selectedTool.url}
                  />
                </div>
              </div>
              <div class="formElement space">
                <div class="formButton">
                  <Button
                    text="Save"
                    onClick={() => {
                      if (formAction === "new") {
                        addTool("bim360");
                      } else {
                        editTool();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          {/if}
        </div>

        <!-- Libraries -->

        <div
          class="aws"
          on:click={() => clickLibraries()}
          on:contextmenu={(ev) => {
            ev.stopPropagation();
          }}
        >
          <div class="awsInner">
            <img src="/images/payrolls.svg" alt="Logo" />
          </div>
        </div>

        <!-- AWS -->

        <div
          class="aws"
          on:click={() => clickAWS()}
          on:contextmenu={(ev) => {
            ev.stopPropagation();
          }}
        >
          <div class="awsInner"><img src="/images/app.svg" alt="Logo" /></div>
          {#if oldVersionNotification}
            <div class="notification" />
          {/if}
        </div>
      </div>
    </div>
    <div class="awsContainer {awsSel ? '' : 'hiddenElement'}">
      {#if oldVersionNotification}
        <div class="notificationAdvice">
          <span
            >There is a new Skylab version available! Please download it to
            ensure compatibility.</span
          >
        </div>
      {/if}
      <div class="platformsContainer">
        <SkylabVersion platform="windows" />
        <SkylabVersion platform="mac" />
      </div>
    </div>
    <div class="libContainer {librariesSel ? '' : 'hiddenElement'}">
      <div id="files" class="files">
        <FileBoobleContainer
          title="Labit Templates"
          url="/templates/labit-templates"
          onPreviewFile={(id) => previewFile(id)}
          readOnly={myRole !== "Admin"}
        />
      </div>
      {#if remoteFilePrev !== "" && remoteFilePrev
          .toLowerCase()
          .trim()
          .startsWith("http")}
        <div class="iframe iframeFile">
          <img
            class="fadedButton"
            src="/images/x.svg"
            alt="Close"
            on:click={() => {
              remoteFilePrev = "";
            }}
          />
          <WebBrowser
            type={platform === "Web" ? "iframe" : "webview"}
            iframeTitle="External URL"
            id={"remoteFileWebBrowser"}
            src={remoteFilePrev}
          />
        </div>
      {:else}
        <PreviewRemoteFile bind:fileUrl={remoteFilePrev} />
      {/if}
    </div>
    {#each myTools as tool, index}
      <div
        class="iframe {tool.url === currentTool &&
        currentTool !== '' &&
        !awsSel &&
        !librariesSel
          ? ''
          : 'hiddenElement'}"
      >
        <WebBrowser
          type={platform === "Web" && tool.url !== "" ? "iframe" : "webview"}
          iframeTitle="External Tool"
          useragent={tool.url.includes("hubspot.es") ||
          tool.url.includes("hubspot.com")
            ? "Custom"
            : "Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"}
          id={`hubspotWebBrowser${index}`}
          src={tool.url}
        />
      </div>
    {/each}
    <div
      class="iframe {BIM360_URL === currentTool && !awsSel && !librariesSel
        ? ''
        : 'hiddenElement'}"
    >
      <WebBrowser
        type={platform === "Web" && BIM360_URL !== "" ? "iframe" : "webview"}
        iframeTitle="External Tool"
        useragent="Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"
        id="bim360WebBrowser"
        src={BIM360_URL}
      />
    </div>
    <div
      class="iframe {SHAREPOINT_URL === currentTool && !awsSel && !librariesSel
        ? ''
        : 'hiddenElement'}"
    >
      <WebBrowser
        type={platform === "Web" && SHAREPOINT_URL !== ""
          ? "iframe"
          : "webview"}
        iframeTitle="External Tool"
        useragent="Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"
        id="sharepointWebBrowser"
        src={SHAREPOINT_URL}
      />
    </div>
  </div>
</div>

<!--
{#if visible}
  <ContextMenu currentURL={currentTool} bind:showMenu={showNavMenu} />
{/if}
-->
<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  div.noLibs {
    height: 100%;
  }

  div.tools {
    width: 100%;
    min-height: 14px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 14px;
  }

  div.toolbars {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  div.toolbarObject {
    width: 43%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  div.toolBar {
    width: 100%;
    height: 85px;
    background-color: var(--labit-dialog-background);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 6px;
    padding-right: 33px;
  }

  img.add {
    height: 45%;
    cursor: pointer;
    margin-left: 38px;
  }

  div.toolForm {
    background-color: var(--labit-dialog-background);
    width: 60%;
    padding: 14px;
    border-radius: 10px;
    margin-top: 14px;
  }

  div.toolForm span {
    font-size: 13px;
    color: var(--labit-card-gray);
  }

  div.formElement {
    margin-bottom: 6px;
  }

  div.formLine {
    height: 23px;
  }

  div.formButton {
    width: 77px;
    height: 29px;
  }

  div.space {
    margin-top: 19px;
  }

  div.x {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  div.x img {
    width: 12px;
    cursor: pointer;
  }

  div.iframe {
    flex-grow: 1;
    width: 100%;
    border-radius: 19px;
    padding-bottom: 20px;
  }

  div.pic {
    width: 120px;
    height: 120px;
  }

  div.titleBar {
    height: 85%;
    background-color: white;
    border-radius: 6px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    margin-right: 38px;
    cursor: pointer;
  }

  div.titleBar img {
    height: 65%;
  }

  div.toolIcons {
    height: 100%;
    width: 100%;
    display: flex;
    overflow-x: auto;
    align-items: center;
  }

  div.aws {
    width: 6%;
    height: 85px;
    background-color: var(--labit-dialog-background);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  div.awsInner {
    width: 85%;
    height: 85%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    margin: 6px;
  }

  div.awsInner img {
    height: 65%;
  }

  div.awsContainer {
    background-color: var(--labit-dialog-background);
    width: 100%;
    flex: 1;
    margin-bottom: 20px;
    margin-top: 14px;
    border-radius: 19px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.libContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  div.files {
    width: 100%;
  }

  div.notification {
    width: 14px;
    height: 14px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    right: 11px;
    top: 12px;
  }

  div.notificationAdvice {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: var(--labit-card-gray);
    margin-bottom: 60px;
  }

  div.platformsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 20%;
    padding-right: 20%;
  }

  div.iframeFile {
    height: 800px;
    background-color: var(--labit-dialog-background);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    margin-top: 20px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  div.iframeFile img {
    width: 9px;
    height: 9px;
    margin: 0px 20px 10px 0px;
  }

  /* Animations */

  img.add {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  img.add:hover {
    opacity: 0.5;
  }

  div.titleBar {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.titleBar:hover {
    opacity: 0.5;
  }

  div.awsInner {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.awsInner:hover {
    opacity: 0.5;
  }
</style>
