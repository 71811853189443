<script>
  import Grid from "svelte-grid";
  import gridHelp from "svelte-grid/build/helper/index.mjs";

  import { onMount, onDestroy } from "svelte";

  import OutlookEmails from "../components/dashboard/outlook/OutlookEmails.svelte";
  import OutlookCalendar from "../components/dashboard/outlook/OutlookCalendar.svelte";
  import PicOfTheDay from "../modules/tools/screens/components/PicOfTheDay.svelte";
  import WeatherBox from "../modules/tools/screens/components/WeatherBox.svelte";
  import AllPeopleTasks from "../modules/pdashboard/screens/components/AllPeopleTasks.svelte";
  import WebBrowser from "../components/WebBrowser.svelte";
  import MonthlyCalendar from "../modules/calendar/screens/components/MonthlyCalendar.svelte";
  import { getOutlookEmail, getPlatform } from "../js/localInfo";
  import { myPersonalPlanner, dashboardItemGrid } from "../js/stores";

  import {
    getAllMyTasks,
    getAllMyPersonalTasks,
    getMyPersonalPlanner,
  } from "../components/dashboard/outlook/outlook.js";

  import DashboardAbsences from "../components/dashboard/DashboardAbsences.svelte";
  import TasksBox from "../modules/pdashboard/screens/components/TasksBox.svelte";

  import CompleteResourcePlanner from "../components/CompleteResourcePlanner/CompleteResourcePlanner.svelte";

  import { openNewWindow } from "../js/openNewWindow";
  
  const userName = getOutlookEmail();
  const platform = getPlatform();

  const { item } = gridHelp;
  let closeTool = () => { showTool = ""; };
  
  let showTool = "";
  let tasksObj = { todo: [], inProgress: [], done: [] };
  let personalTasksObj = { todo: [], inProgress: [], done: [], link: "" };

  const clickTool = (url, forceNewWindow) => {
    if (platform === "Web") {
      openNewWindow(url);
    } else {
      if (forceNewWindow) {
        openNewWindow(url);
      } else {
        showTool = url;
      }
    }
  };  

  const newWindow = (ev, url) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (url !== undefined) openNewWindow(url);
  };

  let defaultItemsGrid = [
    {
      id: 0,
      6: item({ x: 0, y: 0, w: 1, h: 1, customResizer: true, customDragger: true }),
      component: WeatherBox,
      props: {},
    },
    {
      id: 1,
      6: item({ x: 1, y: 0, w: 1, h: 1, customResizer: true, customDragger: true }),
      component: MonthlyCalendar,
      props: { id: "calendarDashboard", dashboard: true },
    },
    {
      id: 2,
      6: item({ x: 2, y: 0, w: 1, h: 1, customResizer: true, customDragger: true }),
      component: OutlookEmails,
      props: { visible: true },
    },
    {
      id: 3,
      6: item({ x: 3, y: 0, w: 1, h: 1, customResizer: true, customDragger: true }),
      component: OutlookCalendar,
      props: { visible: true, isGeneralEvent: false },
    },
    {
      id: 4,
      6: item({ x: 4, y: 0, w: 1, h: 1, customResizer: true, customDragger: true }),
      component: DashboardAbsences,
      props: {},
    },
    {
      id: 5,
      6: item({ x: 5, y: 0, w: 1, h: 1, customResizer: true, customDragger: true }),
      component: PicOfTheDay,
      props: { visible: true },
    },
    {
      id: 6,
      6: item({ x: 0, y: 1, w: 2, h: 1, customResizer: true, customDragger: true }),
      component: AllPeopleTasks,
      props: {
        id: "myProjectsProgressChart",
        boxTitle: "My Projects Progress",
        nTodo: tasksObj.todo.length,
        nInProgress: tasksObj.inProgress.length,
        nDone: tasksObj.done.length,
        link: "https://tasks.office.com/labit.es/es-ES/Home/Planner/#/planhub"
      },
    },
    {
      id: 7,
      6: item({ x: 2, y: 1, w: 1, h: 1, customResizer: true, customDragger: true }),
      component: TasksBox,
      props: {
        editable: true,
        boxTitle: "My Projects Tasks",
        taskList: { todo: tasksObj.todo, progress: tasksObj.inProgress },
      },
    },
    {
      id: 8,
      6: item({ x: 3, y: 1, w: 2, h: 1, customResizer: true, customDragger: true }),
      component: AllPeopleTasks,
      props: {
        id: "myPersonalProgressChart",
        boxTitle: "My Personal Progress",
        nTodo: personalTasksObj.todo.length,
        nInProgress: personalTasksObj.inProgress.length,
        nDone: personalTasksObj.done.length,
        link: ''
      },
    },
    {
      id: 9,
      6: item({ x: 5, y: 1, w: 1, h: 1, customResizer: true, customDragger: true }),
      component: TasksBox,
      props: {
        editable: true,
        boxTitle: "My Personal Tasks",
        taskList: { todo: personalTasksObj.todo, progress: personalTasksObj.inProgress },
      },
    },
  ];

  const getAll = async () => {
    const tryTasksObj = await getAllMyTasks();

    let tryPersonalTasksObj = -1;
    let personalPlanner = $myPersonalPlanner;

    if (
      personalPlanner.id === "" ||
      personalPlanner.id === undefined ||
      personalPlanner.owner === "" ||
      personalPlanner.owner === undefined
    ) {
      personalPlanner = await getMyPersonalPlanner(userName); // El código comentado sirve para no buscar en MSAL en caso de que no exista el personalPlannerId del planner personal en el store de svelte
      // El punto negativo es que no buscara el planner personal hasta que el store caduque (en caso de que lo haga) (suuestamente el store se limpia con f5 o cerrando la app)
      //} else if ($myPersonalPlanner === '-1') {
      //  console.log('No existe un planner personal guardado en LocalStorage (stores de svelte)');
    }

    if (personalPlanner.id === -2) {
      myPersonalPlanner.set({ id: "-1", owner: "-1" });
    }
    if (personalPlanner.id !== -1 && personalPlanner.id !== -2) {
      tryPersonalTasksObj = await getAllMyPersonalTasks(personalPlanner);
      myPersonalPlanner.set(personalPlanner);
    }

    if (tryTasksObj !== -1) {
      tasksObj = tryTasksObj;

      items[6].props.nTodo = tasksObj.todo.length;
      items[6].props.nInProgress = tasksObj.inProgress.length;
      items[6].props.nDone = tasksObj.done.length;
      items[7].props.taskList.todo = tasksObj.todo;
      items[7].props.taskList.progress = tasksObj.inProgress;
    }

    if (tryPersonalTasksObj !== -1) {
      personalTasksObj = tryPersonalTasksObj;

      items[8].props.nTodo = personalTasksObj.todo.length;
      items[8].props.nInProgress = personalTasksObj.inProgress.length;
      items[8].props.nDone = personalTasksObj.done.length;
      items[8].props.link = personalTasksObj.link;
      items[9].props.taskList.todo = personalTasksObj.todo;
      items[9].props.taskList.progress = personalTasksObj.inProgress;
    }
  };

  const cols = [[1000, 6]];

  const gridChanged = (a) => {
    const items = a.detail.items;
    const store = items.reduce((acc, item) => {
      acc[item.id] = {
        x: item["6"].x,
        y: item["6"].y,
        w: item["6"].w,
        h: item["6"].h
      };
      return acc;
    }, {});

    localStorage.setItem("storedDashboardItemGrid", JSON.stringify(store));
  };

  const changeGrid = () => {
    const grid = JSON.parse($dashboardItemGrid);
    let copy = defaultItemsGrid;
    if (grid !== null && grid !== "null") {
      const keys = Object.keys(grid);
      keys.forEach((key) => {
        const index = copy.findIndex( (elem) => parseInt(elem.id) === parseInt(key) );
        copy[index]["6"].x = grid[key].x;
        copy[index]["6"].y = grid[key].y;
        copy[index]["6"].w = grid[key].w;
        copy[index]["6"].h = grid[key].h;
      });
    }
    return copy;
  };

  let items = ($dashboardItemGrid === "null" || $dashboardItemGrid === null) ? defaultItemsGrid : changeGrid();
  getAll();

  let getAllInterval;
  onMount(() => {
    getAllInterval = setInterval(() => {
      const element = document.getElementById("Dashboard");
      if (element.offsetParent !== null) {
        getAll();
      }
    }, 15000);
  });

  onDestroy(() => {
    clearInterval(getAllInterval);
  });

</script>

<div class="main">
  <div class="demo-container">
    <Grid
      {cols}
      gap={[8, 8]}
      fastStart={true}
      bind:items
      rowHeight={400}
      let:dataItem
      let:movePointerDown
      let:resizePointerDown
      on:change={gridChanged}
    >
      {@const props = dataItem.props}
      <div class="demo-widget content">
        <div class="draggable" on:pointerdown={movePointerDown} />
        <svelte:component this={dataItem.component} {...props} on:clicked={ (ev) => clickTool(ev.detail, false) } on:rightClicked={ (ev) => newWindow(ev, ev.detail) } on:updated={getAll}/>
        <svg class="resizer" on:pointerdown={resizePointerDown}> <circle cx="4" cy="4" r="4" fill="lightgrey" /> </svg>
      </div>
    </Grid>
  </div>

  <div class="tool {showTool !== '' ? '' : 'hiddenElement'}">
    <div class="toolOptions">
      <img
        class="fadedButton"
        src="/images/x.svg"
        alt="Close Tool"
        on:click={() => closeTool()}
      />
    </div>
    <div class="toolWebview">
      <WebBrowser
        type="webview"
        iframeTitle="External Tool"
        id="dashboard-webview"
        src={showTool}
      />
    </div>
  </div>

  <div class="projects">
    <CompleteResourcePlanner editable={false} personal={true} />
  </div>
</div>

<style>
  div.main {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.projects {
    width: 100%;
    display: flex;
    /*margin: 20px 0px 20px 0px;*/
    margin-bottom: 20px;
    flex-direction: column;
    background-color: white;
    border-radius: 14px;
    padding-bottom: 20px;
    margin-top: 30px;
    height: 800px;
  }

  .tool {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .toolWebview {
    width: 100%;
    height: 800px;
  }

  .toolOptions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .toolOptions img {
    width: 10px;
  }

  .demo-widget {
    background: white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
  }

  .demo-container {
    width: 100.9%;
    background-color: #f0f4f8;
    margin-top: 12px;
  }

  .draggable {
    cursor: move;
    user-select: none;
    line-height: 30px;
    background: transparent;
    width: 100%;
    height: 20px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
  }

  .resizer {
    width: 12px;
    height: 12px;
    cursor: se-resize;
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
</style>
