<script>
  import Key from "./Key.svelte";
  import ProductIcon from "./ProductIcon.svelte";
  import EditBoxTab from "../../../components/EditBoxTab.svelte";
  import EditBoxFormButton from "./EditBoxFormButton.svelte";
  import DialogSpanFullEdit from "../../../modules/project-analytics/screens/components/DialogSpanFullEdit.svelte";
  import EditBoxButton from "../../../components/EditBoxButton.svelte";
  import Combobox from "../../../components/Combobox.svelte";
  import Switch from "../../../components/Switch.svelte";
  import CompanyProductItem from "./CompanyProductItem.svelte";
  import CompanyKeyItem from "./CompanyKeyItem.svelte";
  import CompanySuscriptionItem from "./CompanySuscriptionItem.svelte";
  import SubmenuMultipleSelect from "../../../components/SubmenuMultipleSelect.svelte";
  import CalendarPicker from "../../../components/CalendarPicker/CalendarPicker.svelte";
  import EditSectionSimple from "../../../components/EditSectionSimple.svelte";

  import { copyObject } from "../../../js/copyObject";
  import { onMount } from "svelte";
  import { post, LABIT_PUBLIC_FOLDER } from "../../../js/labit-connection";
  import { randomString } from "../../../js/randomString";

  import FileUploader from "../../../components/FileUploader.svelte";

  import moment from 'moment';
  export let company;
  export let newCompany = false;
  export let addCompany;
  export let editCompany;
  export let editing = -1;
  export let creatingNew = false;
  export let onDelete = null;

  let toggleCompany = false;

  let createUpdateCompany = false;
  let createUpdateProduct = false;
  let createUpdateKey = false;
  let createUpdateSuscription = false;

  let optionProduct = "create"; // create, update or delete.
  let optionKey = "create"; // create, update or delete.
  let optionSuscription = "create"; // create, update or delete.

  let productsList = [];
  let keysList = [];
  let deletedProductsList = [];
  let deletedKeysList = [];
  let deletedSuscriptionsList = [];

  let currentIconCompany = null;
  let currentIconPathCompany = "";
  let currentIconProduct = null;
  let currentIconPathProduct = "";

  export let errorCompany;
  let errorProduct = false;
  let errorKey = false;
  let errorSuscriptionProduct = false;
  let errorSuscriptionKey = false;

  let keyRoleList = [
    { label: "Admin", value: "Admin" },
    { label: "Analytics", value: "Analytics" },
    { label: "User", value: "User" },
  ];

  let renewalStatusOptions = [
    { label: "Active", value: "Active" },
    { label: "In Process", value: "In Process" },
    { label: "Expired", value: "Expired" },
  ];
  let renewalStatusOptionsSelected = renewalStatusOptions[0];

  let suscriptionTypeOptions = [
    { label: "Fix", value: "Fix" },
    { label: "Floating", value: "Floating" },
  ];

  let suscriptionTypeSelected = suscriptionTypeOptions[0];
  let productSelected = productsList[0];
  let keySelected = keysList[0];

  let currentProduct = {
    id: "",
    name: "",
    pictureURL: "",
  };

  let currentKey = {
    id: "",
    username: "",
    passwd: "",
    active: true,
    roles: [],
  };

  let currentSuscription = {
    id: "",
    contractNumber: "",
    seats: "1",
    type: "",
    term: "",
    expiringDate: moment().format("DD/MM/YYYY"),
    renewalComments: "",
    renewalStatus: "",
    product: "",
    license: "",
  };

  let currentCompany = {
    id: -1,
    name: "",
    pictureURL: "",
    products: [],
    keys: [],
    suscriptions: [],
  };

  onMount(async () => {
    loadProductsComboboxList();
    loadKeysComboboxList();
    createUpdateCompany = newCompany;
    currentCompany = copyObject(company);
  });

  let emptyProduct = () => {
    currentProduct = {
      id: "",
      name: "",
      pictureURL: "",
    };
  };

  let emptyKey = () => {
    currentKey = {
      id: "",
      username: "",
      passwd: "",
      active: true,
      roles: [],
    };
  };

  let emptySuscription = () => {
    currentSuscription = {
      id: "",
      contractNumber: "",
      seats: "1",
      type: "",
      term: "",
      expiringDate: moment().format("DD/MM/YYYY"),
      renewalComments: "",
      renewalStatus: "",
      product: "",
      license: "",
    };
  };

  let reloadProductsListCombobox = () => {
    productsList = [];
    company.products.forEach((p) => {
      let product = {
        label: p.name,
        value: p.id,
      };
      productsList.push(product);
    });
    productsList = productsList;
    productSelected = productsList[0];
  };

  let reloadKeysListCombobox = () => {
    keysList = [];
    company.keys.forEach((l) => {
      let license = {
        label: l.username,
        value: l.id,
      };
      keysList.push(license);
    });
    keysList = keysList;
    keySelected = keysList[0];
  };

  let addNewProduct = () => {
    errorProduct = false;
    if (currentProduct.name) {
      if (currentProduct.icon && currentProduct.icon !== "") {
        errorProduct = false;
        currentProduct.id = -Math.abs(company.products.length + 1);
        /* currentProduct.id = -1; */
        company.products.push(currentProduct);
        company = company;
        emptyProduct();
        createUpdateProduct = false;
        reloadProductsListCombobox();
      }
    } else {
      errorProduct = true;
    }
  };

  let updateProduct = async () => {
    let product = company.products.find((p) => {
      return p.id === currentProduct.id;
    });
    if (product) {
      product.name = currentProduct.name;
      product.pictureURL = currentProduct.pictureURL;
      product.icon = currentProduct.icon;
      company = company;
      reloadProductsListCombobox();
    }
    createUpdateProduct = false;
  };

  let addNewKey = () => {
    if (currentKey.username) {
      errorKey = false;
      currentKey.id = -Math.abs(company.keys.length + 1);
      /* currentKey.id = -1; */
      company.keys.push(currentKey);
      company = company;
      emptyKey();
      createUpdateKey = false;
      reloadKeysListCombobox();
    } else {
      errorKey = true;
    }
  };

  let addNewSuscription = () => {
    if (!productsList.length || !keysList.length) {
      if (!productsList.length) {
        errorSuscriptionProduct = true;
      }
      if (!keysList.length) {
        errorSuscriptionKey = true;
      }
    } else {
      errorSuscriptionKey = false;
      errorSuscriptionProduct = false;
      currentSuscription.id = -Math.abs(company.suscriptions.length + 1);
      /* currentSuscription.id = -1; */
      currentSuscription.product = productSelected.value;
      currentSuscription.license = keySelected.value;
      currentSuscription.renewalStatus = renewalStatusOptionsSelected.label;
      currentSuscription.type = suscriptionTypeSelected.label;
      company.suscriptions.push(currentSuscription);
      company = company;
      emptySuscription();
      createUpdateSuscription = false;
      productSelected = productsList[0];
      keySelected = keysList[0];
    }
  };

  let deleteProduct = async (pos) => {
    const productId = company.products[pos].id;
    company.suscriptions = company.suscriptions.filter((s) => {
      return s.product !== productId;
    });
    company = company;
    company.products = company.products.filter((p) => {
      return p.id !== productId;
    });
    company = company;
    if (productId > 0) {
      deletedProductsList.push(productId);
    }
    deletedProductsList = deletedProductsList;
    reloadProductsListCombobox();
  };

  let updateKey = () => {
    let key = company.keys.find((k) => {
      return k.id === currentKey.id;
    });
    if (key) {
      key.username = currentKey.username;
      key.passwd = currentKey.passwd;
      key.active = currentKey.active;
      key.roles = currentKey.roles;
      key.edit = true;
      key = key;
      company = company;
      reloadKeysListCombobox();
    }
    createUpdateKey = false;
  };

  let deleteKey = async (pos) => {
    const keyId = company.keys[pos].id;
    company.suscriptions = company.suscriptions.filter((s) => {
      return s.license !== keyId;
    });
    company = company;
    company.keys = company.keys.filter((k) => {
      return k.id !== keyId;
    });
    company = company;
    if (keyId > 0) {
      deletedKeysList.push(keyId);
    }
    deletedKeysList = deletedKeysList;
    reloadKeysListCombobox();
  };

  let updateSuscription = () => {
    let suscription = company.suscriptions.find((s) => {
      return s.id === currentSuscription.id;
    });
    if (suscription) {
      currentSuscription.renewalStatus = renewalStatusOptionsSelected.label;
      suscription.contractNumber = currentSuscription.contractNumber;
      suscription.seats = currentSuscription.seats;
      suscription.term = currentSuscription.term;
      suscription.type = suscriptionTypeSelected.label;
      suscription.expiringDate = currentSuscription.expiringDate;
      suscription.renewalComments = currentSuscription.renewalComments;
      suscription.renewalStatus = currentSuscription.renewalStatus;
      suscription.product = productSelected.value;
      suscription.license = keySelected.value;
      company = company;
    }
    createUpdateSuscription = false;
  };

  let deleteSuscription = async (pos) => {
    const suscriptionId = company.suscriptions[pos].id;
    company.suscriptions.splice(pos, 1);
    if (suscriptionId > 0) {
      deletedSuscriptionsList.push(suscriptionId);
    }
    company = company;
  };

  let toggleCreateUpdateCompany = () => {
    createUpdateCompany = !createUpdateCompany;
    editing = company.id;
    if (!currentIconCompany) {
      currentIconPathCompany = company.pictureURL.replace(
        LABIT_PUBLIC_FOLDER,
        ""
      );
    }
  };

  let togglePanelCompany = () => {
    toggleCompany = !toggleCompany;
  };

  let toggleCreateUpdateProduct = () => {
    createUpdateProduct = !createUpdateProduct;
    emptyProduct();
    optionProduct = "create";
  };

  let toggleCreateUpdateKey = () => {
    createUpdateKey = !createUpdateKey;
    emptyKey();
    optionKey = "create";
  };
  let toggleCreateUpdateSuscription = () => {
    createUpdateSuscription = !createUpdateSuscription;
    emptySuscription();
    productSelected = productsList[0];
    keySelected = keysList[0];
    optionSuscription = "create";
  };

  let loadProductsComboboxList = () => {
    productsList = [];
    company.products.forEach((p) => {
      let product = {
        label: p.name,
        value: p.id,
      };
      productsList.push(product);
    });
    productsList = productsList;
  };

  let loadKeysComboboxList = () => {
    keysList = [];
    company.keys.forEach((l) => {
      let license = {
        label: l.username,
        value: l.id,
      };
      keysList.push(license);
    });
    keysList = keysList;
  };

  $: if (!currentIconCompany) {
    currentIconPathCompany = "";
  }

  $: if (currentIconCompany) {
    company.icon = currentIconCompany;
  }

  $: if (createUpdateProduct) {
    currentIconPathProduct = currentProduct.pictureURL.replace(
      LABIT_PUBLIC_FOLDER,
      ""
    );
  }

  $: if (currentIconProduct) {
    currentProduct.icon = currentIconProduct;
  }

  //$: console.log(company);
</script>

<!--{#if editing < 0 || editing === company.id}-->
<div
  class="companyLicenseEditContainer {createUpdateCompany &&
  (editing < 0 || editing === company.id)
    ? ''
    : 'hiddenElement'}"
>
  {#if !creatingNew}
    <div class="editSection">
      <EditSectionSimple text={company.name} onRemove={onDelete} />
    </div>
  {/if}
  <div class="editBox">
    <div class="editBoxInfo">
      <div class="editBoxInfoInfo">
        <div class="editBoxPage">
          <div class="editBoxColumn">
            <EditBoxTab icon="/images/software.svg" text="COMPANY & PRODUCTS" />
            <span class="editBoxTitle requiredElement"><b>Company</b></span>
            <div class="editBoxInput">
              <div class="editBoxInput containerBox">
                <DialogSpanFullEdit
                  color="transparent"
                  height="22"
                  border="var(--labit-card-gray)"
                  error={errorCompany}
                  bind:text={company.name}
                />
              </div>
            </div>
            <span class="editBoxTitle requiredElement">Company Icon</span>
            <div class="companyIcon">
              <FileUploader
                bind:fileInfo={currentIconCompany}
                loadFromPath={currentIconPathCompany}
                picExtraStyle="border-radius: 10px"
                showFullImage={true}
              />
            </div>
            <!--<div class="editBoxInput">
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color="transparent"
                    height="22"
                    border="var(--labit-card-gray)"
                    bind:text={company.pictureURL}
                  />
                </div>
              </div>-->
            <span class="editBoxTitle " />
            <span class="editBoxTitle "><b>Products</b></span>
            <div class="editBoxSection">
              {#each company.products as product, index}
                <CompanyProductItem
                  {product}
                  bind:currentProduct
                  bind:createUpdateProduct
                  bind:option={optionProduct}
                  onClick={async () => {
                    await deleteProduct(index);
                  }}
                />
              {/each}
              <div class="addOption">
                <img
                  class="optionIcon"
                  src="/images/plus.svg"
                  alt="Add Program"
                  on:click={() => {
                    toggleCreateUpdateProduct();
                  }}
                />
              </div>
            </div>
            <div class="boxFields {createUpdateProduct ? '' : 'hiddenElement'}">
              <span class="editBoxTitle  requiredElement normalWeightText"
                >Product Name</span
              >
              <div class="editBoxInput">
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color="transparent"
                    height="22"
                    border="var(--labit-card-gray)"
                    error={errorProduct}
                    bind:text={currentProduct.name}
                  />
                </div>
              </div>
              <span class="editBoxTitle requiredElement">Product Icon</span>
              <div class="companyIcon">
                <FileUploader
                  bind:fileInfo={currentIconProduct}
                  loadFromPath={currentIconPathProduct}
                  picExtraStyle="border-radius: 10px"
                  showFullImage={true}
                />
              </div>
              <!--<div class="editBoxInput">
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color="transparent"
                    height="22"
                    border="var(--labit-card-gray)"
                    bind:text={currentProduct.pictureURL}
                  />
                </div>
              </div>-->
              <div class="editBoxInput containerButtons">
                <EditBoxButton
                  text={optionProduct === "create" ? "Add" : "Edit"}
                  onClick={async () => {
                    if (optionProduct === "create") {
                      addNewProduct();
                    } else {
                      await updateProduct();
                    }
                  }}
                />
                <EditBoxButton
                  text="Cancel"
                  onClick={() => {
                    createUpdateProduct = false;
                    optionProduct = "create";
                  }}
                />
              </div>
            </div>
          </div>
          <div class="editBoxColumn">
            <EditBoxTab icon="/images/key.svg" text="KEYS & PAYMENTS" />
            <span class="editBoxTitle "><b>Keys</b></span>
            <div class="editBoxSection">
              {#each company.keys as key, index}
                <CompanyKeyItem
                  {key}
                  bind:createUpdateKey
                  bind:currentKey
                  bind:option={optionKey}
                  onClick={async () => {
                    await deleteKey(index);
                  }}
                />
              {/each}
              <div class="addOption">
                <img
                  class="optionIcon"
                  src="/images/plus.svg"
                  alt="Add Program"
                  on:click={() => {
                    toggleCreateUpdateKey();
                  }}
                />
              </div>
            </div>
            <div class="boxFields {createUpdateKey ? '' : 'hiddenElement'}">
              <span class="editBoxTitle requiredElement normalWeightText"
                >Username</span
              >
              <div class="editBoxInput">
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color="transparent"
                    height="22"
                    border="var(--labit-card-gray)"
                    error={errorKey}
                    bind:text={currentKey.username}
                  />
                </div>
              </div>
              <span class="editBoxTitle ">Password</span>
              <div class="editBoxInput">
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color="transparent"
                    height="22"
                    border="var(--labit-card-gray)"
                    bind:text={currentKey.passwd}
                  />
                </div>
              </div>
              <div class="activeContainer">
                <span class="editBoxTitle"
                  >{currentKey.active ? "Active" : "Inactive"}
                </span>
                <Switch
                  width={40}
                  height={22}
                  checked={currentKey.active}
                  onClick={() => {
                    currentKey.active = !currentKey.active;
                  }}
                  readonly={false}
                />
              </div>
              <div class="editBoxInput">
                <SubmenuMultipleSelect
                  text="Roles"
                  options={keyRoleList}
                  bind:selected={currentKey.roles}
                  width="100%"
                />
              </div>
              <div class="editBoxInput containerButtons">
                <EditBoxButton
                  text={optionKey === "create" ? "Add" : "Edit"}
                  onClick={() => {
                    if (optionKey === "create") {
                      addNewKey();
                    } else {
                      updateKey();
                    }
                  }}
                />
                <EditBoxButton
                  text="Cancel"
                  onClick={() => {
                    createUpdateKey = false;
                    optionKey = "create";
                  }}
                />
              </div>
            </div>
          </div>
          <div class="editBoxColumn">
            <EditBoxTab icon="/images/seat.svg" text="SUBSCRIPTIONS" />
            <span class="editBoxTitle "><b>Subscriptions</b></span>
            <div class="editBoxSection">
              {#each company.suscriptions as suscription, index}
                <CompanySuscriptionItem
                  bind:company
                  {suscription}
                  bind:createUpdateSuscription
                  bind:currentSuscription
                  bind:option={optionSuscription}
                  bind:productSelected
                  bind:keySelected
                  bind:renewalStatusOptionsSelected
                  bind:suscriptionTypeSelected
                  onClick={async () => {
                    await deleteSuscription(index);
                  }}
                />
              {/each}
              <div class="addOption">
                <img
                  class="optionIcon"
                  src="/images/plus.svg"
                  alt="Add Program"
                  on:click={() => {
                    toggleCreateUpdateSuscription();
                  }}
                />
              </div>
            </div>
            <div
              class="boxFields {createUpdateSuscription ? '' : 'hiddenElement'}"
            >
              <span class="editBoxTitle requiredElement normalWeightText"
                >Product</span
              >
              <div class="editBoxInput">
                <Combobox
                  bind:selectedValue={productSelected}
                  options={productsList}
                  backgroundColor="transparent"
                  searchable={false}
                  error={errorSuscriptionProduct}
                />
              </div>
              <span class="editBoxTitle  requiredElement normalWeightText"
                >License</span
              >
              <div class="editBoxInput">
                <Combobox
                  bind:selectedValue={keySelected}
                  options={keysList}
                  backgroundColor="transparent"
                  searchable={false}
                  error={errorSuscriptionKey}
                />
              </div>
              <span class="editBoxTitle ">Contract Number</span>
              <div class="editBoxInput">
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color="transparent"
                    height="22"
                    border="var(--labit-card-gray)"
                    bind:text={currentSuscription.contractNumber}
                  />
                </div>
              </div>
              <div class="miscellaneousDataBox">
                <div class="smallInputData">
                  <span class="editBoxTitle ">Seats</span>
                  <div class="editBoxInput">
                    <div class="editBoxInput containerBox">
                      <DialogSpanFullEdit
                        color="transparent"
                        height="22"
                        border="var(--labit-card-gray)"
                        bind:text={currentSuscription.seats}
                      />
                    </div>
                  </div>
                </div>
                <div class="smallInputData">
                  <span class="editBoxTitle ">Type</span>
                  <div class="editBoxInput">
                    <div class="editBoxInput containerBox">
                      <Combobox
                        bind:selectedValue={suscriptionTypeSelected}
                        options={suscriptionTypeOptions}
                        backgroundColor="transparent"
                        searchable={false}
                      />
                    </div>
                  </div>
                </div>
                <div class="smallInputData">
                  <span class="editBoxTitle ">Term</span>
                  <div class="editBoxInput">
                    <div class="editBoxInput containerBox">
                      <DialogSpanFullEdit
                        color="transparent"
                        height="22"
                        border="var(--labit-card-gray)"
                        bind:text={currentSuscription.term}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span class="editBoxTitle ">Expiring Date</span>
              <div class="editBoxInput">
                <div class="editBoxInput containerBox">
                  <CalendarPicker
                    bind:date={currentSuscription.expiringDate}
                    options={[]}
                  />
                </div>
              </div>
              <span class="editBoxTitle ">Renewal Comments</span>
              <div class="editBoxInput">
                <div class="editBoxInput containerBox">
                  <DialogSpanFullEdit
                    color="transparent"
                    height="22"
                    border="var(--labit-card-gray)"
                    bind:text={currentSuscription.renewalComments}
                  />
                </div>
              </div>
              <span class="editBoxTitle ">Renewal Status</span>
              <div class="editBoxInput">
                <Combobox
                  bind:selectedValue={renewalStatusOptionsSelected}
                  options={renewalStatusOptions}
                  backgroundColor="transparent"
                  searchable={false}
                />
              </div>
              <div class="editBoxInput containerButtons">
                <EditBoxButton
                  text={optionSuscription === "create" ? "Add" : "Edit"}
                  onClick={() => {
                    if (optionSuscription === "create") {
                      addNewSuscription();
                    } else {
                      updateSuscription();
                    }
                  }}
                />
                <EditBoxButton
                  text="Cancel"
                  onClick={() => {
                    createUpdateSuscription = false;
                    optionSuscription = "create";
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="editBoxButtons">
        <EditBoxFormButton
          icon="/images/dialog_x.svg"
          onClick={() => {
            createUpdateCompany = false;
            newCompany = false;
            company = "";
            company = copyObject(currentCompany);
            editing = -1;
            createUpdateProduct = false;
            createUpdateKey = false;
            createUpdateSuscription = false;
          }}
        />
        <EditBoxFormButton
          icon="/images/save.svg"
          onClick={async () => {
            errorCompany = false;
            if (company.name) {
              if (company.icon && company.icon !== "") {
                if (newCompany) {
                  addCompany();
                } else {
                  await editCompany(company);
                  loadProductsComboboxList();
                  loadKeysComboboxList();
                  currentCompany = copyObject(company);
                }
                createUpdateCompany = false;
                newCompany = false;
                editing = -1;
              }
            } else {
              errorCompany = true;
            }
            renewalStatusOptionsSelected = renewalStatusOptions[0];
            suscriptionTypeSelected = suscriptionTypeOptions[0];
            // Check items to be removed.
            if (deletedProductsList.length > 0) {
              // Products
              for (let i = 0; i < deletedProductsList.length; i++) {
                const product = deletedProductsList[i];
                await post(`DELETE FROM licenseProducts WHERE id=${product}`);
              }
            }
            if (deletedKeysList.length > 0) {
              // Keys
              for (let i = 0; i < deletedKeysList.length; i++) {
                const key = deletedKeysList[i];
                await post(`DELETE FROM licenseKeys WHERE id=${key}`);
              }
            }
            if (deletedSuscriptionsList > 0) {
              // Suscriptions
              for (let i = 0; i < deletedSuscriptionsList.length; i++) {
                const suscription = deletedSuscriptionsList[i];
                await post(
                  `DELETE FROM licenseSuscriptions WHERE id=${suscription}`
                );
              }

              deletedSuscriptionsList = [];
            }
          }}
        />
      </div>
    </div>
  </div>
</div>
<!--{#if editing < 0 && !creatingNew}-->
<div
  class="companyLicenseContainer {editing < 0 && !creatingNew
    ? ''
    : 'hiddenElement'}"
>
  <div class="companyLicenseHeader">
    <div class="companyHeaderLogoName">
      <img
        class="companyLogo"
        src={company.pictureURL + "?" + randomString(10)}
        alt=""
      />
      <span class="companyName">{company.name.toUpperCase()}</span>
    </div>
    <div class="companyHeaderProducts">
      <span class="assignedProducts">ASSIGNED PRODUCTS:</span>
      {#each currentCompany.products as product}
        <ProductIcon {product} company={currentCompany} />
      {/each}
    </div>
    <div class="companyToggleContainer">
      {#if !newCompany}
        <img
          src="images/edit.svg"
          alt=""
          on:click={() => {
            toggleCreateUpdateCompany();
          }}
        />
      {/if}
      <img
        src="images/down_arrow.svg"
        alt=""
        on:click={() => {
          togglePanelCompany();
        }}
      />
    </div>
  </div>
  <div class="companyLicenseInfoPanel {toggleCompany ? '' : 'hiddenElement'}">
    {#each currentCompany.keys as key}
      <Key {key} {currentCompany} />
    {/each}
    <div class="companyLicenseSuscriptions">
      <div class="suscriptionHeaderContainer">
        <span class="bigColumn">SUBSCRIPTION</span>
        <span class="bigColumn">ID [CONTRACT NUMBER]</span>
        <span class="smallColumn">TYPE</span>
        <span class="smallColumn">TOKENS</span>
        <span class="smallColumn">TERM</span>
        <span class="mediumColumn">EXPIRING DATE</span>
        <span class="bigColumn">RENEWAL COMMENTS</span>
        <span class="mediumColumn">RENEWAL STATUS</span>
      </div>
      {#each currentCompany.suscriptions as suscription}
        <div class="suscriptionContainer">
          <div class="bigColumn">
            <img
              class="suscriptionProgramLogo"
              src={currentCompany.products.find(
                (p) => p.id === suscription.product
              ).pictureURL}
              alt="productLogo"
            /><span
              >{currentCompany.products.find(
                (p) => p.id === suscription.product
              ).name}</span
            >
          </div>
          <div class="bigColumn">
            <span>{suscription.contractNumber}</span>
          </div>
          <div class="smallColumn"><span>{suscription.type}</span></div>
          <div class="smallColumn"><span>{suscription.seats}</span></div>
          <div class="smallColumn"><span>{suscription.term}</span></div>
          <div class="mediumColumn">
            <!-- svelte-ignore missing-declaration -->
            <span
              style="color: {moment(
                suscription.expiringDate,
                'DD/MM/YYYY'
              ).diff(
                moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY'),
                'weeks'
              ) <= 2
                ? 'red'
                : ''}">{suscription.expiringDate}</span
            >
          </div>
          <div class="bigColumn">
            <span>{suscription.renewalComments}</span>
          </div>
          <div class="mediumColumn">
            {#if suscription.renewalStatus === "Active"}
              <div
                class="circle"
                style="background-color: var(--labit-button-green);"
              />
            {/if}
            {#if suscription.renewalStatus === "In Process"}
              <div
                class="circle"
                style="background-color: rgb(224, 235, 73);"
              />
            {/if}
            {#if suscription.renewalStatus === "Expired"}
              <div class="circle" style="background-color: gray" />
            {/if}
            <span>{suscription.renewalStatus}</span>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>
<!--{/if}-->

<!--{/if}-->
<style>
  .hiddenElement {
    display: none;
  }

  div.companyLicenseContainer {
    justify-content: flex-start;
    margin-top: 20px;
    width: 100%;
    border-radius: 14px;
    background-color: var(--labit-white);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 20px 0 20px;
  }

  .companyLicenseHeader {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .companyLicenseInfoPanel {
    margin: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--labit-card-lightgray);
    width: 100%;
    padding-top: 20px;
  }

  .companyHeaderLogoName {
    width: 30%;
    display: flex;
    align-items: center;
  }

  .companyHeaderProducts {
    width: 65%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .companyName {
    font-size: 22px;
    color: #646464;
    font-weight: bold;
  }

  .companyLogo {
    width: 30px;
    margin-right: 20px;
  }

  .companyToggleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 5%;
    justify-content: space-evenly;
  }

  .companyToggleContainer img {
    width: 17px;
    cursor: pointer;
  }

  .assignedProducts {
    width: 80px;
    font-size: 13px;
    font-weight: bold;
  }

  .companyLicenseSuscriptions {
    margin: 20px 0 0 0;
    border-radius: 14px;
    border: 1px solid var(--labit-card-lightgray);
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
  }

  .companyLicenseSuscriptions span {
    font-weight: bold;
  }

  .bigColumn {
    width: 20%;
    display: flex;
    align-items: center;
  }

  .mediumColumn {
    width: 10%;
    display: flex;
    align-items: center;
  }

  .smallColumn {
    width: 6%;
    display: flex;
    align-items: center;
  }

  .suscriptionHeaderContainer,
  .suscriptionContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 30px;
    height: auto;
  }

  .suscriptionContainer span {
    font-weight: normal !important;
    padding: 2px;
  }

  .suscriptionHeaderContainer span,
  .suscriptionContainer span {
    font-size: 13px;
  }

  .suscriptionProgramLogo {
    width: 20px;
    margin-right: 10px;
  }

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .companyLicenseEditContainer {
    width: 100%;
  }

  div.editBox {
    width: 100%;
    margin-top: 20px;
  }

  div.editBoxInfo {
    width: 100%;
    display: flex;
    min-height: 710px !important;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  div.editBoxInfoInfo {
    background-color: #d7e1ea;
    padding: 41px 49px 20px 36px;
    border-radius: 15px;
    width: 1510px;
    display: flex;
    flex-direction: column;
  }

  div.editBoxPage {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }

  div.editBoxColumn {
    width: 428px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  span.editBoxTitle {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 10px;
    width: 100%;
  }

  div.editBoxInput {
    height: 22px;
    margin-bottom: 10px;
  }

  div.editBoxInput {
    height: 22px;
    margin-bottom: 10px;
  }

  .containerButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div.editBoxSection {
    width: 100%;
    border-radius: 15px;
    border: 1px solid var(--labit-card-gray);
    padding: 10px 20px 10px 20px;
    margin-bottom: 10px;
  }

  .optionIcon {
    width: 20px;
    cursor: pointer;
  }

  .addOption {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .boxFields {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .suscriptionContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
  }

  .activeContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    margin-bottom: 10px;
  }

  .suscriptionContainer span {
    font-size: 13px;
  }

  .miscellaneousDataBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .smallInputData {
    width: 30%;
    display: flex;
    flex-direction: column;
  }

  .normalWeightText {
    font-weight: normal !important;
  }

  div.companyIcon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  div.editSection {
    margin-top: 20px;
  }
</style>
