<script>
  export let placeholder;
  export let maxlength = 150;
  export let img;
  export let value;
  export let onKeyUp = null;
  export let hints;

  import TextEditHintsItem from "./TextEditHintsItem.svelte";

  import { copyObject } from "../../js/copyObject";

  import ClickOutside from "svelte-click-outside";

  let matchedHints = [];

  let clickItem = (item) => {
    value = item;
    matchedHints = [];
  };

  let keyUp = (event) => {
    if (value === "") {
    } else {
      matchedHints = hints.filter((item) => {
        return item.toUpperCase().includes(value.toUpperCase());
      });
    }
    onKeyUp(event);
  };

  let clickOutside = () => {
    matchedHints = [];
  };

  let clickInput = () => {
    matchedHints = copyObject(hints);
  };
</script>

<div class="textEditHintsContainer">
  <ClickOutside on:clickoutside={() => clickOutside()} ref="outside">
    <div class="container">
      <div class="input">
        {#if img}
          <input
            class="inputImage inputImagePad"
            type="text"
            {placeholder}
            {maxlength}
            bind:value
            on:keyup={(event) => keyUp(event)}
            on:click={() => clickInput()}
          />
          <img src={img} alt="icon" />
        {:else}
          <input
            class="inputImage"
            type="text"
            {placeholder}
            {maxlength}
            bind:value
            on:keyup={(event) => keyUp(event)}
            on:click={() => clickInput()}
          />
        {/if}
      </div>
      <div class="hintsContainer">
        <div class="hints customScrollbars">
          {#each matchedHints as hint}
            <TextEditHintsItem item={hint} onClick={() => clickItem(hint)} />
          {/each}
        </div>
      </div>
    </div>
  </ClickOutside>
</div>

<style>
  div.textEditHintsContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
  }

  div.container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  div.input {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: var(--labit-background-gray);
    border-style: solid;
    border-color: var(--labit-gray);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 960px/960px;
    font-size: 12px;
    position: relative;
  }

  input.inputImagePad {
    padding-right: 25px;
  }

  img {
    position: absolute;
    height: 50%;
    right: 10px;
  }

  div.hintsContainer {
    position: relative;
  }

  div.hints {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    z-index: 10;
    min-width: 320px;
    border-radius: 7px;
    box-shadow: var(--listShadow, 0 2px 3px 0 rgba(44, 62, 80, 0.24));
    top: 6px;
    max-height: 120px;
    overflow-y: scroll;
  }

  :global(div.textEditHintsContainer > div) {
    width: 100%;
    height: 100%;
  }
</style>
