<script>
  export let urlFromParams = "";

  import WebBrowser from "../components/WebBrowser.svelte";
  import ContextMenu from "../components/ContextMenu.svelte";

  import { getOpenURL, getOpenURLUserAgent } from "../js/localInfo";

  import { onMount } from "svelte";

  let url = "";
  let useragent = "";

  let showNavMenu = null;

  /* const DEFAULT_USERAGENT =
    "Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0";*/
  const DEFAULT_USERAGENT =
    "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.93 Safari/537.36"; // Last Chrome version 20210427

  onMount(() => {
    if (urlFromParams === "") {
      url = getOpenURL();
      useragent = getOpenURLUserAgent();
      if (useragent === "") {
        useragent = DEFAULT_USERAGENT;
      }
    } else {
      url = atob(urlFromParams);
      useragent = DEFAULT_USERAGENT;
    }

    window.$("body").css({ "padding-right": "0px", "padding-left": "0px" });
  });
</script>

<div class="URLBrowserContainer">
  <WebBrowser
    id="URLBrowserWebBrowser"
    type="webview"
    src={url}
    {useragent}
    isOut={true}
    webTitle={true}
  />
</div>
<!--
<ContextMenu currentURL={url} bind:showMenu={showNavMenu} />
-->

<style>
  div.URLBrowserContainer {
    width: 100%;
    height: 100%;
  }
</style>
