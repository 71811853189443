<script>
  export let active;
</script>

<div class="stateCircle">
  {#if active}
    <div
      class="circle"
      style={"background-color: var(--labit-button-green);"}
    />
  {:else}
    <div class="circle" style={"border: 1px solid #212321;"} />
  {/if}
</div>

<style>
  .stateCircle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90px;
  }

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
  }
</style>
