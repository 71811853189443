<script>
  export let text;
  export let url;
  export let icons;
</script>

<div class="buttonIconEdit">
  <div class="row">
    <input type="text" bind:value={text} />
    <div class="icons">
      {#each icons as icon}
        <img src={icon.src} on:click={icon.onClick} alt="Icon" />
      {/each}
    </div>
  </div>
  <div class="row">
    <input type="text" bind:value={url} placeholder="PDF url" />
  </div>
</div>

<style>
  div.buttonIconEdit {
    width: 586px;
    /*height: 22px;*/
    background-color: white;
    /*border-radius: 19px;*/
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    /*border-color: var(--labit-card-gray);
    border-width: 1px;
    border-style: solid;*/
  }

  div.buttonIconEdit input {
    background-color: transparent;
    width: 90%;
    border-color: var(--labit-card-gray);
    border-width: 1px;
    border-style: solid;
    border-radius: 19px;
    padding-left: 14px;
    padding-right: 14px;
    height: 100%;
  }

  div.icons {
    position: absolute;
    top: 0px;
    right: 14px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  div.icons img {
    height: 75%;
    cursor: pointer;
    margin-left: 7px;
  }

  div.row {
    height: 22px;
    display: flex;
    position: relative;
  }

  div.row:not(:first-child) {
    margin-top: 4px;
  }

  /* Animations */

  div.icons img {
    transition: opacity 0.5s linear 0s;
  }

  div.icons img:hover {
    opacity: 0.5;
  }
</style>
