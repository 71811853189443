<script>
  export let item;
  export let selectedCompany;
  export let selectedVersion;
  export let selectedProject;
  export let selectedProgram;
  export let selectedSpace;
  export let selectedRoom;
  export let selFilterSpace;
  export let selFilterType;
  export let filterRed;
  export let filterYellow;
  export let filterGreen;

  import Light from "./Light.svelte";

  import { percentageColors } from "../js/percentage-colors";
  import { openURL } from "../js/openURL";
  import { openNewWindow } from "../js/openNewWindow";

  let backgroundColor = "#ffffff";
  let url = "/";

  const GREEN_COLOR = "d3eea2";
  const RED_COLOR = "f6aba1";

  let visible = true;

  $: {
    if (item) {
      backgroundColor = percentageColors(
        GREEN_COLOR,
        RED_COLOR,
        parseFloat(item.status.numGreen) / 100
      );
      url = `/roomdatasheetl/${selectedCompany}/${selectedVersion}/${selectedProject}/${selectedProgram}/${selectedSpace}/${selectedRoom}`;
    }
  }

  $: {
    let visibleS = true;
    let visibleR = true;

    if (selFilterSpace.length > 0) {
      const pos = selFilterSpace.findIndex((sS) => {
        return sS === item.nameSpace;
      });
      visibleS = pos >= 0;
    }

    if (selFilterType.length > 0) {
      const pos = selFilterType.findIndex((sR) => {
        return sR === item.title;
      });
      visibleR = pos >= 0;
    }

    visible = visibleS && visibleR;

    if (visible && (filterGreen || filterYellow || filterRed)) {
      visible = false;
      let type = "green";
      if (
        item.status.numRed >= item.status.numYellow &&
        item.status.numRed >= item.status.numGreen
      ) {
        type = "red";
      } else {
        if (
          item.status.numYellow >= item.status.numRed &&
          item.status.numYellow >= item.status.numGreen
        ) {
          type = "yellow";
        }
      }
      if (
        (filterGreen && type === "green") ||
        (filterYellow && type === "yellow") ||
        (filterRed && type === "red")
      ) {
        visible = true;
      }
    }
  }
</script>

{#if visible}
  <div
    class="roomItemContainer fadedButton"
    style="background-color: #{backgroundColor}"
    on:click={() => openURL(url, true)}
    on:contextmenu={(ev) => {
      ev.preventDefault();
      openNewWindow("/skylab-main" + url);
    }}
  >
    <div class="lights">
      <Light
        type="green"
        size="19px"
        marginRight="2px"
        text={item.status.numGreen}
        strong={true}
      />
      <Light
        type="yellow"
        size="19px"
        marginRight="2px"
        text={item.status.numYellow}
        strong={true}
      />
      <Light
        type="red"
        size="19px"
        marginRight="2px"
        text={item.status.numRed}
        strong={true}
      />
      <div class="emptyLight" />
    </div>
    <div>
      <span>{item.title}</span>
    </div>
  </div>
{/if}

<style>
  div.roomItemContainer {
    width: 100%;
    margin-bottom: 4px;
    border-radius: 19px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    color: var(--labit-card-gray);
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  div.emptyLight {
    width: 13px;
    height: 13px;
    margin-right: 2px;
  }

  div.lights {
    display: flex;
  }

  a {
    text-decoration: none;
  }

  @media print {
    div.roomItemContainer {
      width: 100%;
      margin-bottom: 4px;
      border-radius: 19px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
      color: var(--labit-card-gray);
      cursor: pointer;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    div.emptyLight {
      width: 13px;
      height: 13px;
      margin-right: 2px;
    }

    div.lights {
      display: flex;
    }

    a {
      text-decoration: none;
    }
  }

  /* Animations */

  div.roomItemContainer {
    transition: opacity 0.5s linear 0s;
  }

  div.roomItemContainer:hover {
    opacity: 0.5;
  }
</style>
