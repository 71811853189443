<script>
    import moment from 'moment';
    import { msToTime } from '../../../js/formatDateTime'
    import ToggleableContainer from "./ToggleableContainer.svelte";

    export let data;
    $:console.log(data);
</script>

<div class="container w-100">
    {#each data as {user_id, user_name, picture, days} (user_id)}
    {@const daysKeys = Object.keys(days)}
    {@const time = daysKeys.reduce((acc, key) => {
            const roundOffMin = days[key].roundOffMin;
            const res = days[key].tasks.reduce((acc2, task) => {
                const { duration, project_id } = task;
                acc2.total += duration;
                if (project_id !== '391') acc2.billable += duration;
                return acc2;
            }, { billable: 0, total: 0 });
            const { billable, total } = res;
            acc.billable += (billable - roundOffMin); acc.total += total;
            return acc;
        }, { billable: 0, total: 0 })
    }

    {@const totalTimeStr = msToTime(time.total * 60 * 1000)}
    {@const billableTimeStr = msToTime(time.billable * 60 * 1000)}
    
    {#if daysKeys.length > 0}
    <ToggleableContainer --bgColor="#fafafa">
        <div class="header-main flex-row-center" slot="header">
            <img src={picture} alt={user_name}>
            <span class="bigger">{user_name}</span>
            <div class="total-time-div flex-row-center">
                <span class="big">{totalTimeStr}h</span>
                <span class="billable-time big">{billableTimeStr}h</span>
            </div>
        </div>

        <div class="body-main" slot="body">
        {#each daysKeys as day}
        {@const dayStart = days[day].tasks.sort((a, b) => parseInt(a.start) - parseInt(b.start))[0].start}
        {@const dayEnd = days[day].tasks.sort((a, b) => parseInt(b.end) - parseInt(a.end))[0].end}
        {@const dayTime = days[day].tasks.reduce((acc, task) => {
                const { duration, project_id } = task;
                acc.total += duration;
                if (project_id !== '391') acc.billable += duration;
                return acc;
            }, { billable: 0, total: 0 })
        }
        {@const totalTimeDayStr = msToTime(dayTime.total * 60 * 1000)}
        {@const billableTimeDayStr = msToTime((dayTime.billable - days[day].roundOffMin) * 60 * 1000)}
            <ToggleableContainer --bgColor="white">
                <div class="subheader flex-row-center" slot="header">
                    <span>{moment(day, 'DD-MM-YYYY').format('Do MMM, YYYY')}</span>
                    <span>{moment(dayStart, 'x').format('HH:mm')} - {moment(dayEnd, 'x').format('HH:mm')}</span>
                    <div class="total-time-div-day flex-row-center">
                        <span>{totalTimeDayStr}h</span>
                        <span class="billable-time">{billableTimeDayStr}h</span>
                    </div>
                </div>
                <div class="sub-body" slot="body">
                    <table class="w-100">
                        {#each days[day].tasks as {description, project_name, start, end, duration}, index (index)}
                        {@const taskStart = moment(start, 'x').format('HH:mm')}
                        {@const taskEnd = moment(end, 'x').format('HH:mm')}
                        {@const taskTotal = msToTime(duration * 60 * 1000)}
                        <tr class={index % 2 === 0 ? '' : 'bgGray'}>
                            <td>{description}</td>
                            <td>{project_name}</td>
                            <td>{taskStart} - {taskEnd}</td>
                            <td>{taskTotal}</td>
                        </tr>
                        {/each}
                    </table>
                </div>
            </ToggleableContainer>
        {/each}
        </div>
    </ToggleableContainer>
    {/if}
    {/each}
</div>

<style>
    tr {
        height: 24px;
    }

    .bgGray {
        background-color: #fafafa;
    }

    .total-time-div {
        width: 170px;
    }

    .total-time-div-day {
        width: 120px;
    }

    .billable-time {
        color: gray;
    }

    .flex-row-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .container {
        border-radius: 19px;
        padding: 19px;
        gap: 20px;
        display: flex;
        flex-direction: column;
    }

    .w-100 {
        width: 100%;
    }

    .big {
        font-size: 1.2rem;
    }

    .bigger {
        font-size: 1.4rem;
    }

    .header-main {
        margin: 0 auto;
        width: 80%;
    }

    .subheader {
        width: 80%;
        margin: 0 auto;
    }

    .body-main, .sub-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .subheader span {
        font-size: 1rem;
    }

    img {
        width: 52px;
        border-radius: 100%;
    }

</style>