import { getUserId, getRole, setRole } from "./localInfo";
import { post } from "./labit-connection";

export let updateRole = async () => {
  const id = getUserId();
  const savedRole = getRole();
  const response = await post(
    `select Access
        from people
        where contact_id=${id}`
  );
  const role = response[0].Access;
  if (role !== savedRole) {
    setRole(role);
  }
};
