<script>
  export let text;
  export let action;
  export let last;
  export let error = false;
  export let breakOverflow = true;
  export let readOnly = false;
  export let maxLength = null;
  export let onChange = null;
  export let onFocusOut = null;
  export let isNumeric = false;
  export let marginBottom = "8px";

  $: if (text) {
    text = text.replace(/'/g, "´");
    if (isNumeric) {
      text = text.replace(/[^0-9]/g, "");
    }
  }
</script>

{#if action === "show"}
  <span
    class="columnInfo {breakOverflow ? 'breakOverflow' : ''}
    {last ? 'last' : ''}"
  >
    {text}
  </span>
{:else}
  <input
    class="{last ? 'last' : ''}
    {error ? 'error' : ''}"
    type="text"
    style="margin-bottom: {marginBottom}"
    bind:value={text}
    disabled={readOnly}
    maxlength={maxLength}
    on:keyup={() => {
      if (onChange) {
        onChange();
      }
    }}
    on:focusout={() => {
      if (onFocusOut) {
        onFocusOut();
      }
    }}
  />
{/if}

<style>
  span.columnInfo,
  input {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
  }

  span.columnInfo {
    margin-bottom: 8px;
  }

  span.breakOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span.last,
  input.last {
    margin-bottom: 0px;
  }

  input {
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 14px;
    border-color: var(--labit-card-lightgray);
    border-width: 1px;
    border-style: solid;
    height: 17px;
  }

  span:empty:before {
    content: "\200b";
  }

  input.error {
    border-color: red !important;
  }
</style>
