<script>
  export let day;
  export let phase;
  export let dayWidth;
  export let subphase;
  export let dayArray;
  export let startDate;
  export let endDate;
  export let showPlannerTasks = false;
  export let CRPid;
  export let loaded;
  export let drawPhases;
  
  import moment from 'moment';

  let phaseWidth = -1;
  let phaseOffset = -1;

  let weekend = false;
  let today = false;

  let start = 0;
  let end = 0;
  let clipLeft = false;
  let clipRight = false;

  const PHASE_HEIGHT = 25;
  const SUBPHASE_HEIGHT = 17;
  const PHASE_FONTSIZE = 13;
  const SUBPHASE_FONTSIZE = 11;

  let preparePhase = () => {
    phaseOffset = 0;
    phaseWidth = 0;
    if (phase.end < startDate || phase.start > endDate) {
      start = 0;
      end = 0;
    } else {
      start = phase.start;
      end = phase.end;
      clipLeft = false;
      clipRight = false;

      if (phase.start < startDate) {
        start = startDate;
        clipLeft = true;
      }
      if (phase.end > endDate) {
        end = endDate;
        clipRight = true;
      }

      const endOffset = window.$("#" + CRPid + "-" + end).offset();
      const startOffset = window.$("#" + CRPid + "-" + start).offset();
      if (startOffset !== undefined && endOffset !== undefined) {
        phaseWidth = endOffset.left - startOffset.left + parseInt(dayWidth);
      }
    }
  };

  let checkWeekend = () => {
    const ddd = moment(day, "YYYY-MM-DD").format("ddd");
    weekend = ddd === "Sat" || ddd === "Sun";
  };

  let checkToday = () => {
    today = moment().format("YYYY-MM-DD") === day;
  };

  $: if (day) {
    checkWeekend();
    checkToday();
  }

  $: if (drawPhases) {
    if (loaded && phase && dayWidth && dayArray && startDate && endDate) {
      preparePhase();
    }
  }
</script>

<div
  class="cell {phase ? 'anchor' : ''} {weekend ? 'weekend' : ''} {today
    ? 'today'
    : ''} borderRight borderBottom"
  style="width: {dayWidth}px"
>
  {#if phase && phaseWidth > 0}
    <div
      class="phaseBar {clipLeft ? 'clipLeft' : ''} {clipRight
        ? 'clipRight'
        : ''}"
      title={phase.name}
      style="width: {phaseWidth}px; margin-left: {phaseOffset}px;height: {subphase
        ? SUBPHASE_HEIGHT
        : PHASE_HEIGHT}px; font-size: {subphase
        ? SUBPHASE_FONTSIZE
        : PHASE_FONTSIZE}px;"
    >
      <span class="name">{phase.name}</span>
      {#if phase.plannerInfo && showPlannerTasks}
        <div
          class="phaseProgress {clipLeft ? 'clipLeft' : ''} {clipRight
            ? 'clipRight'
            : ''}"
          style="width: {phase.plannerInfo.percCompleted}%"
        />
        <span class="perc">{parseInt(phase.plannerInfo.percCompleted)}%</span>
      {/if}
    </div>
  {/if}
</div>

<style>
  div.cell {
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
  }

  div.phaseBar {
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    padding: 0px 5px 0px 5px;
    cursor: pointer;
    position: relative;
    background-color: var(--labit-people-work-plan);
    z-index: 1;
    position: absolute;
  }

  div.clipLeft {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  div.clipRight {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  div.phaseBar span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span.perc {
    position: absolute;
    bottom: 0px;
    right: 3px;
    font-size: 10px;
  }

  span.name {
    z-index: 1;
  }

  div.phaseProgress {
    border-radius: 5px;
    background-color: var(--labit-people-task);
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
  }
</style>
