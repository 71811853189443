<script>
  export let text;
  export let calendarOptions;
  export let action;
  export let error;
  export let height;
  export let width;
  export let marginBottom;
  export let analytics = false;
  export let onChange = null;

  import CalendarPicker from "../../../../components/CalendarPicker/CalendarPicker.svelte";
</script>

{#if action === "show"}
  <span class="columnInfo ">{text}</span>
{:else}
  <div
    class="element {error ? 'error' : ''}"
    style="height: {height}px !important; width: {width}px !important; margin-bottom: {marginBottom};"
  >
    <CalendarPicker
      bind:date={text}
      options={calendarOptions}
      {analytics}
      {onChange}
    />
  </div>
{/if}

<style>
  span.columnInfo {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-bottom: 8px;
  }

  div.element {
    width: 100%;
    height: 17px;
    margin-bottom: 8px;
  }

  .error {
    border-radius: 960px/960px;
    border: 1px solid red;
  }

  span:empty:before {
    content: "\200b";
  }
</style>
