<script>
  export let text;
  export let action;
  export let last;
  export let error = false;
  export let breakOverflow = true;
  export let suggestions;
  export let onChange = null;
  export let onFocusOut = null;

  import SuggestionEdit from "./SuggestionEdit.svelte";

  $: if (text) {
    text = text.replace(/'/g, "´");
  }
</script>

{#if action === "show"}
  <span
    class="columnInfo {breakOverflow ? 'breakOverflow' : ''}
    {last
      ? 'last'
      : ''}"
  >
    {text}
  </span>
{:else}
  <div class="edit {last ? 'last' : ''}">
    <SuggestionEdit
      bind:text
      {suggestions}
      fontSize="13px"
      borderColor="var(--labit-card-gray)"
      height="22px"
      marginTop="0px"
      paddingLeft="0px"
      paddingRight="0px"
      color="var(--labit-card-gray)"
      {error}
      {onChange}
      {onFocusOut}
    />
  </div>
{/if}

<style>
  span.columnInfo {
    width: 100%;
    font-size: 13px;
    color: var(--labit-card-lightgray);
    margin-bottom: 8px;
  }

  div.edit {
    margin-bottom: 8px;
  }

  span.breakOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span.last,
  div.last {
    margin-bottom: 0px;
  }

  span:empty:before {
    content: "\200b";
  }
</style>
