<script>
  export let width;
  export let height;
  export let checked;
  export let onClick;
  export let readonly;
  export let isStatic = false;
</script>

{#if isStatic}
  <div
    class="ball"
    style="width: {height * 0.8}px; height: {height *
      0.8}px; background-color: {checked ? '#31d64a' : 'white'};"
  />
{:else}
  <div
    class="container {checked ? 'checked' : 'nochecked'}
  {readonly ? '' : 'selectable'}"
    style="width: {width}px; height: {height}px; padding-left: {height * 0.1}px;
  padding-right: {height * 0.1}px; border-radius:19px;"
    on:click={readonly ? null : onClick}
  >
    <div
      class="ballContainer {checked ? 'ballContainerChecked' : ''}"
      style="width: {height * 0.8}px;"
    >
      <div
        class="ball"
        style="width: {height * 0.8}px; height: {height * 0.8}px;"
      />
    </div>
  </div>
{/if}

<style>
  div.container {
    background-color: #31d64a;
  }

  div.selectable {
    cursor: pointer;
  }

  div.checked {
    justify-content: flex-end;
    background-color: #31d64a;
  }

  div.nochecked {
    justify-content: flex-start;
    background-color: var(--labit-dialog-background);
  }

  div.ballContainer {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  div.ball {
    border-radius: 50%;
    background-color: white;
  }

  /* Animations */

  div.ballContainer {
    transition: width 0.5s;
  }

  div.ballContainerChecked {
    width: 100% !important;
  }

  div.checked {
    transition: background-color 0.5s;
    background-color: #31d64a;
  }

  div.nochecked {
    transition: width 0.5s;
    transition: background-color 0.5s;
    background-color: var(--labit-dialog-background);
  }
</style>
