<script>
  export let column;
  export let columnIndex;
  export let firstColumn;
  export let lastColumn;
  export let editLinks;
  export let onSectionRight;
  export let onSectionLeft;
  export let savingLinks;

  import LinkColumnLink from "./LinkColumnLink.svelte";

  import DialogSpanEdit from "../../../../components/DialogSpanEdit.svelte";
  import DialogSmallButton from "../../../../components/DialogSmallButton.svelte";
  import { post } from "../../../../js/labit-connection";

  let showForm = false;
  let currentTitle = "";
  let errorCurrentTitle = false;

  let accId = -1;

  let openForm = (visible) => {
    showForm = visible;
    if (showForm) {
      currentTitle = "";
      errorCurrentTitle = false;
    }
  };

  let checkErrors = () => {
    errorCurrentTitle = false;
    if (currentTitle.trim() === "") {
      errorCurrentTitle = true;
      return true;
    }
    return false;
  };

  let addSection = async () => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        if (!checkErrors()) {
          let section = {
            title: {
              id: accId,
              title: currentTitle,
            },
            links: [],
          };
          accId--;
          column.push(section);

          const response = await post(
            `insert into linkSection (title, groupColumn, pos)
          values ('${section.title.title}', ${columnIndex}, ${
              column.length - 1
            })`
          );
          section.title.id = response[0];

          column = column;

          openForm(false);
        }
        savingLinks = false;
      }
    }
  };

  let deleteSection = async (id) => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        column = column.filter((c) => {
          return c.title.id !== id;
        });

        await post(
          `delete from linkSection
        where id=${id}`
        );
        savingLinks = false;
      }
    }
  };

  let sectionUp = async (id) => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        const pos = column.findIndex((c) => {
          return c.title.id === id;
        });
        if (pos >= 0) {
          const item = column.splice(pos, 1);
          column.splice(pos - 1, 0, item[0]);
          column = column;

          for (let i = 0; i < column.length; i++) {
            const section = column[i];
            await post(
              `update linkSection
            set pos=${i}
            where id=${section.title.id}`
            );
          }
        }
        savingLinks = false;
      }
    }
  };

  let sectionDown = async (id) => {
    if (editLinks) {
      if (!savingLinks) {
        savingLinks = true;
        const pos = column.findIndex((c) => {
          return c.title.id === id;
        });
        if (pos >= 0) {
          const item = column.splice(pos, 1);
          column.splice(pos + 1, 0, item[0]);
          column = column;

          for (let i = 0; i < column.length; i++) {
            const section = column[i];
            await post(
              `update linkSection
            set pos=${i}
            where id=${section.title.id}`
            );
          }
        }
        savingLinks = false;
      }
    }
  };

  //$: console.log(column);
</script>

<div class="linkColumnContainer">
  {#each column as link, index}
    <LinkColumnLink
      bind:link
      {columnIndex}
      {firstColumn}
      {lastColumn}
      firstLink={index === 0}
      lastLink={index === column.length - 1}
      {editLinks}
      onDeleteSection={(id) => deleteSection(id)}
      onSectionUp={(id) => sectionUp(id)}
      onSectionDown={(id) => sectionDown(id)}
      {onSectionRight}
      {onSectionLeft}
      bind:savingLinks
    />
  {/each}
  {#if editLinks}
    <div class="options">
      <img
        class="fadedButton"
        src="/images/plus.svg"
        alt="Add new section"
        on:click={() => openForm(!showForm)}
      />
    </div>
    {#if showForm}
      <div class="form">
        <span class="requiredElement">Section Title</span>
        <DialogSpanEdit
          bind:text={currentTitle}
          action="edit"
          maxLength={50}
          error={errorCurrentTitle}
          last={false}
        />
        <div class="titleButtons">
          <DialogSmallButton text="Add" onClick={() => addSection()} />
          <DialogSmallButton text="Close" onClick={() => openForm(false)} />
        </div>
      </div>
    {/if}
  {/if}
</div>

<style>
  div.linkColumnContainer {
    width: 16%;
  }

  div.options {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 20px 0px 10px 0px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  div.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    color: var(--labit-card-gray);
  }

  div.titleButtons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 8px;
  }
</style>
