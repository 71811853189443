<script>
  export let title;
</script>

<style>
  div.container {
    width: 14.28% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    font-weight: bold;
  }
</style>

<div class="container">
  <span>{title}</span>
</div>
