<script>
    export let options;
    export let selected = 1;
    export let title = false;
</script>

<div class="radio-container noselect">
    <label>
        <span class="{options["1"].active ? 'dot' : ''}"></span>
        <input type="radio" bind:group={selected} value={options["1"].id} />
        <span class="option" class:title={title} class:selected={selected === options["1"].id}>{options["1"].value}</span>
    </label>

    <label>
        <span class="{options["2"].active ? 'dot' : ''}"></span>
        <input type="radio" bind:group={selected} value={options["2"].id} />
        <span class="option" class:title={title} class:selected={selected === options["2"].id}>{options["2"].value}</span>
    </label>
</div>

<style>
    .radio-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }

    input {
        display: none;
    }
 
    label {
      position:relative;
      cursor: pointer;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dot {
      position: absolute;
      top: .4rem;
      right: .4rem;
      width: .4rem;
      height: .4rem;
      background-color: #696cff;
      border-radius: 50%;
    }

    .option {
        padding: 8px;
        border-radius: 20px;
    }

    .selected {
        background-color: #efefef;
    }

    .title {
        font-size: 15px;
        color: var(--labit-card-gray);
        font-weight: bold;
    }

    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
    }

    .radio-container label:first-child {
        border-right: 1px solid #e9e9e9;
    }
</style>
