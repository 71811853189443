<script>
  export let dashboard = false;

  import { post } from "../../../../js/labit-connection";
  import { getUserId } from "../../../../js/localInfo";

  import { onMount, afterUpdate } from "svelte";

  /* const BIRTHDAYBACKGROUNDCOLOR = "#E0EB49";
  const BIRTHDAYTEXTCOLOR = "black"; */
  const HOLIDAYSBACKGROUNDCOLOR = "purple";
  const GENERALEVENTBACKGROUNDCOLOR = "#34495E";
  const DELIVER_COLOR = "red";

  let myId = getUserId();

  import moment from 'moment';
  let currentDate = moment();
  let dateOptionSelected = currentDate.format("MMMM YYYY");

  let calendar;
  let fullCalendar;
  let eventsFiltered = [];

  onMount(async () => {
    await getEvents();
    createCalendar();
  });

  afterUpdate(() => {
    createCalendar();
  });

  let getEvents = async () => {
    const holidaysPromise = post( `SELECT p.name, c.name, c.city, g.id, g.name, g.startDate, g.location  FROM people AS p LEFT JOIN companies AS c ON p.company_id = c.company_id LEFT JOIN general_holidays AS g ON c.city = g.location WHERE p.contact_id = ${myId};`);
    const generalEventsPromise = post( `SELECT * FROM events WHERE type = "general" and createdBy = ${myId};`);
    const milestonesPromise = post(`select m.name as deliver, m.start as day, m.type, p.name as project from milestone m join projects p on p.project_id = m.project where start > DATE_SUB(CURRENT_TIMESTAMP, INTERVAL 1 DAY) order by start asc;`);

    const [holidays, generalEvents, milestones] = await Promise.all([holidaysPromise, generalEventsPromise, milestonesPromise]);

    const holidaysArr = holidays.reduce((acc, curr) => {
      acc.push({
        id: "holiday-" + curr.id,
        title: curr.name,
        allDay: false,
        start: curr.startDate,
        end: curr.startDate,
        type: "holiday",
        location: curr.location,
        backgroundColor: HOLIDAYSBACKGROUNDCOLOR,
        borderColor: HOLIDAYSBACKGROUNDCOLOR,
        textColor: HOLIDAYSBACKGROUNDCOLOR,
        description: curr.name,
        visible: true,
      });
      return acc;
    }, []);

    eventsFiltered = eventsFiltered.concat(holidaysArr);

    const eventsArr = generalEvents.reduce((acc, curr) => {
      acc.push({
        id: "generalEvent-" + curr.id,
        title: curr.description,
        allDay: null,
        start: curr.startDate + "T" + curr.startTime,
        end: curr.endDate + "T" + curr.endTime,
        type: "general",
        location: null,
        backgroundColor: GENERALEVENTBACKGROUNDCOLOR,
        borderColor: GENERALEVENTBACKGROUNDCOLOR,
        textColor: "white",
        description: curr.description,
        visible: true,
      });
      return acc;
    }, []);

    eventsFiltered = eventsFiltered.concat(eventsArr);

    const milestonesArr = milestones.reduce((acc, item) => {
      const { deliver, day, type, project } = item;
      acc.push({
        id: `${project} - ${deliver} - ${type}`,
        description: `${project} - ${deliver} - ${type}`,
        title: `${project} - ${deliver} - ${type}`,
        allDay: null,
        start: day.replace(' ', 'T'),
        end: day.replace(' ', 'T'),
        type: "general",
        location: null,
        backgroundColor: DELIVER_COLOR,
        borderColor: DELIVER_COLOR,
        textColor: "white",
        visible: true,
      });
      return acc;
    }, []);

    eventsFiltered = eventsFiltered.concat(milestonesArr);

  };

  let createCalendar = () => {
    fullCalendar = new FullCalendar.Calendar(calendar, {
      initialView: "dayGridMonth",
      headerToolbar: false,
      height: "100%",
      initialDate: currentDate.format("YYYY-MM-DD"),
      fixedWeekCount: false,
      dayMaxEvents: 2, // allow "more" link when too many events
      weekNumberCalculation: "ISO", // Sets the first day of the week at Monday
      dayHeaderFormat: { weekday: "short" },
      eventDidMount: function (info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.extendedProps.description,
          placement: "top",
          trigger: "hover",
          container: "body",
        });
      },
      events: eventsFiltered,
    });
    fullCalendar.render();
  };

  let clickNext = () => {
    currentDate.add(1, "months");
    dateOptionSelected = currentDate.format("MMMM YYYY");
  };
  let clickPrev = () => {
    currentDate.subtract(1, "months");
    dateOptionSelected = currentDate.format("MMMM YYYY");
  };
</script>

<div class={dashboard ? "monthlyCalendarDashboard" : "monthlyCalendar"}>
  <div class="header">
    <div class="monthSearch">
      <div class="leftArrow" on:click={() => clickPrev()}>
        <img src="/images/calendar/left_arrow.svg" alt="Left Arrow" />
      </div>
      <div class="monthSelected">
        <span>{dateOptionSelected}</span>
      </div>
      <div class="rightArrow" on:click={() => clickNext()}>
        <img src="/images/calendar/right_arrow.svg" alt="Right Arrow" />
      </div>
    </div>
  </div>
  <div class="divCalendar">
    <div class="calCalendar" bind:this={calendar} />
  </div>
</div>

<style>
  div.monthlyCalendar {
    height: 384px;
    width: 100%;
    margin-top: 16px;
    background-color: white;
    border-radius: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  div.header {
    height: 15%;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.monthSearch {
    height: 29px;
    width: 90%;
    background-color: var(--fullcalendar-header-monthlycalendar);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-right: 10px;
    padding-left: 10px;
  }

  div.divCalendar {
    height: 90%;
    width: 90%;
  }

  div.calCalendar {
    height: 90%;
    width: 100%;
  }

  div.leftArrow,
  div.rightArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 15px;
    cursor: pointer;
  }

  div.monthSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 70%;
  }

  img {
    height: 15px;
    width: 15px;
  }

  span {
    color: var(--labit-card-gray);
    font-weight: bold;
    font-size: 15px;
  }
</style>
