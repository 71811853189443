<script>
  export let iframeTitle = "";
  export let id;
  export let src;
  // export let webpreferences = "nativeWindowOpen=no, javascript=no, allowRunningInsecureContent=no, experimentalFeatures=no, nodeIntegrationInSubFrames=no, nodeIntegrationInSubFrames=no";
  /* export let useragent = "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36"; */
  export let useragent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36"; 


  import { shortcut } from "../js/shortcut";
  import { getPlatform, setOpenURL } from "../js/localInfo";
  import { onMount } from "svelte";

  const platform = getPlatform();

  let showMenu = localStorage.getItem('showMenu') || 'true';
  showMenu = showMenu === 'true';

  let webview = null;
  let url = src;

  onMount(() => {
    if (platform === "APP") {
      webview = document.querySelector(`#${id}`);

      toggleBorderRadius(showMenu);
      webview.addEventListener("did-navigate", (e) => url = e.url );
      webview.addEventListener("did-navigate-in-page", (e) => url = e.url );
      webview.addEventListener("context-menu", (ev) => console.log({ params: ev.params }) );
    }
  });

  const goBack = () => {
    webview.goBack();
  };

  const goForward = () => {
    webview.goForward();
  };

  const refresh = () => {
    webview.reload();
  };

  const copyToClipboard = () => navigator.clipboard.writeText(src);

  const shrinkMenu = () => {
    showMenu = false;
    toggleBorderRadius(showMenu);
  };
  
  const openURL = (defaultBrowser) => {
    if (defaultBrowser) {
      window.open("/openexternal/#/" + currentUrl);
    } else {
      setOpenURL(currentUrl);
      setOpenURLUserAgent(useragent);
      window.open("/urlbrowser");
    }
  };

  const toggleBorderRadius = (showMenu) => {
    const iframe = webview.shadowRoot.querySelector("iframe");
    if (showMenu) {
      iframe.style.borderRadius = "0px 0px 19px 19px";
    } else {
      iframe.style.borderRadius = "19px 19px 19px 19px";
    }
    localStorage.setItem('showMenu', showMenu);
  }

  const toggleShrinkMenu = () => {
    showMenu = !showMenu;
    toggleBorderRadius(showMenu);
    localStorage.setItem('showMenu', showMenu);
  };

</script>

<div class="webBrowserContainer">
  {#if platform !== "APP"}
    <iframe id={id} title={iframeTitle} {src} />
  {:else}
    <div use:shortcut={{ shift: true, code: 'Digit4', callback: () => { toggleShrinkMenu(); } }}></div>
    {#if src !== "" && showMenu}
      <div class="header roundedHeader">
        <img
          class="fadedButton"
          src="/images/navigation/back.svg"
          alt="Go Back"
          on:click={() => goBack()}
        />
        <img
          class="fadedButton"
          src="/images/navigation/forward.svg"
          alt="Go Forward"
          on:click={() => goForward()}
        />
        <img
          class="fadedButton"
          src="/images/navigation/refresh.svg"
          alt="Refresh"
          on:click={() => refresh()}
        />
        <div class="url">
          <span>{url}</span>
        </div>
        <img
          class="fadedButton"
          src="/images/navigation/copy.svg"
          alt="Copy To Clipboard"
          on:click={() => copyToClipboard()}
        />
        <img
          class="fadedButton"
          src="/images/navigation/shrink.svg"
          alt="Shrink Menu"
          on:click={() => shrinkMenu()}
        />
        <img
          class="fadedButton"
          src="/images/navigation/browser.svg"
          alt="Open Website"
          on:click={() => openURL(false)}
        />
        <img
          class="fadedButton"
          src="/images/navigation/out.svg"
          alt="Open in Browser"
          on:click={() => openURL(true)}
        />
      </div>
    {/if}

    <webview {id} {src} {useragent} allowpopups/>
  {/if}
</div>

<style>
  div.webBrowserContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  div.header {
    background-color: var(--labit-dialog-background);
    display: flex;
    padding: 7px 12px 7px 12px;
    justify-content: space-between;
  }

  div.header img {
    height: 25px;
  }

  div.roundedHeader {
    border-radius: 10px 10px 0px 0px;
  }

  div.header img:not(:last-child) {
    margin-right: 10px;
  }

  div.url {
    background-color: white;
    display: flex;
    flex-grow: 1;
    border-radius: 5px;
    align-items: center;
    margin-right: 10px;
    font-size: 13px;
    color: var(--labit-card-gray);
    padding: 0px 5px 0px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div.url span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  iframe,
  webview {
    width: 100%;
    display: flex;
    flex-grow: 1;
    border-radius: 19px;
    border-style: none;
  }
</style>