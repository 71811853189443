<script>
  export let workPeriod;
  import moment from 'moment';
</script>

<div class="userCardWorkPeriodsContainer">
  <span class="description">{workPeriod.description}</span>
  <span class="dates"
    >{moment(workPeriod.start, "YYYY-MM-DD").format("DD/MM/YYYY")} - {moment(
      workPeriod.end,
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY")}</span
  >
</div>

<style>
  div.userCardWorkPeriodsContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  div.userCardWorkPeriodsContainer:not(:last-child) {
    margin-bottom: 10px;
  }

  span.description {
    font-size: 13px;
    color: var(--labit-card-gray);
    width: 100%;
  }

  span.dates {
    font-size: 11px;
    color: var(--labit-card-lightgray);
    width: 100%;
    text-align: right;
  }
</style>
