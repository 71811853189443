<script>
  export let creationInfo;
  export let modificationInfo;
  export let onRemove;
  export let showRemove = true;
  import moment from 'moment';

  import Picture from "./Picture.svelte";
</script>

<div class="editSectionContainer">
  <div class="menu">
    <span>EDITION MODE</span>
    {#if modificationInfo}
      <span>LAST MODIFICATION</span>
      <div class="menuInfo">
        <div class="menuPic">
          <Picture
            image={modificationInfo.pic}
            name={modificationInfo.name}
            grayscale={true}
          />
        </div>
        <span>{modificationInfo.name}</span>
        <span>-</span>
        <span
          >{moment(modificationInfo.date, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )}</span
        >
      </div>
    {/if}
    {#if creationInfo}
      <span>CREATION DATE</span>
      <div class="menuInfo">
        <div class="menuPic">
          <Picture
            image={creationInfo.pic}
            name={creationInfo.name}
            grayscale={true}
          />
        </div>
        <span>{creationInfo.name}</span>
        <span>-</span>
        <span
          >{moment(creationInfo.date, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )}</span
        >
      </div>
    {/if}
  </div>
  {#if showRemove}
    <div class="buttonDelete fadedButton" on:click={onRemove}>
      <img src="/images/trash_white.svg" alt="" />
    </div>
  {/if}
</div>

<style>
  div.editSectionContainer {
    width: 100%;
    display: flex;
    height: 85px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  div.menu {
    width: 1510px;
    height: 100%;
    background-color: #d7e1ea;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  div.menu span {
    color: var(--labit-card-gray);
    font-size: 15px;
  }

  div.menuInfo {
    display: flex;
    align-items: center;
  }

  div.menuInfo span {
    margin-right: 10px;
  }

  div.menuPic {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  div.buttonDelete {
    width: 118px;
    height: 100%;
    background-color: #ff7777;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  div.buttonDelete img {
    width: 30px;
  }
</style>
