<script>
  export let url;
  export let onPreviewFile;
  export let isPayroll = false;
  export let totalFiles;
  import moment from 'moment';

  import FileBoobleFileSimple from "./FileBoobleFileSimple.svelte";

  import { rpost } from "../js/labit-connection";

  import { onMount } from "svelte";

  let expanded = true;
  let expandedList = [];

  let files = [];

  onMount(async () => {
    files = await loadRemoteURL(url);
    if (isPayroll) {
      let payrollFiles = [];
      files.forEach((item) => {
        item.files.forEach((f) => {
          f.name = item.name + " - " + f.name;
          payrollFiles.push(f);
        });
      });
      files = payrollFiles;
    }
    totalFiles = files.length;
    console.log(url);
    console.log(files);
  });

  let loadRemoteURL_ = (remoteURL, file) => {
    let f = null;
    //console.log(file);
    if (file.folder === "1") {
      f = {
        id: remoteURL + "/" + file.name,
        name: file.name,
        folder: true,
        expanded: false,
        files: [],
      };

      // Keep expanded?
      const pos = expandedList.findIndex((item) => {
        return item === f.id;
      });
      f.expanded = pos >= 0;

      file.files.forEach((F) => {
        const F_ = loadRemoteURL_(f.id, F);
        f.files.push(F_);
      });
    } else {
      f = {
        id: remoteURL + "/" + file.name,
        name: file.name,
        folder: false,
        extension: file.extension,
        size: Math.ceil(parseInt(file.size) / 1024) + " KB",
        lastModified: moment(
          file.modificationDate,
          "YYYY-MM-DD HH:mm:ss"
        ).format("DD/MM/YYYY HH:mm:ss"),
      };
    }
    return f;
  };

  let loadRemoteURL = async (remoteURL) => {
    let data = await rpost("ListFolderFull", { path: remoteURL });
    data = data.map((file) => {
      return loadRemoteURL_(remoteURL, file);
    });
    return data;
  };

  let findFileWithId_ = (id, found, file) => {
    let fileOb = null;
    if (file.id === id) {
      fileOb = file;
      found[0] = true;
    } else {
      if (file.folder) {
        file.files.forEach((child) => {
          if (!found[0]) {
            fileOb = findFileWithId_(id, found, child);
          }
        });
      }
    }
    return fileOb;
  };

  let findFileWithId = (id) => {
    let found = [false];
    let fileOb = null;
    files.forEach((file) => {
      if (!found[0]) {
        fileOb = findFileWithId_(id, found, file);
      }
    });
    return fileOb;
  };

  let previewFile = async (id) => {
    onPreviewFile(id);
  };
</script>

<div class="fileContainerContainer">
  <div class="body">
    {#each files as file}
      {#if !file.folder}
        <FileBoobleFileSimple {file} onPreviewFile={(id) => previewFile(id)} />
      {/if}
    {/each}
  </div>
</div>

<style>
  div.fileContainerContainer {
    width: 100%;
    background-color: white;
    border-radius: 14px;
    position: relative;
  }

  div.body {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    font-size: 15px;
  }
</style>
