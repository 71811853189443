<script>
    //import Menu from "../../components/Menu.svelte";
    
    import { post } from "../../../js/labit-connection";
    import { getUserId } from "../../../js/localInfo";
    
    import Picture from "../../../components/Picture.svelte";
    import Combobox from "../../../components/Combobox.svelte";
    import Popup from "../../../components/Popup.svelte";

    import DetailCard from "./DetailCard.svelte";
    import Item from "./Item.svelte";
    

    let selectedQR = {label: "", value: ""}, selectedUser = {label: "", value: ""};
    let usersData = {}, qrsData = {}, user = {}, itemProps = {};
    let visual = "finders";
    let validQR = true, validMail = true;
    let acceptedAction = false, showPopup = false;
    let qrs = [], users = [];
    let popupText = "", popupType = "", popupAction = "";
    let myId = getUserId();

    // $: showPopup = showPopup;

    const modifyProperty = (event) => {
        const prop = event.detail.prop;
        let key = prop.title;
        itemProps[key] = prop.prop;
    }

    const clickedPopup = async (event) => {
        acceptedAction = event.detail.accepted;
        //popupAction = event.detail.action;
        showPopup = false;
        
        if(acceptedAction) {
            const qrId = selectedQR.value;
            if (popupAction === "edit") {
                const userId = selectedUser.value;
                const oldUser = usersData[qrsData[qrId].user_id]; // PASAR A QUERY
                if (oldUser === undefined) {
                    await post( `update inventory set user_id=${userId} where tag="${selectedQR.label}";` );
                } else {
                    await post( `update inventory set user_id=${userId} where id="${qrId}";` );
                }

            } else if (popupAction === "unassign") {
                await post( `update inventory set user_id=NULL where id="${qrId}";` );
            }
            await getQRs();
        }
    }

    const dispatchedNotOwnQR = async (event) => {
        selectedQR = event.detail.qr;

        popupText = "Do you want to unassign this item?";
        popupType = "prompt";
        showPopup = true;
    }
    
    const dispatchedOwnQR = async (event) => {
        selectedQR = event.detail.qr;

        const res = await post( `select p.name, i.user_id from people p join inventory i on p.contact_id = i.user_id where i.id = ${selectedQR.value};` );
        showPopup = true;
        
        if (res.length === 0) { // Si existe en el array de qrs sin asignar
            popupText = "Now this item is assigned to you.";
            popupType = "info";
        } else {
            if (myId.toString() === (res[0].user_id).toString()) {
                popupText = `This item actually belongs to you!`;
                popupType = "alert";
            } else {
                popupText = `This item actually belongs to ${res[0].name}. Are you sure?`;
                popupType = "prompt";
            }
        }
        
    }

    const dispatchedEditQR = (event) => {
        selectedQR = event.detail.qr;
        getData("qr");
    }

    const checkQRInput = (qr) => { // PARA COMPROBAR SI EL QR EXISTE
        let res = false;
        for (var i = 0, l = qrs.length; i < l; i++) {
            if (qrs[i].label === qr) {
                res = true;
                break;
            }
        }
        return res;
    }
    
    const checkMailInput = (email) =>  {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email) ? email.includes('@labit.es') : false;
    }

    const getData = (type) => {
        if (type === "qr") {
            validQR = checkQRInput(selectedQR.label);

            if (validQR) {
                user = usersData[qrsData[selectedQR.value].user_id];
                visual = "gotQR";
            }
        } else if (type === "user") {
            user = usersData[selectedUser.value];
            validMail = checkMailInput(user.email);
            if (validMail) {
                visual = "gotUser";
            }
        }
    };

    const onKeyPress = (e,func) => {
        if (e.charCode === 13) {
            if(func === "qr") {
                getData("qr");
            } else if (func === "email") {
                getData("user");
            } else if (func === "userPic") {
                getPicture();
            }
        }
    };
    
    const saveChanges = async () => { // TODO

        let query = `UPDATE inventory set `;

        for (let key in itemProps) {
            query += `${key}="${itemProps[key]}", `;
        }

        query = query.slice(0, -2);
        query += ` where id=${selectedQR.value};`;

        //console.log(query);
        await post(query);
        visual = "finders";
        await getQRs();

        /*
        
        for (var i = 0, ) {

        }
        
        let query = `UPDATE inventory set
            category='${itemProps.Category}',
            type='${itemProps.Type}',
            tag='${itemProps.Tag}',
            brand='${itemProps.Brand}',
            model='${itemProps.Model}',
            PN='${itemProps["Product Number"]}',
            SN='${itemProps["Serial Number"]}',
            prod_id_1='${itemProps["ID 1"]}',
            prod_id_2='${itemProps["ID 2"]}',
            processor='${itemProps.Processor}',
            graphic_card='${itemProps["Graphic Card"]}',
            ram='${itemProps.RAM}',
            hdd='${itemProps.HDD}',
            size='${itemProps.Size}',
            color='${itemProps.Color}',
            purchase_date='${itemProps["Purchase Date"]}',
            shop='${itemProps.Shop}',
            invoice='${itemProps.Invoice}',
            status='${itemProps.Status}',
            warranty_time='${itemProps["Warranty Time"]}',
            insurance_time='${itemProps["Insurance Time"]}',
            location='${itemProps.Location}',
            user_id='${user.id}'

            WHERE id='${selectedQR.value}'`;

        //await post( query );
        console.log(query);
        itemProps = {};
        */
    };

    const getQRs = async () => {
        // Limpio los datos anteriores
        qrs = [];
        qrsData = {};
        for (let key in usersData) {
            usersData[key].qrs = [];
        }

        // Obtengo los datos nuevos
        const res = await post( 'select id, category, type, accesories, tag, brand, model, PN, SN, prod_id_1, prod_id_2, processor, graphic_card, ram, hdd, size, color, purchase_date, shop, invoice, PO, status, warranty_time, insurance_time, location, asignable, user_id from inventory;' );
        for (var i = 0, l = res.length; i < l; i++) {
            const elem = res[i];
            qrs = [...qrs, {label: elem.tag, value: elem.id} ];
            qrsData[elem.id] = { user_id: elem.user_id, category: elem.category, type: elem.type, accesories: elem.accesories, tag: elem.tag, brand: elem.brand, model: elem.model, PN: elem.PN, SN: elem.SN, prod_id_1: elem.prod_id_1, prod_id_2: elem.prod_id_2, processor: elem.processor, graphic_card: elem.graphic_card, ram: elem.ram, hdd: elem.hdd, size: elem.size, color: elem.color, purchase_date: elem.purchase_date, shop: elem.shop, invoice: elem.invoice, PO: elem.PO, status: elem.status, warranty_time: elem.warranty_time, insurance_time: elem.insurance_time, location: elem.location, asignable: elem.asignable } ;
            
            //Añado cada uno de los qr a su dueño
            if (elem.user_id && usersData.hasOwnProperty(elem.user_id)) {
                usersData[elem.user_id].qrs = [...usersData[elem.user_id].qrs, elem.id];
            }
        }
    }

    const getUsers = async () => {

        const res = await post( 'select contact_id, name, email, picPath from people where company_id = 2 and active = 1 order by name;' );

        users = [];
        usersData = {};
        for (var i = 0, l = res.length; i < l; i++) {
            const elem = res[i];
            users = [...users, {label: elem.name, value: elem.contact_id} ];
            usersData[elem.contact_id] = {name: elem.name, email: elem.email, picture: elem.picPath, qrs: []};
        }

        await getQRs();
    };

    const back = () => visual = "finders";

    getUsers();

</script>

<svelte:head>
    <title>SkyLab - Inventory</title>
</svelte:head>
<div class="dialogContent">

    {#if visual === "finders"}
            
        <div class="container">
            <div class="qr_finder">
                <img id="qr_image" src="/images/qr-code.svg" alt="qr code">
                <div class="wh-auto" on:keypress={(e) => onKeyPress(e, "qr")}>
                    {#if validQR}
                        <Combobox
                            options={qrs}
                            bind:selectedValue={selectedQR}
                            placeholder="QR code..."
                            width="100%"
                            persistentText=true
                        />
                    {:else}
                        <Combobox
                            options={qrs}
                            bind:selectedValue={selectedQR}
                            placeholder="QR code..."
                            width="100%"
                            persistentText=true
                            backgroundColor="#ff404063"
                        />
                    {/if}
                </div>
                <img class="find pointer" src="/images/loupe.svg" alt="" on:click={() => getData("qr")}>
            </div>

            <div class="user_finder">
                <img id="user_image" src="/images/user.svg" alt="user">
                <div class="wh-auto" on:keypress={(e) => onKeyPress(e, "email")}>
                    {#if validMail}
                        <Combobox
                            options={users}
                            bind:selectedValue={selectedUser}
                            placeholder="Email..."
                            width="100%"
                            persistentText=true
                        />
                    {:else}
                        <Combobox
                            options={users}
                            bind:selectedValue={selectedUser}
                            placeholder="Email..."
                            width="100%"
                            persistentText=true
                            backgroundColor="#ff404063"
                        />
                    {/if}
                </div>
                <img class="find pointer" src="/images/loupe.svg" alt="" on:click={() => getData("user")}>
            </div>
        </div>
    
    {:else if visual === "gotQR"}

        <div class="qr_details">
            <div class="user_field">
                <img class="pointer" src="/images/arrow-left.svg" width="50px" on:click={back} alt="">
                <div class="user_pic pointer" on:click={() => { selectedUser = { label: usersData[qrsData[selectedQR.value].user_id].name, value: qrsData[selectedQR.value].user_id }; getData("user")}}>
                    <Picture image="https://skylab.labit.es/uploads{user.picture}"/>
                </div>

                <input class="w-100 userInput {validMail ? "" : "error"}" type="text" name="user" id="user" bind:value={user.email} on:keypress={(e) => onKeyPress(e, "userPic")} on:blur={() => getPicture()}/>

                <img class="pointer save_btn" src="/images/save.svg" alt="save" on:click={saveChanges}>
                <img class="pointer trash_btn" src="/images/trash.svg" alt="trash" on:click={null}>
            </div>

            <div class="details">
                {#each Object.entries(qrsData[selectedQR.value]) as [prop_key, prop_val] (prop_key) }
                    <DetailCard
                        title={prop_key}
                        prop={prop_val}
                        on:modProp={modifyProperty}
                    />
                {/each}
            </div>
        </div>

    {:else if visual === "gotUser"}   

        <div class="user_details">
            <div class="user_field">
                <img class="pointer" src="/images/arrow-left.svg" width="50px" on:click={back} alt="">
                <div class="user_pic">
                    <Picture image="https://skylab.labit.es/uploads{user.picture}"/>
                </div>
                <span>{user.name}</span>
            </div>
            <div class="details">
                {#if showPopup}
                    <div class="alertDiv">
                        <Popup
                            {popupType}
                            {popupText}
                            on:dispatchedClick={clickedPopup}
                        />
                    </div>
                {/if}
                {#each user.qrs as item (item)}
                    <Item
                        qrList={qrs}
                        type={qrsData[item].type || qrsData[item].category}
                        label={qrsData[item].tag}
                        value={item}
                        mine={myId.toString() === qrsData[item].user_id}
                        on:dispatchedEditQR={(event) => { popupAction = "edit"; dispatchedEditQR(event) }}
                        on:dispatchedNotOwnQR={(event) => { popupAction = "unassign"; dispatchedNotOwnQR(event) }}
                    />
                {/each}

                <Item
                    qrList={qrs}
                    toAssign={true}
                    on:dispatchedOwnQR={(event) => { popupAction = "edit"; dispatchedOwnQR(event) }}
                />
                <!--
                <div class="addItemContainer">
                    <img class="pointer icon" src="/images/plus.svg" alt="add new item" on:click={createItem}/>
                </div>
                -->
            </div>
        </div>

    {/if}
</div>

<style>
    div.dialogContent{
        background-color: var(--labit-background-gray);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    
    .container, .qr_details, .user_details {
        border-radius: 19px;
        width: 1640px;
        height: 87%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .qr_details, .user_details {
        background-color: white;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        align-content: space-around;
    }

    .user_field {
        padding-top: 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 20%;
    }

    .details {
        flex-direction: column;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        align-content: center;    
        height: 70%;
        width: 80%;
        display: flex;
        padding-right: 2%;
    }

    .user_pic {
        margin-top: 40px;
        width: 144px;
        height: 144px;
    }
    .user_field span {
        margin-top: 10px;
    }

    div.qr_finder, div.user_finder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
        background-color: white;
        height: 80%;
        border-radius: 19px;
    }

    #qr_image, #user_image {
        margin-bottom: 110px;
        width: 120px;
    }

    .wh-auto {
        width: auto;
        height: auto;
    }

    .find {
        width:48px;
        margin-top: 20px;
    }

    .pointer {
        cursor: pointer;
    }

    .save_btn {
        width: 40px;
        margin-top: 150px;
    }
    .trash_btn {
        width: 40px;
        margin-top: 20px;
    }

    .userInput {
        margin-top: 20px;
        text-align:center;
        background-color: white;
        border-style: solid;
        border-color: var(--labit-gray);
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 960px/960px;
        font-size: 12px;
        position: relative;
    }

    .error {
        background-color: #ff404063;
    }
	input[type="text"] {
		border: none;
		text-align: center;
	}
    .alertDiv {
        position: absolute;
        width: 350px;
        height: 200px;
        top: 40%;
        left: 41%;
        border-radius: 10px;
        background-color: #d7e1ea;
        z-index: 1;
        box-shadow: 2px 1px 3px 0px #afafaf;
    }
</style>