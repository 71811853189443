import { post } from "./labit-connection";
import { getOS } from "./localInfo";

export let checkSkylabVersion = async () => {
  let response = null;
  let version = "-1";
  if (getOS === "windows") {
    response = await post(
      `select bundleVersion
                from skylabVersions
                where platform='windows'`
    );
  } else {
    response = await post(
      `select bundleVersion
                from skylabVersions
                where platform='mac'`
    );
  }
  if (response.length > 0) {
    version = response[0].bundleVersion;
  }
  return version;
};
