<script>
	export let title;
	export let x;
	export let y;
</script>

<div style="top: {y}px; left: {x}px;">{title}</div>

<style>
	div {
		border: none;
		box-shadow: 1px 1px 1px #ddd;
		background: #ebf0f5e4;
		border-radius: 12px;
		padding: 8px;
		position: absolute;
		z-index: 10;
	}
</style>
