<script>
  export let program;
  export let onClick;
  export let onSelect;
  export let onEdit;
  export let onRemove;
  export let removingProgram;
  export let selected;

  import ProgramIcon from "./ProgramIcon.svelte";
</script>

<div class="standardProjectProgramContainer" on:click={onClick}>
  <div class="programInfo" on:click={onSelect}>
    <div class="icon">
      <ProgramIcon image={program.icon} color={program.color} />
    </div>
    <span class={selected ? "selected" : ""}>{program.name}</span>
  </div>
  <div class="options">
    <img
      class="fadedButton"
      src="/images/edit.svg"
      alt="Edit Program"
      on:click={(ev) => {
        ev.stopPropagation();
        onEdit();
      }}
    />
    <img
      class="fadedButton last {removingProgram === program.id
        ? 'hiddenElement'
        : ''}"
      src="/images/trash.svg"
      alt="Remove Program"
      on:click={(ev) => {
        ev.stopPropagation();
        onRemove();
      }}
    />
    <img
      class="fadedButton last {removingProgram !== program.id
        ? 'hiddenElement'
        : ''}"
      src="/images/trash-red.svg"
      alt="Remove Program"
      on:click={(ev) => {
        ev.stopPropagation();
        onRemove();
      }}
    />
  </div>
</div>

<style>
  div.standardProjectProgramContainer {
    width: 100%;
    display: flex;
  }

  div.standardProjectProgramContainer:not(:last-child) {
    margin-bottom: 5px;
  }

  div.programInfo {
    width: 80%;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--labit-card-gray);
    cursor: pointer;
  }

  div.options {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }

  div.options img {
    width: 20px;
  }

  div.options img:not(.last) {
    margin-right: 5px;
  }

  div.icon {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  span.selected {
    font-weight: bold;
  }
</style>
