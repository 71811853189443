<script>
  export let id;
  export let epId;
  export let icon;
  export let categories;
  export let editMode;
  export let onSelect;
  export let currentRoom;
  export let standardId;
  export let onClickPDF;

  import ButtonIconEdit from "./ButtonIconEdit.svelte";
  import RDSGKeyCategory from "./RDSGKeyCategory.svelte";
  import RDSGKeyEdit from "./RDSGKeyEdit.svelte";
  import SuggestionEdit from "./SuggestionEdit.svelte";

  import { post } from "../js/labit-connection";

  let editingCategoryIndex = -1;
  let editingCategoryName = "";

  let editingCategoryUrl = "";
  let creatingCategory = null;
  let creatingCategoryName = "";
  let editingKeyName = "";
  let editingKeySearch3D = "";
  let editingKeyCategoryIndex = -1;
  let editingKeyKeyIndex = -1;
  let creatingKey = null;
  let creatingKeyName = "";
  let creatingKeySearch3D = "";
  let creatingKeyCategoryIndex = -1;

  let editCategory = () => {
    editingCategoryName = categories[editingCategoryIndex].name;
    editingCategoryUrl = categories[editingCategoryIndex].url;
    resetNumDeletedCategories();
    resetNumDeletedKeys();
  };

  let closeEditCategory = () => {
    editingCategoryIndex = -1;
    editingCategoryName = "";
    editingCategoryUrl = "";
  };

  let saveEditCategory = () => {
    categories[editingCategoryIndex].name = editingCategoryName;
    categories[editingCategoryIndex].url = editingCategoryUrl;
    let response = post(
      `update attributes
        set
        Name='${categories[editingCategoryIndex].name}',
        url='${categories[editingCategoryIndex].url}'
        where attribute_id=${categories[editingCategoryIndex].id}`
    );
    categories = categories;
    closeEditCategory();
    resetNumDeletedCategories();
    resetNumDeletedKeys();
  };

  let removeEditCategory = async () => {
    resetNumDeletedCategories(categories[editingCategoryIndex].id);
    resetNumDeletedKeys();
    categories[editingCategoryIndex].numDeleted++;
    if (categories[editingCategoryIndex].numDeleted === 2) {
      let response = await post(
        `delete from attributes
          where attribute_id=${categories[editingCategoryIndex].id}`
      );
      response = await post(
        `delete from types
          where attribute_id=${categories[editingCategoryIndex].id}`
      );

      categories.splice(editingCategoryIndex, 1);
    }
    categories = categories;
    closeEditCategory();
  };

  let createCategory = () => {
    editingCategoryName = "";
    editingCategoryUrl = "";
    creatingCategory = {
      id: -1,
      name: "",
      url: "",
      keys: [],
      numDeleted: 0,
      allKeys: [],
    };
    resetNumDeletedCategories();
    resetNumDeletedKeys();
  };

  let closeCreateCategory = () => {
    creatingCategory = null;
    creatingCategoryName = "";
  };

  let saveCreateCategory = async () => {
    creatingCategory.name = creatingCategoryName;
    categories.push(creatingCategory);

    let response = await post(
      `insert into attributes (Name, epigraph_id, sequence, search3dtext, standards_versions_id, url)
          values ('${creatingCategory.name}', ${epId}, 0, '', ${standardId}, '${creatingCategory.url}')`
    );
    creatingCategory.id = response[0];

    categories = categories;
    closeCreateCategory();
    resetNumDeletedCategories();
    resetNumDeletedKeys();
  };

  let createKey = () => {
    creatingKeyName = "";
    creatingKeySearch3D = "";
    creatingKey = {
      id: -1,
      name: "",
      search3D: "",
      numDeleted: 0,
    };
    resetNumDeletedCategories();
    resetNumDeletedKeys();
  };

  let closeCreateKey = () => {
    creatingKey = null;
    creatingKeyName = "";
    creatingKeySearch3D = "";
    creatingKeyCategoryIndex = -1;
  };

  let saveCreateKey = async () => {
    creatingKey.name = creatingKeyName;
    creatingKey.search3D = creatingKeySearch3D;

    let response = await post(
      `insert into types (Name, attribute_id, space_type_id, search3dtext)
        values ('${creatingKey.name}', ${categories[creatingKeyCategoryIndex].id}, ${currentRoom.id}, '${creatingKey.search3D}')`
    );
    creatingKey.id = response[0];

    categories[creatingKeyCategoryIndex].keys.push(creatingKey);
    categories = categories;
    closeCreateKey();
    resetNumDeletedCategories();
    resetNumDeletedKeys();
  };

  let editKey = () => {
    editingKeyName =
      categories[editingKeyCategoryIndex].keys[editingKeyKeyIndex].name;
    editingKeySearch3D =
      categories[editingKeyCategoryIndex].keys[editingKeyKeyIndex].search3D;
    resetNumDeletedCategories();
    resetNumDeletedKeys();
  };

  let closeEditKey = () => {
    editingKeyCategoryIndex = -1;
    editingKeyName = "";
    editingKeySearch3D = "";
    editingKeyKeyIndex = -1;
  };

  let saveEditKey = () => {
    categories[editingKeyCategoryIndex].keys[
      editingKeyKeyIndex
    ].name = editingKeyName;
    categories[editingKeyCategoryIndex].keys[
      editingKeyKeyIndex
    ].search3D = editingKeySearch3D;

    let response = post(
      `update types
        set Name='${categories[editingKeyCategoryIndex].keys[editingKeyKeyIndex].name}', search3dtext='${categories[editingKeyCategoryIndex].keys[editingKeyKeyIndex].search3D}'
        where type_id=${categories[editingKeyCategoryIndex].keys[editingKeyKeyIndex].id}`
    );
    categories = categories;
    closeEditKey();
    resetNumDeletedCategories();
    resetNumDeletedKeys();
  };

  let removeEditKey = () => {
    resetNumDeletedCategories();
    resetNumDeletedKeys(
      categories[editingKeyCategoryIndex].keys[editingKeyKeyIndex].id
    );
    categories[editingKeyCategoryIndex].keys[editingKeyKeyIndex].numDeleted++;
    if (
      categories[editingKeyCategoryIndex].keys[editingKeyKeyIndex]
        .numDeleted === 2
    ) {
      let response = post(
        `delete from types
          where type_id=${categories[editingKeyCategoryIndex].keys[editingKeyKeyIndex].id}`
      );
      categories[editingKeyCategoryIndex].keys.splice(editingKeyKeyIndex, 1);
    }
    categories = categories;
    closeEditKey();
  };

  let resetNumDeletedCategories = (avoid = null) => {
    categories.forEach((category) => {
      if (category.id !== avoid) {
        category.numDeleted = 0;
      }
    });
    categories = categories;
  };

  let resetNumDeletedKeys = (avoid = null) => {
    categories.forEach((category) => {
      category.keys.forEach((key) => {
        if (key.id !== avoid) {
          key.numDeleted = 0;
        }
      });
    });
    categories = categories;
  };
</script>

<div class="RDSGKey">
  <div class="header">
    <img src={icon} alt="Key Icon" />
    <span>{id}</span>
  </div>
  {#each categories as category, index}
    <div class="category">
      {#if index === editingCategoryIndex}
        <ButtonIconEdit
          bind:text={editingCategoryName}
          bind:url={editingCategoryUrl}
          icons={[
            { src: "/images/save.svg", onClick: () => saveEditCategory() },
            { src: "/images/x.svg", onClick: () => closeEditCategory() },
          ]}
        />
      {:else}
        <div
          class="categoryName {category.url !== '' ? 'hasPDF' : ''}"
          on:click={category.url !== "" ? onClickPDF(category.url) : null}
        >
          {#if category.url !== ""}
            <img class="pdf" src="/images/pdf-gray.svg" alt="PDF" />
          {/if}
          <span>{category.name}</span>
          {#if editMode}
            <div class="categoryIcons">
              <img
                src="/images/edit.svg"
                on:click={() => {
                  editingCategoryIndex = index;
                  editCategory();
                }}
                alt="Edit Icon"
              />
              {#if category.numDeleted === 0}
                <img
                  src="/images/trash.svg"
                  on:click={() => {
                    editingCategoryIndex = index;
                    removeEditCategory();
                  }}
                  alt="Remove Icon"
                />
              {:else}
                <img
                  src="/images/trash-red.svg"
                  on:click={() => {
                    editingCategoryIndex = index;
                    removeEditCategory();
                  }}
                  alt="Remove Icon"
                />
              {/if}
            </div>
          {/if}
        </div>
      {/if}
    </div>
    <div class="keys">
      {#each category.keys as key, keyIndex}
        {#if editingKeyCategoryIndex === index && editingKeyKeyIndex === keyIndex}
          <div class="creatingKey">
            <div class="creatingKeyDiv">
              <div>
                <span>Content</span>
                <div class="creatingKeyIcons">
                  <img
                    src="/images/save.svg"
                    on:click={() => saveEditKey()}
                    alt="Save Icon"
                  />
                  <img
                    src="/images/x.svg"
                    on:click={() => closeEditKey()}
                    alt="Cancel Icon"
                  />
                </div>
              </div>
              <SuggestionEdit
                bind:text={editingKeyName}
                placeholder="name"
                suggestions={category.allKeys}
              />
              <RDSGKeyEdit
                bind:text={editingKeySearch3D}
                placeholder="search 3D"
              />
            </div>
          </div>
        {:else}
          <RDSGKeyCategory
            item={key}
            {editMode}
            onEdit={() => {
              editingKeyCategoryIndex = index;
              editingKeyKeyIndex = keyIndex;
              editKey();
            }}
            onRemove={() => {
              editingKeyCategoryIndex = index;
              editingKeyKeyIndex = keyIndex;
              removeEditKey();
            }}
            {onSelect}
          />
        {/if}
      {/each}
      {#if editMode}
        {#if creatingKeyCategoryIndex === index}
          <div class="creatingKey">
            <div class="creatingKeyDiv">
              <div>
                <span>Content</span>
                <div class="creatingKeyIcons">
                  <img
                    src="/images/save.svg"
                    on:click={() => saveCreateKey()}
                    alt="Save Icon"
                  />
                  <img
                    src="/images/x.svg"
                    on:click={() => closeCreateKey()}
                    alt="Cancel Icon"
                  />
                </div>
              </div>
              <SuggestionEdit
                bind:text={creatingKeyName}
                placeholder="name"
                suggestions={category.allKeys}
              />
              <RDSGKeyEdit
                bind:text={creatingKeySearch3D}
                placeholder="search 3D"
              />
            </div>
          </div>
        {:else}
          <div class="newKey">
            <div
              on:click={() => {
                creatingKeyCategoryIndex = index;
                createKey();
              }}
            >
              <img src="/images/plus.svg" alt="New Icon" />
              <span>Add content</span>
            </div>
          </div>
        {/if}
      {/if}
    </div>
  {/each}
  {#if editMode}
    {#if creatingCategory}
      <div class="creatingNewCategory">
        <div class="creatingNewCategoryDiv">
          <span class="newCategoryText">Attribute</span>
        </div>
        <div class="category">
          <ButtonIconEdit
            bind:text={creatingCategoryName}
            icons={[
              { src: "/images/save.svg", onClick: () => saveCreateCategory() },
              { src: "/images/x.svg", onClick: () => closeCreateCategory() },
            ]}
          />
        </div>
      </div>
    {:else}
      <div class="category">
        <div class="newCategory">
          <div on:click={() => createCategory()}>
            <img src="/images/plus.svg" alt="New Icon" />
            <span>Add new</span>
          </div>
        </div>
      </div>
    {/if}
  {/if}
</div>

<style>
  div.RDSGKey {
    width: 100%;
    margin-top: 19px;
  }

  div.RDSGKey:first-child {
    margin-top: 0px;
  }

  div.header {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
  }

  div.header img {
    height: 30px;
    margin-right: 13px;
  }

  div.category {
    width: 100%;
    /*height: 22px;*/
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }

  div.categoryName {
    width: 586px;
    /*height: 100%;*/
    height: 22px;
    background-color: var(--labit-dialog-background);
    border-radius: 19px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 14px;
    position: relative;
  }

  div.newCategory {
    width: 586px;
    height: 22px;
    background-color: white;
    border-radius: 19px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 14px;
    position: relative;
    border-color: var(--labit-card-gray);
    border-width: 1px;
    border-style: solid;
    opacity: 0.2;
  }

  div.newCategory div {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  div.newCategory div img {
    height: 50%;
    margin-right: 5px;
  }

  div.categoryIcons {
    position: absolute;
    top: 0px;
    right: 14px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  div.categoryIcons img {
    height: 75%;
    cursor: pointer;
    margin-left: 7px;
  }

  div.keys {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 6px;
  }

  div.creatingNewCategory {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  div.creatingNewCategory div.category {
    margin-top: 4px !important;
  }

  div.creatingNewCategoryDiv {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  span.newCategoryText {
    width: 586px;
    font-size: 11px;
  }

  div.newKey {
    width: 586px;
    height: 22px;
    display: flex;
    align-items: center;
    padding-left: 29px;
    margin-top: 4px;
    font-size: 12px;
    font-weight: bold;
    opacity: 0.2;
  }

  div.newKey div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  div.newKey div img {
    height: 50%;
    margin-right: 5px;
  }

  div.creatingKey {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
  }

  div.creatingKeyDiv {
    width: 586px;
    padding-left: 29px;
    display: flex;
    flex-direction: column;
  }

  div.creatingKeyDiv div {
    height: 22px;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  div.creatingKeyIcons img {
    height: 60%;
    cursor: pointer;
    margin-left: 7px;
  }

  img.pdf {
    height: 15px;
    margin-right: 4px;
  }

  div.hasPDF {
    cursor: pointer;
  }

  /* Animations */

  div.categoryIcons img {
    transition: opacity 0.5s linear 0s;
  }

  div.categoryIcons img:hover {
    opacity: 0.5;
  }
</style>
