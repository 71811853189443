<script>
  export let icon = "";
  export let text;
  export let width = "100%";
  export let onClick = "";
  export let extra = false;
  export let hidden = false;
</script>

<div class="element {extra ? 'extra' : ''}" style="width:{width};">
  {#if !hidden}
    {#if icon}
      <img
        src={icon}
        style={onClick ? "cursor: pointer;" : ""}
        alt="Calendar Icon"
        class="elementIcon"
        on:click={onClick}
      />
    {/if}
    <span>{text}</span>
  {/if}
</div>

<style>
  .elementIcon {
    width: 18px;
    margin-right: 10px;
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .element span {
    color: var(--labit-card-gray);
    font-size: 15px;
  }
</style>
