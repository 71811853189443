<script>
  export let tabs;
  export let selected;
</script>

<div class="analyticsTabsContainer">
  {#each tabs as tab}
    <div
      class="tab {selected === tab.index ? 'selected' : ''}"
      on:click={tab.onClick}
    >
      {tab.title}
    </div>
  {/each}
</div>

<style>
  div.analyticsTabsContainer {
    display: flex;
  }

  div.tab {
    width: 320px;
    display: flex;
    justify-content: center;
    height: 50px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;
    background-color: white;
    margin-right: 4px;
    font-size: 14px;
    color: var(--labit-card-gray);
    padding-top: 7px;
  }

  div.selected {
    background-color: #d7e1ea;
  }
</style>
