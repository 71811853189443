<script>
  export let text;
  export let img;
  export let backgroundColor = "var(--labit-background-gray)";
  export let borderColor = "var(--labit-card-gray);";
  export let textColor = "var(--labit-card-gray);";
  export let onClick;
  export let onRightClick = null;
  export let marginLeft = null;
  export let marginRight = null;
  export let marginTop = null;
  export let marginBottom = null;
  export let width = "99px";
  export let height = "21px";
  export let fontSize = "12px";
  export let imgHeight = "70%";

  import { onMount } from "svelte";

  let borderButton = "";
  let marginL = "";
  let marginR = "";
  let marginT = "";
  let marginB = "";

  onMount(() => {
    if (borderColor) {
      borderButton =
        "border-color: " +
        borderColor +
        "; border-width: 1px; border-style: solid;";
    }
    if (marginLeft) {
      marginL = "margin-left: " + marginLeft + ";";
    }
    if (marginRight) {
      marginR = "margin-right: " + marginRight + ";";
    }
    if (marginTop) {
      marginT = "margin-top: " + marginTop + ";";
    }
    if (marginBottom) {
      marginB = "margin-bottom: " + marginBottom + ";";
    }
  });
</script>

<div
  class="submenuButton {onClick ? 'cursor' : ''}"
  style="background-color: {backgroundColor}; color: {textColor}; {borderButton} width: {width}; height: {height}; font-size: {fontSize};
  {marginL}
  {marginR}
  {marginB}
  {marginT}"
  on:click={onClick}
  on:contextmenu={(ev) => {
    ev.preventDefault();
    if (onRightClick) {
      onRightClick();
    }
    return false;
  }}
>
  <span>{text}</span>
  {#if img}<img style="height: {imgHeight}" src={img} alt="icon" />{/if}
</div>

<style>
  div {
    border-radius: 960px/960px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.8%;
    padding-right: 0.8%;
    position: relative;
  }

  div.cursor {
    cursor: pointer;
  }

  img {
    position: relative;
    margin-left: 7%;
  }

  /* Animations */

  div.cursor {
    transition: opacity 0.5s linear 0s;
  }

  div.cursor:hover {
    opacity: 0.5;
  }
</style>
