<script>
  export let id;
  export let title;
  export let chartInfo;

  let showChart = () => {
    setTimeout(function () {
      let data = [0];
      if (chartInfo.NSpaces !== "N/A") {
        const number = chartInfo.NSpaces.replace(/%/, "");
        const numberInt = parseInt(number);
        data = [numberInt, 100 - numberInt];
      }
      let color = "";
      switch (title) {
        case "STANDARD":
          color = "#77ff77";
          break;
        case "REPLACED":
          color = "#ffff77";
          break;
        case "WRONG":
          color = "#ff7777";
          break;
      }

      var ctx = window.$("#" + id);
      new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data,
              backgroundColor: [color, "#ebebeb"],
            },
          ],
        },
        options: {
          responsive: true,
          events: [],
          cutout: '70%',
          title: {
            display: false,
            position: "top",
            fontStyle: "bold",
            fontSize: 0,
            fullWidth: false,
            padding: 0,
          },
          legend: {
            display: false,
            position: "top",
            fullWidth: false,
            labels: {
              display: false,
              usePointStyle: true,
              fontSize: 15,
              fontStyle: "bold",
            },
          },
        },
      });
    }, 1000);
  };
</script>

<div class="chartItemContainer">
  <div class="chart">
    <div>
      <canvas {id} onload={showChart()} width="50" height="50" />
    </div>
  </div>
  <div class="chartInfo">
    <span class="title">{title}</span>
    <span>N/Spaces</span>
    <span>N/Epigraphs</span>
  </div>
  <div class="chartValues">
    <span>&nbsp;</span>
    <span>{chartInfo.NSpaces}</span>
    <span>{chartInfo.NEpigraphs}</span>
  </div>
</div>

<style>
  div.chartItemContainer {
    width: 100%;
    height: 55px;
    display: flex;
    font-size: 12px;
    color: var(--labit-card-gray);
    justify-content: space-between;
    padding-left: 38px;
    padding-right: 38px;
  }

  div.chart {
    height: 100%;
    width: 40%;
    position: relative;
  }

  div.chart div {
    position: relative;
    height: 100% !important;
    width: 100% !important;
  }

  img {
    height: 100%;
  }

  div.chartInfo {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  span.title {
    font-size: 13px;
    font-weight: bold;
  }

  div.chartValues {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  canvas {
    width: 60px !important;
    height: 60px !important;
    margin-left: 5px;
    margin-top: -1px;
  }
</style>
