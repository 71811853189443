<script>
  export let src;
  export let text;
  export let url;
  export let externalUrl;
  export let type;
  export let active;
  export let isDashboard = false;
  export let notification;

  import { getPlatform } from "../js/localInfo";
  import { openNewWindow } from "../js/openNewWindow";
  import { LABIT_URL } from "../js/globalExternalURLs";
  import { openURL } from "../js/openURL";

  const platform = getPlatform();

   // Verifica que el prop notification reciba el valor correcto
   $: console.log('Notification prop in MenuItem:', notification);

</script>

<div class="menuItem {type}">
  {#if type === "logo"}
    <div
      class="link" style="width: 70px"
      on:click={isDashboard
        ? () => openNewWindow(LABIT_URL)
        : () => openURL(url, true)}
      on:contextmenu={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (isDashboard) {
          openNewWindow(LABIT_URL);
        } else {
          openNewWindow(url);
        }
        return false;
      }}
    >
      <img class="anim" {src} alt={text} />
    </div>
  {:else}
    <div
      class="link"
      on:click={() => {
        if (platform === "Web" && externalUrl !== "") {
          openNewWindow(externalUrl);
        } else {
          openURL(url, true);
        }
      }}
      on:contextmenu={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        openNewWindow("/skylab-main" + url);
        return false;
      }}
    >
      <img class={active ? "" : "anim"} {src} alt={text} />
      <span class="anim">{text}</span>
      {#if notification}
        <div class="notification" />
      {/if}
    </div>
  {/if}
</div>

<style>
  div.menuItem {
    display: flex;
    height: 100%;
  }

  div.link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
  }

  img {
    height: 70%;
    max-width: 70px;
    cursor: pointer;
  }

  span {
    color: black;
    font-size: 9px;
    position: absolute;
    bottom: 1px;
  }

  div.item {
    margin-right: 0px;
    margin-left: 0px;
  }

  div.active {
    opacity: 1 !important;
  }

  div.notification {
    width: 14px;
    height: 14px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: 10px;
  }

  /* Animations */

  div.menuItem img.anim {
    opacity: 0.5;
    transition: opacity 0.5s linear 0s;
  }

  div.menuItem:hover img.anim {
    opacity: 1;
  }

  div.menuItem span.anim {
    opacity: 0;
    transition: opacity 0.35s linear 0s;
  }

  div.menuItem:hover span.anim {
    opacity: 1;
    transition: opacity 0.35s linear 0.5s;
  }
</style>
