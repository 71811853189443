import { getUserId } from "./localInfo";
import { post } from "./labit-connection";

import moment from 'moment';

export let updateLastConnection = async () => {
  const id = getUserId();
  const now = moment().format("YYYY-MM-DD HH:mm:ss");
  await post(
    `update people
        set lastActivity='${now}'
        where contact_id=${id}`
  );
};
