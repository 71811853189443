<script>
  import Menu from "../components/Menu.svelte";
  import DashboardIcon from "../components/dashboard/DashboardIcon.svelte";

  import { openNewWindow } from "../js/openNewWindow";
  import { openURL } from "../js/openURL";
  import { getPlatform } from "../js/localInfo";

  import { HUBSPOT_URL } from "../js/globalExternalURLs";
  import { absencesNotification } from "../js/stores";
  
  const PAGE = "DIRECTION";

  let platform = getPlatform();
  let absencesNotif;

  absencesNotification.subscribe(value => {
    absencesNotif = value;
  })

  console.log(absencesNotif, "absences NOtiiiiiiiiiiii");

  let clickIcon = (url, forceNewWindow) => {
    if (platform === "Web") {
      if (forceNewWindow) {
        window.open(HUBSPOT_URL);
      } else {
        openURL(url, true);
      }
    } else {
      if (forceNewWindow) {
        openNewWindow(
          HUBSPOT_URL,
          "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.141 Safari/537.36"
        );
      } else {
        openURL(url, true);
      }
    }
  };
</script>

<div class="content">
  <!-- Body -->
  <div class="container">
    <div class="dashboardItem">
      <DashboardIcon
        title="CRM"
        icon="/images/hubspot.png"
        url=""
        onClick={() => clickIcon("/hubspot", false)}
        onRightClick={() => clickIcon("/hubspot", true)}
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="Payrolls"
        icon="/images/payrolls.svg"
        url="/people-documents"
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="Reports"
        icon="/images/new/iron-man.svg"
        url="/monitor"
        width="90px"
        notification={absencesNotif}
      />
    </div>
  </div>
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }

  div.dashboardItem {
    width: 260px;
    height: 300px;
    background-color: white;
    border-radius: 14px;
    margin-right: 16px;
  }
</style>
