<script>
  import { removeAccents } from "../js/removeAccents";

  export let category;
  export let action;
  export let onRemove;
</script>

<div class="dialogCompanyCategoryContainer">
  <div class="name {action !== 'show' ? 'edit' : ''}">
    <span>{category.name}</span>
  </div>
  {#if action !== "show"}
    <div class="options">
      <img
        class="fadedButton"
        src="/images/trash.svg"
        alt="Remove Category"
        on:click={() => onRemove(category.id)}
      />
    </div>
  {/if}
</div>

<style>
  div.dialogCompanyCategoryContainer {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  div.dialogCompanyCategoryContainer:last-child {
    margin-bottom: 8px;
  }

  div.edit {
    width: 90% !important;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    color: var(--labit-card-lightgray);
  }

  div.name {
    width: 100%;
  }

  div.options {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  img {
    width: 16px;
    height: 16px;
  }
</style>
