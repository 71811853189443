<script>
  export let title;
  export let titleIcon;

  import WifiItem from "./WifiItem.svelte";
  import EditBoxTab from "./EditBoxTab.svelte";
  import EditBoxButton from "./EditBoxButton.svelte";

  import DialogSpanFullEdit from "../modules/project-analytics/screens/components/DialogSpanFullEdit.svelte";

  import { post } from "../js/labit-connection";
  import { removeAccents } from "../js/removeAccents";

  import { onMount } from "svelte";

  let backgroundColorInput = "#d7e1ea";
  let borderColor = "var(--labit-card-gray)";

  let creating = false;
  let editing = null;
  let deleting = null;

  let currentNetwork = "";
  let currentKey = "";

  let errorNetwork = false;
  let errorKey = false;

  let wifis = [];

  let cancelTimer = null;

  const CANCEL_DELETING_TIME = 10000;

  onMount(async () => {
    await getWifis();
  });

  let getWifis = async () => {
    const response = await post(
      `select id, network, wifiKey
            from wifi`
    );
    wifis = response.map((item) => {
      return {
        id: item.id,
        network: item.network,
        key: item.wifiKey,
      };
    });
  };

  let requestAdd = () => {
    creating = true;
    editing = null;
    deleting = null;
    currentNetwork = "";
    currentKey = "";
    errorNetwork = false;
    errorKey = false;
    clearTimeout(cancelTimer);
  };

  let requestEdit = (index) => {
    creating = false;
    editing = wifis[index].id;
    deleting = null;
    currentNetwork = wifis[index].network;
    currentKey = wifis[index].key;
    errorNetwork = false;
    errorKey = false;
    clearTimeout(cancelTimer);
  };

  let requestDelete = async (index) => {
    creating = false;
    editing = null;
    errorNetwork = false;
    errorKey = false;
    clearTimeout(cancelTimer);
    if (!deleting) {
      deleting = wifis[index].id;
      cancelTimer = setTimeout(cancelDelete, CANCEL_DELETING_TIME);
    } else {
      await post(
        `delete from wifi
                where id=${deleting}`
      );
      wifis = wifis.filter((item) => {
        return item.id !== deleting;
      });
      closeForm();
    }
  };

  let cancelDelete = () => {
    deleting = null;
  };

  let closeForm = () => {
    creating = false;
    editing = null;
    deleting = null;
    currentNetwork = "";
    currentKey = "";
    errorNetwork = false;
    errorKey = false;
  };

  let save = async () => {
    errorNetwork = false;
    errorKey = false;
    if (currentNetwork.trim() === "") {
      errorNetwork = true;
      return;
    }
    if (currentKey.trim() === "") {
      errorKey = true;
      return;
    }
    if (creating) {
      const response = await post(
        `insert into wifi (network, wifiKey)
                values ('${currentNetwork}', '${currentKey}')`
      );
      const wifi = {
        id: response[0],
        network: currentNetwork,
        key: currentKey,
      };
      wifis.push(wifi);
    } else {
      await post(
        `update wifi set
                network='${currentNetwork}',
                wifiKey='${currentKey}'
                where id=${editing}`
      );
      let wifi = wifis.find((item) => {
        return item.id === editing;
      });
      if (wifi) {
        wifi.network = currentNetwork;
        wifi.key = currentKey;
      }
    }
    wifis = wifis;
    closeForm();
  };
</script>

<div class="box">
  <div class="editBoxTab">
    <EditBoxTab icon={titleIcon} text={title} />
  </div>
  <div class="items">
    <div
      class="itemList customScrollbars {creating || editing
        ? 'itemListMin'
        : ''}"
    >
      {#each wifis as wifi, index}
        <WifiItem
          {wifi}
          onEdit={() => requestEdit(index)}
          onDelete={() => requestDelete(index)}
          deletingId={deleting}
        />
      {/each}
    </div>
    <div class="plus">
      <img
        class="fadedButton"
        src="/images/plus.svg"
        alt="Add"
        on:click={() => requestAdd()}
      />
    </div>
  </div>
  <div class="form {creating || editing ? '' : 'hiddenElement'}">
    <span class="editBoxTitle requiredElement">Network</span>
    <div class="editBoxInput containerBox">
      <DialogSpanFullEdit
        color={backgroundColorInput}
        height="22"
        border={borderColor}
        error={errorNetwork}
        bind:text={currentNetwork}
      />
    </div>
    <span class="editBoxTitle requiredElement">Key</span>
    <div class="editBoxInput containerBox">
      <DialogSpanFullEdit
        color={backgroundColorInput}
        height="22"
        border={borderColor}
        error={errorKey}
        bind:text={currentKey}
      />
    </div>
    <div class="editBoxAcceptCancel">
      <EditBoxButton text="Save" onClick={() => save()} />
      <EditBoxButton text="Close" onClick={() => closeForm()} />
    </div>
  </div>
</div>

<style>
  div.box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  div.editBoxTab {
    width: 428px;
    margin-bottom: 25px;
  }

  div.editBoxTitle,
  span.editBoxTitle {
    font-size: 12px;
    color: var(--labit-card-gray);
    margin-bottom: 10px;
    width: 100%;
  }

  div.editBoxTitle {
    display: flex;
    justify-content: space-between;
  }

  div.editBoxTitleHalf {
    width: 48%;
    display: flex;
  }

  div.editBoxAcceptCancel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  div.editBoxInput {
    height: 22px;
    margin-bottom: 10px;
  }

  div.items {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 15px;
    border: 1px solid var(--labit-card-gray);
    padding: 15px 23px 15px 23px;
  }

  div.plus {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div.plus img {
    width: 20px;
    height: 20px;
  }

  div.itemList {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
  }

  div.itemListMin {
    max-height: 360px !important;
  }
</style>
