<script>
  export let text;

  import { linkify } from "../js/linkify";

  import { onMount } from "svelte";

  onMount(() => {
    window
      .$("div#longDescription")
      .html(linkify(window.$("div#longDescription").html()));
  });
</script>

<style>
  div {
    font-size: 12px;
  }
</style>

<div id="longDescription" class="withURL">
  <span>{text}</span>
</div>
