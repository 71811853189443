<script>
  export let imgSrc;
  export let name;
  export let onClick;
  export let onRightClick;
  export let selected;
  export let showRemove;
  export let onRemove;
</script>

<div class="resourceOptionContainer">
  <img
    class="icon"
    src={imgSrc}
    alt="Resource Icon"
    on:click={onClick}
    on:contextmenu={(ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      onRightClick();
      return false;
    }}
  />
  {#if showRemove}
    <img
      class="remove"
      src="/images/trash-red.svg"
      alt="Remove"
      on:click={onRemove}
    />
  {/if}
  <span class={selected ? "" : "anim"}>{name}</span>
</div>

<style>
  div.resourceOptionContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: black;
    font-size: 9px;
  }

  div.resourceOptionContainer:not(:last-child) {
    margin-right: 50px;
  }

  img.icon {
    height: 45%;
    min-height: 40px;
  }

  span {
    position: absolute;
    bottom: 8px;
    white-space: nowrap;
  }

  img.remove {
    position: absolute;
    bottom: 20px;
    right: -12px;
    height: 20px;
    width: 20px;
  }

  /* Animations */

  div.resourceOptionContainer {
    transition: opacity 0.5s linear 0s;
  }

  div.resourceOptionContainer:hover {
    opacity: 0.5;
  }

  div.resourceOptionContainer span.anim {
    opacity: 0;
    transition: opacity 0.35s linear 0s;
  }

  div.resourceOptionContainer:hover span.anim {
    opacity: 1;
    transition: opacity 0.35s linear 0.5s;
  }
</style>
