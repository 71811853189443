import { post } from "./labit-connection";
import { prepareSearch } from "./prepareSearch";
import { randomString } from "./randomString";
import { getUserId, getRole } from "./localInfo";
import getProjectClients from "./clients";
import { LABIT_PUBLIC_FOLDER } from "./labit-connection";
import moment from 'moment';

export let getAllProjects = async (
  dashboard,
  myId = -1,
  projectPeople = false,
  clocki = false
) => {
  let projects = [];

  // Only external people
  const responsePeople = await post(
    `select PP.id, PP.people as peopleId, project, PP.role as peopleRole, PP.active as peopleActive, PP.start, PP.end, PP.stakeholder, unactiveDate, P.Name as peopleName, P.Email as peopleEmail, P.PictureUrl as peoplePic, C.company_id as companyId, C.Name as companyName, esLabit, lastActivity, ps.description as profileStatusDescription, ps.iconImg as profileStatusIcon, atHome 
      from projectPeople as PP
      left join people as P on P.contact_id=PP.people
      left join companies as C on C.company_id=P.company_id
      left join profileStatus as ps on ps.id=P.profileStatus
      where stakeholder=1`
  );

  const responseCompanies = await post(
    `select id, Name, company, project, PC.role as companyRole
      from projectCompany as PC
      left join companies as C on C.company_id=PC.company`
  );

  const responseHistoryStatus = await post(
    `select
      SH.id as statusId,
      name,
      DSH.id as dStatusId,
      start,
      end,
      active,
      project
      from statusHistory as SH
      left join defaultStatusHistory as DSH on DSH.id=SH.status
      order by start asc`
  );

  const responseRelatedProjects = await post(
    `select P1.project_id as idP1, P1.name as nameP1, P2.project_id as idP2, P2.name as nameP2
      from relatedProjects as RP
      left join projects as P1 on P1.project_id=RP.project1
      left join projects as P2 on P2.project_id=RP.project2`
  );

  const responsePhases = await post(
    `select id as phaseId, name as phaseName, start, end, realStart, realEnd, realDescription, phaseParent, project from phase`
  );

  const responseMilestones = await post(
    `select id, name, isAllDay, start, end, type, project
      from milestone
      order by start asc`
  );

  const responseLicenses = await post(
    `select A.id as applicationId, A.name, AK.id as keyId, userKey, PK.project, UK.people, P.Name as contactName, P.Email, picPath
      from projectKeys as PK
      left join applicationKeys as AK on AK.id=PK.applicationKey
      left join applications as A on A.id=AK.application
      left join usedKeys as UK on UK.keyId=AK.id
      left join people as P on P.contact_id=UK.people
      order by name asc`
  );

  /*const responseWorkPeriods = await post(
    `select id, description, start, end, type, hours, project, people
      from workPeriod`
  );*/

  const people = await post(
    `select contact_id, Name, PictureUrl, active, picPath, profileStatus, atHome, lastActivity, Email
      from people
      order by Name asc`
  );

  const responseExternalUrls = await post(
    `select id, url, type, project
      from externalUrls
      order by type asc`
  );

  const responseProjectTeam = await post(
    `select ptp.id as periodId, ptp.start, ptp.end, people, phase, role, pt.id as projectTeamId, p.project as projectId
      from projectTeamPeriods as ptp
      left join projectTeam as pt on pt.id=ptp.projectTeam
      left join phase as p on p.id=pt.phase`
  );

  let phases = responsePhases.reduce((acc, curr) => {
    if (clocki) {
      acc.push({
        id: curr.phaseId,
        name: curr.phaseName,
        start: curr.start,
        end: curr.end,
        realStart: curr.realStart,
        realEnd: curr.realEnd,
        realDescription: curr.realDescription,
        project: curr.project,
        scope: curr.scopeId,
        scopeName: curr.scopeName,
        subphases: [],
        people: [],
        modified: false,
      });
    } else if (!curr.phaseParent) {
      acc.push({
        id: curr.phaseId,
        name: curr.phaseName,
        start: curr.start,
        end: curr.end,
        realStart: curr.realStart,
        realEnd: curr.realEnd,
        realDescription: curr.realDescription,
        project: curr.project,
        scope: curr.scopeId,
        scopeName: curr.scopeName,
        subphases: [],
        people: [],
        modified: false,
      });
    }
    return acc;
  }, []);

  responsePhases.forEach((item) => {
    if (item.phaseParent) {
      let phase = phases.find((p) => {
        return p.id === item.phaseParent;
      });
      if (phase) {
        const subphase = {
          id: item.phaseId,
          name: item.phaseName,
          start: item.start,
          end: item.end,
          realStart: item.realStart,
          realEnd: item.realEnd,
          realDescription: item.realDescription,
          project: item.project,
          scope: item.scopeName,
          modified: false,
        };
        phase.subphases.push(subphase);
      }
    }
  });

  let response;

  if (dashboard) {
    /*response = await post(
      `select distinct
      p.project_id as projectId,
      Name, 
      FolderLabitCode,
      TimeLabitCode,
      Nickname,
      Client, 
      Company, 
      Address, 
      City, 
      Country,
      Area,
      aream2,
      noFloors,
      allBuildingDemise,
      newStairCase,
      occupancy,
      raisedFloorCavityHeight,
      existingMinimumHeight,
      singularities,
      sprinklers,
      PEN,
      longDescription,
      ProgramType,
      picUrl,
      gMapUrl,
      serverUrl,
      trelloUrl,
      slackUrl,
      sharepointUrl,
      bim360Url,
      miroUrl,
      picPath,
      latitude,
      longitude,
      outlookCalendarId,
      outlookCalendarName,
      planPicUrl,
      planPicPath,
      creationDate,
      createdBy,
      modificationDate,
      modificatedBy,
      lastModificationDate,
      lastModificatedBy,
      p.active as pActive
    from projectPeople as PP
    left join projects as p on p.project_id=PP.project
    where people=${myId} and PP.active=1
    order by p.TimeLabitCode desc`
    );*/
    response = await post(
      `select distinct
      p.project_id as projectId,
      p.Name, 
      FolderLabitCode,
      TimeLabitCode,
      Nickname,
      Client, 
      Company, 
      Address, 
      City, 
      Country,
      Area,
      aream2,
      noFloors,
      allBuildingDemise,
      newStairCase,
      occupancy,
      raisedFloorCavityHeight,
      existingMinimumHeight,
      singularities,
      sprinklers,
      PEN,
      longDescription,
      ProgramType,
      picUrl,
      gMapUrl,
      serverUrl,
      trelloUrl,
      slackUrl,
      sharepointUrl,
      bim360Url,
      miroUrl,
      picPath,
      latitude,
      longitude,
      outlookCalendarId,
      outlookCalendarName,
      planPicUrl,
      planPicPath,
      creationDate,
      createdBy,
      modificationDate,
      modificatedBy,
      lastModificationDate,
      lastModificatedBy,
      p.active as pActive,
      isInternal
    from projectTeam as PT
    left join phase as PH on PH.id=PT.phase
    left join projects as p on p.project_id=PH.project
    where people=${myId}
    order by p.TimeLabitCode desc`
    );
  } else {
    if (projectPeople) {
      response = await post(
        `select distinct
          project_id as projectId,
          Name, 
          FolderLabitCode,
          TimeLabitCode,
          Nickname,
          Client, 
          Company, 
          Address, 
          City, 
          Country,
          Area,
          aream2,
          noFloors,
          allBuildingDemise,
          newStairCase,
          occupancy,
          raisedFloorCavityHeight,
          existingMinimumHeight,
          singularities,
          sprinklers,
          PEN,
          longDescription,
          ProgramType,
          picUrl,
          gMapUrl,
          serverUrl,
          trelloUrl,
          slackUrl,
          sharepointUrl,
          bim360Url,
          miroUrl,
          picPath,
          latitude,
          longitude,
          outlookCalendarId,
          outlookCalendarName,
          planPicUrl,
          planPicPath,
          creationDate,
          createdBy,
          modificationDate,
          modificatedBy,
          lastModificationDate,
          lastModificatedBy,
          p.active as pActive,
          isInternal
        from projectPeople as pp
        left join projects as p on p.project_id=pp.project
        where stakeholder=0
        order by TimeLabitCode desc`
      );
    } else {
      response = await post(
        `select 
      project_id as projectId,
      Name, 
      FolderLabitCode,
      TimeLabitCode,
      Nickname,
      Client, 
      Company, 
      Address, 
      City, 
      Country,
      Area,
      aream2,
      noFloors,
      allBuildingDemise,
      newStairCase,
      occupancy,
      raisedFloorCavityHeight,
      existingMinimumHeight,
      singularities,
      sprinklers,
      PEN,
      longDescription,
      ProgramType,
      picUrl,
      gMapUrl,
      serverUrl,
      trelloUrl,
      slackUrl,
      sharepointUrl,
      bim360Url,
      miroUrl,
      picPath,
      latitude,
      longitude,
      outlookCalendarId,
      outlookCalendarName,
      planPicUrl,
      planPicPath,
      creationDate,
      createdBy,
      modificationDate,
      modificatedBy,
      lastModificationDate,
      lastModificatedBy,
      active as pActive,
      isInternal
    from projects
    order by TimeLabitCode desc`
      );
    }
  }

  for (let i = 0; i < response.length; i++) {
    const item = response[i];
    let project = {
      id: item.projectId,
      pic: item.picUrl === "" ? "" : item.picUrl + "?" + randomString(10),
      map: item.gMapUrl,
      name: item.Name,
      folderLabitCode: item.FolderLabitCode,
      timeLabitCode: item.TimeLabitCode,
      nickName: item.Nickname,
      lastName: item.Client,
      company: item.Company,
      address: item.Address,
      city: item.City,
      country: item.Country,
      area: item.Area,
      aream2: item.aream2,
      noFloors: item.noFloors,
      allBuildingDemise: item.allBuildingDemise,
      newStairCase: item.newStairCase,
      occupancy: item.occupancy,
      raisedFloorCavityHeight: item.raisedFloorCavityHeight,
      existingMinimumHeight: item.existingMinimumHeight,
      singularities: item.singularities,
      sprinklers: item.sprinklers,
      PEN: item.PEN,
      longDescription: item.longDescription,
      programType: item.ProgramType === "" ? "N/A" : item.ProgramType,
      //active: item.active === "1",
      active: item.pActive === "1",
      /*serverUrl: item.serverUrl,
      trelloUrl: item.trelloUrl,
      slackUrl: item.slackUrl,
      sharepointUrl: item.sharepointUrl,
      bim360Url: item.bim360Url,
      miroUrl: item.miroUrl,*/
      externalUrls: [],
      //team: [],
      //contact: [],
      statusHistory: [],
      picPath: item.picPath,
      latitude: item.latitude,
      longitude: item.longitude,
      outlookCalendarId: item.outlookCalendarId,
      outlookCalendarName: item.outlookCalendarName,
      relatedProjects: [],
      planPicUrl: item.planPicUrl,
      planPicPath: item.planPicPath,
      phases: [],
      milestones: [],
      licenses: [],
      scopes: [],
      currentPhase: "",
      currentSubphase: "",
      currentStatus: "",
      people: [],
      companies: [],
      start: null,
      end: null,
      creationDate: item.creationDate,
      createdBy: item.createdBy,
      modificationDate: item.modificationDate,
      modificatedBy: item.modificatedBy,
      lastModificationDate: item.lastModificationDate,
      lastModificatedBy: item.lastModificatedBy,
      creationInfo: null,
      modificationInfo: null,
      lastModificationInfo: null,
      searchTerms: "",
      visible: true,
      isDSC: item.Company === "DSC",
      isInternal: item.isInternal === "1",
    };

    /*let project = {
      id: item.projectId,
      pic: item.picUrl === "" ? "" : item.picUrl + "?" + randomString(10),
      map: item.gMapUrl,
      name: item.Name,
      folderLabitCode: item.FolderLabitCode,
      timeLabitCode: item.TimeLabitCode,
      nickName: item.Nickname,
      lastName: item.Client,
      company: item.Company,
      address: item.Address,
      city: item.City,
      country: item.Country,
      area: item.Area,
      aream2: item.aream2,
      noFloors: item.noFloors,
      allBuildingDemise: item.allBuildingDemise,
      newStairCase: item.newStairCase,
      occupancy: item.occupancy,
      raisedFloorCavityHeight: item.raisedFloorCavityHeight,
      existingMinimumHeight: item.existingMinimumHeight,
      singularities: item.singularities,
      sprinklers: item.sprinklers,
      PEN: item.PEN,
      longDescription: item.longDescription,
      programType: item.ProgramType,
      active: item.active === "1",
      active: 0,
      serverUrl: item.serverUrl,
      trelloUrl: item.trelloUrl,
      slackUrl: item.slackUrl,
      sharepointUrl: item.sharepointUrl,
      bim360Url: item.bim360Url,
      miroUrl: item.miroUrl,
      team: [],
      contact: [],
      statusHistory: [],
      picPath: item.picPath,
      latitude: item.latitude,
      longitude: item.longitude,
      outlookCalendarId: item.outlookCalendarId,
      outlookCalendarName: item.outlookCalendarName,
      relatedProjects: [],
      planPicUrl: item.planPicUrl,
      planPicPath: item.planPicPath,
      phases: [],
      milestones: [],
      licenses: [],
      scopes: [],
      currentPhase: "",
      currentSubphase: "",
      currentStatus: "",
      people: [],
      companies: [],
      creationDate: item.creationDate,
      createdBy: item.createdBy,
      modificationDate: item.modificationDate,
      modificatedBy: item.modificatedBy,
      lastModificationDate: item.lastModificationDate,
      lastModificatedBy: item.lastModificatedBy,
      creationInfo: null,
      modificationInfo: null,
      lastModificationInfo: null,
      searchTerms: "",
      visible: true,
    };*/

    projects.push(project);
  }

  //console.log(projects);

  responsePeople.forEach((p) => {
    // External People
    let contact = {
      id: p.id,
      peopleId: p.peopleId,
      projectId: p.project,
      role: p.peopleRole,
      start: p.start,
      end: p.end,
      active: p.peopleActive === "1",
      unactiveDate: p.unactiveDate,
      peopleName: p.peopleName,
      peopleEmail: p.peopleEmail,
      peoplePic: p.peoplePic,
      companyId: p.companyId,
      companyName: p.companyName,
      stakeholder: p.stakeholder === "1",
      esLabit: p.esLabit === "1",
      lastActivity: p.lastActivity,
      officeStatus: {
        description: p.profileStatusDescription,
        icon: p.profileStatusIcon,
      },
      atHome: p.atHome,
      workPeriods: [],
    };
    if (contact.end < contact.start) {
      const aux = contact.end;
      contact.end = contact.start;
      contact.start = aux;
    }
    let project = projects.find((item) => {
      return item.id === contact.projectId;
    });
    if (project) {
      project.people.push(contact);
    }
  });

  responseCompanies.forEach((c) => {
    const company = {
      id: c.id,
      companyId: c.company,
      companyName: c.Name,
      projectId: c.project,
      role: c.companyRole,
    };
    let project = projects.find((item) => {
      return item.id === company.projectId;
    });
    if (project) {
      project.companies.push(company);
    }
  });

  responseHistoryStatus.forEach((hs) => {
    const status = {
      id: hs.statusId,
      name: hs.name,
      statusId: hs.dStatusId,
      start: hs.start,
      end: hs.end,
      active: hs.active === "1",
      project: hs.project,
    };
    const project = projects.find((p) => {
      return p.id === status.project;
    });
    if (project) {
      project.statusHistory.push(status);
    }
  });

  responseRelatedProjects.forEach((rProject) => {
    const p1 = {
      id: rProject.idP1,
      name: rProject.nameP1,
    };
    const p2 = {
      id: rProject.idP2,
      name: rProject.nameP2,
    };
    let p = projects.find((proj) => {
      return proj.id === p1.id;
    });
    if (p) {
      p.relatedProjects.push(p2);
    }
    p = projects.find((proj) => {
      return proj.id === p2.id;
    });
    if (p) {
      p.relatedProjects.push(p1);
    }
  });

  phases.forEach((phase) => {
    let project = projects.find((p) => {
      return p.id === phase.project;
    });
    if (project) {
      project.phases.push(phase);
      if (phase.scopeName) {
        let scopePos = project.scopes.findIndex((s) => {
          return s === phase.scopeName;
        });
        if (scopePos === -1) {
          project.scopes.push(phase.scopeName);
        }
      }
    }
  });

  responseProjectTeam.forEach((item) => {
    let phase = phases.find((ph) => {
      return ph.id === item.phase;
    });
    let peop = people.find((pe) => {
      return pe.contact_id === item.people;
    });
    if (phase && peop) {
      let peopl = phase.people.find((p) => {
        return p.peopleId === peop.contact_id && p.role === item.role;
      });
      if (!peopl) {
        peopl = {
          id: item.projectTeamId,
          peopleId: item.people,
          name: peop.Name,
          pic: peop.PictureUrl,
          role: item.role,
          dates: [],
        };
        phase.people.push(peopl);
      }
      peopl.dates.push({
        id: item.periodId,
        start: item.start,
        end: item.end,
      });
    }
  });

  projects.forEach((project) => {
    // Team

    let teamPeople = [];
    project.phases.forEach((phase) => {
      phase.people.forEach((p) => {
        let tw = teamPeople.find((item) => {
          return (item.peopleId = p.peopleId && item.role === p.role);
        });
        if (!tw) {
          const pe = people.find((item) => {
            return item.contact_id === p.peopleId;
          });
          tw = {
            id: "",
            peopleId: pe.contact_id,
            active: pe.active,
            atHome: pe.atHome,
            companyId: "2",
            companyName: "Labit Group",
            start: "",
            end: "",
            esLabit: true,
            lastActivity: pe.lastActivity,
            officeStatus: {
              description: "Working",
              icon: "",
            },
            peopleEmail: pe.Email,
            peopleName: pe.Name,
            peoplePic:
              pe.picPath === "" ? "" : LABIT_PUBLIC_FOLDER + pe.picPath,
            projectId: project.id,
            role: p.role,
            stakeholder: false,
            unactiveDate: null,
            workPeriods: [],
          };
          project.people.push(tw);
        }
        p.dates.forEach((date) => {
          tw.workPeriods.push({
            description: phase.name,
            start: date.start,
            end: date.end,
          });
        });
      });
    });
    project.people.forEach((p) => {
      p.workPeriods.forEach((wp) => {
        if (p.start === "") {
          p.start = wp.start;
          p.end = wp.end;
        } else {
          if (wp.start < p.start) {
            p.start = wp.start;
          }
          if (wp.end > p.end) {
            p.end = wp.end;
          }
        }
      });
    });
  });

  projects.forEach((project) => {
    project = updateCurrentPhases(project);
    project = updateCurrentStatus(project);
    project = calculateStartEndProject(project);
  });

  responseMilestones.forEach((m) => {
    const milestone = {
      id: m.id,
      name: m.name,
      allDay: m.isAllDay === "1",
      start: m.start,
      end: m.end,
      mtype: m.type,
    };
    let p = projects.find((proj) => {
      return proj.id === m.project;
    });
    if (p) {
      p.milestones.push(milestone);
    }
  });

  responseLicenses.forEach((l) => {
    let project = projects.find((p) => {
      return p.id === l.project;
    });
    if (project) {
      let application = project.licenses.find((item) => {
        return item.id === l.applicationId;
      });
      if (!application) {
        application = {
          id: l.applicationId,
          name: l.name,
          keys: [],
        };
        project.licenses.push(application);
      }
      const usedBy = l.people
        ? {
            contactId: l.people,
            name: l.contactName,
            email: l.Email,
            pic: l.picPath,
          }
        : null;
      const key = {
        id: l.keyId,
        key: l.userKey,
        usedBy,
      };
      application.keys.push(key);
    }
  });

  projects.forEach((project) => {
    const urls = responseExternalUrls.filter((eu) => {
      return eu.project === project.id;
    });
    project.externalUrls = urls.map((url) => {
      return {
        id: url.id,
        url: url.url,
        type: url.type,
      };
    });
  });

  let user = null;
  projects.forEach((project) => {
    if (project.createdBy) {
      user = people.find((p) => {
        return p.contact_id === project.createdBy;
      });
      if (user) {
        project.creationInfo = {
          name: user.Name,
          pic: user.PictureUrl,
          date: project.creationDate,
        };
      }
    }
    if (project.modificatedBy) {
      user = people.find((p) => {
        return p.contact_id === project.modificatedBy;
      });
      if (user) {
        project.modificationInfo = {
          name: user.Name,
          pic: user.PictureUrl,
          date: project.modificationDate,
        };
      }
    }
    if (project.lastModificatedBy) {
      user = people.find((p) => {
        return p.contact_id === project.lastModificatedBy;
      });
      if (user) {
        project.lastModificationInfo = {
          name: user.Name,
          pic: user.PictureUrl,
          date: project.lastModificationDate,
        };
      }
    }
  });

  projects.forEach((project) => {
    project.searchTerms =
      prepareSearch(project.name) +
      " " +
      prepareSearch(project.nickName) +
      "  " +
      prepareSearch(project.folderLabitCode) +
      " " +
      prepareSearch(project.timeLabitCode);
  });

  //console.log(projects);

  return projects;
};

export let getProject = async (id) => {
  let projects = [];

  // Only external people
  const responsePeople = await post(
    `select id, PP.people as peopleId, project, PP.role as peopleRole, PP.active as peopleActive, PP.start, PP.end, PP.stakeholder, unactiveDate, P.Name as peopleName, P.Email as peopleEmail, P.picPath as peoplePic, C.company_id as companyId, C.Name as companyName, esLabit 
      from projectPeople as PP
      left join people as P on P.contact_id=PP.people
      left join companies as C on C.company_id=P.company_id
      where stakeholder=1 and PP.project=${id}`
  );

  const responseCompanies = await post(
    `select id, Name, company, project, PC.role as companyRole
      from projectCompany as PC
      left join companies as C on C.company_id=PC.company
      where PC.project=${id}`
  );

  const responseHistoryStatus = await post(
    `select
      SH.id as statusId,
      name,
      DSH.id as dStatusId,
      start,
      end,
      active,
      project
      from statusHistory as SH
      left join defaultStatusHistory as DSH on DSH.id=SH.status
      where SH.project=${id}
      order by start asc`
  );

  const responseRelatedProjects = await post(
    `select P1.project_id as idP1, P1.name as nameP1, P2.project_id as idP2, P2.name as nameP2
      from relatedProjects as RP
      left join projects as P1 on P1.project_id=RP.project1
      left join projects as P2 on P2.project_id=RP.project2
      where RP.project1=${id} or RP.project2=${id}`
  );

  const responsePhases = await post(
    `select P.id as phaseId, P.name as phaseName, start, end, realStart, realEnd, realDescription, phaseParent, project, S.id as scopeId, S.name as scopeName
      from phase as P
      left join scope as S on S.id=P.scope
      where P.project=${id}
      order by start asc`
  );

  const responseMilestones = await post(
    `select id, name, isAllDay, start, end, type, project
      from milestone
      where project=${id}
      order by start asc`
  );

  const responseLicenses = await post(
    `select A.id as applicationId, A.name, AK.id as keyId, userKey, PK.project, UK.people, P.Name as contactName, P.Email, picPath
      from projectKeys as PK
      left join applicationKeys as AK on AK.id=PK.applicationKey
      left join applications as A on A.id=AK.application
      left join usedKeys as UK on UK.keyId=AK.id
      left join people as P on P.contact_id=UK.people
      where PK.project=${id}
      order by name asc`
  );

  const people = await post(
    `select contact_id, Name, PictureUrl
      from people
      order by Name asc`
  );

  const responseExternalUrls = await post(
    `select id, url, type, project
      from externalUrls
      order by type asc`
  );

  const responseProjectTeam = await post(
    `select ptp.id as periodId, ptp.start, ptp.end, people, phase, role, pt.id as projectTeamId, p.project as projectId
      from projectTeamPeriods as ptp
      left join projectTeam as pt on pt.id=ptp.projectTeam
      left join phase as p on p.id=pt.phase
      where project=${id}`
  );

  let phases = responsePhases.reduce((acc, curr) => {
    if (!curr.phaseParent) {
      acc.push({
        id: curr.phaseId,
        name: curr.phaseName,
        start: curr.start,
        end: curr.end,
        realStart: curr.realStart,
        realEnd: curr.realEnd,
        realDescription: curr.realDescription,
        project: curr.project,
        scope: curr.scopeId,
        scopeName: curr.scopeName,
        subphases: [],
        people: [],
        modified: false,
      });
    }
    return acc;
  }, []);

  responsePhases.forEach((item) => {
    if (item.phaseParent) {
      let phase = phases.find((p) => {
        return p.id === item.phaseParent;
      });
      if (phase) {
        const subphase = {
          id: item.phaseId,
          name: item.phaseName,
          start: item.start,
          end: item.end,
          realStart: item.realStart,
          realEnd: item.realEnd,
          realDescription: item.realDescription,
          project: item.project,
          scope: item.scopeName,
          modified: false,
        };
        phase.subphases.push(subphase);
      }
    }
  });

  let response;

  response = await post(
    `select 
      project_id as projectId,
      Name, 
      FolderLabitCode,
      TimeLabitCode,
      Nickname,
      Client, 
      Company, 
      Address, 
      City, 
      Country,
      Area,
      aream2,
      noFloors,
      allBuildingDemise,
      newStairCase,
      occupancy,
      raisedFloorCavityHeight,
      existingMinimumHeight,
      singularities,
      sprinklers,
      PEN,
      longDescription,
      ProgramType,
      picUrl,
      gMapUrl,
      serverUrl,
      trelloUrl,
      slackUrl,
      sharepointUrl,
      bim360Url,
      miroUrl,
      picPath,
      latitude,
      longitude,
      outlookCalendarId,
      outlookCalendarName,
      planPicUrl,
      planPicPath,
      creationDate,
      createdBy,
      modificationDate,
      modificatedBy,
      lastModificationDate,
      lastModificatedBy
    from projects
    where project_id=${id}
    order by TimeLabitCode desc`
  );

  for (let i = 0; i < response.length; i++) {
    const item = response[i];
    let project = {
      id: item.projectId,
      pic: item.picUrl === "" ? "" : item.picUrl + "?" + randomString(10),
      map: item.gMapUrl,
      name: item.Name,
      folderLabitCode: item.FolderLabitCode,
      timeLabitCode: item.TimeLabitCode,
      nickName: item.Nickname,
      lastName: item.Client,
      company: item.Company,
      address: item.Address,
      city: item.City,
      country: item.Country,
      area: item.Area,
      aream2: item.aream2,
      noFloors: item.noFloors,
      allBuildingDemise: item.allBuildingDemise,
      newStairCase: item.newStairCase,
      occupancy: item.occupancy,
      raisedFloorCavityHeight: item.raisedFloorCavityHeight,
      existingMinimumHeight: item.existingMinimumHeight,
      singularities: item.singularities,
      sprinklers: item.sprinklers,
      PEN: item.PEN,
      longDescription: item.longDescription,
      programType: item.ProgramType,
      //active: item.active === "1",
      active: item.pActive === "1",
      serverUrl: item.serverUrl,
      /*trelloUrl: item.trelloUrl,
      slackUrl: item.slackUrl,
      sharepointUrl: item.sharepointUrl,
      bim360Url: item.bim360Url,
      miroUrl: item.miroUrl,*/
      externalUrls: [],
      //team: [],
      //contact: [],
      statusHistory: [],
      picPath: item.picPath,
      latitude: item.latitude,
      longitude: item.longitude,
      outlookCalendarId: item.outlookCalendarId,
      outlookCalendarName: item.outlookCalendarName,
      relatedProjects: [],
      planPicUrl: item.planPicUrl,
      planPicPath: item.planPicPath,
      phases: [],
      milestones: [],
      licenses: [],
      scopes: [],
      currentPhase: "",
      currentSubphase: "",
      currentStatus: "",
      people: [],
      companies: [],
      start: null,
      end: null,
      creationDate: item.creationDate,
      createdBy: item.createdBy,
      modificationDate: item.modificationDate,
      modificatedBy: item.modificatedBy,
      lastModificationDate: item.lastModificationDate,
      lastModificatedBy: item.lastModificatedBy,
      creationInfo: null,
      modificationInfo: null,
      lastModificationInfo: null,
      searchTerms: "",
      visible: true,
      isDSC: item.Company === "DSC",
    };

    projects.push(project);
  }

  //console.log(projects);

  responsePeople.forEach((p) => {
    let contact = {
      id: p.id,
      peopleId: p.peopleId,
      projectId: p.project,
      role: p.peopleRole,
      start: p.start,
      end: p.end,
      active: p.peopleActive === "1",
      unactiveDate: p.unactiveDate,
      peopleName: p.peopleName,
      peopleEmail: p.peopleEmail,
      peoplePic: p.peoplePic,
      companyId: p.companyId,
      companyName: p.companyName,
      stakeholder: p.stakeholder === "1",
      esLabit: p.esLabit === "1",
      lastActivity: p.lastActivity,
      officeStatus: {
        description: p.profileStatusDescription,
        icon: p.profileStatusIcon,
      },
      atHome: p.atHome,
      workPeriods: [],
    };
    if (contact.end < contact.start) {
      const aux = contact.end;
      contact.end = contact.start;
      contact.start = aux;
    }
    let project = projects.find((item) => {
      return item.id === contact.projectId;
    });
    if (project) {
      project.people.push(contact);
    }
  });

  responseCompanies.forEach((c) => {
    const company = {
      id: c.id,
      companyId: c.company,
      companyName: c.Name,
      projectId: c.project,
      role: c.companyRole,
    };
    let project = projects.find((item) => {
      return item.id === company.projectId;
    });
    if (project) {
      project.companies.push(company);
    }
  });

  responseHistoryStatus.forEach((hs) => {
    const status = {
      id: hs.statusId,
      name: hs.name,
      statusId: hs.dStatusId,
      start: hs.start,
      end: hs.end,
      active: hs.active === "1",
      project: hs.project,
    };
    const project = projects.find((p) => {
      return p.id === status.project;
    });
    if (project) {
      project.statusHistory.push(status);
    }
  });

  responseRelatedProjects.forEach((rProject) => {
    const p1 = {
      id: rProject.idP1,
      name: rProject.nameP1,
    };
    const p2 = {
      id: rProject.idP2,
      name: rProject.nameP2,
    };
    let p = projects.find((proj) => {
      return proj.id === p1.id;
    });
    if (p) {
      p.relatedProjects.push(p2);
    }
    p = projects.find((proj) => {
      return proj.id === p2.id;
    });
    if (p) {
      p.relatedProjects.push(p1);
    }
  });

  phases.forEach((phase) => {
    let project = projects.find((p) => {
      return p.id === phase.project;
    });
    if (project) {
      project.phases.push(phase);
      if (phase.scopeName) {
        let scopePos = project.scopes.findIndex((s) => {
          return s === phase.scopeName;
        });
        if (scopePos === -1) {
          project.scopes.push(phase.scopeName);
        }
      }
    }
  });

  responseProjectTeam.forEach((item) => {
    let phase = phases.find((ph) => {
      return ph.id === item.phase;
    });
    let peop = people.find((pe) => {
      return pe.contact_id === item.people;
    });
    if (phase && peop) {
      let peopl = phase.people.find((p) => {
        return p.peopleId === peop.contact_id && p.role === item.role;
      });
      if (!peopl) {
        peopl = {
          id: item.projectTeamId,
          peopleId: item.people,
          name: peop.Name,
          pic: peop.PictureUrl,
          role: item.role,
          dates: [],
        };
        phase.people.push(peopl);
      }
      peopl.dates.push({
        id: item.periodId,
        start: item.start,
        end: item.end,
      });
    }
  });

  projects.forEach((project) => {
    // Team

    let teamPeople = [];
    project.phases.forEach((phase) => {
      phase.people.forEach((p) => {
        let tw = teamPeople.find((item) => {
          return (item.peopleId = p.peopleId && item.role === p.role);
        });
        if (!tw) {
          const pe = people.find((item) => {
            return item.contact_id === p.peopleId;
          });
          tw = {
            id: "",
            peopleId: pe.contact_id,
            active: pe.active,
            atHome: pe.atHome,
            companyId: "2",
            companyName: "Labit Group",
            start: "",
            end: "",
            esLabit: true,
            lastActivity: pe.lastActivity,
            officeStatus: {
              description: "Working",
              icon: "",
            },
            peopleEmail: pe.Email,
            peopleName: pe.Name,
            peoplePic:
              pe.picPath === "" ? "" : LABIT_PUBLIC_FOLDER + pe.picPath,
            projectId: project.id,
            role: p.role,
            stakeholder: false,
            unactiveDate: null,
            workPeriods: [],
          };
          project.people.push(tw);
        }
        p.dates.forEach((date) => {
          tw.workPeriods.push({
            description: phase.name,
            start: date.start,
            end: date.end,
          });
        });
      });
    });
    project.people.forEach((p) => {
      p.workPeriods.forEach((wp) => {
        if (p.start === "") {
          p.start = wp.start;
          p.end = wp.end;
        } else {
          if (wp.start < p.start) {
            p.start = wp.start;
          }
          if (wp.end > p.end) {
            p.end = wp.end;
          }
        }
      });
    });
  });

  projects.forEach((project) => {
    project = updateCurrentPhases(project);
    project = updateCurrentStatus(project);
    project = calculateStartEndProject(project);
  });

  responseMilestones.forEach((m) => {
    const milestone = {
      id: m.id,
      name: m.name,
      allDay: m.isAllDay === "1",
      start: m.start,
      end: m.end,
      mtype: m.type,
    };
    let p = projects.find((proj) => {
      return proj.id === m.project;
    });
    if (p) {
      p.milestones.push(milestone);
    }
  });

  responseLicenses.forEach((l) => {
    let project = projects.find((p) => {
      return p.id === l.project;
    });
    if (project) {
      let application = project.licenses.find((item) => {
        return item.id === l.applicationId;
      });
      if (!application) {
        application = {
          id: l.applicationId,
          name: l.name,
          keys: [],
        };
        project.licenses.push(application);
      }
      const usedBy = l.people
        ? {
            contactId: l.people,
            name: l.contactName,
            email: l.Email,
            pic: l.picPath,
          }
        : null;
      const key = {
        id: l.keyId,
        key: l.userKey,
        usedBy,
      };
      application.keys.push(key);
    }
  });

  projects.forEach((project) => {
    const urls = responseExternalUrls.filter((eu) => {
      return eu.project === project.id;
    });
    project.externalUrls = urls.map((url) => {
      return {
        id: url.id,
        url: url.url,
        type: url.type,
      };
    });
  });

  let user = null;
  projects.forEach((project) => {
    if (project.createdBy) {
      user = people.find((p) => {
        return p.contact_id === project.createdBy;
      });
      if (user) {
        project.creationInfo = {
          name: user.Name,
          pic: user.PictureUrl,
          date: project.creationDate,
        };
      }
    }
    if (project.modificatedBy) {
      user = people.find((p) => {
        return p.contact_id === project.modificatedBy;
      });
      if (user) {
        project.modificationInfo = {
          name: user.Name,
          pic: user.PictureUrl,
          date: project.modificationDate,
        };
      }
    }
    if (project.lastModificatedBy) {
      user = people.find((p) => {
        return p.contact_id === project.lastModificatedBy;
      });
      if (user) {
        project.lastModificationInfo = {
          name: user.Name,
          pic: user.PictureUrl,
          date: project.lastModificationDate,
        };
      }
    }
  });

  projects.forEach((project) => {
    project.searchTerms =
      prepareSearch(project.name) +
      " " +
      prepareSearch(project.nickName) +
      "  " +
      prepareSearch(project.folderLabitCode) +
      " " +
      prepareSearch(project.timeLabitCode);
  });

  //console.log(projects);

  return projects[0];
};

export let getCityCodes = async () => {
  let response = await post(
    `select name, code2, code3
      from city_codes
      order by name asc`
  );
  const cityCodes = response.map((item) => {
    return {
      name: item.name,
      code2: item.code2,
      code3: item.code3,
    };
  });

  return cityCodes;
};

export let getCities = async () => {
  let response = await post(
    `select distinct City
      from people`
  );
  const citiesPeople = response.map((item) => {
    return item.City ? item.City : "";
  });

  response = await post(
    `select distinct City
      from companies`
  );
  const citiesCompanies = response.map((item) => {
    return item.City ? item.City : "";
  });

  response = await post(
    `select distinct City
      from projects`
  );
  const citiesProjects = response.map((item) => {
    return item.City ? item.City : "";
  });

  let cities = citiesPeople.concat(citiesCompanies).concat(citiesProjects);
  cities = [...new Set(cities)];
  cities.sort();

  return cities;
};

export let getClients = async () => {
  /*let response = await post(
    `select co.Name as companyName, code
      from companies as co
      left join categories as ca on co.category_id=ca.category_id
      where ca.Name='Clients' and co.Active=1
      order by companyName asc`
  );*/
  const response = await getProjectClients();
  const clients = response.map((item) => {
    return {
      name: item.Name,
      code: item.code,
    };
  });

  //console.log(clients);

  return clients;
};

export let updateCurrentPhases = (project) => {
  project.currentPhase = "";
  project.currentSubphase = "";
  const today = moment().format("YYYY-MM-DD");
  const currentPhase = project.phases.find((p) => {
    if (p.realStart) {
      return p.realStart <= today && p.realEnd >= today;
    } else {
      return p.start <= today && p.end >= today;
    }
  });
  if (currentPhase) {
    project.currentPhase = currentPhase.name;
    const currentSubphase = currentPhase.subphases.find((s) => {
      if (s.realStart) {
        return s.realStart <= today && s.realEnd >= today;
      } else {
        return s.start <= today && s.end >= today;
      }
    });
    if (currentSubphase) {
      project.currentSubphase = currentSubphase.name;
    }
  }
  return project;
};

export let updateCurrentStatus = (project) => {
  //project.active = true;
  project.currentStatus = "";
  const today = moment().format("YYYY-MM-DD");
  const currentStatus = project.statusHistory.find((sh) => {
    return !sh.end || (sh.start <= today && sh.end >= today);
  });
  if (currentStatus) {
    //project.active = currentStatus.active;
    project.currentStatus = currentStatus.name;
  }
  return project;
};

export let updateDates = (project) => {
  project.start = null;
  project.end = null;
  project.phases.forEach((phase) => {
    const start = moment(phase.startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    const end = moment(phase.endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    if (!project.start) {
      project.start = start;
    } else {
      if (start < project.start) {
        project.start = start;
      }
    }
    if (!project.end) {
      project.end = end;
    } else {
      if (end > project.end) {
        project.end = end;
      }
    }
  });
  return project;
};

export let calculateStartEndProject = (project) => {
  project.start = null;
  project.end = null;
  project.phases.forEach((phase) => {
    const start = phase.start;
    const end = phase.end;
    if (!project.start) {
      project.start = start;
    } else {
      if (start < project.start) {
        project.start = start;
      }
    }
    if (!project.end) {
      project.end = end;
    } else {
      if (end > project.end) {
        project.end = end;
      }
    }
  });
  return project;
};

export let getRoleInProject = (project) => {
  const myId = getUserId();
  const myRole = getRole();
  let role = myRole === "Admin" || myRole === "Analytics" ? "Admin" : "";
  if (role === "") {
    // Check Project Manager

    project.phases.forEach((phase) => {
      phase.people.forEach((p) => {
        if (p.peopleId === myId.toString() && p.role === "Project Manager") {
          role = "Project Manager";
        }
      });
    });

    console.log(role);

    //const today = moment().format("YYYY-MM-DD");

    /*const pos = project.people.findIndex((item) => {
      return (
        item.peopleId === myId.toString() &&
        item.start <= today &&
        item.end >= today
      );
    });
    if (pos >= 0) {
      role = "ProjectManager";
    }*/
  }
  return role;
};
