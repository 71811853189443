<script>
  export let id;
  export let exception;

  import { linkify } from "../js/linkify";

  import { onMount } from "svelte";

  onMount(() => {
    window
      .$("div#exceptionDetail_" + id)
      .html(linkify(window.$("div#exceptionDetail_" + id).html()));
  });
</script>

<style>
  div.RDSGTextExceptionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 19px;
    padding-left: 10px;
    border-left-width: 2px;
    border-left-color: var(--labit-card-gray);
    border-left-style: solid;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  div.column {
    align-items: flex-start !important;
  }

  /*div.line {
    display: flex;
  }*/

  span {
    display: flex;
    font-size: 12px;
    margin-bottom: 6px;
  }

  span.margin {
    margin-left: 6px;
  }
</style>

<div class="RDSGTextExceptionContainer">
  <div>
    <span>{exception.level} - {exception.roomNumbering}:</span>
    <span class="margin">
      <b>{exception.type.toUpperCase()}</b>
    </span>
  </div>
  <div>
    <span>{exception.text}</span>
  </div>
  {#if exception.type === 'replace'}
    <div>
      <span>
        <b>Replaced by:</b>
      </span>
      <span class="margin">{exception.replacingValue}</span>
    </div>
  {/if}
  <div class="column withURL" id={'exceptionDetail_' + id}>
    <span>Comment:</span>
    <span class="margin">
      <i>{exception.description}</i>
    </span>
  </div>
</div>
