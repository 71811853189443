<script>
  export let text;
  export let onClick;
  export let disabled = false;
</script>

<style>
  div {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-color: var(--labit-card-gray);
    border-width: 1px;
    border-radius: 960px/960px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: var(--labit-card-gray);
    position: relative;
    font-weight: bold;
  }

  div.enabled {
    cursor: pointer;
  }

  div.disabled {
    opacity: 0.5;
  }

  /* Animations */

  div.enabled {
    transition: background-color 0.5s linear 0s;
  }

  div.enabled:hover {
    background-color: var(--labit-card-gray);
  }

  div.enabled span {
    transition: color 0.5 linear 0s;
  }

  div.enabled:hover span {
    color: white;
  }
</style>

<div
  class="dialogButton {disabled ? 'disabled' : 'enabled'}"
  on:click={onClick}>
  <span>{text}</span>
</div>
