<script>
    import moment from "moment";

    import { onMount, afterUpdate } from "svelte";
    import { msToFloat, timeConvert, timeToMs } from "../../../js/formatDateTime";

    export let tasks = {};
    export let predefinedTimes = { 0: '08:30', 1: '08:30', 2: '08:30', 3: '08:30', 4: '06:00', 5: '00:00', 6: '00:00' };
    
    Chart.register(ChartDataLabels);

    let reportsChartCanvas, chart;

    let dataLabelArr = [];
    const prepareData = (data) => {
        const days = Object.keys(data);
        let billableDataset = [];
        let nonBillableDataset = [];
        let backgroundBarDataset = [];
        let extraTimeDataset = [];

        days.forEach(day => {
            const spectedTime = predefinedTimes[moment(day, 'DD/MM/YYYY').isoWeekday()-1];
            const roundOffMs = data[day].roundOffMs || 0;
            const spectedMillis = timeToMs(spectedTime);
            const billableMillis = (data[day].billableMillis - roundOffMs < 0) ? 0 : data[day].billableMillis - roundOffMs;
            const billable = msToFloat(billableMillis);
            let extraTime = billableMillis - spectedMillis;
            extraTime = msToFloat(extraTime);
            extraTime = extraTime > 0 ? extraTime : 0;
            billableDataset.push( { x: day, y: billable - extraTime } );
            nonBillableDataset.push( { x: day, y: msToFloat(data[day].nonBillableMillis + roundOffMs) } );
            backgroundBarDataset.push( { x: day, y: msToFloat(spectedMillis) } );
            extraTimeDataset.push( { x: day, y: extraTime } );
            dataLabelArr.push(timeConvert(billableMillis/60000));
        });
        return [billableDataset, nonBillableDataset, backgroundBarDataset, extraTimeDataset];
    };

    const getData = (billableDataset, nonBillableDataset, backgroundBarDataset, extraTimeDataset) => {
        return {
            datasets: [
                {
                    label: "Billable",
                    backgroundColor: "rgb(102, 158, 255)", // Azul
                    data: billableDataset,
                    xAxisID: "xAxis1",
                    barPercentage: 0.6,
                    stack: "stack1",
                    borderRadius: 7,
                    borderWidth: 0,
                    /* datalabels: {
                        align: 'start',
                        anchor: 'start'
                    } */
                },
                {
                    label: "Extra",
                    backgroundColor: "rgb(255, 213, 0)", // Amarillo
                    data: extraTimeDataset,
                    xAxisID: "xAxis1",
                    barPercentage: 0.6,
                    stack: "stack1",
                    borderRadius: 7,
                    borderWidth: 0
                },
                {
                    label: "Round off",
                    backgroundColor: "rgb(153, 160, 172)", // Gris
                    data: nonBillableDataset,
                    xAxisID: "xAxis1",
                    barPercentage: 0.6,
                    stack: "stack2",
                    borderRadius: 7,
                    borderWidth: 0
                },
                {
                    label: "Total",
                    backgroundColor: "rgb(232, 241, 255)",
                    data: backgroundBarDataset, // Azul Fondo
                    xAxisID: "xAxis3",
                    barThickness: "flex",
                    borderRadius: 7,
                    borderWidth: 0
                },
            ],
        };
    } 
    
    const options = {
        responsive: true,
        layout: {
            padding: {
                top: 40
            }
        },
        scales: {
            xAxis1: {
                stacked: true,
                display: true,
            },
            xAxis3: {
                display: false,
                stacked: true,
                type: "category",
                gridLines: {
                    offsetGridLines: true,
                },
                offset: true,
            }

        },
        plugins: {
            legend: {
                position: 'bottom',
                labels : {
                    usePointStyle: true,
                    padding: 20
                }
            },
            tooltip: {
                callbacks: {
                    label: (item) => timeConvert(item.raw.y * 60)
                }
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                formatter: (val, ctx) => {
                    if (ctx.dataset.stack === 'stack1' && ctx.dataset.label === 'Extra') {
                        const label = dataLabelArr[ctx.dataIndex];
                        return label === '0:00' ? '' : label;
                    } else {
                        return '';
                    }
                }
            }
        }
    };

    onMount(() => {
        const [billableDataset, nonBillableDataset, backgroundBarDataset, extraTimeDataset] = prepareData(tasks);
        const data = getData(billableDataset, nonBillableDataset, backgroundBarDataset, extraTimeDataset);

        const ctx = reportsChartCanvas.getContext("2d");
        chart = new Chart(ctx, {
            type: "bar",
            data: data,
            options: options
        });
    });

    afterUpdate(() => {
        dataLabelArr = [];
        const [billableDataset, nonBillableDataset, backgroundBarDataset, extraTimeDataset] = prepareData(tasks);
        const data = getData(billableDataset, nonBillableDataset, backgroundBarDataset, extraTimeDataset);

        chart.data = data;
        //chart.options.plugins.datalabels.formatter = formatterCb;
        chart.update();
    });

</script>

<canvas bind:this={reportsChartCanvas} id="reportsChart" />

<style>
    #reportsChart {
        padding: 10px 0;
        width: 100%;
        height: 100%;
    }
</style>
