<script>
  export let name;
  export let start = "";
  export let end = "";
  export let text = "";
  export let isEditing;
  export let isPhase;
  export let onClick;
  export let editedType;
  export let notAvailable;
  export let fromScope = false;
  export let startTime;
  export let endTime;
  import moment from 'moment';
</script>

<style>
  div.elementResume {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-bottom: 8px; */
  }

  div.elementResume img {
    width: 20px;
    cursor: pointer;
  }

  div.elementInfo {
    /* width: 398px; */
    width: 85%;
    /* height: 22px; */
    border: 0.5px solid var(--labit-card-gray);
    border-radius: 15px;
    display: flex;
    padding: 2px 10px 2px 10px;
    flex-direction: column;
  }

  div.elementInfo span {
    font-size: 15px;
    font-weight: bold;
    color: var(--labit-card-gray);
  }

  div.elementInfo span.name {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div.subphase span {
    font-size: 13px;
  }

  span.date {
    width: 100%;
    text-align: right;
    font-size: 13px !important;
  }
</style>

<div class="elementResume {editedType === 'subphase' ? 'subphase' : ''}">
  <div
    class="elementInfo"
    style={fromScope || editedType === 'stakeholder' ? 'width: 90%;' : ''}>
    <span class="name">{name}</span>
    {#if editedType === 'phase'}
      {#if notAvailable}
        <span>Date not available</span>
      {:else if !notAvailable}
        <span class="date">{moment(start, 'DD/MM/YYYY').format('dd')}
          {start}
          -
          {moment(end, 'DD/MM/YYYY').format('dd')}
          {end}</span>
      {/if}
    {:else if editedType === 'subphase'}
      <span class="date">{moment(start, 'DD/MM/YYYY').format('dd')}
        {start}
        -
        {moment(end, 'DD/MM/YYYY').format('dd')}
        {end}</span>
    {:else if editedType === 'milestone'}
      {#if notAvailable}
        <span class="date">{moment(start, 'DD/MM/YYYY').format('dd')}
          {start}
          All day</span>
      {:else if !notAvailable}
        <span class="date">{moment(start, 'DD/MM/YYYY').format('dd')}
          {start}
          ({startTime}-{endTime})</span>
      {/if}
    {:else if start === '' && end === '' && !notAvailable && !editedType === 'stakeholder'}
      <span class="date">{text} days.</span>
    {:else if editedType === 'stakeholder'}<span class="date">{text}</span>{/if}

    <!--     {#if start}
      <span class="date">{start} - {end}</span>
    {:else if start === '' && end === '' && !notAvailable}
      <span>{text} days</span> -->
  </div>
  {#if !fromScope && editedType !== 'stakeholder'}
    <img
      class="fadedButton"
      src="/images/edit.svg"
      alt=""
      on:click={() => {
        if (editedType === 'phase') {
          isPhase = 1;
        } else if (editedType === 'subphase') {
          isPhase = 2;
        } else if (editedType === 'milestone') {
          isPhase = 3;
        }
        isEditing = true;
        onClick();
      }} />
  {/if}
  <img
    class="fadedButton"
    src="/images/trash.svg"
    alt=""
    on:click={() => {
      isEditing = false;
      onClick();
    }} />
</div>
