<script>
  export let epigraph;

  import RDSGTextAttribute from "./RDSGTextAttribute.svelte";
</script>

<style>
  div.RDSGTextEpigraphContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  div.epigraph {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  img {
    width: 19px;
  }

  span {
    font-size: 13px;
    font-weight: bold;
    margin-left: 8px;
  }
</style>

<div class="RDSGTextEpigraphContainer">
  <div class="epigraph">
    <img src={epigraph.icon} alt="Epigraph Icon" />
    <span>{epigraph.name.toUpperCase()}</span>
  </div>
  <div>
    {#each epigraph.attributes as attribute}
      <RDSGTextAttribute {attribute} />
    {/each}
  </div>
</div>
