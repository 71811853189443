<script>
  export let options;
  export let selectedValue;
  export let placeholder;
  export let onChange;
  export let download = false;

  import Combobox from "./Combobox.svelte";

  let last = false;

  $: if (selectedValue) {
    last = selectedValue.value === options[0].value;
  } else {
    last = false;
  }
</script>

<style>
  div.combobox {
    height: 100%;
    border-color: var(--labit-card-gray);
    border-width: 1px;
    border-style: solid;
    border-radius: 100vh/100vh;
    display: flex;
    align-items: center;
    padding-right: 4px;
    padding-left: 13px;
  }

  span.last {
    font-size: 10px;
    margin-right: 10px;
  }

  span.inactive {
    color: transparent !important;
  }

  span.download {
    color: red;
  }

  div.combobox.download {
    border-width: 0px;
    background-color: white;
  }
</style>

<div class="combobox {download ? 'download' : ''}">
  <span class="last {last ? '' : 'inactive'} {download ? 'download' : ''}">
    LAST
  </span>
  <Combobox
    {options}
    {placeholder}
    bind:selectedValue
    searchable={false}
    {onChange} />
</div>
