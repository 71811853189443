<script>
  export let pic;
  export let name;
  export let company;
  export let companyRole;
  export let profRole;
  export let email1;
  export let telephone1;
  export let email2;
  export let telephone2;
  export let zoom = "";
  export let slack = "";
  export let active;
  export let onClick;
  export let borderColor = "white";
  export let lastActivity;
  export let officeStatus;
  export let atHome;

  import Picture from "./Picture.svelte";

  import { getPlatform, setOpenTool } from "../js/localInfo";
  import { openNewWindow } from "../js/openNewWindow";
  import { openURL } from "../js/openURL";
  import { SLACK_TEAM_URL } from "../js/globalExternalURLs";
  import { openSlackUrlStore } from "../js/stores.js";
  import { randomString } from "../js/randomString";

  const platform = getPlatform();
  let clickZoom = (forceNewWindow) => {
    if (platform === "Web") {
      window.open(zoom);
    } else {
      if (forceNewWindow) {
        openNewWindow(zoom);
      } else {
        setOpenTool(zoom);
        openURL("/tools/external", true);
      }
    }
  };

  let clickSlack = (forceNewWindow) => {
    if (platform === "Web") {
      window.open(SLACK_TEAM_URL + slack);
    } else {
      if (forceNewWindow) {
        openNewWindow(SLACK_TEAM_URL + slack);
      } else {
        openSlackUrlStore.set(
          SLACK_TEAM_URL + slack + "&r=" + randomString(10)
        );
      }
    }
  };
</script>

<div
  class="userCard {active ? '' : 'noActive'}"
  style="border-color: {borderColor};"
>
  <div class="cardContainer">
    <div class="presentation" on:click={onClick}>
      <div class="userPic">
        <Picture
          image={pic}
          {name}
          grayscale={true}
          {lastActivity}
          {officeStatus}
          {atHome}
        />
      </div>
      <span>{name}</span>
      <img class="opcionesDots" src="/images/card-out.svg" alt="Opciones" />
    </div>
    <div class="info">
      <!--<div class="row">
        <div class="column title">
          <span>{company}</span>
        </div>
        <div class="column title">
          <span />
        </div>
      </div>
      <div class="row">
        <div class="column">
          <span>{companyRole}</span>
        </div>
        <div class="column">
          <span>{profRole}</span>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <span>{email1}</span>
        </div>
        <div class="column">
          <span>{email2}</span>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <span>{telephone1}</span>
        </div>
        <div class="column">
          <span>{telephone2}</span>
        </div>
      </div>
    </div>-->
      <div class="row">
        <div class="column title"><span>{company}</span></div>
        <div class="column title"><span /></div>
      </div>
      <div class="row">
        <div class="column"><span>{email1}</span></div>
        <div class="column"><span>{companyRole}</span></div>
      </div>
      <div class="row">
        <div class="column"><span>{telephone1}</span></div>
        <div class="column"><span>{profRole}</span></div>
      </div>
      <!--<div class="row">
        <div class="column">
          <span>{companyRole}</span>
        </div>
        <div class="column">
          <span>{profRole}</span>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <span>{email1}</span>
        </div>
        <div class="column">
          <span>{email2}</span>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <span>{telephone1}</span>
        </div>
        <div class="column">
          <span>{telephone2}</span>
        </div>
      </div>-->
    </div>
  </div>
  <div class="socialIcons">
    {#if slack !== ""}
      <img
        class="fadedButton"
        src="/images/chat.svg"
        alt="Slack Link"
        on:click={() => clickSlack(false)}
        on:contextmenu={(ev) => {
          ev.preventDefault();
          clickSlack(true);
          return false;
        }}
      />
    {/if}
    {#if zoom !== ""}
      <img
        class="fadedButton"
        src="/images/tools/zoom.svg"
        alt="Zoom Link"
        on:click={() => clickZoom(false)}
        on:contextmenu={(ev) => {
          ev.preventDefault();
          clickZoom(true);
          return false;
        }}
      />
    {/if}
  </div>
</div>

<style>
  div.userCard {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 4%/8%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 25px;
    border-style: solid;
    border-width: 1px;
  }

  div.noActive {
    background-color: var(--labit-light-gray);
  }

  div.cardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  img.opcionesDots {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 12px;
    opacity: 1;
  }

  div.presentation {
    width: 100%;
    height: 42%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  div.presentation span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  div.userPic {
    width: 57px;
    height: 57px;
    margin-right: 6%;
  }

  div.presentation span {
    font-size: 19px;
    font-weight: bold;
    color: var(--labit-card-gray);
  }

  div.info {
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: column;
    font-size: 13px;
  }

  div.row {
    width: 100%;
    height: 25%;
    display: flex;
  }

  div.column {
    width: 50%;
    height: 100%;
    font-size: 11px;
    color: var(--labit-card-lightgray);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div.title {
    font-size: 13px;
    color: var(--labit-card-gray);
  }

  div.socialIcons {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }

  div.socialIcons img {
    height: 18px;
  }

  /* Animations */

  img.opcionesDots {
    transition: opacity 0.5s linear 0s;
  }

  img.opcionesDots:hover {
    opacity: 0.5;
  }
</style>
