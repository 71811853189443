<script>
  export let month;
  export let startDate;
  export let endDate;
  export let dayWidth;
  
  import moment from 'moment';

  let days = 0;
  let limit = false;

  let calculateDays = () => {
    limit = false;
    let d = 0;
    const m = moment(month, "YYYY-MM-DD").format("YYYY-MM");
    const sd = moment(startDate, "YYYY-MM-DD").format("YYYY-MM");
    const ed = moment(endDate, "YYYY-MM-DD").format("YYYY-MM");
    if (m === sd) {
      // First month in the planner
      d = parseInt(moment(startDate, "YYYY-MM-DD").format("DD"));
      days = moment(startDate, "YYYY-MM-DD").daysInMonth() - d + 1;
      limit = days < 15;
    } else {
      if (m === ed) {
        // Last month in the planner
        d = parseInt(moment(endDate, "YYYY-MM-DD").format("DD"));
        days = d;
        limit = days < 15;
      } else {
        days = moment(month, "YYYY-MM-DD").daysInMonth();
      }
    }
  };

  $: if (month && startDate && endDate) {
    calculateDays();
  }
</script>

<div
  style="width: {dayWidth * days}px"
  class="borderBottom borderTop borderRight"
>
  {#if !limit}
    {moment(month, "YYYY-MM-DD").format("MMMM YYYY")}
  {/if}
</div>

<style>
  div {
    font-weight: bold;
    font-size: 12px;
    padding-left: 10px;
    flex-shrink: 0;
    flex-grow: 0;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
</style>
