<script>
  export let phase;
  export let currentPhase;
  export let currentSubphase;
  export let isSubphase;
  export let action;
  export let last;
  export let updateCurrentPhases;
  import moment from 'moment';
  import CalendarPicker from "./CalendarPicker/CalendarPicker.svelte";
  import Hoverable from "./Hoverable.svelte";

  let active = false;
  let editing = false;
  let selectedModDate = null;

  let openEdit = () => {
    editing = true;
    selectedModDate = phase.realStart
      ? {
          start: moment(phase.realStart, "YYYY-MM-DD").format("DD/MM/YYYY"),
          end: moment(phase.realEnd, "YYYY-MM-DD").format("DD/MM/YYYY"),
          description: phase.realDescription,
        }
      : {
          start: moment(phase.start, "YYYY-MM-DD").format("DD/MM/YYYY"),
          end: moment(phase.end, "YYYY-MM-DD").format("DD/MM/YYYY"),
          description: "",
        };
  };

  let saveEdit = () => {
    const start = moment(selectedModDate.start, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    const end = moment(selectedModDate.end, "DD/MM/YYYY").format("YYYY-MM-DD");
    if (phase.start !== start || phase.end !== end) {
      phase.realStart = start;
      phase.realEnd = end;
      phase.realDescription = selectedModDate.description;
      phase.modified = true;
      updateCurrentPhases();
    }
    closeEdit();
  };

  let clearEdit = () => {
    phase.realStart = null;
    phase.endStart = null;
    phase.realDescription = null;
    phase.modified = true;
    updateCurrentPhases();
    closeEdit();
  };

  let closeEdit = () => {
    editing = false;
  };
</script>

<style>
  div.dialogProjectPhaseHistoryContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div.row {
    display: flex;
    width: 100%;
  }

  div.name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    align-items: center;
  }

  div.name.show {
    width: 50%;
  }

  div.name.edit {
    width: 40%;
  }

  div.date {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    font-size: 13px;
    color: var(--labit-card-lightgray);
  }

  div.notLast {
    margin-bottom: 8px;
  }

  div.options {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div.options img {
    width: 15px;
    cursor: pointer;
  }

  div.currentPhase span {
    color: green;
  }

  div.real span {
    font-size: 11px !important;
    color: red !important;
  }

  div.description {
    display: flex;
    border-color: red;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    margin-top: 5px;
  }

  div.description span {
    font-size: 11px !important;
    color: red !important;
  }

  div.hasReal {
    cursor: pointer;
  }

  div.subphase span {
    font-size: 11px;
  }

  div.subphase div.name.show {
    margin-left: 5%;
    width: 45%;
  }

  div.subphase div.name.edit {
    margin-left: 5%;
    width: 35%;
  }

  span.title {
    font-size: 11px;
    color: var(--labit-card-gray);
  }

  div.calendars {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }

  div.calendar {
    height: 17px;
    width: 48%;
  }

  div.modOptions {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }

  div.modOptions img {
    height: 15px;
    cursor: pointer;
    margin-right: 5px;
  }

  textarea {
    font-size: 11px;
    color: var(--labit-card-lightgray);
    width: 100%;
    border-radius: 5px;
    margin-top: 5px;
    height: 50px;
  }
</style>

<div
  class="dialogProjectPhaseHistoryContainer {last ? '' : 'notLast'}
  {currentPhase === phase.name ? 'currentPhase' : ''}
  {phase.realStart ? 'hasReal' : ''}
  {isSubphase ? 'subphase' : ''}">
  <Hoverable let:hovering={active}>
    <div class="row">
      <div class="phaseInfo name {action !== 'show' ? 'edit' : 'show'}">
        <span>{phase.name}</span>
      </div>
      <div class="phaseInfo date">
        <span>{moment(phase.start, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
      </div>
      <div class="phaseInfo date">
        <span>{moment(phase.end, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
      </div>
      {#if action !== 'show'}
        <div class="options">
          <img
            class="fadedButton"
            src="/images/edit.svg"
            alt="Edit Phase History"
            on:click={() => openEdit()} />
        </div>
      {/if}
    </div>
    {#if phase.realStart}
      <div class="row">
        <div class="phaseInfo name {action !== 'show' ? 'edit' : 'show'}" />
        <div class="phaseInfo date real">
          <span>
            {moment(phase.realStart, 'YYYY-MM-DD').format('DD/MM/YYYY')}
          </span>
        </div>
        <div class="phaseInfo date real">
          <span>
            {moment(phase.realEnd, 'YYYY-MM-DD').format('DD/MM/YYYY')}
          </span>
        </div>
        {#if action !== 'show'}
          <div class="options" />
        {/if}
      </div>
      {#if active && !editing && phase.realDescription !== ''}
        <div class="row">
          <div class="description"><span>{phase.realDescription}</span></div>
        </div>
      {/if}
    {/if}
  </Hoverable>
</div>
{#if editing}
  <div class="row"><span class="title">Date Modification</span></div>
  <div class="row">
    <div class="calendars">
      <div class="calendar">
        <CalendarPicker bind:date={selectedModDate.start} options={[]} />
      </div>
      <div class="calendar">
        <CalendarPicker bind:date={selectedModDate.end} options={[]} />
      </div>
    </div>
    <div class="modOptions">
      <img
        class="fadedButton"
        src="/images/save.svg"
        alt="Save"
        on:click={() => saveEdit()} />
      <img
        class="fadedButton"
        src="/images/trash.svg"
        alt="Clear"
        on:click={() => clearEdit()} />
      <img
        class="fadedButton"
        src="/images/x.svg"
        alt="Cancel"
        on:click={() => closeEdit()} />
    </div>
  </div>
  <div class="row">
    <textarea bind:value={selectedModDate.description} maxlength="200" />
  </div>
{/if}
{#if !isSubphase}
  {#each phase.subphases as subphase}
    <svelte:self
      phase={subphase}
      currentPhase={currentSubphase}
      currentSubphase={null}
      isSubphase={true}
      {action}
      {last}
      {updateCurrentPhases} />
  {/each}
{/if}
