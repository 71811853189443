import { writable } from "svelte/store";

export let mainUrl = writable({ url: "/" });

export let tempUser = writable(null);

export let refreshLicenses = writable(false);

export let openSlackUrlStore = writable("");

export let taskTimeStart = writable(0);

export let task = writable({ description: '', phase: '', taskType: '', start: '', end: '' });

export let myPersonalPlanner = writable({ id: '', owner: '' });

export let myPlanners = writable([]);

export let generalHolidays = writable([]);

const storedSelectedProjectId = localStorage.getItem('selectedProjectId');
export let selectedProjectId = writable(storedSelectedProjectId);

const storedDashboardItemGrid = localStorage.getItem('storedDashboardItemGrid');
export let dashboardItemGrid = writable(storedDashboardItemGrid);

export const absencesNotification = writable(false);

export const slackNotification = writable(false);

export const currentAbsenceId = writable(null);