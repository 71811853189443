<script>
  export let company;
  export let suscription;
  export let createUpdateSuscription;
  export let currentSuscription;
  export let option;
  export let productSelected;
  export let keySelected;
  export let renewalStatusOptionsSelected;
  export let suscriptionTypeSelected;
  export let onClick;
</script>

<div class="suscriptionContainer">
  <div class="suscriptionInfoImageName">
    <img
      class="suscriptionProgramLogo"
      src={company.products.find((p) => p.id === suscription.product)
        .pictureURL}
      alt="productLogo"
    />
    <span class="separatedData"
      >{company.products.find((p) => p.id === suscription.product).name}</span
    >
    <span class="separatedData">{suscription.contractNumber}</span>
  </div>
  <div class="optionsContainer">
    <!-- svelte-ignore missing-declaration -->
    <img
      class="optionIcon"
      src="/images/edit.svg"
      alt="Edit Logo"
      on:click={() => {
        option = "update";
        createUpdateSuscription = true;
        currentSuscription.id = suscription.id;
        currentSuscription.contractNumber = suscription.contractNumber;
        currentSuscription.seats = suscription.seats.toString();
        currentSuscription.type = suscription.type;
        currentSuscription.term = suscription.term;
        currentSuscription.expiringDate = suscription.expiringDate;
        currentSuscription.renewalComments = suscription.renewalComments;
        currentSuscription.renewalStatus = suscription.renewalStatus;
        productSelected = {
          value: suscription.product,
          label: company.products.find((p) => {
            return p.id === suscription.product;
          }).name,
        };
        keySelected = {
          value: suscription.license,
          label: company.keys.find((k) => {
            return k.id === suscription.license;
          }).username,
        };
        currentSuscription.product = productSelected.value;
        currentSuscription.license = keySelected.value;
        /*         currentSuscription.product = suscription.product;
        currentSuscription.license = suscription.license; */
        renewalStatusOptionsSelected = {
          value: suscription.renewalStatus,
          label: suscription.renewalStatus,
        };
        currentSuscription.renewalStatus = renewalStatusOptionsSelected.value;
        suscriptionTypeSelected = {
          value: suscription.type,
          label: suscription.type,
        };
        currentSuscription.type = suscriptionTypeSelected.value;
      }}
    />
    <img
      class="optionIcon"
      src="/images/trash.svg"
      alt="Remove Logo"
      on:click={() => {
        option = "delete";
        onClick();
      }}
    />
  </div>
</div>

<style>
  .optionsContainer {
    width: 12%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .suscriptionInfoImageName {
    width: 88%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .separatedData {
    width: 45%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .optionIcon {
    width: 20px;
    cursor: pointer;
  }

  .optionsContainer {
    width: 12%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .suscriptionProgramLogo {
    width: 20px;
    margin-right: 10px;
  }

  .suscriptionContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
  }

  .suscriptionContainer span {
    font-size: 13px;
  }
</style>
