<script>
  export let date;
  export let options;
  export let analytics = false;
  export let onChange = null;

  import moment from 'moment';
  import CalendarPickerWeekdayTitle from "./components/CalendarPickerWeekdayTitle.svelte";
  import CalendarPickerWeekdayRow from "./components/CalendarPickerWeekdayRow.svelte";
  import CalendarPickerOption from "./components/CalendarPickerOption.svelte";
  import {
    getMonthNumberFromNameAsNumber,
    getMonthNumberFromNameAsString,
    getMonthNameFromNumberAsString,
    getMonthNameFromNumberAsNumber,
    formatNumber,
  } from "./utils/conversions";

  import { onMount } from "svelte";

  import { randomString } from "../../js/randomString";
  import { clickOutside } from "../../js/clickOutside";

  let showCalendar = false;
  let selectedMonth = "January";
  let selectedYear = "2020";
  let weekContainer = createWeekContainer(selectedMonth, selectedYear);
  let optionMap = [];
  let selectedOption = false;
  let datePrev = date;

  let id = randomString(10);
  let calendarId = randomString(10);

  let years = [];

  onMount(async () => {
    years = [];
    for (let i = 1900; i <= 2100; i++) {
      years.push(i.toString());
    }
    years = years;

    const pos = options.findIndex((item) => {
      return item === date;
    });
    if (pos >= 0) {
      onClickToday();
      clickOption(pos);
    }
  });

  function clickMenu() {
    showCalendar = !showCalendar;
    if (showCalendar) {
      const input = window.$("#" + id);
      let calendar = window.$("#" + calendarId);
      calendar.removeClass("hidden");
      calendar.detach().appendTo("body");
      const pos = input.offset();
      const top = pos.top + input.height() + 5;
      const left = pos.left;
      calendar.css({});
      calendar.css({ top: top + "px", left: left + "px" });
    }
  }

  function onClickOutside() {
    showCalendar = false;
  }

  function createWeekContainer(month, year) {
    let weekCont = [];
    let monthNumber = getMonthNumberFromNameAsNumber(month);
    let dateMonth = new Date(year, monthNumber, 1);
    let loopMonth = monthNumber;
    const days = [];
    do {
      const day = dateMonth.getDate();
      days.push(day);
      dateMonth.setDate(dateMonth.getDate() + 1);
      loopMonth = dateMonth.getMonth();
    } while (loopMonth == monthNumber);
    let row1 = [-1, -1, -1, -1, -1, -1, -1];
    let day = 1;
    let weekday = new Date(year, monthNumber, day).getDay() - 1;
    if (weekday < 0) {
      weekday = 6;
    }
    for (let i = weekday; i < 7; i++) {
      row1[i] = day;
      day++;
    }
    let row2 = [];
    for (let i = 0; i < 7; i++) {
      row2[i] = day;
      day++;
    }
    let row3 = [];
    for (let i = 0; i < 7; i++) {
      row3[i] = day;
      day++;
    }
    let row4 = [];
    for (let i = 0; i < 7; i++) {
      row4[i] = day;
      day++;
    }
    let row5 = [-1, -1, -1, -1, -1, -1, -1];
    for (let i = 0; i < 7; i++) {
      if (day <= days[days.length - 1]) {
        row5[i] = day;
      }
      day++;
    }
    let row6 = [-1, -1, -1, -1, -1, -1, -1];
    for (let i = 0; i < 7; i++) {
      if (day <= days[days.length - 1]) {
        row6[i] = day;
      }
      day++;
    }
    weekCont = [row1, row2, row3, row4, row5, row6];

    let pos = -1;
    for (let i = 0; i < 7 && pos < 0; i++) {
      if (row1[i] >= 0) {
        pos = i;
      }
    }
    const prevMonth = moment(
      selectedYear +
        "-" +
        getMonthNumberFromNameAsString(selectedMonth) +
        "-01",
      "YYYY-MM-DD"
    ).subtract(1, "month");
    let lastDay = parseInt(prevMonth.clone().endOf("month").format("DD"));
    for (let i = pos - 1; i >= 0; i--) {
      row1[i] = -lastDay;
      lastDay--;
    }

    const lastRowStat = row6.reduce((acc, curr) => {
      return acc || curr >= 0;
    }, false);
    const lastRow = lastRowStat ? row6 : row5;
    let firstDay = 1;
    for (let i = 0; i < 7; i++) {
      if (lastRow[i] < 0) {
        lastRow[i] = -firstDay;
        firstDay++;
      }
    }

    return weekCont;
  }

  function onClickDay(day) {
    let dayFormatted = formatNumber(day);
    date =
      dayFormatted +
      "/" +
      getMonthNumberFromNameAsString(selectedMonth) +
      "/" +
      selectedYear;
    if (onChange) {
      onChange();
    }
  }

  function onClickToday() {
    const today = new Date();
    selectedMonth = getMonthNameFromNumberAsNumber(today.getMonth());
    selectedYear = today.getFullYear().toString();
    date =
      formatNumber(today.getDate()) +
      "/" +
      formatNumber(today.getMonth() + 1) +
      "/" +
      today.getFullYear();
    weekContainer = createWeekContainer(selectedMonth, selectedYear);
    onClickDay(today.getDate());
  }

  function clickOption(index) {
    optionMap = optionMap.map((item, i) => {
      const option = item;
      option.selected = index === i ? !option.selected : false;
      return option;
    });
    if (optionMap[index].selected) {
      if (!selectedOption) {
        datePrev = date;
      }
      onClickToday();
      date = optionMap[index].text;
      selectedOption = true;
    } else {
      date = datePrev;
      selectedOption = false;
      if (onChange) {
        onChange();
      }
    }
  }

  let nextMonth = () => {
    let month = getMonthNumberFromNameAsString(selectedMonth);
    let dateM = moment(selectedYear + "-" + month + "-01", "YYYY-MM-DD");
    dateM.add(1, "month");
    selectedMonth = getMonthNameFromNumberAsString(dateM.format("MM"));
    selectedYear = dateM.format("YYYY");
    if (onChange) {
      onChange();
    }
  };

  let previousMonth = () => {
    let month = getMonthNumberFromNameAsString(selectedMonth);
    let dateM = moment(selectedYear + "-" + month + "-01", "YYYY-MM-DD");
    dateM.subtract(1, "month");
    selectedMonth = getMonthNameFromNumberAsString(dateM.format("MM"));
    selectedYear = dateM.format("YYYY");
    if (onChange) {
      onChange();
    }
  };

  $: {
    if (!selectedOption) {
      const dateItems = date.split("/");
      selectedMonth = getMonthNameFromNumberAsString(dateItems[1]);
      selectedYear = dateItems[2];
    }
  }

  $: {
    if (!selectedOption) {
      weekContainer = createWeekContainer(selectedMonth, selectedYear);
    }
  }

  $: optionMap = options.map((item) => {
    return {
      text: item,
      selected: false,
    };
  });
</script>

<div class="MSContainer" {id}>
  <input
    class="values {analytics ? 'analytics' : ''}"
    type="text"
    readonly="true"
    on:click={() => clickMenu()}
    value={date}
  />
  <div
    class="calendar {showCalendar ? '' : 'hidden'}"
    id={calendarId}
    use:clickOutside
    on:click_outside={onClickOutside}
  >
    <div class="header">
      <select
        class="month"
        bind:value={selectedMonth}
        disabled={selectedOption}
        on:change={() => {
          if (onChange) {
            onChange();
          }
        }}
      >
        <option value="January">January</option>
        <option value="February">February</option>
        <option value="March">March</option>
        <option value="April">April</option>
        <option value="May">May</option>
        <option value="June">June</option>
        <option value="July">July</option>
        <option value="August">August</option>
        <option value="September">September</option>
        <option value="October">October</option>
        <option value="November">November</option>
        <option value="December">December</option>
      </select>
      <select
        class="year"
        bind:value={selectedYear}
        disabled={selectedOption}
        on:change={() => {
          if (onChange) {
            onChange();
          }
        }}
      >
        {#each years as year}
          <option value={year}>{year}</option>
        {/each}
      </select>
      <div class="arrowMonths">
        <img
          class="fadedButton"
          src="/images/left-arrow-simple.svg"
          alt="Previous Month"
          on:click={() => {
            previousMonth();
          }}
        />
        <img
          class="fadedButton"
          src="/images/right-arrow-simple.svg"
          alt="Next Month"
          on:click={() => {
            nextMonth();
          }}
        />
      </div>
    </div>
    <div class="line" />
    <div class="weekDays">
      <CalendarPickerWeekdayTitle title="Mo" />
      <CalendarPickerWeekdayTitle title="Tu" />
      <CalendarPickerWeekdayTitle title="We" />
      <CalendarPickerWeekdayTitle title="Th" />
      <CalendarPickerWeekdayTitle title="Fr" />
      <CalendarPickerWeekdayTitle title="Sa" />
      <CalendarPickerWeekdayTitle title="Su" />
    </div>
    <div class="weeks">
      {#if weekContainer.length > 0}
        <CalendarPickerWeekdayRow
          weekdays={weekContainer[0]}
          {date}
          {selectedMonth}
          {selectedYear}
          {selectedOption}
          {onClickDay}
        />
        <CalendarPickerWeekdayRow
          weekdays={weekContainer[1]}
          {date}
          {selectedMonth}
          {selectedYear}
          {selectedOption}
          {onClickDay}
        />
        <CalendarPickerWeekdayRow
          weekdays={weekContainer[2]}
          {date}
          {selectedMonth}
          {selectedYear}
          {selectedOption}
          {onClickDay}
        />
        <CalendarPickerWeekdayRow
          weekdays={weekContainer[3]}
          {date}
          {selectedMonth}
          {selectedYear}
          {selectedOption}
          {onClickDay}
        />
        <CalendarPickerWeekdayRow
          weekdays={weekContainer[4]}
          {date}
          {selectedMonth}
          {selectedYear}
          {selectedOption}
          {onClickDay}
        />
        <CalendarPickerWeekdayRow
          weekdays={weekContainer[5]}
          {date}
          {selectedMonth}
          {selectedYear}
          {selectedOption}
          {onClickDay}
        />
      {/if}
    </div>
    <div class="operations">
      <button on:click={() => onClickToday()} disabled={selectedOption}>
        Today
      </button>
    </div>
    {#if options.length > 0}
      <div class="line" />
      <div class="options">
        {#each options as option, index}
          <CalendarPickerOption
            text={option}
            checked={optionMap[index].selected}
            onClick={() => clickOption(index)}
          />
        {/each}
      </div>
    {/if}
  </div>
</div>

<style>
  div.MSContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--labit-dark-gray);
  }

  div.MSContainer div {
    width: 100%;
  }

  input.values {
    width: 100%;
    height: 100%;
    background-color: var(--labit-background-gray);
    border-style: solid;
    border-color: var(--labit-gray);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 960px/960px;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    color: var(--labit-dark-gray);
  }

  div.calendar {
    min-width: 250px;
    position: absolute;
    z-index: 10005;
    top: 100%;
    background-color: white;
    border-color: var(--labit-gray);
    border-width: 1px;
    border-style: solid;
    font-size: 12px;
    max-width: 288px;
    border-radius: 10px;
  }

  div.header {
    display: flex;
    padding: 10px;
    justify-content: space-between;
  }

  div.weekDays {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 15px;
    padding-left: 15px;
  }

  div.weeks {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    padding-right: 15px;
    padding-left: 15px;
  }

  div.operations {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  div.options {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  div.header select {
    padding-top: 0.4em;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0.4em;
    margin: 0px;
    border-style: solid;
    border-color: var(--labit-gray);
  }

  div.header select {
    font-size: 12px;
    font-family: "Karla";
  }

  div.header select.month {
    width: 47%;
  }

  div.header select.year {
    width: 33%;
  }

  div.line {
    width: 100%;
    height: 1px;
    background-color: var(--labit-light-gray);
    margin-top: 5px;
    margin-bottom: 5px;
  }

  input.analytics {
    border-color: var(--labit-card-gray) !important;
    background-color: rgb(215, 225, 234) !important;
  }

  :global(div.MSContainer div:nth-child(1)) {
    width: 100%;
    height: 100%;
    display: flex;
  }

  div.hidden {
    display: none;
  }

  div.arrowMonths {
    display: flex;
    align-items: center;
  }

  div.arrowMonths img {
    height: 12px;
  }

  div.arrowMonths img:last-child {
    margin-left: 9px;
  }
</style>
