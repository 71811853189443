import { post } from "./labit-connection";

let getStandardInfo = (fullName) => {
  const tokens = fullName.split("_");
  const values = {
    product: tokens[1],
    version: tokens[2],
    location: tokens[3],
  };
  return values;
};

export let loadStandard = async (id) => {
  // Standard

  let standard = null;
  let creationDate = "";
  let createdBy = "";
  let modificationDate = "";
  let modifiedBy = "";
  let response = await post(
    `select standards_versions_id, s.company_id, standard_name, revit_link, Name, creationDate, createdBy, modificationDate, modifiedBy
            from standards_versions as s
            left join companies as c on c.company_id=s.company_id
            where standards_versions_id=${id}`
  );
  if (response.length > 0) {
    standard = {
      id: response[0].standards_versions_id,
      company: {
        value: response[0].company_id,
        label: response[0].Name,
      },
      fullName: response[0].standard_name,
      revitLink: response[0].revit_link,
      product: "",
      version: "",
      location: "",
      projects: [],
      programs: [],
      creationInfo: null,
      modificationInfo: null,
      duplicated: null,
    };

    creationDate = response[0].creationDate;
    createdBy = response[0].createdBy;
    modificationDate = response[0].modificationDate;
    modifiedBy = response[0].modifiedBy;

    // Standard Info
    const values = getStandardInfo(standard.fullName);
    standard.product = values.product;
    standard.version = values.version;
    standard.location = values.location;

    // Projects
    response = await post(
      `select project_id, Name, TimeLabitCode, FolderLabitCode
            from projects
            where standards_versions_id=${standard.id}`
    );
    standard.projects = response.map((project) => {
      return {
        label: project.Name,
        value: {
          id: project.project_id,
          name: project.Name,
          timeLabitCode: project.TimeLabitCode,
          folderLabitCode: project.FolderLabitCode,
        },
      };
    });

    // Programs
    let programs = [];
    let spaces = [];
    let rooms = [];
    response = await post(
      `select st.space_type_id as roomId, st.Name as roomName, description, room_name, sn.space_name_id as spaceId, sn.Name as spaceName, pt.program_type_id as programId, pt.Name as programName, color, iconUrl
            from space_types as st
            right join space_names as sn on sn.space_name_id=st.space_name_id
            right join program_types as pt on pt.program_type_id=sn.program_type_id
            where pt.standards_version_id=${standard.id}
            order by programId asc`
    );
    response.forEach((item) => {
      let program = programs.find((p) => {
        return p.id === item.programId;
      });
      if (!program) {
        program = {
          id: item.programId,
          name: item.programName,
          color: item.color,
          icon: item.iconUrl,
          spaces: [],
          modified: false,
        };
        programs.push(program);
      }
      if (item.spaceId) {
        let space = spaces.find((s) => {
          return s.id === item.spaceId;
        });
        if (!space) {
          space = {
            id: item.spaceId,
            name: item.spaceName,
            program: item.programId,
            rooms: [],
          };
          spaces.push(space);
        }
      }
      if (item.roomId) {
        let room = rooms.find((r) => {
          return r.id === item.roomId;
        });
        if (!room) {
          room = {
            id: item.roomId,
            name: item.roomName,
            code: item.room_name,
            description: item.description,
            space: item.spaceId,
          };
          rooms.push(room);
        }
      }
    });

    rooms.forEach((room) => {
      const space = spaces.find((s) => {
        return s.id === room.space;
      });
      if (space) {
        space.rooms.push({
          id: room.id,
          name: room.name,
          code: room.code,
          description: room.description,
          modified: false,
        });
      }
    });

    spaces.forEach((space) => {
      const program = programs.find((s) => {
        return s.id === space.program;
      });
      if (program) {
        program.spaces.push({
          id: space.id,
          name: space.name,
          rooms: space.rooms,
          modified: false,
        });
      }
    });

    /*console.log(programs);
    console.log(spaces);
    console.log(rooms);*/

    standard.programs = programs;

    // Creation and modification info

    response = await post(
      `select Name, PictureUrl
        from people
        where contact_id=${createdBy}`
    );

    if (response.length > 0) {
      standard.creationInfo = {
        name: response[0].Name,
        pic: response[0].PictureUrl,
        date: creationDate,
      };
    }

    response = await post(
      `select Name, PictureUrl
        from people
        where contact_id=${modifiedBy}`
    );

    if (response.length > 0) {
      standard.modificationInfo = {
        name: response[0].Name,
        pic: response[0].PictureUrl,
        date: modificationDate,
      };
    }
  }

  return standard;
};
