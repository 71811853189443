<script>
  export let text;
  export let options;
  export let selectedValue;
  export let placeholder;
  export let onChange;
  export let special = false;
  export let width = "";
  export let backgroundColor = "transparent";
  export let disabled = false;

  import Combobox from "./Combobox.svelte";
</script>

<div class="submenuComboboxContainer">
  <span>{text}</span>
  <div
    class={special ? "special" : ""}
    style="width: {width !== '' ? width : ''}"
  >
    <Combobox
      {options}
      {placeholder}
      bind:selectedValue
      {onChange}
      {backgroundColor}
      {disabled}
    />
  </div>
</div>

<style>
  div.submenuComboboxContainer {
    display: flex;
    color: var(--labit-card-gray);
    height: 21px;
    font-size: 13px;
    align-items: center;
  }

  div.submenuComboboxContainer div {
    height: 100%;
    width: 163px;
  }

  span {
    margin-right: 13px;
  }

  div.submenuComboboxContainer div.special {
    width: 259px;
  }
</style>
