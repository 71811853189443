<script>
  export let answer;
  export let onEdit;
  export let onRemove;
  export let removingAnswer;
</script>

<div class="answerItemContainer">
  <div class="spaceRoomInfo">
    <span>{answer.answer}</span>
  </div>
  <div class="options">
    <img
      class="fadedButton"
      src="/images/edit.svg"
      alt="Edit"
      on:click={onEdit}
    />
    <img
      class="fadedButton last {removingAnswer === answer.id
        ? 'hiddenElement'
        : ''}"
      src="/images/trash.svg"
      alt="Remove"
      on:click={onRemove}
    />
    <img
      class="fadedButton last {removingAnswer !== answer.id
        ? 'hiddenElement'
        : ''}"
      src="/images/trash-red.svg"
      alt="Remove"
      on:click={onRemove}
    />
  </div>
</div>

<style>
  div.answerItemContainer {
    width: 100%;
    display: flex;
  }

  div.answerItemContainer:not(:last-child) {
    margin-bottom: 5px;
  }

  div.spaceRoomInfo {
    width: 80%;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--labit-card-gray);
  }

  div.options {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }

  div.options img {
    width: 20px;
  }

  div.options img:not(.last) {
    margin-right: 5px;
  }
</style>
