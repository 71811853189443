import { setOpenURL, setOpenURLUserAgent, getPlatform } from "./localInfo";
import { SLACK_FILE_SERVER } from "./globalExternalURLs";

const platform = getPlatform();

export let openNewWindow = (url, useragent = "") => {
  console.log(url);
  if (url.startsWith("/") || platform === "Web") {
    const openUrl =
      url.startsWith("/") || url.startsWith("http") ? url : "https://" + url;
    window.open(openUrl);
  } else {
    if (url.startsWith(SLACK_FILE_SERVER)) {
      window.open(url);
    } else {
      const openUrl = url.startsWith("http") ? url : "https://" + url;
      setOpenURL(openUrl);
      setOpenURLUserAgent(useragent);
      window.open("/urlbrowser");
    }
  }
};
