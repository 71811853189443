<script>
  export let title;
  export let text;
  export let mode; // show, edit
  export let icon;

  import TextEdit from "./TextEdit.svelte";
</script>

<style>
  div.submenuTextEditIconContainer {
    display: flex;
    color: var(--labit-card-gray);
    height: 21px;
    font-size: 13px;
    align-items: center;
  }

  div.submenuTextEditIconContainer div {
    height: 100%;
    width: 259px;
    display: flex;
    align-items: center;
  }

  span {
    margin-right: 7px;
  }

  span.text {
    font-size: 12px;
    font-weight: bold;
  }

  img {
    height: 100%;
    margin-right: 7px;
    cursor: pointer;
  }
</style>

<div class="submenuTextEditIconContainer">
  <img src={icon} alt="Text Edit Icon" />
  <span>{title}</span>
  <div>
    {#if mode === 'show'}
      <span class="text">{text}</span>
    {:else}
      <TextEdit
        placeholder="type here..."
        maxlength="70"
        bind:value={text}
        img={null} />
    {/if}
  </div>
</div>
