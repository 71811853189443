<script>
  import Menu from "../components/Menu.svelte";
  import DashboardIcon from "../components/dashboard/DashboardIcon.svelte";

  import { getPlatform, setOpenTool, setOutlookNext } from "../js/localInfo";
  import { YOUTUBE_URL, CLOUDHQ_URL } from "../js/globalExternalURLs";
  import { openNewWindow } from "../js/openNewWindow";
  import { openURL } from "../js/openURL";

  const PAGE = "ANALYTICS";

  const platform = getPlatform();

  let clickIcon = (url, forceNewWindow) => {
    if (platform === "Web") {
      window.open(url);
    } else {
      if (forceNewWindow) {
        openNewWindow(url);
      } else {
        setOpenTool(url);
        openURL("/tools/external", true);
      }
    }
  };
</script>

<div class="content">
  <!-- Body -->
  <div class="container">
    <div class="dashboardItem">
      <DashboardIcon
        title="Projects"
        icon="/images/projects.svg"
        url="/project-analytics"
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="Project Documents"
        icon="/images/project-documents.svg"
        url="/projects-documents"
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="People Documents"
        icon="/images/payrolls.svg"
        url="/people-documents-analytics"
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="License Manager"
        icon="/images/key.svg"
        url="/license-manager"
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="License Assignments"
        icon="/images/key-assignment.svg"
        url="/assigned-licenses"
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="Revit Links"
        icon="/images/revit-links.svg"
        url="/revit-link"
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="Lesson Learnt"
        icon="/images/youtube.png"
        url=""
        onClick={() => clickIcon(YOUTUBE_URL, false)}
        onRightClick={() => clickIcon(YOUTUBE_URL, true)}
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="CloudHQ"
        icon="/images/cloudhq.png"
        url=""
        onClick={() => clickIcon(CLOUDHQ_URL, false)}
        onRightClick={() => clickIcon(CLOUDHQ_URL, true)}
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="Standards"
        icon="/images/projects.svg"
        url="/standards"
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="Document Management"
        icon="/images/armchair.svg"
        url="/document-management"
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="Planner"
        icon="/images/scopes.svg"
        url=""
        onClick={() => {
          openURL("/resource-management", true);
        }}
        onRightClick={() => {
          openNewWindow("/skylab-main/resource-management");
        }}
      />
    </div>
    <div class="dashboardItem">
      <DashboardIcon
        title="Settings"
        icon="/images/settings.svg"
        url="/settings"
      />
    </div>
  </div>
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    margin-top: 20px;
    display: grid;
    justify-content: center;
    row-gap: 16px;
    column-gap: 16px;
    grid-template-columns: repeat(auto-fill, 260px);
    justify-content: start;
  }

  div.dashboardItem {
    width: 260px;
    height: 300px;
    background-color: white;
    border-radius: 14px;
  }

  div.dashboardItem:not(:last-child) {
    margin-right: 16px;
  }
</style>
