<script>
  export let pic;
  export let name;
  export let lastName;
  export let company;
  export let address;
  export let city;
  export let country;
  export let code;
  export let active;
  export let isInternal;
  export let onClick;
</script>

<div class="projectCard {active ? '' : 'noActive'}">
  <div
    class="pic pointer"
    style="background-image: url('{pic}')"
    on:click={onClick}
  >
    {#if !pic || (pic && pic.trim() === "")}
      <img src="/images/no-pic-project.svg" alt="No Pic" />
    {/if}
  </div>
  <div class="info">
    <div class="row first pointer" on:click={onClick}>
      <span class="title">{name}</span>
      <img class="opcionesDots" src="/images/card-out.svg" alt="Opciones" />
    </div>
    <div class="row pointer" on:click={onClick}>
      <span class="titleCode">{code}</span>
    </div>
    <div class="row lastName">
      <span>{isInternal ? "Internal project" : lastName}</span>
    </div>
    <div class="row location">
      <!--<span>{address}</span>-->
      <span>{city}</span>
      <span>{country.split(" - ")[0]}</span>
    </div>
  </div>
</div>

<style>
  div.projectCard {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8%/8%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  div.noActive {
    background-color: var(--labit-light-gray);
  }

  div.pic {
    width: 100%;
    height: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--labit-dialog-background);
  }

  div.pic img {
    width: 30px;
  }

  div.info {
    width: 100%;
    height: 40%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  span {
    font-size: 11px;
    color: var(--labit-card-lightgray);
  }

  span.title {
    font-size: 16px;
    font-weight: bold;
    color: var(--labit-card-gray);
  }

  span.titleCode {
    font-size: 13px;
    font-weight: bold;
    color: var(--labit-card-gray);
  }

  div.lastName span {
    font-size: 13px !important;
  }

  span.spacer {
    margin-left: 5%;
  }

  div.row {
    width: 100%;
    display: flex;
  }

  div.first {
    justify-content: space-between;
  }

  img.opcionesDots {
    width: 12px;
    opacity: 1;
  }

  div.pointer {
    cursor: pointer;
  }

  div.location {
    justify-content: space-between;
  }

  /* Animations */

  img.opcionesDots {
    transition: opacity 0.5s linear 0s;
  }

  img.opcionesDots:hover {
    opacity: 0.5;
  }
</style>
