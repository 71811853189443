<script>
  export let id;
  export let conflict;
  export let onClick;
  export let filterGreen;
  export let filterYellow;
  export let filterRed;
  export let editable;

  import Hoverable from "./Hoverable.svelte";
  import RDSCellCardDescription from "./RDSCellCardDescription.svelte";

  let title = "";
  let backgroundColor = "";
  let color = "gray";

  let showCard = true;

  $: {
    if (conflict.exception) {
      if (conflict.exception.type === "exception") {
        title = "Exception";
        backgroundColor = "var(--labit-light-yellow)";
        color = "yellow";
      }
      if (conflict.exception.type === "replace") {
        title = "Replace";
        backgroundColor = "var(--labit-light-yellow)";
        color = "yellow";
      }
    } else {
      if (conflict.status === "missing") {
        title = "Missing";
        if (
          conflict.text
            .trim()
            .toUpperCase()
            .endsWith("[OR]") ||
          conflict.text
            .trim()
            .toUpperCase()
            .endsWith("[OPTIONAL]")
        ) {
          backgroundColor = "var(--labit-gray)";
        } else {
          backgroundColor = "var(--labit-light-red)";
          color = "red";
        }
      }
      if (conflict.status === "notExpected") {
        title = "Not Expected";
        backgroundColor = "var(--labit-light-red)";
        color = "red";
      }
      if (conflict.status === "found") {
        title = "Found";
        backgroundColor = "var(--labit-light-green)";
        color = "green";
      }
    }
    if (color === "green") {
      showCard = filterGreen;
    }
    if (color === "yellow") {
      showCard = filterYellow;
    }
    if (color === "red") {
      showCard = filterRed;
    }
    if (color === "gray") {
      showCard = filterGreen && filterYellow && filterRed;
    }
  }
</script>

<style>
  div.RDSCellCard {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.RDSCellCard:not(:last-child) {
    margin-bottom: 12px;
  }

  div.card {
    border-color: var(--labit-darkgray);
    border-width: 1px;
    border-style: solid;
    width: 100%;
    border-radius: 10px;
    font-size: 12px;
    padding: 6px;
  }

  div.cardHeader {
    background-color: var(--labit-darkgray);
    color: white;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 6px;
  }

  div.pointer {
    cursor: pointer;
  }
</style>

{#if (!conflict.exception || (conflict.exception && conflict.exception.type !== 'hidden')) && showCard}
  <div class="RDSCellCard">
    <Hoverable let:hovering={active}>
      <div
        class="card {(conflict.status !== 'found' || conflict.exception) && editable ? 'pointer' : ''}"
        style="background-color: {backgroundColor}"
        on:click={onClick}>
        <div class="cardHeader">{title}</div>
        {#if conflict.exception && conflict.exception.type === 'replace'}
          <span>
            <b>REPLACED:</b>
            {conflict.exception.replacingValue}
          </span>
        {:else}
          <span>{conflict.text}</span>
        {/if}
      </div>
      {#if conflict.exception && active}
        <RDSCellCardDescription
          {id}
          description={conflict.exception.description} />
      {/if}
    </Hoverable>
  </div>
{/if}
