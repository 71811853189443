<script>
  export let title;
  export let url;
  export let onPreviewFile;
  export let initialBorder = false;
  export let readOnly = false;
  export let files = [];
  export let compressedMode = false;
  export let removable = false;
  export let onRemoveFullFolder = null;
  import moment from 'moment';

  //import FileContainerFile from "./FileContainerFile.svelte";
  import TextEditHints from "./TextEditHints/TextEditHints.svelte";
  import TextEdit from "./TextEdit.svelte";
  import Keyword from "./Keyword.svelte";
  import SubmenuBlackButtonIcon from "../components/SubmenuBlackButtonIcon.svelte";
  import Progressbar from "./Progressbar.svelte";
  import FileBoobleFolderUploadButton from "./FileBoobleFolderUploadButton.svelte";
  import FileBoobleFolderUploadLinkButton from "./FileBoobleFolderUploadLinkButton.svelte";

  import FileBoobleFolder from "./FileBoobleFolder.svelte";
  import FileBoobleFile from "./FileBoobleFile.svelte";

  import { post, rpost } from "../js/labit-connection";

  import { onMount } from "svelte";
  import Menu from "./Menu.svelte";

  let expanded = false;
  let expandedList = [];

  let toUploadNumFolders = 0;
  let toUploadNumFiles = 0;
  let toUploadFolderData = null;
  let toUploadFolder = "";
  let toUploadFolderName = "";
  let toUploadFSFiles = [];
  let toUploadFSFolders = [];
  let toBeReplaced = [];
  let dragging = false;
  let draggable = !readOnly;

  let keyword = "";
  let keywords = [];

  let mode = "normal"; // normal, prepareUpload, prepareLink
  let uploading = false;
  let uploadingPerc = 0;
  let cancelUpload = false;

  let removeItem = "";

  let currentName = "";
  let currentUrl = "";

  let removingFullFolder = false;

  let loadingFiles = false;

  const TIME_CANCEL_REMOVE_FULL_FOLDER = 3000;

  let keywordOptions = ["General Document", "Project", "People", "Revit Link"];

  onMount(async () => {
    getKeywordOptions();
    await loadRemoteURLs();

    expanded = true;
  });

  let loadRemoteURLs = async () => {
    if (!loadingFiles) {
      loadingFiles = true;
      files = await loadRemoteURL(url);
      await loadRemoteURLURL(url);
      loadingFiles = false;

      //console.log(files);
    }
  };

  let getKeywordOptions = async () => {
    const response = await post(
      `select distinct keyword
        from keywords`
    );

    keywordOptions = response.reduce((acc, curr) => {
      acc.push(curr.keyword);
      return acc;
    }, keywordOptions);

    keywordOptions.sort();
    keywordOptions = keywordOptions;
  };

  /*let loadRemoteURLFiles = async () => {
    files = await loadRemoteURL(url);
  };*/

  let toggleExpandBooble = () => {
    expanded = !expanded;
  };

  let loadRemoteURL_ = (remoteURL, file, keywords) => {
    let f = null;
    //console.log(file);
    if (file.folder === "1") {
      f = {
        id: remoteURL + "/" + file.name,
        name: file.name,
        folder: true,
        expanded: false,
        files: [],
      };

      // Keep expanded?
      const pos = expandedList.findIndex((item) => {
        return item === f.id;
      });
      f.expanded = pos >= 0;

      file.files.forEach((F) => {
        const F_ = loadRemoteURL_(f.id, F, keywords);
        f.files.push(F_);
      });
    } else {
      const id = remoteURL + "/" + file.name;
      const keys = keywords.reduce((acc, curr) => {
        if (curr.path.replace(/ /g, "").endsWith(id.replace(/ /g, ""))) {
          acc.push({
            id: curr.id,
            keyword: curr.keyword,
          });
        }
        return acc;
      }, []);
      f = {
        id,
        name: file.name,
        url: "",
        path: id,
        folder: false,
        web: false,
        extension: file.extension,
        size: Math.ceil(parseInt(file.size) / 1024) + " KB",
        lastModified: moment(
          file.modificationDate,
          "YYYY-MM-DD HH:mm:ss"
        ).format("DD/MM/YYYY HH:mm:ss"),
        keywords: keys,
      };
    }
    return f;
  };

  let loadRemoteURL = async (remoteURL) => {
    const response = await post(
      `select k.id, keyword, fullPath
        from keywords as k
        left join files as f on f.id=k.file`
    );
    const keywords = response.map((item) => {
      return {
        id: item.id,
        path: item.fullPath,
        keyword: item.keyword,
      };
    });

    let data = await rpost("ListFolderFull", { path: remoteURL });
    data = data.map((file) => {
      return loadRemoteURL_(remoteURL, file, keywords);
    });

    return data;
  };

  let loadRemoteURLURL = async (remoteURL) => {
    //let filesW = [];

    let response = await post(
      `select id, keyword, url
        from urlKeywords`
    );

    const keyws = response.map((item) => {
      return {
        id: item.id,
        url: item.url,
        keyword: item.keyword,
      };
    });

    response = await post(
      `select id, name, url, path, date
        from urls
        where path LIKE '${url}%'`
    );

    if (response.length > 0) {
      const filesW = response.map((item) => {
        const keys = keyws.reduce((acc, curr) => {
          if (curr.url === item.id) {
            acc.push({
              id: curr.id,
              keyword: curr.keyword,
            });
          }
          return acc;
        }, []);
        const f = {
          id: item.id,
          name: item.name,
          url: item.url,
          path: item.path,
          folder: false,
          web: true,
          extension: "",
          size: "",
          lastModified: moment(item.date, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          keywords: keys,
        };
        return f;
      });

      filesW.map((item) => {
        const f = findFileWithId(item.path);
        if (f) {
          if (f.folder) {
            f.files.push(item);
          }
        } else {
          files.push(item);
        }
      });

      files = files;
    }
  };

  let findFileWithId_ = (id, found, file) => {
    let fileOb = null;
    if (file.id === id) {
      fileOb = file;
      found[0] = true;
    } else {
      if (file.folder) {
        file.files.forEach((child) => {
          if (!found[0]) {
            fileOb = findFileWithId_(id, found, child);
          }
        });
      }
    }
    return fileOb;
  };

  let findFileWithId = (id) => {
    let found = [false];
    let fileOb = null;
    files.forEach((file) => {
      if (!found[0]) {
        fileOb = findFileWithId_(id, found, file);
      }
    });
    return fileOb;
  };

  let clickCancelUpload = () => {
    cancelUpload = true;
  };

  let clickRemove = async (id) => {
    if (removeItem !== id) {
      removeItem = id;
    } else {
      const file = findFileWithId(id);
      if (file) {
        if (file.web) {
          await post(
            `delete from urls
              where id=${id}`
          );
        } else {
          await rpost("DeleteFile", { filePath: id });
        }
        await loadRemoteURLs();
      }
    }
  };

  let removeFullFolder = async () => {
    if (removingFullFolder) {
      await rpost("RemoveFolder", {
        path: url,
      });
      onRemoveFullFolder();
    } else {
      removingFullFolder = true;
      setTimeout(cancelRemoveFullFolder, TIME_CANCEL_REMOVE_FULL_FOLDER);
    }
  };

  let cancelRemoveFullFolder = () => {
    removingFullFolder = false;
  };

  let previewFile = async (id) => {
    onPreviewFile(id);
  };

  function dragOverHandler(ev) {
    dragging = true;
    //console.log("File(s) in drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  function traverseFileTree(item, path) {
    /*console.log(item);
    console.log(path);*/
    path = path || "";
    if (item.isFile) {
      toUploadNumFiles++;
      // Get file
      item.file(function (file) {
        //console.log("File:", path + file.name);

        let reader = new FileReader();
        reader.onload = function (event) {
          //console.log(event.target.result);

          let folders = item.fullPath.split("/");
          const name = folders[folders.length - 1];
          folders.pop();
          folders.shift();
          const fullPath = folders.reduce((acc, curr) => {
            acc = acc + "/" + curr;
            return acc;
          }, "");
          const tokens = name.split(".");
          const extension = tokens[tokens.length - 1];

          toUploadFSFiles.push({
            fileName: name,
            fileExtension: extension,
            path: toUploadFolder + fullPath,
            filedata: event.target.result,
          });

          const dupFile = findFileWithId(
            toUploadFolder + fullPath + "/" + name
          );
          if (dupFile) {
            toBeReplaced.push(
              (toUploadFolder + fullPath + "/" + name).replace(url, "")
            );
            toBeReplaced = toBeReplaced;
          }

          //console.log(toBeReplaced);
          //holder.style.background = 'url(' + event.target.result + ') no-repeat center';
        };
        //console.log(file);
        reader.readAsDataURL(file);
      });
    } else if (item.isDirectory) {
      toUploadNumFolders++;

      let folders = item.fullPath.split("/");
      const name = folders[folders.length - 1];
      folders.pop();
      folders.shift();
      const fullPath = folders.reduce((acc, curr) => {
        acc = acc + "/" + curr;
        return acc;
      }, "");

      toUploadFSFolders.push({
        folder: name,
        path: toUploadFolder + fullPath,
      });
      // Get folder contents
      var dirReader = item.createReader();
      dirReader.readEntries(function (entries) {
        for (var i = 0; i < entries.length; i++) {
          traverseFileTree(entries[i], path + item.name + "/");
        }
      });
    }
  }

  let selectFiles = (ev, folder) => {
    toUploadFSFiles = [];
    toUploadFSFolders = [];
    toBeReplaced = [];
    toUploadNumFiles = 0;
    toUploadNumFolders = 0;
    mode = "prepareUpload";
    keywords = [];

    toUploadFolderData = findFileWithId(folder);
    //console.log(toUploadFolderData);
    if (toUploadFolderData) {
      toUploadFolder = toUploadFolderData.id;
      toUploadFolderName = toUploadFolderData.id.replace(url, "");
    } else {
      toUploadFolder = url;
      toUploadFolderName = "/";
    }

    /*console.log(toUploadFolderData);
    console.log(ev.target.files);*/
    for (let i = 0; i < ev.target.files.length; i++) {
      const file = ev.target.files[i];
      if (file) {
        var reader = new FileReader();
        reader.onload = function (evt) {
          const data = evt.target.result;
          const name = file.name;
          const tokens = name.split(".");
          const extension = tokens[tokens.length - 1];
          const path = folder === -1 ? url : folder;

          toUploadFSFiles.push({
            fileName: name,
            fileExtension: extension,
            path,
            filedata: data,
          });

          const dupFile = findFileWithId(toUploadFolder + "/" + name);
          if (dupFile) {
            toBeReplaced.push(
              (toUploadFolder + path + "/" + name).replace(url, "")
            );
            toBeReplaced = toBeReplaced;
          }

          toUploadNumFiles++;
        };
        reader.readAsDataURL(file);
      }
    }
  };

  let selectLink = (uploadURL) => {
    mode = "prepareLink";
    currentName = "";
    currentUrl = "";
    keywords = [];

    toUploadFolder = uploadURL;
    toUploadFolderName = uploadURL.replace(url, "") + "/";
  };

  function drop(ev, id, folder) {
    /*console.log(id);
    console.log(folder);*/

    ev.stopPropagation();
    ev.preventDefault();

    dragging = false;

    toUploadFSFiles = [];
    toUploadFSFolders = [];
    toBeReplaced = [];

    if (folder) {
      toUploadNumFiles = 0;
      toUploadNumFolders = 0;

      toUploadFolderData = findFileWithId(id);
      //console.log(toUploadFolderData);
      if (toUploadFolderData) {
        toUploadFolder = toUploadFolderData.id;
        toUploadFolderName = toUploadFolderData.id.replace(url, "");
      } else {
        toUploadFolder = url;
        toUploadFolderName = "/";
      }

      mode = "prepareUpload";
      keywords = [];
      //console.log(event);
      var items = event.dataTransfer
        ? event.dataTransfer.items
        : event.target.files;
      for (var i = 0; i < items.length; i++) {
        // webkitGetAsEntry is where the magic happens
        var item = event.dataTransfer ? items[i].webkitGetAsEntry() : items[i];
        //console.log(item);
        if (item) {
          traverseFileTree(item);
        }
      }
    }

    /*console.log(toUploadFSFiles);
    console.log(toUploadFSFolders);*/
  }

  function dragleave(ev) {
    event.preventDefault();
    dragging = false;
  }

  let addKeyword = () => {
    const kw = keyword.trim();
    if (kw !== "") {
      const pos = keywords.findIndex((item) => {
        return item === kw;
      });
      if (pos === -1) {
        keywords.push(kw);
      }
    }
    keyword = "";
    keywords = keywords;
  };

  let removeKeyword = (index) => {
    keywords.splice(index, 1);
    keywords = keywords;
  };

  let uploadFiles = async () => {
    uploading = true;

    const uploadTotal = toUploadFSFiles.length + toUploadFSFolders.length;
    let counter = 0;

    for (let i = 0; i < toUploadFSFolders.length && !cancelUpload; i++) {
      counter++;
      uploadingPerc = Math.round((counter / uploadTotal) * 100);
      const folder = toUploadFSFolders[i];
      await rpost("CreateFolder", folder);
    }

    for (let i = 0; i < toUploadFSFiles.length && !cancelUpload; i++) {
      counter++;
      uploadingPerc = Math.round((counter / uploadTotal) * 100);
      const file = toUploadFSFiles[i];
      const f = {
        path: file.path,
        fileName: file.fileName,
        fileExtension: file.fileExtension,
        fileData: file.filedata,
        keywords,
      };
      await rpost("CreateFile", f);
    }

    let currentFolder = findFileWithId(toUploadFolder);
    if (currentFolder && !currentFolder.expanded) {
      const pos = expandedList.findIndex((item) => {
        return item.id === toUploadFolder;
      });
      if (pos === -1) {
        currentFolder.expanded = true;
        expandedList.push(toUploadFolder);
        expandedList = expandedList;
      }
    }

    await loadRemoteURLs();

    cancelUpload = false;
    uploading = false;
    mode = "normal";
  };

  let uploadUrl = async () => {
    if (currentName.trim() === "") {
      return;
    }
    if (currentUrl.trim() === "") {
      return;
    }
    uploading = true;
    if (!currentUrl.toLowerCase().startsWith("http")) {
      currentUrl = "https://" + currentUrl;
    }
    const now = moment().format("YYYY-MM-DD HH:mm:ss");
    const response = await post(
      `insert into urls (name, path, url, date)
        values ('${currentName}', '${toUploadFolder}', '${currentUrl}', '${now}')`
    );
    const id = response[0];
    for (let i = 0; i < keywords.length; i++) {
      const keyword = keywords[i];
      await post(
        `insert into urlKeywords (keyword, url)
          values ('${keyword}', ${id})`
      );
    }

    await loadRemoteURLs();

    cancelUpload = false;
    uploading = false;
    mode = "normal";
  };

  /*const files = [
    {
      id: 1,
      name: "1.1. Example 1",
      folder: true,
      expanded: true,
      files: [
        {
          id: 2,
          name: "File 1.txt",
          expanded: false,
          folder: false,
          lastModified: "2020-10-03 12:04:09",
          extension: "txt",
          size: "1024 KB",
          files: []
        },
        {
          id: 3,
          name: "File 2.pdf",
          expanded: false,
          folder: false,
          lastModified: "2020-10-03 12:04:09",
          extension: "pdf",
          size: "1024 KB",
          files: []
        },
        {
          id: 8,
          name: "Carpeta con nombre largo larguisimo",
          expanded: false,
          folder: true,
          files: [
            {
              id: 9,
              name: "F1.txt",
              expanded: false,
              folder: false,
              lastModified: "2020-10-03 12:04:09",
              extension: "txt",
              size: "1024 KB",
              files: []
            },
            {
              id: 10,
              name: "F2.txt",
              expanded: false,
              folder: false,
              lastModified: "2020-10-03 12:04:09",
              extension: "txt",
              size: "1024 KB",
              files: []
            },
            {
              id: 11,
              name: "F3.txt",
              expanded: false,
              folder: false,
              lastModified: "2020-10-03 12:04:09",
              extension: "txt",
              size: "1024 KB",
              files: []
            }
          ]
        }
      ]
    },
    {
      id: 4,
      name: "1.2. Example 2",
      folder: true,
      expanded: true,
      files: [
        {
          id: 5,
          name: "File 3.txt",
          expanded: false,
          folder: false,
          lastModified: "2020-10-03 12:04:09",
          extension: "txt",
          size: "1024 KB",
          files: []
        },
        {
          id: 6,
          name: "File 4.pdf",
          expanded: false,
          folder: false,
          lastModified: "2020-10-03 12:04:09",
          extension: "pdf",
          size: "1024 KB",
          files: []
        }
      ]
    },
    {
      id: 7,
      name: "Fichero suelto",
      folder: false,
      expanded: false,
      lastModified: "2020-10-03 12:04:09",
      extension: "",
      size: "1024 KB",
      files: []
    }
  ];*/

  $: if (url !== "") {
    loadRemoteURLs();
  }

  $: draggable = !readOnly;

  /*$: console.log(files);
  $: console.log(expandedList);*/
</script>

<div
  class="fileContainerContainer {mode === 'normal' && draggable && dragging
    ? 'dragging'
    : ''}
  {initialBorder ? 'border' : ''}"
  on:drop={draggable
    ? (e) => {
        drop(e, -1, true);
      }
    : null}
  on:dragover={draggable ? dragOverHandler : null}
  on:dragleave={draggable ? dragleave : null}
>
  <div class="title" on:click={() => toggleExpandBooble()}>
    <span>{title.toUpperCase()}</span>
    <div class="titleOptions">
      <img
        src={expanded ? "/images/down_arrow.svg" : "/images/up_arrow.svg"}
        alt="Expand Arrow"
      />
      {#if removable}
        <img
          src={removingFullFolder
            ? "/images/trash-red.svg"
            : "/images/trash.svg"}
          alt="Remove Folder"
          on:click={(ev) => {
            removeFullFolder();
            ev.stopPropagation();
          }}
        />
      {/if}
    </div>
  </div>
  {#if expanded}
    {#if mode === "normal"}
      <div class="body">
        {#each files as file}
          {#if file.folder}
            <FileBoobleFolder
              bind:file
              bind:expandedList
              {drop}
              {dragOverHandler}
              bind:draggableParent={draggable}
              {removeItem}
              onClickRemove={(id) => clickRemove(id)}
              onPreviewFile={(id) => previewFile(id)}
              {mode}
              {readOnly}
              {compressedMode}
              onClickSelectButton={(ev, id) => selectFiles(ev, id)}
              onClickSelectUrlButton={(url) => selectLink(url)}
            />
          {:else}
            <FileBoobleFile
              {file}
              {removeItem}
              onClickRemove={(id) => clickRemove(id)}
              onPreviewFile={(id) => previewFile(id)}
              {readOnly}
              {compressedMode}
            />
          {/if}
        {/each}
      </div>
    {:else if mode === "prepareLink"}
      <div class="bodyUpload customScrollbars">
        {#if !uploading}
          <div class="x">
            <img
              src="/images/x.svg"
              alt="Cancel Icon"
              on:click={() => {
                mode = "normal";
              }}
            />
          </div>
        {/if}
        <span class="keywords">Name:</span>
        <div class="keyword">
          <TextEdit placeholder="" img={null} bind:value={currentName} />
        </div>
        <span class="keywords">URL:</span>
        <div class="keyword">
          <TextEdit placeholder="" img={null} bind:value={currentUrl} />
        </div>
        <span class="keywords">Keywords:</span>
        <div class="keyword">
          <TextEditHints
            placeholder="type keyword..."
            maxlength="50"
            img={null}
            bind:value={keyword}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                addKeyword();
              }
            }}
            hints={keywordOptions}
          />
          <img
            src="/images/plus.svg"
            alt="Add Keyword"
            on:click={() => addKeyword()}
          />
        </div>
        <div class="keywords">
          {#each keywords as keyword, index}
            <Keyword {keyword} onRemove={() => removeKeyword(index)} />
          {/each}
        </div>
        <div class="info">
          <span>Upload to folder: {toUploadFolderName}</span>
        </div>
        {#if uploading}
          <div class="progressbar">
            <img
              class="cancelUploading"
              src="/images/x.svg"
              alt="Cancel Icon"
              on:click={() => clickCancelUpload()}
            />
            <div class="progressbarBar">
              <Progressbar
                perc={uploadingPerc}
                backgroundColor="var(--labit-background-gray)"
                foregroundColor="var(--labit-card-gray)"
              />
            </div>
          </div>
        {:else}
          <div class="button">
            <SubmenuBlackButtonIcon
              text="Upload"
              icon="/images/globe-white.svg"
              onClick={() => uploadUrl()}
            />
          </div>
        {/if}
      </div>
    {:else}
      <div class="bodyUpload customScrollbars">
        {#if !uploading}
          <div class="x">
            <img
              src="/images/x.svg"
              alt="Cancel Icon"
              on:click={() => {
                mode = "normal";
              }}
            />
          </div>
        {/if}
        <span class="keywords">Keywords:</span>
        <div class="keyword">
          <TextEditHints
            placeholder="type keyword..."
            maxlength="50"
            img={null}
            bind:value={keyword}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                addKeyword();
              }
            }}
            hints={keywordOptions}
          />
          <img
            src="/images/plus.svg"
            alt="Add Keyword"
            on:click={() => addKeyword()}
          />
        </div>
        <div class="keywords">
          {#each keywords as keyword, index}
            <Keyword {keyword} onRemove={() => removeKeyword(index)} />
          {/each}
        </div>
        <div class="info">
          <span>Upload to folder: {toUploadFolderName}</span>
          <span>Num Folders: {toUploadNumFolders}</span>
          <span>Num Files: {toUploadNumFiles}</span>
          {#if toBeReplaced.length > 0}
            <div class="danger">
              <span>
                Warning - The following files already exist and they will be
                replaced:
              </span>
              {#each toBeReplaced as path}
                <span>{path}</span>
              {/each}
            </div>
          {/if}
        </div>
        {#if uploading}
          <div class="progressbar">
            <img
              class="cancelUploading"
              src="/images/x.svg"
              alt="Cancel Icon"
              on:click={() => clickCancelUpload()}
            />
            <div class="progressbarBar">
              <Progressbar
                perc={uploadingPerc}
                backgroundColor="var(--labit-background-gray)"
                foregroundColor="var(--labit-card-gray)"
              />
            </div>
          </div>
        {:else}
          <div class="button">
            <SubmenuBlackButtonIcon
              text="Upload"
              icon="/images/cloud-upload.svg"
              onClick={() => uploadFiles()}
            />
          </div>
        {/if}
      </div>
    {/if}
  {/if}
  {#if mode === "normal" && !readOnly}
    <div class="uploadFile">
      <FileBoobleFolderUploadButton
        onClick={(ev, id) => selectFiles(ev, id)}
        file={-1}
      />
      <FileBoobleFolderUploadLinkButton onClick={() => selectLink(url)} />
    </div>
  {/if}
</div>

<style>
  div.fileContainerContainer {
    width: 100%;
    background-color: white;
    border-radius: 14px;
    position: relative;
  }

  div.border {
    border-color: var(--labit-card-lightgray);
    border-width: 1px;
    border-style: solid;
  }

  div.fileContainerContainer:not(:first-child) {
    margin-top: 13px;
  }

  div.title {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--labit-card-gray);
    cursor: pointer;
  }

  div.title img {
    width: 20px;
    margin-left: 7px;
  }

  div.titleOptions {
    display: flex;
  }

  div.body {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    overflow-x: auto;
    overflow-y: auto;
    font-size: 15px;
  }

  div.bodyUpload {
    width: 100%;
    /*height: 100%;*/
    padding: 20px;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--labit-dialog-background);
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    font-size: 15px;
    min-height: 215px;
  }

  div.dragging {
    background-color: var(--labit-card-gray-trans);
  }

  div.keyword {
    height: 21px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    max-width: 800px;
  }

  div.keyword img {
    width: 19px;
    height: 19px;
    cursor: pointer;
    margin-left: 6px;
  }

  div.keywords {
    display: flex;
    flex-wrap: wrap;
  }

  span.keywords {
    margin-bottom: 6px;
  }

  div.x {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 6px;
  }

  div.x img {
    width: 9px;
    height: 9px;
    cursor: pointer;
  }

  div.button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div.info {
    margin-top: 8px;
    margin-bottom: 13px;
    display: flex;
    flex-direction: column;
  }

  div.progressbar {
    width: 100%;
    height: 15px;
    display: flex;
    align-items: center;
  }

  div.progressbarBar {
    width: 100%;
    height: 15px;
    display: flex;
  }

  img.cancelUploading {
    width: 9px;
    height: 9px;
    cursor: pointer;
    margin-right: 10px;
  }

  div.danger {
    display: flex;
    flex-direction: column;
    color: var(--labit-light-red-strong);
    margin-top: 10px;
  }

  div.uploadFile {
    display: flex;
    justify-content: center;
  }

  /* Animations */

  div.bodyUpload img {
    transition: opacity 0.5s linear 0s;
  }

  div.bodyUpload img:hover {
    opacity: 0.5;
  }
</style>
