<script>
  export let text;
  export let onClick;
</script>

<div class="fadedButton dialogSmallButtonContainer" on:click={onClick}>
  <span>{text}</span>
</div>

<style>
  div.dialogSmallButtonContainer {
    display: flex;
    justify-content: center;
    font-size: 11px;
    color: white;
    background-color: var(--labit-card-gray);
    padding: 3px 7px 3px 7px;
    border-radius: 10px;
    min-width: 50px;
    font-weight: bold;
  }
</style>
