<script>
  export let image;
  export let name = "";
  export let grayscale = true;
  export let small = false;
  export let showHint = false;
  export let onClick = null;
  export let lastActivity = "";
  export let officeStatus = null; /* {
    icon: "/images/profile-status/away.svg",
  };*/
  export let atHome = "0";
  export let blurred = false;
  export let border = "";

  import { LABIT_PUBLIC_FOLDER } from "../js/labit-connection";

  import { randomString } from "../js/randomString";

  import { onMount } from "svelte";

  import moment from 'moment';
  let initials = "";

  let status = ""; // online, offline, away
  let statusColor = "transparent";

  let filters = "";

  let getPeopleInterval;
  onMount(() => {
    calculateName();
    getPeopleInterval = setInterval( async () => {
      const element = document.getElementById('status');
      if (element !== null) {
        if (element.offsetParent !== null) {
          getStatus();
        }
      }
    }, 15000 );
  });

  let calculateName = () => {
    initials = "";
    if (
      !image ||
      image === "" ||
      image === LABIT_PUBLIC_FOLDER ||
      typeof image === "undefined"
    ) {
      initials = "";
      if (name) {
        const tokens = name.split(" ");
        if (tokens.length > 0) {
          initials = tokens[0][0];
        }
        if (tokens.length > 1) {
          initials = initials + tokens[1][0];
        }
      }
      initials = initials.toUpperCase();
    }
    setFilters();
  };

  let getStatus = () => {
    if (lastActivity !== "") {
      const now = moment();
      if (
        now.format("YYYYMMDD") !==
        moment(lastActivity, "YYYY-MM-DD HH:mm:ss").format("YYYYMMDD")
      ) {
        status = "offline";
      } else {
        const diff = now.diff(lastActivity, "minutes");
        status =
          diff >= 60 || officeStatus.description !== "Working"
            ? "away"
            : "online";
      }
      switch (status) {
        case "online":
          statusColor = "var(--labit-status-online)";
          break;
        case "away":
          statusColor = "var(--labit-status-away)";
          break;
        case "offline":
          statusColor = "var(--labit-status-offline)";
          break;
      }
    }
  };

  let setFilters = () => {
    filters = "";
    if (grayscale || blurred) {
      filters = "filter:";
      if (grayscale && initials === "") {
        filters = filters + " grayscale(100%)";
      }
      if (blurred) {
        filters = filters + " blur(3px)";
      }
      filters = filters + ";";
    }
  };

  $: if (lastActivity && officeStatus) {
    getStatus();
  }

  $: if (name || image) {
    calculateName();
  }

  $: if (grayscale || blurred) {
    setFilters();
  }
</script>

<div
  class="pictureContainer {showHint ? 'hint' : ''}"
  title={showHint ? name : ""}
  on:click={() => {
    if (onClick) {
      onClick();
    }
  }}
>
  {#if !image || image === "" || image === LABIT_PUBLIC_FOLDER}
    <div
      class="nopic {small ? 'small' : ''}"
      style="{filters} {border !== ''
        ? 'border-color: ' +
          border +
          '; border-width: 1px; border-style: solid;'
        : ''}"
    >
      {initials}
    </div>
  {:else}
    <div
      class="pic"
      style="background-image: url({image}); {filters} {border !== ''
        ? 'border-color: ' +
          border +
          '; border-width: 1px; border-style: solid;'
        : ''}"
    />
  {/if}
  {#if lastActivity !== ""}
    <div
      class="status"
      style="background-color: {statusColor}; border-color: {statusColor ===
      'transparent'
        ? 'transparent'
        : ''}"
    />
  {/if}
  {#if officeStatus && officeStatus.description !== "Working"}
    <img
      class="officeStatus"
      src={officeStatus.icon + "?" + randomString(10)}
      alt="Profile Status"
    />
  {/if}
  {#if atHome === "1"}
    <img
      class="home"
      src="/images/profile-status/athome.svg"
      alt="Working at Home"
    />
  {/if}
</div>

<style>
  div.pictureContainer {
    width: 100%;
    height: 100%;
    position: relative;
  }

  div.pic {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 50%;
  }

  div.nopic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--labit-card-gray);
    color: white;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.small {
    font-size: 8px !important;
  }

  div.hint {
    cursor: pointer;
  }

  div.status {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    border: 1px solid white;
  }

  img.officeStatus {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 25%;
    max-width: 25%;
  }

  img.home {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 25%;
  }
</style>
