import { post } from "./labit-connection";

export let updateCityCode=async (name, code2, code3) =>{
    const response=await post(
        `select id
            from city_codes
            where name='${name}'`
    );
        if (response.length===0) {
            await post(
                `insert into city_codes (name, code2, code3) 
                values ('${name}', '${code2}', '${code3}')`
            );
        }
        else {
            const id=response[0].id;
            await post(
                `update city_codes set
                    code2='${code2}',
                    code3='${code3}'
                    where id='${id}'`
            );
        }
}