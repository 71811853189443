<script>
  export let product;
  export let company;

  import { randomString } from "../../../js/randomString";

  let total = 0;
  let activeKeys = 0;

  $: {
    total = 0;
    activeKeys = 0;
    total = company.keys.length;

    company.suscriptions.forEach((s) => {
      if (s.product === product.id) {
        let keyEnc = company.keys.find((k) => {
          return k.id === s.license;
        });
        if (keyEnc) {
          if (keyEnc.active) {
            activeKeys++;
          }
        }
      }
    });
  }
</script>

<div class="companyProductContainer">
  <img
    class="productLogo"
    src={product.pictureURL + "?" + randomString(10)}
    alt="productLogo"
  />
  <span>{activeKeys}/{total}</span>
</div>

<style>
  .companyProductContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90px;
  }
  .productLogo {
    width: 30px;
  }

  .companyProductContainer span {
    margin-top: 3px;
    font-weight: bold;
    font-size: 13px;
  }
</style>
