<script>
  export let message;
  
  import moment from 'moment';
</script>

<style>
  div.outlookMessageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  div.header {
    width: 100%;
    display: flex;
    margin-bottom: 2px;
  }

  div.new {
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div.newCircle {
    width: 8px;
    height: 8px;
    background-color: var(--labit-bar);
    border-radius: 50%;
  }

  div.from {
    width: 60%;
    font-size: 15px;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div.from span,
  div.time span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div.time {
    width: 30%;
    font-size: 12px;
    color: var(--labit-dashboard-light-gray);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  div.subject {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: var(--labit-dashboard-light-gray);
  }

  div.subject div {
    width: 90%;
  }
</style>

<div class="outlookMessageContainer">
  <div class="header">
    <div class="new">
      {#if message.new}
        <div class="newCircle" />
      {/if}
    </div>
    <div class="from">
      <span>{message.from}</span>
    </div>
    <div class="time">
      <span>
        {moment(message.datetime, 'YYYY-MM-DD HH:mm:ss').fromNow(true)}
      </span>
    </div>
  </div>
  <div class="subject">
    <div>
      <span>{message.subject}</span>
    </div>
  </div>
</div>
