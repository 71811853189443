<script>
    import { tooltip } from './tooltip/tooltip';
    import { fade } from 'svelte/transition';
    export let size = 20;
    export let tooltipText = '';
    export let src = '';
    export let active = false;
</script>

<div in:fade={{ delay: 300, duration: 400 }} out:fade={{ duration: 400 }} class="img-container" class:bg-color={active}>
{#if tooltipText === ''}
<img src={src} alt="" width={size}>
{:else}
<img use:tooltip={{ x: -92, y: -52 }} src={src} alt="" width={size} height={size} description={tooltipText}>
{/if}
</div>

<style>
    img {
        object-fit: cover;
        border-radius: 100%;
        cursor: pointer;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        /* user-drag: none; */
    
        -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
    }

    .img-container {
        padding: 6px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bg-color {
        background-color: #35425b7d;
    }
</style>