<script>
  export let calendar = -1;
  export let visible;

  import WebBrowser from "../components/WebBrowser.svelte";

  import { OUTLOOK_URL, OUTLOOK_CALENDAR_URL } from "../js/globalExternalURLs";
  import {
    getOutlookSendEmail,
    setOutlookSendEmail,
    getOS,
  } from "../js/localInfo";

  let url = "";

  const os = getOS();

  let loadInfo = () => {
    url = getOutlookSendEmail();
    if (url !== "") {
      setOutlookSendEmail("");
    } else {
      url = calendar === -1 ? OUTLOOK_URL : OUTLOOK_CALENDAR_URL;
    }
  };

  $: if (visible) {
    loadInfo();
  }
</script>

<div class="content">
  <!-- Body -->
  <div class="container">
    <WebBrowser
      id="webview-outlook"
      type="webview"
      src={url}
      useragent={os === "mac"
        ? "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.3 Safari/605.1.15"
        : null}
    />
  </div>
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    flex-direction: column;
    margin-bottom: 20px;
  }
</style>
