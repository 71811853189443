<script>
  export let name;
  export let timeLabitCode;
  export let folderLabitCode;
  export let onRemove;
</script>

<div class="standardProjectItemContainer">
  <div class="project">
    <span>{timeLabitCode}</span>
    <span>{folderLabitCode}</span>
    <span>{name}</span>
  </div>
  <div class="options">
    <img
      class="fadedButton"
      src="/images/trash.svg"
      alt="Remove Project"
      on:click={onRemove}
    />
  </div>
</div>

<style>
  div.standardProjectItemContainer {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
  }

  div.project {
    display: flex;
    width: 90%;
    height: 22px;
    border-radius: 14px;
    border: 0.5px solid var(--labit-card-gray);
    font-size: 13px;
    color: var(--labit-card-gray);
    padding: 0px 5px 0px 5px;
    align-items: center;
  }

  div.options {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  img {
    width: 20px;
  }

  span:not(:last-child) {
    margin-right: 13px;
  }
</style>
