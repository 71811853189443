import dayjs from "dayjs";

export const objectIsEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}

export const getTimeArr = (from, to, type) => {
    const diff = (to.diff(from, type)+1);

    let datesArray = [];
    for (let i = 0; i < diff; i++) {
        const m = dayjs(from);
    	datesArray.push(m);
        from = from.add(1, type);
    }
    return datesArray;
}
